import { HaborComponent, HaborIconSelection } from 'habor-sdk';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

//  TODO:  Develop a mechanism by which to register override components with Davel.  This should be a ReactNative component, and Hessia is resopnsible for converting the HaborComponent / Block -> React Native (element / component?)
import { IconDot, IconSelector } from '../../../../packages/davel-ui/habor-types/icon/icon-field';

//
//  Icon Habor Component
//

export interface IconHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    value?: HaborIconSelection;
  };
}

export const IconTypeHaborPrimitive = ({ userProps, frameworkProps }: IconHaborPrimitiveProps) => {
  const { value = { name: "dot", type: "material" } } = userProps;
  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <IconDot {...userProps} icon={value} />
    </HaborContainer>
  );
};

//  TODO-IMPORTANT:  The Components which are used to render a TYPE may be part of a special class of components?  MAYBE they have certain required fields like "Value"?
export const IconTypeHaborComponent: HaborComponent = {
  name: "IconTypeHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "IconTypeHaborPrimitive",
    props: {
      value: { type: "symbol", scopePath: ["props", "value"] }
    },
    children: []
  }
};


//
//  IconSelectorField Habor Component
//

export interface IconSelectorFieldHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    value: HaborIconSelection;
    update: (value: HaborIconSelection) => void;
  }
}

export const IconSelectorFieldHaborPrimitive = ({ userProps, frameworkProps }: IconSelectorFieldHaborPrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <IconSelector value={userProps?.value} update={userProps?.update} />
    </HaborContainer>
  );
};

export const IconSelectorFieldHaborComponent: HaborComponent = {
  name: "IconSelectorFieldHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "IconSelectorFieldHaborPrimitive",

    //  TODO:  SHOULD we be passing low level things like ReduxForm props in our HaborComponents??  It PROBABLY makes sense to abstract this away so we're not tied to their contract!
    props: {
      value: { type: "symbol", scopePath: ["props", "value"] },
      update: { type: "symbol", scopePath: ["props", "update"] }
    },
    children: []
  }
};
