import { HaborIconSelection, InstanceInternal, NamedObject, NounInternal, Relationship, RGBAColor } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { Card } from '../../../../packages/kelp-bar/card';
import { RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { ReactRegisterProps } from '../../../../packages/registers/react-registers';

//  TODO-CRITICAL:  We SHOULD support MULTIPLE Classes!  And perhaps more generically, MULTIPLE ASSOCIATED SYMBOLS.  Then the idea is, wehenever we have a thing, it's up to us how to display it.. MAYBE with a cCOUPLING dislay which is dependet upon MULTIPLE symbols!? HMM MAYBE dependent upon specific doncidiotns, OR simply just the presence of a symbol.  THEN we need a way to break ties and shit!  This is really cool!!!  Super general!
//  NOTE:  This COULD be typed!

export interface RelationshipListItemProps<T extends NamedObject> extends ReactRegisterProps {
  item: InstanceInternal<Relationship>;
  onPress?: (item: InstanceInternal<Relationship>) => void;
  onLongPress?: (item: InstanceInternal<Relationship>) => void;
  registers?: {
    left: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
    top: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
    right: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
  }
}

export class RelationshipListItem<T extends NamedObject> extends React.Component<RelationshipListItemProps<T>, any> {
  public render = () => {

    const { item, onLongPress = () => null, onPress = () => null, registers: { left, top, right } = { left: [], top: [], right: [] } } = this.props;
    let name: string;
    let description: string | undefined;
    let icon: HaborIconSelection | undefined;
    let color: RGBAColor | undefined;

    const renderedColor = renderRGBAColor(color || { r: "255", g: "200", b: "102" });

    return (
      <Card outerStyle={{ flex: 1 }} raisedHeight={RaisedHeight.high} innerStyle={{ display: 'flex', flexDirection: 'column', padding: 0, height: 100 }} onLongPress={() => onLongPress(item)} onPress={() => onPress(item)}>
        <Text>RELATIONSHIP</Text>
        <Text>{ JSON.stringify(item.payload) }</Text>
      </Card>
    );
  }
}
