import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { TagPlugin } from '.';
import { EntityPlugin } from '../entity-plugin';
import { TagEditor } from './tag-editor';
import { TagList } from './tag-list';

const Stack = createStackNavigator();

export const TagNavigator = ({ tagPlugin, navigation, route, entityPlugin }: { tagPlugin: TagPlugin, navigation: NavigationProp<any>, route: any, entityPlugin: EntityPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} { ...{ headerMode: "none" } }>
      <Stack.Screen name="Editor" component={ ({ route, navigation }) => <TagEditor entityPlugin={ entityPlugin } route={ route } tagPlugin={ tagPlugin } navigation={ navigation } /> } />
      <Stack.Screen name="List" component={ ({ route, navigation }) => <TagList navigation={ navigation } tagPlugin={ tagPlugin } /> } />
    </Stack.Navigator>
  );
}