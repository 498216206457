import * as React from 'react';
import { Image, Pressable, Text, TextStyle, View, ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import { ActivityIndicator } from 'react-native-paper';
import { Paragraph, defaultBorderRadius, primaryColor } from '../constants';

// TODO:  SHOULD be able to easily add support for ICON without breakin it hm!... Instead of making a WHOLE new "TextButton" or "IconTextButton" or "IconButton" we just associate MODIFIERS mm!  THIS way we can use an API to change it hm!  It BUILDS a component hm!  But like... maybe that IS just a component then? Hmm...    BUT it changes the API? Hmmm...  Fuck.  DO want to keep pushing it.  CAN have a generator, OR we can hav ea COMPONENT which generates like we have with Halia hm!

//  TODO:  HATEEE that we LIMIT what this thing can do with an explicit MAPPING... This is an API.  It's a MAPPING from one encoding to another hm!  That IS an API HM!  It's a way to specify like hm!  When we ahve a function that "uses" an API, it's JUST REFERENCING those symbols AND COPULING an ONTOLOGICAL STRUCUTRE for the interpreter to map through hm!  LOVE that mm!!! It's ALL ust ontologies hm!!!

//  NOTE:  These "void" functions get information from CLOSURE SCOPE!

// CONSIDER:  We CAN have people change the icon color FileSystemDirectoryHandle, etc... but I think this is effectively a GRAPH that we're modifying.  So, we'll want to make sure we support that hmmm...

export const GalleryButton = ({ emoji, icon, textColor, loading, disabled, title, style, onPress, onLongPress, textStyle }: { textStyle?: TextStyle, emoji?: string, icon?: { type: string, name: string }, textColor?: string, loading?: boolean, disabled?: boolean, title?: string, style?: ViewStyle, onPress?: () => void, onLongPress?: () => void }) => {
  return (
    <Pressable disabled={disabled} style={[{ paddingHorizontal: 30, paddingVertical: 15, borderRadius: defaultBorderRadius, backgroundColor: primaryColor, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }, style]} onPress={onPress} onLongPress={onLongPress} >
      {
        loading ? (
          <ActivityIndicator color="white" />
        ) : (
          <>
            {
              (emoji || icon) && (
                <View style={{ flexGrow: 0, flexShrink: 0, alignItems: 'center', justifyContent: 'center' }}>
                  {icon && <Icon color={textColor} style={{ marginLeft: 10 }} name={icon.name} type={icon.type} />}
                  {emoji && <Text>{emoji}</Text>}
                </View>
              )
            }
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Paragraph style={[{ color: textColor || 'white' }, textStyle]}>
                {title}
              </Paragraph>
            </View>
          </>
        )
      }

    </Pressable>
  );
}

export const EmojiButton = ({ emoji, logo, style, onPress, onLongPress, selected, icon }: { emoji?: string, selected: boolean, logo?: number, icon?: { type: string, name: string }, textColor?: string, loading?: boolean, disabled?: boolean, title?: string, style?: ViewStyle, onPress?: () => void, onLongPress?: () => void }) => {
  return (
    <Pressable style={[{ borderRadius: 5, backgroundColor: selected ? 'transparent' : 'transparent', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }, style]} onPress={onPress} onLongPress={onLongPress} >
      {
        logo ?
          <Image source={logo} style={{ height: 40, width: 40, borderRadius: 20 }} resizeMode='contain' /> :
          icon ?
            <Icon style={{ height: 40, width: 40, borderRadius: 20 }} name={icon.name} type={icon.type} /> :
            <Text style={{ fontSize: 20 }}>{emoji}</Text>
      }

    </Pressable>
  );
}




// CONSIDER:  The idea is.. the FEATURES will determine what the freaking thing is UGH!  ... we lose TYPESCRIPt though  
//  TODO:  Support NESTED features like Halia!  MAYBE we SHOULD just build it with Halia hm!!!
//  CONSIDER:  SOME "features" are MUTUALLY excusive!  In Halia I BELIEVE we can handle that!  THIS way as we're building it's encoded hm! Need ANOTHER INTERPRETER!  That's what TS is mm!!!  MAYBE make ANTHTER HM!!!  This way we CAN do it dynamically and stuff hM!
//             Seriously!!!  Seriously consider building something LIKE Typescipt OR even extending typescirpt for this??? Hm!

//  NOTE:  CAN also just do it by having a component that has a BUNCH of props specified and it uses them all? Hmm.. still what about mutual excusion? hmm...

// export const ButtonFactory = ({ features, ...props }: { features: string[] }) => {

//   if (features.includes("text")) {

//   }

// }