/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

interface HCombineParams {
  [index: string]: any;
}

export const HCombineFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "combine",
  params: {
    type: "object",
    extensible: true
  },
  returns: {
    type: "object",
    extensible: true
  },
  definition: async (input: PrimitiveFunctionParams<HCombineParams>): Promise<HCombineParams> => {
    const { params } = input;
    return params;
  }
};
