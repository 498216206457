import { SDTObject } from 'habor-sdk';
import { Color, HaborComponent, HaborDeferredValue, serializedInstanceInternalSchema } from 'habor-sdk';
import * as React from 'react';
import { Text, TouchableOpacity, ViewStyle } from "react-native";
import { getRaisedStyle, primaryFontFamily, RaisedHeight } from '../../../../../packages/kelp-bar/styles';
import { HaborContainer, PrimitiveProps } from "../../../component-plugin/habor-react/habor-component-lib";

//
//  Instance View Base
//

export const InstanceViewDavelProps = {
  instance: { ...serializedInstanceInternalSchema, required: true }
}
export const InstanceViewComponentProps: { [name: string]: HaborDeferredValue } = {
  instance: { type: "symbol", scopePath: ["props", "instance"] },
}

//
//  Habor InstanceView Primitive
//

//  NOTE:  EVERY Instance View must accept an Instance prop.
//  Ah!  That explains the issue!  I was MAPPING from instance -> instance property USING a PROP... BUT, instead, we should be making that decision within the component itself?? 
//   SO!  TWO different models:  One where we PASS the PATH down the compnent tree and use a SINGLE component to retrieve the value... Another where we COMPOSE the elements UNIQUELY for each case... This seems MUCH more scalable.  We choose what type of complexity to add at the COMPONENT level.  There's a tradeoff here.  Not we DO need to make a new component EACH time we want to use a view... Liket "Chicklet".  Before, when it accepted a Path, we simply CONFIGURED the Component with the path we want to show.  NOW, we need to CREATE the component.  I think this is OK... Maybe even preferable though.  We MIGHT want to consider a mixture?  Not sure.  I like the composition approach better I think.  It keeps things composed nicely.  The wrapping component accepts an instance, and the inner logic maps that to a scope variable to be used by the inner component.  To me, this MAY be preferable to passing a "path"... The path would potentially need transformation as well.  By keeping it in the component, we push all that logic to a new THING... Namely, a component!
//  Hmmm... we MIGHT even want to SKIP the entire process of making a PRIMITIVE for this... Maybe we can go straight to the outer component??  YES, I think we can.  What about mappings?  How we do we map a NESTED Scope variable?
// export interface InstanceViewUserProps { instance: InstanceInternal<any> };
// export interface InstanceViewProps extends PrimitiveProps { userProps: InstanceViewUserProps };
// export const HaborInstanceView = ({ userProps: { instance }, frameworkProps }: InstanceViewProps) => {
//   const value = instance.payload[keywordPropName] as string;
//   return (
//     <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
//       <Chicklet title={ value } />
//     </HaborContainer>
//   );
// };


//
//  Chicklet React Component
//  NOTE:  This follows the pattern where it accepts a custom "style" prop.  It cannot be stylized with the traditional CSS "style" prop.  Although, we COULD consider supporting this.
//

export interface ChickletProps extends React.ComponentProps<any> { onPress?: () => void, style?: ViewStyle };
export interface TitleChickletProps { title: string, onPress?: () => void, style?: { textColor?: string, backgroundColor?: string } };
export const TitleChickletPropsSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    title: { type: "keyword", required: true },
    style: {
      type: "object",
      extensible: false,
      required: false,
      properties: {
        textColor: { type: "keyword", required: false },
        backgroundColor: { type: "keyword", required: false },
      }
    }
  }
};

export const TitleChiclet = ({ onPress, title, style: { textColor, backgroundColor } = { textColor: undefined, backgroundColor: undefined } }: TitleChickletProps) => (
  <TouchableOpacity onPress={ onPress } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: backgroundColor || Color.primary, borderRadius: 15, ...getRaisedStyle({ raisedHeight: RaisedHeight.med }), paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8 }}>
    <Text style={{ fontFamily: primaryFontFamily, color: textColor || Color.white, fontSize: 12 }}>{ title }</Text>
  </TouchableOpacity>
);

export const Chiclet = ({ onPress, style, children }: ChickletProps) => (
  <TouchableOpacity onPress={ onPress } style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 15, ...getRaisedStyle({ raisedHeight: RaisedHeight.med }), paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, ...style }}>
    { children }
  </TouchableOpacity>
);

//
//  Chicklet Primitive Component
//
export interface ChickletHaborPrimitiveProps extends PrimitiveProps {
  userProps: TitleChickletProps;
}
export const ChickletHaborPrimitive = ({ userProps, frameworkProps }: ChickletHaborPrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <TitleChiclet { ...userProps } />
    </HaborContainer>
  );
};

//
//  Chicklet Habor Component
//
export const ChickletHaborComponent: HaborComponent = {
  name: "ChickletHaborComponent",
  propsSchema: TitleChickletPropsSchema,
  element: {
    name: "ChickletHaborPrimitive",
    props: {
      title: { type: "symbol", scopePath: ["props", "title"] },
      style: { type: "symbol", scopePath: ["props", "style"] }
    },
    children: []
  }
};



//  NOTE:  I use the wrapped primitive so the UI knows the types of the props at runtime!
//  TODO:  How to encode the input type of a Instance View?
//  TODO:  Instead of making a new HaborComponent for EACH InstanceView that wants to use "Chicklet", it would be nice if we could simplify this process a bit... We can keep thinking about it.  Maybe make an easy way to "Duplicate" a component and then use a constant / setting INSTEAD of compositional changes to sub-elements?
//  TODO:  Ah, I don't like that we have to recreate for every noun... instead, mabye we SHOULD support passing of a prop?  Maybe the function to render an "Embedded View" or "Instance View" can handle this?  Maybe we can key the UI to understand the point that only ONE variable is required by the user and the other is provided by the system??
export const HaborEmbeddedStatusView: HaborComponent = {
  name: "HaborEmbeddedStatusView",
  propsSchema: {
    type: "object",
    extensible: false,
    properties: InstanceViewDavelProps
  },
  element: {
    name: "ChickletHaborPrimitive",
    props: { title: { type: "symbol", scopePath: ["props", "instance", "payload", "status"] } },
    children: []
  }
};

export const HaborEmbeddedNamedObjectView: HaborComponent = {
  name: "HaborEmbeddedNamedObjectView",
  propsSchema: {
    type: "object",
    extensible: false,
    properties: InstanceViewDavelProps
  },
  element: {
    name: "ChickletHaborPrimitive",
    props: { title: { type: "symbol", scopePath: ["props", "instance", "payload", "name"] } },
    children: []
  }
};

//  TODO:  Register the Primitive and HaborComponent here instead of centrally?  Registration can happen from anywhere, but the store of data is centralized.
