import { HaborComponent } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { registerChildComponent, registerHOC, registerHOCRegister, registerOnMount } from "halia-native";
import * as React from 'react';
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { PrimitiveProps } from "../component-plugin/habor-react/habor-component-lib";
import { HaborPlugin } from "../habor-plugin";
import { NavigationProp } from "@react-navigation/core";
import { EventEmitter } from 'events';
import { HessiaApp } from "./hessia-app";

export interface HessiaRouteDefinition {
  name: string;
  title?: string;
  component: any;  //  TODO:  Type this
  options?: any;  //  TODO:  Type this
  headerRight?: typeof React.Component | React.FunctionComponent;
}

export const HessiaPluginContext = React.createContext<HessiaPlugin>(undefined as any);

export const useHessiaPlugin = () => {
  const hessiaPlugin = React.useContext(HessiaPluginContext);
  return hessiaPlugin;
}

type HaborComponentPrimitive = React.FunctionComponent<PrimitiveProps> | React.ComponentClass<PrimitiveProps>;

class ComponentExistsError extends Error { }

//  TODO-GENERAL:  It should be possible for systems to register this shit!??  We SHOULD be able to do this by exposing a STANDARD API!?  BUT, I'd ALSO like systems to be able to connect to OTHER system's navigators!?

// export interface RootNavigatorAPI {
//   getRoute: () => Route<any> | undefined;
//   getNavigation: () => NavigationProp<any> | undefined;
//   registerRoute: (route: HessiaRouteDefinition) => void;
// }

export enum HessiaEvents {
  RootRouteAddedEvent = "root-route-added-event"
}

export class HessiaPlugin extends CorePluginClass {

  public static details = {
    name: "Hessia",
    description: "Hessia Core Plugin",
    //  TODO:  Decouple Auth!
    //  TODO:  Decouple MenuLayout.. inject using a more generic system.. BUT that couples us to the generic.. MAYBE use "CouplingPlugins" to expliclty bridge the gap using specific connectors, ONE of which can be a generic hmm!
    dependencies: [HaborPlugin.details.id, AuthPlugin.details.id],
    id: "hessia"
  }

  public hessiaEmitter = new EventEmitter();

  //  Define State
  private haborComopnents: { [name: string]: HaborComponent } = {};
  private primitiveComponents: { [name: string]: HaborComponentPrimitive } = {};

  public registerOnMount = (func: any) => registerOnMount("hessia", func);
  public registerHOC = (hoc: any) => registerHOC("hessia", hoc);
  public registerChildComponent = (child: any) => registerChildComponent("hessia", child);

  //  This will register a React "Context" above all previous contexts.  This is last in, first out.  Halia is parent-first.  This way, the React tree will look like this:  (child2(child1(parent)).  We do this because, GENREALLY, we are back-injecting into the parent.  So, this way the PARENT has access to all children contexts.  What What is the CHILD has to access the parent?  That's fine, as long as we inject into the parent as a child instead of an HOC.

  public registerContext = (Context: React.Context<any>, initialState: any) => {
    this.registerHOC(({ children }) => (
      <Context.Provider value={initialState}>
        {children}
      </Context.Provider>
    ))
  };

  public registerAppNavOnMount = (func: any) => registerOnMount("app-nav", func);
  public registerAppNavHOC = (hoc: any) => registerHOC("app-nav", hoc);
  public registerAppNavChildComponent = (child: any) => registerChildComponent("app-nav", child);

  public HessiaApp = () => <HessiaApp hessiaPlugin={this} />;

  //  Define Plugin Methods
  public registerComponent = (component: HaborComponent) => {
    const existing = this.getComponent(component.name);
    if (existing) { throw new ComponentExistsError() }
    this.haborComopnents[component.name] = component;
  }

  public registerPrimitiveComponent = (name: string, primitiveComopnent: HaborComponentPrimitive) => {
    const existing = this.getComponent(name);
    if (existing) { throw new ComponentExistsError() }
    this.primitiveComponents[name] = primitiveComopnent;
  }

  public getComponent = (name: string): HaborComponentPrimitive | HaborComponent | undefined => {
    return this.haborComopnents[name] || this.primitiveComponents[name];
  }

  public getPrimitiveComponent = (name: string): HaborComponentPrimitive => {
    return this.primitiveComponents[name];
  }

  public getHaborComponent = (name: string): HaborComponent => {
    return this.haborComopnents[name];
  }



  //  TODO:  This is basically a hack which assumes singleton instantiation of this component so we can access it from OUTSIDE the React context! HM!
  //  TODO:  DON'T do this like this.. beacuse it's a SINGLETON, it can't be re-used hm!  MAYBE it's ok.. but as long as we're sure we're only using it once for the interpreter instance!
  //  TODO-NEXT:  This SHOULD just be a menu-layout thing or something.. SHOULDN'T do it here like this ugh!  THIS way we can ATTACH things dynamically to the menu layout hm!
  public routes: HessiaRouteDefinition[] = [];

  //  CONSIDER:  SHOULD we allow ANY prop?
  //  CONSIDER:  Do we need this if we have the HOC register?  
  //  CONSIDER:  How can we simpligy the HOC register?  I have some ideas.. vague, but some concrete in there too maybe hm. point is... generalize and makei t a framewor.. ad platform.. a standardized langauge hmlkfad !

  public screenOptions = {};
  public registerRoute = (route: HessiaRouteDefinition) => {
    this.routes.push(route);
    // alert(JSON.stringify(route));
    this.hessiaEmitter.emit(HessiaEvents.RootRouteAddedEvent, this.routes);
  }
  public navigation!: NavigationProp<any>;

  public setOptions = (options: any) => {
    this.screenOptions = options;
  }
  // public RootNavigatorAPI: RootNavigatorAPI = {
  //   getRoute: () => undefined,
  //   getNavigation: () => undefined,
  //   registerRoute: 
  // };

  //  TODO:  Replace this with a MenuLayotu thing shm!


  //  CONSIDER:  MAYBE we should just plug the NAVIGATOR in directly Hm!
  // public registerRoute = (route: HessiaRouteDefinition) => {
  //   //  TODO:  The RootNavigator injected route state should probably be a part of the PLUGIN so we can have multiple instantiations if needed, NOT tied to Global state. For exampole, if we had multipole Hessia instances hmm... and.. just in general!
  //   rootNavigatorAPI.registerRoute(route);
  // }

  public authPlugin!: AuthPlugin;

  public install = (program: Program, { authPlugin }: { authPlugin: AuthPlugin }) => {

    //  TODO:  Use DI for the WHOLE thing!!  Don't pass through like tjis!  Use Context / soem kinda of plugin context state pattern thing hm!
    this.authPlugin = authPlugin;

    // menuLayout.registerMenuItem("Home", { name: "Home", icon: { type: "material", name: "dot" }, component: () => <HessiaApp hessiaPlugin={ this } />, isInitial: true })


    // try {
    //   const path = Linking.makeUrl("test");
    //   alert(path);
    // } catch (err) {
    //   alert(err);
    //   console.log(err);
    // }

    // console.log("PATH: " + path);
    // ) alert(JSON.stringify(Linking.parse(event.url))

    // Linking.addEventListener('url', event => alert(JSON.stringify(event)));
    // Linking.addEventListener('test', () => alert("HIT!!!"));


    //  Build a Register
    registerHOCRegister("hessia");
    registerHOCRegister("app-nav");

    //  Hessia Plugin Context
    this.registerContext(HessiaPluginContext, this);

    //  Mirror Navigation
    // this.registerHOC(({ children }) => {
    //   // const navigation = useNavigation();
    //   // this.navigation = navigation;
    //   return children;
    // });

    return this;
  }
}
