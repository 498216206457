/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Comment,
    CommentFromJSON,
    CommentToJSON,
    Edge,
    EdgeFromJSON,
    EdgeToJSON,
    Estimate,
    EstimateFromJSON,
    EstimateToJSON,
    Event,
    EventFromJSON,
    EventToJSON,
    Image,
    ImageFromJSON,
    ImageToJSON,
    Like,
    LikeFromJSON,
    LikeToJSON,
    Mockup,
    MockupFromJSON,
    MockupToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
    Work,
    WorkFromJSON,
    WorkToJSON,
    WorkProduct,
    WorkProductFromJSON,
    WorkProductToJSON,
} from '../models';

export interface CommentsControllerCreateRequest {
    body: object;
}

export interface CommentsControllerFindOneRequest {
    username: string;
}

export interface CommentsControllerRemoveRequest {
    id: string;
}

export interface CommentsControllerUpdateRequest {
    id: string;
    body: object;
}

export interface EdgesControllerCreateRequest {
    body: object;
}

export interface EdgesControllerFindOneRequest {
    id: string;
}

export interface EdgesControllerRemoveRequest {
    id: string;
}

export interface EdgesControllerUpdateRequest {
    id: string;
    body: object;
}

export interface EventsControllerCreateRequest {
    body: object;
}

export interface ImagesControllerCreateRequest {
    file?: Blob;
    image?: string;
}

export interface ImagesControllerFindOneRequest {
    id: string;
}

export interface ImagesControllerRemoveRequest {
    id: string;
}

export interface ImagesControllerUpdateRequest {
    id: string;
    body: object;
}

export interface LikesControllerFindOneRequest {
    username: string;
}

export interface LikesControllerSaveRequest {
    body: object;
}

export interface MockupsControllerCreateRequest {
    body: object;
}

export interface MockupsControllerFindOneRequest {
    productId: any;
    workId: any;
}

export interface MockupsControllerRemoveRequest {
    id: string;
}

export interface MockupsControllerUpdateRequest {
    id: string;
    body: object;
}

export interface NotificationsControllerNewProductsEmailRequest {
    body: object;
}

export interface PrintfulCategoriesControllerCreateRequest {
    body: object;
}

export interface PrintfulCategoriesControllerFindOneRequest {
    id: string;
}

export interface PrintfulCategoriesControllerRemoveRequest {
    id: string;
}

export interface PrintfulCategoriesControllerUpdateRequest {
    id: string;
    body: object;
}

export interface ProductTemplatesControllerCreateRequest {
    body: object;
}

export interface ProductTemplatesControllerFindOneRequest {
    id: string;
}

export interface ProductTemplatesControllerRemoveRequest {
    id: string;
}

export interface ProductTemplatesControllerUpdateRequest {
    id: string;
    body: object;
}

export interface ProductsControllerFineOneRequest {
    id: string;
}

export interface ProfilesControllerFindOneRequest {
    username: string;
}

export interface ProfilesControllerRemoveRequest {
    id: string;
}

export interface ProfilesControllerUpdateRequest {
    file?: Blob;
    profile?: string;
}

export interface UsersControllerCreateRequest {
    body: object;
}

export interface UsersControllerFindOneRequest {
    id: string;
}

export interface UsersControllerRemoveRequest {
    id: string;
}

export interface UsersControllerUpdateRequest {
    id: string;
    body: object;
}

export interface VariantsControllerCreateRequest {
    body: object;
}

export interface VariantsControllerFindOneRequest {
    id: string;
}

export interface VariantsControllerRemoveRequest {
    id: string;
}

export interface VariantsControllerUpdateRequest {
    id: string;
    body: object;
}

export interface WorkProductsControllerCreateRequest {
    body: object;
}

export interface WorkProductsControllerEstimateRequest {
    body: object;
}

export interface WorkProductsControllerFindOneRequest {
    id: string;
}

export interface WorkProductsControllerGetStripeSessionRequest {
    body: object;
}

export interface WorkProductsControllerImportRequest {
    body: object;
}

export interface WorkProductsControllerRemoveRequest {
    id: string;
}

export interface WorkProductsControllerUpdateRequest {
    id: string;
    body: object;
}

export interface WorksControllerCreateRequest {
    file?: Blob;
    work?: string;
}

export interface WorksControllerFindOneRequest {
    id: string;
}

export interface WorksControllerRemoveRequest {
    id: string;
}

export interface WorksControllerUpdateRequest {
    id: string;
    body: object;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async appControllerGetHelloRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetHello(initOverrides?: RequestInit): Promise<void> {
        await this.appControllerGetHelloRaw(initOverrides);
    }

    /**
     */
    async commentsControllerCreateRaw(requestParameters: CommentsControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling commentsControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentsControllerCreate(requestParameters: CommentsControllerCreateRequest, initOverrides?: RequestInit): Promise<Comment> {
        const response = await this.commentsControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commentsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Comment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentFromJSON));
    }

    /**
     */
    async commentsControllerFindAll(initOverrides?: RequestInit): Promise<Array<Comment>> {
        const response = await this.commentsControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async commentsControllerFindOneRaw(requestParameters: CommentsControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling commentsControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/comments/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentsControllerFindOne(requestParameters: CommentsControllerFindOneRequest, initOverrides?: RequestInit): Promise<Comment> {
        const response = await this.commentsControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commentsControllerRemoveRaw(requestParameters: CommentsControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commentsControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async commentsControllerRemove(requestParameters: CommentsControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.commentsControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async commentsControllerUpdateRaw(requestParameters: CommentsControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commentsControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling commentsControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentsControllerUpdate(requestParameters: CommentsControllerUpdateRequest, initOverrides?: RequestInit): Promise<Comment> {
        const response = await this.commentsControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async edgesControllerCreateRaw(requestParameters: EdgesControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Edge>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling edgesControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/edges/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EdgeFromJSON(jsonValue));
    }

    /**
     */
    async edgesControllerCreate(requestParameters: EdgesControllerCreateRequest, initOverrides?: RequestInit): Promise<Edge> {
        const response = await this.edgesControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async edgesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Edge>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/edges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EdgeFromJSON));
    }

    /**
     */
    async edgesControllerFindAll(initOverrides?: RequestInit): Promise<Array<Edge>> {
        const response = await this.edgesControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async edgesControllerFindOneRaw(requestParameters: EdgesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Edge>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling edgesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/edges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EdgeFromJSON(jsonValue));
    }

    /**
     */
    async edgesControllerFindOne(requestParameters: EdgesControllerFindOneRequest, initOverrides?: RequestInit): Promise<Edge> {
        const response = await this.edgesControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async edgesControllerRemoveRaw(requestParameters: EdgesControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling edgesControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/edges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async edgesControllerRemove(requestParameters: EdgesControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.edgesControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async edgesControllerUpdateRaw(requestParameters: EdgesControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Edge>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling edgesControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling edgesControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/edges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EdgeFromJSON(jsonValue));
    }

    /**
     */
    async edgesControllerUpdate(requestParameters: EdgesControllerUpdateRequest, initOverrides?: RequestInit): Promise<Edge> {
        const response = await this.edgesControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async eventsControllerCreateRaw(requestParameters: EventsControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Event>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling eventsControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }

    /**
     */
    async eventsControllerCreate(requestParameters: EventsControllerCreateRequest, initOverrides?: RequestInit): Promise<Event> {
        const response = await this.eventsControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async eventsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Event>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
    }

    /**
     */
    async eventsControllerFindAll(initOverrides?: RequestInit): Promise<Array<Event>> {
        const response = await this.eventsControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async imagesControllerCreateRaw(requestParameters: ImagesControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Image>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     */
    async imagesControllerCreate(requestParameters: ImagesControllerCreateRequest, initOverrides?: RequestInit): Promise<Image> {
        const response = await this.imagesControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async imagesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Image>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImageFromJSON));
    }

    /**
     */
    async imagesControllerFindAll(initOverrides?: RequestInit): Promise<Array<Image>> {
        const response = await this.imagesControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async imagesControllerFindOneRaw(requestParameters: ImagesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imagesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async imagesControllerFindOne(requestParameters: ImagesControllerFindOneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.imagesControllerFindOneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async imagesControllerRemoveRaw(requestParameters: ImagesControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imagesControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async imagesControllerRemove(requestParameters: ImagesControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.imagesControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async imagesControllerUpdateRaw(requestParameters: ImagesControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imagesControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling imagesControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async imagesControllerUpdate(requestParameters: ImagesControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.imagesControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async likesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Like>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/likes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LikeFromJSON));
    }

    /**
     */
    async likesControllerFindAll(initOverrides?: RequestInit): Promise<Array<Like>> {
        const response = await this.likesControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async likesControllerFindOneRaw(requestParameters: LikesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Like>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling likesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/likes/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LikeFromJSON(jsonValue));
    }

    /**
     */
    async likesControllerFindOne(requestParameters: LikesControllerFindOneRequest, initOverrides?: RequestInit): Promise<Like> {
        const response = await this.likesControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async likesControllerSaveRaw(requestParameters: LikesControllerSaveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Like>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling likesControllerSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/likes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LikeFromJSON(jsonValue));
    }

    /**
     */
    async likesControllerSave(requestParameters: LikesControllerSaveRequest, initOverrides?: RequestInit): Promise<Like> {
        const response = await this.likesControllerSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mockupsControllerCreateRaw(requestParameters: MockupsControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mockup>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling mockupsControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mockups/mockups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MockupFromJSON(jsonValue));
    }

    /**
     */
    async mockupsControllerCreate(requestParameters: MockupsControllerCreateRequest, initOverrides?: RequestInit): Promise<Mockup> {
        const response = await this.mockupsControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mockupsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Mockup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mockups/findAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MockupFromJSON));
    }

    /**
     */
    async mockupsControllerFindAll(initOverrides?: RequestInit): Promise<Array<Mockup>> {
        const response = await this.mockupsControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async mockupsControllerFindOneRaw(requestParameters: MockupsControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mockup>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling mockupsControllerFindOne.');
        }

        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling mockupsControllerFindOne.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.workId !== undefined) {
            queryParameters['workId'] = requestParameters.workId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mockups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MockupFromJSON(jsonValue));
    }

    /**
     */
    async mockupsControllerFindOne(requestParameters: MockupsControllerFindOneRequest, initOverrides?: RequestInit): Promise<Mockup> {
        const response = await this.mockupsControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mockupsControllerRemoveRaw(requestParameters: MockupsControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mockupsControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mockups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mockupsControllerRemove(requestParameters: MockupsControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mockupsControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async mockupsControllerUpdateRaw(requestParameters: MockupsControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mockupsControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling mockupsControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mockups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mockupsControllerUpdate(requestParameters: MockupsControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mockupsControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationsControllerNewProductsEmailRaw(requestParameters: NotificationsControllerNewProductsEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling notificationsControllerNewProductsEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsControllerNewProductsEmail(requestParameters: NotificationsControllerNewProductsEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationsControllerNewProductsEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printfulCategoriesControllerCreateRaw(requestParameters: PrintfulCategoriesControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling printfulCategoriesControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/printful-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printfulCategoriesControllerCreate(requestParameters: PrintfulCategoriesControllerCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.printfulCategoriesControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printfulCategoriesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/printful-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async printfulCategoriesControllerFindAll(initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.printfulCategoriesControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async printfulCategoriesControllerFindOneRaw(requestParameters: PrintfulCategoriesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling printfulCategoriesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/printful-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printfulCategoriesControllerFindOne(requestParameters: PrintfulCategoriesControllerFindOneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.printfulCategoriesControllerFindOneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printfulCategoriesControllerRemoveRaw(requestParameters: PrintfulCategoriesControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling printfulCategoriesControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/printful-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printfulCategoriesControllerRemove(requestParameters: PrintfulCategoriesControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.printfulCategoriesControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printfulCategoriesControllerUpdateRaw(requestParameters: PrintfulCategoriesControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling printfulCategoriesControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling printfulCategoriesControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/printful-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printfulCategoriesControllerUpdate(requestParameters: PrintfulCategoriesControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.printfulCategoriesControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productTemplatesControllerCreateRaw(requestParameters: ProductTemplatesControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling productTemplatesControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/product-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTemplatesControllerCreate(requestParameters: ProductTemplatesControllerCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.productTemplatesControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productTemplatesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/product-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTemplatesControllerFindAll(initOverrides?: RequestInit): Promise<void> {
        await this.productTemplatesControllerFindAllRaw(initOverrides);
    }

    /**
     */
    async productTemplatesControllerFindOneRaw(requestParameters: ProductTemplatesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTemplatesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/product-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTemplatesControllerFindOne(requestParameters: ProductTemplatesControllerFindOneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.productTemplatesControllerFindOneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productTemplatesControllerRemoveRaw(requestParameters: ProductTemplatesControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTemplatesControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/product-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTemplatesControllerRemove(requestParameters: ProductTemplatesControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.productTemplatesControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productTemplatesControllerUpdateRaw(requestParameters: ProductTemplatesControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTemplatesControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling productTemplatesControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/product-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTemplatesControllerUpdate(requestParameters: ProductTemplatesControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.productTemplatesControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async productsControllerFindAll(initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.productsControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productsControllerFineOneRaw(requestParameters: ProductsControllerFineOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsControllerFineOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async productsControllerFineOne(requestParameters: ProductsControllerFineOneRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.productsControllerFineOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profilesControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Profile>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileFromJSON));
    }

    /**
     */
    async profilesControllerFindAll(initOverrides?: RequestInit): Promise<Array<Profile>> {
        const response = await this.profilesControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profilesControllerFindOneRaw(requestParameters: ProfilesControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling profilesControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profiles/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async profilesControllerFindOne(requestParameters: ProfilesControllerFindOneRequest, initOverrides?: RequestInit): Promise<Profile> {
        const response = await this.profilesControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profilesControllerRemoveRaw(requestParameters: ProfilesControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profilesControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profilesControllerRemove(requestParameters: ProfilesControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.profilesControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async profilesControllerUpdateRaw(requestParameters: ProfilesControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.profile !== undefined) {
            formParams.append('profile', requestParameters.profile as any);
        }

        const response = await this.request({
            path: `/profiles`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async profilesControllerUpdate(requestParameters: ProfilesControllerUpdateRequest, initOverrides?: RequestInit): Promise<Profile> {
        const response = await this.profilesControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerCreateRaw(requestParameters: UsersControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling usersControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerCreate(requestParameters: UsersControllerCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerFindAll(initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerFindAllRaw(initOverrides);
    }

    /**
     */
    async usersControllerFindOneRaw(requestParameters: UsersControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerFindOne(requestParameters: UsersControllerFindOneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerFindOneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersControllerRemoveRaw(requestParameters: UsersControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerRemove(requestParameters: UsersControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersControllerUpdateRaw(requestParameters: UsersControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling usersControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerUpdate(requestParameters: UsersControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async variantsControllerCreateRaw(requestParameters: VariantsControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling variantsControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/variants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variantsControllerCreate(requestParameters: VariantsControllerCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.variantsControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async variantsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variantsControllerFindAll(initOverrides?: RequestInit): Promise<void> {
        await this.variantsControllerFindAllRaw(initOverrides);
    }

    /**
     */
    async variantsControllerFindOneRaw(requestParameters: VariantsControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling variantsControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variantsControllerFindOne(requestParameters: VariantsControllerFindOneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.variantsControllerFindOneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async variantsControllerRemoveRaw(requestParameters: VariantsControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling variantsControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/variants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variantsControllerRemove(requestParameters: VariantsControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.variantsControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async variantsControllerUpdateRaw(requestParameters: VariantsControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling variantsControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling variantsControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/variants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variantsControllerUpdate(requestParameters: VariantsControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.variantsControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async workProductsControllerCreateRaw(requestParameters: WorkProductsControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkProduct>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling workProductsControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-products/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkProductFromJSON(jsonValue));
    }

    /**
     */
    async workProductsControllerCreate(requestParameters: WorkProductsControllerCreateRequest, initOverrides?: RequestInit): Promise<WorkProduct> {
        const response = await this.workProductsControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerEstimateRaw(requestParameters: WorkProductsControllerEstimateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Estimate>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling workProductsControllerEstimate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/work-products/estimate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EstimateFromJSON(jsonValue));
    }

    /**
     */
    async workProductsControllerEstimate(requestParameters: WorkProductsControllerEstimateRequest, initOverrides?: RequestInit): Promise<Estimate> {
        const response = await this.workProductsControllerEstimateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<WorkProduct>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/work-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkProductFromJSON));
    }

    /**
     */
    async workProductsControllerFindAll(initOverrides?: RequestInit): Promise<Array<WorkProduct>> {
        const response = await this.workProductsControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerFindOneRaw(requestParameters: WorkProductsControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workProductsControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/work-products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkProductFromJSON(jsonValue));
    }

    /**
     */
    async workProductsControllerFindOne(requestParameters: WorkProductsControllerFindOneRequest, initOverrides?: RequestInit): Promise<WorkProduct> {
        const response = await this.workProductsControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerGetStripeSessionRaw(requestParameters: WorkProductsControllerGetStripeSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling workProductsControllerGetStripeSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/work-products/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async workProductsControllerGetStripeSession(requestParameters: WorkProductsControllerGetStripeSessionRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.workProductsControllerGetStripeSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerImportRaw(requestParameters: WorkProductsControllerImportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkProduct>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling workProductsControllerImport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/work-products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkProductFromJSON(jsonValue));
    }

    /**
     */
    async workProductsControllerImport(requestParameters: WorkProductsControllerImportRequest, initOverrides?: RequestInit): Promise<WorkProduct> {
        const response = await this.workProductsControllerImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workProductsControllerRemoveRaw(requestParameters: WorkProductsControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workProductsControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/work-products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async workProductsControllerRemove(requestParameters: WorkProductsControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.workProductsControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async workProductsControllerUpdateRaw(requestParameters: WorkProductsControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workProductsControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling workProductsControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/work-products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkProductFromJSON(jsonValue));
    }

    /**
     */
    async workProductsControllerUpdate(requestParameters: WorkProductsControllerUpdateRequest, initOverrides?: RequestInit): Promise<WorkProduct> {
        const response = await this.workProductsControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async worksControllerCreateRaw(requestParameters: WorksControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Work>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.work !== undefined) {
            formParams.append('work', requestParameters.work as any);
        }

        const response = await this.request({
            path: `/works`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkFromJSON(jsonValue));
    }

    /**
     */
    async worksControllerCreate(requestParameters: WorksControllerCreateRequest, initOverrides?: RequestInit): Promise<Work> {
        const response = await this.worksControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async worksControllerFindAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Work>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/works`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkFromJSON));
    }

    /**
     */
    async worksControllerFindAll(initOverrides?: RequestInit): Promise<Array<Work>> {
        const response = await this.worksControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async worksControllerFindOneRaw(requestParameters: WorksControllerFindOneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling worksControllerFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/works/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async worksControllerFindOne(requestParameters: WorksControllerFindOneRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.worksControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async worksControllerRemoveRaw(requestParameters: WorksControllerRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling worksControllerRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/works/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async worksControllerRemove(requestParameters: WorksControllerRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.worksControllerRemoveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async worksControllerUpdateRaw(requestParameters: WorksControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Work>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling worksControllerUpdate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling worksControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/works/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkFromJSON(jsonValue));
    }

    /**
     */
    async worksControllerUpdate(requestParameters: WorksControllerUpdateRequest, initOverrides?: RequestInit): Promise<Work> {
        const response = await this.worksControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
