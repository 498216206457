import * as React from 'react';
import { ActivityIndicator, ScrollView, Text, View, ViewStyle } from 'react-native';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { WidgetItem } from '../hessia-plugin/dynamic-component';
import { Entity, useEntityPlugin } from './entity-plugin';

/**
 * Sets the Entity in the context of the editor.
 */
// const setEntity = async (entityPlugin: EntityPlugin, navigation: any, entity?: any) => {

//   if (entity) {
//     entity = await entityPlugin.entityService.update(entity.id, { }); 
//   } else {
//     entity = await entityPlugin.entityService.create({ });
//   }

//   navigation.navigate(EntityRoute.Editor, { entity });
// }

//  TYPING:  This should really be "onOf"..  But again, I think a generic system to really capture the complexity of these "types".  Just as complex as ANY possible pattern hmm...
export const EntityEditor = ({ entity, style }: { entity?: Entity, style?: ViewStyle }) => {

  const entityPlugin = useEntityPlugin();

  //  Use withEntity to load an entity hm!

  const [elements, setElements] = React.useState<any[]>([]);

  //  TODO:  INSTEAD of just rendering the component we actually go out to GET them first?  Hmm... THIS way, we can check if they need to be rendered? Hmm... Otherwise we end up with extra layout space.  THe GERNALIZATION is... don't know if an ENCODING will exist and while we typically DECORATE it... we don't need to if it does not!
  //  CONSIEER:  It WOULD be nice if we could REACT!  AH!!  At a HIGHER level!  This way, if a component is DISMOUNTED, we an remove a surrounding thing hmmmm... interesting.  Why aren't more people?  Because people like to use the tools and don't THINK about the generalization AND ther'e ALL this resistance.  WHY am I rising above?  Because I put in the work.  WHY?  MAYBE I have a stronger drive for SURVIVAL? Hmm... maybe idk.  AH!  It's NOT that!  It's acceptance of DEATH.  SOME fear over what that MEANS, but yeah... NOT clear anyways, and POSSIBLE it's really "death" as we see it hm!  Basically, it would be nice if we had "sub-graphs" what had mounting that we could react to hmm.....  INIFINITELY many!
  //  FOR NOW:  I'm going to make this a responsibilty of the freaking thing... ugh.  I don't like that though.

  //  Let's use a component to show LOADING hmm.. BUT we only want to show loading if it's LIKELY Hmm... Interesing.  THe idea is.  We DO want to STATE perhaps? Hmmm... Maybe.  This IS one benefit of having an encoding hm!  FOR NOW, let's do it WITHOUT and show loading hm!

  const loadElements = async () => {
    const managedViews = entityPlugin.entityDetailViews;
    for (const view of managedViews) {
      if (view.resolver) {
        try {
          const element = await view.resolver({ entity, entityPlugin });
          if (element) {
            const wrappedElement = (
              <>
                {element}
                <View style={{ height: medSpacer }} />
              </>
            );
            setElements([...elements, wrappedElement]);
          }
        } catch (err) {
          alert("An error occurred while loading the " + view.name + " view: " + err);
        }

      }

    }
  }

  React.useEffect(() => {
    if (entity) {
      loadElements();
    }
  }, [entity]);

  if (entity === undefined) {
    return <ActivityIndicator />
  }

  if (entity === null) {
    return <Text>Entity Not Found</Text>
  }

  return (
    <WidgetItem name="Entity Extensions" color="#eeeeee">
      <View style={[style]}>
        {
          entityPlugin.entityDetailViews.map(({ component: Comp, name }) => {

            if (Comp) {
              return (
                <>
                  <Comp entityPlugin={entityPlugin} entity={entity} />
                  <View style={{ height: medSpacer }} />
                </>
              )
            } else {
              return null;
            }

          })
        }
        {
          elements
        }
        {/* <Button title={ entity ? 'Update' : 'Create' } onPress={ () => setEntity(entityPlugin, navigation, entity) } /> */}
      </View>
    </WidgetItem>

  );
}

