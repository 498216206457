import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { configureFonts, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerInput, DatePickerModal, enGB, registerTranslation } from 'react-native-paper-dates';
import { primaryColor } from './constants';
import { kelpStyles, primaryFontFamily, primaryFontFamilyHeavy } from "./styles";




//  CONSIDER:  Make this PLUGGABLE with MODIFIERS which can COMPOSE this hm!
// export const DatePicker = () => {

// }

// import SimplerDatePicker from '@cawfree/react-native-simpler-date-picker';
//  TODO-CRITICAL:  AntDatePicker doesn't seem to work on the web / electron!  NEED to remove these or figure out why!!!
//  HEADBASH:  When you can't import something with "import" (JS Modules) try "require".
// const Ant = require("@ant-design/react-native")
// import { DatePicker as Test, List } from '';
// console.log("THE THIN");
// console.log(Test);
// const T = (() => Test as any)();
// const AntDatePickerCast = Ant.DatePicker;
// const AntList = Ant.List as any;
const moment = require("moment");

export interface DatePickerProps {
  date: Date;
  onUpdate: (date: Date | undefined) => void;
}

//  TODO:  Consider UNIFIED

//  REFERENCE:  https://stackoverflow.com/a/73621037/3625866
const MyWebDatePicker = ({ date, onDateChange }: { date, onDateChange }) => {

  const _date = new Date(date || new Date());
  return React.createElement('input', {
    type: 'date',
    value: _date.toISOString().split("T")[0],
    // onChangeRaw: (event) => {
    //   alert(JSON.stringify(event.target.value));
    // },
    onChange: (event) => {
      // alert(JSON.stringify(event.target.value));
      onDateChange(new Date(event.target.value))
    },
    style: { height: 30, padding: 5, border: "2px solid #677788", borderRadius: 5, width: 250 }
  })
}

registerTranslation('en-GB', enGB);

registerTranslation("pl", {
  save: 'Save',
  selectSingle: 'Select date',
  selectMultiple: 'Select dates',
  selectRange: 'Select period',
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: 'Day is not allowed',
  previous: 'Previous',
  next: 'Next',
  typeInDate: 'Type in date',
  pickDateFromCalendar: 'Pick date from calendar',
  close: 'Close',
});


const fontConfig = {
  regular: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  light: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  thin: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
} as any;

const theme = {
  ...DefaultTheme,
  roundness: 1,
  version: 3,
  colors: {
    ...DefaultTheme.colors,
    primary: primaryColor,
    secondary: 'white',
    tertiary: 'white'
  },
  fonts: configureFonts({
    web: fontConfig,
    ios: fontConfig,
    android: fontConfig
  })
};

interface DatePickerState { }
class DatePickerBase extends React.Component<DatePickerProps, DatePickerState> {
  constructor(props: DatePickerProps) {
    super(props);
    this.state = {}
  }
  public render = () => {

    const { date: selectedDate = new Date() } = this.props;

    //  Create Quick View Times
    // const currentDate = new Date();
    // const minute = currentDate.getMinutes();
    //  TODO:  Make this configurable hm!
    // const startOffset = minute % 15;

    //  CONSIDER:  We can also build this dynamically by specifying the number of intervals, plus the DIRECTION, and the interval size.
    // const dates = [-20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(delta => {
    //   const date = new Date(currentDate.getTime());
    //   date.setMinutes(15*(startOffset + delta));
    //   return date;
    // });


    // if (Platform.OS === 'ios') {

    // }

    const date = new Date(selectedDate);
    return <PaperProvider theme={theme}>
       <DatePickerInput
          locale="en"
          label="Birthdate"
          value={ date }
          onChange={ this.props.onUpdate }
          inputMode="start"
        />
    </PaperProvider>

    // 
    return <MyWebDatePicker date={selectedDate} onDateChange={this.props.onUpdate} />
    return (
      <View>
        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Selected Date: {selectedDate.toLocaleString('en-US', { dateStyle: 'medium' } as any)}</Text>


        {/* <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{ (currentDate).toLocaleString('en-US', { dateStyle: 'medium' }) }</Text>

        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{ minute }</Text>

        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{ currentDate.getHours() }</Text>

        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{ startOffset }</Text>

        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{ JSON.stringify(dates) }</Text> */}

        {/* <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Year</Text> */}

        {/* Quick Selection Panel */}



        <ScrollView horizontal={true} style={{ padding: 15 }}>
          {
            dates.map(date =>
              <TouchableOpacity onPress={() => { this.props.onUpdate(date) }} style={{ backgroundColor: 'white', borderRadius: 20, padding: 15, paddingVertical: 10, marginRight: 10, ...kelpStyles.shadowLow }}>
                <Text style={{ fontFamily: primaryFontFamily }}>
                  {date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
                </Text>
              </TouchableOpacity>)
          }
        </ScrollView>

        {/* <Ant.List>
          <Ant.DatePicker
            value={new Date()}
            mode="datetime"
            defaultDate={new Date()}
            // minDate={new Date(2015, 7, 6)}
            // maxDate={new Date(2026, 11, 3)}
            onChange={this.props.onUpdate}
            format="YYYY-MM-DD"
          >
            <Ant.List.Item arrow="horizontal">Select Date</Ant.List.Item>
          </Ant.DatePicker>
        </Ant.List> */}
      </View>
    );
  }
}
export const DatePicker = DatePickerBase;