import * as Linking from 'expo-linking';
import { CorePluginClass, Program } from 'halia';
import { HaliaComponentPlugin } from 'halia-native';
import { Configuration, OpenAIApi } from 'openai';
import * as React from "react";
import { ActivityIndicator, FlatList, ScrollView, Text } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { GroupCard } from '../../packages/kelp-bar/group-card';
import { TextSubTitle } from '../../packages/kelp-bar/text';
import { Paragraph, SubParagraph, primaryColor } from '../gallery/constants';


import { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import Svg, { Circle, Defs, G, LinearGradient, Stop } from 'react-native-svg';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const RotaryProgressBar = ({ name, value, maxValue, strokeWidth, size, colors, shadowColor }) => {
  const radius = (size - strokeWidth) / 2;
  const circ = 2 * Math.PI * radius;
  const progress = Math.min(1, value / maxValue);
  const anim = useRef(new Animated.Value(progress)).current;

  useEffect(() => {
    Animated.timing(anim, { toValue: progress, duration: 1000, useNativeDriver: true }).start();
  }, [progress]);

  const offset = anim.interpolate({ inputRange: [0, 1], outputRange: [circ, 0] });

  const styles = StyleSheet.create({
    overlay: { position: 'absolute', top: 0, left: 0, width: size, height: size, justifyContent: 'center', alignItems: 'center' },
    name: { fontSize: 14, fontFamily: 'Inter-SemiBold', textAlign: 'center', alignSelf: 'center' },
    value: { fontSize: 13, fontFamily: 'Inter-Bold' },
  });

  return (
    <View>
      <Svg width={size} height={size}>
        <G transform={`translate(${size / 2}, ${size / 2})`}>
          <Circle r={radius} strokeWidth={strokeWidth} strokeLinecap="round" stroke={shadowColor} fill="none" cx={0} cy={0} />
          <Defs>
            <LinearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              {colors.map((color, index) => (
                <Stop key={index} offset={`${index * 100}%`} stopColor={color} />
              ))}
            </LinearGradient>
          </Defs>
          {/* <Defs>
            <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
              <feMerge>
                <feMergeNode in="blur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </Defs> */}

          <AnimatedCircle
            r={radius}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            stroke="url(#gradient)"
            fill="none"
            cx={0}
            cy={0}
            strokeDasharray={circ}
            strokeDashoffset={offset}
            rotation="-90"
            origin={`${size / 2}, ${size / 2}`}
          // filter="url(#glow)"
          />
        </G>
      </Svg>
      <View style={styles.overlay}>
        <Animated.Text style={styles.value}>
          {anim.interpolate({ inputRange: [0, 1], outputRange: [`0%`, `${Math.round(progress * 100)}%`] })}
        </Animated.Text>
      </View>
      <Text style={styles.name}>{name}</Text>
    </View>
  );


};



export default RotaryProgressBar;


const configuration = new Configuration({
  apiKey: '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',
});
const openai = new OpenAIApi(configuration);

const OverwebApp = () => {

  const [summary, setSummary] = React.useState();
  const [response, setResponse] = React.useState<any>();
  const [analysis, setAnalysis] = React.useState<any[]>();

  const [loading, setLoading] = React.useState(true);

  const getLiveSummary = async (html: string) => {

    //
    //  Clientside Usage
    //  REFERENCE:  https://github.com/openai/openai-node/issues/18#issuecomment-1490970390
    //
    const url = "https://api.openai.com/v1/chat/completions";

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + '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');

    xhr.onprogress = function (event) {
      console.log("Received " + event.loaded + " bytes of data.");
      console.log("Data: " + xhr.responseText);
      const newUpdates = xhr.responseText
        .replace("data: [DONE]", "")
        .trim()
        .split('data: ')
        .filter(Boolean)


      const newUpdatesParsed = newUpdates.map((update) => {
        const parsed = JSON.parse(update);
        return parsed.choices[0].delta?.content || '';
      }
      );

      const newUpdatesJoined = newUpdatesParsed.join('')
      setResponse(newUpdatesJoined);
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log("Response complete.");
          console.log("Final data: " + xhr.responseText);
        } else {
          console.error("Request failed with status " + xhr.status);
        }
      }
    };


    // const systemMessage = "You are a system that returns a human readable and simplified explanation of an HTML document.";
    const userMessage = `
        Please return a summarized version of the following HTML document with 20 words or less: ${html}
      `;

    const data = JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: "user",
          content: userMessage,
        }],
      stream: true,
    });

    xhr.send(data);

  }

  const getModelAnalyis = async (targetUrl: string) => {

    //
    //  Clientside Usage
    //  REFERENCE:  https://github.com/openai/openai-node/issues/18#issuecomment-1490970390
    //
    const url = "https://api.openai.com/v1/chat/completions";

    const systemMessage = "You are a system that returns JSON scores for various URLs.";

    const userMessage = `
    Please give me an analyss for a given website.  I need a list of "trackers" as a list of JSON objects.  Each should have a "name", a "value", and a "type" (for mappng to a UI).  Use "score" for any value that is measured between 0 and 1.  For example:

https://twitter.com:

[
  {"name":"trust_score","value":0.9,"type":"score","short_name":"Trust","description":"Level of trust."},
  {"name":"content_summary","value":"A social media company","type":"string","short_name":"Summary","description":"A description of the content served by this URL."},
  {"name":"confidence_score","value":0.8,"type":"score","short_name":"Confidence","description":"Level of confidence."},
  {"name":"related","value":["https://www.facebook.com/","https://www.instagram.com/","https://www.linkedin.com/"],"type":"array","short_name":"Related","description":"Related websites."},
  {"name":"short_reasoning","value":"Popular social media platform.","type":"string","short_name":"Short Reasoning","description":"Brief explanation of popularity."},
  {"name":"reasoning","value":"Popular social media platform with strong security, privacy, legal compliance, and mobile optimization.","type":"string","short_name":"Reasoning","description":"Detailed explanation of trustworthiness and security."},
  {"name":"named_entities","value":["Twitter"],"type":"array","short_name":"Named Entities","description":"Named entities."},
  {"name":"sentiment","value":"Positive","type":"string","short_name":"Sentiment","description":"Sentiment towards website."},
  {"name":"tags","value":["social media","news","opinions","privacy","security"],"type":"array","short_name":"Tags","description":"Website tags."},
  {"name":"type","value":"website","type":"string","short_name":"Type","description":"Type of website."},
  {"name":"ownership_info_score","value":0.9,"type":"score","short_name":"Ownership Info","description":"Level of ownership information."},
  {"name":"privacy_policy_score","value":0.9,"type":"score","short_name":"Privacy Policy","description":"Level of privacy policy."},
  {"name":"external_verification_score","value":0.9,"type":"score","short_name":"External Verification","description":"Level of external verification."},
  {"name":"reputation_and_reviews_score","value":0.9,"type":"score","short_name":"Reputation and Reviews","description":"Level of reputation and reviews."},
  {"name":"up_to_date_content_score","value":0.9,"type":"score","short_name":"Up-to-date Content","description":"Level of up-to-date content."},
  {"name":"design_and_usability_score","value":0.9,"type":"score","short_name":"Design and Usability","description":"Level of design and usability."},
  {"name":"advertising_and_sponsorship_score","value":0.8,"type":"score","short_name":"Advertising and Sponsorship","description":"Level of advertising and sponsorship."},
  {"name":"mobile_optimization_score","value":0.9,"type":"score","short_name":"Mobile Optimization","description":"Level of mobile optimization."},
  {"name":"security_features_score","value":0.9,"type":"score","short_name":"Security Features","description":"Level of security features."},
  {"name":"legal_compliance_score","value":0.9,"type":"score","short_name":"Legal Compliance","description":"Level of legal compliance."},
  {"name":"user_engagement_score","value":0.8,"type":"score","short_name":"User Engagement","description":"Level of user engagement."},
  {"name":"social_media_presence_score","value":0.8,"type":"score","short_name":"Social Media Presence","description":"Level of social media presence."},
  {"name":"summary","value":"Popular social media platform with strong security, privacy, legal compliance, and mobile optimization.","type":"string","short_name":"Summary", "description": "A summary of the results."},
  {"name":"quote","value":"Don't miss what's happening.  People on Twitter are the first to know.","type":"string","short_name":"Quote", "description": "A long and notable quote from the website.  Ideally the size of a Tweet."}
]

From now on, please only reply with your analysis in JSON format shown in the example array.  

Please give the full output without shortening.

Here is the URL to analyze:  ${targetUrl}`;


    setLoading(true);
    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [{ role: "system", content: systemMessage }, { role: "user", content: userMessage }],
    });
    setLoading(false);
    const result = completion.data.choices[0].message?.content || "";
    console.log(result);
    const parsedResult = JSON.parse(result);

    console.log(JSON.stringify(parsedResult));
    console.log(targetUrl);

    setAnalysis(parsedResult);

  }

  //  REFERENCE:  ChatGPT 3.5
  const parseHtml = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const scriptTags = doc.getElementsByTagName("script");
    for (let i = scriptTags.length - 1; i >= 0; i--) {
      scriptTags[i].remove();
    }
    const styleTags = doc.getElementsByTagName("style");
    for (let i = styleTags.length - 1; i >= 0; i--) {
      styleTags[i].remove();
    }
    const textNodes: any[] = [];
    const walk = document.createTreeWalker(
      doc.body,
      NodeFilter.SHOW_TEXT,
      null,
    );
    while (walk.nextNode()) {
      const node = walk.currentNode;
      if (
        node?.parentNode?.nodeName !== "SCRIPT" &&
        node?.parentNode?.nodeName !== "STYLE"
      ) {
        textNodes.push(node?.nodeValue?.trim());
      }
    }
    const content = textNodes.join("\n");
    return content;
  };

  const loadHtml = async () => {

    const parsedUrl = await Linking.parseInitialURLAsync();

    const resource = parsedUrl.queryParams["resource"];
    // const html = parsedUrl.queryParams["html"];
    setSummary(resource);

    //  NOTE:  This CORS Proxy usually works!
    const res = await fetch(`https://cors-anywhere.herokuapp.com/${resource}`);

    //  Try a direct request, if that fails, THEN we use a proxy.
    try {
      // const res = await fetch(resource, { method: "GET", credentials: "omit", mode: "cors" });
      const html = await res.text()
      const parsed = parseHtml(html);
      // getLiveSummary(parsed);
      getModelAnalyis(resource);
    } catch (err) {
      alert(err);
    }
  }

  React.useEffect(() => {
    loadHtml();
  }, []);


  return (
    <ScrollView style={{ padding: 30 }}>

      <TextSubTitle>Resource</TextSubTitle>
      <GroupCard>
        <Text style={{ fontFamily: 'Inter-Bold' }}>{JSON.stringify(summary)}</Text>
      </GroupCard>

      <TextSubTitle>Live Summary</TextSubTitle>
      <GroupCard>
        <Text>{JSON.stringify(response)}</Text>
      </GroupCard>


      <TextSubTitle>Model Analysis</TextSubTitle>
      <GroupCard>
        {
          loading ? (
            <ActivityIndicator color={primaryColor} />
          ) : (
            <>
              <FlatGrid spacing={30} itemDimension={150} data={analysis || []} renderItem={({ item }) => item.type === "score" ? (
                <>
                  <RotaryProgressBar
                    name={item.short_name}
                    value={item.value * 100}
                    maxValue={100}
                    strokeWidth={10}
                    size={70}
                    colors={['#2196F3', '#3F51B5']}
                    shadowColor="#E0E0E0"
                  />
                  {/* <AnimatedCircularProgress
                  size={40}
                  width={4}
                  fill={item.value * 100}
                  tintColor="#00e0ff"
                  delay={500}
                  duration={2000}
                  backgroundColor="#3d5875" /> */}
                </>
              ) : null} />

              <FlatList data={analysis || []} renderItem={({ item }) => item.type != "score" ? (
                <>
                  <GroupCard style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <TextSubTitle>{item.short_name}</TextSubTitle>
                    <Paragraph>{item.value}</Paragraph>
                    <SubParagraph>{item.description}</SubParagraph>
                  </GroupCard>
                </>
              ) : null} />

              <Text>{JSON.stringify(analysis)}</Text>

            </>
          )
        }
      </GroupCard>

    </ScrollView>
  );
}

export class OverwebPlugin extends CorePluginClass {

  public static details = {
    name: 'Overweb Plugin',
    description: 'Overweb Plugin',
    dependencies: [HaliaComponentPlugin.details.id],
    id: 'overweb'
  }

  public install = (program: Program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    haliaComponentPlugin.registerHOC(OverwebApp);
    return this;
  }
}
