import { Color, EntityType, FrameworkContext, HaborComponent, HaborComponentContext, Instance, InstanceID, InstanceInternal, NamedObject } from 'habor-sdk';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { IconButton, MD3Colors, ProgressBar, SegmentedButtons, Text } from 'react-native-paper';
import { FlatGrid } from 'react-native-super-grid';
import { renderRGBAColor } from '../../../packages/davel-ui/habor-types/color/color-field';
import { Card } from '../../../packages/kelp-bar/card';
import { Paragraph } from '../../../packages/kelp-bar/constants';
import { GroupCard } from '../../../packages/kelp-bar/group-card';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";
import { Period, SingleDatePicker } from '../../../packages/kelp-bar/single-date-picker';
import { medSpacer, primaryFontFamilyHeavy, RaisedHeight } from '../../../packages/kelp-bar/styles';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { haborSDK } from '../hessia-plugin/config';
import { HabitPluginContext } from './habit-plugin';

//
//  HabitsBubbleViewItem
//
export interface HabitsBubbleViewItemProps {
  token: string;
  habit: InstanceInternal<NamedObject>;
  refreshData: () => void;
  currentObservation?: InstanceInternal<any>;
  selectedDate: moment.Moment;
  period: Period;
}

interface HabitsBubbleViewItemState {

  //  TODO:  Instead of HARD-CODED primitive systems like this, I want, potentially several, GENERIC systems??? Hmm... which COMPOSE to, potentially several, more SPECIFIC and EASY to use systems??? HMM... I LIKE the idea of conceptualizinng a system as a set of concepts and responders?? Hmm.. which MAY do things with other systems?  BUT, they also need an interface, or control surface??? Hmmm
  borderColor?: string;
}

//  Hmmm... INSTEAD of an explicit function specifically for color, WHAT if AGAIN, the "Accountability" system HOOKS into the ACTION of rendering this thing and it adds its OWN shit?  MAYBE it DOES have access to a basic API?  THIS way, the Habit system is ALSO unaware of what CHANGES could be being  made?? hmmmmmmmm... lots of approaches and angles here.  AND all of them with a different RESPONSIBILITY or KNOWLEDGE profile!?
class HabitsBubbleViewItemBase extends React.Component<HabitsBubbleViewItemProps, HabitsBubbleViewItemState> {
  static contextType = HabitPluginContext;
  constructor(props: HabitsBubbleViewItemProps) {
    super(props);
    this.state = {}
  }

  //  TODO:  This should IMMEDIATELY update the UI and pass to the server in the background!  FOR NOW, we can rely on the server for our data.  We want to make sure we have a robust caching pattern.
  //  TODO:  This is SUPER similar to the Activty bubble widget... consider combining??  THEN migrate to a HaborComponent for re-use / re-purposing by the user?  Ah... re-purposing is ONE reason we might want to support duck-type props comparison instead of class based when determining the set of suitable components?  ALSO, we COULD let the user MAP between things?
  public habitPressed = async (selectedDate: moment.Moment, completed: boolean) => {

    //  TODO:  Check existing!

    //  Unpack
    const { habit, refreshData, currentObservation, token } = this.props;

    console.log("test");
    //  Update
    //  TODO:  Instead of searching for the "Current Observation" build a SCOPE system that we can ask for one based on the DATE!  Or an Ownership system etc.  This way any *match* can be used to indicate association, like the date.  It's an IMPILIED associaiton .. kind of, becaus eit matches the day pattern hm!  SHOULD be able to see EVERYTHING in a SCOPE!
    if (currentObservation) {



      //  Get the Habit List
      const completedHabits: InstanceID[] = [...currentObservation.payload.habits.instanceIdList];  //  TODO-TS:  TYPE THIS
      const missedHabits: InstanceID[] = [...(currentObservation.payload.missedHabits?.instanceIdList || [])];  //  TODO-TS:  TYPE THIS

      //  Check Existing Completed
      const existingCompletedHabit = completedHabits.find(_habit => _habit.instanceId === habit.id);
      const existingCompletedIndex = completedHabits.findIndex(_habit => _habit.instanceId === habit.id);

      //  Check Existing Missed
      const existingMissedHabit = missedHabits.find(_habit => _habit.instanceId === habit.id);
      const existingMssedIndex = missedHabits.findIndex(_habit => _habit.instanceId === habit.id);

      //  Remove Existing (if it exists)
      //  TODO:  GROUP this code in a "concept" which operates as a wrapper to say what it's doing.  Treat code like an IMPLEMENTATION of a pattern!!!
      if (existingCompletedHabit) {
        completedHabits.splice(existingCompletedIndex);
      }
      if (existingMissedHabit) {
        missedHabits.splice(existingMssedIndex);
      }

      //  Create New (if it didn't exist)
      if (!existingCompletedHabit && !existingMissedHabit) {
        if (completed) {
          completedHabits.push({ nounId: habit.nounId, instanceId: habit.id, type: EntityType.Instance });
        } else {
          missedHabits.push({ nounId: habit.nounId, instanceId: habit.id, type: EntityType.Instance });
        }
        
      }

      //  Update the Observation
      await haborSDK.updateInstance(currentObservation.id, { nounId: currentObservation.nounId, payload: { ...currentObservation.payload, habits: { instanceIdList: completedHabits }, missedHabits: { instanceIdList: missedHabits } } }, token);

      //  Update the UI
      //  TODO:  The UI SHOULD eventually be auto-updated with a local cache OR a subscription or something!
      refreshData();
    }

    //  Create
    else {

      //  Create the Habit List
      const habits: InstanceID[] = completed ? [{ nounId: habit.nounId, instanceId: habit.id, type: EntityType.Instance }] : [];
      const missedHabits: InstanceID[] = !completed ? [{ nounId: habit.nounId, instanceId: habit.id, type: EntityType.Instance }] : [];


      const instance: Instance = {
        nounId: "habit-observation",
        payload: {
          startTime: selectedDate.toDate().toISOString(),
          //  TODO:  For this system, use a type that DOEN'T have such options, thus limiting the scope of possible interpretation???
          isPending: false,  //  "isPending" refers to the status of the endTime.  In this case, we KNOW the time of the event (a period), but the event is still open to be updated.  We MAY want to restrict that as well? 
          habits: {
            instanceIdList: habits
          },
          missedHabits: {
            instanceIdList: missedHabits
          },
          period: this.props.period
        }
      };
      await haborSDK.createInstance(instance, token);

      //  Update the UI
      //  TODO:  The UI SHOULD eventually be auto-updated with a local cache OR a subscription or something!
      //         MAYBE start by parsing each "Query" to get the associated objects and "Subscribing" to changes to those and checking if it matches the query on the CLIENT-SIDE!  THAT makes a LOT of sense to me.  THEN, we just pass the changed data (if they have access) to the client, which will then re-evaluate the query based on that thing... That COULD be tricky though, if the query involves multiple objects?? BUT, maybe not... maybe we cache them all, and then when a nested object changes, we just update our cached value.  If a NEW object is provided, that's a CHILD of one of our queries, then we SHOULD be able to get the reference of parent from that child!  Ok... BUT what about when a link changes?  Idk... Hmmm... We COULD let the client send in GUIDS so it knows the name??? THEN, we just make sure that GUID doesn't exist (client could have selected it manually) and if not, then we go ahead and allow it!  Hmm...OR we namespace all names?
      refreshData();
    }

  }

  public componentDidMount = async () => {

    //  Get the Border Colors
    const { habit } = this.props;

    //  TODO:  Instead of manually querying for the associated Events, Hessia will "know" (by Plugin insallation) that the "Event" noun has particular states associated with it?  So... From that, it can load those states as built-in variables in the scope which can be used in conditionals!
    //  IDEA:  A Long Press can potentially show a context menu to which the user can modify the COMPONENT OR the underlying data value(s).  For example, showing an interface the user can use to perform functions / actions available to that user for that object(s)!
    // const habitPlugin = Program.haliaStack?.getPlugin(HabitPlugin.details.id).plugin as HabitPlugin | undefined;
    // const borderColorator = this?.context?.getBorderColorator();
    // const borderColor = borderColorator ? await borderColorator(habit, token) : undefined;//  NOTE:  This variable is connected with STATE.  Because we can't wait on async while it's rendering.  BUT, with the reactive blocks this is AUTOMATIC!?
    // this.setState({ borderColor });
  }

  public render = () => {

    //  Unpack
    const { currentObservation, habit, habit: { id, payload: { name, icon = { name: "run", type: "material-community" }, description, color = { r: "0", g: "200", b: "230" } } } } = this.props;

    //  Render Color
    //  NOTE:  In Hessia, this conversion is automatic and colors are ALWAYS displayed with a uniform interface (potentially with multiple components to chose from).
    const hexColor = renderRGBAColor(color);

    //  Determine Checkin Status
    //  NOTE:  This is JUST an example of a pattern where we havve a THING.. BOOLEAN thing LINKED to another thing hM!  We implement it with this particular pattern, BUT it's a more generic thing and I'd like to encode it as such hm!
    const checkedIn = currentObservation ? currentObservation.payload.habits.instanceIdList.find((instanceId: InstanceID) => instanceId.instanceId == id) : false;
    const missed = currentObservation ? (currentObservation.payload.missedHabits?.instanceIdList || []).find((instanceId: InstanceID) => instanceId.instanceId == id) : false;

    const backgroundColor = checkedIn ? MD3Colors.primary95 : missed ? MD3Colors.error95 : '#f4f4f4';
    const foregroundColor = checkedIn ? MD3Colors.primary60 : missed ? MD3Colors.error60 : '#aaaaaa'

    return (

      //  TODO:  Instead of BORDER COLOR, consider registering a GROUP for this to be GROUPED by!?  I would MUCH prefer that I think?  BUT it's COOl that this type of STYLE change is an option!??? SOME other systems MIGHT want to take advantage of that!?  MAYBE we should expose the ENTIRE interface or redefine some smaller, reasonable subsets for injection / overrides by systems???
      // <Card raisedHeight={ RaisedHeight.none } outerStyle={{ backgroundColor: Color.offWhite, borderColor: borderColor || undefined, borderWidth: borderColor ? 1 : 0 }} innerStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 90, padding: 10 }} onPress={ this.habitPressed }>

      <Card raisedHeight={RaisedHeight.none} outerStyle={{ backgroundColor, borderRadius: 5 }} innerStyle={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 100, padding: 10 }} onPress={() => this.habitPressed(this.props.selectedDate, true)} onLongPress={() => this.habitPressed(this.props.selectedDate, false)}>
        <KelpIcon name={icon.name} type={icon.type} color={foregroundColor} size={30} />
        <View style={{ height: 10 }} />
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 10, color: foregroundColor, textAlign: 'center' }}>{name}</Text>
      </Card>
    );
  }
}
export const HabitsBubbleViewItem = HabitsBubbleViewItemBase

//
//  HabitsBubbleView
//

//  TODO:  In the NEAR future, we want to remove ALL these hard-coded views and let them be built by the user in a reasonable way... Mixing and matching components!  MAYBE we can have several PATTERNS BETWEEN components where several elements of the overall pattern are swappable, like the "Filter" bar, BUT although the interface is changing, the purpose of the piece within the pattern / system remains the SAME at the abstraction of the system.
//  IDEA:  Consider making several versions of a view based on the incoming item type??  For "Events", it's possible to support more component types??  FOR NOW, let's build out the app as if we were in a native context.  This is ESSENTIALLY acting as a prototype, BUT it's going to be functional!
//  NOTE:  This is just a "Quick Entry" form, we have another, more generic "Trackers" / "Event" page to upload other ones?
export interface HabitsBubbleViewProps {
  // frameworkContext: FrameworkContext;
  // componentContext: HaborComponentContext;
  token: string;
  habits: InstanceInternal<NamedObject>[];
  refreshData: () => void;
  currentObservation?: InstanceInternal<any>;
  selectedDate: moment.Moment;
  period: Period;
}

interface HabitsBubbleViewState { }

class HabitsBubbleViewBase extends React.Component<HabitsBubbleViewProps, HabitsBubbleViewState> {
  constructor(props: HabitsBubbleViewProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { habits, refreshData, currentObservation, token } = this.props;

    //  Generate UIs
    //  TODO-CRITICAL:  It's up to us how we want this to work... we COULD have the "Group" Plugin "Hook" into the list views and automatially add Component Settings to support grouping... OR, we can just wrap that view and make a new GroupedListView?  Both seem like validish options... the first seems more maneagable thuogh... Otherwise we'll end up with an explosion of names... Yes I DO like that approach better I think.  However, that doesn't stop us from using the ListComponent WITHOUT grouping and making our OWN grouping code kind of like we're doing here. 
    return (
      <FlatGrid
        itemDimension={70}
        data={habits}
        renderItem={({ item }) => <HabitsBubbleViewItem period={this.props.period} token={token} selectedDate={this.props.selectedDate} currentObservation={currentObservation} refreshData={refreshData} habit={item} />}
        spacing={10}
        style={{ margin: -10, padding: 0, overflow: 'visible', minHeight: 500 }}
      />
    );

  }
}
export const HabitsBubbleView = HabitsBubbleViewBase

interface WorkspaceHabitsProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
}

interface WorkspaceHabitsState {
  habits: InstanceInternal<any>[];
  currentObservation?: InstanceInternal<any>;
  currentDate: moment.Moment;
  period: Period;
  loading: boolean;
}

export class WorkspaceHabits extends React.Component<{ token: string }, WorkspaceHabitsState> {

  constructor(props: any) {
    super(props);
    this.state = {
      habits: [],
      currentObservation: undefined,
      currentDate: moment(),
      period: 'day',
      loading: true
    }
  }

  //  TODO-IMPORTANT:  Eventually use a Subscription for this!  We DON'T want to be manually telling the app to update each time we do!  MAYBE start by supporting a basic subscription for EACH of our types??
  //  TODO:  REALLY hate that we're hard-coding these things... but if it helps us identify the patterns we'll need then it's useful!  AND we can start using the app!  I'm thinking it will be a progressive thing.  Hopefully tomorrow we can start work on the "Custom Page" view.
  private refreshData = async () => {

    //
    //  Groups
    //
    // const groups = await haborSDK.searchInstances(token, { nounId: "habit-group" });

    //  Encode the Sleep Time
    //  TODO:  This SHOULD be encdoed in the app by the user perhaps?  OR even ADDED with a PLUGIN!! HM!
    // const sleepTime = {
    //   hours: 1,
    //   minutes: 0
    // };

    const { period: per } = this.state;

    this.setState({ loading: true });

    //  Get the Current Date
    const currentDate = this.state.currentDate;
    const currentDayStr = currentDate.format("dddd").toLowerCase();

    //  Get Habits
    //  NOTE:  This is a simple query to obtain the object in question.
    const habits = await haborSDK.searchInstances(this.props.token, { nounId: "habit" });

    //  Filter Disabled
    //  TODO:  NEED to be able to INJECT this feature from a thing mm!!  It should define the change to the Habit Model (perhaps add it's own) and freaking... inject the necessary logic.  Instructions for the person to change things in the other house!
    //  TODO:  Feature to "Show Disabled".  I DO want to be able to make this ENTIRE feature on the fly.  Like... say "if a habit is disabled, then don't show it."  The SYSTEM should be able to interpet that there's a PATTERN for "disabled", and it should be able to PROJECT its knowkee of that pattern and then be bale to HIDE it by either disableing in the UI or fitering etc hmm..
    const enabledHabits = habits.filter(habit => !habit.payload.disabled);

    //  Filter by Period
    //  TODO:  Do as part of the search, AND do it with PROJECTION!  This is an EXPLICIT mapping from which ENGLISH has a freakign realization!  Shouldn't need to do it so expliclty!
    const periodHabits = enabledHabits.filter(habit => {

      //  NOTE:  The "Current Date" will represent the START of the period.  But, we don't want to select a period like "Monday".  We COULD do that, bu it's a weird UI.  Instead, we'll just pick the day, and if it IS that period, then we show.  Because, we treat it as a SUBSET of the "Day" selection.  Effectively it's a SCOPED day selection.  One that is sensitive to the INSTANCE, where others are not!
      //  NOTE:  Basically we're selecting a DAY.  The idea is... if the selection matches the habit period, then we show!

      //  Habit Period 
      //  Selected Period

      //  Handle Habits with a SPECIFIC Day (subset of "day" period selection)
      if (this.state.period === 'day') {
        const habitPeriod = habit.payload.period;
        if (habitPeriod === 'day') { return true; }
        return currentDate.format("dddd").toLowerCase() === habit.payload.period;
      }

      //  Return Habits which match the selected period
      return habit.payload.period === this.state.period
    });

    //  Get the Current Observation
    //  FOR NOW:  Get ALL the HabitObservations and sort to get the most recent.  That's the one we'll use.  In the future, we should let the dev-user specify which observation to pull?  MAYBE this component is not knowledgeable of how it's getting its HabitObservation?
    //  TODO-SOON:  We REALLY just need to query for the observation in the given period (only one will be permitted).
    const habitObservations = await haborSDK.searchInstances<any>(this.props.token, { nounId: "habit-observation" });

    //  Sort the Observations
    //  TODO-SOON:  Check the ordering of this sort.
    //  NOTE:  If it's less than 0, A goes down in index.  If it's greater than 0, A goes up in index.
    const observations = habitObservations.sort((a, b) => new Date(b.payload.startTime).getTime() - new Date(a.payload.startTime).getTime());
    // alert(JSON.stringify(observations));

    //  Check Current Period
    //  FOR NOW:  Assume the current period is the DAY.
    //  TODO:  SHOULD have an easy way to manipulate this list of "Dated Things" and filter to "Today", which is PERHAPS defiend elsewhere and brought in hm!
    //  NOW, all we need to do is get the current time.  If it's within the buffer range, we still consider it yesturday hm!

    // const hour = currentTime.hours();
    //  TODO:  Inject a way to SELECT a date.  PERHAPS be able to select a time period too, THOUGH that may require aditional ENCODING DIFFERENTIAOTOIN HM!
    // const currentDateString = currentTime.toDate().toDateString();

    //  Find the Matching Observation
    const currentObservation = observations.find(observation => {

      if (observation.payload.period !== this.state.period) { return false; }
      const observationDate = moment(observation.payload.startTime);

      //  Handle Fornights (Period of 2 Weeks)
      if (per === 'fortnight') {
        const observationFortnight = observationDate.week() / 2;
        const currentFortnight = currentDate.week() / 2;
        if (observationFortnight === currentFortnight) {
          return true;
        } else {
          return false;
        }
      }

      //  Handle Specific Dates
      if (per === 'monday' || per === 'tuesday' || per === 'wednesday' || per === 'thursday' || per === 'friday' || per === 'saturday' || per === 'sunday') {
        const isMatch = currentDate.format("dddd").toLowerCase() === per;
        return isMatch;
      }


      //  Handle Weekdays
      if (per === 'weekday') {
        const isWeekday = currentDayStr === 'monday' || currentDayStr === 'tuesday' || currentDayStr === 'wednesday' || currentDayStr === 'thursday' || currentDayStr === 'friday';
        return isWeekday;
      }

      //  Handle Weekends
      if (per === 'weekend') {
        const isWeekend = currentDayStr === 'saturday' || currentDayStr === 'sunday';
        return isWeekend;
      }

      const isMatch = currentDate.isSame(observationDate, per);
      return isMatch;
    });


    this.setState({ habits: periodHabits, currentObservation, loading: false });
  }

  public toggleLock = async () => {

    const { currentObservation } = this.state;
    const { token } = this.props;

    if (currentObservation) {
      await haborSDK.updateInstance(currentObservation.id, { nounId: currentObservation.nounId, payload: { ...currentObservation.payload, locked: !currentObservation.payload.locked } }, token);
      this.refreshData();
    }
    else {

      const instance: Instance = {
        nounId: "habit-observation",
        payload: {
          startTime: this.state.currentDate.toDate().toISOString(),
          isPending: false,
          habits: {
            instanceIdList: []
          },
          period: this.state.period,
          locked: true
        }
      };
      await haborSDK.createInstance(instance, token);
      this.refreshData();
    }

  }

  public componentDidMount = async () => {
    await this.refreshData();
  }

  public render = () => {

    //  Unpack
    const { token } = this.props;
    const { habits, currentObservation } = this.state;

    //  TODO:  make it possible to BIND to "parent" state if something is availabnle, OR auto-query!  This way params are easy hm!  MAY even be defined separately and expanded hm!  NEED to be ok thinking differen!  The CURRENT solution is BROKEN in so many ways because people don't want to think and re-invent mm!!

    const ProgressWidget = ({ observation, habits }: { observation?: InstanceInternal<any>, habits: InstanceInternal<any>[] }) => {

      //  TODO:  Show Grade, Score, Day - Day Improvement, etc hm!  
      //  TODO:  GAMIFY it with pretty stickers (give off fun vibes, seem COOL and the animations are cool hm!)  Also... think about those fundamtnals... hmm


      const habitCount = habits.length;
      const observationCount = observation ? observation.payload.habits.instanceIdList.length : 0;
      const progress = observationCount / habitCount;

      return (
        <GroupCard style={{ flexDirection: 'column', margin: 0 }}>
          <Text variant="titleMedium">Progress</Text>
          <View style={{ height: 10 }} />
          <ProgressBar progress={progress} color={MD3Colors.primary60} />
          <View style={{ height: 10 }} />
          {/* REFERENCE:  https://stackoverflow.com/a/661569/3625866 */}
          <Paragraph>{Math.round(progress * 100)}% Complete</Paragraph>
        </GroupCard>
      );
    }

    const updatePeriord = (period: Period) => {
      this.setState({ period }, this.refreshData);

    }

    //  TODO:  Instead of using an "if" statement, have an ENCODING associated with the "loading" STATE.  THEN... have that code run and INJECT this mm!!  The BENEFIT, is that we can later VIEW the code from multuple ways mM!!!  WHY is code done like this?  I'm SOOOO happy I started down this path BEFORE I started to have confidence issues mm!!!  The MAIN idea is, there are SOOOO many things that can be improved mm!  ABUNDANCE!  It's just... most of those things do not provide immediate BUSINESS  value mm!!
    if (this.state.loading) {
      <ActivityIndicator style={{ display: 'flex', flex: 1 }} />
    }


    return (
      // <WorkspaceContainer { ...this.props } title="Habits" buttonStyle={ WorkspaceButtonStyle.Configure } scroll={ false }>
      //  NOTE:  ALL Routing is FLAT, just with passed CONTEXT.

      <ScrollView style={{ padding: medSpacer, backgroundColor: 'white' }}>

        {/* Period Selection View */}
        {/* THOUGHTS:  HATE that I need to encode this expliclty  HAte tht I don't have a way to REFERENCE this as a stable thing without polluting the codebase.  CAN use a separate file... ugh.  SO many people become COMPLACENT!!!  The key is to CONTINUE nothing things that don't need to be the way they are!  SO much opportunity!!! */}

        <SegmentedButtons
          value={this.state.period}
          onValueChange={updatePeriord as any}
          buttons={[
            {
              value: 'day',
              label: 'Daily',
            },
            {
              value: 'week',
              label: 'Weekly',
            },
            {
              value: 'fortnight',
              label: 'Bi-Weekly',
            },
            {
              value: 'month',
              label: 'Monthly',
            },
            {
              value: 'quarter',
              label: 'Quarterly',
            },
          ]}
        />

        {/* <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <TouchableOpacity onPress={() => this.setState({ period: 'day' }, this.refreshData)} style={{ height: 40, width: 100, backgroundColor: this.state.period === 'day' ? '#eeeeee' : 'white', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderColor: '#aaaaaa', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }}><Text>Day</Text></TouchableOpacity>
        <TouchableOpacity onPress={() => this.setState({ period: 'week' }, this.refreshData)} style={{ height: 40, width: 100, backgroundColor: this.state.period === 'week' ? '#eeeeee' : 'white', borderColor: '#aaaaaa', borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 2, alignItems: 'center', justifyContent: 'center' }}><Text>Week</Text></TouchableOpacity>
        <TouchableOpacity onPress={() => this.setState({ period: 'fortnight' }, this.refreshData)} style={{ height: 40, width: 100, backgroundColor: this.state.period === 'fortnight' ? '#eeeeee' : 'white', borderColor: '#aaaaaa', borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 2, alignItems: 'center', justifyContent: 'center' }}><Text>Fortnight</Text></TouchableOpacity>
        <TouchableOpacity onPress={() => this.setState({ period: 'month' }, this.refreshData)} style={{ height: 40, width: 100, backgroundColor: this.state.period === 'month' ? '#eeeeee' : 'white', borderColor: '#aaaaaa', borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 2, alignItems: 'center', justifyContent: 'center' }}><Text>Month</Text></TouchableOpacity>
        <TouchableOpacity onPress={() => this.setState({ period: 'quarter' }, this.refreshData)} style={{ height: 40, width: 100, backgroundColor: this.state.period === 'quarter' ? '#eeeeee' : 'white', borderTopRightRadius: 10, borderBottomRightRadius: 10, borderColor: '#aaaaaa', borderWidth: 2, borderLeftWidth: 0, alignItems: 'center', justifyContent: 'center' }}><Text>Quarter</Text></TouchableOpacity>
      </View> */}

        <View style={{ height: 10 }} />

        <SingleDatePicker period={this.state.period} selected={this.state.currentDate} onSelected={(date) => { this.setState({ currentDate: date }, () => this.refreshData()) }} />

        <View style={{ height: 20 }} />

        <ProgressWidget habits={this.state.habits} observation={currentObservation} />

        <View style={{ height: 20 }} />

        <HabitsBubbleView period={this.state.period} token={token} selectedDate={this.state.currentDate} currentObservation={currentObservation} refreshData={this.refreshData} habits={habits} />

        <IconButton
          icon="lock"
          iconColor={this.state.currentObservation?.payload?.locked ? MD3Colors.primary60 : MD3Colors.neutral90}
          size={40}
          onPress={() => this.toggleLock()}
        />


      </ScrollView>
    );
  }
}

//
//  Habits Primitive Component
//
export interface HabitsHaborPrimitiveProps extends PrimitiveProps { }
export const HabitsHaborPrimitive = ({ userProps, frameworkProps }: HabitsHaborPrimitiveProps) => {

  //  Unpack
  const { context: { token } } = frameworkProps;

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <WorkspaceHabits token={token} />
    </HaborContainer>
  );
};

export const HabitsHaborComponent: HaborComponent = {
  name: "HabitsHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "HabitsHaborPrimitive",
    props: {},
    children: []
  }
};
