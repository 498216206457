import { SDTObject } from "habor-sdk";
import { transformSDT } from "../../davel-ui";

//  Transforms the form values
export const sdtObjectTransformer = async (flatValues: any, sdt: SDTObject, name: string) => {

  //  Transform Explicit Properties
  const { properties = {}, itemType } = sdt;
  Object.keys(properties).forEach((propertyName: string) => {
    //  TODO-CRITICAL:  Enforce that names do NOT include ':' (it will break the nested tree), OR use a new transformation pattern which avoids a special character.
    const nestedName = `${name}:${propertyName}`;
    const propSDT = { ...properties[propertyName] };
    transformSDT(flatValues, propSDT, nestedName);
  });

  //  Transform Extended Properties
  if (sdt.extensible) {

    //  Get the Sub-Keys for this Object (All Depths)
    //  WARNING:  If no sub-keys are present on an object, then it wouldn't have been added to the flat list.
    //            When rendering occurs, a bunch of fields are created with a nested name.  Then, we create a list of those fields in a flat list.
    const nestedKeys = Object.keys(flatValues).filter((key) => key.indexOf(name) == 0);

    //  Get the Immediate Sub-Keys for this Object
    const childProps: any = {};
    nestedKeys.forEach((key) => {
      const nestedPortionStart = name.length + 1;
      const nestedPortionLength = key.length - nestedPortionStart;
      const nestedPortion = key.substr(nestedPortionStart, nestedPortionLength);
      const nextKey = nestedPortion.substr(0, nestedPortion.indexOf(':'));
      childProps[nextKey] = nextKey;
    });

    //  TODO:  I feel this much string manipulation is a sign we should consider a more structured data model.
    Object.keys(childProps).forEach((propName: string) => {
      const nestedPropName = `${name}:${propName}`;
      const explicitProp = properties[propName];
      if (explicitProp == undefined) {
        const propType = itemType ? itemType : { type: "any" };
        transformSDT(flatValues, propType, nestedPropName);
      }
    });
  }
};
