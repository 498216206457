import { NavigationProp, useNavigation } from '@react-navigation/native';
import { InstanceInternal, NounInternal, Plugin, serializedUserBlockNoun } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { AppsPlugin } from '../apps-plugin/apps-plugin';
import { useApps } from '../apps-plugin/apps-plugin-context';
import { HAppRoute } from '../apps-plugin/apps.nav.routes';
import { isNoun } from '../habor-plugin/habor-utils';
import { AppContext } from '../hessia-plugin/AppContext';
import { ContainerListSimple } from '../model-plugins/named-object-plugin/named-object-list-simple';
import { SingleElementWiget } from '../model-plugins/named-object-plugin/single-element-widget';
import { SimpleBox } from '../page-plugin/page-widget';
import { AggregatePlugin, getPluginElements } from '../system-plugin/plugin/plugin-tools';

export type HaborElement<T = any> = NounInternal | InstanceInternal<T>;

export interface ComposerWidgetProps {
  navigation: NavigationProp<any>;
  system: InstanceInternal<Plugin>;
  appsPlugin: AppsPlugin;
}

export interface ComposerWidgetState {
  dependencies: InstanceInternal<Plugin>[];
  pluginDetails?: AggregatePlugin;
}

class ComposerWidgetBase extends React.Component<ComposerWidgetProps, ComposerWidgetState> {
  constructor(props: ComposerWidgetProps) {
    super(props);
    this.state = {
      dependencies: []
    }
  }

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>

  public componentDidMount = async () => {

    this.getElements();
  }

  public componentDidUpdate = () => {
    this.getElements();
  }

  public getElements = async () => {

    const { system } = this.props;
    const { token } = this.context.frameworkContext;
    const pluginDetails = (await getPluginElements([system], token))[system.id];
    this.setState({ pluginDetails });
  }

  public onElementPress = async (elem: HaborElement) => {
    if (isNoun(elem)) {
      this.onNounPress(elem as NounInternal);
    } else {
      this.onInstPress(elem as InstanceInternal);
    }
  }

  public onElementLongPress = async (elem: HaborElement) => {
    alert("Should show element menu?");
  }


  public onNounPress = (noun: NounInternal) => {
    this.props.navigation.navigate(HAppRoute.InstanceList, { noun });
  }

  public onInstPress = async (inst: InstanceInternal) => {
    this.props.appsPlugin.applyElementPressHandler(inst, this.props.navigation);
  }

  public render = () => {

    const { pluginDetails } = this.state;

    if (!pluginDetails) {
      // alert("COMPOS")
      return <Text>Loading Composer Details</Text>
    }

    if (!pluginDetails.instances[serializedUserBlockNoun.id]?.instances) {
      return null;
    }

    return (
      <SimpleBox
        title="Composers"
        content={
          <ContainerListSimple component={SingleElementWiget} elems={pluginDetails.instances[serializedUserBlockNoun.id].instances} onItemLongPress={this.onElementLongPress} onItemPress={this.onElementPress} />
        }
      />
    );
  }
}

export const ComposerWidget = (props: Omit<ComposerWidgetProps, "navigation" | "appsPlugin">) => {
  const navigation = useNavigation();
  const appsPlugin = useApps();
  return <ComposerWidgetBase appsPlugin={appsPlugin} navigation={navigation} {...props} />
}
