import AsyncStorage from '@react-native-async-storage/async-storage';
import { EventEmitter } from 'events';
import { APIUser, InstanceInternal, TokenSDK, UserSDK } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { MenuLayoutPlugin } from '../../elements/menu-layout-plugin/menu-layout-plugin';
import { haborSDK } from '../hessia-plugin/config';
import { Start } from "./start";

//  Centralize SDK Initialization
const tokenSDK = new TokenSDK(haborSDK);
const userSDK = new UserSDK(haborSDK);

export class AuthPlugin extends CorePluginClass {

  public user!: InstanceInternal<APIUser>;
  public token!: string;
  //  Logout Register
  // //  CONSIDER:  Instead of JUST adding a function, consider adding an identifier for the "listener".. or "dependent"..  possibly with unlimited "metadata" as well for otherr systems staking HM!
  // public logoutRegister: any[] = [];
  // public onLogOut = (func) => {
  //   this.logoutRegister.push(func);
  // }

  // //  Login Register
  // //  CONSIDER:  Instead of JUST adding a function, consider adding an identifier for the "listener".. or "dependent"..  possibly with unlimited "metadata" as well for otherr systems staking HM!
  // public loginRegister: any[] = [];
  // //  CONSIDER:  Instead of explicltly hard-coding the type and the params herre.. WHY not inject the WHOLE thing?  Why not CREATE this "encoding"... NOT that we need to generate THIS code... but we can inject functions which produce the categorically "same" internal state result? Hmm.. something like that.
  // public onLogin = (func: (user: APIUser, token: string) => void) => {
  //   this.loginRegister.push(func);
  // }

  //  CONCERN:  When using "EventEmitter"... it's SO specific.. I like the idea of it being extensible, BUT innt he SAME way as like.. everything else.. a UNIFIED, "reactive" framework? HM!
  //  TODO-IMPORTANT!!:  I'd like to emit in DEPENDENCY order right?? AND perhaps WAIT for the parents before moving to children, BUT go for async when they are independent in terms of declared dependneices hm!  MAYBE this is all similar to the BLOCKS system hm!!  NICE!
  public authEmitter: EventEmitter = new EventEmitter();


  //  TODO-SHORT-TERM:  Before we have the generic Reactive pattern, do explicit mount / unmount for the listeners.. hmm

  public onLogin = (func) => {
    this.authEmitter.addListener("login", func);
  }

  public onLogout = (func) => {
    try {
      this.authEmitter.addListener("logout", func);
    } catch (ex) {
      alert("An Error Occurred While Logging Out: " + JSON.stringify(ex));
    }
  }

  //  CONCERN:  DON'T really like how this is being exported.. makes this NOTHING more than a package... if we're orchestrating elsewhere hmm... the idea is.. MAYBE it's best of both worlds? Hmm... 
  public Start = () => {
    return <Start login={ this.loginWithPassword } authPlugin={ this } /> 
  }

  public onLoginFailed = (func) => {
    this.authEmitter.addListener("login-failed", func);
  }

  public static details = {
    id: "authPlugin",
    name: "Auth Plugin",
    dependencies: [],
  }

   /**
  * Log into the App with a Password
  * @param user
  * @param token 
  */
    public loginWithPassword = async (username: string, password: string) => {

      if ((username === "no-login") && (password === "no-login")) {
        const user: InstanceInternal<APIUser> = {
          payload: {
            email: "mock@hessia.io",
            username: "mock",
            verified: true
          },
          created: new Date().toISOString(),
          updated: undefined,
          id: "mock-id",
          nounId: "mockNoun"
        };

        this.authEmitter.emit("login", { user, token: "mock-token" });
        return;
      }

      try {
  
        //  Get the User
        // alert("About to Create Token: " + JSON.stringify({ username, password }))
        const tokenInst = await tokenSDK.create({ username, password });
        const { token, user: userId } = tokenInst.payload;
        const user = await userSDK.retrieve(userId, token);
  
        //  Login with Token
        // alert("About to login: " + JSON.stringify({ user, token }))
        await this.loginWithToken(user, token);
  
      } catch (err) {
        alert(err.message);
        this.authEmitter.emit("login-failed");
        // this.history.push('/login/failure');
      }
    };
  
    public logout = async () => {

      //  Trigger Listeners (Dependents)
      //  TODO:  Standardize the "register" pattern!! Then we can invoke a "register" in just one call? HM!  MAYBE with params / encoding and stuff to a resolver?? HM!  WHICH is also Pluggable? HM!  WHEN does the pluggability end???
      //  REALIZATION:  AHHH!!!!We have  a STING here and we COULD have done this in two separate, BUT thats te POINT.. it's all the same at SOME level.. we CAN perhaps do EVERYTHIG with a web of assocated encodings? ? HMklad;lkfjsldf
      this.authEmitter.emit("logout", {});

      //  TODO-AWESOME:  INJECT Auth support for AsyncStorage, wich MAY not be necessary!! HM!!
      //                 Things like THIS will help us build a more generic / flexible programming model hm!
      await AsyncStorage.removeItem('user');
      await AsyncStorage.removeItem('token');

      // this.history.push('/');
    }

    public loginWithToken = async (user: InstanceInternal<APIUser>, token: string) => {
  
        //  Set AsyncStorage
        await AsyncStorage.setItem('user', JSON.stringify(user));
        await AsyncStorage.setItem('token', token);
  
      //  Validate the Token
        //  NOTE:  Currently I validate the token by checking for the "token" Noun.
        try {
          await haborSDK.retrieveNoun("token", token);
          this.user = user;
          this.token = token;
          this.authEmitter.emit("login", { user, token });
          //  NOTE:  Currently I just set the local vars.. but I MAY want to treat these as "managed state", like React does.
        } catch (err) {
          this.authEmitter.emit("login-failed");
          this.logout();
        }

        
        // this.setState({ user, token, frameworkContext: { ...defaultFrameworkContext, user, token, workspace: this.app.state.workspace, loggingIn: false } }, () => {
        //   //  Navigate
        //   navigate(this.history, RouteList.Home, {});
        // });
  
    };

  public install = (program: Program, {  }: {  }) => {

    //  CONSIDER:  FOR NOW, I'm still building a coupled-encoding.  In the future, I can also buid THIS with Plugins.  This makes it easy to mix and match and stuff? Hmm... perhapss.
    //  FOR NOW:   The plan is to have a simple component.  Then, we can register that hmm...  Again... then the problem is what if we have two... the opint is, IF we have Plugins without the couplig system, we will register two.  The App and the Auth hmm... the idea is, we can register the Auth... then what?  Then, we can Fuck... worried about freaking choice all of a sdden ugh... THe POINT is, I thin kit's the SAME as i it with all things.. that guy's just drawing a line where oe doesn't need to be drawn.  So... wtf do we do?  THe point is, we can have a generic strucure.  THis is somethin we use hmm... The idea is a simple generaliztiong.  Where we register a thing for this ad a thing for app.  Then, we go to those systems to get the encoding.  he idea is, infinite layers of reactivity? Hmm.. influence like in "real" life? Hmmm!  THEN, we can have "functions" or... "encodings" which are iterpreted to produce another encoding.  Perhaps MANY such encodings, JUST like React Componetns hm!  INTERESTING.  THey ALSO have "intenal" encodings hmm. maybe we can break out of that model? Hmmmm... 
    //             To start making some freaking coding progress, I'd like to just register this a usual.  THEN, in the APp system, we can register is as well.  It's both just componetns.  Then... Again, it's the same as if they're both systems producing encodings.. hmm.. the point is, then, 
    //  PLAN:  The plan IS, to add BOTH App / Auth to the same thing.  THEN, have that thing mess with it? mmm.. maybe... BUT, maybe instead, we can have the AppAuth "coupling system" just redirect the App so it goes on Auth ? Hmmm maybe... BUT the troubl with that is perhap when we then have additional comlexity... we may ADD new entities? Hmmm... the idea is, whe we add entities, UNLESS we want to triple couple and upward hmm... 
    //  TODO:  ONCE we have the token HOW do we navigate?  I was planning, FOR NOW, to inject the new component? hmm.. MAYBE we want a navigation system? hmm...

    //  TODO:  This SHOULD happen automatially for Class based Plugins?? HM!  I SHOULD do some error loggin in Halia for this too... took like an hour to solve this!  But it's okk.  Stuff like this is what takes codign so long, AND I beleive it can be largely alleviated! HM!

    // menuLayout.registerMenuItem("Login", { name: "Login", component: this.Start, icon: { type: 'material', name: "person" } })
    return this;
  }
}
