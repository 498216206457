import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { NumberEditor } from './number-editor';
import { NumberList } from './number-list';

const Stack = createStackNavigator();

export const NumberPage = () => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      {/* <Route exact={ true } path="/home/entities/detail" component={ EntityDetail } /> */}
      <Stack.Screen name="Editor" component={ NumberEditor } />
      <Stack.Screen name="List" component={ NumberList } />
    </Stack.Navigator>
  );
}