/* eslint-disable import/first */
import { NavigationProp } from '@react-navigation/core';
import { EventEmitter } from 'events';
import * as React from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View, ScrollView, TextInput } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Entity, EntityListItemAPI, EntityListItemComponent, EntityPlugin } from '.';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { Picker } from '../../../packages/kelp-bar/picker';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { GalleryButton } from '../../gallery/components/common';
import { FilterComponent } from '../hessia-plugin/filter-utils';
import { FilterBar } from '../model-plugin/entity/instance-list';
import { EntityRoute } from './entity-navigator';
import { defaultEntityScope, useEntityPlugin } from './entity-plugin';

//  TODO:  Breaks encapsulation!  Now we cant' reuse the component withoutu hmm.. unless we build support for SCOPED componetns hmm...
//  CONSIDER:  START with "scope" but consider extending as needed hmmm...
//  TODO:  SHOULD scope this more!  Currently ALL users can call "removeAll" which would be bad and hm.. don't NEED that abstraction? Hmm
export enum EntityListEvents {
  EntitySelected = "EntitySelected"
}
export const EntityListEmitter = new EventEmitter();

//  NOTE:  We CAN provide entities through a HOOK or through an HOC.  BOTH are just linking into the state tree and reacting to changes in props? Hmm.. the HOOK will use state, and yeah, be able to respond to a chagne of prop hm!
//  TODO:  Build the Providers to separate querying from display?

/**
 * Entity Provider
 * 
 * Provides Entitis in the current context.
 */
// export const EntityProvider = 






//  TODO:  MAYBE I can make COMPONENTS which have Top, BOttom, Left, and Right registers? Hmm... VERY similar to the DOM in a way? Hmm...
//  TODO:  HOW will we REGISTER the view registerrs? Hmm
// export const PluggableView = () => {
// }

export const topRegister: any[] = [];

//  Make the Entity Model
// export interface Entity {
//   name: string;
//   description: string;
// }


//  CONCERN:  I'm going to do the "staking" here.  I worry this is a big mistake... something tells me this is a big mistake... but I'm going to do it anyways.  Maybe we can separrate out to a "staking" system later... OR build something even more complex.  But FOR NOW, I'm going to put the stakes here.
// export interface Entity {
//   //  TODO:  Consider adding more info or doing soething to make it easier to acertain Hmm...
//   // stakes: string[];  //  List of systems with a stake.
// }


// export const entityService = new LocalNounService("entity");
//  CONSIDER:  Make the storage location a user preference for various contexts?  MAYBE support MULTIPLE? Hmm...


//  CONSIDER:  INSTEAD of using the ENTITY SERVICIE... why not GENERALIZed the WHOEL thing and  let the plugins inject piecemeal?? Hmm..

// export interface EntityService {

// }


//  HERE's an example of where we can inject.. HOW though will we do this? Hmm... perrhaps LOTS of ways, BUT the idea is, MAYBE it's a LABEL associated with a more generic RENDER function! HM!  Maybee... 
//  THe benefit to doing it that way is we'll have a "renderer".. OR more generally, LIKE in Habor, a FUNCTION resolver which will go out to the staking systems Hmmm... HOW does it KNOW?  Well... FOR NOW, it'll just trigger them all.. hmmm!  Then it's like a THING that's constrructed? Hmm I ALREADY thought about htis a bit last year.. hmmm  MAYBE this should be added by the "Compoennt" system? Hmm... The idea of "Render"? hmm.. idk.  The point though, is idk.. if we just have this ONE symbol, then how do we know what to trigger when we get to sub-things hmm... MAYBE we do this for now and expand out.. fuck.
//  NOTE:  Perhaps the order is in Plugin registration order FOR NOW? Hmm... LOTS of ways we can do this LOTS of ways.. hmm... mabe only ONE that "matters"? Hmm.. idk man
//  NOTE:  I WOULD like to generalize to NAMED "functions" I think... hmmm... pehaps.. THEN maybe a system can be used to build user functions and stuff? Hmm..  MAYBE we call these ACTIONS? Hmmm... I think I'll call them... things? Hmmmmmm... FOR NOW... i'll call the damn things functions.

// const resolvers: { [name: string]: any } = {};
// export const registerResolver = (name: string, resolver: any) => {
//   resolvers[name] = resolver;
// }

// //  NOTE:  MAYBE we can even have systems CONSTRUCTING this CONTEXT as ONE such "revsolver"? HM!
// export const resolve = (name: string, params: any) => {

//   for (let i = 0; i < resolvers.length; i++) {
//     const resolver = resolvers[i];
//     resolver[i](context, value);
//   }
// }


// const functions: string[] = [];
// export const registerFunction = (funcName: string) => {
//   functions.push(funcName);
// }

//  THOUGHT:  MAYBE the idea is like.. infinite CONTEXT being passed to the function.. then WHY even have a NAME?  WHY not just pass THAT as context? HM!!


// registerFunction("render");

//  NOTE:  DOn'T like that I need to manually add libs for the types and stuff.. SEEMS like it should happen automatic.. hmm

//  CONSIDER:  Instead of a STATIC set of possible registeration points, consider controlling this DYNAMICALLY so plugins can change this too.  THEN again, the plugins work together to build the final encoding which is interpreted to build the state encoding? Hmm...




export const withEntity = (entityId: string) => {

  const entityPlugin = useEntityPlugin();

  const [entity, setEntity] = React.useState<EntityInternal | undefined | null>();

  const loadEntity = async () => {
    if (entityId) {
      try {
        const entity = await entityPlugin.entityService.retrieve(entityId);
        setEntity(entity || null);
      } catch (err) {
        alert("Failed to load entity: " + entityId);
      }

    }
  }
  React.useEffect(() => {
    loadEntity();
  }, []);

  return entity;
}

export const withEntityRoute = (address: any) => {

  const entityPlugin = useEntityPlugin();

  const [entity, setEntity] = React.useState<EntityInternal | undefined | null>();

  const loadEntity = async () => {
    if (address) {
      try {
        const entity = await entityPlugin.entityService.retrieve(address);
        setEntity(entity || null);
      } catch (err) {
        alert("Failed to load entity with path: " + address);
      }

    }
  }
  React.useEffect(() => {
    loadEntity();
  }, []);

  return entity;
}


//  CONSIDER:  SO much here.. UGH... that we can simpligy and stuff hmm... idk man...HATE all these componetns... they're ALL so simlar.. make a SUPER component !  WHICH takes in "props" in an object thing shm!  THEN we can inject theproepty and DEFINE the thing freaking dynamically hm!  I THINK I like taht hm.. lIKE making it a TABLE view and stuff hm!  LOTS of things like this!  WHY do it separate when we can do it compositionally hm!  It's like the Plugin thing!  EXACTL YHJ!m It's still just the modifier pattern hm!  We want to INJECT into this component, NOT just build it direlt !  FOR NOW, let's chug, but yeah asdlkjfsdalkfj !
//  NOTE:  Use this instead of renderEntityListItem
//  CONSIDER:  We require EITHER one or the other.  How can we express that? AH!!  It's the SAME as CATEGORY!  This set of "requirements" can be ARBITRARILY complex and we want to be able to support EXPRESSING the constraints and they can PERHAPS be enforced at "buidl" time by the "callers" expression, but it's JUST an encoding and it's up to us when we want to enforce hm!  Use ECS to make that MANAGEABLE and composable!
export const EntityListItem = ({ entity }: { entity: Entity }) => {

  const entityPlugin = useEntityPlugin();

  if (!entity) {
    return <Text>No Entity Provided</Text>
  }

  //  TODO:  Consider removing "Deferred"... not sure I'm really into that pattern because it breaks us away from Components and then we can't use hooks and stuff in the async function... hmm... 
  if (!entity) {
    return <ActivityIndicator />
  }
  return <Deferred func={renderEntityListItem} args={{ entityPlugin, entity }} def={<View style={{ padding: 15, backgroundColor: '#eeeeee', borderRadius: 10 }}><ActivityIndicator /></View>} />
}

//  TODO:  Should we allow INJECTION of functionality to extend the renderer API itself?  WHEN does it end.... never.  The answer is never.  We can ALWAYS choose to build an encoding that then influences another.  We can ALWAYs do that...  So... if a PROGRAM is ONE encoding... is it inevitable that we CHANGE the encoding manuallY? Hmm... I think it's up to us where we want to abstrac?? Hmm...

//  CONCERN:  Do I REALLY want explicit functions like this all over the place.. hmm  MAYBE use something like Habor so they can be accessed "dynamically" at runtime? mmm... idk man.  Meaning, the user can end up specifying a string to them? hmm.. MAYBE we could use reflection or soemthing, but idk abotu that either.
//  CONCERN:  Currently, the systems need to search for EACH possible instance.. that could take a LONG time.  I believe we can do this more efficneitly!  AND have data ASSOCIATING them perhaps.. hmm  MAYBE even just logging the primtiive system.  THen having it register and passing it ONLY the ones we need? Hmm.. FOR NOW, I'm avoiding premature optimiztaion!

//  NOTE:  This is for systems to hook into an entity and change the way it's displayed.  Perhaps they can also wrap and have their own injections hmm...
//  NOTE:  It's up to the systems how they want to construct the Payload.  They MAY have to go back out to handle corner-cases, but the idea is, the payload is useful for not re-querying.  THen then can inject as they please where the payload is the ontological strucutre hmm... We START with each one... MAY want to support GROUP things as well? Hmm... like a renderer with access to ALL of them? idk though.. it's STILL just a subset right ?  UNLESs we yeah.. like give it a symbol if there are greater than 25 TOTAL in the DB meeting a condition hmm... that's interesting and somethin gwe can probably add to the entity payload for THIS systems' renderer hmmm...
export const renderEntityListItem = async ({ entity, entityPlugin }: { entity: Entity, entityPlugin: EntityPlugin }) => {

  if (!entity) {
    throw "No Entity Passed to 'renderEntityListItem'";
  }

  if (!entityPlugin) {
    throw "No EntityPlugin Passed to 'renderEntityListItem'";
  }

  let itemText;
  const comps: EntityListItemComponent[] = [];

  const api: EntityListItemAPI = {
    setText: (text: string) => {
      itemText = text;
    },
    //  TODO:  Allow extra props from the CSS-like injection system? Hmm...  MAYBE we can allow that system to control LAYOUT too? HMm
    //  TODO:  Hoist this?? HM!
    addComponent: (comp: EntityListItemComponent) => {
      comps.push(comp);
    }
  }

  //  TOOD:  Remove "setText" only ONE will win.'
  // alert("renderers: " + entityListItemRenderers.length);
  for (let i = 0; i < entityPlugin.entityListItemRenderers.length; i++) {
    //  CONSIDER:  Instead of callig THESE thigns "renderers", they're more like MODIFIERS... they're more like PLUGINS.. hm!
    const renderer = entityPlugin.entityListItemRenderers[i];
    // alert(renderer);
    //  THOUGHT:  Instead of rendering DIRECTLY, what if we have a RENDER API that we can use to do thigs!  THEN the renders can ALL run and safely use the API? hmm.. perhaps!  BUT what about order??? Hmm....
    //  CONSIDER:  We could have this all over the place with Joi? Hmm...
    if (!renderer.renderer) {
      alert("Missing Renderer: " + renderer.name)
      continue;
    }

    await renderer.renderer(entity, api);
  }
  return (
    <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {
        itemText ? <Text>{itemText}</Text> : null
      }

      {
        //  TODO:  Remove "any"
        //  TODO:  Support multiple TYPES of injections even into these small things H!M  AND we can use coupling systems to inject into other systems which inject these things! HM!  These systems COULD also go out and retrieve an encoding somehow and then map that to components.  OR we can inject components directly.  It's whatever the system wants to support on its API!? HM!



        (comps as any[]).map((Comp) => (
          <Comp entity={entity} />
        ))

        // <ViewPager 
        //   duration={300}
        //   timeout={100}
        //   easing={(t: number): number => {
        //     return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        //   }}
        //   activePage={0} 
        //   offset="8rem"
        //   pages={


        //   }
        //   onPageChanged={(index) => {
        //     // do something
        //   }}
        // />

        // <PagerView>
        //   {

        //   }
        // </PagerView>


      }
    </View>
  );
}

//  TODO:  Currently the local EntityService uses LocalNounService, which is implemented with local storage.
//         But, the HaborEntityService is implemented with ModelService... and we have wrapper for that.. so.. the idea is to implemented LocalEntity the SAME way? Hmm.. the real problem is.. the ModelService is built with Noun for local, BUT it's fucking using the SDK direclty remote.  Instead, I'd like to use Noun Service for both? Hmm.. This MAY not be as efficient thouguh, because then fucking nounu / instance are separte and not using the index model in Elastic? Hmmm... MAYBE that's FINE for now/? HM!

// export interface IEntityService extends INounService<Entity> { }

// export class LocalEntityService extends LocalNounService<Entity> implements IEntityService {
//   constructor() {
//     super("entity");
//   }
// }

// export class HaborEntityService implements IEntityService {

//   constructor(protected haborSDK: HaborSDK, protected token: string) {}


// }

// export const entityService = 
// entityService.init();

// export const remoteEntityService = new HaborModelSDK(haborSDK, )


//
//  Renders an Asynchronous Component
//  NOTE:  Defaults to "def".
//
export interface DeferredProps {
  func: any;
  args: any;
  def: any;
}
interface DeferredState {
  res: any;
}
class DeferredBase extends React.Component<DeferredProps, DeferredState> {
  constructor(props: DeferredProps) {
    super(props);
    this.state = {
      res: undefined
    }
  }
  public componentDidMount = async () => {
    const { func, args } = this.props;
    const res = await func(args);
    this.setState({ res });
  }
  public render = () => {
    const { def } = this.props;
    const { res } = this.state;
    return res || def;
  }
}

export const EntityWidget = ({ entity, entityPlugin, scope = defaultEntityScope }: { entity: Entity, entityPlugin?: EntityPlugin, scope?: any }) => {

  //  NOTE:  We use SCOPE so MULTIPLE users can use this without triggering ALL of them.

  entityPlugin = entityPlugin || useEntityPlugin();

  const onPress = () => {
    entityPlugin?.selectEntity(entity, scope);
  }

  return (
    <TouchableOpacity style={{ backgroundColor: 'white', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }} onPress={onPress}>
      <Deferred func={renderEntityListItem} args={{ entity, entityPlugin }} def={<ActivityIndicator />} />
    </TouchableOpacity>
  );
}

export const Deferred = DeferredBase

export type EntityInternal = NounServiceInstanceInternal<Entity>;

const defaultPageSize = 100;

interface EntityListState {
  entitys: NounServiceInstanceInternal<Entity>[];
  filteredEntities: NounServiceInstanceInternal<Entity>[];
  loaded: boolean;  //  CONSDIER:  Can use "dirty" as well, as a slightly different scenario.. differentiated, and we can consider injecting loading as a pattern hmm.. MAYBE let us DECLARE our loaded condition? Hmmm...  Maybe use Hooks?  BUT even then, it's not totally composable.. hm... Mixins aren't either because they JUST add to the functional interface, they don't support injection and yeah hM! We want ANOTHER abstraction layer to "BUILD" thefunction and the encodings, whatever they may be hm!
  page: number;
  systemId: string;
}
class EntityListBase extends React.Component<{ navigation: NavigationProp<any>, entityPlugin: EntityPlugin, scope: any }, EntityListState> {

  constructor(props) {
    super(props);
    this.state = {
      entitys: [],
      loaded: false,
      filteredEntities: [],
      page: 0,
      //  TODO:  Remove the "entity-service" in preference of separate things, and build that "Reference" system separately hm!  MAYBE we do want to track references overall too hm!
      systemId: "habor-entity-service"
    }
  }

  private entitySelectedHandler = ({ scope, entity }) => {
    if (scope.systemId === "entity") {
      this.props.navigation.navigate(EntityRoute.Editor as never, { entity } as never);
    }
  }


  public loadEntities = async () => {

    this.setState({ loaded: false });

    //  Load Entitys
    //  TODO:  Instead of loading ALL... get a SAMPLE or like.. PAGE HM!  The IDEA is.. we ONLY have to go as far as we went the firsst time and then skip those the next interesting hm!! MAYBE grab in BATCHES ? Hmmm... do want a unified interface thogh.. KEEP pressing hm!
    //  TODO:  Make a unified interface like GraphQL where we can page through the systems.
    const entitys = await this.props.entityPlugin.entityService.search({ systemIds: [this.state.systemId], size: defaultPageSize, from: this.state.page * defaultPageSize });

    this.setState({ entitys, loaded: true });
  }

  
  componentDidMount = async () => {

    this.loadEntities();

    const { navigation } = this.props;

    // alert(JSON.stringify(navigation.getState()))
    //  Handle Selection
    EntityListEmitter.addListener(EntityListEvents.EntitySelected, this.entitySelectedHandler);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if ((prevState.page != this.state.page) || (prevState.systemId != this.state.systemId)) {
      this.loadEntities();
    }
  }

  componentWillUnmount = () => {
    EntityListEmitter.removeListener(EntityListEvents.EntitySelected, this.entitySelectedHandler);
  }

  //  THOUGHTS:  Show VIEWS, maybe SUGGESTED, etc.. lots of things hat CAN make sense and MAYBE also show the GENERIC object view? Hmm MAYBE ltos of systems intergere with that? Hmmm...  Like.. maybe if ONE suggests a "FieldSet",, ANOTHER can add more and stuff? hmm.. idk. and mayeb delte? hm idk.. hmmm
  // public updateEntities = async () => {

  //   //  NOTE:  INSTEAD of passing everything... MAYBE it's based on the CONTEXT / STATE of the systems IN that context?? Hmmm... perhapssss!  This way we can have mnultipole states / contexts and stuff???? Hmm..
  //   const res = resolve({ resolv })
  // }

  //  Filter / Search Plugin
  //  Plugins that work WITH Filter / Search
  //  Plugins that inject element into the main view? HM!  MAYBE make GENERIC view elements SIMILAR to DOM? HM!


  render() {


    const { navigation } = this.props;

    const setPage = (_page) => {
      if (_page < 0) {
        _page = 0;
      }
      this.setState({ page: _page })
    }

    

    //  TODO:  Update the name lolhaha
    const { entitys, loaded, filteredEntities } = this.state;

    return (
      <PageLoader loading={!loaded}>
        <Page style={{ marginHorizontal: 20 }}>
          <ScrollView showsVerticalScrollIndicator={false}>

            {/* TODO:  I'm adding a 'Filter' here, but I hate it... I want the UIs to be built automatically...  MAYBE this is HALIA hmm... the idea is... we add a "Filter" and the idea is we have ANOTHER system which does the COUPLING HM!  Then it's lots of mappign mM!!  FOR NOW, let's build it expliclty,a nd then we can work on injecting it, and THEN we can work on EXPLODING it and injectinfg into lots of thigns hm! */}
            {/* CONSIDER:  We WILL want to filter the entities using the exisitng systems hmm... damnit..  There WILL be overlap hm...  Let's start with PAGING though ugh...  THEN system */}
            {/* <FilterHandler entitiesPlugin={this.props.entityPlugin} instances={ entitys } setInstances={(filteredEntities) => this.setState({ filteredEntities })} /> */}
            <GalleryButton title='Next' onPress={() => setPage(this.state.page + 1)} />
            <GalleryButton title='Previous' onPress={() => setPage(this.state.page - 1)} />

            {/* TODO:  SUpport SEARCH!!! */}
            {/* TODO:  Support CONTEXTUALIXATION!  Need to be able to contetualize my entiteis! */}

            {/* TODO:  Register the systems */}

            <Picker style={{ zIndex: 1000 }} value={ this.state.systemId } items={this.props.entityPlugin.entityService.services.map(service => ({ label: service.name, value: service.id }))} onChange={ systemId => this.setState({ ...this.state, systemId }) } />
            {/* CONSIDER:  AHH!!!  THIS API ... WHAT if I want to mess with an internal thing like hmm... link into these events? hmm...something is bothering meee... Why not just have the API pluggable and hm... AH!  API iteslf is compsed hmm... */}
            <FlatGrid itemDimension={250} itemContainerStyle={{ height: 250 }} renderItem={(({ item: entity }) => <EntityWidget scope={this.props.scope} entityPlugin={this.props.entityPlugin} entity={entity.payload} />)} data={entitys} />
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedEntityList = (props: any) => <EntityListBase {...props} />
export const EntityList: any = InjectedEntityList;