export const capitalizeAllWords = (text: string): string => {
  const words = text.split(' ');
  const capitalizedWords = words.map((word: string) => capitalizeFirstWord(word));
  const reconstructedText = capitalizedWords.join(' ');
  return reconstructedText;
};

export const capitalizeFirstWord = (text: string): string => {
  //  REFERENCE:  https://paulund.co.uk/how-to-capitalize-the-first-letter-of-a-string-in-javascript
  return text.charAt(0).toUpperCase().concat(text.slice(1));
};

export const getShortName = (name: string): string => {
  const names = name.split(':');
  if (names.length <= 0) { throw new Error('An error occurred while attempting to retrieve the short name.'); }
  return names[names.length - 1];
};
