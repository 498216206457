
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { HaborNounService } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { EntityPlugin } from "../entity-plugin";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { HessiaImage } from "./image-list";
import { ImageApp } from "./image-app";

export const ImagePluginContext = React.createContext<ImagePlugin | undefined>(undefined);

export const useImagePlugin = () => {
  return React.useContext(ImagePluginContext);
}

export class ImagePlugin extends CorePluginClass {

  public imageService!: HaborNounService<HessiaImage>;
  public serviceName = "image";

  public static details = {
    name: "Image",
    description: "Image Primitive System",
    dependencies: [HessiaPlugin.details.id, EntityPlugin.details.id, SystemPlugin.details.id, AuthPlugin.details.id, HaborPlugin.details.id],
    id: "image"
  }


  public install = async (program: Program, { habor, hessia, pEntities, system, authPlugin }: { habor: HaborPlugin, authPlugin: AuthPlugin, hessia: HessiaPlugin, pEntities: EntityPlugin, system: SystemPlugin }) => {


    this.imageService = new HaborNounService<HessiaImage>(this.serviceName, habor.haborSDK);
    await this.imageService.init(authPlugin.token);

    // pEntities.registerEntityListItemRenderer({
    //   renderer: async (entity: NounServiceInstanceInternal<Entity>, api: EntityListItemAPI) => {
    //     // const image = await this.imageService.retrieveByEntityId(entity.id);

    //     let imageUri = `data:image/jpg;base64,${image?.payload.value}`;
    //     if (image) {
    //       api.addComponent(() => (
    //         <ImageBackground source={{ uri: imageUri }} resizeMode="contain" style={{ flex: 1 }} />
    //       ));
    //     }},
    //     id: 'image-renderer',
    //     name: "Image Renderer"
    // });

    hessia.registerAppNavHOC(({ children }) => {
      return <ImagePluginContext.Provider value={this}>
        {children}
      </ImagePluginContext.Provider>
    });

    system.registerPrimitiveSystem({
      id: 'image-system',
      name: "Image",
      description: "Image Primitive System",
      labels: ["primitive"],
      component: () => <ImageApp imagePlugin={this} />,
      menuItem: {
        icon: { name: "image", type: "material-community" },
        backgroundColor: "#ebb031",
        iconColor: "white"
      }
    });

    return this;
  }
}
