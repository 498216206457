import * as React from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';

export const BubbleListItem = ({ children, onPress, onLongPress, style }: { children?: any, onPress?: () => void, onLongPress?: () => void, style?: ViewStyle }) => {
  return (
    <TouchableOpacity onPress={ onPress } onLongPress={ onLongPress } style={{ flex: 1, backgroundColor: '#f2f2f2', borderRadius: 25, height: 50, minHeight: 50, maxHeight: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', ...style }}>
      { children }
    </TouchableOpacity>
  );
}
