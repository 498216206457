import { deserializeSDT, SDTObject } from 'habor-sdk';
import { HaborComponent, HaborComponentContext, HaborDeferredValue, HaborElementDeclaration, HaborScope, resolveDeferredValue } from 'habor-sdk';
import * as React from 'react';
import { FrameworkProps, PrimitiveProps } from './habor-component-lib';

//  TODO-IMPORTANT:  Instead of getting components from "Program", THIS IS the Plugin which should have these thing registered!  OR, other Plugins should register a REFERENCE?  MAYBE that can happen automatically?  We Plugin can EXPORT a particular type, and it becomes automatically available???
import { Text } from 'react-native';
import { getAppliedSettings } from '../../settings-system';
import { HessiaPlugin } from '../../hessia-plugin';

//  NOTE:  componentId is the path to the current HaborComponent in the JSON structure.

/**
 * Renders a HaborElementDeclaration which specifies a component, props to obtain from the parent context, and children elements.  Here, we render the elements defined in the parent component.  This can be PrimitiveComponents OR HaborComponents.
 * Returns a Promise which resolves to a ReactElement.
 */
export const renderHaborElementDeclaration = async (element: HaborElementDeclaration, scope: HaborScope, frameworkProps: FrameworkProps, hessiaPlugin: HessiaPlugin): Promise<React.ReactElement<any>> => {

  //  Unpack Framework Props
  const { componentId, config, context, componentContext } = frameworkProps;
  const { token, user } = context;

  //  Render the Children
  const { children } = element;
  const renderedChildren: React.ReactElement<any>[] = [];
  let childIndex = 0;
  for(const child of children) {
    const childId = `${componentId}::${childIndex}`;
    
    //  Build the new Component Context (for the Settings System)
    //  TODO-IMPORTANT:  The component system as a whole is getting MESSY.  There MUST be some repetitive stuff here we can pull out!
    // const childComponentContext: HaborComponentContext = { parent: componentContext, element: child };
    const childFrameworkProps: FrameworkProps = { ...frameworkProps, componentId: childId, componentContext }
    const childElement = await renderHaborElementDeclaration(child, scope, childFrameworkProps, hessiaPlugin);
    renderedChildren.push(childElement);
    childIndex++;
  }
  
  // Resolve Element Props from Component Scope
  // TODO:  Validate Scope variables match expected Prop type.
  const { props } = element;
  const realizedProps: { [name: string]: any } = {};
  Object.keys(props).forEach(propName => {
    const deferredValue: HaborDeferredValue = props[propName];
    const value = resolveDeferredValue(deferredValue, scope);
    realizedProps[propName] = value.value;
  });

  //  Get the Child Context
  const childComponentContext: HaborComponentContext = { parent: componentContext, props: realizedProps, name: element.name };

  //  Get the Settings
  const childSettings = getAppliedSettings(childComponentContext);

  //  Get the Component
  const { name } = element;
  // throw "The habor-component-core, renderHaborElementDeclaration has missing dependencies.  You can fix this by somehow obtaining a reference to the Hessia plugin.";

  const PrimitiveComponent = hessiaPlugin.getPrimitiveComponent(name);
  const HaborComponent = hessiaPlugin.getHaborComponent(name);

  //  Handle PrimitiveComponents
  if (PrimitiveComponent !== undefined) {
    console.log(`Rendering Primitive Component: '${ name }'`);
    return <PrimitiveComponent userProps={ realizedProps } frameworkProps={{ hessiaPlugin: hessiaPlugin , children: renderedChildren, config, componentId, context, componentContext: childComponentContext, settings: childSettings }} />

  //  Handle HaborComponent
  } else if (HaborComponent !== undefined) {
    return renderHaborComponent(HaborComponent, { userProps: realizedProps, frameworkProps: { hessiaPlugin: hessiaPlugin, config, componentId, children, context, componentContext: childComponentContext, settings: childSettings }}, hessiaPlugin);

  //  Handle Unknown Component
  } else {
    console.warn(`No Primitive Components or Habor Components found with the given name: ${name}`);
    return <Text>{ `No Primitive Components or Habor Components found with the given name: ${name}` }</Text>
    //  TODO:  LOG this for the user to see too ugh!
  }
}

/**
 * Initializes a component by resolving sources, converting props to a Scope, and rendering the element tree.  This function is NOT concerned with children.  It's basically doing the steps required to initialize a new component like "componentWillMount", etc...
 * Returns a Promise which resolves to a ReactElement.  
 */
export const renderHaborComponent = async (component: HaborComponent, primitiveProps: PrimitiveProps, hessiaPlugin: HessiaPlugin): Promise<React.ReactElement<any>> => {

  //  Unpack Props
  const { frameworkProps: { config, componentId, context }, userProps } = primitiveProps;
  const { token, user } = context;

  //  Validate the Props
  //  TODO-IMPORTANT:  Make sure to properly handle the error thrown here!
  //  CONSIDER:  ANy encoding can be associated here.  Don't necessarily need to check the type.
  const defaultPropsSchema: SDTObject = { type: "object", required: false, extensible: false };
  const { propsSchema = defaultPropsSchema } = component;
  const propsDT = await deserializeSDT(propsSchema);
  try {
    await propsDT.validate(userProps);
  } catch (err) {
    console.log(`Prop validation failed for the '${ component.name }' component: ${ err }`);
    throw err;  //  TODO-TECH-DEBT:  Make sure we have REASONABLE errors, AND that when an error is thrown, it's being thrown WITH call-stack CONTEXT!!!  Otherwise, we ONLY see the BASE-LEVEL error and miss ALL the intermediate context!!!!
  }
  

  //  Create the Scope
  //  TODO:  Use real types, NOT "any".
  const scope: HaborScope = { value: { props: userProps }, types: { state: { type: "any" }, props: { type: "any" } } };

  //  Render the Element
  const renderedElement = await renderHaborElementDeclaration(component.element, scope, primitiveProps.frameworkProps, hessiaPlugin);

  return renderedElement;
};
