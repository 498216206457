import { SDTObject } from "davel";
import * as React from "react";

export enum RootRoute {
  Systems = "Systems",
  SystemMenu = "SystemMenu",
  SystemSelector = "SystemSelector",
  AlertBox = "AlertBox"
}

export interface MenuItem {
  icon: { type: string, name: string };
  backgroundColor: string;
  iconColor: string;
}

export interface System {
  id: string;
  name: string;
  description: string;
  component: any;
  headerRight?: typeof React.Component | React.FunctionComponent;
  menuItem: MenuItem;
  onPress?: () => void;
  labels: string[];  //  CONSIDER:  We COULD add this with another INTERNAL system or other primitive system? Hmm  FOR NOW, let's just do it manually.
}

//  NOTE:  That even here.. we can infinltley differentiate and I DO want to be able to sub-class my types ugh!  JUST like Tcomb... MAY just want to use TComb? Hmmm..

export const SystemSDT: SDTObject = {
  type: 'object',
  properties: {
    id: { type: "keyword" },
    name: { type: "keyword" },
    description: { type: "text" },
    component: { type: "text" },
    // headerRight: { type: "text" },
    // menuItem: { type: "object" },
    // onPress: { type: "text" },
    // labels: { type: "array", itemType: "keyword" },
  },
  extensible: true
}