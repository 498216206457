import { SDTObject, SearchTerm } from 'habor-sdk';
import { AllTMSDTs, FrameworkContext, HaborComponent, HaborComponentContext, HaborIconSelection, HDTRelationshipValue, InstanceInternal, NounInternal } from 'habor-sdk';
import * as _ from 'lodash';
const moment = require("moment");
import * as React from 'react';
import { Text, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { haborSDK } from '../hessia-plugin/config';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { nestNouns, NounNode } from '../model-plugin/entity/entity-list';
import { medSpacer, smallSpacer } from '../../../packages/kelp-bar/styles';
import { Activity, ActivityEvent } from '../model-plugins/activity-plugin/activity-types';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";

//  NOTE:  We can probably integrate this functionality with "nestNouns" so we don't need to generate ALL the groups, just the one we want.
export const getNounNodeById = (nounId: string, nounNodes: NounNode[]): NounNode | undefined => {
  for (const currentNode of nounNodes) {

    //  Check the Current Node
    if (currentNode.noun.id == nounId) {
      return currentNode;
    }

    //  Check the Current Node's Children
    const childNode = getNounNodeById(nounId, currentNode.children);
    if (childNode) { return childNode; }
  }
}

export const flattenNounNode = (nounNode: NounNode, nouns: NounInternal[] = []) => {
  nouns.push(nounNode.noun);
  for (const childNode of nounNode.children) {
    flattenNounNode(childNode, nouns);
  }
  return nouns;
}

//  TODO:  Habor should probably be responsible for nesting relationships on an object.  FOR NOW, let's do it here!
//  NOTE:  Also, we'd need a separate interface for both Noun / Instance.  Instead, we SHOULD have just ONE Object interface that we can use to do this... right?  FOR NOW, let's just do it to instances!
//  NOTE:  Consider GENERALIZING this pattern to work for ALL property types!  We MAY want to perform some form of "Realization" at runtime??
//  TODO:  We do NOT want to do this ONE at a time!  AT LEAST bundle this together so we can reduce the query count!
//  NOTE:  In the Hessia Component Builder, we DON'T necessarily need to have the value here... ?  We just need to know we CAN get the value?  

/**
 * Resolves all "relationships" on a Class Instance and adds the values to the Class Instance.
 * NOTE:  It's NOT currently recursive on the children instances!
 * //  NOTE:  We MAY eventually wnt to generalize this, OR DEFER with an on-demand relationship reference or something?? Hmm
 * @param inst 
 * @param token 
 */
export const resolveRelationships_Temp = async (inst: InstanceInternal<any>, token: string) => {

  //  Get the Class
  let hClass: NounInternal;
  try {
    hClass = await haborSDK.retrieveNoun(inst.nounId, token);
  } catch (err) {
    throw (`Failed to retrieve the '${ inst.nounId }' Noun while resolving relationships for the '${ inst.id }' instance.\nFunction:  '${ resolveRelationships_Temp.name }'\nError:  ${ JSON.stringify(err) }`);
  }
  

  //  Define the New Instance
  const newInstPayload: any = {};

  //  Iterate the Properties
  //  NOTE:  We do NOT deal with "Remote Properties" at this point.
  for (const propName in hClass.properties) {
    const prop = hClass.properties[propName];
    if (!prop.type) { continue; }
    if (prop.type.type == "relationship") {

      //  Run for Each Instance
      const value: HDTRelationshipValue | undefined = inst.payload[propName];

      //  Guard Undefined
      if (value == undefined) { continue; }

      const { instanceIdList } = value;
      for (const instanceId of instanceIdList) {

        //  Get the Instance
        let linkedInst: InstanceInternal | undefined = undefined;
        try {
          linkedInst = await haborSDK.retrieveInstance(instanceId.nounId, instanceId.instanceId, token);
        } catch (err) {
          console.warn (`Failed to retrieve the '${ instanceId.instanceId }' Instance while resolving relationships for the '${ inst.id }' instance.\nFunction:  '${ resolveRelationships_Temp.name }'\nError:  ${ JSON.stringify(err) }`);
        }

        if (linkedInst == undefined) {
          continue;
        }

        //  TODO:  This should be checked before the loop if we maintaian this dataa model.. BUT I think we should make different types of "Reltionship" fo this!? HM!
        if (prop.type.allowMany) {

          //  Add to an Array
          if (!newInstPayload[propName]) {
            newInstPayload[propName] = [linkedInst];
          } else {
            newInstPayload[propName].push(linkedInst);
          }
          
        } else {

          //  Add as a Single Element
          newInstPayload[propName] = linkedInst;
        }
      }
    }
  }

  return { ...inst, payload: { ...inst.payload, ...newInstPayload } };
}

//  TODO:  Obtaining the instances of sub-classes SHOULD be an exposed Habor feature!  When we query with the "Class" system.  We should also be able to get JUST "own" instances of non-abstract Classes.
//  TODO: INstead of getting ALL Nouns, we should get the ONE, and PERHAPS we should have STORED the nouns with associations to it? hMm MAYBE we can keep queying for NOUNS using its dependency list.  Hmmm.  I wonder if we can build a system for that type of recursive query?
export async function getIntancesByClass_Temp<T = any> (nounId: string, token: string, search?: SearchTerm<T>) {

  //  Get all Nouns
  const nouns = await haborSDK.searchNouns(token, {});
  console.log("All Nouns");
  console.log(nouns);

  //  Group the Nouns
  const nounNodes = await nestNouns(nouns, token);
  console.log("Noun Nodes");
  console.log(nounNodes);

  //  Get our Group
  const nounNode =  getNounNodeById(nounId, nounNodes);

  //  Guard Undefined
  if (!nounNode) {
    throw new Error(`No noun found with ID: ${ nounId }`);
  }

  //  Get the Nouns in he Group
  const groupNouns = flattenNounNode(nounNode);

  //  Get the Instances
  const instances: InstanceInternal<any>[] = [];
  //  TODO-IMPORTANT:  This query sequence is CRAZY...  It will take a LONG time and a LOT of data.
  for (const noun of groupNouns) {
    try {
      const instLit = await haborSDK.searchInstances(token, { nounId: noun.id, search });
      instances.push(...instLit);  
    } catch (err) {
      console.warn(`Error while searching for instance of the '${ noun.id }' Noun.\nFunction:  '${ nestNouns.name }'\nError:  ${ JSON.stringify(err) }`);
    }
    
  }

  return instances;
}


// export interface TimelineSectionProps {
//   event: InstanceInternal<any>;
// }
// interface TimelineSectionState {}
// class TimelineSectionBase extends React.Component<TimelineSectionProps, TimelineSectionState> {
//   constructor(props: TimelineSectionProps) {
//     super(props);
//     this.state = {}
//   }
//   public render = () => {

//     //  Unpack
//     const { event } = this.props;

//     return (
//       <View style={{ borderLeftColor: 'gray' }}>
//         <CircleFilled />
//         <Text>{ event.payload.startTime }</Text>
//         <Text>{ event.payload.endTime }</Text>
//         <Text>{ event.payload.isPending }</Text>
//       </View>
//     );
//   }
// }
// export const TimelineSection = TimelineSectionBase

export const IconPartialBox = ({ icon, color }: { icon: HaborIconSelection, color: string }) => {
  return (
    <View style={{ flex: 0, backgroundColor: color, borderRadius: 15, width: 45, height: 45, alignItems: 'center', justifyContent: 'center' }}>
      <KelpIcon name={ icon.name } type={ icon.type } color="white" size={ 25 } />
    </View>
  );
}

//  IDEA:  We can support SEVERAL 
interface WorkspaceTimelineProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
}

interface WorkspaceTimelineState {
  events: InstanceInternal<any>[];
}

export interface ActiveEventsProps {
  events: InstanceInternal<any>[];  //  NOTE:  When we're in the HaborEditor, we WILL have the type info from the backend
}
interface ActiveEventsState {}
class ActiveEvents extends React.Component<ActiveEventsProps, ActiveEventsState> {
  constructor(props: ActiveEventsProps) {
    super(props);
    this.state = {}
  }
  public render = () => {

    //  Unpack
    const { events } = this.props;

    //  Filter
    //  NOTE:  We COULD filter before passing to the module, OR we can make it the responsibility of the module.  If we DO pass, then we should still check to make sure the data is valid.
    const activeEvents = events.filter(event => event.payload.isPending);

    //  Active Event View
    //  NOTE:  In Hessia, this would ALSO be a separate component, which we attach as a setting to the "Splitter" / "Broker" / "Router" block?  OR, just a "Grid" block, like we do here!
    const ActiveEventView = ({ event }: { event: InstanceInternal<any> }) => {

      //  Get Elapsed Time
      //  NOTE:  In Hessia, it's POSSIBLE that this is a custom block that comes with the Event Plugin?  It's really just a mathematical function which computes the elaped time object, which can THEN be passed to a "Date" block to be parsed with provided options into a particular format??  Hmmm... we MAY want to pass to a string formatter block and THEN just pipe to a standard text view?  Hmmm...
      //         I DO like the idea of showing the user the "Code" that goes along with these Hessia UIs IF they really want to see it!  I SUPPOSE they could write it in an editor and then include it.  BUT it's simpler than React / JS in a lot of ways... but also damn similar in some!  As I keep saying, they MAY converge at some point.
      const elapsedTime = new Date().getTime() - new Date(event.payload.startTime).getTime();
      const duration = moment.duration(elapsedTime);

      //  Render the Elapsed String
      const elapsedString = duration.humanize();

      return (
        <View style={{ backgroundColor: "#e9e9e9", borderRadius: 15, padding: smallSpacer, display: "flex", flexDirection: "row", margin: 0 }}>
          <IconPartialBox icon={{ name: "material-community", type: "run" }} color="#e14e85" />
          <View style={{ width: smallSpacer }} />
          <View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Text style={{ fontFamily: 'Poppins-Bold', fontSize: 15, color: "#717171" }} >{ event.payload.activities[0].payload.name }</Text>
              <Text style={{ fontFamily: 'Poppins-Bold', fontSize: 13, color: "#a2a2a2" }} >{ elapsedString }</Text>
            </View>
        </View>
      );
    }

    return (
      <View>
        <Text style={{ fontFamily: "Poppins-Bold", letterSpacing: 1, color: "#a2a2a2", fontSize: 15 }}>ACTIVE</Text>
        <FlatGrid spacing={ 10 } itemContainerStyle={{ padding: 0, borderWidth: 0 }} style={{ marginLeft: -10, marginRight: -10, padding: 0, borderWidth: 0 }} data={ activeEvents } itemDimension={ 150 } renderItem={ ({ item: event }) => <ActiveEventView event={ event } /> } />
      </View>
    );
  }
}

  //  Event History View
  //  TODO:  In Hessia this SHOULD be generalized to a "TableView".  We can take in "items" and map them to rows.  That's the simple TableView.  We can make it more complex with Plugin / Wrappers (like sections / groups) in the future.
  //         This means we should accept a set of items, and perhaps a "map" for each one?  MAYBE a "TableMap" object which has a Name + Path for each field?  OR, maybe we pass an array of arrays, and include an array of headers?  I like the idea of passing in an object for the headers, an array.  Ah... BUT, we wan the associated object?  Hmmm... we want the user to be able to re-order fields, we want the user to add custom actions, etc!  HOW can we do all that?  I suggest we pass in a "FieldMap" to start, which has a map from name to path in the incoming object.  How will we model this in Hessia though?  The TableView is a component.  We COULD pass in sub-components which meet the child requirement.  Or, we pass the pieces we need.  In this case, we pass a "FieldMap" object.  It's an array of fields, where each field includes a Name, Description, and Path.  When we define the table, we ALSO pass a Type template variable.  The "path" MUST refer to a valid value within the specified type?
  //  CONCERN:  XCode / ObjectiveC uses "Delegates" to get the table rows.  I BELIEVE the idea is to allow the table to decide how much it needs?  Hmmm... we can consider doing something like this later, OR, even attaching the pipes, and STILL having the table select when it's reasonable to query, BUT with a DECLARATIVE, INTUITIVE interface!
  //            In other words, INSTEAD of passing data directly, we connect a "PIPE" which has a particular TYPE and an interface that the internal API can use??  FOR NOW, let's go ahead and pass directly.
  
  //  FOR NOW, let's just do it with a schema.  FOR NOW, we'll use a TS schema.  In the future, we should use a Davel Schema which supports Name / Description?  MAYBE we shoul should use a Noun?? Hmmm....

  // //  
  // //  Template System
  // //

  // //  NOTE:  Unlike SDTObjects, Templates support inheritance.
  // //  CONSIDER:  Maybe it's possible to collapse Template, SDTObject, Noun, and JUST use SDTObject?  We COULD add Inheritance as a Pluggable feature to that system?



  // export interface NamedObject {
  //   name: string;
  //   description: string;
  // }

  // export interface Template {

  //   name: string;
  //   description: string;
  // }

  //  TODO:  Fill this out!
  const eventSchema: SDTObject = {
    type: "object",
    extensible: true
  }

  //  FOR NOW:  Have the user attach a HeaderComponent AND a RowComponent to generate those pieces!  Simple... This means, the Table component doesn't need to know how to generate the rows or the header.  In the future, we MAY want to provide a more plug-and-play table version!  How will this work when sorting / filtering?  For filtering, we can pass the filter down to the RowComponent and it can make the decision.  For sort, maybe we provide a function?  Once we have a more generic component, IT can be in charge of the rows and types?  MAYBE we'll still have overrides?  Hmmm.... FOR NOW, let's just get it started.  Ideally we'd have a LOT of customizable control over the table?

  //  QUESTION:  SHOULD we pass an explicit schema, OR genreate a type from the passed objects?  We may need the type when attaching a RowGenerator component?  FOR NOW, let's make it explicit.

  //  IDEA:  Make the EMBEDDED renderers look cool AND like top-level things in Hessia!  It seems weird to me that they're clumped with other props in React... Maybe it's not though?  Idk... BUT, I like the idea so let's follow it and see where it leads us.

  //  NOTE!:  Sometimes, I MAY want to generate a special cell whcih combines values from SEVERAL fields.  IF I use a standard renderer, HOW can I include a custom renderer for a particuler SET of the input?  Ah... Well, I can first MAP the original object into a new one, where EACH key corresponds to a column.  THEN, I can optionally specify a custom renderer for EACH key!  I THINK I might like this approach.  It keeps ALL rows the same, BUT we can modulate the rows with the custom renderer!  BUT, this doesn't mean that we need a custom renderer for the entire row / header.  THEN, for custom types, MAYBE I include a comparator function?

  //  THOUGHT:  OK, I SUPPOSE, we CAN have a special validator / Rule / thing, which maps the SDTObject (Type), to each of our "FieldSetting" objects... We CAN use a system to convert that SDT to a NEW SDT!!!  JUST like we do in TS!  This is the thing that will determine the Name, Description, and possibly Renderer override, and OTHER field settings?

  //  NOTE:  This SHOULD EXTEND the "FieldSetting" object in SOME way.  Maybe use templates??
  //  QUESTION:  How can we do this with a more generic pattern?
  const createFieldSettingSchema = (type: AllTMSDTs): SDTObject => {
    return {
      type: "object",
      extensible: false,
      properties: {
        name: { type: "keyword", required: true },
        description: { type: "text", required: false },
        renderer: { type: "any", required: false }
        // renderer:  { type: "" }  //  HERE is where we should specify a HaborComponet WITH A "value" Prop matching schema!!!
      }
    }
  };

  // FOR NOW, let's table that and just push on (no pun intended, but HA, TABLE IT!)  COOL! ("COOL!"" was regarding the createFieldSetting thing, not the pun, BUT the pun is COOL! too!)

  export interface ColumnSetting {
    name: string;
    description?: string;
    renderer?: any;
    //  CONSIDER:  Consider support for filters / ROW ordering!  MAYBE each column can also emit it's own special features for filtering, searching, and MAYBE even other features related to that column (and the other data in the table?)
    //  CONSIDER:  Support an "order" flag to determine COLUMN order?
  }

  //  TODO:  TS template type annotation?
  export interface TableSettings {
    [name: string]: ColumnSetting
  }


  //  NOTE:  FOR NOW, we required that EVERY property in the passed object 
  const createTableSettingsSchema = (itemSchema: SDTObject): SDTObject => {

    //  Make new Properties
    //  NOTE:  I KIND of like being able to directly manipulate properties like this!  Similar to Typescript, but with JS as an interface into the Type system instead of a custom "Type" language.
    //  NOTE:  IF I'm developing in TS, then it's nice to have the types relevant to the current piece of code!  IF we are in TS, then we probably SHOULD have those types!  HOWEVER, at times, we'll be in TS and other times in Hessia.  When in Hessia, we DON'T currently use TS types, and don't plan to.
    const tableSettingsProps = _.mapValues(itemSchema.properties, (prop) => ({ name: prop.name, type: createFieldSettingSchema(prop.type) }));

    return {
      type: "object",
      extensible: false,
      properties: tableSettingsProps
    }
  }

  //  NOTE / IDEA:  Would be REALLY cool if we could now use a PLUGIN to edit this component?  MAYBE, by adding / wrapping so it supports "Groups"?  Maybe "Group By" option?  OR, we can make this a feature of the Table itself.
  function TableComponent<T extends any>({ schema, items, tableSettings }: { schema: SDTObject, items: any[], tableSettings: TableSettings }) {

    return (
      <View style={{ backgroundColor: "#e9e9e9", borderRadius: 15, padding: medSpacer, paddingTop: 0, display: "flex", flexDirection: "column", margin: 0 }}>

        {/* Render Header */}
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: medSpacer, paddingBottom: medSpacer }}>
          {
            Object.keys(tableSettings).map(key => (
              <Text style={{ flex: 1, fontFamily: "Poppins-Bold", letterSpacing: 0, color: "#717171", fontSize: 14 }}>{ tableSettings[key].name }</Text>
            ))
          }
        </View>
        {
          // Render Rows
          items.map((item: any) => (
            <View style={{ display: 'flex', flexDirection: 'row', borderTopColor: '#d5d5d5', borderTopWidth: 2, paddingTop: smallSpacer, paddingBottom: smallSpacer, alignItems: 'center' }}>
              {
                // Render Columns
                Object.keys(item).map((key: string) => (
                  tableSettings[key].renderer ?
                    tableSettings[key].renderer({ item: item[key] }) : 
                    <Text style={{ flex: 1, fontFamily: "Poppins-SemiBold", letterSpacing: 0, color: "#717171", fontSize: 15 }}>{ item[key] }</Text>
                ))
              }
            </View>
          ))
        }

      </View>
    );
  }

export class WorkspaceTimeline extends React.Component<WorkspaceTimelineProps, WorkspaceTimelineState> {

  constructor(props: any) {
    super(props);
    this.state = {
      events: []
    }
  }

  public componentDidMount = async () => {

    //  Unpack
    const { frameworkContext: { token } } = this.props;

    //  Get Events
    //  TODO:  Do NOT scope to activity.

    console.log("About to get Activity Events");
    //  TODO:  Do NOT pass elastic's Date API through directly!  Instead, WRAP it, OR make our OWN which we MAP to Elastic's
    //  REFERENCE:  https://stackoverflow.com/questions/31593445/moment-js-get-yesterday-time-range-from-midnight-to-midnight
    const events = await getIntancesByClass_Temp<InstanceInternal<ActivityEvent>>("activiy-event", token, { range: { payload: { startTime: { gte: moment().startOf('day').toISOString(), lt: moment().endOf('day').toISOString() } } } });

    console.log("Event Check");
    console.log(events);

    //  Resolve References
    const resolvedEvents: InstanceInternal<any>[] = [];
    for (const event of events) {
      const resolvedEvent = await resolveRelationships_Temp(event, token);
      resolvedEvents.push(resolvedEvent);
    }

    console.log("Resolved References");
    console.log(JSON.stringify(resolvedEvents));

    //  Update the State
    //  REMINDER:  Keep in mind that a LOT of what you're doing now is to prototype and get a functional version of the app.  SO, remember to consider a COMPLETE overhaul if neccessary, of things like the caching system and queries, etc... it MAY look TOTALLY different a few months from now!!  That way, it's more general and users have freedom to operate and make tools / process flows that make sene for their business.
    this.setState({ events: resolvedEvents });
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext } = this.props;
    const { frameworkContext: { changePage } } = this.props;
    const { events } = this.state;

    //  Get Closed Events
    const closedEvents = events.filter(event => !event.payload.isPending && !!event.payload.activities[0]);

    //  Convert to Table Format
    const closedEventTableItems = closedEvents.map(event => ({
      icon: {
        icon: {
          name: "run",
          type: "material-community",
        },
        color: "#e14e85"
      },
      name: event.payload.activities[0].payload.name,
      startTime: event.payload.startTime,
      endTime: event.payload.endTime
    }));

    //  Define Table Settings
    const eventTableSettings: TableSettings =  {
      icon: {
        name: "Icon",
        //  TODO:  Pull from the activities list
        renderer: ({ item }: { item: any }) => (
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 0 }}>
          <IconPartialBox icon={ item.icon } color={ item.color } />
          <View style={{ width: smallSpacer }} />
        </View>
        )
      },
      name: {
        name: "Name",
        //  TODO:  Pull from the activities list
        // renderer: ({ item }: { item: any }) => (<Text>CUSTOM NAME</Text>)
      },
      startTime: {
        name: "Start",
        renderer: ({ item }: { item: any }) => (
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontFamily: "Poppins-Medium", letterSpacing: 0, color: "#717171", fontSize: 13 }}>{ moment(item).format("h:mm A") }</Text>
            <Text style={{ fontFamily: "Poppins-SemiBold", letterSpacing: 0, color: "#9c9c9c", fontSize: 10 }}>{ moment(item).calendar(
              undefined, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'ddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] ddd',
                sameElse: 'DD/MM/YYYY'
              }) }</Text>
          </View>
        )
      },
      endTime: {
        name: "End",
        renderer: ({ item }: { item: any }) => (
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontFamily: "Poppins-Medium", letterSpacing: 0, color: "#717171", fontSize: 13 }}>{ moment(item).format("h:mm A") }</Text>
            <Text style={{ fontFamily: "Poppins-SemiBold", letterSpacing: 0, color: "#9c9c9c", fontSize: 10 }}>{ moment(item).calendar(
              undefined, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'ddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] ddd',
                sameElse: 'DD/MM/YYYY'
              }) }</Text>
          </View>
        )
      }
    };

    //  IDEA:  I MAY want to use the "ContainerList" for displaying Events, BUT I'd need to MAP the objects to a set of props suitable for that renderer!!  I think that's good!!  CURRENTLY, we have renderers somewhat COUPLED with a type.  Instead, we can show the user a pretty mapping interface.
    return (
      // <WorkspaceContainer { ...this.props } title="Timeline" buttonStyle={ WorkspaceButtonStyle.Configure } scroll={ false }>
      //  NOTE:  ALL Routing is FLAT, just with passed CONTEXT.
      <View style={{ flex: 1, padding: medSpacer }}>
        {/* { events.map(event => <TimelineSection event={ event } />) } */}
        {/* <ContainerList componentContext={ componentContext } frameworkContext={ frameworkContext } context={{ name: "activity-page" }} items={ events } onItemPress={ (item) => { changePage({ pageName: "Instance Creator", props: { nounId: { nounId: "activiy-event", type: EntityType.Noun }, initial: { activities: { instanceIdList: [ { nounId: item.nounId, instanceId: item.id, type: EntityType.Instance } ] } } } }) } }  /> */}
        <ActiveEvents events={ events } />
        <TableComponent items={ closedEventTableItems } schema={ eventSchema } tableSettings={ eventTableSettings } />
      </View>
    );
  }
}

//
//  Timeline Primitive Component
//
export interface TimelineHaborPrimitiveProps extends PrimitiveProps { }
export const TimelineHaborPrimitive = ({ userProps, frameworkProps }: TimelineHaborPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <WorkspaceTimeline frameworkContext={context} componentContext={componentContext} />
    </HaborContainer>
  );
};

// registerPrimitiveHaborComponent('TimelineHaborPrimitive', TimelineHaborPrimitive);

export const TimelineHaborComponent: HaborComponent = {
  name: "TimelineHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "TimelineHaborPrimitive",
    props: {},
    children: []
  }
};

// registerHaborComponent(TimelineHaborComponent);

// //  Make the Page
// export const dashboardPage: Page = {
//   name: "Timeline",
//   element: { name: 'TimelineHaborComponent', children: [], props: {  } }
// };

