import { NounInternal } from 'habor-sdk';
import * as React from 'react';
import { SectionList, Text, View } from 'react-native';
import { getSystemDependencies, HaborReactComponent } from '../habor-plugin/habor-utils';
import { AggregatePlugin, getPluginElements, SystemMap } from './plugin/plugin-tools';
import { Card } from '../../../packages/kelp-bar/card';
import { useNavigation, NavigationProp } from '@react-navigation/native';

//  REFERENCE:  http://reactnative.dev/docs/sectionlist
const Item = ({ title, onPress }: any) => (
  <Card onPress={ onPress }>
    <Text>{title}</Text>
  </Card>
);

export interface SystemMenuProps {
  navigation: NavigationProp<any>;
}
interface SystemMenuState {
  elements: SystemMap;
}
class SystemMenuBase extends HaborReactComponent<SystemMenuProps, SystemMenuState> {
  constructor(props: SystemMenuProps) {
    super(props);
    this.state = {
      elements: {}
    }
  }

  public componentDidMount = async () => {

    //  Unpack
    const { plugin, token } = this.context.frameworkContext;
    if (!plugin) { throw `Cannot view the System Menu when no system was selected.` }
    console.log("Test");

    //  Get Dependencies
    const dependencies = await getSystemDependencies(plugin, token);
    console.log(dependencies);

    //  Get Dependency Nouns
    //  TODO:  Again, we SHOULD be able to do this AUTOMATICALLY!  We should be able to DECLARE what we want and have the backend give it to us!  PERHAPS this IS like GraphQL, BUT where the Systems can inject their OWN stuff?? HM!
    const elements = await getPluginElements(dependencies, token);

    this.setState({ elements })
  }

  //  TODO:  MAYBE make a re-usable GROUP UI?  Hmm... 

  public render = () => {
    const { elements } = this.state;

    //  Map to Table Interface
    const tableSections = Object.keys(elements).map(systemId => {
      const aggPlugin: AggregatePlugin = elements[systemId];
      return {
        title: aggPlugin.plugin.payload.name,
        data: aggPlugin.nouns
      };
    })

    return (
      <SectionList
          sections={ tableSections }
          // keyExtractor={(item, index) => item + index}
          //  CONSIDER:  A "Managed" navigation system!@?  HMM!!  It will have injection support / plugin support and custom methods added by systems to do things?  PLUS ways to TRIGGER experiences and log context / trigger SEGUES?? HMM!?
          //  CONSIDER:  MAYBE for Intellisense, we should START with ONLY the list of things that CAN be applied CURRENNTLY.  Then, if we want to make a MAPPING we want to be EXPLICLT about it?? Hmmm...
          renderItem={({ item }: { item: NounInternal }) => <Item onPress={ () => {
            alert("SHOULD GO TO HAPP INSTANCE BUILDER");
            // this.props.navigation.navigate(HAppRoute.InstanceBuilder, { noun: item, systemEditMode: true })
          }
          } title={item.name} />}
          renderSectionHeader={({ section: { title } }) => (
            <Text>{title}</Text>
          )}
        />
    );
  }
}

export const SystemMenu = function(props: {}) {
  const navigation = useNavigation();
  return <SystemMenuBase {...props} navigation={navigation} />;
}
