import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { ImageBackground, TouchableOpacity, View } from 'react-native';
import { Paragraph } from '../constants';
import { Profile } from '../openapi';

export const ArtistCard = ({ artist }: { artist: Profile }) => {

  const navigation = useNavigation();

  //  TODO:  Ensure unique display name!

  return (
    <TouchableOpacity onPress={() => navigation.navigate("ArtistDetail" as never, { userId: artist.displayName || artist.username } as never)} style={{
      display: 'flex', borderRadius: 7, ...{
        shadowColor: 'black',
        shadowRadius: 30,
        shadowOpacity: 0.1,
        height: 400,
        shadowOffset: {
          width: 2,
          height: 2
        },
      }, overflow: 'hidden'
    }}>

      {/* Image */}
      <View style={{ borderRadius: 7, display: 'flex', height: 300, width: '100%', overflow: 'hidden', borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
        <ImageBackground style={{ height: '100%', width: '100%' }} resizeMode="cover" source={{ uri: artist?.avatar || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIQTRASmorWbvgwo1lwqYbsKUeWz7FLvnzaE6uDwG6TDmM_NYCMyhvn1bd5kmCVRlk4nU&usqp=CAU' }} />
      </View>

      {/* Identity */}
      <View style={{ padding: 20 }}>
        <Paragraph style={{ fontSize: 20, color: artist?.displayName ? '#333333' : "#aaaaaa" }}>{artist?.displayName || "No Name"}</Paragraph>
        <View style={{ height: 10 }} />
        <Paragraph style={{ fontSize: 13, color: artist?.description ? '#666666' : '#aaaaaa' }}>{artist?.description || "No Description"}</Paragraph>
      </View>

    </TouchableOpacity>
  );
}