import * as React from 'react';
import { FlatList, ActivityIndicator, Image, Pressable, ScrollView, Text, View, ViewProps } from 'react-native';
import { Icon } from 'react-native-elements';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { api } from "../bas-sdk";
import { GroupCard } from '../components/group-card';
import { GalleryHeader } from '../components/header';
import { HeroCard } from '../components/hero-card';
import { defaultBorderRadius, Header, niceShadow, Paragraph, SubHeader } from "../constants";
import { CenteredView, useSettings } from '../content-view';
import { useCart } from '../providers/cart.provider';
import { useSizes } from '../sizes-helper';
import { StripeCheckoutButton } from '../stripe';
import { usdFormatter } from '../utilities';
import { Layer } from './account';
var zipcodes = require('zipcodes');

export interface ShippingRate {
  id: string;
  name: string;
  rate: string;
  currency: string;
  minDeliveryDays: number;
  maxDeliveryDays: number;
}

export interface EstimateCosts {
  currency: string;
  subtotal: number;
  discount: number;
  shipping: number;
  digitization: number;
  additional_fee: number;
  fulfillment_fee: number;
  tax: number;
  vat: number;
  total: number;
}

export interface RetailCosts {
  currency: string;
  subtotal: number;
  discount: number;
  shipping: number;
  tax: number;
  vat: number;
  total: number;
}

export interface Estimate {
  costs: EstimateCosts;
  retailCosts: RetailCosts;
}

export const CheckoutWidget = (props: ViewProps) => {

  const [estimate, setEstimate] = React.useState<Estimate | undefined>(undefined);
  const cart = useCart();

  const loadEstimate = async () => {
    setLoading(true);
    const estimate: any = await api.workProductsControllerEstimate({
      body: {
        cart: cart.cartBare,
        // zip: zipRes.zip,
        // stateCode: zipRes.state
      } as any
    });

    setEstimate(estimate);
    setLoading(false);
  }

  // const [zip, setZip] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  //  TODO:  We use basically the SAME code in Stripe!  Refactor EVEN if it means building a hook / registration / plugin pattern.
  React.useEffect(() => {
    setLoading(true);
    setEstimate(undefined);
    if (!cart.isEmpty()) {
      loadEstimate();
    } else {
      setLoading(false);
    }
  }, [cart])

  const { isDesktop } = useSizes();

  //  CONSIDER:  I'm removing the "zipCode" "feature".  Think about the operations I'm performing.  Howe can we do this "EXTERNALLY"  via reference.  This way we don't have to mess with the thing hmm.. it's compiled and hm!  Ah!  Again.. the encoding doesn't matter!  hmm... the point is, we can reference an encoding and then "compile" down, meaning... interpert and then procuce an encoding or ... just interper twith those pieces and use memory etc hmm... two things... the interpretation and the encoding.  MAYBE we just need the interperte hm...

  return <View style={{ flex: 1, width: '100%', alignItems: 'center' }}>

    {/* ZipCode */}
    {/* <View style={{ width: '100%', maxWidth: 300, borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 30 }}>
      <Text style={{ alignSelf: 'center', fontFamily: primaryFontFamily, fontSize: 12, flex: 1 }}>Enter Delivery Zip Code:</Text>
      <TextInput style={{ textAlign: 'center', fontFamily: primaryFontFamily, fontSize: 14, backgroundColor: '#fafafa', borderColor: '#f4f4f4', borderWidth: 2, padding: 15, borderRadius: defaultBorderRadius, marginVertical: 10 }} value={zip} onChangeText={text => setZip(text)} />
    </View>

    <View style={{ height: 20 }} /> */}

    {/* Estimate Details */}
    <View style={{ width: '100%', maxWidth: 300, borderRadius: defaultBorderRadius, backgroundColor: estimate ? 'white' : '#f4f4f4', paddingHorizontal: 30, borderColor: '#eeeeee', borderWidth: 2, justifyContent: 'center', height: 200 }}>


      {
        loading ? <ActivityIndicator color="white" style={{ alignSelf: 'center' }} /> : (
          <>
            <View style={{ flex: 1, borderRadius: 7, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12, flex: 1 }}>Subtotal</Text>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12 }}>{estimate ? usdFormatter.format(estimate?.retailCosts?.subtotal) : "TBD"}</Text>
            </View>


            <View style={{ height: 2, backgroundColor: '#f4f4f4', borderRadius: 1, width: '100%' }} />


            <View style={{ flex: 1, borderRadius: 7, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12, flex: 1 }}>Shipping</Text>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12 }}>{estimate ? usdFormatter.format(estimate?.retailCosts?.shipping) : "TBD"}</Text>
            </View>


            <View style={{ height: 2, backgroundColor: '#f4f4f4', borderRadius: 1, width: '100%' }} />

            <View style={{ flex: 1, borderRadius: 7, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12, flex: 1 }}>Tax</Text>
              <Text style={{ fontFamily: primaryFontFamily, fontSize: 12 }}>TBD</Text>
            </View>

            <View style={{ height: 2, backgroundColor: '#f4f4f4', borderRadius: 1, width: '100%' }} />


            <View style={{ flex: 1, borderRadius: 7, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12, flex: 1 }}>Total</Text>
              <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>{estimate ? usdFormatter.format(estimate?.retailCosts?.total) : "TBD"}</Text>
            </View>
          </>
        )
      }

    </View>

    <View style={{ height: 20 }} />

    <StripeCheckoutButton />

    <View style={{ flex: 1, minHeight: 30 }} />

    <Image style={{ width: 150, height: 40, tintColor: '#cccccc', alignSelf: 'center' }} source={{ uri: require("../assets/stripe2.png") }} resizeMode="contain" />

    {/* <View style={{ flex: 1, minHeight: 30 }} /> */}

  </View>
}

export const Cart = () => {

  const { cart, addItem, removeItem } = useCart();

  const settings = useSettings();

  // CONSIDER:  WOULD like a system that automatifcally puts space between Elements.. hmmm... maybe make FLEXbox do that? Hmm! SO many ways we can innovate!  People haven't donie becaue they hadn't though of it and UGH!  THey weren't FOCUS ED on that ugh!!!  People do the least mount fto meet their needs ugh!  DON'T think that it's a bad iea because someone hasn't don it ugh!!!  THink oft he freaking BRA!  The people who win are the ones who beleive in ABUNDANCE and know that OVERSIGHT and freaking... BYSTANDER effect are ALL real ugh!!!  Freaking selection bias is ... just there to help us see what the onws who SUCCEEDED did ugh!

  const { isDesktop } = useSizes();

  //  CONSIDER:  Use GraphQL, but how does Typescript work?  Hmm... espeicailly when it's dynmic.  TS is JUST an encoding mm!

  return (
    <View style={{ flex: 1 }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <Layer style={{ left: '50%', backgroundColor: '#fafafa', height: '100%', width: '50%', zIndex: -1 }} />
      <Layer style={{ backgroundColor: 'white', height: '100%', width: '50%', zIndex: -1 }} />
      <CenteredView containerProps={{ style: { flex: 1 } }}>

        <ScrollView style={{ flex: 1 }} scrollEnabled={!isDesktop} contentContainerStyle={{ flex: isDesktop ? 1 : undefined }}>


          <View style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', flex: 1, backgroundColor: 'white' }}>

            <ScrollView style={{ flex: 2 }} scrollEnabled={isDesktop} contentContainerStyle={{ flexDirection: 'column', width: '100%', alignItems: 'center', paddingHorizontal: settings.marginSize, paddingBottom: settings.marginSize }}>
              <HeroCard title='Shopping Cart' subTitle='Edit Your Order' />
              <FlatList style={{ flex: 1, width: '100%', maxWidth: 500 }} ItemSeparatorComponent={() => <View style={{ height: 15 }} />} contentContainerStyle={{ flexDirection: 'column' }} data={Object.keys(cart)} renderItem={({ item: variantId }) => {
                const item = cart[variantId];
                return (
                  <View style={{ backgroundColor: 'white', borderRadius: defaultBorderRadius, padding: 30, flex: 1, display: 'flex', flexDirection: 'row', ...niceShadow, margin: 30 }}>
                    <Image style={{ width: 100, height: 100 }} resizeMode="contain" source={{ uri: item.variant.files.find(file => file.type === "preview")?.url }} />

                    <View style={{ width: 10 }} />
                    <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <SubHeader style={{ flex: 1 }}>{item.variant.name}</SubHeader>
                      <Paragraph style={{ flex: 1 }}>{usdFormatter.format(parseFloat(item.variant.retail_price))} each</Paragraph>
                      <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>

                        <Pressable style={{ height: 30, width: 30, borderRadius: 15, backgroundColor: '#eeeeee', justifyContent: 'center', alignItems: 'center' }} onPress={() => removeItem(item.variant)}>
                          <Icon  size={16} color="#555555" type="entypo" name="minus" />
                        </Pressable>

                        <Paragraph style={{ marginHorizontal: 10 }}>{item.quantity}</Paragraph>

                        <Pressable style={{ height: 30, width: 30, borderRadius: 15, backgroundColor: '#eeeeee', justifyContent: 'center', alignItems: 'center' }} onPress={() => addItem(item.variant)}>
                          <Icon  size={16} color="#555555" type="entypo" name="plus" />
                        </Pressable>

                      </View>
                    </View>

                  </View>
                )
              }} />
            </ScrollView>

            {/* {
              !isDesktop && <View style={{ height: settings.marginSize }} />
            } */}

            <View style={{ width: isDesktop ? 2 : undefined, height: !isDesktop ? 2 : undefined, backgroundColor: '#eeeeee' }} />

            <View style={{ display: 'flex', flex: isDesktop ? 1 : undefined, backgroundColor: '#fafafa', padding: 30, paddingRight: settings.marginSize, alignItems: 'center' }}>
              <Header style={{ color: '#444444', marginBottom: 10, fontSize: 16 }}>Order Summary</ Header>
              <View style={{ height: 30 }} />
              <CheckoutWidget />
            </View>
          </View>
        </ScrollView>
      </CenteredView>

    </View>

  );
}
