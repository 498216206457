import { AnyValueReference, BlockInst, valueAtPath } from 'habor-sdk';
import { getAllBlocks } from "./models/blocks";

export const getComponents = (blockInsts: BlockInst[]) => {

    //  Get Components
    //  THOUGHTS:  This is tricky, because the SET of components can change based on the data flow, and the data flow can change based on the set of components.
    //             so... to start, I'm thinking, what if we assume a STATIC data flow / static components, and LATER we can worry about dynamic changes.
    //             This means we MAY have some components which exist OUTSIDE the data flow, just detached nodes!  They MAY take in direct, primitive values, which we can add as settings on the component?  Later, we MAY want to let the user adjust a component with a Router selection and that sort of stuff... Hmmm... we should probbaly continue considering how they will merge.
    //             FOR NOW, let's assume that the layout is totally independent of the data flow?

    //  QUESTION:  How do we prevent a cast like we do at the end here?
    const components = blockInsts.map(blockInst => getAllBlocks()[blockInst.blockId]).filter(block => !!block.payload.isComponent);
    return components;
};

/**
 * Creates a new map from 'inputId' -> 'value' from two existing maps:
 * @param propMap A map from 'propId' -> 'ValueReference'.  UNDERSTANDING:  This is set by the Block internall?  A map between its props and outside signals?
 * @param signalMap A map from 'signalId' -> 'signal value'.  UNDERSTANDING:  sPerhaps this maps the output of the block?  I'm not sure..
 * TODO:  Generalize this to a standard mapping function!
 */
export const resolveSignals = (propMap: { [propId: string]: AnyValueReference }, signalMap: any) => {

  //  Create the Input
  //  Get the Primitives
  //  REALIZATION:  Sometimes it's NICE to be able to name params what I want!  With ORDERED params, the user gets to choose what to name it!  Hmmm... I hadn't thought of that I don't think.  With an object, we get certain names unless we re-map.  Ah... ok, so even ordered params are basically just doing a light remapping from the documented name.
  //  IDEA:  Consider tracking EACH time a signal is read / written?
  //  IDEA:  Consider "immutable" signals?  OR, maybe just VERSIONED signals!  Then we are ALWAYS immutable, and we can go back in time?

  const input: any = {};
  Object.keys(propMap).forEach(propId => {

    //  Get the ValueReference
    const valReference = propMap[propId];

    //  Handle Constant
    //  CONSIDER:  PERHAPS instead of building this abstraction, we can make separate, unconected BLOCKS for things like "constant" signals!?  THEN, we can assume ALL are just referenceing signals!?
    if (valReference.type == "constant") {
      input[propId] = valReference.value;
      return;
    }

    //  Handle Signal
    const signalId = valReference.signalId;
    const signalIdParts = signalId.split(".");
    const signalValue = valueAtPath(signalMap, signalIdParts);
    input[propId] = signalValue;
  });

  return input;
}