import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { EntityPlugin } from '.';
import { EntityEditor } from './entity-editor';
import { EntityList } from './entity-list';

const Stack = createStackNavigator();

export enum EntityRoute {
  Editor = "EntityEditor",
  List = "EntityList"
}

export const EntityNavigator = ({ entityPlugin }: { entityPlugin: EntityPlugin }) => {

  return (
    <Stack.Navigator initialRouteName={ EntityRoute.List } screenOptions={{ animationEnabled: true }}>
      {/* TODO:  SHOULD support complex scopes and stuff... BASICALLY do something like The Graph.. hmmmm.... */}
      <Stack.Screen name={ EntityRoute.Editor } component={ ({ navigation, route }) => <EntityEditor entity={ route.params.entity } /> } />
      <Stack.Screen name={ EntityRoute.List } component={ ({ navigation, route }) => {
        const scope = route.params?.scope;
        return <EntityList navigation={ navigation } entityPlugin={ entityPlugin } scope={ scope } />
      } } />
    </Stack.Navigator>
  );
}