import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import { Color } from 'habor-sdk';
import * as React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { getRaisedStyle, RaisedHeight, RaisedProps, kelpStyles } from "./styles";

const LinearGradient = ExpoLinearGradient as any;

export interface CardProps extends RaisedProps, React.ComponentProps<any> {
  colors?: string[];
  start?: [number, number];
  end?: [number, number];
  onPress?: () => void;
  onLongPress?: () => void;
  raisedHeight?: RaisedHeight;
  outerStyle?: ViewStyle;
  innerStyle?: ViewStyle;
  onLayout?: any;
}

//  NOTE:  We use two views, one for the shadow, and another for the content.  If we used only one view, then the linear gradient would overflow the bounds of the view, creating a hard rectangle instead of a rectangle with curved corners.
//  IDEA:  Make it possible to bow the sides!?  MAYBE make OTHER cool shapes properties / shapes easily controlled via props???
export const Card = (props: CardProps) => {

  const { onPress, colors, start, end, raisedHeight = RaisedHeight.med, backgroundColor = Color.white, outerStyle = {}, innerStyle = {}, onLongPress } = props;

  const ContentView: any = onPress || onLongPress ? TouchableOpacity : View;

  return (
    <ContentView onLayout={ props.onLayout } onPress={ onPress } onLongPress={ onLongPress } style={{ overflow: 'visible', ...kelpStyles.rounded as any, display: 'flex', ...outerStyle }}>

      {/* Shadow View */}
      <View style={[ kelpStyles.rounded, getRaisedStyle({ raisedHeight }), outerStyle, { borderWidth: 0, position: 'absolute', overflow: 'visible', width: '100%', height: '100%', backgroundColor: 'white', zIndex: 0 } ]} />

      {/* Content View */}
      {/* TODO-UNDERSTANDING:  Why does using { height: '100%', width: '100%' } make this SOMETIMES fill the whole screen?  Does it not use the parent sizing? */}
      <View style={[ kelpStyles.rounded, { overflow: 'hidden', flex: 1, zIndex: 1 }, outerStyle, { borderWidth: 0 } ]} >
        {
          colors ? 
            <LinearGradient style={{ ...innerStyle }} colors={ colors } start={ start } end={ end }>
              { props.children }
            </LinearGradient> : 
            <View style={{ ...innerStyle }}>
              { props.children }
            </View>
        }
      </View>
    </ContentView>
  );
};

//  NOTE:  When a Card user wishes to create a button, they need to include 'onStartShouldSetResponder' to steal the right to respond to touch events from the Card.  We provide this helper prop to make this a feature of the Card API!
export const CardTouchableProp = { onStartShouldSetResponder: () => true };