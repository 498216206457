import { CorePluginClass, Program } from "halia";
import moment from 'moment';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Chiclet } from '../../../packages/kelp-bar/chiclet';
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { GalleryButton } from '../../gallery/components/common';
import { Entity, EntityListItemAPI, EntityPlugin } from '../entity-plugin';
import { WidgetItem } from "../hessia-plugin/dynamic-component";
import { SimpleBox } from "../page-plugin/page-widget";
import { Checkin, CheckinPlugin } from "./checkin-plugin";

//  CONSIDIER:  We have GENERALIZED TODOs that will apply to EVERYTHING.  We want to AVOID duplicate work thoguh!
//  NOTE:  It's up to each system HOW it wants to track entities.  It CAN do it for EACH update.  PERHAPS we can build system to help with this too hm!
//  TODO:  Update entity on update / delete too? Hmmm

export class CheckinEntity extends CorePluginClass {

  public systemId = "checkin";

  public static details = {
    name: 'Checkin Entity',
    description: 'Enables Entity Integration for Checkins',
    dependencies: [CheckinPlugin.details.id, EntityPlugin.details.id],
    id: 'checkinEntityPlugin'
  }

  public install = (program: Program, { checkinPlugin, pEntities }: { checkinPlugin: CheckinPlugin, pEntities: EntityPlugin }) => {
    checkinPlugin.onCreateCheckin(checkin => {
      pEntities.createEntity({
        systemId: checkinPlugin.systemId,
        route: { id: checkin.id },
        metadata: {}
      })
    });

    pEntities.registerEntityListItemRenderer({
      id: "checkin-plugin",
      name: "Checkin Plugin",
      renderer: async (entity: Entity, api: EntityListItemAPI) => {

        api.addComponent(({ entity }) => {

          const [checkins, setCheckins] = React.useState<NounServiceInstanceInternal<Checkin>[]>([]);

          const load = async () => {
            const checkins = await checkinPlugin.checkinService.retrieveAll();
            const filteredCheckins = checkins.filter(checkin => checkin.payload.targetEntity === JSON.stringify(entity));
            setCheckins(filteredCheckins);
          }

          React.useEffect(() => {
            load();
          }, [])

          return (
            <Chiclet backgroundColor='#ffebec'>
              <Text style={{ color: '#d63842', fontSize: 16, fontWeight: '600' }}>Checkins</Text>
              <View style={{ borderRadius: 18, height: 36, width: 36, backgroundColor: '#ffadb2', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: '#d63842', fontWeight: '600', fontSize: 14 }}>{checkins.length}</Text>
              </View>
            </Chiclet>
          )
        });
      }
    });


    pEntities.registerEntityDetailView({
      id: "checkin-plugin",
      name: "Checkin Plugin",
      component: ({ entity, entityPlugin }) => {


        const [checkins, setCheckins] = React.useState<NounServiceInstanceInternal<Checkin>[]>([]);


        const makeCheckin = async () => {

          try {


            const _checkin = await checkinPlugin.checkinService.create({ targetEntity: JSON.stringify(entity), datetime: new Date().toISOString() });
            checkinPlugin.emitter.emit("create-checkin", _checkin);
            load();
          } catch (err) {
            alert("Checkin Error: " + JSON.stringify(err));
          }


        }

        const load = async () => {

          const checkins = await checkinPlugin.checkinService.retrieveAll();
          const filteredCheckins = checkins.filter(checkin => checkin.payload.targetEntity === JSON.stringify(entity));
          setCheckins(filteredCheckins);
        }

        React.useEffect(() => {
          load();
        }, [])



        return (
          <WidgetItem name="Checkins" color="#eeeeee">

            {
              checkins.map(checkin => (
                <TouchableOpacity style={{ backgroundColor: 'white', padding: 30, borderRadius: 30, marginTop: 10 }}>
                  <Text style={{ color: '#dddddd' }}>{moment(checkin.updated).fromNow()}</Text>
                </TouchableOpacity>
              ))
            }

            <GalleryButton title='Checkin' onPress={makeCheckin} />
          </WidgetItem>
        )
        // }
      }
    });

    return this;
  }
}

