import { BlockInst } from 'habor-sdk';
import { TitleChiclet } from "../../../primitives-plugin/habor-components/embedded-views/chicklet-component";
import * as React from 'react';

//
//  SignalNodeErrors
//

//  TODO:  There are LOTS of possible errors... should we encapsulate them with a Feature Plugin?
//  CONSIDER:  Generate WARNINGS for things like dangling nodes, etc...

export interface SignalNodeError {
  type: string;
}

//  TODO:  In the future we may support pushing to the same signal multiple times.  For now, let's keep it an error.
export interface DuplicateSignalDefinitionError extends SignalNodeError {
  type: "duplicate-signal-definition",
  signalId: string;
}

export interface UnmappedInputSignalError extends SignalNodeError {
  type: "unmapped-input-signal",
  blockInst: BlockInst;
  inputName: string;
}

export interface UnmappedOutputSignalError extends SignalNodeError {
  type: "unmapped-output-signal",
  blockInst: BlockInst;
  outputName: string;
}

//
//  Error Renderers
//

export interface ErrorDefinition {
  name: string;
  description: string;
  //  TODO:  Properly type "error"... I was having trouble with the Union / Intersection types.  I believe what I want, is a type to capture the UNION of instances of all SignalNodeError.
  component?: React.FunctionComponent<{ error: any }>;
}
//  TODO:  Maybe this shuold be added / updated with a Plugin?
export const errorDefinitions: { [name: string]: ErrorDefinition } = {
  "duplicate-signal-definition": {
    name: "Duplicate Signal Definition",
    description: "Each signal must have a unique name.",
    component: ({ error }: { error: DuplicateSignalDefinitionError }) => {
      return (
        <TitleChiclet title={ error.signalId } />
      );
    }
  },
  //  TODO:  Support optional inputs.
  "unmapped-input-signal": {
    name: "Unmapped Input Signal",
    description: "A block input has not been connected to a component signal.",
  },
  //  TODO:  Support optional outputs.
  "unmapped-output-signal": {
    name: "Unmapped Output Signal",
    description: "A block output has not been connected to a component signal.",
  }
};
