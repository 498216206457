import { FrameworkContext, SDTAny, UserBlock } from 'habor-sdk';
import * as React from 'react';
import { Button, Switch, Text, TextInput, View } from "react-native";
import { Card } from '../../../../../packages/kelp-bar/card';
import { Paragraph } from '../../../../../packages/kelp-bar/constants';
import { GalleryButton } from '../../../../gallery/components/common';
import { TextField } from '../../../../gallery/components/fields';
import { TitleChiclet } from '../../../primitives-plugin/habor-components/embedded-views/chicklet-component';

export interface SignalViewProps {
  // signalNodeMap: SignalNodeMap;
  frameworkContext: FrameworkContext;
  block: UserBlock;
  updateBlock: (block: UserBlock) => void;
}
interface SignalViewState {
  isInput: boolean;
  signalName: string;
  reactiveInput: boolean;
}


class SignalViewBase extends React.Component<SignalViewProps, SignalViewState> {
  constructor(props: SignalViewProps) {
    super(props);
    this.state = {
      isInput: true,
      signalName: "",
      reactiveInput: true
    }
  }

  public makeInputSignal = (signalName: string, type: SDTAny, reactive?: boolean) => {
    const { block, updateBlock } = this.props;
    const schema = block.inputSchema || { type: "object", properties: {}, extensible: false };
    const { properties = {} } = schema;
    properties[signalName] = { ...type, meta: { reactive } };
    updateBlock({ ...block, inputSchema: schema });
  }

  public makeOutputSignal = (signalName: string, type: SDTAny) => {
    const { block, updateBlock } = this.props;
    const schema = block.outputSchema || { type: "object", properties: {}, extensible: false };
    const { properties = {} } = schema;
    properties[signalName] = type;
    updateBlock({ ...block, outputSchema: schema });
  }

  public makeInternalSignal = (signalName: string, type: SDTAny) => {
    //  TODO:  Implement this!
    alert("Make Internal Signal is Unimplemented");
  }

  public makeSignal = () => {
    const { signalName, isInput, reactiveInput } = this.state;
    if (isInput) {
      this.makeInputSignal(signalName, { type: "any" }, reactiveInput);
    } else {
      this.makeOutputSignal(signalName, { type: "any" });
    }
  }

  public render = () => {

    // Unpack
    const { block: { inputSchema, outputSchema } } = this.props;

    return (
      <>

        {
          inputSchema && inputSchema.properties ?
            (
              <View>
                <Paragraph>Inputs</Paragraph>
                {
                  Object.keys(inputSchema.properties).map(inputProp => (
                    <TitleChiclet title={inputProp} />
                  ))
                }
              </View>
            ) : null
        }

        {
          outputSchema && outputSchema.properties ?
            (
              <View>
                <Paragraph>Outputs</Paragraph>
                {
                  Object.keys(outputSchema.properties).map(outputProp => (
                    <TitleChiclet title={outputProp} />
                  ))
                }
              </View>
            ) : null
        }

        <View style={{ height: 2, backgroundColor: '#eeeeee', width: '100%', marginVertical: 20 }} />

        {/* TODO:  Show INTERNAL signals???  MAYBE that can be a separate thing? */}

        <TextField mode='manual' title='Signal Name' value={this.state.signalName} placeholder="Signal Name" onSave={(text) => this.setState({ signalName: text })} />

        {/* TODO:  Make this a set of check boxes perhaps? */}

        <Paragraph>Input</Paragraph>
        <Switch value={this.state.isInput} onValueChange={(isInput) => { this.setState({ isInput }) }} />

        <Paragraph>Reactive</Paragraph>
        {this.state.isInput ? <Switch value={this.state.reactiveInput} onValueChange={(reactive) => { this.setState({ reactiveInput: reactive }) }} /> : null}

        <View style={{ height: 7 }} />
        <GalleryButton title="Create" onPress={this.makeSignal} />
      </>
    );
  }
}
export const SignalView = SignalViewBase