import { EntityType, Instance, InstanceInternal } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { HaborPlugin } from "../../habor-plugin";
import { HessiaPlugin } from '../../hessia-plugin';
import { ActivityHaborComponent, ActivityHaborPrimitive } from "./activity-page";
import { Activity, ActivityEvent } from "./activity-types";
import * as React from 'react';

//  TODO-GENERAL:  Support GraphQL!

//  TODO:  AGAIN... this is a BANDAID.. and no better than just having a SINGLE instance right???  Ugh.. idk.. I think it WILL give the CLOSEST context though!  SO... maybe it's OK?  BUT what if we want to access a different one?? HM!  Multiple access systems?
export const ActivityPluginContext = React.createContext<ActivityPlugin | undefined>(undefined);

export class ActivityPlugin extends CorePluginClass {

  public static details = {
    name: "Activity",
    description: "Hessia Activity Plugin",
    dependencies: [HessiaPlugin.details.id, HaborPlugin.details.id],
    id: "activity"
  }

  //  REFERENCE:  https://stackoverflow.com/questions/49699067/property-has-no-initializer-and-is-not-definitely-assigned-in-the-construc
  public hessia!: HessiaPlugin;
  public habor!: HaborPlugin;

  public install = (program: Program, { hessia, habor }: { hessia: HessiaPlugin, habor: HaborPlugin }) => {

    hessia.registerContext(ActivityPluginContext, this);

    this.hessia = hessia;
    this.habor = habor;

    hessia.registerPrimitiveComponent('ActivityHaborPrimitive', ActivityHaborPrimitive);
    hessia.registerComponent(ActivityHaborComponent);
    return this;
  }

  //  TODO-GENERAL:  Consider CACHING!  MAYBE on the client-side we can have CONTEXT groups or something which either the server invalidates, or MAYBE if we're the ONLY device making changes, we can rely on our OWN updates? hm!  Maybe use WS, idk, lots of ways we can work with that it seems? HM!
  //  TODO:  There ARE several ways we can query for Activities!  I DO think it makes sense to do something like GraphQL where we can let the UI choose? HM!
  public getActivities = async (token: string, ids?: string[]) => {

    //  Get Activities
    const idTerm = ids ? { search: { any: ids.map(id => ({ match: { id } })) } } : {};
    const activities = await this.habor.haborSDK.searchInstances<Activity>(token, { nounId: "activity", ...idTerm });
    console.log(activities);
    return activities;
  }

  public getPendingEvents = async (token: string) => {

    //  Get Pending Activity Events
    //  CONCERN:  It WOULD be nice to be able to see data like the LAST time a particular activity was logged.

    try {
      // const activityMatchTerms = activities ? { match: { payload: { activities: { instanceIdList: activities.map(activity => ({ instanceId: activity.id })) } } } }: { any: [] };
      const pendingEvents = await this.habor.haborSDK.searchInstances<ActivityEvent>(token, { nounId: "activiy-event", search: { match: { payload: { isPending: true } } } });
      console.log(pendingEvents);
  
      return pendingEvents;
    } catch (err) {
      console.log(err);
      return [];
    }

  }

  public getPendingActivities = async (token: string) => {
    const pendingEvents = await this.getPendingEvents(token);
    //  TODO:  FOR NOW, I'm assuming there's only ONE activity per "ActivityEvent".  I MAY want to change the data model to one-one!? HM!
    const activities = await this.getActivities(token, pendingEvents.map(pendingEvent => pendingEvent.payload.activities.instanceIdList[0].instanceId))
    console.log("Test");
    return activities;
  }

  public closeEvent = async (token: string, pendingEvent: InstanceInternal<any>, endTime?: Date) => {
      const closedEvent = await this.habor.haborSDK.updateInstance(pendingEvent.id, { nounId: pendingEvent.nounId, payload: { ...pendingEvent.payload, endTime: endTime ? endTime : (new Date()).toISOString(), isPending: false } }, token);
      return closedEvent;
  }

  public openEvent = async (token: string, activity: InstanceInternal<any>, startTime?: Date) => {
    
    const instance: Instance = {
      nounId: "activiy-event",  //  TODO:  Fix the typo in the Noun ID
      payload: {
        startTime: startTime ? startTime : (new Date()).toISOString(),
        isPending: true,
        activities: {
          instanceIdList: [{ nounId: activity.nounId, instanceId: activity.id, type: EntityType.Instance }]
        }
      }
    };

    const openEvent = await this.habor.haborSDK.createInstance(instance, token);
    console.log("Test");
    return openEvent;
  }

  public isPending = async (token: string, activity: InstanceInternal<any>) => {
    const pendingEvents = await this.getPendingEvents(token);
    //  TODO:  SHOULD be able to query the server!  I'm having a hard time consturcting a query for this that works though...
    const isPending = !!pendingEvents.find(pendingEvent => pendingEvent.payload.activities.instanceIdList[0].instanceId === activity.id);
    return isPending;
  }

}
