import { CorePluginClass, Program } from 'halia';
import { HaliaComponentPlugin } from 'halia-native';
import { Text } from 'react-native';
import { GalleryNavigator } from './gallery-app';

export class GalleryPlugin extends CorePluginClass {

  public static details = {
    name: 'Gallery Plugin',
    description: 'Gallery Plugin',
    dependencies: [HaliaComponentPlugin.details.id],
    id: 'galleryPlugin'
  }

  public install = (program: Program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    haliaComponentPlugin.registerHOC(GalleryNavigator);
    // haliaComponentPlugin.registerHOC(() => <Text>TEST</Text>);
    return this;
  }
}
