import moment from 'moment';
import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

//  TODO:  Decouple This!!!!!
import { AaroTheme } from '../../plugins/aaro/aaro-core';

const selectorStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%'
  },
  button: {
    padding: 10,
    paddingVertical: 15,
    // backgroundColor: '#DEF7FF',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#eeeeee', borderWidth: 2,
    // borderLeftWidth: 1, 
    // borderRightWidth: 1
    marginHorizontal: 5,
    borderRadius: 10,
    width: 40
    // Add your normal button style here
  },
  activeButton: {
    // backgroundColor: '#90E4FF',
    backgroundColor: AaroTheme.primaryColor,
    borderColor: AaroTheme.primaryColorBorder
    // backgroundColor: '#90E4FF',
    // Add your active button style here
    // borderRadius: 5,
    // height: '110%',
    // width: '110%',
    // left: "-5%",
    // top: '5%'
  },
  buttonText: {
    // color: '#14B9FF',
    color: 'black',
    fontFamily: 'Inter-SemiBold',
    // fontWeight: "600",
    fontSize: 11
    // Add your normal text style here
  },
  activeButtonText: {
    color: 'white',
    // Add your active text style here
  },
  firstButton: {
    marginLeft: 0,
    // borderTopStartRadius: 5, borderBottomStartRadius: 5, borderColor: '#eeeeee',
  },
  lastButton: {
    marginRight: 0,
    // borderTopEndRadius: 5, borderBottomEndRadius: 5, borderColor: '#eeeeee',
  }
});

// CONSIDER:  This could be a horizontal scroller with a nice pretty and like lighter UI hmm...
export const Selector = ({ value, onValueChange, buttons }) => {
  return (
    <View style={selectorStyles.container}>
      {buttons.map((button, index) => (
        <TouchableOpacity
          key={index}
          style={[selectorStyles.button, value === button.value && selectorStyles.activeButton, (index === 0) && selectorStyles.firstButton, (index === buttons.length - 1) && selectorStyles.lastButton]}
          onPress={() => onValueChange(button.value)}
        >
          <Text style={[selectorStyles.buttonText, value === button.value && selectorStyles.activeButtonText]}>{button.label}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export type Period = 'day' | 'week' | 'fortnight' | 'month' | 'quarter' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'weekday' | 'weekend';

export const SingleDatePicker = ({ onSelected, selected, period }: { onSelected: (date: moment.Moment) => void, selected: moment.Moment, period: Period }) => {

  //  CONSIDER:  I want to fit as many dots as will go acrossthe screen as possible.. BUT the size of the DOT is TBD by the params / style / theme / language!  SO... how we dothis?  we let the DOT be sized first and then we use that like a dependency hmm... 

  const [startDate, setStartDate] = React.useState(selected);

  const sevenItems = [-2, -1, 0, 1, 2].map(offset => {
    const _startDate = moment(startDate);
    if (period === 'fortnight') {
      _startDate.add(offset * 2, 'weeks');
    } else {
      const unit: string = period + 's';
      _startDate.add(offset as any, unit);
    }
    return _startDate;
  });



  const getMainLabel = (date: moment.Moment, period: Period) => {
    if (period === 'day') {
      return date.date();
    } else if (period === 'week') {
      return date.startOf('week').date();
    } else if (period === 'fortnight') {
      const fortnightCount = Math.floor(date.week() / 2);
      const fornightStart = date.startOf("year").add(fortnightCount * 2, 'week');;
      return fornightStart.date()
    } else if (period === 'month') {
      return date.startOf('month').format('MMM')
    } else if (period === 'quarter') {
      return date.startOf('quarter').format('MMM')
    } else {
      return date.date()
    }
  }

  //  TODO:  Do all of this with the ONTOLOGICAL / GRAPH system !

  const getSubLabel = (date: moment.Moment, period: Period) => {
    if (period === 'day') {
      return date.format('ddd')
    } else if (period === 'week') {
      return date.startOf('week').format('MMM')
    } else if (period === 'fortnight') {
      const fortnightCount = Math.floor(date.week() / 2);
      const fornightStart = date.startOf("year").add(fortnightCount * 2, 'week');;
      return fornightStart.startOf('week').format('MMM')
    } else if (period === 'month') {
      return date.startOf('month').format('YYYY')
    } else if (period === 'quarter') {
      return date.startOf('quarter').format('YYYY')
    } else {
      return date.date()
    }
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <Selector
        value={selected.toISOString()}
        onValueChange={date => onSelected(moment(date))}
        buttons={
          sevenItems.map(date => ({ label: `${getSubLabel(date, period)} ${getMainLabel(date, period)}`, value: date.toISOString() }))
        }
      />

      {/* {
        sevenItems.map(date => <View style={{ flex: 1, alignItems: 'center' }}>
          <TouchableOpacity onPress={() => { onSelected(date) }} style={{ height: 50, width: 70, borderRadius: 10, backgroundColor: date.isSame(selected, 'day') ? '#aaaaaa' : '#eeeeee', alignItems: 'center', justifyContent: 'center' }}>
            <SubHeader>{getMainLabel(date, period)}</SubHeader>
          </TouchableOpacity>
          <Paragraph>{getSubLabel(date, period)}</Paragraph>
        </View>)
      } */}
    </View>
  );
}