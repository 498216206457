import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { deserializeUserBlock, EntityType, FrameworkContext, HaborComponentContext, InstanceInternal, PrimitiveBlock, SerializedUserBlock, serializedUserBlockNoun } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { RoundIconButton } from '../../../packages/kelp-bar/buttons';
import { registerAppSystemWidget } from "../apps-plugin/screens/app-editor.screen";
import { EntityPlugin } from "../entity-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { AppContext } from "../hessia-plugin/AppContext";
import { haborSDK } from "../hessia-plugin/config";
import { InstanceList } from "../model-plugin/entity/instance-list";
import { SimpleBox } from "../page-plugin/page-widget";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { ComposerWidget } from "./composer-widget";
import { BlockRenderer } from "./composer/components/component-renderer";
import { Composer } from "./composer/composer";
import { primitiveBlocks } from "./composer/models/blocks";
const uuidv4 = require("uuid/v4");

export const ComposerPluginContext = React.createContext<ComposerPlugin | undefined>(undefined);

export const useComposerPlugin = () => {
  return React.useContext(ComposerPluginContext);
}

export class ComposerPlugin extends CorePluginClass {

  public static details = {
    name: "Composer",
    description: "Composer Plugin",
    dependencies: [HessiaPlugin.details.id, SystemPlugin.details.id, EntityPlugin.details.id],
    id: "composer",
  };

  public detailViewBlocks: string[] = [];
  public registerDetailViewBlock = (blockId: string) => {
    alert(blockId)
    this.detailViewBlocks.push(blockId);
  }

  public registerPrimitiveBlock = (
    block: PrimitiveBlock,
    id: string = uuidv4()
  ) => {
    primitiveBlocks[id] = {
      id,
      payload: block,
      updated: new Date().toISOString(),
      created: new Date().toISOString(),
      nounId: "N/A",
    };
  };

  //  CONSIDER:  I think we CAN use TS to inject these.  NOT sure that's a great idea though because then we break
  public install = (program: Program, { hessia, system, pEntities }: { hessia: HessiaPlugin, system: SystemPlugin, pEntities: EntityPlugin }) => {

    //  Register Contesxt
    //  TODO-IMPORTANT:  When we update elemencts in the Plugin it should update in the state tree.  Basically this means that the plugin IS a piece of context in the tree? Hmm... Maybe we can do this with a more basic system idk.
    hessia.registerContext(ComposerPluginContext, this);

    //  CONSIDER:  Can we MARRY Blocks, HaborComponents, and Halia Plugins?  ESPECIALLY Halia and Composer, because they're BOTH starting to look EXACTLY the same hm!
    const ComposerNav = () => {
      const Nav = createStackNavigator();
      const navigation = useNavigation();
      return (
        <Nav.Navigator initialRouteName="List">
          <Nav.Screen name="Composer" component={({ route }) => <Composer block={route?.params?.block} />} />
          <Nav.Screen name="List" component={() => <InstanceList headerButtons={() => <RoundIconButton
            onPress={() => { navigation.navigate("Composer" as never) }}
            iconSelection={{ name: 'plus', type: 'font-awesome' }}
            backgroundColor="#25ced2"
            iconColor='white'
          />} onItemPressed={({ instance }) => { navigation.navigate("Composer" as never, { block: instance } as never) }} nounId={{ nounId: serializedUserBlockNoun.id, type: EntityType.Noun }} />} />
        </Nav.Navigator>
      );
    }

    system.registerPrimitiveSystem({
      id: "composer-system",
      name: "Composer",
      description: "Composer Primitive System",
      labels: ["core"],
      component: ComposerNav,
      menuItem: {
        icon: { name: "brush", type: "material" },
        backgroundColor: "#9a67fe",
        iconColor: "white",
      },
    });

    registerAppSystemWidget({
      name: "Composer Widget",
      description: "Composer Widget",
      id: "composer-widget",
      component: ComposerWidget,
    });

    //  Register a Block Interpreter.  The idea is, we can enable our Blocks for display on Entities.
    pEntities.registerEntityDetailView({
      id: "block-service",
      name: "Block Service",
      description: "Displays blocks for the detail view.",
      component: ({ entity, entityPlugin }) => {

        //  TODO:  Remove "FrameworkContext" and use Context instead.
        //  TODO:  Reconsider "ComponentContext" for built-in React context or alternative solutions.  Essentially it's a way to store additional info about the tree, but at app-level.  Kind of like Error boundaries and Navigation.

        const { frameworkContext: { user, token, appEmitter, workspace } } = React.useContext(AppContext);
        const [detailBlocks, setDetailBlocks] = React.useState<InstanceInternal<SerializedUserBlock>[]>([]);

        const getDetailBlocks = async () => {
          const _detailBlocks = await haborSDK.searchInstances<SerializedUserBlock>(token, { nounId: serializedUserBlockNoun.id, search: { any: this.detailViewBlocks.map(blockId => ({ match: { id: blockId } })) } });
          setDetailBlocks(_detailBlocks);
        };

        React.useEffect(() => {
          getDetailBlocks();
        }, []);

        //  Construct the Framework Props
        const frameworkContext: FrameworkContext = { user, token, appEmitter: appEmitter, changePage: () => alert("page change not supported from the composer"), workspace };

        //  Construct the Context
        const componentContext: HaborComponentContext = { name: "workspace-container" };

        return <>
          {
            detailBlocks.map(block => <SimpleBox title={block.payload.name}><BlockRenderer entity={ entity } entityPlugin={ entityPlugin } setOutput={() => console.log("Output Set")} frameworkContext={frameworkContext} componentContext={componentContext} component={deserializeUserBlock(block.payload)} /></SimpleBox>)
          }
        </>
      },
    });

    return this;
  };
}
