import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Text } from 'react-native-paper';
import { AaroContext, AaroPlugin, RouteConfig } from '../aaro-core';
import { ScrollView, View } from 'react-native';

const mixedPluginId = "mixed-plugin";

const aaroRoutes: RouteConfig[] = [
  {
    name: "Schedule",
    component: () => <Text>Schedule</Text>,
    icon: { type: 'ionicon', name: 'calendar' }, // Ionicons v5: "calendar"
    emoji: "📅" // Calendar emoji for schedule
  },
  {
    name: "Goals",
    component: () => <Text>Goals</Text>,
    icon: { type: 'ionicon', name: 'flag' }, // Ionicons v5: "flag"
    emoji: "🚩" // Flag emoji for goals
  },
  {
    name: "Chores",
    component: () => <Text>Chores</Text>,
    icon: { type: 'ionicon', name: 'home' }, // Ionicons v5: "home" or "brush" for cleaning tasks
    emoji: "🧹" // Broom emoji for chores
  },
  {
    name: "Routines",
    component: () => <Text>Routines</Text>,
    icon: { type: 'ionicon', name: 'repeat' }, // Ionicons v5: "repeat"
    emoji: "🔄" // Repeat emoji for routines
  },
  {
    name: "Events",
    component: () => <Text>Events</Text>,
    icon: { type: 'ionicon', name: 'calendar-number' }, // Ionicons v5: "calendar-number" or "megaphone"
    emoji: "🎟️" // Ticket emoji for events
  },
  {
    name: "Activities",
    component: () => <Text>Activities</Text>,
    icon: { type: 'ionicon', name: 'bicycle' }, // Ionicons v5: "bicycle" for physical activities
    emoji: "🏃" // Runner emoji for physical activities
  },
  {
    name: "People",
    component: () => <Text>People</Text>,
    icon: { type: 'ionicon', name: 'people' }, // Ionicons v5: "people"
    emoji: "👥" // Two people silhouette for people
  },
  {
    name: "Sessions",
    component: () => <Text>Sessions</Text>,
    icon: { type: 'ionicon', name: 'time' }, // Ionicons v5: "time"
    emoji: "⏳" // Hourglass for sessions indicating time-bound sessions
  },
  {
    name: "Base",
    component: () => <Text>Base</Text>,
    icon: { type: 'ionicon', name: 'shield-checkmark' }, // Ionicons v5: "shield-checkmark" to represent protection or an army base
    emoji: "🛖" // Hut emoji to symbolize a secluded or special location in the woods
  },
  {
    name: "Resources",
    component: () => <Text>Resources</Text>,
    icon: { type: 'ionicon', name: 'diamond' }, // Ionicons v5 does not have a direct "diamond" icon, so choose "pricetags" or similar
    emoji: "💎" // Diamond emoji to symbolize valuable resources such as gold, metal, diamonds, etc.
  },

  // TODO:  A plugin to LEARN about SDT and all that stuff!  It should let you long press and see WHY something is helpful for your personal productivity!!!
  {
    name: "Learning",
    component: () => <Text>Learning</Text>,
    icon: { type: 'ionicon', name: 'shield-checkmark' }, 
    emoji: "🛖" 
  },

];

export class MixedPlugin extends CorePluginClass {

  public static details = {
    name: 'Office Tools',
    description: 'Digtal Workbench',
    dependencies: [AaroPlugin.details.id],
    id: mixedPluginId,
    image: require("../../../assets/stickers/office.png"),
    detailView: () => {
      return (
        <ScrollView style={{ flex: 1 }}>
          <Text>Aaro Routes</Text>
          {
            aaroRoutes.map(route => <Text>{JSON.stringify(route)}</Text>)
          }
        </ScrollView>
      );
    }
  }

  public install = async (program: Program, { aaro }: { aaro: AaroPlugin }) => {

    aaro.registerHOC(({ children }) => {

      const { installRoute } = React.useContext(AaroContext);

      React.useEffect(() => {

        //  CONSIDER:  I'd like the "Route" object and register to be observable from the app itself.  That's a big differentiator.  It's a way to make sure we can 
        //  CONSIDER:  Instead of imperative, consider installation via arrangement.

        aaroRoutes.forEach(route => {
          installRoute(route);
        })


        //  TODO:  Add Routines, Rewards, "Blocks" (like 1, 2, 3) to Dashboard!!
        //  TODO:  Journeys?


      }, []);
      return (
        <>
          {children}
        </>
      );
    });


    return this;
  }
}