import { EntityType, getInstanceSchemaFromProperties, InstanceInternal, Menu as MenuType, Page, PageNoun, pluginNoun, SDTObject, SpaceAssociation, SpaceAssociationNoun, SystemEnablement, SystemEnablementNoun, Workspace, workspaceNoun } from 'habor-sdk';
import * as React from 'react';
import { ScrollView, View } from "react-native";
import { AppContext } from '../hessia-plugin/AppContext';
import { haborSDK, navigate, RouteList } from '../hessia-plugin/config';
import { RouteComponentProps, withRouter } from '../../../packages/isomorphic-router/react-router';
import { getMenu } from '../menu-plugin/menu-component';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { DavelForm } from '../../../packages/davel-ui/davel-ui';
import { createOrUpdateInstance } from '../habor-plugin/habor-utils';
import { AppHeader } from '../../../packages/kelp-bar/app-header';

//  TODO-CRITICAL:  Make sure to re-fetch the Workspace after making an edit!  We don't want to navigate to another settings page and overwrite the work we did!

// export const appConfig: AppLayoutConfig = {

// }

//  NOTE:  FOR NOW, we're going to use a CustomComponent for the WorkspaceSettings.  In the future, we MAY want to consider re-writing to use the existing components.

// export type BasicWorkspaceInfo = Exclude<Workspace, { menu: Workspace["menu"], plugins: Workspace["plugins"] }>;


export const AppEvent = {
  HeaderButtonPressed: "HeaderButtonPressed"
}

  //  CONSIDER:  These are strange in this file... Consider a parent component to hold the state of these various pieces?

  //  CONCERN:  The 'onHeaderButtonPress' interface is NOT standard between the options.  In this case, the button's action may need access to the INNER component state.  For example, saving the Menu items.  INSTEAD, this is a case where it probably DOES make sense to signal the child when the button is pressed, and make sure every child knows how to handle that press?  BUT, then the child has knowledge of button event.  
  //  TODO:  In the future, we might want to group all App Elements together under an "Elements" page.  For now, we'll just show a page for each.  These include things like Pages, Components, Actions, Types (Interface), Nouns (already available from the main app menu).  These are really just Pages themselves?  BUT FOR NOW, we'll hard-code them.
  //  NOTE:  SOME Custom Elements only make sense in a particular context, like the "Dashboard"... MAYBE we'd want to somehow encode this?  Maybe add a dependency to the "Dashboard" Plugin?  Or more specifically, the component type?  I suppose we'd have enough information with JUST the link to the component type, which was installed by a particular Plugin.
  //  NOTE:  We MIGHT want to split some of these out into Pages?  For example, we ALREADY have the "Entity Page".
  //  REALIZATION:  These are basically just duplicating the "Page" work... MAYBE we can just navigate to "Pages"?  Either way, let's stick with this FOR NOW.  
  //  TODO:  Remove the hard-coded page references!  This is an example of a CORE system referencing a Plugin!  Instead, we should have this system, "Workspaces" DEPEND upon the "Entity Manager" Plugin, OR combine them to one "Core" Plugin???
  //  IDEA:  MAYBE the thing we pass should be a "Context" object??
  //  TODO:  Navigate to the creat page:   () => { this.props.history.push("/plugins/create") }}
  //  TODO:  Why do we need this thing?  Can we make it optional?
  //  TODO:  When a Plugin is tapped, we should see Plugin Details showing ALL the App Elements.  FOR NOW this can be hard-coded.  In the future, we MAY want to support a general "Context" which is supported at SEVREAL levels!

export interface WorkspaceSettingsProps extends RouteComponentProps {}

interface WorkspaceSettingsState {
  workspaceSchema?: SDTObject;
  pages: InstanceInternal<Page>[];
  menu?: InstanceInternal<MenuType>;
}
class WorkspaceSettingsBase extends React.Component<WorkspaceSettingsProps, WorkspaceSettingsState> {

  static contextType = AppContext;

  constructor(props: WorkspaceSettingsProps) {
    super(props);
    this.state = {
      pages: []
    }
  }

  //  TODO:  Create the settings by making a page for EACH key in the JSON.
  //         This allows us to re-use the SAME UI for ALL settings.
  //  Ah... that MAY be too simple.  We won't have information about the Settings.  What if we make "Setting Groups" and render a new tab for each of those?  What if we ALSO have some options for how to render this in the app?

  //  OK.  Time to take a break.  By end of tonight I hope to have a general Settings display working, maybe re-using some code which can convert similar strucures to an experience in the app?  MAYBE this is our FORM processor!  SO, we are actually creating "Form Groups" and this is eventually mapped back into the required structure.  Ah I like that!


  //  TODO:  This logic is SPECIFIC to "Editing".  We should try to abstract this to make a more generalized parameterized AppContainer pattern.
  public componentDidMount = async () => {

    //  Unpack
    const { workspace, token } = this.context;

    //  Get the Noun
    const workspaceNounInternal = await haborSDK.retrieveSerializedNoun(workspace.nounId, token);

    //  Get the Schema
    const workspaceSchema = await getInstanceSchemaFromProperties(workspaceNounInternal.properties);

    //  Get the Workspace Plugin Relationships
    //  TODO:  MOST of this should be done in the backend System Habor System!
    const pluginOwnerRels = await haborSDK.searchInstances<SystemEnablement>(token, { nounId: SystemEnablementNoun.id, search: { all: [{ match: { payload: { srcId: { nounId: pluginNoun.id } } } }, { match: { payload: { destId: { nounId: workspaceNoun.id, instanceId: workspace.id } } } } ] } });

    //  Get the Workspace Page Relationships
    const pageRelTerms = pluginOwnerRels.map(rel => ({ any: [{ match: { payload: { destId: rel.payload.srcId }} }, { match: { payload: { destId: { nounId: workspace.nounId, instanceId: workspace.id, type: EntityType.Instance as EntityType.Instance } }} }] }));
    const pageRels = await haborSDK.searchInstances<SpaceAssociation>(token, { nounId: SpaceAssociationNoun.id, search: { all: [{ match: { payload: { srcId: { nounId: PageNoun.id } } } }, { any: pageRelTerms } ] } }); 

    //  Get the Workspace Pages
    const pageTerms = pageRels.map(rel => ({ match: { id: rel.payload.srcId.instanceId } }));
    const pages = await haborSDK.searchInstances<Page>(token, { nounId: PageNoun.id, search: { any: pageTerms }});

    //  Get the Menu
    //  TODO:  This should be injected with a Plugin!!
    const menu = await getMenu(workspace, token);

    this.setState({ workspaceSchema, pages, menu });

    //  Get Style 
    //  TODO:  We shouldn't have to hard-code this!  We should be able to get this through an Addon, Remote Property, etc... SOME registration point.
    //         ObjectStyle is attached with a "Remote Proerty".  That prorty should be linked to some Addon?  I would suggest, that we're "Enabling" an Addon for an Object.  Perhaps we should explicitly call the "Enable" method?
    //         It's tricky, because some Addons may be enabled by ALL Nouns?  In this case, this "Style Addon" or setting is applied to "Containers".
    // const style = await haborSDK.searchInstances(token, { nounId: 'objectstyle', search: { match: {  } } })
  }

  //  TODO:  Make the "BasicInfo" type with a "Subtraction" type.
  public submitBasicInfo = (basicInfo: any, schema: SDTObject) => {
    console.log(basicInfo);
  }

  public render = () => {

    //  Unpack
    const { workspace, token } = this.context;
    const { workspaceSchema, menu } = this.state;

    //  TODO:  Consider using a routing framework, to deep link to a particular screen?
    //  TODO:  Eventually generate the Settings forms from the registered App Elements.  
    //  TODO:  For things like "Menu" which may eventually be part of the primitive, can we allow custom form-groups?

    //  Guard
    //  TODO:  Make a generic loading + error handler pattern!
    if (!workspaceSchema || !menu) { return null }

    // TODO:  Enforce DavelForm metadata type where applicable.
    // CONSIDER:  Workspace style options 

      //  Make the "Basic Schema"
      const basicSchema: SDTObject = { ...workspaceSchema, properties: workspaceSchema.properties };
      basicSchema.properties ? delete basicSchema.properties['menu'] : undefined;
      basicSchema.properties ? delete basicSchema.properties['plugins'] : undefined;
      basicSchema.properties ? delete basicSchema.properties['create'] : undefined;

      const initialValues = { ...workspace.payload };
      delete initialValues["plugins"];

      //  Submit Handler
      const handleSubmit = async (value: Workspace, schema: SDTObject) => {

        //  Create Updated Workspace
        const newWorkspace: Workspace = { ...workspace.payload, ...value };

        //  Update the Workspace
        await createOrUpdateInstance(workspaceNoun.id, newWorkspace, token, workspace);
      }

    return (
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <AppHeader buttonIcon={{ name: "plus", type: "font-awesome" }} title={ "Settings" } onPress={ () => navigate(this.props.history, RouteList.Workspaces.Home, { workspace, pageSelection: { pageName: "Instance Creator", props: { nounId: { nounId: "page", type: EntityType.Noun } } } }) } />
        <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#f7f7f7' }} contentContainerStyle={{ padding: medSpacer }}>
          <DavelForm metadata={this.context} schema={ basicSchema } value={ initialValues } onSubmit={ handleSubmit } onCancel={ () => null } />
        </ScrollView>
      </View>
    );
  }
}
export const WorkspaceSettings = withRouter(WorkspaceSettingsBase)
