import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { InstanceInternal as HaborInstanceInternal } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { ActivityIndicator, Button, Platform, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { defaultBorderRadius, medSpacer } from '../../../packages/kelp-bar/styles';
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { EntityIdentityPlugin, IdentityInfo } from '../entity-identity-plugin';
import { Entity, EntityPlugin } from "../entity-plugin";
import { EntityListEmitter, EntityListEvents, EntityListItem, EntityWidget } from '../entity-plugin/entity-list';
import { EntityRoute } from '../entity-plugin/entity-navigator';
import { haborSDK } from '../hessia-plugin/config';
import { WidgetItem } from '../hessia-plugin/dynamic-component';
import { SimpleBox } from '../page-plugin/page-widget';
import { SystemPlugin } from '../system-plugin/system-plugin';
import { EdgeGraphPage } from './edge-graph';


//  CONSIDER:  MAYBE we just accept an ID for the itnernal OR a partial for the fieldset? Hmm

//  CONSIDER:  INJECT the handlers.. instead of coupling with the component? Hmm.. the "Component" is JUST an identifiable thing!  We CAN use the modifier pattern and stuff to make it work.. SAMe thing I was doing with Aggregate trackers and shit ugh!  PLUS Swift extensions hm! MUCH like XML HM!  NOW I could speak to XML and all that with the language of semiotics hm!!!
export const EdgeWidget = ({ edgeInternal, edgePartial, edgePlugin, entityPlugin, onCreated, onUpdated, onChange }: { edgeInternal?: NounServiceInstanceInternal<Edge>, entityPlugin: EntityPlugin, edgePartial?: Partial<Edge>, edgePlugin: EdgePlugin, onCreated?: (edgeInternal: NounServiceInstanceInternal<Edge>) => void, onUpdated?: (edgeInternal: NounServiceInstanceInternal<Edge>) => void, onChange?: (edgeInternal: NounServiceInstanceInternal<Edge>) => void }) => {

  //  CONSIDER:  This is a COMMON pattern.  Can we generalize??  I don't see why not! Inheriting kinda? hmm.. with fallback? Hmm
  const initialEdge: Edge = {
    metadata: edgeInternal?.payload.metadata || edgePartial?.metadata || "",
    destEntityAddress: edgeInternal?.payload.destEntityAddress || edgePartial?.destEntityAddress || "",
    srcEntityAddress: edgeInternal?.payload.srcEntityAddress || edgePartial?.srcEntityAddress || "",
    differentiatorEntityAddress: edgeInternal?.payload.differentiatorEntityAddress || edgePartial?.differentiatorEntityAddress || "",
    differentiatorText: edgeInternal?.payload.differentiatorText || edgePartial?.differentiatorText || ""
  };

  //  NOTE:  We USED to store the ID.  NOW, for Entity we're JUST stringifying and storing the entire entity.  It represents the ADDRESS MM!!  I LIKE that.  We CAN have an entity cache too though hmm..

  const [edge, setEdge] = React.useState(initialEdge);
  const [destEntity, setDestEntity] = React.useState<Entity | undefined>(undefined);
  const [srcEntity, setSrcEntity] = React.useState<Entity | undefined>(undefined);
  const [differentiatorText, setDifferentiatorText] = React.useState<string | undefined>(undefined);
  const [differentiatorEntity, setDifferentiatorEntity] = React.useState<Entity | undefined>(undefined);
  const [edgeEntity, setEdgeEntity] = React.useState<Entity | undefined>(undefined);

  const loadEdge = async () => {

    const entity: Entity = { systemId: edgePlugin.serviceName, route: { id: edgeInternal?.id, created: edgeInternal?.created }, metadata: "Test Meta" };

    const _edgeEntity = await entityPlugin.entityService.retrieve(entity);
    setEdgeEntity(entity);

    const _destEntity = JSON.parse(edge.destEntityAddress);
    setDestEntity(_destEntity);

    const _srcEntity = JSON.parse(edge.srcEntityAddress);
    setSrcEntity(_srcEntity);

    if (edge.differentiatorEntityAddress) {
      const _differentiator = JSON.parse(edge.differentiatorEntityAddress);
      setDifferentiatorEntity(_differentiator);
    }

    setDifferentiatorText(edge.differentiatorText);

  }

  React.useEffect(() => {
    if (edge.destEntityAddress && edge.srcEntityAddress) {
      loadEdge();
    }
  }, []);

  return (
    <View style={{ paddingHorizontal: medSpacer, flexDirection: 'row', flex: 1 }}>

      {/* TODO:  Figure out if it's OK to call the loadEntity thing RIGHT after calling setEdge or if we should be using a callback of some sort? */}
      {/* TODO:  Add MULTIPLE options to select an entity hm!  MAYBE make this pluggable hm! */}

      {
        !!srcEntity ? (
          <EntityListItem entity={srcEntity} />
        ) : <ActivityIndicator />
      }

      <View style={{ width: 15 }} />

      {
        !!differentiatorEntity ? (
          <EntityListItem entity={differentiatorEntity} />
        ) : <ActivityIndicator />
      }

      <View style={{ width: 15 }} />

      {
        !!destEntity ? (
          <EntityListItem entity={destEntity} />
        ) : <ActivityIndicator />
      }





      {/* <Text>Edge Entity</Text>
      {
        !!edgeEntity ? (
          <>
            <Deferred func={renderEntityListItem} args={{ destEntity, entityPlugin, entity: edgeEntity }} def={<View style={{ padding: 15, backgroundColor: '#eeeeee', borderRadius: 10 }}><ActivityIndicator /><Text>Destination Entity</Text></View>} />
          </>
        ) : <Text>Loading Edge Entity</Text>
      } */}

      {/* {
        edgeInternal ?
          <Button title="Update" onPress={async () => {
            const _edgeInternal = await edgePlugin.edgeService.update(edgeInternal.id, edge);
            if (onUpdated) { onUpdated(_edgeInternal); }
            if (onChange) { onChange(_edgeInternal); }
          }} /> :
          <Button title="Create" onPress={async () => {
            const _edgeInternal = await edgePlugin.edgeService.create(edge);
            if (onCreated) { onCreated(_edgeInternal); }
            if (onChange) { onChange(_edgeInternal); }
          }} />
      } */}

    </View>
  );
}


//  NOTE:  This is essentially a "Pointer".  hmm

export interface Edge {
  metadata: any;
  destEntityAddress: string;
  srcEntityAddress: string;
  differentiatorEntityAddress: string;
  differentiatorText?: string;
}

//  TODO:  Move this into the Plugin??
export const edgeService = new HaborNounService<Edge>("new_edge", haborSDK);


export interface EdgeProps {
  edges: NounServiceInstanceInternal<Edge>[];
  edgePlugin: EdgePlugin;
  entityPlugin: EntityPlugin;
}
const EdgeList = (props: EdgeProps) => {

  const navigation = useNavigation();

  const [edges, setEdges] = React.useState<NounServiceInstanceInternal<Edge>[]>([])

  const onMount = async () => {
    const edges = await edgeService.retrieveAll();
    //  TODO:  Remove this filter!  I'm having an issue with that one ID!
    const fiteredEdges = edges.filter(edge => edge.id !== "98ea5b39-8975-42cb-8e2e-f65e8d1d4341");
    setEdges(fiteredEdges);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  const edgeElems = edges.map((edge: NounServiceInstanceInternal<Edge>) => {
    return (
      <TouchableOpacity style={{ borderRadius: defaultBorderRadius, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column' }} onPress={() => navigation.navigate("Editor" as never, { edgeNoun: edge } as never)}>
        <EdgeWidget entityPlugin={props.entityPlugin} edgePlugin={props.edgePlugin} edgeInternal={edge} />
      </TouchableOpacity>
    );
  });
  return (

    <PageLoader loading={false}>
      <Page style={{ marginHorizontal: 20 }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Edges</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <ScrollView showsHorizontalScrollIndicator={false}>
          {edgeElems}
        </ScrollView>
      </Page>
    </PageLoader>
  )
}

const TabNav = createBottomTabNavigator()

export const EdgePage = ({ edgePlugin, entityPlugin }: { edgePlugin: EdgePlugin, entityPlugin: EntityPlugin }) => {


  return (
    <TabNav.Navigator initialRouteName="List" {...{ headerMode: "none" }}>
      <TabNav.Screen options={{
        tabBarLabelPosition: 'below-icon',
        tabBarIcon: ({ color, size }) => (
          <MaterialCommunityIcons name="pencil" color={color} size={size} />
        ),
      }} name="Editor" component={({ route }) => <EdgeEditor edgeNoun={route?.params?.edgeNoun} edgePartial={route?.params?.edgePartial} />} />
      <TabNav.Screen options={{
        tabBarLabelPosition: 'below-icon',
        tabBarIcon: ({ color, size }) => (
          <MaterialCommunityIcons name="format-list-bulleted" color={color} size={size} />
        ),
      }}
        name="List" component={() => <EdgeList entityPlugin={entityPlugin} edgePlugin={edgePlugin} edges={[]} />} />

      {
        // TODO:  Make the graph work on Native!
        Platform.OS === 'web' && (
          <TabNav.Screen options={{
            tabBarLabelPosition: 'below-icon',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="network" color={color} size={size} />
            ),
          }}
            name="Graph" component={() => <EdgeGraphPage entityPlugin={entityPlugin} edgePlugin={edgePlugin} edges={[]} />} />
        )
      }

    </TabNav.Navigator>
  );
}

//  NOTE:  Simulates a relationship linking a String and an Entity TAGGED as a "Edge".. hmmm

//  TODO:  Inject into the SCRIPTING system! HM!

export const EdgeEditor = ({ edgeNoun, edgePartial }: { edgeNoun?: NounServiceInstanceInternal<Edge>, edgePartial?: Partial<Edge> }) => {

  const navigation = useNavigation();

  const edge = edgeNoun?.payload;

  //  NOTE:  For Entity, we're going to use ADDRESS instead of ID.

  const [metadata, setMetadata] = React.useState<string>(edgePartial?.metadata || edge?.metadata || "");
  const [srcEntityAddress, setSrcEntityAddress] = React.useState<string>(edgePartial?.srcEntityAddress || edge?.srcEntityAddress || "");
  const [destEntityAddress, setDestEntityAddress] = React.useState<string>(edgePartial?.destEntityAddress || edge?.destEntityAddress || "");
  const [differentiatorEntityAddress, setDifferentiatorEntityAddress] = React.useState<string>(edgePartial?.differentiatorEntityAddress || edge?.differentiatorEntityAddress || "");
  const [differentiatorText, setDifferentiatorText] = React.useState<string>(edgePartial?.differentiatorText || edge?.differentiatorText || "");

  const edgeScope = {
    systemId: "edge"
  }

  const sourceScope = {
    ...edgeScope,
    target: "source"
  }

  const destinationScope = {
    ...edgeScope,
    target: "destination"
  }

  const differentiatorScope = {
    ...edgeScope,
    target: "differentiator"
  }

  const scopeMatch = (targetScope: any, myScope: any) => {
    return (JSON.stringify(targetScope) === JSON.stringify(myScope))
  }

  React.useEffect(() => {

    const onSelect = ({ scope, entity }) => {

      const srcScope = scopeMatch(scope, sourceScope);
      const destScope = scopeMatch(scope, destinationScope);
      const diffScope = scopeMatch(scope, differentiatorScope);

      if (srcScope) {
        navigation.navigate(EdgePlugin.details.id as never, { screen: "Editor", params: { edgeNoun, edgePartial: { ...edgePartial, srcEntityAddress: JSON.stringify(entity) } } } as never);
      }

      if (destScope) {
        navigation.navigate(EdgePlugin.details.id as never, { screen: "Editor", params: { edgeNoun, edgePartial: { ...edgePartial, destEntityAddress: JSON.stringify(entity) } } } as never);
      }

      if (diffScope) {
        navigation.navigate(EdgePlugin.details.id as never, { screen: "Editor", params: { edgeNoun, edgePartial: { ...edgePartial, differentiatorEntityAddress: JSON.stringify(entity) } } } as never);
      }

    };

    //  CONSIDER:  INSTEAD of doing this here... just have it like ahh!  Spin up another INSTANCE of it which can use a callback so we're not re-purposing the GLOBAL instance!  I THINK that will be MUCH more maintainable hm!  This works too, but even so, should have an abstraction for it hmmm... 
    EntityListEmitter.addListener(EntityListEvents.EntitySelected, onSelect);
    return () => {
      EntityListEmitter.removeListener(EntityListEvents.EntitySelected, onSelect);
    }
  }, [])

  return (
    <View style={{ marginTop: 100 }}>

      <Text>Source</Text>
      {
        srcEntityAddress ? <EntityListItem entity={JSON.parse(srcEntityAddress)} /> : <Text>No Source</Text>
      }
      <Button title="Select Source" onPress={() => navigation.navigate("entity-system" as never, { screen: EntityRoute.List, params: { scope: sourceScope } } as never)} />

      {/* REMEMBER:  We can have networks in the DENDRITES!  These can basically compute ANYTHING and ... it's not "in" the dendrites because conceptually it's still separte but physically in terms of location yeh hm! */}
      <Text>Type</Text>
      {
        differentiatorEntityAddress ? <EntityListItem entity={JSON.parse(differentiatorEntityAddress)} /> : <Text>No Differentiator</Text>
      }
      <Button title="Select Differentiator" onPress={() => navigation.navigate("entity-system" as never, { screen: EntityRoute.List, params: { scope: differentiatorScope } } as never)} />

      <Text>Destination</Text>
      {
        destEntityAddress ? <EntityListItem entity={JSON.parse(destEntityAddress)} /> : <Text>No Destination</Text>
      }
      <Button title="Select Destination" onPress={() => navigation.navigate("entity-system" as never, { screen: EntityRoute.List, params: { scope: destinationScope } } as never)} />

      <Text>Metadata</Text>
      <TextInput value={metadata} onChangeText={setMetadata} />

      <Button title={edge ? 'Update' : 'Create'} onPress={edge ? async () => { await edgeService.update(edgeNoun?.id, { metadata, destEntityAddress, srcEntityAddress, differentiatorEntityAddress, differentiatorText }); } : async () => { try { await edgeService.create({ metadata, destEntityAddress, srcEntityAddress, differentiatorEntityAddress, differentiatorText }); } catch (err) { alert(JSON.stringify(err)) } }} />
      <Button title="Delete" onPress={() => edgeNoun?.id ? edgeService.delete(edgeNoun?.id) : alert("No ID for Edge.")} />
    </View>
  );
}




export class EdgePlugin extends CorePluginClass {

  public edgeService = edgeService;

  public serviceName = "edge";

  public static details = {
    name: "Edges Plugin",
    description: "Edges System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id],
    id: "edges"
  }

  // public createEdge = (edge: Edge) => {
  //   cosnt await edgeService.create(edge)
  // }
  public install = async (program: Program, { system, pEntities, authPlugin, entityIdentityPlugin }: { entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin }) => {

    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {

    //   //  Check if the Entity is a Edges
    //   const edge = await edgeService.retrieveByEntityId(entityId);
    //   if (edge) {
    //     api.setText("Edge: " + edge.payload.name);
    //   }

    //   //  Check if there is a edge RELATED.
    //   const edges = await edgeService.retrieveAll();
    //   console.log(entityId);
    //   console.log(edges);
    //   const related = edges.filter(edge => edge.payload.destEntityAddress === entityId);
    //   console.log(related);
    //   for (let i = 0; i < related.length; i++) {
    //     const relatedEdge = related[i];
    //     api.addComponent(() => {
    //       return (
    //         <View>
    //           <Text>Edge</Text>
    //           <Text>{ relatedEdge.payload.name }</Text>
    //         </View>
    //       );
    //     });
    //   }
    // });

    await edgeService.init(authPlugin.token);

    entityIdentityPlugin.registerIdentityProvider({
      id: "edge-entity-identity-provider",
      name: "Edge Entity Identity Provider",
      description: "Provides 'edge' Entity Identity",
      systemId: "edge",
      provide: async (entity: Entity, entityPlugin?: EntityPlugin): Promise<IdentityInfo> => {

        //  TODO:  Figure out why this isn't working.  It's embedded WITHIN the Entity Plugin host, so idk.
        // const entityPlugin = useEntityPlugin();
        // alert(entityPlugin);

        if (!entityPlugin) { return undefined as any; }

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Edge
        const edgeNoun = await this.edgeService.retrieve(entity.route.id);
        // alert(JSON.stringify(edgeNoun))
        const edge = edgeNoun?.payload;

        //  Get Addresses
        // const srcAddress = edge?.srcEntityAddress ? JSON.parse(edge?.srcEntityAddress) : undefined;
        // const destAddress = edge?.destEntityAddress ? JSON.parse(edge?.destEntityAddress) : undefined;
        // const diffAddress = edge?.differentiatorEntityAddress ? JSON.parse(edge?.differentiatorEntityAddress) : undefined;

        //  Get Entities
        const source = edge && edge?.srcEntityAddress;
        const dest = edge && edge?.destEntityAddress;
        const differentiator = edge && edge?.differentiatorEntityAddress;
        const differentiatorText = edge && edge.differentiatorText;

        const entityString = JSON.stringify(entity);

        // alert(differentiatorText)
        return {
          type: "edge",
          icon: { name: "arrow-forward", type: "material" },
          iconColor: "#727643",
          iconBackgroundColor: "#647486",
          name: "Edge",
          description: "An Edge",
          component: () => {

            return (
              <>
                {
                  source ?
                    source == entityString ?
                      <Text>SAME SOURCE</Text> :
                      <EntityWidget entity={JSON.parse(source)} entityPlugin={entityPlugin} />
                    :
                    <Text>No Source</Text>
                }

                {
                  differentiator ?
                    differentiator == entityString ?
                      <Text>SAME TYPE</Text> :
                      <EntityWidget entity={JSON.parse(differentiator)} entityPlugin={entityPlugin} />
                    :
                    <Text>No Differentiator</Text>
                }

                {
                  dest ?
                    dest == entityString ?
                      <Text>SAME DESTINATION</Text> :
                      <EntityWidget entity={JSON.parse(dest)} entityPlugin={entityPlugin} />
                    :
                    <Text>No Destination</Text>
                }



                {
                  !!differentiatorText ?
                    <Text>{differentiatorText}</Text> :
                    <Text>No Differentiator Text</Text>
                }
              </>
            )

          }
        } as IdentityInfo;

      }
    });

    pEntities.registerEntityDetailView({
      id: "edge-detail-view",
      name: "Edge Detail View",
      description: "Edge Detail View",
      component: ({ entity }: { entity: Entity }) => {

        // if (entity.systemId !== this.serviceName) { return null; }

        const [edges, setEdges] = React.useState<NounServiceInstanceInternal<Edge>[]>([]);

        const loadEdges = async () => {
          const strEntity = JSON.stringify(entity);
          const allEdges = await this.edgeService.retrieveAll();
          const _edges = allEdges.filter(edge => (edge.payload.srcEntityAddress === strEntity) || (edge.payload.destEntityAddress === strEntity) || (edge.payload.differentiatorEntityAddress === strEntity));
          // alert(JSON.stringify(__edges));

          //  TODO:  Figure out why this query isn't working!
          // const _edges = await this.edgeService.search({ search: { any: [{ match: { payload: { destEntityAddress: JSON.stringify(entity) } } }, { match: { payload: { srcEntityAddress: JSON.stringify(entity) } } }, { match: { payload: { differentiatorEntityAddress: JSON.stringify(entity) } } }] } })
          setEdges(_edges);
        }

        React.useEffect(() => {
          loadEdges();
        }, [])


        return (
          <WidgetItem name='Edges' color='#eeeeee'>
            {edges.length ? edges.map(edge => {
              const edgeEntity = { systemId: this.serviceName, route: { id: edge.id, created: edge.created }, metadata: "{ test: 'Test Metadata' }" };
              return <EntityWidget entity={edgeEntity} />
            }) : <Text>No Associated Edges</Text>}
          </WidgetItem>
        );
      }
    })


    // const createEdgeEntity = (edge: NounServiceInstanceInternal<Edge>): Entity => {
    //   return ({ systemId: this.serviceName, route: { id: edge.id, created: edgeInternal.created }, metadata: "{ test: 'Test Metadata' }" };);
    // }

    //  Register Entity Handler
    //  TODO:  Handle other CRUD behaviors AND perhaps use a MIRROR instead of a SYNC HM!
    edgeService.emitter.addListener("create", async (edgeInternal: HaborInstanceInternal<Edge>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: edgeInternal.id, created: edgeInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.createEntity(entity);
      alert("Created Entity: " + JSON.stringify(entityInternal));
    })

    edgeService.emitter.addListener("delete", async (edgeInternal: HaborInstanceInternal<Edge>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: edgeInternal.id, created: edgeInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.deleteEntity(entity);
      alert("Deleted Entity: " + JSON.stringify(entityInternal));
    })

    system.registerPrimitiveSystem({
      id: EdgePlugin.details.id,
      name: "Edge",
      description: "Edges Primitive System",
      labels: ["core"],
      component: () => <EdgePage entityPlugin={pEntities} edgePlugin={this} />,
      menuItem: {
        icon: { name: "math-compass", type: "material-community" },
        backgroundColor: "#5d9af0",
        iconColor: "white"
      }
    });



    return this;
  }
}
