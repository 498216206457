import { UserBlock } from 'habor-sdk';
import * as React from 'react';
import { View } from "react-native";
import { HaborReactComponent } from '../../habor-plugin/habor-utils';
import { BlockItem } from './components/block-item';
import { getComponents } from './utils';

//
//  ComponentLayout Component
//

export interface ComponentLayoutProps {
  component: UserBlock;
}

interface ComponentLayoutState {
  // signalNodeResult?: SignalNodeResult;
}

class ComponentLayoutBase extends React.Component<ComponentLayoutProps, ComponentLayoutState> {
  constructor(props: ComponentLayoutProps) {
    super(props);
    this.state = {}
  }

  // componentDidMount = () => {
  //   this.updateSignalNodeMap();
  // }

  // public componentWillReceiveProps = () => {
  //   this.updateSignalNodeMap();
  // }

  // public updateSignalNodeMap = async () => {
  //   const { component } = this.props;
  //   const { frameworkContext: { token } } = this.context;
  //   const signalNodeMap = await getSignalNodeMap(token, component);
  //   this.setState({ signalNodeResult: signalNodeMap });
  // }

  public render = () => {

    const { component: { blockInsts }, component } = this.props;

    const components = getComponents(blockInsts);

    //  IDEA:  We MAY have other blocks that have OTHER layers to which we should configure!  Maybe a DIFFERENT UI medium, or some other data flow??
    return (
      <View>
        {/* <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>LAYOUT</Text>
          <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }}>Layout your "Component" Blocks.</Text>
        </View> */}
        <View>
          {
            components.map(component => {
              return (
                <BlockItem block={ component } />
              );
            })
          }
        </View>
      </View>
    );
  }
}
export const ComponentLayout = ComponentLayoutBase
