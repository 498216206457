const moment = require('moment');
import * as React from 'react';
import { DatePicker } from '../../../kelp-bar/date-picker';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

const DateFieldStyle = {
  borderRadius: 20,
  paddingLeft: 15,
  paddingRight: 15,
  fontFamily: 'Poppins-Medium',
  borderColor: 'black',
  borderWidth: 1,
  height: 40
};

//  TODO:  Support time / date options.
//  TODO:  Should be able to hot swap WITH a context mask!? HM 
//  TODO:  Support time / date options.

//  REFERENCE:  https://github.com/xgfe/react-native-datepicker#readme

export const DateField = ({ value, update }: { value: any, update: (value: any) => void }) => {
  return (
    <DatePicker date={value} onUpdate={(date) => update(moment(date))} />
  );
};

export const sdtDateRenderer = ({ sdt, name, value, update }: SDTRendererParams) => (
    <DavelField required={sdt.required} key={name} name={name}>
      <DateField key={name} value={value} update={update} />
    </DavelField>
);
