import { HaborComponent, NounId, nounIdSchema } from 'habor-sdk';
import * as React from 'react';
import { ModelPluginContext } from '..';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { WorkspaceInstanceList } from '../components/instance-list';
import { ModelSDK } from '../model-sdk';

//
//  InstanceList Primitive Component
//
export interface InstanceListHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    nounId: NounId;
    modelSDK: ModelSDK;
  }
}
export const InstanceListHaborPrimitive = ({ userProps, frameworkProps, modelSDK }: InstanceListHaborPrimitiveProps) => {

  //  Unpack
  const { nounId } = userProps;
  const { context, componentContext } = frameworkProps;

  const entitiesPlugin = React.useContext(ModelPluginContext);

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <WorkspaceInstanceList { ...userProps } entitiesPlugin={ entitiesPlugin } componentContext={ componentContext } frameworkContext={ context } nounId={ nounId } />
    </HaborContainer>
  );
};

// registerPrimitiveHaborComponent("InstanceListHaborPrimitive", InstanceListHaborPrimitive);

export const InstanceListHaborComponent: HaborComponent = {
  name: "InstanceListHaborComponent",
  propsSchema: {
    type: "object", 
    extensible: false, 
    properties: { 
      nounId: nounIdSchema,
      // appEmitter: { type: "any", required: false }  //  TODO:  Seriously consider whether to support passing JS primitives as HaborComponent props!  ESPECIALLY non-serializable props!  MAYBE we can make a Habor-level event system?  Can we pass these to the primitives as "Framework Props"?  This MAY be reasonable given that Workspace is considered a top level entity.
    } 
  },
  element: {
    name: "InstanceListHaborPrimitive",
    props: {
      nounId: { type: "symbol", scopePath: ["props", "nounId"] },
      // appEmitter: { type: "symbol", scopePath: ["props", "appEmitter"] },
      // changePage: { type: "symbol", scopePath: ["props", "appEmitter"] }
    },
    children: []
  }
};

// registerHaborComponent(InstanceListHaborComponent);

// //  Make the Page
// export const entityListPage: Page = {
//   name: "InstanceList",
//   element: { name: 'InstanceListHaborComponent', children: [], props: {  } }
// };
