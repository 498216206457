import { CorePluginClass, Program } from "halia";
import { ComponentPlugin } from "../component-plugin";
import { belowWorkspaceRegister } from "./registers";

export class WorkspacePlugin extends CorePluginClass {

  public static details = {
    name: "Workspace",
    description: "Workspace Plugin",
    dependencies: [ComponentPlugin.details.id],
    id: "workspace"
  }

  public install = (program: Program, { component }: { component: ComponentPlugin }) => {
    component.createRegister(belowWorkspaceRegister);
    return this;
  }
}
