import * as React from 'react';
import { View, ViewProps } from 'react-native';
import { desktopMarginSize, maxWidth, mobileMarginSize, tabletMarginSize } from './constants';
import { useSizes } from './sizes-helper';

//  NOTE:  We can override a thing at the COMPONENT, or with a TYPE or hm!  LOTS of places!  The POINT is.. it's like CSS HM!  The closer the specificity the higher the priority hm!
//  TODO:  Should be able to have various levels of customization here.. like with the paddingHorizontal hm...
//  NOTE:  We CAN produce "another" "instance" of this with somet hings hard-coded hmm...  It's JUST an abstraction, like a template for a strearing wheel or a car etc hm!  The SAME problem exists in "REAL" life hM!!!   To make a new, use a template and indicate changes, use an existing as is, or... yeah add modifiere setc hmm... ugh.  About the INTERPRETERS HM!!  Th eINTERPRETERS will hm!!  

//  REFERENCE:  https://www.w3resource.com/javascript/form/email-validation.php
//  REALIZAITON:  AH!!! THis is ONE interpreation hm!  It's multi-modal and lost of other hm!
export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/.test(mail)) {
    return (true)
  }
  return (false)
}


export interface Settings {
  marginSize: number;
}

export const useSettings = () => {

  const { isDesktop, isTablet, isMobile, sizeConfig: { desktopBreakpoint, tabletBreakpoint } } = useSizes();
  const marginSize = isMobile ? mobileMarginSize : isTablet ? tabletMarginSize : desktopMarginSize;

  return {
    marginSize
  }

}

export interface CenteredViewProps extends ViewProps {
  containerProps?: ViewProps;
}

/**
 * A View Centered in a Full Width Container
 * CONSIDER:  WHY are we creating a new thing JUST for this pattern when we COULD also have ahh!!! It's the SAME as in life!!! It's up to US if we want to name it a duck or a "bird with a bill".  AHH!!!  It's TOTALLY up to US.  We do what's EFFICIENT and our BRAINS do it based on association hm!  We name things when they're COMMON but they ARE a composition of things hm!  SO ... we MAY want to reference this, and then break some of its rules but either way, it's STILL just an encoding!  A POJECTION HM!
 * @param props 
 * @returns 
 */
export const CenteredView = (props: CenteredViewProps) => {

  const { isDesktop, isTablet, isMobile, sizeConfig: { desktopBreakpoint, tabletBreakpoint } } = useSizes();
  const { marginSize } = useSettings();

  const containerProps = props.containerProps || {};
  const containerStyle = containerProps.style || {};
  const centeredViewStyle = props.style || {};

  return (
    <View {...props.containerProps} onLayout={props?.containerProps?.onLayout} style={[{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }, containerStyle]}>
      <View style={[{ maxWidth, width: '100%', height: '100%' }, centeredViewStyle]}>
        {props.children}
      </View>
    </View>
  );
}