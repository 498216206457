import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ActivityIndicator, ScrollView } from 'react-native';
import { primaryColor } from '../../../constants';
import { getWork } from '../../../utilities';
import { WorkList, WorkListPaged } from "../../gallery";
import { WorkDetail } from '../../work-detail';
import { CreationEditor as NewCreation } from "./creation-editor";

const WorksNavigator = createStackNavigator();

export const CreationNavigator = () => {

  return (
    <WorksNavigator.Navigator initialRouteName="Creations" screenOptions={{ headerMode: 'hidden', cardStyle: { flex: 1, backgroundColor: 'white' } }}>
      <WorksNavigator.Screen name="New Creation" component={NewCreation} />
      <WorksNavigator.Screen name="Creations" component={() => <WorkListPaged pageNumber={1} showOwned={true} />} />
      <WorksNavigator.Screen name="WorkDetail" component={({ route }) => {
        const [work, setWork] = getWork(route?.params?.creationId);
        if (!work) { return <ActivityIndicator color={ primaryColor } /> }
        return <ScrollView style={{ flex: 1 }}>
          <WorkDetail work={ work } setWork={ setWork } />
        </ScrollView>
}} />
    </WorksNavigator.Navigator>
  );
}
