import { HaborComponent, NounId, nounIdSchema } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { haborSDK } from '../../hessia-plugin/config';
import { InstanceCreator } from '../entity/instance-creator';
import { HaborModelSDK } from '../model-sdk';

//
//  InstanceCreator Primitive Component
//

export interface InstanceCreatorHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    nounId: NounId;
    initial?: any;
  }
}
export const InstanceCreatorHaborPrimitive = ({ userProps, frameworkProps }: InstanceCreatorHaborPrimitiveProps) => {

  const { context } = frameworkProps;
  const { nounId } = userProps
  const modelSDK = new HaborModelSDK(haborSDK, context.token);

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <InstanceCreator modelSDK={modelSDK} nounId={nounId} />
    </HaborContainer>
  );
};


//
//  InstanceCreator Habor Component
//

export const InstanceCreatorHaborComponent: HaborComponent = {
  name: "InstanceCreatorHaborComponent",
  propsSchema: {
    type: "object",
    extensible: false,
    properties: {
      nounId: { ...nounIdSchema, required: true },
      initial: { type: 'any', required: false }  //  TODO:  The type should actually conform to the incoming noun instance schema.
    }
  },
  element: {
    name: "InstanceCreatorHaborPrimitive",
    props: {
      nounId: { type: "symbol", scopePath: ["props", "nounId"] }
    },
    children: []
  }
};
