/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstimateCosts
 */
export interface EstimateCosts {
    /**
     * 
     * @type {string}
     * @memberof EstimateCosts
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    shipping: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    digitization: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    additionalFee: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    fulfillmentFee: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    vat: number;
    /**
     * 
     * @type {number}
     * @memberof EstimateCosts
     */
    total: number;
}

export function EstimateCostsFromJSON(json: any): EstimateCosts {
    return EstimateCostsFromJSONTyped(json, false);
}

export function EstimateCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstimateCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'subtotal': json['subtotal'],
        'discount': json['discount'],
        'shipping': json['shipping'],
        'digitization': json['digitization'],
        'additionalFee': json['additional_fee'],
        'fulfillmentFee': json['fulfillment_fee'],
        'tax': json['tax'],
        'vat': json['vat'],
        'total': json['total'],
    };
}

export function EstimateCostsToJSON(value?: EstimateCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'subtotal': value.subtotal,
        'discount': value.discount,
        'shipping': value.shipping,
        'digitization': value.digitization,
        'additional_fee': value.additionalFee,
        'fulfillment_fee': value.fulfillmentFee,
        'tax': value.tax,
        'vat': value.vat,
        'total': value.total,
    };
}

