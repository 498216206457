import { BlockInst, FrameworkContext, SDTAny, SDTObject, UserBlock } from 'habor-sdk';
import * as React from 'react';
import { View } from 'react-native';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { BlockInstItem } from './components/block-inst-item';
import { SignalView } from './components/signal-view';
import { resolveBlock } from './models/blocks';
import { DuplicateSignalDefinitionError, SignalNodeError, UnmappedInputSignalError, UnmappedOutputSignalError } from './models/errors';

//
//  SignalNode Definition
//

//  CONSIDER:  Perhqaps "signals" are really just variables?  MAYBE we should be able to render this into more conventional code form and vice-versa???

/**
 * Each "SignalNode" is matched to a "Signal", which is a "Variable" in the current scope.  MAYBE with some unique features, like single-binding?  OR, perhaps it acts just like a variable.
 */
export interface SignalNode {
  signalId: string;  //  NOTE:  The "signalId" is just the concatenation of the variable reference.
  definitions: BlockInst[];
  references: BlockInst[];
  dependencies: SignalNode[];
  errors: SignalNodeError[];
  isComponentInput?: boolean;
  isComponentOutput?: boolean;
}

//
//  SignalNode Utilities
//

export type SignalNodeMap = { [signalId: string]: SignalNode };
export interface SignalNodeResult {
  valid: boolean;
  errors: SignalNodeError[];
  signalNodeMap: SignalNodeMap;
}

//  TODO:  Support CUSTOM, user-specified Block signals? 

export const processComponentInputSchema = (signalNodeMap: SignalNodeMap, errors: SignalNodeError[], inputSchema?: SDTObject) => {
  if (inputSchema && inputSchema.properties) {
    Object.keys(inputSchema.properties).forEach(inputName => {

      //  Get the Signal ID
      //  NOTE:  The signal ID is the concatenation of "input" and the input name.
      const signalId = ['input', inputName].join(".");

      //  NOTE:  We do not need to check existing, because we're iterating an Object's keys.  There can ONLY be one of each.

      //  Process New Input Signal
      const inputSignalNode: SignalNode = {
        signalId,
        definitions: [],
        references: [],
        dependencies: [],
        errors: [],
        isComponentInput: true
      };

      //  Add the SignalNode to the Map
      signalNodeMap[signalId] = inputSignalNode;
    });
  }
}

export const processComponentOutputSchema = (signalNodeMap: SignalNodeMap, errors: SignalNodeError[], outputSchema?: SDTObject) => {
  if (outputSchema && outputSchema.properties) {
    Object.keys(outputSchema.properties).forEach(outputName => {

      //  Get the Signal ID
      //  NOTE:  The signal ID is the concatenation of "output" and the output name.
      const signalId = ['output', outputName].join(".");

      //  NOTE:  We do not need to check existing, because we're iterating an Object's keys.  There can ONLY be one of each.

      //  Process New Output Signal
      const outputSignalNode: SignalNode = {
        signalId,
        definitions: [],
        references: [],
        dependencies: [],
        errors: [],
        isComponentOutput: true
      };

      //  Add the SignalNode to the Map
      signalNodeMap[signalId] = outputSignalNode;
    });
  }
}

export const processInputSchema = (blockInst: BlockInst, inputSignalNodes: SignalNode[], signalNodeMap: SignalNodeMap, errors: SignalNodeError[], inputSchema?: SDTObject) => {
  if (inputSchema && inputSchema.properties) {
    Object.keys(inputSchema.properties).forEach(inputName => {

      const inputProp: SDTAny = inputSchema.properties ? inputSchema.properties[inputName] : {};

      //  Check for a Mapping (from BlockInst -> Block)
      //  NOTE:  Think of the mapping like passing params to a function.  The param passed to the function doesn't have to have the same name!
      //  TODO:  Consider doing this in an earlier "compilation" pass?
      if (blockInst.input[inputName] == undefined) {
        if (inputProp.required) {
          const unmappedInputError = { type: "unmapped-input-signal", blockInst, inputName } as UnmappedInputSignalError;
          errors.push(unmappedInputError);
        }
        return;
      }

      //  Get the Value Reference
      const valueReference = blockInst.input[inputName];

      //  Check Constant (in which case we can skip this iteration)
      if (valueReference.type == "constant") { return; }

      //  NOTE:  Becuase it's not a constant, we assume it's a signal.

      //  Get the Signal ID
      const signalId = valueReference.signalId;

      //  Update the Reference (if the signal exists)
      const existingSignalNode = signalNodeMap[signalId];
      if (existingSignalNode != undefined) {
        //  CONSIDER:  Instead of referencing the block instance, consider referencing the specific block instanc input.  This way, if the same signal is used by more than one block input, we'll have that tracked.
        existingSignalNode.references.push(blockInst);
      }

      //  Process New Input Signal
      const inputSignalNode: SignalNode = {
        signalId,
        definitions: [],
        references: [blockInst],
        dependencies: [],
        errors: []
      };

      //  Add the SignalNode to the Map and Input Signal List (for this BlockInst)
      signalNodeMap[signalId] = inputSignalNode;
      inputSignalNodes.push(inputSignalNode);
    });
  }
}

export const processOutputSchema = (blockInst: BlockInst, inputSignalNodes: SignalNode[], signalNodeMap: SignalNodeMap, errors: SignalNodeError[], outputSchema?: SDTObject) => {
  if (outputSchema && outputSchema.properties) {
    Object.keys(outputSchema.properties).forEach(outputName => {

      const outputProp: SDTAny = outputSchema.properties ? outputSchema.properties[outputName] : {};

      const output = blockInst?.output;

      //  Check for a Mapping (from BlockInst -> Block)
      //  NOTE:  Think of the mapping like passing params to a function.  The param passed to the function doesn't have to have the same name!
      if (output && (output[outputName] == undefined)) {
        if (outputProp.required) {
          const unmappedOutputError = { type: "unmapped-output-signal", blockInst, outputName } as UnmappedOutputSignalError;
          errors.push(unmappedOutputError);
        }
        return;
      }

      //  Get the Value Reference
      const valueReference = output[outputName];

      //  Check Constant (in which case we can skip this iteration)
      if (valueReference.type == "constant") { return; }

      //  Get the Signal ID
      const signalId = valueReference.signalId;

      //  TODO-DOCS:  Make a DIAGRAM showing the relationship between these things!?  It's getting confusing!?  Should be able to look and QUICKLY see WHICH systems / sub-systems are involved and their interaction? Hmm!?  MAYBE with INTERFACES for the systems and additional diagrams for each system?  AHH!!  PERHAPS can can auto-generate docs and APIs and stuff for Hessia system!??? DAMN!!!

      //  Check Existing
      const existingSignalNode = signalNodeMap[signalId];
      if (existingSignalNode != undefined) {

        //  Check Existing Definitions
        if (existingSignalNode.definitions.length > 0) {
          const duplicateSignalError = { type: "duplicate-signal-definition", signalId } as DuplicateSignalDefinitionError;
          errors.push(duplicateSignalError);
          existingSignalNode.errors.push(duplicateSignalError);
        }

        //  Add the Definition
        existingSignalNode.definitions.push(blockInst);

        //  NOTE:  Here we add the input nodes required to generate this output.  We already know they'll be created if the user added the inputs, because this happens in the "input" loop above.
        existingSignalNode.dependencies.push(...inputSignalNodes);

        return;
      }

      //  Process the New Signal
      signalNodeMap[signalId] = {
        signalId,
        definitions: [blockInst],
        references: [],
        dependencies: inputSignalNodes,
        errors: []
      }
    });
  }
}

/**
 * Return a hierarchy of Nodes (BlockInsts), along with a list of errors (empty if valid).
 */
export const getSignalNodeMap = async (token: string, component: UserBlock): Promise<SignalNodeResult> => {

  //  Unpack
  const { blockInsts, inputSchema: componentInputSchema, outputSchema: componentOutputSchema } = component;

  //  Define Error List
  const errors: SignalNodeError[] = [];

  //  Define the SignalNodeMap
  const signalNodeMap: SignalNodeMap = {};

  //  Process Component I/O (these are all considered signals)
  processComponentInputSchema(signalNodeMap, errors, component.inputSchema);
  processComponentOutputSchema(signalNodeMap, errors, component.outputSchema);

  //  Iterate each BlockInst
  for (const blockInst of blockInsts) {

    //  Get the Block
    const block = await resolveBlock(token, blockInst.blockId);
    if (!block) {
      throw `The specified block was not found: '${ blockInst.blockId }'`;
    }

    //  Unpack the Block
    const { outputSchema, inputSchema } = block.payload;

    //  Create a SignalNode for each Input (or add a reference to an existing)
    const inputSignalNodes: SignalNode[] = [];
    processInputSchema(blockInst, inputSignalNodes, signalNodeMap, errors, inputSchema);

    //  Create a SignalNode for each Output (or add a definition to an existing)
    processOutputSchema(blockInst, inputSignalNodes, signalNodeMap, errors, outputSchema);
  }

  //  Generate the Result
  return {
    valid: errors.length ? false : true,
    errors,
    signalNodeMap
  }
}

export interface SignalInfo {
  signalId: string;
  sourceBlockInstId: string;

}

//
//  DataFlow Component
//

export interface DataFlowProps {
  component: UserBlock;
  frameworkContext: FrameworkContext;
  updateBlock: (block: UserBlock) => void;
  signalNodeResult: SignalNodeResult;
}

interface DataFlowState {
  selectInput: boolean;
}
class DataFlowBase extends React.Component<DataFlowProps, DataFlowState> {
  constructor(props: DataFlowProps) {
    super(props);
    this.state = {
      selectInput: false
    }
  }

  //  TODO-IMPORTANT:  The BLOCK is NOT being persisted in STATE!  This means we're changing the PROP value... I DON'T think we should do that because it's not being tracked by React, which causes it to NOT re-render!?
  private handleDelete = (blockInstToDelete: BlockInst) => {

    //  Unpack
    const { component } = this.props;
    
    //  Duplicate
    const newBlock = { ...component };
    const { blockInsts } = newBlock;
  
    //  Find the Instance
    const instIndex = blockInsts.findIndex(blockInst => blockInst.id == blockInstToDelete.id);

    if (instIndex == -1) {
      throw new Error(`Cannot delete the block instance.  No block instance found with the given block inst id: '${ blockInstToDelete.id }'`);
    }

    //  Update the List
    //  TODO:  Consider giving Component CRUD responsibility to a higher order component and updating immutably.
    //         IN OTHER WORDS, perhaps we should use an explicit ARRAY / COLLECTION API with aditional constraints for the block dynamic?

    blockInsts.splice(instIndex, 1);

    //  Update the Block
    this.props.updateBlock(newBlock);
  }

  public render = () => {

    const { component: { blockInsts }, component, frameworkContext, frameworkContext: { token } } = this.props;
    

    const { signalNodeResult } = this.props;

    //  CONSIDER:  Prevent a connection to a CHILD signal and avaoid acyclic graphs?  A signal must be defined before it can be referenced?  Or... maybe not... maybe it's just undefined and then it gets its value.
    //  UPDATE:  I DO think we want to permit acyclic!?  Hmm.. Imagine the case where you want TWO buttons to toggle the SAME state.  There MAY and probalby is a way to do this without acyclic, even if that means changing the event system, etc?? Hmm.. BUT maybe this is OK FOR NOW?

    return (
      <View style={{ flex: 1 }}>
        
        <SignalView frameworkContext={ frameworkContext } block={ this.props.component } updateBlock={ this.props.updateBlock }  />
        <View style={{ height: 2, backgroundColor: '#eeeeee', width: '100%', marginVertical: 20 }} />
        {
          blockInsts.map(blockInst => <BlockInstItem frameworkContext={ frameworkContext } style={{ marginBottom: medSpacer }} signalNodeMap={ signalNodeResult.signalNodeMap } blockInst={ blockInst } onDelete={ this.handleDelete } />)
        }
      </View>
    );
    //  NOTE:  It CAN be really helpful to allow the user to "sketch" out pieces of the app WITHOUT requiring it to "compile" at all times!
    return 
  }
}
export const DataFlow = DataFlowBase
