import { HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { View } from 'react-native';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { AddonHandler } from '../../model-plugin/entity/instance-creator';
import { AddonHaborComponentProps, AddonProps, AddonUserProps } from '../access-plugin/access-addon';


interface IdentityAddonHandlerProps extends AddonProps { }

interface IdentityAddonHandlerState { }

class IdentityAddonHandler extends React.Component<IdentityAddonHandlerProps, IdentityAddonHandlerState> {

  constructor(props: any) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext, addon: { schema }, setValue, value } = this.props;
    const { frameworkContext: { changePage, token, user } } = this.props;

    return (
      <View style={{ flex: 1, padding: medSpacer }}>
        <DavelForm autoSubmit={true} schema={schema} onSubmit={setValue} onCancel={() => alert("canceled")} metadata={{ token, user, frameworkContext }} value={value} />
      </View>
    );
  }
}

//
//  IdentityAddonHandler Primitive Component
//
interface IdentityAddonHandlerHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: AddonUserProps
}
export const IdentityAddonHandlerHaborComponentPrimitive = ({ userProps: { addon, setValue, value }, frameworkProps }: IdentityAddonHandlerHaborComponentPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <IdentityAddonHandler setValue={setValue} addon={addon} value={value} frameworkContext={context} componentContext={componentContext} />
    </HaborContainer>
  );
};


export const IdentityAddonHandlerHaborComponent: HaborComponent = {
  name: "IdentityAddonHandlerHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "IdentityAddonHandlerHaborComponentPrimitive",
    props: AddonHaborComponentProps,
    children: []
  }
};

//  Define the "Identity" Addon
export const identityAddon: AddonHandler = {
  id: "identity",
  name: "Identity",
  description: "Manage Object Ownership",
  className: "identity",
  icon: { name: "id-badge", type: "font-awesome" },
  component: IdentityAddonHandlerHaborComponent
}
