import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import {
  Button, FlatList, Image, ImageBackground, Pressable, ScrollView, Text, View
} from "react-native";
import { Picker } from "../../../packages/kelp-bar/picker";
import { primaryFontFamilyHeavy } from "../../../packages/kelp-bar/styles";
import { api } from "../bas-sdk";
import { GalleryButton } from "../components/common";
import { GroupCard } from "../components/group-card";
import { GalleryHeader } from "../components/header";
import { defaultBorderRadius, Paragraph, SubHeader } from "../constants";
import { CenteredView, useSettings } from "../content-view";
import { WorkProduct } from "../openapi";
import { useCart } from "../providers/cart.provider";
import { useSizes } from "../sizes-helper";
import { deleteProduct, getDefaultImageUrl, getGalleryApi, getMockupExtra, getPreviewUrl, getProductIdFromWorkProduct, getSyncProductResult, getSyncVariants, isAdmin, usdFormatter, useCognitoToken } from "../utilities";





export const getWorkProduct = (workProductId: string) => {

  // const token = useCognitoToken();

  // const api = getGalleryApi(token?.getJwtToken());

  const [workProduct, setWorkProduct] = React.useState<WorkProduct | undefined>(undefined);

  const _getWorkProduct = async () => {
    try {
      const _workProduct = await api.workProductsControllerFindOne({ id: workProductId });
      setWorkProduct(_workProduct);
    } catch (err) {
      alert(`An error occurred while attempting to retrieve WorkProduct '${workProductId}'.`);
      alert(err)
      alert(err.message)
    }
  }

  React.useEffect(() => {
    _getWorkProduct();
  }, [workProductId]);

  return workProduct;
}

export const ProductDetail = ({ workProductId }: { workProductId: string }) => {

  const workProduct = getWorkProduct(workProductId);

  // TOOD:  Make a custom ActivityIndicator which automatically shows context information hm!  SHould hoave debugging tools to access context mm!


  const [taxCode, setTaxCode] = React.useState<string>("txcd_30011000");  //  Default to 'Clothing and footwear'

  // const [price, setPrice] = React.useState<string | undefined>();

  const [printfulProductResult, setPrintfulProductResult] = React.useState<any>();

  const [selectedExtra, setSelectedExtra] = React.useState<any | undefined>(undefined);

  const syncProductResult = getSyncProductResult(workProduct);
  const productId = getProductIdFromWorkProduct(workProduct);

  const syncVariants = getSyncVariants(syncProductResult);

  const [selectedVariantId, setSelectedVariantId] = React.useState<number | undefined>();

  const getPrintfulProduct = async () => {
    if (!productId) { return; }
    const _product = await api.productsControllerFineOne({ id: productId });
    setPrintfulProductResult((_product as any));
  }
  React.useEffect(() => {
    if (workProduct) {
      getPrintfulProduct();
      setSelectedVariantId(syncVariants[0].id);
    }
  }, [workProduct])

  React.useEffect(() => {
    if (printfulProductResult) {

    }
  }, [printfulProductResult])

  const cart = useCart();

  const selectedVariant = syncVariants.find(_variant => _variant.id === selectedVariantId);

  const settings = useSettings();

  const navigation = useNavigation();

  const _deleteProduct = async () => {
    await deleteProduct(workProduct);
    navigation.goBack();
    window.location.reload(false);
  }

  const syncProduct = async () => {
    if (!workProduct) { return }
    try {
      const _product = await api.workProductsControllerImport({ body: { workId: workProduct.workId, syncProductId: workProduct.syncProductId, stripeTaxCode: taxCode } });
      alert("Synced!!")
    } catch (err: any) {
      alert("Failed to import product" + JSON.stringify(err.message))
    }
  }

  const admin = isAdmin();
  const { isDesktop } = useSizes();

  const imageUrl = getPreviewUrl(syncVariants, workProduct);
  const extras = getMockupExtra(workProduct, selectedVariant);

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />

      <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

        <CenteredView style={{ paddingHorizontal: settings.marginSize, paddingVertical: 15 }}>

          {/* Product Info */}
          <View style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>

            {/* Left Panel */}
            <View style={{ borderRadius: 5, flex: isDesktop ? 2 : undefined, backgroundColor: 'white', overflow: 'hidden' }}>

              {/* Image */}
              {
                imageUrl ?
                  <ImageBackground
                    style={{ flex: 1, minHeight: 500, backgroundColor: '#f4f4f4', borderRadius: defaultBorderRadius }}
                    resizeMode="contain"  //  TODO:  Use "contain" once we're generating PNG mockups.
                    source={{ uri: selectedExtra ? selectedExtra.url : imageUrl }}
                  /> : null
              }

              <View style={{ height: 10 }} />

              <FlatList
                data={[{ url: imageUrl }, ...(extras || [])]}
                renderItem={({ item: extra }) => <Pressable style={{ width: 150, backgroundColor: '#f4f4f4', borderRadius: defaultBorderRadius }} onPress={() => setSelectedExtra(extra)}><Image source={{ uri: extra.url }} style={{ flex: 1 }} resizeMode='contain' /></Pressable>}
                horizontal={true}
                ItemSeparatorComponent={() => <View style={{ width: 20 }} />}
                style={{ height: 150 }}
              />

              <View style={{ height: 20 }} />

            </View>


            {/* Right Panel */}
            <View style={{ flex: 1, padding: 20, overflow: 'hidden' }}>


              <SubHeader>{printfulProductResult?.product.title}</SubHeader>

              <View style={{ height: 15 }} />

              <Paragraph>{syncProductResult?.sync_product.name}</Paragraph>

              <View style={{ height: 40 }} />


              {/* <Paragraph>{ printfulProduct?.model }</Paragraph>
              <Paragraph>{ printfulProduct?.brand }</Paragraph>
              <Paragraph>{ printfulProduct?.type_name }</Paragraph> */}

              {/* TODO:  Type this stuff! */}

              {/* <SubHeader>{ syncProductResult?.sync_variants[0].product. }</SubHeader> */}

              {/* TODO:  Get the Variants and check if they has size hm...  */}
              {

                // NOTE:  At this point, the only variant differentiator is SIZE.  Color is fixed.
                printfulProductResult?.variants?.length ? (
                  <>
                    <SubHeader>Select Size</SubHeader>
                    <View style={{ height: 15 }} />
                    <Picker
                      items={syncVariants.map(syncVariant => {
                        const variant = printfulProductResult.variants.find(variant => variant.id === syncVariant.variant_id);
                        return { label: variant?.size, value: syncVariant };
                      })}
                      value={selectedVariant}
                      onChange={(variant) => setSelectedVariantId(variant?.id)}
                      style={{ zIndex: 1000 }}
                    />
                  </>
                ) : null
              }

              <View style={{ height: 40 }} />

              <SubHeader>Price</SubHeader>
              <View style={{ height: 15 }} />
              <Paragraph>{selectedVariant?.retail_price ? usdFormatter.format(parseFloat(selectedVariant.retail_price)) : "TBD"}</Paragraph>

              {/* CONSIDER:  We can abstract this by adding items to a column interface so we don't need to do things like sizing hm.. */}
              {/* TODO:  Handle "options" correctly.  I don't think the user should be expliclty choosing a "variant"?  Maybe? Hmm... */}
              {/* TODO:  Build our OWN ontological strucutre around "Variants" that we can map to thigns like "Sizes", etc.  Perhaps standardize for various groups hm! */}


              <View style={{ height: 20 }} />

              {/* Cart */}
              <GalleryButton disabled={!selectedVariantId} title="Add to Cart" onPress={() => cart.addItem(selectedVariant!)} />

              <View style={{ height: 20 }} />
              <View style={{ height: 2, backgroundColor: '#eeeeee' }} />
              <View style={{ height: 20 }} />

              {/* Product Info */}
              <View style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                <Paragraph style={{ fontSize: 18, color: '#555555', lineHeight: 18, fontFamily: primaryFontFamilyHeavy }}>Description</Paragraph>
                <View style={{ height: 15 }} />
                <Paragraph style={{ fontSize: 14, color: '#999999', lineHeight: 14 }}>{printfulProductResult?.product.description}</Paragraph>
              </View>

            </View>
          </View>




          {/* Admin Tools */}
          {
            admin && (

              <>
                <View style={{ height: 20 }} />
                <GroupCard style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>

                  <SubHeader style={{ flex: 1, color: '#444444', fontSize: 16, marginBottom: 20 }}>Product Editor</SubHeader>

                  <GalleryButton title="Edit Product" onPress={() => navigation.navigate("ProductEditor" as never, { product: workProduct } as never)} />

                  <GalleryButton title="Open in Printful" onPress={() => { window.open(`https://www.printful.com/dashboard/sync/update?id=${syncProductResult?.sync_product.id}`) }} />

                  {/* <Picker
                  selectedValue={taxCode}
                  style={{ height: 50, width: 200, borderRadius: defaultBorderRadius, borderColor: '#eeeeee', borderWidth: 1, padding: 15, fontFamily: primaryFontFamily, backgroundColor: 'white' }}
                  onValueChange={(text) => setTaxCode(text)}>
                  <Picker.Item label='Clothing and footwear' value="txcd_30011000" />
                  <Picker.Item label='Consumer Electronics Peripherals/Accessories' value="txcd_34020005" />
                </Picker> */}



                  <Picker
                    items={[
                      { label: "Clothing and footwear", value: "txcd_30011000" },
                      { label: "Consumer Electronics Peripherals/Accessories", value: "txcd_34020005" }
                    ]}
                    value={taxCode}
                    onChange={(value) => setTaxCode(value)}
                  />


                  {/* TODO:  Support product / variant editing. */}

                  {/* <TextField mode="manual" title="Price" onSave={price => setPrice(price)} value={ price } /> */}



                  <GalleryButton title="Sync from Printful" onPress={() => { syncProduct() }} />

                  <Button title="Delete" onPress={() => _deleteProduct()} />

                </GroupCard>
              </>

            )
          }




          {/* Printful Variants */}
          {/* <View style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20 }}>

            <Text style={{ fontFamily: 'Poppins', fontSize: 20, marginVertical: 20 }}>{JSON.stringify(JSON.parse((product as any).printfulProduct).sync_variants)}</Text>

          </View> */}

        </CenteredView>
      </ScrollView>

    </View>
  );
  return <Text>{JSON.stringify(workProduct)}</Text>;
};
