import { useNavigation } from "@react-navigation/core";
import { NamedObject } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as ObjectHash from "object-hash";
import * as React from 'react';
import { Clipboard, Text, TouchableOpacity } from 'react-native';
import { HaborNounService, NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { ManagedComponent } from "../apps-plugin/screens/app-editor.screen";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { SimpleBox } from "../page-plugin/page-widget";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { ResourceNavigator, ResourceRoute } from './resource-navigator';

//  Instead of doing this... UGH... why not let these PARTS of the ID be remotely associated? Hmm.. PERHAPS!  Then even the ID can grow as needed? Hmmm.... 
export interface ResourceIDParams {
  serviceId: string;
  created: string;
  id: string;
}

//  TODO:  Update Halia!
//  CONSIDER:  I MAY want to do this as MODIFIERS so it's MORE than just the TYPE and actually has some validation and ... CATEGORY stuff hm!

export interface Identifiable {
  id: string;
}

export interface Named {
  name: string;
}

//  CONSIDER:  CAN I instead use URLs for this?  I MAY want it stored as OBJECTS though.. NOT just simple strings? Hmm.. I mean ANY JSON can be convereted to string ugh idk.. point is.. there MAY be more strucutre to it than just a linear thing? hsadfkljsj...
export interface Resource {
  systemId: string;
  route: any;
  metadata: any;
}

//  CONSIDER:  We MAY want to use Event Sourcing to store all states.
export const getResourceId = (resource: Resource) => {
  const noMeta = { ...resource };
  delete noMeta["metadata"];
  return ObjectHash.sha1(noMeta);
}


export type ResourceListItemComponent = React.Component<{ resource: NounServiceInstanceInternal<any> }, any, any> | React.FunctionComponent<{ resource: NounServiceInstanceInternal<any> }>;

export interface ResourceListItemAPI {
  setText: (text: string) => void;
  addComponent: (comp: ResourceListItemComponent) => void;  //  NOTE:  Instead of regiseing with a function this module could reach out to a specific place with this stuff.. or it can pull just the encodings/ hmm...
}

//  CONSIDER:  AOK!!  CURRENTLY it's JUST on the ID.. BUT we might want to key off TONSS of stuff, AND that stuff isn't necessarily "returned" to the fucking UI.. SO!! We want the SERVICE to process each perhaps and then determine? O... maybe the services just inject DIRECLT when they feel something is an Resourcet? HMM... Then it's also up to the services to deal with global paging and like yea hmm... I think I like that..hmm  KINDA like the register for the thin gbut soo much more j ljerj
//  TODO:  Consider WRAPPING this in an object so we have insight into the renderer from the Application hmmmm... I'd LIKE to be able to SEE the renderers that have been registered, etc hmmm!!
//  NOTE:  We MAY also owant to make MULTIPLE renderers for various parts of the app hm!
export interface EnityListItemRenderer extends Identifiable, NamedObject {
  renderer: (resource: NounServiceInstanceInternal<Resource>, api: ResourceListItemAPI) => (Promise<void> | void);
}

export interface ResourceService extends ManagedComponent { }

//  REALIZATION:  AHH!!!  When we have a NAME and ID for the "Resource Provider" THAT is itself an INTERNAL resource, and the name and ID COULD be used to map that to a resource hm!  Intereitng... hm!  BUT it's owned bu the OTHER sysmtes and has MEANIGN in this one hm!
//  CONSIDER:  We MAY want to be able to FILTER the resources and yeah hm!  GENERALIZE THAT!!!
export interface ResourceProvider {
  id: string;
  name: string;
  provide: () => Promise<Resource[]>;
}

export class ResourcePlugin extends CorePluginClass {

  private providers: ResourceProvider[] = [];

  public static details = {
    name: "Resources",
    description: "Resource System",
    dependencies: [HessiaPlugin.details.id, SystemPlugin.details.id, AuthPlugin.details.id, HaborPlugin.details.id],
    id: "resources"
  }


  public registerProvider = (provider: ResourceProvider) => {
    this.providers = [...this.providers, provider];
    //  TODO:  Emit event!
  }

  //  CONSIDER:  A generic way to FILTER providers liek we could any other entity?  Maybe this is a filter-point? Hmm... think FUNDAMENATLLY.. it's youre superpower because most people DO NOT!!!  Why not?  They think about what they need to to get ONE thing done ugh!
  public getProviders = () => {
    return this.providers;
  }

  public getResources = async (): Promise<Resource[]> => {
    const resources = await Promise.all(this.providers.map(provider => provider.provide()));
    const result = resources.reduce((prev, curr) => [...prev, ...curr], []);
    return result;

  }

  public install = (program: Program, { hessia, system, authPlugin, habor }: { hessia: HessiaPlugin, system: SystemPlugin, authPlugin: AuthPlugin, habor: HaborPlugin }) => {

    system.registerPrimitiveSystem({
      id: 'resource-system',
      labels: ["core"],
      name: "Resource",
      description: "Resource Primitive System",
      component: () => <ResourceNavigator resourcePlugin={this} />,
      menuItem: {
        icon: { name: "trip-origin", type: "material" },
        backgroundColor: "#2896d1",
        iconColor: "white"
      }
    });

    return this;
  }
}
