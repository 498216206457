import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { Image, ScrollView, View } from "react-native";
import { capitalizeAllWords } from "../../../packages/davel-ui/davel-utils";
import { GalleryHeader } from "../components/header";
import { defaultBorderRadius, Paragraph, SubHeader, SuperHeader } from "../constants";
import { CenteredView, useSettings } from "../content-view";
import { Profile } from "../openapi";
import { useSizes } from "../sizes-helper";
import { Breadcrumbs, getProfile, isAdmin, useCognitoToken, useCognitoUser, useProfile } from "../utilities";
import { WorkList } from "./gallery";



export const ArtistDetail = ({ profile }: { profile?: Profile }) => {

  const navigation = useNavigation();
  const { isDesktop } = useSizes();
  const settings = useSettings();
  const admin = isAdmin();
  const user = useCognitoUser();

  const token = useCognitoToken();



  return (

    <View style={{ flex: 1 }}>

      <View style={{ alignItems: 'center', backgroundColor: 'white', padding: settings.marginSize }}>

        {/* TODO!!!:  AH!  SEE there's a SySTEM freaking... HIDDEN in this code... it's SO hard to find it and understand the FLOW... because it's all coupled!  I WANT to be able to have an "Image Layout" system where I INJECT these necessary things ... This means I need access to the comonents and I'm coupled with this ontological strucutre.  Which DOES exist!  It's just a CHILD etc hm!  WHY aren't more people doing it???  Because, people do what they know mm!!  People do what's "standard" even if it's not the best ugh!!!  People don't THINK too much!!!  People don't TRY! */}
        {/* NOTE:  I HATE that we have to couple things like spacing here and stuff... I reALLY think MOST of this can be done by the system.  And it WILL consider DOMAIN SPECIFIC info related to the abstraction! LIKE I was originally sayingmm!! */}

        <SuperHeader style={{ fontSize: 40, color: '#333333' }}>{capitalizeAllWords(profile?.displayName || "No Name!")}</SuperHeader>
        <View style={{ width: isDesktop ? 30 : settings.marginSize, height: isDesktop ? 30 : settings.marginSize }} />
        <Image
          style={{ borderRadius: defaultBorderRadius, height: 300, width: 300, maxWidth: 350 }}
          resizeMode="cover"
          source={{ uri: profile?.avatar }}
        />
        <View style={{ width: isDesktop ? 30 : settings.marginSize, height: isDesktop ? 30 : settings.marginSize }} />
        <Paragraph style={{ fontSize: 20, color: '#777777', flex: 1 }}>{profile?.description}</Paragraph>


        <View style={{ width: settings.marginSize, height: settings.marginSize }} />
      </View>

      <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%' }} />

      <CenteredView style={{ padding: settings.marginSize, backgroundColor: '#fcfcfc' }}>
        <Breadcrumbs style={{ alignItems: isDesktop ? 'flex-start' : 'center' }} />
      </CenteredView>

      <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%' }} />

      {/* TODO:  Build a system to iterate the stakes and let each one have a display. */}
      <CenteredView containerProps={{ style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, padding: settings.marginSize }}>
        <SubHeader>Creations</SubHeader>
        {
          profile && <WorkList showHeader={false} profile={profile} />
        }
        
      </CenteredView>

      {/* <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%' }} /> */}

      {/* TODO:  Add PRODUCTS once we have the OWNER field on the product... OR we can get it with GraphQL or something VIA the WORK field hm! */}
      {/* <CenteredView containerProps={{ style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, padding: settings.marginSize }}>
        <SubHeader>Products</SubHeader>
        <Shop showHeader={ false } username={ profile.username } />
      </CenteredView> */}

    </View>
  );
};

export const ArtistDetailPage = ({ userId }: { userId: string }) => {
  const [profile] = getProfile(userId);
  return (
    <View style={{ flex: 1, width: '100%', height: '100%' }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <ScrollView style={{ flex: 1 }}>
        <ArtistDetail profile={profile} />
      </ScrollView>
    </View>
  );
}
