import { StackNavigationProp } from '@react-navigation/stack';
import { NounInternal, pluginNoun } from 'habor-sdk';
import * as React from 'react';
import { Text, ViewStyle, ScrollView } from 'react-native';
import { HessiaThemePart, registerThemeMapper, withInjections } from '../../../../packages/injector/injector';
import { AppHeader } from '../../../../packages/kelp-bar/app-header';
import { Page } from '../../../../packages/kelp-bar/page';
import { PageLoader } from '../../../../packages/kelp-bar/page-loader';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { SpacePlugin, SpaceReactContext } from '../../space-plugin/space-plugin';
import { SystemStackParamList } from '../apps.nav';
import { HAppRoute } from '../apps.nav.routes';
import { AppList } from '../components/app-list.component';

//  Here we map from the theme to the props in a STATIC way, BUT in the future I REALLY want to be able to attach MULTIPLE functions like this as COUPLING systems!  That means, between the Theme and THIS component symbol!?  Hmm... registered as a function in the style system? Hm!
const hessiaThemeMapper = (part: HessiaThemePart) => {  //  NOTE:  The TYPE of the theme is DYNAMIC and determined by enabled systems!  It's NOT a statically set thign!? HM!  SO... this means that the PIECES that extract the static part of the theme should be separate!? HM!  So.. MAYBE we have a style resolver fo the MAINN Hessia theme elmeents? HM!  I THiNK that would make sense!
  return {
    style: {
      backgroundColor: part.background,
    },
    fontColor: part.color
  };
}

//  TODO:  Move this to anoher file, AND perhaps symbolize the "AppHeader" thing, AND make a way to view all these systems in CONTEXT so it's not OPAQUE and hard to read!  Need DEV TOOLS for this!?  I THINK Hessia will give us MUCH of that!?  HM!
registerThemeMapper("WorkspaceSystems", hessiaThemeMapper, "hessia");

type AppListScreenNavigationProp = StackNavigationProp<
  SystemStackParamList,
  HAppRoute.SystemList
>;

export interface AppListScreenProps {
  navigation: AppListScreenNavigationProp
  style?: ViewStyle;
  fontColor: string;
  spacePlugin: SpacePlugin;
}

interface AppListScreenState {
  systemNoun?: NounInternal;
}

class AppListScreenBase extends React.Component<AppListScreenProps, AppListScreenState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: AppListScreenProps) {
    super(props);
    this.state = {
      systemNoun: undefined
    }
  }

  //  TODO:  I DON'T think we should need to get the system noun here... it seems a litle derivative... hmmm... JUST doing this so we can pass it.  PERHAPS make an explicit page for SystemBuilder instead of linking to the InstanceBuilder???  Either way, it doesn't really seem like this component's responsibility.
  public componentDidMount = async () => {
    const { frameworkContext: { token } } = this.context;
    const systemNoun = await haborSDK.retrieveNoun(pluginNoun.id, token);
    this.setState({ systemNoun });
  }

  public render = () => {

    //  Unpack
    //  CONCERN:  We have the REACT context here, but we MAY also want HABOR Context???  Hmmm.... I think we can let it happen organically, but we DO want to think of a simple system to mange that!  To do that...probably just start out simple with a "Context" object for Habor / Hessia, POTENTIALLY with a call-stack eventually?
    const { user, token } = this.context.frameworkContext;
    const { systemNoun } = this.state;

    return (
      <PageLoader loading={!systemNoun}>
        <AppHeader buttonIcon={{ name: "plus", type: "font-awesome" }} title={"Apps"} onPress={() => this.props.navigation.navigate(HAppRoute.InstanceBuilder, { noun: systemNoun })} />
        <Page style={{ paddingHorizontal: 30, backgroundColor: 'white' }}>
          
          <ScrollView style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: this.props.style?.backgroundColor, padding: 0, margin: 0 }} contentContainerStyle={{ padding: 1, margin: 0 }}>
            {/* <Text>App List</Text> */}
            <AppList spacePlugin={this.props.spacePlugin} navigation={this.props.navigation} token={token} user={user} />
            {/* TODO:  Support WIDGETS on this page!  ALSO, MAYBE we can support multiple TYPES of widgets in areas around the app? HM!  MAYBE people can make theirr OWN widget types!?HM! */}
          </ScrollView>
        </Page>
      </PageLoader>
    );
  }
}

export const withSpacePlugin = (Comp: typeof React.Component | React.FunctionComponent) => {
  return (props: any) => {
    const spacePlugin = React.useContext(SpaceReactContext);
    return <Comp spacePlugin={spacePlugin} {...props} />
  }
}

export const AppListScreen = withSpacePlugin(withInjections(AppListScreenBase, "WorkspaceSystems"));
