import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { InstanceInternal as HaborInstanceInternal } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, Text, TextInput, TouchableOpacity, View, ScrollView } from 'react-native';
import { ENoun } from '../../enoun-service';
import { Page } from '../../packages/kelp-bar/page';
import { PageLoader } from '../../packages/kelp-bar/page-loader';
import { smallSpacer } from '../../packages/kelp-bar/styles';
import { HaborNounService, NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { EntityIdentityPlugin, IdentityInfo } from './entity-identity-plugin';
import { Entity, EntityPlugin } from "./entity-plugin";
import { haborSDK } from './hessia-plugin/config';
import { SystemPlugin } from './system-plugin/system-plugin';

export interface Note {
  title?: string;
  content?: string;
}

export const NoteWidget = ({ noteInternal, notePartial, notePlugin, entityPlugin, onCreated, onUpdated, onChange }: { noteInternal?: NounServiceInstanceInternal<Note>, entityPlugin: EntityPlugin, notePartial?: Partial<Note>, notePlugin: NotePlugin, onCreated?: (noteInternal: NounServiceInstanceInternal<Note>) => void, onUpdated?: (noteInternal: NounServiceInstanceInternal<Note>) => void, onChange?: (noteInternal: NounServiceInstanceInternal<Note>) => void }) => {

  return (
    <View style={{ paddingHorizontal: smallSpacer, display: 'flex', flexDirection: 'column' }}>

      <View style={{}}>

        <Text>{noteInternal?.payload.title}</Text>

      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text>{noteInternal?.updated}</Text>
        <Text>{noteInternal?.payload.content}</Text>

      </View>

    </View>
  );
}

//  NOTE:  This is essentially a document store which is good for mapping an encoding hm...
export const noteService = new HaborNounService<Note>("note-new", haborSDK);

interface NoteProps {
  notes: NounServiceInstanceInternal<Note>[];
  notePlugin: NotePlugin;
  entityPlugin: EntityPlugin;
}
const NoteList = (props: NoteProps) => {

  const navigation = useNavigation();

  const [notes, setNotes] = React.useState<NounServiceInstanceInternal<Note>[]>([])
  const [selectedNote, setSelectedNote] = React.useState<NounServiceInstanceInternal<Note> | undefined>(undefined);

  const onMount = async () => {
    const notes = await noteService.retrieveAll();
    setNotes(notes);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  const noteElems = notes.map((note: NounServiceInstanceInternal<Note>) => {
    const isSelected = note.id === selectedNote?.id;
    const backgroundColor = isSelected ? '#fafafa' : 'white';
    return (
      // () => navigation.navigate("Editor" as never, { note } as never)
      <TouchableOpacity style={{ borderRadius: 20, backgroundColor, paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => setSelectedNote(note)}>
        <NoteWidget entityPlugin={props.entityPlugin} notePlugin={props.notePlugin} noteInternal={note} />
      </TouchableOpacity>
    );
  });
  return (

    <PageLoader loading={false}>
      <Page style={{ marginHorizontal: 20 }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Notes</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>

          {/* TODO:  Support sizing! */}
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
            {noteElems}
          </ScrollView>


          <View style={{ flex: 3 }}>
            <Text>{selectedNote?.payload.content}</Text>
          </View>
        </View>


      </Page>
    </PageLoader>
  )
}

const Stack = createStackNavigator();

export const NotePage = ({ notePlugin, entityPlugin }: { notePlugin: NotePlugin, entityPlugin: EntityPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} {...{ headerMode: "none" }}>
      <Stack.Screen name="Editor" component={NoteEditor} />
      <Stack.Screen name="List" component={() => <NoteList entityPlugin={entityPlugin} notePlugin={notePlugin} notes={[]} />} />
    </Stack.Navigator>
  );
}

//  NOTE:  Simulates a relationship linking a String and an Entity TAGGED as a "Note".. hmmm

//  TODO:  Inject into the SCRIPTING system! HM!

export const NoteEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const [noteNoun, setNoteNoun] = React.useState<ENoun<Note>>(route?.params?.note);
  const [title, setTitle] = React.useState<string>(noteNoun?.payload.title || "");
  const [content, setContent] = React.useState<string>(noteNoun?.payload.content || "");


  return (
    <View style={{ marginTop: 100 }}>

      <Text>Title</Text>
      <TextInput value={title} onChangeText={setTitle} />

      <Text>Content</Text>
      <TextInput multiline={true} numberOfLines={20} value={content} onChangeText={setContent} />

      <Button title={noteNoun ? 'Update' : 'Create'} onPress={noteNoun ? async () => { await noteService.update(noteNoun?.id, { title, content }); } : async () => { await noteService.create({ title, content }); }} />
      <Button title="Delete" onPress={() => noteService.delete(noteNoun.id)} />
    </View>
  );
}




export class NotePlugin extends CorePluginClass {

  public noteService = noteService;

  public serviceName = "note";

  public static details = {
    name: "Notes Plugin",
    description: "Notes System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id],
    id: "notes"
  }

  public install = async (program: Program, { system, pEntities, authPlugin, entityIdentityPlugin }: { entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin }) => {

    await noteService.init(authPlugin.token);

    entityIdentityPlugin.registerIdentityProvider({
      id: "note-entity-identity-provider",
      name: "Note Entity Identity Provider",
      description: "Provides 'note' Entity Identity",
      systemId: this.serviceName,
      provide: async (entity: Entity, entityPlugin?: EntityPlugin): Promise<IdentityInfo> => {

        if (!entityPlugin) { return undefined as any; }

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Note
        const note = await this.noteService.retrieve(entity.route.id);

        return {
          type: "note",
          icon: { name: "note", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#f5aa20",
          name: "Note",
          description: "A Note",
          component: () => {

            return <NoteWidget noteInternal={note} entityPlugin={entityPlugin} notePlugin={this} />

          }
        } as IdentityInfo;

      }
    });

    //  Register Entity Handler
    noteService.emitter.addListener("create", async (noteInternal: HaborInstanceInternal<Note>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: noteInternal.id, created: noteInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.createEntity(entity);
      alert("Created Entity: " + JSON.stringify(entityInternal));
    })

    noteService.emitter.addListener("delete", async (noteInternal: HaborInstanceInternal<Note>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: noteInternal.id, created: noteInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.deleteEntity(entity);
      alert("Deleted Entity: " + JSON.stringify(entityInternal));
    })

    system.registerPrimitiveSystem({
      id: this.serviceName,
      name: "Note",
      description: "Notes Primitive System",
      labels: ["hessia"],
      component: () => <NotePage entityPlugin={pEntities} notePlugin={this} />,
      menuItem: {
        icon: { name: "note", type: "material-community" },
        backgroundColor: "#f5aa20",
        iconColor: "white"
      }
    });

    return this;
  }
}
