import * as React from 'react';
import { View, ViewProps } from 'react-native';

//  TODO:  Make this CONFIGURABLE.
export const GroupCard = (props: ViewProps) => {
  const style = props.style;
  return (
    <View {...props} style={[{ borderColor: '#eeeeee', borderWidth: 2, borderRadius: 5, padding: 30 }, style]}>
      {props.children}
    </View>
  );
}