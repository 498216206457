import { CognitoUser } from '@aws-amplify/auth';
import { NavigationContainer, NavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from 'halia';
import { HaliaComponentPlugin, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Image, Pressable, ScrollView, Text, TextInput, TouchableOpacity, View, ViewProps, ViewStyle, useWindowDimensions } from 'react-native';
import { Icon } from 'react-native-elements';
import { primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { GalleryButton } from '../gallery/components/common';
import { defaultBorderRadius, defaultShadow, primaryColor } from '../gallery/constants';
import { CenteredView, useSettings } from '../gallery/content-view';
import { useSizes } from '../gallery/sizes-helper';
import { PrivacyScreen, TermsScreen } from './terms';

const url = "https://studio.us7.list-manage.com/subscribe/post?u=37fc984d9adf4cb085f6f2f0e&amp;id=69c6f48a47&amp;f_id=009ba3e4f0";

export const SubscribeForm = (props: ViewProps) => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 700 : 600;
  const navigation = useNavigation();
  const settings = useSettings();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  return (

    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => {

        // const FormSection = () => {
        //   return (
        //     <View style={{ flex: isDesktop ? 1 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center' }}>
        //       <View style={{ height: '100%', width: '100%', borderRadius: defaultBorderRadius, overflow: 'hidden', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} >

        //       </View>
        //     </View>
        //   );
        // }

        return (


          <View style={{ marginTop: 30, width: '100%', maxWidth: 300 }}>

            {/* <Paragraph style={{ alignSelf: 'flex-start', color: '#444444', fontSize: 17, fontFamily: primaryFontFamilyHeavy }}>Name</Paragraph> */}
            <TextInput placeholder='Name' style={{ width: '100%', height: 30, lineHeight: 14, fontSize: 14, borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 25, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 10, fontFamily: "Outfit-SemiBold" }} value={name} onChangeText={text => setName(text)} />

            {/* TODO:  NEEEDDD other ways to subscribe.. freaking GenZ doesn't want EMAIL UGH! */}
            {/* <View style={{ height: 0, width: '100%' }} /> */}

            {/* <Paragraph style={{ alignSelf: 'flex-start', color: '#444444', fontSize: 17, fontFamily: primaryFontFamilyHeavy }}>Email</Paragraph> */}
            <TextInput placeholder='Email' style={{ width: '100%', height: 30, lineHeight: 14, fontSize: 14, borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 25, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 10, fontFamily: "Outfit-SemiBold" }} value={email} onChangeText={text => setEmail(text)} />

            <View style={{ height: 20, width: '100%' }} />

            <GalleryButton style={{ height: 50, borderRadius: 25 }} disabled={status === "sending" || status === "success" || status === "error"} loading={status === "sending"} title={status === "success" ? "🎉  Subscribed!  🎉" : status === "error" ? "An Error Occurred =(" : "Join the Beta"} textStyle={{ fontFamily: "Outfit-SemiBold", fontSize: 20 }} onPress={() => subscribe({ EMAIL: email, FNAME: name })} />

          </View>

        )
      }

      }
    />
  );
}

export const HeaderButton = ({ fontColor, name, onPress, style }: { style?: any, fontColor: any, name: any, onPress: any }) => {

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Text style={{ fontSize: 15, fontFamily: primaryFontFamilyHeavy, color: fontColor, flex: 0 }}>{name}</Text>
    </TouchableOpacity>
  );

}

export const AaroHeader = ({ flat = true, centered = false, transparent = false, mode = 'dark', position = undefined, onMenuPress: _onMenuPress, showButtons, children }: { children?: React.ReactNode, transparent?: boolean, mode?: 'light' | 'dark', position?: "absolute" | "relative" | undefined, onMenuPress?: () => void, showButtons: boolean, centered: boolean, flat: boolean }) => {

  const sizes = useSizes();
  const appState = React.useContext(GalleryAppContext);
  const navigation = useNavigation();
  const { marginSize } = useSettings();

  const backgroundColor = mode === 'dark' ? primaryColor : 'white'; //  (mode === 'dark' ? secondaryColor : 'transparent');
  const primaryForegroundColor = mode === 'dark' ? '#f4f4f4' : '#666666';
  const strongForegroundColor = mode === 'dark' ? 'white' : '#444444';
  const lightForegroundColor = mode === 'dark' ? '#aaaaaa' : '#aaaaaa';
  const highlightColor = mode === 'dark' ? 'white' : primaryColor;

  const containerStyle: ViewStyle | ViewStyle[] = [{ flexShrink: 0, width: '100%', zIndex: 1000, }, flat ? {} : defaultShadow, { backgroundColor: transparent ? 'transparent' : backgroundColor, flexDirection: 'column' }];

  const Wrapper = centered ?
    ({ children }: { children: React.ReactNode }) => <CenteredView containerProps={{ style: containerStyle }}>{children}</CenteredView> :
    ({ children }: { children: React.ReactNode }) => <View style={containerStyle}>{children}</View>;

  const { user } = appState || {};


  const HeaderMenu = () => {
    return (
      <>
        {/* <HeaderButton fontColor={primaryForegroundColor} name="Shop" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Shop" as never) }} />

        <View style={{ width: 50 }} />

        <HeaderButton fontColor={primaryForegroundColor} name="Gallery" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Gallery" as never) }} />

        <View style={{ width: 50 }} />

        <HeaderButton fontColor={primaryForegroundColor} name="Artists" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Artists" as never) }} />

        <View style={{ width: 50 }} /> */}

        {

          user ?
            <>


              <View style={{ width: 15 }} />


            </> :
            <>

              {/* <HeaderButton fontColor={primaryForegroundColor} name="Login" onPress={() => navigation.navigate("Authenticate" as never, { mode: "login" } as never)} /> */}

              <View style={{ width: 50 }} />


              <View style={{ width: 15 }} />

              <TouchableOpacity style={{ paddingHorizontal: 30, paddingVertical: 10, backgroundColor: highlightColor, borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate("Authenticate" as never, { mode: "register" } as never)}>
                <Text style={{ color: backgroundColor, fontFamily: "Outfit-Bold" }}>Join the Beta</Text>
              </TouchableOpacity>

            </>

        }

      </>
    );
  }

  const HeaderRight = () => {
    return <>
      {
        sizes.isTablet || sizes.isMobile ?
          <>
            <View style={{ width: 15 }} />
            <Icon size={40} color='#888888' name="menu" type="entypo" />
          </> :
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }}>
            <HeaderMenu />
          </View>
      }
    </>
  };

  const settings = useSettings();

  return (

    <View style={{ backgroundColor: 'white' }}>
      {/* <View style={{ paddingHorizontal: 30, paddingVertical: 15, flexShrink: 0, flexGrow: 0, width: '100%', backgroundColor: '#f8f8f8', alignItems: 'center', justifyContent: 'center', borderBottomColor: '#dddddd', borderBottomWidth: 1 }}>
        <Paragraph style={{ textAlign: 'center', color: '#555555', fontSize: 13, fontFamily: primaryFontFamily }}>We're beta testing 🥳  You can <a href="https://www.badart.studio/auth?mode=register" target="_blank" rel="noopener noreferrer">Sign-Up</a>, join our <a href="https://www.badart.studio?section=subscribe" target="_blank" rel="noopener noreferrer">Mailing List</a>, or join our <a href="https://www.reddit.com/r/badartstudio/" target="_blank" rel="noopener noreferrer">Reddit Sub</a>.  Challenges, Characters, Remixing, and more coming soon! 🦄</Paragraph>
      </View> */}

      <Wrapper>
        <View style={{ backgroundColor: 'white', paddingVertical: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: centered ? settings.marginSize : 0 }}>

          {/* Header Left */}
          <Pressable onPress={() => (window.location as any) = '/'} style={{ display: 'flex', flexShrink: 0, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center' }}>

            {/* TODO-CRITICAL:  IF you use the scribble, make sure to give CCBY4 credit! 
            REFERENCE:  https://www.figma.com/community/file/1117552508596367158 */}
            <Image source={require('../../assets/stickers/rank_6.png')} resizeMode='contain' style={{ width: 32, height: 32 }} />
            <View style={{ marginLeft: 15, flexDirection: 'row' }}>
              <Text style={{ lineHeight: 22, fontFamily: primaryFontFamilyHeavy, color: strongForegroundColor, fontSize: 18 }}>Aaro</Text>
              <Text style={{ lineHeight: 22, fontFamily: primaryFontFamilyHeavy, color: lightForegroundColor, fontSize: 18 }}>.app</Text>
            </View>

          </Pressable>

          {/* Header Center */}
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }} />


          {/* Header Right */}
          {showButtons && <HeaderRight />}

        </View>
        {children}
      </Wrapper>

    </View>
  );
};


export interface MenuItem {
  name: string;
  onPress: () => void;
}

registerHOCRegister("menu-root-above");

const GalleryStackNavigator = createStackNavigator();

export const GalleryAppContext = React.createContext<GalleryAppState>({} as any);

export const useGalleryApp = () => {
  return React.useContext(GalleryAppContext);
}

interface GalleryAppProps {
  sizes: any;
  onStateChange: any;
  navigation: NavigationProp<any>;
}

interface GalleryAppState {

  scrollPos: number,

  //  Form Fields
  title?: string;
  description?: string;
  screenName?: string;
  email?: string;
  zipCode?: string;
  isBundle: boolean;
  imageOne?: any;
  imageTwo?: any;
  imageThree?: any;
  imageFour?: any;

  //  UI State
  sharePosition?: number;
  aboutPosition?: number;

  //  Submisson
  pendingSubmission?: boolean;
  submissionModal?: boolean;
  submissionError?: boolean;

  //  Navigation
  tos?: boolean;
  pp?: boolean;

  menuVisible: boolean;

  //  TODO:  GENERALIZEEE!! MAYBE use UNIVERSAL Navigation??? idkk.. maybe use HTTP stuf?? hm
  showLogin: boolean;

  user?: CognitoUser;

  path?: string | null;
  queryParams: any;
}

export const IntroSection = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 700 : isTablet ? 600 : 500;
  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <View style={{ backgroundColor: 'white' }}>

      <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column', backgroundColor: 'white' }}>

        <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isDesktop ? 'flex-start' : 'center', padding: 50 }}>


          <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Gamify</Text>
          <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Your Life</Text>



          {/* The OVERALL point is that it's a cognitive artifact, a TOOL, which is used to help you fulfill your potential. */}
          <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', marginLeft: isDesktop ? undefined : 20, paddingHorizontal: isDesktop ? undefined : 10, maxWidth: 400, marginTop: 35, color: '#888888', fontFamily: "Outfit-SemiBold", fontSize: isDesktop ? 22 : 20, letterSpacing: -0.5, textAlign: isDesktop ? 'left' : 'left' }}>Get reassurance for small wins, feel your progress, and take control of your life.</Text>
          {/* Aaro is the place to grow and embark on your personal development journey. */}


          <SubscribeForm />

          {/* <TouchableOpacity onPress={() => navigation.navigate("Gallery" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: "Outfit-Bold", fontSize: 20 }}>Join the Beta</Text>
          </TouchableOpacity> */}


        </View>

        <View style={{ flex: isDesktop ? 1.5 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Image source={require('../../assets/stickers/Aaro Marketing.png')} resizeMode="contain" style={{ width: '120%', height: '120%' }} />
        </View>

      </CenteredView>
    </View>

  );
}

export const Overview = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 800 : 600;
  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <View style={{ backgroundColor: '#fffafc' }}>

      <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

        <View style={{ flex: isDesktop ? 1.5 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Image source={require('../../assets/stickers/Aaro Overview.png')} resizeMode="contain" style={{ width: '120%', height: '120%' }} />
        </View>

        <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isDesktop ? 'flex-end' : 'center', padding: 50 }}>

          {/* The OVERALL point is that it's a cognitive artifact, a TOOL, which is used to help you fulfill your potential. */}
          <View style={{ alignSelf: isDesktop ? 'flex-end' : 'center', marginLeft: isDesktop ? undefined : 20, paddingHorizontal: isDesktop ? undefined : 10, maxWidth: 400, marginTop: 35 }}>

            <Text style={{ letterSpacing: 2, lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#ff4583', fontFamily: "Outfit-Bold", fontSize: isMobile ? 20 : isTablet ? 18 : 16, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>OVERVIEW</Text>
            <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 40 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, textAlign: 'left' }}>How does</Text>
            <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, textAlign: 'left' }}>it work?</Text>


            <Text style={{ color: '#888888', fontFamily: "Outfit-SemiBold", fontSize: isDesktop ? 22 : 20, letterSpacing: -0.5, textAlign: 'left' }}>
              Aaro is a customizable workspace for everything in your life.{'\n\n'}
              Use it to track tasks, manage goals, and build a custom productivity system.  {'\n\n'}
            </Text>
          </View>

          {/* Aaro is the place to grow and embark on your personal development journey. */}

          {/* <TouchableOpacity onPress={() => navigation.navigate("Gallery" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: "Outfit-Bold", fontSize: 20 }}>Join the Beta</Text>
          </TouchableOpacity> */}


        </View>


      </CenteredView>
    </View>

  );
}

export const Customize = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 700 : isTablet ? 600 : 500;
  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <View style={{ backgroundColor: 'white' }}>

      <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column', backgroundColor: 'white' }}>

        <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isDesktop ? 'flex-start' : 'center', padding: 50 }}>


          <Text style={{ letterSpacing: 2, lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#aaaaaa', fontFamily: "Outfit-Bold", fontSize: isMobile ? 20 : isTablet ? 18 : 16, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>CUSTOMIZE</Text>
          <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Make it</Text>
          <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>your own.</Text>



          {/* The OVERALL point is that it's a cognitive artifact, a TOOL, which is used to help you fulfill your potential. */}
          <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', marginLeft: isDesktop ? undefined : 20, paddingHorizontal: isDesktop ? undefined : 10, maxWidth: 400, marginTop: 35, color: '#888888', fontFamily: "Outfit-SemiBold", fontSize: isDesktop ? 22 : 20, letterSpacing: -0.5, textAlign: isDesktop ? 'left' : 'left' }}>
            Build a personal productivity system using our extensive library of extensions and integrations. {"\n\n"}
            Build you own custom extensions for deep control over your productivity.
          </Text>
          {/* Aaro is the place to grow and embark on your personal development journey. */}

          {/* <TouchableOpacity onPress={() => navigation.navigate("Gallery" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: "Outfit-Bold", fontSize: 20 }}>Join the Beta</Text>
          </TouchableOpacity> */}


        </View>

        <View style={{ flex: isDesktop ? 1.5 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Image source={require('../../assets/stickers/Aaro Extensions.png')} resizeMode="contain" style={{ width: '120%', height: '120%' }} />
        </View>

      </CenteredView>
    </View>

  );
}


export const Studio = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 800 : 600;
  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <View style={{ backgroundColor: '#f7fbff' }}>

      <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

        <View style={{ flex: isDesktop ? 1.5 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Image source={require('../../assets/stickers/Aaro Data Stack.png')} resizeMode="contain" style={{ width: '120%', height: '120%' }} />
        </View>

        <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isDesktop ? 'flex-end' : 'center', padding: 50 }}>

          {/* The OVERALL point is that it's a cognitive artifact, a TOOL, which is used to help you fulfill your potential. */}
          <View style={{ alignSelf: isDesktop ? 'flex-end' : 'center', marginLeft: isDesktop ? undefined : 20, paddingHorizontal: isDesktop ? undefined : 10, maxWidth: 400, marginTop: 35 }}>

            <Text style={{ letterSpacing: 2, lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#4acfff', fontFamily: "Outfit-Bold", fontSize: isMobile ? 20 : isTablet ? 18 : 16, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>STUDIO</Text>
            <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, textAlign: 'left' }}>Use data</Text>
            <Text style={{ lineHeight: isMobile ? 40 : isTablet ? 55 : 80, color: '#333333', fontFamily: "Outfit-Bold", fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, textAlign: 'left' }}>without code.</Text>


            <Text style={{ color: '#888888', fontFamily: "Outfit-SemiBold", fontSize: isDesktop ? 22 : 20, letterSpacing: -0.5, textAlign: 'left' }}>
              Aaro gives you full control of your data with a built-in no-code development studio.  {'\n\n'}
              Use it as an all-in-one integrated knowledge hub.  {'\n\n'}
            </Text>
          </View>

          {/* Aaro is the place to grow and embark on your personal development journey. */}

          {/* <TouchableOpacity onPress={() => navigation.navigate("Gallery" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: "Outfit-Bold", fontSize: 20 }}>Join the Beta</Text>
          </TouchableOpacity> */}


        </View>


      </CenteredView>
    </View>

  );
}

export const AaroFooter = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const navigation = useNavigation<StackNavigationProp<any, any, any>>();
  const settings = useSettings();

  return (
    <View style={{ height: 100, backgroundColor: '#eeeeee', overflow: 'hidden' }}>
      <CenteredView containerProps={{ style: { flex: 1 } }} style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start', flex: 1, paddingHorizontal: settings.marginSize }}>
        <Image source={require('../../assets/stickers/rank_6.png')} resizeMode="contain" style={{ width: 40, height: 40 }} />
        <Text style={{ color: '#555555', fontFamily: "Outfit-Bold", fontSize: isMobile ? 20 : isTablet ? 18 : 16, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, textAlign: 'left' }}>Aaro</Text>
        <View style={{ justifyContent: 'flex-end', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ marginHorizontal: 10, fontFamily: 'Outfit-Semibold', fontSize: 16, color: "#888888" }} onPress={() => navigation.push("Privacy")}>Privacy Policy</Text>
          <Text style={{ marginHorizontal: 10, fontFamily: 'Outfit-Semibold', fontSize: 16, color: "#888888" }} onPress={() => navigation.push("Terms")}>Terms of Use</Text>
        </View>
      </CenteredView>
    </View>
  );

}

export const AaroLanding = ({ section, toggleMenu }: { section?: string, toggleMenu: () => void }) => {

  const scrollViewRef = React.useRef<ScrollView>(null);
  const [subscribePosition, setSubscribePosition] = React.useState<number | undefined>(undefined);

  const scrollToSection = () => {
    if (section === 'subscribe') {
      scrollViewRef.current?.scrollTo(subscribePosition);
    }
  }

  React.useEffect(() => {
    scrollToSection();
  }, [subscribePosition])

  return (
    <ScrollView onLayout={scrollToSection} ref={scrollViewRef}>
      <AaroHeader flat={true} centered={true} transparent={true} showButtons={true} mode='light' />
      <IntroSection />
      <View style={{ height: 1, backgroundColor: '#ffebf1' }} />
      <Overview />
      <View style={{ height: 1, backgroundColor: '#ffebf1' }} />
      <Customize />
      <View style={{ height: 1, backgroundColor: '#c5e1fc' }} />
      <Studio />
      <View style={{ height: 1, backgroundColor: '#dddddd' }} />
      <AaroFooter />
    </ScrollView>
  );
}

const StackNav = createStackNavigator();

export const LandingNav = () => {
  return (
    <NavigationContainer independent={true}>
      <StackNav.Navigator screenOptions={{ headerShown: false }}>
        <StackNav.Screen name="Landing" component={() => <AaroLanding toggleMenu={() => null} />} />
        <StackNav.Screen name="Terms" component={TermsScreen} />
        <StackNav.Screen name="Privacy" component={PrivacyScreen} />
      </StackNav.Navigator>
    </NavigationContainer>

  );
}

export class AaroLandingPlugin extends CorePluginClass {

  public static details = {
    name: 'Aaro Landing Page',
    description: 'Aaro Landing Page',
    dependencies: [HaliaComponentPlugin.details.id],
    id: 'aaro-landing'
  }

  public registerHOC = (wrapper: any) => {
    registerHOC("aaro", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("aaro", wrapper);
  }

  public install = async (program: Program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    registerHOCRegister("aaro");
    haliaComponentPlugin.registerHOC(LandingNav);
    return this;
  }
}


