import "@expo/match-media";
import * as React from "react";
import { Platform } from "react-native";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 });
  return isMobile ? children : null;
};
const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 499 });
  return isMobile ? children : null;
};
const Default = ({ children }: { children: any }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export interface SizeConfig {
  desktopBreakpoint: number;
  tabletBreakpoint: number;
}

const defaultSizeConfig: SizeConfig = {
  desktopBreakpoint: 1100,
  tabletBreakpoint: 700
}

//  TODO:  Like ALL components, it SHOULD be like a CSS system where I can APPLY somethign to ANY of them.  KIND OF reminds me of Anthem, where they had a COUPLE layer of configurability, but still not that generic and applying to any props hm!
//  CONSIDER:  AHH!!  DO want to use React (or something like it) for EVERYTHIGN in the app! hm!  NOT just for UI... MAYBE we can start it when a "component" is mounted, but NOT everythign needs to render hM!
//  CONCERN:  This WHOLE thing just seems like a lot... can't we simplify ALL of this with CSS-like systems and using runtime types and stuff??? HMMM

export const SizeConfigContext = React.createContext<SizeConfig>(defaultSizeConfig);
export const SizeConfigProvider = ({ children, sizeConfig }: { children: React.ReactChildren, sizeConfig: SizeConfig }) => {
  return (
    <SizeConfigContext.Provider value={ sizeConfig }>
      { children }
    </SizeConfigContext.Provider>
  );
}
export const useSizeConfig = () => {
  const sizeConfig: SizeConfig = React.useContext(SizeConfigContext);
  return sizeConfig;
}

export interface Sizes {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  sizeConfig: SizeConfig;
}

declare global {
  interface Navigator {
    standalone?: boolean;
  }
}

//  NOTE:  Generated by GPT4
export function useAppType() {
  // Check for React Native environment
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    return 'native';
  } else if (typeof window !== 'undefined') {
    // Enhanced checks for PWA vs. browser
    const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    const isFullscreen = window.outerHeight === screen.height && window.outerWidth === screen.width;
    
    // Check if running in a web context but not in full screen (to reduce false positives for standalone detection)
    if (!isFullscreen && !isStandalone) {
      return 'web';
    } else if (isStandalone) {
      // If standalone conditions are met, and we're possibly in fullscreen mode, consider it a PWA
      return 'pwa';
    } else {
      // Default to web if the environment is uncertain, but fullscreen or standalone conditions might be met
      return 'web';
    }
  }
  // Fallback in case none of the above conditions are met
  return 'unknown';
}




export const isPWA = () => {
  const [isPWA, setIsPWA] = React.useState(false);

  React.useEffect(() => {
    // Detect standalone mode for iOS and Android/other browsers
    const isIOSPWA = window.navigator.standalone;
    const isAndroidPWA = window.matchMedia('(display-mode: standalone)').matches;
    setIsPWA(isIOSPWA || isAndroidPWA);
  }, []);

  return isPWA;
};

export const useSizes = (config: SizeConfig = defaultSizeConfig):  Sizes => {
  const sizeConfig = useSizeConfig() || config;
  const isDesktop = useMediaQuery({ minWidth: sizeConfig.desktopBreakpoint });
  const isTablet = useMediaQuery({ minWidth: sizeConfig.tabletBreakpoint, maxWidth: sizeConfig.desktopBreakpoint - 1 });
  const isMobile = useMediaQuery({ maxWidth: sizeConfig.tabletBreakpoint - 1 });
  const sizes = {
    isDesktop,
    isTablet,
    isMobile,
    sizeConfig
  }
  return sizes;
}

export const withSizes = (Component: any) => {
  return (props: any) => {
    const sizes = useSizes();
    return <Component {...props} sizes={sizes} />;
  }
}
