import { EventEmitter } from "events";
import {
  HaborSDK,
  Instance,
  InstanceID,
  InstanceInternal,
  InstanceSearchParams,
  Noun,
  NounInternal,
  NounSearchParams
} from "habor-sdk";
import {
  INounService,
  NounServiceInstanceInternal
} from "../../../packages/noun-service/noun-service";

//  THOUGHTS:  We use classes to build an "coformant constelation"... an encoding / constelation which conforms to some set of expectations.  THEN, we also ahve the concept of "instance"... BUT why should we???  Instead of building memory just for this, WHY not have it be dynamic JUST based on fucking contex ugh hm!llksjLFK
//  NOTE:  These functions are common to ANY task of getting inheritance and stuff hmm... doesn't seem like we should have to keep re-writing such things...
//  TODO:  Add the "inheritance" sub-feature via a Plugin hm!
//  THOUGHTS:  We COULD extend from "CRUD" SDK but that only gives us ONE ... hmm.. MAYBE instead we can have CRUD for Model, Instance, Property, etc.. hmm... EACH of these? Hmm.. AND part of CRUDing .... which is JUST manipulating the BAG!  Is REORGANIZING matter in a way that can produce desired influence hm!  THEN, the idea is, CRUD is a set of operrations on the GRAPH HM!  It's like... kinda operrations on the fundamental ontology? Hmmm.. maybee...  What ONTOLOGY do we need to build the fundamental onoloy? Hmm shoultn't THAT be called the fundametal? HM!  MAYBE they exist toeher?? Hm
//             The models and instances etc ARE separrate thing shm!  BUT some things MAY not be directly CRUDed.. some things are done indierctly? Hmm ughhasdfaslkfjasl;dljf... like piees of a thing?  MAYBE that's a sign we CAN use CRUD and normalization with relations instead? Hmm... maybe

//  CONSIDER:  This is a system which crosses Model and Instance.  Should we build a standarrd thig for that? hmm.. CRUD interface for te both and couplers? Hmm mabe
//  CONSIDER:  A way to TEST at RUNTIME and show when there are "errors" hm!

export abstract class ModelSDK {
  public createModel = (model: Noun): Promise<NounInternal> => {
    throw "'createModel' must be implemented by a sub-class.";
  };

  public updateModel = (id: string, model: Noun): Promise<NounInternal> => {
    throw "'updateModel' must be implemented by a sub-class.";
  };

  public deleteModel = (id: string): Promise<void> => {
    throw "'deleteModel' must be implemented by a sub-class.";
  };

  public getModelByID = async (modelId: string): Promise<NounInternal> => {
    //  TODO:  Build an "Abstract" decorator to centralize this tagging logic!
    throw "'getModelByID' must be implemented by a sub-class.";
  };
  public getImmediateParentClasses = async (
    noun: Noun | NounInternal
  ): Promise<NounInternal[]> => {
    throw "'getImmediateParentClasses' must be implemented by a sub-class.";
  };

  //  We originally "hard-coded" MEANING didn't take from context? an encodig for remote habor SDK.. hmm.. now we want to "pass".. essentially through a sense of context and bind to this generlied thing that lets us decide which one to use... NOW... tere' are lots of ways to do thi, BUT in the Fundamental Ontolgoy model it all kinda looks the samih mm.. as long as we ave yeah.. context binding womehwere doesn't matter where? hmm what about the constelation? can it cange? mm the "API" yeah maybe hmm!  WTF do we do about that?? Hm!  It's so specific hm!  An "API" is a constelatio and a constelstion is an encoding.  It's JUST an encoding.  So... when we "COUPLE" to a particular constelation, we have a set of expectations (expectation constelation) which, when interpreted with the API contelation as input, needs to match and shit hm!  SO... we CAN change it, but it MAY break interpretefs hmmm... maybe if we use stnard stuff hmm  like way to ensure? hmm... idk keep movin man.. any change should be accompaied? hm.. idk... copling is a hing hm1S
  public abstract searchInstances<T>(
    searchTerms: InstanceSearchParams
  ): Promise<InstanceInternal<T>[]>;

  public abstract getInstances(nounId: string): Promise<InstanceInternal[]>;

  public abstract deleteInstance(instId: InstanceID): Promise<void>;

  public abstract retrieveInstance(
    instId: InstanceID
  ): Promise<InstanceInternal | undefined>;

  public abstract createInstance(inst: Instance): Promise<InstanceInternal>;

  public abstract updateInstance(
    originalInst: InstanceInternal,
    newInst: Instance
  ): Promise<InstanceInternal>;

  // public abstract serializeModel () =>
  public abstract searchModels(
    searchTerms: NounSearchParams
  ): Promise<NounInternal[]>;

  public abstract getModels(): Promise<NounInternal[]>;

  public getAllParentClasses = async (
    noun: Noun | NounInternal
  ): Promise<NounInternal[]> => {
    //  Get All Sub-Classes
    const allParentClasses: NounInternal[] = [];

    //  Get Immediate Children
    const immediateParentClasses = await this.getImmediateParentClasses(noun);

    //  Add the Immediate Parent-Classes
    allParentClasses.push(...immediateParentClasses);

    //  Get All Parent ClaSSES of EACH immediate Parent
    for (const immediateParentClass of immediateParentClasses) {
      const parentClasses = await this.getAllParentClasses(
        immediateParentClass
      );
      allParentClasses.push(...parentClasses);
    }

    return allParentClasses;
  };
}

export class HaborModelSDK extends ModelSDK {
  constructor(public haborSDK: HaborSDK, public token: string) {
    super();
  }

  public retrieveInstance = (instId: InstanceID): Promise<InstanceInternal> => {
    return this.haborSDK.retrieveInstance(
      instId.nounId,
      instId.instanceId,
      this.token
    );
  };

  public createInstance = (inst: Instance): Promise<InstanceInternal> => {
    return this.haborSDK.createInstance(inst, this.token);
  };

  public updateInstance = (
    originalInst: InstanceInternal,
    newInst: Instance
  ): Promise<InstanceInternal> => {
    return this.haborSDK.updateInstance(originalInst.id, newInst, this.token);
  };

  public deleteInstance = async (instId: InstanceID): Promise<void> => {
    await this.haborSDK.deleteInstance(
      instId.nounId,
      instId.instanceId,
      this.token
    );
  };

  public searchModels = async (searchTerms: NounSearchParams) => {
    return this.haborSDK.searchNouns(this.token, searchTerms);
  };

  public getModels = async () => {
    return this.haborSDK.searchNouns(this.token);
  };

  public searchInstances = async (searchParams: InstanceSearchParams) => {
    return this.haborSDK.searchInstances(this.token, searchParams);
  };

  public getInstances = async (modelId: string) => {
    return this.haborSDK.searchInstances(this.token, { nounId: modelId });
  };
  public deleteModel = async (id: string): Promise<void> => {
    return this.haborSDK.deleteNoun(id, this.token);
  };

  public createModel = async (model: Noun): Promise<NounInternal> => {
    return this.haborSDK.createNoun(model, this.token);
  };

  public updateModel = async (
    id: string,
    model: Noun
  ): Promise<NounInternal> => {
    return this.haborSDK.updateNoun(id, model, this.token);
  };

  public getModelByID = async (modelId: string): Promise<NounInternal> => {
    return this.haborSDK.retrieveNoun(modelId, this.token);
  };

  //  TODO:  Type the NounSearchParams!
  public getImmediateParentClasses = async (
    noun: Noun | NounInternal
  ): Promise<NounInternal[]> => {
    const searchTerms = noun.inherits
      ? noun.inherits.map((parentId) => ({ match: { id: parentId } }))
      : [];
    const parents = await this.haborSDK.searchNouns(this.token, {
      search: { any: searchTerms },
    });
    return parents;
  };
}

// export class LocalModelSDK extends ModelSDK {

//   constructor () {
//     super();
//   }

//   public getModels(): Promise<NounInternal[]> {
//     return [];
//   }

//   public deleteInstance = async (instId: InstanceID) => {
//     await instanceService.delete(instId.instanceId);
//   }

//   public createInstance = async (inst: Instance): Promise<InstanceInternal> => {
//     const eInstance = await instanceService.create(inst);
//     return {
//       ...eInstance,
//       nounId: inst.nounId,
//       payload: eInstance.payload.payload
//     };
//   }

//   public retrieveInstance = async (instId: InstanceID): Promise<InstanceInternal | undefined> => {
//     const eInstance = await instanceService.retrieve(instId.instanceId);
//     if (!eInstance) { return undefined; }
//     return {
//       ...eInstance,
//       nounId: instId.nounId,
//       payload: eInstance.payload.payload
//     };
//   }

//   public updateInstance = async (originalInst: InstanceInternal, newInst: Instance): Promise<InstanceInternal> => {
//     const eInstance = await instanceService.update(originalInst.id, newInst);
//     return {
//       ...eInstance,
//       nounId: originalInst.nounId,
//       payload: eInstance.payload.payload
//     };
//   }

//   public getInstances = async (modelId: string): Promise<HaborInstanceInternal[]> => {
//     const eInstances = await instanceService.search({ match: { nounId: modelId } });
//     return eInstances.map(eInst => ({
//       ...eInst,
//       nounId: eInst.payload.nounId,
//       payload: eInst.payload.payload
//       })
//     );
//   }

//   public searchModels = async (searchParams: NounSearchParams) => {
//     //  TODO:  Handle Paging? mm..
//     //  CONSIDER:  Generalize to handle ANY case with a parrtial data handle? hmm...
//     const eNouns = await modelService.search(searchParams.search);
//     return eNouns.map(eNoun => ({
//       ...eNoun,
//       ...eNoun.payload
//     } as HaborNounInternal)
//     );
//   }

//   public searchInstances = async (searchParams: InstanceSearchParams) => {
//     console.log("SEARCH PARAMS: " + JSON.stringify(searchParams));
//     const terms: SearchTerm[] = [];
//     const nounTerm = { match: { nounId: searchParams.nounId } };
//     terms.push(nounTerm);
//     //  TODO:  Support RELATIVE QUERIEWS, mning, where we can specify "match" and complex terms at a DEEP context hM!  NOT just with the FULL path hm!s
//     if (searchParams.search) { terms.push(searchParams.search) }

//     //  Get all ENouns
//     const all = await instanceService.retrieveAll();

//     //  Covert to InstanceInternal
//     const allInstInternal = all.map(eInst => ({ ...eInst, nounId: eInst.payload.nounId, payload: eInst.payload.payload }));

//     console.log("ABOUT TO SEARCH")
//     console.log("TERMS: " + JSON.stringify(terms));
//     const eInstances = searchObjects({ all: terms }, allInstInternal);
//     console.log("SEARCHED: " + JSON.stringify(eInstances))
//     const insts = eInstances
//     .filter(eInst => !!eInst.id)  //  TODO:  Remove this.  Temporary filter because I'm seeing emptry entries on local.  Why?  Probably something to do with Object Search hm!
//     .map(eInst => ({
//       ...eInst,
//       ...eInst.payload
//     })
//     );
//     console.log("INSTANCES: " + JSON.stringify(insts));
//     return insts;
//   }

//   public createModel = async (model: Noun): Promise<NounInternal> => {
//     const eModel = await modelService.create(model);
//     const modelInternal: HaborNounInternal = {
//       ...eModel.payload,
//       ...eModel
//     };
//     return modelInternal;
//   }

//   public updateModel = async (id: string, model: Noun): Promise<NounInternal> => {
//     const eModel = await modelService.update(id, model);
//     const modelInternal: HaborNounInternal = {
//       ...eModel.payload,
//       ...eModel
//     };
//     return modelInternal;
//   }

//   public deleteModel = async (id: string): Promise<void> => {
//     await modelService.delete(id);
//   }

//   public getModelByID = async (modelId: string): Promise<HaborNounInternal> => {
//     const model = await modelService.retrieve(modelId);
//     if (!model) { throw "The model was not found"; }
//     const modelInternal: HaborNounInternal = {
//       ...model.payload,
//       ...model
//     };
//     return modelInternal
//   }

//   public getImmediateParentClasses = async (noun: Noun | HaborNounInternal): Promise<HaborNounInternal[]> => {
//     const parents = await Promise.all((noun.inherits || []).map(parentId => this.getModelByID(parentId)));
//     return parents;
//   }
// }

export const wrapModel = (
  noun: NounInternal
): NounServiceInstanceInternal<Noun> => {
  return {
    created: noun.created,
    updated: noun.updated,
    id: noun.id,
    payload: {
      id: noun.id,
      name: noun.name,
      description: noun.description,
      inherits: noun.inherits,
      properties: noun.properties,
      abstract: noun.abstract,
    },
  };
};

export const wrapInstance = (
  instance: InstanceInternal
): NounServiceInstanceInternal<Instance> => {
  return {
    created: instance.created,
    updated: instance.updated,
    id: instance.id,
    payload: {
      id: instance.id,
      nounId: instance.nounId,
      payload: instance.payload,
    },
  };
};

export class HaborModelService implements INounService<Noun> {
  public emitter = new EventEmitter();

  constructor(protected haborSDK: HaborSDK, protected token: string) {}

  public async init() {
    this.emitter.emit("init");
  }

  public async search(searchParams: NounSearchParams) {
    const nouns = await this.haborSDK.searchNouns(this.token, searchParams);
    const wrappedNouns = nouns.map((noun) => wrapModel(noun));
    this.emitter.emit("search", wrappedNouns);
    return wrappedNouns;
  }

  public async create(model: Noun): Promise<NounServiceInstanceInternal<Noun>> {
    const modelInternal = await this.haborSDK.createNoun(model, this.token);
    const wrappedModel = wrapModel(modelInternal);
    this.emitter.emit("create", wrappedModel);
    return wrappedModel;
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<any>;
  }> {
    throw "'retrieveObj' is Unimplemented for Class Entity Service";
  }

  public async retrieveAll(): Promise<NounServiceInstanceInternal<Noun>[]> {
    const models = await this.haborSDK.searchNouns(this.token);
    const wrappedModels = models.map((model) => wrapModel(model));
    this.emitter.emit("retrieveAll", wrappedModels);
    return wrappedModels;
  }

  public async retrieve(
    modelId: string
  ): Promise<NounServiceInstanceInternal<any> | undefined> {
    try {
      const model = await this.haborSDK.retrieveNoun(modelId, this.token);
      const wrappedModel = wrapModel(model);
      this.emitter.emit("retrieve", model);
      return wrappedModel;
    } catch (err) {
      return undefined;
    }
  }

  public async update(modelId: string, model: Noun) {
    this.haborSDK.updateNoun(modelId, model, this.token);
    this.emitter.emit("update", model);
  }

  public async delete(modelId: string) {
    this.haborSDK.deleteNoun(modelId, this.token);
    this.emitter.emit("delete", modelId);
  }
}

export class HaborInstanceService<T> implements INounService<Instance<T>> {
  public emitter = new EventEmitter();

  constructor(
    protected haborSDK: HaborSDK,
    protected token: string,
    protected modelId: string
  ) {}

  public async init() {
    this.emitter.emit("init");
  }

  public async create(
    instance: Instance<T>
  ): Promise<NounServiceInstanceInternal<Instance<T>>> {
    const instanceInternal = await this.haborSDK.createInstance(
      instance,
      this.token
    );
    const wrappedInstance = wrapInstance(instanceInternal);
    this.emitter.emit("create", wrappedInstance);
    return wrappedInstance;
  }

  public async delete(instanceId: string) {
    await this.haborSDK.deleteInstance(this.modelId, instanceId, this.token);
    this.emitter.emit("delete", instanceId);
  }

  public async search(searchParams: NounSearchParams): Promise<NounServiceInstanceInternal<Instance<T>>[]> {
    const instances = await this.haborSDK.searchInstances(this.token, {
      nounId: this.modelId,
      ...searchParams,
    });
    const wrappedInstances = instances.map(instance => wrapInstance(instance));
    this.emitter.emit("search", wrappedInstances);
    return wrappedInstances;
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<Instance<T>>;
  }> {
    throw "'retrieveObj' is unimplemented for HaborInstanceService.";
  }

  public async retrieveAll(): Promise<
    NounServiceInstanceInternal<Instance<T>>[]
  > {
    const instances = await this.haborSDK.searchInstances(this.token, {
      nounId: this.modelId,
    });
    const wrappedInstances = instances.map((instance) =>
      wrapInstance(instance)
    );
    this.emitter.emit("retrieveAll", wrappedInstances);
    return wrappedInstances;
  }

  public async retrieve(
    instanceId: string
  ): Promise<NounServiceInstanceInternal<Instance<T>> | undefined> {
    try {
      const instance = await this.haborSDK.retrieveInstance(
        this.modelId,
        instanceId,
        this.token
      );
      const wrappedInstance = wrapInstance(instance);
      this.emitter.emit("retrieve", wrappedInstance);
      return wrappedInstance;
    } catch (err) {
      return undefined;
    }
  }

  public async update(instanceId: string, instance: Instance<T>) {
    const instanceInternal = await this.haborSDK.updateInstance(
      instanceId,
      instance,
      this.token
    );
    const wrappedInstance = wrapInstance(instanceInternal);
    this.emitter.emit("update", wrappedInstance);
    return wrappedInstance;
  }
}


//
//
//

//  CONSIDER:  I may want to re-build this as the composition of other systems, or... I might want to re-build it as fully textual.  FOR NOW, I'll just use the JSON.  Teh idea of associated Type is the same as what I had with classes over an API.  The ISSUE was, it's still too specific.  It IS useful!  In more classical mapped computing.  

//  Some challenges in this would be, if we extend existing and we need to be able to identify those.  We also might have yeah hmm... we have logic for handling this in Habor.  I think I MAY still be able to salvage Habor as a pluggable backend.  Something we can modify with an API.  But, I'm also leaning more toward microservices now hmm... 
export class LocalModelService implements INounService<Noun> {
  public emitter = new EventEmitter();

  constructor() {}

  public async init() {
    this.emitter.emit("init");
  }

  public async search(searchParams: NounSearchParams) {
    const nouns = await this.haborSDK.searchNouns(this.token, searchParams);
    const wrappedNouns = nouns.map((noun) => wrapModel(noun));
    this.emitter.emit("search", wrappedNouns);
    return wrappedNouns;
  }

  public async create(model: Noun): Promise<NounServiceInstanceInternal<Noun>> {
    const modelInternal = await this.haborSDK.createNoun(model, this.token);
    const wrappedModel = wrapModel(modelInternal);
    this.emitter.emit("create", wrappedModel);
    return wrappedModel;
  }

  public async retrieveObj(): Promise<{
    [id: string]: NounServiceInstanceInternal<any>;
  }> {
    throw "'retrieveObj' is Unimplemented for Class Entity Service";
  }

  public async retrieveAll(): Promise<NounServiceInstanceInternal<Noun>[]> {
    const models = await this.haborSDK.searchNouns(this.token);
    const wrappedModels = models.map((model) => wrapModel(model));
    this.emitter.emit("retrieveAll", wrappedModels);
    return wrappedModels;
  }

  public async retrieve(
    modelId: string
  ): Promise<NounServiceInstanceInternal<any> | undefined> {
    try {
      const model = await this.haborSDK.retrieveNoun(modelId, this.token);
      const wrappedModel = wrapModel(model);
      this.emitter.emit("retrieve", model);
      return wrappedModel;
    } catch (err) {
      return undefined;
    }
  }

  public async update(modelId: string, model: Noun) {
    this.haborSDK.updateNoun(modelId, model, this.token);
    this.emitter.emit("update", model);
  }

  public async delete(modelId: string) {
    this.haborSDK.deleteNoun(modelId, this.token);
    this.emitter.emit("delete", modelId);
  }
}