import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Pressable, Text, TouchableOpacity, View } from 'react-native';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { defaultBorderRadius } from '../../../packages/kelp-bar/styles';
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { Edge, EdgeProps, EdgeWidget } from './edge-plugin';
var dagre = require("dagre");

import ReactFlow, {
  addEdge, removeElements
} from 'react-flow-renderer';

import {
  G as g, Rect as rect
} from 'react-native-svg';
import { HaliaPluginContext, HaliaPluginState } from '../../halia-studio/halia-studio-plugin';
import { Entity, useEntityPlugin } from '../entity-plugin';
import { EntityListItem } from '../entity-plugin/entity-list';


(global as any).rect = rect;
(global as any).g = g;


export const EdgeGraphPage = (props: EdgeProps) => {

  const navigation = useNavigation();

  const [edges, setEdges] = React.useState<NounServiceInstanceInternal<Edge>[]>([])

  const onMount = async () => {
    const edges = await props.edgePlugin.edgeService.retrieveAll();
    //  TODO:  Remove this filter!  I'm having an issue with that one ID!
    const fiteredEdges = edges.filter(edge => edge.id !== "98ea5b39-8975-42cb-8e2e-f65e8d1d4341");
    setEdges(fiteredEdges);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  const edgeElems = edges.map((edge: NounServiceInstanceInternal<Edge>) => {
    return (
      <TouchableOpacity style={{ borderRadius: defaultBorderRadius, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column' }} onPress={() => navigation.navigate("Editor" as never, { edgeNoun: edge } as never)}>
        <EdgeWidget entityPlugin={props.entityPlugin} edgePlugin={props.edgePlugin} edgeInternal={edge} />
      </TouchableOpacity>
    );
  });
  return (

    <PageLoader loading={false}>
      <Page style={{ marginHorizontal: 20 }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Edges</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <EdgeGraph edges={edges} />
      </Page>
    </PageLoader>
  )
}

import { memo } from 'react';

import { StackNavigationProp } from '@react-navigation/stack';
import { Handle } from 'react-flow-renderer';
import { EntityRoute } from '../entity-plugin/entity-navigator';

export const CustomNode = memo(({ data, isConnectable }: any) => {

  const navigation = useNavigation<StackNavigationProp<any>>();
  const entityPlugin = useEntityPlugin();

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />

      {/* TODO:  Don't do this directly!  No need to de-couple this!!! */}
      <Pressable onLongPress={() => navigation.navigate("entity-system", { screen: EntityRoute.Editor, params: { entity: data.entity } })}>
        <EntityListItem entity={data.entity} />
      </Pressable>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="right"
        id="b"
        style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});

const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};


const EdgeGraph = ({ edges }: { edges: NounServiceInstanceInternal<Edge>[] }) => {

  const context = React.useContext<HaliaPluginState>(HaliaPluginContext);

  //  REFERENCE:  https://github.com/dagrejs/dagre/wiki
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  g.setDefaultEdgeLabel(function () { return {}; })

  const initialElements: any[] = [];

  //  CONCERN:  It LOOKS Like it doesn't AUTO-POSITION UGH!

  //  Render (For Size)
  //  Layout
  //  Render (Final)

  //  Add Dagre Nodes and Edges
  edges.forEach(edge => {

    const { srcEntityAddress, destEntityAddress, differentiatorEntityAddress } = edge.payload;

    const srcEntity: Entity = JSON.parse(srcEntityAddress);
    const destEntity: Entity = JSON.parse(destEntityAddress);
    const differentiatorEntity: Entity = JSON.parse(differentiatorEntityAddress);

    //  Add Nodes
    g.setNode(srcEntityAddress, { label: JSON.parse(srcEntityAddress), width: 144, height: 100 });
    g.setNode(destEntityAddress, { label: JSON.parse(destEntityAddress), width: 144, height: 100 });
    g.setNode(differentiatorEntityAddress, { label: JSON.parse(differentiatorEntityAddress), width: 144, height: 100 });

    //  Add Edges
    g.setEdge(srcEntityAddress, differentiatorEntityAddress);
    g.setEdge(differentiatorEntityAddress, destEntityAddress);

  });

  // //  Add Dagre Edges
  // edges.forEach(edge => {
  //   const { srcEntityAddress, destEntityAddress, differentiatorEntityAddress } = edge.payload;

  //   //  TODO:  Support 
  //   //  NOTE:  AHH!  I remember talking about a SUPER NODE in IFT where I wanted to GROUP things as one hm!  This is an example of abstraction?? mmm..  It BECOMES a thing hmm... 

    
  //   //  CONSIDER:  MAYBE even "Differentiator" is too complex? Hmm... this is directed path hmm...
  // });

  dagre.layout(g);

  //  Add React Flow Nodes
  g.nodes().forEach((nodeId) => {
    const node = g.node(nodeId);
    if (!node) { return }
    initialElements.push({
      id: nodeId,
      data: { entity: node.label },
      type: 'custom',
      position: { x: node.x, y: node.y },
    });
  });

  //  Add React Flow Edges
  edges.forEach(edge => {

    const { srcEntityAddress, destEntityAddress, differentiatorEntityAddress } = edge.payload;

    //  NOTE:  In the future, if we have INSTANCES, this and other places need to change
    
    initialElements.push({
      id: srcEntityAddress + differentiatorEntityAddress,  
      source: srcEntityAddress,
      target: differentiatorEntityAddress,
      arrowHeadType: 'arrowclosed'
    });

    initialElements.push({
      id: differentiatorEntityAddress + destEntityAddress,
      source: differentiatorEntityAddress,
      target: destEntityAddress,
      arrowHeadType: 'arrowclosed'
    });


  });

  const [elements, setElements] = React.useState(initialElements);
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els as any) as any);
  const onConnect = (params) => setElements((els) => addEdge(params, els as any) as any);


  return (
    <ReactFlow
      elements={initialElements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[15, 15]}
      // style={{ background: 'red' }}
      nodeTypes={{
        custom: CustomNode,
      }}
    />
  )
}