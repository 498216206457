
import { useNavigation } from "@react-navigation/native";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { FlatGrid } from "react-native-super-grid";
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { GalleryButton } from "../../gallery/components/common";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { EntityIdentityPlugin, IdentityInfo } from "../entity-identity-plugin";
import { Entity, EntityPlugin, getEntityId } from "../entity-plugin";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { WidgetItem } from "../hessia-plugin/dynamic-component";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { getTagsForEntity } from "./tag-editor";
import { TagChiclet } from "./tag-list";
import { TagNavigator } from "./tag-router";
import { Text } from 'react-native';

export interface HessiaTag {
  value: string;
}

export interface HessiaTagAssignment {
  tagId: string;
  destId: string;
}

//  TODO:  Make this an EXTENSION of Halia??? SOMEHOW make it possible to generalize context creation AND support MULTIPLE Plugins NOT tied to hierarchy position!
export const TagPluginContext = React.createContext<TagPlugin>({} as any);
export const useTagPlugin = () => {
  return React.useContext(TagPluginContext);
}

export class TagPlugin extends CorePluginClass {
  public static details = {
    name: "Tag",
    description: "Tag Primitive System",
    //  CONSIDER:  We CAN Couple EntityIdentiyPlugin and TagPlugin SEPARATELY using a COUPLING Plugin!
    dependencies: [HessiaPlugin.details.id, SystemPlugin.details.id, EntityPlugin.details.id, HaborPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id],
    id: "tagPlugin"
  }

  public serviceName = "tag";
  public tagService!: HaborNounService<HessiaTag>;
  public tagAssignmentService!: HaborNounService<HessiaTagAssignment>;
  protected pEntities!: EntityPlugin;

  //  THOGUHT:  We CAN do this all with SEPARATE systems.. but do we REALLY want to do that.. can we use SOME standard UGH... idk man hmm...
  //  TODO:  De-couple the EntityPlugin
  public createTag = async (value: string) => {
    const hessiaTag = await this.tagService.create({ value });
    const strEntity: Entity = { metadata: "test", systemId: this.serviceName, route: { id: hessiaTag.id, created: hessiaTag.created } };
    const entityId = await getEntityId(strEntity);
    const strEntityInternal = await this.pEntities.entityService.create(strEntity, entityId);
    alert(JSON.stringify(strEntityInternal));
  }

  public updateTag = async (tagId: string, value: string) => {
    const hessiaTag = await this.tagService.retrieve(tagId);
    if (!hessiaTag) { throw `Missing instance from the Tag Service: ${tagId}`; }
    // const entityId = await getEntityId({ id: hessiaTag.id, created: hessiaTag.created, serviceId: this.serviceName });
  }

  public deleteTag = async (tagId: string) => {
    const hessiaTag = await this.tagService.retrieve(tagId);
    if (!hessiaTag) { throw `Missing instance from the Tag Service: ${tagId}`; }
    const entityId = await getEntityId({ metadata: "test", route: { id: hessiaTag.id, created: hessiaTag.created }, systemId: this.serviceName });
    await this.pEntities.entityService.delete(entityId);
    await this.tagService.delete(tagId);
  }

  public install = async (program: Program, { hessia, system, pEntities, habor, authPlugin, entityIdentityPlugin }: { hessia: HessiaPlugin, entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, habor: HaborPlugin, authPlugin: AuthPlugin }) => {

    this.tagService = new HaborNounService("tag2", habor.haborSDK);
    this.tagAssignmentService = new HaborNounService("tag-assignment2", habor.haborSDK);

    await this.tagService.init(authPlugin.token);
    await this.tagAssignmentService.init(authPlugin.token);

    this.pEntities = pEntities;



    hessia.registerAppNavHOC(({ children }) => {
      return (
        <TagPluginContext.Provider value={this}>
          {children}
        </TagPluginContext.Provider>
      );
    })

    //  TODO:  Register a Detail View for TAG Entities
    //  Register a Detail View for "Tag" Entities
    // pEntities.registerEntityDetailView({
    //   name: "Tag Service",
    //   description: "Tag Service",
    //   id: "tag-service",
    //   component: ({ entity, entityPlugin }: { entity: NounServiceInstanceInternal<Entity>, entityPlugin: EntityPlugin }) => {

    //     if (entity?.payload.systemId !== this.serviceName) { return null; }

    //     const [str, setStr] = React.useState<string | undefined>(undefined);

    //     const loadStr = async () => {
    //       const tagValue = await this.tagService.retrieve(entity.payload.route.id);
    //       setStr(tagValue?.payload.value);
    //     };

    //     React.useEffect(() => {
    //       loadStr();
    //     }, []);

    //     return <SimpleBox
    //       title="Tag"
    //       content={
    //         str ? <Text>{str}</Text> : <ActivityIndicator />
    //       }
    //     />

    //   }
    // })

    //  Register a Detail View for "Tagged" Entities
    pEntities.registerEntityDetailView({
      name: "Tagged Service",
      description: "Tagged Service",
      id: "tagged-service",
      component: (entity, entityPlugin) => {

        const [tags, setTags] = React.useState<NounServiceInstanceInternal<HessiaTag>[]>([]);

        const [allTags, setAllTags] = React.useState<NounServiceInstanceInternal<HessiaTag>[]>([]);

        const getAllTags = async () => {
          const _allTags = await this.tagService.search({});
          setAllTags(_allTags);
        }

        const getTags = async () => {
          const _tags = await getTagsForEntity(entity, this, entityPlugin);
          setTags(_tags);
        }
        React.useEffect(() => {
          getTags();
          getAllTags();
        }, [])

        const navigation = useNavigation();
        return (
          <WidgetItem name="Tags" color="#eeeeee">
            <Text>Assigned Tags</Text>
            <FlatGrid data={tags} renderItem={({ item: tag }) => <TagChiclet tag={tag} />} />

            <Text>All Tags</Text>
            {/* TODO:  Make it easy to assign a tag to the current entity! */}
            {/* CONSIDER:  EVERYTHING can be an Entity, a "thing", INCLUDING the current "CONTEXT".  So we MAY want to be able to assign to context? */}
            {/* TODO:  Be able to filter by Tags too.  The idea is.. this is a SPECIALIZATION of the more general ontoloyg / association.  The issue is, everything is associative hmm... */}
            <FlatGrid data={allTags} renderItem={({ item: tag }) => <TagChiclet tag={tag} />} />

            <GalleryButton title="Manage Tags" onPress={() => navigation.navigate("tag-system" as never)} />
          </WidgetItem>
        );
      }
    })




    entityIdentityPlugin.registerIdentityProvider({
      id: "tag-entity-identity-provider",
      name: "Tag Entity Identity Provider",
      description: "Provides 'tag' Entity Identity",
      systemId: "tag",
      provide: async (entity: Entity): Promise<IdentityInfo> => {

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Tag
        const tag = await this.tagService.retrieve(entity.route.id);

        return {
          type: "tag",
          icon: { name: "tag", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#719267",
          name: "Tag",
          description: "A Text Tag",
          value: tag?.payload.value
        } as IdentityInfo;

      }
    });

    system.registerPrimitiveSystem({
      id: 'tag-system',
      name: "Tag",
      description: "Tag Primitive System",
      labels: ["primitive"],
      component: ({ navigation, route }) => <TagNavigator entityPlugin={pEntities} route={route} navigation={navigation} tagPlugin={this} />,
      menuItem: {
        icon: { name: "tag", type: "material" },
        backgroundColor: "#719267",
        iconColor: "white"
      }
    });

    return this;
  }
}
