
import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { ENounService } from '../../../enoun-service';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { TextParagraph } from '../../../packages/kelp-bar/text';
import { HaborNounService } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { HessiaPlugin } from "../hessia-plugin";
import { haborSDK } from '../hessia-plugin/config';
import { SystemPlugin } from '../system-plugin/system-plugin';

//  NOTE:  A Context is a SIMPLE observable pattern in the context of Entities / Relatioships, and one REQUIRED to contain the context itself.. because the "Fudamental Ontology" cannot exist without it, we iclude it as a basic, core entity.  It CAN be created with just relationships and associated entities.  BUT, we build our own encoding for optimization? (FOR NOW).
//  CONSIDER:  We COULD have systems register a way to add to this here... BUT MAYBE the idea that IS what the systems are doing.  They contrrol this context? Hmm
//  THOUGHT:  It's starting to look a lot like Redux.. hmmm...  BUT, with MULTIPLE layers and stuff.. eventually, hmm...  Maybe this SHOULD be each system's responsibility? Hmm... 
//  THOUGHT:  The idea of adding a "Layer" is a nice exampole of over-extension...  hmm... instead of changing all the points maybe we can just leave the integration but require that as a piece of context??? M!  MAYBE!  It changes how the context system itself works?? HM!  But what about the conditional netwok thing? Hm
//  CONSIDER:  THis is a generic pattern where we're finding something which matches a primary key.  Can we abstract that?  OR is that too specific?  ALSO, JSON path matching, INSTEAD of generic find with functions? Hmm.. MIGHT make it easier to interpret? Hmm...  I think the functio is pretty simple too though.. MAYBE it's about another layer of "complexity"? Hm
//  CONSIDER:  DOES it make sense to have an explicit "context" OR is that what these systems always do? Hmm... This MAY help make it easy to see and debug though hm!  MAYBE again, we DON'T let the Context system do ANYTHING but DISPLAY that shit hm!  THIS way, it's still the SYSTEM'S responsibility to holdd the state.  We MAY even want "Layers" or "Views" hm!
//  CONSIDER:  Should we ping the system?
//  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
//  NOTE:  PERHAPS we don't actually need to store the context in this app!  MAYBE we just NOTIFIY that a system HAS a context stored? HM!  Then we need to use THAT system to manage it hm!  I DO think I prefer that approach hm!
//  CONSIDER-PATTERN:  We have this "Deffered" thing in ALL the types.. it's JUST a way to show the entity view.. do we always want that?  Can we hide?  Can we JUST scope based on the TYPE string and use the SAME UIs for ALL of these with ONLY UI / Encoding injectiosn forr the differenes, as ONE type of "type" system ah!!! HM!  THEN these are ALL just instaces of the "Type" system but NOT necesssarily insances of hte "Primitive" system concept hM!!!  I THINK I might like that? HM!
//  TODO:  Show a mini entity view here instead of just the ID?
//  NOTE:  We DO allow the same element in the context more than once I suppose.  Hmm... maybe?

export const ContextPluginContext = React.createContext<ContextPlugin>({} as any);

export const contextDefinitionService = new HaborNounService<ContextDefinition>("context-definition2", haborSDK);


interface ContextProps {
  // contexts: NounInternal<Context>[];
}
class ContextListBase extends React.Component<{ navigation: NavigationProp<any> }, any> {

  static contextType = ContextPluginContext;
  // declare context: React.ContextType<typeof ContextContext>;

  constructor(props) {
    super(props);
    this.state = {
      contexts: []
    }
  }

  componentDidMount = async () => {
    const contexts = await this.context?.getContexts() || [];
    this.setState({ contexts });
  }

  render() {

    const ContextItem = ({ context }: { context: any }) => {
      return (
        <TouchableOpacity style={{ backgroundColor: '#e8f5ff', borderRadius: 5, paddingVertical: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("Editor", { context })}>
          <Icon type={context.icon.type} name={context.icon.name} color="#3d86fc" />
          <View style={{ width: 5 }} />
          <TextParagraph style={{ color: '#3d86fc' }}>{context.name}</TextParagraph>
        </TouchableOpacity>
      );
    }


    return (
      <FlatGrid
        data={this.state.contexts}
        renderItem={({ item }) => <ContextItem context={item} />}
        style={{ flexGrow: 0 }}
      />
    )
  }
}

const InjectedContextList = (props: any) => <ContextListBase {...props} />
export const ContextList: any = InjectedContextList;

const Stack = createStackNavigator();

export const ContextPage = () => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      <Stack.Screen name="List" component={ContextList} />
    </Stack.Navigator>
  );
}

export interface ContextDefinition {
  system: string;
  state: any;
  name?: string;
  description?: string;
  icon?: { name: string; type: string };
}

export class ContextPlugin extends CorePluginClass {

  private contexts: ContextDefinition[] = [];

  public static details = {
    name: "Context System",
    description: "Manage Inter-App Context",
    dependencies: [SystemPlugin.details.id, HessiaPlugin.details.id, AuthPlugin.details.id],
    id: "context"
  }

  public getConext = async (system: string) => {
    const contextDefinitions = await contextDefinitionService.retrieveAll();
    const context = contextDefinitions.find(context => context.payload.system === system);
    return context?.payload;
  }

  public getContexts = async () => {
    const contextDefinitions = await contextDefinitionService.retrieveAll();
    return contextDefinitions.map(cd => cd.payload);
  }

  public registerContext = async (contextDefinition: ContextDefinition) => {


    //  CONSIDER:  I SHOULD be able to infinitely extend the Context system with more entity selection hm!  MAYBE this won't change the other systmes htough? HM!  Like maybe a Context set hmm etc. hmm
    //  NOTE:  FOR NOW, we have a simple, single context.
    //  TODO:  Support multiple contexts and perhaps not-stored, ephemeral contexts?  Like expressions perhaps? Hm
    //  TODO:  Make this fire an EVENT so we can see what the heck is happening... This is a key part.. MAYBE we can do that in Debug AND in Prod hmm... I've discusseed this several times hm..

    //  CONSIDER:  Should this all be done at runtime?  PERHAPS with a hydration thing?  EITHER way, each system "owns" the context.  This is just a standard reference to it and explanaiton hm...
    const contextDefinitions = await contextDefinitionService.retrieveAll();
    const existing = contextDefinitions.find(_context => _context.payload.system === contextDefinition.system);
    if (existing) {
      await contextDefinitionService.update(existing.id, contextDefinition);
    } else {
      try {
        await contextDefinitionService.create(contextDefinition);
      } catch (err) {
        console.log("FAILED CREATE" + (err as any).message);
        throw err;
      }

    }
  }

  public install = async (program: Program, { system, hessia, authPlugin }: { system: SystemPlugin, hessia: HessiaPlugin, authPlugin: AuthPlugin }) => {

    authPlugin.onLogin(async () => {
      await contextDefinitionService.init(authPlugin.token);
    });



    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {
    //   const context = await contextService.retrieveByEntityId(entityId);
    //   if (context) {
    //     api.setText("Context");
    //   }
    // });

    hessia.registerHOC((props: any) => {
      return (
        <ContextPluginContext.Provider value={this}>
          {props.children}
        </ContextPluginContext.Provider>
      );
    });

    system.registerPrimitiveSystem({
      id: 'context',
      name: "Context",
      description: "Contexts Primitive System",
      labels: ["core"],
      component: ContextPage,
      menuItem: {
        icon: { name: "select", type: "material-community" },
        backgroundColor: "#26DEAA",
        iconColor: "white"
      }
    });

    hessia.registerAppNavChildComponent(ContextList);

    return this;
  }
}
