import { deserializeSDT, DT, DTAny } from "davel";
import { CorePluginClass, Program as HaliaProgram } from "halia";
import React from "react";
import { ActivityIndicator, Text } from "react-native";
import { EntityService } from "../../../noun-entity-service";
import { DavelForm } from "../../../packages/davel-ui/davel-ui";
import { AsyncStorageNounService, NounService } from "../../../packages/noun-service/noun-service";
import { EntityIdentityPlugin, IdentityInfo } from "../entity-identity-plugin";
import { Entity, EntityPlugin } from "../entity-plugin";
import { SimpleBox } from "../page-plugin/page-widget";
import { SystemPlugin } from "./system-plugin";
import { System, SystemSDT } from "./system-types";

export class SystemEntityPlugin extends CorePluginClass {

  public static details = {
    name: "System Entity Plugin",
    description: "Registers 'Systems' as Entities",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, EntityIdentityPlugin.details.id],
    id: "system-entity"
  }

  public categoryService!: AsyncStorageNounService<System>;

  public install = async (program: HaliaProgram, { system, pEntities, entityIdentityPlugin }: { system: SystemPlugin, pEntities: EntityPlugin, entityIdentityPlugin: EntityIdentityPlugin }) => {

    //
    //  Register "System" Pieces
    //

    const systemEntityService = new EntityService(system.systemService, (systemNoun) => {
      const entity = {
        ...systemNoun,
        payload: {
          systemId: SystemPlugin.details.id,
          route: systemNoun.id,
          metadata: systemNoun.payload
        }
      }
      return entity;
      
    });

    const systemService: NounService<Entity> = {
      name: "System Entity Service",
      id: "system-entity",
      service: systemEntityService
    };

    pEntities.entityService.registerService(systemService);

    entityIdentityPlugin.registerIdentityProvider({
      id: "system-entity-identity-provider",
      name: "System Entity Identity Provider",
      description: "Provides 'system' Entity Identity",
      systemId: SystemPlugin.details.id,
      provide: async (entity: Entity): Promise<IdentityInfo> => {

        if (entity.systemId !== SystemPlugin.details.id) { return undefined as any; }

        const systemInstance = system.systemService.retrieve(entity.route);

        return {
          type: "system",
          icon: { name: "computer", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#f25949",
          name: "System",
          description: "A System",
          value: systemInstance?.payload.name
        } as IdentityInfo;

      }
    });

    pEntities.registerEntityDetailView({
      id: "system-detail-view",
      name: "System Detail View",
      resolver: ({entity}) => {

        if (entity.systemId != SystemPlugin.details.id) {
          return null;
        }

        const systemId = entity.route;

        const _system = system.systemService.retrieve(systemId);

        // const [schema, setSchema] = React.useState<DT<any, any>>();

        // const deserialize = async () => {
        //   const _schema = await deserializeSDT(SystemSDT);
        //   setSchema(_schema);
        // }

        // React.useEffect(() => {
        //   deserialize();
        // }, [])

        return (
          <SimpleBox title="System">
            {/* TODO:  Show with Davel Form? */}
            {/* <Text>{ JSON.stringify(_system) }</Text>
            <Text>{ _system?.payload.component.toString() }</Text> */}
            <DavelForm value={ _system?.payload } schema={ SystemSDT } onSubmit={ () => alert("submitted")}  />
          </SimpleBox>
        );
      }
    })

    //
    //  Register "Detail View" Pieces
    //

    //  TODO:  Let this SHOW mm!!  We can do this by adding a "Detail View" from the System System mM!  INCLUDING other pieces mm!

    return this;
  }
}