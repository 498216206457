import { FrameworkContext, HaborComponentContext, HDTRelationshipValue, HSDTRelationship, InstanceInternal, NounInternal } from 'habor-sdk';
import * as _ from "lodash";
import * as React from 'react';
import { Text, View } from "react-native";
import { haborSDK } from '../../../../plugins/hessia-plugins/hessia-plugin/config';
import { HaborComponentViewer } from '../../../../plugins/hessia-plugins/component-plugin/habor-react/habor-component-viewer';
import { getEmbeddedView } from '../../../../plugins/hessia-plugins/primitives-plugin/habor-components/habor-primitive-app';
import { SDTViewRendererParams } from '../../davel-ui-tools';

export const renderEmbeddedComponent = ({ instance, noun, frameworkContext, componentContext }: { instance: InstanceInternal<any>, noun: NounInternal, frameworkContext: FrameworkContext, componentContext: HaborComponentContext }) => {

  //  TODO:  When resolving the embedded component, use the entire nounId chain, NOT just the most recent sub-type.  Why?  Because we may have attached an embedded renderer to a parent noun when one doesn't exist on the child.  In my notes, you can see that this is the strategy I was using to render my Trackers.  Either way it's pretty common to want to set up a view for a particular type of object.
  const embeddedComponnent = getEmbeddedView(noun);
  // TODO-IMPORTANT:  changePage and workspace don't make sense here!

  //  Guard Undefined
  if (embeddedComponnent == undefined) {
    return <Text>No Renderer: { noun.id }</Text>
  }

  return <HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ embeddedComponnent } handleClose={ () => null } componentProps={{ instance }} />
}

export const sdtRelationshipViewRenderer = async ({ sdt, value, metadata: { token, user, frameworkContext, componentContext } }: SDTViewRendererParams) => {

  //  TODO:  Should this code not be responsible for displaying the individual relationships??  Instead, we should have a component responsible for displaying the LIST.  So, we can move this logic into a HaborComponent.
  //  Get the embedded view registered with this noun
  //  TODO:  Support embedded view overrides!
  const relSDT = sdt as HSDTRelationship;  //  TODO:  Figure out how to do this with the TS types!  It seems to just default to 'any' when I try a type extension.
  const relValue = value as HDTRelationshipValue;

  //  Guard Undefined
  //  TODO:  Validate the type first.  It's possible undefined is NOT permitted.
  if (relValue == undefined) { return <View></View>; }


  //  Group by NounID
  const nounIdToInstanceIdMap = _.groupBy(relValue.instanceIdList, (inst) => inst.nounId);

  //  Noun Dict
  //  TODO:  Use a cache system global to the app!  We MAY want to backend to return LOTS of version IDs so we'll be OK with the 
  const nounIdToNounMap: { [nounId: string]: NounInternal } = {};

  //  Get all the instances
  const instances: InstanceInternal[] = [];
  for (const nounId in nounIdToInstanceIdMap) {
    const instanceIdListForNoun = nounIdToInstanceIdMap[nounId];
    const searchCriteria = instanceIdListForNoun.map(instId => ({ match: { id: instId.instanceId } }));
    const instancesForNoun = await haborSDK.searchInstances(token, { nounId: nounId, search: { any: searchCriteria } });

    //  Get the Noun
    if (instancesForNoun.length > 0) {
      if (!nounIdToNounMap[nounId]) {
        const noun = await haborSDK.retrieveNoun(nounId, token);
        nounIdToNounMap[nounId] = noun;
      }
    }

    instances.push(...instancesForNoun);
  }

  //  Create the React Elements
  const elems = instances.map(instance => {
    const noun = nounIdToNounMap[instance.nounId];
    return renderEmbeddedComponent({ instance, noun, frameworkContext, componentContext });
  });

  return (
    <View>
      { elems }
    </View>
  );
};
