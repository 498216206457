import { FrameworkContext, HaborComponent, HaborComponentConfig, HaborComponentContext } from 'habor-sdk';
import * as React from 'react';
import { useHessiaPlugin } from '../../hessia-plugin';
import { renderHaborComponent } from './habor-component-core';
import { FrameworkProps, PrimitiveProps } from './habor-component-lib';

/**
 * An "Inline" HaborComponent (not full-screen)
 * //  TODO:  Rename to HaborComponentRenderer?
 * //  TODO:  Potentially combine like-elements between this and HaborComponentRenderer.
 * //  TODO:  Re-enable edit mode support for inline Components?
 */
export interface HaborComponentViewerProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  component: HaborComponent;
  handleClose: () => void;  //  TODO:  'handleClose' does not make sense for all rendered components.
  componentProps?: any;
  key?: string;
}


export const HaborComponentViewer = (props: HaborComponentViewerProps) => {

  const [renderedHaborComponent, setRenderedHaborComponent] = React.useState<any>(undefined);

  const hessiaPlugin = useHessiaPlugin();

  React.useEffect(() => {
    renderComponent();
  }, [])

  React.useEffect(() => {
    renderComponent();
  }, [props])

  const renderComponent = async () => {

    const { componentProps, frameworkContext, componentContext } = props;
    const config: HaborComponentConfig = { editMode: false, onPress: () => null };
    const frameworkProps: FrameworkProps = { hessiaPlugin: hessiaPlugin, children: [], config, componentId: props.component.name, context: frameworkContext, componentContext };  //  TODO-IMPORTANT:  The HaborComponent developer may want access to children!  So, it makes sense to keep this OUTSIDE Framework props, which is only available to Primitives.
    const primitiveProps: PrimitiveProps = { userProps: componentProps, frameworkProps };
    const renderedHaborComponent = await renderHaborComponent(props.component, primitiveProps, hessiaPlugin);
    setRenderedHaborComponent(renderedHaborComponent);

  }
  return renderedHaborComponent || null;

}
