import { ComponentRegister } from "../component-plugin";

//  TODO:  This must be callewd BEFORE we proces the Menu plugin.  That's FINE, we just need to make sure Menu depends upon Workspace.
//  TODO:  PROBABLY want to replace this with the alternative, scoped registration pattern!
//  INSTEAD, perpahs we make the Menu dependnt upon the Workspace and we sub-class?  but... that means we need knowledge of the OTHER components which we shouldn't need to know about!

export const belowWorkspaceRegister: ComponentRegister = {
  name: "below-workspace",
  description: "A space below the workspace",
  propType: { type: "any" },
  haborComponents: [],
  primitiveComponents: []
};

