import { HaborIconSelection, InstanceInternal, NamedObject, NounInternal, RGBAColor } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { BubbleListItem } from '../../../../packages/kelp-bar/bubble-list-item';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { primaryFontFamilyHeavy, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { ReactRegisterProps } from '../../../../packages/registers/react-registers';
//  TODO:  Decouple from the apps plugin, the HaborElement CAN perhaps be a UI library component from Habor? Hmm.. maybe
import { HaborElement } from '../../entities-apps-plugin/element-widget.component';
import { isNoun } from '../../habor-plugin/habor-utils';

export interface SingleElementWigetProps<T extends HaborElement> extends ReactRegisterProps {
  elem: HaborElement;
  onPress?: (elem: HaborElement) => void;
  onLongPress?: (elem: HaborElement) => void;
  //  TODO:  SUpport the Register API!?
}

export class SingleElementWiget<T extends HaborElement> extends React.Component<SingleElementWigetProps<T>, any> {

  public render = () => {

    //  Unpack
    const { elem, onLongPress = () => null, onPress = () => null, registers: { left, top, right } = {} } = this.props;

    let name: string;
    let description: string | undefined;
    let icon: HaborIconSelection | undefined;
    let color: RGBAColor | undefined;

    if (isNoun(elem)) {
      const noun = elem as NounInternal;
      name = noun.name;
      description = noun.description;
      icon = { name: "entities", type: "habor" };
      color = { "r": "50", "g": "227", "b": "200" };
    } else {
      const inst = elem as InstanceInternal<NamedObject>;
      name = inst.payload.name;
      description = inst.payload.description;
      icon = inst.payload.icon;
      color = inst.payload.color;
    }

    //  TODO:  Get UI information from the Metadata

    const renderedColor = renderRGBAColor(color || { r: "255", g: "200", b: "102" });

    //  TODO:  Show the associated PLUGIN / System!?
    return (
      <BubbleListItem style={{ paddingHorizontal: smallSpacer, justifyContent: 'center' }} onPress={ () => onPress(elem) } onLongPress={ () => onLongPress(elem) }>
        <View style={{ position: 'absolute', left: 20 }}>
          <KelpIcon name={ icon?.name || 'check' } type={ icon?.type || 'material' } size={25} color={renderedColor} />
        </View>
        
        <View style={{ width: smallSpacer }} />
        <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>{name}</Text>
          
          {/* <Text style={{ color: '#a8a8a8', fontFamily: 'Poppins-Bold', fontSize: 12 }}>{description}</Text> */}
      </BubbleListItem>
    );
  }
}