import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Auth, Hub } from 'aws-amplify';
import * as React from 'react';
import { ActivityIndicator, Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Paragraph, primaryColor } from './constants';
import { GroupCard } from './group-card';
import { primaryFontFamily, primaryFontFamilyHeavy } from './styles';
import { validateEmail } from './utils';
import { setConfigInStorage } from '../../plugins/hessia2/config.service';

export interface AuthState { user: any, isLoading: boolean, error?: any };
export interface AuthContext {
  authState: AuthState;
  login: (username, password) => void;
  logout: () => void;
  guestLogin: () => void;
}
const AuthContext = React.createContext<AuthContext>({ authState: { user: undefined, isLoading: true, error: null }, login: () => undefined, logout: () => undefined, guestLogin: () => undefined });

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = React.useState<AuthState>({ user: null, isLoading: true, error: null });

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthState({ user, isLoading: false, error: null });
      } catch (err: any) {
        console.error("Authentication error:", err);
        setAuthState({ user: null, isLoading: false, error: err.message });
      }
    };

    checkAuth();

    // Optional: Setup a Hub listener if you want to respond to other auth events
    const listener = ({ payload: { event, data } }: any) => {
      if (event === 'signIn') {
        setAuthState({ user: data, isLoading: false, error: null });
      }
      if (event === 'signOut') {
        setAuthState({ user: null, isLoading: false, error: null });
      }
      if (event === 'signIn_failure') {
        alert("Sign-In Failed");
        setAuthState({ user: null, isLoading: false, error: 'Failed to sign in' });
      }
    };

    Hub.listen('auth', listener);

    // Cleanup listener when component unmounts
    return () => Hub.remove('auth', listener);
  }, []);

  const login = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);
      setAuthState({ user, isLoading: false });
    } catch (err) {
      setAuthState({ user: null, isLoading: false });
    }
  };

  const guestLogin = async () => {
    try {
      setAuthState({ user: "guest", isLoading: false });
    } catch (err) {
      setAuthState({ user: null, isLoading: false });
    }
  };

  const logout = async () => {
    try {
      await setConfigInStorage({ storage: 'local' });
      await Auth.signOut();
      setAuthState({ user: null, isLoading: false });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout, guestLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };



export const ErrorBox = ({ style, message, title }: { style?: any, message: string, title: string }) => {
  return (
    <GroupCard style={[{ alignItems: 'center', justifyContent: 'center', paddingVertical: 20, paddingHorizontal: 20, backgroundColor: '#fce1ec', borderColor: primaryColor }, style]}>
      <Paragraph style={{ color: primaryColor }}>{title}</Paragraph>
      <View style={{ height: 7 }} />
      <Paragraph style={{ fontSize: 12, color: primaryColor }}>{message}</Paragraph>
    </GroupCard>
  )
}

const AlwaysOn = (props) => {
  return (
    <div>
      <div>I am always here to show current auth state: {props.authState}</div>
      <button onClick={() => props.onStateChange('signUp')}>Show Sign Up</button>
    </div>
  )
}

const StackNavigator = createStackNavigator();

export const AuthenticationWidget = ({ mode = "login" }: { mode: "login" | "register" }) => {

  const { authState, login, logout } = React.useContext(AuthContext);
  const navigation = useNavigation();

  //  Username Authentication
  const [verify, setVerify] = React.useState<boolean>(false);
  const [verifyCode, setVerifyCode] = React.useState<string>("");
  const [fieldsValid, setFieldsValid] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const isValidEmail = validateEmail(email);

  const [loadingEmail, setLoadingEmail] = React.useState(false);
  const [emailLoginFailure, setEmailLoginFailure] = React.useState(false);
  const [emailSignupFailure, setEmailSignupFailure] = React.useState(false);
  const [verifyFailure, setVerifyFailure] = React.useState(false);

  const [forgotPasswordMode, setForgotPasswordMode] = React.useState(false);
  const [forgotPasswordCode, setForgotPasswordCode] = React.useState<string>(undefined || "");
  const [isUpdatingPassword, setIsUpdatingPassword] = React.useState<boolean>(false);



  const emailSignIn = async () => {
    setLoadingEmail(true);
    setEmailLoginFailure(false);
    setEmailSignupFailure(false);
    try {
      const user = await Auth.signIn({ username: email, password });
      (window as any).location = '/dashboard';
    } catch (err: any) {
      setEmailLoginFailure(err);
    }

    setLoadingEmail(false);
  }

  const emailSignUp = async () => {
    setLoadingEmail(true);
    setEmailLoginFailure(false);
    setEmailSignupFailure(false);
    try {
      await Auth.signUp({ username: email, password, attributes: { email } });
      setVerify(true);
    } catch (err: any) {
      setEmailSignupFailure(err);
    }
    setLoadingEmail(false);

  }

  const emailDisabled = !isValidEmail || verify;

  const registerDisabled = !(password === confirmPassword) || (!password);

  const disabledTextColor = '#888888';
  const disabedBackgroundColor = '#fafafa';

  const [isConfirmingEmail, setIsConfirmingEmail] = React.useState(false);

  const resetForm = () => {
    setPassword("");
    setConfirmPassword("");
    setConfirmPassword("");
    setVerify(false);
    setEmailLoginFailure(false);
    setEmailSignupFailure(false);
    setVerifyFailure(false);
    setIsConfirmingEmail(false);
  }

  const verifyEmail = async () => {
    setIsConfirmingEmail(true);
    try {
      await Auth.confirmSignUp(email, verifyCode);
      resetForm();
      navigation.navigate("Authenticate" as never, { mode: "login" } as never);
    } catch (err: any) {
      setIsConfirmingEmail(false);
      setVerifyFailure(err);
    }
  }

  const forgotPassword = async () => {
    if (!isValidEmail) { alert('Please enter a valid email to reset your password.'); }
    await Auth.forgotPassword(email);
    setForgotPasswordMode(true);
  }

  const verifyForgotPassword = async () => {
    setIsUpdatingPassword(true);
    await Auth.forgotPasswordSubmit(email, forgotPasswordCode, password);
    setForgotPasswordMode(false);
    setIsUpdatingPassword(false);
  }

  const { guestLogin } = React.useContext(AuthContext);

  return (
    <View style={{ flex: 1, width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

      <TouchableOpacity onPress={() => { Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple } as any) }} style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 15, paddingHorizontal: 20, display: 'flex', flexDirection: 'row', width: 400, borderRadius: 5, backgroundColor: 'black' }}>
        {/* <Image source={require('../assets/apple.png')} resizeMode="contain" style={{ width: 25, height: 25, flexShrink: 0, flexGrow: 0 }} /> */}
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Text style={{ color: 'white', fontSize: 14, fontFamily: primaryFontFamily }}>{mode === "login" ? "Sign In with Apple" : "Sign Up with Apple"}</Text>
        </View>
      </TouchableOpacity>

      <View style={{ display: 'flex', flexDirection: 'row', width: 400, height: 20, marginVertical: 20, alignItems: 'center' }}>
        <View style={{ height: 1.5, flex: 1, backgroundColor: '#eeeeee' }} />
        <Text style={{ paddingHorizontal: 10, fontFamily: primaryFontFamilyHeavy, color: '#bbbbbb' }}>OR</Text>
        <View style={{ height: 1.5, flex: 1, backgroundColor: '#eeeeee' }} />
      </View>


      <TextInput editable={!forgotPasswordMode} placeholder='Email' value={email} onChangeText={setEmail} style={{ color: '#555555', fontFamily: primaryFontFamily, backgroundColor: 'white', borderRadius: 5, borderColor: '#dddddd', borderWidth: 1.5, padding: 15, width: 400 }} />

      <View style={{ height: 15 }} />

      <TextInput placeholder='Password' secureTextEntry={true} value={password} onChangeText={setPassword} style={{ color: '#555555', fontFamily: primaryFontFamily, backgroundColor: 'white', borderRadius: 5, borderColor: '#dddddd', borderWidth: 1.5, padding: 15, width: 400 }} />

      {
        mode === "register" && (
          <>
            <View style={{ height: 15 }} />
            <TextInput placeholder='Confirm Password' secureTextEntry={true} value={confirmPassword} onChangeText={setConfirmPassword} style={{ color: '#777777', fontFamily: primaryFontFamily, backgroundColor: 'white', borderRadius: 5, borderColor: '#dddddd', borderWidth: 1.5, padding: 15, width: 400 }} />
          </>
        )
      }

      <View style={{ height: 15 }} />

      <Pressable disabled={emailDisabled || ((mode === "register") && registerDisabled)} onPress={mode === "register" ? emailSignUp : emailSignIn} style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 15, paddingHorizontal: 20, display: 'flex', flexDirection: 'row', width: 400, borderRadius: 5, backgroundColor: emailDisabled ? disabedBackgroundColor : '#e7f3ff', borderColor: emailDisabled ? '#dddddd' : '#3e81f0', borderWidth: 1 }}>

        {
          loadingEmail ? (
            <ActivityIndicator color="white" />
          ) : (
            <>
              {/* <Image source={require('../assets/email.png')} resizeMode="contain" style={{ tintColor: emailDisabled ? disabledTextColor : '#3e81f0', width: 25, height: 25, flexShrink: 0, flexGrow: 0 }} /> */}
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Text style={{ color: emailDisabled ? disabledTextColor : '#3e81f0', fontSize: 14, fontFamily: primaryFontFamily }}>{mode === "register" ? "Sign Up with Email" : "Sign In with Email"}</Text>
              </View>
            </>
          )
        }

      </Pressable>

      <View style={{ display: 'flex', flexDirection: 'row', width: 400, height: 20, marginVertical: 20, alignItems: 'center' }}>
        <View style={{ height: 1.5, flex: 1, backgroundColor: '#eeeeee' }} />
        <Text style={{ paddingHorizontal: 10, fontFamily: primaryFontFamilyHeavy, color: '#bbbbbb' }}>OR</Text>
        <View style={{ height: 1.5, flex: 1, backgroundColor: '#eeeeee' }} />
      </View>

      <Pressable onPress={guestLogin} style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 15, paddingHorizontal: 20, display: 'flex', flexDirection: 'row', width: 400, borderRadius: 5, backgroundColor: emailDisabled ? disabedBackgroundColor : '#e7f3ff', borderColor: emailDisabled ? '#dddddd' : '#3e81f0', borderWidth: 1 }}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Text style={{ color: emailDisabled ? disabledTextColor : '#3e81f0', fontSize: 14, fontFamily: primaryFontFamily }}>Continue as Guest</Text>
        </View>
      </Pressable>

      {
        emailLoginFailure && (
          <GroupCard style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15, width: 400, paddingVertical: 20, paddingHorizontal: 20, backgroundColor: '#FFeeee', borderColor: '#FF8888' }}>
            <Paragraph style={{ color: '#FF6666' }}>Login Failed</Paragraph>
            <View style={{ height: 7 }} />
            <Paragraph style={{ fontSize: 12, color: '#FF6666' }}>{(emailLoginFailure as any).message}</Paragraph>
          </GroupCard>
        )
      }

      {
        emailSignupFailure && (
          <GroupCard style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15, width: 400, paddingVertical: 20, paddingHorizontal: 20, backgroundColor: '#FFeeee', borderColor: '#FF8888' }}>
            <Paragraph style={{ color: '#FF6666' }}>Signup Failed</Paragraph>
            <View style={{ height: 7 }} />
            <Paragraph style={{ fontSize: 12, color: '#FF6666' }}>{(emailSignupFailure as any).message}</Paragraph>
          </GroupCard>
        )
      }

      {
        verify && (
          <GroupCard style={{ marginTop: 15, width: 400 }}>
            <Paragraph style={{ textAlign: 'center' }}>Please enter the verification code sent to {email}:</Paragraph>
            <View style={{ height: 15 }} />
            <TextInput placeholder='Verification Code' value={verifyCode} onChangeText={setVerifyCode} style={{ color: '#777777', fontFamily: primaryFontFamily, backgroundColor: 'white', borderRadius: 5, borderColor: '#dddddd', borderWidth: 1.5, padding: 15, width: '100%' }} />
            <View style={{ height: 15 }} />
            <TouchableOpacity onPress={verifyEmail} style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 15, paddingHorizontal: 20, display: 'flex', flexDirection: 'row', width: '100%', borderRadius: 5, backgroundColor: '#e7f3ff', borderColor: '#3e81f0', borderWidth: 1 }}>
              {
                isConfirmingEmail ? (
                  <ActivityIndicator color="white" />
                ) : (
                  <Text style={{ color: '#3e81f0', fontSize: 14, fontFamily: primaryFontFamily }}>Verify</Text>
                )
              }

            </TouchableOpacity>
          </GroupCard>
        )
      }

      {
        verify && verifyFailure && (
          <GroupCard style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15, width: 400, paddingVertical: 20, paddingHorizontal: 20, backgroundColor: '#FFeeee', borderColor: '#FF8888' }}>
            <Paragraph style={{ color: '#FF6666' }}>Verification Failed</Paragraph>
            <View style={{ height: 7 }} />
            <Paragraph style={{ fontSize: 12, color: '#FF6666' }}>{(verifyFailure as any).message}</Paragraph>
          </GroupCard>
        )
      }

      {
        forgotPasswordMode && (
          <GroupCard style={{ marginTop: 15, width: 400 }}>
            <Paragraph style={{ textAlign: 'center' }}>Enter a new password above and enter the verification code sent to {email}:</Paragraph>
            <View style={{ height: 15 }} />
            <TextInput placeholder='Verification Code' value={forgotPasswordCode} onChangeText={setForgotPasswordCode} style={{ color: '#777777', fontFamily: primaryFontFamily, backgroundColor: 'white', borderRadius: 5, borderColor: '#dddddd', borderWidth: 1.5, padding: 15, width: '100%' }} />
            <View style={{ height: 15 }} />
            <TouchableOpacity onPress={verifyForgotPassword} style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 15, paddingHorizontal: 20, display: 'flex', flexDirection: 'row', width: '100%', borderRadius: 5, backgroundColor: '#e7f3ff', borderColor: '#3e81f0', borderWidth: 1 }}>
              {
                isUpdatingPassword ? (
                  <ActivityIndicator color="white" />
                ) : (
                  <Text style={{ color: '#3e81f0', fontSize: 14, fontFamily: primaryFontFamily }}>Update Password</Text>
                )
              }

            </TouchableOpacity>
          </GroupCard>
        )
      }

      {/* Toggle Mode */}

      <View style={{ height: 20 }} />

      <Text onPress={() => mode === "register" ? navigation.navigate("Authenticate" as never, { mode: "login" } as never) : navigation.navigate("Authenticate" as never, { mode: "register" } as never)} style={{ color: '#3e81f0', fontSize: 14, fontFamily: primaryFontFamily }}>{mode === "register" ? "Sign In" : "Sign Up"}</Text>

      {/* <View style={{ height: 3 }} /> */}

      <Text onPress={() => forgotPassword()} style={{ color: isValidEmail ? '#3e81f0' : '#aaaaaa', fontSize: 14, fontFamily: primaryFontFamily }}>Forgot Password 😭</Text>

      <View style={{ padding: 20, maxWidth: 300 }}>
        {/* NOTE:  I want to make it EASY to interpret these!!  Start with jut ths STANRAD text blob, but in the futur work on that hm! */}
        {/* TODO:  Open these in another window! */}
        <Paragraph style={{ lineHeight: 20, textAlign: 'center', fontSize: 14, fontFamily: primaryFontFamily, color: '#aaaaaa' }}>By continuing, you agree to our <Pressable onPress={() => navigation.navigate("Terms" as never)}><Text style={{ color: '#888888' }}>Terms</Text></Pressable> and <Pressable onPress={() => navigation.navigate("Privacy" as never)}><Text style={{ color: '#888888' }}>Privacy Policy</Text></Pressable></Paragraph>
      </View>

    </View >
  );

}
