import * as React from 'react';
import { TextInput, TextStyle, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

const NumberFieldStyle = {
  borderRadius: 20,
  paddingLeft: 15,
  paddingRight: 15,
  fontFamily: 'Poppins-Medium',
  borderColor: 'black',
  borderWidth: 1,
  height: 40
};

export const NumberField = ({ value, update, style }: { style: TextStyle, value: any, update: (value: any) => void }) => {
  const [num, setNum] = React.useState(value);
  const [valid, setValid] = React.useState(true);
  return (
    <View>
      <TextInput
        onChangeText={(_num) => {
          setNum(_num);
          try {
            const float = parseFloat(_num);
            update(float);
            setValid(true);
          } catch (err) {
            setValid(false);
          }
        }}
        // onBlur={input.onBlur}
        // onFocus={input.onFocus}
        value={num}
        autoCapitalize="none"
      />
    </View>
  );
};

export const sdtNumberRenderer = ({ sdt, value, update, name }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={ name }>
    <NumberField style={NumberFieldStyle} value={value} update={update} />
  </DavelField>
);
