import * as React from 'react';
import { ActivityIndicator, Pressable, TextInput, View } from 'react-native';
import { Button } from 'react-native-elements';
import { api } from '../bas-sdk';
import { GroupCard } from '../components/group-card';
import { defaultBorderRadius, Paragraph, primaryColor, SubHeader } from '../constants';
import { Work } from '../openapi';
import { ProfileContext, updateProfile, useCognitoToken } from '../utilities';

// TODO:  INSTEAD of a flag "supportEditMode" have a MODIFIER that completely changes the component with injection to support that hM!  This is DIFFERENT than a "mixin" because it freaking hmm...   AH!  Mixins let you change the FUNCTIONAL interface... and that's great and fine, but like... there's more to it than just that...

const TextFieldEditor = ({ value, setValue, title, editOnly }: { value?: string, setValue: (value: string) => void, title: string, editOnly?: boolean }) => {

  const [_value, _setValue] = React.useState(value);
  const [editMode, setEditMode] = React.useState<boolean>(!!editOnly);

  const saveText = async () => {
    setValue(_value || "");
    if (!editOnly) {
      setEditMode(false);
    }

  }

  const isSubmitDisabled = !_value;

  return (
    <View style={{ flexDirection: 'column' }}>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>{title}</Paragraph>

        {
          !editOnly && (
            <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
              <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
            </Pressable>
          )
        }

      </View>

      {
        !!editMode ? (
          <>
            <TextInput style={{ width: '100%', height: 45, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: '#fafafa', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 30 }} value={_value} onChangeText={(_value) => _setValue(_value)} />
            <Pressable disabled={ isSubmitDisabled } style={{ flex: 1, backgroundColor: isSubmitDisabled ? '#aaaaaa' : primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={async () => { saveText() }}>
              <Paragraph style={{ color: 'white' }}>Submit</Paragraph>
            </Pressable>
          </>
        ) : (
          <>
            {
              !!_value ? (
                <Paragraph>{_value}</Paragraph>
              ) : (
                <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
              )
            }
          </>

        )
      }

    </View>

  );


}

export const WorkProductImporter = ({ work }: { work: Work }) => {


  const importProduct = async (syncProductId: string) => {
    try {
      const _product = await api.workProductsControllerImport({ body: { workId: work.id, syncProductId } });
      alert("Imported!")
    } catch (err: any) {
      alert("Failed to import product" + JSON.stringify(err.message))
    }
  }

  return (
    <GroupCard>
      <SubHeader style={{ color: '#555555', marginBottom: 25, fontFamily: 'Poppins-Bold' }}>Printful Importer</SubHeader>
      <TextFieldEditor setValue={(value) => importProduct(value)} title="Sync Product ID" editOnly={true} />

      {/* Printful Link */}
      <a>{ `` }</a>
      
      {/* Stripe Links? */}
      
    </GroupCard>
  )
}
