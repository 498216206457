import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
var dagre = require("dagre");

import ReactFlow, {
  addEdge, removeElements
} from 'react-flow-renderer';

import {
  G as g, Rect as rect
} from 'react-native-svg';

import { View } from 'react-native';

(global as any).rect = rect;
(global as any).g = g;

const CustomNode = React.memo(({ data, isConnectable }: any) => {

  return <View style={{ width: 100, height: 100, backgroundColor: 'blue' }}></View>

  // return (
  //   <>
  //     <Handle
  //       type="target"
  //       position="left"
  //       style={{ background: '#555' }}
  //       onConnect={(params) => console.log('handle onConnect', params)}
  //       isConnectable={isConnectable}
  //     />
  //     <div>
  //       Custom Color Picker Node: <strong>{data.color}</strong>
  //     </div>
  //     <input
  //       className="nodrag"
  //       type="color"
  //       onChange={data.onChange}
  //       defaultValue={data.color}
  //     />
  //     <Handle
  //       type="source"
  //       position="right"
  //       id="a"
  //       style={{ top: 10, background: '#555' }}
  //       isConnectable={isConnectable}
  //     />
  //     <Handle
  //       type="source"
  //       position="right"
  //       id="b"
  //       style={{ bottom: 10, top: 'auto', background: '#555' }}
  //       isConnectable={isConnectable}
  //     />
  //   </>
  // );
});



const initialElements2 = [
  {
    id: '1',
    type: 'input',
    data: {
      label: (
        <>
          Welcome to <strong>React Flow!</strong>
        </>
      ),
    },
    position: { x: 250, y: 0 },
  },
  {
    id: '2',
    data: {
      label: (
        <>
          This is a <strong>default node</strong>
        </>
      ),
    },
    position: { x: 100, y: 100 },
  },
  {
    id: '3',
    data: {
      label: (
        <>
          This one has a <strong>custom style</strong>
        </>
      ),
    },
    position: { x: 400, y: 100 },
    style: {
      background: '#D6D5E6',
      color: '#333',
      border: '1px solid #222138',
      width: 180,
    },
  },
  {
    id: '4',
    position: { x: 250, y: 200 },
    data: {
      label: 'Another default node',
    },
  },
  {
    id: '5',
    data: {
      label: 'Node id: 5',
    },
    position: { x: 250, y: 325 },
  },
  {
    id: '6',
    type: 'custom',
    data: {
      label: (
        <>
          An <strong>output node</strong>
        </>
      ),
    },
    position: { x: 100, y: 480 }
  },
  {
    id: '7',
    type: 'output',
    data: { label: 'Another output node' },
    position: { x: 400, y: 450 },
  },
  { id: 'e1-2', source: '1', target: '2', label: 'this is an edge label' },
  { id: 'e1-3', source: '1', target: '3' },
  {
    id: 'e3-4',
    source: '3',
    target: '4',
    animated: true,
    label: 'animated edge',
  },
  {
    id: 'e4-5',
    source: '4',
    target: '5',
    arrowHeadType: 'arrowclosed',
    label: 'edge with arrow head',
  },
  {
    id: 'e5-6',
    source: '5',
    target: '6',
    type: 'smoothstep',
    label: 'smooth step edge',
  },
  {
    id: 'e5-7',
    source: '5',
    target: '7',
    type: 'step',
    style: { stroke: '#f6ab6c' },
    label: 'a step edge',
    animated: true,
    labelStyle: { fill: '#f6ab6c', fontWeight: 700 },
  },
];

const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

import { MenuLayoutPlugin } from '../elements/menu-layout-plugin/menu-layout-plugin';
import { HaliaPluginContext, HaliaPluginState, HaliaStudioPlugin } from './halia-studio-plugin';


//  MAYBE make a comonent that renders NORMALLY on the web... because React Native Web will translate EVERYTHING to fucking DOM things and ReactJS, OR it renders it in a WEB view if we're in a native context hm!... Fuck.. but React Native Web is happening in React somwhere.... we wan it to use the CONVERTED thing for the web view hmm...  SHOLD be doable.. JUST doing shit with the fucking hmm....  Probalby mapping the encong hmm

// const OverviewFlow = ({ children }: ) => {

//   return (
//     <WebView style={{ flex: 1, height: '100%', width: '100%' }} source={{ uri: 'http://google.com/' }} />
//   );
// }

const StackGraph = () => {

  const context = React.useContext<HaliaPluginState>(HaliaPluginContext);

  const plugins = context.haliaPlugin.getInstalledPlugins();

  //  REFERENCE:  https://github.com/dagrejs/dagre/wiki
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  g.setDefaultEdgeLabel(function () { return {}; })

  const initialElements: any[] = [];

  //  CONCERN:  It LOOKS Like it doesn't AUTO-POSITION UGH!

  //  Render (For Size)
  //  Layout
  //  Render (Final)

  //  Add Dagre Nodes
  plugins.forEach(plugin => {
    const { name, id } = plugin.details;
    g.setNode(id, { label: name, width: 144, height: 100 });
  });

  //  Add Dagre Edges
  plugins.forEach(plugin => {
    const { id, dependencies } = plugin.details;
    dependencies.forEach(dep => {
      g.setEdge(id, dep);
    })
  });

  dagre.layout(g);

  //  Add React Flow Nodes
  g.nodes().forEach((nodeId) => {
    const node = g.node(nodeId);
    if (!node) { return }
    initialElements.push({
      id: nodeId,
      data: {
        label: (
          <>
            {node.label}
          </>
        ),
      },
      type: 'input',
      position: { x: node.x, y: node.y },
    });
  });

  //  Add React Flow Edges
  plugins.forEach(plugin => {
    const { id, dependencies } = plugin.details;
    dependencies.forEach(dep => {
      initialElements.push({
        id: id + dep,  //  NOTE:  In the future, if we have INSTANCES, this and other places need to change
        source: dep,
        target: id,
        arrowHeadType: 'arrowclosed'
      },);

    })
  });

  const [elements, setElements] = React.useState(initialElements);
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els as any) as any);
  const onConnect = (params) => setElements((els) => addEdge(params, els as any) as any);


  return (
    <ReactFlow
      elements={initialElements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[15, 15]}
      nodeTypes={{
        custom: CustomNode,
      }}
    />
  )
}

export class FlowPlugin extends CorePluginClass {

  public static details = {
    id: "flow",
    name: "Flow Test",
    dependencies: [MenuLayoutPlugin.details.id, HaliaStudioPlugin.details.id],
  }

  public install = async (program: Program, { menuLayout, halia }: { menuLayout: MenuLayoutPlugin, halia: HaliaStudioPlugin }): Promise<FlowPlugin> => {

    menuLayout.registerMenuItem("Flow", { name: "Flow", component: () => <StackGraph />, icon: { type: "material", name: "flow" } })
    return this;
  }

}
