import "@expo/match-media";
import * as React from "react";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 });
  return isMobile ? children : null;
};
const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 499 });
  return isMobile ? children : null;
};
const Default = ({ children }: { children: any }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export interface SizeConfig {
  desktopBreakpoint: number;
  tabletBreakpoint: number;
}

const defaultSizeConfig: SizeConfig = {
  desktopBreakpoint: 1100,
  tabletBreakpoint: 700
}

//  TODO:  Like ALL components, it SHOULD be like a CSS system where I can APPLY somethign to ANY of them.  KIND OF reminds me of Anthem, where they had a COUPLE layer of configurability, but still not that generic and applying to any props hm!
//  CONSIDER:  AHH!!  DO want to use React (or something like it) for EVERYTHIGN in the app! hm!  NOT just for UI... MAYBE we can start it when a "component" is mounted, but NOT everythign needs to render hM!
//  CONCERN:  This WHOLE thing just seems like a lot... can't we simplify ALL of this with CSS-like systems and using runtime types and stuff??? HMMM

export const SizeConfigContext = React.createContext<SizeConfig>(defaultSizeConfig);
export const SizeConfigProvider = ({ children, sizeConfig }: { children: React.ReactChildren, sizeConfig: SizeConfig }) => {
  return (
    <SizeConfigContext.Provider value={ sizeConfig }>
      { children }
    </SizeConfigContext.Provider>
  );
}
export const useSizeConfig = () => {
  const sizeConfig: SizeConfig = React.useContext(SizeConfigContext);
  return sizeConfig;
}

export interface Sizes {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  sizeConfig: SizeConfig;
}

export const useSizes = (config: SizeConfig = defaultSizeConfig):  Sizes => {
  const sizeConfig = useSizeConfig() || config;
  const isDesktop = useMediaQuery({ minWidth: sizeConfig.desktopBreakpoint });
  const isTablet = useMediaQuery({ minWidth: sizeConfig.tabletBreakpoint, maxWidth: sizeConfig.desktopBreakpoint - 1 });
  const isMobile = useMediaQuery({ maxWidth: sizeConfig.tabletBreakpoint - 1 });
  const sizes = {
    isDesktop,
    isTablet,
    isMobile,
    sizeConfig
  }
  return sizes;
}

export const withSizes = (Component: any) => {
  return (props: any) => {
    const sizes = useSizes();
    return <Component {...props} sizes={sizes} />;
  }
}
