import { EntityType, HaborComponent, InstanceID, instanceIdSchema, NounId } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { haborSDK } from '../../hessia-plugin/config';
import { InstanceCreator } from '../entity/instance-creator';
import { HaborModelSDK } from '../model-sdk';

//
//  InstanceEditor Primitive Component
//

export interface InstanceEditorHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    instanceId: InstanceID;
  }
}
export const InstanceEditorHaborPrimitive = ({ userProps, frameworkProps }: InstanceEditorHaborPrimitiveProps) => {

  const { instanceId } = userProps;
  const { context } = frameworkProps;

  const nounId: NounId = { nounId: instanceId.nounId, type: EntityType.Noun };
  const modelSDK = new HaborModelSDK(haborSDK, context.token);


  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <InstanceCreator modelSDK={modelSDK} nounId={nounId} instanceId={instanceId} />
    </HaborContainer>
  );
};


//
//  InstanceEditor Habor Component
//

export const InstanceEditorHaborComponent: HaborComponent = {
  name: "InstanceEditorHaborComponent",
  propsSchema: {
    type: "object",
    extensible: false,
    properties: {
      instanceId: { ...instanceIdSchema, required: false },
    }
  },
  element: {
    name: "InstanceEditorHaborPrimitive",
    props: {
      instanceId: { type: "symbol", scopePath: ["props", "instanceId"] },
    },
    children: []
  }
};
