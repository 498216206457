import { useNavigation, useRoute } from '@react-navigation/core';
import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { ResourcePlugin } from '.';
import { medSpacer } from '../../../packages/kelp-bar/styles';

/**
 * Sets the Resource in the context of the editor.
 */
// const setResource = async (resourcePlugin: ResourcePlugin, navigation: any, resource?: any) => {

//   if (resource) {
//     resource = await resourcePlugin.resourceService.update(resource.id, { }); 
//   } else {
//     resource = await resourcePlugin.resourceService.create({ });
//   }

//   navigation.navigate(ResourceRoute.Editor, { resource });
// }

export const ResourceEditor = ({ resourcePlugin }: { resourcePlugin: ResourcePlugin }) => {

  const navigation = useNavigation();
  const route = useRoute<any>();

  const { params } = route;

  const resource = params?.resource;

  return (
    <ScrollView style={{ padding: medSpacer }}>
      {
        resourcePlugin.resourceServices.map(({ component: Comp }) => <><Comp resourcePlugin={ resourcePlugin } resource={ resource } /><View style={{ height: medSpacer }} /></>)
      }
      {/* <Button title={ resource ? 'Update' : 'Create' } onPress={ () => setResource(resourcePlugin, navigation, resource) } /> */}
    </ScrollView>
  );
}

