/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkProduct
 */
export interface WorkProduct {
    /**
     * 
     * @type {string}
     * @memberof WorkProduct
     */
    syncProductId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkProduct
     */
    workId: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkProduct
     */
    ownerApproved: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkProduct
     */
    printfulProduct: string;
    /**
     * 
     * @type {string}
     * @memberof WorkProduct
     */
    owner: string;
}

export function WorkProductFromJSON(json: any): WorkProduct {
    return WorkProductFromJSONTyped(json, false);
}

export function WorkProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syncProductId': json['syncProductId'],
        'workId': json['workId'],
        'ownerApproved': json['ownerApproved'],
        'printfulProduct': json['printfulProduct'],
        'owner': json['owner'],
    };
}

export function WorkProductToJSON(value?: WorkProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syncProductId': value.syncProductId,
        'workId': value.workId,
        'ownerApproved': value.ownerApproved,
        'printfulProduct': value.printfulProduct,
        'owner': value.owner,
    };
}

