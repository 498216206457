import * as React from 'react';
import { ActivityIndicator, Pressable, ScrollView, TextInput, View } from 'react-native';
import { GalleryButton } from '../components/common';
import { ProfileImageField } from '../components/fields';
import { GroupCard } from '../components/group-card';
import { HeroCard } from '../components/hero-card';
import { defaultBorderRadius, Paragraph, primaryColor } from '../constants';
import { ProfileContext, updateProfile, useCognitoToken } from '../utilities';

const StoryEditor = () => {

  const token = useCognitoToken();

  const [profile, setProfile] = React.useContext(ProfileContext);

  const [description, setDescription] = React.useState(profile?.description);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setDescription(profile?.description)
    setLoading(false);
  }, [profile])

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const saveDescription = async () => {
    setLoading(true);

    const _profile = await updateProfile({ ...profile, description: description || "" }, token);
    setProfile(_profile);
    setEditMode(false);
    setLoading(false);
  }

  return (
    <View style={{ flexDirection: 'column' }}>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Story</Paragraph>

        <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
          <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
        </Pressable>
      </View>

      {
        editMode ? (
          <>
            <TextInput style={{ width: '100%', height: 200, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: '#fafafa', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 30 }} multiline={true} numberOfLines={Math.max(200 / 16)} value={description} onChangeText={(text) => setDescription(text)} />
            <GalleryButton style={{ width: 100 }} title="Submit" onPress={saveDescription} />
          </>
        ) : (
          <>
            {
              loading ? (
                <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
              ) : (
                <Paragraph>{description || "Unknown"}</Paragraph>
              )
            }
          </>

        )
      }

    </View>
  );


}

const DisplayNameEditor = () => {

  const token = useCognitoToken();

  const [profile, setProfile] = React.useContext(ProfileContext);
  const [loading, setLoading] = React.useState(true);

  const [displayName, setDisplayName] = React.useState(profile?.displayName);

  React.useEffect(() => {
    setDisplayName(profile?.displayName)
    setLoading(false);
  }, [profile])

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const saveDisplayName = async () => {

    setLoading(true);
    const _profile = await updateProfile({ ...profile, displayName }, token);
    setProfile(_profile);
    setEditMode(false);
    setLoading(false);
  }

  return (
    <View style={{ flexDirection: 'column' }}>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Display Name</Paragraph>

        <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
          <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
        </Pressable>
      </View>

      {
        editMode ? (
          <>
            <TextInput style={{ width: '100%', height: 45, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: '#fafafa', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 30 }} value={displayName} onChangeText={(text) => setDisplayName(text)} />
            <GalleryButton style={{ width: 100 }} title="Submit" onPress={saveDisplayName} />
          </>
        ) : (
          <>
            {
              loading ? (
                <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
              ) : (
                <Paragraph>{displayName || "Unknown"}</Paragraph>
              )
            }
          </>

        )
      }

    </View>

  );


}

// TODO:  Abstract some of these things to make it eeasier to make these components.

const LocationEditor = () => {

  const token = useCognitoToken();

  const [profile, setProfile] = React.useContext(ProfileContext);

  const [loading, setLoading] = React.useState(true);

  const [location, setLocation] = React.useState(profile?.location);

  React.useEffect(() => {
    setLocation(profile?.location)
    setLoading(false);
  }, [profile])

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const saveLocation = async () => {

    setLoading(true);
    const _profile = await updateProfile({ ...profile, location }, token);
    setProfile(_profile);
    setEditMode(false);
    setLoading(false);
  }

  return (
    <View style={{ flexDirection: 'column' }}>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Location</Paragraph>

        <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
          <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
        </Pressable>
      </View>

      {
        editMode ? (
          <>
            <TextInput style={{ width: '100%', height: 45, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: '#fafafa', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 30 }} value={location} onChangeText={(text) => setLocation(text)} />
            <GalleryButton style={{ width: 100 }} title="Submit" onPress={saveLocation} />
          </>
        ) : (
          <>
            {
              loading ? (
                <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
              ) : (
                <Paragraph>{location || "Unknown"}</Paragraph>
              )
            }
          </>

        )
      }

    </View>

  );

}

export const ProfileScreen = () => {

  //  CONSIDER:  We DO have hook for when it CHANGEDS... how to differentate between WHY it changes??? This is where it's nice to have auxiliary info hmm... ugh... I think en event emitter and idek hm

  //  CONSIDER:  This is an IMPLEMENTAITON of a common pattern.  Where we MIRROR Hmm...

  return (

    <ScrollView contentContainerStyle={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <HeroCard title="Profile" subTitle="Create a public artist profile." />
      <View style={{ width: '100%', maxWidth: 700, borderRadius: defaultBorderRadius, overflow: 'hidden' }}>
        <GroupCard>
          <ProfileImageField />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <StoryEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <DisplayNameEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <LocationEditor />
        </GroupCard>
      </View>
      <View style={{ height: 100, width: '100%' }} />
    </ScrollView>
  )
}
