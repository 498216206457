import { BlurView } from 'expo-blur';
import { Color, EntityType, FrameworkContext, HaborComponent, HaborIconSelection, HDTRelationshipValue, Instance, InstanceID, InstanceInternal, InstanceSearchParams, menuNoun, NounId, Page, PageSelection, Workspace, OwnershipRelationshipNoun } from 'habor-sdk';
import * as _ from 'lodash';
import * as React from 'react';
import { Animated, Dimensions, Easing, PanResponder, PanResponderInstance, Text, TouchableOpacity, View } from 'react-native';
import { RouteComponentProps, withRouter } from 'react-router';
import { TextH3 } from '../../../packages/kelp-bar/text';
import { haborSDK } from '../hessia-plugin/config';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { getRaisedStyle, largeSpacer, medSpacer, RaisedHeight, tabBarHeight, homeIndicator } from '../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";
import { FlatGrid } from 'react-native-super-grid';
import { getSelectedMenu } from './menu-settings-list';

//  TODO:  Don't forget that this should EVENTUALLY be a function as part of the PLUGIN interface, MAYBE even on the backend (if we keep a distinction?).  Ah!!  if we want to support web, then there SHOULD be a distinction???  MAYBE we can encrypt the logic and FORCE valid state transitions on the client with a secret??
//  REALIZATION:  Normally, this would be locked in another app / API, and we'd need an SDK + credentials to access it.  In Hessia, plugins are all accessible and extensible through registraion points??
export const getMenu = async (workspace: InstanceInternal<Workspace>, token: string) => {

    const selectedMenu = await getSelectedMenu(token);

    return selectedMenu;
}

//  NOTE:  These are DIFFERENT from MenuItemConfig.  They have additional properties (like size).
export interface MenuItemProps {
  size?: number;
  icon: HaborIconSelection;
  onPress: () => void;
}
export const MenuItem = (props: MenuItemProps) => {

  //  Unpack
  const { icon: { name: iconName, type: iconType }, onPress, size = 25 } = props;

  return (
    <TouchableOpacity style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} onPress={ onPress }>
      <KelpIcon name={ iconName } type={ iconType } size={ size } />
    </TouchableOpacity>
  );
}

// let timer: number | undefined = undefined;
// const delay = 100;
// let lastCache: any = undefined;
// const updateAnimation = (value: Animated.Value, toValue: number, duration?: number, easing?: EasingFunction, force?: boolean) => {
  
//   if (force) {
//     clearTimeout(timer);
//     timer = undefined;
//     lastCache = undefined;
//   }

//   if (timer) {
//     lastCache = {
//       value, toValue, duration, easing, force
//     }
//     // console.log("Cached");
//     return
//   };

//   // console.log("Invoked!!");
//   Animated.timing(
//     // Animate value over time
//     value, // The value to drive
//     {
//       toValue,
//       duration: duration || 1500,
//       easing: easing || Easing.linear
//     },
//   ).start(); // Start the animation

//   //  We had two issues:
//   //  1.  We were making the update at the end of a period instead of the start.  That would mean that we'd indicate which position the finger is, then we'd wait a period, then we'd move to that position and simultaneously queue up the next one.  This means we were lagging more than we needed to be.
//   //  2.  If the user had micro-pauses, it's possible that after a period ended, the next would miss the signals.  So, we queue the last signal and fire at end of period.
//   const clearTimer = () => {
//     // console.log("-- CLEARED --");
//     clearTimeout(timer);
//     timer = undefined;
//     if (lastCache) {
//       const { value, toValue, duration, easing, force } = lastCache;
//       updateAnimation(value, toValue, duration, easing, force);
//       lastCache = undefined;
//     }
//   }

//   if (!force) {
//     timer = setTimeout(clearTimer, delay);
//   }
// };

// const debouncedUpdate = _.debounce(updateAnimation, 1000)

export interface MenuProps extends React.ComponentProps<any> {
  hover?: boolean;  //  TODO:  Implement
  children: any[];
}
interface MenuState {
  expanded: boolean;
  menuY: Animated.Value;
  blur: Animated.Value;
  dragOffset: number | undefined;
  deviceHeight?: number;
  shortcutsClarity: Animated.Value;
  jsBlur: number;
}
class MenuBase extends React.Component<MenuProps, MenuState> {
  private _panResponder: PanResponderInstance;
  private initialHeight = tabBarHeight + homeIndicator;
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      expanded: false,
      menuY: new Animated.Value(this.initialHeight),
      blur: new Animated.Value(0),
      shortcutsClarity: new Animated.Value(0),
      dragOffset: undefined,
      deviceHeight: undefined,
      jsBlur: 0
    };
    this.state.blur.addListener((value) => {
      this.setState({ jsBlur: value.value * 100 });
  });
    this._panResponder = PanResponder.create({

      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => false,

      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
        // this.setState({ dragOffset: 0 });
      },
      onPanResponderMove: (evt, gestureState) => {
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
        // console.log(new Date().getTime())
        const deviceHeight = this.state.deviceHeight;
        if (!deviceHeight) { return; }
        
        // console.log(new Date().getTime())
        const menuHeight = deviceHeight - gestureState.moveY;
        if (menuHeight >= this.initialHeight) {

          this.setState({ expanded: true });
            Animated.spring(
              this.state.menuY,
              {
                toValue: menuHeight,
                speed: 13,
                bounciness: 2,
                useNativeDriver: false
              },
            ).start();

            Animated.spring(
              this.state.blur,
              {
                toValue: 0.65 + (menuHeight / deviceHeight),
                speed: 13,
                bounciness: 2,
                useNativeDriver: false
              },
            ).start();

            if (menuHeight >= 0.5 * deviceHeight) {
              Animated.spring(
                this.state.shortcutsClarity,
                {
                  toValue: (menuHeight - 0.5 * deviceHeight) / ( 0.2 * deviceHeight),
                  speed: 13,
                  bounciness: 2,
                  useNativeDriver: false
                },
              ).start();
            }
            
          // updateAnimation(this.state.menuY, menuHeight);
        }
      },
      onPanResponderTerminationRequest: (evt, gestureState) => true,
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        // console.log("RELEASED");
        const deviceHeight = this.state.deviceHeight;
        if (!deviceHeight) { return; }
        const menuHeight = deviceHeight - gestureState.moveY;
        const threshold = 0.5 * deviceHeight;
        if (menuHeight > threshold) {
          Animated.timing(
            this.state.menuY,
            {
              toValue: deviceHeight * .75,
              duration: 700,
              easing: Easing.bounce,
              useNativeDriver: false
            },
          ).start();
          Animated.timing(
            this.state.blur,
            {
              toValue: 100,
              duration: 700,
              easing: Easing.linear,
              useNativeDriver: false
            },
          ).start();
          Animated.timing(
            this.state.shortcutsClarity,
            {
              toValue: 100,
              duration: 700,
              easing: Easing.ease,
              useNativeDriver: false
            },
          ).start();
        } else if (menuHeight <= threshold) {

          this.setState({ expanded: false });

          Animated.timing(
            this.state.menuY,
            {
              toValue: this.initialHeight,
              duration: 350,
              easing: Easing.cubic,
              useNativeDriver: false
            },
          ).start();
          Animated.timing(
            this.state.blur,
            {
              toValue: 0,
              duration: 350,
              easing: Easing.cubic,
              useNativeDriver: false
            },
          ).start();
          Animated.timing(
            this.state.shortcutsClarity,
            {
              toValue: 0,
              duration: 350,
              easing: Easing.ease,
              useNativeDriver: false
            },
          ).start();
        }
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
        // console.log("TERMINATED");

      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming the JS
        // responder. Returns true by default. Is currently only supported on android.
        return true;
      },
    });
  }
  private expandMenu = () => {
    this.setState({ expanded: true });
  }

  public componentDidMount = () => {
    this.setState({ deviceHeight: Dimensions.get("window").height })
  }
  private collapseMenu = () => {
    this.setState({ expanded: false });
  }

  public render = () => {

    const { children } = this.props;
    const { expanded, menuY, deviceHeight, blur, shortcutsClarity, jsBlur } = this.state;

    if (!deviceHeight) { return null; }

    const MenuView = expanded ? View : BlurView;

return (
  <MenuView tint="light" intensity={ expanded ? 0 : 80 } style={{ backgroundColor: Color.white, flexGrow: 0, paddingTop: expanded ? medSpacer : 0, paddingBottom: expanded ? medSpacer : 0, borderRadius: expanded ? 15 : 0, display: 'flex', flexDirection: "row", alignItems: expanded ? 'flex-start' : 'center', height: 100 }} >
              {
                expanded ?
                  <FlatGrid
                    renderItem={ ({ item }) => item }
                    data={ children }
                    itemDimension={ 70 }
                    style={{ flex: 1 }}
                    itemContainerStyle={{ marginBottom: medSpacer }}
                  /> :
                  children.slice(0, 5)
              }
            {/* <FlatGrid
              items={ children }
              renderItem={ ({ item }) => item }
            /> */}
          </MenuView>
);

//  TODO:  Put this back when we learn how to keep it relative on the bottom.  I THINK we can MIX the above code and below!
    // return (
      // <PanGestureHandler minDeltaY={ expanded ? -100 : 100 } onGestureEvent={ expanded ? this.collapseMenu : this.expandMenu }></PanGestureHandler>
      // <PanResponder onGestureEvent={ expanded ? this.collapseMenu : this.expandMenu }>
      // TODO:  Convert this logic to state machine mechanics with SPECIFIC states.  I read this tip in a blog post about React animation, but in general that seems like a good idea.
      //        In this way, each "state" is JUST like a self-contained animation sequence.  Prior to that state, nothing in that anination group should have been animated??
      //  TODO:  Show ALL components below the current WITH their zIndex in Hessia Components / Blocks???  MAYBE show with different colors / gradient?
      //  TOOD:  MAYBE make an expliit "layer" for this?
      // <View style={{ width: '100%', height: '100%', backgroundColor: Color.transparent, position: 'absolute' }} pointerEvents="box-none">
      //   {
      //     expanded ?
      //       <React.Fragment>
      //         <BlurView tint="default" intensity={ jsBlur } style={{ zIndex: 5, width: '100%', position: 'absolute', height: '100%' }} pointerEvents="none" />
      //         <Animated.View style={{ zIndex: 10, width: '100%', position: 'absolute', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Color.medGray, opacity: shortcutsClarity }} pointerEvents="none">
      //           <Animated.View style={{ marginTop: 90, opacity: shortcutsClarity }}>
      //             <TextH3 style={{ color: Color.offWhite }}>Shortcuts</TextH3>
      //           </Animated.View>
      //         </Animated.View>
      //       </React.Fragment> : 
      //       null
      //   }
      //   <Animated.View { ...this._panResponder.panHandlers } style={{ zIndex: 20, width: expanded ? '92%' : '100%', left: expanded ? '4%' : 0, position: 'absolute', bottom: expanded ? -15 : 0, height: menuY, ...getRaisedStyle({ raisedHeight: RaisedHeight.none }), borderRadius: expanded ? 15 : 0 }}>
      //     <MenuView tint="light" intensity={ expanded ? 0 : 80 } style={{ backgroundColor: Color.white, flexGrow: 1, paddingTop: expanded ? medSpacer : 0, paddingBottom: expanded ? medSpacer : 0, borderRadius: expanded ? 15 : 0, display: 'flex', flexDirection: "row", alignItems: expanded ? 'flex-start' : 'center' }} >
      //         {
      //           expanded ?
      //             <FlatGrid
      //               renderItem={ ({ item }) => item }
      //               data={ children }
      //               itemDimension={ 70 }
      //               style={{ flex: 1 }}
      //               itemContainerStyle={{ marginBottom: medSpacer }}
      //             /> :
      //             children.slice(0, 5)
      //         }
      //       {/* <FlatGrid
      //         items={ children }
      //         renderItem={ ({ item }) => item }
      //       /> */}
      //     </MenuView>
      //   </Animated.View>
      // </View>
    // );
  }
}
export const Menu = MenuBase

// export const getInstanceId = (instance: InstanceInternal): InstanceID => {
//   return {
//     nounId: instance.nounId,
//     instanceId: instance.id,
//     type: EntityType.Instance
//   };
// }

// export const getNounId = (instance: InstanceInternal | Instance): NounId => {
//   return {
//     nounId: instance.nounId,
//     type: EntityType.Noun
//   };
// }



//  TODO:  Move this to the "Nestable" Plugin?  Or the "Ownership" Plugin?
//  NOTE:  EVEN THOUGH the interface might LOOK similar, it's CONCEPTUALLY a different thing, BECUASE it gives us the power to then CHANGE the implementation details!!!!  That's a HUGE difference!
//  TODO:  MAYBE find a way to make SEVERAL function signatures!  SOME of which are back-channel!  That means they have access to an overall CONTEXT, THROUGH that param?  Ah... MAYBE it's just a context thing???
export const nestInstance = async (parentId: InstanceID, childId: InstanceID, token: string) => {
  const rel = await haborSDK.createInstance({ nounId: OwnershipRelationshipNoun.id, payload: {
    srcId: childId,
    destId: parentId
  } }, token);
  return rel;
};

//  TODO:  Generalize to 'getInstancesWithContext' and eventually, 'getObjectsWithContext' to obtain objects which meet a certain context?
//  TODO:  Eventually do ALL of this in the backend???
//  IDEA(resurfaced):  Consider top level support for CROSS-ENTITY queries like:  All Menu intances which are linked to this Workspace where the folloing conditions are true...
export async function searchInstancesWithOwner<T>(owner: InstanceInternal<any>, token: string, searchParams: InstanceSearchParams<T>) {

  //  Resolve the Raw Query
  const results = await haborSDK.searchInstances(token, searchParams);

  //  Build terms for owner relationships
  //  TODO:  Watch out for an INPUT which is TOO big for the server to handle!  I THINK the HTTP layer should handle that?  BUT it could still be a VERY slow query???  MAYBE use a learning / predicition layer to estimate the time for a query to complete.
  const ownerTerms = results.map(result => ({ match: { payload: { srcId: { nounId: result.nounId, instanceId: result.id, type: EntityType.Instance } } } }));

  //  Get Owner Relationships
  const ownerRelationships = await haborSDK.searchInstances(token, { nounId: OwnershipRelationshipNoun.id, search: { any: ownerTerms } });

  //  Filter
  //  TODO-CURRENT:  COULD be converted to a map to reduce the order of the operation.
  //  IDEA:  Label TODOs with the VERSION of the plan they apply to!  SOMETIMES, it might apply to the CURRENT plan for the code, and OTHER times it won't make sense until certain milestones are hit and we're ready to move on to a NEW part of the plan!  Planning / brainstorming for the FUTURE insead of the current implementation!
  const filteredResults = results.filter(result => ownerRelationships.find(rel => rel.payload.srcId.instanceId == result.id));

  return filteredResults;
};

export interface WorkspaceMenuProps extends RouteComponentProps {
  frameworkContext: FrameworkContext;
  setPage: (selectedPage: PageSelection) => void;
  pages: { [pageId: string]: InstanceInternal<Page> };
}
export interface WorkspaceMenuState {
  menu?: InstanceInternal<any>;
}

//  TODO:  This menu can be re-used in other parts of the app if it's de-coupled from specific concepts like "MainMenu".
class WorkspaceMenuBase extends React.Component<WorkspaceMenuProps, WorkspaceMenuState> {

  constructor(props: WorkspaceMenuProps) {
    super(props);
    this.state = {
    }
  }


  public componentDidMount = async () => {

    //  Unpack
    const { frameworkContext: { workspace, token } } = this.props;

    //  Guard
    if (!workspace) throw new Error("Workspace must be defined");

    //  Get Menu
    const menu = await getSelectedMenu(token);

    this.setState({ menu });
  }

  public render = () => {

    //  Unpack
    const { setPage, pages } = this.props;
    const { menu } = this.state;
    
    //  TODO:  Abstract / Generlize / Centralize the loading logic and distinguish it from the state, which COULD be undefined even after loading in case of an exception??
    if (!menu) {
      return <Text>Loading...</Text>
    }

    const menuPages: InstanceInternal<Page>[] = menu.payload.pages.map((menuItem: HDTRelationshipValue) => {
      const page = menuItem.instanceIdList[0] ? pages[menuItem.instanceIdList[0].instanceId] : undefined
      return page;
    });

    const PageMenuItem = ({ page }: { page?: InstanceInternal<Page> }) => {
      if (!page) return null;
      return <MenuItem icon={ page.payload.icon || { name: "run", type: "material-community" } } onPress={ () => setPage({ pageId: page.id, props: {} }) } />
    }

    //  Get the Pages
    // const menuPageMap = _.mapValues(menu, (menuItem) => menuItem.instanceIdList[0] ? pages[menuItem.instanceIdList[0].instanceId] : undefined)
    return (
      
      <Menu>
        {
          menuPages.map(page => <PageMenuItem page={ page } />)
        }
      </Menu>
    );
  }
}
export const WorkspaceMenu = withRouter(WorkspaceMenuBase);


export interface MenuHaborPrimitiveProps extends PrimitiveProps {}

export interface MenuHaborPrimitiveProps {}
interface MenuHaborPrimitiveState {}
export class MenuHaborPrimitive extends React.Component<MenuHaborPrimitiveProps, MenuHaborPrimitiveState> {
  constructor(props: MenuHaborPrimitiveProps) {
    super(props);
    this.state = {}
  }

  public componentDidMount = async () => {

  }

  public render = () => {

    //  Unpack
    const { userProps: { pages, setPage }, frameworkProps } = this.props;
    const { context } = frameworkProps;

    //  Construct the Pages Map
    const pageMap = _.mapValues(_.groupBy(pages, (page) => page.id), (orig) => orig[0] );

    return (
      <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row', position: 'absolute', width: '100%', height: '100%' }}>
        <WorkspaceMenu frameworkContext={ context } pages={ pageMap } setPage={ setPage } />
      </HaborContainer>
    );
  }
}

export const MenuHaborComponent: HaborComponent = {
  name: "MenuHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "MenuHaborPrimitive",
    props: {
      pages: { type: "symbol", scopePath: ["props", "pages"] },
      setPage: { type: "symbol", scopePath: ["props", "setPage"] },

    },
    children: []
  }
};
