//  DESCRIPTION:  This is to create a "Ref" to the NavigationContainer for cases where we wish to reference the "navigation prop" OUTSIDE of a component context.
//  REFERENCE:  https://reactnavigation.org/docs/navigating-without-navigation-prop/x

import * as React from 'react';

export const navigationRef: any = React.createRef();

export function navigate(name: any, params: any) {
  navigationRef.current?.navigate(name, params);
}
