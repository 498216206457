import { useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { Color, EntityType, FrameworkContext, getInstanceSchemaFromProperties, HaborComponent, HaborComponentContext, HaborIconSelection, Instance, InstanceID, InstanceInternal, Noun, NounId, NounInternal, NounProperty, ObjectID, Plugin, pluginNoun, RGBAColor, SDTObject, serializedUserBlockNoun, SystemEnablement, SystemEnablementNoun, Workspace } from 'habor-sdk';
import * as React from 'react';
import { ActivityIndicator, Alert, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { ModelPlugin, ModelPluginContext } from '..';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';
import { capitalizeAllWords } from '../../../../packages/davel-ui/davel-utils';
import { NounField } from '../../../../packages/davel-ui/habor-types/noun/noun-field';
import { AppHeader } from '../../../../packages/kelp-bar/app-header';
import { KelpIcon } from '../../../../packages/kelp-bar/kelp-icon';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { TextSubTitle } from '../../../../packages/kelp-bar/text';
import { GalleryButton } from '../../../gallery/components/common';
import { ComponentPlugin, ComponentPluginContext, ComponentRegister, withComponent } from '../../component-plugin';
import { HaborComponentViewer } from '../../component-plugin/habor-react/habor-component-viewer';
import { Composer } from '../../composer-plugin/composer/composer';
import { ContextPlugin, ContextPluginContext } from '../../context-plugin/context-plugin';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { HessiaEmitter } from '../../hessia-plugin/hessia-events';
import { SpacePlugin, SpaceReactContext } from '../../space-plugin/space-plugin';
import { AppEvent } from '../../workspace-plugin/workspace-settings';
import { ModelSDK } from '../model-sdk';

export const BubbleMenu = ({ children }: any) => {
  return (
    <BlurView style={{ display: "flex", flexDirection: "row", alignItems: 'center', height: 70 }}>
      {children}
    </BlurView>
  );
}

export interface BubbleMenuItemProps {
  size?: number;
  icon: HaborIconSelection;
  color?: string;
  onPress: () => void;
}
export const BubbleMenuItem = (props: BubbleMenuItemProps) => {

  //  Unpack
  const { icon: { name: iconName, type: iconType }, onPress, size = 25, color = Color.darkGray } = props;

  return (
    <TouchableOpacity style={{ flex: 1, alignItems: 'center', justifyContent: 'center', borderRadius: 7, height: 50, width: 50, backgroundColor: '#f4f4f4', marginLeft: 10, marginRight: 10 }} onPress={onPress}>
      <KelpIcon name={iconName} type={iconType} size={size} color="#aaaaaa" />
    </TouchableOpacity>
  );
}


// import { withNavigation } from 'react-navigation';

//  TODO-IMPORTANT:  This is a function that ALSO exists in Habor... therea are SEVERAL issues here!!!  This function SHOULD be bundled with the "System" primitive Habor system, perhaps with an API SDK we can invoke directly.  BUT, I feel this should ALSO be done automatically as part of the CONTEXT system!!! When we create a System from "WITHIN" a "Space".
//  TODO:  Reinstate this.
export const enablePluginsForSpace = async (plugins: InstanceInternal<Plugin>[], space: InstanceInternal<any>, token: string) => {
  for (const plugin of plugins) {
    const rel: Instance<SystemEnablement> = { nounId: SystemEnablementNoun.id, payload: { srcId: { nounId: plugin.nounId, type: EntityType.Instance, instanceId: plugin.id }, destId: { nounId: space.nounId, instanceId: space.id, type: EntityType.Instance } } };
    await haborSDK.createInstance(rel, token);
  }
  alert("ENABLED PLUGIN FOR SPACE")
};

//  TODO:  Perhaps LISTEN to changes for Workspace / Plugin association? HM!  THOSE systems should handle this though!
export const createOrUpdateInstance = async (modelSDK: ModelSDK, inst: Instance, originalInstance?: InstanceInternal<any>, systemEditMode?: boolean, space?: InstanceInternal<Workspace>, token?: string) => {

  //  TODO-IMPORTANT:  We should NOT have the concepts of Worksapce / Plugin here!  This is just ANOTHER system and the backend should handle this from the CONTEXT!?  Then, we can just do our stuff and the relations will be built automatically!!!

  //  Create the Instance
  let instInternal: InstanceInternal<any>;
  console.log("test");
  if (!originalInstance) {
    instInternal = await modelSDK.createInstance(inst);

    //  TODO:  Handle linkage UPDATES

    //  TODO:  Broadcast an ACTION for both Before / After of this operation!? HM.. MAYBE let both UI AND serverside shite / stuff handle it!?

    //  FOR NOW:  I'm broadcasting an action that other systems can hook into.  BUT in the future, I'd like this to be gennerlized for the Class system, AND should be usable perhaps by custom systems with custom action / events, PLUS I don't want the primitive systems to directly depend upon eachother? hmm MABYE do this instead with wrappable / injectable functin and THEN a couopling system which uses THAT inteface at the PLUGIN level to enable this shit for our plugins? HM!
    //  CONCERN:  There should be a param type associated with the event type.
    HessiaEmitter.emit("CreatedInstance", { instance: instInternal });

    //  CONSIDER -IMPORTANT:  CONSIDER hard-coding the SYSTEM primitives WITHOUT storing the relationships, THIS way it can be FAST?  HM... MAYBE!?  

    //  Associate the System with the Space (if we're updating a System!?)
    //  TODO:  Make sure there are NOT tons of DUPLICATE shits here.
    //  TODO:  This should be the responsibiliy of the "System" Habor System.
    //  TODO:  Use the "Parent" system for this instead!?  This is just a usage of the Parent system by the "HESSIA" System!?  MAYBE I can make the "Hessia" system explicit, WITH dependencies!??? HmhasdlakjsdfMM!!
    //  TODO:  Re-enable Plugin / Space association!
    // alert(JSON.stringify(space));
    // alert(JSON.stringify({ space, token }))
    if (instInternal.nounId === pluginNoun.id && !!space && !!token) {
      await enablePluginsForSpace([instInternal], space, token);
    }

    // //  Handle Plugin Association
    // if (!!plugin && !!systemEditMode) {
    //   // await enableInstancesForPlugin([instInternal], plugin, token);
    // }

    //  NOTE-IMPORTANT:  WE SHOULD be associating with the SPACE as well!  CURRENTLY, things NOT scoped to a system are scoped to a USER... so... we want to scope to the WORKSPACE, AND in the future, COLLAPSE the Workspace and System and JUST associate with a SYSTEM and associate the SYSTEM with a user!?  That's the model we're going for I think!?!!  Perhaps it's even more primitive in Habor? Hmm.. interesitng.. MAYBE we can re-write Habor with this stuff hard-coded??? Hmm... OR, is Hessia truly a Habor system??  Hmm!??  This lack of clarity is a LARGE part of the reason I'd like to build these LOCAL first in the Beta!  THEN they can be subject to change and all that?! HMM!!  I WOULD like to be able to merge the Beta Members online once cloud is available though!?

  } else {
    instInternal = await modelSDK.updateInstance(originalInstance, inst);
    //  CONSIDER:  MAYBE if a Hessia System updates something, we can ADDITINALLY scope by a feature like the NounID? Hmm.. OR eventully let the sstems rregister their own ssytem level events, and perhaps hide the instance events from those without access? hmm.  MAYBE a syste crreates an access context? hmm
    //  TODO:  This SHOULD closer to the API... PERHAPS on the server and triggering an external thing? hmm.. THIS location is ONNLY through the UI.. hmmm!
    HessiaEmitter.emit("UpdatedInstance", { instance: instInternal });
  }

  //  Handle Workspace Link
  //  TODO:  Do this in a Plugin and register to the onCreate / onUpdate hooks!  This should be the responsibility of the Workspace and UserPlugin Plugins!
  //  TODO:  Instead of attaching to the Workspace / Plugin, consider letting the user of this Component to pass a "Parent"??  Hmmm...



  //  TODO:  FOR NOW we are NOT really filtering by instance for Space... I think we SHOULD be doing so!!!  This means, we ONLY show instances in our current space, OR meeting the search criteria, like parent / children space???  Hmmm... Interesting.

  // if (workspace && !originalInstance) {
  //   attachInstanceToWorkspace(instInternal, workspace, token);
  // }

  // if (plugin && !originalInstance) {
  //   attachInstanceToPlugin(instInternal, plugin, token);
  // }
}

//  TODO-MAYBE:  Consider using one Component for Creation / Update... OR abstract some of the methods... They are very similar.
//  NOTE:  When we create an Instance we check the Addon.  When updating, the Addon connection stays.  Rules should still be run in Habor to validate.
//  TODO-CRITICAL:  We're still using the "serializeNoun" method to artificially show custom constructor / update methods on the Noun which don't really exist... I feel we should either make them exist OR use another mechanism to build the type tree.

//  Need to grab the field level overrides for this (Default) Instance Creator
//  IDEA:  Consider setting each field level override as a function of "props"?  This could INCLUDE the user's custom settings for this component?  FOR NOW, let's just let the user SELECT a field for a particular Noun / Instance / etc... in the future, generalize to a component's props?  MAYBE let the component creator worry about the registration points??  EITHER WAY, almost WHENEVER we see a field, the user shuld have SOME control over how it's to be rendered in THAT context!  Ah! This IS something I thought about a LONG time ago, DIFFERENT UIs for different things, like 5 stars vs. 10 stars vs. continuous, etc...  But... a Component can be reused in SEVERAL places... This is where we make a "Settings Scope", which determine WHICH settings object to use as a function of the Props???  I suggest that the Noun / Instance default override the field defaults for a particular component, BUT the Field settting specified on the compnent FOR this "Prop Scope" can ovveride that?
//         Default Override Order:  Default Field Renderer <- Prop Scope Settings (the settings of elements IN the Prop Scope, like Noun / Instance) <- Component Field Settings <- Component + Prop Scope Settings 
//         HOWEVER, a particular settings CAN be put elsewhere in the override stack?  Maybe we support an "important" indicator like CSS? 
//         MAYBE, we keep "Prop Scope" simple with a SINGLE Prop which is used to indicate the "Scope" name?  Like a Noun / Instance ID along with a qualifier like "Noun" or "Instance"?
//         MAYBE as part of this system, EACH component has a Developer specified value for its "Prop Scope"... or potentially multiple values.
//         Then, we have several buckets of "Prop Scope".  We have the default "Prop Scope", then a specific one under a copmonent.  This is VERY much like a CSS for Component Selection.  Ah!  We can create a RegistrationPoint for Prop Scopes! 

//  FOR NOW:  Let's just start with some BASIC field overrides.

//  NOTE:  EVERY PropScope should be something which can be identified with an ID... SO, this COULD be an Enum.

//  BRAINSTORM:
//    Ah!  Every component has a "Props" param.  We want to detemrine what to render based on a match with that Props object.  We can do this...  I suggest we keep it sipmle and do that AS IS for now.  We CAN look up prop types later?
//    Ah!  When we do an override we want to specify the PROPS that need to MATCH (possibly in addition to the ID of the component).
//    Ah.... BUT... this doesn't totally work, because we can have a whole network of these component trees.  Hmm...
//    Imagine we have a single Component with SEVERAL SubComponents.  Now... the Main component has settings (in the context of ITs parent, if it has one), and the sub-components have settings in the context of THEIR parent.  So... EACH component has SETTINGS.  These can be set... OR... as a "Meta-setting" we can choose HOW / IF / WHEN they can be set!  Ok... BUT FOR NOW, they can be set.  To do that, we need to define a "Context".  I suggest we do that with properties on the parent... This uniquely identifies a "Setting Context".  Ok... SO for us, we want to extend EVERY HaborComponent to support "Settings" we CAN consider doing this with a PLUGIN, which extends the original HaborComponent structure, OR just build it in!  Awesome.  So then... we can attach a setting object.
//    NOTE:  By default, ALL Habor Components support "Field Overrides" which can be EITHER named or Types!  This is AGAIN, like CSS.
//    IF the values specified here match the values of the component, then we have a match.  The attached setting applies to this component!
//    CONSIDER:  The MOST specific context has priority unless "IMPORTANT" is applied (like CSS).
//    NOTE:  EACH component has a "Settings" object that can be applied.  We can apply this setting to either JUST that component OR the entire sub-tree... it "Cascades" JUST like CSS!  So... We can apply a Field Override to a component, EVEN THOUGH there are seveeral subcomponents before we reach the fields, it will still apply to those fields.  So... when we "Edit" a field... I support we'll use the context of the parent?

// export interface ComponentSettingContext {
//   parent?: ComponentSettingContext;
//   props?: any;  //  A set of props used to determine whether the SettingContext matches an actual context.  In terms of Props, they are considered a match when every SettingContext prop matches the props in the current context.  Think of it like a mask.
//   // settingMatch?: any;  //  NOTE:  This is different from these "Settings"... These can really be considered "Overrides".
//   componentName?: string;
// }

//  NOTE:  When a Setting is applied to a parent Component which does NOT support that setting, it simply passes through like a Prop.  ALL the way down to the children.
//  NOTE:  We built this "Settings" system so settings can be specified like CSS selectors.  This means we can address a SPECIFIC component based on its position in the TREE AND its props / state / component settings.  THESE are a DIFFERNET "settings" system?  PERHAPS they can override ANY default value??  Interesting... MAYBE not state though... but we COULD potentially use state for specificity?  But... That's an unpublished implementation detail, so probably not...

// export interface ComponentSetting {
//   name: string;
//   value: any;
// }







//  TODO:  Instead of getting the settings for EACH Context, we can pass them down the tree hierarchically?  This way we ALREADY have our parent context settings, and we JUST need to find the settings that apply within OUR context.
//         FOR NOW, let's just do it explicitly like this.



//  TODO:  Looping through the ENTIRE "ComponentSettings" array is VERY inneficient!  We can insead build a hierarchal tree that MATCHES the tree of the components!  Then, it's always easy to look it up, just like a Trie?  Even so, we need SOME way to quickly determine which settings already apply.  Ah!  We do that by walking to the Compnent starting from the root and collecting settings.  We need SOME way to get the settings at the current Context.

//  IDEA:  Make it possible to AUTOMATIALLY build TS Interfaces from Habor Models and vice versa.
export enum IntervalScope {
  Throughout = "Throughout",
  Within = "Within"
}

//  TODO-IMPORTANT:  Make a "Deferred Type" which can be ANY SDT!  BUT, it's not fully defined until it can obtain all specified values.  These values come from other elements in the system itself.  We can use "this" to refer to other values specified in the current context?  MAYBE this is what we'll call a "Habor Expression".  We can choose ANYTHING from the state?
export interface Observation {
  tracker: string;
  value: string;
  timestamp: string;
}

export interface Tracker {
  name: string;
  description: string;
  model: string;
  startTracker: string;
}




export interface BlockBuilderOverrideState {
}

export class BlockBuilder extends React.Component<CreateInstanceFormProps> {

  constructor(props: CreateInstanceFormProps) {
    super(props);
    this.state = {};
  }

  public render() {

    //  Unpack
    const { frameworkContext: { token }, instance, noun } = this.props;
    return <Composer block={instance} />

  }
}








//   TODO-IMPORTANT:  Remove all this custom code that we made to get the Tracker system up and running!  In the future we should use the Habor UI tools to build Components??  AND / OR customize the default view... MABYE support default view editing in Habor UI?
export interface CreateInstanceFormProps {
  // createSchema: any;  //  TODO:  Don't pass this.
  // nounName: any;  //  TODO:  Don't pass this.
  noun: NounInternal;  //  TODO:  JUST pass this and get the pieces we need.
  instance?: InstanceInternal;

  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  systemEditMode?: boolean;  //  TODO-CRITICAL:  Temporarily indicates whether we're editing the system (or the space).  REMOVE THIS once the concepts are merged!  CURRENTLY, in the "Noun Editor" we ALWAYS associate with the SYSTEM!  HOWEVER, the "Space" SHOULD be a "System" TOO, so it should ALSO be able to have "Nouns", which is JUST a thing in the primtive "Class" System!???!!!!  BIG TODO!  This change is necessary for nested changes, where for example, we have several layers of systems OWNING other systems and we're choosing which to edit and stuff!? HM!  Perhaps there's only ONE we're editing at a time (maybe not in the future?  Though.. mirror editing seems like a corner case? ).
  modelSDK: ModelSDK;

  navigation: any;

  //  CONSIDER:  MAYBE we can use Context .. like React Context, not Hessia Contexgt FO rALL function shm!  THIS way EVERYTING runs in the tree ehaslfjsadlfj...  interesing.. much like JS scopes BUT it "reacts" to chagnsd. . 
  contextPlugin: ContextPlugin;
  spacePlugin: SpacePlugin;
  entitiesPlugin: ModelPlugin;
}

export interface CreateObservationOverrideState {
  trackers: InstanceInternal<Tracker>[];
  isLoading: boolean;
  selectedTrackerId?: string;
}

export class CreateObservationOverride extends React.Component<CreateInstanceFormProps, CreateObservationOverrideState> {

  constructor(props: CreateInstanceFormProps) {
    super(props);
    this.state = {
      trackers: [],
      isLoading: true
    };
  }

  public componentDidMount = async () => {

    //  Unpack
    const { frameworkContext: { token }, modelSDK } = this.props;

    //  Get Trackers
    //  NOTE:  This is normally done with a "Noun Field".
    const trackers = await modelSDK.searchInstances<Tracker>({ nounId: "tracker" });

    this.setState({ trackers, isLoading: false })
  }

  public render() {

    //  Unpack
    const { frameworkContext: { token } } = this.props;
    const { trackers, isLoading, selectedTrackerId } = this.state;

    //  Handle Loading
    if (isLoading) { return <Text>Loading</Text> }

    return (
      <View>
        <NounField onChange={(selectedTrackerId) => this.setState({ selectedTrackerId })} sdt={{ type: "noun", nounId: "tracker" }} token={token} />
      </View>
    );
  }


}

//  IDEA:  Maybe SOME addons are added as tabs, maybe some are buttons that bring up modals??  Maybe the Addon decides with a "registration" script!?  Then it's OPEN ended how it materializes.  However, it DOES reduce the set of remaining fields, right?
//  TODO:  Add this interface to the SDK?
export interface AddonHandler {
  id: string;
  name: string;
  description: string;
  //  TODO:  Instead of just linking an Addon to a className, consider using the "Context Mask" for mathing arbitrary fields.
  className: string;
  icon?: HaborIconSelection;
  color?: RGBAColor;
  component: HaborComponent;  //  The Addon component to be rendererd in a tab.
}

//  TODO-IMPORTANT:  This is just a placeholder!  EVENTUALLY, a Plugin should install this over the default... A Plugin (Entity Manager) should be the one to install the default.  CONSIDER adding this as the default from "Entity Manager", BUT, I'm thinking this might be installed from the "Addon Support" Plugin... Maybe?  But, this applies regardless of Addon?  Hmmm... MAYBE Addon Support adds a new Registration Point to override these?

//  TODO-IMPORTANT:  Merge with the Habor implemention and Move to the SDK!!!
export const getInheritedProperties = async (noun: Noun, sdk: ModelSDK, blacklist: string[] = []) => {

  //  Unpack
  const { inherits } = noun;

  //  Process Inheritance (update schema and properties)
  //  NOTE:  We currently copy the values from the parent to the child.
  //  TODO:  Copy the values ONLY in a caching layer.  The out-most layer should remain normalized!?
  let updatedProperties: { [name: string]: NounProperty } = {};
  if (inherits) {
    for (let i = 0; i < inherits.length; i++) {

      //  Get the Parent ID
      const parentId = inherits[i];
      if (!parentId) { continue; }

      //  Check the Blacklist
      if (blacklist.indexOf(parentId) != -1) { continue; }

      //  Get the Parent Noun
      const parent = await sdk.getModelByID(parentId);
      const parentProperties = await getInheritedProperties(parent, sdk, blacklist);

      //  Update the Noun Properties
      updatedProperties = { ...updatedProperties, ...parentProperties };
    }
  }

  //  Process Overrides
  //  TODO:  Validate type inheritance... will make more sense when we switch to the object model in "Next".
  updatedProperties = { ...updatedProperties, ...noun.properties };
  return updatedProperties;
};

export interface Taxonomy { [modelId: string]: { model: Noun, parents: Taxonomy } };

export const getTaxonomy = async (noun: Noun, sdk: ModelSDK) => {

  //  Unpack
  const { inherits } = noun;

  //  Process Inheritance (update schema and properties)
  //  NOTE:  We currently copy the values from the parent to the child.
  //  TODO:  Copy the values ONLY in a caching layer.  The out-most layer should remain normalized!?

  let taxonomy: Taxonomy = { [noun.id]: { model: noun, parents: {} } };

  if (inherits) {
    for (let i = 0; i < inherits.length; i++) {

      //  Get the Parent ID
      const parentId = inherits[i];
      if (!parentId) { continue; }

      //  Get the Parent Noun
      const parent = await sdk.getModelByID(parentId);
      const parentTaxonomy = await getTaxonomy(parent, sdk);

      //  Update Taxonomy
      taxonomy[noun.id].parents[parentId] = { model: parent, parents: parentTaxonomy };
    }
  }

  return taxonomy;
};

/**
 * Use Chain of Command to get the highest available responder in a taxonomy.
 */
export const getResponder = async (taxonomy: Taxonomy, types: string[], sdk: ModelSDK) => {

  //  Try Sub-Type Keys (Specialization)
  for (const type in taxonomy) {
    if (types.includes(type)) {
      return type;
    }
  };

  //  Try Parent Keys (Generalization)
  for (const type in taxonomy) {
    const parentTaxonomy = taxonomy[type].parents;
    const parentResponder = await getResponder(parentTaxonomy, types, sdk);
    if (parentResponder) { return parentResponder; }
  }

  return undefined;
};

export interface AddonHandlerWithSchema extends AddonHandler {
  schema: SDTObject;
  noun: NounInternal;
}

export interface CreateInstanceFormState {
  isLoading: boolean;
  addons: AddonHandlerWithSchema[];
  nounProperties: { [key: string]: NounProperty };
  schema?: SDTObject;
  selectedAddon?: string;
  addonValues: { [addonId: string]: any };
  otherValues: any;
}

//  TODO-IMPORTANT:  Instead of reaching into 'App' to get the Program, include this in a Plugin which will INJECT it!
export class CreateInstanceForm extends React.Component<CreateInstanceFormProps, CreateInstanceFormState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: CreateInstanceFormProps) {
    super(props);
    this.state = {
      isLoading: true,  //  TODO:  Need to centralize / standardize this loading logic!
      addons: [],
      nounProperties: {},
      schema: undefined,
      selectedAddon: undefined,
      addonValues: {},
      otherValues: undefined
    };
  }

  private handleHeaderPress = () => {
    this.createInstance();
  };

  public componentWillUnmount = () => {

    //  Unpack
    const { frameworkContext: { appEmitter } } = this.props;

    appEmitter.removeListener(AppEvent.HeaderButtonPressed, this.handleHeaderPress);
  }

  //  TODO-IMPORTANT:  SOME Addons MAY not even have the option to change, like "Identity".  It will (FOR NOW), ALWAYS be the logged in user.  MAYBE we should just show a badge or something?
  public componentDidMount = async () => {

    //  Unpack
    const { frameworkContext: { appEmitter }, noun, instance } = this.props;


    //  Listen to the HeaderButtonPressed Event
    //  TODO:  I don't really like the coupling here... the header MAY just be an optional component.  By doing this, we make it required!  I suggest we INSTEAD pass a particular event NAME to this component onto which we can bind to trigger the save action!!  Then, another component can pipe that into this component.
    //  FOR NOR:  Let's keep each page COUPLED with a Header!  This SHOULD probably change in the future, and this can be ONE particular Page template!
    appEmitter.addListener(AppEvent.HeaderButtonPressed, this.handleHeaderPress);

    //  Get the Property Set
    //  TODO:  Does having Class and Instance properties muddle this?  Actually... in this case, ALL we have are instance properties right?
    const nounProperties = await getInheritedProperties(noun, this.props.modelSDK);

    //  NOTE:  Instead of working with the "Instance Schema", we work with the property set!  This way, we can indicate WHICH classes are overridding which properties and what modifiers are applied, etc... FOR NOW, let's stick with the property set.

    //  Get Classes
    const parentClasses = await this.props.modelSDK.getAllParentClasses(noun);

    //  Get the Addons
    //  NOTE:  FOR NOW, each Addon has an assocaited Class on the instance.  In the future, we may want to apply them to Classes as well.
    const addons: AddonHandlerWithSchema[] = [];
    for (const parent of parentClasses) {
      const addon = this.props.entitiesPlugin.getHandlers().find((addon: any) => addon.className == parent.id);
      if (addon) {
        addons.push({ ...addon, schema: { type: "object" }, noun: parent });  //  TODO:  Remove the placeholder "schema"
      }
    }

    //  Get the Class List
    const managedClassList = addons.map(addon => addon.className);

    //  Process Each Addon
    //  NOTE:  This is not merged with the loop above, because we need to know which classes to remove.
    //  TODO:  Improve this in the future.  We may not want to tie classes directly to Addons, and we'll likely want to support overrides!
    const initialAddonValues: { [addonId: string]: any } = {};
    const managedProps: string[] = [];
    for (const addon of addons) {

      //  Create the Value Group
      initialAddonValues[addon.id] = {};

      //  Get Claimed Classes
      const blacklist = managedClassList.filter(classId => classId != addon.className);

      //  Addon Noun Properties
      //  TODO:  Instead of using a blacklist, consider returning auxiliary data with the returned properties, including an "ownership chain" identifying which class is responsible for a particular property?
      //  TODO-IMPORTANT:  Even when we use a blacklist, it does NOT disclude CLASS properties, which are CURRENTLY hard-coded on the class... when we switch to a more normalized model with caching this should improve.
      const addonProps = await getInheritedProperties(addon.noun, this.props.modelSDK, blacklist);

      //  Remove Properties
      Object.keys(addonProps).forEach(addonPropId => {
        delete nounProperties[addonPropId];
      });

      //  Get the Addon Schema
      const addonSchema = await getInstanceSchemaFromProperties(addonProps);

      //  Initialize the Addon Values
      if (instance) {
        for (const propName in addonSchema.properties) {
          initialAddonValues[addon.id][propName] = instance.payload[propName];
          managedProps.push(propName);
        }
      }

      //  Update Addon
      addon.schema = addonSchema;
    }

    //  Get Reamining Values
    const remainingValues: any = {};
    if (instance) {
      for (const propName in instance.payload) {
        if (managedProps.indexOf(propName) == -1) {
          remainingValues[propName] = instance.payload[propName];
        }
      }
    }

    //  Get the Instance Schema
    const schema = await getInstanceSchemaFromProperties(nounProperties);

    //  Update State
    this.setState({ addons, nounProperties, schema, isLoading: false, addonValues: initialAddonValues, otherValues: remainingValues });

    //  CONCERN:  How will we deal with inherited properties?  I think most Addons to which we add specialized UI should declare their properties as "Final"?
    //  FOR NOW:  Let's just not support overridden values!  We MAY want to solve this by NOT coupling Addons with Classes.  Instead, allow "Addons" to grab some properties based on particular conditions and group them?  ONE such condition in the set of classes that defined a property.
    /// IDEA:  MAYBE the "renderered" view of a Class should include the set of overriding classes for property?  MABYE it should just include the full tree?  Then, we can STILL leave that Addon responsible for rendering the property (because it defined it), BUT the value is potentially in a smaller sub-type!  OK, I like that strategy for now.

    /**
     * The end result should be a Map from ClassID -> Property Set.  Where the property set includes all propoerties owned by that Class.  This SHOULD include the type changes added by the sub-classes.  Then, we can render these in groups.
     */
    // const renderProperties = async (noun: Noun | NounInternal) => {

    //   //  NOTE:  FOR NOW, let's not support override types.  We'll just assume the types come from their respective classes.

    //   //  Unpack
    //   const { inherits } = noun;

    //   //  

    // };

  }

  //  IDEA:  Consider an "Advanced" section for complex Addon types?  Like Identity and Ownership?
  //  IDEA:  MAYBE have a VERTICAL icon panel!  That could look cool!  Or, a rotating wheel at the top, or pages with swipes, or pages with guided thing with a progress event-line at the top!  Lots of ways Addons can be rendered I suppose... MAYBE we can also let the user make their own?
  //  FOR NOW:  Let's keep it simple and just render some tab-controlled pages.

  //  THOUGHTS:  We could pass an object to each Addon to be manipulated, by adding new UIs, etc... OR, we can have each Addon register elements in common places?  Like "Badges", etc... But then, is that cleared for each new instance?
  //             I suggest that for now, we stick to the Tab system.  Once we're good there, we can move on to one of those strategies.  I lean toward the first, because this one allows the Addon free reign over the API.  The second means it can only add to pre-defined places?  Are these essentially one in the same?

  //  TODO:  HOW can we deal with pages as a top level thing!??  We want first-class support for nested pages!
  public navigateAddon = (addonId?: string) => {
    this.setState({ selectedAddon: addonId });
  }

  //  NOTE:  Each Addon is given a SINGLE Value (an object) for the associated class.
  public setAddonValue = (addon: AddonHandlerWithSchema, value: any) => {
    //  CONSIDER:  Consider validation here?  Or is this the responsibility of a downstream consumer or an upstream node?
    this.setState({ addonValues: { ...this.state.addonValues, [addon.id]: value } });
  }

  public setOtherValues = (values: any) => {
    this.setState({ otherValues: values });
  }

  public createInstance = async () => {

    //  Unpack
    const { frameworkContext: { changePage, workspace, plugin }, noun, instance: originalInstance, systemEditMode } = this.props;
    const { addonValues, otherValues } = this.state;

    //  Combine Addon Values
    let combinedAddonValues: any = {};
    for (const addonId in addonValues) {
      const addonValue = addonValues[addonId];
      combinedAddonValues = { ...combinedAddonValues, ...addonValue };
    }

    //  Build the Insstance
    const inst: Instance = { nounId: noun.id, payload: { ...otherValues, ...combinedAddonValues } };

    const selectedSpace = await this.props.spacePlugin.getSelectedSpace();
    await createOrUpdateInstance(this.props.modelSDK, inst, originalInstance, systemEditMode, selectedSpace, this.context.token);

    //  TODO:  Navigate back instead of to the dashboard (which may not even be installed).

    //  TOOD:  IMPLEMENT THIS!
    // this.props.navigation.goBack
    // changePage({ pageName: "Dashboard", props: {} });  //  TODO:  When in a Plugin context, App.tsx will override this for Plugins.
    //  TODO:  DECOUPLE THIS!
    this.props.navigation.goBack();
    // alert("Instance Updated")
  }

  public render() {

    //  Unpack
    const { token } = this.context;
    const { frameworkContext: { user }, frameworkContext, noun, componentContext } = this.props;
    const { isLoading, nounProperties, schema, addons, selectedAddon } = this.state;

    //  Handle Loading
    if (isLoading) { return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator /></View> }

    //  Guard Undefined
    if (!schema) {
      throw new Error("Cannot render the editor with an undefined instance schema.");
    }

    //  CONCERN:  What if an overriding class takes control over an Addon?  I SUPPOSE we can resolve it by having Addons depend upon one another?  FOR NOW, let's just not worry about this!

    const addon = addons.find(addon => addon.id == selectedAddon);

    //  TODO-IMPORTANT:  The "Addon" concept is NO LONGER specific to the Class system!  I BELIEVE this is more like INHERITANCE and editors for EACH parent class???


    //  IDEA:  MAYBE an Addon takes control of a particular Noun.  It will block all its parents.  For example, "Class" will be hidden?
    //  For each noun, note that SOME super-classes are associated with Addons, while others are NOT.  For this reason, we walk the list of classes, starting at the sub-class.  Then, we group properties that DON'T have Addons.

    //  CONNECTION:  The way I'm splitting these Addons up reminds me a LOT of Redux reducers.

    console.log(`Rendering Instance Creator`);
    console.log(`Other Values: ${JSON.stringify(this.state.otherValues)}`);

    return (
      <View>
        <BubbleMenu>
          {
            [
              <BubbleMenuItem icon={{ type: "entypo", name: "home" }} onPress={() => this.navigateAddon()} />,
              ...addons.map(addon => <BubbleMenuItem icon={addon.icon ? addon.icon : { type: "material", name: "plus" }} onPress={() => this.navigateAddon(addon.id)} />)
            ]
          }
        </BubbleMenu>
        {
          selectedAddon == undefined ?
            //  TODO: The "DerivedField" expects a source INSTANCE, however DavelForms should be GENERAL enough not to be coupled with that concept.. this is why we may want a more generic registation ssytem so we can dynamicaly enabe fields and stuff based on the context??
            //  TODO:  This concept of "OtherValue" should rreally be an injected thing!!  It's just OTHER associations and shit/ Hmm  MAYBE this file is for GROUPING the various systems and THEN the Class sstem is just one! hM!  BUT only in SOME cases is the set of "other value" mapped to an instqance.. I SUPPOSE in this cae it WOULD because this is the "instance creator"!
            //  CONSIDER:  Re-enable auto-submit.  Disabled due to bug in object / keyword prop auto-nulling.
            <DavelForm value={this.state.otherValues} autoSubmit={true} schema={schema} metadata={{ token, user, frameworkContext, componentContext, sourceNoun: noun, sourcePayload: this.state.otherValues }} onSubmit={this.setOtherValues} onSubmitFail={() => alert("Submit Failed")} onSubmitSuccess={() => null} onCancel={() => alert("Submit Canceled")} /> :
            addon ? <HaborComponentViewer componentProps={{ addon, setValue: (value: any) => this.setAddonValue(addon, value), value: this.state.addonValues[addon.id] }} componentContext={componentContext} frameworkContext={frameworkContext} component={addon.component} handleClose={() => alert("close")} /> : null  //  TODO:  Remove "handleClose" from "HaborComponentViewer".
        }
        {/* TODO:  SOMEHOW use the top header buttons, MAYBE using a generic "Save Provider" system? */}
        <GalleryButton style={{ marginVertical: 7 }} title="Submit Instance" onPress={this.createInstance} />
      </View>
    );
  }
}

const createInstanceOverrides: { [nounId: string]: React.ComponentClass<CreateInstanceFormProps, any> } = {
  "observation": CreateObservationOverride,
  // "serialized-userblock": BlockBuilder
};

export enum AddonAttachmentType {
  Setting = 'setting',
  Value = 'value'
}

//  TODO:  Move the PrimitiveRegister stuff to a REGISTER Plugin!  It shoudln't be coupled with the "Entity Manager" code.
//  NOTE:  This is a BASIC, PRIMITIVE UI Register, meaning we can inject COMPONENTS at this location.  This is NOT the same as Application Level interpreters and things like the Hessia Register, which are injected UI components with names and stuff where we choose how to registerr these.. hmm.
export interface PrimitiveRegisterRendererProps {
  registerName: string;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  componentProps: any;

  componentPlugin: ComponentPlugin;
}
interface PrimitiveRegisterRendererState {
  register?: ComponentRegister;
}
class PrimitiveRegisterRendererBase extends React.Component<PrimitiveRegisterRendererProps, PrimitiveRegisterRendererState> {

  constructor(props: PrimitiveRegisterRendererProps) {
    super(props);
    this.state = {}
  }

  public componentDidMount = () => {

    //  Unpack
    const { registerName } = this.props;

    //  Get the PrimitiveRegister
    const register = this.props.componentPlugin.getRegister(registerName);

    //  Set the State
    this.setState({ register });
  }
  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext, componentProps } = this.props;
    const { register } = this.state;

    //  Guard Undefined
    if (!register) { return <Text>Loadng PrimitiveRegister</Text> }

    //  TODO:  STANDARDIZE the data in each register element for this view!?  Hmm... Then, we can ADAPT the view as needed?? This way, each registered component isn't COUPLED with a view?  OR, perhaps require an Object schema for EACH view that we'd like to register for.  PERHAPS with a DEFAULT one so we can rudimentarily register for ALL views!??  I THINK I like that idea!!!  That will be like the least common denominator!?

    return (
      <>
        {
          [
            ...register.haborComponents.map((component: HaborComponent) => <HaborComponentViewer componentProps={{ ...componentProps, style: { display: 'flex', flexDirection: 'column' } }} frameworkContext={frameworkContext} componentContext={componentContext} component={component} handleClose={() => null} />),
            ...register.primitiveComponents.map((Component) => <Component userProps={{ ...componentProps }} frameworkProps={{
              //  TODO:  REFACTOR a lot of this!!
              settings: {},
              config: {} as any,
              componentId: "PrimitiveComponent",
              context: frameworkContext,
              children: [],
              componentContext: componentContext
            }} />)
          ]
        }
      </>
    )
  }
}
export const PrimitiveRegisterRenderer = withComponent(PrimitiveRegisterRendererBase);

interface InstanceCreatorProps {
  // TODO:  Figure out what the heck this is for... I built it, but I forgot.
  targetObjId?: ObjectID;
  nounId: NounId;
  instanceId?: InstanceID;
  onSuccess?: (inst: InstanceInternal<any>) => void;
  systemEditMode?: boolean;
  skipOverrides?: boolean; //  TODO:  Temporary boolean to determnne whether or not to SKIP the override process.. BUT, this componet should NOT be responsible for this!  It should be an ACTION based thing, or PERHAPS a VIEW system which CHOOSES a view.. hmm.. several ways we can handle that.. like responder / action based system, OR an explicit view registry??  Hmmm!??
  modelSDK: ModelSDK;
  navigation: any;

  componentPlugin: ComponentPlugin;
  contextPlugin: ContextPlugin;
  spacePlugin: SpacePlugin;
  entitiesPlugin: ModelPlugin;
}

interface InstanceCreatorState {
  spinner: boolean;
  status: string;
  createInstanceForm: any;
  noun?: NounInternal;
  instance?: InstanceInternal;
}


//  REFERENCE:  https://stackoverflow.com/questions/65481226/react-native-alert-alert-only-works-on-ios-and-android-not-web
import { Platform } from 'react-native'

const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'))

  if (result) {
    const confirmOption = options.find(({ style }) => style !== 'cancel')
    confirmOption && confirmOption.onPress()
  } else {
    const cancelOption = options.find(({ style }) => style === 'cancel')
    cancelOption && cancelOption.onPress()
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert



export class InstanceCreatorBase extends React.Component<InstanceCreatorProps, InstanceCreatorState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      spinner: false,
      status: 'Initial',
      createInstanceForm: <Text>Loading Instance Form...</Text>,
    };
  }

  public async componentDidMount() {

    //  Unpacks
    const { frameworkContext, componentContext } = this.context;
    const { nounId, instanceId, targetObjId, systemEditMode } = this.props
    const { token, user } = frameworkContext;

    //  Get the Noun
    const noun = await this.props.modelSDK.getModelByID(nounId.nounId);

    //  Get the Instance
    const instance = instanceId ? await this.props.modelSDK.retrieveInstance(instanceId) : undefined;

    //  Serialize the Noun
    //  TODO:  FOR NOW, I'm going to remove the METHOD fuctionality ? Hmm... 
    // const serNoun = await haborSDK.retrieveSerializedNoun(noun.id, token, targetObjId);
    //  TODO:  To simplify the code, don't get a schema from the create function, just use the template.  We can work on first-class support for overrides in the future.
    //  TODO:  Really, what we're doing here is grabbing the signature of the constructor.  IF there's none defined, then the "default constructor" is essentially going to require the raw fields!
    //  THOUGHT:  WHY build a "method" right ON the object... that's a thing we CAN do.. but WHY?  Instead, we can build something to manipulate it ? Hmm.. I don't think it needs to be stored together, and even if it is, I think that's something we can do later.. hmm... 
    // if (serNoun.properties && serNoun.properties.create && serNoun.properties.create.value.params) {
    //   const createSchema = serNoun.properties.create.value.params;
    // const instanceProperties = await getInstanceSchemaFromProperties(createSchema.properties);
    // const updatedCreateSchema = { ...createSchema, properties: instanceProperties };

    //  Attempt to get the Custom Instance Creator
    //  TODO:  Eventually query a service to obtain the HaborComponent.  FOR NOW, we just have a static list of some overrides.
    //  CONSIDER:  Consider allowing the custom override to take the ENTIRE page, not just the form element.
    //  FOR NOW:  Let's add the Addon thing to JUST "Task".  Then, we can apply the settings context thing and apply it to ALL / SEVERAL Nouns.

    //  Check Entity Overrides
    //  TOOD:  Shouldn't get like this!
    // const entitiesPlugin  = Program.haliaStack?.getPlugin("entities").plugin as ModelPlugin;
    //  NOTE:  SHOULD generalize this type of thing!  SO instead of expliciltly doing it in the prmitive, procedural code, we have RULES and stuff for this shit hm!
    // let CreateInstanceOverrideComponent = entitiesPlugin.getCreatePage(nounId.nounId);

    //  Check Hard-Coded Overrides
    // CreateInstanceOverrideComponent = createInstanceOverrides[serNoun.id] || CreateInstanceOverrideComponent;  //  What if we want to override for ALL nouns or a subset??  Or a particular context???  I think we want to use a "Context Mask" to determine which UI to use! 

    //  TODO:  Use a "Context Mask" / "Props Mask" to determine which UI to use here.  When MULTIPLE UIs match, use the MORE SPECIFIC one.  Ideally we should NEVER allow a specificity tie... That should be an exception?
    //  IDEA:  MAYBE when there's a more specific override, we can MERGE them if possible?  Hmmm... FOR NOW, let's skip this.

    let createInstanceReactElem: React.ReactElement<any>;
    // if (CreateInstanceOverrideComponent) {
    //   createInstanceReactElem = <CreateInstanceOverrideComponent systemEditMode={ systemEditMode } noun={ noun } instance={ instance } frameworkContext={ frameworkContext } componentContext={ componentContext } />
    // } else {

    //  Create the Default Create View (with separate tabs for each Class)
    //  CONCERN:  Maybe an Addon can be implemented without a specific class... maybe it involves multiple classes.  MAYBE that means Addon is just a general grouping of fields?  Either way, FOR NOW, let's do it per-class.
    //  Very generaically, an Addon could take some of the fields and provide values for them.  FOR NOW, we'll build a Registration Point to register Addons for a particular Class?  OR, should we just group by class by default?  But, what if we want a custom UI?  Ok, then we'd want an Addon override?  What if we want some fields to be shown together?  Hmmm... Let's start with this for now.

    // createInstanceReactElem = <DavelForm value={ initial } schema={ createSchema } metadata={ { token, user, frameworkContext, componentContext } } onSubmit={this.createInstance} onSubmitFail={this.onFail} onSubmitSuccess={this.onSuccess} onCancel={ this.onCancel } />;
    // TODO:  Decouple navigation
    // }

    this.setState({ noun, instance });
    // } else {
    //   alert('No Creation Params Specified!');
    // }
  }

  public onSuccess = async () => {
    this.onComplete();
    this.setState({ status: 'SUCCESS' });
    // this.props.history.push('/dashboard');
  }

  public onCancel = async () => {
    this.onComplete();
    this.setState({ status: 'CANCELED' });
    // this.props.history.push('/dashboard');
  }

  public onFail = async (err: any) => {
    this.onComplete();
    // this.setState({ status: JSON.stringify(err) });
  }

  public onComplete = async () => {
    this.setState({ spinner: false });
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext } = this.context;  //  TODO:  We lose Habor Component context when we take "componentContext" from a centralized source instead of props (which has the "call-stack"!)
    const { noun, instance } = this.state;
    if (noun == undefined) { return <Text>Loading...</Text> }
    const { skipOverrides, systemEditMode } = this.props;

    //  TODO-CRITICAL:  Do NOT hard-code this choice!  Register the UI with the CSS-like system or SOME sort of register!?
    //  TODO-CRITICAL:  Make SOME way to show the REGULAR "Instance Viewer".. MAYBE we can separate these by showing that there's an "Instance" list in the CLASS system, and PERHAPS the system CHOOSES to MAP these to its OWN Instnace concept!?  And... THOSE are the ones that map to the CUSTOM experience!?  AGAIN, this is the idea of a thing having SEVERAL DIMENSION!?? HMM!??  So... we want to be able to see the Class Instnce view, OR the Block Instance view, or PERHAPS OTHER ones as well!?  MAYBE we can add these to the "Context Menu"!?  COOL!!  MAYBE they SHOULD be considered DIFFERENT views, AND the default view is set by the highest order "responder"!?  HMM??  The order is set by... the user?  BUT perhaps with system priority first?? HM???  Like the closer the system the better?
    //  NOTE:  CURRENTLY "skipOverrides" ONLY skips THIS one override... for the Composer view for block nouns!  INSTEAD, I want to get RID of that boolean and abstract the CHOICE of view based on an action responder, or ... something...
    if ((noun.id === serializedUserBlockNoun.id) && !skipOverrides) {
      return (
        <Composer block={instance} />
      );
    }

    const confirmDelete = (instance) => {
      alert('Confirm Delete', 'Are you sure you want to delete this instance?', [
        {
          text: 'Delete',
          onPress: async () => {
            await this.props.modelSDK.deleteInstance({ nounId: noun.id, instanceId: instance.id, type: EntityType.Instance });
            this.props.navigation.goBack();
          }
        },
        {
          text: 'Cancel',
          onPress: () => null,
          style: 'cancel',
        },
        { text: 'OK', onPress: () => console.log('OK Pressed') },
      ]);
    };

    return (

      <View style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#fafafa' }}>
        <AppHeader title={instance ? `Edit ${noun.name}` : `Create ${capitalizeAllWords(noun.name)}`} />
        <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'white' }} contentContainerStyle={{ padding: medSpacer, alignItems: 'center' }}>
          <View style={{ flex: 1, padding: medSpacer, display: 'flex', flexDirection: 'column', maxWidth: 600, backgroundColor: 'white', borderRadius: 10 }}>
            {this.state.spinner ? <ActivityIndicator /> : undefined}
            <View>

              <CreateInstanceForm entitiesPlugin={this.props.entitiesPlugin} spacePlugin={this.props.spacePlugin} contextPlugin={this.props.contextPlugin} navigation={this.props.navigation} modelSDK={this.props.modelSDK} systemEditMode={systemEditMode} noun={noun} instance={instance} frameworkContext={frameworkContext} componentContext={componentContext} />


              {
                instance ?
                  // TODO:  Deal with peripheral objects and stuff on deletion... though, just deleting should be a good start?  I THINK we'll want each system to link into the action in the backend to handle this??  Hmm... IF we just do things locally, perhaps there IS no need for a backend?  Interesting... BUT, perhaps we'd still have one to sync the system file???  Hmmm...
                  <GalleryButton style={{ marginVertical: 7 }} title="Delete" onPress={() => confirmDelete(instance)} /> :
                  null
              }
            </View>

            <View style={{ height: 5, backgroundColor: '#eeeeee', marginVertical: 10 }} />

            {/* // TODO:  This should be based on the Entity?  Well... both should cross-integrate.
            //  TODO:  This is named "instance-creator-bottom" BUT, we should make an EDITOR version and a CREATOR version??  OR, have some conditional within the things we add??  Hmm...  The main question is... who is responsible?
            //  TODO:  BIG CONCERN:  I now have TWO different implementations of component registers... Hmmm... one has a specific global name?  This does kind of work... but is it really what we want?  With the other model, we can pass our own props and merge with runtime props?  Maybe??  With the other, the Plugin will register, but how does it target a specific component?  Ah... right a global store for that component I think.  SO, it's a LITTLE different than this, which is a global not scoped to a component.  We MAY also want to make CODE REGISTERS, so instead of a Component with particular props, we have a CODE REGISTER which allows us to inject native code (or Blocks in Hessia).  This is VERY similar to Wordpress I suppose?  I THINK that's OK.  I don't believe they can copyright this generic pattern!
            //  TODO:  I don't like that this is GLOBAL!  Instead, I'd like to scope to the Feature API / Plugin.
            //  NOTE:  We use this as a PRIMITIVE register for primitive systems.  We shall see.
            //  CONSIDER:  Do we REALLY need to register this EXPLICLTY in the primitive code?  PERHAPS we can access the element by ID in the DOM tree or something to INJECT this?? HOWEVER, that MAY be dangerous, because we MAY want to show instances with SEVERAL renderers... so... if we hard-code with the DOM tree, it becomes coupled.. consider how to do this in a DE-COPULED way.. MAYBE each renderer / view CONFORMS such that it accepts and displays from certain pre-defined registers??? Hmmm...  BUT, maybe we ALSO want to be able to make CUSTOM ones, in WHICH case, we need a COUPLING for EACH component / view that we want to support that register!!! Makes sense!!!  AND we should be able to SEE which ones have been registered with it in a NICE UI with color indicators or something to make this CLEAR and PLEASANT and SIMPLE... No digging through CODE and shit!  MANY of these (if not all) CODE concerns become USER concerns and are displayed as such!?!!!
            //  TODO:  Support injection here again!  LOTS of things were being added here hm! */}

            <ExtensionsWidget instance={instance} />

          </View>
        </ScrollView>
      </View>
    );
  }
}

export const ExtensionsWidget = ({ instance }: { instance?: InstanceInternal<any> }) => {

  if (!instance) { return null; }

  const componentPlugin = React.useContext(ComponentPluginContext);
  const appContext = React.useContext(AppContext);
  const { frameworkContext, componentContext } = appContext;

  return (
    <>
      <TextSubTitle>Extensions</TextSubTitle>
      <PrimitiveRegisterRenderer componentPlugin={componentPlugin} registerName="instance-creator-bottom" frameworkContext={frameworkContext} componentContext={componentContext} componentProps={{ instance }} wrapper={({ children }) => <View style={{ padding: 20 }}>{children}</View>} /> :
    </>
  );
}

export const InstanceCreator = function (props: Omit<InstanceCreatorProps, "navigation" | "contextPlugin" | "componentPlugin" | "spacePlugin" | "entitiesPlugin">) {
  const navigation = useNavigation();
  const contextPlugin = React.useContext(ContextPluginContext);
  const componentPlugin = React.useContext(ComponentPluginContext);
  const spacePlugin = React.useContext(SpaceReactContext);
  const entitiesPlugin = React.useContext(ModelPluginContext);
  return <InstanceCreatorBase {...props} entitiesPlugin={entitiesPlugin} contextPlugin={contextPlugin} componentPlugin={componentPlugin} spacePlugin={spacePlugin} navigation={navigation} />;
}


//  TODO:  Expliclty register components for "Addons", INSTEAD of using a 1-1 mapping to Class Elements!?  PERHAPS we can keep that model, BUT I'd like to add one for "Addons"!?  Hmm!!  MAYBE we centralize the class stuff, AND then each one of these will be an Addon?  Hmm... interesting.. BUT Addons MAY not be here.. they can register like.. anywhere right!?  Hmm!!  I wonder how similar this is getting to Trello???
