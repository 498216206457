import { SDTObject } from 'habor-sdk';
import * as React from 'react';
import { Button, Modal, ScrollView, Text, View } from 'react-native';
import { DavelForm } from '../../davel-ui';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';


const AnyFieldStyle = {
  borderRadius: 20,
  paddingLeft: 15,
  paddingRight: 15,
  fontFamily: 'Poppins-Medium',
  borderColor: 'black',
  borderWidth: 1,
  height: 40
};

//  TODO:  Allow the user to BUILD a type?? Hmm!?  
//  NOTE:  When we ask the user to enter into an "Any" field, it's up to them to supply ANYTHING they desire!?  SO... we SHOULD give them the power to build a type? Hmm... We BASICALLY already do this with the SDT thing!?  PERHAPS we let them use that to choose a type, and THEN choose a value based on that!?  YES!  SO, SDT is JUST making the type, where THIS is maknig a type and THEN supplying a value?  Hmm... Though perhaps they should be able to do it iterativel?  like... create an object, and then select pieces of it... like hmm..

export interface AnyFieldProps {
  update: (value: any) => void;
  value: any;
  name: string;
  style: any;
}

interface AnyFieldState {
  typeValue: any;
  editType: boolean;
}

class AnyField extends React.Component<AnyFieldProps, AnyFieldState> {

  constructor(props: AnyFieldProps) {
    super(props);
    this.state = {
      editType: false,
      typeValue: {
        type: { type: "keyword" }
      }
    }
  }
  public render = () => {

    const { value, update } = this.props;

    //  TODO:  How do we hndle the case where we alredy HAVE an "Any" value, BUT we donn't know what TYPE it is???  PERHAPS that's the case for NOT using Any and CHOOSING a type!?
    //  CONSIDER:  We COULD perhaps make a NEW "Any" type called "TrackedAny" or "ManagedAny" or something which lets us choose a type which then gets stored along with the value!! THEN we know how to display, EVEN if we let the user choose the value at runtime!? HM!  I LIKE it!  With "Derived" (should be Deferred I think) it's not a problem, because it resolves the type!
    const { typeValue } = this.state;

    const typeSchema: SDTObject = {
      type: "object",
      properties: {
        type: {
          type: "sdt"
        }
      }
    };

    const valueSchema: SDTObject = {
      type: "object",
      properties: {
        value: typeValue.type
      }
    }

    return (
      <View>

        {/* Select a Type */}
        <Button title="Select Type" onPress={() => this.setState({ editType: true })} />
        <Modal visible={this.state.editType}>
          <ScrollView>
            <DavelForm value={typeValue} onCancel={() => this.setState({ editType: false })} schema={typeSchema} onSubmit={(newSchema, oldSchema) => this.setState({ typeValue: newSchema, editType: false })} />
          </ScrollView>
        </Modal>

        {/* Create a Value for the Type */}
        <DavelForm autoSubmit={ true } value={{ value }} schema={valueSchema} onCancel={() => alert("Cancelled")} onSubmit={
          (value) => {
            update(value.value);
          }} />
      </View>
    );
  }
}

export const sdtAnyRenderer = ({ sdt, name, update, value }: SDTRendererParams) => (
  <DavelField required={sdt.required} key={name} name={name}>
    <Text>"Any" Field</Text>
    <AnyField style={AnyFieldStyle} name={name} key={name} update={update} value={value} />
  </DavelField>
);
