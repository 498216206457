import { Color } from 'habor-sdk';
import * as React from 'react';
import { StyleSheet, View, ViewProps } from "react-native";
import { getRaisedStyle, RaisedProps } from "./styles";

//
//  Circle
//

export interface CircleProps extends RaisedProps, ViewProps {
  size?: number;
  color?: Color | string;
  [key: string]: any;
}

const CircleStylesheet = StyleSheet.create({
  circle: {
    borderWidth: 3,
    borderColor: Color.primary,
    margin: 0,
    padding: 0,
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const getCircleSizeStyle = (props: CircleProps) => {
  //  TODO:  Move default size to a constant.
  const { size = 80 } = props;
  return {
    width: size,
    height: size,
    borderRadius: size / 2
  };
};

export const getOpenCircleColorStyle = (props: CircleProps) => {
  const { color = Color.primary } = props;
  return { borderColor: color };
};

export const getFilledCircleColorStyle = (props: CircleProps) => {
  const { color = Color.primary } = props;
  return { backgroundColor: color, borderColor: color };
};

export const CircleOpen = (props: CircleProps) => (
  <View { ...props } style={[ CircleStylesheet.circle, getOpenCircleColorStyle(props), getRaisedStyle(props), getCircleSizeStyle(props), props.style ]}>
    { props.children }
  </View>
);

export const CircleFilled = (props: CircleProps) => (
  <View { ...props } style={[ CircleStylesheet.circle, getFilledCircleColorStyle(props), getRaisedStyle(props), getCircleSizeStyle(props), props.style ]}>
    { props.children }
  </View>
);
