import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Pressable, ScrollView, Text, View, Switch, TextInput } from 'react-native';
import { Icon } from 'react-native-elements';
import { ToggleButton } from 'react-native-paper';
import { GalleryButton } from '../../../components/common';
import { ContentCard } from '../../../components/content-card';
import { FieldContainer, ImageField, TextField } from '../../../components/fields';
import { GroupCard } from '../../../components/group-card';
import { HeroCard } from '../../../components/hero-card';
import { defaultBorderRadius, Paragraph } from '../../../constants';
import { GalleryAppContext } from '../../../gallery-app';
import { useSizes } from '../../../sizes-helper';
import { Breadcrumbs, fetchImageFromUri, getGalleryApi, useCognitoToken } from '../../../utilities';


export const SelectWork = () => {
  return (
    <View>
      <Text>SelectWork</Text>
    </View>
  );
}

export const DescribeWork = () => {
  return (
    <View>
      <Text>DescribeWork</Text>
    </View>
  );
}

export const TagWork = () => {
  return (
    <View>
      <Text>TagWork</Text>
    </View>
  );
}


export const CreationEditor = ({ creationId }: { creationId?: string }) => {

  const navigation = useNavigation();
  const appContext = React.useContext(GalleryAppContext);

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [listed, setListed] = React.useState<boolean>(false);
  const [imageUrl, setPhoto] = React.useState<string | undefined>(undefined);
  const [s3Photo, setS3Photo] = React.useState<string | undefined>(undefined);

  const token = useCognitoToken();

  // const pickImage = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     //  CONSIDER:  Allow videos too? Hmm... set of products with videos? Hmm... movable frames? Hmm...
  //     mediaTypes: ImagePicker.MediaTypeOptions.Images,
  //     // base64: false,
  //     // allowsEditing: true,
  //     // aspect: [4, 3],
  //     quality: 1,
  //   });

  //   if (!result.cancelled) {
  //     setPhoto(result);
  //   } else {
  //     alert("Cancelled")
  //   }
  // };


  //  FOR NOW, we're going to do it all as one!  Unlike PROFILE and ACCOUNT, we're going to make each work separately to start hm....


  const [loading, setLoading] = React.useState(false);


  const submitWork = async () => {

    setLoading(true);

    const api = getGalleryApi(token?.getJwtToken());

    //  TODO:  We'll need to make sure to pass the TOKEN to the server

    if (!imageUrl) { return; }

    const blob = await fetchImageFromUri(imageUrl);

    try {
      const res = await api.worksControllerCreate({
        file: blob,
        work: JSON.stringify({
          title,
          description,
          listed
        })
      });

      // const uploadedPhotoBlob = await api.worksControllerFindOne({
      //   id: res.id
      // });

      // const imageObjectURL = URL.createObjectURL(uploadedPhotoBlob);

      // setS3Photo(imageObjectURL);

      // await fetch({ })
      // const res2 = await api.profilesControllerFindOne({ id: '915' }) as any
      // const value = await res2.raw();

      // const blob = await res2.blob();
      // const imageObjectURL = URL.createObjectURL(res2);

      // fetch()
      setLoading(false);
      navigation.goBack();

      // setProfile(imageObjectURL);

    } catch (err) {
      // alert("PROFILE ERROR" + err.message)

    }

  }




  // const content = [
  //   <SelectWork title="Test" />,
  //   <DescribeWork />,
  //   <TagWork />,
  // ];

  const [active, setActive] = React.useState(0);

  //  TODO:  Consider COLLABORATORS... ah!!  One thing is ... as we ADD these things, it gets more complicated hmm..

  const { isDesktop } = useSizes();


  return (
    <ScrollView contentContainerStyle={{ alignItems: 'center' }} style={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 30 }}>

      <HeroCard title="New Creation" subTitle="Upload a new creation.">

        {/* <Layer>
          <Image resizeMode='cover' style={{ width: '100%', height: '100%', tintColor: '#ff4083', opacity: 0.05 }} source={require("../../../assets/patterns/Whangaehu.png")} />
        </Layer> */}

      </HeroCard>



      <ContentCard>

        {/* TODO:  Replace Breadcrumbs */}
        <View style={[{ flexDirection: 'row', alignItems: 'center', borderRadius: defaultBorderRadius }]}>
          <Pressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => navigation.navigate("Creations" as never)}>
            <Icon type="material" name="chevron-left" size={20} color="#777777" />
            <View style={{ width: 2 }} />
            <Paragraph style={{ color: '#777777' }}>Back</Paragraph>
          </Pressable>
        </View>

        <View style={{ height: 20 }} />

        <GroupCard>

          <ImageField imageUrl={imageUrl} onSave={(imageUrl) => setPhoto(imageUrl)} title="Creation" />

          <View style={{ height: 20 }} />

          {/* <TextInput value={title} onChangeText={_title => setTitle(_title)} /> */}
          <TextField value={ title } title='Title' onSave={text => setTitle(text)} mode="automatic" />

          <View style={{ height: 20 }} />

          <TextField value={ description } multiline={true} title='Description' onSave={text => setDescription(text)} mode="automatic" />

          <View style={{ height: 20 }} />

          <FieldContainer title="Listing" description="List Your Item for Sale">

            <View style={{ height: 20 }} />

            <View style={{ backgroundColor: '#dddddd', borderRadius: 10, flexDirection: 'column' }}>
              <View style={{ backgroundColor: '#fafafa', borderWidth: 2, borderColor: '#eeeeee', borderRadius: 10, alignItems: 'center', justifyContent: 'center', padding: 20, flexDirection: 'row' }}>
                <Paragraph style={{ flex: 1 }}>Enable Sales</Paragraph>
                <Switch onValueChange={() => setListed(!listed)} value={listed} />
              </View>

            </View>

            <View style={{ height: 10 }} />

          </FieldContainer>


          {/* <FieldContainer title="Notice"> */}

          <View style={{ padding: 20 }}>


            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 40 }}>
                <Paragraph>📜</Paragraph>
              </View>
              <Paragraph style={{ fontFamily: 'Poppins-SemiBold' }}>
                By uploading, you agree to our <Pressable onPress={() => window.open("https://www.badart.studio/terms")}><Paragraph style={{ fontFamily: 'Poppins-SemiBold', color: '#555555' }}>terms</Paragraph></Pressable>.
              </Paragraph>
            </View>



            {
              listed && (
                <>
                  <View style={{ height: 20 }} />

                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: 40 }}>
                      <Paragraph>🤝</Paragraph>
                    </View>
                    <Paragraph style={{ fontFamily: 'Poppins-SemiBold' }}>You agree to let us create, list, and sell products based on your art.</Paragraph>
                  </View>

                  <View style={{ height: 20 }} />

                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: 40 }}>
                      <Paragraph>💸</Paragraph>
                    </View>
                    <Paragraph style={{ fontFamily: 'Poppins-SemiBold' }}>Currently, proceeds are split 50% you (Artist), 45% us (Studio), and 5% Charity.</Paragraph>
                  </View>

                  <View style={{ height: 20 }} />

                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: 40 }}>
                      <Paragraph>🦄</Paragraph>
                    </View>
                    <Paragraph style={{ fontFamily: 'Poppins-SemiBold' }}>You can remove products from your account or contact us for modification.</Paragraph>
                  </View>
                </>
              )
            }



          </View>
          {/* </FieldContainer> */}

          <View style={{ height: 30 }} />

          <GalleryButton loading={loading} style={{ width: '100%' }} title='Upload' onPress={submitWork} />
        </GroupCard>

      </ContentCard>

      <View style={{ height: 100 }} />

      {/* TODO:  Indicate that this info will be used for hmmm... RedBubble doesn't give out 1099.  The idea is... I can let them license to me, then it's MY shop, and then I don't have to send 1099s NOR do I have to collect tax for them.  We are just providing a service with a base-rate hm! */}

    </ScrollView>
  )
}
