
import * as React from 'react';
import { Text } from 'react-native-paper';
import { System } from '../../hessia2/hessia2-plugin';
import { TagSetPlugin } from './tag.template';

export class ProjectPlugin extends TagSetPlugin {

  public PluginContext = React.createContext<ProjectPlugin | undefined>(undefined);

  public static details = {
    ...TagSetPlugin.details,
    name: 'Project',
    description: 'Introduces Projects',
    id: "project3"
  }

  public tagSetId = "project3-type";

  public system: System = {
    name: "Project",
    description: "Adds Projects",
    id: "project3",
    icon: { name: "folder-open-outline", type: "ionicon" },
    emoji: "",
    color: "#eeeeee",
    primaryColor: "#aaaaaa",
    component: () => <Text>Project System</Text>,
    backgroundColor: "#555555"
  }
}