
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { ActivityIndicator, Text } from "react-native";
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { EntityIdentityPlugin, IdentityInfo } from "../entity-identity-plugin";
import { Entity, EntityPlugin, getEntityId } from "../entity-plugin";
import { HaborPlugin } from "../habor-plugin";
import { WidgetItem } from "../hessia-plugin/dynamic-component";
import { SimpleBox } from "../page-plugin/page-widget";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { StringNavigator } from "./string-router";

export interface HessiaString {
  value: string;
}

export class StringPlugin extends CorePluginClass {
  public static details = {
    name: "String",
    description: "String Primitive System",
    //  CONSIDER:  We CAN Couple EntityIdentiyPlugin and StringPlugin SEPARATELY using a COUPLING Plugin!
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, HaborPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id],
    id: "stringPlugin"
  }

  public serviceName = "string";
  public stringService!: HaborNounService<HessiaString>;
  protected pEntities!: EntityPlugin;

  //  THOGUHT:  We CAN do this all with SEPARATE systems.. but do we REALLY want to do that.. can we use SOME standard UGH... idk man hmm...
  //  TODO:  De-couple the EntityPlugin
  public createString = async (value: string) => {
    const hessiaString = await this.stringService.create({ value });
    const strEntity: Entity = { metadata: "test", systemId: this.serviceName, route: { id: hessiaString.id, created: hessiaString.created } };
    const entityId = await getEntityId(strEntity);
    const strEntityInternal = await this.pEntities.entityService.create(strEntity, entityId);
  }

  public updateString = async (stringId: string, value: string) => {
    await this.stringService.update(stringId, { value });
  }

  public deleteString = async (stringId: string) => {
    const hessiaString = await this.stringService.retrieve(stringId);
    if (!hessiaString) { throw `Missing instance from the String Service: ${stringId}`; }
    const entityId = await getEntityId({ metadata: "test", route: { id: hessiaString.id, created: hessiaString.created }, systemId: this.serviceName });
    await this.pEntities.entityService.delete(entityId);
    await this.stringService.delete(stringId);
  }

  public install = async (program: Program, { system, pEntities, habor, authPlugin, entityIdentityPlugin }: { entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, habor: HaborPlugin, authPlugin: AuthPlugin }) => {

    //  CONSIDER:  Perhaps we should register this piece?
    this.stringService = new HaborNounService(this.serviceName, habor.haborSDK);
    await this.stringService.init(authPlugin.token);

    this.pEntities = pEntities;


    //  NOTE:  No need to explicltly register when using the entity cache!
    // pEntities.entityService.registerService({
    //   id: "string",
    //   name: "String Entity Service",
    //   service: pEntities.entityService
    // });

    //  TODO:  Do this through a Coupling Plugin!
    //  TODO:  Make this a NAMED thing that we can access in our visual ontology thing hgsn !  EVERYTHING shold perhapss be visible in the ontology visually with enough drill-down? Hmmm
    // pEntities.registerEntityListItemRenderer({
    //   id: "string-entity-renderer",
    //   name: "String Entity Renderer",
    //   renderer: async (entity: InstanceInternal<Entity>, api: EntityListItemAPI) => {
    //     if (entity?.payload.systemId === this.serviceName) {
    //       const stringValue = await this.stringService.retrieve(entity.payload.route.id);
    //       api.addComponent(() => {
    //         return (
    //           <>
    //             <Text>String Entity</Text>
    //             <Text>{ stringValue?.payload.value }</Text>
    //           </>
    //         );

    //       })
    //     }
    //   }
    // });

    //  CONSIDER:  SO much registration happening in these plugins CAN be done in a managed way and I THINK this is what the fucking HApp systems ccan be hm!  Perhaps.. it'll all converge eventually?? Hmmm... BUT may still want this for Halia too, and is that local or remote?? HM
    pEntities.registerEntityDetailView({
      name: "String Service",
      description: "String Service",
      id: "string-service",
      component: ({ entity }: { entity: Entity }) => {

        //  Check if this entity is a string
        //  CONSIDER:  We MAY want to come at this from MULTIPLE angles.. like if we have a BUNCH of these? Hmmm... MAY want a more generic way to do this than in the component hm!  Checking ontological conditions???

        if (entity.systemId !== this.serviceName) { return null; }

        const [str, setStr] = React.useState<string | undefined>(undefined);

        const loadStr = async () => {
          const stringValue = await this.stringService.retrieve(entity.route.id);
          setStr(stringValue?.payload.value);
        };

        React.useEffect(() => {
          loadStr();
        }, []);

        return <WidgetItem name="String" color="#eeeeee">
          {
            str ? <Text>{str}</Text> : <ActivityIndicator />
          }
        </WidgetItem>
      }
    })

    //  CONSIDER:  What overlap does this have with the DETAIL view? Hmm

    entityIdentityPlugin.registerIdentityProvider({
      id: "string-entity-identity-provider",
      name: "String Entity Identity Provider",
      description: "Provides 'string' Entity Identity",
      systemId: "string",
      provide: async (entity: Entity): Promise<IdentityInfo> => {

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the String
        const str = await this.stringService.retrieve(entity.route.id);

        return {
          type: "string",
          icon: { name: "text-fields", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#ebb031",
          name: "String",
          description: "A Text String",
          value: str?.payload.value || ""
        } as IdentityInfo;

      }
    });

    system.registerPrimitiveSystem({
      id: 'string-system',
      name: "String",
      description: "String Primitive System",
      labels: ["primitive"],
      component: ({ navigation, route }) => <StringNavigator route={route} navigation={navigation} stringPlugin={this} />,
      menuItem: {
        icon: { name: "format-letter-case", type: "material-community" },
        backgroundColor: "#ebb031",
        iconColor: "white"
      }
    });
  }
}
