import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Text } from 'react-native';
import { AaroContext, AaroPlugin } from '../aaro-core';
import { AaroHeader } from '../utils';
import { SystemHeader } from '../../../packages/kelp-bar/system-header';

//
//  Navigators
//

export const AssistantnHome = () => {
  return (
    <>
      <SystemHeader system={{ name: "Assistant", icon: { type: "ionicon", name: "color-filter-outline" } }} breadcrumbs={ false } />
      <Text>Assistant</Text>
    </>
  );
}

export class AssistantAaroExtension extends CorePluginClass {

  public static details = {
    name: 'Assistant',
    description: 'Adds an AI Agent to assist with Aaro tasks.',
    dependencies: [AaroPlugin.details.id],
    id: 'assistant',
    image: require("../../../assets/stickers/assistant.png")
  }

  public install = async (program: Program, { aaro }: { aaro: AaroPlugin }) => {

    aaro.registerHOC(({ children }) => {

      const aaroContext = React.useContext(AaroContext);

      React.useEffect(() => {
        aaroContext.installRoute({
          name: "Assistant",
          component: AssistantnHome,
          icon: { type: 'ionicon', name: 'checkbox-outline' },
          emoji: "✅",
          logo: require("../../../assets/stickers/assistant.png")
        });
      }, []);

      return (
        <>
          {children}
        </>
      );

    });
    return this;
  }
}


