import * as React from 'react';
import { Text, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';
import { Picker } from '../../../kelp-bar/picker';
import { capitalizeAllWords } from '../../davel-utils';

interface OptionFieldProps {
  options: string[];
  labels: string[];
  value: any;
  update: (value: any) => void;
}

export const OptionField = ({ options, labels, value, update }: OptionFieldProps) => {

  return (
    <View style={{ zIndex: 1000, width: '100%' }}>
      <Picker
        grow={ true }
        items={options.map(option => {
          return { label: capitalizeAllWords(option), value: option };
        })}
        value={value}
        onChange={update}
        style={{ zIndex: 1000 }}
      />
    </View>
  );
};

export const sdtOptionRenderer = ({ sdt, name, value, update }: SDTRendererParams) => (
  <DavelField required={sdt.required} key={name} name={name}>
    {/* TODO:  Be able to set a background color and configure UI components as we want WITHIN the system!! */}
    <Text>{JSON.stringify(sdt)}</Text>
    <OptionField options={sdt.options} labels={sdt.options} value={value} update={update} />
  </DavelField>
); 
