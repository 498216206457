import * as React from 'react';
import { Switch, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

export const BooleanField = ({ value, update }) => {
  return (
    <View>
      <Switch
        onValueChange={update}
        value={value}
      />
    </View>
  );
};

//  CONSIDER!!!:  Want to be able to have thigns like "update" be PROJECTIVE!  Anything... any symbol!  That way... we FIND a mapping? hmm
export const sdtBooleanRenderer = ({ sdt, name, value, update }: SDTRendererParams) => (
  <DavelField required={sdt.required} key={name} name={name}>
    <BooleanField key={name} value={value} update={update} />
  </DavelField>
);
