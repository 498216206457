import { EntityType, FrameworkContext, HaborComponent, HaborComponentContext, InstanceInternal, NamedObject } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { Card } from '../../../../packages/kelp-bar/card';

export interface InstanceListItemProps<T extends NamedObject> {
  frameworkContext: FrameworkContext;
  item: InstanceInternal<T>;
  onPress?: (item: InstanceInternal<T>) => void;
  onLongPress?: (item: InstanceInternal<T>) => void;
  componentContext: HaborComponentContext;
}

export class InstanceListItem<T extends NamedObject> extends React.Component<InstanceListItemProps<T>, any> {
  public render = () => {

    //  Unpack
    const { frameworkContext: { changePage }, item, item: { payload: { name, description, icon = { name: "run", type: "material-community" }, color } }, onLongPress = () => null, onPress = () => null } = this.props;

    //  TODO:  Get UI information from the Metadata

    // const color: RGBAColor = { r: "255", g: "200", b: "102" };
    // const imageUrl = "test";
    // const materialIcon = "check";

    const renderedColor = renderRGBAColor(color || { r: "255", g: "200", b: "102" });

    return (
      // TODO:  Pull the press handlers out so they can be modified.  Consider the "Action" pattern where the user can select an action.  MAYBE use a default action, or require one?
      <Card outerStyle={{ flex: 1 }} raisedHeight={RaisedHeight.high} innerStyle={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, height: 100 }} onLongPress={() => onLongPress(item)} onPress={ () => onPress(item) }>
        <View style={{ flex: 0, width: 20 }} />
        {/* TODO:  Convert the selected color into a gradient?? */}
        <KelpIcon name={ icon.name || 'check' } type={ icon.type || 'material' } size={33} color={ renderedColor } />
        <View style={{ flex: 0, width: 20 }} />
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ color: '#353535', fontFamily: 'Poppins-Bold', fontSize: 18 }}>{ name }</Text>
          <Text style={{ color: '#a8a8a8', fontFamily: 'Poppins-Bold', fontSize: 12 }}>{ description }</Text>
        </View>
      </Card>
    );
  }
}

//
//  InstanceListItem Primitive Component
//
export interface InstanceListItemHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    item: InstanceInternal;
    onPress?: (item: InstanceInternal) => void;
    onLongPress?: (item: InstanceInternal) => void;
  }
}
export const InstanceListItemHaborPrimitive = ({ userProps, frameworkProps }: InstanceListItemHaborPrimitiveProps) => {

  //  Unpack
  const { componentContext, context } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <InstanceListItem { ...userProps } componentContext={ componentContext } frameworkContext={ context } />
    </HaborContainer>
  );
};


//  TODO:  Consider passing an ID to ALL HaborComponents so the props are serializable.  BUT, do props need to be?
//  TODO:  Consider NOT passing functions directly because they are NOT a member of the data types in this abstraction.
export const InstanceListItemHaborComponent: HaborComponent = {
  name: "InstanceListItemHaborComponent",
  propsSchema: { type: "object", extensible: true },
  classes: ["named-object"],
  element: {
    name: "InstanceListItemHaborPrimitive",
    props: {
      item: { type: "symbol", scopePath: ["props", "item"] },
      onPress: { type: "symbol", scopePath: ["props", "onPress"] },
      onLongPress: { type: "symbol", scopePath: ["props", "onLongPress"] },
    },
    children: []
  }
};
