import { AnyBlock, BlockInst, UserBlock, InstanceInternal, SerializedUserBlock, deserializeUserBlock, serializedUserBlockNoun } from 'habor-sdk';
import * as _ from 'lodash';
import * as React from 'react';
import { View, Text } from "react-native";
import { smallSpacer } from '../../../../packages/kelp-bar/styles';
import { BlockItem } from './components/block-item';
import { NamedObjectItemComponent } from './components/named-object-item';
import { BlockTag, blockTags, getAllBlocks, userBlocks } from './models/blocks';
import { haborSDK } from '../../hessia-plugin/config';
import { HaborReactComponent } from '../../habor-plugin/habor-utils';
const uuidv4 = require('uuid/v4');

export interface BlockExplorerProps {
  component: UserBlock;
}
interface BlockExplorerState {
  tagGroupVisibility: { [tagId: string]: boolean };
  userBlocks: InstanceInternal<UserBlock>[];
}
class BlockExplorerBase extends HaborReactComponent<BlockExplorerProps, BlockExplorerState> {
  constructor(props: BlockExplorerProps) {
    super(props);
    this.state = {
      tagGroupVisibility: _.mapValues(blockTags, (val: BlockTag) => false),
      userBlocks: []
    }
  }

  public componentDidMount = async () => {
    const { token } = this.context.frameworkContext;

    //  TODO:  Use a function in the "Block System" for this, or... maybe the expression sytem.. SOMETHING without going to the Search API abstraction!?
    const serUserBlocks = await haborSDK.searchInstances<SerializedUserBlock>(token, { nounId: serializedUserBlockNoun.id });
    const userBlocks = serUserBlocks.map(block => ({ ...block, payload: deserializeUserBlock(block.payload) }));
    this.setState({ userBlocks });
  }

  public enableTagGroup = (tagId: string) => {
    this.setState({ tagGroupVisibility: { ...this.state.tagGroupVisibility, [tagId]: true } });
  }

  public disableTagGroup = (tagId: string) => {
    this.setState({ tagGroupVisibility: { ...this.state.tagGroupVisibility, [tagId]: false } });
  }

  //  NOTE:  Blocks are added to a "UserBlock" using a "BlockInst" object.
  //  TODO:  This doesn't seem like a responsibility of the Block Explorer!?  PERHAPS just move it out entirely?  I DON'T even think knoweldge of "Adding a Block" should be here?  PERHAPS abstrac it and SIMPLY SELECT a block and return it for ANOTHER system to handle the selection!???!
  private addBlock = (blockInternal: InstanceInternal<AnyBlock>) => {

    //  Unpack Props
    const { component } = this.props;

    //  NOTE:  We DO allow blocks which are NOT fully connected!  This an INSTANTIATION.  We WILL need to connect it!
    // alert("Added a block to the component.  You will still need to connect the IO.  We generate a default output signal, but the input must be attached and the output property routed.");

    //  CONSIDER:  This function is confusing... consider refactoring?

    //  Get the Block (if this is an instance)
    // const blockInternal = block as InstanceInternal;
    // if ((blockInternal as InstanceInternal).id != undefined) {
    //   block = deserializeUserBlock(blockInternal.payload);
    // }


    const block = blockInternal.payload;

    //  Generate the Default Output
    //  NOTE:  THIS is where the default OUTPUT signals are created!???  Hmm!??
    const defaultOutput = block.outputSchema ? _.mapValues(block.outputSchema.properties, (val, key, obj) => ( { type: "signal" as "signal", signalId: ['state', `${key}-${new Date().getTime()}`].join('.') })) : {};

    //  Create the BlockInst
    const blockInstId = uuidv4();
    const blockInst: BlockInst = { id: blockInstId, blockId: blockInternal.id, input: {}, output: defaultOutput };

    //  Add the BlockInst
    component.blockInsts.push(blockInst);

    //  TODO:  Consider navigating back, OR just showing a nice little pop-up to indicate that this has changed!? HM!  Lke maybe a little thing on the bottom or top, like a notification!
    alert("Added Block");

    //  Force Re-Render
    //  TODO:  We should NOT be manipulating props!
    this.forceUpdate();
  }

  public render = () => {

    //  Unpack State
    const { tagGroupVisibility, userBlocks } = this.state;

    if (!userBlocks) {
      return <Text>Loading User Blocks...</Text>
    }

    //  Group Blocks
    //  TODO:  Use the Habor Tag system instead of building a CUSTOM tag system??
    const tagGroups: { [tagId: string]: InstanceInternal<AnyBlock>[] } = {};

    const localBlockMap = getAllBlocks();
    const localBlocks = Object.keys(localBlockMap).map(key => localBlockMap[key]);
    const allBlocks = [...localBlocks, ...userBlocks];

    allBlocks.forEach(block => {
      block.payload.tags.forEach(tag => {
        if (!tagGroups[tag]) { tagGroups[tag] = []; }
        tagGroups[tag].push(block);
      })
    }) 

    return (
      <View>
        {
          Object.keys(tagGroups).map((tagId: string) => {
            const tag = blockTags[tagId];
            const tagGroup = tagGroups[tagId];
            return (
              <View>
                <NamedObjectItemComponent item={ tag } onPress={ () => this.enableTagGroup(tagId) } />
                <View style={{ height: smallSpacer }} />

                {
                  tagGroupVisibility[tagId] ?
                    tagGroup.map(block => {
                      return (
                        <View>
                          <BlockItem onPress={ () => this.addBlock(block) } block={ block } />
                          <View style={{ height: smallSpacer }} />
                        </View>
                      )
                    }) :
                    null
                }
              </View>
            );
          })
        }
      </View>
    );
  }
}
export const BlockExplorer = BlockExplorerBase