import * as React from "react";
import {
  FlatList, Modal, Pressable, View, ViewStyle
} from "react-native";
import { Icon } from "react-native-elements";
import { Paragraph } from "./constants";
import { GroupCard } from "./group-card";

export interface PickerItem { label: string, value: any };

export const Picker = ({ includeNone = false, grow = false, items, value, onChange, style }: { includeNone?: boolean, grow?: boolean, onChange: (value: any) => void, items: PickerItem[], value: any, style?: ViewStyle }) => {

  if (includeNone && !items.find(item => item.value === undefined)) {
    items.push({ value: undefined, label: "None" });
  }
  const [open, setOpen] = React.useState(false);
  const getLabel = (value: any) => {
    return items.find(item => JSON.stringify(item?.value) == JSON.stringify(value))?.label;
  }

  return (
    <View style={[{ maxWidth: 400 }, style]}>
      <Pressable onPress={() => setOpen(!open)}>
        <GroupCard style={{ padding: 15, flexDirection: 'row' }}>
          <Paragraph style={{ flex: 1 }}>{getLabel(value)}</Paragraph>
          <Icon size={16} color="#cccccc"  type="font-awesome" name={open ? "chevron-down" : "chevron-right"} />
        </GroupCard>
      </Pressable>
      {
        open && (
          // <Modal style={{ width: '100%', height: '100%' }} transparent={ true }>
            <GroupCard style={{ width: '100%', top: grow ? undefined : '100%', marginTop: 5, position: grow ? 'relative' : 'absolute', backgroundColor: 'white', padding: 0, maxHeight: 300 }}>
              <FlatList scrollEnabled={true} data={items} ItemSeparatorComponent={() => <View style={{ height: 2, backgroundColor: '#eeeeee' }} />} renderItem={({ item }) => (
                <Pressable style={{ height: 50, alignItems: 'flex-start', justifyContent: 'center', paddingHorizontal: 20 }} onPress={() => { onChange(item.value); setOpen(false); }}><Paragraph>{item.label}</Paragraph></Pressable>
              )} />
            </GroupCard>
          // </Modal>

        )
      }
    </View>
  );
}