import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { api } from '../bas-sdk';
import { ArtistCard } from '../components/artists';
import { GalleryHeader } from '../components/header';
import { HeroCard } from '../components/hero-card';
import { CenteredView } from '../content-view';
import { Profile } from '../openapi';

export const Artists = () => {

  const [artists, setArtists] = React.useState<Profile[]>([]);

  React.useEffect(() => {
    getArtists();
  }, []);

  const getArtists = async () => {
    const allArtists = await api.profilesControllerFindAll({});
    setArtists(allArtists);
  }

  return (
    <View style={{ flex: 1 }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <ScrollView style={{ flex: 1 }}>
        <CenteredView>

          <HeroCard style={{ backgroundColor: 'white' }} title="Artists" subTitle="Find an Artist" />

          <FlatGrid
            data={artists}
            itemDimension={300}
            spacing={50}
            renderItem={({ item: artist }) => <ArtistCard artist={artist} />}
          />

        </CenteredView>
      </ScrollView>
    </View>
  );
}
