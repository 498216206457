import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { RequestRecordListItemHaborComponent, RequestRecordListItemPrimitive } from "./request-record-list-item";

export class RequestRecordPlugin extends CorePluginClass {
  public static details = {
    name: "RequestRecord",
    description: "RequestRecord Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "requestrecord"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {


    hessia.registerPrimitiveComponent("RequestRecordListItemPrimitive", RequestRecordListItemPrimitive);
    hessia.registerComponent(RequestRecordListItemHaborComponent);
  
  }
}
