/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailCosts
 */
export interface RetailCosts {
    /**
     * 
     * @type {string}
     * @memberof RetailCosts
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    shipping: number;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    vat: number;
    /**
     * 
     * @type {number}
     * @memberof RetailCosts
     */
    total: number;
}

export function RetailCostsFromJSON(json: any): RetailCosts {
    return RetailCostsFromJSONTyped(json, false);
}

export function RetailCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'subtotal': json['subtotal'],
        'discount': json['discount'],
        'shipping': json['shipping'],
        'tax': json['tax'],
        'vat': json['vat'],
        'total': json['total'],
    };
}

export function RetailCostsToJSON(value?: RetailCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'subtotal': value.subtotal,
        'discount': value.discount,
        'shipping': value.shipping,
        'tax': value.tax,
        'vat': value.vat,
        'total': value.total,
    };
}

