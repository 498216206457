import * as React from "react";

export interface MenuContext {
  toggleMenu: () => void;
  open: boolean;
}

export const MenuContext = React.createContext<MenuContext>({
  toggleMenu: () => { alert("Not Implemented."); },
  open: false
});

export interface MenuProviderProps { }
export interface MenuProviderState {

}

export const MenuProvider = ({ children }: any) => {
  const [open, setOpen] = React.useState(false);
  return (
    <MenuContext.Provider value={{ toggleMenu: () => setOpen(!open), open }}>
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => {
  return React.useContext(MenuContext);
}
