import { NounSearchParams } from "habor-sdk";
import {
  INounService,
  NounServiceInstanceInternal,
} from "./packages/noun-service/noun-service";
import { Entity } from "./plugins/hessia-plugins/entity-plugin";

export class EntityService extends INounService<Entity> {
  constructor(
    private nounService: INounService<any>,
    private converter: (
      noun: NounServiceInstanceInternal<any>
    ) => NounServiceInstanceInternal<Entity>
  ) {
    super();
  }
  public create(obj: Entity): Promise<NounServiceInstanceInternal<Entity>> {
    throw Error("Not supported");
  }

  public async search(
    searchTerms?: NounSearchParams<any> | undefined
  ): Promise<NounServiceInstanceInternal<Entity>[]> {
    const nouns = await this.nounService.search(searchTerms);
    const entities = nouns.map((noun) => this.converter(noun));
    return entities;
  }
  public async retrieveAll(): Promise<NounServiceInstanceInternal<Entity>[]> {
    const nouns = await this.nounService.retrieveAll();
    const entities = nouns.map((noun) => this.converter(noun));
    return entities;
  }

  public async retrieveObj(): Promise<{[id: string]: NounServiceInstanceInternal<Entity>}> {
    const obj = await this.nounService.retrieveObj();
    const entityObj = {};
    Object.keys(obj).forEach(
      (key) => (entityObj[key] = this.converter(obj[key]))
    );
    return entityObj;
  }

  public delete(objId: string) {
    throw Error("Not Supported");
  }

  public init = () => {};

  public update(objId: string, obj: Entity) {
    throw Error("Not Supported");
  }

  public async retrieve(
    objId: string
  ): Promise<NounServiceInstanceInternal<Entity> | undefined> {
    const noun = await this.nounService.retrieve(objId);
    console.log(JSON.stringify(noun));
    if (noun) {
      return this.converter(noun);
    } else {
      return undefined;
    }
  }
}

export class NounServiceAdapter<Input, Output> extends INounService<Output> {
  constructor(
    private nounService: INounService<Input>,
    private convert: (noun: NounServiceInstanceInternal<Input>) => NounServiceInstanceInternal<Output>
  ) {
    super();
  }
  public create(obj: Output): Promise<NounServiceInstanceInternal<Output>> {
    throw Error("Not supported");
  }

  public async search(
    searchTerms?: NounSearchParams<any> | undefined
  ): Promise<NounServiceInstanceInternal<Output>[]> {
    const nouns = await this.nounService.search(searchTerms);
    const entities = nouns.map((noun) => this.convert(noun));
    return entities;
  }
  public async retrieveAll(): Promise<NounServiceInstanceInternal<Output>[]> {
    const nouns = await this.nounService.retrieveAll();
    const entities = nouns.map((noun) => this.convert(noun));
    return entities;
  }

  public async retrieveObj(): Promise<{[id: string]: NounServiceInstanceInternal<Output>}> {
    const obj = await this.nounService.retrieveObj();
    const entityObj = {};
    Object.keys(obj).forEach(
      (key) => (entityObj[key] = this.convert(obj[key]))
    );
    return entityObj;
  }

  public delete(objId: string) {
    throw Error("Not Supported");
  }

  public init = () => {};

  public update(objId: string, obj: Output): Promise<NounServiceInstanceInternal<Output>> {
    throw Error("Not Supported");
  }

  public async retrieve(
    objId: string
  ): Promise<NounServiceInstanceInternal<Output> | undefined> {
    const noun = await this.nounService.retrieve(objId);
    console.log(JSON.stringify(noun));
    if (noun) {
      return this.convert(noun);
    } else {
      return undefined;
    }
  }
}
