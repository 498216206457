import { StyleSheet } from "react-native";

export const textStyles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    backgroundColor: '#eeeeee'
  },
});
