import { useNavigation } from "@react-navigation/core";
import { InstanceInternal, NamedObject } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as ObjectHash from "object-hash";
import * as React from 'react';
import { Clipboard, Text, TouchableOpacity, View } from 'react-native';
import { HaborNounService, MultiNounAddress, MultiNounService, NounService, NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { ManagedComponent } from "../apps-plugin/screens/app-editor.screen";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { SimpleBox } from "../page-plugin/page-widget";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { EntityNavigator, EntityRoute } from './entity-navigator';
import { EntityListEmitter, EntityListEvents } from "./entity-list";
import { WidgetItem } from "../hessia-plugin/dynamic-component";
import { Hessia2Context, Hessia2Plugin } from "../../hessia2/hessia2-plugin";

//  CONSIDER:  I ALREADY have the "Entity List" view... WHY should I make another???  Just CONFIGURE that for SELECTION Hmmm... also... from the contet ah!  It's OK because it is "aware" of that concept hm!  SO... it's OK for it to call into that flow hmm... I WOULD like to avoid hard-coding a lot of that stuff... just have itmore like a FLOW hmm... FOR NOW, let's just get it working!

//  Instead of doing this... UGH... why not let these PARTS of the ID be remotely associated? Hmm.. PERHAPS!  Then even the ID can grow as needed? Hmmm.... 
export interface EntityIDParams {
  serviceId: string;
  created: string;
  id: string;
}

//  TODO:  Update Halia!
//  CONSIDER:  I MAY want to do this as MODIFIERS so it's MORE than just the TYPE and actually has some validation and ... CATEGORY stuff hm!

export interface Identifiable {
  id: string;
}

export interface Named {
  name: string;
}

//  CONSIDER:  CAN I instead use URLs for this?  I MAY want it stored as OBJECTS though.. NOT just simple strings? Hmm.. I mean ANY JSON can be convereted to string ugh idk.. point is.. there MAY be more strucutre to it than just a linear thing? hsadfkljsj...
//  TODO:  Just call this "Address"?  Hmm...  We CAN make this as complex as we want with additioanl systems, perhaps supporting inheritance or whatever they want to mm!
export interface Entity {
  systemId: string;
  route: any;
  metadata: any;
}

//  CONSIDER:  We MAY want to use Event Sourcing to store all states.
export const getEntityId = (entity: Entity) => {
  const noMeta = { ...entity };
  delete noMeta["metadata"];
  return JSON.stringify(noMeta);

  //  TODO:  Remove this!  I don't want to use a Sha1, because it's irreversible.
  // return ObjectHash.sha1(noMeta);
}

//  TODO:  Avoid the abstraction leak with MultiNounAddress!  I feel this can be hidden by the Entity system.
// export const getEntityAddress = (entity: Entity): string => {
//   return JSON.stringify({
//     type: 'multi-noun-address',
//     serviceId: entity.systemId,
//     address: entity.route
//   });
// }


export type EntityListItemComponent = React.Component<{ entity: NounServiceInstanceInternal<any> }, any, any> | React.FunctionComponent<{ entity: NounServiceInstanceInternal<any> }>;

export interface EntityListItemAPI {
  setText: (text: string) => void;
  addComponent: (comp: EntityListItemComponent) => void;  //  NOTE:  Instead of regiseing with a function this module could reach out to a specific place with this stuff.. or it can pull just the encodings/ hmm...
}

//  CONSIDER:  AOK!!  CURRENTLY it's JUST on the ID.. BUT we might want to key off TONSS of stuff, AND that stuff isn't necessarily "returned" to the fucking UI.. SO!! We want the SERVICE to process each perhaps and then determine? O... maybe the services just inject DIRECLT when they feel something is an Entityt? HMM... Then it's also up to the services to deal with global paging and like yea hmm... I think I like that..hmm  KINDA like the register for the thin gbut soo much more j ljerj
//  TODO:  Consider WRAPPING this in an object so we have insight into the renderer from the Application hmmmm... I'd LIKE to be able to SEE the renderers that have been registered, etc hmmm!!
//  NOTE:  We MAY also owant to make MULTIPLE renderers for various parts of the app hm!
export interface EnityListItemRenderer extends Identifiable, NamedObject {
  renderer: (entity: Entity, api: EntityListItemAPI) => (Promise<void> | void);
}


export const EntityPluginContext = React.createContext<EntityPlugin>({} as any);
export const useEntityPlugin = () => {
  return React.useContext(EntityPluginContext);
}

export interface EntityService extends ManagedComponent { }

export const getEntitiesByIds = async (ids: string[], entityPlugin: EntityPlugin): Promise<NounServiceInstanceInternal<any>[]> => {
  const entities: NounServiceInstanceInternal<any>[] = [];
  for (const id of ids) {
    try {
      const entity = await entityPlugin.entityService.retrieve(id);
      if (entity) {
        entities.push(entity);
      }
    } catch (err) {
      //  TODO:  Make a general error system.
      alert("getEntitiesByIdes:  ERROR!");
    }

  }
  return entities;
}

export const defaultEntityScope = {
  systemId: "entity"
};

export class EntityPlugin extends CorePluginClass {
  public static details = {
    name: "PrimitiveEntities",
    description: "Entity Primitive System",
    dependencies: [HessiaPlugin.details.id, Hessia2Plugin.details.id, SystemPlugin.details.id, AuthPlugin.details.id, HaborPlugin.details.id],
    id: "pEntities"
  }

  public selectEntity = (entity: Entity, scope: any = defaultEntityScope) => {
    EntityListEmitter.emit(EntityListEvents.EntitySelected, { scope, entity });
  }

  //  TODO:  Make protected
  public entityListItemRenderers: EnityListItemRenderer[] = [];
  public registerEntityListItemRenderer = (renderer: EnityListItemRenderer) => {
    this.entityListItemRenderers.push(renderer);
  }


  public entityDetailViews: EntityService[] = [];

  /**
   * Registers an "Entity Service" which stakes on this Entity.  Currently used to add a UI to the entity Editor.
   * @param entityService 
   */
  public registerEntityDetailView = (entityService: EntityService) => {
    this.entityDetailViews.push(entityService);
  }

  //  TODO:  Inject Local / Habor Entity Service... MAY also be able to just remove these!  These were for when we made explicit Entity IDs hmm...

  // public localEntityService: NounService<any> = {
  //   service:  new LocalNounService("entity"),
  //   name: "LocalEntity",
  //   id: "local-entity",
  // }

  // protected habor!: HaborPlugin;
  // protected habor!: HaborPlugin;


  public haborEntityService!: HaborNounService<Entity>;

  //  CONSIDER:  Don't need one of these for CREATION.  Separate that out hmm...

  public entityService!: MultiNounService<Entity>;

  public createEntity = async (entity: Entity) => {
    const id = getEntityId(entity);
    const entityInternal = await this.entityService.create(entity, id);
    return entityInternal;
  }

  public deleteEntity = async (entity: Entity) => {
    const id = getEntityId(entity);
    const entityInternal = await this.entityService.delete(id);
    return entityInternal;
  }

  //  TODO:  Build a Navigation system to standardize this? hmm...  Maybe.. maybe it's ALL navigation.. false dichotomy? kasldjf 
  public showHome;
  public showEditor;

  public systemName = "entity-system";



  //  TODO:  We need to initialize with the type... 
  public install = (program: Program, { hessia2, hessia, system, authPlugin, habor }: { hessia2: Hessia2Plugin, hessia: HessiaPlugin, system: SystemPlugin, authPlugin: AuthPlugin, habor: HaborPlugin }) => {

    //  CONSIDER:  How can we deal with updating values?
    hessia.registerContext(EntityPluginContext, this);

    const EntitySystem1 = {
      color: "#aaaaaa",
      name: "Entity",
      description: "Entities",
      emoji: "🙂",
      component:  () => <EntityNavigator entityPlugin={this} />,
      focus: false
    }

    hessia2.registerHOC(({ children }) => {
      const hessia2Context = React.useContext(Hessia2Context);
      React.useEffect(() => {
        hessia2Context?.installSystem(EntitySystem1);
      }, []);
      return children;
    });

    this.showHome = () => {
      hessia.navigation.navigate("entity-system");
    }

    this.showEditor = () => {
      hessia.navigation.navigate("entity-system", { screen: EntityRoute.Editor });
    }

    //  TODO:  Do not store ANY entities here specifically.
    //  TODO:  Support "extensions" like I was planning in my "paper" network and TrackMine.  Also SWIFT extensions hm!

    this.haborEntityService = new HaborNounService<Entity>("entity", habor.haborSDK, "entity");
    this.haborEntityService.init(authPlugin.token);

    const _haborEntityService: NounService<Entity> = {
      id: "habor-entity-service",
      name: "Habor Entity Service",
      service: this.haborEntityService
    };

    this.entityService = new MultiNounService("entity", [_haborEntityService], _haborEntityService.id);


    // const haborEntityService: NounService<any> = {
    //   service:  new HaborNounService("entity", haborSDK, authPlugin.token),
    //   name: "HaborEntity",
    //   id: "habor-entity",
    // }

    // this.entityService.registerService(haborEntityService);
    // this.entityService.setDefaultWriteService(haborEntityService.id);

    system.registerPrimitiveSystem({
      id: this.systemName,
      labels: ["core"],
      name: "Entity",
      description: "Entity Primitive System",
      component: () => <EntityNavigator entityPlugin={this} />,
      headerRight: () => {

        const navigation = useNavigation();

        return (
          <TouchableOpacity onPress={() => navigation.navigate(EntityRoute.Editor as never)} style={{ width: 34, height: 34, borderRadius: 17, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center', marginRight: 15 }}>
            <Text style={{ color: 'white', fontSize: 25, fontFamily: "Poppins-Bold", paddingTop: 1, paddingLeft: 1 }}>+</Text>
          </TouchableOpacity>
        )
      },
      menuItem: {
        icon: { name: "radiobox-marked", type: "material-community" },
        backgroundColor: "#28d1d1",
        iconColor: "white"
      }
    });

    this.registerEntityDetailView({
      name: "Entity ID Service",
      description: "Entity ID Service",
      id: "entity-id-service",
      component: ({ entity }: { entity: Entity }) => (
        <WidgetItem name="Entity" color="#eeeeee">
          <Text>System</Text>
          <Text>{entity.systemId}</Text>

          <View style={{ height: 7 }} />

          <Text>Route</Text>
          <Text>{JSON.stringify(entity.route)}</Text>

          <View style={{ height: 7 }} />

          <Text>Metadata</Text>
          <Text>{JSON.stringify(entity.metadata)}</Text>
        </WidgetItem>
      )
    })

    //  TODO:  Make this s part of the framework, OR give helpful error messges hm!  EITHER way... it's up to us to define the "API"... the "INTERFACE" hm!
    return this;
  }
}
