import { CorePluginClass, Program } from "halia";
import { ComponentPlugin } from "../component-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { belowWorkspaceRegister } from "../workspace-plugin/registers";
import { MenuHaborComponent, MenuHaborPrimitive } from "./menu-component";
import { MenuSettingsHaborComponent, MenuSettingsHaborPrimitive } from "./menu-settings-component";

export class MenuPlugin extends CorePluginClass {
  public static details = {
    name: "Menu",
    description: "Menu Plugin",
    dependencies: [HessiaPlugin.details.id, ComponentPlugin.details.id],
    id: "menu"
  }

  public install = (program: Program, { hessia, component }: { hessia: HessiaPlugin, component: ComponentPlugin }) => {

    hessia.registerComponent(MenuHaborComponent);
    hessia.registerPrimitiveComponent("MenuHaborPrimitive", MenuHaborPrimitive);
    //  TODO:  Re-evaluate the "Register" system.  Currently, these are HARD-CODED in Hessia and we expose an interface to register to them... BUT, I really don't think this is what we want!!!  We MAY be able to define registers in a System, THEN to register, we'd need to DEPEND on that system!?  OR, we can potentially just add this to a STACK of pages?  Hmm... perhaps we can OVERLAY them, or perhaps we can just add this as a component and it's up to the underlying system to figure out how to deal with that!!  MAYBE we'll have component types and stuff?
    component.registerHaborComponent(belowWorkspaceRegister.name, MenuHaborComponent);
    hessia.registerPrimitiveComponent("MenuSettingsHaborPrimitive", MenuSettingsHaborPrimitive);
    hessia.registerComponent(MenuSettingsHaborComponent);
    
  }
}
