import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { StringPlugin } from '.';
import { StringEditor } from './string-editor';
import { StringList } from './string-list';

const Stack = createStackNavigator();

export const StringNavigator = ({ stringPlugin, navigation, route }: { stringPlugin: StringPlugin, navigation: NavigationProp<any>, route: any }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} { ...{ headerMode: "none" } }>
      {/* <Route exact={ true } path="/home/entities/detail" component={ EntityDetail } /> */}
      <Stack.Screen name="Editor" component={ ({ route }) => <StringEditor stringInternal={ route?.params?.string } stringPlugin={ stringPlugin } navigation={ navigation } /> } />
      <Stack.Screen name="List" component={ () => <StringList navigation={ navigation } stringPlugin={ stringPlugin } /> } />
    </Stack.Navigator>
  );
}