import { NavigationProp, useNavigation } from '@react-navigation/native';
import { EventEmitter } from 'events';
import { BlockType, deserializeUserBlock, EntityType, FrameworkContext, HaborComponentContext, HaborIconSelection, InstanceInternal, Page, Plugin, SDTObject, SerializedUserBlock, serializedUserBlockNoun, serializeUserBlock, UserBlock, UserCredentialProps, Workspace } from 'habor-sdk';
import { History } from 'history';
import * as React from 'react';
import { Alert, Button, Platform, ScrollView, Switch, Text, TextInput, View, ViewProps } from "react-native";
import { RouteComponentProps, withRouter } from '../../../../packages/isomorphic-router/react-router';
import { AppHeader } from '../../../../packages/kelp-bar/app-header';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { GalleryButton } from '../../../gallery/components/common';
import { TextField } from '../../../gallery/components/fields';
import { Paragraph } from '../../../gallery/constants';
import { HAppRoute } from '../../apps-plugin/apps.nav.routes';
import { HaborReactComponent } from '../../habor-plugin/habor-utils';
import { AppState } from '../../hessia-plugin/app-utils';
import { haborSDK, navigate, RouteList } from '../../hessia-plugin/config';
import { Menu, MenuItem } from '../../menu-plugin/menu-component';
import { SpacePlugin, SpaceReactContext } from '../../space-plugin/space-plugin';
import { ComposerPlugin, useComposerPlugin } from '../composer-plugin';
import { BlockExplorer } from './block-explorer';
import { DataFlow, getSignalNodeMap, SignalNodeResult } from './data-flow';
import { ComponentDebugger } from './debugger';
import { Errors } from './errors';
import { ComponentLayout } from './layout';
import { resolveComponent } from './models/blocks';
const uuidv4 = require('uuid/v4');

//  TODO:  Remove the "Page" concept from this module!

//  CONSIDER:  Moving this module "Composer" into a Plugin with a "Page"?  EITHER WAY, we can probably add it as a UI Plugin with Feature APIs INSTEAD of adding it as a UserPugin or in the raw like I'm doing here.

//  CONSIDER:  This "Block" pattern is nearly identical to Habor Functions, BUT with some special component stuff mixed in.  MAYBE we should try to merge them??

//  CONSIDER:  These are nearly identical to the Component system!  I suggest, instead of making Components AND Blocks, we JUST use Components?  MAYBE call ALL of them Blocks.  The main difference, is Presentational Components have UI bundled with them???


export enum ComposerTab {
  //  TODO:  Consider adding "Layers" for separate Data / Layout Layers.
  Blocks, DataFlow, Layout, Code, Settings, Errors, Debug, Play, BlockExplorer
}

export interface AppConnectorProps extends UserCredentialProps, RouteComponentProps {
  plugin?: InstanceInternal<Plugin>;
  workspace?: InstanceInternal<Workspace>;
  appEmitter: EventEmitter;
  // page?: InstanceInternal<Page>;
  block: InstanceInternal<UserBlock>;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  navigation: NavigationProp<any>;
  updateBlock: (block: UserBlock) => void;
  signalNodeResult: SignalNodeResult;
}

export const AppEvent = {
  HeaderButtonPressed: "HeaderButtonPressed"
}
export interface AppContainerConfig {
  name: string;
  headerButton?: HaborIconSelection;
  onHeaderButtonPress: (setters: { setEditor: (setting: ComposerTab) => void, history: History, workspace: InstanceInternal<Workspace> }) => void;  //  TODO:  In the future, consider doing this with nested routing instead of passing a route changing function?
  component: React.FunctionComponent<AppConnectorProps>;
}

export const appContainerConfig: { [name: string]: AppContainerConfig } = {
  [ComposerTab.Blocks]: {
    name: "Blocks",
    // headerButton: { name: "save", type: "material" },  //  TODO:  Save the Davel form with a "Save" button in the header.  We MAY have to use an Event Emmitter to trigger the Davel form programatically.  OR, we can just register an "onChange" function and ALWAYS have the state of the Form?  Ah, I think that makes more sense?
    onHeaderButtonPress: () => alert("test"),
    component: ({ updateBlock, token, workspace, block, frameworkContext, signalNodeResult }: AppConnectorProps) => {
      return <DataFlow signalNodeResult={signalNodeResult} updateBlock={updateBlock} component={block.payload} frameworkContext={frameworkContext} />
    }
  },
  [ComposerTab.Errors]: {
    name: "Errors",
    onHeaderButtonPress: () => alert("test"),
    component: ({ block, signalNodeResult }: AppConnectorProps) => {
      return <Errors signalNodeResult={signalNodeResult} component={block.payload} />
    }
  },
  [ComposerTab.Layout]: {
    name: "Layout",
    // headerButton: { name: "save", type: "material" },  //  TODO:  Save the Davel form with a "Save" button in the header.  We MAY have to use an Event Emmitter to trigger the Davel form programatically.  OR, we can just register an "onChange" function and ALWAYS have the state of the Form?  Ah, I think that makes more sense?
    onHeaderButtonPress: () => alert("test"),
    component: ({ token, workspace, block }: AppConnectorProps) => {
      return <ComponentLayout component={block.payload} />
    }
  },
  [ComposerTab.BlockExplorer]: {
    name: "Block Explorer",
    // headerButton: { name: "save", type: "material" },  //  TODO:  Save the Davel form with a "Save" button in the header.  We MAY have to use an Event Emmitter to trigger the Davel form programatically.  OR, we can just register an "onChange" function and ALWAYS have the state of the Form?  Ah, I think that makes more sense?
    onHeaderButtonPress: () => alert("test"),
    component: ({ token, workspace, block }: AppConnectorProps) => {
      return <BlockExplorer component={block.payload} />
    }
  },
  [ComposerTab.Debug]: {
    name: "Debugger",
    // headerButton: { name: "save", type: "material" },  //  TODO:  Save the Davel form with a "Save" button in the header.  We MAY have to use an Event Emmitter to trigger the Davel form programatically.  OR, we can just register an "onChange" function and ALWAYS have the state of the Form?  Ah, I think that makes more sense?
    onHeaderButtonPress: () => alert("test"),
    component: ({ token, workspace, user, block, frameworkContext, componentContext, signalNodeResult }: AppConnectorProps) => {
      return <ComponentDebugger signalNodeResult={signalNodeResult} frameworkContext={frameworkContext} componentContext={componentContext} user={user} token={token} component={block.payload} />
    }
  },
  // [ComposerTab.DataFlow]: {
  //   name: "Select Menu",
  //   headerButton: { name: "save", type: "material" },
  //   onHeaderButtonPress: () => null,
  //   component: ({ workspace, appEmitter, token }: AppConnectorProps) => {

  //     //  Create State
  //     const [menu, setMenu] = React.useState<Workspace["menu"]>(workspace.payload.menu);

  //     React.useEffect(() => {
  //       const listener = async () => {
  //         try {
  //           await createOrUpdateInstance(workspaceNoun.id, { ...workspace.payload, menu }, token, workspace);
  //           alert("Success!");
  //         } catch (err) {
  //           alert(err);
  //         }
  //       };
  //       appEmitter.addListener(AppEvent.HeaderButtonPressed, listener);
  //       return function cleanup() {
  //         appEmitter.removeListener(AppEvent.HeaderButtonPressed, listener)
  //       };
  //     });

  //     //  Change Handler
  //     const changeHandler = (menu: Workspace["menu"]) => setMenu(menu);

  //     // return <PageSelector workspace={ workspace } installedPages={ pages } onChange={ changeHandler } />
  //     return <View />
  //   }
  // },
  //  TODO:  In the future, we might want to group all App Elements together under an "Elements" page.  For now, we'll just show a page for each.  These include things like Pages, Components, Actions, Types (Interface), Nouns (already available from the main app menu).  These are really just Pages themselves?  BUT FOR NOW, we'll hard-code them.
  //  NOTE:  SOME Custom Elements only make sense in a particular context, like the "Dashboard"... MAYBE we'd want to somehow encode this?  Maybe add a dependency to the "Dashboard" Plugin?  Or more specifically, the component type?  I suppose we'd have enough information with JUST the link to the component type, which was installed by a particular Plugin.
  //  NOTE:  We MIGHT want to split some of these out into Pages?  For example, we ALREADY have the "Entity Page".
  //  REALIZATION:  These are basically just duplicating the "Page" work... MAYBE we can just navigate to "Pages"?  Either way, let's stick with this FOR NOW.  
  [ComposerTab.Code]: {
    name: "Pages",
    headerButton: { name: "plus", type: "font-awesome" },
    onHeaderButtonPress: ({ history, workspace }) => navigate(history, RouteList.Workspaces.Home, { workspace, pageSelection: { pageName: "Instance Creator", props: { nounId: { nounId: "page", type: EntityType.Noun } } } }),
    component: ({ workspace, token, user }: AppConnectorProps) => {
      return <View />
    }
  },
  //  TODO-GENERAL:  Make SURE we can't reference a thing we don't have access / visiblity into!  PERHAPS that means we need to add a DEPENDENCY.. PERHAPS we can SUGGEST soutionss!??
  [ComposerTab.Settings]: {
    name: "Settings",
    onHeaderButtonPress: () => null,  //  TODO:  Why do we need this thing?  Can we make it optional?
    component: ({ componentContext, frameworkContext, block, navigation }: AppConnectorProps) => {
      // <InstanceCreator nounId={ { nounId: block.nounId, type: EntityType.Noun } } instanceId={ { nounId: block.nounId, instanceId: block.id, type: EntityType.Instance } } />
      navigation.navigate(HAppRoute.InstanceBuilder, { instance: block, skipOverrides: true });  //  TODO:  Pass "systemEditMode" IN CASE we're building the block for a NEW system!?  HMM!??  NEED to work on generalization for that so ALL systems can benefit!?  ALSO, remove this WHOLE thing and just use a DIFFERENT view or a View Manager type thing!?  MAYBE with serveral system registrations, and PERHAPS several views per system, OR just FUNCTIONS / BLOCKS that GENERATE Views!????!?? HMM
      return null;
    }
  }
}

//  TODO:  REALLY want a way (or several) to create and DISPLAY thigns like REGISTERS and register registrations!?  Shoudl have UIs and shit, ad shoudln't need to specify certain things like the fact that the destination is a register, shoul be automatic!?  AND the source needs to be Component / Serialized Block?? HMM???
//  TODO:  When a Plugin is tapped, we should see Plugin Details showing ALL the App Elements.  FOR NOW this can be hard-coded.  In the future, we MAY want to support a general "Context" which is supported at SEVREAL levels!

export const ConditionalView = ({ render, children, ...props }: { render: boolean, children: any } & ViewProps) => {
  if (render) {
    return <View {...props}>{children}</View>
  } else {
    return null;
  }
};

export interface ComposerProps {
  page?: InstanceInternal<Page>;
  block?: InstanceInternal<SerializedUserBlock>;
  navigation: NavigationProp<any>;
  spacePlugin: SpacePlugin;
  composerPlugin: ComposerPlugin;
}

interface ComposerState {
  setting: ComposerTab;
  workspaceSchema?: SDTObject;
  // pages: InstanceInternal<Page>[];
  blockInst?: InstanceInternal<UserBlock>;
  signalNodeResult?: SignalNodeResult;
  workspace?: InstanceInternal<Workspace>;
}
class ComposerBase extends HaborReactComponent<ComposerProps, ComposerState> {

  private appEmitter = new EventEmitter();

  //  declare context: React.ContextType<typeof AppContext>;
  // static contextType: React.ContextType<typeof AppContext> = AppContext;

  constructor(props: ComposerProps) {
    super(props);
    this.state = {
      setting: ComposerTab.Blocks,
    }
  }

  //  TODO:  Create the settings by making a page for EACH key in the JSON.
  //         This allows us to re-use the SAME UI for ALL settings.
  //  Ah... that MAY be too simple.  We won't have information about the Settings.  What if we make "Setting Groups" and render a new tab for each of those?  What if we ALSO have some options for how to render this in the app?

  //  OK.  Time to take a break.  By end of tonight I hope to have a general Settings display working, maybe re-using some code which can convert similar strucures to an experience in the app?  MAYBE this is our FORM processor!  SO, we are actually creating "Form Groups" and this is eventually mapped back into the required structure.  Ah I like that!

  private setEditor = (setting: ComposerTab) => {
    this.setState({ setting });
  }

  private makeDefaultComponent = async (compId: string, token: string) => {

    const newComponent: UserBlock = {
      type: BlockType.User,
      name: compId,
      blockInsts: [],
      tags: [],
      isComponent: true
    };

    const serNewComp = serializeUserBlock(newComponent);

    const newCompInternal = await haborSDK.createInstance({ nounId: serializedUserBlockNoun.id, payload: serNewComp }, token);
    return newCompInternal;
  }

  public overwriteHaborComponent = () => {

  }

  //  TODO:  Don't pass this down!  it can just nne an encoding in a CONTEXT where this "worspace" and other variables exist hmmm... MAYBE don't reference them by simple name like this, BUT GUID? Hmm... 
  public buildPage = async (page: InstanceInternal<Page>, token: string, compId?: string) => {

    const serComp = await this.buildBlock(token, compId || page.id);

    //  Update the Page
    await haborSDK.updateInstance(page.id, { nounId: page.nounId, payload: { ...page.payload, componentId: serComp.id } }, token);
  }

  public buildBlock = async (token: string, blockId: string = uuidv4()) => {

    //  Build the Default Component
    const serializedBlock = await this.makeDefaultComponent(blockId, token);

    //  Set the Page Component
    const block: InstanceInternal<UserBlock> = { ...serializedBlock, payload: deserializeUserBlock(serializedBlock.payload) };

    const workspace = await this.props.spacePlugin.getSelectedSpace();

    this.setState({ blockInst: block, workspace });

    return serializedBlock;
  }

  //  TODO:  Support severl component "form-factors" for now, we just support "page".
  public componentDidMount = async () => {

    //  Unpack
    const { page, block } = this.props;
    const { frameworkContext: { token } } = this.context;

    //  TODO:  Insteaad of coupling with Space system we can use a general interface that's dynamically coupled with a Plugin from teh Space system OR perhaps use an explicit coupling system hM!
    const workspace = await this.props.spacePlugin.getSelectedSpace();

    //  Create the Comopnent (if it doens't exist)

    //  TODO:  EACH page should have an associated Component.

    if (page) {
      let compId = page.payload.componentId;

      const { hessiaPlugin } = this.context as AppState;
      if (!hessiaPlugin) { throw `No Hessia Plugin in the composer!` }

      let component = await resolveComponent(token, hessiaPlugin, compId);

      //  TODO:  By default a page may NOT have a comopnent.  IF there's a linked ID and the component doesn't exist, then it may make more sense to just throw an exception than to build the component here.

      //  Handle Habor Component
      if (component && !component.blockComponent) {

        if ((Platform.OS !== 'ios') && (Platform.OS !== 'android')) {
          alert("The selected Page references a HaborComponent.  Editing a Habor Component is not currently supported off mobile.")
          this.props.navigation.goBack();
        }

        //  TODO:  Build a WEB enabled version of this Alert API!? HM!
        //  REFERENCE:  https://github.com/expo/expo/issues/6560
        Alert.alert("Overwrite Component", "The selected Page references a HaborComponent.  Would you like to overwrite this with an editable Block Component?  This operation cannot (currently) be reversed.", [
          { text: 'Yes', onPress: () => this.buildPage(page, token, compId) },
          { text: 'No', onPress: () => this.props.navigation.goBack() },
        ]);

        //  Handle Existing Block
      } else if (component && !!component.blockComponent) {
        this.setState({ blockInst: component.blockComponent as InstanceInternal<UserBlock>, workspace })

        //  Handle Undefined
      } else if (!component) {
        await this.buildPage(page, token, compId);
      }
    } else {
      if (block) {
        const regBlock = await deserializeUserBlock(block.payload);
        this.setState({ blockInst: { ...block, payload: regBlock }, workspace })
      } else {
        await this.buildBlock(token);
      }
    }

    this.updateSignalNodeMap();
  }

  public updateSignalNodeMap = async () => {
    const { frameworkContext: { token } } = this.context;
    const { blockInst } = this.state;
    if (blockInst) {
      const signalNodeMap = await getSignalNodeMap(token, blockInst.payload);
      this.setState({ signalNodeResult: signalNodeMap });
    }
  }

  public saveBlock = async () => {

    //  Unpack
    const { blockInst } = this.state;
    const { frameworkContext: { token } } = this.context;

    if (!blockInst) { return null }

    //  Write the Component
    const serComponent: SerializedUserBlock = serializeUserBlock(blockInst.payload);
    await haborSDK.updateInstance(blockInst.id, { nounId: serializedUserBlockNoun.id, payload: serComponent }, token);

    //  TODO:  Make a nice notification pop-up / go back
    alert("Saved!");
  }

  //  CONSIDER:  SHOULD this be a responsibility of this module, or CAN it be generalized?  Hmm...
  public updateBlock = (block: UserBlock) => {
    const { blockInst } = this.state;
    if (!blockInst) { return; }
    const updatedBlockInst = { ...blockInst, payload: block };
    this.setState({ blockInst: updatedBlockInst }, this.updateSignalNodeMap)
  }

  public render = () => {

    //  Unpack
    const { page } = this.props;
    const { frameworkContext: { token, user } } = this.context;
    const { setting, blockInst: pageComponent, workspace } = this.state;

    //  Guard Undefined
    if (!pageComponent) { return <Text>Loading Component</Text> }
    if (!workspace) { return <Text>Loading Workspace</Text> }

    const config = appContainerConfig[setting];

    const ChildComponent = withRouter(config.component);

    //  Construct the Framework Props
    const frameworkContext: FrameworkContext = { user, token, appEmitter: this.appEmitter, changePage: () => alert("page change not supported from the composer"), workspace };

    //  Construct the Context
    const componentContext: HaborComponentContext = { name: "workspace-container" };

    //  TODO:  I THINK it makes sense to TOTALLY remove the "Component" concept from this module now!!  MAYBE we can keep it around for posterity, BUT I think it's good to go!

    if (!this.state.blockInst || !this.state.signalNodeResult) {
      return <Text>Loading...</Text>
    }

    return (
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <ScrollView style={{ flex: 1 }}>
          <AppHeader title={config.name} onPress={this.saveBlock} buttonIcon={{ name: "save", type: "material" }} />
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 15 }}>
            {/* TODO:  Navigate with "back" support!  If we came from the Workspace menu, then return there.  Same with the Workspace Home page. */}
            {/* <GalleryButton title="back" onPress={ () => this.props.navigation.goBack() } /> */}
            <View style={{ height: 10 }} />
            <TextField title="Name" mode='manual' value={this.state.blockInst?.payload.name} onSave={text => this.setState({ blockInst: { ...this.state.blockInst, payload: { ...this.state.blockInst?.payload, name: text } as any } } as any)} />
            <View style={{ height: 2, backgroundColor: '#eeeeee', width: '100%', marginVertical: 20 }} />
            {/* CONSIDER:  Register this option, and more generally, consider not coupling it with this system. */}
            <Paragraph>Enable Detail View</Paragraph>
            <Switch value={this.props.composerPlugin.detailViewBlocks.includes(this.state.blockInst?.id)} onValueChange={value => this.props.composerPlugin.registerDetailViewBlock(this.state.blockInst?.id)} />
            <View style={{ height: 2, backgroundColor: '#eeeeee', width: '100%', marginVertical: 20 }} />
            <ChildComponent signalNodeResult={this.state.signalNodeResult} updateBlock={this.updateBlock} navigation={this.props.navigation} block={this.state.blockInst} frameworkContext={frameworkContext} componentContext={componentContext} workspace={workspace} token={token} user={user} appEmitter={this.appEmitter} />
          </View>
        </ScrollView>

        {/*
            IDEA:  Collapse the editors in ONE "Editor" window!  Make a switch at the top to change views?
            IDEA:  Collapse the Debug / Play buttons to a single page with a changer like the editor?
            IDEA:  Show a "badge" above the tabs to indicate things like NUMBER of errors!  
            IDEA:  Like that other thing I saw online, consider making the tabs on the SIDE, mabye with a gesture to show them?  MAYBE make them floating bars??  We can make them COOL looking floating bars, whimsical and fun elements!
          */}

        <Menu>
          <MenuItem icon={{ name: 'flow-branch', type: 'entypo' }} onPress={() => this.setEditor(ComposerTab.Blocks)} />
          <MenuItem icon={{ name: 'error', type: 'material' }} onPress={() => this.setEditor(ComposerTab.Errors)} />
          <MenuItem icon={{ name: 'plus', type: 'font-awesome' }} onPress={() => this.setEditor(ComposerTab.BlockExplorer)} />
          {/* <MenuItem icon={{ name: 'flow-branch', type: 'entypo' }} onPress={ () => this.setEditor(ComposerTab.DataFlow) } /> */}
          {/* <MenuItem icon={{ name: 'code', type: 'entypo' }} onPress={ () => this.setEditor(ComposerTab.Code) } /> */}
          <MenuItem icon={{ name: 'dashboard', type: 'material' }} onPress={() => this.setEditor(ComposerTab.Layout)} />
          <MenuItem icon={{ name: 'bug', type: 'entypo' }} onPress={() => this.setEditor(ComposerTab.Debug)} />
          {/* <MenuItem icon={{ name: 'play', type: 'font-awesome' }} onPress={ () => this.setEditor(ComposerTab.Play) } /> */}
          <MenuItem icon={{ name: 'md-settings', type: 'ionicon' }} onPress={() => this.setEditor(ComposerTab.Settings)} />
        </Menu>
      </View>
    );
  }
}

export const useSpacePlugin = () => {
  return React.useContext(SpaceReactContext);
}

export const Composer = (props: any) => {
  const navigation = useNavigation();
  const spacePlugin = useSpacePlugin();
  const composerPlugin = useComposerPlugin();
  return <ComposerBase navigation={navigation} composerPlugin={composerPlugin} spacePlugin={spacePlugin} {...props} />
}