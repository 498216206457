import { Audio } from 'expo-av';
import * as React from 'react';
import { Button, Text, TextStyle, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';
const uuidv4 = require('uuid/v4');

//  REFERENCE:  GPT4
async function convertUriToBase64(uri: string): Promise<string> {
  try {
    // Fetch the audio file
    const response = await fetch(uri);
    const blob = await response.blob();

    // Convert Blob to Base64 using FileReader
    return new Promise((resolve, reject) => {

      //  TODO:  Will need a serpate API for Native Mobile apps.
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader?.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting audio to Base64:', error);
    throw error;
  }
}


export const VoiceMemoFieldStyle = [
  {
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    color: '#555555',
    minHeight: 37,
    borderBottomColor: '#555555',
    borderBottomWidth: 1
  },
];

export const VoiceMemoField = ({ style, value: voiceMemoBase64, update }: { style: TextStyle, value: string, update: (value: string) => void }) => {

  const [recording, setRecording] = React.useState<Audio.Recording>();

  async function startRecording() {
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
        staysActiveInBackground: true,
      });
      const { recording } = await Audio.Recording.createAsync({
          isMeteringEnabled: true,
          android: {
            extension: '.3gp',
            outputFormat: Audio.AndroidOutputFormat.THREE_GPP,
            audioEncoder: Audio.AndroidAudioEncoder.AMR_NB,
            sampleRate: 44100,
            numberOfChannels: 2,
            bitRate: 128000,
          },
          ios: {
            extension: '.caf',
            audioQuality: Audio.IOSAudioQuality.MIN,
            sampleRate: 44100,
            numberOfChannels: 2,
            bitRate: 128000,
            linearPCMBitDepth: 16,
            linearPCMIsBigEndian: false,
            linearPCMIsFloat: false,
          },
          web: {
            mimeType: 'audio/mp4',
            bitsPerSecond: 128000,
          }
      });
      setRecording(recording);
    } catch (err: any) {
      alert('Failed to start recording: ' + (err?.message));
    }
  }

  async function stopRecording() {
    console.log('Stopping recording..');
    setRecording(undefined);
    await recording?.stopAndUnloadAsync();
    const uri = recording?.getURI();
    if (!uri) { alert("No Audio URI Constructed."); return; }
    const base64 = await convertUriToBase64(uri);
    update(base64);
    console.log('Recording stopped and stored');
  }

  const playRecording = async () => {
    const sound = new Audio.Sound();
    await sound.loadAsync({ uri: voiceMemoBase64 }) 
    await sound.playAsync();
  }

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      <Button
        title={recording ? 'Stop Recording' : 'Start Recording'}
        onPress={recording ? stopRecording : startRecording}
      />
      {
        voiceMemoBase64 && <Button title="Play" onPress={playRecording} />
      }
    </View>
  );

};

export const sdtVoiceMemoRenderer = ({ sdt, key, value, update, name }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={name}>
    <VoiceMemoField style={VoiceMemoFieldStyle as TextStyle} update={update} value={value} />
  </DavelField>
);
