import {
  EntityType,
  InstanceInternal,
  Noun,
  Plugin,
  pluginNoun,
  workspaceNoun,
} from "habor-sdk";
import { CorePluginClass, Program } from "halia";
// import { enableNounsForPlugin } from "../entities-plugin/entity/entity-editor";
import { HaborPlugin } from "../habor-plugin";
import {
  createOrUpdateInstance,
  createOrUpdateNoun,
} from "../habor-plugin/habor-utils";
import { HessiaPlugin } from "../hessia-plugin";
import { haborSDK } from "../hessia-plugin/config";
import { HessiaEmitter } from "../hessia-plugin/hessia-events";
import { AccessPlugin } from "../model-plugins/access-plugin";

export class RewardPlugin extends CorePluginClass {
  public hessia!: HessiaPlugin;
  public habor!: HaborPlugin;
  public access!: AccessPlugin;

  public static details = {
    name: "Reward",
    description: "Reward Plugin Updated",
    dependencies: [
      HessiaPlugin.details.id,
      HaborPlugin.details.id,
      AccessPlugin.details.id,
    ],
    id: "reward",
  };

  //  Builds the System if Necessary
  //  CONSIDER:  Should we have a Flag in Hessia to indicate "Built" status?
  private build = async () => {
    
    //  Get the System Token (using mine for now)
    //  TODO-CRITICAL:  Do NOT expose the credentials to my personal account!!!
    const token = await this.access.getToken("will", "sullivan");

    //  Check Existing
    const existingSystems = await haborSDK.searchInstances(token, {
      nounId: pluginNoun.id,
      search: { match: { payload: { name: "Rewards" } } },
    });

    //  Create the System
    //  TODO:  Instead of using the RAW SDK, SHOULD be using the SYSTEM interface / plugin!  This way, we're ABSTRACTING to THAT symbol-space!  NOT the lower!? HM!
    const rewardPlugin = await createOrUpdateInstance<Plugin>(
      pluginNoun.id,
      { name: "Rewards", description: "Treat Yourself!" },
      token,
      existingSystems[0]
    );

    //  Poinnt Reward Noun
    const pointRewardNoun: Noun = {
      id: "pointreward",
      name: "Point Reward",
      description: "Point Based Rewards",
      inherits: ["namedobject"],
      properties: {
        value: { type: { type: "number" }, name: "Value" },
      },
    };

    const existingPointRewardNouns = await haborSDK.searchNouns(token, {
      search: { match: { id: "pointreward" } },
    });
    const pointRewardNounInternal = await createOrUpdateNoun(
      pointRewardNoun,
      token,
      existingPointRewardNouns[0]
    );

    //  Point Reward Entry Noun
    const pointRewardEntryNoun: Noun = {
      id: "pointrewardentry",
      name: "Point Reward Entry",
      description: "Point Based Reward Entry",
      properties: {
        timestamp: {
          type: { type: "date" },
          name: "Timestamp",
          description: "The time at which the points were awarded.",
        },
        reward: {
          type: {
            type: "relationship",
            nounIds: [{ nounId: "pointreward", type: EntityType.Noun }],
          },
          name: "Reward",
        },
      },
    };
    const existingPointRewardEntryNouns = await haborSDK.searchNouns(token, {
      search: { match: { id: "pointrewardentry" } },
    });
    const pointRewardEntryNounInternnal = await createOrUpdateNoun(
      pointRewardEntryNoun,
      token,
      existingPointRewardEntryNouns[0]
    );

    //  Task Completion Reward
    //  Check Existing
    //  TODO-GENERAL:  Again, SHOULD be able to generalize to crreateorupdate for NAMED object wherre the NAME is the key!  AND genralize furthe  to other things with a non explitit ID field / alternative unique field.
    const existingTaskCompletionRewards = await haborSDK.searchInstances(
      token,
      {
        nounId: pointRewardNoun.id,
        search: { match: { payload: { name: "Task Completion" } } },
      }
    );

    //  Create the System
    //  TODO:  This should be introduced in the Task / Reward COUPLING system.
    //  TODO:  Instead of using the RAW SDK, SHOULD be using the SYSTEM interface / plugin!  This way, we're ABSTRACTING to THAT symbol-space!  NOT the lower!? HM!
    await createOrUpdateInstance<Plugin>(
      pointRewardNoun.id,
      { name: "Task Completion", description: "Rewarded Upon Task Completion" },
      token,
      existingTaskCompletionRewards[0]
    );

    //  Get the Workspace
    const workspaces = await haborSDK.searchInstances(token, {
      nounId: workspaceNoun.id,
      search: { match: { payload: { name: "Hessia" } } },
    });

    //  TODO:  We SHOULD only do this if needed.. not each time.
    //  CONCERN:  THings like this create a "leak" where duplicate associatations

    //  TODO:  FIX THIS!!!
    // await enablePluginsForSpace([rewardPlugin], workspaces[0], token);
    // await enableNounsForPlugin([pointRewardNounInternal, pointRewardEntryNounInternnal], rewardPlugin, token);
  };

  public install = async (
    program: Program,
    {
      hessia,
      habor,
      access,
    }: { hessia: HessiaPlugin; habor: HaborPlugin; access: AccessPlugin }
  ) => {
    this.hessia = hessia;
    this.habor = habor;
    this.access = access;

    //  CONCERN:  SHOULD we do async ops like this when starting the app? Hmm.. These systems should eventually exit OUTSIDE the user's app itslef, and alwys exist? hmmm
    await this.build();

    HessiaEmitter.addListener(
      "UpdatedInstance",
      async ({ instance }: { instance: InstanceInternal }) => {
        //  THOUGHT:  Exterrnalize action SERVICE that all the variouys physical systems can use... Then we spin off this. hmm..
        //  TODO:  This SHOULD be registered condition and NOT a hard-coded thing!!!
        console.log(
          "UpdateInstace called in the Reward Plugin for Instance: " +
            JSON.stringify(instance)
        );
        if (instance.nounId === "task") {
          //  TODO:  Use the LOGGED in userr for this, NOT a static user!!
          const token = await this.access.getToken("will", "sullivan");

          //  Get the Completed Status
          const completedStatuses = await haborSDK.searchInstances(token, {
            nounId: "status",
            search: { match: { payload: { name: "Completed" } } },
          });

          //  Get the Reward
          const taskCompletionRewards = await haborSDK.searchInstances(token, {
            nounId: "pointreward",
            search: { match: { payload: { name: "Task Completion" } } },
          });

          console.log("About to check");
          if (instance?.payload?.status?.instanceIdList[0]?.instanceId === completedStatuses[0].id) {
            // alert("Completed a Task!  Now you get rewarded!");
            await haborSDK.createInstance(
              {
                nounId: "pointrewardentry",
                payload: {
                  timestamp: new Date().toISOString(),
                  reward: {
                    instanceIdList: [
                      {
                        nounId: "pointreward",
                        instanceId: taskCompletionRewards[0].id,
                        type: EntityType.Instance,
                      },
                    ],
                  },
                },
              },
              token
            );
            //  TODO:  Make an App-level notification which is CUSTOMIZABLE UI witth linkns and shit to show that a reward was earned.  MAYBE make stars pop onn the screen or something!  HM!  MABE this is connfigurable!? HM!!

            //  I WOULD ilke to be able to show a UI thing... Hmm.. MAYBE this is where it could be helpful to let Plugins inject into the UI for thigns like this? Hmm... just having access to that context? hmmmmmm
            //  TODO:  Show a COOL animation and shit for this!  MAYBE the REWARD system can inject its OWN custom I shit, I'm thinkning SIMLAR to the Tineder MATCH thing!
            //  TODO:  Bring this back!
            // if (HaliaReactExports.showAlert) {
            //   HaliaReactExports.showAlert("Task Complete", { name: "star", type: "material" }, "You've earned a reward for completing your task!", () => null);
            // }
          }
        }
      }
    );
  };
}
