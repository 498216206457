import { CorePluginClass, Program } from "halia";
import { ComposerPlugin } from "../composer-plugin";
import { ModelPlugin } from '../model-plugin';
import { dueDateCreator, dueDateInstanceView, dueDateQuery } from "./due-date.blocks";
import { DueDateFilter, DueDateFilterComponent } from "./due-date.filters";


//  NOTE:  The "InstanceWidget" is being built with Blocks and is not injected with this Plugin.  THOUGH, we could serialize it and bundle it all together here, or a Habor script.

export class DueDatePlugin extends CorePluginClass {
  public static details = {
    name: "Due Date",
    description: "Due Date Plugin",
    dependencies: [ComposerPlugin.details.id, ModelPlugin.details.id],
    id: "duedate"
  }

  //  CONSIDER:  Do we really need to pass "program"?  It seems like this could just be a root plugin.
  //  MAYBE we can have a standardized way to add filter elements, like "min", "max", and other properties? hmm
  public install = (program: Program, { composer, modelPlugin }: { composer: ComposerPlugin, modelPlugin: ModelPlugin }) => {
    composer.registerPrimitiveBlock(dueDateQuery, "dueDateQuery");
    composer.registerPrimitiveBlock(dueDateCreator, "dueDateCreator");
    composer.registerPrimitiveBlock(dueDateInstanceView, "dueDateInstanceView");
    modelPlugin.registerFilter("due-date", DueDateFilter);
    modelPlugin.registerFilterComponent("due-date", DueDateFilterComponent);
  }
}
