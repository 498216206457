import { StackNavigationProp } from '@react-navigation/stack';
import { Color as HaborColor, InstanceInternal as HaborInstanceInternal, Plugin, UserCredentialProps } from 'habor-sdk';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { defaultBorderRadius } from '../../../../packages/kelp-bar/styles';
import { TextParagraph } from '../../../../packages/kelp-bar/text';
import { getSystemsForWorkspace } from '../../habor-plugin/habor-utils';
import { AppContext } from '../../hessia-plugin/AppContext';
import { FilterBar } from '../../model-plugin/entity/instance-list';
import { SpacePlugin } from '../../space-plugin/space-plugin';
import { SystemStackParamList } from '../apps.nav';
import { HAppRoute } from '../apps.nav.routes';
const Color = require('color');

type AppListNavigationProp = StackNavigationProp<
  SystemStackParamList,
  HAppRoute.SystemList
>;

interface AppListProps extends UserCredentialProps {
  navigation: AppListNavigationProp;
  spacePlugin: SpacePlugin;
}

interface AppListState {
  installedPlugins: HaborInstanceInternal<Plugin>[];
  filteredInstances: HaborInstanceInternal<Plugin>[];
  showOptions: boolean;
  selectedPlugin?: HaborInstanceInternal<Plugin>;
}

class AppListBase extends React.Component<AppListProps, AppListState> {

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      installedPlugins: [],
      filteredInstances: [],
      showOptions: false
    };
  }

  public componentDidMount = async () => {

    //  Unpack Props
    const { token, user } = this.props;

    //  TODO:  Instead of hard-coupling with "Context" and "Space" we should be able to generically filter%%

    //  Check Undefined
    if (user == undefined) { throw new Error("A valid user object must be passed to the Plugins Component."); }
    if (token == undefined) { throw new Error("A valid token must be passed to the Plugins Component."); }

    const space = await this.props.spacePlugin.getSelectedSpace();

    if (!space) {
      alert("No Space Selected");
      this.props.navigation.goBack();
    }

    const installedPlugins = await getSystemsForWorkspace(space, token);


    //  Update State
    this.setState({ installedPlugins, filteredInstances: installedPlugins });
  }

  public onItemPress = async (app: HaborInstanceInternal<Plugin>) => {

    //  Update State
    this.context.setPlugin(app, () => {

      //  Navigate to the Page
      this.props.navigation.navigate(HAppRoute.SystemConfig, {
        plugin: app
      });
    });
  }

  public onItemLongPress = async (plugin: HaborInstanceInternal<Plugin>) => {

    alert("SHOULD NAVIGATE TO ROOT ROUTE THING")
    // this.props.navigation.navigate(HAppRoute.ElementMenu as any, { elem: plugin });
    // this.setState({ selectedPlugin: plugin, showOptions: true });

    //  Navigate to the Page
    // alert("This should navigate to: (RouteList.Plugins.Detail, { plugin })");
  }

  public render = () => {

    //  Unpack
    const { installedPlugins, filteredInstances, selectedPlugin } = this.state;

    // items={ installedPlugins } onItemPress={ plugin => navigate(this.props.history, RouteList.Plugins.Detail, { plugin })}


    return (
      <React.Fragment>

        {/* <DetailModal item={ this.state.selectedPlugin } visible={ this.state.showOptions } onDismiss={ () => this.setState({ showOptions: false }) } /> */}

        {/* <Desktop>
          <Text>Desktop</Text>
        </Desktop> */}
        {/* TODO:  Enable using a widget, AND move to the parent */}
        <FilterBar token={this.props.token} instances={installedPlugins} setInstances={(filteredInstances) => this.setState({ filteredInstances })} />
        <FlatGrid itemDimension={125} horizontal={false} data={filteredInstances} renderItem={({ item: plugin }: { item: HaborInstanceInternal<Plugin> }) => {
          const pluginColor = plugin.payload.color ? renderRGBAColor(plugin.payload.color) : HaborColor.medGray
          console.log(JSON.stringify(plugin?.payload?.icon))
          return (
            <TouchableOpacity style={{ borderRadius: defaultBorderRadius, backgroundColor: pluginColor, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: 125, overflow: 'hidden' }} onPress={() => this.onItemPress(plugin)} onLongPress={() => this.onItemLongPress(plugin)}>
              <Icon  name={plugin?.payload?.icon?.name || "question"} type={plugin?.payload?.icon?.type || "material"} color="white" size={ 35} />
              <View style={{ height: 7 }} />
              <TextParagraph style={{ textAlign: 'center', fontSize: 15, color: "white", fontWeight: "900", letterSpacing: -0.75 }}>{plugin.payload.name}</TextParagraph>
            </TouchableOpacity>
          )
        }} />
      </React.Fragment>
    );
  }
}

export const AppList = AppListBase;
