import { StackNavigationProp } from '@react-navigation/stack';
import { Color, DefaultMenuSelection, DefaultMenuSelectionNoun, EntityType, InstanceInternal, Menu, menuNoun } from 'habor-sdk';
import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { AppContext } from '../hessia-plugin/AppContext'; //  TODO:  Should this module have knowledge of the actual AppContext OR use a props interface?
import { CardTouchableProp } from '../../../packages/kelp-bar/card';
import { AppHeader } from '../../../packages/kelp-bar/app-header';
import { haborSDK } from '../hessia-plugin/config';
import { PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { InstanceListSimpleItem } from '../model-plugins/named-object-plugin/named-object-list-simple';
import { MenuConfigRoute, MenuStackParamList } from './menu-settings.nav';
import { getMenu } from './menu-component';

//  TODO:  We NEED to evnetually support MORE context than just TOKEN!  LOTS of other systems need to inject thier shit!  Like SPACE.
export const getDefaltMenuSelection = async (token: string) => {

  //  Get the Default Indicator
  //  Hmm.... We know that there should be only ONE default per Workspace (for now)... but how do we encode this?  Perhaps we CAN encode it on the menu itself?  Hmm... I suppose an issue with that, is that a MENU might be shared with CHILD workspaces.  This means, we MAY want to deal with inheritance by allowing a selection PER workspace.  I SUPPOSE this would be the menu system's responsibility?  UNLESS, this ends up being a common pattern, in which case, perhaps we can abstract?  It's VERY similar to what we did with the PAGE system!
  const defaultMenus = await haborSDK.searchInstances<DefaultMenuSelection>(token,{
    nounId: DefaultMenuSelectionNoun.id
  });

  //  Validate
  //  TODO:  Do this in the backend, POSSIBLY with BLOCKS???
  //  TOOD:  When we make a new Space with inherited systems, WHAT should we do?  Make a new, OR just INHERIT and support OVERRIDE?  PERHAPS that should be a SYSTEM / SPACE feature / resopnsibility!?
  if (defaultMenus.length > 1) {
    throw new Error("A Space cannot have multiple default menus.");
  } else if (defaultMenus.length <= 0) {
    throw new Error("No Default Menu for the Space.");
  }

  //  Unpack
  const defaultMenuSelection = defaultMenus[0];
  return defaultMenuSelection;
}

//  TODO:  We NEED to evnetually support MORE context than just TOKEN!  LOTS of other systems need to inject thier shit!  Like SPACE.
export const getMenus = async (token: string) => {

  //  TODO:  Pass Space ID. We need to decide if we search normally an the CONTEXT determines Context for OTHER systems (like System / Plugin), OR if we just do that in our primitive system.  PERHAPS that's fine for now.
  //  QUESTION:  Should the "searchInstances" part be its OWN system, JUST like "Plugin" / "System"?  Hmm.... but,  I think it's a SINGLE invocation within a system, and there's a a CONTEXT which can be used for others which have a stake in this action???  I THINK that makes sense.  So, we should eventually be invoking system APIs, along with "Context" for stake-holders?  Perhaps the MAIN argument DOES still go to the owning system.
  //  CONCERN:  We may have LOTS of systems adding shit to context!  We should pass these for processing!  PERHAPS we make sure CONTEXT matches the expected context in the backend!  This way THAT'S the front-end state in terms of these sytems!  HOWEVER, we ALSO have our OWN state regarding the navigation / usage WITHIN that context!  It's JUST like selecting a LOCATION over several dimensions, THEN exploring and using shit in that location!!!
  //  FOR NOW, let's just get the menu system working globally WITHOUT the context thing.  BUT, I THINK we might want that to come next!!!
  const menus = await haborSDK.searchInstances(token, {
    nounId: menuNoun.id
  });
  return menus;
}

export const getSelectedMenu = async (token: string) => {
  const menus = await getMenus(token);
  const defaultMenuSelection = await getDefaltMenuSelection(token);
  const selectedMenu = menus.find(menu => menu.id === defaultMenuSelection.payload.menu.instanceIdList[0].instanceId);
  if (!selectedMenu) {
    throw new Error("Could not find the selected menu.");
  }
  return selectedMenu;
}

//
//  Default Menu Toggle
//

export interface MenuDefaultToggleProps {
  defaultMenuSelection?: InstanceInternal<DefaultMenuSelection>;
  menu: InstanceInternal<Menu>;
}

interface MenuDefaultToggleState {}
class MenuDefaultToggleBase extends React.Component<MenuDefaultToggleProps, MenuDefaultToggleState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: MenuDefaultToggleProps) {
    super(props);
    this.state = {}
  }

  public setDefault = async () => {

    const { defaultMenuSelection, menu } = this.props;
    const { token } = this.context;

    //  Guard
    if (!defaultMenuSelection) {
      throw new Error("No default menu selection to update");
    }

    if (!token) {
      throw new Error("Undefined Token");
    }

    const updatedMenuSelection: DefaultMenuSelection = {
      menu: {
        instanceIdList: [{
          type: EntityType.Instance,
          nounId: menuNoun.id,
          instanceId: menu.id
        }]
      }
    };

    await haborSDK.updateInstance(defaultMenuSelection?.id, {
      nounId: defaultMenuSelection?.nounId,
      payload: updatedMenuSelection
    }, token);

    //  TODO:  We want to build a STANDARD way to update the UI when objects change??? Hmm... MAYBE Websockets?  This COULD be a whole thing in and of itslef!?  AND maybe caching too????  We can get more into that as we build BLOCKS and expect the USER to build UIs!  CURRENTLY a lot of this is still hard-coded!  Intersting... it's an interesting mix of hard-coding and generalization for user development.

    alert("Updated!")
  }
 
  public render = () => {
    const { defaultMenuSelection, menu } = this.props;

    const defaultMenuId = defaultMenuSelection?.payload.menu.instanceIdList[0];

    //  Validate
    if (!defaultMenuId) {
      throw new Error("No default menu selected.");
    }

    const isDefault = menu.id === defaultMenuId?.instanceId;

    //  CONSIDER:  Do we REALLY want buttons ON buttons?  Perhaps this is not a good strategy to employ?
    return (
      <View { ...CardTouchableProp }>
        {
          isDefault ?
            <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Color.secondary, width: 70, height: 30, borderRadius: 15, padding: 7 }}>
              <Text style={{ color: Color.white, fontFamily: 'Poppins-Bold', fontSize: 12 }}>Default</Text>
            </View> :
            <TouchableOpacity onPress={ this.setDefault } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Color.lighterGray, width: 70, height: 30, borderRadius: 15, padding: 7 }}>
              <Text style={{ color: Color.medGray, fontFamily: 'Poppins-Bold', fontSize: 12 }}>Select</Text>
            </TouchableOpacity>
        }
        
      </View>
    );
  }
}

export const MenuDefaultToggle = MenuDefaultToggleBase

//
//  MenuSettingsList
//

type MenuListNavigationProp = StackNavigationProp<
  MenuStackParamList,
  MenuConfigRoute.MenuList
>;

export interface MenuSettingsListProps extends PrimitiveProps {
  navigation: MenuListNavigationProp
}
export interface MenuSettingsListState {
  menus: InstanceInternal<Menu>[];
  defaultMenuSelection?: InstanceInternal<DefaultMenuSelection>;
}
export class MenuSettingsList extends React.Component<MenuSettingsListProps, MenuSettingsListState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: MenuSettingsListProps) {
    super(props);
    this.state = {
      menus: []
    }
  }

  public componentDidMount = async () => {
    const { token } = this.context;
    const { frameworkContext: { user, workspace } } = this.context;

    // Guard
    if (!token) {
      throw new Error("Undefined Token");
    }

    const menus = await getMenus(token);

    const defaultMenuSelection = await getDefaltMenuSelection(token);
   
    this.setState({ menus, defaultMenuSelection });

  }

  public navigate = () => {
    this.props.navigation.navigate({ key: MenuConfigRoute.MenuBuilder });
  }

  public render = () => {

    //  Unpack
    const { frameworkProps } = this.props;
    const { menus } = this.state;
   
    //  NOTE:  This is just a way to set the generic for a React Class.
    const TypedInstanceListSimpleItem: new () => InstanceListSimpleItem<Menu> = InstanceListSimpleItem as any;

    return (
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <AppHeader title="Menus" buttonIcon={{ type: "material", name: "add" }} onPress={ this.navigate } />
        <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#f7f7f7' }} contentContainerStyle={{ padding: medSpacer }}>
          <FlatGrid
          itemDimension={ 400 }
          data={menus}
          renderItem={({ item }) => (<TypedInstanceListSimpleItem item={ item } registers={{ top: [], left: [], right: [({ item }) => <MenuDefaultToggle defaultMenuSelection={ this.state.defaultMenuSelection } menu={ item as InstanceInternal<Menu> } />] }} onPress={ (menu: any) => this.props.navigation.navigate(MenuConfigRoute.MenuBuilder, { menu }) } />)}
          style={{ padding: 0, margin: -10, overflow: 'visible' }}
          />
        </ScrollView>
      </View>
    );
  }
}