import { SDTObject } from "davel";

//  TODO-IMPORTANT:  I've split the definitions into several TS files to use recursive imports to support the recursive declaration... HOWEVER, I believe the PARSER will still attempt to continue until the END... which will be an infinite loop...  SO, perhaps I can PROGRESSIVELY attempt to access the next layer instead of all at once??? hmm... Basically, support recursive types in Davel!?

//  TODO:  Habor type definitions SHOULD support recursive definitions!?

//  TODO-DAVEL:  SHOULD support a reference to the "SerchTermSDT" for recursive types!!!!!  The limitation is that we currently reference the OBJECT instead of some identifier?  Hmmm...  MAYBE we can make it more like Swagger with IDs!??

//  TODO:  Should be RecursivePartial, meaning, the terms we specify here should be a path in the schema over which we're searching!?  MAYBE just call the type a "Path"!?  Hmmmm!!!


//  TODO-DAVEL:  Support RECURSIVE type definitions..
//               FOR NOW, do this with IDs in the type definition?  Hmm... OR perhaps we need to REGISTER them?  That MAY make sense.. that's where we bind the ID?  Hmm...

//  TODO-DAVEL:  Support UNION types.. meaning MULTIPLE types?
//  IDEA:  Union / Intersection? Idea:  const MatchTerm = { type: "object", extensible: true, itemType: { union: [{ type: "keyword" }, { type: "object", itemType: < THIS would be where the recursion comes in with a TYPE REFERENCE!? > }], properties: {} };

//  HESSIA:  Support Recursive types AND union types in the UI...

//  TODO-DAVEL:  ALSO need to support Recursive Partial, which is the TRUE type of the Match Term, which means I ALSO want to support GENERICS!?? HMM!!!  Damn.. SO much!?  Maybe it WOULD be more fruitful to map TS!? Hmm...  I IMAGINE TS has a JSON representation as well?  Hmm... PERHAPS I can use that system to BUILD Davel!?  HMM!???  OR.. do away with Davel and JUST use TS?? Hmm...

//  TODO-NEXT:  Let's START with Union types!?

//  OR, to keep making progress in OTHER regards, we can expect the USER to make the type and we can give them a sufficient "any" interface!?  PERHAPS we can ALSO build the system to show the UI based on the SCHEMA!?  Hmm!  Let's start here instead!?

//  TODO:  SHOULD be RecursivePartial on the object, NOT "any"!?  OR, in the meantime, before we support recursive partial, perhaps it can be 
const MatchTerm = { type: "object", extensible: true, properties: {}, itemType: { type: "any" } };

//  TODO-NEXT:  Support UI for the ANY type!?  ALSO make sure it's going to be properly stored in the DB?  Hmm.. in this case, we don't need it stored in the DB though?? Hmmm!?

const SearchTermMatchOnly = {
  type: "object",
  properties: {
    match: MatchTerm
  },
  extensible: false
}

const SearchTermSDT1 = {
  type: "object",
  properties: {
    any: SearchTermMatchOnly,
    and: SearchTermMatchOnly,
    match: MatchTerm,
    not:  SearchTermMatchOnly,
    exists: SearchTermMatchOnly
  },
  extensible: false
}

export const SearchTermSDT2: SDTObject = {
  type: "object",
  properties: {
    any: SearchTermSDT1,
    and: SearchTermSDT1,
    match: MatchTerm,
    not:  SearchTermSDT1,
    exists: SearchTermSDT1
  },
  extensible: false
};

export const SearchTermSDT3: SDTObject = {
  type: "object",
  properties: {
    any: SearchTermSDT2,
    and: SearchTermSDT2,
    match: MatchTerm,
    not:  SearchTermSDT2,
    exists: SearchTermSDT2
  },
  extensible: false
};

//  TODO:  EVEN in this primitive Search API, we SHOULD be able to specify a TYPE over which we're searching an perhaps show search / "intellisense" and UI selections BASED on that !?? Hmm... like.. STILL have Any / All, etc.. BUT when we do the MATCH query!?  It should be limited to the scope of that Schema!??