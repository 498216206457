import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { EventEmitter } from 'events';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { EntityPlugin } from '../entity-plugin';
import { haborSDK } from '../hessia-plugin/config';
import { SystemPlugin } from '../system-plugin/system-plugin';

//  TODO:  Make an easy way to see the event view, BUT perhaps EDIT the page to change the way it looks and stuff?? HM!
//  TODO:  Support check IN / OUT and support for EVENT system sync.. hmm
//  THOUGHT:  We can show a MINI view of the Entity for the associaiton? Hmm..
//  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
//  TODO:  Should NOT do ALL of this for EVERY entity... or if we DO.. shold have it CACHED HM!
//  NOTE:  WOULD like to be able to see a REPRESENTATION for a SYSTEM.. hm!  It IS a set of interconnected things HM!  EVEN the system builder is a SYSETM HMSLDFj !
//  TODO:  Add Loading
//  TODO:  We DO need a way to de-copule entiteis !  This way if the path CHANGES UGH! It's like coupling with the neuron directly vs. hm... maybe.... vs. coupling with something that activated for the concept? HM!  CAN have MULTIPLE things that activate for the concept hm!  The the idea is we can back-project based on the info hm! MAYBE! Mm!
//  TODO:  Instead of injecting specific UI, inject into a more generic ontological interpreter and inject the key pieces... hmm.. MAYBE like "title" and AH!  MAYBE we can have "sub" pieces, which have the SAME ontological strucutre, and .. perhaps  multiple TYPES of sub-pieces and hm!  Lot of thing slike taht hmmmm.. MAYBE we have MULTIPLE ontological strucutres, like the data, and the "style" hmmm... this way we can present it in a way that makes sense to us? Hmm...I mean haha, sounds like HTML and CSS, BUT the point is, we do it in a way that's freaking automated asdfjlsdf.... 
//  TODO:  Build a SEARCH feature in the noun service.  It's ESSENTIALLY just AsyncStorage.. a DOCUMENT store... a TYPE that goes on it.. hmm... I DID also discuss keeping the trackers SCHEMALESs and just posting them with a TYPE... ugh!  The POINT is.. either way... I wanted to be able to make new types by providinng a renderer for the TYPE HM!  THIS is something I talked about!!!
//  TODO:  SHOULD verify that the CheckinService has been iitialized... WOULD like to do this DECLARATIVELY.. DON'T want to write EVERYTHING in primtiive code.. CAN perhaps do this OUTSIDE with a system that specifically handles STATE transitions that we'd declared and shit.. STOP programming with the ONE domain .. the ONE interpreter, and start doing system driven developement or something ughh... the point is... instead of coding logic for patterns in this interpreter.. build a way to DECLARE the patterns, build an interpreter for them, and APPLY them hmm... LIKE this idea of context againnn.. the point is... this can't even EXIST until that condition is met hmm .. WHYYY Should I check it here using the JS Primtiive code when I COULD express it more generically? Hmm... it's JUST a piece of context? Hmm... interesting...  I DO want to keep pushin on hat!!!
//  NOTE:  For each entity it WOULD be nice if it had an ICON / style info.. hmm... MAYBE this isn't REQUIRED, but perhaps this is some COMMON data that a LOT of things can use.. hmm */}
//  NOTE:  MAYBE to make a Chekin this could be REQUIRED? Hmm... Maybe.. maybe not.. PLUS, it's not really specific to Checkin is it? Hmmm.. like "Named Object", "Styled", etc.. and maybe just a specific style group? Hmmm HOW can we add this though?  MAYBE the Activity system can required PART of it?? HM!  The idea is to enable Checkin, maybe it's not necessary.. PLUS the Activity system COULD pair? Hmm... but that would have to be custom for now? Hmm.. MAYBE TO START, the idea is we CAN add that info if we want.  MAYBE we build an "Activity System", ah!!! MAYBE!  THEN things that are considred "Instances" can STAY where they are in the map, and we can KEEP associating with all sorts of stuff! hm!  MAYBE even make it an instance of SEVERRAL things HM! */}
//  TODO:  Be careful abouto the STATE of the AuthPlugin.  It's one thing to depend upon the Plugin iteslf, but ANOTHER to depend on a particular state.  The IDEA is... this shold not HAVE to be concerned with it! HM!  Instead, HOIST the instantiation of things that depend on Habor and an authorized user hmm... interesting.. Then inject? Hmm...
//  TODO:  Make the PRIMITIVE Checkin Service work again, AND with ANY entity, INCLUDING Tasks.. hmm... 
//  TODO:  PLUS, make the PLUGIN be able to have a ReactContext so we have access to things like Token? Hmm... MAYBE just depend upon Hessia? Hmmm.. OR maybe this is a secondary concern that THIS Plugin shouldn't be aware of??? Hmmm...  BASICALLY something we can solve with DEPENDENCY injection AGAIN, with fucking COUPLING plugin hm!  MAYBE make a CheckServiceProvider???? hmm.. SOMETHING like that.. I THINK maybe this is like what's done in Nest??? Hmmm....
//  TODO:  Make it configurable.
//  NOTE:  ONE common pattern is the CRUD pattern for a THING.. whatever it may be.. we MAY want to ABSTRACT that.. possibly with a class.. hmm
//  CONSIDER:  I can ALSO inject with an EVENT indicator to show that there's an associated EVENT in the Event system hm!  It's all about INTEGRATION!  We build that using PLUGINS HM!
//  TODO:  Build a SEARCH feature in the noun service.  It's ESSENTIALLY just AsyncStorage.. a DOCUMENT store... a TYPE that goes on it.. hmm... I DID also discuss keeping the trackers SCHEMALESs and just posting them with a TYPE... ugh!  The POINT is.. either way... I wanted to be able to make new types by providinng a renderer for the TYPE HM!  THIS is something I talked about!!!
//  TODO:  Should NOT do ALL of this for EVERY entity... or if we DO.. shold have it CACHED HM!
//  NOTE:  WOULD like to be able to see a REPRESENTATION for a SYSTEM.. hm!  It IS a set of interconnected things HM!  EVEN the system builder is a SYSETM HMSLDFj !
//  TODO:  We DO need a way to de-copule entiteis !  This way if the path CHANGES UGH! It's like coupling with the neuron directly vs. hm... maybe.... vs. coupling with something that activated for the concept? HM!  CAN have MULTIPLE things that activate for the concept hm!  The the idea is we can back-project based on the info hm! MAYBE! Mm!
//  TODO:  Add Loading


export interface Checkin {
  datetime: string;
  targetEntity: string;
}

interface CheckinProps {
  checkins: NounServiceInstanceInternal<Checkin>[];
}
class CheckinListBase extends React.Component<{ checkinPlugin: CheckinPlugin, navigation: NavigationProp<any> }, any> {

  constructor(props) {
    super(props);
    this.state = {
      checkins: []
    }
  }

  componentDidMount = async () => {
    const checkins = await this.props.checkinPlugin.checkinService.retrieveAll();
    this.setState({ checkins });
  }

  render() {

    const checkinElems = this.state.checkins.map((checkin: NounServiceInstanceInternal<Checkin>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("Editor", { checkin })}>
          <Text>{JSON.stringify(checkin.payload)}</Text>
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Checkins</Text>
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('Editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>
          {checkinElems}
        </Page>
      </PageLoader>
    )
  }
}

const InjectedCheckinList = (props: any) => <CheckinListBase {...props} />
export const CheckinList: any = InjectedCheckinList;

const Stack = createStackNavigator();

export const CheckinPage = ({ checkinPlugin }: { checkinPlugin: CheckinPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      <Stack.Screen name="Editor" component={() => <CheckinEditor />} />
      <Stack.Screen name="List" children={({ navigation }) => <CheckinList navigation={navigation} checkinPlugin={checkinPlugin} />} />
    </Stack.Navigator>
  );
}


export const CheckinEditor = ({ }: {}) => {

  return (
    <View style={{ marginTop: 100 }}>
      <Text>You cannot currently make Checkins here.  You must make them from the "Entity".</Text>
    </View>
  );
}


export class CheckinPlugin extends CorePluginClass {
  public static details = {
    name: "PrimitiveCheckins",
    description: "Checkins Primitive System",
    dependencies: [AuthPlugin.details.id, SystemPlugin.details.id, EntityPlugin.details.id],
    id: "checkinPlugin"
  }

  public systemId = "checkin";

  public checkinService!: HaborNounService<Checkin>;

  public emitter = new EventEmitter();

  public onCreateCheckin = (cb: (checkin: NounServiceInstanceInternal<Checkin>) => void) => {
    this.emitter.addListener("create-event", cb);
  }

  public install = async (program: Program, { authPlugin, system, pEntities }: { authPlugin: AuthPlugin, system: SystemPlugin, pEntities: EntityPlugin }) => {


    this.checkinService = new HaborNounService<Checkin>("checkin-new", haborSDK);
    await this.checkinService.init(authPlugin.token);

    //
    //  Adds Ability to "Checkin" to an Entity
    //

    system.registerPrimitiveSystem({
      id: 'checkins-system',
      name: "Checkin",
      description: "Checkins Primitive System",
      labels: ["core"],
      component: () => <CheckinPage checkinPlugin={this} />,
      menuItem: {
        icon: { name: "check", type: "material" },
        backgroundColor: "#e65581",
        iconColor: "white"
      }
    });

    return this;
  }
}



