import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { AudioPlugin } from '.';
import { AudioEditor } from './audio-editor';
import { AudioList } from './audio-list';

const Stack = createStackNavigator();

export const AudioNavigator = ({ audioPlugin, navigation, route }: { audioPlugin: AudioPlugin, navigation: NavigationProp<any>, route: any }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      {/* <Route exact={ true } path="/home/entities/detail" component={ EntityDetail } /> */}
      <Stack.Screen name="Editor" component={ () => <AudioEditor route={ route } audioPlugin={ audioPlugin } navigation={ navigation } /> } />
      <Stack.Screen name="List" component={ () => <AudioList navigation={ navigation } audioPlugin={ audioPlugin } /> } />
    </Stack.Navigator>
  );
}