import { SDTBinarySerializedSchema } from 'davel/dist/src/parsers/binary';
import { HSDTDerivedSchema, SDTAnySerializedSchema, SDTArraySerializedSchema, SDTBooleanSerializedSchema, SDTDateSerializedSchema, SDTKeywordSerializedSchema, SDTNumberSerializedSchema, SDTObject, SDTObjectSerializedSchema, SDTOptionSerializedSchema, SDTTextSerializedSchema, SDTTypeSerializedSchema } from 'habor-sdk';
import { AllTMSDTs, HSDTDependencySerializedSchema, HSDTRelationshipSerializedSchema, HDTCustomType } from 'habor-sdk';
import { sdtDerivedRenderer } from '../../../../packages/davel-ui/types/derived/derived-field';

export interface TypeInfo {
  schema?: SDTObject | (() => SDTObject);
  fontAwesomeIcon: string;
  color: string;
  name: string;
  defaultValue?: AllTMSDTs;
}
export const primitives: { [name: string]: TypeInfo } = {
  'boolean': {
    schema: SDTBooleanSerializedSchema,
    defaultValue: { type: "boolean" },
    fontAwesomeIcon: 'menu',
    color: 'orange',
    name: 'Boolean'
  },
  'keyword': {
    schema: SDTKeywordSerializedSchema,
    defaultValue: { type: "keyword" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Keyword'
  },
  'binary': {
    schema: SDTBinarySerializedSchema,
    defaultValue: { type: "keyword" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Binary'
  },
  'text': {
    schema: SDTTextSerializedSchema,
    defaultValue: { type: "text" },
    fontAwesomeIcon: 'pencil',
    color: 'red',
    name: 'Text'
  },
  'date': {
    schema: SDTDateSerializedSchema,
    defaultValue: { type: "date" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Date'
  },
  'array': {
    schema: SDTArraySerializedSchema,
    defaultValue: { type: "array", itemType: { type: "any" } },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Array'
  },
  'object': {
    schema: SDTObjectSerializedSchema,
    defaultValue: { type: "object" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Object'
  },
  'sdt': {
    schema: SDTObjectSerializedSchema,
    defaultValue: { type: "sdt" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'SDT'
  },
  'any': {
    schema: SDTAnySerializedSchema,
    defaultValue: { type: "any" },
    fontAwesomeIcon: 'menu',
    color: 'orange',
    name: 'Any'
  },
  'number': {
    schema: SDTNumberSerializedSchema,
    defaultValue: { type: "number" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Number'
  },
  'option': {
    schema: SDTOptionSerializedSchema,
    defaultValue: { type: "option", options: [] },
    fontAwesomeIcon: 'pencil',
    color: 'red',
    name: 'Option'
  },
  'type': {
    schema: SDTTypeSerializedSchema,
    defaultValue: { type: "sdt" },
    fontAwesomeIcon: 'pencil',
    color: 'blue',
    name: 'Type'
  },
  'dependency': {
    schema: HSDTDependencySerializedSchema,
    defaultValue: { type: "dependency", nounId: "" },
    fontAwesomeIcon: 'pencil',
    color: 'pink',
    name: 'Dependency'
  },
  'relationship': {
    schema: HSDTRelationshipSerializedSchema,
    defaultValue: { type: "relationship", nounIds: [] },
    fontAwesomeIcon: 'pencil',
    color: 'green',
    name: 'Relationship'
  },
  'custom-type': {
    //  TODO:  Use a function to obtain the schema.
    fontAwesomeIcon: 'pencil',
    color: 'green',
    name: 'Relationship'
  },
  //  TODO:  AGAIN this shuold be INJECTED with Halia and a Feature API!!!
  'derived': {
    schema: HSDTDerivedSchema,
    defaultValue: { type: "derived", path: [] } as any,
    fontAwesomeIcon: 'arrow',
    color: 'gray',
    name: 'Derived'
  },
};