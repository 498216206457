import { Color } from 'habor-sdk';
import { HaborIconSelection } from 'habor-sdk/dist/src/models/icon';
import * as React from 'react';
import { Button, Modal, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Card } from '../../../kelp-bar/card';
import { KelpIcon } from "../../../kelp-bar/kelp-icon";
import { SimpleCard } from '../../../kelp-bar/simple-card';
import { getRaisedStyle, largeSpacer, medSpacer, RaisedHeight } from '../../../kelp-bar/styles';
import { TextSubParagraph } from '../../../kelp-bar/text';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

const materialIconsGlyphMap = require('@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/MaterialIcons.json');
const entypoIconsGlyphMap = require('@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/Entypo.json');
const featherIconsGlyphMap = require('@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/Feather.json');

//  TODO:  ALL of this should be installed with a Plugin?  This should be the field for a CUSTOM type, and we should remove the primitive type?

export interface DotProps {
  icon: HaborIconSelection;
  onPress?: (icon: HaborIconSelection) => void;
  selected?: boolean;
}

//  TODO:  We should have both a Icon Viewer AND a Icon Editor.  In SOME cases, the user MAY want to edit directly from the viewer!
//         Either way, the generalization is a component that does something.  IF that component happens to edit the field containing its value, that's fine!
//  We can make editors for the WHOLE object, OR allow the user to long press and edit the specific thing??  Remember, long press will bring up a context menu with a stck of actions??

export const IconDot = ({ icon, onPress = () => null, selected }: DotProps) => {
  selected
  return (
    <TouchableOpacity style={{ borderWidth: selected ? 3 : 0, width: 40, height: 40, borderRadius: 20 }} onPress={() => onPress(icon)}>
      <KelpIcon name={icon.name} type={icon.type} />
    </TouchableOpacity>
  );
};

export interface IconFieldProps {
  value: any;
  onChange: any;
}

export interface IconSelectorProps {

}

//  CONSIDER:  Grouping by type just like the Github viewer?
//  TODO:  SEARCH
//  TODO:  OTHER types of grouping, like by PROJECT or DESIGN, or THINGS, etc... MAYBE up to the user and involved in the Hessia system, ORRRR, we can just build a native thing with a few "Groups"!??  Like "Line" / "Closed" / etc (Design), and "Food", "Office" (Type / Things), etc...!??
//  TODO:  Consider loading ONCE at start!?
//  TODO:  Use a CANNED / standard / modular filter system?? HMMM...  PERHAPS injected into this system?  AND perhaps work on ways to make th injections FAST!?
//  TODO:  Currently the custom type was registered in HESSIA... hmm... I know I built that system, but I'm not sure about it...
//  TODO:  Use the field name registered with the Hessia Custom Type?? Hmmm...  PERHAPS we should wrap the custom type with this? YEAH, FOR NOW!
//  TODO:  Use a React Component or proper state for this showModal state??? Hmmm..
//  NOTE:  Code to determine whether or not an icon is selected (previously used for an indicator)
// CONSIDER:  The NAMING isn't right in the perspective of the PROVIDER!!! We want to adapt mm!  Like... "onSelected" hm!

export const IconSelector = ({ value, update }: { value: { type: string, name: string }, update: (icon: { name: string, type: string }) => void }) => {

  const [showModal, setShowModal] = React.useState(false);
  const [iconSearch, setIconSearch] = React.useState<string | undefined>(undefined);

  const combinedIcons: HaborIconSelection[] = [];
  combinedIcons.push(...Object.keys(materialIconsGlyphMap).map(key => ({ name: key, type: "material" })));
  combinedIcons.push(...Object.keys(entypoIconsGlyphMap).map(key => ({ name: key, type: "entypo" })));
  combinedIcons.push(...Object.keys(featherIconsGlyphMap).map(key => ({ name: key, type: "feather" })));

  const icons: any[] = [...combinedIcons];

  const filteredIcons = iconSearch ? icons.filter(icon => (icon.name as string).indexOf(iconSearch) !== -1) : icons;

  return (

    <Card raisedHeight={RaisedHeight.none} outerStyle={{ flex: 1 }} innerStyle={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 70 }} onPress={() => { setShowModal(true) }}>
      {
        value ?
          <KelpIcon color={Color.medGray} size={30} type={value.type} name={value.name} /> :
          <Text>No Selection</Text>
      }

      {
        showModal ?
          <Modal visible={true}>

            {/* TODO:  Build a modular header which makes the text smaller when we start to scroll! */}
            <View style={[{ flexDirection: 'row' }, { backgroundColor: "white", paddingTop: 100, paddingBottom: medSpacer / 2, paddingHorizontal: 8 * 2 }, getRaisedStyle({ raisedHeight: RaisedHeight.high })]}>
              <TextSubParagraph style={{ color: "black", fontSize: 27 }}>Icon Picker</TextSubParagraph>
              <View style={{ flex: 1 }} />
              <Button title="Cancel" onPress={() => setShowModal(false)} />
            </View>

            <View style={{ flex: 1, paddingHorizontal: 8 * 2 }}>

              {/* CONSIDERs:  How can we AUTOMATE this layout.  MAYBE something like the CardStack library.. BUT maybe with Hessia concepts like injections / system coupling? */}
              <View style={{ height: largeSpacer }} />

              <SimpleCard>
                <TextSubParagraph style={{ color: "#aaaaaa" }}>Search</TextSubParagraph>
                <TextInput style={{ borderBottomColor: '#aaaaaa', borderBottomWidth: 1 }} autoCapitalize="none" onChangeText={(text) => setIconSearch(text)} />
              </SimpleCard>

              <View style={{ height: medSpacer }} />

              <ScrollView style={{ padding: medSpacer / 2 }}>
                <FlatGrid style={{ margin: -medSpacer }} spacing={medSpacer} itemDimension={60} horizontal={false} data={filteredIcons} renderItem={(item) => (

                  <Card raisedHeight={RaisedHeight.none} outerStyle={{ backgroundColor: Color.offWhite }} innerStyle={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 60 }} onPress={() => { update(item.item); setShowModal(false); }}>
                    <KelpIcon size={25} color={Color.medGray} type={item.item.type} name={item.item.name} />
                  </Card>
                )} />
                {/* TODO:  Plugin / system to add ZOOM feature?? Hmm...  Or primitive? */}
                {/* TODO:  Standardize Modal styles, OR perhaps the App styles and let the Modal be defined by the user (if they want to override?? Just like MOST non-primitive UIs!?  OR, just keep this PRIMITIVE for now and build those up later!?  Hmmmm!!!???) */}
              </ScrollView>
            </View>
          </Modal> :
          null
      }
    </Card>
  );
};

//  SHOWER THOUGHT:  Make a way to DRAW entire apps and PERHAPS onfigure shit by drawing CONNECTIONS and a NAME of the configurere, and PERHAPS systems can inject their OWN shit into these OTHER systems!?  That's a HUGE benefit of Hessia!?  Then, perhaps we can also build the RESERVATION app, for TRADNG reservations!?  Like inject MODULES and stuff with DRAWINGS / MAGNETS!!!  Maybe show how to make a FEW different apps ?? HMM!?  MAYBE we do this in Hessia so we have access to my own, higher order API where systems have those critical features and all that!?? HMM!??
//  NOTE:  The Icon Field is using the Habor Custom Type system instead???
//  TODO-NEXT:  Make a HaborComponent for the IconViewer.

export const sdtIconRenderer = ({ sdt, key, value, update, name }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={name}>
    <IconSelector update={update} value={value} />
  </DavelField>
);
