/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

interface HSumParams {
  first: number;
  second: number;
}

export const HSumFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "sum",
  params: {
    type: "object",
    properties: {
      first: { type: "number" },
      second: { type: "number" }
    }
  },
  returns: {
    type: "number"
  },
  definition: async (input: PrimitiveFunctionParams<HSumParams>): Promise<number> => {
    const { params } = input;
    return params.first + params.second;
  }
};
