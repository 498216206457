import { FrameworkContext, HaborComponentContext, NounId } from 'habor-sdk';
import * as React from 'react';
import { ModelPlugin } from '..';
import { AppEvent } from '../../workspace-plugin/workspace-settings';
import { InstanceList } from '../entity/instance-list';
import { ModelSDK } from '../model-sdk';

//  CONSIDER:  Separate the Framework Props, App Props, User Props, etc... SOMETIMES, one may be able to override the other.  Consider making a more explicit prop hierarchy with inheritance?

interface WorkspaceInstanceListProps {
  nounId: NounId;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  modelSDK: ModelSDK;
  entitiesPlugin: ModelPlugin;
}

interface WorkspaceInstanceListState {}

//  TODO:  Consider separating "Page" from "Component".  A "Page" comes with some special "PageProps".
//  NOTE:  Here's an example where we attached some action to a parent UI context.  HOW should we generalie on this and make it work with Habor Components / Blocks??
export class WorkspaceInstanceList extends React.Component<WorkspaceInstanceListProps, WorkspaceInstanceListState> {

  private handleHeaderPress = () => {
    
    //  Unpack
    const { frameworkContext, nounId } = this.props;
    const { changePage, appEmitter } = frameworkContext;

    //  Change Page
    changePage({ pageName: "Instance Creator", props: { nounId } });

  };

  public componentDidMount = () => {

    //  Unpack
    const { frameworkContext: { appEmitter } } = this.props;

    appEmitter.addListener(AppEvent.HeaderButtonPressed, this.handleHeaderPress);
  }

  public componentWillUnmount = () => {

    //  Unpack
    const { frameworkContext: { appEmitter } } = this.props;

    appEmitter.removeListener(AppEvent.HeaderButtonPressed, this.handleHeaderPress);
  }

  public render = () => {

    const { nounId } = this.props;

    return (
        <InstanceList { ...this.props } nounId={ nounId } />
    );
  }
}
