/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstimateCosts,
    EstimateCostsFromJSON,
    EstimateCostsFromJSONTyped,
    EstimateCostsToJSON,
    RetailCosts,
    RetailCostsFromJSON,
    RetailCostsFromJSONTyped,
    RetailCostsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Estimate
 */
export interface Estimate {
    /**
     * 
     * @type {EstimateCosts}
     * @memberof Estimate
     */
    costs: EstimateCosts;
    /**
     * 
     * @type {RetailCosts}
     * @memberof Estimate
     */
    retailCosts: RetailCosts;
}

export function EstimateFromJSON(json: any): Estimate {
    return EstimateFromJSONTyped(json, false);
}

export function EstimateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Estimate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costs': EstimateCostsFromJSON(json['costs']),
        'retailCosts': RetailCostsFromJSON(json['retail_costs']),
    };
}

export function EstimateToJSON(value?: Estimate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costs': EstimateCostsToJSON(value.costs),
        'retail_costs': RetailCostsToJSON(value.retailCosts),
    };
}

