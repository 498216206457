import { CognitoUser } from '@aws-amplify/auth';
import { useNavigation } from "@react-navigation/native";
import { CognitoAccessToken, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js";
import Amplify, { Auth } from "aws-amplify";
import * as React from 'react';
import { ActivityIndicator, Pressable, View, ViewStyle } from "react-native";
import { Icon } from "react-native-elements";
import { defaultBorderRadius } from "../../packages/kelp-bar/styles";
import { api } from './bas-sdk';
import { GalleryButton } from './components/common';
import { awsConfig, basApiHost, Paragraph, primaryColor } from './constants';
import { Configuration, ConfigurationParameters, DefaultApi, Mockup, Profile, Work, WorkProduct } from "./openapi";
import { Cart } from "./providers/cart.provider";

export interface SyncProduct {
  id: number;
  external_id: string;
  name: string;
  variants: number;
  synced: number;
  thumbnail_url: string;
  is_ignored: boolean;
}

export interface SyncFile {
  id: number;
  type: string;
  hash: string;
  url: string;
  filename: string;
  mime_type: string;
  size: number;
  width: number;
  height: number;
  dpi: number;
  status: string;
  created: number;
  thumbnail_url: string;
  preview_url: string;
  visible: boolean;
}

export interface SyncOption {
  id: string;
  value: string;
}

export interface SyncVariant {
  id: number;
  external_id: string;
  sync_product_id: number;
  name: string;
  synced: boolean;
  variant_id: number;
  warehouse_product_variant_id: string;
  retail_price: string;
  sku: string;
  currency: string;
  product: {
    variant_id: number;
    product_id: number;
    image: string;
    name: string;
  };
  files: SyncFile[];
  options: SyncOption[];
  is_ignored: boolean;
}

export interface SyncProductResult {
  sync_product: SyncProduct;
  sync_variants: SyncVariant[];
  extra: any[];
}

//  TODO:  Merge the back and front intefaces hm!  Make it FAST ghouth!!  Should be able to edit without puhing hm!  Monorepo? HM!

export interface EstimateParams {
  cart: Cart;
  recipient: Recipient;
}

export interface Recipient {
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state_code: string;
  state_name: string;
  country_code: string;
  country_name: string;
  zip: string;
  phone: string;
  email: string;
}

export interface SyncVariant {
  id: number;
  external_id: string;
  sync_product_id: number;
  name: string;
  synced: boolean;
  variant_id: number;
  warehouse_product_variant_id: string;
  retail_price: string;
  sku: string;
  currency: string;
  product: {
    variant_id: number;
    product_id: number;
    image: string;
    name: string;
  };
  files: SyncFile[];
  options: SyncOption[];
  is_ignored: boolean;
}

export const getSyncVariants = (product?: SyncProductResult): SyncVariant[] => {
  const syncVariants: SyncVariant[] = product?.sync_variants || [];
  return syncVariants;
}

export const getSyncProductResult = (product: WorkProduct | undefined): SyncProductResult | undefined => {
  if (!product) { return undefined; }
  try {
    const printfulProduct: SyncProductResult = JSON.parse(product.printfulProduct);
    return printfulProduct;
  } catch (err) {
    return undefined;
  }

}

export const getMinPrice = (product?: SyncProductResult) => {
  const syncVariants = getSyncVariants(product);
  const minPrice = Math.min(...syncVariants.map(variant => parseFloat(variant.retail_price)));
  return minPrice;
}

/**
 * Returns the most recent task
 * @param mockup 
 * @returns
 * TODO:  The BACKEND should parse!
 */
export const getTask = (mockup: Mockup) => {
  if (!mockup || !mockup.tasks || !mockup.tasks.length) { return undefined; }
  const tasks = JSON.parse(mockup.tasks);
  const task = tasks[tasks.length - 1];
  return task;
}

export const getProductIdFromWorkProduct = (workProduct: WorkProduct | undefined) => {
  const syncProductResult = getSyncProductResult(workProduct);
  const productId = syncProductResult?.sync_variants[0].product.product_id.toString();
  return productId;
}

//  Hook to get the default image of a WorkProduct
export const getDefaultImageUrl = (workProduct?: WorkProduct, syncVariant?: SyncVariant) => {

  const defaultPreviewUrl = syncVariant?.files.find(file => file.type === "preview")?.url;

  return defaultPreviewUrl;

  // const token = useCognitoToken();

  // const api = getGalleryApi(token?.getJwtToken());

  // const [mockupImage, setMockupImage] = React.useState(undefined);

  // const [loading, setLoading] = React.useState(true);

  // const productId = getProductIdFromWorkProduct(workProduct);

  // const getMockups = async () => {
  //   setLoading(true);
  //   if (!workProduct) { return }
  //   try {
  //     const _mockup = await api.mockupsControllerFindOne({ workId: workProduct.workId, productId });
  //     const lastTask = getTask(_mockup);
  //     const _mockups = lastTask?.mockups;
  //     const lastMockup = _mockups?.length ? _mockups[0] : undefined;
  //     if (lastMockup) {
  //       setMockupImage(lastMockup.mockup_url);
  //     }
  //   } catch (err) {

  //     //              Display the most recent mockup as the default image.
  //     //              Consider CACHING the default if we need to.
  //     //              FOR NOW, we can use a separate product to store the mockup differentiated options and yeah... differentiate by gender and stuff hm!
  //     //              Need a way to generate mockups and set them as the default.  Possibly even switching the WHOLE store.  Obviously we'll do that by MAPPING!

  //     //  TODO:  Differentiate the "Not Found" error from other potential errors.
  //     console.log(`An error occurred while attempting to find mockups for Product '${productId}' for work '${workProduct.workId}'.  This is probably because no mockups have been generated.`);
  //   }
  //   setLoading(false);
  // }

  // React.useEffect(() => {
  //   if (token || workProduct) {
  //     getMockups();
  //   }
  // }, [token, workProduct]);

  // return loading ? undefined : (mockupImage || defaultPreviewUrl);

}

export const getMockupExtra = (workProduct?: WorkProduct, syncVariant?: SyncVariant) => {

  const [extras, setExtras] = React.useState<any[] | undefined>(undefined);

  const [loading, setLoading] = React.useState(true);

  const productId = getProductIdFromWorkProduct(workProduct);

  const getMockups = async () => {
    setLoading(true);
    if (!workProduct) { return }
    try {
      // TODO:  Scope to WorkProduct
      const _mockup = await api.mockupsControllerFindOne({ workId: workProduct.workId, productId });
      const lastTask = getTask(_mockup);
      //  TODO:  Work on the way we store mockups... Hmm... looks like there's a default like... "primary" mockup that gets generated and then the "extra" list is in it.  Mmmmm
      const _extras = lastTask?.mockups[0].extra;
      setExtras(_extras);
    } catch (err) {
      console.log(`An error occurred while attempting to find mockups for Product '${productId}' for work '${workProduct.workId}'.  This is probably because no mockups have been generated.`);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (workProduct) {
      getMockups();
    }
  }, [workProduct]);

  return loading ? undefined : extras;
}

//  TODO:  Consider getting this from the server?  DO have an ontological hierarchy mm!

const productToOptionGroup = {

  //  TODO:  Support Option

  //  T-Shirt
  12: {
    'men': "Men's 2",
    'women': "Women's 1",
    'everyone': "Flat Lifestyle"
  },

  //  Hoodie
  380: {
    'men': "Men's Lifestyle",
    'women': "Women's",
    'everyone': "Flat"
  },

  //  Colored Mug
  403: {
    'men': "Lifestyle 2",
    'women': "Lifestyle 2",
    'everyone': "Lifestyle 2"
  },

  //  Kiss-Cut Sticker
  358: {
    'men': "Lifestyle",
    'women': "Lifestyle",
    'everyone': "Lifestyle"
  },

  //  Phone Case
  //  TODO:  Support multiple variant mockups!
  181: {
    'men': "Lifestyle",
    'women': "Lifestyle",
    'everyone': "Lifestyle"
  }
}

const productToOption = {

  //  TODO:  Support Option

  //  T-Shirt
  12: "Front",

  //  Hoodie
  380: "Front",

  //  Colored Mug
  403: "Right",

  //  Kiss-Cut Sticker
  358: "Front",

  //  Phone Case
  //  TODO:  Support multiple variant mockups!
  181: "Front"
}




//  TODO:  Support SELECTED variant
export const getPreviewUrl = (sync_variants: SyncVariant[], workProduct?: WorkProduct) => {
  const defaultMockupUrl = getDefaultImageUrl(workProduct, sync_variants[0]);
  const [gender] = useGender();
  const selectedSyncVariant = sync_variants[0];
  const productId = selectedSyncVariant?.product?.product_id;
  const genderGroup = productToOptionGroup[productId];
  const optionGroup = genderGroup ? genderGroup[gender] : undefined;
  const option = productId ? productToOption[productId] : "Front";
  const mockupExtra = getMockupExtra(workProduct, sync_variants[0]);
  console.log("Mockup Extra: " + JSON.stringify(mockupExtra))
  const genderImage = mockupExtra?.find(extra => (extra.option_group === optionGroup) && (extra.option === option))?.url;
  return genderImage || defaultMockupUrl;
}

//  ATTRIBUTION:  ChatGPT!
export function extractImageIdFromUrl(url) {
  // Split the URL into an array of path segments
  const segments = url.split('/');
  
  // Extract the filename from the last path segment
  const filename = segments[segments.length - 1];

  return filename;
}

export const deleteProduct = async (workProduct?: WorkProduct) => {
  if (!workProduct) { return }
  const res = await api.workProductsControllerRemove({ id: workProduct.syncProductId });
}

export const usdFormatter = Intl.NumberFormat("en-US", {
  style: 'currency',
  currency: 'USD',
});


//
//  Gender Filter
//

export const GenderContext = React.createContext<[string, (value: any) => void]>(['everyone', () => null]);

export const GenderProvider = ({ children }) => {
  const [gender, setGender] = React.useState('everyone');
  return (
    <GenderContext.Provider value={[gender, setGender]}>
      {children}
    </GenderContext.Provider>
  );
}

export const useGender = (): [string, (value: any) => void] => {
  const [gender, setGender] = React.useContext(GenderContext);
  return [gender, setGender];
}

export const GenderFilter = () => {

  const [gender, setGender] = useGender();

  return (
    <View style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', flex: 1, width: '100%' }}>
      <Paragraph style={{ alignSelf: 'center' }}>Gender</Paragraph>
      <View style={{ height: 30 }} />
      <GalleryButton textColor={gender === 'women' ? 'white' : '#888888'} style={{ height: 50, width: '100%', backgroundColor: gender === 'women' ? '#cccccc' : '#f4f4f4' }} emoji="👩" title="Women" onPress={() => setGender('women')} />
      <View style={{ height: 10 }} />
      <GalleryButton textColor={gender === 'men' ? 'white' : '#888888'} style={{ height: 50, width: '100%', backgroundColor: gender === 'men' ? '#cccccc' : '#f4f4f4' }} emoji="👨" title="Men" onPress={() => setGender('men')} />
      <View style={{ height: 10 }} />
      <GalleryButton textColor={gender === 'everyone' ? 'white' : '#888888'} style={{ height: 50, width: '100%', backgroundColor: gender === 'everyone' ? '#cccccc' : '#f4f4f4' }} emoji="🙂" title="Unisex" onPress={() => setGender('everyone')} />
    </View>
  );
}



//
//  Type Filter
//

export const TypeContext = React.createContext<[string[], (value: any) => void]>([['T-Shirt'], () => null]);

export const TypeProvider = ({ children }) => {
  const [typeList, setTypeList] = React.useState(['T-Shirt']);
  return (
    <TypeContext.Provider value={[typeList, setTypeList]}>
      {children}
    </TypeContext.Provider>
  );
}

export const useType = (): [string[], (value: any) => void] => {
  const [typeList, setTypeList] = React.useContext(TypeContext);
  return [typeList, setTypeList];
}

export const TypeFilter = () => {

  const [typeList, setTypeList] = useType();

  const TypeSelector = ({ type, text, emoji }: { type: string, text: string, emoji: string }) => {
    return (
      <GalleryButton textColor={isTypeSelected(type) ? 'white' : '#888888'} style={{ height: 50, width: '100%', backgroundColor: isTypeSelected(type) ? '#cccccc' : '#f4f4f4' }} emoji={ emoji } title={ text } onPress={() => toggleType(type)} />
    );
  }

  const toggleType = (type: string) => {
    const _typeList = [...typeList];
    const typeIndex = _typeList.indexOf(type);
    if (typeIndex != -1) {
      _typeList.splice(typeIndex, 1);
    } else {
      _typeList.push(type);
    }
    setTypeList(_typeList);
  }

  const isTypeSelected = (type: string) => {
    return typeList.includes(type);
  }

  const allTypesSelected = () => {
    return typeList.length === 6;
  }

  const selectAllTypes = () => {
    setTypeList(['T-Shirt', 'Hoodie', 'Mug', 'Sticker', 'Canvas', 'Phone Case'])
  }

  return (

    <View style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Paragraph style={{ alignSelf: 'center' }}>Product Types</Paragraph>
      <View style={{ height: 30 }} />
      <TypeSelector type="T-Shirt" text="T-Shirts" emoji="👕" />
      <View style={{ height: 10 }} />
      <TypeSelector type="Mug" text="Mugs" emoji="☕" />
      <View style={{ height: 10 }} />
      <TypeSelector type="Sticker" text="Stickers" emoji="😄" />
      <View style={{ height: 10 }} />
      <TypeSelector type="Hoodie" text="Hoodies" emoji="🧥" />
      <View style={{ height: 10 }} />
      <TypeSelector type="Canvas" text="Prints" emoji="🖼️" />
      <View style={{ height: 10 }} />
      <TypeSelector type="Phone Case" text="Phone Cases" emoji="📱" />
      <View style={{ height: 10 }} />
      <GalleryButton textColor={allTypesSelected() ? primaryColor : '#eeeeeee'} style={{ width: 200, backgroundColor: allTypesSelected() ? primaryColor : '#eeeeee' }} title="All" onPress={() => selectAllTypes()} />
    </View>

      
  );
}



export const MatureContext = React.createContext<[boolean, (value: any) => void]>([false, () => null]);

export const MatureProvider = ({ children }) => {
  const [mature, setMature] = React.useState(false);
  return (
    <MatureContext.Provider value={[mature, setMature]}>
      {children}
    </MatureContext.Provider>
  );
}

export const useMature = (): [boolean, (value: any) => void] => {
  return React.useContext(MatureContext);
}

export const MatureFilter = () => {

  const [mature, setMature] = useMature();

  return (
    <View style={{ marginVertical: 20 }}>
      <View style={{ height: 7 }} />
      <View style={{ flexDirection: 'row', height: 50, borderRadius: defaultBorderRadius }}>

        {/* TODO:  Use the "Visibility" icon to show with only one button =) */}

        <GalleryButton icon={mature ? { name: "visibility", type: "material" } : { name: "visibility-off", type: "material" }} textColor={!mature ? '#333333' : 'white'} style={{ width: 200, backgroundColor: mature ? primaryColor : '#eeeeee' }} title="Mature" onPress={() => setMature(!mature)} />
      </View>
    </View>
  );
}

export const mockRecipient: Recipient = {
  name: "Will Sullivan",
  company: "",
  address1: "111 Hope Street",
  address2: "1S",
  city: "Greenfield",
  state_code: "MA",
  state_name: "Massachusetts",
  country_code: "US",
  country_name: "United States",
  zip: "01301",
  phone: "",
  email: "wrsulliv@umich.edu"
};

export const useCognitoUser = (): CognitoUser | undefined | null => {
  console.log("useCognitoUser")

  const [user, setUser] = React.useState<CognitoUser | undefined | null>(undefined);

  const loadUser = async () => {
    try {
      // Amplify.configure(awsConfig);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setUser(user);
    } catch (err) {
      setUser(null);
      console.log("User not authenticated")
    }
  }

  React.useEffect(() => {
    loadUser();
  }, [])

  return user;
}


export const AttributeContext = React.createContext<undefined | CognitoUserAttribute[]>(undefined);
export const AttributeProvider = ({ children }: any) => {
  const attributes = useCognitoUserAttributes();
  return (
    <AttributeContext.Provider value={attributes}>
      {children}
    </AttributeContext.Provider>
  );
}


//  TODO:  CAN do this with GraphQL instead because there IS an association we can make hmm... even if not JSON compatible, CAN reference a thing within the JSON hmm.. it's justtt an encoding hmm... human readable AND machine hmm... 
export const useWorkProfile = (work: Work): [Profile | undefined, React.Dispatch<React.SetStateAction<Profile | undefined>>] => {

  const { owner } = work;

  const [profile, setProfile] = React.useState<Profile>();

  const token = useCognitoToken();

  const loadProfile = async () => {
    const api = getGalleryApi(token?.getJwtToken());
    const _profile = await api.profilesControllerFindOne({ username: owner || "" }) as any;
    setProfile(_profile);
  }

  React.useEffect(() => {
    if (!owner) { return; }
    loadProfile();
  }, [owner]);

  return [profile, setProfile];
}

export const getWork = (creationId: string): [Work | undefined, React.Dispatch<React.SetStateAction<Work | undefined>>] => {

  const [work, setWork] = React.useState<Work | undefined>(undefined);

  const loadWork = async () => {
    const _works = await api.worksControllerFindAll();
    const work = _works.find(work => work.id === creationId);
    setWork(work);
  }

  React.useEffect(() => {
    setWork(undefined);
    loadWork();
  }, [creationId]);

  return [(work?.id === creationId) ? work : undefined, setWork];
}

//  CONSIDER:  With somethin glike Redux we do have global state, but I don't want to be pulling it out each timea nd idk... 
//  NOTE:  CAN perhaps have these listed HIGH in the tree, and we can use CONTEXT to obtain them hmm... MAYBE use Redux? hm...  This way we don't keep re-fetching.
//  CONSIDER:  Using this may impact ALL consumers of this?? Hmm....  Want to be abnle to use it for multiple specific profiles though hmm..
export const useProfile = (): [Profile | undefined, React.Dispatch<React.SetStateAction<Profile | undefined>>] => {

  const token = useCognitoToken();
  const user = useCognitoUser();
  const [profile, setProfile] = React.useState<Profile>();

  const loadProfile = async () => {

    const api = getGalleryApi(token?.getJwtToken());
    try {
      const _profile = await api.profilesControllerFindOne({ username: user?.getUsername() || "" }) as any;
      setProfile(_profile);
    } catch (err) {
      // alert("PROFILE ERROR: " + err);
    }
  }

  React.useEffect(() => {
    if (!user) { return; }
    loadProfile();
  }, [user]);

  return [profile, setProfile];
}

export const isAnonymous = (): boolean => {
  const user = useCognitoUser();
  return user ? user.getUsername().startsWith("session_") : true;
}

export const useDisplayName = (): string | undefined => {

  //  TODO:  AS we're calling these thigns we SHOULD be able to CACHE!

  const [profile] = useProfile();
  const anonnymous = isAnonymous();
  if (anonnymous) { return undefined; }
  if (profile?.displayName) { return profile?.displayName }
  return "Unknown";

}

export const getProfile = (userId: string): [Profile | undefined, React.Dispatch<React.SetStateAction<Profile | undefined>>] => {

  const [profile, setProfile] = React.useState<Profile>();

  const loadProfile = async () => {

    try {
      const _profile = await api.profilesControllerFindOne({ username: userId || "" }) as any;
      setProfile(_profile);
    } catch (err) {
      // alert("PROFILE ERROR: " + err.message);
      console.warn("Profile Error: " + err.message);
    }
  }

  React.useEffect(() => {
    if (!!userId) {
      loadProfile();
    }
  }, []);

  React.useEffect(() => {
    if (!!userId) {
      loadProfile();
    }
  }, [userId]);

  return [profile, setProfile];
}

//  CONSIDER:  Instead of makine separate contexts for lots of structures, perhaps it's time to invest in something like Redux?  Though, the separate slices does de-couple things.
export const ProfileContext = React.createContext<[Profile | undefined, React.Dispatch<React.SetStateAction<Profile | undefined>>]>([undefined, () => null]);
export const ProfileProvider = ({ children }: any) => {
  const [profile, setProfile] = useProfile();
  return (
    <ProfileContext.Provider value={[profile, setProfile]}>
      {children}
    </ProfileContext.Provider>
  );
}

export const useCognitoUserAttributes = (): CognitoUserAttribute[] | undefined => {

  console.log("useCognitoUserAttributes")

  const [attributes, setAttributes] = React.useState<CognitoUserAttribute[] | undefined>(undefined);

  const user = useCognitoUser();

  const loadAttributes = async () => {
    try {
      // Amplify.configure(awsConfig);
      const attributes = await Auth.userAttributes(user);
      setAttributes(attributes);
    } catch (err) {
      setAttributes([])
      console.log("Error retrieving userAttributes.")
    }
  }

  React.useEffect(() => {
    if (user) {
      loadAttributes();
    }
  }, [user])

  return attributes;
}


export const useCognitoSession = (): CognitoUserSession | undefined | null => {

  console.log("useCognitoSession")

  const [session, setSession] = React.useState<CognitoUserSession | undefined | null>(undefined);
  const user = useCognitoUser();

  const loadSession = async () => {
    try {
      // Amplify.configure(awsConfig);
      const session = await new Promise<CognitoUserSession | undefined>((resolve, reject) => {
        user?.getSession((error, session: CognitoUserSession) => {
          resolve(session);
        })
      });
      setSession(session || null);
    } catch (err) {
      console.log("Session not available.")
      setSession(null);
    }
  }

  React.useEffect(() => {
    if (user === null) { setSession(null); }
    if (user === undefined) { setSession(undefined); }
    loadSession();
  }, [user]);

  return session;
}


export const useCognitoToken = (): CognitoAccessToken | undefined | null => {
  console.log("useCognitoToken")
  const session = useCognitoSession();
  console.log(JSON.stringify(session));
  if (session === undefined) { return undefined; }
  if (session === null) { return null; }
  const token = session.getAccessToken();
  console.log("Token: " + token)
  return token;
}

export const useCognitoGroups = (): string[] | undefined | null => {
  console.log("useCognitoGroups")
  const token = useCognitoToken();
  if (token === null) { return null; }
  if (token === undefined) { return undefined; }
  const payload: any = token.decodePayload();
  const groups = payload.hasOwnProperty("cognito:groups") ? payload["cognito:groups"] : [];
  return groups;
}

export const isAdmin = (): boolean | undefined | null => {
  console.log("isAdmin")
  const groups = useCognitoGroups();
  return !!groups?.includes("Admin");
}

// export const useGalleryApi = () => {
//   const token = useCognitoToken();

//   if (token === undefined) { return undefined; }

//  ;
// }

export const getGalleryApi = (jwtToken?: string) => {
  const configParams: ConfigurationParameters = {
    basePath: basApiHost,
    accessToken: jwtToken,
    credentials: 'include'
  };
  const apiConfig = new Configuration(configParams);
  const api = new DefaultApi(apiConfig);
  return api
}


//  CONSIDER:  This is a function that's separate from things, BUT I still want to be able to pull from React state... It's frusterating, because in React the ONLY way is to re-render the functiona dn the FRAMEWORK injects hmmm...   We also don't see WHICH thigns a thing depends on ugh!
//  TODO:  Pass username?  Only Admins can create others hmm...
//  TODO:  Update to "Update" instead of Create? Hmm...  

export const updateProfile = async (profile: Partial<Profile>, token?: CognitoAccessToken | null) => {
  const api = getGalleryApi(token?.getJwtToken());
  const updatedProfile = await api.profilesControllerUpdate({
    profile: JSON.stringify(profile)
  });
  return updatedProfile;
}

export const saveWorkProduct = async (workProduct: Partial<WorkProduct>, token?: CognitoAccessToken | null) => {
  const api = getGalleryApi(token?.getJwtToken());
  const updatedWorkProduct = await api.workProductsControllerUpdate({
    id: workProduct.syncProductId as any,
    body: workProduct
  });
  return updatedWorkProduct;
}


//  REFERENCE:  https://github.com/expo/examples/blob/master/with-aws-storage-upload/App.js
export const fetchImageFromUri = async (uri) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

export const updateAvatar = async (imageUrl: string, token: CognitoAccessToken) => {

  const api = getGalleryApi(token?.getJwtToken());

  const blob = await fetchImageFromUri(imageUrl);

  const _profile = await api.profilesControllerUpdate({
    file: blob
  });

  return _profile;
}

//  CONSIDER:  Pluggable components, additional components, layers, etc.

const artistDetailRouteTransformer = (route) => {
  const artistId = route.params.userId;
  const [profile, setProfile] = getProfile(artistId);
  return profile?.displayName || profile?.username;
}

const workDetailRouteTransformer = (route) => {
  const creationId = route.params.creationId;
  const [work, setWork] = getWork(creationId);
  return work?.title || work?.id;
}

const defaultOptions = {
  transformHook: (route) => {
    if (route.name === "Dashboard") { return "Home" }
    if (route.name === "ArtistDetail") { return artistDetailRouteTransformer(route); }
    if (route.name === "WorkDetail") { return workDetailRouteTransformer(route); }
    return route.name;
  }
}


export interface BreadCrumbOptions {
  //  NOTE:  The transform Hook is a react Hook that will update this component when we have the name.
  transformHook: (route) => string | undefined;
}

const Crumb = ({ state, options, index, list, states }: { state: any, options: BreadCrumbOptions, index: number, list: any[], states: any[] }) => {


  const routeName = state.route.name;
  const updatedRouteName = options?.transformHook ? options.transformHook(state.route) : routeName;
  const lastElementIndex = list.length - 1;
  const isLastElement = index === lastElementIndex;

  const navigation = useNavigation();

  return (
    <>
      <Pressable onPress={() => navigation.navigate(routeName as never, states[index].params as never)}>
        {updatedRouteName ? <Paragraph style={{ color: '#777777' }}>{updatedRouteName}</Paragraph> : <ActivityIndicator color={primaryColor} />}
      </Pressable>
      {
        !isLastElement && (
          <Icon type="material" name="chevron-right" size={20} color="#777777" style={{ marginHorizontal: 7 }} />
        )

      }
    </>

  );
}

interface State {
  key: string;
  index: number;
  routeNames: never[];
  history?: unknown[] | undefined;
  routes: any[];
  type: string;
  stale: false;
}
export const Breadcrumbs = ({ options = defaultOptions, style }: { options?: BreadCrumbOptions, style?: ViewStyle }) => {

  const navigation = useNavigation();
  return (
    <View style={[{ flexDirection: 'row', alignItems: 'center', borderRadius: defaultBorderRadius }, style]}>
      <Pressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => navigation.goBack()}>
        <Icon type="material" name="chevron-left" size={20} color="#777777" />
        <View style={{ width: 2 }} />
        <Paragraph style={{ color: '#777777' }}>Back</Paragraph>
      </Pressable>
    </View>
  );

  // return <

  // const navigationStates: State[] = [navigation.getState()];
  // const parent = navigation.getParent();
  // alert(JSON.stringify(navigation.getParent()))
  // while (navigation.getParent() && navigation.getParent()?.getState() && navigation.getParent()?.getState().key != undefined) {
  //   const parent = navigation.getParent();
  //   const parentState = parent?.getState();
  //   navigationStates.push(parentState as State);
  // }

  // alert(JSON.stringify(navigationStates.length))
  // return (
  //   <View style={[{ flexDirection: 'row', alignItems: 'center', borderRadius: defaultBorderRadius }, style]}>
  //     {
  //       navigationStates.reverse().map((state, index, list) => {
  //         return <Crumb state={state} index={index} list={list} states={navigationStates} options={options} />
  //       })
  //     }
  //   </View>
  // );
}