import { useNavigation } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Button, Text, TextInput, View, ScrollView } from 'react-native';
import { medSpacer, smallSpacer } from '../../packages/kelp-bar/styles';
import { HaborNounService, NounServiceInstanceInternal } from '../../packages/noun-service/noun-service';
import { GalleryButton } from '../gallery/components/common';
import { AttachmentPlugin } from './attachment-plugin/attachment-plugin';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { Entity, EntityPlugin } from './entity-plugin';
import { Deferred, renderEntityListItem } from './entity-plugin/entity-list';
import { haborSDK } from './hessia-plugin/config';
import { WidgetItem } from './hessia-plugin/dynamic-component';
import { SimpleBox } from './page-plugin/page-widget';
import { StringPlugin } from './string-plugin';
import { Symbol, SymbolPlugin, SymbolWidget } from './symbol-plugin/symbol-plugin';
import { SystemPlugin } from './system-plugin/system-plugin';

//  CONSIDER:  We COULD just use Symbol and another system like ... aprent or scope to put them on the fucking entity? Hmmmmm.... interesitng.. isntead of this particular property system ... hmmmm... I THINK I like taht ugh.  I THINK what I'll do is use a SYMBOL and a fucking link to an entity hm!

//  TODO:  Consider using hte STRING system for the fuckin symbol key!!!
//  TODO:  Consider making this identifiable and named?
export interface Property {
  targetEntityId: string;
  targetSymbolId: string;
}

export interface PropertyRealization {
  entity: NounServiceInstanceInternal<Entity>;
  symbol?: {
    name: string;
    destinationEntity?: NounServiceInstanceInternal<Entity>;
  }
}

export const PropertyEditor = ({ navigation, route, propertiesPlugin, symbolPlugin, entityPlugin, onChange }: { onChange?: (propertyInternal: NounServiceInstanceInternal<Property>) => void, entityPlugin: EntityPlugin, navigation: any, route: { params: { propertyInternal: NounServiceInstanceInternal<Property> | undefined, propertyPartial: Partial<Property> | undefined } }, propertiesPlugin: PropertiesPlugin, symbolPlugin: SymbolPlugin }) => {



  //  We have PROPERTY INTERNAL which CAN be passed here. hmm... OR we can have a partial.  IF we pass the internal, THEN it's clear we're UPDATING it and shit.  The POINT is.. IF we change the UNDERLYING, then it's FINE!  The property remains the same!  

  const { propertyInternal: propertyInternalInitial, propertyPartial } = route?.params;

  const [propertyInternal, setPropertyInternal] = React.useState<NounServiceInstanceInternal<Property> | undefined>(propertyInternalInitial);

  const initialProperty: Property = {
    targetEntityId: propertyInternal?.payload?.targetEntityId || propertyPartial?.targetEntityId || "",
    targetSymbolId: propertyInternal?.payload?.targetSymbolId || propertyPartial?.targetSymbolId || ""
  };

  const [property, setProperty] = React.useState<Property>(initialProperty);


  //  CONSIDER:  WHat happens if we don't COMPLETE hm!  The idea is it's up to THIS system to DEStROY that pattern hm!  MAYBE we can wait until fully ready? Hm!
  const [symbolInternal, setSymbolInternal] = React.useState<NounServiceInstanceInternal<Symbol> | undefined>(undefined);

  const loadSymbol = async () => {
    const _symbolInternal = await symbolPlugin.symbolService.retrieve(initialProperty.targetSymbolId);
    setSymbolInternal(_symbolInternal)
  }

  React.useEffect(() => {
    //  CONSIDER:  THIS can be set up as a reaction? Hmm...
    if (initialProperty.targetSymbolId) {
      loadSymbol();
    }
  }, []);

  return (
    <View style={{ padding: medSpacer }}>

      <SimpleBox title='Property ID' content={
        <Text>{propertyInternal?.id}</Text>
      } />

      <SimpleBox title='Entity ID' content={
        <TextInput value={property?.targetEntityId} onChangeText={value => setProperty({ ...property, targetEntityId: value })} />
      } />

      <View style={{ height: medSpacer }} />

      <SimpleBox title='Symbol' content={
        <SymbolWidget entityPlugin={entityPlugin} onChange={(_symbolInternal) => { setSymbolInternal(_symbolInternal); setProperty({ ...property, targetSymbolId: _symbolInternal.id }) }} symbolInternal={symbolInternal} symbolPlugin={symbolPlugin} />
      } />

      <View style={{ height: medSpacer }} />

      {/* TODO:  Show the MINI entity widget thing hm! */}

      {/* TODO:  Update the Property Internal thing! */}
      <Button title={propertyInternal ? 'Update Property' : 'Create Property'} onPress={propertyInternal ? async () => { const _propertyInternal = await propertiesPlugin.updateProperty(propertyInternal.id, property); setPropertyInternal(_propertyInternal) } : async () => { const _propertyInternal = await propertiesPlugin.createProperty(property); setPropertyInternal(_propertyInternal) }} />
      {/* <Button title="Delete" onPress={ () => stringPlugin.deleteString(string.id) } /> */}
    </View>
  );
}

export const PropertyView = ({ propertiesPlugin, symbolPlugin, entityPlugin, property }: { propertiesPlugin: PropertiesPlugin, symbolPlugin: SymbolPlugin, entityPlugin: EntityPlugin, property: NounServiceInstanceInternal<Property> }) => {

  const [symbolInternal, setSymbolInternal] = React.useState<NounServiceInstanceInternal<Symbol> | undefined>(undefined);
  const [targetEntity, setTargetEntity] = React.useState<NounServiceInstanceInternal<Entity> | undefined>(undefined);

  const loadSymbol = async () => {
    console.log("LOADING SYMBOL");
    const _symbolInternal = await symbolPlugin.symbolService.retrieve(property.payload.targetSymbolId);
    setSymbolInternal(_symbolInternal)
    if (_symbolInternal?.payload.destEntityId) {
      const _targetEntity = await entityPlugin.entityService.retrieve(property.payload.targetEntityId);
      setTargetEntity(_targetEntity);
    }
  }

  React.useEffect(() => {
    if (property.payload.targetSymbolId) {
      loadSymbol();
    }
  }, []);

  return (
    <View style={{ padding: medSpacer }}>


      {/* NOTE:  Effectively, we have a thing that has TWO other things associated with it.  THIS is a common ontological strucutre AND AH!!! THe idea is we can display UI based on this ontological strucutre which we can encode and display in MULTIPLE ways hm!  SOme even custom.  IT's about communication and signaling hm... even wanted to do this with aggregate trackers hmm */}
      <SimpleBox title='Entity' content={
        <>
          {
            targetEntity ?
              <Deferred func={renderEntityListItem} args={{ targetEntity, entityPlugin }} def={<Text>Loading...</Text>} /> :
              <Text>Loading Entity: {property.payload.targetEntityId}</Text>
          }
        </>
      } />

      <View style={{ height: medSpacer }} />

      <SimpleBox title='Symbol' content={
        symbolInternal ?
          <SymbolWidget entityPlugin={entityPlugin} symbolInternal={symbolInternal} symbolPlugin={symbolPlugin} /> :
          property.payload.targetSymbolId ?
            <Text>Loading Symbol: {property.payload.targetSymbolId}</Text> :
            <Text>Missing Symbol</Text>

      } />

      <View style={{ height: medSpacer }} />

      {/* TODO:  Show the MINI entity widget thing hm! */}

      {/* TODO:  Update the Property Internal thing! */}
      {/* <Button title={propertyInternal ? 'Update Property' : 'Create Property'} onPress={propertyInternal ? async () => { await propertiesPlugin.updateProperty(propertyInternal.id, property); } : async () => { await propertiesPlugin.createProperty(property); }} /> */}
      {/* <Button title="Delete" onPress={ () => stringPlugin.deleteString(string.id) } /> */}
    </View>
  );
}




// CONSDIER:  Instead of mapping expliclty like this I'd REALLY like to map CONTEXTUALLY and dynamically so ike.. we can PUT something in a CONTEXT hm!  It's like a SPACE HM!  THEN these things are automtiacally available hm !  IT's just a n encoding , but the idea is, those SYMBOLS then have MEANING.. they are CONNECTED HM!
export const PropertyList = ({ propertiesPlugin, symbolPlugin, entityPlugin }: { propertiesPlugin: PropertiesPlugin, symbolPlugin: SymbolPlugin, entityPlugin: EntityPlugin }) => {


  // const entity = route?.params?.entity;

  // const [string, setString] = React.useState(stringObj?.payload.value || "");

  const [properties, setProperties] = React.useState<NounServiceInstanceInternal<Property>[]>([]);

  const loadProperties = async () => {
    //  CONSIDER:  AGAIN... this is a CONTEXT where all properties COULD be EXPLICITLY visible hm!  THe idea is to be able to access that list via a symbol and NOT have to deal with ALL this code hM!  IT's just a way to access a thing WITHOUT constraitns hM!  PLUS.. its VIEW is dependnet upon the constraints hm!! I THINK I like that hm!!!!  EVERYTHING can work this way?? HM!
    const _properties = await propertiesPlugin.propertyService.retrieveAll()
    setProperties(_properties);
  }
  React.useEffect(() => {
    loadProperties();
  });

  //  CONSIDER:  Properties REQUIRES an Entity target.  MEANING, it's coupled with the ENTITY system hm!  BUT the others are not?? HM!  BUT what if we want a proeprty on one of THEIR IDs hmm... need another system or differnetiate, this is the generalization hm.. I'm thinking WHY not BUILD IN the entity support.. so we DO have a global entity list hm!  By DEFAULT... this way we don't need ALL this BS hmm... it's going to be a system everything will use, and hmm.. but what about IDs and collisions?  I THINK it might be easier to work on that when we get to ti?Hm.. but a LOT of systems won't already be integrate.. ok, I THINK we're OK as is hmm...
  return (
    <ScrollView style={{ padding: medSpacer }}>
      {
        properties.map(prop => (
          <>
            <SimpleBox title={prop.id} content={
              <PropertyView entityPlugin={entityPlugin} property={prop} symbolPlugin={symbolPlugin} propertiesPlugin={propertiesPlugin} />
            } />
            <View style={{ height: medSpacer }} />
          </>
        )
        )
      }
    </ScrollView>
  );
}


//  TODO:  Instead of explicitly building a UI like this.. use the Auto-UI tools to build one dynamically.
//         Like... using KNOWN ontological strucutre like Title and stuff hmmm... that's KINDA still a component though.. just very abstracted hmm.. BUT things like yeah.. recursively building out the UI SOME of it WILL be due to types and shit but I was doing that in TM too and Autone ...
export const PropertiesList = ({ propertiesPlugin, entity, entityPlugin }: { propertiesPlugin: PropertiesPlugin, entity: NounServiceInstanceInternal<Entity>, entityPlugin: EntityPlugin }) => {

  const [properties, setProperties] = React.useState<PropertyRealization[]>([]);

  const loadProperties = async () => {
    const _properties = await propertiesPlugin.loadProperties(entity);
    setProperties(_properties);
  }
  React.useEffect(() => {
    loadProperties();
  }, [])

  return (
    <>
      {
        properties.map(prop => (
          <>
            <SimpleBox title='Property' content={(
              <>
                {!prop.symbol && <Text>Missing Symboll</Text>}
                <Text>{prop.symbol?.name}</Text>
                <Deferred func={renderEntityListItem} args={{ entity: prop.symbol?.destinationEntity, entityPlugin }} def={<Text>Loading...</Text>} />
              </>
            )} />
            <View style={{ height: smallSpacer }} />
          </>
        ))
      }
      {
        !properties.length && <Text>No Properties</Text>
      }
    </>
  );
}

// CONSIDER:  It would be VERY nice if we could navigate with "Entities" as a dot operator thing and services as the propetie hsm!  I THINK I like that hm!  This way it's easy for us in primtiive code AND user-land to navigate the ontological strucutre hm...
const Stack = createStackNavigator();

export const PropertiesNavigator = ({ propertiesPlugin, symbolPlugin, entityPlugin }: { entityPlugin: EntityPlugin, propertiesPlugin: PropertiesPlugin, symbolPlugin: SymbolPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      <Stack.Screen name="Editor" component={({ route, navigation }) => <PropertyEditor entityPlugin={entityPlugin} route={route} propertiesPlugin={propertiesPlugin} navigation={navigation} symbolPlugin={symbolPlugin} />} />
      <Stack.Screen name="List" component={() => <PropertyList entityPlugin={entityPlugin} propertiesPlugin={propertiesPlugin} symbolPlugin={symbolPlugin} />} />
    </Stack.Navigator>
  );
}

//  CONSIDER:  I don't like that I even need to repeat THIS in raw-code.. WOULD prefer a normalized representation? Hmm.. still need a way to map tothe properties thoghu??? .. to the ONTOLOGY??s ajdf 
export class PropertiesPlugin extends CorePluginClass {

  public static details = {
    name: 'Properties Plugin',
    description: 'Provides Entity Properties',
    dependencies: [EntityPlugin.details.id, AttachmentPlugin.details.id, StringPlugin.details.id, SystemPlugin.details.id, AuthPlugin.details.id, SymbolPlugin.details.id],
    id: 'propertiesPlugin'
  }

  public attachmentPlugin!: AttachmentPlugin;
  public stringPlugin!: StringPlugin;

  public propertyService = new HaborNounService<Property>("properties", haborSDK);

  public loadProperties = async (entity: NounServiceInstanceInternal<Entity>) => {
    //  TODO:  Build SEARCH in the service!... Seriously we need this!
    //  TODO:  Instead of doing this manuglly build a system to resolve the steps in the onotlogy based on the injected system component thingsl kasjflkds  
    const properties = await this.propertyService.retrieveAll()
    const entityProperties = properties.filter(prop => prop.payload.targetEntityId === entity.id);

    const entityPropertiesExpanded: PropertyRealization[] = [];
    for (const prop of entityProperties) {

      const targetSymbol = await this.symbolPlugin.symbolService.retrieve(prop.payload.targetSymbolId);

      if (targetSymbol) {
        const destinationEntity = await this.entityPlugin.entityService.retrieve(targetSymbol?.payload.destEntityId);
        if (destinationEntity) {
          entityPropertiesExpanded.push({
            entity, symbol: {
              name: targetSymbol.payload.name,
              destinationEntity
            }
          })
        } else {
          entityPropertiesExpanded.push({
            entity, symbol: {
              name: targetSymbol.payload.name,
              destinationEntity: undefined
            }
          })
        }

      } else {
        entityPropertiesExpanded.push({
          entity, symbol: undefined
        })
      }

    }
    return entityPropertiesExpanded;
  }

  public createProperty = async (property: Property) => {
    //  TODO:  Support ENTITY integration here!
    const propertyInternal = await this.propertyService.create(property);
    return propertyInternal;
  }

  public updateProperty = async (id: string, property: Property) => {
    const propertyInternal = await this.propertyService.update(id, property);
    return propertyInternal;
  }

  public symbolPlugin!: SymbolPlugin;
  public entityPlugin!: EntityPlugin;

  public install = async (program: Program, { pEntities, system, authPlugin, symbolPlugin }: { pEntities: EntityPlugin, system: SystemPlugin, authPlugin: AuthPlugin, symbolPlugin: SymbolPlugin }) => {

    this.entityPlugin = pEntities;
    this.symbolPlugin = symbolPlugin;

    await this.propertyService.init(authPlugin.token);

    system.registerPrimitiveSystem({
      name: "Properties",
      description: "Entity Properties",
      id: "propertiesPlugin",
      menuItem: {
        icon: { name: "format-list-bulleted", type: "material" },
        backgroundColor: "#7260eb",
        iconColor: "white"
      },
      headerRight: () => {
        const navigation = useNavigation();
        return (
          <Button onPress={() => navigation.navigate("propertiesPlugin", { screen: "Editor" })} title="Create"></Button>
        );
      },
      labels: [],
      component: () => <PropertiesNavigator entityPlugin={pEntities} propertiesPlugin={this} symbolPlugin={symbolPlugin} />
    })

    pEntities.registerEntityDetailView({
      name: "Properties Service",
      description: "Manage Entity Properties",
      id: "property-service",
      component: ({ entity }) => {
        const navigation = useNavigation();
        {/* TODO:  Still hatee that we're passing things like these plugins.. I believe the functions we're calling can be BOUDN to those contexts? Hmm the fact that we're CALLING them means that we have access to that context too?? Hmm  Somthing like that! */ }
        return (
          <WidgetItem name="Properties" color="#eeeeee">
            <PropertiesList entityPlugin={pEntities} propertiesPlugin={this} entity={entity} />
            <View style={{ height: 7 }} />
            <GalleryButton title="Mangage" onPress={() => navigation.navigate("propertiesPlugin" as any, { screen: "Editor", params: { propertyPartial: { targetEntityId: entity.id, targetSymbolId: undefined } } })} />
          </WidgetItem>
        );

      }
    })
    return this;
  }
}