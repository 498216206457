import { createStackNavigator } from '@react-navigation/stack';
import { EntityType, InstanceInternal, NounId, NounInternal, Plugin } from 'habor-sdk';
import * as React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { AppHeader } from '../../../packages/kelp-bar/app-header';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { AppContext } from '../hessia-plugin/AppContext';
import { haborSDK } from '../hessia-plugin/config';
import { EntityEditor } from '../model-plugin/entity/entity-editor';
import { InstanceCreator } from '../model-plugin/entity/instance-creator';
import { InstanceList } from '../model-plugin/entity/instance-list';
import { HaborModelSDK, ModelSDK } from '../model-plugin/model-sdk';
import { HAppRoute } from './apps.nav.routes';
import { AppListScreen } from './screens/app-list.screen';
import { AppViewer } from './screens/app-viewer.screen';

//  TODO:  We have TWO entity concepts... move them together... and CONSIDER making it so the MAIN entity system is where a lot of stuff is displayed.. but AGAIN.. an entity in a CONTEXT can have a DIFFERENT UI HM!  JUST like what I was doing in TrackMine where I had things being displayed based on the sub-type and like... yeah hmm....

export type SystemStackParamList = {
  [HAppRoute.SystemList]: undefined;
  [HAppRoute.SystemBuilder]: undefined;
  [HAppRoute.ElementMenu]: undefined;
  [HAppRoute.EntityBuilder]: {
    noun?: NounInternal;
  };
  [HAppRoute.SystemConfig]: {
    plugin?: InstanceInternal<Plugin>
    pluginId?: string;
    showConfig?: boolean;
  };
  //  TODO:  This is part of the "Entity" system!!!
  [HAppRoute.InstanceList]: {
    noun: NounInternal
  };
  [HAppRoute.InstanceBuilder]: {
    noun: NounInternal
    instance?: InstanceInternal;
    systemEditMode?: boolean;
  };
};

const Stack = createStackNavigator();


//  NOTE:  This is more of the USER system thing! HM!

export function AppsNavigator() {

  // TODO:  The "Menu" should be a USER PLUGIN and dynamically added, maybe in its own special section?  Maybe it should show in "Systems"? s Not a primitive Plugin.  FOR NOW, we use Primitive Plugins to make the developers life more manageable, we use User Plugins to give users control of THEIR system
  return (
    <Stack.Navigator screenOptions={{ animationEnabled: true , headerShown: false}} initialRouteName={HAppRoute.SystemList}>
      <Stack.Screen children={
        ({ navigation, route }) => {
          return <AppListScreen navigation={navigation} />
        }
      } name={HAppRoute.SystemList} />

      <Stack.Screen children={
        ({ route, navigation }) => {
          const elem = (route?.params as any).elem;
          //  CONSIDER: I THINK I'd like to handle the display of this detail card with an EVENT.  This way the responder is de-coupled? HMmm  MEANING, we have a REGISTRAtiON POINT that the systems can inject to for this event.  Then there's only ONE way coupling, instead of two? HM!  "Coupling" just meaning knowledge / responsibility!?
          return (
            <Text>Shuold be detail modal!</Text>
            // <DetailModal item={elem} />
          )
        }
      } name={HAppRoute.ElementMenu} />

      <Stack.Screen name={HAppRoute.SystemConfig} component={
        ({ navigation, route }) => {

          // const SystemConfigWrapper = () => {
          const plugin = (route?.params as any).plugin;
          const showConfig = (route?.params as any).showConfig || false;
          const pluginId = (route?.params as any).pluginId;
          return <AppViewer showConfig={showConfig} pluginId={pluginId} navigation={navigation} plugin={plugin} />
          // }
          // return <SystemConfigWrapper />;
        }} />

      {/* NOTE:  We MAY want to pull this up for use in Workspace too??  I think I MAY want a StackNavigator AND a DrawerNavigator for the Space (Workspace)??? */}
      <Stack.Screen name={HAppRoute.InstanceList} children={
        //  CONSIDER:  Standardize a place for this route and routes in general???  Again... Hessia is ONE Habor App... I'd like to use a similar pattern here that we use in the User "Experiences" / "Apps".
        //  TODO:  Pass a FULL context with info from ALL other systems that were involved in this decision?  The idea is, this is ONE Experience and it can be navigated to from ANY dependent system!  PERHAPS it also has a RETURN setting, or a NEXT setting, etc... MAYBE it's part of a process and just a STEP within a set of blocks??? Huh!!!  I THINK I like that!  What if the user stops the app mid-way through?  Hmm... We SHOULD be able to handle it?  OR, maybe the user's blocks CAN if they want?  BUT, I think there should be a DEFAULT behacior!  AHH!!!  I'm starting to feel a LOT clearer on this!?
        ({ navigation, route }) => {
          const noun: NounInternal = (route?.params as any).noun;
          const context = React.useContext(AppContext);
          //  TODO:  We DON'T always need this FULL Entity ID thing.. USUALLY CONTEXT is ENOUGH!  I suppose we only need to store whaat we can't somehow get through context!?  Hmmm... Or, if we don't WANT to get through context?? Hmm...
          return <InstanceList nounId={{ nounId: noun.id, type: EntityType.Noun }} />
        }
      } />
      <Stack.Screen name={HAppRoute.InstanceBuilder} children={
        ({ navigation, route }) => {
          const context = React.useContext(AppContext);
          const skipOverrides: boolean = (route?.params as any)?.skipOverrides;
          const systemEditMode: boolean = (route?.params as any)?.systemEditMode;
          const noun: NounInternal = (route?.params as any)?.noun;
          const instance: InstanceInternal = (route?.params as any)?.instance;
          //  TODO:  Rename to "InstanceEditor" for BOTH create / edit instead of "InstanceCreator"?  OR perhaps "InstanceBuilder"???
          const nounId: NounId = { type: EntityType.Noun, nounId: noun ? noun.id : instance.nounId };
          const modelSDK = new HaborModelSDK(haborSDK, context.frameworkContext.token);
          return (
            // <Text>Shuld be HApp Instance Creator!</Text>
            <InstanceCreator modelSDK={modelSDK} skipOverrides={skipOverrides} systemEditMode={systemEditMode} nounId={nounId} instanceId={instance ? { type: EntityType.Instance, nounId: instance.nounId, instanceId: instance.id } : undefined} />
          );
        }
      } />
      <Stack.Screen name={HAppRoute.EntityBuilder} children={
        ({ navigation, route }) => {
          const context = React.useContext(AppContext);
          const noun: NounInternal = (route?.params as any)?.noun;
          const nounId: NounId | undefined = noun && { type: EntityType.Noun, nounId: noun.id };
          const modelSDK = new HaborModelSDK(haborSDK, context.frameworkContext.token);
          return (
            <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <AppHeader title={noun ? `Edit ${noun.name}` : "Create Entity"} />
              <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#f7f7f7' }} contentContainerStyle={{ padding: medSpacer }}>
                <EntityEditor modelSDK={modelSDK} navigation={navigation} nounId={nounId} />
              </ScrollView>
            </View>
          );
        }
      } />
      {/* <Stack.Screen name={ SystemRoute.SystemBuilder } component={ ({ route }) => <WorkspaceSettings />  } /> */}

      {/* CONSIDER:  Make a NEW Navigator for Block / Component systems, MAYBE a bunch of "Experiences" / "Segues", etc?? /*}
      {/* CONSIDER:  COLLAPSING the Space and System navigators once (if) the concepts are merged in Habor! */}

      {/* <Stack.Screen name={ SystemRoute.SystemBuilder } component={ ({ route }) => <WorkspaceSettings />  } /> */}

    </Stack.Navigator>
  );
}