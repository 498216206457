
import { TokenSDK } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { haborSDK } from "../../hessia-plugin/config";
import { HessiaPlugin } from '../../hessia-plugin';
import { accessAddon, AccessAddonHandlerHaborComponent, AccessAddonHandlerHaborComponentPrimitive } from "./access-addon";
import { ModelPlugin } from "../../model-plugin";

//  TODO:  Centralize SDK Initialization
const tokenSDK = new TokenSDK(haborSDK);

//  TODO:  This is effectively a "part" of the "Entities" system.  So... why don't ahh!!  The PLUGIN placement complexity can be JUST as complex as the other systems hm!
export class AccessPlugin extends CorePluginClass {
  public static details = {
    name: "Access",
    description: "Hessia Access Plugin",
    dependencies: [HessiaPlugin.details.id, ModelPlugin.details.id],
    id: "access"
  }

  public getToken = async (username: string, password: string) => {

    try {
      const tokenInst = await tokenSDK.create({ username, password });
      return tokenInst.payload.token;
    } catch(err) {
      throw `Failed to obtain the token with the following error: ${ JSON.stringify(err) }`
    }
  }

  //  TODO:  Do something like Nest.js where we can get the import with a parameter decorator?
  //  TODO:  Generalie errr hndling for plugins with a decratorr or somethign to report the plugin name? HM!  I's JUST a symbol with stuff associated with it, and we INJECT!
  public install = (program: Program, { hessia, modelPlugin }: { hessia: HessiaPlugin, modelPlugin: ModelPlugin }) => {
    hessia.registerPrimitiveComponent('AccessAddonHandlerHaborComponentPrimitive', AccessAddonHandlerHaborComponentPrimitive)
    hessia.registerComponent(AccessAddonHandlerHaborComponent);
    modelPlugin.registerAddonHandler(accessAddon);
    return this;
  }
}
