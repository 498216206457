import { StackNavigationProp } from '@react-navigation/stack';
import { InstanceInternal, NounInternal, UserCredentialProps, Workspace, workspaceNoun } from 'habor-sdk';
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View, ViewProps } from 'react-native';
import { AppContext } from '../hessia-plugin/AppContext';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { haborSDK } from '../hessia-plugin/config';
import { RouteComponentProps, withRouter } from '../../../packages/isomorphic-router/react-router';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { HessiaThemePart, registerThemeMapper } from '../../../packages/injector/injector';
import { ContainerListSimple } from '../model-plugins/named-object-plugin/named-object-list-simple';
import { spaceEmitter, SpaceReactContext, SPACE_CHANGE_EVENT } from './space-plugin';
import { Icon } from 'react-native-elements/dist/icons/Icon';
const Color = require('color');


//  Here we map from the theme to the props in a STATIC way, BUT in the future I REALLY want to be able to attach MULTIPLE functions like this as COUPLING systems!  That means, between the Theme and THIS component symbol!?  Hmm... registered as a function in the style system? Hm!
const hessiaThemeMapper = (part: HessiaThemePart) => {  //  NOTE:  The TYPE of the theme is DYNAMIC and determined by enabled systems!  It's NOT a statically set thign!? HM!  SO... this means that the PIECES that extract the static part of the theme should be separate!? HM!  So.. MAYBE we have a style resolver fo the MAINN Hessia theme elmeents? HM!  I THiNK that would make sense!
  return {
    style: {
      backgroundColor: part.background,
    }
  };
}

//  TODO:  Move this to anoher file, AND perhaps symbolize the "AppHeader" thing, AND make a way to view all these systems in CONTEXT so it's not OPAQUE and hard to read!  Need DEV TOOLS for this!?  I THINK Hessia will give us MUCH of that!?  HM!
registerThemeMapper("WorkspaceList", hessiaThemeMapper, "hessia");


export interface Record {
  [record: string]: any
}
// interface OutgoingNavProps extends Record {
// }

// interface ThisNavProps extends Record {
  
// }

type WorkspaceListNavProp = StackNavigationProp<any
  // {
  // [AppRoute.WorkspaceNavigator]: any,
  // 'Workspace': any,
  // [HomeScreen.Workspaces]: {}
  // },
  // HomeScreen.Workspaces
>;

interface WorkspaceListProps extends UserCredentialProps, RouteComponentProps {
  onPressWorkspace: (workspace: InstanceInternal<Workspace>, callback: () => void) => void;
  navigation?: WorkspaceListNavProp;
  style?: ViewProps;
}

interface WorkspaceListState {
  workspaces: InstanceInternal<Workspace>[];
  isLoading: boolean;
  noun?: NounInternal;
}

export const WorkspaceListBase = (props: WorkspaceListProps) => {

  const appContext = React.useContext<any>(AppContext);
  const spaceContext = React.useContext(SpaceReactContext);

  const defaultState = { workspaces: [], isLoading: true };
  const [state, setState] = React.useState<WorkspaceListState>(defaultState);

  React.useEffect(() => {
    loadComponent();
  }, []);

  return render();
  

  async function loadComponent () {

    //  Unpack Props
    const { token } = props;

    //  Retrieve the Noun
    const noun = await haborSDK.retrieveNoun(workspaceNoun.id, token);

    //  Retrieve Instances
    const workspaces: InstanceInternal<Workspace>[] = await haborSDK.searchInstances(token, { nounId: workspaceNoun.id });

    //  TODO:  Get style info

    //  Update State
    setState({ workspaces, isLoading: false, noun });
  }

  function render() {

    //  Unpack
    const { history, user, token } = props;
    const { workspaces, isLoading } = state;

    //  Guard
    if (isLoading) { return <Text>Loading...</Text>; }

    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
        {/* <View style={{ backgroundColor: 'blue' }}>
          { component }
        </View> */}

          {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

            {/* TODO:  Left align
            TODO:  Generalize to ALL fo the views LIKE this!  COMPRESS! */}
            <Icon name="chevron-left" size={ 35 } onPress={ () => props.navigation?.goBack() } />
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
              <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Workspaces</Text>

              {/* CONSIDER:  We CAN have some built-in systems AND perhaps let the user install "Plugins" too! HM! */}
              <TouchableOpacity onPress={ () => { props.navigation?.navigate("Editor") } } style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1 }} contentContainerStyle={{ padding: medSpacer }}>
          <View style={{ height: medSpacer }} />
          {/* TODO:  Properly type this if possible... Nested routes seem tricky here. How would I handle in Hessia? */}

          {/* NOTE:  NOW, we may have MULTIPLE ACTIONS!  We MAY want to EDIT a Workspace, OR we may wish to add it to CONTEXT!  The IDEA then, is that we "edit" the CONTENTS of the Workspace by having shit expliclty scoped to it.. hmm... BUT... what about automated tasks and stuff?  Will they be run in this context?  Will their results be scoped, OR is it enough to have only SOME be scoped?  FOR NOW, let's assume all? HM! */}
          {/* THOUGHT:  AH!!!  MAYBE the idea is the "Context Menu" can be DIFFERENT depending upon which component we use hm!  THe more general concetpf is context and differentiation.  This way, no matter WHAT it is we're talking about, we can produce a "differentiation" given some state.... MUCH like an explicilt IF statement hm!!!! */}
          <ContainerListSimple elems={ workspaces } onItemLongPress={ (item) => props.navigation?.navigate(AppRoute.WorkspaceNavigator as any, { screen: WorkspaceRoute.Systems, params: { screen: HAppRoute.InstanceBuilder, params: { noun: workspaceNoun, instance: item as InstanceInternal<Workspace> }  } }) } onItemPress={ async (workspace) => { 
            try {

              //  CONSIDER:  MAY want to DESCRIVE a feature and hav ea system to PROJECT style / colors hmmm.... MAY yeah hmm...  Then I can prject based on color scheme and stuff too mm!
              await spaceContext?.context.registerContext({ system: "space", state: { space: workspace }, name: "Space", icon: { name: "folder", type: "material" } });
              spaceEmitter.emit(SPACE_CHANGE_EVENT, { space: workspace, token })
              props.navigation?.goBack();
            } catch (err) {
              console.log(err);
              // alert(JSON.stringify(err))
            }
            
          }} />
        </ScrollView>
        </Page>
      </PageLoader>


      // <View style={{ display: 'flex', flexDirection: 'column', flex: 1, ...props.style }}>
      //   <AppHeader buttonIcon={{ name: "plus", type: "font-awesome" }} title={ "Workspaces" } { ...getProps("AppHeader") } />
        
      // </View>
    );
  };


};

// class WorkspaceListBase extends React.Component<WorkspaceListProps, WorkspaceListState> {


//   //  TODO:  Support CROSS ENTITY filtering!  This is important for GENERAL Queries
//   //  NOTE:  From now on we want to try to keep the QUERY step separate from the RENDERING step for these GENERIC renderers.  For custom renderers, it's not as big an issue.

// }

export const WorkspaceList = withRouter(WorkspaceListBase);
