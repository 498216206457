 //  CONSIDER:  Make "ExtensibleFunctions" its own NPM module!  MAYBE call it "Extensive" or "Pensive", or "Expansive", or "Exordered", or ... something else.


 //  CONSIDER:  How is this different than decorators?  Well... they need to be defined at build time!  I don't THINK we can typically build decorators at runtime?
 export type RegisterItem<Args> = (ExtensibleFunction<Args, Args> | ((params: Args) => Promise<Args>));

 export class Register<Args> {

  private functionList: RegisterItem<Args>[] = [];

  public register = (func: RegisterItem<Args>) => {
    this.functionList.push(func);
  }

  public invoke = async (input: Args): Promise<Args> => {
    let updatedInput: Args = input;
    for (let i = 0; i < this.functionList.length; i++) {
      const func = this.functionList[i];
      if (func instanceof ExtensibleFunction) {
        updatedInput = await func.invoke(updatedInput);
      } else {
        updatedInput = await func(updatedInput);
      }
    }
    return updatedInput;
  }
 }

  //  CONSIDER:  We COULD make a chain so the outputs of one go to the input of the next?  FOR NOW, let's just allow state mutation.
  //  CONSIDER:  Should we support child functions with a different set of input / output types?  Should they manipulate their inputs?  IF they do, can we do it immutably?  If not, why?  If so, does it matter?
  //  CONSIDER:  This is a case where we want to give other elements within the plugin access, but NOT the user...  Due to limitations in JS / TS, we'll need to make these public, or accessor methods public / helper methods public!  I think... right?

 export class ExtensibleFunction<FuncArgs, FuncReturn> {

  public beforeRegister: Register<FuncArgs> = new Register<FuncArgs>();
  public afterRegister: Register<{ args: FuncArgs, res: FuncReturn }> = new Register<{ args: FuncArgs, res: FuncReturn }>();

  constructor(public func: (params: FuncArgs) => FuncReturn) {}

  public invoke = async (args: FuncArgs): Promise<FuncReturn> => {

    //  Handle Before
    //  NOTE:  The 'before' handler has the power to change the INPUT.
    const updatedArgs: any = await this.beforeRegister.invoke(args);

    //  Handle Func
    const res = await this.func(updatedArgs);

    //  Handle After
    //  NOTE:  The 'after' handler has the power to change the INPUT AND the sRESULT.
    const upatedRes = await this.afterRegister.invoke({ args, res });

    return upatedRes.res;
  }
 }