import { Text, TextProps } from 'react-native';
import { useSizes } from './sizes-helper';
import { primaryFontFamily, primaryFontFamilyHeavy } from './styles';

export const basColors = {
  background: "#FAFAFA"
};

export const defaultShadow = { shadowColor: 'black', shadowOpacity: 0.25, shadowOffset: { width: 0, height: 0 }, shadowRadius: 2 };

export const SuperHeader = (props: TextProps) => {
  const { isDesktop, isTablet } = useSizes();
  const size = isDesktop ? 60 : isTablet ? 37 : 32;
  return (
      <Text { ...props } style={[{ lineHeight: size, fontFamily: primaryFontFamilyHeavy, fontSize: size, color: "#555555", letterSpacing: -1 }, props.style || {}]}>{ props.children }</Text>
  );
}


export const Header = (props: TextProps) => {
  return (
      <Text { ...props } style={[{ lineHeight: 31, fontFamily: primaryFontFamilyHeavy, fontSize: 31, color: "#777777", letterSpacing: -0.5 }, props.style || {}]}>{ props.children }</Text>
  );
}

export const SubHeader = (props: TextProps) => {
  return (
      <Text { ...props } style={[{ lineHeight: 18, fontFamily: primaryFontFamilyHeavy, fontSize: 18, color: "#555555" }, props.style || {}]}>{ props.children }</Text>
  );
}

export const Paragraph = (props: TextProps) => {
  return (
      <Text { ...props } style={[{ lineHeight: 14, fontFamily: primaryFontFamily, fontSize: 14, color: "black" }, props.style || {}]}>{ props.children }</Text>
  );
}

export const defaultBorderRadius = 5;

export const medShadow = { shadowColor: 'black', shadowOffset: { height: 3, width: 3 }, shadowRadius: 5, shadowOpacity: 0.1 };

export const primaryColor = '#f53183';
export const secondaryColor = '#fafafa';

export const maxWidth = 1500;
export const desktopBreakpoint = 1500;
export const desktopGridWidth = 1500;

export const tabletGridWidth = 700;
export const tabletBreakpoint = 700;

export const desktopMarginSize = 60;
export const tabletMarginSize = 40;
export const mobileMarginSize = 20;

export const gridsize = 12;

//  TODO:  Use the ACTUAL environment to change this!
export const environment = process.env.NODE_ENV;
