import { APIUser, EntityType, InstanceInternal, Workspace, AccessPermission, NounInternal, Plugin, SystemEnablement, SystemEnablementNoun, pluginNoun, SystemAssociation, SystemAssociationNoun } from 'habor-sdk';
import { haborSDK } from '../../hessia-plugin/config';
import { SDTObject } from 'habor-sdk';
import { nestObject } from '../../habor-plugin/habor-utils';
import * as _ from 'lodash';

export interface InstanceGroup {
  noun: NounInternal;
  instances: InstanceInternal[];
}

export interface AggregatePlugin {
  plugin: InstanceInternal<Plugin>;
  nouns: NounInternal[]
  instances: {
    [nounId: string]: InstanceGroup
  }
}

//  REALIZATION:  We COULD have a "Publish" system for the Plugins.  This system MAY use OTHER systems, like the Auth system?  That would be an implementation detail of the publishing?

//  TODO:  If "OwnerRelationship" is being used to identify a particular type of relationship maybe we should consider renaming to something more suitable?  In this case, we don't "Own" the Plugin just because we "Enable" it.
export const activatePluginForSpace = async (plugin: InstanceInternal<Plugin>, space: InstanceInternal<Workspace>, user: APIUser, token: string) => {
  const rel: SystemEnablement = { srcId: { nounId: plugin.nounId, instanceId: plugin.id, type: EntityType.Instance }, destId: { nounId: space.nounId, instanceId: space.id, type: EntityType.Instance } };
  await haborSDK.createInstance({ nounId: SystemEnablementNoun.id, payload: rel }, token);
}

export const getPlugins = async (user: InstanceInternal<APIUser>, token: string) => {

  //  Get OWNED User Plugin Relationships (each relationship indicates Plugin installation)
  //  TODO:  We should have an API exposing methods for the UI to do this WITHOUT knowledge of implementation details!
  //  TODO:  Consider building a query engine that lets us query at an APPLICATION level across entities?  KIND of like GraphQL?
  //  BRAINSTORM:  The "Auth" / "Identity" systems add qualifiers to all our entities?  MAYBE they automatically make endoints to make it easy to query for a particular user?  PLUS, we currently set owner with an "Owner Relationship", BUT I'm not sure we need to do that?  Instead, can't we use the existing systems?? I REALLY don't like that we explicitly pull from the relationship thing.  How can we solve that?  Well... I suppose we can have auth / identity add a new query resolver?  EVERYTHING is basically like GraphQL, and then, when we're in a module, we just need to make sure to depend on those things?  Maybe we can generate documentation and intellisense based on the combination of depencncies!?  Hmm!  This way, we DON'T have to know that it's stored as a relationship, ALL we need to know is that we want to get "Instances of X owned by Y".  Or, more generally, where Y has some particular access grant?  I DO like these ideas, but for now, let's keep pushing forward with the coupled system.
  // const pluginRelationships: InstanceInternal<OwnerRelationship>[] = await haborSDK.searchInstances<OwnerRelationship>(token, { nounId: OwnerRelationshipNoun.id, search: { all: [{ match: { payload: { destId: { instanceId: user.id } } } } ] } });

  //  Get the Plugins
  const allPlugins: InstanceInternal<Plugin>[] = await haborSDK.searchInstances(token, { nounId: pluginNoun.id});

  //  AH!  I remember.. we might want to make some that are OWNED by us, and some that are shared.  EVEN though the Access gives us permission to several!

  //  Filter the Installed Plugins
  // const ownedPlugins = allPlugins.filter(plugin => !pluginRelationships.find(rel => rel.payload.srcId.instanceId == plugin.id));

  return allPlugins;
};

//  TODO:  This should be a top-level accessor of the Plugin system, OR be a modifier to the query exposed by the Auth system?
export const getPublicPlugins = (plugins: InstanceInternal<Plugin>[]): InstanceInternal<Plugin>[] => {
  return plugins.filter((plugin: any) => {
    plugin.payload.instancePermission == AccessPermission.Any
  });
}

export const getPrivatePlugins = (plugins: InstanceInternal<Plugin>[]): InstanceInternal<Plugin>[] => {
  return plugins.filter((plugin: any) => {
    plugin.payload.instancePermission == AccessPermission.Owner
  });
}

//  TODO:  We should NOT need to re-implement this implementation detail here!
export const getOwnedPlugins = (plugins: InstanceInternal<Plugin>[], user: InstanceInternal<APIUser>): InstanceInternal<Plugin>[] => {
  return plugins.filter((plugin: any) => {
    return plugin.payload.instanceOwner.instanceIdList[0].instanceId == user.id
  });
}

export const attachEntityToPlugin = async (entity: NounInternal, plugin: InstanceInternal<Plugin>, token: string) => {
  await nestObject(entity, plugin, token);
}

export const attachInstanceToPlugin = async (inst: InstanceInternal, plugin: InstanceInternal<Plugin>, token: string) => {
  await nestObject(inst, plugin, token);
}

export const attachInstanceToWorkspace = async (inst: InstanceInternal, workspace: InstanceInternal<Workspace>, token: string) => {
  await nestObject(inst, workspace, token);
}

export const getSystemAssociationsForPlugin = async (plugin: InstanceInternal<Plugin>, token: string) => {

    //  Get SystemAssociation Objects for this Plugin (System)
    //  TODO:  Eventually I should be able to SCOPE a search with a system?  This is an example of a SYSTEM (search primitive) where ALL other systems have influcence!!!  So... I think that makes sense.
    //  We do this by querying for that RELATIONSHIP, and then doing this I think.
    //  Basically, I'd like to do this with the CONTEXT system, AND the query options system like GraphQL.  Then when I query for a "System", perhaps I can ALSO request the associations OR even the nouns / instances themselves!?  PERHAPS that's exactly what I should do, with a DEFAULT GraphQL setup, but the ability for the user to make custom ones as well???

    const associations = await haborSDK.searchInstances<SystemAssociation>(token, {
      nounId: SystemAssociationNoun.id,
      search: {
        match: {
            payload: {
              destId: {
                type: EntityType.Instance,
                nounId: pluginNoun.id,
                instanceId: plugin.id
              }
            }
        }
      }
    });

    return associations;
}

export const getSystemAssociationsForInstance = async (instance: InstanceInternal<any>, token: string) => {

  const associations = await haborSDK.searchInstances<SystemAssociation>(token, {
    nounId: SystemAssociationNoun.id,
    search: {
      match: {
          payload: {
            srcId: {
              type: EntityType.Instance,
              nounId: instance.nounId,
              instanceId: instance.id
            }
          }
      }
    }
  });

  return associations;
}

/**
 * Get systems associated with an instance
 * @param instance 
 * @param token 
 */
export const getInstanceSystems = async (instance: InstanceInternal<any>, token: string) => {

  const associations = await getSystemAssociationsForInstance(instance, token);

  const systemIds = associations.map(association => association.payload.destId.instanceId);
  const matchTerms = systemIds.map(systemId => ({ match: { id: systemId } }));
  const systems = await haborSDK.searchInstances<Plugin>(token, { nounId: pluginNoun.id, search: { any: matchTerms } });

  return { associations, systems };
}

//  This is an example of a thing with a simple delete method? Hmm.. SOME may be more complex though?
// export const deleteAssociation = async (inst: InstanceInternal, plugin: InstanceInternal<Plugin>, token: string) => {
//   await nestObject(inst, plugin, token);
// }



// const getPluginElements = async (plugin: InstanceInternal<Plugin>, token: string) => {
//   const associations = await getSystemAssociations(plugin, token);
//   const nounAssociations = associations.filter(assoc => assoc.payload.srcId.type === EntityType.Noun);
//   const instAssociations = associations.filter(assoc => assoc.payload.srcId.type === EntityType.Instance);
//   const nouns = getNouns
// }



export type SystemMap = {
  [systemId: string]: AggregatePlugin;
}

//  TODO:  Make a GraphQL query for this, OR build it into the context system?
//  NOTE:  We SHOULD eventually return this info when we do the original query for Plugins?  Then, it can be done in Bulk?  Hmm... FOR NOW, let's just make a function to do it individually?
export const getPluginElements = async (ownedPlugins: InstanceInternal<Plugin>[], token: string): Promise<SystemMap> => {

      //  Get the Plugin Noun Relationships
      const nounMatchTerms = ownedPlugins.map(plugin => ({ match: { payload: { destId: { instanceId: plugin.id } } } }));
      const systemAssociations: InstanceInternal<SystemAssociation>[] = await haborSDK.searchInstances<SystemAssociation>(token, { nounId: SystemAssociationNoun.id, search: { all: [{ any: nounMatchTerms } ] } });
  
      const nounAssociations = systemAssociations.filter(assoc => assoc.payload.srcId.type === EntityType.Noun);
      const instAssociations = systemAssociations.filter(assoc => assoc.payload.srcId.type === EntityType.Instance);

      //
      //  Nouns
      //

      //  TODO-IMPORTANT:  We should NOT explicitly check that the query term is empty.  Instead, the backend should return an empty list when there is an empty 'any' query instead of all.  To obtain all, maybe we should query with NO conditions?

      //  Get the Plugin Nouns
      const pluginNouns = nounAssociations.length ? await haborSDK.searchNouns(token, { search: { any: nounAssociations.map(rel => ({ match: { id: rel.payload.srcId.nounId } }) ) } }) : [];
  
      //  Group the Nouns by Plugin
      const pluginGroups: { [pluginId: string]: AggregatePlugin } = {};
      ownedPlugins.forEach(plugin => {
        pluginGroups[plugin.id] = {
          plugin,
          nouns: [],
          instances: {}
        }
      });
  
      pluginNouns.forEach(noun => {
        const rel = nounAssociations.find((value, index) => value.payload.srcId.nounId == noun.id);
        if (!rel) { throw new Error("No Plugin relationship associated with the given Noun.") }
        const pluginId = rel.payload.destId.instanceId as string;
        const plugin = ownedPlugins.find((value, index) => value.id == pluginId);
        if (!plugin) { throw new Error("No Plugin with the given Plugin ID.") }
        const pluginGroup = pluginGroups[pluginId];
        pluginGroup.nouns.push(noun);
      });


      //
      //  Instances
      //


      //  Group by Noun (This is a map from nounID => associations for ALL plugins)
      const assocByNoun = _.groupBy(instAssociations, association => association.payload.srcId.nounId);

      //  Get the Nouns
      const instNounIDs = Object.keys(assocByNoun);
      const instNounSearchTerms = instNounIDs.map(nounId => ({ match: { id: nounId } }));
      const instNouns = await haborSDK.searchNouns(token, { search: { any: instNounSearchTerms } });

      //  Get the Instances
      const instances: InstanceInternal[] = [];
      for (const noun of instNouns) {
        const instIds = assocByNoun[noun.id].map(association => association.payload.srcId.instanceId);
        const instSearchTerms = instIds.map(instId => ({ match: { id: instId } }));
        const insts = await haborSDK.searchInstances(token, { nounId: noun.id, search: { any: instSearchTerms } });
        instances.push(...insts);
      }

      instances.forEach(inst => {
        const rel = instAssociations.find((value, index) => value.payload.srcId.instanceId == inst.id);
        if (!rel) { throw new Error("No Plugin relationship associated with the given Instance.") }
        const pluginId = rel.payload.destId.instanceId as string;
        const plugin = ownedPlugins.find((value, index) => value.id == pluginId);
        if (!plugin) { throw new Error("No Plugin with the given Plugin ID.") }
        const instNoun = instNouns.find(instNoun => instNoun.id === inst.nounId);
        if (!instNoun) { throw new Error("No Noun was found for the given Instance."); }
        const pluginGroup = pluginGroups[pluginId];
        if (!pluginGroup.instances[inst.nounId]) { pluginGroup.instances[inst.nounId] = { noun: instNoun, instances: [] } }
        const instanceGroup = pluginGroup.instances[inst.nounId];
        instanceGroup.instances.push(inst);
      });

      return pluginGroups;
}

//  TODO:  This should act like a RESOLVER, which changes the query in the backend?  I THINK that makes sense.  BUT, it's not resolving a specific resource, it's like a resource wrapper / decorator??


//  MAYBE we have a "Query Resolver", and different SYSTEMS can register their own pieces.  Then, we know that we're querying within that system?  In other words, we're looking for a list of results, BUT we want the list of results to have some values for those systems!

//  NOTE:  The end result of the resolver chain is a "Primitive" query to the backend system.  Now... we CAN do the resolution IN the backend, and THEN generate a query that we pass to our persistence layer!  MAYBE we'll need to do a CHAIN of queries at some point.
//  ANALYSIS:  HOW is this different from GraphQL?  Well, GraphQL has a set of pre-defined resolvers that we invoke by name with a set of arguments, ONE of which is the nested set of resolvers to call.  The end result is NOT a query, but a list of objects.  With our model, we MAY wish to do the same, instead of making a query, we may want to run queries and then filter...hmm... BUT, maybe only when necessary?  So, one is that we pass down and COMBINE queries until we NEED to fire.
//             OK, so query merging MAY be a new technique (which may involve several mergings / queries / mergings, etc...) but what other differences are there?  It's AS IF the other pieces, like "Access" were part of the same model.  SO, I THINK we MAY actually want to use GraphQL for this?  Then, we can add new resolvers when we install those Plugins??  This enables querying at a higher level?

export interface QueryResolver {
  plugin: string;  //  The ID of the owning Plugin / System
  name: string;
  description?: string;
  params: SDTObject;

}

const queryResolver = () => {

}
