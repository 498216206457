import * as React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { getRaisedStyle, RaisedHeight } from "./styles";

export const SimpleCard = ({ style, children, onPress }: { style?: ViewStyle, children?: any, onPress?: () => void }) => {

  return onPress ? (
    <TouchableOpacity onPress={onPress} style={[{ backgroundColor: '#fdfdfd', borderRadius: 15, padding: 15, display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: "#f2f2f2" }, getRaisedStyle({ raisedHeight: RaisedHeight.low }), style]}>
      {children}
    </TouchableOpacity>
  ) : (
    <View style={[{ backgroundColor: '#fdfdfd', borderRadius: 15, padding: 15, display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: "#f2f2f2" }, getRaisedStyle({ raisedHeight: RaisedHeight.low }), style]}>
      {children}
    </View>
  );
}
