import * as React from 'react';
import { ActivityIndicator, View, ScrollView } from 'react-native';
import Stripe from 'stripe';
import { GalleryButton } from '../components/common';
import { GalleryHeader } from '../components/header';
import { HeroCard } from '../components/hero-card';
import { basApiHost, Paragraph, primaryColor } from '../constants';
import { CenteredView, useSettings } from '../content-view';

export const OrderSuccessPage = ({ checkoutSessionId }: { checkoutSessionId: string }) => {

  //  TODO:  Double check that nothing secret is exposed  in the Session / Customer objects that the client shouldn't see!

  const [loading, setLoading] = React.useState(true);
  const [session, setSession] = React.useState<Stripe.Checkout.Session>();
  const [customer, setCustomer] = React.useState<Stripe.Customer>();
  const [shippingRate, setShippingRate] = React.useState<Stripe.ShippingRate>();
  const [receiptUrl, setReceiptUrl] = React.useState<string>();

  React.useEffect(() => {
    getCheckoutSession();
  }, []);

  const getCheckoutSession = async () => {

    const res = await fetch(`${basApiHost}/checkout-session?session_id=${checkoutSessionId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const json = await res.json();
    setSession(json.session);
    setCustomer(json.customer);
    setShippingRate(json.shippingRate);
    setReceiptUrl(json.receiptUrl);
    setLoading(false);
  }

  const settings = useSettings();

  if (loading) {
    return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}><ActivityIndicator color={primaryColor} /></View>
  }

  const customerData = customer || session?.customer_details;

  return (
    <View style={{ flex: 1, width: '100%', height: '100%' }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <ScrollView style={{ flex: 1 }}>
        <CenteredView containerProps={{ style: { backgroundColor: 'white', height: '100%' } }} style={{ paddingHorizontal: settings.marginSize }}>
          <HeroCard style={{ marginBottom: 0 }} title="Order Confirmation">
            <GalleryButton style={{ marginTop: 20 }} title='Show Receipt' onPress={() => window.open(receiptUrl)} />
          </HeroCard>
          <Paragraph style={{ maxWidth: 400, alignSelf: 'center' }}>{`
${customerData?.name},

Your order has been placed, and we've sent a receipt to ${customerData?.email}.

Your order is expected to arrive at ${customerData?.address?.line1} ${customerData?.address?.line2} ${customerData?.address?.city} ${customerData?.address?.state} within ${shippingRate?.delivery_estimate?.minimum?.value} to ${shippingRate?.delivery_estimate?.maximum?.value} business days.

We have a 30 day return policy, so if you don't love your purchase, we'll happily provide a refund.

If you have any questions, please contact support@badart.studio

Thank you!
Bad Art Studio
          `}
          </Paragraph>
        </CenteredView>
      </ScrollView>
    </View>

  );
}
