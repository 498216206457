import * as Speech from 'expo-speech';
import { AnyBlock, BlockType, Color, deserializeUserBlock, EntityType, FrameworkParamsNew, HaborIconSelection, InstanceInternal, InstanceSearchParams, NamedObject, OwnershipRelationship, OwnershipRelationshipNoun, PrimitiveBlock, Relationship, SDTObject, SearchTerm, serializedInstanceInternalSchema, SerializedUserBlock, serializedUserBlockNoun, UserBlock, valueAtPath } from 'habor-sdk';
import * as React from 'react';
import { Button, Switch, Text, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Card } from '../../../../../packages/kelp-bar/card';
import { DatePicker } from '../../../../../packages/kelp-bar/date-picker';
import { medSpacer, primaryFontFamilyHeavy } from '../../../../../packages/kelp-bar/styles';
import { TextParagraph, TextSubParagraph } from '../../../../../packages/kelp-bar/text';
import { GalleryButton } from '../../../../gallery/components/common';
import { } from '../../../component-plugin/habor-react/habor-component-lib';
import { Entity } from '../../../entity-plugin';
import { HessiaPlugin } from '../../../hessia-plugin';
import { haborSDK } from '../../../hessia-plugin/config';
import { } from '../../../model-plugins/named-object-plugin/named-object-list';
import { InstanceListItem } from '../../../model-plugins/named-object-plugin/named-object-list-item';
import { TitleChiclet } from '../../../primitives-plugin/habor-components/embedded-views/chicklet-component';
import { KanBanBoard } from '../../../status-plugin/kanban-component';
import { BlockRenderer } from '../components/component-renderer';
import { SearchTermSDT3 } from './search-term';

export interface BlockTag {
  id: string;
  name: string;
  description: string;
  color: string;
  icon: HaborIconSelection;
}

//  TODO:  Instead of JUST these types, Groups by Plugin / Library / ... Ontology???
export const blockTags: { [tagId: string]: BlockTag } = {
  source: { id: "source", name: "Source", description: "Import data into your component.", color: Color.warning, icon: { name: 'arrow-down', type: 'font-awesome' } },
  inline: { id: "inline", name: "Inline", description: "Inline data processor.", color: Color.secondary, icon: { name: 'flow-line', type: 'entypo' } },
  component: { id: "component", name: "Component", description: "A block with a visual element.", color: Color.primary, icon: { name: 'th-large', type: 'font-awesome' } },
  action: { id: "action", name: "Action", description: "A block which performs an action.", color: Color.danger, icon: { name: 'bolt', type: 'font-awesome' } },
  event: { id: "event", name: "Event", description: "Blocks to handle events", color: Color.danger, icon: { name: 'calendar', type: 'font-awesome' } },
};

//
//  Block Definitions
//  TODO:  ALL of these should be in their own Modules / Plugins and installed FROM that Plugin!
//

//  NOTE:  FOR NOW, each block has TWO "channels" the Data Channel (setOutput), and the Interface Channel (function output).



//  TODO:  SERIOUSLY consider an OBJECT abstraction where we can group FUNCTIONS?? Hmm.. OR at least functional groups? Hmm!!  MAYBE functions CAN apply to an object, BUT perhaps they can be associated with SEVERAL contexts!?  For example, if a "drive" function requires both a CAR and a DRIVER, PERHAPS in the right context it can be shown "on" or.. ASSOCIATED with BOTH!?  This is based on the block input types FOR NOW?? HMMM!!

//  TODO:  Make some standard functional things like MAPPERS, and potentially Classes?  SHOULD be able to make new blocks too?? Hmm!  ALSO should be able to construct types and stuf!?  ALL in nice, POTENTIALLY EXTENSIBLE UIs!??

//  NOTE:  FOR NOW, I can expose the current query language to the user.. in the future, I'd like something which has Plugins / Systems to automatically handle associations and stuff??  I SUPPOSE this will be a MERGING of EXPRESSIONS and SEARCH!?  HM!!!


//  CONSIDER:  We COULD support a GENERIC search including nouns / instances, OR just remove the distinction between the two at this abstraction and search over THIGNS? Hmm...
export const InstanceSearchParamsSDT: SDTObject = {
  type: "object",
  properties: {
    nounId: { type: "keyword", required: true },  //  CONSIDER:  Eventually switch to a proper Model / Noun / Entity / Whatever reference.. 
    search: { ...SearchTermSDT3, required: false },
    from: { type: "number", required: false },
    size: { type: "number", required: false }
  },
  extensible: false
}

export const processRunner: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Process Runner",
  color: "#882233",
  icon: { name: "import", type: "material-community" },
  description: "Runs a Process",
  inputSchema: { type: "object", extensible: false, properties: { processId: { type: "keyword" } } },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams, processId }: { frameworkParams: FrameworkParamsNew, processId?: string }) => {

    //  Guard
    //  CONSIDER:  Consider a way to enfore an input generically and controlled by the system.
    if (!processId) { return null; }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;  //  TODO:  Add "blockInst" to the Framework Params.

    interface GraphNode<T> {
      item: T | undefined,
      dependencies: string[],
      children: string[]
    }

    type Graph<T> = { [id: string]: GraphNode<T> };

    const [process, setProcess] = React.useState<InstanceInternal<any> | undefined>(undefined);
    const [graph, setGraph] = React.useState<Graph<InstanceInternal<any>>>({});
    const [activeStep, setActiveStep] = React.useState<GraphNode<InstanceInternal<any>> | undefined>(undefined);
    const [processInst, setProcessInst] = React.useState<InstanceInternal<any> | undefined>(undefined);

    React.useEffect(() => {

      //  Get the Process
      //  TODO:  Use the "Expression" / Django-like system for this!?
      haborSDK.retrieveInstance<any>("process", processId, token).then(process => {
        setProcess(process);
        haborSDK.searchInstances(token, {
          nounId: "processstep3",
          //  TODO:  Shouldn't need knowledge of the "Relationship" internal data-type.
          search: { match: { payload: { Process: { instanceIdList: { instanceId: processId } } } } }
        }).then(processSteps => {
          // setProcessSteps(processSteps);

          //  Order!
          //  CONSIDER:  Use the SAME algoritmm we'll use for Hessia / Halia systems!?
          //  TODO:  Abstract this COMMON logic of building a dependency map!?
          const graph: Graph<InstanceInternal<any>> = {};
          processSteps.forEach((processStep: any) => {

            //  Get Parent
            const parentID = processStep?.payload["Precious Step"]?.instanceIdList[0]?.instanceId;

            //  Get Existing Node
            const existingNode = graph[processStep.id];

            if (existingNode) {
              existingNode.item = processStep;
              existingNode.dependencies = parentID ? [parentID] : [];
            } else {
              graph[processStep.id] = {
                item: processStep,
                dependencies: parentID ? [parentID] : [],
                children: []
              }
            }

            //  Process Parent
            if (!parentID) { return; }
            const existingParentNode = graph[parentID];

            if (existingParentNode) {
              existingParentNode.children.push(processStep.id);
            } else {
              graph[parentID] = {
                item: undefined,
                dependencies: [],
                children: [processStep.id]
              }
            }
          });
          setGraph(graph);

          //  Get the Root
          const rootId = Object.keys(graph).find((nodeId) => graph[nodeId].dependencies.length === 0);
          if (!rootId) { throw `No root!`; }
          const rootNode = graph[rootId];
          setActiveStep(rootNode);

          //  Create the Process Instance
          //  TODO:  LOTS going on here.. break it up and de-couple!
          haborSDK.createInstance({
            nounId: "process_instance",
            payload: {
              processId,
              startTime: new Date().toISOString(),
              //  TODO:  COnsider storing a process state?
              endTime: undefined
            }
          }, token).then(inst => {
            setProcessInst(inst);
          })
        })
      });

    }, [token, processId]);

    //  Return the UI Output
    if (!processInst) { return <Text>Loading Graph...</Text> }
    if (!activeStep?.item) { throw `No root item!` }

    //  Process Step Viewer
    //  TODO:  SHOULD be able to create CUSTOM process steps that might do additional things like log events?? HMM!  BUT in the CONTEXT of the Process!? HMM!
    const ProcessStepViewer = ({ processStep, handleSuccess, handleFailure }: { processStep: InstanceInternal<any>, handleSuccess: () => void, handleFailure: () => void }) => {
      return (
        <View>
          <TextParagraph>{processStep.payload.name}</TextParagraph>
          <TextSubParagraph>{processStep.payload.description}</TextSubParagraph>
          <Button title="Complete" onPress={handleSuccess} />
          <Button title="Fail" onPress={handleFailure} />
        </View>
      );
    }

    const logStepResult = async (state: string) => {

      console.log(`Process Step ${activeStep.item?.id}: ${state}`);

      await haborSDK.createInstance({
        nounId: "process_step_result",
        payload: {
          processStepId: activeStep.item?.id,  //  TODO:  SHOULD enforce this type in the data model instead of string.
          processId: process?.id,
          processInstId: processInst.id,
          state
        }
      }, token);
    }

    const handleSuccess = async () => {
      await logStepResult("success");
      next();
    }

    const handleFailure = async () => {
      await logStepResult("failure");
      next();
    }

    const close = async () => {
      await haborSDK.updateInstance(processInst.id, {
        nounId: "process_instance",
        payload: {
          ...processInst.payload,
          endTime: new Date().toISOString()
        }
      }, token);
      alert("Closed the Process!");
    }

    const next = () => {
      const children = activeStep.children;
      if (children.length == 0) {
        close();
      } else if (children.length == 1) {
        const child = children[0];
        setActiveStep(graph[child]);
      } else {
        throw `Multiple children are not currently supported.`;
      }
    }

    //  Show Each Node and store the result
    //  CONSIDER:  This is the system I'm building FOR NOW.. there may be a more fundamental or preferable way to do this!?
    return (
      <View>
        <Text>{process?.payload.name}</Text>
        <ProcessStepViewer processStep={activeStep.item} handleSuccess={handleSuccess} handleFailure={handleFailure} />
      </View>
    );
  },
  tags: ["source"],
  isComponent: false
}

export const queryPrimitiveBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Query",
  color: "#882233",
  icon: { name: "import", type: "material-community" },
  description: "Import data from the server.",
  //  CONSIDER:  DOES is make sense to use "Settings", OR does it make more sense to just use a regular input signal?
  inputSchema: { type: "object", extensible: false, properties: { params: InstanceSearchParamsSDT } },
  outputSchema: { type: "object", properties: { count: { type: "number" }, items: { type: "array", itemType: { type: "object", extensible: true } as SDTObject } } },
  renderer: ({ frameworkParams, params }: { frameworkParams: FrameworkParamsNew, params?: InstanceSearchParams }) => {

    //  Guard
    if (!params) { return null; }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;  //  TODO:  Add "blockInst" to the Framework Params.
    // const { nounId } = params;

    //  Create an "Effect" to handle data flow re-run
    React.useEffect(() => {

      //  Run the Data Function
      //  TODO:  THIS is the only part of the "Data" layer we really care about... maybe build an abstraction which lets us avoid the complex "React.useEffect" stuff?
      haborSDK.searchInstances<any>(token, params).then(items => {
        console.log(params);
        setOutput({ items, count: items.length }, blockInst);
      });

    }, [params, token]);

    //  Return the UI Output
    return null;
  },
  tags: ["source"],
  isComponent: false
}

export const pagerBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Pager",
  color: "#882233",
  icon: { name: "import", type: "material-community" },
  description: "Create Multiple Pages",
  //  NOTE:  I'd LIKE to be able to reference SCOPE variables from within the rendered components, MUCH the same way I would with an ARRAY of ELEMENTS I produce and pass in React... it's the SAME as calling a FUNCTION with scope variable references!?  Ok!  We CAN do that!?  HOW though?  We CAN use an interface where we pass to the pager, BUT I'd like the Pager to be AGNOSTIC and UNAWARE of this!!!  So... if we could make a VARIABLE in the Block which DEFINED a BlockInst, I think we'd be good!?  This is like defining a SIGNAL, and then passing that Block Inst?? HMM!??  I THINK this should be possible!?
  //  TODO-IMPORTANT:  MAke a way to define "BlockInst" objects and PASS them to shit??? HMMM???
  //  TODO:  We SHOULD be able to pass BLOCKS into this JUST like a RENDER prop!?  BUT I think that means the blocks entered here are NOT involved in the "Layout" of the main Block we're editing!  AHH!!!  That's essentially the same as using a LIST like we do in REACT!?  Which is JUST what we have when we have multiple components in a Fragment?  Hmmm.. BUT perhaps it IS up to us to control the order of these children!?  HMMM...  React has a BUILT-IN mechanism for this!?  Should we have one too??? Hmm!s
  //  TODO:  I COULD also support a pager where each is an ACTUAL separate PAGE, which is another abstraction, instead of blocks!?? HM??
  //  CONSIDER:  Does this mean that blocks we initialize here are ALSO signals?? Hm!?  Like.. we can REFERENCE them??  Hmm.. BUT I wonder about layout then!?
  inputSchema: { type: "object", extensible: false, properties: { pages: { type: "array", itemType: { type: "user-block" } } } },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams, pages }: { frameworkParams: FrameworkParamsNew, pages?: UserBlock[] }) => {

    //  Guard
    if (!pages) { throw new Error("Pages are not defined in the 'Pager' block"); }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Return the UI Output
    return null;
  },
  tags: ["component"],
  isComponent: true  //  NOTE-GENERAL:  This indicates whether or not this component returns UI (React Element).
}


export const objectBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Object",
  color: "#882233",
  icon: { name: "import", type: "material-community" },
  description: "Create or Update an Object",
  inputSchema: { type: "object", extensible: false, properties: { inObject: { type: "object", extensible: true }, objKey: { type: "keyword" }, value: { type: "any" }, merge: { type: "boolean" } } },
  outputSchema: { type: "object", properties: { resObject: { type: "object", extensible: true } } },
  renderer: ({ frameworkParams, inObject, objKey, value, merge }: { frameworkParams: FrameworkParamsNew, inObject?: any, objKey?: string, value?: any, merge?: boolean }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    if (!objKey || !value) {
      return null;
    }

    if (merge && !inObject) {
      return null;
    }

    React.useEffect(() => {
      setOutput({ resObject: { ...inObject, [objKey]: value } }, blockInst);
    }, [inObject, objKey, value]);

    //  Return the UI Output
    return null;
  },
  tags: ["inline"],
  isComponent: false
}

export const textBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Text",
  color: "#882233",
  icon: { name: "text-fields", type: "material" },
  description: "Create Text",
  inputSchema: { type: "object", extensible: false, properties: { text: { type: "keyword", required: false }, json: { type: "any", required: false } } },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams, text, json }: { frameworkParams: FrameworkParamsNew, text?: string, json?: any }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Return the UI Output
    const jsonStr = json ? JSON.stringify(json) : undefined;
    return <Text>{text || jsonStr || "No Text Specified"}</Text>
  },
  tags: ["component"],
  isComponent: true
}

//  TODO-CRITICAL:  KEY POINT!!! The MODAL should NOT be responsible for knowledge of its children or MAPPING params to them!  I BELIEVE this is why we want a BLOCK that's defined in the PARENT context, but MOUNTED inside the MODAL!!  I'd like to work on that!!  PERHAPS we can make explicit support for this kind of thing!?  HOWEVER, we can STILL pass the block down TO the modal.  And perhaps we'll want to think of how to show this in the editor / layout, etc!?  BUT, we JUST pass the block without props and stuff!!  PERHAPS we CAN mount something outside, BUT then internally pipe the React Element to the input? Hmm... INTERSTING.  MAYBE it doesn't have to be mounted IN the modal?  HMmmmm...Perhaps it CAN be though? Hmm... even in React,  believe that means we're just creating an ELEMENT and nothing is mounted until it's passed to the mount function.   So... it's just a matter of WHERE to create the element and pass it!  SO, I think the CHILDREN pattern (in React) is JUST a way to build an element in the PARENT context and then pass it!!!  THIS is what I want to do!  That way, I can go ahead, CALL the element, and PERHAPS have it enabled.. Ah!??  MAYBE this will happen automatially??? Hmm... basiclaly I can create the element, BUT nothing should happen with that unless it's MOUNTED!?  So... Perhaps I define the block inst as normal and stuff, but NORMALLY they're mounted?  So in this case, instead of returning the React Element from the parent, I simply HOLD it, and PASS it to the containing comonent!?? HMM!!  PERHAPS we can support a "Children" signal, and if something is attached as a child, then we do NOT reutrn it from ourself, but we DO create the ELEMENT in our context.  THEN we pass the element to the other!!!  HMM!!!
//  FOR NOW:  For now, let's just accept a "props" param and pass it down?  Whoaa... just got Dejavu!!

export const modalBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Modal",
  color: "#882233",
  icon: { name: "window-maximize", type: "font-awesome" },
  description: "Show a Modal",
  inputSchema: { type: "object", extensible: false, properties: { show: { type: "boolean" }, blockId: { type: "keyword" }, innerProps: { type: "any" } } },
  outputSchema: { type: "object", properties: { innerOutput: { type: "any" }, cancel: { type: "string" } } },  //  NOTE:  The innerOutput will be determined by the inner block.  PERHAPS we can also include info such as whether or not it's being displayed?
  //  TODO:  The TYPE of the "innerOutput" should match the type of the incoming block's output!??
  //  TOOD:  I REALLY think the inputs should be grouped as "input" and explicitly typed with proper required indicator.  This MAY mean overriding the "shouldComponentUpdate" method in the block renderer.
  renderer: ({ frameworkParams, show, blockId, innerProps = {} }: { frameworkParams: FrameworkParamsNew, show?: boolean, blockId?: string, innerProps?: any }) => {

    return <Text>SHOULD SHOW MODAL</Text>;
    //  Return if Not Shown
    //  CONSIDER:  We COULD still resolve the block, BUT, in the future, perhaps we don't need to?  Perhaps in the future the block is injected instead of the ID?  This ALSO means OTHER blocks can be used to resolve the block that will be injected!?
    // if (!show || !blockId) { return null }

    // //  Unpack
    // const { setOutput, componentContext, frameworkContext, frameworkContext: { token }, blockInst } = frameworkParams as any;

    // const [block, setBlock] = React.useState<InstanceInternal<AnyBlock> | undefined>(undefined);
    // const [innerOutput, setInnerOutput] = React.useState<any>({});
    // const [cancel, setCancel] = React.useState<any>(undefined);

    // React.useEffect(() => {

    //   if (!block) {
    //     resolveComponent(token, blockId).then(res => {
    //       if (res?.blockComponent) {
    //         setBlock(res?.blockComponent);
    //       }
    //     });
    //   }

    //   setOutput({ innerOutput, cancel }, blockInst);

    // }, [show, blockId, block, innerOutput, cancel]);

    // if (!block) { return null; }

    // return (
    //   //  NOTE:  'new Date().getTime())' is used as an EVENT ID!?
    //   <CardModal onCancel={ () => setCancel(new Date().getTime()) }>
    //     <HaborComponentNewRenderer { ...innerProps } component={ block.payload } componentContext={ componentContext } frameworkContext={ frameworkContext } setOutput={ setInnerOutput }  />
    //   </CardModal>
    // );
  },
  tags: ["component"],
  isComponent: true
}

export const cardBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Card",
  color: "#882233",
  icon: { name: "square", type: "font-awesome" },
  description: "Create Card",
  //  NOTE:  FOR NOW, the "children" prop is a list of Block IDs to render!  Eventually we should potentially accept Blocks direclty,a nd perhaps Block Insts?? AND support passing props!?? HMM!!???
  //  TODO: Support passng props to the children!??
  inputSchema: { type: "object", extensible: false, properties: { children: { type: "array", itemType: { type: "keyword" }, required: false } } },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams, children }: { frameworkParams: FrameworkParamsNew, children?: string[] }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Return the UI Output
    //  TODO:  CURRENTLY, the 'children' prop IS a Block Instance!  That means we need to RENDER it!  PERHAPS we can use a system where, IF such is returned, React AUTOMATICALLY knows to render it as a Block?? Hmm!??
    //  UPDATE:  ACTUALLY, the input is a BLOCK not a Block Instancne FOR NOW... Hmmm...  BUT, perhaps the props are still mapped??? hmmm... Let's just keep pushing and see what happens!?
    return (
      <Card>
        {
          children?.map(childBlockId => {
            //  CONCERN:  AM I properly mapping "setOutput", OR should that be something else!??  MAYBE for THIS block?  Hmmm...
            const childBlock = getAllBlocks()[childBlockId].payload;
            return <BlockRenderer componentContext={frameworkParams.componentContext} frameworkContext={frameworkParams.frameworkContext} component={childBlock} input={{}} setOutput={setOutput} />
          })
        }
      </Card>
    );
  },
  tags: ["component"],
  isComponent: true
}

export const extractorBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Extractor",
  color: "#882233",
  icon: { name: "reply", type: "material" },
  description: "Extract a Value",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "any", required: false }, path: { type: "keyword", required: true } } },  //  TODO:  Add explicit path support, OR perhaps just support ONE level deep here??? hmm...
  outputSchema: { type: "object", properties: { value: { type: "any" } } },
  renderer: ({ frameworkParams, children, path, input }: { frameworkParams: FrameworkParamsNew, children?: string[], path?: string, input?: any }) => {

    if (!input) { return null; }

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;
    const [prevValue, setPrevValue] = React.useState(undefined);

    React.useEffect(() => {
      const parts = path?.split(".");
      let value = undefined;
      try {
        value = parts ? valueAtPath(input, parts) : undefined;  //  CONSIDER:  Perahps throw an error if it's not found and / or if "parts" is undefined?
      } catch (e) { }
      if (value !== prevValue) {
        setPrevValue(value);
        setOutput({ value }, blockInst);
      }
    }, [path, token, input]);

    return null;
  },
  tags: ["inline"],
  isComponent: true
}

export const mapBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Map",
  color: "#882233",
  icon: { name: "arrows-h", type: "font-awesome" },
  description: "Maps an Array",
  inputSchema: { type: "object", extensible: false, properties: { inArray: { type: "array", required: true }, blockId: { type: "keyword" } } },
  outputSchema: { type: "object", properties: { outArray: { type: "array" } } },
  renderer: ({ frameworkParams, inArray, blockId }: { frameworkParams: FrameworkParamsNew, inArray?: any[], blockId?: string }) => {

    return <Text>SHOLD MAP</Text>
    // const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    // if (!inArray || !blockId) { return null; }

    // const [block, setBlock] = React.useState<InstanceInternal<AnyBlock> | undefined>(undefined);
    // const [outputMap, setOutputMap] = React.useState<any>({});

    // const updateOutputMap = (index: number, output: any) => {
    //   setOutputMap({ ...outputMap, [index]: output });
    // }

    // React.useEffect(() => {

    //   if (!block) {
    //     resolveComponent(token, blockId).then(res => {
    //       if (res?.blockComponent) {
    //         setBlock(res?.blockComponent);
    //       }
    //     });
    //   }

    //   const outArray = Object.keys(outputMap).map(key => outputMap[key]);
    //   setOutput({ outArray }, blockInst);
    // }, [inArray, blockId, block, outputMap]);

    // if (!block) { return null; }

    // return inArray.map((elem, index) => {
    //   //  TODO:  Is there SOME way to do this without actually MOUNTING these!?? HMM!??
    //   return <HaborComponentNewRenderer elem={ elem } frameworkContext={ frameworkParams.frameworkContext } componentContext={ frameworkParams.componentContext } component={ block.payload } setOutput={
    //     (output) => {
    //       return updateOutputMap(index, output);
    //     }
    //   }  />
    // });

  },
  tags: ["inline"],
  isComponent: true
}

export const notBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Not",
  color: "#882233",
  icon: { name: "arrows-h", type: "font-awesome" },
  description: "Negates a Boolean",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "boolean", required: false } } },
  outputSchema: { type: "object", properties: { output: { type: "boolean" } } },
  //  TODO:  Consider explicit support for a default value?  ALSO consider ability to set default output? Hmm!??  MAYEB just use a new "default" value prop?
  renderer: ({ frameworkParams, input = true }: { frameworkParams: FrameworkParamsNew, input?: boolean }) => {

    const { setOutput, blockInst } = frameworkParams as any;

    if (input === undefined) { return undefined; }

    React.useEffect(() => {
      setOutput({ output: !input }, blockInst);
    }, [input]);

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

//  TODO:  Remove this for a more robvust component / module thing??? This seems WAY too specific!?  AHH!! OR perhaps just GROUP with other similar blocks VERY similar to the JS "Array" Object / Namespace?? HMM
export const elementAt: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "ElementAt",
  color: "#882233",
  icon: { name: "my-location", type: "material" },
  description: "Get the Element at the given Array Index.",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "array" }, index: { type: "keyword" } } }, //  TODO:  Switch to number once we properly show the Number Field!
  outputSchema: { type: "object", properties: { value: { type: "any" } } },
  renderer: ({ frameworkParams, children, input = [], index }: { frameworkParams: FrameworkParamsNew, children?: string[], input?: any[], index?: string }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    if (!index) { return; }

    React.useEffect(() => {
      const value = input ? input[parseInt(index)] : undefined;
      setOutput({ value }, blockInst);
    }, [input, token, index]);

    return null;
  },
  tags: ["inline"],
  isComponent: true
}

export const countBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Count",
  color: "#882233",
  icon: { name: "format-list-numbered", type: "material" },
  description: "Count Array Elements.",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "array" } } },
  outputSchema: { type: "object", properties: { count: { type: "number" } } },  //  CONCERN:  Should the SCHEMA "required" field be used to determine whether a signal connection is REQUIRED???  That's how it's CURRENTLY implemented!
  renderer: ({ frameworkParams, children, input = [] }: { frameworkParams: FrameworkParamsNew, children?: string[], input?: any[] }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    React.useEffect(() => {
      setOutput({ count: input.length }, blockInst);  //  TODO:  Perhaps we SHOULD type the output to make sure it's correct!??
    }, [input, token]);

    return null;
  },
  tags: ["inline"],
  isComponent: true
}

//  TODO:  CONSIDER primitive things for this!?  MAYBE expressions and stuff? PERHAPS for simple object manipulation as well!?? HMM!
export const divisionBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Division",
  color: "#882233",
  icon: { name: "divide", type: "font-awesome" },
  description: "Divide Two Values",
  inputSchema: { type: "object", extensible: false, properties: { numerator: { type: "number" }, denominator: { type: "number" } } },
  outputSchema: { type: "object", properties: { result: { type: "number" } } },  //  CONSIDER:  Additional division information?
  renderer: ({ frameworkParams, children, numerator, denominator }: { frameworkParams: FrameworkParamsNew, children?: string[], numerator?: number, denominator?: number }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    if (!numerator || !denominator) {
      return null;
    }
    React.useEffect(() => {
      setOutput({ result: numerator / denominator }, blockInst);
    }, [numerator, denominator]);

    return null;
  },
  tags: ["inline"],
  isComponent: true
}

export const speechBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Speech",
  color: "#882233",
  icon: { name: "sound", type: "entypo" },
  description: "Convert text to speech.",
  inputSchema: { type: "object", extensible: false, properties: { text: { type: "text" }, trigger: { type: "boolean" } } },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams, children, text, trigger }: { frameworkParams: FrameworkParamsNew, children?: string[], text?: string, trigger?: boolean }) => {

    //  TODO:  This should be ABSTRACTED in an Event system or SOMETHING!?  PERHPS ANY Block can be set to be retriggered using a "Trigge" prop that it doesn't actually READ!? HMM??  PERHAHSP it IS possible to read it? Hmm...
    if (trigger == undefined) {
      return null;
    }

    if (text) {
      Speech.speak(text.toString());
    }

    return null;
  },
  tags: ["inline"],
  isComponent: true
}

export const buttonBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Button",
  color: "#882233",
  icon: { name: "gesture-tap", type: "material-community" },
  description: "Fires an Action.",
  //  TODO:  Work on the pattern here!  NORMALLY, in JS, a button invokes a HANDLER FUNCTION.. perhaps we can do seomthing silr?  FOR NOW... lets just update the state value? Hmm...  Similar to like keeping a COUNTER for the event in state and re-loading the component when it's updated?? HJmm.. perhaps that means the componet needs to DEPEND upon it?? Hmm.. MAYBE we CAN have componetns depend upon a signal JUST for the re-trigger then?? Hmmm?? Like if a PROP had changed!?? HmmM!??!?
  inputSchema: { type: "object", extensible: false, properties: { title: { type: "keyword" } } },
  outputSchema: { type: "object", properties: { onPress: { type: "string" } } },
  //  TODO:  Consider INCREMENTING the number... SO, we SHOULD probably get the PREVIOUS output if it's available!??  MAYBE we can expliclty depend upon this?HMM..  MAYBE this is a built-in feature, BUT I wouldn't want to re-trigger when the output changes!? HMM!??  
  //  TODO:  With OTHER UI elements, also suppor thte other event types like onLongPress, etc?
  //  TODO:  Consider direct support for CSS styles, or perhaps abstract around it?  I DO like the idea of specifying style at VARIOUS levels... perhaps start with the component itself, and then maybe other places... VERY similar to CSS!?  BUT, perhaps these RULES can be assocaited with a CONTEXT as well!?

  renderer: ({ frameworkParams, children, title }: { frameworkParams: FrameworkParamsNew, children?: string[], title?: string }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;
    return <GalleryButton title={title || "Press Me"} onPress={() => setOutput({ onPress: new Date().getTime() }, blockInst)} />
  },
  tags: ["component"],
  isComponent: true
}


export const navigationBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Navigation",
  color: "#882233",
  icon: { name: "compass", type: "material-community" },
  description: "Navigate to a different page.",
  inputSchema: { type: "object", extensible: false, properties: { pageName: { type: "keyword" }, props: { type: "object", extensible: true } } },  //  TODO:  ACTUALLY pull Pages, and ACTUALLY present a REASONABLE UI to select page props!  MAYBE mapping from other input if not set explicitly!  MAYBE use the "PageSelection" type and make a Davel type (with Habor-specific types?)
  outputSchema: { type: "object", extensible: true },
  renderer: ({ frameworkParams, pageName, props }: { frameworkParams: FrameworkParamsNew, pageName?: string, props?: any }) => {

    //  Guard
    if (!pageName) { throw new Error("SettpageNameings is not defined in the 'navigation' block"); }

    //  Unpack
    const { frameworkContext: { changePage } } = frameworkParams;

    //  Navigate
    changePage({ pageName, props })

    //  Return the UI Output
    return null;
  },
  tags: ["action"],
  isComponent: false
}

//  CONSIDER:  Should we use a separate block for EACH event, OR use an "Event" block and link the Events??  Hmm.... Each Plugin can publish its OWN events right??  Can Plugins OVERRIDE events?
//  NOTE:  I DO KIND OF like the idea of using the Object pattern, where EACH object is assigned an Index... Then, inherited pieces are stored in that index?  THEN, we make Classes out of a similar pattern, but with additional contraints about terminals??  Hmmmmmmm....!
//  NOTE:  Each block has SEVERAL Outputs, Events, and MAYBE State that can be accessed (Output?) like, "Activated Action", "Pending Action", etc... Just like a Promise normally does?
//  CONCERN:  WHEN is a block active??? Does it make more sense to have ALL blocks technically "active", BUT with an "active" signal to turn them on?  OR, are blocks only "active" when they're "mounted"??  Something like this??  Maybe only when they have input?  Do blocks activate EVERY time they get a NEW input, or continuously?  I THINK for now it will be activate on NEW / changed input.
//  CONSIDER:  In a CODE file, functions have a SCOPE.  In Habor / Hessia, there are no "Code Files", and functions are used in several contexts.  So... it MAY make sense to support a SELECTED scope??  OR, where we'd NORMALLY have a function, like a callback to the "emit" function, perhaps we can PASS the params to the "Emit" function, which then passes these on to the block?
//  IDEA:  MAYBE treat this like an "Event GATE", which means we attach outputs as normal, BUT it stops the flow until the event fires?  Hmmm... but then how long does it flow until??  I KIND of like that idea, but I'm not SURE that's the pattern we want... I ALSO like the one-shot model where we trigger a function once it's enabled, and then we stop.  Hmmm.... both seem like two important use-cases?  MAYBE we make a "Gate" component, and trigger the GATE with an Event / Signal???
//  REALIZATION / TODO:  INSTEAD of passing a Block + Props, this is the SAME as an instantiation.  SO, MAYBE we can instantiate in the current context?  Then though, that block won't be rendered until the signal hits... MAYBE use a Signal component + a Gate??  I KIND of like that idea actually... But, how do we reset the gate?  An internal signal?  Something else??  Actually, I'm thinking a simple block which converts an event to a signal using a toggle.  It has a "default" setting and flips every time the event triggers.  THEN, this signal feeds a "Gate" / "Mux" which has SEVERAL blocks attached to it??  MAYBE even with NICE looking SLOTS!  Ah!  I REALLY like the idea of CUSTOM UIs for the different block types!!  Then... I suppose this will be a separate block context?  It's take as a param, a Block + Params?  One for each possible input signal?  THIS is a "Mux" block?  THEN, we ALSO have a block which fires a one-shot function IF we need to?
//                       OK!  This IS similar to the current block, which checks the signal and instantiates the block!  Not too bad!  BUT, we CAN make it more general with components like Latch / Flip-Flop (switches when Event fires), Switch, and Mux / Gate!

//  IDEA:  Instead of JUST creating Primitive Blocks, let the USER create blocks, MAYBE with a particular INTERFACE??
export const eventListenerPrimitiveBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Event Triggered Navigator",
  color: "#3366AA",
  icon: { name: "compass", type: "material-community" },
  description: "Listen to an Event",
  //  TODO:  Type the "params" with a template type, to be assigned to the props of the attached Block?  FOR NOW, just pass through anything?  MAYBE make the template system with a PLUGIN to Davel??
  //  NOTE:  Ah!  FOR NOW, this block will mount the new blocks once the event hits??

  //  TODO-IMPORTANT:  Do NOT make SPECIFIC blocks like this!  Instead, use the functions and COMPOSE them!  Make the type system sufficient to handle that generality!
  //  TOOD-DAVEL:  MAYBE change "keyword" to "identifier" in Davel??
  //  TODO-DAVEL:  Support DEFAULT values??
  inputSchema: { type: "object", extensible: false, properties: { eventId: { type: "keyword", required: true }, params: { type: "object", extensible: false, properties: { pageName: { type: "keyword", required: true }, props: { type: "object", extensible: false, properties: { nounId: { type: "object", properties: { nounId: { type: "keyword", required: true }, type: { type: "keyword", required: true } } } } } } } } },
  outputSchema: { type: "object", extensible: true },
  renderer: ({ frameworkParams, eventId, params = {}, blockId }: { frameworkParams: FrameworkParamsNew, eventId?: string, params?: any, blockId?: string }) => {

    //  Guard
    if (!eventId) { throw new Error("Either EventId is not defined."); }

    //  Create State
    const [block, setBlock] = React.useState<InstanceInternal<AnyBlock> | undefined>(undefined);
    const [fired, setFired] = React.useState<boolean>(false);

    //  Unpack
    const { pageName, props } = params;
    const { setOutput, frameworkContext, componentContext } = frameworkParams;
    const { appEmitter, changePage } = frameworkContext;

    //  Create an "Effect" to handle data flow re-run
    React.useEffect(() => {

      //  Get the Block
      //  TODO:  Support UserBlock selection!
      // const blockPrimitive = getAllBlocks()[blockId];

      //  Update State
      // setBlock(blockPrimitive);

      //  Define the Handler
      const handleEvent = () => {
        changePage({ pageName, props });
        setFired(true);
      }

      //  Listen to the Event
      appEmitter.addListener(eventId, handleEvent);

      //  Register the Cleanup Event
      return function cleanup() {
        appEmitter.removeListener(eventId, handleEvent)
      };

    }, [eventId]);

    //  Return the UI Output
    return null;
    // return block && fired ?
    //   <HaborComponentNewRenderer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ block.payload } input={{ params }} setOutput={ () => null } /> :
    //   null;
  },
  tags: ["event"],
  isComponent: false
}

//  TODO:  The props we're passing are getting confusing... I like the old way of separating out userProps and frameworkProps... maybe we should move back to that soon?
//         FOR NOW, let's just getting working as is, where all the "userProps" are passed in the interface... This helps to ensure the copmonent doesn't re-trigger when it's not supposed to?  I DO suggest we build a system that lets us GROUP different types of input, EACH of which would trigger a re-render... that's basically what we're doing with "signals" right?
//  TODO:  Seems like a derived function.. MOST of these do.. consider more fundamental blocks!?
export const addPrimitiveBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Add",
  color: "#710738",
  description: "Add an array of values",
  inputSchema: { type: "object", extensible: false, properties: { array: { type: "array", itemType: { type: "number" } } } },
  outputSchema: { type: "object", properties: { sum: { type: "number" } } },
  renderer: ({ frameworkParams, array }: { frameworkParams: FrameworkParamsNew, array?: number[] }) => {

    //  Guard
    if (!array) { throw new Error("Array is not defined in the 'add' block"); }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Create an "Effect" to handle data flow re-run
    React.useEffect(() => {

      //  Run the Data Function
      const sum = array.reduce((prev, current, index) => prev + current, 0);
      setOutput({ sum }, blockInst);

    }, [array]);

    //  Return the UI Output
    return null;
  },
  tags: ["inline"],
  isComponent: false
}

export const createInstance: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Create Instance",
  color: "#719367",
  description: "Create an instance.",
  inputSchema: { type: "object", extensible: false, properties: { nounId: { type: "keyword" }, payload: { type: "object", extensible: true } } },
  outputSchema: { type: "object", properties: { created: { type: "boolean" } } },
  renderer: ({ frameworkParams }) => {
    alert("This block is not yet implemented.  Using system specific functions is the prefeerred method.");
    return null;
  },
  tags: ["action"],
  isComponent: false
}

export const dateField: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Date Field",
  color: "#719367",
  description: "Select a Date",
  inputSchema: { type: "object", extensible: false, properties: { date: { type: "boolean" }, time: { type: "boolean" } } },
  outputSchema: { type: "object", properties: { datetime: { type: "keyword" } } },
  renderer: ({ frameworkParams, date, time }: { frameworkParams: FrameworkParamsNew, date?: boolean, time?: boolean }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    const [dateSelection, setDateSelection] = React.useState<Date>(new Date());

    React.useEffect(() => {
      setOutput({ datetime: dateSelection.toISOString() }, blockInst);
    }, [dateSelection, date, time]);

    //  TODO:  Support THEMES, and manual style overrides, and LOTS of customizations for these UI components!? HM!
    //         PLUS, make my own NICE default comopnents, maybe bubbly like Snap / FB UIs.
    //  TODO:  Support the other pieces of the date API!
    //  TODO:  Date AND Time is only supported on iOS... make a NEW component to fix this!? HM
    const mode = time && date && "datetime" || time && !date && "time" || !time && date && "date" || "date";
    return <DatePicker date={dateSelection} onUpdate={(dateObj) => setDateSelection(dateObj || dateSelection)} />

  },
  tags: ["component"],
  isComponent: true
}

export const gateBlock: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Gate",
  color: "#719367",
  description: "Passes a signal after the input is defined.",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "any" }, value: { type: "any" } } },
  outputSchema: { type: "object", properties: { output: { type: "any" } } },
  renderer: ({ frameworkParams, input, value }: { frameworkParams: FrameworkParamsNew, input?: any, value?: any }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    React.useEffect(() => {
      if (!!input) {
        setOutput({ output: value }, blockInst);
      } else {
        setOutput({ output: undefined }, blockInst);
      }
    }, [input, value]);

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

export const activeActivities: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Active Activities",
  color: "#719367",
  //  TODO:  Should we only support one at this level?
  description: "Displays the Active Activities",
  inputSchema: { type: "object", extensible: false, properties: {} },
  outputSchema: { type: "object", properties: {} },
  renderer: ({ frameworkParams }: { frameworkParams: FrameworkParamsNew }) => {

    return <Text>SHOULD SHOW ACTIVITY</Text>
    //  Unpack
    // const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    // //  TODO:  Grab the plugin from React Context!
    // const activityPlugin = Program.haliaStack?.getPlugin("activity").plugin as ActivityPlugin;

    // const [pending, setPending] = React.useState<ActivityInternal[]>([]);

    // const updatePendingEvents = async () => {
    //   const _pending = await activityPlugin.getPendingActivities(token);
    //   setPending(_pending);
    // }

    // //  TODO:  Consider auto-updating or 
    //   React.useEffect(() => {
    //     updatePendingEvents();
    //   });

    //   //  TODO-GENERAL:  MAYBE let the user choose how it's layed-out in a standard way!  Perrhaps multiple style systems / componnent syst4ems like widgets that the user can pop-through.
    // <View style={{ flex: 1 }}>
    //   {
    //     pending.map(activity => (
    //       <ActivityBubbleItem activity={ activity } isPending={ true } onPress={ () => alert("Pressed!") } />
    //     )) 
    //   }
    // </View>

  },
  tags: ["component"],
  isComponent: true
}

export const counter: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Counter",
  color: "#719367",
  description: "Counts Up",
  //  CONSIDER:  How do I feel about using Davel for the type system? hmm...
  inputSchema: { type: "object", extensible: false, properties: {} },
  outputSchema: { type: "object", properties: { count: { type: 'number' } } },
  renderer: ({ frameworkParams }: { frameworkParams: FrameworkParamsNew }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;
    const [count, setCount] = React.useState(0);

    const updateCount = () => {
      setCount(count + 1);
      setOutput({ count }, blockInst);
    }

    React.useEffect(() => {
      updateCount();
    }, [])

    React.useEffect(() => {
      setTimeout(updateCount, 1000);
    }, [count])

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

export const match: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Match",
  color: "#719367",
  description: "Checks for a Match",
  inputSchema: { type: "object", extensible: false, properties: { input: { type: "any" }, value: { type: "any" } } },
  outputSchema: { type: "object", properties: { match: { type: 'boolean' } } },
  renderer: ({ frameworkParams, input, value }: { frameworkParams: FrameworkParamsNew, input?: any, value?: any }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    React.useEffect(() => {
      //  TODO:  Support a PREDICATE... like checking if somethin is EVEN, etc.  Basically, just hmm... comparison block mm!
      //  CONSIDER:  HOW is any of this different from programming as it is???  The point is... instead of doing it all with text, we have a visual representation, AND it's reactive hmm...
      setOutput({ match: input == value }, blockInst);
    }, [input, value])

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

export const anyGate: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Any Gate",
  color: "#719367",
  description: "Allows ANY Signal to Pass",
  //  TODO:  Support unlimited inputs!
  inputSchema: { type: "object", extensible: false, properties: { s1: { type: "any" }, s2: { type: "any" }, s3: { type: "any" } } },
  outputSchema: { type: "object", properties: { output: { type: 'any' } } },
  renderer: ({ frameworkParams, s1, s2, s3 }: { frameworkParams: FrameworkParamsNew, s1?: any, s2?: any, s3?: any }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    React.useEffect(() => {
      setOutput({ output: s1 || s2 || s3 }, blockInst);
    }, [s1, s2, s3])

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

// export const selectedEntity: PrimitiveBlock = {
//   type: BlockType.Primitive,
//   name: "Selected Entity",
//   color: "#719367",
//   description: "Obtains the Selected Entity",
//   inputSchema: { type: "object", extensible: false, properties: { } },
//   outputSchema: { type: "object", properties: { selectedEntity: { type: 'any' } } },
//   renderer: ({ frameworkParams, selectedEntity }: { frameworkParams: FrameworkParamsNew, selectedEntity?: Entity }) => {

//     const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;


//     React.useEffect(() => {
//       setOutput({ output: s1 || s2 || s3 }, blockInst);
//     }, [s1, s2, s3])

//     return null;

//   },
//   tags: ["inline"],
//   isComponent: false
// }

export const associateTag: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Associate Tag",
  color: "#719367",
  description: "Associate a Tag with an Object",
  inputSchema: { type: "object", extensible: false, properties: { tag: { type: serializedInstanceInternalSchema }, object: { type: serializedInstanceInternalSchema } } },
  outputSchema: { type: "object", properties: { tagAssociation: { type: serializedInstanceInternalSchema } } },
  renderer: ({ frameworkParams, tag, object }: { frameworkParams: FrameworkParamsNew, tag?: InstanceInternal, object?: InstanceInternal }) => {

    //  Guard
    if (!tag) {
      return null;
    } else if (!object) {
      return null;
    }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Create an "Effect" to handle data flow re-run
    React.useEffect(() => {
      haborSDK.createInstance<Relationship>({
        nounId: "tag-association",
        payload: {
          srcId: {
            nounId: tag.nounId,
            instanceId: tag.id,
            type: EntityType.Instance
          },
          destId: {
            nounId: object.nounId,
            instanceId: object.id,
            type: EntityType.Instance
          }
        }
      }, token).then(tagAssociation => {
        setOutput({ tagAssociation });
      })
    }, [tag, object]);

    return null;

  },
  tags: ["action"],
  isComponent: false
}

//  TODO:  This type of block should come from the Project or Category Plugin!  MAYBE simplify this, export a low-level primitive, and build up the Project filter composed with an "Owner Relationship" filter??
//  CONSIDER:  Do we want to ONLY support "IntanceInternal" here?  OR, should we keep the input open?  FOR NOW, and for this particular filter example, let's only support InstanceInternal[] here.
//  TODO:  'projectId' SHOULD be required.  BUT, when we make the type required, it fails the type inspection!
export const filterComponent: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Project Filter",
  icon: { name: "filter", type: "materia" },
  description: "Filters data to a particular 'Project'",
  inputSchema: { type: "object", extensible: false, properties: { projectId: { type: "noun", nounId: "project" }, items: { type: "array", itemType: { type: "object", extensible: true } } } },  //  TODO:  In the real Project Filter block, it should ACTUALLY reference instances of the "Project" noun!  SO, MAYBE use a "Noun" type here?  ALSO, we SHOULD potentially obey any implied CONTEXT that exists, like a scope to the current Workspace.  FOR NOW, all that is buil-in though!
  outputSchema: { type: "object", extensible: false, properties: { filteredItems: { type: "array", itemType: { type: "object", extensible: true } } } },
  renderer: ({ frameworkParams, projectId, items = [] }: { frameworkParams: FrameworkParamsNew, projectId?: string, items?: InstanceInternal[] }) => {

    //  Guard
    if (!projectId) {
      throw new Error("Undefined Project ID");
    }

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    //  Define the Enabled State for the filter and the filtered items list
    const [enabled, setEnabled] = React.useState(false);
    const [filteredItems = [], setFilteredItems] = React.useState(items);

    //  Create an "Effect" to handle data flow re-run
    React.useEffect(() => {
      const filter = async () => {
        //  TODO:  Support DEFAULT value inputs.
        if (items == undefined) { return; }
        //  TODO:  Include owner relationships in the initial query WHEN its a required field in the child blocks!  This COULD potentially be bubbled up to the query resolver in the future!  FOR NOW, we'll continue doing thing imperatively!

        //  Get Outgoing OwnerRelationships
        //  TODO:  STOP doing manual queries through low level systems like OwnerRelationhips!  Instead, use a function from a plugin which encapulates that abstraction!
        const terms: SearchTerm<InstanceInternal<OwnershipRelationship>>[] = items.map(item => ({
          match: {
            payload: {
              srcId: {
                nounId: item.nounId,
                instanceId: item.id,
                type: EntityType.Instance
              },
              destId: {
                nounId: "project",
                instanceId: projectId,
                type: EntityType.Instance
              }
            }
          }
        }));
        const params = { nounId: OwnershipRelationshipNoun.id, search: { any: terms } };
        const ownerRels = await haborSDK.searchInstances(token, params);

        //  Get Filtered Instances
        const itemsInProject = ownerRels.map(rel => rel.payload.srcId.instanceId);
        const filtered = enabled ? items.filter(item => itemsInProject.indexOf(item.id) != -1) : items;

        //  Set the Output
        setOutput({ filteredItems: filtered }, blockInst);
        setFilteredItems(filtered);
      }
      filter();
    }, [items, enabled]);

    //  Render the Filter
    return (
      <Card innerStyle={{ padding: medSpacer }} outerStyle={{ flex: 1 }}>
        <Text style={{ fontFamily: primaryFontFamilyHeavy }}>FILTER BLOCK</Text>
        <Switch onValueChange={() => setEnabled(!enabled)} value={enabled} />
        <TitleChiclet title={filteredItems.length.toString()} />
      </Card>
    );
  },
  tags: ["component", "inline"],
  isComponent: true
}

export const namedObjectListComponent: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Named Object List",
  icon: { name: "list", type: "font-awesome" },
  description: "Displays a list of named objects.",
  inputSchema: { type: "object", extensible: false, properties: { items: { type: "array", itemType: { type: "object", extensible: true } } } },
  outputSchema: { type: "object", extensible: false, properties: { itemPress: { type: "any", required: false }, itemLongPress: { type: "any", required: false } } },
  renderer: ({ frameworkParams, frameworkParams: { frameworkContext, componentContext }, items = [] }: { frameworkParams: FrameworkParamsNew, items?: any[] }) => {

    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    const [itemPress, setItemPress] = React.useState(undefined);
    const [itemLongPress, setItemLongPress] = React.useState(undefined);


    React.useEffect(() => {
      setOutput({ itemPress, itemLongPress }, blockInst);
    }, [itemPress, itemLongPress, items]);

    //  Render the Filter
    return (
      <FlatGrid
        data={items}
        renderItem={({ item }) => <InstanceListItem
          onPress={() => setItemPress(item)}
          onLongPress={() => setItemLongPress(item)}
          frameworkContext={frameworkContext}
          componentContext={componentContext}
          item={item}
        />}
        style={{}}
        itemDimension={300}
      />
    );
  },
  tags: ["component", "inline"],
  isComponent: true
}

//  TODO:  Ok.. if I have a LIST, HOW is that represented in Hessia?  It SHOULD have a TYPE associated with it, and THAT should potentially have FUNCTIONS? Hmm.. DO we have methods, OR is EVERYTHING function!?  Hmmmm...

//  TODO:  We SHOULD be able to render based on ANY enum, not JUST Status... For example, if we wanted to render based on Project!  OR day of the week, etc!  FOR NOW, we can render based on a Status.s
export const kanBanComponent: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "KanBan",
  icon: { name: "cards", type: "material" },
  description: "Renders objects on a KanBan board grouped by Status.",
  inputSchema: { type: "object", extensible: true, properties: { input: { type: "array", itemType: { type: "object", extensible: true } } } },
  outputSchema: { type: "object", extensible: false },
  renderer: ({ frameworkParams: { frameworkContext, componentContext }, input = [] }: { frameworkParams: FrameworkParamsNew, input?: InstanceInternal<NamedObject>[] }) => {

    //  Render the Filter
    return (
      <KanBanBoard frameworkContext={frameworkContext} componentContext={componentContext} whitelist={input} />
    );
  },
  tags: ["component", "inline"],
  isComponent: true
}

//  TODO:  I'd like to be able to press a BUTTON to activate the SPEECH!  HOW can I do that?  Perhaps it's like an event? Hmm...  Not 100% certain YET, but it shouldn't be too hard.. let's just get SOMETHING working for that!?  HMMM!??  MAYBE I can make it MOUNT when it's pressed?  I THINK I'd want it to be de-coupled though?  Hmm..  PERHAPS I can just INCREASE a counter or something so it's like it triggers a re-render.. BUT I'm not sure that's exaclty the interface I want.. because it CAN re-render for other reasons too!? HM...  FOR NOW, let's just get it in the pipeline!?

//  TODO:  We are currently using a mock "InstanceInternal" so we can use the same ID pattern for the entire component system.  Perhaps we should consider re-arranging this, and keeping "ID" inside the payload or included in some other wrapper than just InstanceInternal?
//  NOTE:  I really like that we have the block system working, and if we keep iterating, eventually we can fix this and remove the "Internal" concept becaue I really don't think it belongs here!
//  CONSIDER:  Instead of tagging everything here in the primitive system, CONSIDER actually using Hessia systems for this!?? Hmm!!!
//  CONCERN:  I can't really upload a JS function to the DB... can I??? Hmm... MAYBE I CAN??? Dang.. interestng!
export const primitiveBlocks: { [blockId: string]: InstanceInternal<PrimitiveBlock> } = {
  "query": { id: "query", payload: queryPrimitiveBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "create-instance": { id: "create-instance", payload: createInstance, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "add": { id: "add", payload: addPrimitiveBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "filter": { id: "filter", payload: filterComponent, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "named-object-list": { id: "named-object-list", payload: namedObjectListComponent, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "kan-ban": { id: "kan-ban", payload: kanBanComponent, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "event-listener": { id: "event-listener", payload: eventListenerPrimitiveBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "navigation": { id: "navigation", payload: navigationBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: 'N/A' },
  "card": { id: "card", payload: cardBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "text": { id: "text", payload: textBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "pager": { id: "pager", payload: pagerBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "extractor": { id: "extractor", payload: extractorBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "elementAt": { id: "elementAt", payload: elementAt, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "count": { id: "count", payload: countBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "division": { id: "division", payload: divisionBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "speech": { id: "speech", payload: speechBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "button": { id: "button", payload: buttonBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },  //  TODO-GENERAL:  Support a STYE framework.. MAYBE just mapping React Native primitive CSS API for now?  NOTE, this is different from the "CSS-like" API I keep referring to for Context!!!
  "object": { id: "object", payload: objectBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "map": { id: "map", payload: mapBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "modal": { id: "modal", payload: modalBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "not": { id: "not", payload: notBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "processRunner": { id: "processRunner", payload: processRunner, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "associateTag": { id: "associateTag", payload: associateTag, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "dateField": { id: "dateField", payload: dateField, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "gate": { id: "gate", payload: gateBlock, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "activeActivities": { id: "activeActivities", payload: activeActivities, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "counter": { id: "counter", payload: counter, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "match": { id: "match", payload: match, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  "any": { id: "any", payload: anyGate, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
  // "selected-entity": { id: "selected-entity", payload: selectedEntity, updated: new Date().toISOString(), created: new Date().toISOString(), nounId: "N/A" },
}

//  CONSIDER:  Instead of SIGNALS to re-trigger a block, consider just passing the block?  Hmm.. similar to JS passing a callback?  Hmm... interesitng.. MAYBE ew can have a BLOCK that when it receiveds a signal, will trigger a block? HJMM!??  SEVERAL ways to do this I guess?  Make Events explicit or not.  Make blocks passable and trigger, OR just trigger "events", OR just keep using the SIGNALS and ALLOW arbitrary "trigger" signals... etc.. hmmm  that's what I'm doing FOR NOW.

//  TODO:  Support TRIGGER dependencies, which JUST re-trigger but perhaps DON'T come in as props!
//  TODO:  Support feedback by passing the OUTPUT back to the INPUT for ALL blocks!?  BUT, perhaps NOT depending upon it!  THis way, it doesn't cause a retrigger, BUT it's AVAILABLE.. now.. IF one wanted to make a block with retriggering feedback, we COULD do that as well??

//  TODO:  I SHOULD be able to make Blocks from RIGHT within the system!?  IF blocks are limiting, PERHAPS we can open REGUALR Functions AND Components as well!?  Hmm.. PERHAPS.. perhaps eventually we build the more derived systems using the others??? Hmmm.  FOR NOW, let's keep working on Blocks?  Hmmm...

//  TODO-NEXT:  Need a way to QUERY for just TODAY'S observations.  THEN, INSPECT that to determine HOW many we've added.  
//  Do the query for ALL Habits, and DIVIDE.  THEN use that as input to a custom component to show the Grade.  It just needs some conditionals..

//  OK... now I can pass a BLOCK, what does that mean?  Hmm... I DO want a "Pager".. it would be easy to switch between Pages, but what about Blocks?  ESP blocks instantiated in the block context?  Hmm...  So... first perhaps I need to d that?  Or.. maybe it doesn't need to be instantiated in this context, but the props need to be mapped?  Hmm... Then, perhaps I can make a user block?

export const userBlocks: { [blockId: string]: InstanceInternal<UserBlock> } = {};


//  TODO:  Support references to OTHER blocks in the DB too, AND support the creation of these UserBlocks?? HM!
//         I THINK this is quite similar!? HMm!/MAYBE with context specific stuff?? Hmm
export const getAllBlocks = () => {
  return {
    ...primitiveBlocks,
    ...userBlocks
  };
}

//  THOUGHT:  EACH block has SEVERAL inputs... the data flow input, which is runtime.  The dev settings, and the user settings!  The user settings MAY jut be modeled with the input??



export const resolveBlock = async (token: string, blockId: string) => {

  //  Get the Blocks
  //  TODO:  IF we replace 'getAllBlocks" with a direct reference to the dictionary, it sporadically comes in empty.. I BELIEVE it MAY be due to cyclical imports and a potential race condition?
  const builtInBlock = getAllBlocks()[blockId];  //  TODO:  Eventually make a common method to obtain blocks, includng primitives AND user defined?
  console.log("Test");
  if (builtInBlock) {
    return builtInBlock;
  }

  //  Get Saved User Blocks
  const savedUserBlocks = await haborSDK.searchInstances<SerializedUserBlock>(token, { nounId: serializedUserBlockNoun.id, search: { match: { id: blockId } } });
  if (savedUserBlocks && savedUserBlocks.length > 0) {
    return { ...savedUserBlocks[0], payload: deserializeUserBlock(savedUserBlocks[0].payload) };
  }
}

/**
 * Returns Block OR a Component depending on which is found first with the given ID.
 * TODO:  Remove this once we're using "Blocks" for all components!??
 * TODO:  WHY Shuld this be here?  COnsider moving to Comopnent... it's a COPULING between Component and Blocks hmm.. move to coupling plugin OR make Composer depend on Comopnent etc hmm..  Maybe build a general interface?
 * @param token 
 * @param componentId 
 */
export const resolveComponent = async (token: string, hessia: HessiaPlugin, componentId?: string) => {

  //  Guard Undefined
  if (!componentId) { return; }

  //  Get the Habor Component
  // alert("Resolving: " + componentId)
  //  TODO:  Grab through React COntext!
  const haborComponent = hessia.getHaborComponent(componentId);  //  TODO:  Eventually remove the "pageComponent" in place of blockComponent!
  if (haborComponent) {
    return { haborComponent };
  }

  //  Get the Block
  const block = await resolveBlock(token, componentId);

  if (block) {
    return { blockComponent: block };
  }

  throw "Could not find component or Block with ID: " + componentId;

}

