import { HaborIconSelection, NamedObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native'; //  TODO:  Should use the "react-native" alias here. 
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { defaultBorderRadius, primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { Entity, EntityPlugin, Identifiable } from './entity-plugin';
import { HessiaPlugin } from './hessia-plugin';
import { SystemPlugin } from './system-plugin/system-plugin';

//  CONSIDER:  Instead of passing all this info... maybe use the default access sysstme.. the idea is.. things wcan grab what they want in context hm..also, maybe we can resolve icon and iconColor from the type? Hmm.. basicaly like inheritance? hm
export interface IdentityInfo {
  name?: string;
  type: string;
  description?: string;
  icon?: HaborIconSelection;
  iconColor?: string;
  iconBackgroundColor?: string;
  value?: string;  //  CONSDIER:  We CAN make another injection for like.. a SUB view .. this is just providie one PIECE
  component?: any;
}

export interface IdentityProvider extends Identifiable, NamedObject {
  systemId: string;
  provide: (entity: Entity, entityPlugin?: EntityPlugin) => Promise<IdentityInfo> | IdentityInfo | undefined;
}

export class EntityIdentityPlugin extends CorePluginClass {

  public static details = {
    name: 'Entity Identity Plugin',
    description: 'Provides Entity Identity',
    dependencies: [EntityPlugin.details.id, HessiaPlugin.details.id, SystemPlugin.details.id],
    id: 'entityIdentityPlugin'
  }

  public entityPlugin!: EntityPlugin;

  //  CONSIDER:  Can we generalize the "Provider" pattern?
  public identityProviders: IdentityProvider[] = [];
  public registerIdentityProvider = (provider: IdentityProvider) => {
    this.identityProviders.push(provider);
  }

  //  TODO:  Register "Types" as entities too.  Which are PROJECTED.

  public install = async (program: Program, { pEntities, hessia, system }: { pEntities: EntityPlugin, hessia: HessiaPlugin, system: SystemPlugin }) => {

    this.entityPlugin = pEntities;

    this.registerIdentityProvider({
      id: "entity-entity-identity-provider",
      name: "Entity Entity Identity Provider",
      description: "Provides 'entity' Entity Identity",
      systemId: "entity",
      provide: async (entity: Entity): Promise<IdentityInfo> => {

        //  If *NO* systemId is specified, we show this as an "Entity" because no other system is showing it.  
        //  CONSIDER:  We MAY want an explicit Entity service and show this as an "Unknown" entity instead?

        if (entity.systemId) { return undefined as any; }

        return {
          type: "entity",
          icon: { name: "crop-square", type: "material" },
          iconColor: "#727643",
          iconBackgroundColor: "#647486",
          name: "Entity",
          value: "This is an entity"
        } as IdentityInfo;

      }
    });

    pEntities.registerEntityListItemRenderer({
      name: "Identity Provider Service",
      description: "Show Entity Identity",
      id: "identity-provider-service",
      renderer: async (entity, api) => {

        //  CONSIDER:  Do we REALLY want to loop through ALL the registered systems?? Hmmm
        for (const provider of this.identityProviders) {
          const identityInfo = await provider.provide(entity, this.entityPlugin);
          if (identityInfo) {
            const { name, description, type, icon, iconColor, value, iconBackgroundColor, component: Component } = identityInfo;
            api.addComponent(() => {
              return (
                <View style={{ backgroundColor: iconBackgroundColor, borderRadius: defaultBorderRadius, padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, flexDirection: 'column' }}>

                  {
                    icon && iconColor && (
                      <View style={{ position: 'absolute', top: 20, left: 20, width: 50, height: 50, backgroundColor: 'rgba(255,255,255,0.2)', padding: 15, borderRadius: defaultBorderRadius, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Icon  type={icon.type} name={icon.name} color="white" size={25} />
                      </View>
                    )

                  }

                  {
                    !!value && <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 20, color: 'white' }}>{value}</Text>
                  }

                  {
                    Component && <Component />
                  }

                </View>
              );
            })
          }
        }
      }
    })

    //  IDEA:  AHH!! Consider a system that AUTOMATICALLY registers stuff based on what we DO "know" mm!  Then tis' KINDA like inheritnace hm!  PROJECTION system mm!!!

    system.registerPrimitiveSystem({
      id: "type",
      name: "Type",
      description: "A Type System (used to map an encoding to another encoding)",
      menuItem: { icon: { name: "dot", type: "material" }, backgroundColor: '#aaaaaa', iconColor: 'red' },

      //  CONSIDER:  Shouldl be able to specify "List of X" and "Create Button" at this high level.
      //  CONSIDER:  Can make it possible to "create" new types from within the app.  For now, it'll be registered.
      component: () => (
        <>


          {/* CONSIDER:  I WOULD like to beable to just say it!  Button which shows the item and links to the system.  The IDEA is... the style / implementation of the button can be dynamic hmm... I can have explicit mappings, OR I can project from what I've learned mm!! */}
          {/* CONSIDER:  We DO have a way to do this by displaying it as an ENTITY.  Hmm... This is one way, but yeah hmm... FOR NOW, let's just liste it as a simple button ugh. */}
          <FlatGrid scrollEnabled={true} data={this.identityProviders} renderItem={({ item }) => <TouchableOpacity onPress={() => { hessia.navigation.goBack(); system.openSystem(item.systemId); }}><Text>{item.name}</Text></TouchableOpacity>} />
        </>
      ),
      labels: []
    });

    return this;
  }
}