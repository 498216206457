import { Color, EntityType, InstanceInternal, NamedObject, Relationship } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, Text, View } from "react-native";
import { DavelField } from "../../../../packages/davel-ui/davel-ui-tools";
import { renderRGBAColor } from "../../../../packages/davel-ui/habor-types/color/color-field";
import { largeSpacer, medSpacer, smallSpacer } from "../../../../packages/kelp-bar/styles";
import { TextSubParagraph } from "../../../../packages/kelp-bar/text";
import { GalleryButton } from "../../../gallery/components/common";
import { primaryColor } from "../../../gallery/constants";
import { HaborContainer, PrimitiveProps } from "../../component-plugin/habor-react/habor-component-lib";
import { createOrUpdateInstance } from "../../habor-plugin/habor-utils";
import { HessiaPlugin } from "../../hessia-plugin";
import { AppContext } from "../../hessia-plugin/AppContext";
import { haborSDK } from "../../hessia-plugin/config";
import { WidgetItem } from "../../hessia-plugin/dynamic-component";
import { FilterComponent, FilterComponentProps } from "../../hessia-plugin/filter-utils";
import { ModelPlugin } from '../../model-plugin';
import { Chiclet } from "../../primitives-plugin/habor-components/embedded-views/chicklet-component";

//  TODO-TS:  Type everything in this module.

export interface PriorityComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal;
  }
}
interface PriorityComponentPrimitiveState {
  priority?: InstanceInternal;
  priorityAssociation?: InstanceInternal;
  priorities: InstanceInternal<NamedObject>[];
}

//  CONSIDER:  There are LOTS of common patterns emerging here that we can use in LOTS of other components!?
//  CONCERN:  What about HISTORY!?  SHOULD we support HISTORIC values?  Hmmm...  FOR NOW, perhaps not?  BUT I think this WOULD be really nice to have... PERHAPS we do this be associating SEVERAL relationships.  OR, perhaps that means we have several Priorities?  OR, perhaps we have a standard version system for ALL of Habor, INCLUDING the relationsihp?  Ah!  I THINK that makes sense!  SO, instead of adding a new relationship or whatever, we use our API to EDIT it.  AND, perhaps we can see how the associated Priorities changed over time as well!?  MAYBE we can just use Tags for this, or perhaps we can build a NEW priority system with nubers??? SO cool!  PLUS, perhaps we can SCOPE the applicability of these systems and ACL, ALL that stuff???
//  TODO-GENERAL:  Loading (full-screen / piecemeal), Scope / Context, Habor System SDKs / APIs!?, Components for a LOT of this stuff, common PATTERNS in the Models / interaction of this system, like the "Array" concept???

class PriorityComponentPrimitive extends React.Component<PriorityComponentPrimitiveProps, PriorityComponentPrimitiveState> {
  constructor(props: PriorityComponentPrimitiveProps) {
    super(props);
    this.state = {
      priorities: []
    }
  }

  public getSelectedPriority = async (assignment: InstanceInternal<any>) => {
    if (!assignment) { return undefined; }
    const priority = (await haborSDK.searchInstances(this.props.frameworkProps.context.token, { nounId: "priority", search: { match: { id: assignment.payload.srcId.instanceId } } }))[0];
    return priority;
  }

  public getAllPriorities = async () => {
    const priorities = await haborSDK.searchInstances(this.props.frameworkProps.context.token, { nounId: "priority" });
    return priorities;
  }

  public componentDidMount = async () => {

    // Get the Priorities

    const { instance } = this.props.userProps;

    const priorityAssociations = await haborSDK.searchInstances(this.props.frameworkProps.context.token, { nounId: "priority-assignment", search: { match: { payload: { destId: { instanceId: instance.id } } } } })

    //  CONSIDER:  Instead of ONE enablement of an Addon / system per Element, consider MULTIPLE, potentially NAMESPACED enablemenets???
    // if (priorityAssociations.length > 1) { throw new Error("Multiple priorities for the same element are not currently permitted."); }
    console.log("Multiple priorities defined for " + instance.id);
    const selectedAssociation = priorityAssociations[0];

    const priority = await this.getSelectedPriority(selectedAssociation);

    //  TODO:  Just do the ONE qery instead of BOTH this and the previous???
    const priorities = await this.getAllPriorities();
    this.setState({ priority, priorities, priorityAssociation: selectedAssociation });
  }

  //  CONSIDER:  A way to quickly link to the page to create new Priorities in this context!?  This is definitely in part Kelsi's idea??

  public setPriority = async (priorityId: string) => {
    const { instance } = this.props.userProps;
    const { priorityAssociation } = this.state;
    const newPriorityAssociation = {
      srcId: {
        instanceId: priorityId,
        type: EntityType.Instance,
        nounId: "priority"
      },
      destId: {
        instanceId: instance.id,
        type: EntityType.Instance,
        nounId: instance.id
      }
    }

    //  COSIDER:  It's interesting that in SOME contexts we know know that we have ONE instance that we're updating and we DON'T need to know the ID in a staatic context... we query to get the appropriate one (or multiple) and then edit???
    try {
      const newPriorityAssociationInternal = await createOrUpdateInstance("priority-assignment", newPriorityAssociation, this.props.frameworkProps.context.token, priorityAssociation);

      const priority = await this.getSelectedPriority(newPriorityAssociationInternal);
      this.setState({ priorityAssociation: newPriorityAssociationInternal, priority });
    } catch (err) {
      alert(err);
    }

  }

  public render = () => {

    //  Unpack
    const { context, componentContext } = this.props.frameworkProps;

    const { priority, priorities } = this.state;

    // {/* CONSIDER:  Instead of giving each registered componetn FREE range, we can register with a name and stuff and standardize the WRAPPING.. which may have multiple implementations.. like list, accordian list, tabs, pages, cards, etc... Basically, we DO want to de-couple the parent UI / layout!?? */}

    //     {/* CONSIDER:  When we DO define priorities, we can do it with a "Lense" or "Scope".  The idea being, they are ACTIVE only when a condition is met!  NOT necessarily the condition of being "in" a certain context? hmm.. I think it's the same!  They activate when a condition is met hm! */}

    const selectedPriority = priority && priority.payload.name;

    return (
      <HaborContainer frameworkProps={this.props.frameworkProps} style={{ display: 'flex', flexDirection: 'row', marginBottom: largeSpacer }}>

        {
          priorities.length ?
            <TextSubParagraph>{selectedPriority ? selectedPriority : 'No Selection'}</TextSubParagraph> :
            <TextSubParagraph>No Priorities Defined</TextSubParagraph>
        }

        {/* NOTE:  At this point we COULD assume the structure of the Priority, BUT perhaps OTHER systems will want to influence this??? */}
        <View style={{ marginTop: medSpacer, display: 'flex', flexDirection: 'row' }}>
          {
            priorities.map(currPriority => {
              const { color, name } = currPriority?.payload;
              //  CONSIDER:  We use "WHITE" as the text color... PERHPS we can either select a text color with high contrast from the base, OR explicitly select ONLY colors that make sense for white?  Hmmm... BUT then we can ALSO have a DARK mode!?  Interesting!??  MAYBE WE CAN CHOOSE ALL COLORS WHERE WHITE OR BLACK WOULD WORK??  HMMMM!???
              //  TODO-UI:  Make the chiclets scroll, OR support DIFFERENT layouts / selections for this UI?  AGAIN, it should use the SAME selection system as ALL the other UIs!?  Associaetd with settings and shit in a CONTEXT!???  POSSIBLY a UI and / or DATA context, and MAYBE controllable by OTHER factors as well, like constraints and data.. maybe that's just a DATA context??? Hmmmmm...  MAYBE just use a GRID???
              //  TODO-UI:  Consider how to ensure the WIDTH is the same.. MAYBE for use in this UI we need SPECIFIC, SUITABLE data?? OR, CAN we use the original???  Huh?!!
              return (
                <Chiclet style={{ backgroundColor: selectedPriority === name ? color && renderRGBAColor(color) : '#eeeeee', marginLeft: smallSpacer, paddingHorizontal: 40, height: 40, borderRadius: 20 }} onPress={() => this.setPriority(currPriority.id)} >
                  <TextSubParagraph style={{ color: Color.white }}>{name}</TextSubParagraph>
                </Chiclet>
              )
              // <NamedObjectItemComponent item={ priority.payload } onPress={ () => this.setPriority(priority.id) } />
            }
            )
          }
        </View>
      </HaborContainer>
    );
  }
}

// export const PriorityComponent: HaborComponent = {
//   name: "PriorityComponent",
//   propsSchema: {
//     type: "object", 
//     extensible: false, 
//     properties: { 
//       instance: { ...serializedInstanceInternalSchema, required: false },
//     } 
//   },
//   //  CONCERN-GENERAL:  This is a SIMPLE mapping from input down through to the user-level components.  We don't use React Components because they're converted and represented in JS, and we don't want to run user code in OUR interpreter as a security concern.. AND perhaps also as a usablity concern.. we want to express our OWN language and expose salient features as needed instead of ALL the JS / React / TS stuff???  INSEAD, use "Blocks" which have a MUCH more generic props passing mechanism, using OTHER blocks to process them and stuff!??
//   element: {
//     name: "priority-detail-component-primitive",
//     props: {
//       instance: { type: "symbol", scopePath: ["props", "instance"] },
//     },
//     children: []
//   }
// };


//  TODO-GENERAL:  A LOT of this stuff can probably be generalized and abstracted for re-use in OTHER systems?  MAYBE we hav ea STANDARD filter registrtion? Hmm... this way we on't need to do it manually each time?? Hmm..  MAYBE some systems do, OR if we "extend" or USE a SYSTEM TEMPLATE!???  THAT WAY, it's PREDICTABLE!?
export interface PriorityFilterProps {
  onChange: () => void;
  instances: InstanceInternal[];
}
interface PriorityFilterSettings {
  priorities?: InstanceInternal<NamedObject>[];
}
class PriorityFilterComponent extends FilterComponent<PriorityFilterSettings> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: FilterComponentProps) {
    super(props);
    this.state = {
      allPriorities: []
    }
  }

  public componentDidMount = async () => {

    const { token } = this.context.frameworkContext;

    //  Get Priorities
    //  CONSIDER:  This MAY change per CONTEXT!?

    //  TODO:  Abstract this to BOTH Primitive and User Functions in the "Priority Plugin".  
    //  CONCERN:  Figure out the relationship between Halia Plugin and Hessia System.s
    const allPriorities = await haborSDK.searchInstances(token, { nounId: "priority" });

    this.setState({ allPriorities });

  }
  public render = () => {

    const { setSettings, settings = { priorities: undefined } } = this.props;
    const selectedPriorities = settings.priorities;
    const { allPriorities } = this.state;

    //  TODO:  Get the proprity plugin!!!
    const priorityPlugin = {} as any;

    const selectedIds = selectedPriorities?.map(priority => priority.id);

    //  TODO-IMPORTANT:  Here, we're accessing the "Priority Plugin" Halia Plugin instance directly, INSTEAD of with injection... PLUS, a piece of state is being held OUTSIDE the React State tree... are EITHER of these two things acceptable to me!?
    return (
      <WidgetItem name="Priority Filter" color="#eeeeee">

{

          allPriorities.map(priority => (
            <GalleryButton style={{ backgroundColor: selectedIds?.includes(priority.id) ? primaryColor : '#eeeeee' }} title={priority.payload.name} onPress={() => { priorityPlugin.filterSelection = priority; setSettings({ priorities: [priority] }); }} />
          ))
        }
        <Button title="Unassigned" onPress={() => { priorityPlugin.filterSelection = null; setSettings({ priorities: undefined }) }} />
        <Button title="All" onPress={() => { priorityPlugin.filterSelection = undefined; setSettings({ priorities: allPriorities }); }} />
      </WidgetItem>
    )
  }
}

//  CONSIDER:  I just needd to depend on the "Entities" plugin because I'm encoding my knowledge that it creates the "instance-creator-bottom" prmitive register.  PERHAPS I can actually refer to that type to make this dependenc explicit INSTEAD of using a string which effectively COUPLED the systems?  HOWEVER, in other cases, perhaps I DON'T need to reference something but need someting to exist FIRST for whawtever reason?
export class PriorityPlugin extends CorePluginClass {

  public filterSelection: InstanceInternal<NamedObject> | undefined | null = undefined;

  public static details = {
    name: "Priority",
    description: "Priority Plugin",
    dependencies: [HessiaPlugin.details.id, ModelPlugin.details.id],
    id: "priority"
  }

  public install = (program: Program, { hessia, modelPlugin }: { hessia: HessiaPlugin, modelPlugin: ModelPlugin }) => {

    //  CONSIDER:  Instad of wrapping in a user component, it seems we can invoke the primtive directly?  I THINK the MAIN point is the registration of PROPS / TYPES???
    //  NOTE:  INSTEAD of pushing into a PRIMITIVE regisration point, this is done in HESSIA!!!  It's a distinction that's important!
    hessia.registerPrimitiveComponent("priority-detail-component-primitive", PriorityComponentPrimitive);
    // hessia.registerComponent(PriorityComponent);

    //  TODO-CRITICAL:  Users should be able to make APP level Filters using blocks and stuff!  Shoud be able to register all this themselves.
    modelPlugin.registerFilter("priority", async (instances, token, settings: PriorityFilterSettings = { priorities: undefined }) => {

      const { priorities } = settings;

      //  TODO:  Highly inneficient... I EALLY want a way to express all this stuff in a query that can be optimzed in the backend???

      //  Check "All" Case
      // if (priorities === undefined) { return instances; }

      const updatedInsts: InstanceInternal[] = [];

      const searchTerms = instances.map(instance => ({ match: { payload: { destId: { instanceId: instance.id } } } }));
      const priorityAssignments = await haborSDK.searchInstances<Relationship>(token, { nounId: "priority-assignment", search: { any: searchTerms } });

      for (const inst of instances) {
        //  TODO:  To simplify this, FOR NOW, we can make ONE query for all the assignments then match against the instances?
        //  TODO:  Handle the case of MULTIPLE Priority Assignments, OR ensure that's NOT possible with some registered rule? AND make that show up in the UI so we KNOW it's NOT an option to select from multiple when we're editing this in Hessia!?? HMMM!???  DYNAMIC things should depend on other systems for their fullfilment??? Hmmm... Like, if a RULE is dynamic and a type MAY be arrary or not based on some condition?  HMm.. MAYBE the corresponding UIs are ALSO dynamic then??? HHmmm...
        const assignment = priorityAssignments.find(assignment => assignment.payload.destId.instanceId === inst.id);

        //  Check "Unassigned" Case
        if (priorities === undefined) {
          if (!assignment) {
            updatedInsts.push(inst);
          } else {
            continue;
          }
        }

        //  Check Specific Selection
        else {
          if (assignment) {
            priorities.forEach(priority => {
              if (assignment.payload.srcId.instanceId === priority.id) {
                updatedInsts.push(inst);
              }
            })
          }
        }
      }
      return updatedInsts;
    });

    modelPlugin.registerFilterComponent("priority", PriorityFilterComponent);

    return this;
  }
}
