
//  TODO:  Update Extensions
//         Model Instances
//         Model / Type Integration (Note, there's unlimited here.  But it's a start)
//         Instance View in Models

//  Core Data:  A system which lets the user encode a blob.  THEN we have plugins for interpreters.  Key component is being able to modify an existing.  Meaning, being able to ah!  It's the same as a symbol.  A blank.  Being able to write that to a certain location.  This is the same as Turing!  So build a thing where we have spaces, and we can move left / right, but where we don't have addresses.  Key point is we have *state* which is something that changes as we go along and changes our INSTRUCTION!  Instead of doing all of that, we build a way to create encodings which will have IDs.  We COULD always interpret that as two encodings (one for the number in one square, and another for the value in another square).  So, to start, we want to give users the ability to encode their data with an identifier.  Then TYPE and all of that can be built using the "relation" system.  BUT we'll simplify by bringing that into the model I suppose. 
//  Core UI (Hessia Core):  A registration system where we register systems for display.  We MAY want to additionally model those USING the system itself down the line.  That MAY be a primary differentiator for Hessia.  This is a feedback loop between human and computer (HCI) and it's the entry point for influence (both ways).

//  Elief
//  Rimitive

//  Could use a separate system for that, but not sure.  Key here is when we key off the idea of type we need to do it in the primitive system or we need to do it with functions, which is the common langauge between systems.  Basically it's the domain level language they can use.  To do things like check for a type on an entity.  We might have logic to check for a particular thing. Like we would without the seprate systems.  Can't treat it as a property, because of potential naming conflicts.  So, we can treat it like a "most likely" scenario.  It's fine to have conflicts as long as we can differentiate.  Just encodings around.  Thing is, if we need to model relationship, then why do it separatly?  The idea is we *can* have multiple instances too but with shared logic.  We can have the encoding in this system have that, or we can have separate systems house it but with the same encoding core....  Same as having an entry in the context table (the thing I built which is just encodings and / or their context).  Separate means we need to have a SYSTEM to observe in a consistent way.  It SEEMS to mean we need to differentiate by system.  Which... may be ok.  These systems don't need to encode their values in this central system although they CAN if they want to.  Point is.. we don't just need ONE thing to model everything!  That's a KEy point.  Instead we can model those pieces separately.  So... what to do with type is interesting.  I like the idea of keeping it pure.  Then, it's just a thing that's associated.  It MIGHT not be as efficient, but we can have a circuit for that.  The idea is, we can have a type for "Relationship", and we can make instances of that, and we can have a "Category" system that depends on that SPECIFIC piece mm!

import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { StackNavigationProp, createStackNavigator } from "@react-navigation/stack";
import * as Davel from 'davel';
import { CorePluginClass } from "halia";
import * as React from 'react';
import { Button, Clipboard, FlatList, Image, Pressable, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import Modal from "react-native-modal";
import { Checkbox, DataTable } from "react-native-paper";
import { Path, Svg } from "react-native-svg";
import { registerDavelType } from "../../packages/davel-ui/davel-ui";
import { DavelField, SDTRendererParams } from "../../packages/davel-ui/davel-ui-tools";
import { registerSDTSchema } from "../../packages/davel-ui/types/sdt/sdt-field";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { primaryFontFamily } from "../../packages/kelp-bar/styles";
import { SystemHeader, SystemIcon } from "../../packages/kelp-bar/system-header";
import { NounServiceInstanceInternal, S3Config } from "../../packages/noun-service/noun-service";
import { GroupCard } from "../gallery/components/group-card";
import { Entity, EntityService, SavedEvent, SavingEvent } from "./entity-service";
import { CustomSVG, Hessia2Context, Hessia2Plugin, System } from "./hessia2-plugin";
import { getConfigFromStorage, getS3Config } from "./config.service";
import { NavContext } from "./NavigationProvider";
const uuid = require('uuid/v4');

//  CONSIDER:  Even in this bare entity, we ALREADY have the concept of "property".  We DO want to work on making it less explicit and more of a fuzzy pattern activation (both the key and value).
interface EntityProperty {
  key: string;
  value: any;
}

//  TODO:  Versioning and Event Sourcing
//  CONSIDER:  The SYSTEM is built USING a version of the system (like users, etc WHICH gives us generality AND perhaps projection? HM!)

interface EntityContext {
  selectedEntity?: NounServiceInstanceInternal<Entity>;
  selectEntity: (entity?: NounServiceInstanceInternal<Entity>) => void;
  entities: NounServiceInstanceInternal<Entity>[];
  loadEntities: () => void;
  updateProperties: (properties: EntityProperty[]) => void;
  updateProperty: (name: string, value: string) => void;
  filterComponents: FilterComponent<any>[];
  registerFilterComponent: (filter: FilterComponent<any>) => void;
  openEditor: () => void;
  syncState: typeof SavedEvent | typeof SavingEvent;
  entityListViews: EntityListView[];
  registerEntityListView: (entityListView: EntityListView) => void;
}

export const EntityContext = React.createContext<EntityContext>({ registerEntityListView: () => null, entityListViews: [], syncState: SavedEvent, openEditor: () => null, filterComponents: [], registerFilterComponent: () => null, selectedEntity: undefined, selectEntity: (entity?: NounServiceInstanceInternal<Entity>) => null, entities: [], loadEntities: () => null, updateProperties: () => null, updateProperty: () => null });


//  TODO:  Build a "Builder" for selecting a type and then making the entity.  This COULD be an object.  It's basically the Davel code we already have.  These are Datums.  NEED String, Number, Relation, Directed Relation, and POSSIBLY Triple to start.  Use PLUGINS for these builders!!!

/**
 * Entity Context
 */


export const EntityPluginContext = React.createContext<Entity2Plugin | undefined>(undefined);

//  CONSIDER:  We could add a "filter" function here which will let the caller specify how to filter.  Whose responsibility is it to get the entities?  I GUESS this is more for display.

//  TODO:  Let Systems inject "Columns" and standard selections so we don't have to do all the column selection manually.

/**
 * Used to extract a "Column" value from the Entity.  
 */
export interface ColumnSelection<T = any> {
  name: string;
  minWidth: number;
  maxWidth?: number;
  // CLEANUP:  Force the type to by entity but parameterize?
  component: ({ entity }: { entity: NounServiceInstanceInternal<Entity<T>> }) => any;
  extractor: (entity: NounServiceInstanceInternal<Entity<T>>) => any;
}

const cellTextStyle = { fontFamily: 'Outfit-SemiBold', fontSize: 12 };

export const defaultSelections: ColumnSelection[] = [
  {
    name: "System",
    minWidth: 200,
    maxWidth: 200,
    extractor: (entity) => {
      return entity.payload.owners;
    },
    //  CONSIDER:  Support a Davel type instead of an explicit component.
    component: ({ entity: { payload: { owners } } }) => {
      return (
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
          {owners?.map(owner =>
            <View style={{ marginLeft: 3 }}>
              <SystemIcon {...owner} />
            </View>
          )}
        </View>
      );
    }
  },
  {
    name: "Name",
    minWidth: 200,
    maxWidth: 200,
    extractor: (entity) => {
      return entity.payload.name;
    },
    component: ({ entity: { payload: { name } } }) => {
      return (<Text style={cellTextStyle}>{name}</Text>
      );
    }
  },
  {
    name: "Description",
    minWidth: 400,
    maxWidth: 400,
    extractor: (entity) => {
      return entity.payload.description;
    },
    component: ({ entity: { payload: { description } } }) => {
      return (<Text style={cellTextStyle}>{description}</Text>
      );
    }
  }
];

export interface FilterComponent<T> {
  id: string;
  name: string;
  description: string;
  component: ({ config, setConfig, entities }: { config: T, setConfig: (config: T) => void, entities: NounServiceInstanceInternal<Entity>[] }) => any;
  filter: (entities: NounServiceInstanceInternal<Entity>[], allEntities: NounServiceInstanceInternal<Entity>[], config: T) => NounServiceInstanceInternal<Entity>[];
};

//  TODO:  Consider HOW can we show a GENERIC entity filter??? When we have a TABLE we also have "Columns", but there are LOTS of ways to filter ... ugh... should NOT have to couple it!  Need AI...

//  CONSIDER:  This COULD be made pluggable.

export const EntityTable = ({ columns = defaultSelections, entities = [], onPress }: { columns?: ColumnSelection[], entities?: NounServiceInstanceInternal<Entity>[], onPress?: (entity: NounServiceInstanceInternal<Entity>) => void }) => {

  // console.log("RENDERING ENTITY TABLE");

  const { selectEntity, filterComponents, entities: allEntities, entityListViews } = React.useContext(EntityContext);
  const navigation = useNavigation<StackNavigationProp<any>>();

  const views = entityListViews;
  const [view, setView] = React.useState<string>(EntityListTableView.id);

  //  TODO:  Support Created / Updated and VERSIONING!
  //  CONSIDER:  Be able to dynamically generate tables and views with functionality based on user requests.

  const [filterConfigs, setFilterConfigs] = React.useState<{ [id: string]: any }>({})

  //  TODO:  Encode the CHAIN of command from the higher-level to lower level.  May also create things indirectly on behalf of another... for example if we are to fill out a survey in one system which then uses anoher to update things... But the ENTITIES we create are managed by and owned by a certain system? Hmmm... The ENTITY PATTERN is when we build a system which supports building instances of a thing.  Let's start simple and expand out.

  const { setSelectedView } = React.useContext(NavContext);

  const pressHandler = (elem) => {

    if (onPress) {
      console.log("Calling Entity Pressed Handler");
      onPress(elem);
    } else {
      console.log("Calling Default Pressed Handler (selecting entity, navigating to Editor)")
      selectEntity(elem);
      //  Navigate to the main route with param to select the 
      setSelectedView({ name: EntitySystem.name });

      navigation.navigate(EditorRoute);
      // navigation.navigate(Entity2System.name, { screen: EditorRoute });
    }
  };

  //  Build the Filter
  //  NOTE:  We DID this already in Hessia I!!!  Annoying that I have to do it all again.. I wish I hadn't messed with Hessia I so much hmm... 

  const [showFilter, setShowFilter] = React.useState(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  }

  //  Build the Column Selector
  const [showColumnSelector, setShowColumnSelector] = React.useState(false);
  const toggleColumnSelector = () => {
    setShowColumnSelector(!showColumnSelector);
  }
  const [columSelections, setColumnSelections] = React.useState<string[]>([...columns.map(column => column.name)]);
  const toggleColumnSelection = (name: string) => {
    const index = columSelections.findIndex(_name => _name === name);
    const newColumns = [...columSelections];
    if (index == -1) {
      newColumns.push(name);
    } else {
      newColumns.splice(index, 1);
    }
    setColumnSelections(newColumns);
  }

  const styles = StyleSheet.create({
    content: {
      backgroundColor: 'white',
      padding: 22,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    contentTitle: {
      fontSize: 20,
      marginBottom: 12,
    },
  });

  const selectedColumns = React.useMemo(() => columns.filter(column => columSelections.includes(column.name)), [columns, columSelections]);

  const [sortColumn, setSortColumn] = React.useState<ColumnSelection | undefined>(undefined);

  const [filteredEntities, setFilteredEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

  //  Generate the Entity React Elements
  React.useMemo(() => {

    let _entities: NounServiceInstanceInternal<Entity>[] = [...entities];
    filterComponents.forEach(filter => {
      _entities = filter.filter(_entities, allEntities, filterConfigs[filter.id] || {});
    });

    //  TODO:  Should be able to sort by ANYTHING!!!  Not just alphabetical or whatever mm!
    if (sortColumn) {
      _entities.sort((a, b) => {
        const aValue: string = sortColumn.extractor(a);
        const bValue: string = sortColumn.extractor(b);
        return aValue?.toString().localeCompare(bValue?.toString());
      });

      // console.log(_entities.map(e => sortColumn.extractor(e)));
    }

    setFilteredEntities(_entities);

  }, [entities, sortColumn, filterConfigs]);


  const [width, setWidth] = React.useState(0);
  const [page, setPage] = React.useState(0);

  //  NOTE:  Use the SAME pattern as before!  We have COMPONENTS which can be injected as filters which literally just RESTRICT which entities are hit.
  return (
    <>

      {/* Entity Filter */}
      <Modal isVisible={showFilter} onBackdropPress={toggleFilter}>
        <View style={styles.content}>
          <Text style={styles.contentTitle}>Filter</Text>
          {
            filterComponents.map(({ component: Filter, id, name }) => (
              <GroupCard style={{ padding: 15, alignItems: 'flex-start', marginBottom: 5 }}>
                <Text>{name}</Text>
                <Filter entities={entities} config={filterConfigs[id] || {}} setConfig={config => setFilterConfigs({ ...filterConfigs, [id]: config })} />
              </GroupCard>

            ))
          }
          <Button testID={'close-button'} onPress={toggleFilter} title="Close" />
        </View>
      </Modal>

      {/* Column Selector */}
      <Modal isVisible={showColumnSelector} onBackdropPress={toggleColumnSelector}>
        <View style={styles.content}>
          {
            columns.map(({ name }) => <><Checkbox onPress={() => toggleColumnSelection(name)} status={columSelections.includes(name) ? 'checked' : 'unchecked'} /><Text>{name}</Text></>)
          }
          <Button testID={'close-button'} onPress={toggleColumnSelector} title="Close" />
        </View>
      </Modal>

      {/* Explorer Toolbar */}
      {/* NOTE: I DO like the idea of contextual refinement.  BUT, I like the idea of being able to apply that to ANYTHING (perhaps with the context "Magic Press" feature).  I suppose it's up to us how much we want to show as explicit.  Something about it just bothers me in general though, it FEELS like context should be more natural, like using language, etc. */}
      <View style={{ height: 50, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: 'white', paddingHorizontal: 15 }}>
        <View style={{ flex: 1, justifyContent: 'flex-start', flexDirection: 'row' }}>
          {
            views.map(view => (
              <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type={view.icon.type} name={view.icon.name} onPress={() => setView(view.id)} />
            ))
          }
          {/* <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type="ionicon" name="grid-outline" onPress={() => setView('grid')} />
          <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type="ionicon" name="git-network-outline" onPress={() => setView('graph')} /> */}
        </View>
        <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type="ionicon" name="funnel-outline" onPress={() => setShowFilter(!showFilter)} />
        <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type="ionicon" name="filter-outline" onPress={() => setShowFilter(!showFilter)} />
        <Icon color="black" size={22} style={{ marginHorizontal: 10 }} type="ionicon" name="settings-outline" onPress={() => setShowColumnSelector(!showColumnSelector)} />
      </View>

      {/* TODO:  Lock columns by placing them BEFORE the horizontal scroller. */}

      <ViewSelector page={page} setPage={setPage} pressHandler={pressHandler} entities={filteredEntities} views={views} view={view} selectedColumns={selectedColumns} width={width} setWidth={setWidth} setSortColumn={setSortColumn} sortColumn={sortColumn} />
    </>
  );
}

export const EntityListTable = ({ selectedColumns, width, setWidth, setSortColumn, sortColumn, entities, pressHandler, page, setPage }) => {

  const itemsPerPage = 20;
  const from = page * itemsPerPage;
  const to = (page + 1) * itemsPerPage;

  const entityElements = React.useMemo(() => {
    return (entities as any[]).slice(from, to).map(elem => (
      <DataTable.Row key={elem.id} style={{ backgroundColor: 'white', borderBottomColor: '#eeeeee' }} onPress={() => pressHandler(elem)}>
        {selectedColumns?.map(({ component: Comp, minWidth, maxWidth }) => <DataTable.Cell style={{ minWidth: minWidth || width / selectedColumns.length, flex: 1, maxWidth: maxWidth || undefined }}>
          <Comp entity={elem} />
        </DataTable.Cell>)}
      </DataTable.Row>

    ))
  }, [entities, sortColumn, page])

  const titleTextStyle = { fontFamily: 'Outfit-SemiBold', fontSize: 14 };

  return (
    <View style={{ flex: 1, paddingHorizontal: 10, backgroundColor: 'white' }}>
      <ScrollView onLayout={event => setWidth(event.nativeEvent.layout.width)} horizontal={true} style={{ width: '100%', backgroundColor: 'white' }}>
        <View style={{ backgroundColor: 'white', minWidth: width }}>
          <View style={{ flexDirection: 'row', paddingHorizontal: 15, backgroundColor: 'white', borderColor: '#efefef', borderRadius: 10, borderWidth: 2 }}>
            {selectedColumns?.map(column => (
              <TouchableOpacity onPress={() => setSortColumn(column)} style={{ flexDirection: 'row', alignItems: 'center', minWidth: column.minWidth || width / selectedColumns.length, maxWidth: column.maxWidth || undefined }}>
                <DataTable.Title textStyle={titleTextStyle}>{column.name}</DataTable.Title>
                <View style={{ flex: 1 }} />
                {
                  sortColumn && sortColumn.name === column.name && <Icon name="chevron-down" type="feather" />
                }

              </TouchableOpacity>
            ))}
          </View>

          <ScrollView style={{ width: '100%', marginTop: 10 }}>
            {entityElements}
          </ScrollView>
        </View>
      </ScrollView>
      <DataTable.Pagination
        page={page}
        numberOfPages={Math.floor(entities.length / itemsPerPage)}
        onPageChange={page => setPage(page)}
        label={`${from + 1}-${Math.min(to, entities.length)} of ${entities.length}`}
      />
    </View>
  );
}

export const EntityListTableView: EntityListView = {
  id: "table",
  name: "Table",
  icon: { name: "reorder-four", type: "ionicon" },
  component: EntityListTable
}
export interface EntityListView {
  id: string;
  component: ({ selectedColumns, width, setWidth, setSortColumn, sortColumn, entities, pressHandler, page, setPage }) => React.ReactElement;
  name: string;
  icon: { name: string, type: string };
}

export const ViewSelector = ({ pressHandler, views, view, selectedColumns, width, setWidth, setSortColumn, sortColumn, entities, page, setPage }) => {
  const selectedView = views.find(_view => _view.id === view);
  const Comp = selectedView.component;
  return <Comp pressHandler={pressHandler} entities={entities} selectedColumns={selectedColumns} width={width} setWidth={setWidth} setSortColumn={setSortColumn} sortColumn={sortColumn} page={page} setPage={setPage} />
}



export const entityStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: '#eeeeee',
    borderBottomWidth: 1,
    height: 50,
    paddingHorizontal: 10,
    alignItems: 'center'
  },
  title: {
    flex: 1,
    fontSize: 18,
    fontFamily: 'Poppins-Bold',
    letterSpacing: -0.5,
    display: 'flex'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  editIconButton: {
    backgroundColor: '#F5F5F5',
    marginHorizontal: 4,
    borderRadius: 8
  },
  statusIconButton: {
    backgroundColor: '#6200EE',
    marginHorizontal: 4,
    borderRadius: 8
  },
});

//  TODO:  Register these as nodes in the graph.
//  CONSIDER:  Instead of making this hard-coded just ADD an icon / name, etc as "components" which give it meaning mm!
export interface EntityExtension {
  systemId: string;
  id: string;
  name: string;
  description: string;
  icon: { name: string; type: string; }
  DetailComponent: ({ entity }: { entity?: NounServiceInstanceInternal<Entity> }) => React.ReactElement;
}

const ExplorerStack = createStackNavigator();

const CustomHeader = (props) => {
  return (
    <View style={{
      flexDirection: 'row',
      height: 50,
      alignItems: 'center',
      borderBottomColor: '#000',
      borderBottomWidth: 1,
      paddingHorizontal: 10
    }}>
      <Text style={{
        fontFamily: 'Inter-SemiBold',
        color: '#555555',
        fontSize: 14
      }}>{props.title}</Text>
    </View>
  )
};


const HomeRoute = "Entities";
export const EditorRoute = "Entity Editor";

const EntityApp = () => {
  const { selectedEntity } = React.useContext(EntityContext);
  return selectedEntity ? <EntityEditor /> : <EntityExplorer />;
}

const ExtensionStackNav = createStackNavigator();

const EntityDetailEditor = ({ entity }: { entity?: NounServiceInstanceInternal<Entity> }) => {
  const { updateProperty } = React.useContext(EntityContext);
  const { name = '', description = '', emoji = '', id } = entity?.payload || {};
  return (
    <>

      {/* Name */}
      <View style={{ flexDirection: 'row', overflow: 'hidden', width: '100%' }}>
        <TextInput value={name} onChangeText={name => updateProperty("name", name)} placeholderTextColor="#aaaaaa" placeholder="Untitled" style={{ fontFamily: 'Inter-Black', fontSize: 20, lineHeight: 20, color: '#333333' }} />
      </View>

      {/* Description */}
      <TextInput multiline={true} numberOfLines={1} placeholderTextColor="#aaaaaa" value={description} onChangeText={description => updateProperty("description", description)} placeholder="Describe this entity" style={{ fontFamily: 'Inter-Bold', fontSize: 16, lineHeight: 18, color: '#777777', marginTop: 5, marginBottom: 8 }} />

      {/* ID */}
      <View style={{ overflow: 'hidden', alignSelf: 'flex-start', borderRadius: 7, backgroundColor: '#e6f5ff', borderWidth: 1, borderColor: '#3095ff', height: 30, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }} onPress={() => { alert("Copied!"); return id ? Clipboard.setString(id) : null }}>
        <View style={{ backgroundColor: '#3095ff', alignItems: 'center', justifyContent: 'center', borderRightColor: '#3095ff', borderRightWidth: 1, height: '100%' }}>
          <Text style={{ fontFamily: "Outfit-Bold", color: '#e6f5ff', paddingHorizontal: 5 }}>ID</Text>
        </View>
        <View style={{ width: 5 }} />
        <Text style={{ fontFamily: "Outfit-SemiBold", color: '#3095ff' }}>{id}</Text>
        <View style={{ width: 5 }} />
      </View>

    </>
  );
}

// Define your styles here
const styles = StyleSheet.create({
  tabBar: {
    flexDirection: 'row',
    height: 52,
    // rest of the styles for tab bar
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // rest of the styles for tab item
  },
  // other styles
});

//  TODO:  Track UPDATES (Event Sourcing)
//  TODO:  Remove the context loading from entity?.  I don't like that we're coupling with the context.

const EntityInjector = ({ route }: { route: any }) => {
  // const { component: Component } = route.params;
  // const { selectedEntity: entity } = React.useContext(EntityContext);
  return <View style={{ height: 1000, backgroundColor: 'green', width: '100%' }}></View>
  // return <Component entity={entity} />
}


const ExtensionPanel = ({ }) => {

  const entityPlugin = React.useContext(EntityPluginContext);
  const extensions = entityPlugin?.enitityExtensions || [];
  const [selectedExtension, setSelectedExtension] = React.useState<EntityExtension>();

  if (!selectedExtension) {
    // TODO:  Do NOT hard-code this!  either let the extensions decide or control it in a COUPLING system.
    const contentExt = extensions.find(ext => ext.id === "object-extension");
    if (contentExt) {
      setSelectedExtension(contentExt);
    }
  }

  //  NOTE:  This is only rebuilt when screens change.  The entity is injected into the "DetailComponent" with the "EntityInjector". 
  //  CONSIDER:  We MAY want to couple the detail components with "selectedEntity" directly and mount it in the state tree?

  // const screens = React.useMemo(() => extensions.map(({ DetailComponent, name, icon }) => (
  //   <ExtensionStackNav.Screen
  //     key={name}
  //     name={name}
  //     component={EntityInjector}
  //     // initialParams={{ component: DetailComponent }}
  //     options={{
  //       // drawerStyle: { width: isDesktop ? 150 : 70, borderWidth: 0, borderRightWidth: 0, borderRightColor: 'white' },
  //       // drawerLabelStyle: { width: 0 },
  //       // drawerContentContainerStyle: { borderWidth: 0 },
  //       // drawerContentStyle: { borderWidth: 0 },
  //       // drawerIcon: ({ focused, size }) => {
  //       //   return <View style={{ paddingLeft: 3, flexDirection: 'row', alignItems: 'center' }}>
  //       //     <Icon
  //       //       name={icon.name}
  //       //       type={icon.type}
  //       //       size={size}
  //       //       color={focused ? '#007bff' : '#ccc'}
  //       //     />
  //       //     {
  //       //       isDesktop && <Text style={{ color: focused ? '#007bff' : '#ccc', fontFamily: 'Outfit-SemiBold', marginLeft: 5 }}>{name}</Text>
  //       //     }
  //       //   </View>

  //       // }
  //     }}
  //   />
  // )), [extensions, isDesktop]);

  // if (!screens.length) {
  //   alert("Missing Screens");
  //   return null;
  // }

  //  TODO:  Remove this independent navigation container.  Seems to be some issue with re-rendering, BUT using the independent one breaks navigation from within the entity view!!!
  const Component = selectedExtension ? selectedExtension.DetailComponent : () => <Text>Select Extension</Text>;
  const { selectedEntity } = React.useContext(EntityContext);

  return (
    <>
      <FlatList ItemSeparatorComponent={() => <View style={{ width: 7 }} />} style={{ height: 30, flexGrow: 0 }} horizontal={true} scrollEnabled={true} data={extensions} renderItem={({ item: ext }) => (
        <Pressable style={{ borderRadius: 7, backgroundColor: '#e6f5ff', borderWidth: 1, borderColor: '#3095ff', height: 30, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 7 }} onPress={() => setSelectedExtension(ext)}>
          <Text style={{ fontFamily: "Outfit-SemiBold", color: '#3095ff' }}>{ext.name}</Text>
        </Pressable>
      )} />
      {
        <Component entity={selectedEntity} />
      }
    </>

  );
};

export const EntityEditor = ({ entityId }: { entityId?: string }) => {

  const entityPlugin = React.useContext(EntityPluginContext);
  const extensions = entityPlugin?.enitityExtensions || [];

  const { loadEntities, selectedEntity: entity, selectEntity } = React.useContext(EntityContext);

  if (!entity) { return <Text>No Entity Selected</Text> }

  const navigation = useNavigation();

  const deleteEntity = async () => {
    if (!entity) { return; }
    await entityPlugin?.entityService.entityNounService.delete(entity?.id);
    await loadEntities();
    navigation.goBack();
  }

  const saveEntity = async () => {
    if (!entity) { return; }
    const newInternal = await entityPlugin?.updateEntity(entity.id, { ...entity.payload });
    if (!newInternal) { throw new Error("Could not update entity: " + entity.id); }
    await loadEntities();
    navigation.goBack();
  }

  return (
    <>

      {/* Header */}
      <SystemHeader crumbs={[{ text: "Entities", onPress: () => { selectEntity(undefined); } }, { text: entity.payload.name || "No Name", onPress: () => { selectEntity(entity); } }]} system={EntitySystem}>
        <TouchableOpacity onPress={saveEntity} style={{ marginHorizontal: 10 }}>
          <Icon name="save-outline" type="ionicon" />
        </TouchableOpacity>
        <TouchableOpacity onPress={deleteEntity} style={{ marginHorizontal: 10 }}>
          <Icon name="trash-outline" type="ionicon" />
        </TouchableOpacity>
      </SystemHeader>

      {/* Entity Editor */}
      <ScrollView style={{ flex: 1, padding: 15 }}>
        <EntityDetailEditor entity={entity} />
        <View style={{ height: 20 }} />
        <ExtensionPanel />
      </ScrollView>
    </>

  );
}


//  TODO:  Show entity info including which systems are STAKED on it!  ALSO show which systems MADE the stake (e.g. if there is stack!)

export const EntityExplorer = () => {

  const entityPlugin = React.useContext(EntityPluginContext);
  const entityContext = React.useContext(EntityContext);
  const navigation = useNavigation();

  const createEntity = async () => {
    const id = uuid();
    const internal = await entityPlugin?.entityService.entityNounService.create({ id, owners: [PersonalSystem], exported: true });
    await entityContext.loadEntities();
    if (!internal?.payload) { throw new Error("Failed to create entity"); }
    entityContext.selectEntity(internal);
    navigation.navigate(EditorRoute as never);
  }

  return (
    <>
      <SystemHeader crumbs={[{ text: "Entities", onPress: () => null }]} system={EntitySystem}>
        <TouchableOpacity onPress={createEntity} style={{ height: 30, width: 30 }}>
          <Icon name="add-circle-outline" type="ionicon" size={30} />
        </TouchableOpacity>
      </SystemHeader>
      <EntityTable entities={entityContext.entities} />
    </>
  );

}




const FilledBolt = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path fill={color} d="m5.67 9.914l3.062-4.143c1.979-2.678 2.969-4.017 3.892-3.734c.923.283.923 1.925.923 5.21v.31c0 1.185 0 1.777.379 2.148l.02.02c.387.363 1.003.363 2.236.363c2.22 0 3.329 0 3.704.673l.018.034c.354.683-.289 1.553-1.574 3.29l-3.062 4.144c-1.98 2.678-2.969 4.017-3.892 3.734c-.923-.283-.923-1.925-.923-5.21v-.31c0-1.185 0-1.777-.379-2.148l-.02-.02c-.387-.363-1.003-.363-2.236-.363c-2.22 0-3.329 0-3.704-.673a1.084 1.084 0 0 1-.018-.034c-.354-.683.289-1.552 1.574-3.29Z" />
    </Svg>
  );
};

const EntityIcon = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path strokeWidth="2" fill={color} d="M3.5 5.25c0-.966.784-1.75 1.75-1.75h3a.75.75 0 0 0 0-1.5h-3A3.25 3.25 0 0 0 2 5.25v3a.75.75 0 0 0 1.5 0v-3Zm17 0a1.75 1.75 0 0 0-1.75-1.75h-3a.75.75 0 0 1 0-1.5h3A3.25 3.25 0 0 1 22 5.25v3a.75.75 0 0 1-1.5 0v-3ZM5.25 20.5a1.75 1.75 0 0 1-1.75-1.75v-3a.75.75 0 0 0-1.5 0v3A3.25 3.25 0 0 0 5.25 22h3a.75.75 0 0 0 0-1.5h-3Zm15.25-1.75a1.75 1.75 0 0 1-1.75 1.75h-3a.75.75 0 0 0 0 1.5h3A3.25 3.25 0 0 0 22 18.75v-3a.75.75 0 0 0-1.5 0v3ZM13.82 8.227a2.25 2.25 0 0 0-3.64 0l-3.054 4.2C6.044 13.914 7.106 16 8.946 16h6.108c1.84 0 2.902-2.086 1.82-3.573l-3.054-4.2Z" />
    </Svg>
  );
};

const TriangleIcon = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      {/* <Path stroke={color} strokeWidth="2" d="M0 0h24v24H0z" /> */}
      <Path fill={color} d="M11.99 1.968c1.023 0 1.97.521 2.512 1.359l.103.172l7.1 12.25l.062.126a3 3 0 0 1-2.568 4.117L19 20H5l-.049-.003l-.112.002a3 3 0 0 1-2.268-1.226l-.109-.16a3 3 0 0 1-.32-2.545l.072-.194l.06-.125L9.366 3.516a3 3 0 0 1 2.625-1.548z" />
    </Svg>
  );
};

const BoltIcon = ({ color, size, ...rest }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <Path fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7Z" />
    </Svg>
  );
};

//  TODO:  Separate the BUTTON injected into the main app from the system name!

const EntitySystem: System = {
  id: "entity",
  pinned: true,
  priority: 1,
  color: "#aaaaaa",
  //  NOTE:  Considered switching to "Find" for a bit with Octicon / Telescope
  name: "Entities",  //  CONSIDER:  Change this to "Entities" / "Things" / "Items" (GPT Idea) / "Elements"
  description: "Manage Entities",
  emoji: "🧬",
  component: EntityApp,
  icon: {
    type: "feather", name: "box"
  },
  primaryColor: '#14f5b9',
  backgroundColor: '#f5fffc',
}

export const PersonalSystem: System = {
  id: "personal",
  color: "#aaaaaa",
  name: "Personal",
  description: "Your System",
  emoji: "🧬",
  component: () => <Text>Personal System</Text>,
  icon: {
    name: "person-outline",
    type: "ionicon",
  },
  primaryColor: '#14f5b9',
  backgroundColor: '#f5fffc',
}

export const getEntities = () => {
  const { entities } = React.useContext(EntityContext);
  return entities;
};

/**
 * Entity Plugin
 */
export class Entity2Plugin extends CorePluginClass {

  public static details = {
    name: "Entities",
    description: "Entity System",
    dependencies: [Hessia2Plugin.details.id],
    id: "entity2"
  }

  public enitityExtensions: EntityExtension[] = [];

  public registerEntityExtension = (extension: EntityExtension) => {
    this.enitityExtensions.push(extension);
  }

  public updateEntity = async (internalId: string, updatedNode: Entity) => {
    // const allNodes = await entityService.retrieveAll();
    // const _node = allNodes.find(node => node.payload.id === internalId);
    // if (!_node) { return; }
    return await this.entityService.entityNounService.update(internalId, updatedNode);
  }

  public entityService!: EntityService;

  public install = async (program: any, { hessia2 }: { hessia2: Hessia2Plugin }) => {

    const EntityIDField = ({ value, update }: { value: string, update: (value: string) => void }) => {


      const entities = getEntities();

      const selectEntitiy = (entity: NounServiceInstanceInternal<Entity>) => {
        update(entity.payload.id);
        setShowSelector(false);
      }

      const [showSelector, setShowSelector] = React.useState(false);

      // TODO:  Make this a "Entity Selection" Component

      return (
        <>
          <Modal isVisible={showSelector}>
            <EntityTable onPress={selectEntitiy} entities={entities} />
          </Modal>
          <Button title="Select Entity" onPress={() => setShowSelector(true)} />
          {!!value ? <Text>{value}</Text> : <Text>No Entity Selected</Text>}
        </>
      )
    };

    //  Register "Entity ID" Davel Type
    Davel.registerSDTDeserializer("entityid", () => {
      const dt = new Davel.DT<any, any>({});
      return Promise.resolve(dt);
    });

    //  Register "Entity ID" UI
    registerDavelType({
      id: "entityid",
      name: "Entity ID",
      color: "#eeeeee",
      icon: { type: "ionicon", name: "square" },
      renderer: ({ sdt, key, value, update, name }: SDTRendererParams) => (
        <DavelField required={sdt.required} name={name}>
          <Text>This is the value: {value}</Text>
          <EntityIDField update={update} value={value} />
        </DavelField>
      ),
      defaultSDT: { type: "entityid" }
    });

    //  NOTE:  This is the Schema used to validate an "EntityID" SDT.
    const EntityIDMetaSchema: Davel.SDTObject = {
      type: 'object',
      required: true,
      extensible: false,
      properties: {
        ...Davel.SDTSerializedSchemaProperties,
        type: { type: 'option', options: ['entityid'] }
      }
    };

    //  Register SDT Schema
    registerSDTSchema("entityid", EntityIDMetaSchema)

    const config = await getConfigFromStorage();
    if (!config) { alert("Entity Plugin failed to install:  Missing Config"); return; }
    const useAws = config.storage !== 'local';
    let s3Config!: S3Config;
    if (useAws) {
      s3Config = await getS3Config();
    }
    this.entityService = new EntityService(useAws, s3Config);
    await this.entityService.init();

    const _me = this;

    //  Add a New Davel Type - Node
    // class DTNode extends Davel.DT<any, any> { }
    // registerDavelType(DTNode);
    // Davel.registerSDTDeserializer

    hessia2.registerHOC(({ children }) => {

      //  Register React State
      const [selectedEntity, selectEntity] = React.useState<NounServiceInstanceInternal<Entity> | undefined>();
      const { installSystem } = React.useContext(Hessia2Context);
      const [entities, setEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);
      const [syncState, setSyncState] = React.useState<typeof SavedEvent | typeof SavingEvent>(SavedEvent);
      const [entityListViews, setEntityListViews] = React.useState<EntityListView[]>([EntityListTableView]);
      const registerEntityListView = (entityListView: EntityListView) => {
        setEntityListViews((prev) => {
          return [...prev, entityListView];
        })
      }

      const [filterComponents, setFilterComponents] = React.useState<FilterComponent<any>[]>([
        {
          id: "entity-filter",
          name: "Entity Filter",
          description: "Filters Entities Properties",
          component: ({ config, setConfig, entities }) => {
            return (
              <View>
                <Text>Name</Text>
                <TextInput onChangeText={name => setConfig({ ...config, name })} />

                <Text>Description</Text>
                <TextInput onChangeText={description => setConfig({ ...config, description })} />
              </View>
            );
          },
          filter: (filteredEntities, allEntities, { name, description }) => {
            return filteredEntities.filter(entity => {
              if (name && !entity.payload.name?.includes(name)) {
                return false;
              }
              if (description && !entity.payload.description?.includes(description)) {
                return false;
              }
              return true;
            })
          }
        }
      ]);

      const navigation = useNavigation<any>();

      const openEditor = () => {
        navigation.navigate(EntitySystem.name, { screen: EditorRoute });
      }

      const registerFilterComponent = (filter: FilterComponent<any>) => {
        setFilterComponents([...filterComponents, filter]);
      }

      const loadEntities = async () => {
        const _entities = await this.entityService.entityNounService.retrieveAll();
        if (!_entities) { return; }
        setEntities(_entities);
      }

      const updateProperties = (proprties: EntityProperty[]) => {
        if (!selectedEntity) { return; }
        let newInternal = { ...selectedEntity, payload: { ...selectedEntity.payload } };
        proprties.forEach(prop => {
          newInternal.payload[prop.key] = prop.value;
          // newInternal = { ...newInternal, payload: { ...newInternal.payload, [prop.key]: prop.value } };
        })
        selectEntity(newInternal);
      }

      const updateProperty = (key: string, value: any) => {
        updateProperties([{ key, value }]);
      }

      React.useEffect(() => {
        loadEntities();

        this.entityService.entityServiceEmitter.addListener(SavedEvent, () => {
          setSyncState(SavedEvent);
        });

        this.entityService.entityServiceEmitter.addListener(SavingEvent, () => {
          setSyncState(SavingEvent);
        });

        installSystem(EntitySystem, true);
      }, []);

      // console.log("RENDERING ENTITY PROVIDER");

      return (
        <EntityContext.Provider value={{ registerEntityListView, entityListViews, syncState, openEditor, selectedEntity, selectEntity, entities, loadEntities, updateProperties, updateProperty, filterComponents, registerFilterComponent }}>
          <EntityPluginContext.Provider value={_me}>
            {children}
          </EntityPluginContext.Provider>
        </EntityContext.Provider>
      );
    });

    return this;
  }
}
