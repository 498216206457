import * as Davel from 'davel';
import { AllTMSDTs } from 'habor-sdk';
import { CorePluginClass } from "halia";
import React from "react";
import { Button, Modal, ScrollView, Text, View } from "react-native";
import { Icon } from 'react-native-elements';
import ReactNativeModal from 'react-native-modal';
import { SDTComponent, davelTypeRegister, getDavelType } from "../../packages/davel-ui/davel-ui";
import { getSchemaFromType } from "../../packages/davel-ui/types/sdt/sdt-field";
import { SystemHeader } from '../../packages/kelp-bar/system-header';
import { NounServiceInstanceInternal } from '../../packages/noun-service/noun-service';
import { GroupCard } from '../gallery/components/group-card';
import { Entity2Plugin, EntityContext, EntityTable, getEntities } from "./entity-plugin";
import { Entity } from './entity-service';
import { Hessia2Context, Hessia2Plugin, System } from "./hessia2-plugin";
import { PropertyPlugin } from "./property-plugin";
import { useSizes } from '../gallery/sizes-helper';

export interface SDTSelection {
  type: "anonymous" | "reference",
}

export interface AnonymousSDTSelection extends SDTSelection {
  type: "anonymous";
  davelType: Davel.AllSDT;
}

export interface ReferenceSDTSelection extends SDTSelection {
  type: "reference";
  entityId: string;
}


export type TypeSelection = AnonymousSDTSelection | ReferenceSDTSelection;

// {/* NOTE:  Models are defined as primitives?  The serve as a template for an object. */}
//       {/* <Text>Models</Text> */}
//       {/* <EntityTable entities={compositeTypes} /> */}

//       {/* CONSIDER:  Use Ontology / Models for everything.  Although... should not NEED a "type" definition to declare something as a type.  Up to how WE want to build the system.  A RULE is that something has a "value" matching conditions, but doesn't NEED to.  */}
//       {/* <Text>Classes</Text> */}
//       {/* NOTE:  Classes are defined as part of an Ontology. */}
//       {/* <EntityTable entities={classTypes} /> */}


const PrimitivesList = () => {
  const { types } = React.useContext(TypeContext);
  const compositeTypes = useCustomTypes();
  return (
    <EntityTable entities={types} />
  );
}

const ModelsList = () => {
  const { types } = React.useContext(TypeContext);
  const compositeTypes = useCustomTypes();
  return (
    <EntityTable entities={compositeTypes} />
  );
}

const PrimitivesHome = () => {
  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <SystemHeader system={PrimitivesSystem} breadcrumbs={false} />
      <ScrollView>
        <PrimitivesList />
      </ScrollView>
    </View>
  );
}

const ModelsHome = () => {
  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <SystemHeader system={ModelsSystem} breadcrumbs={false} />
      <ModelsList />
    </View>
  );
}


//  NOTE:  This used to be "Models" with icon: { name: "database", type: "feather" },
//  TODO:  Seprate the "Entity" system and the "Object" (Primitive) systems from the broader concepts of explore and find....  CAN even inject these AFTER the fact from within a Find / Explore system.  Up to us if we want code we right to be "aware" of these thigns OR if we want them to inject.  I MAY want to bake Entities and Objects RIGHT into Hessia.  That would solve some of this.
//  NOTE:  Considered switching this to "Create" for a while with Feather / Loader.
//  TODO:  We REALLY need an INTEGRATED "Type" system that lets us make types to compose over ALL the systems not JUST this Object system!

//  NOTE:  The OBJECT system should be SEPARATED from this "Type" system!  The Type system works for ALL services INCLUDING the Object / Data service!  It should support registration!  Whenever we make a "Type" it is created as an Entity AND it is marked as a Type through THIS system!  A TYPE is a thing which has associated Constraints!  SOME of those can be PRIMITIVE!
const PrimitivesSystem: System = {
  pinned: false,
  id: "file-system",
  color: "#aaaaaa",
  name: "Primitives",
  description: "File System",
  emoji: "⚛️",
  component: PrimitivesHome,
  icon: { name: "book", type: "feather" },
  primaryColor: "#007bff",
  backgroundColor: "#f5faff"
}

const ModelsSystem: System = {
  pinned: false,
  id: "models",
  color: "#aaaaaa",
  name: "Models",
  description: "Models",
  emoji: "⚛️",
  component: ModelsHome,
  icon: { name: "server", type: "feather" },
  primaryColor: "#007bff",
  backgroundColor: "#f5faff"
}

/**
 * Returns all custom SDTs
 * These are VALUES which are conformant with type SDT.  They ARE "Serialized Davel Types".  
 */
export const useCustomTypes = () => {

  const [customTypes, setCustomTypes] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

  const entities = getEntities();

  const loadCustomTypes = async () => {

    const validEntities: NounServiceInstanceInternal<Entity>[] = [];
    for (const entity of entities) {

      //  Check if the "Value" has as "Type" property (which all SDTs do)
      // const hasType = !!entity.payload.value?.type;
      // const isSDT = entity.payload.value?.type === "sdt";
      const hasType = !!entity.payload.type;
      const isAnonymous = entity.payload.type?.type == "anonymous";
      const isSDT = entity.payload.type?.davelType?.type == "sdt";
      if (hasType && isAnonymous && isSDT) { validEntities.push(entity); }

      //  TODO:  Verify the Actual SDT
      // const potentialSDT = entity.payload.value;
      // const deserializedSDT = await Davel.deserializeSDT(potentialSDT);
      // if (deserializedSDT) { return true; }
      // return false;
    }
    setCustomTypes(validEntities);
  };

  React.useEffect(() => {
    loadCustomTypes();
  }, [entities]);

  return customTypes;

  // return entities.filter(entity => entity.payload.type?.type === "anonymous");
}

// const useSDTFromTypeSelection = (selection: TypeSelection) => {

// }

/**
 * Selects an existing type within the Hessia Entity / Primitive Systems OR lets the user create a new type.
 */
const HessiaTypeSelector = ({ onClose, selection, select, sdt, typeEntity }: { typeEntity?: NounServiceInstanceInternal<Entity>, sdt?: AllTMSDTs, onClose: () => void, selection?: TypeSelection, select: (selection: TypeSelection, value: any) => void }) => {

  const [showAnonymousModal, setAnonymousModal] = React.useState(false);
  const [showReferenceModal, setReferenceModal] = React.useState(false);

  const customTypes = useCustomTypes();

  //  Update the Type (based on its meta-type or Type SDT)
  const updateType = (type: Davel.AllSDT) => {
    console.log("Update Type Value: " + JSON.stringify(type));
    console.log("Type Type: " + type.type);
    const newTypeSelection: AnonymousSDTSelection = { type: "anonymous", davelType: type };
    const defaultValue = getDavelType(type.type)?.defaultValue;
    select(newTypeSelection, defaultValue);
  }

  return (
    <View>

      {/* IN-PROGRESS:  New Templates (encoding used to build an encoding) */}
      {/* IN-PROGRESS:  Reference (reference an existing by ID or other connectives) */}
      {/* TODO:  Remix (use one as starting point) */}
      {/* TODO:  Extend (Inherit one template for use as the foundation WITHOUT changing it) */}

      <Text>Create a new type or select an existing.</Text>

      <Text>{JSON.stringify(selection || "Undefined")}</Text>

      <Text>{selection?.type === "anonymous" ? "You are creating an 'anonymous' type." : selection?.type === "reference" ? "You have selected an existing type" : "You have not selected or created a type"}</Text>

      <Button onPress={() => setAnonymousModal(true)} title="New Type" />
      <Button onPress={() => setReferenceModal(true)} title="Existing Type" />
      <Button onPress={onClose} title='Close' />

      selection && (
      <GroupCard>
        {/* New Type Editor */}
        {
          selection!.type === "anonymous" && sdt &&
          <GroupCard>
            <Text>Type Editor</Text>
            <SDTComponent value={sdt} sdt={getSchemaFromType(sdt.type)} name="Type" update={updateType} autoSubmit={true} />
          </GroupCard>
        }

        {/* Type Reference */}
        {
          selection!.type === "reference" &&
          <GroupCard>
            <Text>Type Reference</Text>
            {!!typeEntity && <EntityTable entities={[typeEntity]} />}
          </GroupCard>
        }
      </GroupCard>
      )


      <Modal visible={showAnonymousModal}>
        {
          davelTypeRegister.map(type => <Button title={type.name} onPress={() => { setAnonymousModal(false); select({ type: "anonymous", davelType: type.defaultSDT as any }, type.defaultValue) }} />)
        }
      </Modal>
      <Modal visible={showReferenceModal}>
        <EntityTable entities={customTypes} onPress={entity => {
          setReferenceModal(false);
          const type = entity?.payload?.value?.type;
          if (!type) {
            console.warn("Invalid SDT selected.  The selected entity does NOT have a type property.");
            return;
          }
          //  CONSIDER:  Instead of taking this from the SDT be may want to let the user make a default value.
          const defaultValue = davelTypeRegister[type]?.defaultValue;
          select({ type: "reference", entityId: entity?.payload?.id }, defaultValue)
        }} />
      </Modal>
    </View>
  );
}

export const PrimitivePluginContext = React.createContext<PrimitivesPlugin | undefined>(undefined);

export interface TypeContext {
  types: NounServiceInstanceInternal<Entity>[];
}
export const TypeContext = React.createContext<TypeContext>({ types: [] });

export class PrimitivesPlugin extends CorePluginClass {

  public static details = {
    name: "Primitives Plugin",
    description: "Adds support for 'primitives' to Hessia.",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: "primitive2"
  }

  public hessia2!: Hessia2Plugin;
  public entity2!: Entity2Plugin;

  /**
   * Creates the given entity and attaches it to a primitive value.
   * @param entity - The Entity to create and attach to.
   * @param type - the "type" of the primitive value.
   * @param value - the "value" of the primitive to store.
   */
  public createObject = async (entity: Entity, type: TypeSelection, value: any) => {
    entity.type = type;
    entity.value = value;
    const internalEntity = this.entity2.entityService.createEntity(entity);
    return internalEntity;
  }

  /**
  * Attaches the given entity to a primitive value.
  * @param entityId - The Entity to attach to.
  * @param type - the "type" of the primitive value.
  * @param value - the "value" of the primitive to store.
  */
  public attachObject = async (entity: NounServiceInstanceInternal<Entity>, type: TypeSelection, value: any) => {
    const newEntity = entity.payload;
    newEntity.type = type;
    newEntity.value = value;
    const internalEntity = this.entity2.entityService.entityNounService.update(entity.id, newEntity);
    return internalEntity;
  }

  //  TODO:  I do NOT like that this Feature has any knoweldge of Entities at this point.  I think it would be best to keep them separate.

  public async getValue<T>(entity: Entity<T>): Promise<T | undefined> {
    return entity.value;
  }

  public hasPrimitiveType = (entity: NounServiceInstanceInternal<Entity<any>>, type: string): boolean => {
    const hasType = (entity.payload.type?.type === "anonymous") && (entity.payload.type?.davelType.type === type);
    return hasType;
  }

  public hasReferenceType = (entity: NounServiceInstanceInternal<Entity<any>>, entityTypeId: string): boolean => {
    const hasType = (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === entityTypeId);
    return hasType;
  }

  public updateEntityPrimitive = async (entityId: string, type?: TypeSelection, value?: any) => {
    const entity = await this.entity2.entityService.getEntityNounById(entityId);
    if (!entity) {
      console.error("No Entity Defined");
      alert("No Entity Provided!");
      return;
    }
    const updatedEntity: Entity = { ...entity.payload, value: value || entity.payload.value, type: type || entity.payload.type };
    await this.entity2.entityService.entityNounService.update(entity.id, updatedEntity);
  }

  public install = async (program: any, { hessia2, graph2, entity2 }: { hessia2: Hessia2Plugin, graph2: PropertyPlugin, entity2: Entity2Plugin }) => {

    this.hessia2 = hessia2;
    this.entity2 = entity2;

    try {

      //  Register the Entity Extension
      entity2.registerEntityExtension({
        systemId: PrimitivesSystem.id,
        icon: PrimitivesSystem.icon,
        id: "object-extension",
        name: "Content",
        description: "Displays Primitive Value",
        DetailComponent: ({ entity }) => {


          // return <View style={{ height: 1000, backgroundColor: 'green' }}></View>
          const { updateProperties } = React.useContext(EntityContext);
          const [sdt, setSDT] = React.useState<AllTMSDTs | undefined>(undefined);

          const type: TypeSelection = entity?.payload?.type || { type: "anonymous", davelType: { type: "keyword" } };

          if (!entity) { return <Text>Missing Entity</Text> }

          const { value } = entity?.payload;

          //  Selects a Type
          //  NOTE:  Uses the "Default SDT" for the selected type and the default value.
          const selectType = (selection: TypeSelection, value: any) => {
            // console.log("New Type: " + JSON.stringify(selection));
            updateProperties([{ key: "value", value }, { key: "type", value: selection }]);
          }

          //  Selects the Value
          const updateValue = (value: any) => {
            // console.log("New Value: " + JSON.stringify(value));
            updateProperties([{ key: "value", value: value }]);
          }

          const entities = getEntities();

          const [typeEntity, setTypeEntity] = React.useState<NounServiceInstanceInternal<Entity> | undefined>();

          const updateSDT = async () => {
            console.log("Updating SDT...");
            if (type.type === "anonymous") {
              setSDT(type.davelType);
              console.log("Set SDT: " + JSON.stringify(type.davelType));
              console.log("--  Set new Anonymous SDT");
            } else if (type.type === "reference") {
              const entityId = type.entityId;
              const entity = entities.find(entity => entity.payload.id === entityId);
              if (!entity) { console.warn("Referenced entity type could not be found!"); }
              setTypeEntity(entity);
              const davelType = entity?.payload.value;
              //  TODO:  Verify SDT and possibly make a rule to show when it's invalid.
              setSDT(davelType);
              console.log("--  Set new Reference SDT");
            } else {
              console.error("Invalid Type Selection!")
              console.error(JSON.stringify(type));
            }
          };


          React.useEffect(() => {
            console.warn("PRIMITIVE ENTITY CHANGE");
            updateSDT();
          }, [entity])

          React.useEffect(() => {
            console.warn("PRIMITIVE MOUNTING");
          }, []);

          const [showTypeSelector, setShowTypeSelector] = React.useState(false);

          const { isMobile } = useSizes();

          return (
            <View style={{ width: '100%', backgroundColor: 'white' }}>

              {
                isMobile && (
                  <SystemHeader style={{ padding: 0 }} system={PrimitivesSystem} breadcrumbs={false}>
                    <Icon name="edit" type="material" onPress={() => setShowTypeSelector(!showTypeSelector)} />
                  </SystemHeader>
                )
              }

              {/* Type Selector Modal */}
              {/* CONSIDER:  I really don't like this being plugged in here...  */}
              <ReactNativeModal isVisible={showTypeSelector}>
                {/* TODO:  Dynamically get the SDT from the "TypeSelection" (it's either going to be anonymous or we get it from the reference) */}
                <HessiaTypeSelector typeEntity={typeEntity} sdt={sdt} onClose={() => setShowTypeSelector(!showTypeSelector)} selection={type} select={selectType} />
              </ReactNativeModal>

              <View style={{ flex: 1, backgroundColor: 'white' }}>

                {
                  !!sdt && (
                    <>

                      {/* Value Editor */}
                      {
                        !!type &&
                        <SDTComponent name="Value" topLevel={true} value={value} sdt={sdt} update={updateValue} autoSubmit={true} />
                      }



                    </>
                  )
                }
              </View>
            </View>
          );

        }
      })

      hessia2.registerHOC(({ children }) => {

        const primitiveTypes = davelTypeRegister;
        const hessia2Context = React.useContext(Hessia2Context);
        const [typeEntities, setTypeEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

        const createTypesIfNeeded = async () => {
          const _typeEntities: NounServiceInstanceInternal<Entity>[] = [];
          for (const primitiveType of primitiveTypes) {
            const systemEntityId = entity2.entityService.generateEntityIdentifier(primitiveType);
            const systemEntity = await entity2.entityService.getEntityNounById(systemEntityId);
            if (!systemEntity) {
              const internalEntity = await entity2.entityService.createEntity({
                name: primitiveType.name,
                id: systemEntityId,
                description: primitiveType.description,
                owners: [PrimitivesSystem]
              });
              _typeEntities.push(internalEntity);
            } else {
              _typeEntities.push(systemEntity);
            }
          }
          setTypeEntities(_typeEntities);
        }

        React.useEffect(() => {
          hessia2Context?.installSystem(PrimitivesSystem, false);
          hessia2Context?.installSystem(ModelsSystem, false);
          //  TODO:  Need to make this reactive so when we add new primitive types they get modeled.
          createTypesIfNeeded();
        }, []);

        return (
          <TypeContext.Provider value={{ types: typeEntities }}>
            <PrimitivePluginContext.Provider value={this}>
              {children}
            </PrimitivePluginContext.Provider>
          </TypeContext.Provider>
        );
      });

    } catch (err) {
      alert("Failed to Install Primitive System: " + JSON.stringify(err));
    }
    return this;
  }
}