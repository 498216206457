import * as React from 'react';
import { TextInput, TextStyle, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';
import { KeywordFieldStyle } from '../keyword/keyword-field';

const lineHeight = 15;

const TextFieldStyle = [
  ...KeywordFieldStyle,
  {
    lineHeight,
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 200, maxHeight: 1000
  }
];

export const TextField = ({ style, update, value }: { style: TextStyle, update: (value: string) => void, value: string }) => {

  const numberOfLines = (value && value.split && value.split('\n').length) || 0;
  return (
      <TextInput
        style={[ { height: numberOfLines * lineHeight }, style]}
        onChangeText={update}
        value={value}
        multiline={true}
        autoCapitalize="none"
      />
  );
};

export const sdtTextRenderer = ({ sdt, update, key, value, name }: SDTRendererParams) => (
  <DavelField required={sdt.reqired} name={ name }>
    <TextField style={TextFieldStyle as TextStyle} update={update} value={value} />
  </DavelField>
);
