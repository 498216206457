import { InstanceInternal, NounInternal, PageSelection, Plugin, pluginNoun, SystemAssociation, SystemAssociationNoun, SystemEnablement, SystemEnablementNoun, UserCredentialProps, Workspace } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, TouchableOpacity, View } from 'react-native';
import { RouteComponentProps, withRouter } from '../../../../packages/isomorphic-router/react-router';
import { Card } from '../../../../packages/kelp-bar/card';
import { getRaisedStyle, medSpacer, RaisedHeight, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { haborSDK } from '../../hessia-plugin/config';
import { AggregatePlugin } from '../../system-plugin/plugin/plugin-tools';

//  Function to Nest Nouns under their Parents
export const nestNouns = async (nouns: NounInternal[], token: string): Promise<NounNode[]> => {

  //  Get Parents
  const nounIdToNounMap: { [nounId: string]: NounInternal } = {};
  for (const noun of nouns) {

    //  Unpack
    const { inherits = [] } = noun;

    //  Check Existing
    if (nounIdToNounMap[noun.id] != undefined) {
      continue;
    }

    //  Add the Noun to the Map
    nounIdToNounMap[noun.id] = noun;

    //  Get Parents
    for (const parentNounId of inherits) {

      //  Get the Cached Value
      const parentNoun = nounIdToNounMap[parentNounId];

      //  If undefined, retrieve the noun
      if (parentNoun == undefined) {
        try {
          const parentEntity = await haborSDK.retrieveNoun(parentNounId, token);
          nounIdToNounMap[parentNounId] = parentEntity;
        } catch (err) {
          //  TODO:  SHOULD be able to genericise error handling in Hessia.
          console.warn(`Failed to find the '${parentNounId}' Noun while building the dependency tree.\nFunction:  '${nestNouns.name}'\nError:  ${JSON.stringify(err)}`);

        }
      }
    }
  }

  //  Define All Nouns
  const allNouns = Object.keys(nounIdToNounMap).map(nounId => nounIdToNounMap[nounId]);

  //  Create the Entity Hierarchy
  const flatMap: { [nounId: string]: NounNode } = {};
  const topLevelEntities: NounNode[] = [];

  //  Create the Flat Map
  //  NOTE:  We COULD combine this with the section above, but I believe this improves readability.
  for (const noun of allNouns) {
    flatMap[noun.id] = { noun: noun, children: [] };
  }

  //  Update the Map with the 
  for (const noun of allNouns) {

    //  Unpack
    console.log(noun);
    const { id: nounId, inherits = [] } = noun;

    //  Get the Noun Node
    const nounNode = flatMap[nounId];

    //  Process Top Level
    if (!inherits.length) { topLevelEntities.push(nounNode); continue; }

    //  Process Child
    for (const parentNounId of inherits) {
      const parentNounNode = flatMap[parentNounId];
      //  TODO:  PERHAPS we should support "strict" mode for this? Hmm...
      if (parentNounNode) {
        parentNounNode.children.push(nounNode);
      }
    }
  }

  return topLevelEntities;
}

export interface NounNode {
  noun: NounInternal;
  children: NounNode[];
}

export interface NounNodeProps extends RouteComponentProps {
  nounNode: NounNode;
  changePage: (pageSelection: PageSelection) => void;
}
export interface NounNodeState {
  showChildren: boolean;
}

export const ModelCard = ({ noun, group, onPress }: { noun: NounInternal, group: boolean, onPress: any }) => {
  return (
    <Card onPress={onPress} outerStyle={{ height: 75 }} innerStyle={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1, padding: 20 }}>
      <View style={{ flex: 1 }}>
        <Text style={{ fontFamily: "Poppins-Bold", fontSize: 14, color: "rgba(0,0,0,0.5)" }} >{noun.name.toUpperCase()}</Text>
        <Text style={{ fontFamily: "Poppins-Bold", fontSize: 10, color: "rgba(0,0,0,0.5)" }} >{noun.description.toUpperCase()}</Text>
      </View>
      {/* {
        !group ? [
          <MaterialIcons name='build' size={23} color="gray" style={{ marginLeft: smallSpacer, marginRight: smallSpacer }} />,
          // TODO-NEXT:  This should link to the entity INSTANCE creator.
          <MaterialIcons name='add' size={23} color="gray" style={{ marginLeft: smallSpacer, marginRight: smallSpacer }} />
        ] : null
      } */}
    </Card>
  );
}

class NounNodeViewerBase extends React.Component<NounNodeProps, NounNodeState> {

  constructor(props: NounNodeProps) {
    super(props);
    this.state = {
      showChildren: false
    };
  }

  private toggleChildren = () => {
    this.setState({ showChildren: !this.state.showChildren });
  }

  public render() {
    const { nounNode: { children, noun }, changePage } = this.props;
    const { showChildren } = this.state;
    const groupNouns = children.length && !showChildren;

    return (
      <React.Fragment>

        {/* Visual effect showing a stack of Entities */}
        {groupNouns ? [
          <TouchableOpacity style={[{ position: 'absolute', top: -6, left: 6, height: 75, borderRadius: 5, padding: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }, getRaisedStyle({ raisedHeight: RaisedHeight.med })]} />,
          <TouchableOpacity style={[{ position: 'absolute', top: -3, left: 3, height: 75, borderRadius: 5, padding: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }, getRaisedStyle({ raisedHeight: RaisedHeight.med })]} />
        ] : null}

        {/* CONCERN:  How can we enforce the type on the page change?  We want to make sure we're passing the right props.  We SHOULD be able to to this with TS when we're in this primitive context.  In the managed context of HaborCode, we should be able to do it with Davel. */}
        {/* TODO-IMPORTANT:  We are selecting a page by NAME here.  We need to do TWO things:  Force UNIQUENESS in the backend for this field (consider TOP-level support ) */}

        <View style={{ height: smallSpacer }} />
        {showChildren ? children.map(child => <NounNodeViewer changePage={changePage} nounNode={child} />) : null}
      </React.Fragment>


    );
  }
}
export const NounNodeViewer = withRouter(NounNodeViewerBase);

interface EntityListProps extends RouteComponentProps, UserCredentialProps {
  // workspace: InstanceInternal<Workspace>;
  changePage: (PageSelection: PageSelection) => void;
}

interface EntityListState {
  // installedPlugins: { [pluginId: string]: AggregatePlugin };
  // strayEntities: NounInternal[];
  topLevelEntities: NounNode[];
  showAll: boolean;
  entities: NounInternal[];
}

const getForWorkspace = async (token: string, workspace: InstanceInternal<Workspace>) => {

  const allEntities = await haborSDK.searchNouns(token);

  //  Get Installed User Plugin Relationships (each relationship indicates Plugin installation)
  const pluginRelationships: InstanceInternal<SystemEnablement>[] = await haborSDK.searchInstances<SystemEnablement>(token, { nounId: SystemEnablementNoun.id, search: { all: [{ match: { payload: { destId: { instanceId: workspace.id } } } }] } });
  if (pluginRelationships.length <= 0) { return; }

  //  Get the Plugins
  const installedPlugins: InstanceInternal<Plugin>[] = await haborSDK.searchInstances(token, { nounId: pluginNoun.id, search: { any: pluginRelationships.map(rel => ({ match: { id: rel.payload.srcId.instanceId } })) } });

  //  Get the Plugin Noun Relationships
  const nounMatchTerms = installedPlugins.map(plugin => ({ match: { payload: { destId: { instanceId: plugin.id } } } }));
  const pluginNounRelationships: InstanceInternal<SystemAssociation>[] = await haborSDK.searchInstances<SystemAssociation>(token, { nounId: SystemAssociationNoun.id, search: { all: [{ any: nounMatchTerms }] } });

  //  Get the Plugin Nouns
  const pluginNouns = await haborSDK.searchNouns(token, { search: { any: pluginNounRelationships.map(rel => ({ match: { id: rel.payload.srcId.nounId } })) } });

  //  TODO-IMPORTANT:  We need to enforce dependencies in Plugins!  I was able to install one thta relied on Events and it's throwing an error.  Should NOT have been installable, and should show intelligible error.

  //  Group the Nouns by Plugin
  const strayEntities: NounInternal[] = [];
  const pluginGroups: { [pluginId: string]: AggregatePlugin } = {};
  pluginNouns.forEach(noun => {
    const rel = pluginNounRelationships.find((value, index) => value.payload.srcId.nounId == noun.id);
    if (!rel) {
      console.log('Entity found without a relationship to a Plugin.');
      strayEntities.push(noun);
      return;
    }
    const pluginId = rel.payload.destId.instanceId as string;
    const plugin = installedPlugins.find((value, index) => value.id == pluginId);
    if (!plugin) { throw new Error("No Plugin with the given Plugin ID.") }
    const pluginGroup: AggregatePlugin = pluginGroups[pluginId] ? pluginGroups[pluginId] : { plugin, nouns: [], instances: {} };
    pluginGroup.nouns.push(noun);
    pluginGroups[pluginId] = pluginGroup;
  });

  //  Group Nouns
  const topLevelEntities = await nestNouns([...pluginNouns, ...allEntities], token);

  return {
    pluginGroups, strayEntities, topLevelEntities
  }

}

class EntityListBase extends React.Component<EntityListProps, EntityListState> {

  constructor(props: any) {
    super(props);
    this.state = {
      entities: [],
      showAll: false,
      topLevelEntities: []
    };
  }


  public updateEntities = async (showAll: boolean) => {

    const { token, user } = this.props;

    const entities = await haborSDK.searchNouns(token);

    //  Group Nouns
    const topLevelEntities = await nestNouns([...entities], token);

    this.setState({ entities, topLevelEntities });
  }

  public componentDidMount = () => {

    //  Unpack
    const { showAll } = this.state;

    this.updateEntities(showAll);
  }

  //  IDEA:  This MAY make more sense as a fully containerized thing that knows how to get these, and then inject them into the component?  Almost like a mini-plugin??
  public toggleShowAll = () => {

    //  Get the Current Value
    const { showAll } = this.state;

    //  Update the Value
    const newShowAll = !showAll;

    //  TODO:  Support NESTING at the top level!  AH!  Let's just rebrand this so model.

    //  Update the Entities
    this.updateEntities(newShowAll);

    //  Update the State
    this.setState({ showAll: newShowAll })
  }

  //  TODO:  We will use a "Hessia" / "Hessia" API to control the Workspace from a Primitive Component?  I REALLY think we should move away from explicit "Pages"... Maybe... or at least support nested Pages / Components soon.  
  public selectEntity = (noun: NounInternal, edit?: boolean) => {
    // const { workspace } = this.props;

    if (edit) {
      this.props.history.push(`/apps/entities/edit`, { noun });
    } else {
      this.props.history.push(`/apps/entities/create`, {});
    }
  }

  public render = () => {

    const { changePage } = this.props;

    return (
      <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <View style={{ display: 'flex', flex: 2 }}>
          <View style={{ flex: 1, padding: medSpacer }}>
            <Button title="Toggle All" onPress={this.toggleShowAll} />
            {this.state.topLevelEntities.map(nounNode => <NounNodeViewer changePage={changePage} nounNode={nounNode} />)}
          </View>
        </View>
      </View>
    );
  }
}

export const EntityList = withRouter(EntityListBase);
