import { CorePluginClass, Program } from 'halia';
import { AppsPlugin } from '../apps-plugin/apps-plugin';
import { ElementWidget as ElementWidget } from './element-widget.component';
import { registerAppSystemWidget } from '../apps-plugin/screens/app-editor.screen';

export class ModelAppPlugin extends CorePluginClass {

  public static details = {
    name: 'Models for Apps Plugin',
    description: 'Adds Model Support for Apps',
    dependencies: [AppsPlugin.details.id],
    id: 'entitiesHappPlugin'
  }

  public install = (program: Program, { appsPlugin }: { appsPlugin: AppsPlugin }) => {
    registerAppSystemWidget({
      name: "Model Widget",
      description: "Model Widget",
      id: "model-widget",
      component: ElementWidget
    });

    return this;
  }
}