import { StackNavigationProp } from '@react-navigation/stack';
import { Color, Noun, nounFromInternal, NounId, NounInternal, NounProperty } from 'habor-sdk';
import * as React from 'react';
import { Button, LayoutAnimation, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { BubbleSwitch } from '../../../../packages/kelp-bar/bubble-switch';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { primaryFontFamily, primaryFontFamilyHeavy, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { GalleryButton } from '../../../gallery/components/common';
import { FieldContainer, TextField } from '../../../gallery/components/fields';
import { GroupCard } from '../../../gallery/components/group-card';
import { Paragraph } from '../../../gallery/constants';
import { CenteredView } from '../../../gallery/content-view';
import { AppContext } from '../../hessia-plugin/AppContext';
import { RoundButton } from '../../workspace-plugin/workspace-switcher';
import { enableNounsForPlugin, getClassProperties, getInstanceProperties } from '../entity-utils';
import { ModelSDK } from '../model-sdk';
import { } from './instance-creator';
import { primitives } from './primitives';
import { PropertiesView } from './property-editor';

//
//  TODOs
//

//  TODO:  Make a TS Decorator for throwing error messages from a function? Hmm MAYBE... but sometimes we have MULTIPLE hmm... MAYBE support multiple with conversion from a struct thing? Hmm.. what about deeply nested?  Do it on the sub-functions too then? HM! maybe.
//  TODO-IMPORTANT:  Duplicated from Habor.. should be exposed as an API / SDK from the primitive Habor System system!
//  TODO:  The POINT is when we're RUNNING the app and an error is thrown, we LOSE that info hm!  MAYBE we can pepper the code with things to help but... isn't it already in the debugger? Hmm... maybe.  But I think it could help to do it at runtime too.  MAYBEjust activate a debugger?  MAYBE though I want to convert the LANGUAGE into my own App domain thing hM!  LOL would be silly to keep the line number and stuff htere hmm  EVEN function name may be too much mhmm..  DECORATOR can grab it hm!


export interface PropertyBoxProps {
  property: NounProperty;
  onPress: (property: NounProperty) => void;
  editable: boolean;
  onDelete: (property: NounProperty) => void;
}
interface PropertyBoxState { }
class PropertyBoxBase extends React.Component<PropertyBoxProps, PropertyBoxState> {
  constructor(props: PropertyBoxProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { property, onPress, editable } = this.props;

    if (!property.type) { throw new Error(`Noun Property does not have an associated type: '${property.name}'`) }
    const typeInfo = primitives[property.type.type];
    if (!typeInfo) {
      throw new Error(`TypeInfo not found for type: '${property.type.type}'`)
    }

    const ViewComponent: any = editable ? TouchableOpacity : View;
    return (
      <ViewComponent style={{ marginBottom: smallSpacer, display: 'flex', flexDirection: 'row', borderRadius: 7, backgroundColor: editable ? '#eeeeee' : '#f4f4f4', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 15 }} onPress={() => onPress(property)}>
        <KelpIcon type="font-awesome" name={typeInfo.fontAwesomeIcon} color={typeInfo.color} />
        <View style={{ width: 10 }} />
        <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 18, color: '#333333' }}>{property.name}</Text>
          <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 14, color: '#888888' }}>{property.type.type.toUpperCase()}</Text>
        </View>
        {
          editable ?
            <RoundButton icon={{ type: "material", name: "delete" }} iconColor={Color.almostDarkGray} circleColor="#bfbfbf" selected={false} onPress={() => this.props.onDelete(property)} size={40} /> :
            null
        }
      </ViewComponent>
    );
  }
}
export const PropertyBox = PropertyBoxBase

enum InheritanceAction {
  Add, Remove
}

interface EntityEditorProps {
  navigation?: StackNavigationProp<any>;
  noun?: Noun;
  nounId?: NounId;
  modelSDK: ModelSDK;
}

interface EntityEditorState {
  spinner: boolean;
  // properties: { [propName: string]: NounProperty };
  noun?: Noun;
  editEntity: boolean;
  propertyEditor: boolean;
  selectedProperty?: NounProperty;
  parents: NounInternal[];
  className?: string;
  //  TODO:  FOR NOW, let's use an EditMode, BUT in the future, I'd like to invoke a "Delegate System".  This would be mounted higher in the component tree and a "Stack" system can keep track to properly mount in absolute containers above the others.  Then, a callback is invoked to pass the data back to the parent (or some mechanism).
}

export class EntityEditor extends React.Component<EntityEditorProps, EntityEditorState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      spinner: false,
      editEntity: false,
      noun: undefined,
      propertyEditor: false,
      selectedProperty: undefined,
      parents: []
    };
  }

  public componentDidMount = async () => {

    //  Unpack Props
    const { token } = this.context.frameworkContext;
    const { nounId, noun } = this.props;

    if (noun) {

      //  Set Parents
      await this.refreshParentClasses(noun);

      //  Update the State
      this.setState({ noun });

      return;

    }

    if (nounId) {

      //  Get the Noun
      const nounInternal = await this.props.modelSDK.getModelByID(nounId.nounId);

      //  CONSIDER:  Should this be a backend function.. I want to keep the front-end as simple as possible... I THINK I should have a day to work through technical debt???
      const noun = nounFromInternal(nounInternal);

      //  Set Parents
      await this.refreshParentClasses(noun);

      //  Update the State
      this.setState({ noun });

      return;
    }

    this.setState({
      noun: {
        id: "",
        name: "",
        description: "",
        properties: {}
      }
    })
  }

  private refreshParentClasses = async (noun: Noun) => {

    //  Unpack
    const { token } = this.context.frameworkContext;

    //  Get the Parents
    const parents = await this.props.modelSDK.getAllParentClasses(noun);

    this.setState({ parents });
  }

  private toggleEditEntity = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
    this.setState({ editEntity: !this.state.editEntity });
  }

  private updateParentClass = (className: string, action?: InheritanceAction) => {

    //  Unpack
    const { noun } = this.state;

    //  Guard
    if (!noun) { throw new Error("Cannot add the class because the entity is undefined."); }

    //  Copy Noun
    const nounCopy: Noun = { ...noun };

    //  Add the Class
    const parentList = noun.inherits ? [...noun.inherits] : [];
    parentList.push(className);
    nounCopy.inherits = parentList;

    //  Update the Noun
    this.setState({ noun: nounCopy })

    //  Refresh the Parents
    this.refreshParentClasses(nounCopy);
  }

  private onPropertyChange = (property?: NounProperty) => {

    //  Unpack
    const { noun } = this.state;

    //  Guard
    if (!noun) { return; }
    if (!property) {
      this.setState({ selectedProperty: undefined, propertyEditor: false });
      return;
    }

    //  Update the Noun
    const newNoun: Noun = { ...noun, properties: { ...noun.properties, [property.name]: property } }

    //  Update the State
    this.setState({ noun: newNoun, selectedProperty: undefined, propertyEditor: false });
  }

  //  TODO-IMPORTANT:  Much of this duplicated from create.tsx, centralize somewhere.
  //  TODO-CRITICAL:  When we push a NOUN to the server, we should NOT have to specify the create / update properties... JUST the noun.
  //  CONSIDER:  Should this be enforced in Habor?  Ah!  This IS a field on "TMObject", but ALSO a field on the Noun... we should clean this up.  MAYBE make a Name for the Noun?  Either way, I don't think it should be editable after the first creation.  So... we MAY want "NounCreateParams" and "NounEditParams".  Should we ALSO support that for other objects in Hessia?
  //  CONSIDER:  Client-side validation / sanitization.
  private handleSubmit = async () => {

    //  TODO:  Still want to generalize the error catching.  MAYBE use my ENTITY GRAPH.. "node" network that I've been talking about for SO long.  I DON'T know how similar it is to Gatsby byt WHATEVER... of course there's simlar shit out there ugh!

    try {
      //  Unpack
      const { token, workspace, plugin } = this.context.frameworkContext;
      const { noun } = this.state;

      const nounId = this.props.nounId?.nounId || this.props.noun?.id;

      //  Guard
      if (!noun) { throw "Cannot submit the form without a valid entity." }

      //  Create or Update the Noun
      let nounInternal: NounInternal;
      if (nounId) {
        try {
          //  TODO:  We remove updated / created, because it's possible the noun is coming in as NounInternal.  In that case, we're really doing this to cast it to a Noun.  These fields are handled fully by the backend.
          delete noun['updated'];
          delete noun['created'];
          nounInternal = await this.props.modelSDK.updateModel(nounId, noun);
        } catch (err) {
          throw "Error while updating the model: " + err;
        }
      } else {
        try {
          nounInternal = await this.props.modelSDK.createModel(noun);
        } catch (err) {
          throw "Error while creating the model: " + JSON.stringify(err);
        }
      }

      //  Attach

      //  TODO-IMPORTANT:  JUST like the instance, this should have 3 things (at least):
      //  1:  An API / SDK contributed by the "System" primitive Habor system.
      //  2:  A built-in CONTEXT system which will automatically do this based on the CONTEXT in the backend!?
      //  3:  Before / After action broadcasts, and PERHAPs a hook to obtain the curreently executing job for cancelation and stuff??? 

      //  TODO:  IF we want to add to a Plugin (inside Habor or otherwise) it can be "Injected" here? Hmm... or... we can DEPEND upon this EVENT NODE? Hmm.. it's up to us perhaps it we want it to BLOCK / break .. MAYBE we can inject a system that will let us suppor that, OR if we want it to continue in parallel.  ALL that is just ADDITIONAL DIFFERENTIATION in the process hm!
      if (plugin) {
        try {
          await enableNounsForPlugin([nounInternal], plugin, this.props.modelSDK);
        } catch (err) {

          throw "Error while enabling the plugins" + err;
        }
        //  TODO:  Make a NICE pop-up??
        alert("Enabled Noun For Plugin");
      }

      // s

      //  TODO:  I don't like that this 'handleSubmit' function knows about this navigation!
      //  INSTEAD, make a new function "createOrUpdateEntity"... or something like that which does the creation, and a new onSubmit function which SHOULD be privy to that info.s
      this.close();
    } catch (err) {
      throw "Handle Submit:  " + JSON.stringify(err);
    }
  };

  private close = () => {
    //  TODO:  I don't like:
    //  1.  That we combine the "navigate" AND "history" APIs.
    //  2.  That this is the responsibility of THIS function.
    //  3.  That we are calling "goBack", when in the future, we MAY have other nested routes that we access WITHIN this "Delegate Context".  We REALLY want to go back to the PARENT which invoked this "Delegate Context"!!

    //  NOTE:  When we pass "navigation" that's from ONE context.. one differentiated use-case.  I WOULD like to be able to ADD that in DYNAMICALLY!  BASICALLY like Extension I suppose? Hmm... BUT... like the SAME thing, but with that added? Hmm... Maybe... The problem with EXTENSION at THIS level, is what if we wanted to do something deeply nested??? It's challenging!  INSTEAD, we can "inject" into the deeply nested spot and still consider it an "extension" BUT maybe NOT have a separate symbol and just ALLOW the encoding / constelation "under" a name (encoding) to fucking change.  Why the fuck not??? HM!
    if (this.props.navigation) {
      this.props.navigation.goBack();
    }
  }

  private handleNameChange = (name: string) => {

    //  Unpack
    const { noun } = this.state;

    //  Guard
    if (!noun) { return; }

    //  Update the Noun
    const newNoun: Noun = { ...noun, name }

    //  Update the State
    this.setState({ noun: newNoun });
  }

  private handleDescriptionChange = (description: string) => {

    //  Unpack
    const { noun } = this.state;

    //  Guard
    if (!noun) { return; }

    //  Update the Noun
    const newNoun: Noun = { ...noun, description }

    //  Update the State
    this.setState({ noun: newNoun });
  }

  private makeNewProperty = () => {
    this.setState({ propertyEditor: true, selectedProperty: undefined });
  }

  private updateProperty = (prop: NounProperty) => {
    this.setState({ propertyEditor: true, selectedProperty: prop });
  }

  public handleDelete = (property: NounProperty) => {

    const { noun } = this.state;

    if (!noun) { throw `Cannot delete property "${property.name}", no noun has been set.` }

    //  New Properties
    const newProps = { ...noun.properties };
    delete newProps[property.name];

    //  Update the Noun
    const newNoun: Noun = { ...noun, properties: { ...newProps } }

    //  Update the State
    this.setState({ noun: newNoun, selectedProperty: undefined, propertyEditor: false });

  }

  //  TODO:  Get the types from the server instead of hard-coding.  This way, users can create new types?
  public render = () => {

    //  Unpack Props
    const { token, plugin, workspace } = this.context.frameworkContext;
    const { nounId } = this.props;
    const { noun, editEntity, propertyEditor, selectedProperty, parents } = this.state;

    //  Guard
    //  TODO:  Split this whole file into TWO components, one for Creation, and another for Editing.
    if (!noun) {
      return <Text>Loading...</Text>
    }

    const updating = noun.id != "";

    //  Set Initial Values
    //  TODO:  Use the TMObject Schema to SUBTRACT these types instead of doing it manually.  This seems pretty similar to TS.
    const initialValues: NounInternal | any = { ...noun } || {};
    delete initialValues["updated"];
    delete initialValues["created"];

    //  TODO:  Does it make sense to have this here?  Sometimes the concept of cancelation doesn't make sense on the rendered form, for example, when NOT in a modal.
    return (
      <View style={{ flex: 1, backgroundColor: 'white', padding: 30 }}>

        {
          propertyEditor ?
            <PropertiesView static={editEntity} onNewProperty={this.onPropertyChange} prop={selectedProperty} /> :
            null
        }



        <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }} contentContainerStyle={{ display: 'flex' }}>
          <CenteredView style={{ padding: 30, maxWidth: 600 }}>



            <TextField title='Name' value={noun.name} onSave={this.handleNameChange} mode={updating ? "manual" : "automatic"} />

            <View style={{ marginVertical: 30, height: 2, backgroundColor: '#eeeeee' }} />

            <TextField title='Description' value={noun.description} onSave={this.handleDescriptionChange} mode={updating ? "manual" : "automatic"} />

            <View style={{ marginVertical: 30, height: 2, backgroundColor: '#eeeeee' }} />

            {/* TODO:  Auto-complete for classes */}
            <View>
              <FieldContainer title='Inherits'>
                <GroupCard style={{ marginTop: 15 }}>
                  <TextField title='Model ID' value={this.state.className} onSave={(className) => this.setState({ className })} mode="automatic" />
                  <GalleryButton disabled={!this.state.className} title="Add Extension" onPress={async () => this.state.className ? await this.updateParentClass(this.state.className) : null} />
                </GroupCard>
              </FieldContainer>
            </View>


            {/* TODO-NEXT:  Support inheritance selection...  PERHAPS we can use the default davel snoun editor for now?  Hmm...  that seemed decent enough?  Hmm...  */}


            {/* NOTE:  FOR NOW, I'm going to disable "Model" variables.  We can use the Entity / Property systems for that. */}
            {/* <BubbleSwitch onTitle="Entity" offTitle="Instance" onChange={this.toggleEditEntity} value={this.state.editEntity} /> */}

            <View style={{ marginVertical: 30, height: 2, backgroundColor: '#eeeeee' }} />

            {/* Owned Properties */}

            <View>
              <FieldContainer onPress={this.makeNewProperty} buttonText='Create' title='Properties'>
                <GroupCard style={{ marginTop: 15 }}>
                  <>
                    {
                      // TODO:  Remove the redundency here.
                      (editEntity ? getClassProperties(noun) : getInstanceProperties(noun)).length ?
                        (editEntity ? getClassProperties(noun) : getInstanceProperties(noun)).map(nounProp => <PropertyBox onDelete={this.handleDelete} editable={true} property={nounProp} onPress={this.updateProperty} />) :
                        <Text style={{ fontFamily: primaryFontFamily, fontSize: 14, color: Color.almostDarkGray }}>No Properties</Text>
                    }
                  </>
                </GroupCard>
              </FieldContainer>
            </View>


            {/* Inherited Properties (May be overridden) */}
            {/* TODO:  Show an OVERRIDE indicator, which, when pressed, jumps to the override property. */}
            {
              parents.map(parent => (
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingBottom: 20 }}>
                    <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 16, letterSpacing: 3, color: Color.almostDarkGray }}>{parent.name}</Text>
                    </View>
                    <RoundButton size={40} circleColor="#66cbab" iconColor={Color.almostDarkGray} selected={false} icon={{ type: "font-awesome", name: "edit" }} onPress={() => alert("This should go to:  navigate(this.props.history, RouteList.Entities.Edit, { noun: parent, plugin, workspace })")} />
                  </View>
                  {
                    // TODO:  Remove the redundency here.
                    (editEntity ? getClassProperties(parent) : getInstanceProperties(parent)).length ?
                      (editEntity ? getClassProperties(parent) : getInstanceProperties(parent)).map(nounProp => <PropertyBox onDelete={this.handleDelete} editable={false} property={nounProp} onPress={this.updateProperty} />) :
                      <Text style={{ fontFamily: primaryFontFamily, fontSize: 14, color: Color.almostDarkGray }}>No Properties</Text>
                  }
                </View>
              ))
            }

            <View style={{ height: 30 }} />

            <GalleryButton title='Submit' onPress={async () => { try { await this.handleSubmit(); this.props.navigation?.goBack(); } catch (err) { alert("Entity Editor Error: " + JSON.stringify(err)) } }} />

            <View style={{ height: 7 }} />

            <GalleryButton title='Cancel' onPress={this.close} />

            <View style={{ height: 7 }} />

            {
              noun ?
                // TODO-GENERAL:  Deal with this in a moe generic, possibly using the backend / action system so EACH system has a say in an object's deletion, INCLDING a possible cascasde???s
                <GalleryButton title='Delete' onPress={async () => {
                  await this.props.modelSDK.deleteModel(noun.id);
                  if (this.props.navigation) {
                    this.props.navigation.goBack();
                  }
                }} /> :
                null
            }

            {/* <ScrollView style={{ flex: 5 }}>
    <View style={{ height: '100%', width: '100%', display: 'flex' }}>
      { this.state.spinner ? <ActivityIndicator /> : undefined }
      <View style={{ margin: largeSpacer }}>
        <DavelForm schema={ nounSerializedSchema } onSubmit={ this.onSubmitNounForm } value={ initialValues } onCancel={ () => {} } metadata={{ token: this.props.token }} />
      </View>
    </View>
  </ScrollView> */}
          </CenteredView>
        </ScrollView>
      </View>
    );
  }
}
