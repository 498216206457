import * as React from 'react';
import { Image, ImageSourcePropType } from "react-native";
import { Icon, IconProps } from "react-native-elements";
import { IconType } from 'react-native-elements/dist/icons/Icon';
import { unpackAsset } from '../unpack/unpack';

//  CONSIDER:  Consider doing this with a registration function.
//  TODO:  Don't hard-code this!
const KelpIconMap: { [name: string]: ImageSourcePropType } = {
  dashboard: unpackAsset(require('../../assets/icons/Dashboard.png')),
  entities: unpackAsset(require('../../assets/icons/Entities.png')),
  magic: unpackAsset(require('../../assets/icons/Magic.png')),
  tools: unpackAsset(require('../../assets/icons/Tools.png')),
  more: unpackAsset(require('../../assets/icons/More.png')),
  system: unpackAsset(require('../../assets/icons/System.png')),
  menu: unpackAsset(require('../../assets/icons/Menu.png')),
  exit: unpackAsset(require('../../assets/icons/Exit.png')),
  pages: unpackAsset(require('../../assets/icons/Pages.png')),
  gears: unpackAsset(require('../../assets/icons/Gears.png'))
}

export interface KelpIconProps extends IconProps {
  //  TODO:  Remove the "habor" concept!
  type: IconType | "habor";
}

export const KelpIcon = (props: KelpIconProps) => {

  //  Unpack
  const { name, type, size = 20, color } = props;

  //  Map from Name to Resource

  return (
    props.type == "habor" ?
      <Image source={ KelpIconMap[name] } style={{ width: size * 3/4, height: size * 3/4, tintColor: color }} /> :
      <Icon { ...props } size={ size } />
  );
}
