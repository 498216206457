import { AnyBlock, BlockInst, BlockType, FrameworkContext, HaborComponentContext, InstanceInternal, SignalValueReference } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native-elements';
import { AppContext } from '../../../hessia-plugin/AppContext';
import { resolveBlock } from '../models/blocks';
import { resolveSignals } from '../utils';

/**
 * Renders a HaborComponentNew Object
 */

export interface HaborComponentNewRendererProps {
  component: AnyBlock;
  input?: any;
  setOutput: (newSignals: any, blockInst?: BlockInst) => void;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  blockInst?: BlockInst;  //  NOTE:  NOT required to use this, but used for nested blocks?
  [key: string]: any;
}
interface HaborComponentNewRendererState {
  signals: any;
  output: any;
  blockMap: { [blockId: string]: InstanceInternal<AnyBlock> };
  // changeList: any;
}

//  TODO:  Figure out WHY we can't use "HaborReactComponent" here... gives a weird error reated to "super" in the UI.
export class BlockRenderer extends React.Component<HaborComponentNewRendererProps, HaborComponentNewRendererState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: HaborComponentNewRendererProps) {
    super(props);
    this.state = {
      signals: {},
      output: {},
      blockMap: {}
      // changeList: {}  //  TODO:  Does this defeat the purpose of using React at all??
    }
  }

  //  TODO / CONCERN:  We MAY want to build a system that's LIKE React, but which doesn't have it's overhead... I'm NOT sure if we really want to use React in the backend or use React to run plain functions which have no output... Maybe... but then we're using it for state encapculation.  However, CHILDREN will know that their parents are stateful in this case, because we're calling recursively, and each child knows to call "setSignals" to set the state of the parent!  This deviates from the typical React model, BUT I think it's OK!  It's a new thing which lets the parent hold signals defined by the child... Either way, we can assume that the child doesn't know that it's being stored on the parent... it can be stored ANYWHERE.  I just happen to know it's stored on parent!
  //  CONSIDER:  We COULD de-couple from React.. currently, we DON'T just use React as a VIEW library!  We use it as a dependency maangement / reactive program intepreter!?  Hmmm!!!???

  public setSignals = (newSignals: any, blockInst: BlockInst) => {

    //  Unpack
    const { signals } = this.state;

    console.log("Set Signals Called: " + JSON.stringify(newSignals));
    console.log("Existing Signals: " + JSON.stringify(signals));

    //  Validate
    //  TODO:  Validate the output value!

    //  Update the Change List 
    //  NOTE:  We assume that if a value was provided here, then it's a change.
    // const newChangeList: any = {};
    // Object.keys(newSignals).forEach(key => {
    //   newChangeList[key] = true;
    // });

    //  Map the Signals
    //  EXPLANATION:  THe incoming signals are the OUTPUT of a block.  However, the Block has an INSTANTIATION mapping it to the signals in THIS block.  So.. we want to use that!
    //  CONSIDER:  We could do this with a Lodash mapping function?
    const mappedSignals: any = {};
    const mappedOutput: any = {};
    for(const signalId in newSignals) {
      const signalValue = newSignals[signalId];
      const output = blockInst?.output;
      if (!output) { console.warn(`Missing output from block '${ blockInst.blockId }' with ID '${ blockInst.id }' while setting the signals.`); continue; }
      const valueReference = output[signalId] as SignalValueReference;
      //  CONSIDER-GENERAL:  Group things like this (functions of the current domain / system) into functions? Hmm...  MAYBE make sub-systems???
      const values = valueReference.signalId.split(".");
      const type = values[0];
      values.splice(0, 1)  //  Remove the First
      const mappedId = values.join(".");

      if (type === "state") {
        mappedSignals[mappedId] = signalValue;
      } else {
        mappedOutput[mappedId] = signalValue;
      }
      //  EXPLANATION:  Remove the "state" prefix, which indicates that this signal comes from the STATE instead of the input...?
      //  TODO:  This seems MESSY!  Perhaps we should NOT have such a prefix, because ALL output signals are going to be added to state!? Hmm... BUT, when we REFERENCE the signal, we still want to know where it should locate the signal?  PERHAPS there should NOT be a namespace difference, and they can ALL be in the SAME namespace!?  I THINK I prefer that!!!  THEN, it's JUST like Props / Params and Local Vars in a Function... they CAN override, and they can shadow / COLLIDE.
      //  TODO-CRITICAL:  We DON'T always map from STATE, we COULD potentially pass-through an input (prop)!???
      
      // mappedSignals[mappedId] = signalValue;
    }

    //  Update the Signal
    // this.setState((state) => ({ signals: { ...state.signals, ...newSignals }, changeList: { ...state.changeList, ...newChangeList } }));
    this.setState((state) => ({ signals: { ...state.signals, ...mappedSignals }, output: { ...state.output, ...mappedOutput } }), () => {
      this.props.setOutput(this.state.output, this.props.blockInst);
    });
  }

  public defaultEmpty = {};

  public shouldComponentUpdate = (nextProps: any, nextState: any) => {

    //  Check New / Deleted Keys
    if (Object.keys(nextProps).length !== Object.keys(this.props).length) { return true; }
    if (Object.keys(nextState).length !== Object.keys(this.state).length) { return true; }

    for(const key in this.state) {
      const currentStateVal = (this.state as any)[key];
      const nextStateVal = nextState[key];
      if (currentStateVal !== nextStateVal) {
        return true;
      }
    }

    for(const propName in this.props) {
      const currentPropsVal = (this.props as any)[propName];
      const nextPropsVal = nextProps[propName];
      if (currentPropsVal !== nextPropsVal) {

        //  Check if this is a Reactive Input
        const { inputSchema } = this.props.component;
        const { properties: inputProperties = {} } = inputSchema || {};
        if (propName in inputProperties) {
          const type = inputProperties[propName];
          if (!type.meta || type.meta.reactive !== false) {
            return true;
          }
        } else {
          //  This prop is NOT an input prop, so we re-render... 
          //  TODO:  There COULD be a naming collision!  We SHOULD namespace the component inputs in their own "input" prop or something!?s
          return true;
        }
      }
    }
    
    return false;
  }

  // public componentWillMount = async () => {
  //   const { component } = this.props;
  //   const { frameworkContext: { token } } = this.context;
  //   console.log("test");
  //   if (component.type == BlockType.User) {
  //     const instMap: { [blockId: string]: InstanceInternal<AnyBlock> } = {};
  //     for (const blockInst of component.blockInsts) {
  //       //  NOTE:  REMEMBER, a BlockInst is a COUPLING of I/O and a Block WITHIN a running block.
  //       const instComp = await resolveBlock(token, blockInst.blockId);
  //       if (!instComp) { throw `Block not found: '${ blockInst.blockId }'`; }
  //       instMap[blockInst.id] = instComp;
  //     }
  //     this.setState({ instMap });
  //   }
  // }

  public render = () => {

    //  Unpack
    const { component, setOutput, frameworkContext, frameworkContext: { token }, componentContext, blockInst, ...input } = this.props;
    const { signals, output } = this.state;

    //  NOTE:  The "input" prop holds SOME of the "signals" we're going to hook to in our children.  We do NOT change these.  The "signals" state holds the signals that our child blocks will create / change!.  But, we DO control the "output" signals.  What does this mean?  A primitive function has the option to control these signals, but it doesn't have to?  Remember, a signal needs to be set on the parent component.  This means, whenever we have an output, we're simply setting that in our own component.  Then, OTHER children can use it. 

    const updatedInput: any = Object.keys(input).length == 0 ? this.defaultEmpty : { ...input };
    delete updatedInput["frameworkContext"];
    delete updatedInput["componentContext"];
    delete updatedInput["setOutput"];
    delete updatedInput["component"];
    delete updatedInput["blockInst"];

    //  Create the Signal Map
    const signalMap = {
      input: updatedInput,
      state: signals,
      output: output
    }

    //  NOTE:  FOR NOW we are assuming there are ONLY two BlockTypes.  If this assumption changes, then we may need additional conditionals.

    //  Handle PrimitiveBlock
    //  NOTE:  When we handle a primitive, there's no need to "componentDidMount", because all the logic will happen in that primitive... it will call the callback, which will change props?
    //  NOTE:  We ARE responsible for handling the signal changes at this point??
    if (component.type == BlockType.Primitive) {
      const ReactPrimitive = component.renderer;
      const res = <ReactPrimitive { ...updatedInput } frameworkParams={ { blockInst, setOutput, frameworkContext, componentContext } } />
      console.log(res);
      return res || null;
    }

    //  Handle UserBLock
    //  Get the Components
    // const components = getComponents(component.blockInsts);

    //  OK to be honest this is gettting dang messy... I have this weird boolean to indicate whether or not it's a component... That bothers me, but a bunch of other things are bothering me too... like the hard-coded "Tags" thing, etc... FOR NOW, let's just keep going.  This means, that in THIS abstraction that API is hard-coded?  Do we want that?

    //  TODO:  Normally we'd have a more complex layout system where we can order / nest the components.
    //  FOR NOW:  Let's just render them in the orer we get them from the "getComponents" call.

    //  NOTE:  Each component has a list of Block Instances.  Currently, we're keeping it simple, and the flow starts when a component is mounted.
    //         BUT, the sub-components are rendered FIRST.  In the future, we MAY add the sub-components later, when data first reaches them.  FOR NOW, we'll render them immediatly.  This means, that THEIR data flow will start immediatly!
    //         Ok... but where is the actual UI coded?  How do we know what to actually render??  We don't need to worry about it until we get to a "Primitive" which returns a JSX element?

    //  NOTE:  We can call this SAME function again for all of our sub-components.  EVENTUALLY one will return a ReactElement when it's a PRIMITIVE!  We can make that check here.  We are currently going to do a sipmle MAP.  That means we get the primitives!

    //  NOTE:  In THIS part of the code, we are ONLY concerned with the COMPONENT output.  Actually... we MAY want two separate functions in a Component?  One to render the element and another to render the output signals??  MAYBE... hmmm... or, MAYBE in a primitive, it's responsible for setting those itself??  I'm not sure about that...  Let's come back to this soon.
    //  FOR NOW:  I'll pass a "setSignal" method to the component, VERY similar to our existing "frameworkProps" and "componentContext" systems.

    //  Get the Component Instances

    //  BIG IDEA:  Instead of splitting between Components vs. Non-Components, make EVERYTHING a comonent?  That way, we can have NESTED blocks, and they'll behave in the SAME way... We know that when props change, the component is re-run.  So, we can do this with blocks like sources too.  In the middle, they will only re-run the elements under them that depend upon them.  IF we break from this pattern and try to render those in "componentDidMount" it might actually be messier... so... is it a false dichotomy?  This ALSO means that I can use React for MORE than just a VIEW library... I COULD also use this in the backend!  Why?  So I can have functions which obtain updates, and then call "setOutput", which will automatically cascade down that part of the tree.  It's like a dependency handler?? 
    //  NOTE:  BUT, that doesn't means that blocks will be involved in the layout!

    // const shouldUpdate = Object.keys(changeList).map(key => changeList[key]).find(changed => !!changed);

    //  NOTE:  We should only re-calculate the input if an input signal changed.
    //  TODO:  I THINK it may make sense to EXTRACT this for rendering a BLOCK INSTANCE!?? Hmm!?

    const { blockMap } = this.state;
    // if (!instMap) { return <Text>Loading Inst Map...</Text> }

    const res = component.blockInsts.map(blockInst => {

      //  NOTE:  Obtains a block if in the cache, otherwise, cancel render until it's available.
      //  THOUGHT:  I wonder what unique things will pop out of these models as an unintended side effect!?? HMM!??
      const block = blockMap[blockInst.blockId];
      if (!block) {
        resolveBlock(token, blockInst.blockId).then(block => {
          if (!block) { throw `Block not found: ${ blockInst.id }` }
          this.setState({ blockMap: { ...this.state.blockMap, [block.id]: block } });
        });
      return <Text>Loading Block: '{ blockInst.id }'</Text>
      }

      const blockComponent = blockMap[blockInst.blockId];
      const blockInput = resolveSignals(blockInst.input, signalMap);

      // TODO:  I'd like to pass "blockInput" as a SINGLE prop, BUT because we recreate the object each time, I believe it has a different internal ID and triggers a re-render.  REALLY digging into React's code might help with this?  Can we make our own mini-wrapper or it this OK?
      //  NOTE:  I do NOT pass "blockInput", because React will AUTOMATICALLY determine whether a component needs updating by checking PROPS...  IF I passed "blockInput", it would ALWAYS be a new object, and ALWAYS re-render ALL children whenever ANY state signal changes!  Instead, we ONLY want it to update when a component's dependnecy has changed (and on initial load... which CAN be considered a state change from non-existent, to mounted and non-existent!?)
      const element = <BlockRenderer { ...blockInput } frameworkContext={ frameworkContext } componentContext={ componentContext } key={ blockInst.id } component={ blockComponent.payload } blockInst={ blockInst } setOutput={ this.setSignals } />;
      return element;
    });

    //  Clear the Change List

    return res;
  }
}