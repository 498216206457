import { Portal, PortalHost } from '@gorhom/portal';
import { useNavigation } from '@react-navigation/native';
import { HOCRegister } from 'halia-native';
import * as React from 'react';
import { Image, Pressable, ScrollView, Text, TouchableOpacity, useWindowDimensions, View, ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import Menu from '../../../packages/kelp-bar/menu';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { defaultBorderRadius, defaultShadow, Paragraph, primaryColor } from '../constants';
import { CenteredView, useSettings } from '../content-view';
import { GalleryAppContext, useGalleryApp } from '../gallery-app';
import { useCart } from '../providers/cart.provider';
import { useMenu } from '../providers/menu.provider';
import { useSizes } from '../sizes-helper';

export const MenuTrayButton = ({ onPress, title }) => {
  return (
    <Pressable style={{ backgroundColor: 'white', alignItems: 'center', justifyContent: 'flex-start', padding: 25, flexDirection: 'row', borderRadius: defaultBorderRadius, marginVertical: 10 }} onPress={onPress}>
      <Paragraph style={{ color: "#333333" }}>{title}</Paragraph>
    </Pressable>
  )
}

//  NOTE:  IF this were defined in an HOC THEN we could manipuatle it hmm SO whyyy shouldn' it be???  THE POINT is, ANYTHING we depend on is from HOC?? HM!

//  TODO-NEXT:  I DO want to split these into PLUGINS which are supportedin the Gallery hmmm... We ALSO want to support AUTH in the gallery hmmm... and we yeah fuck hm... may have multiple instances? hmm... The GIST is.. we CAN have features which depend upon AUth hmm.. .then, when accessed we direct to the AUth thing? Hmm.. perhapssss... WOULD like a UNIVERSALLocation for these things OUTSIDE of any library hm!  a STARNDARD for the location of a thing / CONTEXT HM!  THIS way I can NAVIGATE independne BUT even THAT is a system hm!  We have our OWN universal system we use for navigation then hm! Based on the context / system etc hm!!


//  CONSIDER:  We CAN have a "component" to show the "user" which ALSO acts as a Button and... we can CONFIGURE it t fucking show LOTS of user information and it SHOULD be up to us to EASILY manipulate to include certain things hmm. the ide is VISUAL inspector and yeah!  MUCH like doing it in the "code" her hm!  BUT... even BUTTON can be configured ughh...

export const useEmail = () => {

  const { user } = useGalleryApp();
  const [email, setEmail] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (user) {
      user.getUserData((err, res) => {
        const emailAttr = res?.UserAttributes.find(attr => attr.Name === "email");
        const email = emailAttr?.Value;
        setEmail(email);
      });
    }

  }, [])

  return email;

}

const CartButton = ({ fontColor }) => {

  //  TODO:  Decouple the whole Cart system!!
  const cart = useCart();
  const navigation = useNavigation();


  return (
    <TouchableOpacity style={{ height: 40, width: 40, backgroundColor: '#f4f4f4', borderRadius: defaultBorderRadius, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate("Cart" as any)}>
      <Icon name="shopping-cart" type="entypo" size={16} style={{ fontFamily: "Poppins-SemiBold", fontSize: 11, fontWeight: "700", color: fontColor, letterSpacing: 0.5, flex: 0 }} color="#444444" />
      {
        !!Object.keys(cart.cart).length &&
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 22, height: 22, borderRadius: 11, backgroundColor: primaryColor, position: 'absolute', top: 23, left: 23 }}>
          <Text style={{ color: 'white', fontFamily: 'Poppins-SemiBold' }}>{Object.keys(cart.cart).length}</Text>
        </View>
      }
    </TouchableOpacity>
  );

}

const DashboardButton = () => {

  const navigation = useNavigation();

  return (

    <TouchableOpacity style={{ paddingHorizontal: 30, paddingVertical: 10, backgroundColor: '#f4f4f4', borderRadius: defaultBorderRadius, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }} onPress={() => navigation.navigate("Dashboard" as never)}>

      <Icon type="material" name="dashboard" size={16} color="#555555" style={{ marginRight: 5, flex: 0 }} />

      <Text style={{ color: '#555555', fontFamily: primaryFontFamilyHeavy, flex: 1 }}>Dashboard</Text>


      {/* {

        email ?
          <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 11, fontWeight: "700", color: 'black', letterSpacing: 0.5 }}>{email}</Text> :
          <ActivityIndicator />

      } */}

      {/* <Image style={{ height: 30, width: 30, borderRadius: 15 }} source={{ uri: user.get }} /> */}
    </TouchableOpacity>
  )

}

export const HeaderButton = ({ fontColor, name, onPress, style }: { style?: any, fontColor: any, name: any, onPress: any }) => {

  const navigation = useNavigation();

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Text style={{ fontSize: 15, fontFamily: primaryFontFamilyHeavy, color: fontColor, flex: 0 }}>{name}</Text>
    </TouchableOpacity>
  );

}


//  NOTE:  'transparent' will blend the header into the background with no shadow.

//  ALWAYS going to have an ontological strucutre that's being interpreted and we have OPTIONS in that space... we CAN edit that... hm!  I'd LIKE TO be able to edit the interpreter hm!!  AS LONG as we KEEP the users of that "interface" AH!! an INTERFACE is JUST a DOMAIN OF DISCOURSE.. a SYMBOL space and an ontological strucut rehm!

export const GalleryHeader = ({ flat = true, centered = false, transparent = false, mode = 'dark', position = undefined, onMenuPress: _onMenuPress, showButtons, children }: { children?: React.ReactNode, transparent?: boolean, mode?: 'light' | 'dark', position?: "absolute" | "relative" | undefined, onMenuPress?: () => void, showButtons: boolean, centered: boolean, flat: boolean }) => {

  const sizes = useSizes();
  const { open, toggleMenu } = useMenu();
  const appState = React.useContext(GalleryAppContext);
  const navigation = useNavigation();
  const { marginSize } = useSettings();

  const backgroundColor = mode === 'dark' ? primaryColor : 'white'; //  (mode === 'dark' ? secondaryColor : 'transparent');
  const primaryForegroundColor = mode === 'dark' ? '#f4f4f4' : '#666666';
  const strongForegroundColor = mode === 'dark' ? 'white' : '#444444';
  const lightForegroundColor = mode === 'dark' ? '#aaaaaa' : '#aaaaaa';
  const highlightColor = mode === 'dark' ? 'white' : primaryColor;

  const containerStyle: ViewStyle | ViewStyle[] = [{ flexShrink: 0, width: '100%', zIndex: 1000, }, flat ? {} : defaultShadow, { backgroundColor: transparent ? 'transparent' : backgroundColor, flexDirection: 'column' }];

  const Wrapper = centered ?
    ({ children }: { children: React.ReactNode }) => <CenteredView containerProps={{ style: containerStyle }}>{children}</CenteredView> :
    ({ children }: { children: React.ReactNode }) => <View style={containerStyle}>{children}</View>;

  const { user } = appState || {};

  const { isEmpty } = useCart();


  const onMenuPress = () => {
    toggleMenu();
    _onMenuPress && _onMenuPress();
  }

  //  NOTE:  The mode sets the background to light or dark.

  //  TODO:  Re-implement Dark Mode.  BUT!  THis can be an ADDON!!!  We can hav ea PLUGIN for "Dark Mode" by LINKING into the INTERNALS of this component wshm!! THat's the KEY THING HM!!  We can change the props and shit WITHOUT actually changing this code hm!!!  That's why ORDER is so frusteratin ghM!  INSTEAD of "order" hm!  We can pop things like interceptors and re-route hm!  REAQLLLYYY want to start using this!  Have a FRAMEWORK... LIKE React which has the "components" registered hM!  THEN we can link in with props and shi thm!


  //  Transparent
  //  Dark / Light

  //  Everyrthign is set by primaryForeground.

  //  BUT... when the background is DARK, then we want everything to be WHITE... BUT we diferentiate the LOGO when the background is LIGHT.  In that case, we want the logo to be PINK...  SO we created "secondardyBackground."  EVEN the BUTTON should match the primary.  So in LIGHT header mode, the logo uses secondary which is PRIMARY.  But in LIGHT header mode, the BUTTON isn't primary!  It's using SECONDARY!  ... butin DARK is the button uses secondary, then it's WHITE... and the primary is dark.. that's fine!

  //  TODO:  Do not couple the style and the components hm!  It WOULD be nice if we could see it in CONTEXT though and hm!!!  LVOE THAT TOO HM! 
  //         Basically the THEME system and THEN, the Dark Mode configuration for it should be SEPARATE!
  //         SAME goes for the TRANSPARENT system hm!  These are ALL modifiers that change the ENCODING stack hm!  Doens't need to be copuled at all hM!




  //  The BUTTON is the PRIMARY.

  //  The ICON is secondary, because it needs to be differentiated.  BUT... ah!  It's differentiated on the main page.. hmm... leike fucking

  //  Used for the Icon


  //  TODO:  Instead of DUPLICATING the menu items between header and menu, and maybe other places, INJECT in a common API like we do in Wordpress hm!


  const MenuTray = () => {

    const { toggleMenu } = useMenu();

    return (
      <View style={{ flex: 1 }}>

        <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Home" as any); }} title="🏡    Home" />

        <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Shop" as any); }} title="👕    Shop" />

        <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Gallery" as any) }} title="🖼️    Gallery" />

        <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Artists" as any) }} title="🧑‍🎨    Artists" />


        {/* <View style={{ height: 2, backgroundColor: '#eeeeee', marginVertical: 20 }} /> */}

        {/* <Pressable style={{ alignItems: 'center', backgroundColor: '#fafafa', justifyContent: 'flex-start', padding: 30, flexDirection: 'row', borderRadius: defaultBorderRadius }} onPress={() => navigation.navigate("Shop" as any)}>
          <Icon color="#aaaaaa"  name="gallery" type="entypo" />
          <View style={{ width: 15 }} />
          <Paragraph style={{ color: "#aaaaaa" }}>Gallery</Paragraph>
        </Pressable> */}

        {

          user ?
            <>

              <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Cart" as any) }} title="🛒    Cart" />

            </> :
            <>

              <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Cart" as any) }} title="🛒    Cart" />

              <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Authenticate" as never, { mode: "login" } as never) }} title="🧑    Login" />

              <MenuTrayButton onPress={() => { toggleMenu(); navigation.navigate("Authenticate" as never, { mode: "register" } as never) }} title="📝    Sign Up" />

            </>

        }

      </View>
    );
  }

  const HeaderMenu = () => {
    return (
      <>
        <HeaderButton fontColor={primaryForegroundColor} name="Shop" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Shop" as any) }} />

        <View style={{ width: 50 }} />

        <HeaderButton fontColor={primaryForegroundColor} name="Gallery" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Gallery" as any) }} />

        <View style={{ width: 50 }} />

        <HeaderButton fontColor={primaryForegroundColor} name="Artists" onPress={() => { (navigation as any).popToTop(); navigation.navigate("Artists" as any) }} />

        {/* <View style={{ width: 50 }} /> */}

        {/* <HeaderButton fontColor={primaryForegroundColor} name="Artists" onPress={() => navigation.navigate("Artists" as any)} /> */}


        <View style={{ width: 50 }} />

        {

          user ?
            <>

              <CartButton fontColor={"#eeeeee"} />

              <View style={{ width: 15 }} />

              <DashboardButton />

            </> :
            <>

              <HeaderButton fontColor={primaryForegroundColor} name="Login" onPress={() => navigation.navigate("Authenticate" as never, { mode: "login" } as never)} />

              <View style={{ width: 50 }} />

              <CartButton fontColor={"#eeeeee"} />

              <View style={{ width: 15 }} />

              <TouchableOpacity style={{ paddingHorizontal: 30, paddingVertical: 10, backgroundColor: highlightColor, borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate("Authenticate" as never, { mode: "register" } as never)}>
                <Text style={{ color: backgroundColor, fontFamily: primaryFontFamilyHeavy }}>Sign Up</Text>
              </TouchableOpacity>

            </>

        }

      </>
    );
  }

  const HeaderRight = () => {
    return <>
      {
        sizes.isTablet || sizes.isMobile ?
          <>
            <CartButton fontColor={"#eeeeee"} />
            <View style={{ width: 15 }} />
            <Icon  size={40} color='#888888' name="menu" type="entypo" onPress={onMenuPress} />
          </> :
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }}>
            <HeaderMenu />
          </View>
      }
    </>
  };

  const { isMobile } = useSizes();
  // alert(isMobile)
  const { width } = useWindowDimensions();
  const settings = useSettings();

  return (

    <>
      <View style={{ paddingHorizontal: 30, paddingVertical: 15, flexShrink: 0, flexGrow: 0, width: '100%', backgroundColor: '#f8f8f8', alignItems: 'center', justifyContent: 'center', borderBottomColor: '#dddddd', borderBottomWidth: 1 }}>
        <Paragraph style={{ textAlign: 'center', color: '#555555', fontSize: 13, fontFamily: primaryFontFamily }}>We're beta testing 🥳  You can <a href="https://www.badart.studio/auth?mode=register" target="_blank" rel="noopener noreferrer">Sign-Up</a>, join our <a href="https://www.badart.studio?section=subscribe" target="_blank" rel="noopener noreferrer">Mailing List</a>, or join our <a href="https://www.reddit.com/r/badartstudio/" target="_blank" rel="noopener noreferrer">Reddit Sub</a>.  Challenges, Characters, Remixing, and more coming soon! 🦄</Paragraph>
      </View>

      <Wrapper>
        <View style={{ paddingVertical: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: centered ? settings.marginSize : 0 }}>
          {/* Header Left */}
          <Pressable onPress={() => window.location = '/'} style={{ display: 'flex', flexShrink: 0, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center' }}>

            {/* TODO-CRITICAL:  IF you use the scribble, make sure to give CCBY4 credit! 
            REFERENCE:  https://www.figma.com/community/file/1117552508596367158 */}
            <Image source={require('../assets/bad_art_logo_new.png')} resizeMode='contain' style={{ width: 32, height: 32 }} />
            <View style={{ marginLeft: 15, flexDirection: 'row' }}>
              <Text style={{ lineHeight: 22, fontFamily: primaryFontFamilyHeavy, color: strongForegroundColor, fontSize: 18 }}>badart</Text>
              <Text style={{ lineHeight: 22, fontFamily: primaryFontFamilyHeavy, color: lightForegroundColor, fontSize: 18 }}>.studio</Text>
            </View>

          </Pressable>

          {/* Header Center */}
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }} />


          {/* Header Right */}
          {showButtons && <HeaderRight />}

        </View>
        {children}
      </Wrapper>


      <Portal name='header-menu' hostName='root-portal'>
        <Menu style={{ padding: settings.marginSize }} opacity={0.7} hideMenu={onMenuPress} width={300} backgroundColor={'white'} animationDuration={300} enabled={open}>
          <ScrollView>
            <PortalHost name="menu-root-above" />

            <HOCRegister id="menu-root-above" />
            <MenuTray />

            {/* TODO:  Show this ABOVE the other, conditionally with the Portal! */}

            {/* TODO:  Make our OWN Portal so we can pass a render prop which has portal children context to conditionally render hmm... it's JUST a COUPLING between the LOCAL closure here (encodings) AND those "in" the POrtal.  So... MORE GENERALLY, be able to COUPLE with those hm! */}
            <PortalHost name="menu-root-below" />
          </ScrollView>
        </Menu>
      </Portal>

    </>
  );
};

//  CONSIDER:  Should be able to inject Header.  But then.. do things expliclt hav ea "header" support?  Or like hmmm...  how is it consumed?  MAYBE it just IS? HM!  THEN we inject its pieces BUT it can be re-used hm!  LIKE this "Component" has OTHER things it affects, and they all work together hm!