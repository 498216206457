import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { AuthenticationWidget } from '../../../packages/kelp-bar/auth';
import { GalleryHeader } from '../components/header';
import { CenteredView } from '../content-view';
import { useSizes } from '../sizes-helper';

export const Authenticate = ({ mode }: { mode: "login" | "register" }) => {

  const { isDesktop } = useSizes();

  return (
    <View style={{ flex: 1, display: 'flex', backgroundColor: 'white' }}>
      <GalleryHeader flat={true} centered={true} transparent={true} showButtons={true} mode='light' />
      <ScrollView contentContainerStyle={{ minHeight: '100%' }} style={{ flex: 1 }}>
        <CenteredView containerProps={{ style: { flex: 1 } }} style={{ alignItems: isDesktop ? 'center' : 'stretch', justifyContent: isDesktop ? 'center' : undefined, flex: 1, paddingHorizontal: undefined, height: '100%' }}>
          <View style={{ flex: 1 }} />
          <View style={{ borderRadius: 5, width: isDesktop ? 800 : '100%', borderWidth: isDesktop ? 2 : 0, borderColor: '#eeeeee' }}>
            <AuthenticationWidget mode={mode} />
          </View>
          <View style={{ flex: 1 }} />
        </CenteredView>

      </ScrollView>

    </View>
  )
}
