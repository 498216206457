import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { MenuLayoutPlugin } from '../../elements/menu-layout-plugin/menu-layout-plugin';
import { AudioPlugin } from '../audio-plugin';
import { EntityPlugin } from '../entity-plugin';
import { HaborPlugin } from '../habor-plugin';
import { HessiaPlugin } from '../hessia-plugin';
import { Menu, MenuItem } from '../menu-plugin/menu-component';
import { RootRoute } from '../system-plugin/system-types';

export class EntityMenuPlugin extends CorePluginClass {

  public static details = {
    name: 'Entity Menu Plugin',
    description: 'Entity Menu',
    //  CONSIDER:  SHOULD we be aware of the MenuLayoutPlugin?  This is coupling with the program structure? Hmm.. maybe genralize or pack into Hessia?
    dependencies: [AudioPlugin.details.id, EntityPlugin.details.id, HessiaPlugin.details.id, MenuLayoutPlugin.details.id, EntityPlugin.details.id, HaborPlugin.details.id],
    id: 'entityMenuPlugin'
  }

  public install = async (program: Program, { audioPlugin, pEntities, hessia, menuLayout }: { audioPlugin: AudioPlugin, pEntities: EntityPlugin, hessia: HessiaPlugin, menuLayout: MenuLayoutPlugin }) => {

    menuLayout.addBelow(() => (
      <Menu hover={ false }>
        {/* THOUGHTS:  When navigating... a system MAY need intimite knowldge of an API to get to a tpoing.. hm.. we DO depend upon these systems.. so I think that's oK Hmm... the ideais, we could invoke directly from the thing? Hmm... maybe.. or we can "know" the path and the navigation library hmm.. FOR NOW, maybe that's OK hmm... */}
        {/* CONSIDER:  We CAN associate certain types of STARRED items hm!  PLUS we MAY want a thing to have MULTIPLE contexts!  NOT just the ONE that we have her ehm!  LIKE when it reacts to MULTIPLE thi ngshmm.... we can do that with mixin into the compnent itself? Hmm still though.multple??hm soethi glike that.hm */}
        <MenuItem icon={{ name: 'notes', type: 'material' }} onPress={ () => hessia.navigation.navigate("habor-apps", { screen: "SystemConfig", params: { pluginId: "ff27bdb6-cea0-52b1-9ffd-515ca23a4f30" } }) } />        
        <MenuItem icon={{ name: 'home', type: 'material' }} onPress={ () => hessia.navigation.navigate(RootRoute.Systems) } />
        <MenuItem icon={{ name: 'add', type: 'material' }} onPress={ () => pEntities.showEditor() } />
        <MenuItem icon={{ name: 'speaker', type: 'material' }} onPress={ () => audioPlugin.showAudio() } />
        <MenuItem icon={{ name: 'check', type: 'material' }} onPress={ () => hessia.navigation.navigate("habor-apps", { screen: "SystemConfig", params: { pluginId: "ff27bdb6-cea0-52b1-9ffd-515ca23a4f30" } }) } />
      </Menu>
    ))
    return this;
  }
}