import { EventEmitter } from 'events';
import * as Linking from 'expo-linking';
import { APIUser, FrameworkContext, HaborComponentContext, PageSelection, Plugin, Workspace } from 'habor-sdk';
import { History } from "history";
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { HessiaPlugin } from '.';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { AppProps } from './app-main';

//  TODO:  Why keep this separate from App.tsx?  Because it can be passed down?  But then it's acting like a Global... hmmm..  Mabe we should use the module pattern for everything?
//
//  AppUtils - A set of utilities for managing the application state.
//             Storing these here helps de-coupled the components from app implementation details.
//


//  CONSIDER:  Instead of "App", consider that there are SEVERAL contexts in which we'd be rendering a component.  So, pass a more general context object instead?  Potentially make "App" and other elements which can host a Component as "ComponentContext" sub classes, where ComponentContext is the base class of all things that can host components?  Some might not be "hosting", but more like affiliated...

/**
 * Props to provide context to React Components used within a Habor App.
 * TODO:  Consider how to remove this so the component doesn't HAVE to be within a Habor App context.  This makes the component more reusable.
 */
export interface HaborWorkspaceContextProps {
  //  TODO:  Figure out how to support multiple routers in a HaborPrimitive / HaborComponent tree.
  //         Consider reading more about "Nested Routes" in React Router to see the preferred way to navigate to these routes.
  //         We could pass an object with Route keys where values are functions to obtain the route?  This would be passed from the entity-router to the route children.
  workspace: NounServiceInstanceInternal<Workspace>;
}

/**
 * Set of Helper Functions to manage Application State. 
 * NOTE:  Internally this uses Redux.  We could use Redux directly from components, but let's centralize here for now.
 */

 //  THOUGHTS:  With Redux, each piece of state handles itself... In resonse to an incoming "Action".  In other apps, a set of functions handle the state, and they handle several sections of state.  
 //             In Redux, it can be difficult to determine which pieces of state changed when an Action was invoked.  In conventional apps, it's easy to determine which pieces of state changed given an action (assuming a pure function "Reducer"), because it's all in one place, but it's difficult to determine all the Actions that change a particular piece of state, which is easy in Redux.
 //             In BOTH cases, all we really need is logging!  I propose that for every action, we can determine which pieces of state were updated, and for each piece of state, we determine which actions can update it.  This is a more complex state model?  This means each "Action" can affect several state slices (like Redux and Conventional), and every "State Slice" can be affected by SEVERAL Actions (like Redux and Conventional).  BUT, the differentiator, is that it won't necessary be obvious in the CODE, but there will be ANOTHER system introspecting the code and the state tree which can answer those questions and re-organize the code dynamically to show what we want!
 //             In Redux, I DON'T like how it's the developer's job to use an If statement... I suppose each case has the opportunity to mutate?  I almost think it would be preferable to register these?

 //  REALIZATION:  Another BIG thing we're missing in Code is REGISTRATION... Like adding functionality to the end / beginning of Functions... We can do this with Decorators, BUT EVERYTHING is intimately tied to the written code.  In the new model, the Habor model, we can program directly with the API.

/**
 * Holds the State of the application.  Functions change the state, and when it updates, it passes the state down to the consumers as needed.
 * NOTE:  This is just a way to duplicate SOME of the benefits of Redux, without taking on that overhead... conceptual overhead, not load time / size.  This way, I'm still in complete understanding the toolset and I can use this as a learning experience.  I don't like doing things until I fully understand the tradeoffs.
 */

//  Define State Getters:  Unlike Redux, we define a set of "State Views", with defined types and give the reader that view of the state.
//  Define State Setters:  Unlike Redux, we define a set of "State Setters", which have access to the entire state tree, instead of just one slice (Reducer)...  Sure, we COULD use a Global Reducer and put everything there, but that's not scalable.  Instead, we define these "Setters", which will act JUST like Actions, except we're more explicit about the state changes they make?  Consider REGISTERING State slice listeners (Reducers) which operate for only ONE given action... OR, just let every Setter manipulate the state how they see fit?  In both cases, there are challenges.
//  ISSUES:  I don't like that components have free range over the Redux state, with no API... Ideally, 
//  GOOD POINT:  https://react-redux.js.org/using-react-redux/connect-mapstate#let-mapstatetoprops-reshape-the-data-from-the-store
//               I like the suggestion here, but they're saying to use a Function (Memoized Selector Function) to abstract some common concerns.  THIS IS basically the same as my "View" / "Getter" idea.  I like that.  But, I'm still not convinced it makes sense to write a BUNCH of reducers each of which handles a piece of state... and these selectors live where exactly?  They are just Transformers to transform the state!  I like that!!  I suppose it's pretty similar to writing a custom class for this...  SO, I'm considering using Redux... it DOES do reducer registration, which is nice.  It also does "Views" of the data which I can "Connect" to my components.  Those all sound like nice features, but is all of this absoluely necessary?  I mean, the components have knowledge of App-Level constructs... but they DON'T need full access to the store or the mechanisms which mutate the Store.  Instead, we can operate with an Interface, where Props will match a given interface, and when we want to invoke a change, we can still use the AppManger class!  This way, all of our App Behavior is still encapsulated, and the components are NOT coupled in any way to the state container.  They are only coupled to the Utility class, which can implement state logic in ANY way... Ah... but then there's ALSO the "Connect" thing... That WOULD normally couple components to Redux.  I don't like that much...  Instead, we can define our own functions in our utility class which give the components their props.  What is the benefit of this abstraction layer?  Well, it separates concerns.  The components are NOT worried about state at all, and they aren't aware of the persistence layer.  They are just aware or the Manger class, which wraps all that up nicely!
//  REALIZATION:  NORMALLY, when we make a "Registration" pattern, we have to star using Strings / Constants to identify the registered element... BUT, in Halia / Hessia / Hesha / Habor, we can actually use intellisense with that thing?  In TS, we can use an Enum, and we have to register that enum at DEVELOPMENT time, not runtime... Hmmm... Not sure about all this though.

//  FOR NOW:  I'm just going to manipulate state manually, with explicit functions!
//  IDEA:  MAYBE support NESTED state managers?  This would act simiar to a reducer?  My problem with reducers, is a situation where we need to make a decision between 2 pieces of state, and it needs to be computed TWICE.  Instead, we can make the calculation once and decide which state to change, under our management slice.
// export class StateManager<T> {
//   private state: T;
//   private listeners: (() => void)[] = [];
//   constructor(defaultState: T) {
//     this.state = defaultState;
//   }
//   public setState = (obj: object) => {
//     const newState: T = { ...this.state, ...obj };
//     this.state = newState;
//     this.notifyListeners();
//   }
//   public attachListener = (listener: () => void) => {
//     this.listeners.push(listener);
//   }
//   private notifyListeners = () => {
//     for (const listener of this.listeners) {
//       listener();
//     }
//   }
// }

//  NOTE:  I don't like the idea of a Process owning a piece of state... At THIS level, I suppose the App state tree is free range for anyone to access... BUT, we might want to have nested managers... I'm trying to differentiate from Redux though, where EACH piece of state has a registered Reducer, and the effects associated with an "Action" are NOT centralized.  I'd prefer a combination of the two... SO, we have "Processes" which are used to manipulate state, and they can manipulate ANY state in their Context.  INCLUDING sub-states!  BUT, how do we set up those nested managers?  MAYBE, we set up particular pieces of state to be managed by a Manager... Anything NOT managed by a manager is just raw, accessible state, and regardless, that state is accessible to the current manager.  BUT, a submanager can ONLY see the state at its level in the hierarhcy.

//  IDEA:  Maybe State should have permissions?  Ideally, I want a coponent to have access only to what it should have access to and NOT see implementation details... By giving it free-range to the whole state tree, we expose lots of implementation details... 
//         MAYBE, we "EXPORT" State, just like we do from a JS Module?  Then, we have an internal State interface and an External...  If you're IN the Module, then you have access to the state from there?  If you're outside, then you get the external API?
//         Even then, maybe we have State Views and State Getters / Setters?  I imagine a TREE of classes?  State components?  Where each one only has what it needs?  
//         WHAT IF:  We allow components to set "Global" state??  In other words, they can declare that they will own a State slice?  Then, when we initialize a copmonent, we can select from the StateProviders?  Hmm.. idk.
//         Really, it's just a piece of shared memory... maybe we SHOULD have each section owned by a module?  Redux has each owned by a pure function which determines the value... 
//         A class for each piece of state, a function for each piece of state (Redux), a Component for each piece of state (Maybe Vanilla React?), a set of classes all of which have access to the entire state?  Components with access to the entire state??

//  REALIZATION:  IF I have a problem with Redux, then, I MAY have a problem with Flux... Which is FB's state management architectureal pattern.  However, there are differences.  Flux has multiple stores where Redux only has one.  So, I'm not sure...  Either way, let's approach this like any other problem.  I know a lot of smart people are working on it, but you can be the "smartest" person in the world and still get caught in the dogma and forget that you HAVE made some assumptions that you should double check... Make sure the pattern works in the big picture.
//  FOR NOW:  I'm really interested in this problem, BUT there are a LOT of other problems I need to solve with this... So FOR NOW, I'm going back to putting much of the functionality in the Component tree... Either way, the components need to know to call out to my API OR Redux, SO, they're still coupled with the app-level concepts, ALTHOUGH, we COULD expose an interface, and that's partially what Redux gives us.  OR, we can expicitly make an API interface layer and inject the API into our app?  Ah!  For now, I'm just going to keep the state local in the tree I suppose...

//  NOTE:  When we pass down pieces of state / API functions, we COULD encapsulate it, OR we can pass individually... What if we expose an interface and we let the user of the interface chose what they need?  That's basically what passing the encapsulated form would do...  Hmm...

//  FOR NOW:  I'm going to keep using the AppManger utility class, BUT the state will be stored in the App component and the state will be passed down through the prop tree!  To pass deep, we'll need barier components that can pass the props... I SUPPOSE we can have managers like this at several places in the App.  In the future, consider doing the SAME THING, but, constructing the Manager outside the Component tree, and initializing it in the tree.  That way, we can still tap into it without passing props?
//            We lose time-travel with this, but we get a simple interface into our Application.  Code completion will work (although we COULD have done that exposing all Redux Actions from a single module).  The interface is just as limiting as Redux, because in the Redux app, we also coupled to the Actions.  NOW though, we are NOT coupled to Redux... Internally, we could use Redux in the future... Let's just keep working on this and keep crawling in the direction I believe to be the most reasonable.

//  QUESTION:  Why is this method preferable to Redux?  Becuase, we aren't coupling to Redux, and we don't need to "dispatch actions".  Instead, we just call regular callbacks passed to our components?  It IS nice if components are somewhat reusable, but yeah... Still not sure what to do about this.  Even with Redux, as long as the Action interface stays the same, then the components are reusable.  In EITHER case, we're injecting props into the Presentational comonents, and we're attaching to the store from our connected compnents, which can wrap the presentational... I'm still not sure how to set up a pattern I feel is optimal, but FOR NOW, I'll just keep moving forward with this.. I DO like how there are ConnectedComponents in the Redux pattern which shield the Presentational.  In my case, I DO NOT want to pass the AppManager down to all components... I think I'll keep going with what I have, but I MAY want to switch to Redux at some point to get time travel (Reactor Pattern), the ability to create DataViews and the SOC we get between state persistance and business logic?  I'm not sure... BUT, I am concerned about Responsibility in Redux!

//  QUESTION:  What's the benefit of defining these things here instead of directly in the App component?

/**
 * App State Type Definition
 */
export interface AppState {
  componentContext: HaborComponentContext;  //  TODO:  Ah!  IF we use this, we lose the Habor Component context!!!  BUT, it makes it easy to get up and running!?  Hmm... But, my custom CSS-like system SHOULD break!
  frameworkContext: FrameworkContext;  //  CONSIDER:  Should this be here?  Perhaps we have several contexts dependent upon which part of the app we're in ?  MAYBE tied to a Habor Space??
  nouns: any[];
  selectedNoun: number;
  showMenu: boolean;
  user?: NounServiceInstanceInternal<APIUser>;
  token?: string;
  workspace?: NounServiceInstanceInternal<Workspace>;
  pageSelection?: PageSelection;
  logout: any;
  appManager?: AppManager;
  setPlugin: (plugin: NounServiceInstanceInternal<Plugin>, afterUpdate?: () => void) => void;
  loggingIn: boolean;
  initialUrl?: string;
  hessiaPlugin?: HessiaPlugin;
}

//  TODO:  Clean this up!!!  Yes, the User App side WILL likely have additinal complexities, but lets go at it one piece at a time??  The user SHOULD be able to build models, and routes, etc... basiclaly anything they want!
const defaultAppEmitter = new EventEmitter();
const defaultComponentContext: HaborComponentContext = { name: 'AppPrimitive' };
//  TODO:  While we still have these alternative contexts, remove 'as any'!  BUT, this should probaly be passed in some app context?? We want to keep things isolated and composable / reusable!
const defaultFrameworkContext: FrameworkContext = { user: undefined as any, token: undefined as any, appEmitter: defaultAppEmitter, workspace: undefined as any, changePage: () => {} };
//  CONSIDER:  We can produce defaultState with multiple systems using ECS.. it's just an encoding.
//  TODO:  Define this with a function or.. find a way to get these pieces at the time we define the context hmm...  SHOLD be doable if we produce the context outside of global scope and "inside" the DI system.
export const defaultState: AppState = { initialUrl: undefined, loggingIn: false, componentContext: defaultComponentContext, frameworkContext: defaultFrameworkContext, nouns: [], selectedNoun: -1, token: undefined, user: undefined, showMenu: false, logout: undefined, appManager: undefined, setPlugin: () => null };

export class AppManager {

  constructor(private setState: (state: any, callback?: any) => void, private history: History, private app: React.Component<AppProps, AppState>) {

  }

  public reloadApp = () => {
    //  NOTE:  NOEW force update mechnsiam!  
    //  TODO:  CHANGE!
    this.setState({ test: new Date() });
    // this.app.forceUpdate();
  }

  /**
   * Reload the State from AsyncStorage
   */
  public hydrate = async () => {

    //  TODO:  Don't do this here!
    const { authPlugin } = this.app.props.hessiaPlugin;

    if (!authPlugin) { alert("No Auth Plugin, could not hydrate!"); return; }

    const user = authPlugin.user;
    const token = authPlugin.token;

    const initialUrl = await Linking.getInitialURL();
    // alert(initialUrl);

    this.setState({ hessiaPlugin: this.app.props.hessiaPlugin, appManager: this, setPlugin: this.setPlugin, user, token, frameworkContext: { ...defaultFrameworkContext, user, token }, initialUrl });

  }

  public setPlugin = (plugin: NounServiceInstanceInternal<Plugin>, afterUpdate: () => void) => {
    if (this.app.state.frameworkContext.plugin != plugin) {
      this.setState({ frameworkContext: { ...this.app.state.frameworkContext, plugin } }, afterUpdate);
    }
  }
}

export const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 })
  return isMobile ? children : null
}
export const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 499 })
  return isMobile ? children : null
}
export const Default = ({ children }: { children: any }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

export interface SizesProp extends React.Props<any> {
  sizes: {
    isDesktop: number,
    isTablet: number,
    isMobile: number
  }
}

export function withSizes (Component: any){
  return (props: any) => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 767 });
    const isMobile = useMediaQuery({ maxWidth: 499 });
    const sizes = {
      isDesktop,
      isTablet,
      isMobile
    }
    return <Component { ...props } sizes={ sizes } />;
  }
}