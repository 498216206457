import { useNavigation } from "@react-navigation/native";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import Cookies from 'js-cookie';
import * as React from "react";
import {
  ActivityIndicator, FlatList, Image, Pressable, ScrollView, Text,
  TextInput,
  View,
  ViewStyle
} from "react-native";
import { Icon } from "react-native-elements";
import { MD3Colors as Colors, Switch } from "react-native-paper";
import { FlatGrid } from "react-native-super-grid";
import { capitalizeAllWords } from "../../../packages/davel-ui/davel-utils";
import { Picker } from "../../../packages/kelp-bar/picker";
import { primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { api } from "../bas-sdk";
import { GalleryButton } from "../components/common";
import { FieldContainer, ImageField, TextField } from "../components/fields";
import { GroupCard } from "../components/group-card";
import { GalleryHeader } from "../components/header";
import { Header, Paragraph, SubHeader, SuperHeader, defaultBorderRadius, primaryColor } from "../constants";
import { CenteredView, useSettings } from "../content-view";
import { Comment, Image as GalleryImage, Like, Work } from "../openapi";
import { Mockup } from "../openapi/models/Mockup";
import { WorkProduct } from "../openapi/models/WorkProduct";
import { useSizes } from "../sizes-helper";
import { Breadcrumbs, deleteProduct, extractImageIdFromUrl, fetchImageFromUri, getGalleryApi, getMinPrice, getPreviewUrl, getProfile, getSyncProductResult, getTask, getWork, isAdmin, saveWorkProduct, usdFormatter, useCognitoToken, useCognitoUser, useDisplayName, useGender, useProfile, useWorkProfile } from "../utilities";
import { WorkProductImporter } from "./product-editor";

//  TODO:  Support cuatom components in picker
//  CONSIDER:  We COULD also use a system to "extract" the group... Perhaps Lodash has this.  This is a common pattern.  This is good enoguh for now.
//  TODO:  Add spacer component
const GroupedPickers = ({ items, groupIds, select, selected }) => {

  //  Define Groups
  const groups = {};
  items.forEach(item => {
    groupIds.forEach(groupId => {
      const itemGroupValue = item[groupId];
      if (!itemGroupValue) { return; }
      if (!groups[groupId]) { groups[groupId] = [] }
      if (!groups[groupId].includes(itemGroupValue)) {
        groups[groupId].push(itemGroupValue);
      }
    })
  })

  const updateSelection = (updatingGroupId, updatedValue) => {
    const _selected = items.find(item => {
      for (let i = 0; i < groupIds.length; i++) {
        const _groupId = groupIds[i];
        const expectedValue = _groupId === updatingGroupId ? updatedValue : selected[_groupId];
        const currentValue = item[_groupId];
        if (currentValue !== expectedValue) { return false; }
      }
      return true;
    });
    select(_selected);
  }

  return (
    <>
      {
        // NOTE:  I tried doing this with FlatList, but each element is wrapped in the same view, so I can't set the zIndex.  If we had something like CSS, then we could target the parent.  That would be nice.
        groupIds.map((groupId, index) => !!groups[groupId] ? <Picker style={{ zIndex: 100 - index, marginBottom: 7 }} items={groups[groupId].map(value => ({ value, label: value }))} value={selected[groupId]} onChange={value => updateSelection(groupId, value)} /> : null)
      }
    </>
  );
}

//  Get this fromt the SDK
interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  workId: string;
  printfulId: string;
}


//  CONSIDER:  SHOULD be able to EASILY display this based on ... yes.. we CAN have a custom type for it, BUT we also have the fields.  The POINT is.. it's like in Pipes when I had those fields I wanted to use COMBINED with TrackMine where I hada a CCUSTOM renderer, adn where we can interper differently based on consumption context etc hm!.. MAYBE write a BOOK about SEMIOTICS and programming in THAT light... hmm... LOTS of things got me thinking down that Vein... TONS of things.  Plus, I was ALREADY considerng doing away with classes like JS and Ah!  The JS thing had a LOT to do wtih it and it's ANY encoding!  NO reason to romanticize hm!  JSON is limiting !!  hm!  

//  TODO:  Render this AUTOMATICALLY based on the ontological strucutre and some SIMPLER fucking intermediate strucutres hm!

//  NOTE:  We ALREADY had this in the fucking OBJECT system in Pipes... PLUS they were already doing this in IFT and ugh!

export const JSONRenderer = ({ json }: { json: any }) => {
  const keys = Object.keys(json);
  const elements = keys.map(key => {
    return (
      <>
        <Text>{key}</Text>
        <Text>{JSON.stringify(json[key])}</Text>
      </>
    );

  })
  return (
    <>
      {elements}
    </>
  )
}

//  TODO:  This should generate a MOCKUP object.  Then, we also have a STORE_PRODUCT hm!

//  CONSIDER:  Decouple the mockup? Hmm... Inject?  INCLUDING perhaps injecting into the system that generates the "product"? Hmm... interesting.



export const ProductItem = ({ selectedPrintfile, product: { product, variants }, work, mockup: initialMockup, backgroundColor }: { backgroundColor?: string, selectedPrintfile?: GalleryImage, product: any, work: Work, mockup?: any }) => {

  const [mockup, setMockup] = React.useState(initialMockup);

  const task = getTask(mockup);

  const [variant, setVariant] = React.useState(variants[0]);

  const token = useCognitoToken();

  const [generating, setGenerating] = React.useState(false);
  const [activating, setActivating] = React.useState(false);

  //  TODO:  Don't separate the internal mockup and external... MAYBE have a way to have a shared thing so we can always pull and update it within that context? Hm!  MAYBE... should this thing have access? HM!  COOL!  Brain is all over the place.. hm!

  const generateMockups = async (product: Product, work: Work, token?: CognitoAccessToken | null) => {

    setGenerating(true);
    if (!token) { return null; }
    const api = getGalleryApi(token?.getJwtToken());
    const _mockup = await api.mockupsControllerCreate({
      body: {
        productId: product.id,
        workId: work.id,
        variant,
        printfileId: selectedPrintfile?.id,
        backgroundColor: backgroundColor
      }
    });
    setGenerating(false);
    setMockup(_mockup);
  }

  const activateProduct = async (product: Product, work: Work, token?: CognitoAccessToken | null) => {

    setActivating(true);

    if (!token) { return null; }
    const api = getGalleryApi(token?.getJwtToken());
    const _workProduct = await api.workProductsControllerCreate({
      body: {
        productId: product.id,
        workId: work.id,
        variant,
        printfileId: selectedPrintfile?.id,
        backgroundColor
      }
    });
    setActivating(false);
  }

  const refreshMockup = async (token: CognitoAccessToken) => {
    const api = getGalleryApi(token?.getJwtToken());
    const _mockup = await api.mockupsControllerFindOne({ workId: work.id, productId: product.id });
    setMockup(_mockup);
  }

  React.useEffect(() => {
    if (!token) { return; }
    const task = !!mockup && getTask(mockup);
    if (task?.status === "pending") {
      setTimeout(refreshMockup, 5000);
    }
  }, [mockup, token])

  //  The INCOMING mockup take precedent.
  React.useEffect(() => {
    setMockup(initialMockup);
  }, [initialMockup])

  const mockups: any[] = [];
  if (task?.status === "completed") {
    console.log(JSON.stringify(task.mockups))
    mockups.push(...task.mockups);
  }

  const loading = generating || task?.status === "pending";


  return (
    <GroupCard style={{ padding: 0, minHeight: 450, flexDirection: 'column', flex: 1 }}>

      <Image source={product.image} style={{ height: 200, width: '100%' }} />

      {/* TODO:  CAN ascertain STYLE info from the CONTEXT of placement hm!  REALLY want to do that ugh! */}

      <View style={{ padding: 30, flex: 1 }}>
        {/* <View style={{ height: 10 }} /> */}
        {/* <Header style={{ fontSize: 20, color: '#555555' }}>{product.model}</Header>
        <View style={{ height: 10 }} /> */}

        <Text>{product.id}</Text>

        <View style={{ height: 7 }} />
        {/* Category */}
        {/* <View>
          <Text>{product.main_category_id}</Text>
        </View> */}

        {/* Type */}
        {/* <Text>{product.type}</Text> */}
        {/* <Text>{product.type_name}</Text> */}

        {/* Title */}
        <SubHeader style={{ fontSize: 17, color: '#666666', width: '100%' }}>{product.title}</SubHeader>
        <View style={{ height: 7 }} />

        {/* Brand */}
        {/* <SubHeader style={{ fontSize: 17, color: '#666666', width: '100%' }}>{product.brand}</SubHeader> */}

        {/* Model */}
        {/* <SubHeader style={{ fontSize: 17, color: '#666666', width: '100%' }}>{product.model}</SubHeader> */}

        {/* Description */}
        {/* <Text numberOfLines={10} style={{ fontSize: 14, color: '#aaaaaa', width: '100%' }}>{product.description}</Text> */}

        {/* TODO:  INSTEAD of doing this explicily, encode Type info and relationships etc in the thing so we can auto-generate a UI.  Then, we can consider multiple layouts / algos for different purpsoes? Hmm... NOT the same as JUST mapping types ugh! */}

        {/* Options */}
        {/* REFERENCE:  https://developers.printful.com/docs/#section/Options */}

        {/* <Picker
          onChange={variant => setVariant(variant)}
          items={variants.map(variant => ({ value: variant, label: variant.name }))}
          value={variant}
        /> */}


        <Text>{usdFormatter.format(variant.price)}</Text>
        <View style={{ height: 7 }} />

        <GroupedPickers items={variants} groupIds={["size", "color"]} select={item => setVariant(item)} selected={variant} />
        <View style={{ height: 7 }} />

        <View>
          {
            product?.options?.map(option => {

              if ((option.id as string).includes("thread")) { return null; }

              const MultiSelectOption = ({ option }: { option: any }) => {
                return (
                  <>
                    <FlatGrid
                      itemDimension={30}
                      spacing={5}
                      data={Object.keys(option?.values || {})}
                      renderItem={
                        ({ item: key }) => <Pressable style={{ backgroundColor: key, width: 30, height: 30, borderRadius: 15, borderWidth: 2, borderColor: '#eeeeee' }} />
                      }
                    />
                  </>

                );
              };

              const TextOption = ({ option }: { option: any }) => {
                return (
                  <>
                    <TextInput value={option?.values} />
                  </>
                );
              };

              const RadioOption = ({ option, selectOption }: { option: any, selectOption: (key: string) => void }) => {
                return (
                  <View>
                    {
                      Object.keys(option?.values || {}).map(key => {
                        const value = option?.values[key];
                        return <Pressable style={{ backgroundColor: '#f4f4f4', padding: 15, borderRadius: defaultBorderRadius }} onPress={() => selectOption(key)}>
                          <Text>{value}</Text>
                        </Pressable>
                      })
                    }
                  </View>
                )
              };

              return (
                <View style={{ zIndex: 1000 }}>
                  {/* <SubHeader style={{ fontSize: 17, color: '#666666', width: '100%' }}>{option.id}</SubHeader> */}
                  <Paragraph style={{ fontSize: 14, color: '#666666', width: '100%' }}>{option.title}</Paragraph>
                  <SubHeader style={{ fontSize: 17, color: '#666666', width: '100%' }}>{option.type}</SubHeader>

                  {
                    option.type === "multi_select" && <MultiSelectOption option={option} />
                  }

                  {
                    option.type === "radio" && <RadioOption option={option} selectOption={(key) => alert(key)} />
                  }

                  {
                    option.type === "text" && <TextOption option={option} />
                  }
                  {/* {
                    Object.keys(option?.values || {}).map(key => {



                      return (
                        <View>
                          <Paragraph style={{ fontSize: 17, color: '#666666', width: '100%' }}>{key}</Paragraph>
                          <Paragraph style={{ fontSize: 17, color: '#666666', width: '100%' }}>{option?.values[key]}</Paragraph>
                        </View>
                      )
                    })
                  } */}
                </View>
              )
            })
          }
        </View>
        <View style={{ height: 7 }} />

        {/* <View style={{ height: 10 }} /> */}
        {/* TODO:  Make a component for a TEXT box that doesn't overflow and shows "Read More" hm! */}
        {/* <View style={{ height: 200, overflow: 'hidden' }}> */}

        {/* </View> */}

        {/* TODO:  MAke a section for "Pending".  If they look good, approve them for the store.  If not, our design team will help you out. */}

        <View style={{ flex: 1 }} />

        {
          task && task.status === "pending" && (
            <View style={{ height: 50, borderRadius: defaultBorderRadius, alignItems: 'center', justifyContent: 'center', padding: 15, flexDirection: 'row', borderWidth: 2, borderColor: primaryColor }}>
              <ActivityIndicator color={primaryColor} />
              <View style={{ width: 5 }} />
              <Paragraph style={{ color: primaryColor }}>Rendering</Paragraph>
            </View>
          )
        }

        {
          task && task.status === "failed" && (
            <View style={{ height: 50, marginBottom: 5, borderRadius: defaultBorderRadius, alignItems: 'center', justifyContent: 'center', padding: 15, flexDirection: 'row', borderWidth: 2, borderColor: primaryColor }}>
              <Icon name="error" type="material" color={primaryColor} />
              <View style={{ width: 5 }} />
              <Paragraph style={{ color: primaryColor }}>Render Failed</Paragraph>
            </View>
          )
        }


        <View style={{ width: '100%' }}>
          {
            mockups.map(mockup => {
              const mockupUrl = mockup.mockup_url;
              return <>
                <Image source={{ uri: mockupUrl }} style={{ height: 200, width: 200 }} />
                {
                  mockup.extra?.map(extra => <Image source={{ uri: extra.url }} style={{ height: 200, width: 200 }} />)
                }
              </>
            })
          }
        </View>
        {/* {mockupUrl && <Image source={mockupUrl} style={{ height: 200, width: '100%' }} />} */}

        {/* {
          (!task || task.status === "failed") && (
            
          )
        } */}

        <Pressable disabled={loading} onPress={() => generateMockups(product, work, token)} style={{ height: 50, borderRadius: defaultBorderRadius, backgroundColor: loading ? '#eeeeee' : primaryColor, alignItems: 'center', justifyContent: 'center', padding: 15 }}>
          {
            loading ? (
              <ActivityIndicator color={primaryColor} />
            ) : (
              <Paragraph style={{ color: 'white' }}>{task?.status === "failed" ? "Try Again" : "Try Product"}</Paragraph>
            )
          }

        </Pressable>

        <Pressable disabled={activating} onPress={() => activateProduct(product, work, token)} style={{ height: 50, borderRadius: defaultBorderRadius, backgroundColor: activating ? '#eeeeee' : primaryColor, alignItems: 'center', justifyContent: 'center', padding: 15 }}>
          {
            activating ? (
              <ActivityIndicator color={primaryColor} />
            ) : (
              <Paragraph style={{ color: 'white' }}>Activate Product</Paragraph>
            )
          }

        </Pressable>

        {/* <Pressable onPress={() => loadMockup()} style={{ borderRadius: defaultBorderRadius, backgroundColor: primaryColor, alignItems: 'center', justifyContent: 'center', padding: 15 }}>
          <Paragraph style={{ color: 'white' }}>Load Mockup</Paragraph>
        </Pressable> */}

      </View>



      {/* <JSONRenderer json={product} /> */}
    </GroupCard>
  );
}

export const WorkProductItem = ({ workProduct: initialWorkProduct }: { mockup?: any, workProduct: WorkProduct }) => {

  const [gender, setGender] = useGender();

  const [workProduct, setWorkProduct] = React.useState(initialWorkProduct);

  console.log("Rendering work product" + JSON.stringify(workProduct));

  const token = useCognitoToken();

  const navigation = useNavigation();

  const [hover, setHover] = React.useState(false);
  const syncResult = getSyncProductResult(workProduct);

  if (!syncResult) { return null; }

  const { sync_product, sync_variants } = syncResult;

  const user = useCognitoUser();

  const [work] = getWork(workProduct.workId);

  //  TODO:  Get the owner directly from the WorkProduct.
  const isOwner = work?.owner === user?.getUsername();

  const minPrice = getMinPrice(syncResult);
  console.log(sync_product.name + " - Min Price: " + minPrice)
  const multipleVariants = sync_variants.length > 1;

  const shadowStyle = {
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 5,
      height: 5
    },
  };

  const downShadowStyle = {
    shadowColor: 'black',
    shadowRadius: 2,
    shadowOpacity: 0.04,
    shadowOffset: {
      width: 3,
      height: 3
    },
  };

  const toggleApproval = async () => {
    const updatedWorkProduct = await saveWorkProduct({ ...workProduct, ownerApproved: !(workProduct as any).ownerApproved } as any, token);
    setWorkProduct(updatedWorkProduct);
    //  TODO:  Have a GLOBAL way to update workProduct.  For example, Redux or... it's just an ontological strucutre.  We CAN tie it to a type.. hmm.. ugh.

  }

  const previewUrl = getPreviewUrl(sync_variants, workProduct);

  // const previewFile = sync_variants[0].files.find(file => file.type === "preview");

  const admin = isAdmin();

  const _deleteProduct = async () => {
    await deleteProduct(workProduct);
    window.location.reload(false);
  }


  //  Construct a new URL to get the "Optimized" image
  const imageRequest = JSON.stringify({
    bucket: "bad-art-studio",
    key: extractImageIdFromUrl(previewUrl),
    edits: {
      resize: {
        height: 600,
        width: 600,
        fit: 'contain',
        background: { r: 255, g: 255, b: 255 }
      }

    }
  });

  console.log(imageRequest);

  const url = `https://daj8no6oioxxi.cloudfront.net/${btoa(imageRequest)}`;

  console.log("THIS IS THE URL: " + url);
  console.log("THIS IS THE URL: " + url);

  const { isMobile } = useSizes();

  return (
    <View style={[{ borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 0, flexDirection: 'column', width: '100%', height: admin ? 580 : 375, alignItems: 'center' }, hover ? shadowStyle : undefined]}>

      <Pressable style={{ width: 250 }} onHoverIn={() => setHover(true)} onHoverOut={() => setHover(false)} onPress={() => { navigation.navigate("ProductDetail" as never, { workProductId: workProduct.syncProductId } as never) }}>

        <View style={{ height: 30 }} />

        {/* TODO:  Switch to contain once we have transparent PNGs mockups */}
        {/* <LazyLoad height={300}> */}
        <Image source={{ uri: url }} style={{ height: 250, width: 250, backgroundColor: '#f4f4f4', borderRadius: defaultBorderRadius }} resizeMode='contain' />
        {/* </LazyLoad> */}

        <View style={{ margin: 15, flex: 1, backgroundColor: '#fdfdfd' }}>

          {/* <TextInput multiline={true} numberOfLines={10} style={{ fontSize: 14, color: '#777777', width: '100%' }} value={product.id} scrollEnabled={false} /> */}

          <Paragraph style={{ color: '#333333', fontSize: 15, lineHeight: 18, letterSpacing: 0 }}>{sync_product.name}</Paragraph>

          <View style={{ height: 10 }} />

          {/* <Paragraph style={{ color: '#777777', fontSize: 16, lineHeight: 16 }}>{sync_variants[0].product.name}</Paragraph> */}

          {/* <View style={{ height: 20 }} /> */}

          {
            multipleVariants ?
              <Paragraph style={{ color: '#777777', fontSize: 14, lineHeight: 14 }}>{`Starting at ${usdFormatter.format(minPrice)}`}</Paragraph> :
              <Paragraph style={{ color: '#777777', fontSize: 14, lineHeight: 14 }}>{usdFormatter.format(minPrice)}</Paragraph>
          }


          {/* TODO:  Hide in dashboard.. ALSO... want "Owner" field to be determiend by the associated product.  GraphQL can help with this.  The MAIN thing with GraphQL is that it lets us define our OWN ontological in the backend and it's up to IT to determine.  Decoupled from the DB HM! */}
          {/* <View style={{ height: 20 }} /> */}

          {/* TODO:  Support disable by owner too! */}
        </View>



      </Pressable>

      {
        (admin) && (
          <>
            <View style={{ height: 10 }} />
            <Paragraph style={{ color: '#444444', fontSize: 16, lineHeight: 16 }}>Admin Tools</Paragraph>
            <View style={{ height: 10 }} />
            <Paragraph style={{ color: '#777777', fontSize: 14, lineHeight: 14 }}>Enable Product</Paragraph>
            <View style={{ height: 7 }} />
            <Switch value={(workProduct as any).ownerApproved} onValueChange={toggleApproval} />
            <View style={{ height: 10 }} />
            <GalleryButton title="Delete" onPress={_deleteProduct} />
          </>
        )
      }

    </View>
  );
}



export const PrintfulProducts = ({ work }: { work: Work }) => {

  const [selectedImage, setSelectedImage] = React.useState<GalleryImage | undefined>(undefined);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [mockups, setMockups] = React.useState<Mockup[]>([]);
  const [categories, setCategories] = React.useState<Product[]>([]);

  const getCategories = async () => {
    const _categories = await api.printfulCategoriesControllerFindAll() as any[];
    setCategories(_categories);
  }

  const getProducts = async () => {
    const _products = await api.productsControllerFindAll();
    setProducts(_products as any);
  }

  const getMockups = async () => {
    const _mockups = await api.mockupsControllerFindAll({});
    setMockups(_mockups as any);
  }

  const [images, setImages] = React.useState<GalleryImage[]>([]);

  const token = useCognitoToken();


  const getPrintfiles = async () => {
    const api = getGalleryApi(token?.getJwtToken());
    const _images = await api.imagesControllerFindAll();
    setImages(_images);
  }

  //  TODO:  I COULD be doing this with GraphQL... maybe now I see the value... because there ARE associations between mockups and products and I want that HERE.  But... they are currently separate APIs.  SO... I can build it with a COUPLING system that let's me grab it by letting me pass "withMockup" and things like that, but the idea is... just psas the name and it'll be resolved hmmm.... it's basically just a way to do it by name in a way that's readable and hmm... compressed and hmm.. CONSISTENT hm!  Don't want to duplciate code for each endpoint hm!
  React.useEffect(() => {
    getProducts();
    getMockups();
    getCategories();
  }, [])

  React.useEffect(() => {
    if (token) {
      getPrintfiles();
    }
  }, [token])

  //  NOTE:  The idea is.. in every context we can have a "printFile" hmm... we can inherit form the parent context.. I'd MUCH rather not have to do this expliclty.. the idea being, I can just have something like ontology where I have a name.. like one, two, etc and inherit in the various hmm.. interesitng.  

  const savePrintFile = async (imageUrl: string) => {
    if (!token) { return; }
    const api = getGalleryApi(token.getJwtToken());
    const blob = await fetchImageFromUri(imageUrl);
    const res = await api.imagesControllerCreate({
      file: blob,
      image: JSON.stringify({ name: "Test Image" })
    });
  }

  const [backgroundColor, setBackgroundColor] = React.useState<string | undefined>(undefined);

  return (
    <View>
      <View style={{ height: 2, width: '100%', backgroundColor: '#eeeeee', marginVertical: 30 }} />
      <Header style={{ color: '#888888', fontSize: 20 }}>Product Editor</Header>

      <Paragraph style={{ marginVertical: 30 }}>Select products for activation.</Paragraph>

      <FlatGrid
        itemDimension={ 120 }
        pagingEnabled={ true }
        data={ images }
        renderItem={({ item: image }) => <Pressable style={{ borderBottomColor: 'red', borderBottomWidth: image === selectedImage ? 2 : undefined }} onPress={() => setSelectedImage(image)}><Image source={{ uri: image.imageUrl }} style={{ height: 100, width: 100 }} /></Pressable>}
      />

      {/* <GalleryButton title="Upload Printfile" onPress={uploadPrintFile} />

      <GalleryButton title="Select Printfile" onPress={uploadPrintFile} /> */}
      {/* CONSIDER:  The upload process and the display process should be separate? Hmm... intereint.  MAYBE make a simple button idk ugh. */}
      <ImageField imageUrl={undefined} onSave={savePrintFile} title="Printfile" description="Alternative printfile" />

      {/* // TODO:  Support proper pagination! */}

      <GroupCard style={{ width: '100%' }}>
        <Paragraph>Background Color</Paragraph>
        <FlatGrid
          itemDimension={50}
          data={Object.keys(Colors).map(key => Colors[key])}
          renderItem={({ item: color }) => <Pressable onPress={() => setBackgroundColor(color)} style={{ backgroundColor: color, height: 20, width: 20, borderRadius: 10, borderColor: color === backgroundColor ? '#555555' : '#eeeeee', borderWidth: 2 }} />}
        />
        <TextField mode="manual" title="Hex Color" onSave={value => setBackgroundColor(value)} />
        <View style={{ height: 40, width: 40, backgroundColor: backgroundColor, borderRadius: defaultBorderRadius }} />
      </GroupCard>

      <FlatGrid
        data={products.slice(0, 10)}
        itemDimension={250}
        spacing={15}
        style={{ marginHorizontal: -30 }}
        renderItem={({ item: product }) => {

          const mockup = mockups.find(mockup => (mockup.workId.toString() === work.id.toString()) && (mockup.productId.toString() === product.product.id.toString()));
          return (
            <>
              <ProductItem backgroundColor={backgroundColor} selectedPrintfile={selectedImage} mockup={mockup} work={work} product={product} />
            </>
          )
        }}

      />
    </View>
  );
}



export const WorkProducts = ({ work }: { work: Work }) => {

  const [workProducts, setWorkProducts] = React.useState<WorkProduct[]>([]);
  const [loading, setLoading] = React.useState(true);

  const getWorkProducts = async () => {
    //  TODO:  Scope to the Work instead of filtering client-side.
    const _workProducts = await api.workProductsControllerFindAll();
    const filteredWorkProducts = _workProducts.filter(workProduct => workProduct.workId === work.id);
    setWorkProducts(filteredWorkProducts);
    setLoading(false);
  }

  React.useEffect(() => {
    getWorkProducts();
  }, [])


  if (loading) { return <ActivityIndicator color={primaryColor} /> }

  if (!work.listed) {
    return (
      <View>
        <View style={{ height: 350, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <SubHeader>Not Listed</SubHeader>
          <View style={{ height: 15 }} />
          <Paragraph style={{ maxWidth: 400, textAlign: 'center' }}>This work is not for sale.</Paragraph>
          <View style={{ height: 30 }} />
          <View style={{ height: 70, width: 70, borderRadius: 35, borderColor: '#555555', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }}>
            <Icon type="ionicon" name="shirt-sharp" size={30} color="#555555" />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View>
      {
        !workProducts.length && (
          <View style={{ height: 350, width: '100%', alignItems: 'center', justifyContent: 'center' }}>

            {/* TODO:  Do NOT show this to people who have not listed. */}
            <SubHeader>No Products Yet</SubHeader>
            <View style={{ height: 15 }} />
            <Paragraph style={{ maxWidth: 400, textAlign: 'center' }}>Initial products should be generated soon.  Please check back later!</Paragraph>
            <View style={{ height: 30 }} />
            <View style={{ height: 70, width: 70, borderRadius: 35, borderColor: '#555555', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }}>
              <Icon type="ionicon" name="shirt-sharp" size={30} color="#555555" />
            </View>
          </View>
        )
      }

      <FlatGrid
        data={workProducts.slice(0, 10)}
        itemDimension={300}
        spacing={15}
        style={{ marginHorizontal: -15 }}
        renderItem={({ item: workProduct }) => <WorkProductItem workProduct={workProduct} />}
      />
    </View>
  );
}

//  THOUGHT:  Instead of totally separate comopnent WHY can't I just pull arbitrary thigns I need?  UGH... I do like react, but like... REMEMBER.. there are ALWAYS ways to improve and "smart" people... are just about as smart as you and fuck.  Also, React seems to do the dependnecy st runtime, which is similar to Halia hmm...

// export const ProfileWidget = ({ profile, style }: { profile?: Profile, style?: ViewStyle }) => {
//   return (
//     <View style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }, style]}>

//     </View>
//   );
// }


export const MatureEditor = ({ work, setWork }: { work: Work, setWork: (work: Work) => void }) => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());
  const [loading, setLoading] = React.useState(false);

  const toggle = async () => {
    setLoading(true);
    const newWork = await api.worksControllerUpdate({ id: work.id, body: { ...work, mature: !(work as any).mature } });
    setWork(newWork as any);
    setLoading(false);
  }

  //  TODO:  SHOULD be able to hide the "Loading" behavior of ALL components and like... REMOVE that complexity mm!!  EVEN though it exists and mm!!!

  return (
    <GroupCard>
      <FieldContainer title="Mature Content">
        {
          loading ? <ActivityIndicator color={primaryColor} /> : <Switch value={(work as any).mature} onValueChange={toggle} />
        }
      </FieldContainer>
    </GroupCard>
  );
}

export const LikesWidget = ({ style, targetId, targetType }: { style?: ViewStyle, targetId: string, targetType: string }) => {

  const token = useCognitoToken();
  const user = useCognitoUser();
  const api = getGalleryApi(token?.getJwtToken());
  const [likes, setLikes] = React.useState<Like[]>([]);
  const [myLike, setMyLike] = React.useState<Like | undefined>();

  const loadLikes = async () => {
    const likes = await api.likesControllerFindAll();
    const filteredLikes = likes.filter(like => (like.targetId === targetId) && (like.targetType === targetType));
    setLikes(filteredLikes);
  }

  //  TODO:  Stop VSCode from making braces and stuff!!!
  React.useEffect(() => {
    const username = user?.getUsername() || 'session_' + Cookies.get('id');
    const myLike = likes.find(like => like.owner === username)
    setMyLike(myLike);
  }, [user, likes]);

  React.useEffect(() => {
    loadLikes();
  }, [])

  const upLike = myLike && myLike.reaction === 'up';
  const downLike = myLike && myLike.reaction === 'down';

  const likePressed = async () => {
    if (upLike) { return; }  //  CONSIDER:  Super likes and LOTS of other differentiators!
    await api.likesControllerSave({ body: { targetType, targetId, reaction: downLike ? '' : 'up' } });
    loadLikes()
  }

  const dislikePressed = async () => {
    if (downLike) { return; }
    await api.likesControllerSave({ body: { targetType, targetId, reaction: upLike ? '' : 'down' } });
    loadLikes();
  }



  const selectedColor = '#333333';
  const unselectedColor = '#aaaaaa';
  const likeCount = likes.reduce((total, like, index) => total + (like.reaction === 'up' ? 1 : like.reaction === 'down' ? -1 : 0), 0)

  return (
    <View style={[{ borderWidth: 2, borderColor: '#eeeeee', width: 50, backgroundColor: '#fafafa', borderRadius: 25, height: 100, justifyContent: 'center', alignItems: 'center' }, style]}>
      <Icon color={upLike ? selectedColor : unselectedColor} name="arrow-up" type="font-awesome" onPress={likePressed} />
      <View style={{ height: 5 }} />
      {/* TODO:  Should be able to limit the number to a human readable area of a certain size... maybe hmm... like 1k, etc hmm.... */}
      <Paragraph>{likes.length > 0 ? likeCount : "-"}</Paragraph>
      <View style={{ height: 5 }} />
      <Icon color={downLike ? selectedColor : unselectedColor} name="arrow-down" type="font-awesome" onPress={dislikePressed} />
    </View>
  );
}

export const CommentsWidget = ({ style, targetId, targetType }: { style?: ViewStyle, targetId: string, targetType: string }) => {

  const token = useCognitoToken();
  const user = useCognitoUser();
  const [profile] = useProfile();
  const api = getGalleryApi(token?.getJwtToken());
  const [comments, setComments] = React.useState<Comment[]>([]);
  const [comment, setComment] = React.useState("");


  const displayName = useDisplayName();

  // const isRando = user ? user.getUsername().startsWith("session_") : true;

  //  TODO:  MOST of these widgets are the same!  Obtaining the list is the same!  THIS is why we might want to use a system to obtain all "edges" and then we can broker based on the type.  UGH.. that's exaclty what I was doing in TrackMine too mm!! It doesn't mean the broker needs to happen yeah mm... CAN be a context and we CAN have providers for the various "types" mm!!  Like React Context providers hm!  THEN we can obtain ALL associations hmm...  SOME may be associated in more complex ways though hmm...  

  const loadComments = async () => {
    const comments = await api.commentsControllerFindAll();
    const filteredComments = comments.filter(like => (like.targetId === targetId) && (like.targetType === targetType));
    setComments(filteredComments);
  }

  const postComment = async () => {
    await api.commentsControllerCreate({ body: { targetType, targetId, comment } });
    loadComments();
  }

  React.useEffect(() => {
    loadComments();
  }, []);

  const selectedColor = '#333333';
  const unselectedColor = '#aaaaaa';

  const settings = useSettings();


  const avatarSize = 50;
  const usernameSize = 15;
  //  TODO:  Should be able to express that the USERNAME is centered to the Avatar!  THAT should set the margin mmm... We CAN do that too hmm... Basically have a layout thing on Avatar, and when we have that expression, we use it to determine that number, BUT the interpretation will be dependent on the nesting?? Hmm..
  const usernameOffset = avatarSize / 2 - usernameSize / 2;

  const CommentWidget = ({ comment, style }: { comment: Comment, style?: ViewStyle }) => {
    const navigation = useNavigation();
    const [profile] = getProfile(comment.owner);

    //  TODO:  Abstract "session_" deatils!  MEANING, we want to build a system or "circuit of influence" which does NOT expose those details, BUT which has enough to differentiate THIS pattern (Guest vs. Registered Accounts)
    const isRando = comment?.owner.startsWith("session_");
    return (

      // Container
      <View style={[{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }, style]}>

        {/* Avatar */}
        <View style={{ width: avatarSize, height: avatarSize, borderRadius: 25, backgroundColor: 'f4f4f4', borderWidth: 1, borderColor: '#eeeeee', overflow: 'hidden' }}>
          <Image source={{ uri: isRando ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIQTRASmorWbvgwo1lwqYbsKUeWz7FLvnzaE6uDwG6TDmM_NYCMyhvn1bd5kmCVRlk4nU&usqp=CAU' : profile?.avatar }} style={{ height: '100%', width: '100%' }} resizeMode="cover" />
        </View>

        {/* Vertical Separator */}
        <View style={{ width: 15 }} />

        {/* Comment Text */}
        {/* NOTE:  + 2 is for aesthetic  */}
        <View style={{ marginTop: usernameOffset + 5 }}>
          <Paragraph style={{ lineHeight: usernameSize, fontSize: usernameSize, fontFamily: primaryFontFamilyHeavy, color: '#888888' }}>{isRando ? "Rando" : profile?.displayName}</Paragraph>
          {/* TODO:  NEED timestamps!!! */}
          {/* <Paragraph style={{ fontSize: 12, fontFamily: primaryFontFamilyHeavy, color: '#aaaaaa' }}>{comment}</Paragraph> */}
          <View style={{ height: 10 }} />
          <Paragraph>{comment.comment}</Paragraph>
        </View>

        {/* TODO:  SOOOO Much more can be added.  We MAY want to support arbitrary metadata that meets certain TYPE constraints like "Properties" or ... specific properties/ systems, etc.  The idea is, display LOTS of useful indicators and yeah mm!  EVENTUALLY use neural! */}

      </View>
    );
  }

  return (
    <View style={[{ padding: settings.marginSize, backgroundColor: 'white' }, style]}>

      {
        !comments.length && (
          <View style={{ padding: 30, borderRadius: 10, backgroundColor: '#eeeeee', alignItems: 'center', justifyContent: 'center' }}>
            <Paragraph>No Comments Yet</Paragraph>
          </View>
        )
      }

      <FlatList
        data={comments}
        renderItem={item => <CommentWidget comment={item.item} />}
        ItemSeparatorComponent={() => <View style={{ height: 17 }} />}
      />

      <View style={{ height: 50 }} />


      {/* <View style={{ minHeight: 2, minWidth: 2, backgroundColor: '#eeeeee' }} /> */}


      <View style={{ width: '100%', maxWidth: 500, alignSelf: 'center' }}>
        <Paragraph>{displayName ? "Comment as " + profile?.displayName : "Comment Anonymously"}</Paragraph>
        <TextField mode="automatic" title="" value={comment} onSave={comment => setComment(comment)} />
        <GalleryButton title="Comment" onPress={postComment} />
      </View>

    </View>
  );
}

export const TagsWidget = ({ style, targetId, targetType }: { style?: ViewStyle, targetId: string, targetType: string }) => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());

  return (
    <View style={[style]}>

      <SubHeader>Tags</SubHeader>
    </View>
  );
}

export const WorkDetail = ({ work, setWork }: { work: Work, setWork: (work: Work) => void }) => {


  // return <Text>asdf</Text>

  // const [imageUrl, setImageUrl] = React.useState<string>("");
  // const [loading, setLoading] = React.useState(true);

  // const getImage = async () => {
  //   setLoading(true);

  //   try {
  //     const workImageBlob = await api.worksControllerFindOne({
  //       id: work.work.id,
  //     });
  //     const workImageUrl = URL.createObjectURL(workImageBlob);
  //     setImageUrl(workImageUrl);
  //   } catch (err) {
  //     console.log("Failed to obtain image.");
  //   }

  //   setLoading(false);
  // };

  // React.useEffect(() => {
  //   getImage();
  // }, []);

  // if (loading) {
  //   return <ActivityIndicator />;
  // }

  // const generateMockups = async () => {
  //   const _work = await api.worksControllerCreateMockups({
  //     body: { id: work.work.id },
  //   });
  //   alert("Updated Work: " + JSON.stringify(_work));
  //   //  TODO:  Update the Image URL too
  //   setWork({ imageUrl: work.imageUrl, work: _work });
  // };


  const navigation = useNavigation();
  const { isDesktop, isMobile } = useSizes();
  const settings = useSettings();
  const admin = isAdmin();
  const user = useCognitoUser();

  const isOwner = work.owner === user?.getUsername();
  const token = useCognitoToken();
  const [profile, setProfile] = useWorkProfile(work);
  const [height, setHeight] = React.useState(400);
  const [width, setWidth] = React.useState(400);
  const [columnWidth, setColumnWidth] = React.useState(400);

  Image.getSize(work.url, (width, height) => {
    setWidth(width);
    setHeight(height);
  });

  const deleteWork = async () => {
    const api = getGalleryApi(token?.getJwtToken());
    const res = await api.worksControllerRemove({ id: work.id })
    navigation.goBack();
  }

  const aspectRatio = width / height;


  //  TODO:  SHOULD have a BINDING for the Davel Type.  This way, I can make it, and then I have abstract thigns available, like bindings.  THen I can "bind" it to certin UIs mm  that does make some sense hmm... Don't want to have to freaking do it manually every time.

  const updateWork = async (newWork: Work) => {
    await api.worksControllerUpdate({ id: newWork.id, body: newWork });
    window.location.reload();
  }

  // We'll let FLEX determine the width.  After that, we'll set the height based on the aspect ratio.  I think this may be similar to the OpenSea appraoch.

  const [showAdminTools, setShowAdminTools] = React.useState(false);

  // return <Text></Text>
  return (

    <View style={{ flexDirection: 'column' }}>



      <View style={{ alignItems: 'center', backgroundColor: 'white', padding: settings.marginSize, flex: isMobile ? undefined : 2, height: isDesktop ? 800 : 650 }}>

        {/* TODO!!!:  AH!  SEE there's a SySTEM freaking... HIDDEN in this code... it's SO hard to find it and understand the FLOW... because it's all coupled!  I WANT to be able to have an "Image Layout" system where I INJECT these necessary things ... This means I need access to the comonents and I'm coupled with this ontological strucutre.  Which DOES exist!  It's just a CHILD etc hm!  WHY aren't more people doing it???  Because, people do what they know mm!!  People do what's "standard" even if it's not the best ugh!!!  People don't THINK too much!!!  People don't TRY! */}
        {/* NOTE:  I HATE that we have to couple things like spacing here and stuff... I reALLY think MOST of this can be done by the system.  And it WILL consider DOMAIN SPECIFIC info related to the abstraction! LIKE I was originally sayingmm!! */}

        {/* TODO:  Update the edit interface.  I don't like it being built-in like this.  Instead, I'd like a separate form I think? */}
        <SuperHeader editable={isOwner ? true : false} onChange={(value) => updateWork({ ...work, title: value })} style={{ fontSize: 40, color: '#333333', textAlign: 'center', lineHeight: 45, maxWidth: 400 }}>{capitalizeAllWords(work.title)}</SuperHeader>

        <View style={{ width: isDesktop ? 30 : settings.marginSize, height: isDesktop ? 30 : settings.marginSize }} />

        <View style={{ flexDirection: 'row', width: '100%', maxWidth: isMobile ? 250 : 400 }}>
          <LikesWidget style={{ position: 'absolute', right: -70, zIndex: 1000 }} targetType="creation" targetId={work.id} />
          <Image
            onLayout={(event) => { setColumnWidth(event.nativeEvent.layout.width) }}
            style={{ borderRadius: defaultBorderRadius, height: columnWidth / aspectRatio, width: '100%', maxWidth: isMobile ? 250 : 400 }}
            resizeMode="cover"
            source={{ uri: work.url }}
          />
        </View>

        <View style={{ width: isDesktop ? 30 : settings.marginSize, height: isDesktop ? 30 : settings.marginSize }} />
        <Paragraph style={{ maxWidth: 500 }}>{work.description}</Paragraph>
        <View style={{ width: settings.marginSize, height: settings.marginSize }} />
        <Pressable onPress={() => navigation.navigate("ArtistDetail", { userId: profile?.username })} style={{ alignItems: 'center' }}>
          <Paragraph style={{ fontSize: 16, fontFamily: primaryFontFamilyHeavy, color: '#888888' }}>{profile?.displayName}</Paragraph>
          <View style={{ width: 10, height: 10 }} />
          <Image source={{ uri: profile?.avatar }} style={{ height: 60, width: 60, borderRadius: 30 }} resizeMode="cover" />
        </Pressable>

        {/* <View style={{ width: settings.marginSize, height: settings.marginSize }} /> */}
      </View>

      {/* <View style={{ minHeight: 2, minWidth: 2, backgroundColor: '#eeeeee' }} /> */}

      <CommentsWidget style={{ maxWidth: 700, alignSelf: 'center', width: '100%' }} targetType="creation" targetId={work.id} />

      <View>

        <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%' }} />

        <CenteredView style={{ padding: settings.marginSize, backgroundColor: '#fcfcfc' }}>
          <Breadcrumbs style={{ alignItems: isDesktop ? 'flex-start' : 'center' }} />
        </CenteredView>

        <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%' }} />

        {/* TODO:  Implement Delete! */}
        {/* TODO:  Rewards! */}

        <CenteredView containerProps={{ style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, padding: settings.marginSize }}>
          <WorkProducts work={work} />
        </CenteredView>

        <CenteredView containerProps={{ style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, padding: settings.marginSize }}>

          {/* TODO:  Re-Enable the Admin Tools!  They are breaking the site right now.  Also, make them NICER!!! */}
          {
            admin && (
              <>
                <GalleryButton title="Show Admin Tools" onPress={() => setShowAdminTools(!showAdminTools)} />
                {
                  showAdminTools && (
                    <GroupCard>
                      <SubHeader style={{ color: '#666666', fontSize: 25 }}>Admin Tools</SubHeader>
                      <View style={{ height: 15 }} />
                      <WorkProductImporter work={work} />
                      <MatureEditor work={work} setWork={setWork} />
                      <View style={{ height: 15 }} />
                      <PrintfulProducts work={work} />
                      <View style={{ height: 30 }} />
                      <GroupCard style={{ flexDirection: 'row' }}>
                        <Paragraph style={{ alignSelf: 'flex-start' }}>Listed</Paragraph>
                        <Switch style={{ alignSelf: 'flex-end' }} value={work.listed} onValueChange={(_listed) => updateWork({ ...work, listed: _listed })} />
                      </GroupCard>
                    </GroupCard>
                  )
                }


              </>
            )

          }


          {

            // TODO:  Should be able to list for sale.
            (admin || isOwner) && (
              <View>
                <View style={{ height: 2, backgroundColor: '#eeeeee', width: '400%', marginVertical: 30 }} />
                <SubHeader>Owner Tools</SubHeader>
                <View style={{ height: 15 }} />
                <GalleryButton title="Delete Work" onPress={deleteWork} />
                <View style={{ height: 30 }} />
              </View>
            )
          }
        </CenteredView>
      </View>

    </View>

  );
};

export const WorkDetailPage = ({ creationId }: { creationId: string }) => {
  const [work, setWork] = getWork(creationId);
  if (!work) { return <ActivityIndicator color={primaryColor} /> }
  return (
    <View style={{ flex: 1, width: '100%', height: '100%' }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <ScrollView style={{ flex: 1 }}>
        <WorkDetail work={work} setWork={setWork} />
      </ScrollView>
    </View>
  );
}
