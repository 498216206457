import { Color, FrameworkContext, HaborComponentContext, HaborIconSelection, InstanceID, InstanceInternal, NamedObject } from 'habor-sdk';
import * as React from 'react';
import { Text, View, ViewStyle } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Card } from '../../../../packages/kelp-bar/card';
// import { Program } from '../../program';
import { primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { ActivityPlugin } from './activity-plugin';
import { ActivityEventInternal, ActivityInternal } from './activity-types';


//
//  RoundIconButton
//

export const RoundIconButton = ({ icon, iconColor, style, onPress, onLongPress }: { icon?: HaborIconSelection, iconColor: string, style?: ViewStyle, onPress?: () => void, onLongPress?: () => void }) => (
  <Card outerStyle={{ width: 64, height: 64, borderRadius: 32 }} innerStyle={{ flex: 1, borderRadius: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }} onPress={ onPress } onLongPress={ onLongPress }>
    <KelpIcon name={ icon?.name || "info" } type={ icon?.type || "material" } color={ iconColor } size={ 24 } />
  </Card>
);


//
//  ActivityBubbleItem
//
export const ActivityBubbleItem = ({ isPending, activity, onPress }: { isPending?: boolean, activity: ActivityInternal, onPress: () => void }) => {

  //  Unpack
  const { id, payload: { name, icon, color = { r: "0", g: "200", b: "230" } } } = activity;

  //  Render Color
  const hexColor = renderRGBAColor(color);

  const isLoading = (isPending === undefined);

  //  TODO:  MAYBE do an animation around the circle while it's loading!!
  return (
    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <RoundIconButton icon={ icon } iconColor={ isPending ? hexColor : Color.darkGray } style={{ backgroundColor: isPending ? 'white' : Color.lightGray }} onPress={ onPress } />
      <View style={{ height: 10 }} />
      <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>{ name }</Text>
    </View>
  );
}

//
//  ActivityBubbleViewItem
//  INFO:  Keeps an Activity Bubble up-to-date with the pending status of an activity.
//  CONSIDER:  De-couple the pending state from the component used to render that state!  PERHAPS build a component JUST to keep / manage the state.  Then another as the view.  I suppose we could pass in a render prop with a component conforming to an INTERFACE to make this work!  Does React have built-in support for matching the interface?  I THINK we can do that with the prop types!
//

export interface ActivityBubbleViewItemProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  activity: ActivityInternal;
  onUpdated?: () => void;
  activityPlugin: ActivityPlugin;
}

interface ActivityBubbleViewItemState {
  isPending?: boolean;  //  Undefined => Loading
}

class ActivityBubbleViewItemBase extends React.Component<ActivityBubbleViewItemProps, ActivityBubbleViewItemState> {

  private activityPlugin = this.props.activityPlugin;

  constructor(props: ActivityBubbleViewItemProps) {
    super(props);
    this.state = {}
  }

  public componentDidMount = async () => {
    this.refreshPending();
  }

  public refreshPending = async () => {
    const { frameworkContext: { token }, activity } = this.props;
    const isPending = await this.activityPlugin.isPending(token, activity);
    this.setState({ isPending });
  }

  public activityPressed = async () => {

    //  Unpack
    const { frameworkContext: { token }, activity, onUpdated } = this.props;

    const pendingEvents = await this.activityPlugin.getPendingEvents(token);

    if (pendingEvents.length > 0) {
      //  Close a Pending Event
      const pendingEvent = pendingEvents[0];
      await this.activityPlugin.closeEvent(token, pendingEvent);
    }
    else {
      //  Create a New Event
      await this.activityPlugin.openEvent(token, activity);
    }

    //  Refresh
    this.refreshPending();

    //  Callback
    if (onUpdated) {
      onUpdated();
    }
  }

  public render = () => {

    //  Unpack
    const { activity } = this.props;
    const { isPending } = this.state;

    return <ActivityBubbleItem activity={ activity } isPending={ isPending } onPress={ this.activityPressed } />


  }
}
export const ActivityBubbleViewItem = ActivityBubbleViewItemBase

//
//  ActivityBubbleView
//

//  TODO:  In the NEAR future, we want to remove ALL these hard-coded views and let them be built by the user in a reasonable way... Mixing and matching components!  MAYBE we can have several PATTERNS BETWEEN components where several elements of the overall pattern are swappable, like the "Filter" bar, BUT although the interface is changing, the purpose of the piece within the pattern / system remains the SAME at the abstraction of the system.
//  IDEA:  Consider making several versions of a view based on the incoming item type??  For "Events", it's possible to support more component types??  FOR NOW, let's build out the app as if we were in a native context.  This is ESSENTIALLY acting as a prototype, BUT it's going to be functional!
//  NOTE:  This is just a "Quick Entry" form, we have another, more generic "Trackers" / "Event" page to upload other ones?
export interface ActivityBubbleViewProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  activities: InstanceInternal<NamedObject>[];
  updateActivities: () => void;
  activityPlugin: ActivityPlugin;
}

interface ActivityBubbleViewState {}

class ActivityBubbleViewBase extends React.Component<ActivityBubbleViewProps, ActivityBubbleViewState> {
  constructor(props: ActivityBubbleViewProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { activities, componentContext, frameworkContext, updateActivities } = this.props;

    return (
      <FlatGrid
        itemDimension={ 70 }
        data={ activities }
        renderItem={ ({ item }) => <ActivityBubbleViewItem activityPlugin={ this.props.activityPlugin } onUpdated={ updateActivities } activity={ item } componentContext={ componentContext } frameworkContext={ frameworkContext } /> }
        style={{ padding: 0, overflow: 'visible' }}
      />
    );
  }
}

//  TODO:  Decopule the bublle view and activity stuff ugh
export const ActivityBubbleView = ActivityBubbleViewBase