import { FrameworkContext, HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { AppEvent } from '../../workspace-plugin/workspace-settings';
import { EntityList } from '../entity/entity-list';

//
//  EntityList Primitive Component
//


//  CONCERN:  This is VERY similar to "HaborElement"... MAYBE we should combine them, OR make a sub-class?
//  TODO:  Re-write all the EntityRouter views with WorkSpace contaienrs!  Make breadcrumbs!  It shouldn't all be under one.


export interface EntityListHaborPrimitiveProps extends PrimitiveProps { }
export const EntityListHaborPrimitive = ({ userProps, frameworkProps }: EntityListHaborPrimitiveProps) => {

  const { context } = frameworkProps;
  //  TODO:  Do NOT use the system builder to build systems WHILE we're building the system builder!  At least FOR NOW.  In the future, when it's stable, we can re-write and STILL use the same INTERPRETER code hm!  LOL.. even if we DO re-write the INTERPRETRE code hmmm.. that could be interesting hmmm!  EVEN if we write an interpreter IN the the new language FOR the intepreter, then that's STILL going to be interpreted by the primitive? Hmm.. like.. if I "invoke" code USING the interpreter written in that language, then we CAN have that interpreter interpret, BUT what's interpreting that interpreter?  IF it's another of the same, then what's interpreting that?  For example, call it HCode.  We write an HCode interpreter IN HCode.  Then, we have the HCode interpreter interpet ITSELF along with a "program".  How does this work?  Well... it's written in HCode, so SOMETHING needs to be running the ORIGINAL HCode interpreter which will interpret the interpreter written in HCode.  EVENTUALLY we'll have something writtn in some OTHER language that we have a running interpreter for hm!  Why?  Because the HCode interpreter we have written in HCOde, still needs to be interpreted to interpret.  For that to happen, we need a "running" interpreter.  It CAN be an instance of the same, but then what is interpreting THAT?  Basically, ANYTHING written in HCode must be interpreted by an HCode interpreter.  That INCLUDES an HCode interpreter written in HCode.  SO... AH!  In OTHER words, in order to interpret the FIRST HCode interpreter written in HCode, we need an HCode interpreter.  BUT... all we have to START is an ENCODING which needs to be INTERPRETED to become an "interpreter".  We need something INTERPRETING.  That must be written in something OTHER than HCode hm!  MAYBE we eventually write an HCode interpreter directly with "physics", which is the only encoding which is ALSO its own interpreter? Hmm... maybe there are other members of that category hmm...


  const { user, workspace, token, changePage } = frameworkProps.context;

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <EntityList changePage={changePage} user={user} token={token} workspace={workspace} />
    </HaborContainer>
  );
};

//
//  EntityList Habor Component
//

export const EntityListHaborComponent: HaborComponent = {
  name: "EntityListHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "EntityListHaborPrimitive",
    props: {},
    children: []
  }
};
