
// import { EventEmitter } from 'react';
// import { EventEmitter } from "react-native";

import { EventEmitter } from "events";

//  CONSIDER:  Support a type.  Maybe TS and / or Davel.  
//  CONSIDER:  We could use TAGS and stuff too? Hm to show things like system, etc.. MAYBE use the MetaMesh? hm
//  FOR NOW:  I'm keeping things simple with a global event emitter.. BUT I imagine this COULD get more complicated, ESPECIALLY as we income the server events in the event emission.. it's not JUST happening on the user's device!? HM!

export const HessiaEmitter = new EventEmitter();

