/* eslint-disable import/first */
import { InstanceInternal, searchObjects, SearchTerm } from 'habor-sdk';
import { INounService, AsyncStorageNounService } from './packages/noun-service/noun-service';

export type ENoun<T> = InstanceInternal<T & { entityId: string }>;

export class ENounService<T> extends AsyncStorageNounService<T & { entityId: string }> {

  constructor(public name: string, public entityService: INounService<any>) {
    super(name);
  }

  //  TODO:  Add to the NounService? Hmm...
  //  THOUGHT:  Each instance is JUST a simple namespace.. it's a DIFFERENTIATION.  But WHY do we nneed it like this ugh??? Do we NEED an EXPLICIT for each hm!!  MAYBE make it possible to referece the diferentaiton / context? Hmmasmfasl;kdf
  //  TODO:  INJECT the Entity Service, OR just make it possible to ADD Entity stuff to the existing NounService via a PLUGIN insteaddd of making a WHOLE new class!1  THE BENEFIT is we don't have to re-work all the functions and hmm.. the interface isn't just replaceing / calling FUNCTIONS.. it's actually MORE general beacuse it's up tot eh THING what the change interface looksl ik hm!
  public async search (searchTerm?: SearchTerm): Promise<InstanceInternal<T & { entityId: string }>[]> {
    //  TODO:  Improve this!!!
    const all = await this.retrieveAll();
    //  CONSIDER:  DO we really want to return noting here? Hmm
    if (!searchTerm) { return [] }
    const results = searchObjects(searchTerm, all);
    return results;
  }

  public async create (obj: T, id?: string): Promise<InstanceInternal<T & { entityId: string }>> {
    const entityInternal = await this.entityService.create({});
    const nounInternal = await super.create({ ...obj, entityId: entityInternal.id });
    return nounInternal as any;
  };

  public async retrieveByEntityId(entityId: string) {
    //  TODO:  Optimize!
    const nouns = await super.retrieveAll();
    const noun = nouns.find(noun => noun.payload.entityId === entityId);
    return noun;
  }

  public async update (objId: string, obj: T): Promise<InstanceInternal<T & { entityId: string }>> {
    const original = await super.retrieve(objId);
    if (!original) { throw `Could not update '${ objId }' the object does not exist.`; }
    const nounInternal = await super.update(objId, { ...original.payload, ...obj });
    return nounInternal as any;
  };

  public async delete (objId: string) {
    const original = await super.retrieve(objId);
    if (!original) { throw `Could not delete '${ objId }' the object does not exist.`; }
    await this.entityService.delete(original.payload.entityId);
    await super.delete(original.id);
    console.log("DELETED!");
    //  TODO:  Follow up by removing ALL associations! HM!! MAYBE this is a function of the ENTITY system?? Hm...
  };
}
