
import * as React from 'react';
import { View } from 'react-native';

//  NOTE:  This is a SIMPLE register for adding additional React Components.  
//  CONSIDER:  We can also build a "wrapper" to make it easy to inject AROUND.  Hmm...
//  CONSIDER:  We can then perhaps build a "Modifier" system for these too... PERHAPS implement these with the SAME FUNCTIONAL register logic.  This is a specific example of the more general case of an API that we pass so the function can "decide" how it wants to handle changes.. hm!  This is ESSENTIALLY exposing an imperative API. HM!  MAYBE it would be preferable to use the specific API? Hm... it DOES seem more limiting perhaps, BUT either way, we want to EVENTUALLY build an explicit encoding.  The idea is, IF we inject something and it's NOT in the right position, then we wern't "coordinating" properly? Hmm...
//  NOTE:  FOR NOW, we'll just use NAMED registers.. I nthe future, I'd like to be able to access via an API and / or throuhg a CSS-like system with multiple things including stuff like "Class" which can AUTOMTALLY wrap OTHERR classes to support a PLETHORA of classes?   MAYBE with params on how to handle that too? HM!  THAT'S interestig! HM!  SO, we then have a way to manage which classess??? HM!  THen perhap for that and it continues FOREVER HM!? lasfj;lkasjfklasdjfl

//  TODO:  Build a system to add additoial constraints around this too, like perhaps METADATA associated wit hthe component for TYPE ifo and stuff... MAYBE do the WHOLE thing with registratio and nodse HM!
//  CONSIDER:  We DO allow systems to INJECT components.. BUT what if we want PROPS which come from the HOST? Hmm.. MAYBE that's up to the host, and AGAIN, perhaps solvable if we use an API instead.. hmm  FOR NOW, we'll make a register for EACH props set I guess.

//  EXCITING THOUGHTS:  We OFTEN have a MAP which results in a LIST after each item... PERHAPS this is a pattern, BUT can we MODIFY the HashMap system to support this WITHOUT building a WHOLE new symbol?  DOES it make sense to just build a new symbol?  We COULD Inherit hashmap and make a specialization... BUT, does that make senese???  What about when we have MORE specializations, do we INHERIT EACH time?  MAYBE this is where the "Modifier" system can be helpful.  This way, we can specify we want a particular modification, and that's a nested definition.  THIS way, UNLIKE regular "inheritance", we use the API provided in the original to make changes? Hmm... we don't necessarily hav ethe same API? Hmm... we modify using composition?  Hmm... Though, even the CLASS thing makes some sense.. when we have Mixins and stuff.. hmm.. Maybe, BUT then we need to define a new symbol each time? Hm... idk man, mabye it's the same?  I think the gist is, we can then have NESTED modifications, we even if the FIRST layer isn't much different, AND we can have MULTIPLE, which we CAN have in things like Python, BUT it's JUST a SET that we're extending, so we get those things.. With the MODIFIER system, the things work together to CHANGE it... including functioaltity? HM.. NOT just override the interface, PERHAPS with "coupling" modifiers!? HM!  The things we CAN change are determined by the host?? HM!  MAYBE!  This IS interesting... sooo... what if we start at the BOTTOM ad use this approach ALL the way up??? Hmm.... what IS the "bottom"? Hm....  VERY interesting.. hmmm... maybe an "If" statmetne?   Maybe MATCHING? Hmm.. that seems to be the FIRST thing the TM is doing.. Ah!  DIFFERENTIATION is the primitive operation perhaps? Hmm... or "OBSERVATION"? Hmm....  MAYBE it's like a PSEUDO-'operation' that involes MULTIPLE at the same time, because it's ALL a false dichotomy, and there is no such thing as an explicit, INDEPENDENT, isolated thing? Hmm... maybee....
//  MODIFIER SYSTEM:  Hmm!  MAYBE with the modifier system, we can then modify the nested pieces? hmm... so if I add a "List" modifier, I can THEN perhaps modify the set of things that can go in the list? Hm....  All of this WITHOUT using "inheritance" and building a specific thing JUST for that? Hm.... idk man.  The idea is COUPLING modifiers? Hm... like when we have a LIST AND we have an ODD modifier... MAYBE that says that every ODD element is NULL... this way, we can mere BOTH of these with an "List-Odd" coupling? Hm... maybe... maybe they can co-eist as is.  PERHAPS if I trried to do this with inheritance, it would be challenging because my ONLY iterface is inheiting from a SET and being able to access the parent set directly / override for future callers.. BUT 

//  FOR NOW:  Let's continue focusing on the string list thing, BUT let's consider coming back to this!

// export const makeHashMap = () => {

// }

// export class HashMap<T> {
//   public map: { [name: string]: T } = {};
//   public insert (name: string, value: T) {
//     if (!this.map[name]) {
//       this.map[name] = ;
//     }
//   }
// }

//  NOTE: We DO have a HIERARCHIAL position perhaps, but MAYBE we can have SEVERAL hieravhies and the node LIKE the elemetns in our system just SIT i this framework? HM!   HEN we can MODIFY them and stuff? HM!

type AnyReactComponent = React.Component<any, any, any> | React.FunctionComponent<any>;
const componentRegisters: { [name: string]: { id: string, component: AnyReactComponent }[] } = {};

//  TODO:  Type!
//  NOTE:  The ID is a custom identifier for this compennt.  This will stop from double registration.  We MAY want to VERSION it or include system info as well!
export const registerComponent = (registerName: string, id: string, component: AnyReactComponent) => {

  //  TODO:  This pattern is SO common.  Build a data structure (HashMap) which does this automatically.
  if (!componentRegisters[registerName]) {
    componentRegisters[registerName] = [];
  }
  const entries = componentRegisters[registerName];
  const exists = entries.find(entry => entry.id === id);
  if (exists) { console.log("Component " + id + " has already been regisered with register " + registerName + "."); return; }
  componentRegisters[registerName].push({ id, component });
}

export interface RegisterProps<P> {
  name: string;
  props: P;
}
interface RegisterState {}
export class Register<P> extends React.Component<RegisterProps<P>, RegisterState> {
  constructor(props: RegisterProps<P>) {
    super(props);
    this.state = {}
  }
  public render = () => {

    //  TODO:  Consider the component id when listing!  OR, consider injecting INTO the component instance direclty.. hmm PERHAPS two separate systems. the more primitive injection into the insance system, and THEN a system to manage global injections? HM!
    const components = componentRegisters[this.props.name] || [];
    console.log(components);
    return components.map(comp => {
      //  TODO:  Type this.
      const Comp: any = comp.component;
      return <Comp { ...this.props.props } />
    })
  }
}