import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { TimelineHaborComponent, TimelineHaborPrimitive } from "./timeline-page";

export class TimelinePlugin extends CorePluginClass {
  public static details = {
    name: "Timeline",
    description: "Timeline Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "timeline"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {

    hessia.registerPrimitiveComponent("TimelineHaborPrimitive", TimelineHaborPrimitive);
    hessia.registerComponent(TimelineHaborComponent);
  }
}
