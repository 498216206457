/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction, HRequestContext } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

export enum HComparatorOperator {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  LESS = "LESS",
  GREATER = "GREATER",
  LESS_OR_EQUAL = "LESS_OR_EQUAL",
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL"
}

interface HComparatorParams {
  firstValue: number;
  operator: HComparatorOperator;
  secondValue: number;
}

export const HComparatorFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "compare",
  params: {
    type: "object",
    properties: {
      firstValue: { type: "number" },
      operator: { type: "option", options: Object.keys(HComparatorOperator)  },
      secondValue: { type: "number" }
    }
  },
  returns: {
    type: "boolean"
  },
  definition: (input: PrimitiveFunctionParams<HComparatorParams>): boolean => {

    const { params, context } = input;

    //  Define the map from comparison type to comparator function.
    //  NOTE:  We user triple equals because types must match.
    const comparatorFunctions = {
      [HComparatorOperator.EQUAL]: (first: number, second: number) => (first === second),
      [HComparatorOperator.NOT_EQUAL]: (first: number, second: number) => (first !== second),
      [HComparatorOperator.LESS]: (first: number, second: number) => (first < second),
      [HComparatorOperator.GREATER]: (first: number, second: number) => (first > second),
      [HComparatorOperator.LESS_OR_EQUAL]: (first: number, second: number) => (first <= second),
      [HComparatorOperator.GREATER_OR_EQUAL]: (first: number, second: number) => (first >= second),
    };

    //  Get the comparator function
    const comparator = comparatorFunctions[params.operator];

    //  Check for undefined
    if (!comparator) { throw new Error("No comparator for the given input.  Make sure you've specified a valid operator."); }

    //  Get the result
    const result = comparator(params.firstValue, params.secondValue);

    //  Return the result
    return result;
  }
};
