import { HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { AddonHandler } from '../../model-plugin/entity/instance-creator';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { AddonProps, AddonUserProps, AddonHaborComponentProps } from '../access-plugin/access-addon';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';


interface NamedObjectAddonHandlerProps extends AddonProps {}

interface NamedObjectAddonHandlerState {}

class NamedObjectAddonHandler extends React.Component<NamedObjectAddonHandlerProps, NamedObjectAddonHandlerState> {

  constructor(props: any) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext, addon: { schema }, setValue, value } = this.props;
    const { frameworkContext: { changePage, token, user } } = this.props;

    return (
      <View style={{ flex: 1, padding: medSpacer }}>
        <DavelForm value={ value } autoSubmit={ true } schema={ schema } onSubmit={ setValue } onCancel={ () => alert("canceled") } metadata={{ token, user, frameworkContext }} />
      </View>
    );
  }
}

//
//  NamedObjectAddonHandler Primitive Component
//
interface NamedObjectAddonHandlerHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: AddonUserProps;
}
export const NamedObjectAddonHandlerHaborComponentPrimitive = ({ userProps: { addon, setValue, value }, frameworkProps }: NamedObjectAddonHandlerHaborComponentPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <NamedObjectAddonHandler value={ value } setValue={ setValue } addon={ addon } frameworkContext={ context } componentContext={ componentContext } />
    </HaborContainer>
  );
};


export const NamedObjectAddonHandlerHaborComponent: HaborComponent = {
  name: "NamedObjectAddonHandlerHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "NamedObjectAddonHandlerHaborComponentPrimitive",
    props: AddonHaborComponentProps,
    children: []
  }
};


//  NOTE:  Currently, each Addon controls a "slice" of the Instance "state".  In the future, perhaps we instead want to conceptualize this as a chain of "filters" which go through, one by one, to reach a final value, where each step doesn't necessarily map to a single class!  FOR NOW, I think this is OK!  MAYBE in that the later conceptualization, we can pull out named properties, or even affect remote properties?? 
//  IDEA:  Maybe add a checkbox to the addons when they are filled out.  Otherwise, we won't be able to create the new element.  In this case, we're giving each addon a class ot deal with... This makes it easier to construct the final object?  MAYBE we just process them in order?

//  Define the "NamedObject" Addon
export const namedObjectAddon: AddonHandler = {
  id: "namedobject",
  name: "Named Object",
  description: "Manage Basic Object Traits",
  className: "namedobject",
  icon: { name: "brush", type: "material" },
  component: NamedObjectAddonHandlerHaborComponent
}
