import { Color, HaborIconSelection, InstanceInternal, Workspace } from 'habor-sdk';
import * as React from 'react';
import { Text, View, ViewStyle, ScrollView, TouchableOpacity } from 'react-native';
import { withRouter } from 'react-router';
import { RouteComponentProps } from '../../../packages/isomorphic-router/react-router';
import { navigate, RouteList } from '../hessia-plugin/config';
import { getRaisedStyle, primaryFontFamilyHeavy, RaisedHeight } from '../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";

const menuWidth = 250;

const floatingButtonStyle = {
  height: 16,
  width: 50,
  borderRadius: 8
}

export const RoundTextButton = ({ icon, color, selected, onPress, style, title }: { icon: HaborIconSelection, color: string, selected: boolean, onPress: any, style?: any, title: string }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 20 }}>
      <TouchableOpacity onPress={ onPress } style={{ height: 70, width: 70, borderRadius: 35, backgroundColor: color, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...style }}>
        <KelpIcon name={ icon.name } type={ icon.type } color={ Color.white } size={ 25 } />
      </TouchableOpacity>
      <View style={{ height: 5 }} />
      <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 14, color: Color.black, opacity: 0.5 }}>{ title }</Text>
    </View>
  );
}

const defaultSize = 70;
//  CONSIDER:  We don't always have the concept of "selected".  Consider removing that?  MAYBE we can highlight it on press?? Hmm
export const RoundButton = ({ icon, circleColor, iconColor, selected, onPress, style, size = defaultSize }: { icon: HaborIconSelection, circleColor: string, iconColor: string, selected: boolean, onPress: any, style?: ViewStyle, size?: number }) => {
  const reductionFactor = size / defaultSize;
  return (
    // <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 20 }}>
      <TouchableOpacity onPress={ onPress } style={{ height: size, width: size, borderRadius: size / 2, backgroundColor: circleColor, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...style }}>
        <KelpIcon name={ icon.name } type={ icon.type } color={ iconColor } size={ 25 * reductionFactor } />
      </TouchableOpacity>
    // </View>
  );
}

export interface SimpleSwitcherProps extends RouteComponentProps {
  workspace: InstanceInternal<Workspace>;
  open: boolean;
  onPressOffscreen: () => void;
}

interface SimpleSwitcherState {
}
class SimpleSwitcherBase extends React.Component<SimpleSwitcherProps, SimpleSwitcherState> {
  constructor(props: SimpleSwitcherProps) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  public render = () => {

    const { open } = this.props;

    //  TODO:  I'd like the menu to push the rest of the screen down instead of coming over it.
    return (
      // TODO:  JUST like React Navigation, I'd like to have NESTED Navigators where we DON'T need to specify the top / height!
      <View style={{ top: 50, position: 'absolute', height: '100%', width: '100%' }} pointerEvents='box-none'>
        {
          open ?
            <View style={{ position: 'absolute', zIndex: 1 , backgroundColor: Color.black, opacity: 0.7, height: '100%', width: '100%' }}>
              <TouchableOpacity style={{ width: '100%', height: '100%' }} onPress={ this.props.onPressOffscreen } />
            </View> :
            null
        }
        <View style={{ position: 'absolute', paddingTop: 15, display: 'flex', flexDirection: 'column', left: open ? 0 : -menuWidth, width: menuWidth, flex: 0, height: '100%', paddingBottom: 5, backgroundColor: Color.white, zIndex: 2, ...getRaisedStyle({ raisedHeight: RaisedHeight.low }) }}>
          <ScrollView horizontal={ true } contentContainerStyle={{ paddingLeft: 20, paddingRight: 20 }} showsHorizontalScrollIndicator={ false }>
            <RoundTextButton title="Systems" onPress={ () => navigate(this.props.history, RouteList.Workspaces.Edit, { workspace: this.props.workspace }) } icon={{ name: "system", type: "habor" }} color="#a712ef" selected={ false } />
            <RoundTextButton title="Pages" onPress={ () => navigate(this.props.history, RouteList.Workspaces.Edit, { workspace: this.props.workspace }) } icon={{ name: "pages", type: "habor" }} color="#ef1275" selected={ false } />
            <RoundTextButton onPress={ () => navigate(this.props.history, RouteList.Workspaces.Edit, { workspace: this.props.workspace }) } title="Entities"  icon={{ name: "entities", type: "habor" }} color="#10e0a3" selected={ false } />
            <RoundTextButton title="Close" onPress={ () => navigate(this.props.history, RouteList.Home, {}) } icon={{ name: "exit", type: "habor" }} color="#efa412" selected={ false } />
          </ScrollView>
          <View style={{ height: 5 }} />
          <View style={{ marginLeft: 20, marginRight: 20, height: 2, backgroundColor: Color.black, opacity: 0.1 }} />
          <View style={{ height: 8 }} />
          <Text style={{ marginLeft: 20, fontFamily: primaryFontFamilyHeavy, fontSize: 12, color: Color.darkGray, letterSpacing: 3, opacity: 0.5 }}>SYSTEMS</Text>
          <View style={{ height: 5 }} />
          <ScrollView horizontal={ true } contentContainerStyle={{ paddingLeft: 20, paddingRight: 20 }} showsHorizontalScrollIndicator={ false }>
            <RoundTextButton title="Menu" onPress={ () => navigate(this.props.history, RouteList.Workspaces.Edit, { workspace: this.props.workspace }) } icon={{ name: "menu", type: "material" }} color="#12beef" selected={ false } />
          </ScrollView>
        </View>
      </View>
    );
  }
}
export const SimpleSwitcher = withRouter(SimpleSwitcherBase);