import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { InstanceInternal, NamedObject, EntityType } from "habor-sdk";
import { haborSDK } from "../hessia-plugin/config";
import { HabitPlugin } from "../habit-plugin";

export class AccountabilityPlugin extends CorePluginClass {
  public static details = {
    name: "Accountability",
    description: "Accountability Plugin",
    dependencies: [HessiaPlugin.details.id, HabitPlugin.details.id],
    id: "accountability"
  }

  public install = (program: Program, { hessia, habit }: { hessia: HessiaPlugin, habit: HabitPlugin }) => {

    //  TODO:  We want to register something with the Habit system.  Currently, the habit system shows bubbles.  MAYBE I can introduce an API there, but it would need to be PER Habit?  OR, I can use a more generic CSS-like framework to inject elements based on UI / state context!?  MAYBE context is set by VARIOUS systems?? Hmm... maybe systems have an interface state?  AH!! MAYBE the INTERFACE has a state, BUT systems can INFLUENCE the sub-states!?


    // hessia.registerPrimitiveComponent("HabitsHaborPrimitive", HabitsHaborPrimitive);
    // hessia.registerComponent(HabitsHaborComponent);
    //  NOTE:  We could ALSO pass in a thing to this function for editing.. I THINK I like the more generic CSS thing better though? Hmm... I think this pattern I'm using is a PRIMITIVE for that system?
    habit.registerBorderColorator(async (habit: InstanceInternal<NamedObject>, token: string) => {

      //  TODO:  FOR NOW, we do this for EACH habit... in the future, we MAY want to do it for them in bulk.. AND use a more generic system for setting style and stuff?  BUT this IS an introduction to this concept in the primitive layer!?

      //  Get the Tag Instance
      //  Abstract as a function expose by the TAG system?  This way way we can get a Tag by ID?  We can use this in the back AND frontend.  BUT this is different from HESSIA functions which can be built and registerd with primitives and may MIRROR these primitive things!?  COOL!!!  MAUYBE we should make an explicit space for both of those!???  hmmmmm
      //  TODO:  Eventually do this in the application level to get by NAME!?  MAYBE using linkes from the NAME system??? Hmmm MAYBE we SHOULD start with some connected local DB???  Hmm.. MAYBE we can load all the users stuff in a MASSVE space which is run in MEMORY?? That MAY be more sensible that going through EVERY user's stuff??? Hmmm... interesting.. BUT there's a lot of OVERLAP between that?!?? This DOES start to make snse!?  MAYBE this is why we have PROGRAMS because it's like a SMALL instance of that SAME thing without all the overhead?  BUT, I like the idea of doing it with APIs to stay consistent? MAYBE it's about CACHING locally but implementing more globally?? Hmmmmm
      const tagInst = (await haborSDK.searchInstances(token, {
        nounId: "tag",
        search: {
          match: {
            payload: {
              name: "Required Habit"  //  TODO:  Don't hard-code names.. use a shared system.. MAYBE combine all three front-end / back, etc???
            }
          }
        }
      }))[0];
      console.log("test");

      //  Get the Tag Link
      //  TODO:  SHOULD be a PRIMITIVE and HABOR FUNCTION as part of the Accountability Plugin to check if a Habit is marked as Accountable!  Should ONLY accept "Habit" types!!!?
      //  TODO:  Habor SDK should ALSO be a Halia Plugin?  More generally, KEEP working on a way to get this information in a COMPOSABLE DYNAMIC way, where EACH plugin adds pieces.. let's start to brainstorm on that SOON!?
      const tagLink = await haborSDK.searchInstances(token, {
        nounId: "tag-association",
        search: {
          match: {
            payload: {
              srcId: {
                type: EntityType.Instance,
                nounId: "tag",
                instanceId: tagInst.id
              },
              destId: {
                type: EntityType.Instance,
                nounId: "habit",
                instanceId: habit.id
              }
            }
          }
        }
      });
      console.log("test");

      return tagLink.length ? "red" : undefined;


    });

  }


  //  Be able to assocate PEOPE and stuff ENTITIES in general with my shit!?  Again, we SHOULD be able to see POSIBLE associations?  MAYBE it needs to be registerd though?  EITHER way, I REALLY like that we've moved to this point!?

}
