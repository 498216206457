import AsyncStorage from "@react-native-async-storage/async-storage";
import * as React from "react";
import { SyncVariant } from "../utilities";


//  TODO:  Consider EVENT DATES for when things were changed in this encoding.  Like when things were added to a cart etc.  Consider that as a FUNDAMENTAL data model system!!!  Called "event-sourcing" ugh.....

//  TODO:  REALLY don't like using Context for this... I think there's a better way and I THINK as I build out Hessia it will become more clear.  

//  TODO:  ALL of this is ONE implementaiton of SUCH a common pattern... associating TWO pieces of information... one of which is an Integer Count and the other is a Variant ID.  I DO believ we can encode the FULL range of operations by specificying these TYPES and ENCODING their "type" then interpreting that hmmm...  Like "rules" INSTEAD of encoding hmmm... MAY want to change the LANGUAGE though!  For "Add Item" and stuff to make it relevant.  BUT the other stuff should be COMPOSED from the more fundamtnal types... Variant Reference, and Count.  Hmmm... POSSIBLY even "Quantity"  and I CAN use type inheritance.  Which is what I was doing in TrackMine hm!
//         Seem to need a more expressive "type" system for tha thmm... I can do that in HESSIA though hm! m!

//  TODO:  Decopule Printful

export interface CartItemBare {
  quantity: number;
};

export interface CartItemWithVariant {
  variant: SyncVariant;
  quantity: number;
};

export interface CartBare {
  [variantId: string]: CartItemBare;
}

export interface Cart {
  [variantId: string]: CartItemWithVariant;
}

export interface CartContext {
  addItem: (variant: SyncVariant) => void;
  removeItem: (variant: SyncVariant) => void;
  setQuantity: (variant: SyncVariant, quantity: number) => void;
  isEmpty: () => boolean;
  cart: Cart;
  cartBare: CartBare;
}

export const CartContext = React.createContext<CartContext>({
  addItem: (variant: SyncVariant) => { throw new Error(`Could not add item.  CartContext not initialized.`) },
  removeItem: (variant: SyncVariant) => { throw new Error(`Could not add item.  CartContext not initialized.`) },
  setQuantity: (variant: SyncVariant, quantity: number) => { throw new Error(`Could not add item.  CartContext not initialized.`) },
  cart: {},
  isEmpty: () => { return true; },
  cartBare: {}
});

export interface CartProviderProps { }
export interface CartProviderState {
  cart: Cart;
  cartBare: CartBare;
}

const makeBare = (cart: Cart) => {
  const cartBare: CartBare = {};
  Object.keys(cart).forEach(variantId => {
    cartBare[variantId] = { quantity: cart[variantId].quantity };
  });
  return cartBare;
}

class CartProviderBase extends React.Component<CartProviderProps, CartProviderState> {

  constructor(props: CartProviderProps) {
    super(props);
    this.state = {
      cart: {},
      cartBare: {}
    }
  }

  //  TODO:  Consdier that it COULD be bad to SERIALIZE cart state when we could remove products!
  public componentDidMount = async () => {
    const cartJson: any = await AsyncStorage.getItem("cart");
    const cart: Cart = cartJson ? JSON.parse(cartJson) : {};
    this.setCart(cart);
  }

  public addItem = (variant: SyncVariant) => {
    const variantId = variant.id.toString();
    const { cart } = this.state;
    const quantity = cart[variantId] ? cart[variantId].quantity + 1 : 1;
    this.setQuantity(variant, quantity);
  }

  public removeItem = (variant: SyncVariant) => {
    const variantId = variant.id.toString();
    const { cart } = this.state;
    const quantity = cart[variantId] ? cart[variantId].quantity - 1 : 0;
    this.setQuantity(variant, quantity);
  }

  public setCart = async (cart: Cart) => {

    //  Update State
    this.setState({ cart, cartBare: makeBare(cart) });

    //  Update AsyncStorage
    await AsyncStorage.setItem("cart", JSON.stringify(cart));
  }

  public setQuantity = (variant: SyncVariant, quantity: number) => {
    const variantId = variant.id.toString();
    const { cart } = this.state;
    if (quantity > 0) {
      const newCart = { ...cart, [variantId]: { quantity, variant } };
      this.setCart(newCart);
    } else {
      const newCart: Cart = {};
      Object.keys(cart)
        .filter(_variantId => _variantId != variantId)
        .forEach(_variantId => {
          newCart[_variantId] = cart[_variantId];
        });
      this.setCart(newCart);
    }
  }

  public isEmpty = () => {
    return !Object.keys(this.state.cart).length;
  }

  public render = () => {
    return (
      <CartContext.Provider value={{ isEmpty: this.isEmpty, addItem: this.addItem, removeItem: this.removeItem, setQuantity: this.setQuantity, cart: this.state.cart, cartBare: this.state.cartBare }}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}
export const CartProvider = CartProviderBase


export const useCart = () => {
  return React.useContext(CartContext);
}