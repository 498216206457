import * as React from 'react';
import { View } from 'react-native';

export const Window = ({ children }) => {
  return children
}

// export interface SplitterAPI {
//   addWindow: (window: any) => void;
// }
// export const Splitter = ({ children, horizontal = true, parent = {} }: { children: any, horizontal?: boolean, parent?: any}) => {

export const Splitter = ({ children, horizontal = true, extraChildren = [] }: { children: any, horizontal?: boolean, extraChildren?: any[]}) => {

  // const [extraChildern, setExtraChildern] = React.useState<any[]>([]);


  //  NOTE:  The idea is to MOUNT JS this context in the parent so there's an imperative API to adjust...
  //  TODO:  Generalize this with either a Portal, inheritance, composition, or maybe the modifier pattern!
  //  CONSIDER:  What are the pros / cons of this appraoch, GIVEN that it's KINDA impaerative when React is strong with its imperative API?
  //  GENERAL:  Continue working on COMBINING these plugin concepts with React's declarative approach?  REMEMBER, React is SEPARATE from Flux hm!
  // parent.splitter = {
  //   addWindow: (window: any) => {  //  TODO:  Type as react element
  //     setExtraChildern([...extraChildern, window])
  //   }
  // }

  return (
    <View style={{ display: 'flex', flexDirection: horizontal ? 'row' : 'column', flex: 1 }}>
      { React.Children.map(children, (child: any) => <View style={{ display: 'flex', flex: 1 }}>{ child.props.children }</View>) }
      { extraChildren.map((child: any) => <View style={{ display: 'flex', flex: 1 }}>{ child }</View>) }
    </View>
  )
}



// export const Portal = () => {

//   const [children]
//   return ()
// }

