import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { EventEmitter } from 'events';
import { EntityType, HaborComponent, NounInternal as HaborNounInternal, InstanceInternal, NounId, NounInternal, Relationship, SerializedHaborComponentNoun, UserBlock, deserializeHaborComponent, deserializeUserBlock, serializedInstanceInternalSchema, serializedUserBlockNoun } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View, ViewStyle } from "react-native";
import { FlatGrid } from 'react-native-super-grid';
import { CardModal } from '../../../packages/kelp-bar/card-modal';
import { IconButton } from '../../../packages/kelp-bar/icon-button';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { medSpacer, smallSpacer } from "../../../packages/kelp-bar/styles";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { ComponentPlugin } from "../component-plugin";
import { HaborContainer, PrimitiveProps } from "../component-plugin/habor-react/habor-component-lib";
import { HaborComponentViewer } from "../component-plugin/habor-react/habor-component-viewer";
import { EntityPlugin } from "../entity-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { AppContext } from "../hessia-plugin/AppContext";
import { haborSDK } from "../hessia-plugin/config";
import { DynamicWidgetItem, WidgetItem } from "../hessia-plugin/dynamic-component";
import { Filter, FilterComponent, FilterComponentProps, typeToFilterMap } from '../hessia-plugin/filter-utils';
import { SystemPlugin } from "../system-plugin/system-plugin";
import { EntityEditor } from './entity/entity-editor';
import { ModelCard } from './entity/entity-list';
import { AddonHandler, InstanceCreator } from './entity/instance-creator';
import { InstanceList } from './entity/instance-list';
import { FilterContext, FilterProvider, InstanceFilterComponent } from "./filters/filters";
import { EntityCreatorHaborComponent, EntityCreatorHaborPrimitive } from "./habor-components/entity-creator";
import { EntityEditorHaborComponent, EntityEditorHaborPrimitive } from "./habor-components/entity-editor";
import { EntityListHaborComponent, EntityListHaborPrimitive } from "./habor-components/entity-list";
import { InstanceCreatorHaborComponent, InstanceCreatorHaborPrimitive } from "./habor-components/instance-creator";
import { InstanceEditorHaborComponent, InstanceEditorHaborPrimitive } from "./habor-components/instance-editor";
import { InstanceListHaborComponent, InstanceListHaborPrimitive } from "./habor-components/instance-list";
import { MainInstanceListItemHaborComponent, MainInstanceListItemHaborComponentPrimitive } from "./habor-components/instance-list-item";
import { HaborModelSDK, HaborModelService, ModelSDK } from './model-sdk';



//  NOTE:  A Model is a SIMPLE observable pattern in the context of Entities / Relatioships, and one REQUIRED to contain the model itself.. because the "Fudamental Ontology" cannot exist without it, we iclude it as a basic, core entity.  It CAN be created with just relationships and associated entities.  BUT, we build our own encoding for optimization? (FOR NOW).
//  CONSIDER:  I'd like to converge local and remote work ... hmm.... I DO think there's quite some benefit to the local systemitized thing too though hm!

//  NOTE:  In Habor we call it "noun" but I want to change it to "model".  Then, here, "noun" is a more general term I use forr my local storage abstrraction hm... all we do is give it a namespace hm.
// export const modelService = new HaborNounService<HaborNoun>("model");
// modelService.init();

// export const instanceService = new ENounService<Instance>("instance");
// instanceService.init();



// const localModelSDK = new LocalModelSDK();

interface ModelState {
  models: HaborNounInternal[];
}


export const ModelList = ({ modelPlugin }: { modelPlugin: ModelPlugin }) => {

  const navigation = useNavigation();

  const [models, setModels] = React.useState<HaborNounInternal[]>([]);

  const loadModels = async () => {
    const models = await modelPlugin.modelSdk.getModels();
    setModels(models);
  }

  React.useEffect(() => {
    loadModels();
  }, []);

  return (

    <PageLoader loading={false}>
      <Page style={{ marginHorizontal: 20 }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Models</Text>
          <TouchableOpacity onPress={() => { navigation.navigate(ModelRoute.ModelEditor as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <FlatGrid
            data={models}
            itemDimension={200}
            renderItem={({ item: model }) => <ModelCard onPress={() => navigation.navigate(ModelRoute.InstanceList as never, { model } as never)} group={true} noun={model} />}
          />
        </ScrollView>
      </Page>
    </PageLoader>
  );
};

export enum ModelRoute {
  ModelEditor = "ModelEditor",
  ModelList = "ModelList",
  InstanceList = "InstanceList",
  InstanceBuilder = "InstanceBuilder",
  FilterMenu = "FilterMenu"
}
const Stack = createStackNavigator();
export const ModelNavigator = ({ modelPlugin }: { modelPlugin: ModelPlugin }) => {

  return (
    <Stack.Navigator initialRouteName={ModelRoute.ModelList} screenOptions={{ animationEnabled: true }} {...{ headerMode: "none" }}>
      {/* CONSIDER:  I'm not a big fan of coupling the navigation system with most of the componenets.  Instead, pass with props? hmm... */}
      <Stack.Screen name={ModelRoute.ModelEditor} component={({ route, navigation }) => <EntityEditor modelSDK={modelPlugin.modelSdk} noun={route?.params?.noun} />} />
      <Stack.Screen name={ModelRoute.ModelList} component={({ route, navigation }) => <ModelList modelPlugin={modelPlugin} />} />

      {/* TODO-NEXT:  Move the filter logic somewhere near the ROOT of the tree.  This MAY serve as the first Context system.  BUT, consider that we MAY want to further differentiate the filter in multiple contexts, MORE than just global!!!  NEED to be able to attach filters to UNMOUNTED states!  Then, when it IS mounted, we use it mm!  This is like the CSS-like system.  The idea is, it's JUST an encoding hmm */}
      <Stack.Screen name={ModelRoute.InstanceList} component={
        ({ navigation, route }) => {
          const nounId: NounId = { type: EntityType.Noun, nounId: route?.params?.model.id };
          return <InstanceList nounId={nounId} />
        }
      }
      />


      <Stack.Screen children={
        ({ navigation, route }) => {

          const FilterMenu = () => {

            const context = React.useContext(AppContext);
            const entitiesPlugin = React.useContext(ModelPluginContext);

            const instances = (route?.params as any).instances;
            const { settings, setSettings } = React.useContext(FilterContext);
            // const setSettings = (route?.params as any).setSettings;  //  NOTE:  This is used to set the Filter Settings in the calling component.

            // const entitiesPlugin = Program.haliaStack?.getPlugin("entities").plugin as ModelPlugin | undefined;
            const [visible, setVisible] = React.useState(true);

            //  CONSIDER:  Instead of doing this here, just register an OBJET with a name and stuff.. hmm
            const FilterRenderer = entitiesPlugin ? entitiesPlugin.FilterRenderer : () => null;

            // const FilterRenderer = (...params) => { return <Text>SHOULD BE FILTER RENDERER</Text> }

            // console.log(FilterRenderer);
            return (
              <CardModal visible={visible} onCancel={() => navigation.goBack()} style={{ backgroundColor: '#eeeeee' }}>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <View style={{ height: 40, borderRadius: 20, borderColor: "#aaaaaa", borderWidth: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton size={50} iconType="material" iconName="add" color="#aaaaaa" selected={false} onPress={() => null} />
                  </View>
                </View>
                <View style={{ height: smallSpacer }} />
                <ScrollView showsVerticalScrollIndicator={false}>
                  <FilterRenderer instances={instances} settings={settings} setSettings={(settings) => { setSettings(settings); setVisible(false); }} />
                </ScrollView>
              </CardModal>
            )
          }
          return <FilterMenu />

        }
      } name={ModelRoute.FilterMenu} />

      <Stack.Screen name={ModelRoute.InstanceBuilder} children={
        ({ navigation, route }) => {
          const skipOverrides: boolean = (route?.params as any)?.skipOverrides;
          const systemEditMode: boolean = (route?.params as any)?.systemEditMode;
          const noun: NounInternal = (route?.params as any)?.noun;
          const instance: InstanceInternal = (route?.params as any)?.instance;
          //  TODO:  Rename to "InstanceEditor" for BOTH create / edit instead of "InstanceCreator"?  OR perhaps "InstanceBuilder"???
          const nounId: NounId = { type: EntityType.Noun, nounId: noun ? noun.id : instance.nounId };
          return (
            <InstanceCreator modelSDK={modelPlugin.modelSdk} skipOverrides={skipOverrides} systemEditMode={systemEditMode} nounId={nounId} instanceId={instance ? { type: EntityType.Instance, nounId: instance.nounId, instanceId: instance.id } : undefined} />
          );
        }
      } />
    </Stack.Navigator>
  );
}


// export const ModelEditor = ({ modelPlugin, model }: { modelPlugin: ModelPlugin, model?: NounInternal }) => {

//   // const modelNoun: ENoun<Model> = route?.params?.model;
//   // const [modelId, modelModelId] = React.useState<string | undefined>(modelNoun.id || undefined);
//   const [modelNoun, setModelNoun] = React.useState<HaborNounInternal | undefined>(model);
//   // const [newEntityId, modelNewEntityId] = React.useState<string>("");

//   return (
//     <ScrollView style={{ marginVertical: 100 }}>

//       {/* <Button title="Delete" onPress={ () => modelService.delete(modelNoun.id) } /> */}

//       <EntityEditor modelSDK={modelPlugin.modelSdk} noun={modelNoun} />
//       {/* <DavelForm  value={ modelNoun?.payload } schema={ nounSerializedSchema } onSubmit={ async (model) => { const modelNoun = await modelService.create(model); setModelNoun(modelNoun); } } /> */}

//     </ScrollView>
//   );
// }

//
//  TODOs
//

//  NOTE:  THIS should be a COUPLING between FILTER and ENTITY!  Interestingly though, they can BOTH stand alone.  This is extending.. both though? Hmm... MAYBE that's why it's not totally clear which one it should be nested under AND an argument CAN be made for both!!!  THAT'S why we use HALIA to show that.  We COULD try to infer this from the JS Module dependency cache map thing.  But, it's relatively stable and stuff? Hmm After program execution HM!  FOR NOW, let's just leave it hear I guess?
//  TODO-TECH-DEBT:  MOVE the "Hessia Register" stuff to another Plugin!  It's being COUPLED here!!

export interface RegisterComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal;
    style: ViewStyle;
  }
}

interface RegisterComponentPrimitiveState {
  components: HaborComponent[];
  blocks: UserBlock[];
}

//  CONSIDER:  This is JUST for the "InstanceDetail" register.. SO!  THIS should probably stay here!  OR be move and abstracted in some other way, BUT the coupling of PROPS to this MAY be importatn?  This sets an INTERFACE for the componetns registered to this Register!  Hmm... Again, MAYBE we can use CONTEXT???  OR perhaps let the components individually choose what props to pull from available pool of stuff??
//  NOTE:  We SHOULD be able to use the same primitive logic for ANY register? hmm MAYBE make it possible to inject with a Block or perhaps a Widget... MAYBE they are one in the same?  I THINK Widget is higher level.
class InstanceDetailRegisterRendererPrimitive extends React.Component<RegisterComponentPrimitiveProps, RegisterComponentPrimitiveState> {

  //  CONSIDER:  FOR NOW, I'm getting the "instance" through props, BUT we COULD make a global "context" setting that we can use to set this!?

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: RegisterComponentPrimitiveProps) {
    super(props);
    this.state = {
      components: [],
      blocks: []
    }
  }

  public componentDidMount = async () => {

    const { token } = this.context.frameworkContext;

    //  TODO-TS:  Type these.
    //  Hmmm... so we want to register a component with the Detail View which will be used to render the Detail View Register.  Ok!  BUT, perhaps instead of using a HABOR Component as the thing, we can instead just use a regular component.  FOR NOW, we'll register this from the Entity Component.
    //  TODO-GENERAL:  This is just how we're rendiner the Instance Detail Register, BUT there may be MANY registers!  SO!  We should be able to standardize a LOT of this!?  I THINK we should look into serverside rendering???  Hmmm... BUT it's also nice to have stuff done on EACH user's device to off-load processing?  Hmm...  I DO want more standardized shit all around!  Like... primitive SDKs as needed, BUT I want to get away from Primitive Code too!  AND maybe GraphQL-like interactions where we can select "fields" across systems, and perhaps a deeper query language that automatically resolves across systems, and perhaps the Action system, CSS-like system, etc!! SO many things I'd like to standardize, BUT we're getting there!!!

    //  CONSIDER:  Instead of going to Habor and getting the stuff explicilty with the search commands and stuff, SHOULD be able to build encodings (functions) in the new systems that we can REFERENCE (sometimes invoke inline) to do things we want!  ALSO, sometimes, instead of a "function" it's more like a declarative ting hm
    //  Get the Register
    const detailViewRegister = (await haborSDK.searchInstances<any>(token, { nounId: "register", search: { match: { payload: { name: "Instance Detail Register" } } } }))[0];

    //  Get the Register Enablement Relationships
    const registerEnablements = await haborSDK.searchInstances<Relationship>(token, { nounId: "register-enablement", search: { match: { payload: { destId: { instanceId: detailViewRegister.id } } } } });

    //  Get the Associated Components
    //  TODO-NEXT:  Split search between Components and Blocks.... AHH!!  This SHOULD BE AUTOMATIC with the Django-like EXPRESSION system!?? Damn.. SHOULD be built in a SUPER generic way ONCE WITH the option to EXTEND THOUGH!!!  That way other systems can register THEIR interpreters and elements, etc... MUCH like the Drawing / Magnet to APp system AND the speech to app system / speech / drawing to WHATEVER else!!
    const componentSearchTerms = registerEnablements.filter(enablement => enablement.payload.srcId.nounId === SerializedHaborComponentNoun.id).map((enablement: any) => ({ match: { id: enablement.payload.srcId.instanceId } }));
    const serComponents = await haborSDK.searchInstances(token, { nounId: SerializedHaborComponentNoun.id, search: { any: componentSearchTerms } });
    const components = serComponents.map(serComp => deserializeHaborComponent(serComp.payload));

    const blockSearchTerms = registerEnablements.filter(enablement => enablement.payload.srcId.nounId === serializedUserBlockNoun.id).map((enablement: any) => ({ match: { id: enablement.payload.srcId.instanceId } }));
    const serBlocks = await haborSDK.searchInstances(token, { nounId: serializedUserBlockNoun.id, search: { any: blockSearchTerms } });
    const blocks = serBlocks.map(serBlock => deserializeUserBlock(serBlock.payload));

    //  TODO:  PERHAPS don't hard-code these types and INSTEAD support TYPE registartion as well!? HMM!?? Currently we have Components and Blocks, but PERHAPS we can support CUSTOM "renderers" here as well!???

    this.setState({ components, blocks });
  }

  public render = () => {

    const { userProps, frameworkProps } = this.props;

    //  Unpack
    //  TODO:  I'm NOT sure we'll be able to get proper component context from the React Component Context?  UNLESS we regularly change the React Component Context?  We want to be able to see our actual "Habor Component" or "Block" parent for the CSS-like system!?
    const { frameworkContext, componentContext } = this.context;

    const { components, blocks } = this.state;
    const { style, instance } = userProps;

    const registerComopnentProps = { instance };

    return (
      // CONSIDER:  Enabling OPTIONS to let the USER choose how the register is displayed in a context.. MAYBE as an open list, maybe as an accordian list, maybe as separate tabs, etc!?  BUT, consdier a GENERAL syst4em for this!??? Hmmmmmmmm... WHAT should be exposed for other systems and wht should NOT?  I FEEL just about ANYTHING should be possible, BUT we typically use / STANDARDIZE a more nuanced subset of possible functionaliy.
      <WidgetItem name="Habor Instance Detail Register (Model Plugin)" color="#53e6b0">

        <HaborContainer frameworkProps={frameworkProps} style={{ flex: 1, display: 'flex', flexDirection: 'row', ...style }}>
          <>
            {/* CONSIDER:  Consider a collapsable component to hde / show the widgets?? */}
            {
              components.map(comp => {
                return (
                  <WidgetItem name={comp.name} color={"#884be3"}>
                    <HaborComponentViewer componentProps={registerComopnentProps} frameworkContext={frameworkContext} componentContext={componentContext} component={comp} handleClose={() => null} />
                  </WidgetItem>
                )
              })
            }
            {/* CONCERN:  Blocks and Componetns should be ORDERED and propritieid in the SAME system instead of components first! */}
            {
              blocks.map(block => {
                return (
                  <DynamicWidgetItem props={{ style: { marginBottom: medSpacer } }} parts={{ namedObject: { name: "Test", color: { r: 30, g: 30, b: 30 } } }}>
                    <Text>SHOULD BE HABOR COMPONENT RENDERER??</Text>
                    {/* <HaborComponentNewRenderer componentContext={ componentContext } frameworkContext={ frameworkContext } instance={ instance } component={ block } setOutput={ () => null } /> */}
                  </DynamicWidgetItem>
                )
              })
            }
          </>
        </HaborContainer>
      </WidgetItem>
    );
  }
}

export const InstanceDetailRegisterRenderer: HaborComponent = {
  name: "InstanceDetailRegisterRenderer",
  propsSchema: {
    type: "object",
    extensible: true,
    properties: {
      instance: { ...serializedInstanceInternalSchema, required: false },
      //  TODO-CRITICAL:  The "any" type doesn't seem to work when "extensible: false"!  Maybe it's not properly being recognized??
      //  TODO-TS:  Type this for Hessia!!?  
      style: { type: "object", extensible: true },
    }
  },
  element: {
    name: "InstanceDetailRegisterRendererPrimitive",
    props: {
      instance: { type: "symbol", scopePath: ["props", "instance"] },
      style: { type: "symbol", scopePath: ["props", "style"] }  //  NOTE:  This was the ORIGINAL plan for mapping between Hessia and React Native.  BUT, Blocks is the successor, and it's MUCH more general I believe!  I'd like to switch MOST work to that soon!!!
    },
    children: []
  }
};


//  TODO-GENERAL:  Figure out how to generalize this between BOTH "Backend", "Frontend", and "SDK".  PERHAPS by "server-side" rendering?  OR just do it on the client?  BRIDGE the gap to make them one and the same???

//  NOTE:  EACH filter can be passed "settings", the type of which should be known by the instantiator.
//  INSIGHT:  VERY intereseting!  In this case, it isn't NECESSARY to know ALL the filter types!  We just need to be sure the instantiator selects a propr type for the filter!??

export const ModelPluginContext = React.createContext<ModelPlugin>({} as ModelPlugin);


export enum ModelPluginEvents {
  Initialized = "Initialized"
}

export class ModelPlugin extends CorePluginClass {

  //  TODO:  Instead of reaching to global React Context, we SHOLD be forced to DEPEND upon a thing and then use it in the things in the plugin...


  public static details = {
    name: "PrimitiveModels",
    description: "Models Primitive System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id, HessiaPlugin.details.id, ComponentPlugin.details.id],
    id: "modelPlugin"
  }

  //  TODO:  We DO eventually want to SCOPE the filter stuff to an entity or MORE GENERALLY, SOME sort of condition or "Context Mask", etc...
  public filters: { name: string, filter: Filter<any> }[] = [];
  public filterComponents: { name: string; component: typeof FilterComponent | React.FunctionComponent<FilterComponentProps> }[] = [];

  //  Primitive Page Overrides
  public createPages: { [entityId: string]: any } = {};

  //  NOTE:  I LOVE how this is a PIECE of functionality.. its a self-contained system.. WHY shold iit NOT be injected???/k sdafj ... the SYSETM has MULTIPOLE pieces spread throughout!
  private addonHandlers: AddonHandler[] = [];

  public registerAddonHandler = (addonHandler: AddonHandler) => {
    this.addonHandlers.push(addonHandler);
  }

  public getHandlers = () => {
    return this.addonHandlers;
  }

  //  TODO:  Make setters for various pages.
  //  TODO:  Build this in with the CONTEXT system!  This way, this turns into ONE piece of context and the chosen view is based on other thigns as well?  Hmm.. MAYBE this is how the context system should worrk though? hmm  THe idea is LOTS of systems that all work together and the context is passed? Hmmmm...
  //  TODO:  BUT, we may have different create pages for different contexts!  NOT just the one! HM!  So... interesting.  FOR NOW, we can do per Entity, BUT I think we can move to doing it within a CONTEXT.. MAYBE most of the operations can be SCOPED to a context HM!  THis way we wet up for THAT context? HM!  MAYBE this is the default tho? HM!
  //  CONSIDER:  There's also a difference between doing it in JS like this and doing it in Hessia which is a SYSTEM written in JS, which, instead of being expressed in JS, is exprressed in app-level object forrmat and INTERPRETED by a JS cinterpreter running the system! HM!

  //  TODO:  SHOULD these getterrs / setters be a responsibility of this Plugin OR pehaps a dependent Plugin? HM!
  public getCreatePage = (entityId: string) => {
    return this.createPages[entityId];
  }

  //  CONSIDER:  Should Page should be separrate??  hmm... this is the context newtork / if / conditio network / CSS-like thing hm!
  public setCreatePage = (entityId: string, component: any) => {
    this.createPages[entityId] = component;
  }

  public filterChangeHandlers: (() => void)[] = [];
  public onFilterChange = (handler: () => void) => {
    this.filterChangeHandlers.push(handler);
  }

  private fireFilterChange = () => {
    this.filterChangeHandlers.forEach(handler => handler());
  }

  public registerDetailComponent = (CustomComponent: typeof React.Component | React.FunctionComponent<any>) => {
    this.component.registerPrimitiveComponent("instance-creator-bottom", (props) => <View style={{ marginVertical: 15 }}><CustomComponent {...props} /></View>);
  }

  protected component!: ComponentPlugin;

  public systemName = "models-system";

  //  CONSIDER:  How do we handle the case of Plugins which need some additional configuration before they can be used... Like in this case, we need the TOKEN.  hm!  MAYBE we can DEPEND not ONLY on a "Plugin" being "installed", but we can ALSO depend on ANY other state hm!  MAYBE we can split a plugin into pieces and let one depend upon another like sub-plugins?? HM!

  public modelSdk!: ModelSDK;
  public modelService!: HaborModelService;

  private eventEmitter: EventEmitter = new EventEmitter();
  private initialized = false;

  //  TODO:  This pattern of LISTENING to a piece of state IF it's defined or IN a particular state is a COMMON pattern that I DO want to abstract!!!  Should be able to register a VARIABLE hmmm... 

  public onInitialized = (callback) => {
    if (this.initialized) {
      callback();
    } else {
      this.eventEmitter.addListener(ModelPluginEvents.Initialized, callback);
    }
  }


  //  TODO:  Maybe I want more of a declarative filter that I can combine to make a QUERY instead of explicitly filtering through the WHOLE list???  THIS WON'T scale to LOTS of items!?  We DON'T want to get ALL the elements THEN apply this.. BUT, I think it's OK FOR NOW!!!
  //  TODO:  Remove this from Entity Manager
  //  TODO:  Support a generic "Context Mask" for UI / data context / conditions!?
  /**
   * A "Filter" is a function which filters a set of objects.
   * @param filter
   */
  public registerFilter = (name: string, filter: Filter<any>) => {
    this.filters.push({ name, filter });
  }

  //  TODO-GENERAL:  We seem to pass token a lot, but that's just ONE system!  We MAY have OTHER context!?  This IS setting the context in the Identity system!?s
  public processFilters = async (instances: InstanceInternal<any>[], token: string, settings: { [name: string]: any } = {}, noun: NounInternal): Promise<InstanceInternal<any>[]> => {
    //  CONCERN:  WHERE does the filter get its OPTIONS?  I THINK this is another process, where we can add registration stuff to change primitive settnigs for the filters owned by EACH system (FOR NOW!)  So... BASICALLY, just inject UI for that filter!??  FOR NOW, I'm doing all this with PRIMITIVE code, but I DO want to add support for USER code!
    const filters = this.filters;
    if (!filters) { return instances; }
    //  NOTE:  I process in registration order.  Shouldn't matter, because the filters should not depend upon the elements in the list, and should be 'idempotent'?  Hmm... I THINK that concept might apply here... element stays the same after being operated upon?  So.. in other words, IF we pass the result set bck through it's ALWAYS the same???  PLUS, I suppose I can say order shouldn't matter by definition??  Hmm... not sure that's QUITE the way I want to say it?
    let updatedInstSet: InstanceInternal<any>[] = instances;
    for (const filter of filters) {
      const filterSettings = settings[filter?.name];
      updatedInstSet = await filter.filter(updatedInstSet, token, filterSettings, noun);
    }
    return updatedInstSet;
  }

  //  NOTE:  A "filter config component" is a component which controls elements of the filtering process for the registered system.  We leave it up to the systems to ensure the components are updating relevant state.. I THINK it MAY reference the Plugin method? Hmmm... or, yeah, I'm not 100% sure on that... SHOULD our Plugins / Systems ALSO be mounted components so they can be involved in the reactive state tree???
  //  TODO:  Consdier ASSOCIATING the SYSTEM that's registering these pieces, INCLDING the filter itself???  Hmmm... MAYBE we can group this as a package?  OR, maybe it's OK to keep it separate???  Ugh...  I guess EITHER works, but one requires more knowledge of the inerface where the other keeps things contained?  One is more piecemeal?  Can we combine to get the best of BOTH?  Progressive interfacing??? Hmm!!!?? 
  /**
   * A "Filter Component" is the visual representation of a filter and controls settings used by the corresponding Filter function.
   * @param filterComponent 
   */
  public registerFilterComponent = (name: string, filterComponent: typeof FilterComponent | React.FunctionComponent<FilterComponentProps>) => {
    //  NOTE:  FOR NOW, I suppose we'll keep them unorganized.. just rendered by order of registration?
    const filterComponents = this.filterComponents;
    filterComponents.push({ name, component: filterComponent });
  }

  public FilterRenderer = ({ instances, settings = {}, setSettings: setCallerSettings }: { instances: any, settings: any, setSettings: (settings: any) => void }) => {
    const filterComponents = this.filterComponents;

    //  Create the State
    // const [filterState, setFilterState] = React.useState<any>({});
    // React.useEffect(() => {
    //   filterComponents.forEach(comp => setFilterState({ ...filterState, [comp.name]: undefined }));
    // })

    //  THOUGHT:  This MAY be a COMMON pattern!  Where we have a STATE that we want to keep which we ALSO want to be able to pass through other functions... hmm...
    //            ACTUALLY though, I'm changing my mind about this entire thing!  It think each FilterComonent SHOULD hve an interface in sync with the Filter, BUT it should ALSO report its state through a function.  Then, it's the parent component responsibilility to PIPE that to the filter? hmmmmm  In this case, ON CLOSE we can fire it, BUT we MAY also want to run filters in the modal, MAYBE it's just a BACKGROUDN data thing we can add to the tree???Hm!  THEN we can USE it in several views? HMM INTERESTING model!  

    const setSettings = (name: string, filterSettings: any) => {
      const newSettings = { ...settings, [name]: filterSettings };
      setCallerSettings(newSettings);
      console.log(newSettings);
    }

    return (
      //  NOTE:  Here we're rendering a Filter.  So, what does this mean?
      <React.Fragment>
        {/* TODO-TS:  Fix any */}
        {filterComponents.map((comp) => {
          const FilterComponent: any = comp.component;
          return (
            <React.Fragment>
              {/* TODO:  Is it OK to pass 'this' directly?  SEEMS like it may have stale state?  WON'T update unless we expliclty update the FilterRenderer thing.. which happens often.. so maybe it's ok, idk though... */}
              {/* <Text>SHUOLD BE FILTER COMPONENT</Text> */}
              <FilterComponent entitiesPlugin={this} instances={instances} settings={settings[comp.name]} setSettings={(settings) => setSettings(comp.name, settings)} />
              <View style={{ height: smallSpacer }} />
            </React.Fragment>
          )
        })}
      </React.Fragment>
    );
  }

  //  TODO:  If I mistype the name of a dependency or I forget to return "this", then things break.  I'd like perhaps build a framework to enforce these thigns... hmm...
  public install = (program: Program, { system, pEntities, authPlugin, hessia, component }: { system: SystemPlugin, pEntities: ModelPlugin, authPlugin: AuthPlugin, hessia: HessiaPlugin, component: ComponentPlugin }) => {

    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {
    //   const model = await modelService.retrieveByEntityId(entityId);
    //   if (model) {
    //     api.setText(model.payload.name);
    //     api.addComponent(() => {
    //       const navigation = useNavigation();
    //       return (
    //         <Button title="Instances" onPress={ () => navigation.navigate("Instances", { nounId: { nounId: model.id } as NounId, navigation }) } />
    //       );
    //     });
    //   }
    // });

    authPlugin.onLogin(() => {

      this.modelSdk = new HaborModelSDK(haborSDK, authPlugin.token);
      this.modelService = new HaborModelService(haborSDK, authPlugin.token);

      hessia.registerHOC(FilterProvider);

      system.registerPrimitiveSystem({
        id: this.systemName,
        name: "Model",
        description: "Models Primitive System",
        labels: ["primitive"],
        component: () => <ModelNavigator modelPlugin={this} />,
        menuItem: {
          icon: { name: "box", type: "feather" },
          backgroundColor: "#26DEAA",
          iconColor: "white"
        }
      });

      this.initialized = true;
      this.eventEmitter.emit(ModelPluginEvents.Initialized);
    });

    this.component = component;


    //  Register Context
    hessia.registerHOC(({ children }) => {
      return (
        <ModelPluginContext.Provider value={this}>
          {children}
        </ModelPluginContext.Provider>
      );
    })

    component.createRegister({ name: "instance-list-item-below", description: "Renders items below the Instance List Item", haborComponents: [], primitiveComponents: [], propType: { type: "any" } });

    //  Instance

    hessia.registerPrimitiveComponent('InstanceListHaborPrimitive', InstanceListHaborPrimitive);
    hessia.registerComponent(InstanceListHaborComponent);

    hessia.registerPrimitiveComponent('InstanceEditorHaborPrimitive', InstanceEditorHaborPrimitive);
    hessia.registerComponent(InstanceEditorHaborComponent);

    hessia.registerPrimitiveComponent('InstanceCreatorHaborPrimitive', InstanceCreatorHaborPrimitive);
    hessia.registerComponent(InstanceCreatorHaborComponent);

    //  Entity

    hessia.registerPrimitiveComponent('EntityListHaborPrimitive', EntityListHaborPrimitive);
    hessia.registerComponent(EntityListHaborComponent);

    hessia.registerPrimitiveComponent('EntityEditorHaborPrimitive', EntityEditorHaborPrimitive);
    hessia.registerComponent(EntityEditorHaborComponent);

    hessia.registerPrimitiveComponent('EntityCreatorHaborPrimitive', EntityCreatorHaborPrimitive);
    hessia.registerComponent(EntityCreatorHaborComponent);

    //  Injections

    hessia.registerPrimitiveComponent("MainInstanceListItemHaborComponentPrimitive", MainInstanceListItemHaborComponentPrimitive);
    hessia.registerComponent(MainInstanceListItemHaborComponent);

    hessia.registerPrimitiveComponent("InstanceDetailRegisterRendererPrimitive", InstanceDetailRegisterRendererPrimitive);
    hessia.registerComponent(InstanceDetailRegisterRenderer);

    //  CONSIDER:  NOT sure I really like this have presense in that lower abstraciton hmm I think it's ok! BUT want to LINK it to the higher hmm!
    component.createRegister({ name: "instance-creator-bottom", description: "Renders items below the Instance Creator", haborComponents: [], primitiveComponents: [], propType: { type: "any" } });
    component.registerHaborComponent("instance-creator-bottom", InstanceDetailRegisterRenderer);


    //  TODO:  Integrate for Entity

    //  TODO-NEXT:  Finish the filter work for Diet / Date.  Looks like we need to work on where to set the settings.

    //  CONCERN:  Are filter settings JUST for the field filters?? Hmm... maybe that's a concept INTERNAL to the class system?  Perhaps though we can re-use???
    //  NOTE:  It SEEMS like this is specificit to Class or Object.  I believe it's a system that INTERPRETS another injection point for the fields... hmmm.

    //  TODO:  We currenlty have TWO systems to stop props hmm... we can store it in ONE tree, or we can store it separately like we do now.. ugh.

    this.registerFilter("instance", async (instances, token, settings = {}, noun) => {

      let filteredInstances: InstanceInternal[] = instances;
      for (const propName in settings) {
        const propSettings = settings[propName];
        const properties = noun?.properties || {};
        const propSDT = properties[propName]?.type;
        if (!propSDT) { continue; }
        const propTypeName = propSDT?.type;
        const filter = propTypeName ? typeToFilterMap[propTypeName] : undefined;
        if (!filter) { continue; }
        filteredInstances = await filter(instances, token, propSettings, noun, propName);
      }
      return filteredInstances;
    });

    this.registerFilterComponent("instance", InstanceFilterComponent);

    return this;


  }
}

export const withModels = (Comp: any) => {
  return (props: any) => {
    const entitiesPlugin = React.useContext(ModelPluginContext);
    return <Comp {...props} entitiesPlugin={entitiesPlugin} />
  }
}