import { deserializeUserBlock, HaborComponent, HDTRelationshipValue, InstanceInternal, NamedObject, NounInternal, Relationship, SerializedUserBlock, UserBlock } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { Card } from '../../../../packages/kelp-bar/card';
import { RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { HAppRoute } from '../../apps-plugin/apps.nav.routes';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { BlockRenderer } from '../../composer-plugin/composer/components/component-renderer';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { InstanceListSimpleItem } from '../../model-plugins/named-object-plugin/named-object-list-simple';
import { RelationshipData } from '../entity/instance-list';
import { RelationshipListItem } from '../components/relationship-list-item';

//
//  InstanceListItem Primitive Component
//

//  THOUGHT:  Look at this name... it's crazy.  It's several pieces:  "Main" is temporary and distinguishes it from the other "NamedObject" list view (which I didn't namespace).  "Instance" tells us that this view is specific to an "Instance".  "ListItem" tells us that this is a component for an item in a list.  "HaborComponent" indicates that it's a HaborComponent.  "Primitive" indicates that it's specifically a PRIMITIVE, and "Props" indicates that it's a type to be used as props.  SO... we COULD consider breaking all of this up... instead of individually named things, just add ONE element to the "InstanceListViewComponents" thing, and within that, add our props?  In other words... do this stuff in a nested hierarchy instead of flat!  Maybe that's PART of what Habor / Hessia will give us?
export interface MainInstanceListItemHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal<any>;
    noun: NounInternal;
    relData: RelationshipData;
  }
}

//  TODO:  This should be renamed to represent the fact it's a Map
//  TODO:  Move ALL this stuff to the Entity Manager Plugin!
export interface InstanceListComponent {
  Noun: string;
  Block: HDTRelationshipValue;
}


//  TODO:  We CAN generralize this WHOEL thing!!!  So we have REGISTERED shit that matches a prop mask ND perhspa defalt primitive stuff?

export interface MainInstanceListItemHaborComponentPrimitiveProps {}
interface MainInstanceListItemHaborComponentPrimitiveState {
  block?: UserBlock | null;
}
export class MainInstanceListItemHaborComponentPrimitive extends React.Component<MainInstanceListItemHaborComponentPrimitiveProps, MainInstanceListItemHaborComponentPrimitiveState> {

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: MainInstanceListItemHaborComponentPrimitiveProps) {
    super(props);
    this.state = {}
  }

  public componentDidMount = async () => {

    const { token } = this.context.frameworkContext;
    const { userProps: { noun } } = this.props;

    //  Check for a Registered Block
    const listItems = await haborSDK.searchInstances<InstanceListComponent>(token, { nounId: 'instance_list_component' });

    console.log("GOT LIST ITEMS #1");
    console.log(listItems);

    //  Find the Right One
    //  TODO:  SUppoer inherritance, BUT first de-couple THESE systems (dynamic component selection patterrn / Entity Manger, AND inject Class Inheritance support with the Eneity Manager system? HM!)
    const listItem = listItems.find(listItem => listItem.payload.Noun === noun.id);

    if (!listItem) { this.setState({ block: null }); return; }

    console.log("GOT LIST ITEM");
    console.log(listItem);

    //  Get the Block
    //  TODO-GENERAL:  In ALL these casese, don't hard-code!  Get the owning Plugin with Halia and reference the ID THAT way!
    const blocks = await haborSDK.searchInstances<SerializedUserBlock>(token, { nounId: 'serialized-userblock', search: { match: { id: listItem?.payload.Block.instanceIdList[0].instanceId } } })

    console.log("GOT BLOCKS");
    console.log(blocks);

    if (!blocks[0]) { throw `The specified block was not found`; }

    const block = deserializeUserBlock(blocks[0].payload);

    console.log("GOT BLOCK");
    console.log(block);

    this.setState({ block });
  }

  public render = () => {

    const { userProps, frameworkProps, navigation } = this.props;
    const { block } = this.state;

    //  Unpack
    const { componentContext, context } = frameworkProps;

    //  NOTE:  We currently only render if it's a sub-class of "relationship" or "namedobject".  To generalize, we attach renderers to each class, and we fall-back.  I DO believe this is how I was planning to do it in TrackMine... ugh.

    const renderers = {
      // NOTE:  This is an example of a differention hM!
      // TODO:  Decouple the HApp!
      "relationship": <RelationshipListItem onPress={ () => navigation.navigate(HAppRoute.InstanceBuilder, { noun: userProps.noun, instance: userProps.instance }) } registers={{ top: [], left: [], right: [] }} item={ (userProps.instance as InstanceInternal<Relationship>) } />,
      "namedobject": <InstanceListSimpleItem onPress={ () => navigation.navigate(HAppRoute.InstanceBuilder, { noun: userProps.noun, instance: userProps.instance }) } registers={{ top: [], left: [], right: [] }} item={ (userProps.instance as InstanceInternal<NamedObject>) } />,
    }

    //  TODO:  Separate BLOCK logic and add that as a DIFFERENTIAITON when that PLUGIN is instqlled HMM!

    
    //  TODO: THis is SOOO messy!!! Instead, I'd like to map from class to component and fallbacka nd stuff, ONT just this simple FLAT thing hm!
    const defaultRenderer = <>
      <Text>Default Renderer:  "instance-list-item.tsx" is not currently iterating parentclasses!</Text>
      <Text>{ JSON.stringify(userProps.instance) }</Text>;
      </>;

    let didRender = false;

    //  TODO:  Make inheitance work!!!  This is why it's not showing@!
    const elements = Object.keys(renderers).map(className => {
      if (userProps.noun.inherits?.indexOf(className) !== -1) {
        didRender = true;
        return renderers[className]
      }
      return null;
    });

    if (!didRender) {
      elements.push(defaultRenderer);
    }
    
    return (
      <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        {/* <DefaultInstanceView { ...userProps } componentContext={ componentContext } frameworkContext={ context } /> */}
        {/* TODO:  Show something ELSE when we don't meet "NamedObject" criteria!? */}
        {/* TODO:  REALLY want to generalize this process to clean this up... this is where we mak a selection.. though PERHAPS it can happen HIGHER at the LIST level, OR maybe the UI that USES this component can be the responsible party? HM! */}
        {
          //  TODO:  SHOULD look down the ENTIRE inheritance tree!  REMEMBER too, inheritance only exists because a system INTERPRETS those symbols and assigns meaning!  It doesn't have to be "properties", we can inherit based on other stuff too? Hmmm
          //  TODO:  This is just a VIEW and we have a view OPTION injected from the Class system which I'd like to let the user be able to configure to choose what things are shown in the mini view!?  Like NamedObject AND Relationship, etc.. hmm... I AM thinking that I'd like these to be separate systems ASSOCIATED with it though.. hmm  Maybe this is still ok for now.  BUT, the elastic DB has everything in it I think.. which I'm not crazy about...
          block ?
            <BlockRenderer componentContext={ componentContext } frameworkContext={ this.context.frameworkContext } component={ block } input={{ }} setOutput={ () => null } /> :
            <Card outerStyle={{ flex: 1 }} raisedHeight={RaisedHeight.high} innerStyle={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
              {
                elements
              }
            </Card>

        }
      </HaborContainer>
    );
  }
}


// export const MainInstanceListItemHaborComponentPrimitive = function(props: any) {
//   const navigation = useNavigation();
//   return <MainInstanceList {...props} navigation={navigation} />;
// }


export const MainInstanceListItemHaborComponent: HaborComponent = {
  name: "MainInstanceListItemHaborComponent",
  propsSchema: { type: "object", extensible: true },  //  TODO:  Type this!
  classes: ["named-object"],
  element: {
    name: "MainInstanceListItemHaborComponentPrimitive",
    props: {
      instance: { type: "symbol", scopePath: ["props", "instance"] },
      noun: { type: "symbol", scopePath: ["props", "noun"] },
      relData: { type: "symbol", scopePath: ["props", "relData"] }
    },
    children: []
  }
};
