import { HaborSDK } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { haborSDK } from "../hessia-plugin/config";

export class HaborPlugin extends CorePluginClass {

  public static details = {
    name: "Habor",
    description: "Habor SDK",
    dependencies: [],
    id: "habor"
  }

  public haborSDK!: HaborSDK;

  public install = (program: Program, imports: any) => {

    this.haborSDK = haborSDK;

    return this;  //  TODO:  When using a Class as a plugin, make the default export the class instance.
  }
}
