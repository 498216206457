import { HaborIconSelection, InstanceInternal, NamedObject, NounInternal, RGBAColor } from 'habor-sdk';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { HaborElement } from '../../entities-apps-plugin/element-widget.component';
import { Card } from '../../../../packages/kelp-bar/card';
import { RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { isNoun } from '../../habor-plugin/habor-utils';
import { ReactRegisterProps } from '../../../../packages/registers/react-registers';

//
//  TODO-IMPORTANT:  This file is being used JUST for the "Workspace" system!  Eventually, port the Workspace, static system to the other dynamic objects we're using throughout the app.
//

export interface InstanceListSimpleItemProps<T extends NamedObject> extends ReactRegisterProps {
  item: InstanceInternal<T> | NounInternal;
  onPress?: (item: InstanceInternal<T> | NounInternal) => void;
  onLongPress?: (item: InstanceInternal<T> | NounInternal) => void;
  //  CONSIDER:  MAY want to be explicit about the API we're implementing!? HM!  MAYBE compnent ypes and stuff.. again.. just associations and symbols!  That's ALL any of this is!? HM!  Plus encoders / decoders? HM!
  registers: {
    //  NOTE:  This COULD be typed!
    left: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
    top: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
    right: (React.ComponentClass<{item: InstanceInternal<T> | NounInternal}> | React.FunctionComponent<{item: InstanceInternal<T> | NounInternal}>)[];
  }
}

export class InstanceListSimpleItem<T extends NamedObject> extends React.Component<InstanceListSimpleItemProps<T>, any> {
  public render = () => {

    //  Unpack
    const { item, onLongPress = () => null, onPress = () => null, registers: { left, top, right } } = this.props;
    let name: string;
    let description: string | undefined;
    let icon: HaborIconSelection | undefined;
    let color: RGBAColor | undefined;
    if (isNoun(item)) {
      const noun = item as NounInternal;
      name = noun.name;
      description = noun.description;
      icon = { name: "entities", type: "habor" };
      color = { "r": "50", "g": "227", "b": "200" };
    } else {
      const inst = item as InstanceInternal<NamedObject>;
      name = inst.payload.name;
      description = inst.payload.description;
      icon = inst.payload.icon;
      color = inst.payload.color;
    }

    //  TODO:  Get UI information from the Metadata

    // const color: RGBAColor = { r: "255", g: "200", b: "102" };
    // const imageUrl = "test";
    // const materialIcon = "check";

    const renderedColor = renderRGBAColor(color || { r: "255", g: "200", b: "102" });

    return (
      <TouchableOpacity style={{ display: 'flex', flexDirection: 'column', padding: 0, height: 100 }} onLongPress={() => onLongPress(item)} onPress={() => onPress(item)}>
        <View style={{ flexGrow: 0 }}>
          {
            //  TODO:  FOR NOW, it's the devs responsibility to map the props into the registered components.  In the future, and in Hessia.  MAYBE the framework can do this at runtime basedo on a props map, OR maybe it makes sense to continue doing it manually?
            top && top.length ? top.map(Comp => <Comp item={ item } />) : null
          }
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
          <View style={{ flexGrow: 0, width: 20 }} />
          <View>
            {
              //  TODO:  FOR NOW, it's the devs responsibility to map the props into the registered components.  In the future, and in Hessia.  MAYBE the framework can do this at runtime basedo on a props map, OR maybe it makes sense to continue doing it manually?
              left && left.length ? [left.map(Comp => <Comp item={ item } />), <View style={{ flexGrow: 0, width: 20 }} />] : null
            }
          </View>
          {/* TODO:  Convert the selected color into a gradient?? */}
          <KelpIcon name={ icon?.name || 'check' } type={ icon?.type || 'material' } size={33} color={renderedColor} />
          <View style={{ flexGrow: 0, width: 20 }} />
          <View style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Text style={{ color: '#353535', fontFamily: 'Poppins-Bold', fontSize: 18 }}>{name}</Text>
            <Text style={{ color: '#a8a8a8', fontFamily: 'Poppins-Bold', fontSize: 12 }}>{description}</Text>
          </View>
          <View style={{ flexGrow: 0, display: 'flex', flexDirection: 'row' }}>
            {
              //  TODO:  FOR NOW, it's the devs responsibility to map the props into the registered components.  In the future, and in Hessia.  MAYBE the framework can do this at runtime basedo on a props map, OR maybe it makes sense to continue doing it manually?
              right && right.length ? [right.map(Comp => <Comp item={ item } />), <View style={{ flexGrow: 0, width: 20 }} />] : null
            }
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

interface ItemProps {
  onPress?: (elem: HaborElement) => void | Promise<void>;
  onLongPress?: (elem: HaborElement) => void | Promise<void>;
  elem: HaborElement;
}

interface ContainerListSimpleProps {
  onItemPress?: (elem: HaborElement) => void | Promise<void>;
  onItemLongPress?: (elem: HaborElement) => void | Promise<void>;
  elems: HaborElement[];
  //  CONSIDER:  Instead of doing all the style changes with this, CONSIDER composition / more suitable de-coupling?  MAYBE build separate compoennts with similar pieces similar to Vue?
  component?: React.ComponentClass<ItemProps> | React.FunctionComponent<ItemProps>;
}

interface ContainerListSimpleState {
  elems: HaborElement[];
  noun?: NounInternal;
  isLoading: boolean;
}


//  TODO:  FOR NOW, maybe we can select between a few major style? hmm... I THINK, FOR NOW, I'd just like to re-style?
export class ContainerListSimple<T extends NamedObject> extends React.Component<ContainerListSimpleProps, ContainerListSimpleState> {

  constructor(props: ContainerListSimpleProps) {
    super(props);
    this.state = {
      elems: [],
      noun: undefined,
      isLoading: true
    };
  }

  //  TODO:  Support CROSS ENTITY filtering!  This is important for GENERAL Queries
  //  NOTE:  From now on we want to try to keep the QUERY step separate from the RENDERING step for these GENERIC renderers.  For custom renderers, it's not as big an issue.
  // public componentDidMount = async () => {

  //   //  Unpack Props
  //   const { token, user, onItemLongPress, onItemPress } = this.props;

  //   //  Retrieve the Noun
  //   const noun = await haborSDK.retrieveNoun(nounId, token);

  //   //  Retrieve Instances
  //   const items: InstanceInternal<T>[] = await haborSDK.searchInstances(token, { nounId });

  //   //  TODO:  Get style info

  //   //  Update State
  //   this.setState({ noun, items, isLoading: false });
  // }

  public render = () => {

    //  Unpack Props
    const { onItemLongPress, onItemPress, elems, component: Component } = this.props;

    //  Update Types
    //  REFERENCE:  https://github.com/Microsoft/TypeScript/issues/3960#issuecomment-123456256
    //  TODO:  Remove cast to any.
    //  NOTE:  I HAD done:  `class InstanceListSimpleItemT extends InstanceListSimple` BUT, someone made a good point that's inneficient:  https://github.com/Microsoft/TypeScript/issues/3960#issuecomment-226005513
    const TypedInstanceListSimpleItem: new () => InstanceListSimpleItem<T> = InstanceListSimpleItem as any;

    return (
      <FlatGrid
        itemDimension={ 400 }
        data={elems}
        renderItem={
          Component ?
            ({ item }) => (<Component onPress={ onItemPress } onLongPress={ onItemLongPress } elem={item} />) :
            ({ item }) => (<TypedInstanceListSimpleItem registers={{ top: [], left: [], right: [] }} onPress={ onItemPress } onLongPress={ onItemLongPress } item={item} />)
        }
        style={{ padding: 0, margin: -10, overflow: 'visible' }}
      />
    );
  }
}
