
import { CorePluginClass, Program } from "halia";
import { HaborNounService } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { haborSDK } from "../hessia-plugin/config";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { AttachmentPage } from "./attachment-navigator";

//  NOTE:  This is based on the idea of Entity *relation* Entity (SVO) pairings and more specifically, an EAV system.

export class AttachmentPlugin extends CorePluginClass {

  public static details = {
    name: "PrimitiveAttachments",
    description: "Attachments Primitive System",
    dependencies: [SystemPlugin.details.id, AuthPlugin.details.id],
    id: "primitive-attachments"
  }

  public attachmentService = new HaborNounService("attachment", haborSDK);

  public install = (program: Program, { system, authPlugin }: { system: SystemPlugin, authPlugin: AuthPlugin }) => {

    authPlugin.onLogin(() => {
      this.attachmentService.init(authPlugin.token);
    })

    system.registerPrimitiveSystem({
      id: AttachmentPlugin.details.id,
      name: "Attachment",
      description: "Attachments Primitive System",
      labels: ["core"],
      component: () => <AttachmentPage attachmentPlugin={ this } />,
      menuItem: {
        icon: { name: "link", type: "material" },
        backgroundColor: "#f55d80",
        iconColor: "white"
      }
    });

    return this;
  }
}
