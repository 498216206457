import { InstanceInternal } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from "react";
import { Clipboard, Text } from "react-native";
import { DavelField } from "../../../packages/davel-ui/davel-ui-tools";
import { Card } from "../../../packages/kelp-bar/card";
import { largeSpacer } from "../../../packages/kelp-bar/styles";
import { HaborContainer, PrimitiveProps } from "../component-plugin/habor-react/habor-component-lib";
import { HessiaPlugin } from '../hessia-plugin';

export interface DebugComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal;
  }
}
interface DebugComponentPrimitiveState { }

class DebugComponentPrimitive extends React.Component<DebugComponentPrimitiveProps, DebugComponentPrimitiveState> {
  constructor(props: DebugComponentPrimitiveProps) {
    super(props);
    this.state = {
      priorities: []
    }
  }

  public render = () => {

    //  Unpack
    const { context, componentContext } = this.props.frameworkProps;
    const { instance } = this.props.userProps;

    return (
      <HaborContainer frameworkProps={this.props.frameworkProps} style={{ display: 'flex', flexDirection: 'row', marginBottom: largeSpacer }}>
        <DavelField name="Debugger">

          {/* CONSIDER:  Instead of using the system Clipboard, consider a more advanced APPLICATION LEVEL "Clipboard" for thigns like setting context, and a BUNCH of other shit that PERHAPS systems can REGISTER INTO as targets!?  HMM!! etc!?? */}

          {/* TODO:  Consider an abstraction where we run an action THEN show a modal / alert box to finish!?  MAYBE we can just abstrac t the alert box!? HMM! */}
          <Text>Instance ID</Text>
          <Card onPress={async () => { await Clipboard.setString(instance.id); alert("Copied!"); }}>
            <Text>{instance.id}</Text>
          </Card>

          <Text>Noun ID</Text>
          <Card onPress={async () => { await Clipboard.setString(instance.nounId); alert("Copied!"); }}>
            <Text>{instance.nounId}</Text>
          </Card>

        </DavelField>
      </HaborContainer>
    );
  }
}

export class DebugPlugin extends CorePluginClass {
  public static details = {
    name: "Debug",
    description: "Hessia Debug Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "debug"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {
    hessia.registerPrimitiveComponent('debug-detail-component-primitive', DebugComponentPrimitive);
  }
}
