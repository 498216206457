import { NavigationProp, useNavigation } from '@react-navigation/native';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import { AllTMSDTs, Color, FrameworkContext, getInstanceSchemaFromProperties, HaborComponentContext, InstanceInternal, NamedObject, NounId, NounInternal, Relationship, SearchTerm, serializedUserBlockNoun } from 'habor-sdk';
import * as React from 'react';
import { ActivityIndicator, ScrollView, Text, TextInput, View } from 'react-native';
import { Button } from 'react-native-elements';
import { ModelPlugin, ModelPluginContext } from '..';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';
import { AppHeader } from '../../../../packages/kelp-bar/app-header';
import { RoundIconButton } from '../../../../packages/kelp-bar/buttons';
import { Card } from '../../../../packages/kelp-bar/card';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { medSpacer, RaisedHeight, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { HAppRoute } from '../../apps-plugin/apps.nav.routes';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { InstanceListSimpleItem } from '../../model-plugins/named-object-plugin/named-object-list-simple';
import { RelationshipListItem } from '../components/relationship-list-item';
import { FilterContext } from '../filters/filters';
import { ModelRoute } from '../model-plugin';
import { HaborModelSDK, ModelSDK } from '../model-sdk';
import { ExtensionsWidget, getInheritedProperties, getResponder, getTaxonomy, Taxonomy } from './instance-creator';
import { FlatGrid } from 'react-native-super-grid';

const LinearGradient = ExpoLinearGradient as any;

//  TODO:  Show the user which Plugins / Registration Points are available on each component.  Similarly, show which Registration Points a comopnent USES.  For example, we might register a particular class of component to be added to a group declaratively.  OR, we can push directly to the group imperatively?
//         More generally, these are "things" recognized by a "component" that we can REFERECE hm!  The INTERPRETER is the thing that "registers" them hmm...

export interface InstanceViewProps {
  //  TODO:  In the future, we will want to either update this props dict with support from Plugins, actually, this whole thing may have come from the "Class" Plugin.  But it can then be extended with all the default things that an objec can experience, like relationships, and in the future the "Attachment" plugin, etc... ALL of this stuff should be available within this context!  We MIGHT want to query for it, but its presence should at least be known in this context?
  //  CRITICAL:  Keep this in mind!  Although it's messy right now, in the future, we CAN extend these TS types with installed Plugins, and we can update the associated Davel types for the user system!
  instance: InstanceInternal<any>;
  noun: NounInternal;
  // relData: RelationshipData; //  TODO:  This should come from a Plugin?

  //  NOTE:  These things should be organized / grouped separately from the main stuff above.  MAYBE collapse these internal objects in a more manageable way?
  componentContext: HaborComponentContext;
  frameworkContext: FrameworkContext;
}

export interface InstanceListInternalProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  instances: InstanceInternal[];
  noun: NounInternal;
  // filter?: SearchTerm;
  // relationshipData: RelationshipData;
  navigation: NavigationProp<any>;
  onItemPress?: ({ noun, instance }: { noun: NounInternal, instance: InstanceInternal }) => void;
  modelSDK: ModelSDK;
}

const DefaultRenderer = ({ noun, instance, onPress, schema }: { noun: NounInternal, instance: InstanceInternal, onPress: () => void, schema: AllTMSDTs }) => {
  return (
    //  NOTE:  JUST like CSS we COULD swap COMPONETNS and stuff based on the context hm!!!  This way this outer thing.. and anything can be controlled by context masking?? HM!
    //  NOTE:  AGAIN, at IFT we had TYPES... simple, primitive types, JUST like my trackers which were encoded with key / values.  In my trackers, the encoding was done with a set of fields, which I later with with restfully defined Trackers.  THIS meant it had a TYPE ame, and it also had the other stuff... the tie from TrackerClass to UI element was discussed in a prior note, and it INCLUDES the idea of FALLBACK jeven though I didn't label it as such.  What I'm doing here is more like aggregate trackers hmm.. we STILL have the fall back to the object type, BUT it's different because it's not a simple key/value encoding, it's an encoding that includes field types and stuff hmm.. so we have a more robust way of showing, UNLIKE the other point..either way, the idea of classes passed between was something I discussed INCLUDING trackers with types AND even aggregate trackers! HM!  i THINK the ideas were there and it's all sorta swirling around hm!
    <Card>
      <Button onPress={onPress}>Edit</Button>
      <DavelForm schema={schema as any} onSubmit={() => null} value={instance.payload} />
    </Card>
  );
};

//  TODO:  This LIST of renderers should be registered explicitly hmm.. the idea is, we can link to ANY context.  NOT just the "type" hmmm... 
const renderers = {
  "relationship": ({ onPressHandler, instance }) => <RelationshipListItem onPress={onPressHandler} registers={{ top: [], left: [], right: [] }} item={(instance as InstanceInternal<Relationship>)} />,
  "namedobject": ({ onPressHandler, instance }) => <InstanceListSimpleItem onPress={onPressHandler} registers={{ top: [], left: [], right: [] }} item={(instance as InstanceInternal<NamedObject>)} />,
  [serializedUserBlockNoun.id]: ({ onPressHandler, instance }) => <InstanceListSimpleItem onPress={onPressHandler} registers={{ top: [], left: [], right: [] }} item={(instance as InstanceInternal<NamedObject>)} />
};

//  CONSIDER:  In SOME contexts we may have a different schema each time, in others, it'll be the same hmm...
export const InstanceListItem = ({ schema, onItemPress, noun, instance, modelSDK, responder }: { schema: AllTMSDTs, onItemPress: any, noun: NounInternal, instance: InstanceInternal<any>, modelSDK: ModelSDK, responder?: string }) => {

  const navigation = useNavigation();

  const onPressHandler = () => {

    if (onItemPress) {
      onItemPress({ noun, instance })
    } else {
      navigation.navigate("models-system" as never, { screen: ModelRoute.InstanceBuilder, params: { noun, instance } } as never);
    }
  }

  const DefaultComponent = ({ onPressHandler, instance }) => <DefaultRenderer schema={schema} onPress={onPressHandler} noun={noun} instance={instance} />;

  //  NOTE:  We use the DefaultComponent if we can't find a valid responder, OR if the responder if undefined.
  const Component = responder ? renderers[responder] || DefaultComponent : DefaultComponent;

  // NOTE:  We SHOULD be able to see things like status, priority, project, etc right from the box.  The idea is, it MAY be a challenge to load them all?  The idea is, we can determine the set of instances, and then make a SINGLE query to get the data we need.  BUT I want that to happen behind the scenes.  This way, the pieces can stay autonomous? Hmmm... Maybe we have a "Render Batch" and for each SYSTEM we determine what we need to get, COMBINE them, and then go from there? hmm... TO START THOUGH, we can lower the number of elements, and just have it display for each one.  Maybe show 10 per page mm!
  return (
    <>
      <Component instance={instance} onPressHandler={onPressHandler} />

      {/* <ExtensionsWidget instance={ instance } /> */}
    </>
  )
}


export interface InstanceListInternalState {
  schema?: AllTMSDTs;
  taxonomy: Taxonomy;
  responder?: string;
  loading: boolean;
}

export class InstanceGrid extends React.Component<InstanceListInternalProps, InstanceListInternalState> {

  constructor(props: InstanceListInternalProps) {
    super(props);
    this.state = {
      schema: undefined,
      taxonomy: {},
      responder: undefined,
      loading: true
    };
  }

  public async componentDidMount() {
    this.setState({ loading: true });
    const schema = await getInstanceSchemaFromProperties(this.props.noun.properties);
    const taxonomy = await getTaxonomy(this.props.noun, this.props.modelSDK);
    const responder = await getResponder(taxonomy, Object.keys(renderers), this.props.modelSDK);
    this.setState({ schema, responder, loading: false });

  }

  public render() {

    const { schema } = this.state;

    if (!schema) {
      return <Text>Loading Schema...</Text>;
    }

    //  Unpack
    const { noun, frameworkContext, componentContext, instances } = this.props;
    // const { filteredInstances } = this.state;

    //  NOTE:  The "renderObjectView" should create the entire view.  Really, we want to pass this all the props required by the "InstanceView" component class.  Then, we can contorl which class we end up using. 
    //  OK!  So HERE, is an example of where we're rendering a UI based on a selection.  In this case, does the user still have a choice?  OR, will we force the custom UI if it exists?  I suggest we let them choose I suppose, but IF a custom UI exists, we MAY want to set it as the default.

    const InstanceItem = ({ instance, color }: { instance: InstanceInternal<any>, color: string }) => {


      //  Define Props
      const instanceViewProps: InstanceViewProps = { instance, noun, frameworkContext, componentContext };


      // const elements = Object.keys(renderers).map(className => {
      //   if (inherits.indexOf(className) !== -1) {
      //     return renderers[className]
      //   }
      //   return defaultRenderer;
      // });

      const onPressHandler = () => {

        if (this.props.onItemPress) {
          this.props.onItemPress({ noun, instance })
        } else {
          this.props.navigation.navigate("models-system", { screen: ModelRoute.InstanceBuilder, params: { noun, instance } });
        }
      }

      if (this.state.loading) {
        return <Text>Loading</Text>
      };

      return (
        <View>
          {/* <HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ HaborComponentRouterHaborComponent } handleClose={ () => null } componentProps={{ componentClassName: "main-instance-list-item", defaultComponentName: "MainInstanceListItemHaborComponent", componentProps: { ...instanceViewProps }}} /> */}
          <View style={{ display: 'flex', flexDirection: 'column', padding: 0, backgroundColor: color, flex: 1, borderRadius: 10, overflow: 'hidden' }}>
            <InstanceListItem responder={this.state.responder} schema={schema} noun={noun} instance={instance} onItemPress={onPressHandler} modelSDK={this.props.modelSDK} />
          </View>
        </View>
      )
    }

    return (
      <FlatGrid
        itemDimension={1000}
        data={instances}
        renderItem={({ item, index }) => <InstanceItem color={index % 2 ? 'white' : '#f7f7f7'} instance={item} />}
      />
    );
    // return <HaborComponentRouter componentClassName="named-object" frameworkContext={ frameworkContext } componentContext={ componentContext } componentProps={ instanceViewProps } />;

  }
}


//  TODO-NEXT:  Build a TABLE for the instance view!  
//  NOTE:  I REALLY want to be able to do this for the Knowledge Graph too!  Let's just keep moving!

// export const InstanceTable = () => {

//   const [loading, setLoading] = React.useState(false);
//   const [schema, setSchema] = React.useState();
//   const [schema, setSchema] = React.useState();
//   const [schema, setSchema] = React.useState();


//   const loadComponent = () => {

//   }

//   React.useEffect(() => {
//     const schema = await getInstanceSchemaFromProperties(this.props.noun.properties);
//     const taxonomy = await getTaxonomy(this.props.noun, this.props.modelSDK);
//     const responder = await getResponder(taxonomy, Object.keys(renderers), this.props.modelSDK);
//     this.setState({ schema, responder, loading: false });
//   });


//   }

//   public render() {

//     const { schema } = this.state;

//     if (!schema) {
//       return <Text>Loading Schema...</Text>;
//     }

//     //  Unpack
//     const { noun, frameworkContext, componentContext, instances } = this.props;
//     // const { filteredInstances } = this.state;

//     //  NOTE:  The "renderObjectView" should create the entire view.  Really, we want to pass this all the props required by the "InstanceView" component class.  Then, we can contorl which class we end up using. 
//     //  OK!  So HERE, is an example of where we're rendering a UI based on a selection.  In this case, does the user still have a choice?  OR, will we force the custom UI if it exists?  I suggest we let them choose I suppose, but IF a custom UI exists, we MAY want to set it as the default.

//     const InstanceItem = ({ instance }: { instance: InstanceInternal<any> }) => {


//       //  Define Props
//       const instanceViewProps: InstanceViewProps = { instance, noun, frameworkContext, componentContext };


//       // const elements = Object.keys(renderers).map(className => {
//       //   if (inherits.indexOf(className) !== -1) {
//       //     return renderers[className]
//       //   }
//       //   return defaultRenderer;
//       // });

//       const onPressHandler = () => {

//         if (this.props.onItemPress) {
//           this.props.onItemPress({ noun, instance })
//         } else {
//           this.props.navigation.navigate("models-system", { screen: ModelRoute.InstanceBuilder, params: { noun, instance } });
//         }
//       }

//       if (this.state.loading) {
//         return <Text>Loading</Text>
//       };

//       return (<View style={{ marginBottom: medSpacer }}>
//         {/* <HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ HaborComponentRouterHaborComponent } handleClose={ () => null } componentProps={{ componentClassName: "main-instance-list-item", defaultComponentName: "MainInstanceListItemHaborComponent", componentProps: { ...instanceViewProps }}} /> */}
//         <Card outerStyle={{ flex: 1 }} raisedHeight={RaisedHeight.high} innerStyle={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
//           <InstanceListItem responder={this.state.responder} schema={schema} noun={noun} instance={instance} onItemPress={onPressHandler} modelSDK={this.props.modelSDK} />
//         </Card>
//       </View>)
//     }

//     return (
//       <FlatGrid
//         itemDimension={ 300 }
//         data={ instances }
//         renderItem={({item}) => <InstanceItem instance={ item } />}
//       />
//     );
//     // return <HaborComponentRouter componentClassName="named-object" frameworkContext={ frameworkContext } componentContext={ componentContext } componentProps={ instanceViewProps } />;

//   }
// }

export interface FilterBarProps {
  //  TODO:  Generalize to filtering ANY type of object?
  setInstances: (instances: InstanceInternal[]) => void
  instances: InstanceInternal[];
  token: string;
  navigation?: any;
  noun: NounInternal;  //  TODO:  I'm not crazy about passing the Noun here... This implies we're using this filter ONLY for a Noun... we should be able to use it for any set of objects...  Maybe do a pre-processing step where we determine what fields are available in a set of objects?
}

//  TODO:  ONLY show this if we pull-down?  BUT, there MAY be OTHER filters applied from other CONTEXTS!?  I THINK that's VERY important!???  We NEED to work on that!  This way, the filters are VERY dynamic!  Hmmmmmmm

export const FilterBar = ({ token, instances, setInstances, noun }: FilterBarProps) => {


  const navigation = useNavigation();
  const modelPlugin = React.useContext(ModelPluginContext);
  const { settings, setSettings } = React.useContext(FilterContext);

  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>();

  React.useEffect(() => {
    runFilters();
  }, [])

  React.useEffect(() => {
    runFilters();
  }, [settings, searchTerm])

  //  CONSIDER:  Should we automatically do this on ANY state update (not including the newInstances state to prevent recursion?)  I believe Hooks support a state subset selection.
  const runFilters = async () => {

    setLoading(true);

    //  Filter - Use the "Model Plugin"
    //  CONSIDER:  We CAN generalize filtering to ENTITIES, and we can eventually do it projectively.  Meaning, many interpretations and recursive interpretation.
    const filteredInstances = await modelPlugin.processFilters(instances, token, settings, noun) || instances;

    //  Search - Use the "Name" Field
    const searchedInstances = filteredInstances.filter(inst => {
      if (!searchTerm) { return true; }
      const instName = inst.payload.name as string;
      if (instName && (instName.toLowerCase().indexOf(searchTerm.toLowerCase()) == -1)) {
        return false;
      }
      return true;
    })
    setLoading(false);

    setInstances(searchedInstances);
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  //  TODO:  Work on MULTIPLE ways to handle loading... FOR NOW.. we can use an absolutely positioned screen.. I DO think we can use the more general event approach though...s tatefu thing shm.. UGH.. need heroes... Jobs is kidna dead ughh

  if (loading) {
    return <ActivityIndicator size={50} />;
    // return (
    //   <View style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', backgroundColor: 'blue' }}>
    //     <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.5 }} />
    //     <Text>Loading Filters</Text>
    //     <ActivityIndicator size={ 50 } />
    //   </View>
    // );
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingTop: smallSpacer, paddingBottom: smallSpacer, paddingLeft: medSpacer, paddingRight: medSpacer }}>

      <View style={{ backgroundColor: 'white', borderRadius: 10, height: 40, flexGrow: 1, marginRight: smallSpacer, borderColor: "#f1f1f1", borderWidth: 2, paddingLeft: 10, paddingRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <KelpIcon type="material" name="search" color="#aaaaaa" size={18} />
        <TextInput autoCapitalize="none" style={{ flex: 1, paddingLeft: 10, paddingRight: 10, fontFamily: 'Poppins-Bold', letterSpacing: -0.2, fontWeight: "900", color: "#AAAAAA" }} value={searchTerm} onChangeText={(text) => setSearchTerm(text)} />
        {
          //  TODO:  REALLY want to be ble to set a CONTEXT, which SYSTEMS can define UIs forr and HANDLE!  Hm! So cool IMO!
          searchTerm ?
            <KelpIcon type="material" name="close" color="#aaaaaa" size={18} onPress={() => { setSearchTerm(""); runFilters(); }} /> :
            null
        }

      </View>

      {/* Filter Button */}
      {/* TODO:  Support INJECTIONS, like Propositional Injections into ANY component and use the CSS-Like system to RESOLVE / RENDER the view? HM! */}
      <RoundIconButton
        onPress={() => navigation.navigate('models-system' as never, { screen: ModelRoute.FilterMenu, params: { instances, settings, setSettings } } as never)}
        iconSelection={{ name: "filter", type: "material" }}
        backgroundColor="#25ced2"
        iconColor={Color.white}
      />

      {/* <Text style={{ color: Color.medGray, fontFamily: primaryFontFamily, fontSize: 15 }}>FILTER BAR</Text>
         */}
      {/* NOTE:  We currently let each component handle its state, but we trigger a re-render here on every update (FOR NOW) */}
      {/* {
           this.state.showModal ?
          //  TODO:  MAYBE we want some standardized modals???  SOME of which (or all) we may want to expose to Hessia as USER-LEVEL components!?
            <CardModal style={{ position: "absolute", zIndex: 10000, width: '100%', height: '100%' }}>
              <ScrollView>
                { entitiesPlugin?.filterComponents.map(Comp => <Comp instances={ instances } onChange={ () => { this.runFilters(); this.toggleModal(); } } />) }
                <Button title="Close" onPress={ this.toggleModal } />
              </ScrollView>
            </CardModal> :
            null
         } */}
      {/* <Button onPress={ () => setFilter({ match: { payload: { status: "Backlog" } } }) } title="SET FILTER" /> */}
    </View>
  );
}

interface DetailSettings { }
const listSettings: DetailSettings = {};

//  TODO-NEXT:  Break this into smaller pieces??

interface InstanceListBaseProps {
  nounId: NounId;
  navigation: NavigationProp<any>;
  modelSDK: ModelSDK;
  entitiesPlugin: ModelPlugin;
  onItemPressed?: ({ noun, instance }: { noun: NounInternal, instance: InstanceInternal }) => void;
  headerButtons?: () => any;
  filterSettings?: any;
}

interface InstanceListBaseState {
  instances: InstanceInternal[];
  filteredInstances: InstanceInternal[];
  filter?: SearchTerm;
  relData?: RelationshipData;
  noun?: NounInternal;
}

//  Destination Instances with Outgoing Properties
interface OutgoingInstanceMap { [srcInstId: string]: InstanceInternal<any>[] }

//  Destination Nouns with Outgoing Properties
interface OutgoingNounMap { [srcNounId: string]: NounInternal[] }

//  TODO:  This should be extracted in some other Plugin??
export interface RelationshipData {
  outgoingRemoteProperties: Relationship[];
  destinationNouns: NounInternal[];
  outgoingInstanceMap: OutgoingInstanceMap;
}

class InstanceListBase extends React.Component<InstanceListBaseProps, InstanceListBaseState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      instances: [],
      filteredInstances: []
    };
  }

  public async componentDidMount() {

    const { nounId } = this.props;

    //  Get the Noun
    const noun = await this.props.modelSDK.getModelByID(nounId.nounId);

    //  Get the Instances
    const instances = await this.props.modelSDK.searchInstances({ nounId: noun.id, from: 0, size: 10000 });
    if (instances.length > 10000) { alert("Reached the 10k limit.  Need paging for instances!") }

    const instanceViews: any[] = [];

    //  TODO:  Move the relationship stuff to its own system / coupling systems!  AND put it back hm!
    //  TODO-IMPORTANT:  The "Relationsihp" pattern may be built in, but any "Attachments" or "Addons" SHOULD be activated with Plugins!  We MAY want to consider doing this for Primitive types too... really, we should be expecting the server to serialize and send this relationship data for us!
    //  NOTE:  Incoming properties are available as value(s) on the Noun.
    //  TODO:  We need to support the "allowMany" Relationship boolean.  I'm not sure if it's working ATM.
    //  TODO:  Instead of getting EVERYTHING at once, consider making LOTS more queries and showing spinners for each instance and load as we go...  Or, do it in batches.  It would be nice if we could optionally include nested fields directly from the backend.

    //  Get Outgoing Remote Property Relationships
    // const outgoingPropertyRelationships = await this.props.modelSDK.searchInstances<Relationship>({ nounId: RelationshipNoun.id, search: { match: { payload: { srcId: { nounId: noun.id } } } } });
    // const outgoingRemoteProperties = outgoingPropertyRelationships.map(rel => rel.payload);

    // //  Get the Attached Nouns
    // const nounQuery = outgoingPropertyRelationships.map(rel => ({ match: { id: rel.payload.destId.nounId } }));
    // const nounsWithOutgoingProperties = await this.props.modelSDK.searchModels({ search: { any: nounQuery } });

    // //  Group the Attached Nouns by Source Instance ID
    // const outgoingNounMap: OutgoingNounMap = {};
    // outgoingPropertyRelationships.forEach(rel => {
    //   nounsWithOutgoingProperties.forEach(noun => {
    //     //  Check if the destNoun belongs to this outgoingRel
    //     if (rel.payload.destId.nounId == noun.id) {
    //       outgoingNounMap[rel.payload.srcId.nounId] = outgoingNounMap[rel.payload.srcId.nounId] || [];
    //       outgoingNounMap[rel.payload.srcId.nounId].push(noun);
    //     }
    //   })
    // });

    // //  Get Outgoing Remote Value Relationships
    // const valueQuery = instances.map(inst => ({ match: { payload: { srcId: { nounId: noun.id, instanceId: inst.id } } } }));
    // const outgoingValueRelationships = await this.props.modelSDK.searchInstances<Relationship>({ nounId: RelationshipNoun.id, search: { any: valueQuery }});

    // //  Group by Destination Noun
    // const outgoingInstanceMap: OutgoingInstanceMap = {};
    // const outgoingRelsGroupedByNounId = _.groupBy(outgoingValueRelationships, value => value.payload.destId.nounId);
    // for (const destNounId in outgoingRelsGroupedByNounId) {
    //   const outgoingRelsForNoun = outgoingRelsGroupedByNounId[destNounId];
    //   //  TODO:  Type this properly... I believe the intersection of types for destId is not what I inended.
    //   const instQueries = outgoingRelsForNoun.map(rel => ({ match: { id: (rel.payload.destId as any).instanceId } }));
    //   const destInsts = await this.props.modelSDK.searchInstances({ nounId: destNounId, search: { any: instQueries } });

    //   //  Group Destination Instances by their Source Instance (potentially multiple)
    //   outgoingValueRelationships.forEach(rel => {
    //     destInsts.forEach(inst => {

    //       //  Check if the destInstance belongs to this outgoingRel
    //       if ((rel.payload.destId as any).instanceId == inst.id) {
    //         outgoingInstanceMap[(rel.payload.srcId as any).instanceId] = outgoingInstanceMap[(rel.payload.srcId as any).instanceId] || [];
    //         outgoingInstanceMap[(rel.payload.srcId as any).instanceId].push(inst);
    //       }
    //     })
    //   })
    // }

    // const relData: RelationshipData = {
    //   // outgoingInstanceMap,
    //   // outgoingRemoteProperties,
    //   destinationNouns: nounsWithOutgoingProperties
    // };

    //  TODO-IMPORTANT:  A Habor App should be namespaced!  This means it shouldn't be able to navigate out to other routes.

    this.setState({ instances, noun });
  }

  public render = () => {
    const { frameworkContext, componentContext } = this.context;
    const { instances, relData, filter, noun, filteredInstances } = this.state;

    if (!noun) { return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}><ActivityIndicator /></View> }

    // console.log(this.props.navigation.getState());
    // alert(JSON.stringify(this.props.navigation.getState()));

    const headerButtons = () => (
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        {/* Entity Editor */}
        <RoundIconButton
          onPress={() => this.props.navigation.navigate("models-system", { screen: ModelRoute.ModelEditor, params: { noun } })}
          iconSelection={{ name: 'edit', type: 'font-awesome' }}
          backgroundColor="#25ced2"
          iconColor={Color.white}
        />

        <View style={{ width: smallSpacer }} />

        {/* Instance Builder */}
        <RoundIconButton
          onPress={() => this.props.navigation.navigate("models-system", { screen: ModelRoute.InstanceBuilder, params: { noun } })}
          iconSelection={{ name: 'plus', type: 'font-awesome' }}
          backgroundColor="#25ced2"
          iconColor={Color.white}
        />

      </View>
    );

    return (
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'white' }}>
        {/* NOTE:  This priority list / chain of command is a SYMPTOM / MANIFESTATION of a greater rule regarding the usage of this component!  WOULD like to be able to encode that!! mm... should be able to extend / override EVERYTHING?  Or... can the component encapsulate?  Essentially this is the same as letting patterns live with a differentiator!   */}
        <AppHeader title={noun.name} headerComponent={this.props.headerButtons || headerButtons} />
        <FilterBar noun={noun} navigation={this.props.navigation} token={frameworkContext.token} instances={instances} setInstances={(filteredInstances) => this.setState({ filteredInstances })} />
        {/* TODO:  De-couple noun from filter! */}
        {/* TODO:  Collect ALL todos / considerations!  and organized them EXTERNALLY perhaps with LINKS back to the original hm!  Keep the code "clean" by PERSPECTIVE!!!  Can be dirty by associatoin mm! */}

        <InstanceGrid modelSDK={this.props.modelSDK} onItemPress={this.props.onItemPressed} navigation={this.props.navigation} componentContext={componentContext} frameworkContext={frameworkContext} noun={noun} instances={filteredInstances} />

      </View>

    );
  }
}

export const InstanceList = function (props: Pick<InstanceListBaseProps, "nounId" | "onItemPressed" | "headerButtons" | "filterSettings">) {
  const navigation = useNavigation();
  const appContext = React.useContext(AppContext);
  const entitiesContext = React.useContext(ModelPluginContext);
  const modelSDK = new HaborModelSDK(haborSDK, appContext.frameworkContext.token);
  return <InstanceListBase {...props} entitiesPlugin={entitiesContext} modelSDK={modelSDK} navigation={navigation} />;
}