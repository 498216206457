import { HaborComponent, NounInternal, UserCredentialProps } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { RouteComponentProps, withRouter } from '../../../packages/isomorphic-router/react-router';
import { haborSDK } from '../hessia-plugin/config';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { WorkspaceContextProps } from '../workspace-plugin/workspace-container';

interface WorkspaceTrackerListProps extends RouteComponentProps, UserCredentialProps, WorkspaceContextProps {}

interface WorkspaceTrackerListState {
  trackers: NounInternal[];
}

class WorkspaceTrackerListBase extends React.Component<WorkspaceTrackerListProps, WorkspaceTrackerListState> {

  constructor(props: any) {
    super(props);
    this.state = {
      trackers: []
    }
  }


  //  1.  Component Router:  Routes to a user-selected component to fulfill a particular rendering job.  This might be "Entity List", or "Entity List Item", etc...
  //  2.  Component Class:  Defines a class of components for use in a Router.
  //  3.  Render Sysetm:  Create a "System" for each Renderer which includes registration of a Setting object or Addon to the relevant Objects?
  //      This is tricky... I'm thinking we just create an "Addon" with a "Scope" which applies that Addon to particular objects.  Ahh!  We already decided that each Addon will come with a Model.  This means that we have an "Addable" object which can attach to a class.  THEN, we create instances of that thing to bind Addon settings to a particular set of objects.  Ok... so each setting will apply to that set?  BUT, what if we just want to apply it to a single object?  What if we want to active it for all instances of a particular class, AND let the instances override?  I think that's fine.  We'll just allow the Addon to be overriden on the instance.  OR, we can even partially define settings in a larger group and then let the child definition inherit from the pre-existing one?  That kind of makes sense to me.  MAYBE to keep it simple, we DO start by applying it to Classes and their Instances.  Hmmm... This is different from the "Remote Property" system in that there may be explicit UI?
  //      FOR NOW, I want to keep things as simple as possible!  SO, I'm thinking we just make it a simple connection on the relevant objects.  To do that, we just attach a remote property to the Class with an option set to apply to children?  The equivalent would be adding it to the prototype of the object.
  //      FOR NOW, the SIMPLER idea is to just hard-code the new UI and just attach the pieces that we need... IDEALLY, the UI WOULD be aware of the OPTION to set these values though... This means we would attach the property to the class but tag it as an "abstract" property?  This does get complicated, and this is where the prototype pattern would really nice.  FOR NOW, we'll make it possible to declare OR define "static" and "instance" proerties.  When we declare a "static" property on a Noun, that means it shows in the UI for entities in the chain!  When we DON'T declare as static, it shows on ALL instances.  HOWEVER, it's possible for sub-classes to override it.  Then, when we DEFINE a value, that's when we actually set a value for the thing... Note that multiple values in the chain are NOT merged onto the child FOR NOW. 

  public componentDidMount = async () => {

    //  Unpack
    const { token } = this.props;

    //  Get the Trackers
    const trackers = await haborSDK.searchNouns(token, { search: { match: { inherits: "event" } } });

    //  Update State
    this.setState({ trackers });

  }

  public render = () => {

    //  Unpack Props
    const { trackers } = this.state;

    return (
      <View style={{ flex: 1 }}>
        { trackers.map(tracker => <Text>{ tracker.name }</Text>) }
      </View>
    );
  }
}

export const WorkspaceTrackerList = withRouter(WorkspaceTrackerListBase);

//
//  TrackerList Primitive Component
//
export interface TrackerListHaborPrimitiveProps extends PrimitiveProps {}
export const TrackerListHaborPrimitive = ({ userProps, frameworkProps }: TrackerListHaborPrimitiveProps) => {

  //  Unpack
  const { context: { token, user, workspace } } = frameworkProps;

  //  Guard
  if (!workspace) { throw new Error("Cannot render the 'TrackerList' Page with an undefined 'workspace'.") }

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <WorkspaceTrackerList { ...userProps } token={ token } user={ user } workspace={ workspace } />
    </HaborContainer>
  );
};

// registerPrimitiveHaborComponent('TrackerListHaborPrimitive', TrackerListHaborPrimitive);

export const TrackerListHaborComponent: HaborComponent = {
  name: "TrackerListHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "TrackerListHaborPrimitive",
    props: {},
    children: []
  }
};

// registerHaborComponent(TrackerListHaborComponent);

// //  Make the Page
// export const dashboardPage: Page = {
//   name: "TrackerList",
//   element: { name: 'TrackerListHaborComponent', children: [], props: {  } }
// };

