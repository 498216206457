import { HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { AppContext } from '../hessia-plugin/AppContext'; //  TODO:  Should this module have knowledge of the actual AppContext OR use a props interface?
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { MenuConfigNavigator } from './menu-settings.nav';


export interface MenuSettingsHaborPrimitiveProps extends PrimitiveProps {}

//  TODO:  Should we add "Habor" to the name here?  I THINK that's OK, because the props WILL assume Habor context.
export interface MenuSettingsHaborPrimitiveProps {}
interface MenuSettingsHaborPrimitiveState {}
export class MenuSettingsHaborPrimitive extends React.Component<MenuSettingsHaborPrimitiveProps, any> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: MenuSettingsHaborPrimitiveProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  NOTE:  Framework Props and Framework CONTEXT should be SEPARATED.  the props store something like a call-stack while the context is global for this app / workspace (for now)???
    const { frameworkProps } = this.props;
   
    return (
      <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row', position: 'absolute', width: '100%', height: '100%' }}>
        <MenuConfigNavigator />
      </HaborContainer>
    );
  }
}

//  NOTE:  This is necessary, because we want to eventually express components in a way the UI can interpret and manipulate.  A REACT component is written in JS and generally unavailable for edit once in a runtime environment!
//  EXCITEMENT:  Once some of this stuff is done the Hessia system should REALLY start to work as we pull these pieces together and start to scale out horizontally???

export const MenuSettingsHaborComponent: HaborComponent = {
  name: "MenuSettingsHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "MenuSettingsHaborPrimitive",
    props: {
      //  TODO:  The CONTEXT given to this is important!  I SUGGEST we START by providing context in the primitive plugin / space system!  THIS way, we know what "Space" we're in!?
      //  NOTE:  This means each component DOES have knowledge of that context and CAN perform its own queries and stuff???
    },
    children: []
  }
};
