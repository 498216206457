import { Color, UserBlock } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from "react-native";
import { Card } from '../../../../packages/kelp-bar/card';
import { TitleChiclet } from '../../primitives-plugin/habor-components/embedded-views/chicklet-component';
import { medSpacer, primaryFontFamily, primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';
import { getSignalNodeMap, SignalNodeResult } from './data-flow';
import { errorDefinitions } from './models/errors';

//
//  Errors Component
//

export interface ErrorsProps {
  component: UserBlock;
  signalNodeResult: SignalNodeResult;
}

interface ErrorsState {}

class ErrorsBase extends React.Component<ErrorsProps, ErrorsState> {
  constructor(props: ErrorsProps) {
    super(props);
    this.state = {}
  }

  

  public render = () => {

    const { component: { blockInsts }, component, signalNodeResult: res } = this.props;

    return (
      <View>
         {
          res.errors.length ?
            <View>
              <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>ERRORS</Text>
              <TitleChiclet title={ res.errors.length.toString() } style={{ backgroundColor: Color.danger }} />
              {
                //  TODO:  Group the errors!  Make PANELS for our editor window!
                  res.errors.map(error => {
                    const errorDef = errorDefinitions[error.type];
                    const ErrorComponent = errorDef.component;
                    return (
                      <Card innerStyle={{ alignItems: 'center', justifyContent: 'center', padding: medSpacer }}>
                        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>{ errorDef.name }</Text>
                        <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }}>{ errorDef.description }</Text>
                        {
                          ErrorComponent ?
                            <ErrorComponent error={ error } /> :
                            null
                        }
                      </Card>
                    );
                  })
              }
              </View> :
              null
          }
      </View>
    );
  }
}
export const Errors = ErrorsBase