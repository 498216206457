import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { InstanceInternal as HaborInstanceInternal, SDT } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, FlatList, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { ENoun } from '../../enoun-service';
import { getDavelType, SDTForm } from '../../packages/davel-ui/davel-ui';
import { capitalizeAllWords } from '../../packages/davel-ui/davel-utils';
import { HexColorField } from '../../packages/davel-ui/habor-types/color/color-field';
import { IconSelector } from '../../packages/davel-ui/habor-types/icon/icon-field';
import { GroupCard } from '../../packages/kelp-bar/group-card';
import { Page } from '../../packages/kelp-bar/page';
import { PageLoader } from '../../packages/kelp-bar/page-loader';
import { HaborNounService, NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { TextField } from '../gallery/components/fields';
import { Paragraph } from '../gallery/constants';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { EntityIdentityPlugin, IdentityInfo } from './entity-identity-plugin';
import { Entity, EntityPlugin, useEntityPlugin } from "./entity-plugin";
import { HessiaPlugin } from './hessia-plugin';
import { haborSDK } from './hessia-plugin/config';
import './rating-plugin'; //  TODO:  Consider a PLUGIN to add Rating instead of importing it here.
import { SystemPlugin } from './system-plugin/system-plugin';

//  TODO:  Eventually make this WITHOUT the primitive system!
//  TODO:  Should be able to generate SOME representation based on the model!  Keep it SIMPLE.. MAYBE use a neral net and freaking SEMANTIC association to help with that hm!
//  CONSIDER:  Merge this with Surveys / other systems hmm...
//  CONSIDER:  When we make a model it CAN be used as a template!  The idea is.. then we can have a "template selection" hmm...
//  CONSIDER:  We can complicate this encoding by adding thign slike Day, etc... BUT a big part of this is NOT doing that.  We CAN add Plugins to modify...  Ughhh... the idea is... we CAN have coupling, but yeah hmm... The idea is.. instead of doign that, we can also have regular things tagged with something like "Track" and then we can have a period.  The ahh!  The idea is it's VERY similar to tagging with something like "Recurring", BUT it's hmm.. a system that will ensure that it gets tracked by that period hm.. it's a particular coupling of concepts hmm... ANY period can perhaps do hmm... 
//  CONSIDER:  Pulling these out to separate indices MAY be helpful in the future.  We may want to do several thigns liek the DB MUX to optimize their usage hm!
//  CONSIDER:  We CAN just have a random thing with like "Tracker" and then an intensity or even some other strucutre!!!  That's 100% fine!!!  The QUESTION is, how do we "program" with it, given we MAY not be consistent and stuff hm!  This way, we have our OWN symbol set for these ontological pieces mmm!!!  EVEN "separate systems" are JUST accessible via some symbol / name in some context hmmm... 
//  CONSIDER:  DO have types.  DO have contexts (CSS-like system).  The point is... we want to be able to use ALL of this.  Already had types in TM that we applied to Trackers and ... could have abstractions for those hmm... could also yeah... use the baic and then have stuff for the "Tracker".  EIther way... Corey didn't have that generalizetion hm
//  CONSIDER:  Abstract "parser" - takes in ANY encoding and processes it.  Then.. we can have an INFINITE number of sub-parsers hmm.. but don't want it to be feed-forward but recurrant hmm... Also iterative? Hmm...
//  CONSIDER:  Generic Renderer - Render based on ANYTHING... an encoding, a statement, etc...  Essentially a PROJECTION hm!
//  CONSIDER:  A simplified renderer that works on JSON.  It takes in Davel Types and we use those to render.  We CAN even have a "Relationship" BUT... instead of coupling with Habor, we can include a resolver for the objects, which WILL perhaps have IDs. hmm... Then we can just use that ID hmm... PERHAPS the ID can be ANY string (also JSON)
//  CONSIDER:  When we make a service, auto-create HOOKS!  I think that would REALLY help speed up development!  It makes it easy to access these objects from within a React Component, which cannot just make an async call without a hook.

//
//  React Context
//

export const TrackerPluginContext = React.createContext<TrackerPlugin>({} as TrackerPlugin);

export const useTrackerPlugin = () => {
  const trackerPluginContext = React.useContext(TrackerPluginContext);
  return trackerPluginContext;
}

//
//  Data Model
//

export interface Tracker {
  title: string;
  description?: string;
  color: string;
  type: SDT;
  icon: {
    name: string;
    type: string;
  }
}

export interface TrackerEntry {
  trackerId: string;
  payload: any;  //  NOTE:  This must be an object corresponding to the type.  

}

export const trackerService = new HaborNounService<Tracker>("tracker-plugin-tracker", haborSDK);

export const trackerEntryService = new HaborNounService<TrackerEntry>("tracker-plugin-tracker-entry", haborSDK);


//
//  Hooks
//

export const getTrackers = () => {

  const trackerPlugin = useTrackerPlugin();

  const [trackers, setTrackers] = React.useState<NounServiceInstanceInternal<Tracker>[]>([]);

  const loadTrackers = async () => {
    const _trackers = await trackerPlugin.trackerService.retrieveAll();
    setTrackers(_trackers);
  }

  React.useEffect(() => {
    loadTrackers();
  }, [])

  return trackers;
}

//
//  Tracker Widgets
//

export const TrackerWidget = ({ trackerInternal, trackerPartial, trackerId, onCreated, onUpdated, onChange, onPress }: { trackerInternal?: NounServiceInstanceInternal<Tracker>, trackerPartial?: Partial<Tracker>, trackerId?: string, onCreated?: (trackerInternal: NounServiceInstanceInternal<Tracker>) => void, onUpdated?: (trackerInternal: NounServiceInstanceInternal<Tracker>) => void, onChange?: (trackerInternal: NounServiceInstanceInternal<Tracker>,) => void, onPress?: (trackerInternal: NounServiceInstanceInternal<Tracker>) => void }) => {

  const trackerPlugin = useTrackerPlugin();

  const _trackerInternal = trackerPlugin?.trackerService?.retrieveHook(trackerId);

  trackerInternal = trackerInternal || _trackerInternal;

  const sdt: SDT = trackerInternal?.payload.type || { type: "keyword" };

  return (
    <TouchableOpacity style={{ height: 150, borderRadius: 7, padding: 30, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: trackerInternal?.payload.color, width: '100%' }} onPress={() => trackerInternal && onPress && onPress(trackerInternal)}>

      <Paragraph style={{ color: 'white', fontSize: 20, lineHeight: 20 }}>{trackerInternal?.payload.title}</Paragraph>
      <View style={{ width: 20 }} />
      <Paragraph style={{ color: 'white', fontSize: 14, lineHeight: 14 }}>{trackerInternal?.payload.description}</Paragraph>
      <View style={{ width: 20 }} />
      {/* <SDTForm autoSubmit={true} sdt={sdt} onSubmit={() => null} /> */}
      <Paragraph style={{ color: 'white' }}>{capitalizeAllWords(sdt.type)}</Paragraph>
    </TouchableOpacity>
  );
}

export const TrackerEntryWidget = ({ trackerEntryInternal, trackerEntryPartial, onCreated, onUpdated, onChange, onPress }: { trackerEntryInternal?: NounServiceInstanceInternal<TrackerEntry>, trackerEntryPartial?: Partial<TrackerEntry>, onCreated?: (trackerInternal: NounServiceInstanceInternal<Tracker>) => void, onUpdated?: (trackerInternal: NounServiceInstanceInternal<Tracker>) => void, onChange?: (trackerInternal: NounServiceInstanceInternal<TrackerEntry>) => void, onPress: (trackerEntry: NounServiceInstanceInternal<TrackerEntry>) => void }) => {

  const trackerPlugin = useTrackerPlugin();

  const _trackerInternal = trackerPlugin?.trackerService?.retrieveHook(trackerEntryInternal?.payload.trackerId);

  const tracker = _trackerInternal?.payload;

  const type: SDT = _trackerInternal?.payload.type || { type: "keyword" };

  const davelType = getDavelType(tracker?.type.type);

  return (
    <TouchableOpacity onPress={() => trackerEntryInternal && onPress(trackerEntryInternal)}>
      <GroupCard style={{ backgroundColor: _trackerInternal?.payload.color, height: 70, flexDirection: 'row', alignItems: 'center' }}>

        <Icon name={tracker?.icon.name || "number"} type={tracker?.icon.type || "material"} />
        <View style={{ width: 20 }} />

        <Icon name={davelType?.icon.name || "number"} type={davelType?.icon.type || "material"} />
        <View style={{ width: 20 }} />

        <Paragraph>{tracker?.title}</Paragraph>
        <View style={{ width: 20 }} />

        {/* <View style={{ flexDirection: "column", justifyContent: 'center', alignItems: 'flex-start' }}>
          <Paragraph style={{ flex: 1 }}>{tracker?.type.type}</Paragraph>
        </View> */}

        <Paragraph>{trackerEntryInternal?.updated}</Paragraph>
        <View style={{ width: 20 }} />

        <Paragraph>{JSON.stringify(trackerEntryInternal?.payload.payload)}</Paragraph>


        {/* <SDTForm autoSubmit={true} sdt={type} onSubmit={() => null} value={trackerEntryInternal?.payload.payload} /> */}
      </GroupCard>
    </TouchableOpacity>

  );
}

export const TrackerEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  //  TODO:  Should be able to do this with a model and semantic classes INSTEAD of specifically using something like a hard-coded type.  Should be able to specifcy "name" or "description" WITHOUT hard-coding the type.
  const [trackerNoun, setTrackerNoun] = React.useState<ENoun<Tracker>>(route?.params?.tracker);
  const [title, setTitle] = React.useState<string>(trackerNoun?.payload.title || "");
  const [description, setDescription] = React.useState<string>(trackerNoun?.payload.description || "");
  const [color, setColor] = React.useState<string>(trackerNoun?.payload.color || "#eeeeee");
  const [type, setType] = React.useState<SDT>(trackerNoun?.payload.type);
  const [icon, setIcon] = React.useState<{ name: string, type: string }>({ name: "dot", type: "material" });

  return (
    <ScrollView style={{ padding: 30 }}>

      <TextField title="Title" onSave={setTitle} mode="automatic" value={title} />

      <TextField multiline={true} title="Description" onSave={setDescription} mode="automatic" value={description} />

      <Text>Color</Text>
      <HexColorField color={color} setColor={_color => setColor(_color)} />

      <Text>Icon</Text>
      <IconSelector value={icon} update={setIcon} />

      <Text>Type</Text>
      <SDTForm autoSubmit={true} onSubmit={(value) => { setType(value) }} sdt={{ type: "sdt" }} value={type} />

      <Text>{JSON.stringify(type)}</Text>

      {/* TODO:  Support PERIOD to specify when this should be updated etc... */}
      {/* CONSIDER:  When should we have our own thing vs. separate it out!  I DO want to be able to separate it and use it with composition ahh!!! I LOVE the idea of SEMANTIC COMPOSITION!!!  Meaning we can freaking express at a high level and have it GENERATE connection / code for us mm! */}

      <Button title={trackerNoun ? 'Update' : 'Create'} onPress={trackerNoun ? async () => { await trackerService.update(trackerNoun?.id, { title, description, color, type, icon }); } : async () => { await trackerService.create({ title, description, color, type, icon }); }} />
      <Button title="Delete" onPress={() => trackerService.delete(trackerNoun.id)} />
    </ScrollView>
  );
}

const TrackerList = () => {

  const navigation = useNavigation();
  const trackerPlugin = useTrackerPlugin();
  const entityPlugin = useEntityPlugin();

  const [trackers, setTrackers] = React.useState<NounServiceInstanceInternal<Tracker>[]>([])

  const onMount = async () => {
    const trackers = await trackerService.retrieveAll();
    setTrackers(trackers);
  }

  React.useEffect(() => {
    onMount();
  }, [])

  return (

    <PageLoader loading={false}>
      <Page style={{ paddingHorizontal: 20, backgroundColor: 'white' }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Trackers</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
            <FlatGrid
              itemDimension={200}
              data={trackers}
              renderItem={({ item: tracker }) => <TrackerWidget trackerInternal={tracker} onPress={(tracker) => navigation.navigate('Editor' as never, { tracker } as never)} />}
            />
          </ScrollView>
        </View>

      </Page>
    </PageLoader>
  )
}

export const TrackerEntryEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const trackerPlugin = useTrackerPlugin();

  const [trackerEntry, setTrackerEntry] = React.useState<ENoun<TrackerEntry> | undefined>(route?.params?.trackerEntry);

  const [trackerId, setTrackerId] = React.useState<string>(trackerEntry?.payload.trackerId || "");

  const tracker = trackerPlugin?.trackerService?.retrieveHook(trackerId);

  const trackers = getTrackers();

  const [payload, setPayload] = React.useState<any>(trackerEntry?.payload.payload);

  const type = tracker?.payload.type;

  return (
    <ScrollView style={{ backgroundColor: 'white', paddingHorizontal: 20 }}>

      <Text>Select a Tracker</Text>
      <FlatGrid data={trackers} renderItem={({ item }) => <TrackerWidget trackerInternal={item} onPress={_tracker => setTrackerId(_tracker.id)} />} />

      <View style={{ height: 20 }} />
      {
        tracker && (
          <>
            <TrackerWidget trackerInternal={tracker} />
          </>
        )
      }

      {
        type && <SDTForm autoSubmit={true} onSubmit={(value) => setPayload(value)} sdt={type} value={payload} />
      }

      {
        !type && <Paragraph>No Type Specified for this Tracker</Paragraph>
      }


      <Button title={trackerEntry ? 'Update' : 'Create'} onPress={trackerEntry ? async () => { await trackerPlugin.trackerEntryService.update(trackerEntry?.id, { trackerId, payload }); } : async () => { await trackerPlugin.trackerEntryService.create({ trackerId, payload }); }} />
      {trackerEntry && <Button title="Delete" onPress={() => trackerService.delete(trackerEntry?.id)} />}

    </ScrollView>
  );
}

const TrackerEntryList = () => {

  const navigation = useNavigation();
  const trackerPlugin = useTrackerPlugin();
  const entityPlugin = useEntityPlugin();

  const [trackerEntries, setTrackerEntries] = React.useState<NounServiceInstanceInternal<TrackerEntry>[]>([])
  const [selectedTrackerEntru, setSelectedTrackerEntry] = React.useState<NounServiceInstanceInternal<TrackerEntry> | undefined>(undefined);

  const onMount = async () => {
    const trackerEntries = await trackerEntryService.retrieveAll();
    setTrackerEntries(trackerEntries);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  return (

    <PageLoader loading={false}>
      <ScrollView style={{ paddingHorizontal: 20, backgroundColor: 'white' }}>

        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Entries</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>

        <FlatList
          data={trackerEntries}
          renderItem={({ item: trackerEntry }) => <TrackerEntryWidget onPress={(trackerEntry) => navigation.navigate('Editor' as never, { trackerEntry } as never)} trackerEntryInternal={trackerEntry} />}
        />
      </ScrollView>
    </PageLoader>
  )
}


const Tabs = createBottomTabNavigator();

export const TrackerPage = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name="Editor" component={TrackerEditor} />
      <Stack.Screen name="List" component={() => <TrackerList />} />
    </Stack.Navigator>
  );
}

export const TrackerEntryPage = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name="Editor" component={TrackerEntryEditor} />
      <Stack.Screen name="List" component={() => <TrackerEntryList />} />
    </Stack.Navigator>
  );
}

export const TrackerTabs = ({ trackerPlugin, entityPlugin }: { trackerPlugin: TrackerPlugin, entityPlugin: EntityPlugin }) => {


  return (
    <Tabs.Navigator screenOptions={{ tabBarStyle: { height: 70 }, headerShown: false }}>
      <Tabs.Screen options={{ tabBarIcon: () => <Icon name="analytics-outline" type="ionicon" /> }} name='Trackers' component={TrackerPage} />
      <Tabs.Screen options={{ tabBarIcon: () => <Icon name="form" type="ant-design" /> }} name='Entries' component={TrackerEntryPage} />
    </Tabs.Navigator>
  );
}

//  TRACKER:  Simulates a relationship linking a String and an Entity TAGGED as a "Tracker".. hmmm

//  TODO:  Inject into the SCRIPTING system! HM!


export class TrackerPlugin extends CorePluginClass {

  public trackerService = trackerService;
  public trackerEntryService = trackerEntryService;

  public serviceName = "tracker";

  public static details = {
    name: "Trackers Plugin",
    description: "Trackers System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id, HessiaPlugin.details.id],
    id: "trackers"
  }

  public install = async (program: Program, { system, pEntities, authPlugin, entityIdentityPlugin, hessia }: { entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin, hessia: HessiaPlugin }) => {

    await trackerService.init(authPlugin.token);
    await trackerEntryService.init(authPlugin.token);

    hessia.registerContext(TrackerPluginContext, this);

    entityIdentityPlugin.registerIdentityProvider({
      id: "tracker-entity-identity-provider",
      name: "Tracker Entity Identity Provider",
      description: "Provides 'tracker' Entity Identity",
      systemId: this.serviceName,
      provide: async (entity: Entity, entityPlugin?: EntityPlugin): Promise<IdentityInfo> => {

        if (!entityPlugin) { return undefined as any; }

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Tracker
        const tracker = await this.trackerService.retrieve(entity.route.id);

        return {
          type: "tracker",
          icon: { name: "moon", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#f5aa20",
          name: "Tracker",
          description: "A Tracker",
          component: () => {

            return <TrackerWidget trackerInternal={tracker} />

          }
        } as IdentityInfo;

      }
    });

    //  Register Entity Handler
    trackerService.emitter.addListener("create", async (trackerInternal: HaborInstanceInternal<Tracker>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: trackerInternal.id, created: trackerInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.createEntity(entity);
      alert("Created Entity: " + JSON.stringify(entityInternal));
    })

    trackerService.emitter.addListener("delete", async (trackerInternal: HaborInstanceInternal<Tracker>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: trackerInternal.id, created: trackerInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.deleteEntity(entity);
      alert("Deleted Entity: " + JSON.stringify(entityInternal));
    })

    system.registerPrimitiveSystem({
      id: 'trackers-system',
      name: "Trackers",
      description: "Trackers Primitive System",
      labels: ["core"],
      component: () => <TrackerTabs entityPlugin={pEntities} trackerPlugin={this} />,
      menuItem: {
        icon: { name: "analytics-outline", type: "ionicon" },
        backgroundColor: "#46038f",
        iconColor: "#e3bbfc"
      }
    });

    return this;
  }
}
