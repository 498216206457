import { NavigationProp, useNavigation } from '@react-navigation/native';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import { Color, HaborIconSelection } from 'habor-sdk';
import * as React from 'react';
import { Platform, Text, View, ViewStyle } from 'react-native';
import { HessiaThemePart, registerThemeMapper, withInjections } from '../injector/injector';
import { extraSmallSpacer, headerHeight, layourMargin as layoutMargin, statusBarHeight } from './styles';
import { KelpIcon } from "./kelp-icon";
import { RoundIconButton } from './buttons';
import { primaryColor } from './constants';

//  NOTE:  Here we map from the theme to the props in a STATIC way, BUT in the future I REALLY want to be able to attach MULTIPLE functions like this as COUPLING systems!  That means, between the Theme and THIS component symbol!?  Hmm... registered as a function in the style system? Hm!
//  THOUGHT:  The POITN is... somethign like a "theme" needs to be mapped.  But, what do we do when we have say...1000 ?  The idea is.. we can "learn" to map between them, and project.  But yeah... 
//  TODO:  Move this to anoher file, AND perhaps symbolize the "AppHeader" thing, AND make a way to view all these systems in CONTEXT so it's not OPAQUE and hard to read!  Need DEV TOOLS for this!?  I THINK Hessia will give us MUCH of that!?  HM!
//  NOTE:  The TYPE of the theme is DYNAMIC and determined by enabled systems!  It's NOT a statically set thign!? HM!  SO... this means that the PIECES that extract the static part of the theme should be separate!? HM!  So.. MAYBE we have a style resolver fo the MAINN Hessia theme elmeents? HM!  I THiNK that would make sense!

//  TODO:  Consider implementing UIKitten or something.  I DO like what I have now, becausde it lets us injecet MULTIPLE systems.  The problem is... what if we have ANOTHER system we want to use OVER this system system... I'm not sure ugh... 

const hessiaThemeMapper = (part: HessiaThemePart) => {
  return {
    style: {
      backgroundColor: part.background,
    },
    fontColor: part.color
  };
}


registerThemeMapper("AppHeader", hessiaThemeMapper, "hessia");

const LinearGradient = ExpoLinearGradient as any;

export enum AppHeaderButtonStyle {
  None, Plus, Configure, Edit, Save
}

export interface AppHeaderProps {
  onPress?: () => void;
  title: string;
  buttonIcon?: HaborIconSelection;
  onPressEdit?: () => void;
  style?: ViewStyle;
  headerComponent?: () => React.ReactElement;
  navigation: NavigationProp<any>;
  fontColor?: string;
}

interface AppHeaderState { }

export class AppHeaderBase extends React.Component<AppHeaderProps, AppHeaderState> {

  constructor(props: any) {
    super(props);
    this.state = {
      apps: []
    };
  }

  public setButtonAction = (onPress: () => void) => {
    this.setState({ onButtonPress: onPress });
  }

  public render = () => {

    //  Unpack Props
    const { title, buttonIcon, onPressEdit, style, headerComponent } = this.props;

    //  HESSIA BENEFITS:  Work on defining the differences between us and regular programming and WHY we needed new languages and stuff.. I think the SYSTEM approach with the philosophy of infinitely dependent systems, with eventual rule "compilation" / "squashing" to improve performance, similar to ECS, and the REACTIVE programming model all help!? ?HMM!!  I'm actally NOT building an entirely new language, I "LEVERAGE" JavaScript!?
    //  HESSIA IDEA:  Build an "Out of Place" tag, which is used to indicate when something is out of place, and PERHAPS can implicit "Task"!?  Ah!!  Simlar to REPEATING Tasks, MAYBE we DON'T need each "Task" to ACTUALLY be a Task Instance!?  HMM!??  I still REALLY want to build those systems!?

    return (
      // f5f5f5
      // When the components are STATIC and re-used as-is, then there's no need for style overrides.  SOMETIMES I think that's OK.  BUT, we MAY want to set some global styles and we should generally do it on a component / component basis I imagine?  Again, VERY simlar to CSS?
      <View style={{ height: headerHeight + statusBarHeight, display: 'flex', flexDirection: 'row', flexGrow: 0, paddingLeft: layoutMargin, paddingRight: layoutMargin, justifyContent: 'flex-end', paddingBottom: extraSmallSpacer, elevation: 8, overflow: 'visible', position: 'relative', zIndex: 1, paddingTop: (Platform.OS === 'ios') ? 20 : 10, ...style }}>
        {/* <View style={{ display: 'flex', flexDirection: 'row' }}> */}

        {/* <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}> */}
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-start' }}>


          {
            this.props.navigation.canGoBack() ?
              <View style={{ marginLeft: 10, marginRight: 10 }}>
                <KelpIcon size={18} color="#333333" type="font-awesome" name="chevron-left" onPress={() => this.props.navigation.goBack()} />
              </View> :
              null
          }

          <Text style={{ color: this.props.fontColor || "white", fontFamily: 'Inter-Bold', fontSize: 20, overflow: 'visible' }}>{title}</Text>

          {/* CONSIDER:  Do we still need the "onPressEdit" logic here? */}
          {/* {
                  onPressEdit ?
                    <View style={{ marginLeft: 7 }}>
                      <HaborIcon name="settings" type="material" size={ 25 } color={ '#929292' } onPress={ onPressEdit } />
                    </View> : null
                } */}

        </View>
        {/* </View>  */}
        {headerComponent ? headerComponent() : null}
        {
          // TODO:  Abstract this to a "RoundButton" component?
          buttonIcon ?
            <RoundIconButton
              onPress={this.props.onPress}
              iconSelection={{ name: buttonIcon.name, type: buttonIcon.type }}
              backgroundColor={primaryColor}
              iconColor={Color.white}
            /> :
            null
        }
        {/* </View> */}
      </View>
    );
  }
}

const AppHeaderWithNavigation = function (props: Omit<AppHeaderProps, "navigation">) {
  const navigation = useNavigation();
  return <AppHeaderBase {...props} navigation={navigation} />;
}

//  TODO:  Considerr adding the NAME to the component and have the compoent extend "NamedVompent" or "InjectbleComponent" o something!
export const AppHeader = withInjections(AppHeaderWithNavigation, "AppHeader");

//  CONSIDER:  Create an "AppComponent" React Component to encapsulate the Header / Body / Footer pattern and parameterize with an array of otions for each Page.
