import { NavigationProp } from '@react-navigation/native';
import { AnyBlock, Color, HaborComponent, InstanceInternal, Page, PageSelection } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";
import { largeSpacer, primaryFontFamilyHeavy, smallSpacer } from '../../../packages/kelp-bar/styles';
import { HaborComponentViewer } from '../component-plugin/habor-react/habor-component-viewer';
import { BlockRenderer } from '../composer-plugin/composer/components/component-renderer';
import { HaborReactComponent } from '../habor-plugin/habor-utils';
import { HessiaPlugin } from '../hessia-plugin';
import { resolveComponent } from '../composer-plugin/composer/models/blocks';
import { AppContext } from '../hessia-plugin/AppContext';
//  TODO:  Decouple this from Workspace!  It CAN be coupled later if we need it to be mm!

export const getPage = (pages: InstanceInternal<Page>[], pageSelection?: PageSelection) => {

  //  Guard
  if (!pageSelection) { return; }

  //  Unpack
  const { pageName, pageId } = pageSelection;

  //  Get the Page
  const page: InstanceInternal<Page> | undefined = pageId ?
    pages.find((page) => page.id == pageId) :
    pageName ?
      pages.find((page) => page.payload.name == pageName) :
      undefined;

  return page;
}


export interface PageRouterProps {
  page: InstanceInternal<Page> | undefined;
  pageProps: any;
  navigation: NavigationProp<any>;
  hessiaPlugin: HessiaPlugin;

}
interface WorkspacePageRouterState {
  haborComponent?: HaborComponent;
  blockComponent?: InstanceInternal<AnyBlock>;
  loading: boolean;
}
class PageViewerBase extends HaborReactComponent<PageRouterProps, WorkspacePageRouterState> {

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: PageRouterProps) {
    super(props);
    this.state = {
      loading: true
    }
  }

  public componentWillReceiveProps = (newProps: PageRouterProps) => {
    this.updateComponent(newProps);
  }

  public componentDidMount = async () => {
    this.updateComponent(this.props);
  }

  public updateComponent = async (props: PageRouterProps) => {

    //  Set Loading
    this.setState({ loading: true, blockComponent: undefined, haborComponent: undefined });

    //  Unpack
    const { frameworkContext: { token } } = this.context;
    const { page } = this.props;

    //  Guard Undefined
    if (!page) { return; }

    //  Get the Components
    const components = await resolveComponent(token, this.context.hessiaPlugin, page.payload.componentId);

    //  Guard Undefined
    if (!components) {
      this.setState({ loading: false });
      return;
    }

    //  Update the State
    const { haborComponent, blockComponent } = components;
    if (haborComponent) {
      this.setState({ haborComponent, blockComponent: undefined })
    } else if (blockComponent) {
      this.setState({ blockComponent, haborComponent: undefined })
    }

    //  Update Loading
    this.setState({ loading: false });
  }

  public render = () => {

    //  Unpack
    const { page, pageProps, navigation } = this.props;
    const { componentContext, frameworkContext: { workspace, token }, frameworkContext } = this.context;
    const { haborComponent, blockComponent, loading } = this.state;

    //  TODO:  Render a cute page to tell the user that they have a blank canvas!  Give them a link to install Plugins into their Workspace!
    //         They should also be able to create App Elements directly in a Workspace... like Nouns, Forms, Pages, etc... Maybe anything that can go into a Plugin?  BUT maybe can install Plugins to support all of those things.

    //  Guard Undefined
    if (!page) {
      return <Text>Page Not Found</Text>
    }

    //  Check Loading
    if (loading) {
      return <Text>Loading Page...</Text>
    }

    //  TODO:  At some point build a REALLY cool, flat design that shows some type of space stationy thing on a distant planet ready to BUILD stuff!
    //  TODO:  Abstract the builder stuff!!!  Make it look cooler.
    //  TODO:  General to the app, BUT use MULTI-LAYERED Shadows.
    if (haborComponent == undefined && blockComponent == undefined) {
      return (
        <View style={{ padding: largeSpacer, flex: 1, display: "flex" }}>
          <View style={{ flex: 1, borderColor: Color.lightGray, borderStyle: "dashed", borderWidth: 3, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 25 }}>
            <KelpIcon type="material-community" name="crane" size={75} color={Color.medGray} />
            <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 14, color: Color.medGray }}>No Component Selected</Text>
            <View style={{ height: smallSpacer }} />
            <Text>SHOULD BE TOUBHLE LINK TO PAGE</Text>
            {/* <TouchableOpacity style={{ width: 150, padding: smallSpacer, borderRadius: 7, borderWidth: 1, borderColor: Color.medGray, display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onPress={ () => this.props.navigation.navigate(PageRoute.PageEditor, { page }) }>
              <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12, color: Color.medGray }} >CONFIGURE</Text>
            </TouchableOpacity> */}
          </View>
        </View>
      );
    }

    //  TODO-IMPORTANT:  Check the prop types we're passing to the component!  Make sure they're compatible.
    //  TODO-IMPORTANT:  Instead of parsing the element EVERY time we want to render a HaborComponent, make a HaborElement Viewer / Renderer!  In other words, we shouldn't be manually parsing the element EACH time we want to render it.  Instead, have some function do this for us.
    //                   Here's the language we currently use:  Viewer = Raw Component, Renderer = Component in Editor, MagicComponent = Component with 4 Magic Buttons (select, configure, edit, and fork).  TODO:  Unify this language and make it more interpretable!  Remove / merge like things.
    //  CONSIDER:  Consider using MagicCompnent to allow the user to Select / Configure / Edit / Fork pages (access permitting)?
    //  CONSIDER:  Consider supporting user-specified props for the Page Definition.  Or, let the framework use the Page system to make props (like when navigating from the List to a nested screen like the Instance List?)
    //  TODO:  Figure out where to PROPERLY pass "workspace".

    return (
      <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

        {/* Dynamically Rendered Component / Block */}
        {/* <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}> */}
          {
            haborComponent ?
              <HaborComponentViewer componentContext={componentContext} frameworkContext={frameworkContext} componentProps={{ ...pageProps }} component={haborComponent} handleClose={() => null} /> :
              <BlockRenderer componentContext={componentContext} frameworkContext={frameworkContext} component={(blockComponent as InstanceInternal<AnyBlock>).payload} setOutput={() => null} />
          }
        {/* </View> */}

        {/* Info Bar */}
        {/* TODO:  Maybe Edit the name right here.  Show hich SYSTEM it's from, include CONTEXT like NAVIGATION, Data, UI, etc..!  LOTS of options down here, AND make it PLUGGABLE!  With BOTH code AND blocks!  Similar to VSCode? HM! */}
        {/* TODO:  Decouple the Page System! */}
        <View style={{ height: 40, display: 'flex', flexDirection: 'row', backgroundColor: '#f5f5f5', flexGrow: 0, flexShrink: 0 }}>
          <View style={{ paddingLeft: 30, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <KelpIcon size={12} style={{ marginRight: 20 }} color={Color.medGray} type="font-awesome" name="chevron-left" onPress={() => this.props.navigation.goBack()} />
            <View style={{ width: 10 }} />
            <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12, color: Color.medGray }} >{blockComponent?.payload.name || haborComponent?.name || "Unnamed"}</Text>
          </View>
          {/* () => this.props.navigation.navigate(PageRoute.PageEditor, { page })  */}
          {/* <Button onPress={() => alert("Should navigate to the PageRoute.PageEditor Screen")} title="Edit" /> */}
        </View>

      </View>
    );
  }
}
export const PageViewer = PageViewerBase;