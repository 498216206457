import { NamedObject } from "habor-sdk";
import * as React from 'react';
import { View, ViewStyle } from "react-native";
import { renderRGBAColor } from "../../../packages/davel-ui/habor-types/color/color-field";
import { SimpleCard } from "../../../packages/kelp-bar/simple-card";
import { smallSpacer } from "../../../packages/kelp-bar/styles";
import { TextSubParagraph } from "../../../packages/kelp-bar/text";


//  TODO:  Use a "DynamicComponent" for this!? HM!  MAYBE the BASE component is built, and exports an API which we inject into the sub-componetns!? HM!

//  NOTE:  MAYBE we can use Halia for this whole thing? HM!

//  THOUGH:  MAYBE it's JUST a "Halia System" and we build by depending upon the original and stuff? HM!  This COULD make sense!? Hm!

//  TODO:  Make a codebase JUST for this stuff?? HM!  BASICALLY a React Component that uses FEATURE APIs, which is a Halia Concept!? HM!

//  CONCERN:  Is this recrreating the wheel SPECIFICALLY for React stuff?? HM!


interface WidgetItemProps {
  children?: any;
  name: string;
  color: string;
  style?: ViewStyle;
}

//  CONSIDER:  Should something like "smallSpacer" NOT be hard-coded???
export const WidgetItem = (props: WidgetItemProps) => (
  <SimpleCard style={{ backgroundColor: 'white', padding: 0, overflow: 'hidden', ...props.style }}>
    <View style={{ backgroundColor: props.color, height: 30, display: 'flex', flexDirection: 'row', borderRadius: 15, alignItems: 'center', justifyContent: 'flex-start', paddingLeft: smallSpacer, paddingRight: smallSpacer, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <TextSubParagraph style={{ color: 'white' }}>{ props.name }</TextSubParagraph>
    </View>
    { props.children }
  </SimpleCard>
);


interface DynamicComponentPart {
  builder: (...props: any) => void
}

interface DynamicComponent<S> {
  // api: any;  //  Will operate on an object instantiated at build time!
  stateClass: S,
  component: any;  //  React component / class
  symbols: { [symbol: string]: DynamicComponentPart }  //  TODO:  Support COUPLING between MULTIOPLE sysmbols.
}

//  Singleton Definition
//  CONSIDER:  Hook into a SINGLETON manager / DI system
const dynamicComponents: { [compName: string]: DynamicComponent<any> } = {}

//  NOTE:  This is the STATE of the "object" we use between parts.
//  CONSIDER:  MAYBE this is an indication we should be using a CLASS for this!?  Like... with a constructor and stuff.
// interface WidgetItemObject {
//   children: any[];
//   name: string;
//   color: string;
// }


class DynamicComponentAPI<P> {
  protected props: P;

  constructor(props: P) {
    this.props = props;
  }
  public getProps = () => {
    //  CONSIDER:  Protects from manipulation at the TOP-LEVEL only... MAYBE we should stringify?
    return { ...this.props };
  }
}

class WidgetItemAPI extends DynamicComponentAPI<WidgetItemProps> {

  constructor () {
    super({
      name: "Default",
      color: "#eeeeee"
    })
  }

  public setName = (name) => {
    this.props.name = name;
  }
  public setColor = (color: string) => {
    this.props.color = color;
  }
}

const WidgetItemDC = {
  stateClass: WidgetItemAPI,

  //  TODO:  This should JUST take the state interface object, NOT the entire instance!  This means we build the component from the OBJECT we create! HM!
  component: WidgetItem,
  symbols: {}
};

//  NOTE:  MAYBE we can have special functions which keep track of how many times they're called, like "setName" and stuff to make sure sub-system's are NOT overrideing? HM!
//  NOTE:  The WHOLE idea is to have systems which underrstand THEIR slice AND this API, so they can inject.  NORMALLY, one would have a piece of code that couples everything in one spot to make one component.. hmm
///        BUT then, how do we set the NAME?  This is a job for the NAMEDOBJECT system, BUT what if a DIFFERENT system wants to add a name? hmmm... PERHAPS the component logic can be aware of this, OR maybe it's the devs job to know to only put in ONE system that's going to set the name, or maybe it SHOULD be passed as a PROP to the dynamic component? Hmmm Interesting.

dynamicComponents["WidgetItem"] = WidgetItemDC;

WidgetItemDC.symbols["namedObject"] = {
  builder: (api: WidgetItemAPI, prop: NamedObject) => {
    // alert(JSON.stringify(prop));
    api.setColor(prop.color ? renderRGBAColor(prop.color) : '#eeeeee');
    api.setName(prop.name);
  }
}

interface DynamicComponentProps<P> {
  props: Partial<P>; //  NOTE:  These will be passed through to the component.
  children: any;
  parts: { [partName: string]: any }
}

const DynamicComponentRenderer = ({ compName, props }: { compName: string, props: DynamicComponentProps<any> }) => {
  const dc: DynamicComponent<any> = dynamicComponents[compName];
  const stateClass = new dc.stateClass();
  Object.keys(props.parts)
    .forEach(key => {
      const partOptions = props.parts[key];
      const dcp: DynamicComponentPart = dc.symbols[key];
      if (dcp) {
        dcp.builder(stateClass, partOptions);
      }
    });
  const Comp = dc.component;
  const renderedProps = stateClass.getProps();
  //  TODO:  SHOULD just be the object!
  return <Comp { ...renderedProps } { ...props.props }>{ props.children }</Comp>
}

//  IDEA:  We can also have DYNAMIC GROUPS, for various INDEPENDENT contexts? HM!

export const DynamicWidgetItem = (props: DynamicComponentProps<WidgetItemProps>) => {
  return <DynamicComponentRenderer compName="WidgetItem" props={ props } />
}