/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction, HStatement } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

interface HIfParams {
  booleanValue: boolean;
  trueBranch: HStatement[];
  falseBranch: HStatement[];
}

//  NOTE:  Matches 'true' or 'false' strings and boolean primitives.
//  NOTE:  Consider explicitly encoding with something like "HBranch".
export const HIfFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "if",
  params: {
    type: "object",
    properties: {
      booleanValue: {
        type: "boolean"
      },
      trueBranch: {
        type: "array",
        itemType: {
          type: "statement"
        }
      },
      falseBranch: {
        type: "array",
        itemType: {
          type: "statement"
        }
      }
    }
  },
  returns: {
    type: "array",
    itemType: {
      type: "statement"
    }
  },
  definition: async (input: PrimitiveFunctionParams<HIfParams>): Promise<HStatement[]> => {

    const { params } = input;

    //  Define a map from boolean to HStatement[]
    const branchMap: { [index: string]: HStatement[] } = {
      true: params.trueBranch,
      false: params.falseBranch
    };

    //  Get the selected branch
    const booleanString = `${params.booleanValue}`;
    const branch = branchMap[booleanString];

    //  Check for undefined
    if (!branch) { throw new Error("No branch for the given input.  Make sure you've specified a valid boolean."); }

    //  Return the result
    return branch;
  }
};
