import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../../hessia-plugin";
import { ModelPlugin } from "../../model-plugin/model-plugin";
import { ColorSelectorFieldHaborComponent, ColorSelectorFieldHaborPrimitive, ColorTypeHaborComponent, ColorTypeHaborPrimitive } from "./color-component";
import { IconSelectorFieldHaborComponent, IconSelectorFieldHaborPrimitive, IconTypeHaborComponent, IconTypeHaborPrimitive } from "./icon-component";
import { namedObjectAddon, NamedObjectAddonHandlerHaborComponent, NamedObjectAddonHandlerHaborComponentPrimitive } from "./named-object-addon";
import { InstanceListItemHaborComponent, InstanceListItemHaborPrimitive } from "./named-object-list-item";

export class NamedObjectPlugin extends CorePluginClass {
  public static details = {
    name: "NamedObject",
    description: "NamedObject Plugin",
    dependencies: [HessiaPlugin.details.id, ModelPlugin.details.id],
    id: "namedobject"
  }

  public install = (program: Program, { hessia, modelPlugin }: { hessia: HessiaPlugin, modelPlugin: ModelPlugin }) => {

    hessia.registerPrimitiveComponent('NamedObjectAddonHandlerHaborComponentPrimitive', NamedObjectAddonHandlerHaborComponentPrimitive);
    hessia.registerComponent(NamedObjectAddonHandlerHaborComponent);
    
    hessia.registerPrimitiveComponent('InstanceListItemHaborPrimitive', InstanceListItemHaborPrimitive);
    hessia.registerComponent(InstanceListItemHaborComponent);
  
    hessia.registerPrimitiveComponent("ColorTypeHaborPrimitive", ColorTypeHaborPrimitive);
    hessia.registerComponent(ColorTypeHaborComponent);
  
    hessia.registerPrimitiveComponent("ColorSelectorFieldHaborPrimitive", ColorSelectorFieldHaborPrimitive);
    hessia.registerComponent(ColorSelectorFieldHaborComponent);
  
    hessia.registerPrimitiveComponent("IconSelectorFieldHaborPrimitive", IconSelectorFieldHaborPrimitive);
    hessia.registerComponent(IconSelectorFieldHaborComponent);
  
    hessia.registerPrimitiveComponent("IconTypeHaborPrimitive", IconTypeHaborPrimitive);
    hessia.registerComponent(IconTypeHaborComponent);
  
    modelPlugin.registerAddonHandler(namedObjectAddon);
  }
}
