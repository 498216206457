import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { HaborComponentRouterHaborComponent, HaborComponentRouterHaborPrimitive } from "./habor-component-router";

export class RouterPlugin extends CorePluginClass {
  public static details = {
    name: "Router",
    description: "Router Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "router"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {

    hessia.registerPrimitiveComponent("HaborComponentRouterHaborPrimitive", HaborComponentRouterHaborPrimitive);
    hessia.registerComponent(HaborComponentRouterHaborComponent);
  }
}
