
import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { EventEmitter } from 'events';
import { InstanceInternal as HaborInstanceInternal, Workspace, workspaceSerializedSchema } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Text, TouchableOpacity, View, ScrollView } from 'react-native';
import { ENoun } from '../../../enoun-service';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { ContextPlugin } from '../context-plugin/context-plugin';
import { EntityPlugin } from "../entity-plugin";
import { Deferred, renderEntityListItem } from "../entity-plugin/entity-list";
import { HessiaPlugin } from "../hessia-plugin";
import { haborSDK } from '../hessia-plugin/config';
import { SystemPlugin } from '../system-plugin/system-plugin';
import { WorkspaceList } from './space-list';

//  NOTE:  A Space is a SIMPLE observable pattern in the context of Entities / Relatioships, and one REQUIRED to contain the space itself.. because the "Fudamental Ontology" cannot exist without it, we iclude it as a basic, core entity.  It CAN be created with just relationships and associated entities.  BUT, we build our own encoding for optimization? (FOR NOW).

//  CONSIDER:  Even when making "internal" systems like this, I COULD also refernece a model hm!  This way I don't need to build it over and over.  Hmm...
// export interface Space {
//   name: string;
//   description: string;
//   color: string;
//   icon: string;
// }

//  TODO:  Consider... WHY should we have "Space" as differentiated from Set????  What is the usefuless of this?  Perhaps a different view?  BUT why not just SCOPE Set with additional constriants... hmmmmmmmmmkasflkasjflkasjdf... MAYBE a main one is that it has import and exports? hmm....  Maybe FOR NOW, I can start with "Set" then? Hmmm... 

const spaceSchema = workspaceSerializedSchema;

export const spaceService = new HaborNounService<Workspace>("space", haborSDK);


interface SpaceProps {
  spaces: NounServiceInstanceInternal<Workspace>[];
}
class SpaceListBase extends React.Component<{ navigation: NavigationProp<any> }, any> {

  constructor(props) {
    super(props);
    this.state = {
      spaces: []
    }
  }

  componentDidMount = async () => {
    const spaces = await spaceService.retrieveAll();
    this.setState({ spaces });
  }

  render() {

    //  CONSIDER-PATTERN:  We have this "Deffered" thing in ALL the types.. it's JUST a way to show the entity view.. do we always want that?  Can we hide?  Can we JUST scope based on the TYPE string and use the SAME UIs for ALL of these with ONLY UI / Encoding injectiosn forr the differenes, as ONE type of "type" system ah!!! HM!  THEN these are ALL just instaces of the "Type" system but NOT necesssarily insances of hte "Primitive" system concept hM!!!  I THINK I might like that? HM!
    const spaceElems = this.state.spaces.map((space: ENoun<Workspace>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("Editor", { space })}>
          <Deferred func={renderEntityListItem} args={space.payload.entityId} def={<Text>Loading...</Text>} />
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Spaces</Text>
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('Editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>
          <ScrollView showsHorizontalScrollIndicator={false}>
            {spaceElems}
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedSpaceList = (props: any) => <SpaceListBase {...props} />
export const SpaceList: any = InjectedSpaceList;

const Stack = createStackNavigator();

export const SpacePage = () => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      {/* <Stack.Screen name="Editor" component={ InstanceEditor } /> */}
      <Stack.Screen name="List" component={WorkspaceList} />
    </Stack.Navigator>
  );
}


// export const SpaceEditor = ({ navigation, route }: { navigation: any, route: any }) => {

//   const [spaceNoun, setSpaceNoun] = React.useState<ENoun<Workspace>>(route?.params?.space);

//   const onSubmit = (space: Workspace) => {

//     //  NOTE:  THis is just a "node" in the differentation space, and when this CONTEXT is hit, we "do" the stuff? Hm...
//     let _spaceNoun;
//     if (spaceNoun) {
//       _spaceNoun = spaceService.update(spaceNoun.id, space);
//     } else {
//       _spaceNoun = spaceService.create(space);
//     }
//     setSpaceNoun(_spaceNoun);
//   }

//   return (
//     <ScrollView style={{ marginVertical: 100 }}>

//       <DavelForm value={ spaceNoun } schema={ spaceSchema } onSubmit={ space => setSpaceNoun(space) } />

//       {/* Delete Button */}
//       {
//         spaceNoun && <Button title="Delete" onPress={ () => spaceService.delete(spaceNoun.id) } />
//       }

//     </ScrollView>
//   );
// }


//THOUGHT:  Mm!  hee sytems are MUCH like COMPONENTS< BUT where they don't necessarily have a particular place in the UI hierarchy hm!  It's like.. they're more like contexts/ Hmm...

export interface SpaceContext {
  space: HaborInstanceInternal<Workspace>;
}

export const SpaceReactContext = React.createContext<SpacePlugin>({} as any);

// export const useSpacePlugin = () => {

// }

//  NOTE:  ALL emitters are JUST a list of ENCODINGS which we'll INTERPRETER when the "emit" encoding is interpreted ugh.. so it's just a list of encodings with some mechanism ughh I HATE that paper sooo muchhh... really messes with morale
//  NOTE:  The idea of JUST listening on a STRING ENCODING is ... limietting!  We CAN keep it more generic and add ADDITIONAL differentiation as needed ugh!  That's LAL these DOMAINS are... just the SAME shit with DIFFERENTION UGH!  Like Music... it's JUST an encoding that we observe which influences us.  The way it influences us is common with the way anything else does, and it's LARGELY based on evolution and stufff, but the POINT is the same it's about communication and shit ugh!  These ideas are TOXIC!!!  Just like radical Islam.  Yes, I said it.  Some scientific ideas are JUST as toxic as ideas like that.. they're BOTH exaple of dogma that destroys lives.

export const spaceEmitter = new EventEmitter();
export const SPACE_CHANGE_EVENT = "space_change_event";

export class SpacePlugin extends CorePluginClass {

  public system!: SystemPlugin;
  public context!: ContextPlugin;

  //  NOTE:  Adding something to the context stack serves as BOTH a map from context -> state, AND the new key for the next mapping.  Sometimes, we may NOT want to NEST though... like... maybe sometimes we want a particular app AND a particular workspace selected... we DON'T want the app to be activated when the WORKSPACE is selected, but when the USER is selected mm!!  The idea is, each system gets to CHOOSE its context for a selection!  That selection then BECOMES a new context mm!!!  FOR NOW, we will continue adding the state to the "Context" system, BUT I'd like to add IDs to limit the likelihood of interface change.  I'd also like to keep everything at the TOP level FOR NOW... but in the future, we CAN have MULTIPLE pieces to context.  This means, when we select workspace + app, BOTH will be activated for a user.  We do not have to first select a workspace hmm...  

  //  TODO:  Generalize this!  Perhaps use React COntext AND the registeration pattern to make a React "builder" thin ghmm.. REMEMBER it's best to UN-ROMANTICIZE all thise and STOP thinking of these WORDS as special!  It's JUST OBSERVATION... that's what SCIENCE is too.. ALL it is, is observing, encoding (the observations), and drawing "conclusions" based on ASSUMPTIONS!  "Modeling" a STATE SPACE that can bbe iterated and shit hmmm.. communicatin that encoding.. by building ore encodigns so interpreters will take it hm!
  public onSpaceChange = (listener: (space: NounServiceInstanceInternal<Workspace>) => void) => {
    spaceEmitter.addListener(SPACE_CHANGE_EVENT, listener);
  }

  public static details = {
    name: "PrimitiveSpaces",
    description: "Spaces Primitive System",
    //  TODO:  Separate context to COUPLING plugin.  As long as nothing depends upon the coupling plugin, it should be safe to REMOVE HM!
    dependencies: [AuthPlugin.details.id, SystemPlugin.details.id, EntityPlugin.details.id, ContextPlugin.details.id, "hessia"],
    id: "primitive-spaces"
  }

  //  TODO:  NONE of this should be done herre!  BADICALLY we should have a generic search thing that we scope and stuff.. maybe entity search? Hm.. maybe several, like Model.. hmm..  How do we handle nested context? hmm... FOR NOW, I thin it makes sense to do it with separate coupling systems.. so we'll have one for the three... System, Space, Context? hmmm...MAYBE just System, Space.  Context is mostly a visualization / interface? hmmm
  //         It SHOULD be done automatically and we're just monkey patching FOR NOW HM!

  public getSelectedSpace = async () => {
    const contextDef = await this.context.getConext("space");
    const spaceContext: SpaceContext = contextDef?.state;
    return spaceContext?.space;
  }

  public install = (program: Program, { system, context, hessia, authPlugin }: { authPlugin: AuthPlugin, system: SystemPlugin, context: ContextPlugin, hessia: HessiaPlugin }) => {

    spaceService.init(authPlugin.token);

    this.system = system;
    this.context = context;

    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {
    //   const space = await spaceService.retrieveByEntityId(entityId);
    //   if (space) {
    //     api.setText("Space");
    //   }
    // });

    //  TODO:  Shoule be fully defined and coming from a Plugin? hmm... OR use a flag to indicate loading instead of using the data value.
    // if (!HaliaReactExports.registerHOC) { throw `HaliaReactExports not yet defined.`;  }
    hessia.registerHOC((props: any) => {
      return (
        <SpaceReactContext.Provider value={this}>
          {props.children}
        </SpaceReactContext.Provider>
      );
    });
    system.registerPrimitiveSystem({
      id: 'spaces-system',
      name: "Space",
      description: "Spaces Primitive System",
      labels: ["core"],
      component: () => <SpacePage />,
      menuItem: {
        icon: { name: "radiobox-blank", type: "material-community" },
        backgroundColor: "#937af5",
        iconColor: "white"
      }
    });
  }
}
