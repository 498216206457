
import { HessiaEvents, HessiaPlugin, HessiaPluginContext } from '..';
import { HOCRegister } from 'halia-native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { Text } from 'react-native';

//  CONSIDER:  Is it OK to export this?  We MAY be re-using across multiple plugins. I THINK it's just a binding of Navigator and Router.. hmm... MAY be sn INSTANCE thoguh!  Work on that!!!
export const RootStack = createStackNavigator();

export const RootNavigator = ({  }: {  }) => {

  const hessiaPlugin = React.useContext(HessiaPluginContext);

  //  CONCERN-GENERAL:  Inline functions... 
  // RootNavigatorAPI.getRoute = () => route;
  // RootNavigatorAPI.getNavigation = () => { alert("HITTTT");  return navigation; };

  const [routes, setRoutes] = React.useState(hessiaPlugin.routes);
  const [screens, setScreens] = React.useState<any[]>([]);

  React.useEffect(() => {
    //  TODO:  This is a sign there's a pattern we can be abstracting.  Currently we "MOUNT" a Plugin, and it has "state".  Then we "MOUNT" a component to hold the STATE for that component in the React Tree...  PERHAPS we can just treat the Plugin AS a Component? Hmm... Maybe... but we lose out on the features? Hmm... like... If it were JUST a component, then we wouldn't be able to DEPEND on it? Hmm.. we CAN but we'd be using CONTEXT hmmm..... PERHAPS that's OK?  BUT I like the Halia VIEWER and configuration tools and stuff hmmm... that stuff SEEMS to be lost inside the depths of React? Hmmm... like seeing the dependency hierarchy? Hmm....  plus it auto-grabs the CLOSEST match hmmm... Intersting. ..
    hessiaPlugin.hessiaEmitter.addListener(HessiaEvents.RootRouteAddedEvent, setRoutes);
    return () => { hessiaPlugin.hessiaEmitter.removeListener(HessiaEvents.RootRouteAddedEvent, setRoutes) }
  }, []);

  React.useEffect(() => {

    console.log("ROUTES CHANGED");

    const screens: any[] = [];
    if (routes.length) {
      routes.map(route => {

        const options = {
          title: route.title || route.name,
          ...route.options
        };

        if (route.headerRight) {
          options['headerRight'] = route.headerRight;
        }

        screens.push(<RootStack.Screen name={route.name} component={ route.component } options={options} />);
      });

    } else {
      screens.push(<RootStack.Screen name="Systems" component={() => <Text>No Registered Hessia Routes</Text>} />)
    }

    setScreens(screens);
  }, routes);


  //  TODO:  There are currently THREE things... Mounted Component state DURING the installation psas, the state IN the plugins.. and the state in the mounted components AFTER installation !!LKjsdkj⁄  FRAMEWORK IT systemitizie it!
  const { screenOptions } = hessiaPlugin

  return (
    <HOCRegister id="app-nav">
      <RootStack.Navigator initialRouteName="Systems" screenOptions={screenOptions}>
        {screens}
      </RootStack.Navigator>
    </HOCRegister>
  );
}
