import { Color, FrameworkContext, HaborComponentContext, InstanceInternal, NamedObject } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { Card } from '../../../../packages/kelp-bar/card';
import { primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';

/**
 * Simple component to render a Habor Component.  Eventually we want to use a render chain and only apply the latest in the chain.
 */
export interface NamedObjectItemProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  instance: InstanceInternal<NamedObject>;  //  TODO-CRITICAL:  We should NOT assume that this uses the Named Object interface... isntead, check for regisitered rendered renderers and use those!  First check the instance, then the class hierarhy?  More generally, EVERY object has properties associated with it that obeys a complex set of rules.  In MANY cases, we should be able to cache the result of the rule resolution so we can just access that instead resolving all rules!
  onPress?: (item: InstanceInternal<NamedObject>) => void;
}
interface NamedObjectItemState {}
export class NamedObjectItem extends React.Component<NamedObjectItemProps, NamedObjectItemState> {
  constructor(props: NamedObjectItemProps) {
    super(props);
    this.state = {}
  }
  public render = () => {
    const { instance, onPress = () => null } = this.props;
    const { id, payload: { name, description } } = instance;

    return (
      <Card innerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }} onPress={ () => onPress(instance) }>
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 18, color: Color.medGray }}>{ name ? name : id }</Text>
        {/* <Text>{ rel.payload.destId.instanceId }</Text> */}
      </Card>
    );
  }
}