
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from 'halia';
import { HaliaComponent, HaliaComponentPlugin } from 'halia-native';
import * as React from 'react';
import { ActivityIndicator, Text } from 'react-native';
import { HaliaStudioPlugin } from '../../halia-studio/halia-studio-plugin';
import { Login } from '../../gallery/screens/login';
import { Authenticate } from '../../gallery/screens/authenticate';
import { ImageEditor } from './image-editor';
import { ImageList } from './image-list';
import { ImagePlugin, useImagePlugin } from './image-plugin';
import { MenuLayoutPlugin } from '../../elements/menu-layout-plugin/menu-layout-plugin';

export interface ImageAppContext {
  imagePlugin?: ImagePlugin;
  user?: CognitoUser;
  setUser: (user: CognitoUser) => void;
}

export const ImageAppContext = React.createContext<ImageAppContext>({} as any);

export const useImageApp = () => {
  return React.useContext(ImageAppContext);
}

const Stack = createStackNavigator();

export const ImageLogin = () => {

  const [loading, setLoading] = React.useState(true);
  const imageApp = useImageApp();
  const navigation = useNavigation();

  const handleLogin = (user: CognitoUser) => {
    imageApp.setUser(user);
    navigation.navigate("List" as any);
  }

  const loadUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      handleLogin(user);
    } catch (err) {
      console.log("User not authenticated");
      setLoading(false);
    }
  }

  React.useEffect(() => {
    loadUser();
  }, []);

  if (loading) { return <ActivityIndicator /> }
  return <Login onLogin={handleLogin} navigation={navigation} />
}


class ImageAppPlugin extends CorePluginClass {
  public static details = {
    name: "Image",
    description: "Image Primitive System",
    dependencies: [HaliaComponentPlugin.details.id],
    id: "image"
  }

  public install = (program: Program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    haliaComponentPlugin.registerHOC(() => {

      const imagePlugin = useImagePlugin();
      const [user, setUser] = React.useState<CognitoUser | undefined>(undefined);

      return <ImageAppContext.Provider value={{ user, imagePlugin, setUser }}>
        <NavigationContainer independent={true}>
          <Stack.Navigator initialRouteName="Login" screenOptions={{ animationEnabled: true }} {...{ headerMode: "none" }}>
            <Stack.Screen name="Login" component={ImageLogin} />
            <Stack.Screen name="Register" component={Authenticate} />
            <Stack.Screen name="Editor" component={ImageEditor} />
            <Stack.Screen name="List" component={ImageList} />
          </Stack.Navigator>
        </NavigationContainer>
      </ImageAppContext.Provider>
    });
  }
}

export const ImageApp = ({ imagePlugin }: { imagePlugin: ImagePlugin }) => {

  {/* CONSIDER:  For now, I'm going to keep this isolated.  But we COULD link to an existing Hessia Context hm! */ }
  // TODO:  When the component re-renders it does not seem to properly persist the state of the HaliaComponent!!!
  return (
    <HaliaComponent plugins={[
      { plugin: MenuLayoutPlugin, options: { showHeader: true } },
      { plugin: HaliaStudioPlugin, options: { knownPlugins: [ImageAppPlugin], installedPlugins: [ImageAppPlugin] } }
    ]} />

  );
}