import { HDTRelationshipValue, HSDTRelationship, InstanceInternal, NounInternal } from "habor-sdk";
import moment from "moment";
import * as React from 'react';
import { Text } from "react-native";
import { Button } from "react-native-elements";
import { DatePicker } from "../../../packages/kelp-bar/date-picker";
import { HaborReactComponent } from "../habor-plugin/habor-utils";
import { ModelPlugin, ModelPluginContext } from "../model-plugin";
import { AppContext } from "./AppContext";
import { haborSDK } from "./config";

//  TODO-GENERAL:  SOOO many things in here can be split out to their respective categories.. their systems hmm...

//
//  Generic Filter Component Props
//  TODO:  Remove from this plugin.. not it's responsiblity.. maybe make a NEW "Filter" Plugin.
//  CONCERN:  SHOULD we also support filter-specific prop passage?  Maybe through the CSS-like system!?  THIS way we can pass custom props relevant to the context of the filter instantiation??  Hmmm...
//  CONSIDER:  Renameing "Filter Component" to "Filter Settings"?
//

export interface FilterComponentProps<T = any, P = any> {

  //  TODO:  EWANT to beak this out in te node net hmm... NOT just pass these expiclt pieves hmm.. let it be extensible and stuff? Hm
  //  TODO:  This is for additioanl props passed from other systems... DO this in some more ... supporting way??klasjdf 
  //  TODO:  SHOULD we de-couple this from the "Class" ("Entity") system and support ANY type here?  Hmmmm..  OR maybe a more primitie "Object" or "Thing" type???

  settings?: T;
  setSettings: (settings: T) => void;
  instances: InstanceInternal[];
  additionalProps: P;
  [item: string]: any;
}

export class FilterComponent<T = any, S = any, P = any> extends HaborReactComponent<FilterComponentProps<T, P>, S> { }

//
//  Filter Component Props
//

export interface FieldFilterComponentProps<T> {
  instances: InstanceInternal[];
  propName: string;
  noun: NounInternal;
  modelPlugin: ModelPlugin;
  propSettings: T;
  setPropSettings: (settings: T) => void;
}

export interface FieldFilterSettings {
  propName: string;
}

export type Filter<T> = (instances: InstanceInternal<any>[], token: string, settings: T, noun: NounInternal) => Promise<InstanceInternal[]>;

export type FieldFilter<T> = (instances: InstanceInternal<any>[], token: string, settings: T, noun: NounInternal, propName: string) => Promise<InstanceInternal[]>;

//
//  Relationship Filter
//

interface RelationshipFieldFilterComponentState {
  instances: InstanceInternal[];
}

export interface RelationshipFieldFilterSettings extends FieldFilterSettings {
  selectedInstance: InstanceInternal;  //  TODO-GENERAL:  For ALL the filters don't just hard-code ONE possible selection, support my DYNAMIC stuff like AND / ORs, etc..???  MAYBE even string search.. MAYBE it's up to US to BUILD / MANAGE filters / searchers, etc!??  Hmmm.. maybe this is just ONE way to SELECT!??  Hmmm.. Maybe we can make even more complex rules??? Hmm!!!??  THAT SAID, I think this is a GREAT START!  It gets us ROLLING!!
}

export interface RelationshipFieldSettings {
  selection: any;
}

class RelationshipFieldFilterComponentBase extends React.Component<FieldFilterComponentProps<RelationshipFieldSettings>, RelationshipFieldFilterComponentState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: FieldFilterComponentProps<RelationshipFieldSettings>) {
    super(props);
    this.state = {
      instances: []
    }
  }

  //  TODO-NEXT:  REALLY want more dynamic filter support.. thigns like adding / removing pieces, etc.. each filter IS separated, BUT perhaps we can start using some generic features!?

  public componentDidMount = async () => {

    const { noun, propName } = this.props;
    const { token } = this.context.frameworkContext;

    const prop = noun.properties[propName];

    const type = prop.type as HSDTRelationship;

    //  TODO:  Support multiple Noun IDs.

    const nounId = type.nounIds[0];

    //  TODO:  This SHOULD obey scoping rules in the current CONTEXT!?  For example, MAYBE we have a rule which dictates this should only show items associated with a particular selected Project!?
    const instances = await haborSDK.searchInstances(token, { nounId: nounId.nounId });

    this.setState({ instances });
  }

  public render = () => {

    const { noun, setPropSettings, propName, propSettings } = this.props;
    const { instances } = this.state;

    // return <Text>SHUOLD INJECT ENTITIES FILTER THING</Text>;
    //  TODO-GENERAL:  Here, we're accessing the "Instance Plugin" Halia Plugin instance directly, INSTEAD of with injection... PLUS, a piece of state is being held OUTSIDE the React State tree... are EITHER of these two things acceptable to me!?
    //  TODO:  Move this to the "Entities" PLugin???
    const modelPlugin = this.props.modelPlugin;

    return [(
      instances.map(instance => (

        // CONSIDER:  When we register a "prop" filter, we're actually register a specific type of filter within the "Instance" filter.  We're specifically adding a "PropFilter" which is an encoding interpreted by THIs system. 
        //  TODO:  Use the setPropSettings instead of replacing the ENTIRE prop filter hmm...
        <Button title={instance.payload.name} onPress={() => { setPropSettings({ selection: instance.id }) }} />
      ))
    ),
    <Button title="Undeclared" onPress={() => { setPropSettings({ selection: null }); }} />,
    <Button title="All" onPress={() => { setPropSettings({ selection: undefined }); }} />]
      ;
  }
}
export const RelationshipFieldFilterComponent = (props: FieldFilterComponentProps<RelationshipFieldSettings>) => {
  const modelPlugin = React.useContext(ModelPluginContext);
  return <RelationshipFieldFilterComponentBase {...props} modelPlugin={modelPlugin} />
}


export const RelationshipFieldFilter: FieldFilter<RelationshipFieldSettings> = async (instances: InstanceInternal[], token: string, settings, noun, propName): Promise<InstanceInternal[]> => {

  const { selection } = settings;
  console.log("INSIDE: " + JSON.stringify(selection));

  //  Check "All" Case
  //  NOTE:  CURRENTLY we differentiate between "null" and "unedefined"!  Null means Unnasigned, undefined means ALL values.
  //  TODO-GENERAL:  I want to buid SOME kind of FRAMEWORK, maybe using the local SEARCH API (or local / remote hybrid, OR abstract over that and make that a false dichotomy in our domain) to we can EASILY and DYNAMICALLY configure filters / searchers, etc!???  AND other sorts of widgets??? HMM!!??
  if (selection === undefined) { return instances; }

  const filteredInsts = instances.filter(inst => {
    const relationship: HDTRelationshipValue = inst.payload[propName];

    //  Check "Unassigned" Case
    if (selection === null) {
      if (!relationship) {
        return inst;
      }
    }

    //  Check Specific Selection
    else {
      if (!relationship) { return false; }
      if (relationship.instanceIdList.map(instId => instId.instanceId).indexOf(selection) != -1) {
        console.log("VALID RELATION: " + inst.id)
        // alert("VALID");
        return inst;
      }
    }
  });
  return filteredInsts;
}

//
//  Date Filter
//

//  TODO:  Build a Lgocic System to "Query" or Match.
//  TODO:  Use a NEURAL INTERPRETER to PROJECT between encodings!  More people aren't doing, because it's FOREIGN to MOST developers!  Same with PLUGINS!  NOT many people do these thigns!!!
//         Basically just an endocing for the instruction, like "Does this match this thing?", etc hmm.. OR train specific etc hmm...

export const DateFieldFilterComponent = ({ setPropSettings, propName, propSettings }: FieldFilterComponentProps<{ date: string }>) => {
  const date = (propSettings && propSettings.date) ? new Date(propSettings.date) : new Date();
  return <DatePicker date={date} onUpdate={_date => { if (_date) { setPropSettings({ date: _date.toISOString() }) } }} />
}

//  CONSIDER:  We should NOT be setting ONE filter for a TYPE.  Instead, we should set it on a CONTEXT!  LIKE a type + field name, etc...  Plus, it's only perhaps in THIS context that this need be applied, etc... mmm!
//  TODO:  Fix issue where, if the instance value is changed, it requires a reload!!!

export const DateFieldFilter: FieldFilter<{ date: Date, propName }> = async (instances, token, { date }, noun, propName): Promise<InstanceInternal[]> => {
  const filteredInstances = instances.filter(instance => {
    const selectedDate = moment(date);
    const candidateDate = moment(instance.payload[propName]);
    if (selectedDate.isSame(candidateDate, 'day')) {
      return true;
    }
    return false;
  });

  return filteredInstances;
}

//
//  Type -> Filter Component Map
//

//  Ok... we want to render a filter for EVERY field in the class!  FOR NOW, let's just make placeholders for ALL of these except the "relationship" field??
//  TODO:  Access this via function instead?  Though.. why should it matter?  BECAUSE, there's a SPECIFIC pattern here!  A SPECIFIC encoding, and "direct" manipulation can break that "contract" or "set of expectations"! HM!
export const typeToFilterComponentMap: { [type: string]: React.ComponentClass<FieldFilterComponentProps<any>, any> | React.FunctionComponent<FieldFilterComponentProps<any>> } = {
  "relationship": RelationshipFieldFilterComponent,

  // CONSIDER:  Should be able to construct a filter from more declarative, primitive encoding hmm... like... JUST need to know hmm... maybe give a thing a class?   Express UGH.. again.. types that have PARENT classes mm... 
  "date": DateFieldFilterComponent,
};

export const typeToFilterMap = {
  "relationship": RelationshipFieldFilter,
  "date": DateFieldFilter
}

//  CONSIDER:  For each "type", we have an encoding.  The idea is, we want to MATCH, but... that can be sufficiently general.  Like... "Date on which a president was born".  We can PROJECT for that!  Meaning... have a set of dates and build a projection.  Similar to my dashboard "Show the summed macros for the day".  It will add them up across items, and the system should "know" to use recursion if it needs to... basically, when we DON'T have something, then we try PROJECTING to it!  This is mm!  we CAN build recursion that way mm!  