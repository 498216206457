import { Color } from 'habor-sdk';
import * as React from 'react';
import { View } from "react-native";
import { TextParagraph, TextSubParagraph } from "../../../kelp-bar/text";
import { smallSpacer } from '../../../kelp-bar/styles';
import { renderView } from "../../davel-ui";
import { SDTViewRendererParams } from '../../davel-ui-tools';
import { capitalizeAllWords } from '../../davel-utils';

export const sdtObjectViewRenderer = async ({sdt, value, name, topLevel = false, metadata}: SDTViewRendererParams ) => {
  const { properties = {} } = sdt;

  //  Cast the Value
  const values: { [key: string]: any } = { ...value };

  // Render the Typed Values
  const typedViews: any[] = [];
  for (const propertyName of Object.keys(properties)) {
    const value = values[propertyName];
    const propertySDT = properties[propertyName];
    const view = await renderView({ sdt: propertySDT, value, name: propertyName, metadata });
    delete values[propertyName];
    const typedView = (
      <View key={ propertyName }>
        { view }
        <TextSubParagraph>{ capitalizeAllWords(propertyName) }</TextSubParagraph>
        <View style={{ height: smallSpacer }} />
      </View>
    );
    typedViews.push(typedView);
  }

  //  Render the Untyped Values
  const untypedViews: any[] = [];
  for (const valueName of Object.keys(values)) {
    const value = values[valueName];
    //  TODO:  Move fontFamily italic to component prop logic.
    const untypedView = (
      <View key={ valueName }>
        <TextParagraph style={value ? {} : { fontFamily: 'Poppins-MediumItalic' }}>{ value ? ((typeof value == 'object') ? JSON.stringify(value) : value.toString()) : 'undefined' }</TextParagraph>
        <TextSubParagraph>{ capitalizeAllWords(valueName) }(Unknown Type)</TextSubParagraph>
        <View style={{ height: smallSpacer }} />
      </View>
    );
    untypedViews.push(untypedView);
  }

  return (
  <View style={[ { borderLeftColor: Color.lightGray }, topLevel ? {} : { borderLeftWidth: 2, paddingLeft: smallSpacer } ]}>
    { typedViews }
    { untypedViews }
  </View>
  );
};
