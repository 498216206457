import { HaborComponent, InstanceInternal, NounInternal } from 'habor-sdk';
import * as React from 'react';
import { View, Text } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { InstanceViewProps, RelationshipData } from '../model-plugin/entity/instance-list';

export interface RequestRecordListItemProps extends InstanceViewProps {}
interface RequestRecordListItemState {}
class RequestRecordListItem extends React.Component<RequestRecordListItemProps, RequestRecordListItemState> {
  constructor(props: RequestRecordListItemProps) {
    super(props);
    this.state = {}
  }
  public render = () => {
    return (
      <Text>{ JSON.stringify(this.props.instance) }</Text>
    );
  }
}

//
//  InstanceListItem Primitive Component
//

//  THOUGHT:  Look at this name... it's crazy.  It's several pieces:  "Main" is temporary and distinguishes it from the other "NamedObject" list view (which I didn't namespace).  "Instance" tells us that this view is specific to an "Instance".  "ListItem" tells us that this is a component for an item in a list.  "HaborComponent" indicates that it's a HaborComponent.  "Primitive" indicates that it's specifically a PRIMITIVE, and "Props" indicates that it's a type to be used as props.  SO... we COULD consider breaking all of this up... instead of individually named things, just add ONE element to the "InstanceListViewComponents" thing, and within that, add our props?  In other words... do this stuff in a nested hierarchy instead of flat!  Maybe that's PART of what Habor / Hessia will give us?
export interface RequestRecordListItemPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal<any>;
    noun: NounInternal;
    relData: RelationshipData;
  }
}
export const RequestRecordListItemPrimitive = ({ userProps, frameworkProps }: RequestRecordListItemPrimitiveProps) => {

  //  Unpack
  const { componentContext, context } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <RequestRecordListItem { ...userProps } componentContext={ componentContext } frameworkContext={ context } />
    </HaborContainer>
  );
};

export const RequestRecordListItemHaborComponent: HaborComponent = {
  name: "RequestRecordListItem",
  propsSchema: { type: "object", extensible: true },  //  TODO:  Type this!
  classes: ["named-object"],
  element: {
    name: "RequestRecordListItemPrimitive",
    props: {
      instance: { type: "symbol", scopePath: ["props", "instance"] },
      noun: { type: "symbol", scopePath: ["props", "noun"] },
      relData: { type: "symbol", scopePath: ["props", "relData"] }
    },
    children: []
  }
};