import { Picker } from '@react-native-picker/picker';
import { InstanceInternal, TMSerializedNoun } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { haborSDK } from '../../../../plugins/hessia-plugins/hessia-plugin/config';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

export const NounFieldStyle = [
  {
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    color: '#333333',
    borderColor: '#444444',
    borderWidth: 1,
    minHeight: 37,
  },
];

export interface NounFieldProps {
  token: string;
  sdt: TMSerializedNoun;
  update: (selectedNounId: string) => void;
  value?: string;
}

export interface NounFieldState<T> {
  instances?: InstanceInternal<T>[];
  selectedInstanceId?: string;
}

//  NOTE:  This is the DEFAULT NounField renderer.  We SHOULD support overrides!
//  NOTE:  These fields should NOT be coupled with Redux Form!  IF we want to wrap in Redux Form, it should be done in ANOTHER layer!
//  TODO-IMPORTANT:  I think it makes a LOT of sense to make components for EACH Davel type!  This way, we can compose them and make overrides without a "Custom Type"?  A Custom Type is really just a Schema which doesn't have a DB representation by itself.  It's not a "Model", because a Model is stored in the DB.  We use this for things like "Color" where we DON'T need the reverse lookup!  In Django, they use Abstarct Models for this, BUT we're not going to do that.  Our Abstract Models are still stored in their own tables.  We COULD consider changing this... BUT, the reason, is that I DON'T want users to be aware of that implementation detail... although this "CustonType" system does expose it to some degree... Well... not really, they don't need to know HOW it's stored.  They just need to know that it's part of the same "Model", which MAY be gropued in tables?
export class NounField<T> extends React.Component<NounFieldProps, NounFieldState<T>> {

  constructor(props: NounFieldProps) {
    super(props);
    this.state = {
      selectedInstanceId: props.value  //  NOTE:  This internal state conflicts with the Redux Form model.  I'd consider letting the CALLER store the state and update with "value".  I think that makes a lot of sense.
    }
  }

  public componentDidMount = async () => {

    //  Unpack
    const { sdt, token } = this.props;

    //  Search Instances
    const instances = await haborSDK.searchInstances(token, { nounId: sdt.nounId });

    //  Set Instances
    this.setState({ instances })
  }

  public render() {

    //  Unpack
    const { value, update } = this.props;
    const { instances, selectedInstanceId } = this.state;

    //  Guard
    //  TODO:  Unify the "Loading" pattern across the package.
    if (!instances) { return (<Text>Loading</Text>) }

    //  Render the Field
    //  TODO:  Let the user configure which field is shown for this basic picker (depends on the Noun).  HOWEVER, this shuold porobably be set as a dependency to the noun... This way, when the noun changes, we can validate this.  Very interesting actually... We are performing a type of static analysis with the code elements in Habor.  We can use a cache to make sure we don't re-evaluate too often.
    const options = instances.map((inst) => inst.id);
    const labels = instances.map((inst) => JSON.stringify(inst.payload));
    const optionElems = options.map((option, i) => <Picker.Item label={labels[i]} value={option} />);

    return (
      <Picker
        selectedValue={value}
        onValueChange={update}>
        {optionElems}
      </Picker>
    );
  }
}

//  TODO:  Reevaluate this pattern... I'm not a big fan of specialized UI here and querying Habor in the Davel code...
export const sdtNounRenderer = ({ sdt, name, metadata: { token }, update, value }: SDTRendererParams) => {
  return (
    <DavelField required={sdt.required} key={name} name={name}>
      <NounField key={name} sdt={sdt} token={token} update={update} value={value} />
    </DavelField>
  );
};
