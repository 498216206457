import { NavigationProp } from "@react-navigation/core";
import { InstanceInternal, NamedObject } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { ModelPlugin } from "../model-plugin";
import { EntityPlugin } from "../entity-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { haborSDK } from "../hessia-plugin/config";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { AppsPluginContext } from "./apps-plugin-context";
import { AppsNavigator } from "./apps.nav";
import { HAppRoute } from './apps.nav.routes';
import * as React from "react";
import { Button } from "react-native";
import { AppContext } from "../hessia-plugin/AppContext";

//  TODO:  We have TWO entity concepts... move them together... and CONSIDER making it so the MAIN entity system is where a lot of stuff is displayed.. but AGAIN.. an entity in a CONTEXT can have a DIFFERENT UI HM!  JUST like what I was doing in TrackMine where I had things being displayed based on the sub-type and like... yeah hmm....

//  NOTE:  These id, name, systemId, seem to be a COMMON pattern hm!
// export interface ElementRenderer {
//   id: string;
//   name: string;
//   systemId: string;

// }

export interface ElementPressHandler extends NamedObject {
  condition: (inst: InstanceInternal) => boolean;
  handler: (inst: InstanceInternal, navigation: NavigationProp<any>) => void;
  systemId: string;
}

export const AppsPluginID = 'appsPlugin';
export class AppsPlugin extends CorePluginClass {
  public static details = {
    name: "Apps",
    description: "Build Apps",
    //  TODO-SOON:  Fully de-couple PagePlugin by having PagePlugin inject!
    //  TODO-NOW:  Make it possible fo this to depend on the PAGE plugin, because it uses it!
    //  TODO:  De-copule Entities??
    dependencies: [SystemPlugin.details.id, AuthPlugin.details.id, EntityPlugin.details.id, ModelPlugin.details.id, HessiaPlugin.details.id],
    id: AppsPluginID
  }

  //  AH!  We can register ean encoding that THIS conctext willl interper OR one that a more primitive context will like hmmmmm
  //  CONSIDER:  AGAIN!  THis is SUPER farmiliar... it's the SAME kind of thing hmm... as ENTITY and HALIA Hmm.... we have things that are ASSOCIATED hmm... things that are like hmm... in this case considred PART OF the systen hmasdfds ...  MAYBE it's up to EACH system to determine WHAT that "means" and stuf fhasldffdskalj !!

  //  TODO:  Genralize using CSS-like system!!!

  //  NOTE:  This REGISTRATION system is a COMMON pattern!!!  I want to GENERALIZE it and apply it to things ugh!  Make an INSTANCE of the pattern.. and in MANY cases.. I'm registerin ghte SAME "class" of thigns.. SO do it centrally with fuckign refernces and shit hasdlfksajdf 
  private elementPressHandlers: ElementPressHandler[] = [];
  public registerElementPressHandler = (handler: ElementPressHandler) => {
    this.elementPressHandlers.push(handler);
  }

  public getElementPressHandlers = () => {
    return this.elementPressHandlers;
  }

  //  TODO:  De-couple navigation!
  public applyElementPressHandler = async (inst: InstanceInternal, navigation: NavigationProp<any>) => {

    //  TODO:  Support CSS-like system overrides and stuff.
    //  FOR NOW:  We just hit the first one in the list.
    //  NOTE:  We can have MULTIPLE press handlers assigned.  The IDEA is to build it like the CSS system.  We choose the one based on greates contextual specificity.  We do this with EVERYHING in the app hmm.. I THINK we even have the code for this already.  It KINDA reminds of of the other thing.. ugh.. but again... VERY common in CSS, and it's NOT about "inheritance" per-se, more about selection / contextualizing ughhh... 
    //  FOR NOW:  We just trigger all the press handlers?  I think we trigger whichever one comes first.. ughh...LOTs of things have defaults thoguh ughh... nothing crazy about that... 
    //  TODO:  DON'T use a hard-coded thing for ANY instances!  It should broadcast an action for our systems to handle???
    //  TODO:  This should be injected from the Page System!!!
    //  TODO:  Automatic client-side caching???  So we can qery for shit we need from ANYWHERE and just get it client-side if it’s available???

    for (const handler of this.elementPressHandlers) {
      const hit = await handler.condition(inst);
      if (hit) {
        await handler.handler(inst, navigation);
        return;
      }
    }

    //  Default Handler
    const noun = await haborSDK.retrieveNoun(inst.nounId, this.authPlugin.token);
    navigation.navigate(HAppRoute.InstanceBuilder, { instance: inst, noun });
  }




  //  TODO:  Consider NOT injecting this? Hmm.... we already inject its pieces hmm.. TH POINT is... NO encoding is separate!  It's a NETOWRK and we can group in LOTS of ways.. but there is NO "domain".  Things have connections ACROSS encodings .. reference ALL over the place! hm!  It IS more like spaghetti and we can EMBRACE that as long as it can be VIEWED in a way, a CROSS SECTION that makes it manageable hm!
  //  TODO:  Inject this with a "UserSystem" plugin thing? Hmm...  Make a better name ahah

  //  CONSIDER:  SHOLD be able to condense Hessia and Systems Plugins, AND we shold be able to use HALIA to BUILD "Apps" IN fucking Hessia hm!  THEN be able to edit ANYTHING in Halia? HM!  MOST things that were CREATEd there hm!

  private authPlugin!: AuthPlugin;
  public install = (program: Program, { system, pEntities, authPlugin, hessia }: { system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin, hessia: HessiaPlugin }) => {

    hessia.registerAppNavHOC(({ children }) => {

      return (
        <AppsPluginContext.Provider value={{ appsPlugin: this }}>
          {children}
        </AppsPluginContext.Provider>
      );
    })

    this.authPlugin = authPlugin;
    system.registerPrimitiveSystem({
      id: 'apps',
      name: "Apps",
      description: "Build Habor Apps",
      component: AppsNavigator,
      labels: ["core"],
      menuItem: {
        icon: { name: "apps", type: "material" },
        backgroundColor: "#e64e79",
        iconColor: "white"
      }
    });

    return this;
  }
}
