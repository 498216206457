import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { EventEmitter } from "events";
import * as Linking from 'expo-linking';
import { InstanceInternal, NounInternal, Plugin, Workspace, pluginNoun } from "habor-sdk";
import { CorePluginClass, Program as HaliaProgram } from "halia";
import * as React from "react";
import { Button, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Icon } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { capitalizeAllWords } from '../../../packages/davel-ui/davel-utils';
import { AlertBox } from "../../../packages/kelp-bar/alert";
import { CardModal } from "../../../packages/kelp-bar/card-modal";
import { KelpIcon } from '../../../packages/kelp-bar/kelp-icon';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { defaultBorderRadius, medSpacer, primaryFontFamilyHeavy } from "../../../packages/kelp-bar/styles";
import { TextParagraph, TextSubTitle } from '../../../packages/kelp-bar/text';
import { MemoryNounService } from '../../../packages/noun-service/noun-service';
import { GalleryButton } from '../../gallery/components/common';
import { useSizes } from '../../gallery/sizes-helper';
import { HAppRoute } from '../apps-plugin/apps.nav.routes';
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from '../hessia-plugin';
import { AppContext } from "../hessia-plugin/AppContext";
import { haborSDK } from "../hessia-plugin/config";
import { getPlugins } from './plugin/plugin-tools';
import { SystemMenu } from "./system-menu";
import { RootRoute, System } from "./system-types";

//  Add a "Primitive" System

//  NOTE:  EVEN in JS when we ADD to a thing it's just a REFERENVE which is then AUTOMATICALLY resolved when we attempt to access! HM!

//  NOTE:  PERHAPS a system can have MULTIPLE injections onto this screen? Hmm  BUT that means therre's STILL a place where we have every system.  SO.. might as well have this be that place.  Again, these are UIs associated with primitive encodings hm!  POSSIBLY ones that use OTHER primitive encodings, so perhaps think of them more like primitive PATTERNS HM!

//  TODO:  Everything should be registered within Halia Plugins?  THIS way we have the injection? Hmm.. MAYBE we can do it in functions which inject into the module though? Hmmm...


export interface SystemsProps { }
interface SystemsState { }

export const Systems = () => {

  //  NOTE:  Because it's mounted in the main app, this should NOT be triggered until AFTER the plugin stack completes!
  // alert("SYSTEM RENDER")

  const context = React.useContext(SystemPluginContext);
  const navigation = useNavigation();
  const [systems, setSystems] = React.useState<System[]>(context?.systemPlugin.getSystems() || []);

  // const onSystemsUpdated = (systems) => {
  //   setSystems(systems);
  // }

  // React.useEffect(() => {
  //   //  TODO:  ALL of this shouldb e in the framemwork!  MUCH like REACT.. hmm.. SIMPLIFY it though?  We have a THING.. and ENCODING.. and it can CHANGE... things can DEPEND upon it.. hmm... BUT.. somethings things depend upon it, BUT they're also triggered externally and shit.. so WHY would we want to reproduce it?  MAYBE we just put it in the space? Hmm
  //   context?.systemPlugin.emitter.addListener(SystemChangeEvent, onSystemsUpdated);
  //   return () => { context?.systemPlugin.emitter.removeListener(SystemChangeEvent, onSystemsUpdated); }
  // }, []);

  // alert(JSON.stringify(systems));

  // alert(JSON.stringify(systems));

  //  Maybe the idea is we have a list.. then we can process the list one at a time OR we can GRROUP and then render like that? Hm... we want sysetms to be able to add new registers? Hmm.. MAYBE we need coupling systems to make all this work? HMm the idea is one can process the ones, and one can group adn theat still uses the origal sinel processor, but maybe single processig chages with the introductio of the group? Hmm...   Again.. everything is just encodings being interrped... hmm.. but an encoding can BE an interpreter.. hmm... Maybe we produce the various encodings (data) we need and dynamically choose the renderer afterr that? Hmm... 

  //  Group
  //  TODO:  I DON'T like how this couples with this more primitive encoding.  I mean... I know we're REFERECING FlatGrid and all, but still... just seems like we SHOULD be able to enable / disable this feature WITHUOT influencing this encoding direcly.    
  //  THOUGHS:  The idea is, we GROUP the items, then for each group we produce an encoding (React Element) which can be combined and rendered.  So...  maybe there's a way to do that.. BUT the ORIGINAL thing was still producing for each item.. MAYBE the idea is, we just have a renderer for each item... BUT we can GROUP and PROCESS any way we wan BEFORE we hit the rederer.  THEN, we dynamically select the next one BASED on that encoding metadata, like which "group" it's a part of and stuff?? HM!  MAYBE that just adds context HM!  The POINT then, is that we have a SIPMLE "List" renderer and we choose how to display based on the context and stuff NOT hard-coding? HM!  I THINK I like taht approach? HM!  THEN, these "Couplig systems" are lIKE the CSS-like system.  This way, we progressively determine how something is displayed.  WHAT then has access?  If 3 things link here.. hmm  like one to render the items, then another to GROUP them? Hmm.. MAYBE we can make a distinction at EACH level between "Middleware" and "Leaf" node? HM!  THEN, the MIDDLEWARE is always given prioity? HM!  Intersitng.. WHAT then when we have MULTIPLE middlewares? Hmm... we're just attaching to abstract render thing too! HM!  MAYBE both are options and we can set a default?  MAYBE the idea is if NONE is set, we DEFAULT to just rendering each.  BUT if we add Middleware, that works too. hmm..  MAYBE though, we want multiple Middleware to combine... MAYBE if that's the case we can use a Coupling system to indicate that... hmm... getting closer I think!  
  //  NOTE:  I DO like the idea of an internal data structure that "knows" that it's going to be an array for each dict item so we don't have to check.. hmm.. we CAN use a FUNCTION for this, BUT with the ability to build new SYNTAX, and stuff, that's really nice too.. a PLUGGABLE language like that? HM!
  const groups: { [label: string]: System[] } = {};
  systems.forEach(system =>
    system.labels.forEach(label => {
      if (!groups[label]) { groups[label] = []; }
      groups[label].push(system)
    })
  );


  const { isMobile, isDesktop, isTablet } = useSizes();

  return (
    <PageLoader loading={false}>
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ padding: 20 }}>

          {/* <Button title="OPEN MENU" onPress={ () => alert("SHOLD be injected by a Coupoling plugin and open the Drawer!") } /> */}
          {/* <View style={{ backgroundColor: 'blue' }}>
        { component }
      </View> */}

          {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
          {/* <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Systems</Text> */}

          {/* CONSIDER:  We CAN have some built-in systems AND perhaps let the user install "Plugins" too! HM! */}
          {/* <Text>SHOLD BE + BUTTON</Text> */}
          {/* <TouchableOpacity onPress={ () => navigation.navigate("user-systems" as never, { screen: SystemRoute.InstanceBuilder, params: { noun: context?.systemPlugin.systemNounInternal }} as never) } style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View> */}


          {/* <View style={{ marginVertical: 10 }}> */}
          {/* <TextSubParagraph style={{ fontSize: 15 }}>{ capitalizeAllWords(groupName) }</TextSubParagraph> */}
          {/* <View style={{ height: 5 }} /> */}
          {/* </View> */}
          {
            // TODO:  DEFINILTEY want to INJECT this DON'T like how it's hard-coded ugh!  Like... inject HOW?  It's ONE example of a common theme.. a way to Group the elements.. BUT it also changes the UI hmm...  I want to inject it BUT with a sifficiently generic API.  MAYBE a node graph and we have the interpreter use that hmm ... I THINK we're HEADED there hm!  Where we have like ECS, entity component syste hm!  I HATTEEEE the idea that domains aren't fucking connected it's BS!!!  Parallel Computing and Game Dev, and APp Deve, etc.. they EACH have their own contextual things, BUT those contextual thingsa re EXAMPLES of INSTANCES UGH!  It's about learning that DEEPLY so we can TAG in that way and have access to that fucking domain!  Ths opint is it's just WORDS!  SO IS "SCIENCE"!!!! It's a WORD..  There are ASSOCIATED "words" and "concepts" WHICH are JUST the imagary and sensory experience when we're influenced by this word and shit? Hmm mabyee  I think PERHAPS the SYMBOL / ENCODING DOES make a diference hM!  If I called "Science", "Toppum" then all of a sudden it seems unique!  It's NOT "Scince" hmm.. the idea is, we can re-define it with a blank slate hM!  OVSERCATION is the main thing hm!
            Object.keys(groups).map(groupName => {
              const group = groups[groupName];  //  NOTE:  This is a common pattern too.. iterating the element in a Dictioanry.. hmm
              return <>
                <TextSubTitle>{capitalizeAllWords(groupName)}</TextSubTitle>
                <FlatGrid style={{ marginHorizontal: -5 }} scrollEnabled={false} itemDimension={125} horizontal={false} itemContainerStyle={{ padding: 0, overflow: 'visible' }} spacing={5} data={group} renderItem={({ item: system }) => (
                  <TouchableOpacity style={{ borderRadius: defaultBorderRadius, backgroundColor: system?.menuItem.backgroundColor, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: 125, overflow: 'hidden' }} onPress={() => system.onPress ? system.onPress() : navigation.navigate(system.id as never)}>
                    <Icon name={system.menuItem?.icon.name || "question"} type={system.menuItem?.icon.type || "material"} color={system?.menuItem.iconColor} size={30} />
                    <View style={{ height: 7 }} />
                    <TextParagraph style={{ textAlign: 'center', fontSize: 15, color: system?.menuItem.iconColor, fontWeight: "900", letterSpacing: -0.75 }}>{system.name}</TextParagraph>
                  </TouchableOpacity>
                )} />
              </>
            })
          }

          <GalleryButton title="Logout" onPress={() => context?.systemPlugin.authPlugin.logout()} />
          <View style={{ height: 5 }} />
          <GalleryButton title="Clear" onPress={() => { AsyncStorage.clear(); alert("Cleared!"); }} />
        </ScrollView>

      </View>
    </PageLoader>
  );
}


//  CONSIDER:  Remove the wrapping object and just store the plugin.
export const SystemPluginContext = React.createContext<{ systemPlugin: SystemPlugin } | undefined>(undefined);

export const useSystemPlugin = () => {
  const systemPluginContext = React.useContext(SystemPluginContext);
  if (!systemPluginContext) throw "Could not obtain the System Plugin Context."
  return systemPluginContext.systemPlugin;
}

// export interface SystemComponentPrimitiveProps extends PrimitiveProps {
//   userProps: {
//     instance: InstanceInternal;
//   }
// }
// interface SystemComponentPrimitiveState {
//   systemAssociations: InstanceInternal<SystemAssociation>[];
//   systems: InstanceInternal<Plugin>[];
//   newAssignment: boolean;  //  TODO:  This should be removed in favor of a de-copuled experience / workflow!!!
//   allSystems: InstanceInternal<Plugin>[];
// }

// class SystemComponentPrimitive extends HaborReactComponent<SystemComponentPrimitiveProps, SystemComponentPrimitiveState> {
//   constructor(props: SystemComponentPrimitiveProps) {
//     super(props);
//     this.state = {
//       systemAssociations: [],
//       systems: [],
//       newAssignment: false,
//       allSystems: []
//     }
//   }

//   public componentDidMount = async () => {

//     //  Unpack
//     const { instance } = this.props.userProps;
//     const { token } = this.context.frameworkContext;

//     //  Get System Enablements 
//     const { associations, systems } = await getInstanceSystems(instance, token);

//     //  Get All Systems
//     //  Get Systems!
//     //  TODO:  Should NOT be a resonsibility of this module!!!  Just doing this so we can get the systems to list and select! HM!  SHOULD be scoped by CONTEXT, and USER, and perhaps the associated UI shold show which pieces are OWNED vs installed vs AVAILABLE for install, etc!? HM!  Maybe which are part of the users ORGANIZATION, etc!? HM!
//     const allSystems = await haborSDK.searchInstances(token, { nounId: pluginNoun.id });

//     //  Update State
//     this.setState({ systemAssociations: associations, systems, allSystems });

//   }

//   private deleteEnablement = async (association: InstanceInternal<SystemAssociation>) => {
//     const { token } = this.context.frameworkContext;
//     await haborSDK.deleteInstance(association.nounId, association.id, token);
//     this.forceUpdate();
//   }

//   private createEnablement = async (system: InstanceInternal<Plugin>) => {
//     const { token } = this.context.frameworkContext;
//     const { instance } = this.props.userProps;
//     await haborSDK.createInstance<SystemAssociation>({
//       nounId: SystemAssociationNoun.id,
//       payload: {
//         destId: {
//           instanceId: system.id,
//           nounId: system.nounId,
//           type: EntityType.Instance
//         },
//         srcId: {
//           instanceId: instance.id,
//           nounId: instance.nounId,
//           type: EntityType.Instance
//         }
//       }
//     }, token);
//     this.setState({ ...this.state });
//     this.forceUpdate();
//   }

//   public closeModal = () => {
//     this.setState({ newAssignment: false });
//   }

//   public openModal = () => {
//     this.setState({ newAssignment: true });
//   }

//   public render = () => {

//     //  Unpack
//     const { context, componentContext } = this.props.frameworkProps;
//     const { instance } = this.props.userProps;
//     const { systemAssociations, systems, newAssignment, allSystems } = this.state;

//     return (
//       <HaborContainer frameworkProps={ this.props.frameworkProps } style={{ display: 'flex', flexDirection: 'row', marginBottom: largeSpacer }}>
//         <DavelField name="System">
//           {/* TODO:  HERE, I'd like to call a KNOWN experience to obtain a system selection!  Then, that thing should RETURN... this way, I don't need to COUPLE this with the UI to get the system!!  FOR NOW, I'll just hard-code it... ugh */}
//           <Button title="New Association" onPress={() => { this.openModal() }} />
//           <Modal visible={ newAssignment }>
//             <ScrollView>
//               {
//                 allSystems.map(system => <NamedObjectItemComponent onPress={ () => { this.createEnablement(system); this.closeModal(); } } item={{ ...system.payload, color: system.payload.color ? renderRGBAColor(system.payload.color) : '#555555' }} />)
//               }
//             </ScrollView>
//             <Button title="Close" onPress={ () => { this.closeModal() } } />
//           </Modal>
//           {
//             systemAssociations.map(association => {
//               const system = systems.find((value, i) => value.id === association.payload.destId.instanceId);
//               return (
//                 <Card>
//                   <Text>{ system?.payload.name }</Text>
//                   <Button title="Delete" onPress={ () => this.deleteEnablement(association) } />
//                 </Card>
//               )
//             })
//           }
//         </DavelField>
//       </HaborContainer>
//     );
//   }
// }

const missingReload = () => console.log("Missing Reload")

export interface HandlerPayload<T> {
  handlerId: string;
  handlerPayload: T;
}

export interface SystemHandlerPayload {
  systemId: string;
}

export const SystemChangeEvent = "system-change";

/**
 * System Plugin
 * The primary purpose of the Plugin is to inject a screen to show primitive systems.  
 * TODO:  IF we want to show user-systems on this page, do that in another Plugin? Hmm...
 */
export class SystemPlugin extends CorePluginClass {

  public systemService = new MemoryNounService<System>();

  public static details = {
    name: "System",
    description: "Hessia System Plugin",
    //  ALSO internally depend upon HApp and System plugin hmm...
    //  TODO:  Reference with the symbol?  Currently there seems to be a circular dependnecy.  Consider separating the symbol from the Plugin.
    dependencies: [HessiaPlugin.details.id, HaborPlugin.details.id, AuthPlugin.details.id],
    id: "system"
  }

  public authPlugin!: AuthPlugin;

  //  TODO:  SHould record Plugin here??
  //  NOTE:  AGAIN!  Remembeer that we can CONCEPTUALLY think of this as ONE BIG ARRAY!  Just with deliniation hm!
  public unregisterSystem = (id: string) => {
    //  NOTE:  WHYYY have two "FUNCTIONS" for findIndex and find.. they're the SAME shit.. THIS is where the NODe network can fucking help and people in that paper domain are mistaken because they didn't properly include abstraction or multiple thing when EVERYTHING CAN BE RELATEDD!!!
    this.systemService.delete(id);
  }

  public systemNounInternal!: NounInternal;

  //  CONSIDER a way to hook things .. listen to them and sthi hmm... like variables. hmm keep pushing on that model hmm.. intial and change? Hmm... BUT yeah.. nice to be reactive, so it automatically changes the output hmmmm... I DO see where that can be helpful hmm...CAN have imperative things on change thoguh hmm...
  public emitter = new EventEmitter();

  //  TODO-NEXT:  Make sure we have this in state, SO when we add to it, it'll actually update!  We COULD do an explicit Event LIstener.. but the satet litener is handling a lot of that automaticall?? HM!
  //  CONSIDER:  This is JUST piping through to Hessia..not doign much else.. WHY don't we just COMBINE the two with a single like... hmm... Dashboard component? Hmm.. BUT again.. THAT can be SWAPPED for other things too hm!!  It's about using a standard interface hlkasdjf !
  //  CONSIDER:  Uhhhmm... looks like it's JUST wrapping Hessia... WHY don't we have another SYSTEM navigator nested in it??? hmm.. WOULD like to bea ble to RENEST plugins dynamically !!  RENROUTE the tree hm!
  public registerPrimitiveSystem = (system: System) => {



    this.systemService.create(system, system.id);

    this.hessia.registerRoute({
      name: system.id,
      title: system.name,
      headerRight: system.headerRight,
      component: system.component,
      options: {
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }
    });

    // this.emitter.emit(SystemChangeEvent, this.systems)
  }

  public openSystem = (id: string) => {
    this.hessia.navigation.navigate(id);
  }

  public getSystems = () => this.systemService.retrieveAll().map(internalSystem => internalSystem.payload);


  public hessia!: HessiaPlugin;

  public install = async (program: HaliaProgram, { hessia, habor, authPlugin }: { hessia: HessiaPlugin, habor: HaborPlugin, authPlugin: AuthPlugin }) => {

    this.hessia = hessia;
    this.authPlugin = authPlugin;

    //
    //  Get System Noun
    //
    // alert({ pluginId: pluginNoun.id, token: authPlugin.token })
    const systemNounInternal = await habor.haborSDK.retrieveNoun(pluginNoun.id, authPlugin.token);
    this.systemNounInternal = systemNounInternal;

    //     <>
    //     {/* TODO:  DON'T copule direclty with Route Stack! */}
    //     {this.getSystems().map(system => <RootStack.Screen name={system.id} children={system.component} />) || []}
    //     <Systems />
    //   </>
    // ),


    //
    //  Inject Routes
    //

    //  TODO:  Inject as a Navigator?  OR build out our Custom navigation solution...

    hessia.registerRoute({
      name: RootRoute.SystemMenu,
      component: ({ navigation, route }) => {

        const [visible, setVisible] = React.useState(true);

        return (
          //  NOTE:  When we're here, it's assumed that th SYSTEM selection has been made in the context!??  STILL keep working on INTER-APP Context!?  MAYBE I really need to start blogging about these ideas!?
          //  CONCERN:  These routes make this navigator HIGHLY knowledgeable and coupled to the particular screen / "experience" we're trying to access.. INSTEAD, I DO like the idea of DEPENDING upon a system that knows this, and invoking an ACTION to get to this!  This way it's not SO
          <CardModal visible={visible} onCancel={() => navigation.goBack()}>
            {/* TODO:  Shouldn't be COUPLED with so much info.. want an Experience system to register an experience or "Intent" which we can resolve hmm kidna like Android and yeah? Hmm...  But with a way to share back? */}
            {/* <Text>Shuold be ENTITY BUILDER from HApp Plugin?  INJECT??</Text> */}
            <Button title="Entity Builder" onPress={() => navigation.navigate(HAppRoute.EntityBuilder)} />
            <Button title="Close" onPress={() => { setVisible(false); }} />
            <SystemMenu />
            {/* <Button title="New Page" onPress={ async () => { await haborSDK.createInstance({ nounId: "page", payload: { name: "New Page", description } }, token) } } /> */}
            {/* TODO:  AHH!!!  FOR NOW, we can go into other systems, make an instance, which MAY be owned by our SPACE, BUT perhaps change the ownership to this System?  SO!  MAYBE when we're in a system we have the interface we have in "Space"!? Hm!! */}
            {/* TODO:  We SHOULD display options for building nistances and stuff in DEPENDNET syst4ems!!!  MAYBE with widgets thaat can be added to THIS quick view!?</Text> */}
          </CardModal>
        )
      },
      options: {
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }
    });

    hessia.registerRoute({
      name: RootRoute.Systems,
      component: Systems
    });

    hessia.setOptions({
      headerMode: 'hidden',  //  NOTE:  Switch to 'screen' to show the header.  Switch to 'hidden' to hide it.
      cardStyle: {
        backgroundColor: 'transparent',

        // paddingTop: 10

      },
      headerTitleContainerStyle: {
        // paddingLeft: 12
      },
      headerStyle: {
        backgroundColor: 'white',
        shadowColor: 'transparent',
        borderBottomWidth: 1,
        bottomBordercolor: '#eeeeee',
        height: 60

      },
      // headerTintColor: 'transparent',
      headerTitleStyle: {
        fontSize: 18,
        fontFamily: 'Inter-Bold',
        color: '#333333'
      },

      headerLeft: () => {

        const navigation = useNavigation();

        return (
          navigation.canGoBack() ?
            <View style={{ marginLeft: 20 }}>
              <KelpIcon size={16} color="#333333" type="font-awesome" name="chevron-left" onPress={() => navigation.goBack()} />
            </View> :
            null
        )
      }

      // headerRight: () => {

      //   const navigation = useNavigation();
      //   const context = React.useContext(SystemPluginContext);

      //   return (
      //     // <Text>SHOLD BE link to instance builder.. WHY is that in HAPP ??</Text>
      //     // TODO-CYCLIC-DEPENDENCY:  I removed the SYMBOL "InstanceBuilder" and hard-coded it until we separate the System implementation... which uses an Instance, hmmm... it is a bit cyclic...  The opint is... we build an ENTITY BUT ah!  The BACKEND bits can be independentn? Hm
      //     <TouchableOpacity onPress={ () => navigation.navigate("user-systems" as never, { screen: "InstanceBuilder", params: { noun: context?.systemPlugin.systemNounInternal }} as never) } style={{ width: 34, height: 34, borderRadius: 17, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center', marginRight: 15 }}>
      //       <Text style={{ color: 'white', fontSize: 25, fontFamily: "Poppins-Bold" }}>+</Text>
      //     </TouchableOpacity>
      //   )
      // },
    });

    {/* TODO:  I DO believe these top level things should be INJECTED via theier respective systems!? HM! */ }

    hessia.registerRoute({
      name: RootRoute.AlertBox,
      component: ({ navigation, route }) => {

        const AlertBoxWrapper = () => {

          const { title, icon, description, onContinue } = (route?.params as any);

          const [visible, setVisible] = React.useState(true);

          return (
            <AlertBox title={title} icon={icon} description={description} onContinue={onContinue} visible={visible} onCancel={() => { navigation.goBack() }} />
          )
        }
        return <AlertBoxWrapper />

      },
      options: {
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }
    });


    hessia.registerRoute({
      name: RootRoute.SystemSelector,
      component: ({ navigation, route }) => {
        const SystemSelector = () => {

          const context = React.useContext(AppContext);
          const { token } = context.frameworkContext;

          const onSelect = (route?.params as any).onSelect;

          const [visible, setVisible] = React.useState(true);
          const [systems, setSystems] = React.useState<InstanceInternal<Plugin>[]>([]);

          const getSystems = async () => {
            const _systems = await haborSDK.searchInstances(token, { nounId: pluginNoun.id })
            setSystems(_systems);
          }

          React.useEffect(() => {
            getSystems();
          });

          return (
            <CardModal style={{ display: 'flex' }} visible={visible} onCancel={() => navigation.goBack()}>
              <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  {systems.map(system => (
                    <TouchableOpacity onPress={() => { setVisible(false); onSelect(system); }} style={[{ marginTop: medSpacer, backgroundColor: '#f2f2f2', borderRadius: 25, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }]}>
                      <Text style={{ color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>
                        {system.payload.name}
                      </Text>
                    </TouchableOpacity>
                  ))}
                  <Button title="Close" onPress={() => { setVisible(false); }} />
                </View>
              </ScrollView>
            </CardModal>
          )
        }
        return <SystemSelector />

      },
      options: {
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }
    });


    //  CONSIDER:  MAYBE make an "injection group" to make it easy to replace hmm..

    // hessia.registerOnMount(() => {})
    const injectSystems = async ({ space, token, navigation, forceUpdate }: { space: InstanceInternal<Workspace>, token: string, navigation: NavigationProp<any>, forceUpdate: any }) => {

      //  TODO:  Inject the systems with another plugin.. this is for injecting "User" plugins...
      // const installedPlugins = await getSystemsForWorkspace(space, token);

      //  NOTE:  We have TWO different flow now... the one where we have a componetn and one where we have an onPress... possibley both.  The point is we added complexity by chanin ht encoding AND the INTERPRETER HM!  MAYBE we can INJECT that as a FEATURE or like... build it as an auxiliary system that has CLEAR boundaires JUST because it's assocaited with that label hm!!!
      // installedPlugins.forEach(plugin => {
      //   hessia.registerPrimitiveSystem({
      //     id: plugin.id,
      //     name: plugin.payload.name,
      //     description: plugin.payload.description || "",
      //     onPress: () => navigation.navigate("user-systems", { screen: SystemRoute.SystemConfig, params: { plugin } }),
      //     component: () => null,
      //     labels: ["custom"],
      //     menuItem: {
      //       icon: plugin.payload.icon || { name: "dot", type: "material" },
      //       backgroundColor: renderRGBAColor(plugin.payload.color || { r: "0", g: "0", b: "0" }),
      //       iconColor: "white"
      //     }
      //   })
      // })

      //  Reload App
      // alert("Attempting update")
      // alert(forceUpdate)
      forceUpdate();
    }

    //  Get the Systems
    //  NOTE:  The IDEA is I'd like to do this with a Plugin hm THEN I can choose options like how they show up and stuff beause they're just ONE encoding and shit hm! UGH!
    //  TODO:  Update this when the WORKSPACE changes...  FOR NOW, we can PERHAPS do it onLoad of the main page? Hmm... But... do we REALLY want to re-do it if we don't need to.. hmm.. I HATE that article about far transfer not being a thing FUCK that!   THEIR "data" "proves" it's not, but it's THEIR conclusion.  It's reproducible, but that's just THEIR implementaiton.  It says NOTHING about others and the THEORY / MODEL that we can create to explain it UGH!  What I learned in MATH and in BIOLOGY start to look similar and I see the RELATION and then BOOM I'm projecting DECISIONS and NEURONS into linear algrebra because one is an INSTANCE of the other UGH... EVEYRHING is!  JUST like one programmin language and anotheer  it's SO DUMBBB to think that things are different.  They ARE the same at a level UGH!  I NEED to find more fucking people like me ughhh! We refute SCIENCE for SPECIFIC reasons.. it's a WORD.  It is JUST observations... NO observation is any more important than another perhaps.. why should they be?  More people?  BUT what if ALL I need is ONE to prove that it happened ughh  We don't need an army of people!! ugh.
    //  TODO:  Decopule from the Space system.. FOR NOW, I think it's oK to keep it.. hmm Also GENERALIZE and build a GENERIC dependency system LIKE react withotu excpliclty "listening" to thigns hmm...
    //  TODO:  DON'T pass TOKEN here!  We SHLD bea able to do this more like React and stuff ughh

    //  Register the Deep Link Handler
    //  TODO:  This can be generalized.  We MAY want to give the handler an ID... or maybe we want to do it per system.. we MAY want to use the MODIFIER pattern to CREATE handlers like we can with everything else?? HM!
    //  TODO:  Register a generic handler system.. PERHAPS just use the "Linking.addEventListener" thing hmm... This way we can just attach our handlers like that? Hmm... then we can run ALL of them? Hmm.. maybe... do we need the additional layer hmm... the idea is, we have a MAIN one that brokers? Hmmm... maybe!  It DOES seem like that will eliminate some repetitive stuff in the individual handlers! HM!
    //  CONSIDER:  Add a NAME to the HOC? HM!

    //  NOTE:  THe MORE general idea is things.. that can be EXTEND INFINITELY... but SOMETIME we have interpreters that are COUPLED with a structure... this means we can't re-use that path without collision.. UNLESS we have an auxiliary encoding for the "interpreter" and THIS is whwat I mean by "system" perhaps? HmmBUT that limits us THERE.. THEN we can extend out In the OTHER direction with auxiliary encodings hm! KNDA like the dimensional prime number fanount hM!

    //  Register an HOC ABOVE the Hessia App so we can use Context

    // appMainEmitter.addListener("app-main-did-mount", () => {

    hessia.registerAppNavHOC(({ children }) => {

      // const [state, useState] = React.useState({

      // });

      return (
        <SystemPluginContext.Provider value={{ systemPlugin: this }}>
          {children}
        </SystemPluginContext.Provider>
      );
    })

    hessia.registerAppNavHOC(({ children }) => {

      //  TODO:  Get navigation like this instead of my API export.  Here's the reason I went with my current solution:  https://github.com/react-navigation/react-navigation/issues/6773
      const navigation = useNavigation();
      const context = React.useContext(AppContext);

      const initialUrl = context.initialUrl;

      const [handledInitial, setHandledInitial] = React.useState(false);

      //  When the Hessia App loads and mounts, initialized the systems.
      //  TODO:  This syntax / workflow is TOO verbose and hard to follow.. We CAN also just pass "this" from the component we're injecting into? Hm Ah...
      //  TOOD:  PLUS the timing is imporntnat, and it's now something that happens on the mount hm
      React.useEffect(() => {
        // alert("MOUNTEDDD")

        //  TODO:  Inject systems with another plugin.
        // initializeSystems();
        handleInitialUrl();
      }, [])

      function parseUrl<T>(url: string): HandlerPayload<T> | undefined {
        const { path, queryParams } = Linking.parse(url);
        // alert(JSON.stringify(queryParams))
        if (!queryParams || !queryParams.payload) { console.log("Empty Query Params"); return; }
        const payload: HandlerPayload<T> = JSON.parse(queryParams.payload as string);
        return payload;
      }

      //  TODO:  This is MORE like an APP Payload.. and I think it should be handled by the APP system!
      //  CONSIDER:  WHAT if we wanted to make ANOTHER "system"... one where it's not 
      async function handleSystemPayload(payload: HandlerPayload<SystemHandlerPayload>) {

        const { handlerId, handlerPayload } = payload;
        const systemPayload: SystemHandlerPayload = handlerPayload;

        //  THOGUHT:  THe IDEA is the URL can POSIBLY reconstruct the WHOLE app and stuff hm!  THIS point FORNOW, is that we'll have the MAIN Hessia screen loaded hm!  SO we SHOLD be able to navigate hm!  MAYBE!  But that will be triggered from inside the thing hm!  MAYBE we can start NAVIGATION from HESSIA and pass it down and stuff to each sub-systemh// H??? MAYBE MULTIPLE routes for each and yeha hm! 
        const { systemId } = systemPayload;

        //  TODO:  We need to constantly reference things from the React context.  MAYBE that means this should be IN the react context?? hmm.. BUT I'd like to keep the Halia plugin system framework agnostic, SO, instead, maybe we can register this in the React stack hmmmm... MAYBE!
        const { frameworkContext: { user, token } } = context;

        const plugins = await getPlugins(user, token);
        const plugin = plugins.find(plugin => plugin.id === systemId);

        // console.log("NAVIIGATING: " + JSON.stringify({ screen: SystemRoute.SystemConfig, params: { plugin } }));

        //  TODO:  Why do we need the never cast here?
        //  TODO:  FIX THIS!  SHOULD be able to navigate through deep links.. MAYBE this is an effort BETWEEN systems.. and it SEEMS like this one is being overloaded... in this case, we should have a system for interpreting deep links, MAYBE hav teh system thing play a role, AND inject into that from the fucking user-system thing hm!
        navigation.navigate("apps" as never, { screen: HAppRoute.SystemConfig, params: { plugin } } as never);
      }

      async function handleInitialUrl() {

        if (initialUrl && !handledInitial) {
          const payload = await parseUrl<SystemHandlerPayload>(initialUrl);
          if (!payload) { return; }
          setHandledInitial(true);
          await handleSystemPayload(payload);
        }
      }

      Linking.addEventListener("url", async ({ url }) => {
        const payload = parseUrl<SystemHandlerPayload>(url);
        if (!payload) { return; }
        await handleSystemPayload(payload);
      })


      // async function initializeSystems() {
      //   const { token } = context.frameworkContext;
      //   const selectedSpace = await getSelectedSpace();
      //   // alert(selectedSpace);
      //   console.log("--  Selected Space --")
      //   //  TODO:  GENERALISE loggin of the application STORY to make it EASY to DEBUG and shit hm!  THIS way we can NAIGATE the story MUCH like a debugger .  OFTEN the NAMES of methods won't line up perfectly and CODE is just ONE implementation of this practive uGH!!  I could see MYSELF that they charry picked example in their paper thing hmhmm!  Casey's point but still It's clear!  They disregard ALL with positive results and ONL reference one "small" negative one lol!  The point is , it doesn't matter HOW big.  ALL that matters is gettin a POSITIVE signal.  The LACK of evidence is NOT evidence of absense.  In ANY case!  It JUST shows that something isn't working!  In the few I read about the testss were SO dumb.. just teach people some music and see if it extends and stuff.. it's not about that!  Transfer is more explicit and related to CONCEPTUAL MAPPING.  If that doesn't happen, then it fucking won't ugh!People can become smarter by LEARNING to conceptually map!  MAYBE some do it more than others, but it's those CONNECTIONS that MAKE "intelligence " in my optinoi ugh@  AND the other thigns like working memory andd stuff CAN be improved with certain strateies !
      //   // space.onSpaceChange(space => injectSystems({ space, token, navigation, forceUpdate: context.appManager?.reloadApp }));
      //   injectSystems({ space: selectedSpace, token, navigation, forceUpdate: context.appManager?.reloadApp || missingReload })
      // } 

      return <>{children}</>

    });
    // })

    //  TODO-GENERAL:  WHY should this be repeated?
    return this;
  }
}
