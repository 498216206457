import * as ImagePicker from 'expo-image-picker';
import * as React from 'react';
import { ActivityIndicator, Button, Image, Pressable, TextInput, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { defaultBorderRadius, Paragraph, primaryColor } from '../constants';
import { ProfileContext, updateAvatar, useCognitoToken } from '../utilities';
import { GalleryButton } from './common';

//  CONSIDER:  This is a PARTICULAR thing it conforms to THAT interface hm!  BUT we can have MANY of them hm!  The idea is we have a DESIGN LANGUAGE for the Gallery and this conforms?? HM!  INCLUSING its emplate? HM!  INSTEAD of couling with the name ah!!  CAn't we use a MODIFIER??? HM!  THIS way we can pop LOTS of things on WITHOUT needing a whole new component for each one? HM!  THIS is where we start to have FACTORIES for creating these components hM!  PERHAPS HM!

interface FieldContainerProps {
  title: string;
  description?: string;
  buttonText: string;
  onPress: () => void;
  children: React.ReactNode;
}


export const FieldContainer = ({ title, description, onPress, buttonText, children }: { title: string, description?: string, onPress?: () => void, buttonText?: string, children: any }) => {
  return (
    <View style={{ flexDirection: 'column' }}>

      <Paragraph style={{ color: '#444444', fontSize: 16, flexGrow: 0 }}>{title}</Paragraph>
      {/* {description && <Paragraph style={{ color: '#999999', marginBottom: 25, fontFamily: 'Poppins-SemiBold' }}>{description}</Paragraph>} */}

      <View style={{ flex: 1 }} />

      {
        buttonText && (
          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={onPress}>
            <Paragraph style={{ color: 'white' }}>{buttonText}</Paragraph>
          </Pressable>
        )
      }

      {children}
    </View>



  )
}

interface ImageFieldProps {
  imageUrl?: string;
  onSave: (imageUrl: string) => void;
  title: string;
  description?: string;
}

export const ImageField = ({ imageUrl, onSave, title, description }: ImageFieldProps) => {

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
    });

    if (!result.cancelled) {
      onSave(result.uri);
    }
  };

  return (

    <FieldContainer title={title} description={description} onPress={pickImage} buttonText="Select Image">
      <View style={{ width: 300, height: 300, borderRadius: defaultBorderRadius, alignSelf: 'center', justifyContent: 'center' }}>
        {
          imageUrl ? (
            <Image resizeMode='contain' source={{ uri: imageUrl }} style={{ width: '100%', height: '100%', borderRadius: defaultBorderRadius, alignSelf: 'center' }} />
          ) :
            <Icon style={{ alignSelf: 'center' }} name="image" type="material" color="#888888" size={100} />
        }
      </View>
    </FieldContainer>
  )
}


//  CONSIDER:  Instead of baking 'multiline' in, we COULD use a modifier for this ugh!  Maybe we SHOULD... 

//  NOTE:  We CAN make another thing hmm.. or we can yeah hmm... 

export interface TextFieldProps {
  title: string;
  description?: string;
  onSave: (text: string) => void;
  multiline?: boolean;
  mode: 'automatic' | 'manual';
  placeholder?: string;

  //  NOTE:  Pass null if it's loading.
  value?: string;
}

export const TextField = ({ placeholder, onSave, title, description, multiline, mode, value }: TextFieldProps) => {

  const [text, setText] = React.useState<string>(value || "");

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const buttonText = mode === 'manual' ? editMode ? 'Cancel' : 'Edit' : undefined;
  const onPress = mode === 'manual' ? () => setEditMode(!editMode) : undefined;

  React.useEffect(() => {
    setText(value || "");
  }, [value])

  return (

    <FieldContainer title={title} description={description} onPress={onPress} buttonText={buttonText}>
      {
        mode === 'automatic' || editMode ? (
          <>
            <TextInput style={{ width: '100%', height: multiline ? 200 : 50, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: '#fafafa', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 15 }} multiline={multiline} numberOfLines={multiline ? Math.max(200 / 16) : 1} value={text} onChangeText={(text) => mode === 'automatic' ? onSave(text) : setText(text)} />
            {
              mode === 'manual' && <GalleryButton title="Submit" onPress={() => { onSave(text); setEditMode(false); }} />
            }

          </>
        ) : (
          <>
            {
              text !== null ? (
                <Paragraph>{text || "Unknown"}</Paragraph>
              ) : (
                <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
              )
            }
          </>

        )
      }
    </FieldContainer>
  )
}

export const defaultProfileImge = require('../assets/new_scribbles.png');

//  TODO:  I DO want to construct this with a PLUGIN system!!!  A MODIFIER system... this way we can AHH!!  It's JUST like mutli-modal activation where VARIOUS modifiers can have COUPLIGN systems to fucking MEDIATE HM!!!!!! LOVE IT AHH!!  Fuck OOP it's antiquated and it's just too dogmatic hm!  It's a cult.  GROUP THINK, freaking... HERD BIAS!!!
//  NOTE:  Couples "ImageField" and "Bad Art Profile"
export const ProfileImageField = () => {

  const token = useCognitoToken();
  const [profile, setProfile] = React.useContext(ProfileContext);

  const onSave = async (_imageUrl: string) => {
    if (!token) { return; }
    const _profile = await updateAvatar(_imageUrl, token);
    setProfile(_profile);
  }

  return (<ImageField imageUrl={profile?.avatar || defaultProfileImge} onSave={onSave} title="Avatar" />);
}

export const CreationImageField = () => {

  const token = useCognitoToken();
  const [profile, setProfile] = React.useContext(ProfileContext);

  const onSave = async (_imageUrl: string) => {
    if (!token) { return; }
    const _profile = await updateAvatar(_imageUrl, token);
    setProfile(_profile);
  }

  return (<ImageField imageUrl={profile?.avatar} onSave={onSave} title="Avatar" />);
}