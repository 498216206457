import { HaborComponent } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { haborSDK } from '../../hessia-plugin/config';
import { AppEvent } from '../../workspace-plugin/workspace-settings';
import { EntityEditor } from '../entity/entity-editor';
import { HaborModelSDK } from '../model-sdk';

//
//  EntityCreator Primitive Component
//

export interface EntityCreatorHaborPrimitiveProps extends PrimitiveProps { }
export const EntityCreatorHaborPrimitive = ({ userProps, frameworkProps }: EntityCreatorHaborPrimitiveProps) => {

  const { context: { token, user, workspace, changePage, appEmitter } } = frameworkProps;

  if (!workspace) { throw new Error("Cannot render the 'EntityCreator' Page with an undefined 'workspace'.") }

  const handleHeaderPress = () => {
    alert("Pressed Save");
  };

  React.useEffect(() => {
    appEmitter.addListener(AppEvent.HeaderButtonPressed, handleHeaderPress);
    return () => {
      appEmitter.removeListener(AppEvent.HeaderButtonPressed, handleHeaderPress);

    }
  }, [])

  const modelSDK = new HaborModelSDK(haborSDK, token);

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <EntityEditor modelSDK={ modelSDK } />
    </HaborContainer>
  );
};


//
//  EntityCreator Habor Component
//

export const EntityCreatorHaborComponent: HaborComponent = {
  name: "EntityCreatorHaborComponent",
  propsSchema: {
    type: "object",
    extensible: false,
    properties: {}
  },
  element: {
    name: "EntityCreatorHaborPrimitive",
    props: {},
    children: []
  }
};
