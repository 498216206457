import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { SDTObject } from 'davel';
import moment from 'moment';
import { Configuration, OpenAIApi } from "openai";
import * as React from 'react';
import { Image, ScrollView, Text, TextInput, View } from 'react-native';
import { DataTable, Switch } from 'react-native-paper';
import { DavelForm } from '../../../packages/davel-ui/davel-ui';
import { GalleryButton } from '../components/common';
import { GroupCard } from '../components/group-card';
import { HeroCard } from '../components/hero-card';
import { defaultBorderRadius } from '../constants';
import { Work } from '../openapi';
import { getGalleryApi, useCognitoToken } from '../utilities';


// const sendEmail = async (api: DefaultApi) => {
//   const res = await api.notificationsControllerWelcomeEmail({ body: {} });
// }

const NewProductSDT: SDTObject = {
  type: "object",
  properties: {
    email: { type: "keyword" },
    displayName: { type: "keyword" },
    workId: { type: "keyword" },
    sampleUrls: { type: "array", itemType: { type: "keyword" } },
    thanksMessage: { type: "keyword" }
  }
}

export const NewProcuctsForm = () => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());

  const onSubmit = async (formValue: any) => {
    const res = await api.notificationsControllerNewProductsEmail({ body: formValue });
  }

  return (
    <DavelForm onSubmit={onSubmit} schema={NewProductSDT} />
  );
}

export const NotificationScreen = () => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <HeroCard title="Admin" subTitle="Manage Bad Art Studio" />
      <View style={{ width: '100%', maxWidth: 700, borderRadius: defaultBorderRadius, overflow: 'hidden' }}>

        <GroupCard>
          <NewProcuctsForm />
        </GroupCard>

      </View>
      <View style={{ height: 100, width: '100%' }} />
    </ScrollView>
  );
}

export const AIScreen = () => {

  const [prompt, setPrompt] = React.useState("");
  const [response, setResponse] = React.useState<any>();


  const doAi = async () => {

    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);
    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [{ role: "system", content: "You are a cute dinosoar trying to find love in the pre-historic age." }, { role: "user", content: "Please pretend you are a dinosoar in the pre-historic age trying to find friends when you reply to this message:  " + prompt }],
    });
    setResponse(completion.data.choices[0].message);

  }

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <HeroCard title="AI" subTitle="Use AI" />
      <Text>{JSON.stringify(response)}</Text>
      <TextInput onChangeText={setPrompt} value={prompt} />
      <GalleryButton title="Do AI" onPress={doAi} />
    </ScrollView>
  );
}


export const AdminDashboardScreen = () => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());
  const [works, setWorks] = React.useState<Work[]>([]);

  //  Get All Listed Works
  //  Get All Products

  const loadWorks = async () => {
    const _works = await api.worksControllerFindAll();  //  FUNDAMENTAL:  This is an encoding which, when interpreted, changes our state to include the structures we expect.
    const listedWorks = _works.filter(_work => !!_work.listed);  //  FUNDAMENTAL:  This is an encoding whicih, when interpreted, changes state to remove items (implies list encoding) that we dont' need.
    const sortedWorks = listedWorks.sort((a, b) => moment(b.created).unix() - moment(a.created).unix()); //  FUNDAMTNA:  This is an encoding which when intepreted, ORDERS ths list.  This implies numeric association / etc mmm...
    setWorks(sortedWorks);
  }

  React.useEffect(() => {
    loadWorks();
  }, [])


  //  TODO:  LINK a "Notification" to the notified indicator to increase confidence.
  const toggleNotified = async (work: Work) => {
    await api.worksControllerUpdate({
      id: work.id,
      body: {
        ...work,
        notified: !work.notified
      }
    });
    loadWorks();
  }

  const WorkItem = ({ work }: { work: Work }) => {
    const navigation = useNavigation();

    return (
      <DataTable.Row>
        <DataTable.Cell onPress={() => navigation.navigate("WorkDetail" as never, { creationId: work.id } as never)} style={{ flex: 2 }}>{work.title}</DataTable.Cell>
        <DataTable.Cell>
          <Image style={{ height: 30, width: 30 }} source={{ uri: work.url }}></Image>
        </DataTable.Cell>
        <DataTable.Cell><Switch onValueChange={() => toggleNotified(work)} value={work.notified} /></DataTable.Cell>
        <DataTable.Cell>{moment(work.created).format("MMM. DD, YYYY hh:mm")}</DataTable.Cell>
      </DataTable.Row>
    );
  }

  return (
    <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={{ flex: 2 }}>Title</DataTable.Title>
          <DataTable.Title>Work</DataTable.Title>
          <DataTable.Title>Notified</DataTable.Title>
          <DataTable.Title sortDirection='descending'>Uploaded</DataTable.Title>
        </DataTable.Header>
        {works.map(work => <WorkItem work={work} />)}
      </DataTable>
    </ScrollView>
  );
}

export const AdminScreen = () => {

  const token = useCognitoToken();
  const api = getGalleryApi(token?.getJwtToken());
  const TabNav = createBottomTabNavigator();

  return (

    <TabNav.Navigator screenOptions={{ headerShown: false }}>
      <TabNav.Screen name="Dashboard" component={AdminDashboardScreen} options={{
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="list" color={color} size={size} />
        ),
      }} />
      <TabNav.Screen name="Notify" component={NotificationScreen} options={{
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="mail" color={color} size={size} />
        ),
      }} />

      <TabNav.Screen name="AI" component={AIScreen} options={{
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="settings" color={color} size={size} />
        ),
      }} />

    </TabNav.Navigator>

  )
}
