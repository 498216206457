import { NavigationProp, useNavigation } from '@react-navigation/native';
import { HaborIconSelection, InstanceInternal, NounInternal, PageNoun, Plugin } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { defaultBorderRadius, getRaisedStyle, medSpacer, primaryFontFamilyHeavy, RaisedHeight } from '../../../packages/kelp-bar/styles';
import { useApps } from '../apps-plugin/apps-plugin-context';
import { AppsPlugin } from '../apps-plugin/apps-plugin';
import { HAppRoute } from '../apps-plugin/apps.nav.routes';
import { isNoun } from '../habor-plugin/habor-utils';
import { AppContext } from '../hessia-plugin/AppContext';
import { ContainerListSimple } from '../model-plugins/named-object-plugin/named-object-list-simple';
import { SingleElementWiget } from '../model-plugins/named-object-plugin/single-element-widget';
import { AggregatePlugin, getPluginElements } from '../system-plugin/plugin/plugin-tools';

//  TODO:  I DO want to be able to provide ADDITIONAL propeties to this by ASSOCIATING them UGH!!  I gues it's like CSS.. but it's like metadata fuck... it reminds me of IFT again FUCK.. the point is.. we can choose to INJECT based on certain conditions.. hmmm.. MAYBE not just the freaking ID hmm!  tht'a s key thing, AND CSS does the SAME shit with style hm!  So we do this GENERICALLY with props hm!  LOVe that.. ok!  MAYBE I shold do a code audit soon? Hm
// TODO:  Use freaking children??? Insted of content? Hm
//  CONSIDER:  HATE that I'm adding COMPLECITY here that's not needed with the icon!  SOMETIMES we don't even need this!  I'd LIKE To be able to make this with a MIXIN or like.. a Modifier system hmmm
export const SimpleBox = ({ title, content, icon, iconColor, iconBackgroundColor, children }: { children?: any, title: string, content?: any, icon?: HaborIconSelection, iconColor?: string, iconBackgroundColor?: string }) => {
  return (
    <View style={[{ backgroundColor: 'white', borderRadius: defaultBorderRadius, padding: 15, display: 'flex', flexDirection: 'column', borderWidth: 2, borderColor: "#eeeeee" }]}>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{ color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: 16, letterSpacing: -0.5 }}>
          {title}
        </Text>
        {
          icon && (
            <View>
              <View style={{ backgroundColor: iconBackgroundColor, height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon tvParallaxProperties={ undefined } color={ iconColor } name={ icon.name } type={ icon.type } />
              </View>
            </View>
          )
        }
      </View>

      <View style={{ height: medSpacer }} />

      {
        content
      }
      {
        children
      }
    </View>
  );
}
export type HaborElement<T = any> = NounInternal | InstanceInternal<T>;

export interface PageWidgetProps {
  navigation: NavigationProp<any>;
  system: InstanceInternal<Plugin>;
  appsPlugin: AppsPlugin;
}

export interface PageWidgetState {
  //  TODO:  PERHAPS when the SAME data is needed by multiple pieces in a context we can AUTOMATICALLY abstract!?  It MIGHT seem reasomable to have the parent inject, BUT that creates coupling!  INSTEAD, what if we pop up and CACHE the children data somewhere so this shit always goes fast!? HMmm
  dependencies: InstanceInternal<Plugin>[];
  pluginDetails?: AggregatePlugin;
}

class PageWidgetBase extends React.Component<PageWidgetProps, PageWidgetState> {
  constructor(props: PageWidgetProps) {
    super(props);
    this.state = {
      dependencies: []
    }
  }

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>

  public componentDidMount = async () => {

    this.getElements();
  }

  public componentDidUpdate = () => {
    this.getElements();
  }

  public getElements = async () => {

    const { system } = this.props;
    const { token } = this.context.frameworkContext;
    const pluginDetails = (await getPluginElements([system], token))[system.id];
    this.setState({ pluginDetails });
  }

  public onElementPress = async (elem: HaborElement) => {
    if (isNoun(elem)) {
      this.onNounPress(elem as NounInternal);
    } else {
      this.onInstPress(elem as InstanceInternal);
    }
  }

  //  TODO:  This should show a pop up context menu!  Probably like a CARD that pops up from the bottom with information??  Make it look FRESH!!!  Make it looks DYNAMIC and BUBBLY??? User ICONS and shit to incidate the system, entity, and other stats???
  public onElementLongPress = async (elem: HaborElement) => {
    alert("Should show element menu?");
    // this.props.navigation.navigate(RootRoute.ElementMenu as any, { elem });
  }

  //  TODO:  Supporrt injections for grouping / filtering / searching!? Hmm!  MAYBE make it possible to inject coomponent level interpretations, like a Group definition BUT perhaps ALSO make it generric with FUNCTIONS that can process the items and shit, AND also abilty to TOTAALLY change the things with inejctions / yeah, I GUESS composition is a good word... but it annoys me because it's too similar to the Vue term.

  public onNounPress = (noun: NounInternal) => {
    this.props.navigation.navigate(HAppRoute.InstanceList, { noun });
  }

  public onInstPress = async (inst: InstanceInternal) => {
    this.props.appsPlugin.applyElementPressHandler(inst, this.props.navigation);
  }

  public render = () => {

    const { pluginDetails } = this.state;

    //  Handle Loading
    //  TODO:  I REALLY like the idea of supporting a "Loading" interface or something that AUTOMATICALLY shows this as loading when that condition is true, AND declaratively reporting WHEN the loading condition is true, AND keeping this OUT of the main render method ?? MAYBE we can process it IN the render method, BUT MAYBE similar to Vue composition we can have it be a function which is automaticlly invoked on render and registered with the component?? MHmmm!
    //         ALSO, thee MAY be full-screen loaders, AND component based / animated gray block scanning loaderr things!  I can ALSO export that as a LIBrARY!  AND I think it's different from Vue which ONLY seems to do it ONCE at startfup, BUT we can register things declaratively with a bunch of methods AND compose with injections and stuff???
    if (!pluginDetails) {
      return <Text>Loading Page Details</Text>
    }

    if (!pluginDetails.instances[PageNoun.id]?.instances) {
      return null;
    }

    return (
      <SimpleBox
        title="Pages"
        content={
          <ContainerListSimple component={SingleElementWiget} elems={pluginDetails.instances[PageNoun.id].instances} onItemLongPress={this.onElementLongPress} onItemPress={this.onElementPress} />
        }
      />
    );
  }
}

export const PageWidget = (props: Omit<PageWidgetProps, "navigation" | "appsPlugin">) => {
  const navigation = useNavigation();
  const appsPlugin = useApps();
  return <PageWidgetBase appsPlugin={appsPlugin} navigation={navigation} {...props} />
}
