import { Auth } from 'aws-amplify';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator, Pressable, ScrollView, TextInput, View, ViewProps } from 'react-native';
import { configureFonts, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal, enGB, registerTranslation } from 'react-native-paper-dates';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { Picker } from '../../../packages/kelp-bar/picker';
import { primaryFontFamily } from '../../../packages/kelp-bar/styles';
import { ContentCard } from '../components/content-card';
import { GroupCard } from '../components/group-card';
import { HeroCard } from '../components/hero-card';
import { defaultBorderRadius, Paragraph, primaryColor, SubHeader } from '../constants';
import { useSettings } from '../content-view';
import { useSizes } from '../sizes-helper';
import { AttributeContext, useCognitoUser, useCognitoUserAttributes, validateEmail } from '../utilities';

registerTranslation('en-GB', enGB);

registerTranslation("pl", {
  save: 'Save',
  selectSingle: 'Select date',
  selectMultiple: 'Select dates',
  selectRange: 'Select period',
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: 'Day is not allowed',
  previous: 'Previous',
  next: 'Next',
  typeInDate: 'Type in date',
  pickDateFromCalendar: 'Pick date from calendar',
  close: 'Close',
});


const fontConfig = {
  regular: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  light: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
  thin: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: 'normal',
  },
} as any;

const theme = {
  ...DefaultTheme,
  roundness: 1,
  version: 3,
  colors: {
    ...DefaultTheme.colors,
    primary: primaryColor,
    secondary: 'white',
    tertiary: 'white'
  },
  fonts: configureFonts({
    web: fontConfig,
    ios: fontConfig,
    android: fontConfig
  })
};

export const Layer = (props: ViewProps) => {
  return (
    <View style={[{ width: '100%', height: '100%', top: 0, left: 0, position: 'absolute' }, props.style]}>
      {props.children}
    </View>
  );
}

export const Account = () => {

  const { isDesktop } = useSizes();
  const settings = useSettings();
  const user = useCognitoUser();
  const attributes = useCognitoUserAttributes()

  //  Attributes

  //  TODO:  Builda a mapping to automate this.

  //  Basic Info
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [birthDate, setBirthDate] = React.useState("");

  //  Contact Info
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");

  React.useEffect(() => {
    if (attributes) {

      const _firstName = attributes?.find(attr => attr.Name === "given_name")?.Value || "";
      const _familyName = attributes?.find(attr => attr.Name === "family_name")?.Value || "";
      const _gender = attributes?.find(attr => attr.Name === "gender")?.Value || "";
      const _birthDate = attributes?.find(attr => attr.Name === "birthdate")?.Value || "";

      const _phone = attributes?.find(attr => attr.Name === "phone")?.Value || "";
      const _email = attributes?.find(attr => attr.Name === "email")?.Value || "";
      const _address = attributes?.find(attr => attr.Name === "address")?.Value || "";

      setFirstName(_firstName);
      setLastName(_familyName);
      setGender(_gender);
      setBirthDate(_birthDate);

      setPhone(_phone);
      setEmail(_email);
      setAddress(_address);
    }
  }, [attributes])

  const save = async () => {
    if (!attributes) { return; }
    const attributeObject = {};

    attributeObject["given_name"] = firstName;
    attributeObject["family_name"] = lastName;
    attributeObject["gender"] = gender;
    attributeObject["birthdate"] = birthDate;

    attributeObject["phone"] = phone;
    attributeObject["email"] = email;
    attributeObject["address"] = address;

    try {
      await Auth.updateUserAttributes(user, attributeObject);
    } catch (err) {
      alert(err);
    }
  }


  const NameEditor = () => {

    const attributes = React.useContext(AttributeContext);

    const [loading, setLoading] = React.useState(true);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {

        const _firstName = attributes?.find(attr => attr.Name === "given_name")?.Value || "";
        const _familyName = attributes?.find(attr => attr.Name === "family_name")?.Value || "";

        setFirstName(_firstName);
        setLastName(_familyName);
        setLoading(false);
      }
    }, [attributes])

    const save = async () => {
      if (!attributes) { return; }
      const attributeObject = {};
      attributeObject["given_name"] = firstName;
      attributeObject["family_name"] = lastName;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setEditMode(false);
      } catch (err) {
        alert(err);
      }
    }


    return (
      <View style={{ flexDirection: 'column' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Legal Name</Paragraph>

          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <>
              <Paragraph style={{ marginTop: 15 }}>First Name</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={firstName} onChangeText={text => setFirstName(text)} />
              <Paragraph style={{ marginTop: 15 }}>Last Name</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={lastName} onChangeText={text => setLastName(text)} />
              <View style={{ height: 30 }} />
              <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-start' }} onPress={() => save()}>
                <Paragraph style={{ color: 'white' }}>Save</Paragraph>
              </Pressable>
            </>
          ) : (
            <>
              {
                loading ? (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                ) : (
                  <Paragraph>{firstName || lastName ? `${firstName} ${lastName}` : "Unknown"}</Paragraph>

                )
              }
            </>

          )
        }

      </View>

    );


  }

  const GenderEditor = () => {

    const attributes = React.useContext(AttributeContext);

    const [gender, setGender] = React.useState<null | string | undefined>(null);

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {
        const _gender = attributes?.find(attr => attr.Name === "gender")?.Value || undefined;
        setGender(_gender);
      }
    }, [attributes])

    const save = async () => {
      if (!attributes) { return; }
      const attributeObject = {};
      attributeObject["gender"] = gender;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setEditMode(false);
      } catch (err) {
        alert(err);
      }
    }


    return (
      <View style={{ flexDirection: 'column', zIndex: 100 }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Gender</Paragraph>

          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <>
              <Picker
                value={gender}
                onChange={setGender}
                items={[
                  { value: "Female", label: "Female" },
                  { value: "Male", label: "Male" },
                  { value: "Other", label: "Other" },
                  { value: "Unspecified", label: "Unspecified" },
                ]} />

              <View style={{ height: 30 }} />
              <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-start' }} onPress={() => save()}>
                <Paragraph style={{ color: 'white' }}>Save</Paragraph>
              </Pressable>
            </>
          ) : (
            <>
              {
                gender !== null ? (
                  <Paragraph>{gender || "Unknown"}</Paragraph>
                ) : (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                )
              }
            </>

          )
        }

      </View>

    );


  }

  const BirthDateEditor = () => {

    const attributes = React.useContext(AttributeContext);

    const [birthdate, setBirthDate] = React.useState<null | string | undefined>(null);
    const [loading, setLoading] = React.useState(true);

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {
        const _birthdate = attributes?.find(attr => attr.Name === "birthdate")?.Value || undefined;
        setBirthDate(_birthdate);
        setLoading(false);
      }
    }, [attributes])

    const save = async (date: CalendarDate) => {
      setLoading(true);
      if (!attributes || !date) { return; }
      const dateStr = moment(date).format('YYYY-MM-DD');
      const attributeObject = {};
      attributeObject["birthdate"] = dateStr;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setBirthDate(dateStr);
        setEditMode(false);
        setLoading(false);
      } catch (err) {
        alert(err);
      }
    }


    const birthdateMoment = moment(birthdate);

    const month = birthdateMoment.month() + 1;
    const date = birthdateMoment.date();
    const year = birthdateMoment.year();
    const dateString = `${month}/${date}/${year}`;

    return (
      <View style={{ flexDirection: 'column' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Birthdate</Paragraph>

          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <View style={{ flex: 1, padding: 100 }}>
              <PaperProvider theme={theme}>
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={editMode}
                  onDismiss={() => setEditMode(false)}
                  date={birthdateMoment.toDate()}
                  onConfirm={({ date }) => { save(date) }}
                />
              </PaperProvider>

            </View>
          ) : (
            <>
              {
                loading ? (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                ) : (
                  <Paragraph>{birthDate ? dateString : "Unknown"}</Paragraph>
                )
              }
            </>

          )
        }

      </View>

    );


  }

  const PhoneEditor = () => {

    const attributes = React.useContext(AttributeContext);

    const [phone, setPhone] = React.useState<null | string | undefined>(null);

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {
        const _phone = attributes?.find(attr => attr.Name === "phone")?.Value || undefined;
        setPhone(_phone);
      }
    }, [attributes])

    const save = async () => {
      if (!attributes) { return; }
      const attributeObject = {};
      attributeObject["phone"] = phone;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setEditMode(false);
      } catch (err) {
        alert(err);
      }
    }

    //  NOTE:  The idea is we SHOULD be able to use a TEMPLATE for string fitting hm!

    return (
      <View style={{ flexDirection: 'column' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Phone</Paragraph>

          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <View style={{ flex: 1 }}>
              <Paragraph style={{ marginTop: 15 }}>Phone</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={phone || ""} onChangeText={text => setPhone(text)} />
              <View style={{ height: 30 }} />
              <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-start' }} onPress={() => save()}>
                <Paragraph style={{ color: 'white' }}>Save</Paragraph>
              </Pressable>
            </View>
          ) : (
            <>
              {
                phone !== null ? (
                  <Paragraph>{phone || "Unknown"}</Paragraph>
                ) : (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                )
              }
            </>

          )
        }

      </View>

    );


  }


  const EmailEditor = () => {

    const attributes = React.useContext(AttributeContext);

    const [email, setEmail] = React.useState<null | string | undefined>(null);

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {
        const _email = attributes?.find(attr => attr.Name === "email")?.Value || undefined;
        setEmail(_email);
      }
    }, [attributes])

    const isEmailValid = validateEmail(email);

    const save = async () => {
      if (!attributes || !isEmailValid) { return; }
      const attributeObject = {};
      attributeObject["email"] = email;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setEditMode(false);
      } catch (err) {
        alert(err);
      }
    }


    return (
      <View style={{ flexDirection: 'column' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Email</Paragraph>

          <Pressable disabled={ true } style={{ backgroundColor: '#dddddd', padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <View style={{ flex: 1 }}>
              <Paragraph style={{ marginTop: 15 }}>Email</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={email || ""} onChangeText={text => setEmail(text)} />
              <View style={{ height: 30 }} />
              <Pressable style={{ backgroundColor: isEmailValid ? primaryColor : '#f4f4f4', padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-start' }} onPress={() => save()}>
                <Paragraph style={{ color: 'white' }}>Save</Paragraph>
              </Pressable>
            </View>
          ) : (
            <>
              {
                email !== null ? (
                  <Paragraph>{email || "Unknown"}</Paragraph>
                ) : (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                )
              }
            </>

          )
        }

      </View>

    );


  }

  interface Address {
    formatted: string;  //  NOTE:  Not used.
    street_address: string;
    locality: string;
    postal_code: string;
    country: string;
    region: string;
  }

  //  CONSDIER:  In React the REINTERPRETATION happens at the "FUNCTION" level!  WHY ddo we need to do this!  It is UNNECESSARY!  Instead, ONLY run the pieces we need hM!  WITHOUT checking through the framework? Hmm... 

  const AddressEditor = () => {

    const [loading, setLoading]= React.useState(true);

    const [addressString, setAddressString] = React.useState<string | undefined>(undefined);

    const attributes = React.useContext(AttributeContext);

    const getAddress = () => {
      const _address: Address = addressString ? JSON.parse(addressString) : undefined;
      return _address;
    }

    const address = getAddress();

    const { street_address = "", locality = "", postal_code = "", country = "", region = "" } = address || {};

    const setAddressPart = (part: string, value: string) => {
      const _address: Address = getAddress() || {};
      _address[part] = value;
      setAddressString(JSON.stringify(_address));
    }

    const [editMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (attributes) {
        const _address = attributes?.find(attr => attr.Name === "address")?.Value || undefined;
        setAddressString(_address);
        setLoading(false);
      }
    }, [attributes])

    const save = async () => {
      setLoading(true);
      if (!attributes) { return; }
      const attributeObject = {};
      attributeObject["address"] = addressString;
      try {
        await Auth.updateUserAttributes(user, attributeObject);
        setEditMode(false);
        setLoading(false);
      } catch (err) {
        alert(err);
      }
    }

    const street_address_lines = street_address.split("\n");
    const street = street_address_lines.length >= 1 ? street_address_lines[0] : "";
    const apt = street_address_lines.length >= 2 ? street_address_lines[1] : "";

    const setStreet = (_street: string) => {
      const streetAddress = `${_street}\n${apt}`;
      setAddressPart("street_address", streetAddress);
    }

    const setApt = (_apt: string) => {
      const streetAddress = `${street}\n${_apt}`;
      setAddressPart("street_address", streetAddress);
    }

    

    return (
      <View style={{ flexDirection: 'column' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Paragraph style={{ flex: 1, color: '#444444', fontSize: 16 }}>Address</Paragraph>

          <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={() => setEditMode(!editMode)}>
            <Paragraph style={{ color: 'white' }}>{editMode ? "Cancel" : "Edit"}</Paragraph>
          </Pressable>
        </View>

        {
          editMode ? (
            <View style={{ flex: 1 }}>
              <Paragraph style={{ marginTop: 15 }}>Country</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={country || ""} onChangeText={text => setAddressPart("country", text)} />

              <View style={{ height: 30 }} />
              <Paragraph style={{ marginTop: 15 }}>Street Address</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={street || ""} onChangeText={text => setStreet(text)} />

              <View style={{ height: 30 }} />
              <Paragraph style={{ marginTop: 15 }}>Apt, suite. (optional)</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={apt || ""} onChangeText={text => setApt(text)} />

              <View style={{ height: 30 }} />
              <Paragraph style={{ marginTop: 15 }}>City</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={locality || ""} onChangeText={text => setAddressPart("locality", text)} />

              <View style={{ height: 30 }} />
              <Paragraph style={{ marginTop: 15 }}>State</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={region || ""} onChangeText={text => setAddressPart("region", text)} />

              <View style={{ height: 30 }} />
              <Paragraph style={{ marginTop: 15 }}>Zip Code</Paragraph>
              <TextInput style={{ marginTop: 10, backgroundColor: '#f5f5f5', borderRadius: defaultBorderRadius, height: 50, paddingHorizontal: 10, fontFamily: primaryFontFamily, color: '#555555' }} value={postal_code || ""} onChangeText={text => setAddressPart("postal_code", text)} />

              <View style={{ height: 30 }} />
              <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-start' }} onPress={() => save()}>
                <Paragraph style={{ color: 'white' }}>Save</Paragraph>
              </Pressable>
            </View>
          ) : (
            <>
              {
                loading ? (
                  <ActivityIndicator color={primaryColor} style={{ alignSelf: 'flex-start' }} />
                ) : (
                  <Paragraph>{address ? `${street} ${apt} ${locality} ${region} ${postal_code} ${country}` : "Unknown"}</Paragraph>
                )
              }
            </>

          )
        }

      </View>

    );


  }

  return (
    <ScrollView contentContainerStyle={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <HeroCard title="Account" subTitle="Configure private account settings." />

      {/* TODO:  Indicate that this info will be used for hmmm... RedBubble doesn't give out 1099.  The idea is... I can let them license to me, then it's MY shop, and then I don't have to send 1099s NOR do I have to collect tax for them.  We are just providing a service with a base-rate hm! */}

      <ContentCard>

        <GroupCard>
          <SubHeader style={{ color: '#555555', marginBottom: 25, fontFamily: 'Poppins-Bold' }}>Basic Info</SubHeader>
          <NameEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <GenderEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <BirthDateEditor />
        </GroupCard>

        <View style={{ height: 30 }} />

        <GroupCard>
          <SubHeader style={{ color: '#555555', marginBottom: 25, fontFamily: 'Poppins-Bold' }}>Contact Info</SubHeader>
          <PhoneEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <EmailEditor />
          <View style={{ width: '100%', height: 2, backgroundColor: '#e4e4e4', borderRadius: 30, marginVertical: 30 }} />
          <AddressEditor />
        </GroupCard>

        <View style={{ height: 30 }} />

        <GroupCard>
          <SubHeader style={{ color: '#555555', marginBottom: 25, fontFamily: 'Poppins-Bold' }}>Session</SubHeader>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>

            {/* TODO:  Remove unhandled async functions */}
            <Pressable style={{ backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius, alignSelf: 'flex-end' }} onPress={async () => { await Auth.signOut(); location.reload(); }}>
              <Paragraph style={{ color: 'white' }}>Sign Out</Paragraph>
            </Pressable>
          </View>
        </GroupCard>

        {/* <Button title="Sign Out" onPress={async () => { await Auth.signOut(); alert("Should reset the app... use the Hub!") }} /> */}
      </ContentCard>
      <View style={{ height: 100, width: '100%' }} />
    </ScrollView>
  )
}
