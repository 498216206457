import { CorePluginClass } from "halia";
import { ReactAppPlugin } from "../elements/react-app-plugin/react-app-plugin";
import { HaliaComponentPlugin } from "halia-native";
import { AuthPlugin } from "./auth-plugin/auth-plugin";
import { HessiaPlugin } from "./hessia-plugin";
import { MenuLayoutPlugin } from "../elements/menu-layout-plugin/menu-layout-plugin";
import { Hessia2Context, Hessia2Plugin, System } from "../hessia2/hessia2-plugin";
import React from "react";


// //  CONCERN:  KINDA makes me freak out a little.. what is the point of any of this if we're just orchestrating like we used to?  I THINK we want to perhaps CONFIGURE those plugins with a config? Hmm.. OR shodl they NOT have the respnsicility of choosing how they are used?? Hmmm.. intersting.  Either way, we MAY want to plug MULTIPLE orchestrators and stuff? Hm.. NOT just one giant one? Hmmm... something liek that.. idk yet.. maybe even have them depend upon a particular app thing.. this we we can have different implementations hmmm... something like that .. hmm 
export class OrchestratorPlugin extends CorePluginClass {

  public static details = {
    name: "Orchestrator",
    //  TODO:  Build a FLOW system to make this easier, keep pusihin on GENERTIC plugins, this is ONE exmaple of a Coupling plugin for THIS app... PLUS, work on CONTEXT dependencies and the overall REACT system that works betwen PLUGISN and lots of thigns NOT just UIs!!!
    description: "Orchestrates Plugins for the App",
    //  NOTE:  I think we can make this more "advanced".. basically make more system / interpreters.  THen we can do thingsl ike depend on a CATEGORY adn require certian connections etc hm!  EITHER WAY... it's all just being plugged into an ontological strucutre.  Whatever conditoins need to be met can perhaps be expressed hM!!  Perhaps systems to verify and show injection sites hm!! I like that hm!  MAYBE a way to look at the "App" in a global view and show the injection sites visually? Hm!  Plus flow-based view, etc hm!
    //  CONSIDER:  HOW does this affect the MODIFIER pattern, which is silar but like.. ECS., when things are applied we CHANGE a thing! hm!
    //  CONSIDER:  MAY want to differentiate between the plugins / thigns we're COMPOSING / using as
    //  CONCERN:  HOW is this different from just doing this in a regular app?  It's not MUCH.. like Auth , and then another thing.  We orchestrate using libraries and stuff.. the point is... we make it EXPLICIT that that's what we're doing so we KNOW how things need to be coupled and we have clear API usage and ... what about creep of API passed through if we only use a subset?  MAYBE eventually differentiate the "dependencies" more precisely or like... somethinggg... like this hm  POint is... this gives us an advantage.. which is the ability to compose dynamically at this high level.  By adding / removing Plugins to buld the thing.. hmm it's ESSENTIALLY a GIANT modifier pattern hm!!! The encoding IS the interaction between them and even MODIFIERS can screw with eachother perhaps then hm!!!  It's ALL just a big encoding hm!
    dependencies: [AuthPlugin.details.id, HessiaPlugin.details.id],
    id: "orchestratorPlugin"
  }

  //  CONCERN:  AGAIN.. this is just turing into the app .. just a glorified way of importing libraries.. hmm.. but at least we have visibility into the process and we KINDA support async loading and stuff hm...  the point is... it's like.. ugh.. We can also EXTEND it and shit .. one BIG bonus is the idea of Coupling Plugins maybe? Hmm.. a PLUGIN is just a piece of code that gets added to the program.  It can use the APIs and stuff, buttt it's contextual hmm... whyy can't we just do that with JS Modules? Hmm... maybe we can idk.. but this way we can reload and stuff on demand and yeah.. it's not just the single loading idk.. soemthing like that.. working for me.. plus I like taking things apart into separate plugins.. hm
  public install = async (program: any, { authPlugin, hessia }: { authPlugin: AuthPlugin, hessia: HessiaPlugin }) => {



    //  CONCERN:  Plugins seem to COUPLE things.. maybe that's fine.. but the point is.. it's both a library.. an ENCOGIN we can reference ... an ONTOLOGICAL STRUCURE which, is created when interpreted, AND an orchestrator which "Plugs" that in hm!  BOTH are useful!  We can build Plugins to add functionality in lots of places, AND also build Plugins with Orchestrators which have Pluggable points and stuff? Hmm.. perhaps!  Interesting... something like this... Or "Modifiers" hmm
    // hessia2.registerHOC(authPlugin.Start);

    //  Register
    authPlugin.onLogin((details) => {

      // alert(JSON.stringify(details));
      //  TODO:  Work with a higher level navigation library or like.. remote the Login instead of just registering this HOC over it? hmm...  Plus how do we handle more complex tree changes.. ugh
      //  CONSIDER:  MAYBE Build a coupling plugin for Auth, OR do it separate, or like.. multiple OPTIONS, but alll the same hmm.. the poitn is.. we want to give the user cusomitzation options.. BUT also give reaonsable defaults and stuff?? Hm.. and inject multipel places WITHOUT coupling concpte hsm!  Again it's about doing the shit that I LIKE.. MOST people can!  Just do your best man hM!
      // hessia2.removeHOC(authPlugin.Start);
      //  TODO:  WHEN we do this hmm.. ONE problem is.. what if we want to use a DIFFERENT insttance.. this is putting it up .. sure.. but now ANYTHING that depends upon "MenuPlugin" will need to use that hmm... isntead.. we wnt an INSTANCE of the thing.. which is why we build a NEW node which has config info and stuff that we can pass aroudn hmm... the point is.. we CAN operate this way? Hmm.. why not???  But yeah.. crazy tome.. it seems to converge in a way.. hmm idk man... Maybe it's all the same shit.. BUT one big one is introspection and reloding adns tuff.. PLUS in some cases, maybe we DO hard-code the enablement.. maybe in others the ENABLEMENT / ORCHESTRATION is ALSO plugged hmm.. / configured etc.. This way we can have like.. cohesive set of these things.. like another plauign to inject the Auth disablement thing? Hm

      //  TODO:  Really don't like teh Orchestrator.. would like to de-coupled to support composition from the Plugins.  MAYBE have a Coupling Plugin or .. something.. Maybe LAYERS and shit hmm..  We CAN produce a whole stack, but what if we want to add something before Login? Hmm... maybe we CAN still do that?  BUT the point is, the PLUGIN knows how to inject ITSELF? Hmm.. This is where we can split into Libraries, AND into COUPLERS HM!  THIS way we can copule in MULTIPLE ways depending on the user preferences hm!!!  I THINK I like that hm!Still we MAY be able to couple with an ID and shit .. so we can add experiences AROUND a thing, like "Main", can be one, and then we can have it inject things like Auth and stuff? Hmmm... Maybe generalize more? Hmm...

      // const HessiaLegacySystem: System2 = {
      //   color: "#aaaaaa",
      //   name: "Legacy",
      //   description: "Hessia Legacy",
      //   emoji: "🏛️",
      //   component: hessia.HessiaApp,
      //   icon: {
      //     name: "book",
      //     type: "font-awesome"
      //   },
      //   primaryColor: '#256fe6',
      //   backgroundColor: '#f7faff'
      // };
  
      // hessia2.registerHOC(({ children }) => {
      //   const hessia2 = React.useContext(Hessia2Context);
      //   React.useEffect(() => {
      //     hessia2?.installSystem(HessiaLegacySystem);
      //   }, []);
      //   return (
      //     <>
      //       {children}
      //     </>
      //   );
      // });
    })

    authPlugin.onLoginFailed(() => {
      alert("Login Failed")
    })

    return this;
  }
}
