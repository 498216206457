import { createDrawerNavigator, DrawerContentScrollView } from '@react-navigation/drawer';
import * as _ from 'lodash';
import * as React from 'react';
import { ScrollView, TouchableOpacity, View, ViewStyle } from 'react-native';
import { IconButton } from 'react-native-paper';
import { FlatGrid } from 'react-native-super-grid';
import { api } from '../bas-sdk';
import { GroupCard } from '../components/group-card';
import { GalleryHeader } from '../components/header';
import { CenteredView, useSettings } from '../content-view';
import { WorkProduct } from '../openapi';
import { GenderFilter, getSyncProductResult, TypeFilter, useGender, useType } from '../utilities';
import { WorkProductItem } from './work-detail';
import { Paragraph, SubParagraph } from '../constants';
import { useSizes } from '../sizes-helper';
import { Chiclet } from '../../../packages/kelp-bar/chiclet';
import { capitalizeAllWords } from '../../../packages/davel-ui/davel-utils';

//  CONSIDER:  OFTEN have a case where ONE thing is rendering and another is querying.. BUT often they can be COUPLED too hmm.. basically there's CONTEXT!  Sometimes we "have" part of the context, other times we don't etc mm!!  I THINKKK we can do this with DEPENDENCY management hM!  MEANING.. if an interpreter REQUIRES a particular context, then it will grab it and hm!  MAYBE we have a way to AUTO-GRAB a RESOLVER for it if we need it mm!! I LOVE that idea hm!  So ... the FRAMEWORK can find a way to bridge the gaps hm!  Again... MUCH like Halia mm!!

export interface ShopProps {
  showHeader?: boolean;
  username?: string;
}
export const Shop = ({ showHeader = true, username }: ShopProps) => {

  const [products, setProducts] = React.useState<WorkProduct[]>([]);

  React.useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const products = await api.workProductsControllerFindAll();

    //  CONSIDER:  Filter in the API
    //  TODO:  Build a unifieid "filter" system that we can add pieces to.. tie it with the DEPENDENCY system hmm...

    //  Filter Approved
    let filteredProducts = products.filter(item => item.ownerApproved == null || item.ownerApproved == true);

    //  Filter Username
    filteredProducts = username ? filteredProducts.filter(item => item.owner === username) : filteredProducts;

    //  Shuffle
    //  TODO:  Let the USER choose how to organize these!
    const randomizedProducts = _.shuffle(filteredProducts);

    setProducts(randomizedProducts);
  }

  const settings = useSettings();

  interface DropDownItemProps {
    selected: boolean;
    pressed: () => void;
    style: ViewStyle;
    title: string;
    menuPosition?: 'left' | 'right';  //  CONSIDER:  Doing this automatically? Hmm...
    //  AHH!!  INSTEAD of exposing all of it through PROPS.. the idea is WE have a hierarchy that's standardizesd that we can use to change style of these thing shm!
  }

  const [typeList] = useType();
  const filteredProducts = products.filter(product => {
    const syncProduct = getSyncProductResult(product);
    for (const type of typeList) {
      if (syncProduct?.sync_product.name.includes(type)) {
        return true;
      }
    }
    return false;
  });

  const { isMobile } = useSizes();
  return (
      <FlatGrid
        data={filteredProducts}
        scrollEnabled={true}
        itemDimension={ 250 }
        // spacing={10}
        // style={{ width: '100%' }}
        // contentContainerStyle={{  }}
        renderItem={({ item: product }) => <WorkProductItem workProduct={product} />}
      />
  );
}


const DrawerNav = createDrawerNavigator();

//  TODO-NEXT:  Put the filters in here!
const CustomDrawer = props => {
  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{  }}>
        <View style={{backgroundColor: "white", padding: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <GenderFilter />
          <View style={{ height: 60 }} />
          <TypeFilter />
        </View>

      </DrawerContentScrollView>
    </View>
  );
};

export const ShopPage = () => {

  const settings = useSettings();
  const [gender] = useGender();
  const [typeList] = useType();

  const { isMobile } = useSizes();

  return (
    <View style={{ flex: 1 }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light'>
        {/* <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 60 }}>
          <SelectionItem title='Clothing' pressed={() => setSelectedItem('Clothing')} selected={selectedItem === 'Clothing'} style={{}} />
          <SelectionItem title='Prints' pressed={() => setSelectedItem('Prints')} selected={selectedItem === 'Prints'} style={{}} />
          <SelectionItem title='Home and Living' pressed={() => setSelectedItem('Home and Living')} selected={selectedItem === 'Home and Living'} style={{}} />
          <SelectionItem title='Accessories' pressed={() => setSelectedItem('Accessories')} selected={selectedItem === 'Accessories'} style={{}} />
          <SelectionItem title='Pets' pressed={() => setSelectedItem('Pets')} selected={selectedItem === 'Pets'} style={{}} />
          <SelectionItem menuPosition='right' title='Beachware' pressed={() => setSelectedItem('Beachware')} selected={selectedItem === 'Beachware'} style={{}} />
          <SelectionItem menuPosition='right' title='NFTs' pressed={() => setSelectedItem('NFTs')} selected={selectedItem === 'NFTs'} style={{}} />
          <SelectionItem menuPosition='right' title='Digital' pressed={() => setSelectedItem('Digital')} selected={selectedItem === 'Digital'} style={{}} />
        </View> */}
      </GalleryHeader>

      {/* CONSIDER:  SHould we be able to "extend" the header with this instead?  This way we still get the header interface? hmm... kindal ike... add it as PART of a car, OR do it as a separate accessory? Hmm...  EITHER way... it's JUST a pattern... an ENCODING, and it's ALL interpretive hm... */}

      <DrawerNav.Navigator screenOptions={{ headerShown: false }} drawerContent={props => <CustomDrawer {...props} />}>
        <DrawerNav.Screen name='shop' component={({ navigation }) => (
          <ScrollView style={{ flex: 1 }}>
            <CenteredView containerProps={{ style: { backgroundColor: 'white', height: '100%' } }} style={{ paddingHorizontal: settings.marginSize, paddingTop: 30 }}>
              {/* <Breadcrumbs /> */}
              {/* NOTE:  This is SUCH a SPECIFIC thing we're doing based on ENGLISH!  It's something we "know" (have a mapping for) to encode, but a system can learn this and help us display ugh! */}

              <TouchableOpacity onPress={() => navigation?.toggleDrawer()}>

                <GroupCard style={{ maxWidth: 700, width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', backgroundColor: '#edfffd', borderColor: 'transparent', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Paragraph style={{ color: '#3fcdd1', textAlign: 'center', maxWidth: 300 }}>Showing {typeList.map((_type, index) => index === typeList.length - 1 ? _type + 's' : _type + 's, ')} for {capitalizeAllWords(gender)}</Paragraph>
                    <View style={{ height: 7 }} />
                    <Chiclet backgroundColor='#d7fcf9'>
                      <IconButton style={{ flexGrow: 0, flexShrink: 0, padding: 0, margin: 0 }} size={15} iconColor='#23acb0' icon="filter" />
                      <SubParagraph style={{ color: '#23acb0', margin: 0, padding: 0 }}>Filter</SubParagraph>
                    </Chiclet>

                  </View>

                </GroupCard>

              </TouchableOpacity>



              <View style={{ height: 20 }} />
              <Shop />
            </CenteredView>
          </ScrollView>
        )} />
      </DrawerNav.Navigator>
    </View>

  );
}
