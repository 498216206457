import { Color, RGBAColor } from 'habor-sdk';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { MD3Colors as Colors } from "react-native-paper";
import { FlatGrid } from 'react-native-super-grid';
import { TextField } from '../../../../plugins/gallery/components/fields';
import { GroupCard } from '../../../kelp-bar/group-card';
import { defaultBorderRadius } from '../../../kelp-bar/styles';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

//  TODO:  ALL of this should be installed with a Plugin?  This should be the field for a CUSTOM type, and we should remove the primitive type?

export const renderRGBAColor = (color: RGBAColor) => { return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a || "1"})` };

export interface ColorDotProps {
  color?: RGBAColor;
  onPress?: (color: RGBAColor) => void;
  selected?: boolean;
}

export interface HexColorDotProps {
  color?: string;
  onPress?: (color: string) => void;
  selected?: boolean;
  size: number;
}

//  TODO:  We should have both a Color Viewer AND a Color Editor.  In SOME cases, the user MAY want to edit directly from the viewer!
//         Either way, the generalization is a component that does something.  IF that component happens to edit the field containing its value, that's fine!
//  We can make editors for the WHOLE object, OR allow the user to long press and edit the specific thing??  Remember, long press will bring up a context menu with a stck of actions??

export const ColorDot = ({ color = { r: '0', g: '0', b: '0' }, onPress = () => null, selected }: ColorDotProps) => {
  const rgbaColor = renderRGBAColor(color);
  const handlePress = () => onPress(color);
  selected
  return (
    <TouchableOpacity style={{ borderWidth: selected ? 3 : 0, borderColor: Color.lightGray, width: 40, height: 40, borderRadius: 20, backgroundColor: rgbaColor }} onPress={handlePress} />
  );
};

export const HexColorDot = ({ color = '#eeeeee', onPress = () => null, selected, size }: HexColorDotProps) => {
  const handlePress = () => onPress(color);
  selected
  return (
    <TouchableOpacity style={{ borderWidth: selected ? 3 : 0, borderColor: Color.lightGray, width: size, height: size, borderRadius: 20, backgroundColor: color }} onPress={handlePress} />
  );
};

export const HexColorField = ({ color, setColor }) => {

  return (
    <GroupCard style={{ width: '100%' }}>
      <FlatGrid
        itemDimension={50}
        data={Object.keys(Colors).map(key => Colors[key])}
        renderItem={({ item }) => <Pressable onPress={() => setColor(item)} style={{ backgroundColor: item, height: 20, width: 20, borderRadius: 10, borderColor: color === item ? '#555555' : '#eeeeee', borderWidth: 2 }} />}
      />
      <TextField mode="manual" title="Hex Color" onSave={value => setColor(value)} />
      <View style={{ height: 40, width: 40, backgroundColor: color, borderRadius: defaultBorderRadius }} />
    </GroupCard>
  );
}

export const ColorField = ({ value, update }: { value: RGBAColor, update: (color: RGBAColor) => void }) => {

  //  IDEA:  Add more colors, and render enough to fill screen space?
  const colors: RGBAColor[] = [
    { r: "211", g: "47", b: "47" },
    { r: "123", g: "31", b: "162" },
    { r: "48", g: "63", b: "159" },
    { r: "0", g: "121", b: "107" },
    { r: "104", g: "159", b: "56" },
    { r: "251", g: "192", b: "45" },
    { r: "245", g: "124", b: "0" },
    { r: "93", g: "64", b: "55" },
    { r: "69", g: "90", b: "100" },
    { r: "255", g: "255", b: "255" }
  ];

  return (
    <View style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <FlatGrid itemDimension={40} horizontal={false} data={colors} renderItem={(item) => {
        console.log('test');
        return <ColorDot onPress={(color: RGBAColor) => update(color)} color={item.item} selected={JSON.stringify(item.item) == JSON.stringify(value)} />;
      }} />
    </View>
  );
};

export const sdtColorRenderer = ({ sdt, name, update, value }: SDTRendererParams) => {
  return (
    <DavelField required={sdt.required} key={name} name={name}>
      <ColorField value={value} update={update} />
    </DavelField>
  );
};

//  REFERENCE:  ChatGPT!!!
function rgbToHex({ r, g, b }: RGBAColor) {

  r = parseFloat(r).toString(16);
  g = parseFloat(g).toString(16);
  b = parseFloat(b).toString(16);

  if (r.length == 1)
      r = "0" + r;
  if (g.length == 1)
      g = "0" + g;
  if (b.length == 1)
      b = "0" + b;

  return "#" + r + g + b;
}

function hexToRgb(hex = "#FFFFFF"): RGBAColor {
  let r = parseInt(hex.slice(1, 3), 16).toString();
  let g = parseInt(hex.slice(3, 5), 16).toString();
  let b = parseInt(hex.slice(5, 7), 16).toString();

  return { r, g, b };
}


export const sdtHexColorRenderer = ({ sdt, name, update, value }: SDTRendererParams) => {
  return (
    <DavelField required={sdt.required} key={name} name={name}>
      <ColorField value={hexToRgb(value)} update={(value) => { update(rgbToHex(value))}} />
    </DavelField>
  );
};
