import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { EntityType, InstanceInternal, Menu, menuNoun } from 'habor-sdk';
import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { AppHeader } from '../../../packages/kelp-bar/app-header';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { AppContext } from '../hessia-plugin/AppContext';
import { InstanceCreator } from '../model-plugin/entity/instance-creator';
//  TODO:  Typo in "instance-editor"
//  TODO:  Make sure to have the Menu Plugin DEPEND upon the Entities Plugin and have it INJECT the pieces we need!?  THEN, in the UI we SHOULD see this as well???
//  CONCERN:  OW do we differentiate these "UI Plugins / Systems" from the backend / server???
import { haborSDK } from '../hessia-plugin/config';
import { HaborModelSDK } from '../model-plugin/model-sdk';
import { MenuSettingsList } from './menu-settings-list';

//  TODO:  I THINK we MAY want this nav to be generalied for other systems?  PREHAPS we should blockify it??  Hmm... I REALLLY like the idea of a layout / nav block... in fact, I just really like the idea of representing the data flow with blocks on both the frotne and backend I think?  It's VISUAL, AND reactive, and the data flow uses one system, instead of teh react way of functional callbacks which ALSO affect state... instead, EVERYTHING is done with "state signals"???  Hmmmm... we'll have to see how far we can push this model???

export enum MenuConfigRoute {
  MenuList = "MenuList",
  MenuBuilder = "MenuBuilder"
}

export type MenuStackParamList = {
  [MenuConfigRoute.MenuList]: undefined;
  [MenuConfigRoute.MenuBuilder]: {
    menu?: InstanceInternal<Menu>
  };
};

const Stack = createStackNavigator();

export interface MenuConfigNavigatorProps { }
interface MenuConfigNavigatorState { }
export class MenuConfigNavigator extends React.Component<MenuConfigNavigatorProps, MenuConfigNavigatorState> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: MenuConfigNavigatorProps) {
    super(props);
    this.state = {}
  }
  public render = () => {

    const modelSDK = new HaborModelSDK(haborSDK, this.context.frameworkContext.token)

    const { frameworkContext, componentContext } = this.context;
    return (

      //  NOTE:  I THINK we need "independent" here, otherwise, it's a disconnected stack... In other words, it's NOT attached as a node in ANOTHER navigator.  perhaps we can "attach" it by passing the "navigation" prop explicitly?  Hmm... I think navigation will certainly get interesting.  We MAY end up writing our own...  FOR NOW, this satisfies my requirement.
      <NavigationContainer independent={true}>
        <Stack.Navigator headerMode='none'>
          <Stack.Screen name={MenuConfigRoute.MenuList} component={MenuSettingsList} />
          {/* TOOD:  Centralize this! */}
          <Stack.Screen name={MenuConfigRoute.MenuBuilder} children={
            ({ navigation, route }) => {
              const menu: InstanceInternal<Menu> = (route?.params as any)?.menu;
              //  TODO:  Rename to "InstanceEditor" for BOTH create / edit instead of "InstanceCreator"?  OR perhaps "InstanceBuilder"???
              return (
                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <AppHeader title={menu ? "Edit Menu" : "Create Menu"} />
                  <ScrollView style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#f7f7f7' }} contentContainerStyle={{ padding: medSpacer }}>
                    <InstanceCreator modelSDK={modelSDK} nounId={{ type: EntityType.Noun, nounId: menuNoun.id }} instanceId={menu ? { type: EntityType.Instance, nounId: menu.nounId, instanceId: menu.id } : undefined} />
                  </ScrollView>
                </View>
              );
            }
          } />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}