import { CorePluginClass } from "halia";
import moment from 'moment';
import * as React from 'react';
import { Clipboard, FlatList, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import Markdown from 'react-native-markdown-renderer';
import { IconButton } from "react-native-paper";
import { AsyncStorageNounService, NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { TextField } from "../gallery/components/fields";
import { Graph2Context, Node } from "./property-plugin";
import { Hessia2Context, Hessia2Plugin } from "./hessia2-plugin";
import { ClassPluginContext, PatternPlugin } from "./class-plugin";
import { DavelForm } from "../../packages/davel-ui/davel-ui";
const uuid = require("uuid/v4");

const markDownStyle = StyleSheet.create({
  root: {
    fontFamily: 'Poppins-SemiBold',
  },
  view: {
    fontFamily: 'Poppins-SemiBold',
  },
  codeBlock: {
    borderWidth: 1,
    borderColor: '#CCCCCC',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
  },
  codeInline: {
    borderWidth: 1,
    borderColor: '#CCCCCC',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
  },
  del: {
    backgroundColor: '#000000',
  },
  em: {
    fontStyle: 'italic',
  },
  headingContainer: {
    flexDirection: 'row',
  },
  heading: {
    fontFamily: 'Poppins-Bold',
    letterSpacing: -0.5
  },
  heading1: {
    fontFamily: 'Poppins-Bold',
    fontSize: 32
  },
  heading2: {
    fontFamily: 'Poppins-Bold',
    fontSize: 24,
  },
  heading3: {
    fontFamily: 'Poppins-Bold',
    fontSize: 18,
  },
  heading4: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
  },
  heading5: {
    fontFamily: 'Poppins-Bold',
    fontSize: 13,
  },
  heading6: {
    fontFamily: 'Poppins-Bold',
    fontSize: 11,
  },
  hr: {
    backgroundColor: '#000000',
    height: 1,
  },
  blockquote: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    margin: 20,
    backgroundColor: '#eeeeee',
    borderRadius: 5
  },
  inlineCode: {
    borderRadius: 3,
    borderWidth: 1,
    fontFamily: 'Courier',
    fontWeight: 'bold',
  },
  list: {},
  listItem: {
    flex: 1,
    flexWrap: 'wrap',
    // backgroundColor: 'green',
  },
  listUnordered: {},

  listUnorderedItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  // listUnorderedItemIcon: {
  //   marginLeft: 10,
  //   marginRight: 10,
  //   ...Platform.select({
  //     [PlatformEnum.IOS]: {
  //       lineHeight: 36,
  //     },
  //     [PlatformEnum.ANDROID]: {
  //       lineHeight: 30,
  //     },
  //   }),
  // },
  listUnorderedItemText: {
    fontSize: 20,
    lineHeight: 20,
  },

  listOrdered: {},
  listOrderedItem: {
    flexDirection: 'row',
  },
  // listOrderedItemIcon: {
  //   marginLeft: 10,
  //   marginRight: 10,
  //   ...Platform.select({
  //     [PlatformEnum.IOS]: {
  //       lineHeight: 36,
  //     },
  //     [PlatformEnum.ANDROID]: {
  //       lineHeight: 30,
  //     },
  //   }),
  // },
  listOrderedItemText: {
    fontWeight: 'bold',
    lineHeight: 20,
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  hardbreak: {
    width: '100%',
    height: 1,
  },
  strong: {
    fontWeight: 'bold',
  },
  table: {
    borderWidth: 1,
    borderColor: '#000000',
    borderRadius: 3,
  },
  tableHeader: {},
  tableHeaderCell: {
    flex: 1,
    // color: '#000000',
    padding: 5,
    // backgroundColor: 'green',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#000000',
    flexDirection: 'row',
  },
  tableRowCell: {
    flex: 1,
    padding: 5,
  },
  text: {
    fontFamily: 'Poppins-Regular',
  },
  strikethrough: {
    textDecorationLine: 'line-through',
  },
  link: {
    textDecorationLine: 'underline',
  },
  blocklink: {
    flex: 1,
    borderColor: '#000000',
    borderBottomWidth: 1,

  },
  u: {
    borderColor: '#000000',
    borderBottomWidth: 1,
  },
  image: {
    flex: 1,
  },
});



export interface Note extends Node {
  value: {
    body: string;
    created: string;
    updated: string;
  }
}

const noteItemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontFamily: 'Poppins-Bold',
    color: '#3B3B3B',
  },
  date: {
    fontSize: 12,
    fontFamily: 'Poppins-SemiBold',
    color: '#757575',
  },
  iconContainer: {
    marginLeft: 16,
  },
  icon: {
    fontWeight: 'bold',
  },
});

// export const NoteItem = ({ noteInternal, notePartial, notePlugin, onCreated, onUpdated, onChange }: { noteInternal?: NounServiceInstanceInternal<Note>, notePartial?: Partial<Note>, notePlugin: Notes2Plugin, onCreated?: (noteInternal: NounServiceInstanceInternal<Note>) => void, onUpdated?: (noteInternal: NounServiceInstanceInternal<Note>) => void, onChange?: (noteInternal: NounServiceInstanceInternal<Note>) => void }) => {

//   return (
//     <View style={{ paddingHorizontal: smallSpacer, display: 'flex', flexDirection: 'column' }}>

//       <View style={{}}>

//         <Text>{noteInternal?.payload.title}</Text>

//       </View>

//       <View style={{ display: 'flex', flexDirection: 'row' }}>
//         <Text>{noteInternal?.updated}</Text>
//         <Text>{noteInternal?.payload.content}</Text>

//       </View>

//     </View>
//   );
// }

//  NOTE:  This is essentially a document store which is good for mapping an encoding hm...
// export const noteService = new AsyncStorageNounService<Note>("notes2");

const NoteListItem = ({ note, onPress, selected, key }: { note: Note, onPress: (note: Note) => void, selected: any, key: any }) => {

  const { name, value: { body, created, updated } = {} } = note;

  const formattedDate = moment(updated || created).format('MMM D, YYYY');

  return (
    <TouchableOpacity key={key} onPress={() => onPress(note)} style={[noteItemStyles.container, { backgroundColor: selected ? '#eeeeee' : 'white' }]}>
      <View style={noteItemStyles.textContainer}>
        <Text style={noteItemStyles.title}>{name}</Text>
        <Text style={noteItemStyles.date}>{formattedDate}</Text>
      </View>
      {/* <View style={noteListStyles.iconContainer}>
        <AntDesign name="right" size={16} color="#757575" style={noteListStyles.icon} />
      </View> */}
    </TouchableOpacity>
  );
};


const NoteView = ({ editMode, note, updateNote }: { editMode: boolean, note?: Note, updateNote }) => {

  return (
    <View style={{ flex: 1 }}>

      {/* Title Container */}
      <View style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, paddingHorizontal: 30, paddingVertical: editMode ? 30 : 15 }}>
        {
          editMode ? (
            <TextField title="Title" mode="automatic" value={note?.name} onSave={name => updateNote({ name })} />
          ) : (
            <Text style={{ fontFamily: 'Poppins-Bold', fontSize: 20 }}>{note?.name}</Text>
          )
        }
      </View>


      {/* Content Container */}
      <View style={{ flex: 1, paddingHorizontal: 30, paddingVertical: 20 }}>
        {
          editMode ? <TextInput style={{ flex: 1 }} multiline={true} value={note?.value?.body} onChangeText={body => updateNote({ body })} /> : <Markdown style={markDownStyle}>{note?.value?.body}</Markdown>
        }
      </View>


      {/* Meta View */}
      <View style={{ flexDirection: 'column', height: 60, borderTopColor: '#eeeeee', borderTopWidth: 1, padding: 10, backgroundColor: '#f7f7f7', borderRadius: 5 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ flex: 1, fontFamily: 'Poppins-SemiBold', fontSize: 13 }}>{note?.id}</Text>
          <IconButton
            icon={'content-copy'}
            onPress={() => note ? Clipboard.setString(note?.id) : null}
            style={metaStyle.linkIconButton}
            iconColor="#555555"
            size={20}
          />
        </View>

      </View>

    </View>
  );
}

const metaStyle = StyleSheet.create({
  linkIconButton: {
    backgroundColor: '#F5F5F5',
    marginHorizontal: 4,
    borderRadius: 8
  },
});

const noteAppStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: '#eeeeee',
    borderBottomWidth: 1,
    height: 50,
    paddingHorizontal: 10,
    alignItems: 'center'
  },
  title: {
    flex: 1,
    fontSize: 18,
    fontFamily: 'Poppins-Bold',
    letterSpacing: -0.5,
    display: 'flex'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  editIconButton: {
    backgroundColor: '#F5F5F5',
    marginHorizontal: 4,
    borderRadius: 8
  },
  addIconButton: {
    backgroundColor: '#6200EE',
    marginHorizontal: 4,
    borderRadius: 8
  },
  statusIconButton: {
    backgroundColor: '#6200EE',
    marginHorizontal: 4,
    borderRadius: 8
  },
});

/**
 * Syncronizes notes every 500 ms.
 * CONSIDER:  This can be generalized for lots of types?
 * @param changeSet 
 * @param notes 
 * @param onSync 
 * @returns 
 */
const useNoteSync = (changeSet, notes, onSync) => {

  const [saved, setSaved] = React.useState(true);
  const changeSetRef = React.useRef(changeSet);
  const notesRef = React.useRef(notes);
  const graph = React.useContext(Graph2Context);

  const syncNotes = async () => {
    for (const noteId of changeSetRef.current) {
      const note = notesRef.current.find((note) => note.id === noteId);
      if (note) {
        await graph?.updateEntity(note.id, note);
      }
    }
    setSaved(true);
    onSync();
  };

  //  Make an Interval to Sync every 500ms
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      syncNotes();
    }, 500);
    return () => {
      clearInterval(intervalId);
      syncNotes();
    };
  }, []);

  //  Sync the Ref and Update Saved Status
  React.useEffect(() => {
    if (changeSet.length) {
      setSaved(false);
    }
    changeSetRef.current = changeSet;
  }, [changeSet])

  React.useEffect(() => {
    notesRef.current = notes;
  }, [notes])

  return { saved };
};

const NoteList = () => {

  const graph = React.useContext(Graph2Context);
  const type2 = React.useContext(ClassPluginContext);

  const [notes, setNotes] = React.useState<Note[]>([])
  const [selectedNoteId, setSelectedNoteId] = React.useState<string | undefined>(undefined);
  const [changeSet, setChangeSet] = React.useState<string[]>([]);

  //  CONSIDER:  Visual Editor, Markdown Editor, Note View (also consider using AI to quickly export to ANY format mm!!)
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const { saved } = useNoteSync(changeSet, notes, () => {
    setChangeSet([]);
  });


  const selectedNote = notes.find(note => note.id == selectedNoteId);

  const loadNotes = async () => {
    const _notes = await type2?.getInstancesFromTypeName("note");
    if (!_notes) { return; }
    setNotes(_notes as Note[]);
  }

  React.useEffect(() => {
    loadNotes();
    return () => {
      console.log("Unmounting!!!")
    }
  }, [])

  const updateNote = async ({ body, name }: { body?: string, name?: string }) => {
    if (!selectedNoteId || !selectedNote) { return; }
    const { value: { body: oldBody, created }, name: oldName } = selectedNote;
    const newNotes = [...notes];
    const index = newNotes.findIndex((note) => note.id == selectedNoteId);
    if (index === -1) { return; }
    newNotes[index] = { ...selectedNote, name: name || oldName, value: { body: body || oldBody, created, updated: new Date().toISOString() } };
    setNotes(newNotes);
    if (!changeSet.includes(selectedNoteId)) {
      setChangeSet([...changeSet, selectedNoteId]);
    }
  }

  const createNote = async () => {
    const id = uuid();
    const newNoteInternal = await graph?.createNode({ name: "Untitled Note", entityType: "node", id, emoji: '📔', description: 'A Note', value: { body: "", created: new Date().toISOString() } });
    if (!newNoteInternal) { alert("Failed to create note node"); throw new Error("Could not create the note node") };
    try {
      await type2?.registerInstance(id, "note");
    } catch (err) {
      alert("failed to create note edge!")
    }
    await loadNotes();
    setSelectedNoteId(id);
  }

  return (

    <View style={{ backgroundColor: '#fdfdfd', flex: 1 }}>

      {/* Notes Header */}
      <View style={noteAppStyles.header}>
        <Text style={noteAppStyles.title}>📔  Notes</Text>

        <View style={noteAppStyles.buttonGroup}>
          <IconButton
            icon={editMode ? 'pencil' : 'eye'}
            onPress={() => setEditMode(!editMode)}
            style={noteAppStyles.editIconButton}
            iconColor="#555555"
            size={20}
          />
          <IconButton
            icon="plus"
            onPress={createNote}
            style={noteAppStyles.addIconButton}
            iconColor="white"
            size={20}
          />

          {/* <View style={{ width: 1, marginHorizontal: 10, backgroundColor: '#eeeeee' }} />

          <IconButton
            icon={saved ? "check" : "warning"}
            style={noteAppStyles.statusIconButton}
            iconColor="white"
            size={20}
          /> */}

        </View>
      </View>

      {/* Notes Container */}
      <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>

        {/* Note List */}
        <ScrollView style={{ flex: 1, borderRightColor: '#eeeeee', borderRightWidth: 1, height: '100%' }} showsHorizontalScrollIndicator={false}>
          <FlatList keyExtractor={(item) => item.id} style={{ borderBottomWidth: 0, flex: 1 }} ItemSeparatorComponent={() => <View style={{ height: 5 }} />} data={notes} renderItem={({ item }) => <NoteListItem key={item.id} note={item} onPress={() => setSelectedNoteId(item.id)} selected={selectedNote === item} />} />
        </ScrollView>

        {/* Note Container */}
        <View style={{ flexDirection: 'column', flex: 3 }}>
          <NoteView note={selectedNote} updateNote={updateNote} editMode={editMode} />
        </View>

      </View>


    </View>
  )
}

export const Notes2Context = React.createContext<Notes2Plugin | undefined>(undefined);
export class Notes2Plugin extends CorePluginClass {

  public static details = {
    name: "Notes",
    description: "Updated Notes System",
    dependencies: [Hessia2Plugin.details.id, PatternPlugin.details.id],
    id: "notes2"
  }

  public install = async (program: any, { hessia2, type2 }: { hessia2: Hessia2Plugin, type2: PatternPlugin }) => {

    // alert("Installing notes")
    // await noteService.init();

    //  Create the "Node" Type
    await type2.createTypeIfNeeded({ id: "note", name: "Note", description: "Note Type", entityType: "node", emoji: "📚" });

    //  Register System

    // alert("Initialized Service");
    const Notes2System = {
      color: "#aaaaaa",
      name: "Notes",
      description: "Manage Notes",
      emoji: "📔",
      component: NoteList
    }

    hessia2.registerHOC(({ children }) => {
      const hessia2 = React.useContext(Hessia2Context);
      React.useEffect(() => {
        hessia2?.installSystem(Notes2System);
      }, []);
      return (
        <>
          {children}
        </>
      );
    });

    return this;
  }
}


//  TODO:  We need an interface where we can query the objects in this system in a standardized way, like "Get Entities", and "Get Properties", and search over the properties in general ways.  Maybe use an LLM for that? hmm!
//  TODO:  For now, we'll build a "Notes Entity" Coupling plugin which makes it easy to create an entity when a note is created.  This way, we can easily show those entities in the system, and we'll also register ... actually, I like the idea of provider a BIT better to start... We want the API anyways, so it'll make it easy hmm... I guess I've changed my mind on that.  Before it seemed easier to do it the other way, but by providing, we get our API (which I'll want anyways).
