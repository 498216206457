import { NavigationProp } from '@react-navigation/core';
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Resource, ResourcePlugin } from '.';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { ResourceRoute } from './resource-navigator';

export const topRegister: any[] = [];

// export const ResourceListItem = ({ resource, resourcePlugin }: { resource: NounServiceInstanceInternal<Resource>, resourcePlugin: ResourcePlugin }) => {
//   return <Deferred func={renderResourceListItem} args={{ resourcePlugin, resource }} def={<View style={{ padding: 15, backgroundColor: '#eeeeee', borderRadius: 10 }}><ActivityIndicator /></View>} />
// }

// export const renderResourceListItem = async ({ resource, resourcePlugin }: { resource: NounServiceInstanceInternal<any>, resourcePlugin: ResourcePlugin }) => {

//   if (!resource) {
//     throw "No Resource Passed to 'renderResourceListItem'";
//   }

//   if (!resourcePlugin) {
//     throw "No ResourcePlugin Passed to 'renderResourceListItem'";
//   }

//   let itemText;
//   const comps: ResourceListItemComponent[] = [];

//   const api: ResourceListItemAPI = {
//     setText: (text: string) => {
//       itemText = text;
//     },
//     //  TODO:  Allow extra props from the CSS-like injection system? Hmm...  MAYBE we can allow that system to control LAYOUT too? HMm
//     //  TODO:  Hoist this?? HM!
//     addComponent: (comp: ResourceListItemComponent) => {
//       comps.push(comp);
//     }
//   }

//   //  TOOD:  Remove "setText" only ONE will win.'
//   // alert("renderers: " + resourceListItemRenderers.length);
//   for (let i = 0; i < resourcePlugin.resourceListItemRenderers.length; i++) {
//     //  CONSIDER:  Instead of callig THESE thigns "renderers", they're more like MODIFIERS... they're more like PLUGINS.. hm!
//     const renderer = resourcePlugin.resourceListItemRenderers[i];
//     // alert(renderer);
//     //  THOUGHT:  Instead of rendering DIRECTLY, what if we have a RENDER API that we can use to do thigs!  THEN the renders can ALL run and safely use the API? hmm.. perhaps!  BUT what about order??? Hmm....
//     //  CONSIDER:  We could have this all over the place with Joi? Hmm...
//     if (!renderer.renderer) {
//       alert("Missing Renderer: " + renderer.name)
//       continue;
//     }

//     await renderer.renderer(resource, api);
//   }
//   return (
//     <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//       {
//         itemText ? <Text>{itemText}</Text> : null
//       }

//       {
//         //  TODO:  Remove "any"
//         //  TODO:  Support multiple TYPES of injections even into these small things H!M  AND we can use coupling systems to inject into other systems which inject these things! HM!  These systems COULD also go out and retrieve an encoding somehow and then map that to components.  OR we can inject components directly.  It's whatever the system wants to support on its API!? HM!

//         (comps as any[]).map((Comp) => (
//           <View style={{ marginTop: 10, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
//             <Comp resource={resource} />
//           </View>
//         ))
//       }
//     </View>
//   );
// }

interface ResourceListState {
  resources: Resource[];
  loaded: boolean;
}
class ResourceListBase extends React.Component<{ navigation: NavigationProp<any>, resourcePlugin: ResourcePlugin }, ResourceListState> {

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      loaded: false
    }
  }

  public async componentDidMount() {
    const resources = await this.props.resourcePlugin.getResources();
    // alert(JSON.stringify(resources))
    this.setState({ resources, loaded: true });
  }

  render() {

    const { navigation } = this.props;

    const { resources, loaded } = this.state;

    const ResourceWidget = ({ resource }: { resource: Resource }) => {

      return (
        <TouchableOpacity style={{ backgroundColor: 'white', flex: 1, borderRadius: 20, padding: 20, marginBottom: 15, display: 'flex', flexDirection: 'column', overflow: 'hidden' }} onPress={() => navigation.navigate(ResourceRoute.Editor, { resource })}>
          <Text>{resource.systemId}</Text>
          <Text>{resource.route}</Text>
        </TouchableOpacity>
      );
    }

    return (
      <PageLoader loading={!loaded}>
        <Page style={{ marginHorizontal: 20 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <FlatGrid itemDimension={250} renderItem={(({ item: resource }) => <ResourceWidget resource={resource} />)} data={resources} />
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedResourceList = (props: any) => <ResourceListBase {...props} />
export const ResourceList: any = InjectedResourceList;