/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Edge
 */
export interface Edge {
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    srcRef: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    difRef: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    dstRef: string;
}

export function EdgeFromJSON(json: any): Edge {
    return EdgeFromJSONTyped(json, false);
}

export function EdgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Edge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': json['created'],
        'updated': json['updated'],
        'srcRef': json['srcRef'],
        'difRef': json['difRef'],
        'dstRef': json['dstRef'],
    };
}

export function EdgeToJSON(value?: Edge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'updated': value.updated,
        'srcRef': value.srcRef,
        'difRef': value.difRef,
        'dstRef': value.dstRef,
    };
}

