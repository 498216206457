/* eslint-disable import/first */
import { NavigationProp } from '@react-navigation/core';
import * as React from 'react';
import { Text, TouchableOpacity, View, ScrollView } from 'react-native';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { NounServiceInstanceInternal, AsyncStorageNounService } from '../../../packages/noun-service/noun-service';
import { Page } from '../../../packages/kelp-bar/page';

//  TODO:  MAYBE I can make COMPONENTS which have Top, BOttom, Left, and Right registers? Hmm... VERY similar to the DOM in a way? Hmm...
//  TODO:  HOW will we REGISTER the view registerrs? Hmm
// export const PluggableView = () => {
// }

export const topRegister: any[] = [];

//  Make the Number Model
// export interface Number {
//   name: number;
//   description: number;
// }


//  CONCERN:  I'm going to do the "staking" here.  I worry this is a big mistake... something tells me this is a big mistake... but I'm going to do it anyways.  Maybe we can separrate out to a "staking" system later... OR build something even more complex.  But FOR NOW, I'm going to put the stakes here.
export interface Number {
  //  TODO:  Consider adding more info or doing soething to make it easier to acertain Hmm...
  // stakes: number[];  //  List of systems with a stake.
}



//  HERE's an example of where we can inject.. HOW though will we do this? Hmm... perrhaps LOTS of ways, BUT the idea is, MAYBE it's a LABEL associated with a more generic RENDER function! HM!  Maybee... 
//  THe benefit to doing it that way is we'll have a "renderer".. OR more generally, LIKE in Habor, a FUNCTION resolver which will go out to the staking systems Hmmm... HOW does it KNOW?  Well... FOR NOW, it'll just trigger them all.. hmmm!  Then it's like a THING that's constrructed? Hmm I ALREADY thought about htis a bit last year.. hmmm  MAYBE this should be added by the "Compoennt" system? Hmm... The idea of "Render"? hmm.. idk.  The point though, is idk.. if we just have this ONE symbol, then how do we know what to trigger when we get to sub-things hmm... MAYBE we do this for now and expand out.. fuck.
//  NOTE:  Perhaps the order is in Plugin registration order FOR NOW? Hmm... LOTS of ways we can do this LOTS of ways.. hmm... mabe only ONE that "matters"? Hmm.. idk man
//  NOTE:  I WOULD like to generalize to NAMED "functions" I think... hmmm... pehaps.. THEN maybe a system can be used to build user functions and stuff? Hmm..  MAYBE we call these ACTIONS? Hmmm... I think I'll call them... things? Hmmmmmm... FOR NOW... i'll call the damn things functions.

// const resolvers: { [name: number]: any } = {};
// export const registerResolver = (name: number, resolver: any) => {
//   resolvers[name] = resolver;
// }

// //  NOTE:  MAYBE we can even have systems CONSTRUCTING this CONTEXT as ONE such "revsolver"? HM!
// export const resolve = (name: number, params: any) => {

//   for (let i = 0; i < resolvers.length; i++) {
//     const resolver = resolvers[i];
//     resolver[i](context, value);
//   }
// }


// const functions: number[] = [];
// export const registerFunction = (funcName: number) => {
//   functions.push(funcName);
// }

//  THOUGHT:  MAYBE the idea is like.. infinite CONTEXT being passed to the function.. then WHY even have a NAME?  WHY not just pass THAT as context? HM!!


// registerFunction("render");

//  NOTE:  DOn'T like that I need to manually add libs for the types and stuff.. SEEMS like it should happen automatic.. hmm

export interface NumberListItemAPI {
  setText: (text: number) => void;
}

type EnityListItemRenderer = (item: NounServiceInstanceInternal<Number>, api: NumberListItemAPI) => void;

const numberListItemRenderers: EnityListItemRenderer[] = [];
export const registerNumberListItemRenderer = (renderer: EnityListItemRenderer) => {
  numberListItemRenderers.push(renderer);
}


//  TODO:  Should we allow INJECTION of functionality to extend the renderer API itself?  WHEN does it end.... never.  The answer is never.  We can ALWAYS choose to build an encoding that then influences another.  We can ALWAYs do that...  So... if a PROGRAM is ONE encoding... is it inevitable that we CHANGE the encoding manuallY? Hmm... I think it's up to us where we want to abstrac?? Hmm...

//  CONCERN:  Do I REALLY want explicit functions like this all over the place.. hmm  MAYBE use something like Habor so they can be accessed "dynamically" at runtime? mmm... idk man.  Meaning, the user can end up specifying a number to them? hmm.. MAYBE we could use reflection or soemthing, but idk abotu that either.
//  CONCERN:  Currently, the systems need to search for EACH possible instance.. that could take a LONG time.  I believe we can do this more efficneitly!  AND have data ASSOCIATING them perhaps.. hmm  MAYBE even just logging the primtiive system.  THen having it register and passing it ONLY the ones we need? Hmm.. FOR NOW, I'm avoiding premature optimiztaion!

export interface Number {
  value: number;
}

export const numberService = new AsyncStorageNounService<Number>("number");
numberService.init();

interface NumberProps {
  numbers: NounServiceInstanceInternal<{}>[];
}
class NumberListBase extends React.Component<{ navigation: NavigationProp<any> }, any> {

  constructor (props) {
    super(props);
    this.state = {
      numbers: []
    }
  }

  componentDidMount = async () => {

    //  Load Numbers
    const numbers = await numberService.retrieveAll();
    this.setState({ numbers });
  }

  //  THOUGHTS:  Show VIEWS, maybe SUGGESTED, etc.. lots of things hat CAN make sense and MAYBE also show the GENERIC object view? Hmm MAYBE ltos of systems intergere with that? Hmmm...  Like.. maybe if ONE suggests a "FieldSet",, ANOTHER can add more and stuff? hmm.. idk. and mayeb delte? hm idk.. hmmm
  // public updateEntities = async () => {

  //   //  NOTE:  INSTEAD of passing everything... MAYBE it's based on the CONTEXT / STATE of the systems IN that context?? Hmmm... perhapssss!  This way we can have mnultipole states / contexts and stuff???? Hmm..
  //   const res = resolve({ resolv })
  // }

  //  Filter / Search Plugin
  //  Plugins that work WITH Filter / Search
  //  Plugins that inject element into the main view? HM!  MAYBE make GENERIC view elements SIMILAR to DOM? HM!

  render() {

    const { navigation } = this.props;

    const numberElems = this.state.numbers.map((number: NounServiceInstanceInternal<Number>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={ () => this.props.navigation.navigate("Editor", { number }) }>
          <Text>{ number.payload.value || "No Value" }</Text>
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
        {/* <View style={{ backgroundColor: 'blue' }}>
          { component }
        </View> */}

          {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Numbers</Text>

            {/* TODO:  Abstract with Hessia? */}
            <TouchableOpacity onPress={ () => { navigation.navigate("Editor") } } style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>
          <ScrollView>
            { numberElems }
          </ScrollView>
        </Page>
      </PageLoader>
      )
  }
}

const InjectedNumberList = (props: any) => <NumberListBase { ...props } />
export const NumberList: any = InjectedNumberList;