import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { ImageBackground, Text, TouchableOpacity, View, Image } from 'react-native';
import { primaryFontFamily } from '../../../packages/kelp-bar/styles';
import { defaultBorderRadius, Paragraph } from '../constants';
import { Work } from '../openapi';
import { extractImageIdFromUrl } from '../utilities';

export interface AggWork {
  work: Work;
  imageUrl?: string;
  minWidth?: number;
}

interface Dimension {
  height: number;
  width: number;
}

export const WorkCard = ({ aggWork, minWidth }: { aggWork: AggWork, minWidth?: number }) => {

  const navigation = useNavigation();
  const [{ height, width }, setDimension] = React.useState<Dimension>({ height: 250, width: 250 });

  const { imageUrl } = aggWork;

  React.useEffect(() => {
    if (imageUrl) {
      Image.getSize(imageUrl, (width, height) => { setDimension({ width, height }) });
    }
  }, [imageUrl])


  const [itemWidth, setItemWidth] = React.useState<number>(300);

  //  Ok!  We're going to use constant product and hmm.. idk hmm...  That keeps the AREA the same.


  //  This means that:
  //  1.  width * height = area
  //  2.  width / height = ratio

  //  [x] width = area / height
  //  [x] (area / height) / height = ratio
  //  [x] (area / height) = ratio * height
  //  [x] area = ratio * height^2

  //  height = sqrt(area / ratio)
  //  width = area / height


  //  We want to surround the image.
  const imageAspectRatio = width / height;
  const itemHeight = 300;


  //  Compress the Width
  let itemImageWidth = itemWidth;
  let itemImageHeight = itemWidth / imageAspectRatio;

  //  Compress the Height (if needed)
  if (itemImageHeight > itemHeight) {
    itemImageHeight = 300;
    itemImageWidth = itemImageHeight * imageAspectRatio;
  }

  //  Construct a new URL to get the "Optimized" image
  const imageRequest = JSON.stringify({
    bucket: "bad-art-studio",
    key: extractImageIdFromUrl(aggWork.imageUrl),
    edits: {
      resize: {
        height: itemImageHeight,
        width: itemImageWidth,
        fit: 'contain',
        background: { r: 255, g: 255, b: 255 }
      }
    }
  });

  console.log(imageRequest);

  const url = `https://daj8no6oioxxi.cloudfront.net/${btoa(imageRequest)}`;

  console.log(url);

  return (
    <TouchableOpacity onPress={() => navigation.navigate("WorkDetail" as never, { creationId: aggWork.work.id } as never)} style={{
      height: 400,
      width: '100%',
      display: 'flex',
      overflow: 'hidden',
      // borderColor: '#aaaaaa',
      // borderWidth: 2,
      // shadowColor: 'black',
      // shadowOffset: { height: 2, width: 2 },
      // shadowOpacity: 0.1,
      // shadowRadius: 7
      backgroundColor: 'white'
    }}>

      <View onLayout={(event) => setItemWidth(event.nativeEvent.layout.width)} style={{ height: itemHeight, width: '100%', display: 'flex', borderBottomColor: '#eeeeee', borderBottomWidth: 0, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
        <ImageBackground style={{ width: itemImageWidth, height: itemImageHeight, borderRadius: defaultBorderRadius, overflow: 'hidden' }} resizeMode="contain" source={{ uri: url }} />
      </View>

      <View style={{ padding: 20, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Paragraph style={{ textAlign: 'center', fontFamily: primaryFontFamily, fontSize: 18, color: '#333333' }}>{aggWork.work.title}</Paragraph>
        <View style={{ height: 7 }} />
        <Paragraph style={{ textAlign: 'center', fontFamily: 'Poppins-Medium', fontSize: 16, color: '#888888' }}>{aggWork.work.description}</Paragraph>
      </View>
    </TouchableOpacity>
  );
}