import { FrameworkContext, HaborComponent, HaborComponentContext, InstanceInternal } from 'habor-sdk';
import * as React from 'react';
import { View } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { ActivityBubbleView } from './activity-bubble-view';
import { ActivityPlugin, ActivityPluginContext } from '.';

interface WorkspaceActivityProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  activityPlugin?: ActivityPlugin;
}

interface WorkspaceActivityState {
  activities: InstanceInternal<any>[];
  pendingActivityEvents: InstanceInternal<any>[];
}

export class WorkspaceActivity extends React.Component<WorkspaceActivityProps, WorkspaceActivityState> {

  constructor(props: any) {
    super(props);
    this.state = {
      activities: [],
      pendingActivityEvents: []
    }
  }

  //  TODO-IMPORTANT:  Eventually use a Subscription for this!  We DON'T want to be manually telling the app to update each time we do!  MAYBE start by supporting a basic subscription for EACH of our types??
  private updateActivities = async () => {

    //  Unpack
    const { frameworkContext: { token } } = this.props;

    if (!this.props.activityPlugin) { throw 'No Activity Plugin Provided'; }

    //  Get Activities
    const activities = await this.props.activityPlugin.getActivities(token);

    //  Get Pending Activity Events
    //  CONCERN:  It WOULD be nice to be able to see data like the LAST time a particular activity was logged.
    //  IDE:  MAYBE we can have a "Metadata" API to inspect object changes and stuff??? hmm  MAYBE we can do this in ES by default? Hmm
    const pendingActivityEvents = await this.props.activityPlugin.getPendingEvents(token);

    this.setState({ activities, pendingActivityEvents });
  }

  public componentDidMount = async () => {
    await this.updateActivities();
  }

  public render = () => {

    if (!this.props.activityPlugin) { throw "Missing Activity Plugin" }

    //  Unpack
    const { frameworkContext, componentContext } = this.props;
    const { frameworkContext: { changePage } } = this.props;
    const { activities, pendingActivityEvents } = this.state;

    return (
      // <WorkspaceContainer { ...this.props } title="Activity" buttonStyle={ WorkspaceButtonStyle.Configure } scroll={ false }>
      //  NOTE:  ALL Routing is FLAT, just with passed CONTEXT.
      <View style={{ flex: 1, padding: medSpacer }}>
        {/* <ContainerList componentContext={ componentContext } frameworkContext={ frameworkContext } context={{ name: "activity-page" }} items={ activities } onItemPress={ (item) => { changePage({ pageName: "Instance Creator", props: { nounId: { nounId: "activiy-event", type: EntityType.Noun }, initial: { activities: { instanceIdList: [ { nounId: item.nounId, instanceId: item.id, type: EntityType.Instance } ] } } } }) } }  /> */}
        <ActivityBubbleView activityPlugin={ this.props.activityPlugin } updateActivities={ this.updateActivities } componentContext={ componentContext } frameworkContext={ frameworkContext } activities={ activities } />
      </View>
    );
  }
}

//
//  Activity Primitive Component
//
export interface ActivityHaborPrimitiveProps extends PrimitiveProps {}
export const ActivityHaborPrimitive = ({ userProps, frameworkProps }: ActivityHaborPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  const activityPlugin = React.useContext(ActivityPluginContext);

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <WorkspaceActivity activityPlugin={ activityPlugin } frameworkContext={ context } componentContext={ componentContext } />
    </HaborContainer>
  );
};

// registerPrimitiveHaborComponent('ActivityHaborPrimitive', ActivityHaborPrimitive);

export const ActivityHaborComponent: HaborComponent = {
  name: "ActivityHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "ActivityHaborPrimitive",
    props: {},
    children: []
  }
};

// registerHaborComponent(ActivityHaborComponent);

// //  Make the Page
// export const dashboardPage: Page = {
//   name: "Activity",
//   element: { name: 'ActivityHaborComponent', children: [], props: {  } }
// };

