
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { TextParagraph } from '../../packages/kelp-bar/text';
import { Touchable } from 'react-native';

export interface NamedObject {
  name: string;
  description?: string;
  icon?: { type: string, name: string };
  foregroundColor?: string;
  backgroundColor?: string;
}

export interface Widget extends NamedObject {
  component: any;
  pluginId: string;
}

export const WidgetWrapper = ({ editMode = false, widget }: { editMode: boolean, widget: Widget }) => {

  const [collapsed, setCollapsed] = React.useState(false);

  const { component: Widget, name } = widget;

  //  TODO:  Go to an Entity for the Widget (meta-model).
  //  TODO:  Support NESTED widgets.
  const onLongPressDefault = () => {
    alert(widget.pluginId);
  }

  return <Pressable  onLongPress={onLongPressDefault}>
    {
      editMode ? (
        <View style={{ backgroundColor: '#fcfcfc', borderRadius: 5, borderColor: '#eeeeee', borderWidth: 1 }}>
          <View style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', padding: 15 }}>
            <Icon name="drag-indicator" type="material" color="#999999" />
            <View style={{ width: 10 }} />
            <TextParagraph style={{ color: "#777777" }}>{name}</TextParagraph>
            <View style={{ flex: 1 }} />
            <Icon name={collapsed ? "chevron-back" : "chevron-down"} onPress={() => setCollapsed(!collapsed)} type="ionicon" color="#999999" />
          </View>
          {
            !collapsed && (
              <View style={{ padding: 15 }}>
                <Widget />
              </View>
            )
          }
        </View>
      ) : <Widget />
    }
  </Pressable>
}