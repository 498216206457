import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { InstanceInternal as HaborInstanceInternal } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, FlatList, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon, Slider } from 'react-native-elements';
import { FlatGrid } from 'react-native-super-grid';
import { ENoun } from '../../enoun-service';
import { HexColorDot, HexColorField } from '../../packages/davel-ui/habor-types/color/color-field';
import { CircleFilled } from '../../packages/kelp-bar/circles';
import { Page } from '../../packages/kelp-bar/page';
import { PageLoader } from '../../packages/kelp-bar/page-loader';
import { HaborNounService, NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { Paragraph } from '../gallery/constants';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { EntityIdentityPlugin, IdentityInfo } from './entity-identity-plugin';
import { Entity, EntityPlugin, useEntityPlugin } from "./entity-plugin";
import { HessiaPlugin } from './hessia-plugin';
import { haborSDK } from './hessia-plugin/config';
import { SystemPlugin } from './system-plugin/system-plugin';

//  TODO:  Eventually make this WITHOUT the primitive system!
//  CONSIDER:  Merge this with Surveys / other systems hmm...
//  CONSIDER:  When we make a model it CAN be used as a template!  The idea is.. then we can have a "template selection" hmm...

//
//  React Context
//

export const MoodPluginContext = React.createContext<MoodPlugin>({} as MoodPlugin);

export const useMoodPlugin = () => {
  const moodPluginContext = React.useContext(MoodPluginContext);
  return moodPluginContext;
}

//
//  Data Model
//

export interface Mood {
  title: string;
  description?: string;
  color: string;  //  NOTE:  Hex color
}

export interface MoodEntry {
  moodId: string;
  moodIntensity: number;  //  NOTE:  Intensity is a number from -10 : +10
}

export interface MoodPanel {
  title?: string;
  description?: string;
}

//  TODO:  SHOULD be able to use the freaking MOOD Model for this!!!  Then, perhaps we can even include links for the model and stuff... like a quick way to build a system from those pieces hm!

export const moodService = new HaborNounService<Mood>("mood-plugin-mood", haborSDK);

export const moodEntryService = new HaborNounService<MoodEntry>("mood-plugin-mood-entry", haborSDK);

export const moodPanelService = new HaborNounService<MoodPanel>("mood-plugin-mood-panel", haborSDK);


//
//  Mood Widgets
//

export const MoodWidget = ({ moodInternal, moodPartial, moodId, onCreated, onUpdated, onChange, onPress }: { moodInternal?: NounServiceInstanceInternal<Mood>, moodPartial?: Partial<Mood>, moodId?: string, onCreated?: (moodInternal: NounServiceInstanceInternal<Mood>) => void, onUpdated?: (moodInternal: NounServiceInstanceInternal<Mood>) => void, onChange?: (moodInternal: NounServiceInstanceInternal<Mood>,) => void, onPress?: (moodInternal: NounServiceInstanceInternal<Mood>) => void }) => {

  const moodPlugin = useMoodPlugin();

  const _moodInternal = moodPlugin?.moodService?.retrieveHook(moodId);

  moodInternal = moodInternal || _moodInternal;

  // TODO:  Should be able to generate SOME representation based on the model!  Keep it SIMPLE.. MAYBE use a neral net and freaking SEMANTIC association to help with that hm!
  return (
    <TouchableOpacity style={{ borderRadius: 7, padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: "#eeeeee" }} onPress={() => moodInternal && onPress && onPress(moodInternal)}>
      <HexColorDot size={20} color={moodInternal?.payload.color} />
      <View style={{ width: 10 }} />
      <Paragraph style={{ color: "#555555" }}>{moodInternal?.payload.title}</Paragraph>
    </TouchableOpacity>
  );
}

//  CONSIDER:  We CAN just have a random thing with like "Mood" and then an intensity or even some other strucutre!!!  That's 100% fine!!!  The QUESTION is, how do we "program" with it, given we MAY not be consistent and stuff hm!  This way, we have our OWN symbol set for these ontological pieces mmm!!!  EVEN "separate systems" are JUST accessible via some symbol / name in some context hmmm... 

//  CONSIDER:  DO have types.  DO have contexts (CSS-like system).  The point is... we want to be able to use ALL of this.  Already had types in TM that we applied to Trackers and ... could have abstractions for those hmm... could also yeah... use the baic and then have stuff for the "Tracker".  EIther way... Corey didn't have that generalizetion hm

export const MoodEntryWidget = ({ moodEntryInternal, moodEntryPartial, onCreated, onUpdated, onChange, onPress }: { moodEntryInternal?: NounServiceInstanceInternal<MoodEntry>, moodEntryPartial?: Partial<MoodEntry>, onCreated?: (moodInternal: NounServiceInstanceInternal<Mood>) => void, onUpdated?: (moodInternal: NounServiceInstanceInternal<Mood>) => void, onChange?: (moodInternal: NounServiceInstanceInternal<MoodEntry>) => void, onPress: (moodEntry: NounServiceInstanceInternal<MoodEntry>) => void }) => {

  return (
    <TouchableOpacity style={{ borderRadius: 7, display: 'flex', flexDirection: 'column', marginVertical: 15 }} onPress={() => moodEntryInternal && onPress(moodEntryInternal)}>
      <Paragraph>{moodEntryInternal?.created}</Paragraph>
      <Paragraph>{moodEntryInternal?.updated}</Paragraph>
      <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <MoodWidget moodId={moodEntryInternal?.payload.moodId} />
        <View style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: '#eeeeee', alignItems: 'center', justifyContent: 'center' }}>
          <Paragraph>{moodEntryInternal?.payload.moodIntensity.toFixed(1)}</Paragraph>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export const MoodEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const [moodNoun, setMoodNoun] = React.useState<ENoun<Mood>>(route?.params?.mood);
  const [title, setTitle] = React.useState<string>(moodNoun?.payload.title || "");
  const [color, setColor] = React.useState<string>(moodNoun?.payload.color || "#eeeeee");


  return (
    <ScrollView style={{ marginTop: 100 }}>

      <Text>Title</Text>
      <TextInput value={title} onChangeText={setTitle} />

      <Text>Color</Text>
      <HexColorField color={color} setColor={_color => setColor(_color)} />

      <Button title={moodNoun ? 'Update' : 'Create'} onPress={moodNoun ? async () => { await moodService.update(moodNoun?.id, { title, color }); } : async () => { await moodService.create({ title, color }); }} />
      <Button title="Delete" onPress={() => moodService.delete(moodNoun.id)} />
    </ScrollView>
  );
}

const MoodList = () => {

  const navigation = useNavigation();
  const moodPlugin = useMoodPlugin();
  const entityPlugin = useEntityPlugin();

  const [moods, setMoods] = React.useState<NounServiceInstanceInternal<Mood>[]>([])

  const onMount = async () => {
    const moods = await moodService.retrieveAll();
    setMoods(moods);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  //  CONSIDER:  Abstract "parser" - takes in ANY encoding and processes it.  Then.. we can have an INFINITE number of sub-parsers hmm.. but don't want it to be feed-forward but recurrant hmm... Also iterative? Hmm...

  return (

    <PageLoader loading={false}>
      <Page style={{ paddingHorizontal: 20, backgroundColor: 'white' }}>
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Moods</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
            <FlatGrid
              data={moods}
              renderItem={({ item: mood }) => <MoodWidget moodInternal={mood} onPress={(mood) => navigation.navigate('Editor' as never, { mood } as never)} />}
            />
          </ScrollView>
        </View>

      </Page>
    </PageLoader>
  )
}

//  CONSIDER:  Generic Renderer - Render based on ANYTHING... an encoding, a statement, etc...  Essentially a PROJECTION hm!
//  CONSIDER:  A simplified renderer that works on JSON.  It takes in Davel Types and we use those to render.  We CAN even have a "Relationship" BUT... instead of coupling with Habor, we can include a resolver for the objects, which WILL perhaps have IDs. hmm... Then we can just use that ID hmm... PERHAPS the ID can be ANY string (also JSON)

//  CONSIDER:  When we make a service, auto-create HOOKS!  I think that would REALLY help speed up development!  It makes it easy to access these objects from within a React Component, which cannot just make an async call without a hook.

export const getMoods = () => {

  const moodPlugin = useMoodPlugin();

  const [moods, setMoods] = React.useState<NounServiceInstanceInternal<Mood>[]>([]);

  const loadMoods = async () => {
    const _moods = await moodPlugin.moodService.retrieveAll();
    setMoods(_moods);
  }

  React.useEffect(() => {
    loadMoods();
  }, [])

  return moods;
}


export const MoodEntryEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const moodPlugin = useMoodPlugin();

  const [moodEntry, setMoodEntry] = React.useState<ENoun<MoodEntry>>(route?.params?.moodEntry);

  const [moodId, setMoodId] = React.useState<string>(moodEntry?.payload.moodId || "");

  const [mood, setMood] = React.useState<NounServiceInstanceInternal<Mood>>();

  const [moodIntensity, setMoodIntensity] = React.useState<number>(moodEntry?.payload?.moodIntensity || 0);

  const moods = getMoods();

  const getMood = async () => {
    const _mood = await moodPlugin.moodService.retrieve(moodId);
    setMood(_mood);
  };

  React.useEffect(() => {
    getMood();
  }, [moodId]);

  return (
    <View style={{ backgroundColor: 'white', paddingHorizontal: 20 }}>

      <Text>Select a Mood</Text>
      <FlatGrid data={moods} renderItem={({ item }) => <MoodWidget moodInternal={item} onPress={_mood => setMoodId(_mood.id)} />} />

      {
        mood && (
          <>
            <Text>Mood</Text>
            <MoodWidget moodInternal={mood} />
          </>
        )
      }

      <Text>Intensity</Text>
      <Slider minimumValue={0} maximumValue={10} value={moodIntensity} onValueChange={value => setMoodIntensity(value)} />
      <Text>{moodIntensity.toFixed(1)}</Text>

      <Button title={moodEntry ? 'Update' : 'Create'} onPress={moodEntry ? async () => { await moodPlugin.moodEntryService.update(moodEntry?.id, { moodId, moodIntensity }); } : async () => { alert(JSON.stringify({ moodId, moodIntensity })); await moodPlugin.moodEntryService.create({ moodId, moodIntensity }); }} />
      <Button title="Delete" onPress={() => moodService.delete(moodEntry.id)} />
    </View>
  );
}

const MoodEntryList = () => {

  const navigation = useNavigation();
  const moodPlugin = useMoodPlugin();
  const entityPlugin = useEntityPlugin();

  const [moodEntries, setMoodEntries] = React.useState<NounServiceInstanceInternal<MoodEntry>[]>([])
  const [selectedMoodEntru, setSelectedMoodEntry] = React.useState<NounServiceInstanceInternal<MoodEntry> | undefined>(undefined);

  const onMount = async () => {
    const moodEntries = await moodEntryService.retrieveAll();
    setMoodEntries(moodEntries);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  return (

    <PageLoader loading={false}>
      <Page style={{ paddingHorizontal: 20, backgroundColor: 'white' }}>

        {/* Mood Entries */}
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Entries</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
            <FlatList
              data={moodEntries}
              renderItem={({ item: moodEntry }) => <MoodEntryWidget onPress={(moodEntry) => navigation.navigate('Editor' as never, { moodEntry } as never)} moodEntryInternal={moodEntry} />}
            />
          </ScrollView>
        </View>


      </Page>
    </PageLoader>
  )
}


export const MoodPanelEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const [moodNoun, setMoodNoun] = React.useState<ENoun<Mood>>(route?.params?.mood);
  const [title, setTitle] = React.useState<string>(moodNoun?.payload.title || "");


  return (
    <View style={{ marginTop: 100 }}>

      <Text>Title</Text>
      <TextInput value={title} onChangeText={setTitle} />

      <Button title={moodNoun ? 'Update' : 'Create'} onPress={moodNoun ? async () => { await moodService.update(moodNoun?.id, { title }); } : async () => { await moodService.create({ title }); }} />
      <Button title="Delete" onPress={() => moodService.delete(moodNoun.id)} />
    </View>
  );
}

const MoodPanelList = () => {

  const navigation = useNavigation();
  const moodPlugin = useMoodPlugin();
  const entityPlugin = useEntityPlugin();

  const [moods, setMoods] = React.useState<NounServiceInstanceInternal<Mood>[]>([])
  const [selectedMood, setSelectedMood] = React.useState<NounServiceInstanceInternal<Mood> | undefined>(undefined);

  const onMount = async () => {
    const moods = await moodService.retrieveAll();
    setMoods(moods);
  }

  React.useEffect(() => {
    onMount();
  }, [])


  return (

    <PageLoader loading={false}>
      <Page style={{ marginHorizontal: 20 }}>

        {/* Moods */}
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Moods</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
            <FlatGrid
              data={moods}
              renderItem={({ item: mood }) => {
                const isSelected = mood.id === selectedMood?.id;
                const backgroundColor = isSelected ? '#fafafa' : 'white';
                return (
                  <TouchableOpacity style={{ borderRadius: 7, backgroundColor, paddingVertical: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => setSelectedMood(mood)}>
                    <MoodWidget moodInternal={mood} />
                  </TouchableOpacity>
                );

              }}
            />
          </ScrollView>
        </View>

        {/* Mood Entries */}
        <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
          <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Mood Entries</Text>
          <TouchableOpacity onPress={() => { navigation.navigate('Editor' as never) }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
          </ScrollView>
        </View>


      </Page>
    </PageLoader>
  )
}

const Tabs = createBottomTabNavigator();

export const MoodPage = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name="Editor" component={MoodEditor} />
      <Stack.Screen name="List" component={() => <MoodList />} />
    </Stack.Navigator>
  );
}

export const MoodEntryPage = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name="Editor" component={MoodEntryEditor} />
      <Stack.Screen name="List" component={() => <MoodEntryList />} />
    </Stack.Navigator>
  );
}


export const MoodPanelPage = () => {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name="Editor" component={MoodPanelEditor} />
      <Stack.Screen name="List" component={() => <MoodPanelList />} />
    </Stack.Navigator>
  );
}

export const MoodTabs = ({ moodPlugin, entityPlugin }: { moodPlugin: MoodPlugin, entityPlugin: EntityPlugin }) => {


  return (
    <Tabs.Navigator screenOptions={{ tabBarStyle: { height: 70 }, headerShown: false }}>
      <Tabs.Screen options={{ tabBarIcon: () => <Icon  name="moon" type="entypo" /> }} name='Moods' component={MoodPage} />
      <Tabs.Screen options={{ tabBarIcon: () => <Icon  name="form" type="ant-design" /> }} name='Entries' component={MoodEntryPage} />
      <Tabs.Screen options={{ tabBarIcon: () => <Icon  name="format-list-bulleted" type="material" /> }} name='Panels' component={MoodPage} />
    </Tabs.Navigator>
  );
}

//  MOOD:  Simulates a relationship linking a String and an Entity TAGGED as a "Mood".. hmmm

//  TODO:  Inject into the SCRIPTING system! HM!


export class MoodPlugin extends CorePluginClass {

  public moodService = moodService;
  public moodEntryService = moodEntryService;
  public moodPanelService = moodPanelService;

  public serviceName = "mood";

  public static details = {
    name: "Moods Plugin",
    description: "Moods System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id, EntityIdentityPlugin.details.id, HessiaPlugin.details.id],
    id: "moods"
  }

  public install = async (program: Program, { system, pEntities, authPlugin, entityIdentityPlugin, hessia }: { entityIdentityPlugin: EntityIdentityPlugin, system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin, hessia: HessiaPlugin }) => {

    await moodService.init(authPlugin.token);
    await moodEntryService.init(authPlugin.token);
    await moodPanelService.init(authPlugin.token);

    hessia.registerContext(MoodPluginContext, this);

    entityIdentityPlugin.registerIdentityProvider({
      id: "mood-entity-identity-provider",
      name: "Mood Entity Identity Provider",
      description: "Provides 'mood' Entity Identity",
      systemId: this.serviceName,
      provide: async (entity: Entity, entityPlugin?: EntityPlugin): Promise<IdentityInfo> => {

        if (!entityPlugin) { return undefined as any; }

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Mood
        const mood = await this.moodService.retrieve(entity.route.id);

        return {
          type: "mood",
          icon: { name: "moon", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#f5aa20",
          name: "Mood",
          description: "A Mood",
          component: () => {

            return <MoodWidget moodInternal={mood} />

          }
        } as IdentityInfo;

      }
    });

    //  Register Entity Handler
    moodService.emitter.addListener("create", async (moodInternal: HaborInstanceInternal<Mood>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: moodInternal.id, created: moodInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.createEntity(entity);
      alert("Created Entity: " + JSON.stringify(entityInternal));
    })

    moodService.emitter.addListener("delete", async (moodInternal: HaborInstanceInternal<Mood>) => {
      const entity: Entity = { systemId: this.serviceName, route: { id: moodInternal.id, created: moodInternal.created }, metadata: "{ test: 'Test Metadata' }" };
      const entityInternal = await pEntities.deleteEntity(entity);
      alert("Deleted Entity: " + JSON.stringify(entityInternal));
    })

    system.registerPrimitiveSystem({
      id: 'moods-system',
      name: "Mood",
      description: "Moods Primitive System",
      labels: ["hessia"],
      component: () => <MoodTabs entityPlugin={pEntities} moodPlugin={this} />,
      menuItem: {
        icon: { name: "moon", type: "entypo" },
        backgroundColor: "#46038f",
        iconColor: "white"
      }
    });

    return this;
  }
}
