
import { CorePluginClass, Program } from "halia";
import { SetPlugin } from "../set-plugin/set-plugin";
import { StringPlugin } from "../string-plugin";

export class SetSelectorPlugin extends CorePluginClass {
  public static details = {
    name: "Set Selector",
    description: "Set Selector Injectio",
    dependencies: [SetPlugin.details.id, StringPlugin.details.id],
    id: "setSelector"
  }

  public install = (program: Program, { stringPlugin, setPlugin }: { stringPlugin: StringPlugin, setPlugin: SetPlugin }) => {
  };
}
