import { Image, Text, TextStyle, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';
import { S3Image } from 'aws-amplify-react-native';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import * as React from 'react';
import { Button } from 'react-native';
const uuidv4 = require('uuid/v4');

const enableRollPermissions = async () => {
  const permission = await Permissions.getAsync(Permissions.CAMERA_ROLL);
  if (permission.status !== 'granted') {
    const newPermission = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (newPermission.status === 'granted') {
      //  Permission Granted
    }
  }
}

const enableCameraPermissions = async () => {
  const permission = await Permissions.getAsync(Permissions.CAMERA);
  if (permission.status !== 'granted') {
    const newPermission = await Permissions.askAsync(Permissions.CAMERA);
    if (newPermission.status === 'granted') {
      //  Permission Granted
    }
  }
}

  //  REFERENCE:  https://instamobile.io/react-native-tutorials/react-native-aws-s3/
  const fetchResourceFromURI = async uri => {
    const response = await fetch(uri);
    console.log(response);
    const blob = await response.blob();
    return blob;
  };

//  REFERENCE:  https://stackabuse.com/uploading-files-to-aws-s3-with-node-js/
const createImage = async (imageUri: string): Promise<void> => {

  const blob = await fetchResourceFromURI(imageUri);

  const id = uuidv4();

  // const result = await Amplify.Storage.put(id, blob, {
  //   level: 'private',
  //   contentType: 'image/jpg'
  // });

  alert(id);
  return;
  // await imagePlugin.imageService.create({ uri: id });
};


const imgPickerOptions: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.All,
  base64: false,
  allowsEditing: true,
  quality: 1,
}

const pickImage = async (): Promise<string | undefined> => {
  let result = await ImagePicker.launchImageLibraryAsync(imgPickerOptions);
  if (!result.canceled) {
    const uri = result.assets[0].uri;
    alert(JSON.stringify(uri));
    return uri;
  } else {
    alert("Canceled!")
    return undefined;
  }
};

const takeImage = async (): Promise<string | undefined> => {
  let result = await ImagePicker.launchCameraAsync(imgPickerOptions);
  if (!result.canceled) {
    const uri = result.assets[0].uri;
    alert(JSON.stringify(uri));
    return uri;
  } else {
    return undefined;
  }
};


export const ImageFieldStyle = [
  {
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    color: '#555555',
    minHeight: 37,
    borderBottomColor: '#555555',
    borderBottomWidth: 1
  },
];

export const ImageField = ({ style, value: imageUri, update }: { style: TextStyle, value: string, update: (value: string) => void }) => {

 
  React.useEffect(() => {
    enableCameraPermissions();
    enableRollPermissions();
  }, []);

  const _pickImage = async () => {
    const _imageUrl = await pickImage();

    if (_imageUrl) {
      update(_imageUrl);
    } else {
      alert("No image was selected.");
    }
  };

  const _takeImage = async () => {
    const _imageUrl = await takeImage();
    if (_imageUrl) {
      update(_imageUrl);
    } else {
      alert("No image was selected.");
    }
  };

  return (
    <View style={{ margin: 30, flex: 1 }}>
      <Button title="Pick Image" onPress={_pickImage} />
      <Button title="Take Image" onPress={_takeImage} />
      {
        imageUri ?
          <Image source={{ uri: imageUri }} style={{ height: 500, width: 500 }} />
          : <Text>No Image Yet</Text>
      }

      {/* <Button disabled={!imageUri} title='Create' onPress={() => createImage(imageUri)} /> */}
      {/* <Button disabled={!imageObj} title="Delete" onPress={() => imagePlugin.imageService.delete(imageObj.id)} /> */}
    </View>
  );
  
};

export const sdtImageRenderer = ({ sdt, key, value, update, name }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={ name }>
    <ImageField style={ImageFieldStyle as TextStyle} update={update} value={value} />
  </DavelField>
);
