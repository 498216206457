import React from 'react';
import { Pressable, Text, TextInput, TextProps, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { GalleryButton } from './components/common';
import { TextField } from './components/fields';
import { useSizes } from './sizes-helper';
import awsexports from '../../aws-exports';

export const niceShadow = {
  shadowColor: '#13232b',
  shadowRadius: 25,
  shadowOpacity: 0.1,
  // height: 400,
  shadowOffset: {
    width: 2,
    height: 2
  },
};

export const basColors = {
  background: "#FAFAFA"
};

export const defaultShadow = { shadowColor: 'black', shadowOpacity: 0.25, shadowOffset: { width: 0, height: 0 }, shadowRadius: 2 };

//  NOTE:  We can ALWAYS differentiate further!  The point is... instead of coupling with a new thing, we can OVERRIDE for a particular component? hmm... I DO like that hmm... the idea is, the NODES DO have the common interface, SO ... why not!  SAME with props hm!!
//         Meaning... we can override completely, or we can wrap, etc hm!


//  CONSIDER:  We CAN make this work with THEME with things like "Dark Mode" etc hmm.... is this the "right" abstraction for that though? Hmm...


export interface SuperHeaderProps extends TextProps {
  editable?: boolean;
  onChange?: (value: string) => void;
}

//  TODO:  WRAP this so, we can pass the component we're going to use in!

export const SuperHeader = (props: SuperHeaderProps) => {
  const { isDesktop, isTablet } = useSizes();
  const size = isDesktop ? 40 : isTablet ? 35 : 30;

  //  TODO:  SHOULD be able to enable EDIT MODE WITHOUT breaking the original component!  Essentially it should be a "plugin" where we can REFERENCE the existing pieces of the component and add mm!!!  NEED to be able to program in a graph like that damnit!
  const [editMode, setEditMode] = React.useState(false);
  const [newValue, setNewValue] = React.useState<string>((props.children as string));

  return <>
    {
      editMode ?
        <>
          <TextInput onChangeText={(value) => setNewValue(value)} value={newValue} style={[{ lineHeight: size, fontFamily: primaryFontFamilyHeavy, fontSize: size, color: "#555555", letterSpacing: -1, maxWidth: 400 }, props.style || {}]} />
          <View style={{ flexDirection: 'row' }}>
            <GalleryButton title='Save' onPress={() => { setEditMode(false); props.onChange ? props.onChange(newValue) : null; }} />
            <View style={{ width: 7 }} />
            <GalleryButton title='Cancel' onPress={() => { setEditMode(false); setNewValue((props.children as string)) }} />
          </View>

        </> :
        <View>
          <Text {...props} style={[{ lineHeight: size, fontFamily: primaryFontFamilyHeavy, fontSize: size, color: "#555555", letterSpacing: -1 }, props.style || {}]}>{props.children}</Text>
          {
            props.editable && (
              <Pressable onPress={() => setEditMode(true)} style={{ backgroundColor: '#eeeeee', height: 30, width: 30, borderRadius: 15, position: 'absolute', left: '103%', top: '60%', alignItems: 'center', justifyContent: 'center' }}>
                <Icon  name="edit" type="material" size={17} color="#666666" />
              </Pressable>
            )
          }
        </View>

    }


  </>




}


export const Header = (props: TextProps) => {
  return (
    <Text {...props} style={[{ lineHeight: 31, fontFamily: primaryFontFamilyHeavy, fontSize: 31, color: "#777777", letterSpacing: -0.5 }, props.style || {}]}>{props.children}</Text>
  );
}

export const SubHeader = (props: TextProps) => {
  return (
    <Text {...props} style={[{ lineHeight: 20, fontFamily: primaryFontFamilyHeavy, fontSize: 18, color: "#555555" }, props.style || {}]}>{props.children}</Text>
  );
}

export const Paragraph = (props: TextProps) => {
  const { isDesktop, isTablet } = useSizes();
  const size = 16;
  return (
    <Text {...props} style={[{ lineHeight: size, fontFamily: primaryFontFamily, fontSize: size, color: "#555555", letterSpacing: 0 }, props.style || {}]}>{props.children}</Text>
  );
}

export const SubParagraph = (props: TextProps) => {
  const { isDesktop, isTablet } = useSizes();
  const size = 14;
  return (
    <Text {...props} style={[{ lineHeight: size, fontFamily: primaryFontFamilyHeavy, fontSize: size, color: "#999999" }, props.style || {}]}>{props.children}</Text>
  );
}

export const defaultBorderRadius = 6;

export const medShadow = { shadowColor: 'black', shadowOffset: { height: 3, width: 3 }, shadowRadius: 5, shadowOpacity: 0.1 };

//f53183
export const primaryColor = '#f53183';
export const secondaryColor = '#fafafa';

export const maxWidth = 1500;
export const desktopBreakpoint = 1500;
export const desktopGridWidth = 1500;

export const tabletGridWidth = 700;
export const tabletBreakpoint = 700;

//  TODO:  Set these with the CSS-like context system.  THIS way we can always add NEW ones without explicit coupling? Hmmm...
export const desktopMarginSize = 45;
export const tabletMarginSize = 40;
export const mobileMarginSize = 35;

export const gridsize = 12;

export const environment = process.env.NODE_ENV;

//  CONSIDER:  I can get it from the server... but that's got startup costs.  I could also just set it here hmm...  Ah!  Do it in BABEL!  WHen I buid for production hm!

export const basSecure = environment === 'production' ? true : false;

export const basClientDomain = environment === 'production' ? "www.hessia.io" : "localhost";
export const basClientHost = environment === 'production' ? `https://${basClientDomain}` : `http://${basClientDomain}:19006`;

export const basApiDomain = environment === 'production' ? "api.hessia.io" : "localhost"; //  Applebees - "10.239.134.250";
export const basApiHost = environment === 'production' ? `https://${basApiDomain}` : `http://${basApiDomain}:3000`;

//  Override the AWS Exports
awsexports.oauth.redirectSignIn = basClientHost + "/";
awsexports.oauth.redirectSignOut = basClientHost + "/";

export const awsConfig = awsexports;
