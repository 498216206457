import React from "react";
import { NamedObject } from "./utils";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { Text } from "react-native";
import { PluginStore } from "../halia-studio/halia-studio-plugin/plugin-list";
import { Settings } from "./Settings";

interface View extends NamedObject {
  name: string;
  component: any;
  system?: string;  //  NOTE:  EVERY thing that gets registered in Hessia comes from a system.  The trick is... it can come from multiple IF something is created by system X BECAUSE of creation of Y in system Z... so Z is "really" the root of the creation.  That requires a BIT more representation.  FOR NOW, we'll just keep it simple with a system reference.  MAY want to track which system LITERALLY called "register" hmmm... and track any callers it had to track invocation chain.
}

interface ViewSelection {
  name: string;
  params?: any;
};

export interface NavContext {
  selectedView: ViewSelection;
  setSelectedView: (view: ViewSelection) => void;
  registerView: (view: View) => void;
  views: View[];
  showPanel: boolean;
  setShowPanel: (showPanel: boolean) => void;
}

const DefaultViewSelection: ViewSelection = { name: "home", params: {} };
export const NavContext = React.createContext<NavContext>({ showPanel: false, setShowPanel: () => null, registerView: (view: View) => null, views: [], selectedView: DefaultViewSelection, setSelectedView: () => null });

const HessiaPluginStore = () => {
  return <PluginStore options={{ basePluginId: "hessia2", immediateOnly: true }} />
}

export const NavProvider = ({ children }) => {

  const { isDesktop } = useSizes();

  const [selectedView, _setSelectedView] = React.useState<ViewSelection>(DefaultViewSelection);
  const [showPanel, setShowPanel] = React.useState(isDesktop ? true : false);

  const setSelectedView = (view: ViewSelection) => {
    _setSelectedView(view);
    if (!isDesktop) {
      setShowPanel(false);
    }
  }
  const [views, setViews] = React.useState<View[]>([
    {
      name: "home",
      component: () => <Text>Home</Text>
    },
    {
      name: "extensions",
      component: HessiaPluginStore
    },
    {
      name: "settings",
      component: Settings
    },
    {
      name: "create",
      component: () => <Text>Create</Text>
    }
  ]);

  const registerView = (view: View) => {
    setViews((views) => {
      if (!views.find(_view => _view.name === view.name)) {
        return [...views, view];
      } else {
        return views;
      }
    });
  };

  return (
    <NavContext.Provider value={{ showPanel, setShowPanel, selectedView, setSelectedView, registerView, views }}>
      {children}
    </NavContext.Provider>
  );
}