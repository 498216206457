import { Color } from 'habor-sdk';
import * as React from 'react';
import { Text, TextProps } from 'react-native';

//  TODO:  Abstract This!
import { primaryFontFamily, primaryFontFamilyHeavy } from "./styles";

// interface TextProps extends React.Props<any> {
//   [key: string]: any;
// }

export const TextH1 = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamily, fontSize: 32, color: Color.darkGray }, props.style ]}></Text>
);

export const TextH2 = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 35, color: Color.black }, props.style ]}></Text>
);

export const TextH3 = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 25, color: Color.darkGray }, props.style ]}></Text>
);

export const TextH4 = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 20, color: Color.darkGray }, props.style ]}></Text>
);

export const TextSubTitle = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 20, color: "#999999", letterSpacing: -0.5 }, props.style ]}></Text>
);

export const TextParagraph = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamily, fontSize: 14, color: Color.black }, props.style ]}></Text>
);

export const TextSubParagraph = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 14, color: Color.medGray }, props.style ]}></Text>
);

export const TextMini = (props: TextProps) => (
  <Text { ...props } style={[ { fontFamily: primaryFontFamilyHeavy, fontSize: 12, color: Color.medGray }, props.style ]}></Text>
);
