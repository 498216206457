
import { S3Category } from 'aws-amplify-react-native';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Text } from "react-native";
import { EntityService } from '../../../noun-entity-service';
import { NounService } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { EntityIdentityPlugin, IdentityInfo } from "../entity-identity-plugin";
import { Entity, EntityPlugin } from "../entity-plugin";
import { WidgetItem } from '../hessia-plugin/dynamic-component';
import { SimpleBox } from '../page-plugin/page-widget';
import { CategoryPlugin } from "./category-plugin";


export const CategoryPluginContext = React.createContext<CategoryPlugin | undefined>(undefined);

export const useCategoryPlugin = () => {
  return React.useContext(CategoryPluginContext);
}

export class CategoryEntityPlugin extends CorePluginClass {

  //  NOTE:  We may have MULTIPLE plugins with the SAME service name!
  public serviceName = "category";

  //  TODO:  HOW will we get the Category Plugin and Resource Plugin into THIS context when like... it enables it for the OUTER context too????? Hmmm... that's interesting.  Fuck?  We DO want to keep thinking through these things!!  Keep pushing that's what going to help ugh!  FOR NOW, maybe we can just bridge. hmm... But like... the RESOURCE system needs to know about the CATEGORY system hmm...  SO... Maybe these plugins ARE enabled GLOBALLY hmmm... Or like CONTEXTUALLY so it's not JUST for the app hmmmmmmm... THe idea then is we show all GLOBAL plugins which DEPEND on category and we can enable them hmmm... Maybe show which ones have INTEGRATIONS and shit? MAYBE hm!

  public static details = {
    name: "Category Entity Plugin",
    description: "Activates the 'Entity' Feature for Categories",
    dependencies: [EntityPlugin.details.id, CategoryPlugin.details.id, EntityIdentityPlugin.details.id, AuthPlugin.details.id],
    id: "categoryEntityPlugin"
  }


  public install = async (program: Program, { pEntities, categoryPlugin, entityIdentityPlugin, authPlugin }: { pEntities: EntityPlugin, categoryPlugin: CategoryPlugin, entityIdentityPlugin: EntityIdentityPlugin, authPlugin: AuthPlugin }) => {


    //  TODO:  Should NOT need to couple with Auth!  Using this because the CategoryPlugin only creates the CategoryService after login.
    //         Like... maybe this should be part of a higher level abstraction?
    authPlugin.onLogin(() => {
      const categoryEntityService = new EntityService(categoryPlugin.categoryService, (noun) => ({
        //  CONSIDER:  Should each service be responsible for the ID?  Should we let the Entity service handle this?
        ...noun,
        payload: {
          systemId: this.serviceName,
          route: noun.id,
          metadata: noun.payload
        }
      }));

      const categoryService: NounService<Entity> = {
        name: "Category Entity Service",
        id: "category-entity",
        service: categoryEntityService
      };

      pEntities.entityService.registerService(categoryService);
    })
    
    pEntities.registerEntityDetailView({
      name: "Category Service",
      description: "Category Service",
      id: "category-service",
      component: ({ entity, entityPlugin }: { entity: Entity, entityPlugin: EntityPlugin }) => {

        if (entity.systemId !== this.serviceName) { return null; }

        const [uri, setUri] = React.useState<string | undefined>(undefined);

        const loadStr = async () => {
          const categoryValue = await categoryPlugin.categoryService.retrieve(entity.route.id);
          setUri(categoryValue?.payload.name);
        };

        React.useEffect(() => {
          loadStr();
        }, []);

        return (<WidgetItem name="Category" color="#eeeeee">

        {
          uri && (
            <>
              <Text>S3 Key:  {uri}</Text>
              <S3Category imgKey={uri} level="private" style={{ width: 100, height: 100 }} />
            </> )
        }
        </WidgetItem>);

      }
    })

    entityIdentityPlugin.registerIdentityProvider({
      id: "category-entity-identity-provider",
      name: "Category Entity Identity Provider",
      description: "Provides 'category' Entity Identity",
      systemId: "category",
      provide: async (entity: Entity): Promise<IdentityInfo> => {

        //  Guard
        if (entity.systemId !== this.serviceName) { return undefined as any; }

        //  Get the Category
        const category = await categoryPlugin.categoryService.retrieve(entity.route.id);

        //  TODO:  Merge this with a way to make the thing ANY SIZEE!!!  Size should NOT matter lol!!!  CONTEXT does hm!
        return {
          type: "category",
          icon: { name: "category", type: "material" },
          iconColor: "white",
          iconBackgroundColor: "#f25949",
          name: "Category",
          description: "An Category",
          value: category?.payload.name
        } as IdentityInfo;

      }
    });

    // resources.registerProvider({
    //   id: "category-resources",
    //   name: "Category Resources",
    //   provide: async (): Promise<Resource[]> => {
    //     const categorys = await category.categoryService.retrieveAll();
    //     return categorys.map(category => ({
    //       systemId: "category",
    //       route: category.id,
    //       metadata: {}
    //     }))
    //   }
    // })

    return this;
  }
}




// export class CategoryResourcePlugin extends CorePluginClass {

//   //  NOTE:  We may have MULTIPLE plugins with the SAME service name!
//   public serviceName = "category";

//   //  TODO:  HOW will we get the Category Plugin and Resource Plugin into THIS context when like... it enables it for the OUTER context too????? Hmmm... that's interesting.  Fuck?  We DO want to keep thinking through these things!!  Keep pushing that's what going to help ugh!  FOR NOW, maybe we can just bridge. hmm... But like... the RESOURCE system needs to know about the CATEGORY system hmm...  SO... Maybe these plugins ARE enabled GLOBALLY hmmm... Or like CONTEXTUALLY so it's not JUST for the app hmmmmmmm... THe idea then is we show all GLOBAL plugins which DEPEND on category and we can enable them hmmm... Maybe show which ones have INTEGRATIONS and shit? MAYBE hm!

//   public static details = {
//     name: "Category Resources",
//     description: "Category Resources",
//     dependencies: [],
//     id: "category-rousource"
//   }


//   public install = async (program: Program, { }: {  }) => {

  
//     return this;
//   }
// }
