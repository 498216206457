import { NavigationProp, useNavigation } from '@react-navigation/native';
import { InstanceInternal, NounInternal, Plugin } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { getRaisedStyle, medSpacer, primaryFontFamilyHeavy, RaisedHeight } from '../../../packages/kelp-bar/styles';
import type { AppsPlugin } from '../apps-plugin/apps-plugin';
import { useApps } from '../apps-plugin/apps-plugin-context';
import { HAppRoute } from '../apps-plugin/apps.nav.routes';
import { isNoun } from '../habor-plugin/habor-utils';
import { AppContext } from '../hessia-plugin/AppContext';
import { ContainerListSimple } from '../model-plugins/named-object-plugin/named-object-list-simple';
import { SingleElementWiget } from '../model-plugins/named-object-plugin/single-element-widget';
import { AggregatePlugin, getPluginElements } from '../system-plugin/plugin/plugin-tools';

export type HaborElement<T = any> = NounInternal | InstanceInternal<T>;

export interface ElementWidgetProps {
  navigation: NavigationProp<any>;
  system: InstanceInternal<Plugin>;
  appsPlugin: AppsPlugin;
}

export interface ElementWidgetState {
  //  TODO:  PERHAPS when the SAME data is needed by multiple pieces in a context we can AUTOMATICALLY abstract!?  It MIGHT seem reasomable to have the parent inject, BUT that creates coupling!  INSTEAD, what if we pop up and CACHE the children data somewhere so this shit always goes fast!? HMmm
  dependencies: InstanceInternal<Plugin>[];
  pluginDetails?: AggregatePlugin;
}

class ModelWidgetBase extends React.Component<ElementWidgetProps, ElementWidgetState> {
  constructor(props: ElementWidgetProps) {
    super(props);
    this.state = {
      dependencies: []
    }
  }

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>

  public componentDidMount = async () => {

    this.getElements();
  }

  public componentDidUpdate = () => {
    this.getElements();
  }

  public getElements = async () => {

    const { system } = this.props;
    const { token } = this.context.frameworkContext;
    const pluginDetails = (await getPluginElements([system], token))[system.id];
    this.setState({ pluginDetails });
  }

  public onElementPress = async (elem: HaborElement) => {
    if (isNoun(elem)) {
      this.onNounPress(elem as NounInternal);
    } else {
      this.onInstPress(elem as InstanceInternal);
    }
  }

  //  TODO:  This should show a pop up context menu!  Probably like a CARD that pops up from the bottom with information??  Make it look FRESH!!!  Make it looks DYNAMIC and BUBBLY??? User ICONS and shit to incidate the system, entity, and other stats???
  public onElementLongPress = async (elem: HaborElement) => {
    alert("Should show element menu?");
    // this.props.navigation.navigate(RootRoute.ElementMenu as any, { elem });
  }

  //  TODO:  Supporrt injections for grouping / filtering / searching!? Hmm!  MAYBE make it possible to inject coomponent level interpretations, like a Group definition BUT perhaps ALSO make it generric with FUNCTIONS that can process the items and shit, AND also abilty to TOTAALLY change the things with inejctions / yeah, I GUESS composition is a good word... but it annoys me because it's too similar to the Vue term.

  public onNounPress = (noun: NounInternal) => {
    this.props.navigation.navigate(HAppRoute.InstanceList, { noun });
  }

  public onInstPress = async (inst: InstanceInternal) => {
    this.props.appsPlugin.applyElementPressHandler(inst, this.props.navigation);
  }

  public render = () => {

    const { pluginDetails } = this.state;

    //  Handle Loading
    //  TODO:  I REALLY like the idea of supporting a "Loading" interface or something that AUTOMATICALLY shows this as loading when that condition is true, AND declaratively reporting WHEN the loading condition is true, AND keeping this OUT of the main render method ?? MAYBE we can process it IN the render method, BUT MAYBE similar to Vue composition we can have it be a function which is automaticlly invoked on render and registered with the component?? MHmmm!
    //         ALSO, thee MAY be full-screen loaders, AND component based / animated gray block scanning loaderr things!  I can ALSO export that as a LIBrARY!  AND I think it's different from Vue which ONLY seems to do it ONCE at startfup, BUT we can register things declaratively with a bunch of methods AND compose with injections and stuff???
    if (!pluginDetails) {
      return <Text>Loading Plugin Details...</Text>
    }

    return (
      <View style={[{ backgroundColor: 'white', borderRadius: 15, padding: 15, display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: "#EDEDED" }, getRaisedStyle({ raisedHeight: RaisedHeight.low })]}>

        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: 16, letterSpacing: -0.5 }}>
            Entities
          </Text>
        </View>

        <View style={{ height: medSpacer }} />

        <ContainerListSimple component={SingleElementWiget} elems={pluginDetails.nouns} onItemLongPress={this.onElementLongPress} onItemPress={this.onElementPress} />


        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: 16, letterSpacing: -0.5, marginBottom: 15 }}>
            Instances
          </Text>
        </View>

        {
          Object.keys(pluginDetails.instances).map(nounId => pluginDetails.instances[nounId].instances).map(insts => (
            // CONSIDER:  Show which NOUN its coming from / the associated SYSTEM!?
            <ContainerListSimple component={ SingleElementWiget } elems={ insts } onItemLongPress={ this.onElementLongPress } onItemPress={ this.onElementPress } />
          ))
        }

      </View>
    );
  }
}

export const ElementWidget = (props: Omit<ElementWidgetProps, "navigation" | "appsPlugin">) => {
  const navigation = useNavigation();
  const appsPlugin = useApps();
  return <ModelWidgetBase appsPlugin={appsPlugin} navigation={navigation} {...props} />
}

