/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    targetType: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    targetId: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    owner: string;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'targetType': json['targetType'],
        'targetId': json['targetId'],
        'comment': json['comment'],
        'owner': json['owner'],
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'targetType': value.targetType,
        'targetId': value.targetId,
        'comment': value.comment,
        'owner': value.owner,
    };
}

