import { FrameworkContext, HaborComponentContext, InstanceInternal, NamedObject, NounInternal } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { HaborComponentViewer } from '../../component-plugin/habor-react/habor-component-viewer';
import { primaryFontFamily } from '../../../../packages/kelp-bar/styles';
import { HaborComponentRouterHaborComponent } from '../../router-plugin/habor-component-router';
import { AppContext } from '../../hessia-plugin/AppContext';

//  TODO:  Support "Groups"!  Really, that means support the Groups Plugin by exposing a "Registration Point" that it can hook into?  We MIGHT need additional data about how the list is being rendered?  MAYBE we'd want to show them in groups, OR completely change the rendering mechanism and show them in collapsed groups and THEN eventually come back to the original rendering pattern.  EITHER WAY, it doesn't change their press actions (for now)... in the future, we MIGHT want to change press action based on the group, or at least include it in the context.


//  TODO:  The set of props taken by this component is common... that indicates that it may be ONE of a class of components with this interface.

interface ContainerListProps<T extends NamedObject> {
  // frameworkContext: FrameworkContext;
  // componentContext: HaborComponentContext;
  onItemPress?: (item: InstanceInternal<T>) => void;
  onItemLongPress?: (item: InstanceInternal<T>) => void;
  items: InstanceInternal<T>[];
  // context: HaborComponentContext;

}

interface ContainerListState<T extends NamedObject> {
  items: InstanceInternal<T>[];
  noun?: NounInternal;
  isLoading: boolean;
}

export class ContainerList<T extends NamedObject> extends React.Component<ContainerListProps<T>, ContainerListState<T>> {

  static contextType = AppContext;
  //  declare context: React.ContextType<typeof AppContext>;

  constructor(props: ContainerListProps<T>) {
    super(props);
    this.state = {
      items: [],
      noun: undefined,
      isLoading: true
    };
  }

  //  TODO:  Support CROSS ENTITY filtering!  This is important for GENERAL Queries
  //  NOTE:  From now on we want to try to keep the QUERY step separate from the RENDERING step for these GENERIC renderers.  For custom renderers, it's not as big an issue.
  // public componentDidMount = async () => {

  //   //  Unpack Props
  //   const { token, user, onItemLongPress, onItemPress } = this.props;

  //   //  Retrieve the Noun
  //   const noun = await haborSDK.retrieveNoun(nounId, token);

  //   //  Retrieve Instances
  //   const items: InstanceInternal<T>[] = await haborSDK.searchInstances(token, { nounId });

  //   //  TODO:  Get style info

  //   //  Update State
  //   this.setState({ noun, items, isLoading: false });
  // }

  public render = () => {

    //  Unpack
    const { onItemLongPress, onItemPress, items = [] } = this.props;
    const { frameworkContext, componentContext } = this.context;

    //  Update Types
    //  REFERENCE:  https://github.com/Microsoft/TypeScript/issues/3960#issuecomment-123456256
    //  TODO:  Remove cast to any.
    //  NOTE:  I HAD done:  `class InstanceListItemT extends InstanceList` BUT, someone made a good point that's inneficient:  https://github.com/Microsoft/TypeScript/issues/3960#issuecomment-226005513
    //  QUESTION:  Should the context be stored in Habor??  It seems like we might want to store at least the name?  Maybe not actually, we'll be editing from the page itself I suppose.
    // const TypedInstanceListItem: new () => InstanceListItem<T> = InstanceListItem as any;

    return (
      items.length ?
        <FlatGrid
          itemDimension={ 1000 }
          data={items}
          // onPress={ onItemPress } onLongPress={ onItemLongPress } item={item}
          // renderItem={({ item }) => (<MagicComponent context={{ name: "named-object-list", parent: context }} props={{ onItemPress, onItemLongPress, item }} />)}
          renderItem={({ item }) => (<HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ HaborComponentRouterHaborComponent } handleClose={ () => null } componentProps={{ componentClassName: "named-object", componentProps: { item, onPress: () => alert("pressed"), onItemLongPress: () => alert("Long Press") } }} />)}
          style={{ padding: 0, margin: -10, overflow: 'visible' }}
        /> :
        <Text style={{ fontFamily: primaryFontFamily }}>No Items</Text>
    );
  }
}
