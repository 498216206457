/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Work
 */
export interface Work {
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    tasks: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    mockups: string;
    /**
     * 
     * @type {boolean}
     * @memberof Work
     */
    removed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Work
     */
    mature: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Work
     */
    listed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Work
     */
    notified: boolean;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    owner: string;
}

export function WorkFromJSON(json: any): Work {
    return WorkFromJSONTyped(json, false);
}

export function WorkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Work {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'updated': json['updated'],
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'url': json['url'],
        'status': json['status'],
        'tasks': json['tasks'],
        'mockups': json['mockups'],
        'removed': json['removed'],
        'mature': json['mature'],
        'listed': json['listed'],
        'notified': json['notified'],
        'owner': json['owner'],
    };
}

export function WorkToJSON(value?: Work | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'updated': value.updated,
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'url': value.url,
        'status': value.status,
        'tasks': value.tasks,
        'mockups': value.mockups,
        'removed': value.removed,
        'mature': value.mature,
        'listed': value.listed,
        'notified': value.notified,
        'owner': value.owner,
    };
}

