import { HDTRelationshipValue, InstanceInternal } from 'habor-sdk';
import * as React from 'react';
import { Switch, Text, View } from 'react-native';
import { BubbleListItem } from '../../../packages/kelp-bar/bubble-list-item';
import { haborSDK } from '../hessia-plugin/config';
import { primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { Filter, FilterComponent } from '../hessia-plugin/filter-utils';
import { WidgetItem } from '../hessia-plugin/dynamic-component';
const moment = require('moment');

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var TOMORROW = REFERENCE.clone().add(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
}
function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
}
function isTomorrow(momentDate) {
  return momentDate.isSame(TOMORROW, 'd');
}
function isWithinAWeek(momentDate) {
    return momentDate.isAfter(A_WEEK_OLD);
}
function isTwoWeeksOrMore(momentDate) {
    return !isWithinAWeek(momentDate);
}

export interface DueDateFilterSettings {
  selection: "any" | "today" | "yesterday" | "tomorrow"  //  TODO:  Obviously make this more general!
  requireDueDate: boolean;
}

//  MAYBE each filter can get dat for each and UPDATE as we process, BUT also have a thing for "finished"?  Hmm.. FOR NOW, let's do them all.
export const DueDateFilter: Filter<DueDateFilterSettings> = async (instances: InstanceInternal<any>[], token: string, settings: DueDateFilterSettings) => {

  if (!settings) { return instances; }

  // alert("Due Date Filter")
  console.log("Checking due dates with: ")
  console.log(settings);


  const allowedInsts: any[] = [];

  //  Get the Due Dates
  //  CONSIDER:  SHOULD be a way to search PERHAPS coupled with the Davel Types? hmmm!  This way we don't expose type internals? HM!  AND maybe ALSo a way to search based on ENTITY / INSTANCE! HM!  SYMBOL.  This is similar to GraphQL.. SO!  We have a Query Language for BOTH levels AND a generic DB language? HM!
  //  TODO:  When we use this it breaks the Recursive Partial, because we're assigning a VALUE to what should be a LIST.. hmm which is WHY we should be building a NEW abstraction to hide the detail here!? HM!
  //  REFERENCE:  https://stackoverflow.com/questions/30668373/moment-js-test-if-a-date-is-today-yesterday-within-a-week-or-two-weeks-ago/30674186
  //  CONSIDER:  PERHAPS generalize to DATE features in Hessia NOT just Due Date!  Hm!  MAYBE build a way to EASILY call Hessia Blocks and shit from the NATIVE code!? HM!
  //  TODO:  Clean swears and shit from the codebases before going live? HM!  MAYBE buid a platform, MAYBE HESSIA that lets a single platform work as the central place to do work/ hmm.. BUT then there are ALWAYS otherr tools.  SO, a single place to AGGREGATE and build a PERSONAL / UNIQUE / CUSTOM experience with those entities!? HM!  AND has the same funcntinality exposure as the native? HM!

  //  TODO:  We should ONLY run due-date filterrs in pages whee the filter is enabled!? HM!  PLUS, onlny for INSTANCES with it enabled/ HMM  MAYBE generralize to an "Entity".. anything which is uniquely identifiable with its own ID in Hessia? hmm!  THESE are the things that can be used in the mesh, AND it's posible to make NEW ONES!? HM!  MAYBE also support identity by reference to OTHER traits and stuff? Hm
  const dateSearchTerms = instances.map(inst => ({ match: { payload: { Target: { instanceIdList: { instanceId: inst.id } } } } }));
  const dueDateInsts = await haborSDK.searchInstances<{ Target: HDTRelationshipValue, Date: Date }>(token, { nounId: "duedate", search: { any: dateSearchTerms as any } })

  for (const inst of instances) {

    const dueDateInst = dueDateInsts.find(dueDateInst => dueDateInst.payload.Target.instanceIdList[0].instanceId === inst.id);

    if (dueDateInst === undefined) {
      if (!!settings.requireDueDate) {
        continue;
      } else {
        allowedInsts.push(inst);
        continue;
      }
    }

    const date = moment(dueDateInst?.payload.Date).startOf("day");

    //  Today Check
    if (settings.selection === "today" && !isToday(date)) {
      continue;
    } else if (settings.selection === "yesterday" && !isYesterday(date)) {
      continue;
    } else if (settings.selection === "tomorrow" && !isTomorrow(date)) {
      continue;
    }

    allowedInsts.push(inst);
  }
  
  return allowedInsts;
};

//  CONSIDER:  We COULD have default filter elements and stuff for these simple Davel pieces, like a date selection?

interface DueDateFilterComponentState {}

class DueDateFilterComponentBase extends FilterComponent<DueDateFilterComponentState> {
  constructor(props) {
    super(props);
    this.state = {}
  }

  // public componentDidMount = () => {
  //   //  CONSIDER:  Build a way to set default settings without setting on mount?  These are propagated to the filter.
  //   this.props.setSettings( { requireDueDate: false, selection: "any" });
  // }

  public render = () => {

    const settings = this.props.settings || { requireDueDate: false, selection: "any" };

    return (
      <WidgetItem name="Due Date Filter" color="#eeeeee">

        <BubbleListItem onPress={ () => this.props.setSettings({ ...settings, selection: "today" }) }>
          <View style={{ flex: 1 }}>
            <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Today</Text>
          </View>
        </BubbleListItem>
  
        <BubbleListItem onPress={ () => this.props.setSettings({ ...settings, selection: "yesterday" }) }>
          <View style={{ flex: 1 }}>
            <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Yesterday</Text>
          </View>
        </BubbleListItem>

        <BubbleListItem onPress={ () => this.props.setSettings({ ...settings, selection: "tomorrow" }) }>
          <View style={{ flex: 1 }}>
            <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Tomorrow</Text>
          </View>
        </BubbleListItem>

        {/* TODO:  Consider a generic Clear button. */}
        <BubbleListItem onPress={ () => this.props.setSettings({ ...settings, selection: "any" }) }>
          <View style={{ flex: 1 }}>
            <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>Any</Text>
          </View>
        </BubbleListItem>

        {/* TODO:  Support a custom range */}

        <Switch value={ settings.requireDueDate } onValueChange={() => {this.props.setSettings({ ...settings, requireDueDate: !settings.requireDueDate })}} />
  
      </WidgetItem>
    );
  }
}
export const DueDateFilterComponent = DueDateFilterComponentBase
