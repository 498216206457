
import { CorePluginClass, Program } from "halia";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { NumberPage } from "./number-router";

export class NumberPlugin extends CorePluginClass {
  public static details = {
    name: "Number",
    description: "Number Primitive System",
    dependencies: [SystemPlugin.details.id],
    id: "number"
  }

  public install = (program: Program, { system }: { system: SystemPlugin }) => {
    system.registerPrimitiveSystem({
      id: 'number-system',
      name: "Number",
      description: "Number Primitive System",
      labels: ["primitive"],
      component: NumberPage,
      menuItem: {
        icon: { name: "numeric", type: "material-community" },
        backgroundColor: "#30bcf0",
        iconColor: "white"
      }
    });
  }
}
