//  TODO:  Consider enabling with a SIMLAR, or the SAME API as the Alert... methods? HM!

import { BlurView } from 'expo-blur';
import { HaborIconSelection } from 'habor-sdk';
import * as React from 'react';
import { Animated, Easing, Modal, ModalProps, View, TouchableOpacity, Text, Button } from 'react-native';
import { largeSpacer, medSpacer, primaryFontFamilyHeavy } from './styles';
import { KelpIcon } from "./kelp-icon";

export interface AlertBoxProps extends ModalProps {
  onCancel?: () => void;
  title: string;
  icon: HaborIconSelection;
  description: string;
  onContinue: () => void;
}

export interface AlertBoxState {
  fadeValue: Animated.Value;
  positionValue: Animated.Value;
  visible: boolean;
}
/**
 * Has an internal "visible" state and "visible" prop.  The state will mirror the prop, unless cancelled, in which the state will be set to false and fire "onCancel" when the hide animation completes.
 */
export class AlertBox<P extends AlertBoxProps, S extends AlertBoxState> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      fadeValue: new Animated.Value(0),
      positionValue: new Animated.Value(400),
      visible: false
    }as any;
  }

  public componentDidMount = () => {
    if (this.props.visible) {
      console.log("Mounted!!");
      this.onShow();
    }
  }

  // public componentWillUnmount = () => {
  //   this.onHide()
  // }

  public componentWillReceiveProps = (nextProps: AlertBoxProps) => {
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.visible) {
        this.onShow();
      } else {
        this.onHide();
      }
    }
  }

  public onShow = () => {
    this.setState({  visible: true });
    Animated.timing(
      this.state.fadeValue,
      {
        toValue: 1,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: true
      }
    ).start();

    Animated.timing(
      this.state.positionValue,
      {
        toValue: 0,
        duration: 400,
        easing: Easing.ease,
        useNativeDriver: true
      }
    ).start();
  }

  public onHide = () => {
    Animated.timing(
      this.state.fadeValue,
      {
        toValue: 0,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: true
      }
    ).start(() => {

      this.setState({  visible: false });
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    });

    Animated.timing(
      this.state.positionValue,
      {
        toValue: 400,
        duration: 400,
        easing: Easing.ease,
        useNativeDriver: true
      }
    ).start();
  }

  public render = () => {

    const { title, description, icon, onContinue } = this.props;

    return (

    
    <Modal pointerEvents="box-none" style={[{ width: "100%", height: "100%", borderWidth:0,borderColor:'none' }]} transparent={ false } visible={ this.state.visible }>

        <Animated.View style={{ zIndex: 1, opacity: this.state.fadeValue, position: 'absolute', display: 'flex', width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <TouchableOpacity style={{ flex: 1 }} onPress={ this.onHide }>
            <BlurView>
            </BlurView>
          </TouchableOpacity>
        </Animated.View>

      {/* CONSIDER:  Blur up / down! */}
      <Animated.View pointerEvents="box-none" style={{ transform: [{ translateY: this.state.positionValue }], zIndex: 3, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <View style={[{ backgroundColor: 'white', maxHeight: 450, maxWidth: 400, width: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', padding: largeSpacer }, this.props.style]}>
            
            <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: medSpacer, borderBottomWidth: 1, borderBottomColor: '#eeeeee', alignSelf: 'stretch' }}>
              <Text style={{ flex: 1, color: "#888888", fontFamily: primaryFontFamilyHeavy, fontSize: 24, overflow: 'visible', letterSpacing: -0.5}}>{ title }</Text>
              <KelpIcon size={ 24 } color="#888888" name={ icon.name } type={ icon.type } />
            </View>

            <View style={{ marginTop: 50, marginBottom: 50, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: "#bbbbbb", textAlign: 'center', fontFamily: primaryFontFamilyHeavy, fontSize: 15, overflow: 'visible', letterSpacing: -0.5}}>{ description }</Text>
            </View>

            <View>
              <Button title="Continue" onPress={ () => { this.onHide(); onContinue(); } } />
              <Button title="Cancel" onPress={ () => this.onHide() } />
            </View>

            { this.props.children }
        </View>
      </Animated.View>
    </Modal>
    );
  }
}
