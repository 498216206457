import { BlurView } from 'expo-blur';
import * as React from 'react';
import { Animated, Easing, Modal, ModalProps, View, TouchableOpacity } from 'react-native';
import { largeSpacer, medSpacer, smallSpacer } from "./styles";
import { IconButton } from './icon-button';

export interface CardModalProps extends ModalProps {
  onCancel?: () => void;
  // onComplete?: () => void;  //  This doesn't seem necessary, seems like a leak... we are doign this fo the case the modal is copmleted without going back.  Hmmm.
}

export interface CardModalState {
  fadeValue: Animated.Value;
  positionValue: Animated.Value;
  visible: boolean;
}
/**
 * Has an internal "visible" state and "visible" prop.  The state will mirror the prop, unless cancelled, in which the state will be set to false and fire "onCancel" when the hide animation completes.
 */
export class CardModal<P extends CardModalProps, S extends CardModalState> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      fadeValue: new Animated.Value(0),
      positionValue: new Animated.Value(400),
      visible: false
    }as any;
  }

  public componentDidMount = () => {
    if (this.props.visible) {
      console.log("Mounted!!");
      this.onShow();
    }
  }

  // public componentWillUnmount = () => {
  //   this.onHide()
  // }

  public componentWillReceiveProps = (nextProps: CardModalProps) => {
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.visible) {
        this.onShow();
      } else {
        this.onHide();
      }
    }
  }

  public onShow = () => {
    this.setState({  visible: true });
    Animated.timing(
      this.state.fadeValue,
      {
        toValue: 1,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: false
      }
    ).start();

    Animated.timing(
      this.state.positionValue,
      {
        toValue: 0,
        duration: 400,
        easing: Easing.ease,
        useNativeDriver: false
      }
    ).start();
  }

  public onHide = () => {
    Animated.timing(
      this.state.fadeValue,
      {
        toValue: 0,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: false
      }
    ).start(() => {

      this.setState({  visible: false });
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    });

    Animated.timing(
      this.state.positionValue,
      {
        toValue: 400,
        duration: 400,
        easing: Easing.ease,
        useNativeDriver: false
      }
    ).start();
  }

  public render = () => {

    console.log("Renderring!!!")

    return (

    
    <Modal pointerEvents="box-none" style={[{ width: "100%", height: "100%", borderWidth: 0, borderColor:'none', display: 'flex', flexDirection: 'column' }]} transparent={ false } visible={ this.state.visible }>

      <Animated.View style={{ zIndex: 1, opacity: this.state.fadeValue, position: 'absolute', display: 'flex', width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <TouchableOpacity style={{ flex: 1 }} onPress={ this.onHide }>
          <BlurView>
          </BlurView>
        </TouchableOpacity>
      </Animated.View>

      {/* TODO:  Add horizontal margin on Desktop?
      THOUGH:  More generally, just work on making the WHOLE desktop experience more... desktop-like? Hmmm */}
      <View pointerEvents="box-none" style={{ zIndex: 3, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginVertical: 30, backgroundColor: 'white', borderRadius: 20, padding: medSpacer }}>
          { this.props.children }
      </View>

    </Modal>
    );
  }
}
