import { AllTMSDTs, HaborComponent, HaborType, HSDTType } from 'habor-sdk';
import * as React from 'react';
import { haborSDK } from '../../../../plugins/hessia-plugins/hessia-plugin/config';
import { HaborComponentViewer } from '../../../../plugins/hessia-plugins/component-plugin/habor-react/habor-component-viewer';
import { ColorSelectorFieldHaborComponent, ColorTypeHaborComponent } from '../../../../plugins/hessia-plugins/model-plugins/named-object-plugin/color-component';
import { IconSelectorFieldHaborComponent, IconTypeHaborComponent } from '../../../../plugins/hessia-plugins/model-plugins/named-object-plugin/icon-component';
import { renderView } from '../../davel-ui';
import { SDTViewRendererParams } from '../../davel-ui-tools';

//  NOTE:  We make a distinction between editor and viewer.  We MAY have more "Modes"?  OR perhaps this concept collapses to a pre-existing concept like "Class"?
//  TODO:  Move this registration to Habor.  Consider multiple TYPES of registreation.  Consider NOT even using a seprate pattern for this and JUST using the "Settings" pattern?  Remember, settings exist on a COMPONENT in a particular Context.  Maybe we can set a Class / Component for a Router in a particular context with a given custom type??

//  TODO-IMPORTANT:  We need to fix this... the USER should be able to make components that they add OR, at least configure / select from existing?
//                   This means it needs to be stored in a serizalized way int he DB.  Also, I think there will be more types than just "Edit" and "View".  It MAY make more sense to legitimately remove the original component?  In this case though, we're just updating the DAVEL view!  We're not actually making an entirely new component.  SO, for Davel "Edit" and "View" MAY be legitimate. 

//  NOTE:  Instead, I think I'd prefer to just use icon directly.  Why should we have to hmm... I think the idea is, we let the user register a UI for it, but we can do that in code for now, and we can build a plugin to let them build their own encodings for that.

export const customTypeRenderers: { [mode: string]: { [typeName: string]: HaborComponent } } = {
  edit: {
    "Color": ColorSelectorFieldHaborComponent,
    "icon": IconSelectorFieldHaborComponent,
  },
  view: {
    "Color": ColorTypeHaborComponent,
    "icon": IconTypeHaborComponent
  }
};

export const sdtCustomTypeViewer = async (params: SDTViewRendererParams) => {

  //  Type the SDT
  const customTypeSDT: HSDTType = params.sdt;

  //  Get the Token
  const { value, metadata: { token, user, frameworkContext, componentContext } } = params;

  //  Get the Custom Type Instance
  //  TODO:  FOR NOW we're using "name".  It WOULD be nice to be able to "retrieve" by name.  I BELIEVE Elatic will operate faster and have the doc available faster if we use it as the ACTUAL ID.  Again... for now, I suppose we can just search.  It MAY be good to offer a "retrieve by query" method, or pass an option to the search query so we only get ONE result back or we throw.  
  //  TODO:  ABSTRACT this "search for a particualr instance" thing to a new function which does these length checks for us!
  const { typeId } = customTypeSDT;
  const types = await haborSDK.searchInstances<HaborType>(token, { nounId: "type", search: { match: { payload: { name: typeId } } } });
  if (types.length > 1) { throw new Error(`The '${ typeId }' CustomType has been registered more than once.`) }
  if (types.length <= 0) { throw new Error(`The '${ typeId }' CustomType is not registered.`) }
  const customType = types[0];

  //  Get the Custom UI Component
  //  TODO:  Support multiple custom components, maybe through a "Priority" on the relationship?
  //  TODO / THOUGHTS:  We DON'T necessarily want to ALWAYS couple components with objects / types.  They are just fed with an object, but that doesn't NEED to map to a Habor object.
  //  TODO:  We should consider if this is the right relationship... Instead, we MAY have a different type of ComponentRegistration system... 
  //  Hmmm.... to me, it seems more like a setting of the UI element than a setting on he custom type?  Maybe??  Then, this is just a SETTING.  But, how do we know which component classes we support?  Well... hmmm... This is a particular interface calld "MainInstanceListItem".  OK, we already have a selection for that.  THEN, within that, we use another basic view called "FieldView".  NOW, there are LOTS of different field views we can register!  HOWEVER, these are PER FIELD VIEWS.  If we want to use this same instance view and have SEVERAL fields grouped, ONE way to do this is a CustomType... BUT MAYBE we can do that through the UI selection system too?  FOR NOW, let's assume it's per field.  THEN, I can make a setting on a particular field!  Ok cool.  FOR NOW, we'll support settings on CUSTOM FIELDS and eventually on ALL.  I SUGGEST, we keep things simple and support a "Component Class"?  Then, we can create components which conform to that class??  In this case, we want to render the "Color" UI...
  //  FOR NOW:  TYPES are a special thing... They can have a UI Comopnent associated for EACH UI Hierarchy (like edit / view)?  MAYBE that's just a setting in the Conext?  Hmmm... In this case it COULD be a Router which supports SEVERAL different components.  FOR NOW, we'll just attach a component with a relationship.
  //  TODO:  DO NOT attach with a standard relationship!  These are special relationships ONLY used by the primitives.  FOR NOW, let's go ahead...
  //  CONCERN:  I don't like that this is another component registration point.  Maybe we should register with an ID / options object??  In other words, a partially completed instantiation object??

  const customComponent = customTypeRenderers['view'][customType.payload.name];

  // const customComponentRelationships = await haborSDK.searchInstances<Relationship>(token, { nounId: RelationshipNoun.id, search: { match: { payload: { destId: { instanceId: typeId } } } } });
  // const customComponentRelationship = customComponentRelationships.length ? customComponentRelationships[0] : undefined;
  // let customComponent: HaborComponent | undefined;
  // if (customComponentRelationship && customComponentRelationship.payload.srcId.instanceId) {
    // const serCustomComp = await haborSDK.retrieveInstance<SerializedHaborComponent>(SerializedHaborComponentNoun.id, customComponentRelationship.payload.srcId.instanceId, token);
    // customComponent = serCustomComp ? deserializeHaborComponent(serCustomComp.payload) : undefined;
  // }

  //  Get the Schema
  const delegateSDT: AllTMSDTs = customType.payload.type;

  //  Create the Field
  if (customComponent == undefined) {
    return await renderView({ ...params, sdt: delegateSDT });
  } else {

    //  TODO-IMPORTANT:  As the UI gets more complicated, how can we make sure that these nested components are NOT running queries to Habor in parallel?  This would cause exceptions until the queuing system is in place.
    //  TODO:  The Custom Type components should have a specific Props interface.  This means we should pass those props down (FOR NOW, we're just rendering a dummy component).
    
    //  Create the onChange HaborProp
    //  PLANNING:  A Function has a clear INPUT and OUTPUT.  I like the idea of Emitters, which emit a variable.  Instead of passing a callback to be handled, consider passing an Event, which takes a set of params, and that event is emitted.  Then, there is an event listener... This sounds a LOT like an Observable.  I like the idea of "Beacon".  So, ONE ACTION is "Lighting" a Beacon, or MAYBE "Emitter".  Which is really an Event Emitter.  So, why don't we do this in JS instead of passing callbacks?  These days, we don't really even pass callbacks.  We use Promises, which internally passes a callback to a method in the Promise module.  Even in React though, we pass functions... Ok, so is that what we should do here?  Why don't we use Promise in React, why don't we pass Event info to invoke events?  A passed function is essentially an Event handler, but with only ONE listener.  It makes sense to use Promises when something is Asynchronous.  In React, it's not that it's going to come back once at some unknown point in time, it can fire SEVERAL times.  Promises are KIND of a specific use case, where the callback is triggered ONCE and we want might want to block the caller until it's resolved.  OK!  I like the EVENT handler idea!
    //  DECISION:  We're going to create "Emitters" which emit "Events".  It's then possible to attach functions which respond to those events.  MAYBE it would make more sense to pass the function directly?  Actually, maybe we SHOULD do that for now... what is the Emitter concept buying us other than another abstraction layer?  
    //  IDEA:  Functions SHOULD have a Closure context, just like JS?  MAYBE we can inject the function into a context / existing function (process)?
    //  IDEA:  Right now, each statement selection requires a "functionName" param... BUT, the set of functions available depends on the CONTEXT.  We MAY even want to make a MODULE system to import things!??  That's super fun and cool, I like that.  It's like a really abstracted language with a bunch of guarantees!  OK, so FOR NOW, let's just keep everything registered Globally with very little context, but we sure can work on that.

    //  TODO:  Need to pass Input / Output / Event list to the component where Output needs to be attached to the onChange ReduxField handler?  Figure out the interplay between Outputs and Events.
    // const HaborFieldComponent = ({ input: { onChange, value } }: WrappedFieldProps) => {

    //   //  Create the Value HaborProp
    //   const valuePropType = delegateSDT;
    //   const valueProp: HaborValue = { type: valuePropType, value };

    //   // TODO-IMPORTANT:  changePage and workspace don't make sense here!
    //   return <HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ customComponent as HaborComponent } handleClose={ () => null } componentProps={{ propTest: "This is a prop test!" }} />
    // };

    //  TODO-NEXT:  Support Input (props) and OUTPUTS in HaborComponents to avoid passing callbacks!  An Output is just a function that will be called when a particular thing happens... It's BASICALLY the same in ReactNative, they are just all grouped in Props, and we explicitly pass a function.  In Habor, we have Input and Output, and the required Inputs / Outputs must be connected for the component to work!  In React, an Output might require a callback, in Habor, we just need to BIND the output to a name in the parent component.  This name is like a pipe that will be invoked each tim the callback is triggered.  So, basically a function.  This means things like "onBlur" are Variables?  Hmmm... things like that are usually just triggered once?  MAYBE we have outputs with names like "blurred", which is TYPED, and triggers a State change in the parent when its value changes!  What about events like "onClick"?  This should TRIGGER a handler... Maybe the component is a BUTTON, and we want to invoke a method in the parent!  OK, I think I got it.  I want to be able to attach to ANY child component property and use that in the parent WITHOUT setting up an explicit function to set the state and all that.  Instead, I'd like there to be an Output PORT on the child that I can connect to, process, and pass on.  If it's something that's only TRIGGERED, not continuously represented, THEN, I suppose I can attach an Event Handler?  This is like the distinction between a live wire with Analog data and a wire with Digital data!  The EVENT HANDLER wire is digital.  OK, so I'd like to break this up in the components to make the interface even more obvious to the user... It's confusing trying to wrap everything in functions in React!  So, we have Inputs, Outputs, and Events!  I think I really like that model!
    //  CONCERN:  I remember reading a Kickstarter post about someone else who made a game with Photoshop like filters to write code?  I HAD already thought of something very similar though, and I'm fairly sure I wrote about it, and I DO remember talking about it.  I think of it more like making a song though.  How useful will this be compared to actually writing code?  Well, even on a desktop, I think this can be very helpful, because it's a SIMPLIFIED development API, and it's SUPER introspective.  In JS, the introspection is confusing and not entirely clear... I'm hoping this overlay will be more interpretable to developers who want to make UIs to render the actual development experience!  MAYBE there's already something like this in VSCode and languages like JS, but I'm not entirely sure... I mean, I guess there must be because the code does break down in to files and CODE elements within those files.  But, this is nice because it doesn't need an interpreter other than the app, and it's a high level language... basically it makes it easy for me to do the things I want to do?  It might also be overkill... I suppose I'll find out soon enough.  It's also nice that EVERYTHING is serializable, and therefore searchable?  My question is... why not just do this whole thing with pure JS... Well, one reason is the introduction of Runtime types!
    //  NOTE:  We want the user to have drop-downs / searcheable selections for just about everything... I mean, I suppose Typescript and JS are just like that with intellisense... We can choose from an enum, etc...  But, not from user registered instances?  Just classes and statically defined things.  I think it will be cool to support more than that?
    //         Working on this will hopefully give clarification into where I'm doing more work than I need to.

    //  PLAN:  Ok, so now we know what we want the component interface to look like... But, what about the app level interface and route interface with registered settings?  Not so sure about that just yet... 
    //         Honestly, I feel like I'm reinventing the wheel a little bit... Typescript IS a great way to represent types... Maybe if I learn more about JS and TS I can do MUCH of what I'm trying to do with them... But I think this is good because at least it's my own creation, so I define the interface.
    //         Ok, SO, short-term goal is to just freaking get this custom type thing working... To do that, we need to support either inputs / outputs / events on components OR support passing functions.  All of that sounds like a like a lot of work that won't realize in usable immediate gains... Now that I know what's involved and I have a plan for components and app level settings (and route / router level with registration at primitive level or HaborComponents... the settings thing needs more work) I feel like I can move on and yeh, just table this last piece of work... So, FOR NOW, let's go ahead and just let the type use the DEFAULT!

    //  TODO:  SOMETIMES, we may want to render in the context of a FORM.  That will require this type of logic that I removed?  At least for now.. I think it would help to free ourselves from ReduxForm and make our OWN Form framework that can work with context / cross-app!
    // const reactElement = <Field name={ params.name } component={ HaborFieldComponent } />

    console.log("test");
    return <HaborComponentViewer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ customComponent as HaborComponent } handleClose={ () => null } componentProps={{ value }} />
  }
};
