import { EntityType, FrameworkContext, HaborComponentContext, InstanceInternal, NamedObject, NounInternal, Relationship } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, View, TextInput } from 'react-native';
import { DavelField } from '../../../../packages/davel-ui/davel-ui-tools';
import { Card } from '../../../../packages/kelp-bar/card';
import { KelpIcon } from "../../../../packages/kelp-bar/kelp-icon";
import { largeSpacer, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { HaborContainer } from '../../component-plugin/habor-react/habor-component-lib';
import { getSystemForNoun, HaborReactComponent } from '../../habor-plugin/habor-utils';
import { haborSDK } from '../../hessia-plugin/config';
import { ParentPlugin } from './parent-plugin';


//  TODO:  Pull this out into an "ObjectSelector" widget?

//  TODO:  AGAIN, instead of explicit component TYPES like "mini-widget", PERHAPS we should link into ACTIONS and do this there!?  PERHAPS this IS ok for now, BUT I worry THIS is a critical touch point!!!  So, I REALLY want to come back to this.  We want to make sure the user has proper control!??  IF it's hard-codded to a specific type, then they MAY not have such control!?  BUT we ALSO want tomake sure the selections are CONFORMANT!?

//  TODO:  STILL not sure it's necessary to have "InstanceInternal".. I think it's OK for now though!?  MAYBE build some systems to automatically resolve the associated stuff?? MAYBE use GraphQL, etc...

export interface NamedObjectMiniComponentProps extends MiniComponentProps { }
interface NamedObjectMiniComponentState {
  noun?: NounInternal;
  system?: InstanceInternal<NamedObject>;
}
class NamedObjectMiniComponentBase extends HaborReactComponent<NamedObjectMiniComponentProps, NamedObjectMiniComponentState> {

  public componentDidMount = async () => {
    const { token } = this.context.frameworkContext;
    const { instance } = this.props;
    if (!instance) { return; }
    //  CONSIDER:  I'd REALLY like a Habor SDK / API fof RESOLVING associated shit!  This way we can get pieces from OTHER systems AND things like the associated NOUN!??  BUT, I'd like it all memoized and stuff so pieces can grab what they need WITHOUT a ton of queries!? HMM!!  MAYBE a way to merge frontend queries and backend, LIKE GraphQL.. BUT perhaps by SIDESTEPPING it completely where everything is memoized?? Hmmmmm
    //  TODO:  Support Noun ICONS!!!  Again.. MAYBE with an Association OR just make this part of the primitive class system!? HMM!
    const noun = await haborSDK.retrieveNoun(instance.nounId, token);
    const system = await getSystemForNoun(noun, token);
    this.setState({ noun, system });
  }

  constructor(props: NamedObjectMiniComponentProps) {
    super(props);
    this.state = {}
  }
  public render = () => {
    const { instance, children } = this.props;
    const { noun, system } = this.state;

    if (!instance) { return <Text>No Instance...</Text> }
    if (!noun || !system || !instance) { return <Text>Loading...</Text> }

    return (
      <Card innerStyle={{ padding: smallSpacer }}>
        <KelpIcon type={system.payload.icon?.type || "material"} name={system.payload.icon?.name || "dots"} />
        <KelpIcon type={instance.payload.icon?.type || "material"} name={instance.payload.icon?.name || "dots"} />
        <Text>{instance.payload.name}</Text>
        {/* TODO:  I'd like to be able to DELETE this component.. BUT I ALSO use this as a GENERIC view!  So... how should we proceed?  PERHAPS it should be injected? */}
        {children}
      </Card>
    );
  }
}
export const NamedObjectMiniComponent = NamedObjectMiniComponentBase

const EventMiniWidget = ({ instance }: MiniComponentProps) => {
  if (!instance) { return <Text>No Instance...</Text> }
  return (
    <Card>
      <Text>{instance.payload.startDate}</Text>
    </Card>
  );
}

//  TODO:  Do ALL this in Hessia and in a MORE organized way!?
//  TODO-TS:  Type this!
type ComponentMap = { [nounId: string]: React.FunctionComponent<MiniComponentProps> | React.ComponentClass<MiniComponentProps> };
const miniComponentMap: ComponentMap = {
  'namedobject': NamedObjectMiniComponent,
  'event': EventMiniWidget
};

//  TODO:  Make a COMPONENT for this!! This way, we can render an instance, and it will AUTOAMTICALLY resolve the the proper thing?? Hmmm...
//  CONCERN:  This FEELS VERY derived to me.. I think we want to abstract in a more general way??? PLUS, it should be clear this is DEPENDENT upon the CLASS system!?  Hmmmmm... I think we want to make things like this clear in our dependency list!?  IN the future, MAYBE use Actions?  MAYBE don't couple with Class?  THOUGH, I think that's actually potentially acceptable!
//  CONCERN:  WHAT if we want to select a certain widget based on the context conditions?  MAYBE we want to override the GLOBAL selection of widget for a particular class!?  Hmmmm
const getComponent = async (componentMap: ComponentMap, nounIds: string[], token: string): Promise<React.FunctionComponent<MiniComponentProps> | React.ComponentClass<MiniComponentProps> | null> => {
  for (const nounId of nounIds) {
    const component = componentMap[nounId];
    if (component) { return component };
    const noun = await haborSDK.retrieveNoun(nounId, token);
    const { inherits = [] } = noun;
    return await getComponent(componentMap, inherits, token);
  }
  return null;
}

//  TODO:  SHOULD we make a more general one that takes 
//  THOUGHT:  I REALLY don't think props are that special.. it's just an argument.. I think React stuff is just ONE primitive way of doing things...  There are LOTS of other models!?? HMM!!??  Plus, I like the idea of binding props at RUNTIME with things like the CSS-like system!?

export interface MiniComponentProps {
  instance?: InstanceInternal;
}
interface MiniComponentState {
  component: React.FunctionComponent<MiniComponentProps> | React.ComponentClass<MiniComponentProps> | null;
}
class MiniComponentBase extends HaborReactComponent<MiniComponentProps, MiniComponentState> {

  constructor(props: MiniComponentProps) {
    super(props);
    this.state = {
      component: null
    }
  }

  private updateComponent = async (inst?: InstanceInternal) => {
    const { token } = this.context.frameworkContext;
    if (!inst) {
      console.log("Instance List Widget:  Undefined Instance!");
      return;
    }
    console.log("Instance List Widget:  Instance Defined: " + JSON.stringify(inst));
    //  TODO:  Performance!!!
    const component = await getComponent(miniComponentMap, [inst.nounId], token);
    this.setState({ component });
  }

  public componentWillReceiveProps = (newProps: MiniComponentProps) => {
    const { instance } = newProps;
    this.updateComponent(instance);
  }

  public componentDidMount = () => {
    this.updateComponent(this.props.instance);
  }

  public render = () => {
    const { component: Component } = this.state;

    //  TODO:  Build a generic LOADING tool!!
    if (Component === undefined) { return <Text>Loading...</Text> }

    //  NOTE:  FOR NOW, all "Mini Components" will get the SAME props.  DO we want this???  Hmmm...  What about UI PAIRS and AGGREGATIONS??? HMMM.. I want this to be DYNAMIC and DECLARATIVE!?
    return Component ? <Component {...this.props} /> : <Text>Default Mini Component</Text>;
  }
}
export const MiniComponent = MiniComponentBase

export interface ParentSelectionWidgetProps {
  instance: InstanceInternal<any>;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext
  parentPlugin: ParentPlugin;
}
interface ParentSelectionWidgetState {
  parentAssignments: InstanceInternal<Relationship>[];
  parents: InstanceInternal[];
  children: InstanceInternal[];
  selectedParentId?: string;
  selectedParentNounId?: string;
  selectedChildId?: string;
  selectedChildNounId?: string;
}

class ParentWidget extends HaborReactComponent<ParentSelectionWidgetProps, ParentSelectionWidgetState> {
  constructor(props: ParentSelectionWidgetProps) {
    super(props);
    this.state = {
      parentAssignments: [],
      parents: [],
      children: []
    }
  }

  public componentDidMount = async () => {

    //  Unpack
    const { frameworkContext: { token } } = this.props;

    //  Get the Owner Relationships
    await this.refreshOwnershipRelationships();
  }

  private refreshOwnershipRelationships = async () => {

    //  Unpack
    const { frameworkContext: { token }, instance } = this.props;

    //  Get Owner Relationships
    const parentAssignments = await this.props.parentPlugin.getParentAssociations(instance, token);

    //  Get the Parents
    //  TODO:  There SHOULD be an easier and EFFICIENT way to do this!?  FOR NOW, let's abstract this to a common function!?  Again, I REALLY want each Plugin / whatever to have it's OWN set of potential primitive functions AND perhaps inject them into the UI!?  I DO think it MAY make sense to centralize this stuff!?  EVEN if the UI doesn't need access to everything in the back?? Hmm

    const parents: InstanceInternal[] = [];
    const children: InstanceInternal[] = [];
    for (const parentAssignment of parentAssignments) {
      const { payload: { srcId: childId, destId: parentId } } = parentAssignment;

      //  Check if "instance" is the Parent
      if (parentId.instanceId === instance.id) {
        if (childId.instanceId) {
          const child = await haborSDK.retrieveInstance(childId.nounId, childId.instanceId, token);
          children.push(child);
        } else {
          throw `Cannot display a child, because the child 'instanceId' is missing.`;  //  TODO:  PERHAPS include file / location in DEBUG mode, and hide this in production!?
        }
      }

      //  Check if "instance" is the Parent
      //  CONSIDERs:  Should we support self reference?
      if (childId.instanceId === instance.id) {
        if (parentId.instanceId) {
          const parent = await haborSDK.retrieveInstance(parentId.nounId, parentId.instanceId, token);
          parents.push(parent);
        } else {
          throw `Cannot display a parent, because the parent 'instanceId' is missing.`;  //  TODO:  PERHAPS include file / location in DEBUG mode, and hide this in production!?
        }
      }
    }

    //  TODO-CRITICAL:  SOOO Important!!  I REALLY don't want to be doing SOOO Much manual coding!  I NEED to abstract this stuff in a reasonable way!?  MAYBE starting with the Plugins, BUT perhaps moving to GraphQL or something similar?? Hmm...  I'm NOT sure that will reall help though?  MAYBE when we make the move to LOCAL storage as an option it will give some insight?  I DO have some ideas scattered about AND partially there in my brain though!?
    this.setState({ parentAssignments, parents, children });
  }

  public setParent = async () => {
    const { instance } = this.props;
    const { token } = this.context.frameworkContext;
    const { selectedParentId, selectedParentNounId } = this.state;
    if (!selectedParentId || !selectedParentNounId) { alert("Must specify a Parent Instance ID and Noun ID."); return; }

    const newParentAssociation: Relationship = {
      srcId: {
        instanceId: instance.id,
        type: EntityType.Instance,
        nounId: instance.nounId
      },
      destId: {
        instanceId: selectedParentId,
        type: EntityType.Instance,
        nounId: selectedParentNounId
      }
    }

    //  TODO-GENERAL:  Don't hard-code!  Either use the SDK, merge the codebases, OR do something else.s
    await haborSDK.createInstance({ nounId: "parent-assignment", payload: newParentAssociation }, token);
    alert("Created the Parent Assignment!");
  }

  //  CONSIDER:  We COULD collpase the child / parent functions.  BUT, sometimes I think it's just easier to read this way.
  public setChild = async () => {
    const { instance } = this.props;
    const { token } = this.context.frameworkContext;  //  TODO:  Why is the intellisense helper not showing for this?
    const { selectedChildId, selectedChildNounId } = this.state;
    if (!selectedChildId || !selectedChildNounId) { alert("Must specify a Child Instance ID and Noun ID."); return; }

    const newChildAssociation: Relationship = {
      srcId: {
        instanceId: selectedChildId,
        type: EntityType.Instance,
        nounId: selectedChildNounId
      },
      destId: {
        instanceId: instance.id,
        type: EntityType.Instance,
        nounId: instance.nounId
      }
    }

    //  TODO-PARENT-SYSTEM:  CAN we have MULTIPLE associations between the SAME two things?  Does that have ANY meaning in this system?? hmm...  MAYBE a system using this system COULD povide meaning for that.. BUT I don't think we should permit this?  Instead, it can be haneled with metadata on the relationship!??  IF, for example, we're trying to indicate WHEN and HOW MANY associations exist, OR maybe association STRENGTH, etc!?
    await haborSDK.createInstance({ nounId: "parent-assignment", payload: newChildAssociation }, token);
    alert("Created the Parent Assignment!");
  }

  public deleteParent = async (parentAssociation: InstanceInternal<Relationship>) => {
    await haborSDK.deleteInstance(parentAssociation.nounId, parentAssociation.id, this.context.frameworkContext.token);
    alert(`Deleted '${parentAssociation.id}'`);
  }

  public render = () => {

    //  Unpack
    const { parents, children, parentAssignments } = this.state;

    return (

      //  TODO:  SHOULD we support multiple parents?
      //  TODO:  Should we support additional options like "Dependency", etc???
      //  TODO:  Consider WHAT to display here.  I'd suggest we keep things DE-COUPLED.  PERHAPS we choose a View, then the shit associated with that view?  Hmm... Or, MAYBE we treat this as an "Action" and then it's up to the systems to choose how to return?  Hmmm... Again, I REALLY like my plans for the ACTION system.  BUT, maybe FOR NOW, we just stick with a SINGLE pre-determined view?  MAYBE we abstract further, and just use Action.. I DO kinda like that though@!?  THen, perhaps the selection can be bound to the context and stuff??? Hmmm..

      <View style={{ flex: 1 }}>
        <Text>Parents</Text>
        <View>
          {
            parentAssignments.map(parentAssignment => (
              <MiniComponent instance={parents.find(parent => parent.id === parentAssignment.payload.destId.instanceId)}>
                <Button title="Delete" onPress={() => this.deleteParent(parentAssignment)} />
              </MiniComponent>
            ))
          }
          <TextInput autoCapitalize="none" defaultValue="Parent Instance ID" onChangeText={(selectedParentId: string) => { this.setState({ selectedParentId }) }} />
          <TextInput autoCapitalize="none" defaultValue="Parent Noun ID" onChangeText={(selectedParentNounId: string) => { this.setState({ selectedParentNounId }) }} />
          <Button title="Add Parent" onPress={this.setParent} />
        </View>

        <Text>Children</Text>
        <View>
          {
            children.map(child => (
              <MiniComponent instance={child} />
            ))
          }
          <TextInput autoCapitalize="none" defaultValue="Child Instance ID" onChangeText={(selectedChildId: string) => { this.setState({ selectedChildId }) }} />
          <TextInput autoCapitalize="none" defaultValue="Child Noun ID" onChangeText={(selectedChildNounId: string) => { this.setState({ selectedChildNounId }) }} />
          <Button title="Add Child" onPress={this.setChild} />
        </View>
      </View>
    );
  }
}

export interface ParentListHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal<any>;
  }
}
export const ParentListHaborComponentPrimitive = ({ userProps, frameworkProps }: ParentListHaborComponentPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  // const parentPlugin = useContext(ParentPluginContext);

  //  TODO:  PASS THE PARENT PLUGIN!!!

  return (
    <HaborContainer frameworkProps={frameworkProps} style={{ display: 'flex', flexDirection: 'row', marginBottom: largeSpacer }}>
      <DavelField name="Parent System">
        <ParentWidget parentPlugin={{} as any} {...userProps} frameworkContext={context} componentContext={componentContext} />
      </DavelField>
    </HaborContainer>
  );
};

// export const ParentListHaborComponent: HaborComponent = {
//   name: "ParentListHaborComponent",
//   propsSchema: { type: "object", extensible: true },
//   element: {
//     name: "ParentListHaborComponentPrimitive",
//     props: {
//       instance: { type: "symbol", scopePath: ["props", "instance"] }
//     },
//     children: []
//   }
// };
