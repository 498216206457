import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import { NamedObject, NounInternal, pluginNoun } from 'habor-sdk';
import { saveToHome } from 'homeward';
import * as React from 'react';
import { ScrollView, Text, View, ViewStyle } from 'react-native';
import { renderRGBAColor } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { HessiaThemePart, registerThemeMapper, withInjections } from '../../../../packages/injector/injector';
import { AppHeader } from '../../../../packages/kelp-bar/app-header';
import { RoundIconButton } from '../../../../packages/kelp-bar/buttons';
import { medSpacer, smallSpacer } from '../../../../packages/kelp-bar/styles';
import { Entity, EntityPlugin, Identifiable } from '../../entity-plugin';
import { AppContext } from '../../hessia-plugin/AppContext';
import { AggregatePlugin, getPluginElements } from '../../system-plugin/plugin/plugin-tools';
import { RootRoute } from '../../system-plugin/system-types';
import { DependencyWidget } from '../components/dependency-widget.component';
import { HAppRoute } from '../apps.nav.routes';
import { AppViewerNavigationProp } from './app-viewer.screen';


//  TODO:  As shownn on StackOverflow, I shuold ensure TWO hex digits here.  MAY just want to grab a lib.
// export const renderHexColor = (color: RGBAColor, includeHash?: boolean) => { return `${ includeHash && "#" }${ parseInt(color.r).toString(16) }${ color.g }${ color.b }` };

const LinearGradient = ExpoLinearGradient as any;

//  TODO:  Support prop typing
export interface ManagedComponent extends NamedObject, Identifiable {
  component?: React.FunctionComponent<any> | typeof React.Component;
  resolver?: ({ entity, entityPlugin }: { entity: Entity, entityPlugin: EntityPlugin }) => any;
}

//  CONSIDER:  This is VERY similar to "Habor Component".  MAYBE we can auto-wrap? Hmm...  Don't need 
export interface AppSystemWidget extends ManagedComponent {}

const appSystemWidgets: AppSystemWidget[] = [];

export const registerAppSystemWidget = (widget: AppSystemWidget) => {
  appSystemWidgets.push(widget);
}

interface AppEditorProps {
  navigation: AppViewerNavigationProp;
  style?: ViewStyle;
}
interface AppEditorState {
  pluginDetails?: AggregatePlugin;
}
class AppEditorBase extends React.Component<AppEditorProps, AppEditorState> {

  //  TODO:  Seriously consider making a CLASS to encapsulate this... Then, just sub-class it.  We can ALSO attach lots of other useful app stuff???

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: AppEditorProps) {
    super(props);
    this.state = {
      pluginDetails: undefined
    }
  }

  componentDidMount = async () => {

    //  Get the System
    const { token, frameworkContext: { plugin } } = this.context;
    
    //  TODO:  Centralize!  Keep it DRY!

    if (!plugin) { throw "No Plugin Provided in FrameworkContext." }
    if (!token) { throw new Error("Undefined Token"); }

    const pluginDetails = (await getPluginElements([plugin], token))[plugin.id];

    this.setState({ pluginDetails });
  }
 
  public render = () => {


    const { pluginDetails } = this.state;
    const { frameworkContext: { plugin } } = this.context;

    if (!plugin) { throw "No Plugin Provided in FrameworkContext." }
    if (!pluginDetails) { return <Text>Loading...</Text> }

    
    const saveToHomeOptions = { manifest: { name: plugin.payload.name, letter: plugin.payload.name.substr(0, 1), background_color: renderRGBAColor(plugin.payload.color || { r: "100", g: "100", b: "100" }) , theme_color: renderRGBAColor(plugin.payload.color || { r: "100", g: "100", b: "100" }) }, link: Linking.makeUrl() + '?payload=' + JSON.stringify({ handlerId: "system", handlerPayload: { systemId: plugin.id } }) };

    const headerButtons = () => (
      <React.Fragment>
        
        {/* TODO:  These buttons shuld be INJECTED? HM!  The idea is, everything should be? hmm.. when do we just have a stable encoding?  I THINK the idea is, it's up to US how much we want to hard-code in a thing..  Either way it's going to INTERPRETED hmm... */}

        {/* Plugin Editor */}
        {/* <Text>SHOULD SHOW INSTANCE BUILDER BUTTON</Text> */}
        <RoundIconButton
          onPress={ () => this.props.navigation.navigate(HAppRoute.InstanceBuilder, { noun: pluginNoun as NounInternal, instance: plugin }) }
          iconSelection={{ name: 'edit', type: 'font-awesome' }}
          backgroundColor="#25ced2"
        />

        <View style={{ width: smallSpacer }} />

        {/* Entity Builder */}
        <RoundIconButton
          onPress={ () => this.props.navigation.navigate(RootRoute.SystemMenu as any) }
          iconSelection={{ name: 'plus', type: 'font-awesome' }}
          backgroundColor="#25ced2"
        />

        <View style={{ width: smallSpacer }} />

        {/* Add to Home */}
        <RoundIconButton
          onPress={ () => saveToHome(saveToHomeOptions) }
          iconSelection={{ name: 'star', type: 'material-community' }}
          backgroundColor="#25ced2"
        />

      </React.Fragment>
    );

    //  TODO:  On long press show the Element Actions box!???  This is a QUICK view, like a CONTEXT menu with actions and shit???
    //  TODO:  On Press, trigger the DEAULT action for that element??? Go to the defaut page for this UI Context?  AGAIN, perhaps WITHIN the UI it's one thing, but elsewhere it MAY be comsething else... I suppsose we can configure the BUTTON, OR perhaps configure the UI option like CSS thing???
    //  TODO:  Make an easy way to pop a modal from ANY screen with shared functionality???  Hmmm...
    //  HEADBASH:  On Web, the outer view containing a ScrollView seems to need to be "flex: 1" for the ScrollView to expand correctly.  I'm not exactly sure why though.
    return (
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1, ...this.props.style }}>
        {/* IDEA:  We COULD make a DEBUG class to make it EASY to see which elements correspond to which code? HM and stuff like that? Hm associations Hm! */}
        <AppHeader { ...this.props } headerComponent={ headerButtons } title={ plugin.payload.name } />
        <ScrollView alwaysBounceVertical={ true } showsVerticalScrollIndicator={ false } style={{ display: 'flex', flexDirection: 'column', flex: 1 }} contentContainerStyle={{ padding: medSpacer }}>

          <View style={{ height: medSpacer }} />

          {/* TODO:  Should be injected along with a framework for spacing!? */}
          <DependencyWidget system={ plugin } />

          <View style={{ height: medSpacer }} />

          {
            appSystemWidgets.map(({ component: Comp }) => <><Comp system={ plugin } /><View style={{ height: medSpacer }} /></>)
          }

        </ScrollView>
      </View>
    );
  }
}


const hessiaThemeMapper = (part: HessiaThemePart) => {  //  NOTE:  The TYPE of the theme is DYNAMIC and determined by enabled systems!  It's NOT a statically set thign!? HM!  SO... this means that the PIECES that extract the static part of the theme should be separate!? HM!  So.. MAYBE we have a style resolver fo the MAINN Hessia theme elmeents? HM!  I THiNK that would make sense!
  return {
    style: {
      backgroundColor: part.background,
    },
    fontColor: part.color
  };
}

//  TODO:  Move this to anoher file, AND perhaps symbolize the "AppHeader" thing, AND make a way to view all these systems in CONTEXT so it's not OPAQUE and hard to read!  Need DEV TOOLS for this!?  I THINK Hessia will give us MUCH of that!?  HM!
registerThemeMapper("AppEditor", hessiaThemeMapper, "hessia");

export const AppEditor = withInjections(AppEditorBase, 'AppEditor');