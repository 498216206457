import { EntityType, Instance, InstanceInternal, Noun, NounInternal, Plugin, SystemAssociation, SystemAssociationNoun } from 'habor-sdk';
import { ModelSDK } from './model-sdk';

export const enableNounsForPlugin = async (nouns: NounInternal[], plugin: InstanceInternal<Plugin>, sdk: ModelSDK) => {
  try {
    for (const noun of nouns) {
      const rel: Instance<SystemAssociation> = { nounId: SystemAssociationNoun.id, payload: { srcId: { nounId: noun.id, type: EntityType.Noun }, destId: { nounId: plugin.nounId, instanceId: plugin.id, type: EntityType.Instance } } };
      
        await sdk.createInstance(rel);
    }
  } catch (err) {
    throw "EnableNounsForPlugin:  " + err;
  }
};

export const getAllProperties = (entity: NounInternal | Noun) => {
  const allProperties = Object.keys(entity.properties).map(propName => entity.properties[propName]);
  return allProperties;
}
export const getClassProperties = (entity: NounInternal | Noun) => {
  const allProperties = getAllProperties(entity);
  const classProperties = allProperties.filter(prop => !!prop.static);
  return classProperties;
}

export const getInstanceProperties = (entity: NounInternal | Noun) => {
  const allProperties = getAllProperties(entity);
  const instanceProperties = allProperties.filter(prop => !prop.static);
  return instanceProperties;
}


//  TODO:  Use the GENERALIZED Entity system to associate with the apps!  The idea is, these things will "belong" to the app? Hmm... it's not 100% clear just yet.  But it IS clear that I want them to be in that context.  We MAY even want to make a quick system where we don't need to have everything encoded with the DB.  Then the idea is it's BASICALLY jus ta scripting language.  MAYBE we can have all the relations declared in a file and as long as there aren't too many, then we can mmmm.... we MAY want to do that... WHY do we want a WHOLE index for each of these things???  It's CRAZY over-kill!  We CAN just define it in a local file, and that makes it easy to transfer and stuff too hmm... 

// export const enableInstancesForPlugin = async (insts: InstanceInternal[], plugin: InstanceInternal<Plugin>, token: string) => {
//   for (const inst of insts) {
//     //  TODO:  Make sure we don't associate them MULTIPLE times!  The "System" system just uses ONE assocition and does NOT have meaning in the NUMBER of such associations, BUT duplicates WOULD slow things down and remove the meaning which SHOULD have been encapsulated by the Version system? HMMM...  As in, we normally make a new relationship on create, AND perhaps Update.. which we should not be doing.  We should UPDATE the existing relationship if necessary!?
//     const rel: Instance<SystemAssociation> = { nounId: SystemAssociationNoun.id, payload: { srcId: { nounId: inst.nounId, instanceId: inst.id, type: EntityType.Instance }, destId: { nounId: plugin.nounId, instanceId: plugin.id, type: EntityType.Instance } } };
//     await haborSDK.createInstance(rel, token);
//   }
// };

