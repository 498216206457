import { HaborSDK, RelationshipSDK } from 'habor-sdk';
import { History } from 'history';
import * as _ from 'lodash';

//
//  Habor SDKs
//
//  TODO:  Select server zone based on environment?  We want sto avoid accidentally deploying this and pointing all production users to dev!
//  TODO:  Perhaps PRINT info to the console when building and stuff to REMIND us to update the Oranda dependencies and update Habor and stuff?? Hm!

//  This is the working one.
// export const haborSDK = new HaborSDK('https://hessia-habor.herokuapp.com/api');


export const environment = 'production'; // process.env.NODE_ENV;

//  CONSIDER:  I can get it from the server... but that's got startup costs.  I could also just set it here hmm...  Ah!  Do it in BABEL!  WHen I buid for production hm!

const homeWifi = "192.168.88.239";
// const iPhoneWifi = "172.20.10.2";
const catulpaWifi = "172.16.102.63";
const applebeesWifi = "10.239.134.250";
const turnersLibrary = "10.121.33.132";
const localWifi = homeWifi;

export const haborHost = environment === 'production' ? 'https://habor.services.oranda.io/api' : `http://${ localWifi }:3000/api`

export const haborSDK = new HaborSDK(haborHost);

// export const haborSDK = new HaborSDK('http://192.168.88.239:3000/api');

// export const haborSDK = new HaborSDK('http://192.168.88.239:3000/api');
// alert(haborSDK);


// export const haborSDK = new HaborSDK('https://habor-dev.herokuapp.com/api');
// export const haborSDK = new HaborSDK('http://10.0.0.164:3000/api');

//  Tolland House
// export const haborSDK = new HaborSDK('http://192.168.1.39:3000/api');

//  AWS
// export const haborSDK = new HaborSDK('https://master.d3x8avi32hyp0.amplifyapp.com/api');

//  Sean's House
// export const haborSDK = new HaborSDK('http://192.168.0.164:3000/api');

//  Will's iPhone
// export const haborSDK = new HaborSDK('http://172.20.10.6:3000/api');


//  Endwell - Maies
// export const haborSDK = new HaborSDK('http://192.168.1.10:3000/api');



// export const haborSDK = new HaborSDK('http://10.0.0.79:3000/api');

export const relationshipSDK = new RelationshipSDK(haborSDK);

//
//  App Settings
//

// export interface AppSettings {}

//
//  Route Management Utility
//  TODO:  Eventually consider combing this tool and the router itself.  Maybe add components to the route definitions below?
//

export interface Route<T> {
  parent?: Route<any>;
  name: string;
  displayName?: string;
  component?: React.Component<any>;
  children?: Route<any>[];
}

export const attachChildRoute = (parentRoute: Route<any>, childRoute: Route<any>) => {
  if (!parentRoute.children) { parentRoute.children = []; }
  parentRoute.children.push(childRoute);
  childRoute.parent = parentRoute;
  return childRoute;
}

//  TODO:  Use type-inference of the passed in variable to enforce the props sent to the route!
export function navigate (history: History, routeListItem: RouteListItem, props: any) {

  //  Get the Path
  const path = getRoutePath(routeListItem);

  //  Navigate
  history.push(path, props);
};

export const getRoutePath = (routeListItem: RouteListItem) => {
  const route = routeListItem._route;
  const pathParts: string[] = [route.name];
  let parentRoute = route.parent;
  while (parentRoute != undefined) {
    pathParts.push(parentRoute.name);
    parentRoute = parentRoute.parent;
  }
  let path = ('/' + _.reverse(pathParts).join('/'));
  if (path.endsWith("/")) {
    path = path.substr(0, path.length - 1);
  }
  return path;
}

// //
// //  App Route Definitions
// //

// //  Create Routes

// //  Define Route Params
// export interface WorkspaceRouteParams {
//   workspace: InstanceInternal<App>;  //  TODO:  Change the name to "Workspace".
// }

// export interface EntityRouteParams {
//   entity: NounInternal;  //  TODO:  Change the name to "Entity"?
// }

// export interface InstanceRouteParams {
//   instance: InstanceInternal<any>;  //  TODO:  Change the name to "Entity"?
// }


export interface RouteListItem {
  _route: Route<any>;
  [name: string] : RouteListItem | Route<any>;
}
export interface RouteList {
  [name: string]: RouteListItem;
}

//  NOTE:  Let's start here and work our way toward the goals we defined above...
//  TODO:  Let's consider NOT making a separate wrapped and doing everything from a Root "RouteListItem"... This also simplifies the getRoutePath function.
//  TODO:  Figure out where you wrote the goals for this system.  I BELIEVE the idea is to enable Intellisene / auto-complete for routes?  And make it possible to reference the same route multiple places without a BUNCH of dense names and / or hard-coded strings?

const InstanceRouteList = {
  _route: { name: "instances" },
  Create: {
    _route: { name: "create" }
  },
  List: {
    _route: { name: "list" }
  },
  Edit: {
    _route: { name: "edit" }
  }
};

const EntityRouteList = {
  _route: { name: "entities" },
  Create: {
    _route: { name: "create" }
  },
  List: {
    _route: { name: "list" }
  },
  Edit: {
    _route: { name: "edit" }
  },
  Instances: InstanceRouteList
};

export const RouteList = {
  Home: {
    _route: { name: "home" }
  },
  Profiles: {
    _route: { name: "profiles" },
    Detail: {
      _route: { name: "detail" }
    }
  },
  Plugins: {
    _route: { name: "plugins" },
    Create: {
      _route: { name: "create" }
    },
    List: {
      _route: { name: "list" }
    },
    Edit: {
      _route: { name: "edit" }
    },
    Detail: {
      _route: { name: "detail" }
    }
  },
  Pages: {
    _route: { name: "pages" },
    Edit: {
      _route: { name: "edit" }
    }
  },
  Composer: {
    _route: { name: "composer" }
  },
  Workspaces: {
    _route: { name: "workspace" },
    Create: {
      _route: { name: "create" }
    },
    List: {
      _route: { name: "list" }
    },
    Edit: {
      _route: { name: "edit" }
    },
    Home: {
      _route: { name: "home" }
    },
    Plugins: {
      _route: { name: "plugins" },
      Library: {
        _route: { name: "library" }
      },
      List: {
        _route: { name: "list" }
      },
      Detail: {
        _route: { name: "detail" }
      },
      Entities: EntityRouteList
    },
    Entities: EntityRouteList
  },
  Entities: EntityRouteList
}

export const linkRouteListItem = (routeListItem: RouteListItem, parentRouteListItem?: RouteListItem) => {

  //  Unpack
  const itemRoute = routeListItem._route;
  const parentRoute = parentRouteListItem ? parentRouteListItem._route : undefined;

  //  Attach the Parent
  itemRoute.parent = parentRoute;

  //  Initialize Children
  if (!itemRoute.children) { itemRoute.children = []; }

  //  Attach and Process the Children
  Object.keys(routeListItem)
    .filter(key => !key.startsWith('_'))
    .forEach(key => {
      const childRouteListItem = routeListItem[key] as RouteListItem;

      //  Attach the Child
      (itemRoute.children as Route<any>[]).push(childRouteListItem._route);

      //  Process the Child
      linkRouteListItem(childRouteListItem, routeListItem);
  }); 
};

export const linkRoutes = (routeList: RouteList) => {

  //  Links Parent and Child Routes from the RouteList
  Object.keys(routeList).forEach(key => {
    const routeListItem: RouteListItem = routeList[key];
    linkRouteListItem(routeListItem);
  })
}

linkRoutes(RouteList);

console.log(RouteList);

//  REALIZATION:  The React component tree is REALLY just a bunch of state and UI that renders conditional to that state... A ROUTE is a particular, GLOBAL pieces of state that determines what to render... INSTEAD of supporting explicit routes... MAYBE we should expose functions which can be used to change the state which changes the "Route"... for example, we'll still have an "EntityRouter", but it won't explicitly be called a "Router"... Then, we'll expose a set of App Actions which we can perform with a given context?? This might be calling an Action like "listInstances", given that we already have an entity selected.  OR, we can perform an action which brings us to that state... Like "selectEntity".  THEN, we have more Actions at our disposal?    MAYBE we can track the state of the state of the Application in this way?  Then, at any given line, we'll know what our Context looks like??  I DO suggest we do SOMETHING like this in Habor Custom UI.

//  With this approach, we will eventually be de-coupled from the Navigation implementation, with things like "history"... BUT, we're coupled with this NEW navigational interface.  One HUGE benefit is the intellisense / autocomplete!
