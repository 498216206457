import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { Text, View } from 'react-native';
import { Paragraph, ProgressBar } from 'react-native-paper';
import { AaroTheme, Extension } from '../aaro-core';
import { DashboardContext, DashboardPlugin } from './dashboard.extension';
import { HabitContext, HabitPlugin } from './habits.extension';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';

const progressPluginId = "progress";

export const ProgressExtension: Extension = {
  id: progressPluginId,
  name: "Progress",
  description: "Progress Tracking",
  color: '#6FE3D4',
  image: require("../../../assets/stickers/progress.png")
};

//  TODO:  Be able to extend ANY widget by its ID.  Different from DOM, because it's NON-DESTRUCTIVE?? HM !  Additive hm!

export interface IProgressContext {
  progress: number;
  total: number;
  completed: number;
  progressWidgetExtensions: WidgetExtension[];
  installProgressWidgetExtension: (ext: WidgetExtension) => void;
}

//  NOTE:  This should be injected as an HOC when we install the plugin.  Order is enforced through the Halia Plugins.
export const ProgressContext = React.createContext<IProgressContext>({ total: 0, completed: 0, progress: 0, progressWidgetExtensions: [], installProgressWidgetExtension: () => null });

//  Widget Modifier (Extension) - The idea is, each WIDGET can itself have modifiers which are JUST plugins which use functions exposed by a particular widget? Hmm... we MAY have some common ones, but we DON'T really want it to couple with the "DOM" too much.  This is why it's a "functional" interface instead of a document interfave hmmm...

//  DOM would be nice, but it doesn't seem like the total solution like... instead of that, I can build domain specific interfacves instead of at the UI level hmm... 

const ProgressWidget = () => {

  const { progress, progressWidgetExtensions } = React.useContext(ProgressContext);

  const animatedValue = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: `${animatedValue.value}%`,
    };
  });

  React.useEffect(() => {
    animatedValue.value = withTiming(progress, {
      duration: 500 // Adjust timing here
    });
  }, [progress]);

  //  TODO:  Show Grade, Score, Day - Day Improvement, etc hm!  
  //  TODO:  GAMIFY it with pretty stickers (give off fun vibes, seem COOL and the animations are cool hm!)  Also... think about those fundamtnals... hmm


  return (
    <View style={{ marginVertical: 15 }}>
      {progressWidgetExtensions.map(({ component: Comp }) => <Comp />)}
      <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 18 }}>Progress</Text>
      <View style={{ height: 15 }} />
      <ProgressBar useNativeDriver={ true } animatedValue={progress} style={{ height: 15, borderRadius: 10, backgroundColor: '#eeeeee' }} color={AaroTheme.primaryColor} />
      <View style={{ height: 15 }} />
      {/* REFERENCE:  https://stackoverflow.com/a/661569/3625866 */}
      <Paragraph style={{ fontFamily: 'Inter-SemiBold', fontSize: 12 }}>{Math.round(progress * 100)}% Complete</Paragraph>
    </View>
  );
}


export interface WidgetExtension {
  component: any;
  id: string;
  name: string;
  description;
}

export class ProgressPlugin extends CorePluginClass {

  // public ProgressContext = ProgressContext;

  public static details = {
    name: 'Progress',
    description: 'Progress Plugin',
    dependencies: [DashboardPlugin.details.id, HabitPlugin.details.id],
    id: progressPluginId,
    image: require("../../../assets/stickers/progress.png")
  }

  //  NOTE:  When we depend on things, we MUST be mounted below them to get their context.  So... we want to always mount as a child OR export our own mountpoint hmm...

  public install = (program: Program, { habit, dashboard }: { dashboard: DashboardPlugin, habit: HabitPlugin }) => {

    //  TODO:  This installation process should be part of the AaroPlugin
    //  NOTE:  It should ALL be done in the React Context!  THIS way, we can LISTEN to things.  ALSO... it's SUPER nice to be able to define a thing and have it AUTO-PLACE itself AND have it so it updates when deps change!!!  Plus... it CAN also render content, but the idea is the context tree is a bit separate from the main render tree hm!
    //  TODO:  This should also be using WEAVE a library built with Halia which lets users install / remove Plugins.  The trick is... we STILL install the pieces as Plugins to our core, and then EXTENSIONS are the things users enable / disable mm!!!  So they basically enable / dislable THAT plugin hmm... that makes sense I think.  So it's still the same thing.  So we want users to be able to manage the Halia Plugins mm!

    habit.registerHOC(({ children }) => {

      const { registerDashboardWidget } = React.useContext(DashboardContext);
      const { habits, observation } = React.useContext(HabitContext);

      const habitCount = habits?.length;

      const observationCount = observation ? observation.payload.value.habits.length : 0;

      const progress = observationCount / habitCount;

      const [progressWidgetExtensions, setProgressWidgetExtensions] = React.useState<WidgetExtension[]>([]);
      const installProgressWidgetExtension = (widgetExtension: WidgetExtension) => {
        const existing = progressWidgetExtensions.find(ext => ext.id === widgetExtension.id);
        if (!existing) {
          setProgressWidgetExtensions([...progressWidgetExtensions, widgetExtension]);
        }
      };

      React.useEffect(() => {
        registerDashboardWidget({
          name: "Progress Widget",
          description: "Progress Widget",
          pluginId: ProgressPlugin.details.id,
          component: ProgressWidget
        })
      }, []);
      return (
        <ProgressContext.Provider value={{ total: habitCount, completed: observationCount, progress, progressWidgetExtensions, installProgressWidgetExtension }}>
          {children}
        </ProgressContext.Provider>
      );
    });


    return this;
  }
}