/* tslint:disable */
/* eslint-disable */
/**
 * Cats example
 * The cats API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Mockup
 */
export interface Mockup {
    /**
     * 
     * @type {string}
     * @memberof Mockup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Mockup
     */
    workId: string;
    /**
     * 
     * @type {number}
     * @memberof Mockup
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof Mockup
     */
    variantId: number;
    /**
     * 
     * @type {string}
     * @memberof Mockup
     */
    tasks: string;
    /**
     * 
     * @type {string}
     * @memberof Mockup
     */
    mockups: string;
    /**
     * 
     * @type {string}
     * @memberof Mockup
     */
    owner: string;
}

export function MockupFromJSON(json: any): Mockup {
    return MockupFromJSONTyped(json, false);
}

export function MockupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mockup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'workId': json['workId'],
        'productId': json['productId'],
        'variantId': json['variantId'],
        'tasks': json['tasks'],
        'mockups': json['mockups'],
        'owner': json['owner'],
    };
}

export function MockupToJSON(value?: Mockup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'workId': value.workId,
        'productId': value.productId,
        'variantId': value.variantId,
        'tasks': value.tasks,
        'mockups': value.mockups,
        'owner': value.owner,
    };
}

