import { AnyBlock, BlockInst, Color, FrameworkContext, InstanceInternal } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, View, ViewStyle } from "react-native";
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../../../../packages/kelp-bar/styles';
import { HaborReactComponent } from '../../../habor-plugin/habor-utils';
import { TitleChiclet } from '../../../primitives-plugin/habor-components/embedded-views/chicklet-component';
import { SignalNodeMap } from '../data-flow';
import { resolveBlock } from '../models/blocks';
import { SelectedProperty } from '../models/signals';
import { BlockItem } from './block-item';
import { SignalSelector } from './signal-selector';

export interface BlockInstItemProps {
  blockInst: BlockInst;
  onPress?: () => void;
  signalNodeMap: SignalNodeMap;
  style?: ViewStyle;
  onDelete: (blockInst: BlockInst) => void;
  frameworkContext: FrameworkContext;
}
interface BlockInstItemState {
  selectInput: boolean;
  selectedProp?: SelectedProperty;
  internalBlock?: InstanceInternal<AnyBlock>;
}
class BlockInstItemBase extends HaborReactComponent<BlockInstItemProps, BlockInstItemState> {
  constructor(props: BlockInstItemProps) {
    super(props);
    this.state = {
      selectInput: false
    }
  }

  public openSignalConnector = (selectedProp: SelectedProperty) => {
    this.setState({ selectInput: true, selectedProp });
  }

  public closeSignalConnector = () => {
    this.setState({ selectInput: false });
  }

  public componentDidMount = async () => {

    const { frameworkContext: { token } } = this.context;
    const { blockInst } = this.props;

    //  Get the Block
    const block = await resolveBlock(token, blockInst.blockId);
    this.setState({ internalBlock: block });

  }

  public render = () => {

    

    //  Unpack
    const { selectInput, selectedProp, internalBlock } = this.state;
    const { blockInst, onPress, signalNodeMap, style, frameworkContext } = this.props;
  
    if (!internalBlock) { return <Text>Loading Block...</Text> }
    const block = internalBlock.payload;

    //  Check Selected Input
    if (selectInput && selectedProp) {
      return <SignalSelector frameworkContext={ frameworkContext } close={ this.closeSignalConnector } selectedProp={ selectedProp } signalNodeMap={ signalNodeMap } />;
    }

    return (
      <BlockItem style={ style } onPress={ onPress } block={ internalBlock }>
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>EXISTING</Text>
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }} >INPUT</Text>
        {

          //  Create Input Chiclets
          block.inputSchema && block.inputSchema.properties ?
            Object.keys(block.inputSchema.properties).map(propName => {
              const valueReference = blockInst.input[propName];
              return (
                <View>
                  <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }}>{ propName }</Text>
                  {
                    valueReference ?
                      (
                        valueReference.type == "signal" ?
                          <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: true, propName }) } style={{ backgroundColor: Color.good }} title={ valueReference.signalId } /> :
                          <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: true, propName }) } style={{ backgroundColor: Color.primary }} title={ 'constant' } /> 
                      ) :
                      <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: true, propName }) } style={{ backgroundColor: Color.medGray }} title={ 'N/A' } /> 
                  }
                </View>
              )
            }) : <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }} >No Input Schema</Text>
        }
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }} >OUTPUT</Text>
        {

          //  Create Output Chiclets
          block.outputSchema && block.outputSchema.properties ?
            Object.keys(block.outputSchema.properties).map(propName => {
              const output = blockInst?.output;
              if (!output) { console.warn("No output - Block Inst Item!"); return;  }
              const valueReference = output[propName];
              return (
                <View>
                  <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }}>{ propName }</Text>
                  {
                    valueReference ?
                      (
                        valueReference.type == "signal" ?
                          <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: false, propName }) } style={{ backgroundColor: Color.good }} title={ valueReference.signalId } /> :
                          <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: false, propName }) } style={{ backgroundColor: Color.primary }} title={ 'constant' } /> 
                      ) :
                      <TitleChiclet onPress={ () => this.openSignalConnector({ blockInst, input: false, propName }) } style={{ backgroundColor: Color.medGray }} title={ 'N/A' } /> 
                  }
                </View>
              )
            }) : <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }} >No Output Schema</Text>
        }
        <Button title="Delete" onPress={ () => this.props.onDelete(blockInst) } />
      </BlockItem>
    );
  }
}

export const BlockInstItem = BlockInstItemBase