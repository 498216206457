//  TODO:  Split out the stuff that depend on React!!
import * as React from 'react';

//  TODO:  MAYBE Innstea of just "Colors" make THEMES.  The idea is, it's up to the APP developer to define constants which can be use in the app, AND not just CSS settings!!!  ANY settings can be pulled from the "Theme"!  SO, it CAN be more than just style!  It's a TOUCHPOINT or EXPRESSION to be interpreted to control the app.  Ah!!! It's a NEW abstraction!  WHy???  Becuase it's a new LANGAGE.  It's a set of SYMBOLS and ASSOCIATIONS that are to be INTERPRETED and responded to!  MAYBE though, each component can define its OWN "Theme Abstraction" which is SPECIFICALLY for THIS "Theme" system, and it's up to the component what OVERRIDES it wants to permit from OTHER systems.  I think it would be cool if we could inject this to exising components instead of coupling.. hm!  I think we CAN by wrapping their defualt Prop interface.  Then, the idea is we can assign those props from the "theme" at runtime? HM!  FOR NOW, let's keep it simple.

const Color = require("color");


//  The STYLE system can then register as a stakeholder and COUPLED system the "Header" component for example.

interface System {
  name: string;
  getProps: (compName: string) => any
}

const systems: System[] = [];


//  FOR NOW:  Let's just make the theme STATIC, but I DO want to enabe additional sysem in the future!  
//  TODO:  We have a GENRRC way to control props now, BUT I may ALSO want to NAMESPACE to the multiple pieces that inject THEME, so I KNOW to bundle them togetherr as a UNIFORM system!!!

//  THOUGHT:  NOT crazy about the way React is coupled with the view.   It's a DI system hmm... THey're JUST encodings ... functions specifically, that we use to develope an "element" which is a thing that's going to be interpreted by the interpreter... it'll map it to hmm... but like.. why do we need to "produce" anything?  That idea of "proucing" something doesn't have to be couple with the React DI system hm... 
//  NOTE:  THe idea is, we're injecting a "class" of properties... it's the same as making a set of encodings hmm... the KEY is we're in an interpretation context to start with.  Now the idea is, we're mapping with these hmm... 
//  POint is... withinjections is a way to COUPLE two things.  The first is the Component (which is a function) with another encoding (the properties, which can be *any* JS Object... which basically means a "blank" encoding).  But, the point is, each of these "properties" has a "NAME" which, is itself an encoding which is associated with each property.  So, when we inject props, we're COUPLING the funciton with these named encodings.  That means, that the function is presumed to USE that mapping.  Now... what's interesting is, we COULD have a plugin to both do the prop injection AND do the internal mapping.  Interestingly, this can be SEPPARATE from the "function".  Essentially it's an encoding which will be interpreted to update that encodin hmm... so, insted of injecting props directly, we can inject them well... we CAN have a "framework" that we can use to do the injection using an IMPERATIVE API so we can inject an injeciton lol... Interesting... basically an "injection" just means link something to an existin encoding hmmm... So, in this case, we want to do what?  
//  I'm NOT sure I'm a big fan of these explicit injections.  Instead... we can perhaps have an API to "build" a component, and one thing is the HOOKS.   Which can give us that stuff... hmm... we CAN have it put in props, but hmm... if we're INJECTING something like this, that means the component is expected to INTERPRET them.  Ah!  That means.. if we're pulling from VARIOUS "systems" then the comonent is expected to MAP from those.  Instead, I think we can keep it simpler and each compoennt is just its own thing hm!  AH!!  BUT... the point is the MAPPER does that for us!  THIS way, we have SYSTEMS that we MAP into the componen thm!  THIS way we can have MULTIPLE systems hmmmm... then the componen just has its OWN "API"... instead, what if EVERY component were 100% accessible.  Meaning, LIKE THE DOM, we can affect the props sent to individual thjings... this way we can do thigsn like view1.view1.etc... style={etc..}  so we can set style on a particular inner thing?  This is the CSS-like thing and I LIKE it.  Maybe we can do this with props?  As long as we have a standard mapping? Hmm... the beauty o the way it is NOW though, is that each compoennt CHOOSES... the PROBLEM is that... if we WANT it to be fully available for injection it's NOT easy hmml... how can we DEFAULT to EVERYTHING being avilable instead of what we have now which is nothing??  HM!  Maybe... As for the mapper thing.  Instead of registering systems separatly with that ONE system and doing injectsions, we CAN do each system separately hmm.. it's up to us though.
export const withInjections = (Component: any, name: string) => {
  return (props: any) => <Component { ...getProps(name) } { ...props } />
}

interface ThemePart {}


export interface HessiaThemePart extends ThemePart {
  background: string,
  color: string
}

const themeParts: { [partName: string]: ThemePart } = {};
const registerThemePart = (partName: string, part: ThemePart) => {
  themeParts[partName] = part;
}

export const HessiaThemePart: HessiaThemePart = {
  background: "white",
  color: "#333333"
}
//  REALIZATION!!!  h!  A THEME may be a SELECTIOn of Theme Parts, which are JUST theme SYSTEMS? HM!  MAYBE we just rremove that theme / style abstraction and use the injector directly?  Hmmm BUT I like the idea of SUB-PLUGINS and systems and stuff!!!
//  NOTE:  I COULD use a VERY general theme setting like "Clay", etc. AND perhaps combine this with the other options?? Hmmmmm... interesting.  I THINK if I were to do that, I'd wnt to have THEME dependencies? Hmmm  This way it's clear what's compatible and what's not??? Hm!
const buildHessiaThemePart = (): HessiaThemePart => {
  return HessiaThemePart
  // return {
  //   background: Color("#f52c78").alpha(0.93),
  //   color: "white"
  // }
}

//  NOTE:  The later registations get prioity.
registerThemePart("hessia", buildHessiaThemePart())

  //  Here we build the theme based on the enabled systems.  The API is NOT static and depends on the components via coupling systems!  The theme is actually SET by a theme definition, which is held throughout the various systems respondible for defining the theme!


type ThemeMapper<T extends ThemePart> = (part: T) => any;

const partMappings: { [compName: string]: { mapper: ThemeMapper<any>, partName: string }[] } = {};

export function registerThemeMapper<P extends ThemePart> (compName: string, mapper: ThemeMapper<any>, partName: string) {
  if (!partMappings[compName]) { partMappings[compName] = [] }
  const mappings = partMappings[compName];
  mappings.push({ mapper , partName });
}

const resolveStyle = (compName) => {
  const mappings = partMappings[compName];
  const styles = mappings.map(partMapping => partMapping.mapper(themeParts[partMapping.partName]));
  return styles.reduce((prev, curr) => ({ ...prev, ...curr }));
}

//  Return the Systems
const getSystems = () => {
  return systems;
}




//  FOR NOW, the idea is, the THEME is unaware of the specific compnetns, ND the compnents re unawre of the theme.  We use FUNCTIONS to map between them.  AND we can do so with a DECLARATIVE, interpreted mapping if we WANT< but that has LESS control thn a plain funnction!  BUT, what if we want to ADD a part to our theme??  Well, then we extend the THEME API, but DON'T require a coupling?? HM!  I think this is OK for now?  BUT... like.. maybe the user registers some component which has a style coupling system which reqirres theme elements which don't exist? Ah!!  This is where we'd DEPEND upon those theme elements, which ADD those pieces to that absraction!!  PERFECT!  So, PERHAPS even in the CODE we want an absrraction like this? THe idea of DEPENDENCIES between systems?? HM!!!  COUPLING systems!

//  NOTE:  We do this to DE-COUPLED the component style and the theme? HM!  Again, this is just ONE sstem.  But it COULD be used for other stuff too??  It's a conceptual partition???
const getComponentStyle = (compName: string) => {

  //  Use Our Theme System
  //  NOTE:  HERE we can iterrate our STYLE system plugins, which can inject themselves as having a stake in THIS regard..  BUT are we doing this TOO explicitly in code?   Hm!  MAYBE we can build all this with runtie registes and stuff?? HMM
  //  FOR NOW:  Let's end the abstraction and hard-code the Theme -> Style Prop mapping here!
  //  TODO:  PERRHAPS this COULD act as ANOTHER abstraction point for OTHER "style" systems? HM!
  const compStyle = resolveStyle(compName);
  return compStyle;
  
}

systems.push({
  name: "style",
  //  TODO:  Maybe this should be a hook or somethign?  It needs to get access to relevant context!
  getProps: (compName: string) => {
    return getComponentStyle(compName);
  }
})

//  We detemine stakeholderrs by lettng ECH system rrun to see if it has a stake in a THING.  NOTE!!!  FOR NOW, we're talking about COMPONENTS, but this cann be genealzied!
//  Get the Stakeholders for the current context / component

//  This function is used to determine component props based on the current context.
//  NOTE:  It's up to EACH funnction to pull out the relevant context.
//  TODO: MAYBE part of the context can be the components CURRENT props!
//  TODO:  What if we can control LAYOUT too!?  THEN, A PROGRAM is view via multiple CONTEXT slices!  This is how we program LINEARLY? HM!  This keeps things orgnized aqnd we know it's copmlete when we've iterscted the full partition space? Hmmmmm
//  IDEA:  COnsider this as a decorator!!! Hm!  Or a HOC or something? Hm!
export const getProps = (compName: string) => {

  const systems = getSystems();
  const props = systems.map((system) => {
    return system.getProps(compName);
  });
  return props.reduce((prev, curr, i) => ({ ...prev, ...curr }));
}

