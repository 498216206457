
import * as React from 'react';
import { View, ViewStyle } from 'react-native';
import { defaultBorderRadius, Paragraph, SuperHeader } from '../constants';
import { useSizes } from '../sizes-helper';

export const HeroCard = ({ title, subTitle, children, style }: { style?: ViewStyle, title: string, subTitle?: string, children?: React.ReactNode }) => {

  const { isDesktop } = useSizes();

  return (
    <View style={[{ height: isDesktop ? 260 : 150, borderRadius: defaultBorderRadius, width: '100%', overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }, style]}>

      <SuperHeader>{title}</SuperHeader>
      <View style={{ height: 10 }} />
      <Paragraph>{subTitle}</Paragraph>

      {
        children
      }
      {/* <Layer>
        <Image resizeMode='cover' style={{ width: '100%', height: '100%', tintColor: '#ff4083', opacity: 0.05 }} source={require("../assets/patterns/Whangaehu.png")} />
      </Layer> */}

    </View>
  );
}
