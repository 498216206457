import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import { TrackerListHaborComponent, TrackerListHaborPrimitive } from "./tracker-list-page";

//  CONSIDER:  Remove the "Tracking" primitive Plugin in favor of a Block based Plugin.

export class TrackingPlugin extends CorePluginClass {
  public static details = {
    name: "Tracking",
    description: "Tracking Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "tracking"
  }

  public install = (program: Program, { hessia }: { hessia: HessiaPlugin }) => {

    hessia.registerPrimitiveComponent('TrackerListHaborPrimitive', TrackerListHaborPrimitive);
    hessia.registerComponent(TrackerListHaborComponent);
  }
}
