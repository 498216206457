import { HaborComponent, RGBAColor } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { ColorDot, ColorField } from '../../../../packages/davel-ui/habor-types/color/color-field';
import { WrappedFieldProps } from 'redux-form';

//
//  Color Habor Component
//

export interface ColorHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    value?: RGBAColor;
  };
}

export const ColorTypeHaborPrimitive = ({ userProps, frameworkProps }: ColorHaborPrimitiveProps) => {
  const { value } = userProps;
  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <ColorDot { ...userProps } color={ value } />
    </HaborContainer>
  );
};

//  TODO-IMPORTANT:  The Components which are used to render a TYPE may be part of a special class of components?  MAYBE they have certain required fields like "Value"?
export const ColorTypeHaborComponent: HaborComponent = {
  name: "ColorTypeHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "ColorTypeHaborPrimitive",
    props: {
      value: { type: "symbol", scopePath: ["props", "value"] }
    },
    children: []
  }
};


//
//  ColorSelectorField Habor Component
//

export interface ColorSelectorFieldHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    value: RGBAColor;
    update: (value: RGBAColor) => void;
  }
}

export const ColorSelectorFieldHaborPrimitive = ({ userProps, frameworkProps }: ColorSelectorFieldHaborPrimitiveProps) => {
  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <ColorField { ...userProps } />
    </HaborContainer>
  );
};

export const ColorSelectorFieldHaborComponent: HaborComponent = {
  name: "ColorSelectorFieldHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "ColorSelectorFieldHaborPrimitive",

    //  TODO:  SHOULD we be passing low level things like ReduxForm props in our HaborComponents??  It PROBABLY makes sense to abstract this away so we're not tied to their contract!
    props: {
      value: { type: "symbol", scopePath: ["props", "value"] },
      update: { type: "symbol", scopePath: ["props", "update"] },
      meta: { type: "symbol", scopePath: ["props", "meta"] }
    },
    children: []
  }
};
