import { LinearGradient } from 'expo-linear-gradient';
import { HaborIconSelection } from 'habor-sdk';
import * as React from 'react';
import { TouchableOpacity, ViewStyle } from "react-native";
import { Icon } from 'react-native-elements';
import { CircleFilled, CircleOpen, CircleProps } from './circles';

//
//  RoundButton
//

export interface ButtonRoundProps extends CircleProps {
  onPress: () => void;
}

export const ButtonRoundFilled = (props: any) => (
  <CircleFilled { ...props }>
    <TouchableOpacity onPress={ props.onPress } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      { props.children }
    </TouchableOpacity>
  </CircleFilled>
);

export const ButtonRoundOpen = (props: any) => (
  <CircleOpen { ...props }>
    <TouchableOpacity onPress={ props.onPress } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      { props.children }
    </TouchableOpacity>
  </CircleOpen>
);

//  TODO:  Move this to a presentaion file!
export enum RoundIconButtonSize {
  Small, Medium
}
// TODO:  Support gradient
export const RoundIconButton = ({ size = RoundIconButtonSize.Small, style, onPress, iconSelection, iconColor = "white", backgroundColor = "#eeeeee" }: { size?: RoundIconButtonSize, style?: ViewStyle, onPress?: () => void, iconSelection: HaborIconSelection, iconColor?: string, backgroundColor?: string }) => {

  const sizes = {
    [RoundIconButtonSize.Small]: { boxWidth: 32, iconSize: 16 },
    [RoundIconButtonSize.Medium]: { boxWidth: 40, iconSize: 20 }
  }
  return (
    <TouchableOpacity style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', ...style }} onPress={ onPress }>
      <LinearGradient style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 32, width: 32, borderRadius: 16 }} colors={[ backgroundColor, backgroundColor ]}>
        <Icon type={ iconSelection.type } name={ iconSelection.name } color={ iconColor } size={ 16 } />
      </LinearGradient>
    </TouchableOpacity>
  );
}