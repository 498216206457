import { HaborComponent, NounId, nounIdSchema } from 'habor-sdk';
import * as React from 'react';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { haborSDK } from '../../hessia-plugin/config';
import { AppEvent } from '../../workspace-plugin/workspace-settings';
import { EntityEditor } from '../entity/entity-editor';
import { HaborModelSDK } from '../model-sdk';


//  CONSIDER:  Separate the Framework Props, App Props, User Props, etc... SOMETIMES, one may be able to override the other.  Consider making a more explicit prop hierarchy with inheritance?
//  TODO:  Consider separating "Page" from "Component".  A "Page" comes with some special "PageProps".


//
//  EntityEditor Primitive Component
//

export interface EntityEditorHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    nounId: NounId;
  }
}
export const EntityEditorHaborPrimitive = ({ userProps, frameworkProps }: EntityEditorHaborPrimitiveProps) => {

  //  Unpack
  const { context: { token, user, workspace, changePage, appEmitter } } = frameworkProps;

  if (!workspace) { throw new Error("Cannot render the 'EntityCreator' Page with an undefined 'workspace'.") }
  
  const handleHeaderPress = () => {
    alert("Pressed Save");
  };

  React.useEffect(() => {
    appEmitter.addListener(AppEvent.HeaderButtonPressed, handleHeaderPress);
    return () => {
      appEmitter.removeListener(AppEvent.HeaderButtonPressed, handleHeaderPress);

    }
  }, [])

  const modelSDK = new HaborModelSDK(haborSDK, token);

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
      <EntityEditor { ...userProps } modelSDK={ modelSDK } />
    </HaborContainer>
  );
};


//
//  EntityEditor Habor Component
//

export const EntityEditorHaborComponent: HaborComponent = {
  name: "EntityEditorHaborComponent",
  propsSchema: {
    type: "object", 
    extensible: false, 
    properties: { 
      nounId: { ...nounIdSchema, required: false },
    } 
  },
  element: {
    name: "EntityEditorHaborPrimitive",
    props: {
      nounId: { type: "symbol", scopePath: ["props", "nounId"] },
    },
    children: []
  }
};
