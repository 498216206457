
//  TODO:  Recognize that this is BASICALLY a tagging system.  So instead of having JUST "Types" we want to be able to have Tag Sets where we have a GROUP of tags... this is BASICALLY a "Category" system, and we can make categories and put things inside them.  That's essentailly all it is.  INCLUDING categories themselves.  But, they can also be IMPLICIT.
//  CONSIDER:  An "instance" is a member of the SET for its type.  I'm not 100% sure why the triple is so useful, but it's a way to indicate that a thing is associated with something else.

//  Type System: "Instance Of" Relationship
//    *  Build an "Instance Of" Node
//      -  Models the "Instance of" Predicate
//    *  Given an entity, show:
//      -  Instances:  Show our instances (who is an instance of us)
//      -  Types:  Show our Types (what we are an instance of)

//  CONSIDER:  Type System Metamodel:
//    *  Build a Type Node
//      -  Model the class of "types".
//      -  Instance of itself
//    *  Build a "Predicate" Node
//      -  Models the class of "Edge Types" or "Predicates"
//      -  Instance of "Type".
//    *  Mark "Instance of" as a Predicate
//
//  NOTE:  While it might seem true that if something has an instance then it must also be a "type" in some sense, we don't MANDATE that it be expliclty modeled, and it may be contextual (one person thinks X and another thinks Y), or it could be a joke and encoded as a fragment from which IMAGINARY and FUN IMPLICATIONS / INFERENCES can be made!  I LOVE this idea of playground fragments!
//
//  CONSIDER:  Subtype System:  "Type Of" Relationship.
//    *  Given an entity, show:
//      -  Super Types:  Show types that we are a sub-type of.
//      -  Sub Types:  Show types that are a sub-type of us.
//

import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { CorePluginClass } from "halia";
import React from "react";
import { Modal, ScrollView, Text, View } from "react-native";
import { IconButton } from "react-native-paper";
import { GroupCard } from "../../packages/kelp-bar/group-card";
import { SystemHeader } from "../../packages/kelp-bar/system-header";
import { NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { Edge, PropertyPlugin, ExistingEdgeError } from "./property-plugin";
import { Entity2Plugin, EntityContext, EntityEditor, EntityTable, PersonalSystem } from "./entity-plugin";
import { Entity } from "./entity-service";
import { Hessia2Context, Hessia2Plugin, System } from "./hessia2-plugin";
const uuid = require('uuid/v4');

interface TypeContext {
  selectedType?: NounServiceInstanceInternal<Entity>;
  selectType: (entity: NounServiceInstanceInternal<Entity>) => void;
  types: NounServiceInstanceInternal<Entity>[];
  loadTypes: () => void;
}

export const INSTANCEOF_NODE_ID = "instanceOf";

//  
//  Type Errors
//

export class ExistingTypeError extends Error { }
export class MissingTypeError extends Error { }
export class MultipleTypesError extends Error { }
export class ExistingInstanceError extends Error { }
export class MissingInstanceError extends Error { }
export class MultipleInstancesError extends Error { }

//
//  Class Context
//

export const ClassPluginContext = React.createContext<PatternPlugin | undefined>(undefined);
export const ClassContext = React.createContext<TypeContext | undefined>(undefined);

/**
 * Component to select a "Type"
 */
export const TypeSelector = ({ entity }: { entity: Entity }) => {

  const entityPlugin = React.useContext(EntityContext);
  const [types, setTypes] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

  const loadTypes = async () => {
    const _types = await entityPlugin.entities;
    if (_types) {
      setTypes(_types);
    }
  }

  React.useEffect(() => {
    loadTypes();
  }, [])

  return (
    <View>
      {
        types.map(type => <Text style={{ padding: 20, backgroundColor: '#eeeeee', borderRadius: 5, margin: 5 }}>{type.payload.name}</Text>)
      }
    </View>
  );
}

const TypeStack = createStackNavigator();

const TypeHomeRoute = "Classes";
const TypeEditorRoute = "Class Editor";

//  CONSIDER:  This is just a SET.  It's when we have a "Type" which is a SET, and then members in that set.  It's explicit here though.  Instead of having set as an explicit primitive, it's something we can add to ANY entity.  In other words, any entity can be a Set / Type / Type.  It seems SET is perhaps MOST appropriate for this pattern, because it's less specific than a Type or a Type... but at the SAME time, we might have a Type Set or a Type Set.  But, Type is general in another sense.  Trick is... we need to be able to make a Set of Sets if we do that.  Types is itself a set, and each Type is a set.  How can we enforce for each element?  Ugh... more generally, ANYTHING associated with something else is part of the set of things associated.. more generally, anything meeting a condition etc... 

export const TypeExplorer = () => {


  const typePlugin = React.useContext(ClassPluginContext);

  const typeContext = React.useContext(ClassContext);
  const entityContext = React.useContext(EntityContext);
  const { types = [] } = typeContext || {};

  // const createType = async () => {
  //   const id = uuid();
  //   const internal = await typePlugin?.createType({ id, exported: true, owners: [PersonalSystem, Type2System] }, [PersonalSystem]);
  //   if (!internal) { alert("Failed to create the type"); return; }
  //   await entityContext?.loadEntities();
  //   await typeContext?.loadTypes();
  //   typeContext?.selectType(internal);
  //   entityContext?.selectEntity(internal);
  // }

  const navigation = useNavigation();

  return (
    <>
      <SystemHeader system={Type2System} />
      <EntityTable entities={types} onPress={(entity) => { entityContext?.selectEntity(entity); navigation.navigate(TypeEditorRoute as never) }} />
    </>
  );

}

const TypeApp = () => {

  return (
    <TypeStack.Navigator initialRouteName={TypeHomeRoute} screenOptions={{ headerShown: false }}>
      <TypeStack.Screen name={TypeHomeRoute} component={TypeExplorer} />
      <TypeStack.Screen name={TypeEditorRoute} component={EntityEditor} />
    </TypeStack.Navigator>
  );
}

const Type2System: System = {
  id: "pattern",
  color: "#aaaaaa",
  pinned: true,
  priority: 3,
  //  TODO:  2nd Order Tyeps - Centralize to the single TYPES system!  It should support arranging over ALL systems!
  name: "Classes",
  description: "Type Management",
  emoji: "🔤",
  component: TypeApp,
  icon: { name: "book", type: "feather" },
  primaryColor: "#007bff",
  backgroundColor: "#f5faff"
}

export class PatternPlugin extends CorePluginClass {

  public static details = {
    name: "Type System",
    description: "Type 2 Core Plugin",
    dependencies: [Hessia2Plugin.details.id, PropertyPlugin.details.id, Entity2Plugin.details.id],
    id: "pattern"
  }

  public instanceOfNodeId = INSTANCEOF_NODE_ID;

  public hessia2Plugin!: Hessia2Plugin;
  public entity2Plugin!: Entity2Plugin;
  public graph2Plugin!: PropertyPlugin;

  /**
   * Retrieve all Edges with an "InstanceOf" Predicate
   * TODO: [OPTIMIZATION]  Should not need to query ALL entities for these methods.
   * @returns 
   */
  public getInstanceOfEdges = async (): Promise<NounServiceInstanceInternal<Entity<Edge>>[]> => {
    const allEdges = await this.graph2Plugin.getEdges();
    const instanceOfEdges = this.graph2Plugin.filterByPredicate(allEdges, this.instanceOfNodeId);
    return instanceOfEdges;
  }

  /**
   * Retrieves all "Types" assigned to the given entity
   * @param entity 
   * @returns
   */
  public getTypesForEntity = async (entity: Entity): Promise<NounServiceInstanceInternal<Entity>[]> => {
    const allEntities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
    const allInstanceEdges = await this.getInstanceOfEdges();
    const entityOutgoingInstanceEdges = allInstanceEdges.filter(edge => edge.payload.value?.sub === entity.id);
    const entityTypeIds = entityOutgoingInstanceEdges.map(edge => edge.payload.value?.obj);
    const entityTypes = allEntities.filter(entity => entityTypeIds.includes(entity.payload.id));
    return entityTypes;
  }

  /** 
   * Retrieves all "Instances" assigned to the given entity
   * @param entity 
   * @returns
   */
  public getInstancesForEntity = async (entityId: string): Promise<NounServiceInstanceInternal<Entity>[]> => {
    const edges = await this.getInstanceOfEdges();
    const entityEdges = this.graph2Plugin.filterByObject(edges, entityId);
    const instances = await this.graph2Plugin.getSubjects(entityEdges);
    return instances;
  }

  /**
   * Retrieves all entities which are being used as "Types".
   * @returns NounServiceInstanceInternal<Entity>[]
   */
  public getAllTypes = async (): Promise<NounServiceInstanceInternal<Entity>[]> => {
    const instanceOfEdges = await this.getInstanceOfEdges();
    const types = await this.graph2Plugin.getObjects(instanceOfEdges);
    return types;
  }


  /**
   * Retrieves all entities which are being used as "Instances".
   * @returns NounServiceInstanceInternal<Entity>[]
   */
  // public getAllInstances = async (): Promise<NounServiceInstanceInternal<Entity>[]> => {
  //   const allEntities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
  //   const typeEdges = await this.getInstanceAssignments();
  //   const typeIds: string[] = [];
  //   typeEdges.forEach(({ payload: { value } }) => {
  //     if (!value) { return; }
  //     typeIds.push(value.sub);
  //   });
  //   const allInstances = allEntities.filter(entity => typeIds.includes(entity.payload.id));
  //   return allInstances;
  // }

  // public getInstances = async (entity: Entity): Promise<NounServiceInstanceInternal<Entity>[]> => {
  //   const allEntities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
  //   const typeAssignments = await this.getSubtypeEdges();
  //   const instanceIds = typeAssignments.filter(assignment => (assignment.srcId === entity.id) && !!assignment.instance).map(edge => edge.destId);
  //   return allEntities.filter(entity => instanceIds.includes(entity.payload.id));
  // }
  // public getInstancesFromTypeId = async (typeId: string): Promise<NounServiceInstanceInternal<Entity>[]> => {
  //   const allEntities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
  //   const typeAssignments = await this.getSubtypeEdges();
  //   const instanceIds = typeAssignments.filter(assignment => (assignment.srcId === typeId) && !!assignment.instance).map(assignment => assignment.destId);
  //   return allEntities.filter(entity => instanceIds.includes(entity.payload.id));
  // }

  // public getTypesFromTypeId = async (typeId: string): Promise<NounServiceInstanceInternal<Entity>[]> => {
  //   const allEntities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
  //   const typeAssignments = await this.getSubtypeEdges();
  //   const instanceIds = typeAssignments.filter(assignment => (assignment.srcId === typeId) && !assignment.instance).map(assignment => assignment.destId);
  //   return allEntities.filter(entity => instanceIds.includes(entity.payload.id));
  // }

  // public registerType = async (typeId: string, owners: System2[], entityId?: string) => {
  //   return await this.registerSubtype(typeId, NODETYPE_NODE_ID, owners, entityId);
  // }

  // /**
  //  * Registering a type creates a new node and connects it to the "Type" node.
  //  * @param node 
  //  */
  // public createType = async (entity: Entity, owners: System2[]) => {

  //   //  Check Existing
  //   const existing = await this.entity2Plugin.entityService.getEntityById(entity.id);
  //   if (existing) { throw new ExistingTypeError() }

  //   //  Create the Type Node
  //   const typeEntity = await this.entity2Plugin.entityService.entityNounService.create(entity);

  //   //  Register the Type
  //   await this.registerType(entity.id, owners);

  //   return typeEntity;
  // }

  // /**
  //  * Registering a type creates a new node and connects it to the "Type" node (if needed)
  //  * @param node 
  //  */
  // public createTypeIfNeeded = async (entity: Entity, owners: System2[]) => {

  //   try {
  //     await this.createType(entity, owners);
  //   } catch (err) {
  //     if (err instanceof ExistingTypeError) { return; }
  //     throw err;
  //   }
  // }

  // public registerInstance = async (instId: string, typeId: string, owners: System2[], assignmentId?: string) => {
  //   return await this.registerAssignment({ srcId: typeId, destId: instId, instance: true }, owners, assignmentId);
  // }

  // public registerSubtype = async (subTypeId: string, parentTypeId: string, owners: System2[], assignmentId?: string) => {
  //   return await this.registerAssignment({ srcId: parentTypeId, destId: subTypeId, instance: false }, owners, assignmentId);
  // }

  /**
   * Creates a new "Instance Of" Edge from { instanceId } to { typeId }
   * 
   * @param instanceId 
   * @param typeId 
   * @param owners 
   * @param edgeId 
   */
  public registerInstance = async (instanceId: string, typeId: string, owners: System[], edgeId?: string) => {

    const entityPlugin = this.entity2Plugin;

    //  Get the Type Node
    const typeNode = await entityPlugin.entityService.getEntityById(typeId);
    if (!typeNode) { alert("Missing Type Error"); throw new MissingTypeError(); }

    //  Get the Instance Node
    const instanceNode = await entityPlugin.entityService.getEntityById(instanceId);
    if (!instanceNode) { alert("Missing Instance Error"); throw new MissingInstanceError(); }

    //  Check Existing
    if (edgeId) {
      const existingEdge = await this.entity2Plugin.entityService.getEntityById(edgeId);
      if (existingEdge) { alert("Existing Edge Error"); throw new ExistingEdgeError() }
    }

    //  Create the Edge
    await this.graph2Plugin.createEdge({ sub: instanceNode.id, pred: INSTANCEOF_NODE_ID, obj: typeNode.id }, true, [Type2System, ...owners]);
  }

  //  TODO:  Support MULTIPLE types.  This is just a way to allow multiple assignments.  This involves DISPLAYING multiple as well.  Currenlty doesn't work. 
  // public removeInstance = async (instanceId, typeId, id?: string) => {
  //   const typeAssignments = await this.getTypeAssignmentEntities();
  //   const assignment = typeAssignments.find(entity => {
  //     const assignment: TypeAssignment = (entity.payload.data!)[Type2Plugin.details.id];
  //     return (assignment.srcId === typeId) && (assignment.destId === instanceId) && (!!assignment.instance);
  //   });
  //   if (!assignment) { throw new MissingTypeError() }
  //   await this.entity2Plugin.entityService.entityNounService.delete(assignment.id);
  // }

  public install = async (program: any, { hessia2, graph2, entity2 }: { hessia2: Hessia2Plugin, graph2: PropertyPlugin, entity2: Entity2Plugin }) => {

    try {
      this.hessia2Plugin = hessia2;
      this.entity2Plugin = entity2;
      this.graph2Plugin = graph2;

      //
      //  Type System
      //

      //  Install the "Instance Of" Predicate Node (if needed)
      const existingInstanceOf = await this.entity2Plugin.entityService.getEntityById(INSTANCEOF_NODE_ID);
      if (!existingInstanceOf) {
        await this.graph2Plugin.createPredicate({ exported: true, id: INSTANCEOF_NODE_ID, name: "Instance Of", description: "A Predicate used to mark a Subject as an 'Instance' of the Object.", owners: [Type2System] })
      }

      //
      //  Type System Meta-Model
      //

      // //  Install the Type Node if Needed
      // const existingType = await this.entity2Plugin.entityService.getEntityById(NODETYPE_NODE_ID);
      // if (!existingType) {

      //   //  Install the "Type" Node
      //   await this.entity2Plugin.entityService.createEntity({ exported: true, id: NODETYPE_NODE_ID, name: "Type Type", description: "Type Node", owners: [Type2System] });

      //   //  Install the "Type" Assignment
      //   await this.registerSubtype(NODETYPE_NODE_ID, NODETYPE_NODE_ID, []);
      //   await this.registerInstance(NODETYPE_NODE_ID, NODETYPE_NODE_ID, []);
      // }

      //  Install the Edge Type (Predicate) Node if Needed
      // const existingPredicateType = await this.entity2Plugin.entityService.getEntityById(PREDICATETYPE_NODE_ID);
      // if (!existingPredicateType) {

      //   //  Install the "Predicate" Node
      //   await this.entity2Plugin.entityService.createEntity({ exported: true, id: PREDICATETYPE_NODE_ID, name: "Predicate", description: "Predicate Type", owners: [Type2System] });

      //   //  Install the Instance and Subtype Predicates
      //   await this.entity2Plugin.entityService.createEntity({ exported: true, id: INSTANCEOF_NODE_ID, name: "Instance Of", description: "A Predicate used to mark a Subject as an 'Instance' of the Object.", owners: [Type2System] });
      //   await this.entity2Plugin.entityService.createEntity({ exported: true, id: SUBTYPEOF_NODE_ID, name: "Subtype Of", description: "A Predicate used to mark a Subject as a 'Subtype' of the Object.", owners: [Type2System] });

      //   //  Install Type Relationships
      //   await this.registerSubtype(PREDICATETYPE_NODE_ID, NODETYPE_NODE_ID, []);
      //   await this.registerSubtype(INSTANCEOF_NODE_ID, PREDICATETYPE_NODE_ID, []);
      //   await this.registerSubtype(SUBTYPEOF_NODE_ID, PREDICATETYPE_NODE_ID, []);
      // }

      //  Register the Entity Extension
      this.entity2Plugin.registerEntityExtension({
        systemId: Type2System.id,
        icon: Type2System.icon,
        id: "type-entity-extension",
        name: "Type",
        description: "Displays Type Information",
        DetailComponent: ({ entity }) => {

          //  TODO:  Breaks hooks!
          if (!entity) { return <Text>Missing Entity</Text>; }

          const [typeList, setTypeList] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);
          const [instanceList, setInstanceList] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

          const [typeSelector, setTypeSelector] = React.useState(false);
          const [instanceSelector, setInstanceSelector] = React.useState(false);
          const [allEntities, setAllEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

          const loadTypes = async () => {
            const _types = await this.getTypesForEntity(entity.payload);
            const _instances = await this.getInstancesForEntity(entity.payload.id);
            const _entities = await this.entity2Plugin.entityService.entityNounService.retrieveAll();
            setAllEntities(_entities);
            setTypeList(_types);
            setInstanceList(_instances);
          }

          React.useEffect(() => {
            loadTypes();
          }, []);

          const addType = async (typeEntity: NounServiceInstanceInternal<Entity>) => {
            await this.registerInstance(entity.payload.id, typeEntity.payload.id, [PersonalSystem]);
            await loadTypes();
            setTypeSelector(false);
          }

          const addInstance = async (instanceEntity: NounServiceInstanceInternal<Entity>) => {
            await this.registerInstance(instanceEntity.payload.id, entity.payload.id, [PersonalSystem]);
            await loadTypes();
            setInstanceSelector(false);
          }

          // const removeInstance = async (instanceEntity: Entity) => {
          //   await this.removeInstance(instanceEntity.id, entity.id);
          //   await loadTypes();
          // }

          // const removeType = async (typeEntity: Entity) => {
          //   await this.removeInstance(entity.id, typeEntity.id);
          //   await loadTypes();
          // }

          return (
            <View style={{ backgroundColor: 'white', flex: 1 }}>

              {/* Type System Card */}

              <SystemHeader breadcrumbs={false} system={{ name: "Type", icon: Type2System.icon }} />

              <ScrollView style={{ padding: 30 }}>

                {/* Types */}
                <Modal visible={typeSelector}>
                  <EntityTable onPress={addType} entities={allEntities} />
                </Modal>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333", flex: 1 }}>Types</Text>
                  <IconButton
                    icon='plus'
                    onPress={() => setTypeSelector(true)}
                    style={{
                      backgroundColor: '#F5F5F5',
                      marginHorizontal: 4,
                      borderRadius: 8
                    }}
                    iconColor="#555555"
                    size={20}
                  />
                </View>
                <EntityTable entities={typeList} />

                <View style={{ height: 20 }} />

                {/* Instances */}
                <Modal visible={instanceSelector}>
                  <EntityTable onPress={addInstance} entities={allEntities} />
                </Modal>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333", flex: 1 }}>Instances</Text>
                  <IconButton
                    icon='plus'
                    onPress={() => setInstanceSelector(true)}
                    style={{
                      backgroundColor: '#F5F5F5',
                      marginHorizontal: 4,
                      borderRadius: 8
                    }}
                    iconColor="#555555"
                    size={20}
                  />
                </View>
                <EntityTable entities={instanceList} />
              </ScrollView>
            </View>


          );
        }
      })

      hessia2.registerHOC(({ children }) => {

        const hessia2Context = React.useContext(Hessia2Context);
        const entityPlugin = React.useContext(EntityContext);
        const [selectedType, selectType] = React.useState<NounServiceInstanceInternal<Entity>>();
        React.useEffect(() => {
          hessia2Context?.installSystem(Type2System, false);
        }, []);

        const [types, setTypes] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

        const loadTypes = async () => {
          const _types = await this.getAllTypes();
          if (!_types) { return; }
          setTypes(_types);
        }

        React.useEffect(() => {
          loadTypes();
        }, []);



        return (
          <ClassPluginContext.Provider value={this}>
            <ClassContext.Provider value={{ selectedType, selectType, types, loadTypes }}>
              {children}
            </ClassContext.Provider>
          </ClassPluginContext.Provider>
        )
      });

    } catch (err: any) {
      alert("Failed to Install Type System: " + JSON.stringify(err?.message));
    }
    return this;
  }
}