
//  CONSIDER:  Should be able to quickly build a system to 
//  TODO:  Re-create the Habor filter thing here ugh!!!  SO much work re-creating EVERYTHING!!!
//  TODO:  Catalog ALL the existing work so I can explore it ugh!

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SDTObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister } from 'halia-native';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { SingleDatePicker } from '../../../packages/kelp-bar/single-date-picker';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { ColumnSelection, Entity2Plugin, EntityContext, EntityEditor, EntityPluginContext, EntityTable } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { PrimitivesPlugin, TypeSelection } from '../../hessia2/content-plugin';
import { AaroContext, AaroPlugin, AaroSystem } from '../aaro-core';
import { AaroHeader } from '../utils';
const uuidv4 = require('uuid/v4');

//
//  Data Model
//

export const NutritionProperties = {

  //  Calories
  calories: { type: "number", required: false },

  //  Macros
  carbs: { type: "number", required: false },
  fat: { type: "number", required: false },
  protein: { type: "number", required: false },

  //  More
  satFat: { type: "number", required: false },
  totalSugar: { type: "number", required: false },
  addedSugar: { type: "number", required: false },
  water: { type: "number", required: false },

}

//  Davel Schemas
const DietEntrySchema: SDTObject = {
  type: "object",
  properties: {

    date: { type: "date", required: true },
    day: { type: "option", options: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"], required: true },
    time: { type: "date", required: true },
    servings: { type: "text", required: true },
    product: { type: "entityid", required: true }

    //  TODO:  SHOULD be able to declare conditional fields .. either all the specifics OR a product... hmm... ALSO... should be able to do this more smoothly with AI. 

    //  TODO:  Make a Davel type called "Conditional" and it's valid if EITHER of two davel types are valid. 
    //  TODO:  Make a Custom Davel type for "Relationship" which lets us connect to an Entity. 
    //  TODO:  Make the Relationship field customizable so that it requires a certain predicate match (like being a "Product" Entity)
    //  TODO:  Use the Conditional Davel type to switch between the Entity Relationship or a Product Entry?  OR just make it easy and make it possible to add a simple entity relationship to start... 
  }
};

const ProductSchema: SDTObject = {
  type: "object",
  properties: {
    ...NutritionProperties
  }
};

//  TypeScript Schemas
export interface Nutrition {
  calories: number;
  carbs: number;
  fat: number;
  protein: number;
  satFat: number;
  totalSugar: number;
  addedSugar: number;
  water: number;
}

export const NutritionLabels = {
  calories: "Calories",
  carbs: "Carbs",
  fat: "Fat",
  protein: "Protein",
  satFat: "Saturated Fat",
  totalSugar: "Total Sugar",
  addedSugar: "Added Sugar",
  water: "Water"
}


export interface Product extends Nutrition { }

export interface DietEntry {
  date: string,
  day: string;
  time: string;
  servings: number;
  product: string;

}

export interface DietEntryEntity extends Entity {
  value: DietEntry;
}
export interface ProductEntity extends Entity {
  value: Product;
}

//
//  React Context
//
export interface IDietContext {
  dietEntries: NounServiceInstanceInternal<DietEntryEntity>[];
  dietPlugin?: DietAaroExtension;
  createDietEntry: () => Promise<void>;
  createProductEntry: () => Promise<void>;
  products: NounServiceInstanceInternal<ProductEntity>[];
}
export const DietContext = React.createContext<IDietContext>({ products: [], dietPlugin: undefined, dietEntries: [], createDietEntry: async () => { return; }, createProductEntry: async () => { return; } });

/**
 * Screens
 */
const DietStackNav = createStackNavigator();
const DietTabNav = createBottomTabNavigator();

export interface HabitsHeaderProps {
  borderOpacity?: any, title: any, navigation: any, showFilter?: any, style?: any
}

export const CreateDietEntryButton = () => {

}

export const DietEntryList = () => {
  const navigation = useNavigation<any>();
  const { dietEntries, products } = React.useContext(DietContext);
  const { createDietEntry } = React.useContext(DietContext);

  const currentMoment = moment();

  //  Custom Filters
  //  1.  Date
  const [date, setDate] = React.useState<Moment>(currentMoment);

  const filteredEntries = dietEntries.filter(dietEntry => {
    const entryMoment = moment(dietEntry.payload.value.date);
    const isSame = date.isSame(entryMoment, "day");
    return isSame;
  }, [dietEntries, date]);

  //  Totals
  //  TODO:  SHOULD be able to use tools to aggregate across "joins" like this faster.

  const totals: Nutrition = filteredEntries.reduce<Nutrition>((prev, curr, index) => {
    const { servings, product: productId } = curr.payload.value as DietEntry;
    const product = products.find(product => product.payload.id === productId);
    if (!product) { return prev; }
    const productNutrition = product.payload.value;
    const result = {} as Nutrition;
    Object.keys(productNutrition).forEach(nutrient => result[nutrient] = prev[nutrient] + servings * productNutrition[nutrient]);
    return result;
  }, { calories: 0, carbs: 0, fat: 0, protein: 0, satFat: 0, totalSugar: 0, addedSugar: 0, water: 0 });

  //  IDEA:  Make it EASY to show things in the table like ASSOCIATED data and freaking... nested data from selections etc!  CUSTOMIZE it and make it easy to program here and in the app!

  const columnSelectors: ColumnSelection[] = [
    {
      name: "Date",
      minWidth: 100,
      component: ({ entity }: { entity: NounServiceInstanceInternal<DietEntryEntity> }) => {
        return (
          <Text>{entity?.payload.value?.date}</Text>
        );
      }
    },
    {
      name: "Time",
      minWidth: 100,
      component: ({ entity }: { entity: NounServiceInstanceInternal<DietEntryEntity> }) => {
        return (
          <Text>{entity?.payload.value?.time}</Text>
        );
      }
    },
    {
      name: "Servings",
      minWidth: 50,
      component: ({ entity }: { entity: NounServiceInstanceInternal<DietEntryEntity> }) => {
        return (
          <Text>{entity?.payload.value?.servings}</Text>
        );
      }
    },
    {
      name: "Name",
      minWidth: 150,
      component: ({ entity }: { entity: NounServiceInstanceInternal<DietEntryEntity> }) => {
        const { entities } = React.useContext(EntityContext);
        const productId = entity?.payload.value?.product;
        if (!productId) { return <Text>No Product</Text> }
        const product = entities.find(_entity => _entity.payload.id === productId);
        return (
          <Text>{product?.payload.name}</Text>
        );
      }
    }
  ];
  return (
    <>

      {/* Header */}
      <AaroHeader title="Entries">
        <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={createDietEntry}>
          <Icon color="#888888" name="plus" type="font-awesome" size={19} />
        </TouchableOpacity>
      </AaroHeader>

      {/* Filters */}
      <View style={{ backgroundColor: 'white', padding: 30 }}>
        {/* CONSIDER:  Instead of doing this manually we SHOULD be able to auto-generate filters from the entity, and we DID this before!!! */}
        {/* FOR NOW:  Let's just do the main ones?  Hmm...  even in the Sheets system we BASICALLY just kept it simple and just added pieces to the current one.  Let's contextualize to a DATE. */}
        <SingleDatePicker period='day' onSelected={setDate} selected={date} />
      </View>

      {/* Nutrient Totals */}
      <View style={{ backgroundColor: 'white', padding: 30, flexDirection: 'row' }}>
        {
          Object.keys(totals).map(nutrient => (
            <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontWeight: "700" }}>{NutritionLabels[nutrient]}</Text>
              <Text>{totals[nutrient]}</Text>
            </View>
          ))
        }
      </View>

      {/* Entry Table */}
      <EntityTable columns={columnSelectors} entities={filteredEntries} onPress={entity => navigation.navigate("EntryEditor", { entity })} />
    </>
  );
}

export const ProductList = () => {
  const navigation = useNavigation<any>();
  const { products } = React.useContext(DietContext);
  const { createProductEntry } = React.useContext(DietContext);
  return (
    <>
      <AaroHeader title="Products">
        <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={createProductEntry}>
          <Icon color="#888888" name="plus" type="font-awesome" size={19} />
        </TouchableOpacity>
      </AaroHeader>
      <EntityTable entities={products} onPress={entity => navigation.navigate("ProductEditor", { entity })} />
    </>
  );
}

/**
 *  Navigators
 */

export const EntryHome = () => {
  return (
    <DietStackNav.Navigator initialRouteName='Entries' screenOptions={{ headerShown: false }}>
      {/* CONSIDER:  We COULD mount a new version of the app at the Entity editor thing? hm.. */}
      <DietStackNav.Screen name="EntryEditor" component={({ route }) => {
        const { selectEntity } = React.useContext(EntityContext);
        React.useEffect(() => {
          selectEntity(route.params.entity);
        }, []);
        return <EntityEditor />
      }} />
      <DietStackNav.Screen name="Entries" component={DietEntryList} />
    </DietStackNav.Navigator>
  );
}

export const ProductHome = () => {
  const navigation = useNavigation<any>();
  const { dietEntries } = React.useContext(DietContext);
  return (
    <DietStackNav.Navigator initialRouteName='Products' screenOptions={{ headerShown: false }}>
      <DietStackNav.Screen name="ProductEditor" component={({ route }) => {
        const { selectEntity } = React.useContext(EntityContext);
        React.useEffect(() => {
          selectEntity(route.params.entity);
        }, []);
        return <EntityEditor />
      }} />
      <DietStackNav.Screen name="Products" component={ProductList} />
    </DietStackNav.Navigator>
  );
}

export const DietApp = () => {
  return (
    <DietTabNav.Navigator screenOptions={{ headerShown: false }}>
      <DietTabNav.Screen
        name="Entries"
        component={EntryHome}
        options={{
          tabBarIcon: ({ focused, color, size }) => (
            <Icon
              name={focused ? 'restaurant' : 'restaurant-outline'}
              type="ionicon"
            />
          )
        }}
      />
      <DietTabNav.Screen
        name="Products"
        component={ProductHome}
        options={{
          tabBarIcon: ({ focused, color, size }) => (
            <Icon
              name={focused ? 'fast-food' : 'fast-food-outline'}
              type="ionicon"
            />
          )
        }}
      />
    </DietTabNav.Navigator>
  );
}


export class DietAaroExtension extends CorePluginClass {

  public static details = {
    name: 'Diet Plugin',
    description: 'Diet App',
    dependencies: [Entity2Plugin.details.id, AaroPlugin.details.id, PrimitivesPlugin.details.id],
    id: 'diet',
    image: require("../../../assets/stickers/diet.png")
  }

  //  ID for the Habit Model Primitive
  public dietEntryModelId = "diet-entry-model";
  public productModelId = "product-model";

  public primitive2!: PrimitivesPlugin;

  public install = async (program: Program, { entity2, aaro, primitive2 }: { entity2: Entity2Plugin, aaro: AaroPlugin, primitive2: PrimitivesPlugin }) => {

    this.primitive2 = primitive2;

    //  Build the Diet Entry Model
    const dietEntryModelEntity = await entity2.entityService.getEntityById(this.dietEntryModelId);
    if (!dietEntryModelEntity) {
      console.log("Building Diet Entry Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = DietEntrySchema;
      const entity = { id: this.dietEntryModelId, name: "Diet Entry Model", description: "Diet Entry Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Diet Entry Model Exists!");
    }

    //  Build the Product Model
    const productModelEntity = await entity2.entityService.getEntityById(this.productModelId);
    if (!productModelEntity) {
      console.log("Building Product Model");
      const type = { type: "anonymous" as "anonymous", davelType: { type: "sdt" as "sdt" } };
      const value = ProductSchema;
      const entity = { id: this.productModelId, name: "Product Model", description: "Product Model Entity", owners: [AaroSystem] };
      await primitive2.createObject(entity, type, value);
    } else {
      console.log("Product Entry Model Exists!");
    }

    //  Register Services
    //  TODO:  Use HESSIA for this not Habor!??
    //  TODO:  Register all functions.  The point is, we need a way for AI and the client to explore these functions / services based on associations!

    aaro.registerHOC(({ children }) => {

      const { entities } = React.useContext(EntityContext);
      const isDietEntity = (entity: NounServiceInstanceInternal<Entity>) => {
        return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.dietEntryModelId);
      };

      const isProductEntity = (entity: NounServiceInstanceInternal<Entity>) => {
        return (entity.payload.type?.type === "reference") && (entity.payload.type?.entityId === this.productModelId);
      };

      const dietEntries = entities.filter(entity => isDietEntity(entity)) as NounServiceInstanceInternal<DietEntryEntity>[];
      const products = entities.filter(entity => isProductEntity(entity)) as NounServiceInstanceInternal<ProductEntity>[];

      const aaroContext = React.useContext(AaroContext);

      React.useEffect(() => {

        aaroContext.installRoute({
          name: 'Diet',
          icon: {
            name: "ionicon",
            type: "nutrition"
          },
          emoji: "🍎",
          logo: require("../../../assets/stickers/diet.png"),
          component: DietApp
        });

      }, []);

      const navigation = useNavigation<any>();

      const createDietEntry = async () => {
        const entry: DietEntry = { date: new Date().toISOString(), time: new Date().toISOString(), day: "Monday", servings: 1, product: "" };
        const entryEntity: Entity = { id: uuidv4(), name: "New Diet Entry", description: "New Habit", owners: [AaroSystem] };
        const entryType: TypeSelection = { type: "reference", entityId: this.dietEntryModelId }
        const newEntry = await this.primitive2.createObject(entryEntity, entryType, entry);
        navigation.navigate("EntryEditor", { entity: newEntry });
      }

      const createProductEntry = async () => {
        const product: Product = { calories: 100, satFat: 10, addedSugar: 0, carbs: 20, fat: 50, protein: 5, totalSugar: 0, water: 0 };
        const productEntity: Entity = { id: uuidv4(), name: "Raspberry", description: "One Raspberry", owners: [AaroSystem] };
        const productType: TypeSelection = { type: "reference", entityId: this.productModelId }
        const newEntry = await this.primitive2.createObject(productEntity, productType, product);
        navigation.navigate("ProductEditor", { entity: newEntry });
      }

      return (
        <DietContext.Provider value={{ dietPlugin: this, dietEntries, createDietEntry, products, createProductEntry }}>
          <HOCRegister id="habit">
            {children}
          </HOCRegister>
        </DietContext.Provider>
      );

    });
    return this;
  }
}


