
import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { ENounService } from '../../../enoun-service';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { TextParagraph } from '../../../packages/kelp-bar/text';
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { EntityPlugin } from "../entity-plugin";
import { ModelPlugin } from '../model-plugin';
import { SystemPlugin } from '../system-plugin/system-plugin';
import { RoundButton } from '../workspace-plugin/workspace-switcher';

//  NOTE:  A Set is a SIMPLE observable pattern in the context of Entities / Relatioships, and one REQUIRED to contain the model itself.. because the "Fudamental Ontology" cannot exist without it, we iclude it as a basic, core entity.  It CAN be created with just relationships and associated entities.  BUT, we build our own encoding for optimization? (FOR NOW).

export interface Set {
  entityIdList: string[];
}

export const setService = new ENounService<Set>("set");
setService.init();


interface SetProps {
  sets: NounServiceInstanceInternal<Set>[];
}
class SetListBase extends React.Component<{ navigation: NavigationProp<any> }, any> {

  constructor(props) {
    super(props);
    this.state = {
      sets: []
    }
  }

  componentDidMount = async () => {
    const sets = await setService.retrieveAll();
    this.setState({ sets });
  }

  render() {

    //  CONSIDER-PATTERN:  We have this "Deffered" thing in ALL the types.. it's JUST a way to show the entity view.. do we always want that?  Can we hide?  Can we JUST scope based on the TYPE string and use the SAME UIs for ALL of these with ONLY UI / Encoding injectiosn forr the differenes, as ONE type of "type" system ah!!! HM!  THEN these are ALL just instaces of the "Type" system but NOT necesssarily insances of hte "Primitive" system concept hM!!!  I THINK I might like that? HM!
    const setElems = this.state.sets.map((set: ENoun<Set>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("Editor", { set })}>
          {/* <Deferred func={ renderEntityListItem } args={ set.payload.entityId } def={ <Text>Loading...</Text> } /> */}
          <Text>Should show entity list</Text>
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Sets</Text>
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('Editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>
          <ScrollView showsHorizontalScrollIndicator={false}>
            {setElems}
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedSetList = (props: any) => <SetListBase {...props} />
export const SetList: any = InjectedSetList;

const Stack = createStackNavigator();

export const SetPage = () => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      <Stack.Screen name="Editor" component={SetEditor} />
      <Stack.Screen name="List" component={SetList} />
    </Stack.Navigator>
  );
}


export const SetEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  console.log("Rendiner set editor!!")
  // const setNoun: ENoun<Set> = route?.params?.set;
  // const [setId, setSetId] = React.useState<string | undefined>(setNoun.id || undefined);
  const [setNoun, setSetNoun] = React.useState<ENoun<Set>>(route?.params?.set);
  const [newEntityId, setNewEntityId] = React.useState<string>("");

  const entityIdList = setNoun?.payload.entityIdList || [];

  //  NOTE:  We DO allow the same element in the set more than once I suppose.  Hmm... maybe?
  const removeElement = async (index: number) => {
    if (!setNoun) { throw `Cannot update the set.  No set was provided.`; }
    const newEntityList = [...entityIdList];
    newEntityList.splice(index, 1);
    const newSetNoun = await setService.update(setNoun.id, { ...setNoun.payload, entityIdList: newEntityList });
    setSetNoun(newSetNoun);
  }

  const addElement = async (entityId: string) => {
    if (!setNoun) { throw `Cannot update the set.  No set was provided.`; }
    const newEntityList = [...entityIdList, entityId];
    const newSetNoun = await setService.update(setNoun.id, { ...setNoun.payload, entityIdList: newEntityList });
    setSetNoun(newSetNoun);
  }

  const makeSet = async () => {
    console.log("About to make set")
    const newSetNoun = await setService.create({ entityIdList: [] });
    console.log("Made set!")
    setSetNoun(newSetNoun);
  }

  if (!setNoun) {
    return <RoundButton onPress={makeSet} icon={{ name: "add", type: "material" }} circleColor="#aaaaaa" iconColor="white" selected={false} />;
  }

  return (
    <ScrollView style={{ marginVertical: 100 }}>

      <Button title="Delete" onPress={() => setService.delete(setNoun.id)} />

      <Text>Entity IDs</Text>
      {
        entityIdList.map((entityId, index) => (
          //  TODO:  Show a mini entity view here instead of just the ID?
          <View style={{ padding: 30, backgroundColor: 'white', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <View style={{ flex: 1 }}>
              {/* <Deferred func={ renderEntityListItem } args={ entityId } def={ <Text>Loading...</Text> } /> */}
              <Text>Should show entity list</Text>
            </View>
            <RoundButton onPress={() => removeElement(index)} icon={{ name: "delete", type: "material" }} circleColor="#aaaaaa" iconColor="white" selected={false} />
          </View>
        ))
      }

      <View style={{ height: 15 }} />
      <TextParagraph>Add</TextParagraph>
      <View style={{ height: 10 }} />

      <View style={{ padding: 30, backgroundColor: '#eeeeee', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <View style={{ flex: 1 }}>
          <TextInput value={newEntityId} onChangeText={setNewEntityId} />
        </View>
        <RoundButton onPress={() => addElement(newEntityId)} icon={{ name: "add", type: "material" }} circleColor="#aaaaaa" iconColor="white" selected={false} />
      </View>

      <Text>Entity ID</Text>
      <TextInput value={setNoun.payload.entityId} />


    </ScrollView>
  );
}




export class SetPlugin extends CorePluginClass {
  public static details = {
    name: "PrimitiveSets",
    description: "Sets Primitive System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id],
    id: "setPlugin"
  }

  public install = (program: Program, { system, pEntities }: { system: SystemPlugin, pEntities: ModelPlugin }) => {

    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {
    //   const set = await setService.retrieveByEntityId(entityId);
    //   if (set) {
    //     api.setText("Group");
    //   }
    // });

    system.registerPrimitiveSystem({
      id: 'sets-system',
      name: "Set",
      description: "Sets Primitive System",
      labels: ["core"],
      component: SetPage,
      menuItem: {
        icon: { name: "group-work", type: "material" },
        backgroundColor: "#937af5",
        iconColor: "white"
      }
    });
  }
}
