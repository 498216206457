/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { DTOptions, DT, DTObject, DTOption, SDT } from "davel";
import { HUserFunction, HUserFunctionSchema } from "habor-sdk";

interface HDTUserFunctionOptions extends DTOptions {}

//
//  NOTE:  For a user to set a property on a Noun, they need to specify a valid SDT.  We need to interpret
//         that SDT as a function, NOT some other type.  This is why we need a new type instead of a
//         schema (which would be of type 'object' and treated as an object property instead of a method).
//

export class HDTUserFunction extends DT<HUserFunction, any> {
  constructor(public options: HDTUserFunctionOptions, protected metadata?: any) {
    super(options);
  }

  public async validate(hUserFunction: HUserFunction): Promise<HUserFunction | undefined> {

    //  Super Validation
    let validatedHUserFunction = await super.validate(hUserFunction);

    //  Handle Undefined
    if (validatedHUserFunction === undefined) { return undefined; }

    //  Validate Input
    validatedHUserFunction = await HUserFunctionSchema.validate(hUserFunction);

    return validatedHUserFunction;
  }

  //  TODO:  Should be a cross concern!!!
  public async getElasticSchema() {
    return HUserFunctionSchema.getElasticSchema(false);
  }
}

const HSDTUserFunctionSchema = new DTObject({
  required: true,
  extensible: true,
  properties: {
    type: new DTOption({
      required: true,
      options: ["user-function"]
    })
  }
});

/**
 * Returns a Davel Type Validator (used to validate types) given serialized options for the User Function type.
 */
export const HSDTUserFunctionParser = async (sdt: HSDTUserFunction, metadata?: any): Promise<HDTUserFunction> => {

  const validatedSDT = await HSDTUserFunctionSchema.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hdtUserFunctionOptions: HDTUserFunctionOptions = { ...serializedTypeCopy };

  const hdtUserFunction = new HDTUserFunction(hdtUserFunctionOptions, metadata);
  return hdtUserFunction;
};

export interface HSDTUserFunction extends SDT {
  type: "user-function";
}

export interface HSDTStatement extends SDT {
  type: "statement";
}
