import { AllSDT, FrameworkContext, HaborComponentConfig, HaborComponentContext, HaborIconSelection, SDTObject } from 'habor-sdk';
import * as React from 'react';
import { View, ViewStyle } from 'react-native';
import { LongPressGestureHandler, State } from 'react-native-gesture-handler';
import { isArray } from 'util';
import { HessiaPlugin } from '../../hessia-plugin';

//  FOR NOW:  Creating a tree of actions.  We eventually need to namespace this to an app and hopefully auto-generate actions.  These are the things that can be invoked by pressing a button, or interacting with the app.
//  TODO:  Make sure the name is unique per group!  Maybe use a dict. structure to enforce this?
export interface AppAction {
  group: string[];  //  A list of nested groups
  name: string;
  icon?: HaborIconSelection;
  function: (context: FrameworkContext, params: any) => any;
}

// export const AppActions: AppAction[] = [
//   {
//     group: ["Routes"],
//     name: "navigate",
//     icon: {
//       name: "md-navigate",
//       type: "ionicon"
//     },
//     function: (appContext: FrameworkContext, { routeId, metadata }: { routeId: string, metadata: any }) => {
//       const { history } = appContext;
//       const appRoute = AppRoutes.find(route => route.name == routeId);
//       if (appRoute == undefined) { throw new Error(`AppRoute not found: ${routeId}`) }
//       const path = `${appContext.match.path}/${appRoute.path.join("/")}`;
//       history.push(path, metadata);
//     }
//   }
// ];

//  FOR NOW:  Creating App Routes.  These should eventually be more dynamic and exist in Habor all that...
export interface AppRoute {
  name: string;
  path: string[]
  contextSchema?: SDTObject;
}
export const AppRoutes: AppRoute[] = [
  //  TODO:  Should we be explicitly stating the path?  It seems like an implementation detail, especially if we're selecting based off the ID.  Consider selecting based off the route?
  { name: "entity-list", path: ["entities", "list"] },
  { name: "entity-create", path: ["entities", "create"] },
  { name: "events", path: ["events"] },
  { name: "create-instance", path: ["instances", "create"] }
];

//  FOR NOW:  Creating a list of App Types which can be used as props within App Components.  This should eventually be dynamic and potentially use DTs, and be known to Habor, AND should probably work more generally for COMPONENTS, not just the App.
export interface AppType {
  name: string;
  schema: AllSDT;
}

// export const ActionSelectionSchema: SDTObject = {
//   type: "object",
//   extensible: false,
//   properties: {
//     actionId: { type: "option", options: AppActions.map(action => action.name)  },
//     params: {  }
//   }
// }
// export const AppTypes: AppType[] = [
//   {
//     name: "Action",
//     schema: { type: "option", options: AppActions.map(action => action.name)  }
//   },
//   {
//     name: "Route",
//     schema: { type: "option", options: AppRoutes.map(route => route.name)  }
//   }
// ];

//  TODO:  Make a prop type and state type for ALL HaborComponents!!  
//  IDEA:  Wrap "Primitive" components in a HaborComponent to pass the Davel Prop Types!

export interface HaborPrimitiveProps {
  config: HaborComponentConfig;
}

export interface FrameworkProps {
  settings?: any;  //  Computed Component User-Settings (part of the CSS-like framework)
  config: HaborComponentConfig;
  componentId: string;  //  TODO:  Not sure we should be defining this here.  Maybe... but not sure it should be the responsibility of the "renderHaborComponent" caller to set this value.  Maybe it should be the responsibility of "renderHaborComponent"?  Actually, I think I set it up this way because sometimes, it's going to be a primitive and that's something the caller knows... ah, so this isn't really componentId, it's more like ElementPath?
  context: FrameworkContext;
  children: React.ReactElement<any>[] | any[];  //  TODO:  Make a distinction between HaborComponent children and Primitive Children (which are created from HaborComponents).
  componentContext: HaborComponentContext;
  hessiaPlugin: HessiaPlugin;
}
export interface PrimitiveProps extends React.ComponentProps<any> {
  userProps: any;
  //  TODO:  Separate the HaborComponent specifics from FrameworkProps, and inject something SIMILAR for PRIMITIVE components.. PERHAPS we can STILL wrap the primitives thoguh!  THIS way we can still see how they exist in the hierarchy? HM!
  frameworkProps: FrameworkProps;
}

//  Basically All CSS Properties?  Convert to DT for Wrapped Components?
// export interface HaborStyle extends ViewStyle {}

export interface ActionSelection {
  actionId: string;
  actionParams: any;
}

//  EXPLANATION:  There are 3 layers to the Habor component system.
//  1.  A set of React Components.
//  2.  A HaborPrimitive React Component which uses React Components with a touchable view for edit-mode drill-down.
//  3.  A HaborComponent which is defined in the User Space, which wraps a HaborPrimitive and exports Davel Types for runtime type checking.

//  TODO:  The user's API into Habor components should ALWAYS use HaborComponents, not HaborPrmitives.  However, with the JS context, it's possible to use HaborPrimitives, which is what makes it possible to wrap them in HaborComponents.

/**
 * A wrapper used around ALL HaborPrimitive Components.  This adds edit mode functionality.  It's the HaborPrimitive component's responsibility to choose how to style this container.
 */
export const HaborContainer = ({ frameworkProps: { config: { editMode, onPress: onEditPress }, componentId, children: haborChildren }, style, children, onPress = () => null, ...props }: { frameworkProps: FrameworkProps, style?: ViewStyle, children: React.ReactElement<any>[] | React.ReactElement<any>, onPress?: () => void }) => {
  const childList = isArray(children) ? children : children ? [children] : [];
  const handlePress = () => {
    alert("PRESSED");
    if (editMode) {
      onEditPress(componentId);
    } else {
      onPress();
    }
  }

  //  NOTE:  Better to apply this hackish solution here rather than the "makeHaborPrimitiveComponent" wrapper:  This Container Component ALWAYS accepts a style prop.  If we wrapped ALL user components, then it would pollute user defined APIs.
  //  TODO:  I'm concerned this is going to become the bane of my existence... Consider a new method to fully abstract some of this layout stuff, maybe removing flexbox from the API altogether and using specialized components?
  //  WARNING:  I don't like that we pass "style" to the outer wrapper but then do alignment on the lower wrapper... Aligning will happen at both layer and could cause problems!

  //  NOTE:  The outer view is to shield ReactElement children from touch events when we're in Edit Mode and there are NO HaborElement children.  In this case, and only in this case, do we stop touch events.  We do this to prevent activation of application level touch events in edit mode.  This will allow the deepest in the chain to be pressed but stop control from entering the ReactElements.
  //  TODO-CRITICAL:  I'm concerned that using the OUTER view to block will prevent the lowest level HaborElement from receiving a tap event... Seems like it should be blocked BELOW the onPress handler, but that doesn't seem to be working for some reason... 

  //  NOTE:  I am MANUALLY checking that the handler state is "End" because the "onGestureEvent" does not appear to work for the TapGestureHandler
  //  REFERENCE:  https://github.com/kmagiera/react-native-gesture-handler/issues/479

  return (

    //  NOTE:  I'm using a LongpressGestureHandler instead of "TouchableOpacity" so it doesn't block the children?
    //  TODO:  We should ONLY have to HOLD he view, no long press + release.
    <LongPressGestureHandler minDurationMs={1000} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state == State.ACTIVE) { alert(componentId) } }}>
      <View style={{ overflow: 'visible', ...style }} pointerEvents={editMode && !haborChildren.length ? 'none' : 'box-none'} {...props}>
        {/* <TouchableOpacity style={{ overflow: 'visible', position: 'absolute', width: '100%', height: '100%', zIndex: 100 }} onPress={ handlePress } onLongPress={ () => alert(componentId) } /> */}
        {childList}
      </View>
    </LongPressGestureHandler>
  );
}
