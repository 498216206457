import { useIsFocused, useNavigation } from '@react-navigation/native';
import { EventEmitter } from "events";
import * as React from 'react';
import { ActivityIndicator, Image, Pressable, ScrollView, Text, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { GroupCard } from '../../../packages/kelp-bar/group-card';
import { GalleryHeader } from '../components/header';
import { HeroCard } from '../components/hero-card';
import { AggWork, WorkCard } from '../components/works';
import { defaultBorderRadius, Paragraph, primaryColor, SubHeader } from '../constants';
import { CenteredView, useSettings } from '../content-view';
import { Profile } from '../openapi';
import { getGalleryApi, MatureFilter, useCognitoToken, useCognitoUser, useMature } from '../utilities';
import { ErrorBox } from './authenticate';
import { GalleryButton } from '../components/common';

//  CONSIDER:  What we're actually doing is CONTEXTUALIZING and then showing something COUPLED to that context.  We CAN couple the contextualizationa and the display like we do here, but it can also be separated hmm...
//  CONSIDER:  We CAN "pass" the Artist, OR the username, etc.. hmm...  AH!  It's like networks that are COUPLED with specific thigns, BUT they can grow apart ughh etc damnitt..
//  CONSIDER:  RedBubble integration, and lots of others hm!

export interface WorkListProps {
  showApproved?: boolean;
  showOwned?: boolean;  //  Defaults to NO
  //  TODO:  I hate that this is coupled.  This CAN be injected or connected or... mediated by THAT system... this thing is being over-compliated hmm...
  profile?: Profile;  //  Limit to a particular profile  
  showHeader?: boolean;
  pageNumber: number;
  maxPages?: number;
}


interface Dimension {
  height: number;
  width: number;
}

export const WorkList = ({ showHeader = true, showApproved, showOwned, profile, pageNumber = 1 }: WorkListProps) => {

  const focused = useIsFocused();

  const getWorks = async () => {

    setLoading(true);

    const api = getGalleryApi(token?.getJwtToken());

    //  TODO:  Filter by USER and ADMIN Roles.  Display user / role info.
    //  TODO:  Use GraphQL to enforce this in the backend!

    //  TODO:  Eliminate redundant calls!

    //  TODO:  Add paging to the backend call.
    try {
      const allWorks = await api.worksControllerFindAll({});


      //  TODO:  Add filters explicity.  MAY also want a step to re-add and allow for complex "rules" which are JUST interpreted encodings!

      //  Paging

      //  Owner Filter
      let filteredWorks = showOwned ? allWorks.filter(work => work.owner === user?.getUsername()) : allWorks;

      //  Username Filter
      filteredWorks = profile ? filteredWorks.filter(work => work.owner === profile?.username) : filteredWorks;

      //  Mature Filter
      filteredWorks = filteredWorks.filter(work => mature ? work : !(work as any).mature);


      //  TODO:  Support Approval Filtering
      //         allWorks.filter(work => showApproved ? work.status === "approved" : true);

      //  Sort Works
      // filteredWorks = _.shuffle(filteredWorks);

      //  Page Works
      const pageSize = 20;
      filteredWorks = filteredWorks.splice(0, pageSize * pageNumber);

      const _aggWorks: AggWork[] = [];
      for (const work of filteredWorks) {
        _aggWorks.push({ work, imageUrl: work.url });
      }

      setAggWorks(_aggWorks);
      setLoading(false);

    } catch (err: any) {
      //  TODO:  Generalize and really think about how to REWRITE ErrorBoundaries and react from FUNDMANTALS.. even SOFTWARE as a whole hm!  NOT as needed mM!
      alert(err);
      setError(err);
    }
  }

  const user = useCognitoUser();

  const getWorksIfReady = () => {
    if (showOwned && token && user) {
      getWorks();
    }

    if (!showOwned) {
      getWorks();
    }
  }



  const [aggWorks, setAggWorks] = React.useState<AggWork[]>([]);
  const token = useCognitoToken();

  const navigation = useNavigation();

  const [groups, setGroups] = React.useState<AggWork[][]>([[]]);

  const totalWidth = 1000;
  const lineHeight = 350;
  const maxAspectRatio = 1.25;

  const getSize = async (url: string | undefined): Promise<Dimension | undefined> => {
    if (!url) { return undefined }
    return new Promise((resolve, reject) => {
      Image.getSize(url, (width, height) => { resolve({ width, height }) }, (err) => { reject(err) });
    });
  }

  const getDimensions = async (images: (string | undefined)[]) => {
    const dimensions: (Dimension | undefined)[] = [];
    for (const image of images) {
      const dimension = await getSize(image);
      dimensions.push(dimension);
    }
    return dimensions;
  }

  const generateGroups = async () => {
    const imageUrls = aggWorks.map(aggWork => aggWork.imageUrl);
    const dimensions = await getDimensions(imageUrls);

    const workGroups: AggWork[][] = [[]];
    aggWorks.forEach((work, workId) => {

      const workDimensions = dimensions[workId];
      if (!workDimensions) { return; }

      const { width, height } = workDimensions;
      const ratio = Math.min((width / height), maxAspectRatio);
      const _width = lineHeight * ratio;
      work.minWidth = _width;

      const group = workGroups[workGroups.length - 1];

      const groupWidth = group.reduce((prev, curr, index) => {
        return prev + (curr.minWidth || 0)
      }, 0);

      if (groupWidth >= totalWidth) {
        workGroups.push([work])
      } else {
        group.push(work);
      }
    });

    setGroups(workGroups);
  }

  //  CONSIDER!  We do something based on a STATE PATTERN MATCH!!  mm!  SHOULD be able to specify that h? HM!

  //  TODO:  SHOULD be able to GROUP multiple contexts for a bundled feature or mm!  Something like that mm!  It's just ontology and correlation mm!
  const [mature] = useMature();

  React.useEffect(() => {
    getWorksIfReady();
  }, [token, focused, user, mature, pageNumber]);

  // React.useEffect(() => {
  //   addListener();
  //   getWorksIfReady();
  // }, []);


  // React.useEffect(() => {
  //   addListener();
  // }, [screenEmitter]);

  const [error, setError] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  return (

    <View style={{ paddingVertical: 30, flex: 1 }}>


      {/* TODO:  Support REMIXES!  Show LINKS to the original hm! */}

      {showHeader && (
        <HeroCard style={{ backgroundColor: 'transparent' }} title="Creations" subTitle={showOwned ? "Manage your creations." : "Explore Bad Art"}>
          <Pressable style={{ marginVertical: 15, backgroundColor: primaryColor, padding: 15, borderRadius: defaultBorderRadius }} onPress={user ? () => navigation.navigate("Dashboard", { screen: "Creations" as any, params: { screen: "New Creation" } }) : () => navigation.navigate("Authenticate", { mode: "login" })}>
            <Paragraph style={{ color: 'white' }}>Upload</Paragraph>
          </Pressable>
        </HeroCard>
      )}

      <View style={{ paddingHorizontal: 20 }}>
        <MatureFilter />
      </View>


      {
        error ? (
          <GroupCard style={{ alignSelf: 'center', maxWidth: 700, width: '100%', minHeight: 350, alignItems: 'center', justifyContent: 'center' }}>
            <ErrorBox title="Loading Error" message={error?.message} />
          </GroupCard>
        ) : (


          aggWorks.length ? (
            <>
              <FlatGrid
                data={aggWorks}
                spacing={20}
                itemDimension={300}
                style={{ width: '100%' }}
                renderItem={({ item: aggWork }) => <WorkCard aggWork={aggWork} />}
              />
              {
                loading && <ActivityIndicator color={primaryColor} />
              }
            </>

          ) : (
            <View style={{ alignSelf: 'center', maxWidth: 700, width: '100%', minHeight: 400, alignItems: 'center', justifyContent: 'center', borderRadius: defaultBorderRadius, backgroundColor: '#fafafa' }}>
              <SubHeader>No Creations!</SubHeader>
              <View style={{ height: 7 }} />
              {
                showHeader && <Paragraph>Press "Create" to make one</Paragraph>
              }
            </View>
          )
        )
      }


      {/* TODO:  SHow EMPTY if empty */}


    </View>
    // </View>
  );
}

export const PageControl = ({ currentPage, maxPages, setPage, style }) => {

  const goForward = () => {
    let page = currentPage + 1;
    if (page > maxPages) { page = maxPages; }
    setPage(page);
  }

  const goBack = () => {
    let page = currentPage - 1;
    if (page < 1) { page = 1; }
    setPage(page);
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'row', maxWidth: 500, width: '100%', ...style }}>
      <GalleryButton style={{ maxWidth: 200, backgroundColor: currentPage === 1 ? '#eeeeee' : primaryColor }} title="Back" onPress={goBack} />
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <SubHeader>{currentPage}</SubHeader>
      </View>
      <GalleryButton style={{ maxWidth: 200 }} title="Next" onPress={goForward} />
    </View>
  );
}

//  TODO:  Convert to "WorkList" and eventually be able to render it all just from English??? mmm... Use it to PULL together known componsents hm!
export const WorkListPaged = (props: WorkListProps) => {

  const [page, setPage] = React.useState<number>(1);

  return (
    <ScrollView>
      <WorkList {...props} />
      <PageControl style={{ alignSelf: 'center', marginVertical: 30 }} currentPage={ page } maxPages={ props.maxPages } setPage={ setPage } />
    </ScrollView>
  );
}


export enum ScreenEvents {
  ScrollEnd = "ScrollEnd"
}

export const GalleryScreen = () => {

  const settings = useSettings();

  // const [screenEmitter, setScreenEmitter] = React.useState<EventEmitter>();

  const [pageNumber, setPageNumber] = React.useState(1);

  // React.useEffect(() => {
  //   setScreenEmitter(new EventEmitter());
  // }, []);



  //  AI INPUT:  When we scroll most of the way down, we should load the next page.
  //  AI INPUT:  We should page on scroll.
  //  NOTE:  I SHOULD be able to tie directly to this.  Basically I can pass the event emitter!
  const onScroll = (height: number, yOffset: number) => {
    if (yOffset > 0.7 * height) {
      setPageNumber(pageNumber + 1);
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <GalleryHeader flat={false} centered={true} showButtons={true} mode='light' />
      <ScrollView onScroll={event => onScroll(event.nativeEvent.contentSize.height, event.nativeEvent.contentOffset.y)}>
        <CenteredView style={{ paddingHorizontal: settings.marginSize }}>
          <WorkList pageNumber={pageNumber} showApproved={true} />
          <View style={{ height: 100, width: '100%' }} />
        </CenteredView>
      </ScrollView>
    </View>
  );
}
