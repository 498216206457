import * as React from 'react';
import { View, ViewProps } from 'react-native';
import { defaultBorderRadius } from '../constants';

export const ContentCard = (props: ViewProps) => {
  return (
    <View {...props} style={{ width: '100%', maxWidth: 800, borderRadius: defaultBorderRadius, overflow: 'hidden' }}>
      {props.children}
    </View>
  );
}
