import AsyncStorage from "@react-native-async-storage/async-storage";
import { S3Config } from "../../packages/noun-service/noun-service";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

export const defaultAuthConfig: HessiaConfig = { storage: "cloud" };
export const defaultUnauthConfig: HessiaConfig = { storage: "local" };
export interface HessiaConfig {
  storage: "custom" | "local" | "cloud";
  s3Config?: S3Config;
}

export const getUserId = async () => {
  //  Get Identity
  const credentials = await getCredentials();
  const identityIdFromIdentityPool = credentials.identityId;
  return identityIdFromIdentityPool;
};

export const setConfigInStorage = async (config: HessiaConfig): Promise<void> => {
  const identityIdFromIdentityPool = await getUserId();
  await AsyncStorage.setItem(identityIdFromIdentityPool, JSON.stringify(config));
};

//  CONSIDER:  We COULD scope config to the "config" object and differentiate within that instead of at the top level.
export const getConfigFromStorage = async (): Promise<HessiaConfig | undefined> => {
  //  Get Identity
  const identityIdFromIdentityPool = await getUserId();

  //  Get Auth User
  let authUser: CognitoUser | undefined;
  try {
    authUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    authUser = undefined;
  }

  //  Get Config
  const configString = await AsyncStorage.getItem(identityIdFromIdentityPool);
  let config: HessiaConfig = configString ? JSON.parse(configString) : undefined;

  //  Handle Missing Config
  if (!config) {
    const _config = authUser ? defaultAuthConfig : defaultUnauthConfig;
    await setConfigInStorage(_config);
    config = _config;
  }

  return config;
};

export const getCredentials = async () => {
  try {
    const currentCredentials = await Auth.currentCredentials();
    const essentialCredentials = Auth.essentialCredentials(currentCredentials);
    return essentialCredentials;
  } catch (error) {
    console.error("Error retrieving credentials from Amplify", error);
    throw new Error("Failed to retrieve or refresh credentials");
  }
};

//  NOTE:  This can transform the Config.
//  TODO:  This should probably be moved to a separate module but still marked as "part" of this overall idea.
export const getS3Config = async (): Promise<S3Config> => {
  const config = await getConfigFromStorage();

  //  Cloud
  if (config?.storage === "cloud") {
    const identityIdFromIdentityPool = await getUserId();
    const credentials = await getCredentials();
    return { region: "us-east-2", bucket: "hessia", bucketKey: `meshes/${identityIdFromIdentityPool}/database.json`, credentials };
  }

  //  Custom
  else if (config?.storage === "custom") {
    if (!config.s3Config) {
      const MissingCustomS3ConfigError = new Error("Could not get S3 configuration.  Custom S3 is not configured.");
      alert(MissingCustomS3ConfigError.message);
      throw MissingCustomS3ConfigError;
    }
    return config.s3Config;
  }

  //  Other
  else {
    const MissingS3ConfigError = new Error("Could not get S3 configuration.  S3 is not configured.");
    alert(MissingS3ConfigError.message);
    throw MissingS3ConfigError;
  }
};
