import { BlockType, EntityType, FrameworkParamsNew, HDTRelationshipValue, InstanceID, InstanceInternal, instanceInternalSchema, PrimitiveBlock } from 'habor-sdk';
import * as React from 'react';
import { Button, Text } from 'react-native';
import { DatePicker } from '../../../packages/kelp-bar/date-picker';
import { haborSDK } from '../hessia-plugin/config';
import { DavelField } from '../../../packages/davel-ui/davel-ui-tools';


//  TODO:  Add this to an API for this sytem.
//  NOTE:  Remember, things like CRUD are FUNDAMENTAL "Operations" on SYMBOLS and associations and shit!? HM!
//  THOUGHT:  AH!!!  I'd LOVE to have CONTEXTUAL FUNCTIONS, which PERHAPS exist ina CoNTEXT and have access to that shit, LIKE a closure, BUT explicit!? HM!  THEN, we can do things like access particular baiables nd expct  CONTEXTUAL interface!? HM!  LIKE a TOKEN!
//  TODO:  Create or Update!!
export const createDueDate = async (target: InstanceID, date: string, token: string) => {
  const dueDateInst = await haborSDK.createInstance<{ Target: HDTRelationshipValue, Date: string }>({
    nounId: "duedate",
    payload: {
      Target: {
        instanceIdList: [{
          nounId: target?.nounId,
          instanceId: target.instanceId,
          type: EntityType.Instance
        }]
      },
      Date: date
    }
    
  }, token);
  alert("Due Date Created: " + date);
  return dueDateInst;
}

export const dueDateCreator: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Due Date Creator",
  color: "#719367",
  description: "Creates a Due Date",
  inputSchema: { type: "object", extensible: false, properties: { target: { type: instanceInternalSchema }, date: { type: "date"} } },
  outputSchema: {type: "object", properties: { dueDate: { type: "any" } } },
  renderer: ({ frameworkParams, target, date }: { frameworkParams: FrameworkParamsNew, target?: InstanceInternal, date?: string }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    if (!target || !date) { return null }

      React.useEffect(() => {
        haborSDK.createInstance<{ Target: HDTRelationshipValue, Date: string }>({
          nounId: "duedate",
          payload: {
            Target: {
              instanceIdList: [{
                nounId: target?.nounId,
                instanceId: target.id,
                type: EntityType.Instance
              }]
            },
            Date: date
          }
        }, token).then(dueDate => {
          setOutput({ dueDate }, blockInst);
        })
    }, [target, date]);

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

export const dueDateQuery: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Due Date Query",
  color: "#719367",
  //  TODO:  Should we only support one at this level?
  description: "Returns the Due Dates for an Object",
  inputSchema: { type: "object", extensible: false, properties: { instance: { type: instanceInternalSchema }} },
  outputSchema: {type: "object", properties: { dueDates: { type: "array", itemType: "any" } } },
  renderer: ({ frameworkParams, instance }: { frameworkParams: FrameworkParamsNew, instance?: InstanceInternal }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    if (!instance) { return null }

      React.useEffect(() => {
        haborSDK.searchInstances<{ Target: HDTRelationshipValue, Date: string }>(token, {
          nounId: "duedate",
          search: {
            match: {
              payload: {
                Target: {
                  instanceIdList: {
                    instanceId: instance.id
                  }
                }
              }
            }
          } as any
        }).then(dueDates => {
          setOutput({ dueDates }, blockInst);
        })
    }, [instance]);

    return null;

  },
  tags: ["inline"],
  isComponent: false
}

export const dueDateInstanceView: PrimitiveBlock = {
  type: BlockType.Primitive,
  name: "Due Date Instance View",
  color: "#719367",
  description: "Shows a Due Date for an Instance",
  inputSchema: { type: "object", extensible: false, properties: { instance: { type: instanceInternalSchema }} },
  outputSchema: {type: "object", properties: { } },
  renderer: ({ frameworkParams, instance }: { frameworkParams: FrameworkParamsNew, instance?: InstanceInternal }) => {

    //  Unpack
    const { setOutput, frameworkContext: { token }, blockInst } = frameworkParams as any;

    // const [dueDates, setDueDates] = React.useState<InstanceInternal<{ Target: HDTRelationshipValue, Date: string }>[]>([]);
    const [dueDate, setDueDate] = React.useState<Date | undefined>(undefined);
    const [newDueDate, setNewDueDate] = React.useState<Date>(new Date());
    const [hasMultiple, setHasMultiple] = React.useState<boolean | undefined>(undefined);

    if (!instance) { return null }

      React.useEffect(() => {
        haborSDK.searchInstances<{ Target: HDTRelationshipValue, Date: string }>(token, {
          nounId: "duedate",
          search: {
            match: {
              payload: {
                Target: {
                  instanceIdList: {
                    instanceId: instance.id
                  }
                }
              }
            }
          } as any
        }).then(dueDates => {
          if (dueDates && dueDates[0]) {
            const dateStr = dueDates[0].payload.Date;
            if (dateStr) {
              setDueDate(new Date(dateStr))
            }
            if (dueDates.length > 1) {
              setHasMultiple(true);
            }
          }

        })
    }, [instance]);

    return (
      <DavelField name="Due Date">

        <DatePicker date={ newDueDate } onUpdate={ (date) => setNewDueDate(date) } />
        <Button title="Save Due Date" onPress={ () => createDueDate({ instanceId: instance.id, nounId: instance.nounId, type: EntityType.Instance }, newDueDate.toISOString(), token) } />

        {
          !dueDate ?
            <Text>No Due Date</Text> :
            null
        }

        {
          hasMultiple ?
            <Text>Multiple Due Dates not Permitted</Text> :
            null
        }

        {
          !!dueDate ?
            <Text>{ new Date(dueDate).toLocaleDateString() }</Text> :
            null
        }

      </DavelField>
    );

  },
  tags: ["inline"],  //  TODO:  Upate tag
  isComponent: true
}