import { InstanceInternal, NamedObject } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { HabitsHaborComponent, HabitsHaborPrimitive, WorkspaceHabits } from "./habit-page";

export const HabitPluginContext = React.createContext<HabitPlugin>({} as any);
export const useHabitPlugin = () => {
  return React.useContext(HabitPluginContext);
}

export class HabitPlugin extends CorePluginClass {

  public borderColorator?: (habit: InstanceInternal<NamedObject>, token: string) => any = undefined;

  public static details = {
    name: "Habit",
    description: "Habit Plugin",
    dependencies: [HessiaPlugin.details.id, SystemPlugin.details.id, AuthPlugin.details.id],
    id: "habit"
  }

  public install = (program: Program, { hessia, system, authPlugin }: { hessia: HessiaPlugin, system: SystemPlugin, authPlugin: AuthPlugin }) => {

    authPlugin.onLogin((token) => {
      system.registerPrimitiveSystem({
        name: "Habit",
        description: "Track Your Habits",
        id: "habit",
        component: () => <WorkspaceHabits token={ token } />,
        menuItem: {
          icon: { name: "check-circle", type: "feather" },
          iconColor: "#acf7fa",
          backgroundColor: '#49dde3'
        },
        labels: ["hessia"]
      })
    });



    //  Consider a BORDER Color for my things.. I SUPPOSE this is a UI I can use.
    hessia.registerPrimitiveComponent("HabitsHaborPrimitive", HabitsHaborPrimitive);
    hessia.registerComponent(HabitsHaborComponent);

    hessia.registerContext(HabitPluginContext, this);

    return this;  // TODO-CRITICAL:  This is TOO easy to forget!  We want to package this!!!
  }

  //  NOTE:  This is ONE example of the type of thing I'd like to change with my system!?  THIS is explicit with a primitive system.  BUT if we're using HABOR components / blocks, maybe we can use their context and stuff??
  //  I think ONE point is that we'd want to be doing this IN Habor, not typically in the primitive code?  BUT, perhaps I add support for the "Colorator" in the primitive code?  Hmm...

  //  So I COULD register the "Colorator" in the primitive code, BUT that doesn't do us much good right.. that means we're building PLUGINS that extend plugins from PRIMITIVE code.. I want to extend using Habor code and shit.
  //  Hmmm... 

  //  TODO-NEXT:  HOW can I access the Habit Plugina nd other plugins FROM this?  I WOULD like to inject it.

  //  I think I should support BOTH and eventually support user installed plugins with native code.  Ok, FOR NOW, I think this is OK!  Then I can keep pushing on it.
  //  NOTE:  This FUNCTION is a way to add a layer of control between the lower level class / property system and the user of that property!?  BOTH are like functions though Hmm..
  public registerBorderColorator = (colorator: (habit: InstanceInternal<NamedObject>, token: string) => any) => {
    if (this.borderColorator) { throw new Error("Cannot register more than one colorator"); }
    this.borderColorator = colorator;
  }

  public getBorderColorator = () => {
    return this.borderColorator;
  }
}
