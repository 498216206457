
import * as React from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';
import { Paragraph } from './constants';

export const Chiclet = ({ children, containerStyle = {}, textStyle={} }: { children: any, containerStyle?: ViewStyle, textStyle?: TextStyle }) => {

  return (
    <View style={{ paddingHorizontal: 12, paddingVertical: 6, borderRadius: 13, display: 'flex', flexDirection: 'row', height: 26, alignItems: 'center', justifyContent: 'center', ...containerStyle }}>
      <Paragraph style={ textStyle }>
        {children}
      </Paragraph>
      
    </View>
  );
}