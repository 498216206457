
import { FrameworkContext, HaborComponentContext } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';
import { AddonHandler, AddonHandlerWithSchema } from '../../model-plugin/entity/instance-creator';
import { medSpacer } from '../../../../packages/kelp-bar/styles';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';

import { HaborComponent } from "habor-sdk";


export const AddonHaborComponentProps = {
  addon: { type: "symbol" as "symbol", scopePath: ["props", "addon"] },
  setValue: { type: "symbol" as "symbol", scopePath: ["props", "setValue"] },
  value: { type: "symbol" as "symbol", scopePath: ["props", "value"] }
};

export interface AddonUserProps {
  addon: AddonHandlerWithSchema;
  setValue: (value: any) => void;
  value?: any;
}

export interface AddonProps extends AddonUserProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
}

interface AccessAddonHandlerProps extends AddonProps {}

interface AccessAddonHandlerState {}

class AccessAddonHandler extends React.Component<AccessAddonHandlerProps, AccessAddonHandlerState> {

  constructor(props: any) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext, addon: { schema, id }, setValue, value } = this.props;
    const { frameworkContext: { changePage } } = this.props;

    //  TODO:  Concern... what if we have the SAME addon mounted in several locations?  Would we use the same Redux form?  I REALLY don't like Redux Form, I think I'd prefer the state be local to the initializer???
    return (
      <View style={{ flex: 1, padding: medSpacer }}>
        {/* <Text>TODO:  FIX WHATEVER BIZZARE ISSUE IS STOPPING THE DAVEL FORM... MAYBE it's just Cyclic Dependencies?</Text> */}
        <DavelForm value={ value } autoSubmit={ true } schema={ schema } onSubmit={ setValue } onCancel={ () => alert("canceled") } />
      </View>
    );
  }
}

//
//  AccessAddonHandler Primitive Component
//
interface AccessAddonHandlerHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: AddonUserProps;
}
export const AccessAddonHandlerHaborComponentPrimitive = ({ userProps: { addon, setValue, value }, frameworkProps }: AccessAddonHandlerHaborComponentPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <AccessAddonHandler value={ value } setValue={ setValue } addon={ addon } frameworkContext={ context } componentContext={ componentContext } />
    </HaborContainer>
  );
};


export const AccessAddonHandlerHaborComponent: HaborComponent = {
  name: "AccessAddonHandlerHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "AccessAddonHandlerHaborComponentPrimitive",
    props: AddonHaborComponentProps,
    children: []
  }
};


//  NOTE:  Currently, each Addon controls a "slice" of the Instance "state".  In the future, perhaps we instead want to conceptualize this as a chain of "filters" which go through, one by one, to reach a final value, where each step doesn't necessarily map to a single class!  FOR NOW, I think this is OK!  MAYBE in that the later conceptualization, we can pull out named properties, or even affect remote properties?? 
//  IDEA:  Maybe add a checkbox to the addons when they are filled out.  Otherwise, we won't be able to create the new element.  In this case, we're giving each addon a class ot deal with... This makes it easier to construct the final object?  MAYBE we just process them in order?

//  Define the "Access" Addon
export const accessAddon: AddonHandler = {
  id: "access",
  name: "Access",
  description: "Manage Object Permissions",
  className: "access",
  icon: { name: "group-work", type: "material" },
  component: AccessAddonHandlerHaborComponent
}
