import { CorePluginClass, Program } from 'halia';
import React from "react";
import { ActivityIndicator } from 'react-native';
import {
  PlaidLinkOptions, usePlaidLink
} from 'react-plaid-link';
import { GalleryButton } from '../gallery/components/common';
import { primaryColor } from '../gallery/constants';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { haborHost } from './hessia-plugin/config';
import { SystemPlugin } from './system-plugin/system-plugin';

//  TODO-CRITICAL:  Remove this from the frontend!

// const App = () => {
//   const { linkSuccess, isItemAccess, dispatch } = useContext();

//   const getInfo = useCallback(async () => {
//     const response = await fetch("/api/info", { method: "POST" });
//     if (!response.ok) {
//       dispatch({ type: "SET_STATE", state: { backend: false } });
//       return { paymentInitiation: false };
//     }
//     const data = await response.json();
//     const paymentInitiation: boolean = data.products.includes(
//       "payment_initiation"
//     );
//     dispatch({
//       type: "SET_STATE",
//       state: {
//         products: data.products,
//       },
//     });
//     return { paymentInitiation };
//   }, [dispatch]);

//   const generateToken = useCallback(
//     async (paymentInitiation) => {
//       const path = paymentInitiation
//         ? "/api/create_link_token_for_payment"
//         : "/api/create_link_token";
//       const response = await fetch(path, {
//         method: "POST",
//       });
//       if (!response.ok) {
//         dispatch({ type: "SET_STATE", state: { linkToken: null } });
//         return;
//       }
//       const data = await response.json();
//       if (data) {
//         if (data.error != null) {
//           dispatch({
//             type: "SET_STATE",
//             state: {
//               linkToken: null,
//               linkTokenError: data.error,
//             },
//           });
//           return;
//         }
//         dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
//       }
//       localStorage.setItem("link_token", data.link_token); //to use later for Oauth
//     },
//     [dispatch]
//   );

//   useEffect(() => {
//     const init = async () => {
//       const { paymentInitiation } = await getInfo(); // used to determine which path to take when generating token
//       // do not generate a new token for OAuth redirect; instead
//       // setLinkToken from localStorage
//       if (window.location.href.includes("?oauth_state_id=")) {
//         dispatch({
//           type: "SET_STATE",
//           state: {
//             linkToken: localStorage.getItem("link_token"),
//           },
//         });
//         return;
//       }
//       generateToken(paymentInitiation);
//     };
//     init();
//   }, [dispatch, generateToken, getInfo]);

//   return (
//     <div className={styles.App}>
//       <div className={styles.container}>
//         <Header />
//         {linkSuccess && isItemAccess && (
//           <>
//             <Products />
//             <Items />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

export class PlaidPlugin extends CorePluginClass {

  public static details = {
    name: 'Plaid Plugin',
    description: 'Plaid Plugin',
    dependencies: [AuthPlugin.details.id, SystemPlugin.details.id],
    id: 'plaidPlugin'
  }

  public install = (program: Program, { authPlugin, system }: { authPlugin: AuthPlugin, system: SystemPlugin }) => {

    authPlugin.onLogin(({ user, token: haborToken }) => {

      system.registerPrimitiveSystem({
        id: "plaid",
        name: "Plaid",
        description: "Plaid Plaids",
        menuItem: {
          backgroundColor: '#026358',
          icon: {
            name: "money",
            type: "material"
          },
          iconColor: "#619367"
        },
        labels: ["integrations"],
        component: () => {


          const PlaidComponent = ({ linkToken }: { linkToken: string }) => {

            // The usePlaidLink hook manages Plaid Link creation
            // It does not return a destroy function;
            // instead, on unmount it automatically destroys the Link instance
            const config: PlaidLinkOptions = {
              onSuccess: (public_token, metadata) => { },
              onExit: (err, metadata) => { },
              onEvent: (eventName, metadata) => { },
              token: linkToken,
              //required for OAuth; if not using OAuth, set to null or omit:
              receivedRedirectUri: 'https://hessia.services.oranda.io/redirect.html',
            };

            const { open, exit, ready } = usePlaidLink(config);
            return <GalleryButton onPress={open as any} title='Open Plaid' />
          }

          const [token, setToken] = React.useState<string | undefined>(undefined);

          const getPlaidLinkToken = async () => {
            const res = await fetch(`${haborHost}/link-token`, {
              method: 'POST',
              body: JSON.stringify({ client_name: "William Sullivan", client_user_id: "wrsulliv" }),
              headers: {
                "x-access-token": haborToken,
                "Content-Type": "application/json"
              }
            });
            const json = await res.json();
            setToken(json.link_token);
          }

          React.useEffect(() => {
            getPlaidLinkToken();
          }, []);

          return token ? <PlaidComponent linkToken={token} /> : <ActivityIndicator color={primaryColor} />
        }
      })
    })



    return this;
  }
}