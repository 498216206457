
import { NavigationProp, useNavigation, useRoute } from "@react-navigation/core";
import { createStackNavigator } from '@react-navigation/stack';
import { NamedObject } from 'habor-sdk';
import { CorePluginClass, Program } from "halia";
import moment from "moment";
import * as React from 'react';
import { ActivityIndicator, Button, Text, TouchableOpacity, View, ScrollView } from "react-native";
import { Icon } from "react-native-elements";
import { withRouter } from 'react-router';
import { renderRGBAColor } from "../../../packages/davel-ui/habor-types/color/color-field";
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { HaborNounService, INounService, NounServiceInstanceInternal, AsyncStorageNounService } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { Named } from "../entity-plugin";
import { HaborPlugin } from "../habor-plugin";
import { SystemPlugin } from "../system-plugin/system-plugin";

//  TODO:  Update Halia!

//  TODO:  Support BOTH interval events AND point events hm!

export interface TimePoint {
  time: Date;
}

export interface TimeInterval {
  start: Date;
  end: Date;
}

export interface EventBase extends NamedObject {
  type: "point" | "interval";  //  TODO:  Inject this?
  systemId: string;
}

//  NOTE:  This is the SAME kind of thing I've done in SO many places.. like the IFT Assets thing which I did on my own time hm! 
export interface PointEvent extends EventBase, TimePoint {
  type: "point";
}
export interface IntervalEvent extends EventBase, TimeInterval {
  type: "interval";
}

export type Event = PointEvent | IntervalEvent;

interface EventDetailProps {
  events: NounServiceInstanceInternal<{}>[];
  eventPlugin: EventPlugin;
  navigation: NavigationProp<any>;
}

class EventDetailBase extends React.Component<EventDetailProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      events: []
    }
  }

  componentDidMount = async () => {

    //  Load Events
    const events = await this.props.eventPlugin.eventService.retrieveAll();
    this.setState({ events });
  }

  //  Filter / Search Plugin
  //  Plugins that work WITH Filter / Search
  //  Plugins that inject element into the main view? HM!  MAYBE make GENERIC view elements SIMILAR to DOM? HM!
  render() {

    const eventElems = this.state.events.map((event: NounServiceInstanceInternal<{}>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("detail")}>
          <Text>{event.id}</Text>
          <Text>{event.created}</Text>
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
          {/* <View style={{ backgroundColor: 'blue' }}>
          { component }
        </View> */}

          {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Events</Text>

            {/* TODO:  Abstract with Hessia? */}
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>

          {/* Top Register */}
          <View>
            {topRegister}
          </View>

          {eventElems}
        </Page>
      </PageLoader>
    )
  }
}

const InjectedEventDetail = (props: any) => <EventDetailBase {...props} />
export const EventDetail: any = withRouter(InjectedEventDetail);

/**
 * Sets the Event in the context of the editor.
 */
const setEvent = async (eventPlugin: EventPlugin, navigation: any, event?: any) => {

  if (event) {
    event = await eventPlugin.eventService.update(event.id, {});
  } else {
    event = await eventPlugin.eventService.create({});
  }

  navigation.navigate(EventRoute.Editor, { event });
}

export const EventEditor = ({ eventPlugin }: { eventPlugin: EventPlugin }) => {

  const navigation = useNavigation();
  const route = useRoute<any>();

  const { params } = route;

  const event = params?.event;

  return (
    <View style={{ marginTop: 100 }}>
      <Text>ID</Text>
      <Text>{event?.id}</Text>
      <Button title={event ? 'Update' : 'Create'} onPress={() => setEvent(eventPlugin, navigation, event)} />
    </View>
  );
}



//  NOTE:  We CAN provide events through a HOOK or through an HOC.  BOTH are just linking into the state tree and reacting to changes in props? Hmm.. the HOOK will use state, and yeah, be able to respond to a chagne of prop hm!
//  TODO:  Build the Providers to separate querying from display?

/**
 * Event Provider
 * 
 * Provides Entitis in the current context.
 */
// export const EventProvider = 






//  TODO:  MAYBE I can make COMPONENTS which have Top, BOttom, Left, and Right registers? Hmm... VERY similar to the DOM in a way? Hmm...
//  TODO:  HOW will we REGISTER the view registerrs? Hmm
// export const PluggableView = () => {
// }

export const topRegister: any[] = [];

//  Make the Event Model
// export interface Event {
//   name: string;
//   description: string;
// }


//  CONCERN:  I'm going to do the "staking" here.  I worry this is a big mistake... something tells me this is a big mistake... but I'm going to do it anyways.  Maybe we can separrate out to a "staking" system later... OR build something even more complex.  But FOR NOW, I'm going to put the stakes here.
//  CONSIDER:  We COULD also into a "Caller"... MAYBE this is something we INJECT with a coupling system thoguh hm!
//  CONSIDER:  DO we actually need to STORE this?  CAN each SYSTEm jut INJECT its events in the UI and store them themselves?? Hmm... perhaps! 
//  FOR NOW:  Let's start simple and let the event have a SYSTEM ID which it will use to LINK back ot the calling system? Hmm.. MAYBE we CAN support a "caller" and keep it SUPER generic? hmm.. ANYthing can perhaps be a caller if it can be interpreted?
// export interface Event {
//   caller: string;
//   description: string;
//   type: string;
// }


// export const eventService = new LocalNounService("event");
//  CONSIDER:  Make the storage location a user preference for various contexts?  MAYBE support MULTIPLE? Hmm...


//  CONSIDER:  INSTEAD of using the ENTITY SERVICIE... why not GENERALIZed the WHOEL thing and  let the plugins inject piecemeal?? Hmm..

// export interface EventService {

// }


//  HERE's an example of where we can inject.. HOW though will we do this? Hmm... perrhaps LOTS of ways, BUT the idea is, MAYBE it's a LABEL associated with a more generic RENDER function! HM!  Maybee... 
//  THe benefit to doing it that way is we'll have a "renderer".. OR more generally, LIKE in Habor, a FUNCTION resolver which will go out to the staking systems Hmmm... HOW does it KNOW?  Well... FOR NOW, it'll just trigger them all.. hmmm!  Then it's like a THING that's constrructed? Hmm I ALREADY thought about htis a bit last year.. hmmm  MAYBE this should be added by the "Compoennt" system? Hmm... The idea of "Render"? hmm.. idk.  The point though, is idk.. if we just have this ONE symbol, then how do we know what to trigger when we get to sub-things hmm... MAYBE we do this for now and expand out.. fuck.
//  NOTE:  Perhaps the order is in Plugin registration order FOR NOW? Hmm... LOTS of ways we can do this LOTS of ways.. hmm... mabe only ONE that "matters"? Hmm.. idk man
//  NOTE:  I WOULD like to generalize to NAMED "functions" I think... hmmm... pehaps.. THEN maybe a system can be used to build user functions and stuff? Hmm..  MAYBE we call these ACTIONS? Hmmm... I think I'll call them... things? Hmmmmmm... FOR NOW... i'll call the damn things functions.

// const resolvers: { [name: string]: any } = {};
// export const registerResolver = (name: string, resolver: any) => {
//   resolvers[name] = resolver;
// }

// //  NOTE:  MAYBE we can even have systems CONSTRUCTING this CONTEXT as ONE such "revsolver"? HM!
// export const resolve = (name: string, params: any) => {

//   for (let i = 0; i < resolvers.length; i++) {
//     const resolver = resolvers[i];
//     resolver[i](context, value);
//   }
// }


// const functions: string[] = [];
// export const registerFunction = (funcName: string) => {
//   functions.push(funcName);
// }

//  THOUGHT:  MAYBE the idea is like.. infinite CONTEXT being passed to the function.. then WHY even have a NAME?  WHY not just pass THAT as context? HM!!


// registerFunction("render");

//  NOTE:  DOn'T like that I need to manually add libs for the types and stuff.. SEEMS like it should happen automatic.. hmm

//  CONSIDER:  Instead of a STATIC set of possible registeration points, consider controlling this DYNAMICALLY so plugins can change this too.  THEN again, the plugins work together to build the final encoding which is interpreted to build the state encoding? Hmm...

export type EventListItemComponent = React.Component<{ event: NounServiceInstanceInternal<any> }, any, any> | React.FunctionComponent<{ event: NounServiceInstanceInternal<any> }>;

export interface EventListItemAPI {
  setText: (text: string) => void;
  addComponent: (comp: EventListItemComponent) => void;  //  NOTE:  Instead of regiseing with a function this module could reach out to a specific place with this stuff.. or it can pull just the encodings/ hmm...
}

//  CONSIDER:  AOK!!  CURRENTLY it's JUST on the ID.. BUT we might want to key off TONSS of stuff, AND that stuff isn't necessarily "returned" to the fucking UI.. SO!! We want the SERVICE to process each perhaps and then determine? O... maybe the services just inject DIRECLT when they feel something is an Eventt? HMM... Then it's also up to the services to deal with global paging and like yea hmm... I think I like that..hmm  KINDA like the register for the thin gbut soo much more j ljerj
//  TODO:  Consider WRAPPING this in an object so we have insight into the renderer from the Application hmmmm... I'd LIKE to be able to SEE the renderers that have been registered, etc hmmm!!
//  NOTE:  We MAY also owant to make MULTIPLE renderers for various parts of the app hm!
export interface EnityListItemRenderer extends Identifiable, NamedObject {
  renderer: (event: NounServiceInstanceInternal<any>, api: EventListItemAPI) => (Promise<void> | void);
}

//  TODO:  Do this in the ENEITY plugin!
const eventListItemRenderers: EnityListItemRenderer[] = [];
export const registerEventListItemRenderer = (renderer: EnityListItemRenderer) => {
  eventListItemRenderers.push(renderer);
}



//  TODO:  Should we allow INJECTION of functionality to extend the renderer API itself?  WHEN does it end.... never.  The answer is never.  We can ALWAYS choose to build an encoding that then influences another.  We can ALWAYs do that...  So... if a PROGRAM is ONE encoding... is it inevitable that we CHANGE the encoding manuallY? Hmm... I think it's up to us where we want to abstrac?? Hmm...

//  CONCERN:  Do I REALLY want explicit functions like this all over the place.. hmm  MAYBE use something like Habor so they can be accessed "dynamically" at runtime? mmm... idk man.  Meaning, the user can end up specifying a string to them? hmm.. MAYBE we could use reflection or soemthing, but idk abotu that either.
//  CONCERN:  Currently, the systems need to search for EACH possible instance.. that could take a LONG time.  I believe we can do this more efficneitly!  AND have data ASSOCIATING them perhaps.. hmm  MAYBE even just logging the primtiive system.  THen having it register and passing it ONLY the ones we need? Hmm.. FOR NOW, I'm avoiding premature optimiztaion!

//  NOTE:  This is for systems to hook into an event and change the way it's displayed.  Perhaps they can also wrap and have their own injections hmm...
//  NOTE:  It's up to the systems how they want to construct the Payload.  They MAY have to go back out to handle corner-cases, but the idea is, the payload is useful for not re-querying.  THen then can inject as they please where the payload is the ontological strucutre hmm... We START with each one... MAY want to support GROUP things as well? Hmm... like a renderer with access to ALL of them? idk though.. it's STILL just a subset right ?  UNLESs we yeah.. like give it a symbol if there are greater than 25 TOTAL in the DB meeting a condition hmm... that's interesting and somethin gwe can probably add to the event payload for THIS systems' renderer hmmm...
export const renderEventListItem = async (event: Event) => {

  const color = event.color ? renderRGBAColor(event.color) : '#888888';

  return (
    // TODO:  UGHHH!!! I do the SAME shit making cards CONSTANTLY!  Build a SUPER generic CARD SystemPlugin.details. where we can add .. yes.. POSSIBLY TYPES to change the wway it looks.. but again.. it's more like MODIFIERS!  THese are THINGS that change the API of the thing hm!! I REALLY ewant hits!!  I ALSO want to go through TrackMine / Hessia and CATELOG alllll the shit I've done so far.... IN fucking Hessia lol dhfm!
    //  TODO:  SHOULD be able to display in MULTIPLE ways that are injected and shit >??  ANDDD takes input from the CONTEXT HM!!!  THis is the main thign!  EVER THING is jut a THING in a WEB and we have a FINITE number of systems which determine what will happen? Hmlksdf mYABE/// MAYBE we can make it infinite too?? Hmm... interesting... then PROGRESSIVELY display hm!jlkjdf
    <View style={{ backgroundColor: 'white', padding: 30, borderRadius: 30, display: 'flex', flexDirection: 'row' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Icon  type={event.icon?.type} name={event.icon?.name || "unknown"} />
        <Text>{event.name}</Text>
      </View>

      {/* CONSIDER:  We can SHOW the interval AND perhaps events that happened WITHIN IT HM! */}
      { event.type === "interval" ? <><Text>{moment(event.start).fromNow()}</Text><Text>{ moment(event.end).fromNow() }</Text></> : null }
      { event.type === "point" ? <><Text>{moment(event.time).fromNow()}</Text></> : null }

    </View>
  );
}

//  TODO:  Currently the local EventService uses LocalNounService, which is implemented with local storage.
//         But, the HaborEventService is implemented with ModelService... and we have wrapper for that.. so.. the idea is to implemented LocalEvent the SAME way? Hmm.. the real problem is.. the ModelService is built with Noun for local, BUT it's fucking using the SDK direclty remote.  Instead, I'd like to use Noun Service for both? Hmm.. This MAY not be as efficient thouguh, because then fucking nounu / instance are separte and not using the index model in Elastic? Hmmm... MAYBE that's FINE for now/? HM!

export interface IEventService extends INounService<Event> { }

export class LocalEventService extends AsyncStorageNounService<Event> implements IEventService {
  constructor() {
    super("event");
  }
}

// export class HaborEventService implements IEventService {

//   constructor(protected haborSDK: HaborSDK, protected token: string) {}


// }

// export const eventService = 
// eventService.init();

// export const remoteEventService = new HaborModelSDK(haborSDK, )


//
//  Renders an Asynchronous Component
//  NOTE:  Defaults to "def".
//  CONCERN:  I'm NOT a big fan of this... it's jut a component which calls a function .. a component, to return elements.  The PROBLEM is, the lifecycle methods won't be activated for the sub-component.  Instaed I think we should perhaps just do it normally hmmm...
//  TODO:  Get rid of this!  I think it IS a reasonable abstraction idea, but in practice with lifecycle methods it's limiting, and like.. just isn't clear and like.. MAYEB we can abstract at a DIFFERENT opint to make this easy to write.. compresss hm!
//
export interface DeferredProps {
  func: any;
  args: any;
  def: any;
}
interface DeferredState {
  res: any;
}
class DeferredBase extends React.Component<DeferredProps, DeferredState> {
  constructor(props: DeferredProps) {
    super(props);
    this.state = {
      res: undefined
    }
  }
  public componentDidMount = async () => {
    const { func, args } = this.props;
    const res = await func(args);
    this.setState({ res });
  }
  public render = () => {
    const { def } = this.props;
    const { res } = this.state;
    return res || def;
  }
}
export const Deferred = DeferredBase

interface EventListState {
  events: Event[];
  loaded: boolean;  //  CONSDIER:  Can use "dirty" as well, as a slightly different scenario.. differentiated, and we can consider injecting loading as a pattern hmm.. MAYBE let us DECLARE our loaded condition? Hmmm...  Maybe use Hooks?  BUT even then, it's not totally composable.. hm... Mixins aren't either because they JUST add to the functional interface, they don't support injection and yeah hM! We want ANOTHER abstraction layer to "BUILD" thefunction and the encodings, whatever they may be hm!
}
class EventListBase extends React.Component<{ navigation: NavigationProp<any>, eventPlugin: EventPlugin }, EventListState> {

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loaded: false
    }
  }

  componentDidMount = async () => {

    //  Load Events
    //  TODO:  Instead of loading ALL... get a SAMPLE or like.. PAGE HM!  The IDEA is.. we ONLY have to go as far as we went the firsst time and then skip those the next interesting hm!! MAYBE grab in BATCHES ? Hmmm... do want a unified interface thogh.. KEEP pressing hm!
    const events = await this.props.eventPlugin.getEvents();
    this.setState({ events, loaded: true });
  }

  //  THOUGHTS:  Show VIEWS, maybe SUGGESTED, etc.. lots of things hat CAN make sense and MAYBE also show the GENERIC object view? Hmm MAYBE ltos of systems intergere with that? Hmmm...  Like.. maybe if ONE suggests a "FieldSet",, ANOTHER can add more and stuff? hmm.. idk. and mayeb delte? hm idk.. hmmm
  // public updateEvents = async () => {

  //   //  NOTE:  INSTEAD of passing everything... MAYBE it's based on the CONTEXT / STATE of the systems IN that context?? Hmmm... perhapssss!  This way we can have mnultipole states / contexts and stuff???? Hmm..
  //   const res = resolve({ resolv })
  // }

  //  Filter / Search Plugin
  //  Plugins that work WITH Filter / Search
  //  Plugins that inject element into the main view? HM!  MAYBE make GENERIC view elements SIMILAR to DOM? HM!

  render() {

    const { navigation } = this.props;

    //  TODO:  Update the name lolhaha
    const { events, loaded } = this.state;

    const eventElems = events.map((event: Event) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.navigate(EventRoute.Editor, { event })}>

          <Deferred func={renderEventListItem} args={event} def={<ActivityIndicator />} />
          {/* <Text>{ itemText }</Text> */}
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={!loaded}>
        <Page style={{ marginHorizontal: 20 }}>
          <ScrollView>
            {eventElems}
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedEventList = (props: any) => <EventListBase {...props} />
export const EventList: any = InjectedEventList;

const Stack = createStackNavigator();

export enum EventRoute {
  Editor = "Editor",
  List = "List"
}

export const EventNavigator = ({ eventPlugin }: { eventPlugin: EventPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      {/* <Route exact={ true } path="/home/events/detail" component={ EventDetail } /> */}
      <Stack.Screen name={EventRoute.Editor} component={() => <EventEditor eventPlugin={eventPlugin} />} />
      <Stack.Screen name={EventRoute.List} component={({ navigation }) => <EventList navigation={navigation} eventPlugin={eventPlugin} />} />
    </Stack.Navigator>
  );
}


export interface Identifiable {
  id: string;
}

//  TODO:  We have this pattern EVERYWHERE!! We want to TRACK.. with ENTITY features the THIGNS... LIKE PROVIDERS and shti !  
export interface EventProvider extends Named {
  systemId: string;
  provide: () => Promise<Event[]>;
}
export class EventPlugin extends CorePluginClass {
  public static details = {
    name: "Events",
    description: "Event Primitive System",
    dependencies: [SystemPlugin.details.id, AuthPlugin.details.id, HaborPlugin.details.id],
    id: "eventPlugin"
  }

  //  TODO:  Inject Local / Habor Event Service... MAY also be able to just remove these!  These were for when we made explicit Event IDs hmm...

  // public localEventService: NounService<any> = {
  //   service:  new LocalNounService("event"),
  //   name: "LocalEvent",
  //   id: "local-event",
  // }

  // protected habor!: HaborPlugin;
  // protected habor!: HaborPlugin;

  // public eventService = new MultiNounService("event", [this.localEventService], this.localEventService.id);
  public eventService!: HaborNounService<any>;

  public eventProviders: EventProvider[] = [];
  public registerProvider = (provider: EventProvider) => {
    this.eventProviders.push(provider);
  }

  //  TODO:  We can GENERALIZE the PROVIDER pattern? Hmksldf .. PLUS use mOdifierd for differentnatoin? sadf
  public getEvents = async () => {
    const events: Event[] = [];
    for (const provider of this.eventProviders) {
      const _events = await provider.provide();
      events.push(..._events);
    }
    return events;
  }

  //  TODO:  We need to initialize with the type... 
  public install = (program: Program, { system, authPlugin, habor }: { system: SystemPlugin, authPlugin: AuthPlugin, habor: HaborPlugin }) => {

    this.eventService = new HaborNounService<{}>("event", habor.haborSDK);
    this.eventService.init(authPlugin.token);

    system.registerPrimitiveSystem({
      id: 'event-system',
      labels: ["core"],
      name: "Event",
      description: "Event Primitive System",
      component: () => <EventNavigator eventPlugin={this} />,
      headerRight: () => {

        const navigation = useNavigation();

        return (
          <TouchableOpacity onPress={() => navigation.navigate(EventRoute.Editor as any)} style={{ width: 34, height: 34, borderRadius: 17, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center', marginRight: 15 }}>
            <Text style={{ color: 'white', fontSize: 25, fontFamily: "Poppins-Bold", paddingTop: 1, paddingLeft: 1 }}>+</Text>
          </TouchableOpacity>
        )
      },
      menuItem: {
        icon: { name: "timeline", type: "material-community" },
        backgroundColor: "#28d1d1",
        iconColor: "white"
      }
    });

    //  TODO:  Make this s part of the framework, OR give helpful error messges hm!  EITHER way... it's up to us to define the "API"... the "INTERFACE" hm!
    return this;
  }
}
