import { Color, FrameworkContext, HaborComponent, HaborComponentContext, InstanceInternal, Relationship, RelationshipNoun, SearchTerm, EntityType, Instance, NamedObject } from 'habor-sdk';
import * as _ from 'lodash';
import * as React from 'react';
import { ScrollView, View } from 'react-native';
// import { Pages } from 'react-native-pages';
import { haborSDK } from '../hessia-plugin/config';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../packages/kelp-bar/kelp-icon";
import { TextH4 } from '../../../packages/kelp-bar/text';
import { InstanceListItem } from '../model-plugins/named-object-plugin/named-object-list-item';

export const DraggableWidget = () => {
  return <KelpIcon type="material" name="menu" color={ Color.medGray } size={ 30 } />
}

export interface KanBanColumnProps {
  width?: number;
  instances: InstanceInternal<any>[];
  status: InstanceInternal<any>;  //  TODO:  Type with Status (OR rebuild this whole experience in Hessia).
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
}
interface KanBanColumnState {}
class KanBanColumn extends React.Component<KanBanColumnProps, KanBanColumnState> {
  constructor(props: KanBanColumnProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    //  Unpack
    const { instances, status, width, frameworkContext: { changePage }, frameworkContext, componentContext } = this.props;

    //  Make the Cards
    const cards = instances.map(inst => (
      //  CONCERN:  When we're injecting to a component, we MAY want to EXTEND it... Ahhh!!! THis is like what we did in Habor.  We BUILD the component first, THEN we "render" the function?  In THIS case below, we're just immediatly injecting it.  BUT, what if several other Plugins want to alter it first??  Instead of THIS comopnent being the main source of injection, what if we support MULTIPLE?
      //            In those situations, we have a Plugin which introduces a Component.  THEN, we have another Plugin which GLOBALLY alters that component.  MAYBE even introducing new Registration points?  THEN, we have a comopnent USER who can inject to these registration points at display time.  BUT that means we have BOTH a GLOBAL (almost static??) flow from the Plugins and THEN another dynamic injection at runtime??
      //  BIG REALIZATION:  I DON'T want to support "Priotity" from here.  Instead, the user can install a "Priority" Plugin, and THEN they will see the option to support "Priority" from the StatusPlugin!  This means that the StatusPlugin KNOWS about support for an additional Plugin!  AHHH what I'm trying to say, is that each Plugin MAY have additional, "Addon" Plugins which ONLY become available when particular dependencies are installed.  IF these had a name it might be something like "StatusPriorityPlugin", which is a combinatorial explosion.  BUT, that's OK I think, because they don't need to have names and we can pick / choose which we support?  Hmmmmm, OR maybe we do name them.  OR, we install them all with the Priority / Status Plugin?  AH... but then we're installing / registering a BUNCH of stuff we might never use.  I think it DOES make sense to keep them in separate packages.  But, maybe instead of showing them all together, we show them in a CONTEXT!  In this case, this Plugin EXTENDS not the APP but a particular PLUGIN.  So, this acts as an EXTENSION instead of a Plugin?  MAYBE an Extension IS a Plugin, but is so called, because it cross-extends or just alters an existing Plugin? Hmmm... I think it may be a false dichotomy though.  We can instead just call them Plugins and show them in the global context (if some option is applied?) OR show them in the "Extensions" part of the Status / Priority plugin!
      //                    So!  I'd kind of like to see this through!  This means that I'm setting priorities, BUT items with the same priority will be sorted based on some other condition?
      //  TODO:  Next, I'd like to: 
      //  -  Show the more detailed Instance view.
      //  -  Create the "Priority" Plugin which includes several "Priorities"
      //  -  Add the "KanBan" Component as a Component Block!
      //  -  Create ANOTHER Plugin called "StatusPriority" which depend on BOTH Status and Priority (I know plugin dependencies isn't a thing yet), which AUGMENTS the UI created by the "Status" Plugin with a NEW feature!  This means that THIS Plugin IS aware of the Feature API at that point!  We can work on this, but let's just get SOMETHING in place and working!  The main idea is to extend existing stuff, and that should mean UI, Logic, etc... MAYBE we can use multiple types of registration points?  hmmm... Maybe we can register new types of events and new "Channels"?  MAYBE most of this CAN be done in the Hessia layer??  FOR NOW, let's just keep moving forward.  MAYBE we have a rutime API and a Feature API / Static API?  Then, we can register these new pieces which then exist EVERYWHERE the component is used?  MAYBE we should be able to turn it off too?  Hmmmmm.... MAYBE we have a GLOBAL register for "registration points".  Then we can register on these points BEFORE the component loads, and the framework / component looks at this global register first, THEN merges it with the augmentations from the parent???  I LIKE this idea for now.  BUT, how will we inject a handler / event name onto which we can call / trigger when a tap / drag event occurs (in the case of draggable items)??  Well... we can potentially add "off-band" props when we register globally OR from a parent!  THis means the component will MIX props from the rendered comopnent, AND the original definition / registering module!  In other words, we can have something like this:
      //  -  Make the "Status" show in the KanBan.


      // {
      //   name: "left",
      //   props: { static props },
      //   component:  (runtimeProps) => {
      //     return (staticProps) => {
            
      //     }
      //   }
      // }
    
      //  NOTE:  Here we pass a wrapped component.  The wrapper will take the regular props, and the inner function will take the static props?  MAYBE they aren't static but come from several sources??  I DO want to be careful about that though, registering TOO many "channels" I'd LIKE to keep a primarily unidirectional data flow (which maily occurs because things are declarative instead of calling other things which MAY even call back?  Hmm... I'm not even sure this is true, BUT I want to keep the data flow manageable!).  
      //         How the heck will this work?  Well... because these are in the "registers" prop, the component knows to call a framework method to render this.  We do this by calling the framework method and passin the props + registered component.  Then, the framework method injects the props to the first one, then calls the second one with the static props.  Then, finally returns the result?  I THINK this might work.  BUT, is it more work than necessary?  It IS nice that we don't need to reach into the global directly from the component!



      // registers={{ top: [], right: [DraggableWidget], left: [] }}
      <InstanceListItem onPress={ (item) => changePage({ pageName: "Instance Editor", props: { instanceId: { nounId: item.nounId, instanceId: item.id, type: EntityType.Instance }} }) } item={ inst } frameworkContext={ frameworkContext } componentContext={ componentContext } />
    ));

    //  IDEA:  Instead of horizontally spaced cards, make them vertically stacked like the "Reminders" app!
    return (
      <View style={{ flexGrow: 1, backgroundColor: Color.lighterGray, borderRadius: 15, padding: medSpacer, marginLeft: medSpacer, marginRight: medSpacer, width }}>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextH4>{ status.payload.name }</TextH4>
        </View>
        <View style={{ height: medSpacer }} />
        <ScrollView>
          {/* TODO:  Make the cards drag / droppable. */}
          { cards }
        </ScrollView>
      </View>
    );
  }
}

//  TODO:  FOR NOW we're doing 3 items / page.  In the future, do some math based on the screen size.  We want to make sure we can fit a good number of boards before extending to a new page.  Maybe make a comopnent to generalize this practice of columns + pages based on column width??

export interface KanBanBoardProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  whitelist?: InstanceInternal<NamedObject>[]
}

export interface KanBanBoardState {
  //  CONSIDER:  Instead of grabbing the Statuses and then their targets, consider grabbing all with a single query?
  statusInsts: InstanceInternal<any>[];
  statusToTargetMap: { [statusId: string]: InstanceInternal<any>[] };
}

export class KanBanBoard extends React.Component<KanBanBoardProps, KanBanBoardState> {

  constructor (props: KanBanBoardProps) {
    super(props);
    this.state = {
      statusInsts: [],
      statusToTargetMap: {}
    }
  }

  public updateKanBan = async (props: KanBanBoardProps) => {
    //  Unpack
    const { frameworkContext: { token }, whitelist } = props;

    //  Get all the "Status" Instances
    const statusInsts = await haborSDK.searchInstances(token, { nounId: "status" });

    //  Get the "Status" Relationships (status -> inst)
    const relTerms: SearchTerm<InstanceInternal<Relationship>>[] = statusInsts.map(status => ({ match: { payload: { srcId: { instanceId: status.id } } } }));
    const targetRelationships =  await haborSDK.searchInstances(token, { nounId: RelationshipNoun.id, search: { any: relTerms } });

    //  Group the Relationships
    const instIdToStatusRelsMap: { [targetId: string]: InstanceInternal<Relationship>[] } = _.groupBy(targetRelationships, rel => rel.payload.destId.instanceId);

    //  Group by Noun
    const nounIdToStatusRelsMap = _.groupBy(targetRelationships, rel => rel.payload.destId.nounId);

    //  Get the Target Instances
    //  TODO:  Helper method / backend call to get a list of instance IDs??
    const statusToTargetMap: { [statusId: string]: InstanceInternal<any>[] } = {};
    for (const nounId in nounIdToStatusRelsMap) {
      const instTerms: SearchTerm<InstanceInternal<any>>[] = targetRelationships.map(targetRel => ({ match: { id: targetRel.payload.destId.instanceId } }));
      const instances: InstanceInternal<any>[] = await haborSDK.searchInstances(token, { nounId, search: { any: instTerms } });
      instances.forEach(inst => {

        //  Filter with Whitelist
        if (whitelist && whitelist.map(item => item.id).indexOf(inst.id) == -1) {
          return;
        }

        //  Get the Status Relationships for the current Instance
        const rels = instIdToStatusRelsMap[inst.id] || [];

        //  Add the current Instance to each applicable Status bucket
        rels.forEach(rel => {
          const statusId = rel.payload.srcId.instanceId as string;
          statusToTargetMap[statusId] = statusToTargetMap[statusId] || [];
          statusToTargetMap[statusId].push(inst);
        });
      })
    }

    //  Set State
    this.setState({ statusInsts, statusToTargetMap });
  }

  public componentDidMount = async () => {
    this.updateKanBan(this.props);
  }

  public componentWillReceiveProps = async (newProps: any) => {
    if (newProps !== this.props) {
      this.updateKanBan(newProps);
    }
  }

  public render = () => {

    //  Unpack
    const { frameworkContext, componentContext } = this.props;
    const { statusInsts, statusToTargetMap } = this.state;

    //  Make the Boards
    //  IDEA:  On mobile, when we go to select an item, it would be cool if the Statuses are shown as BUCKETS so we can place the item in that area.  THEN, it goes to that area so we can re-order??  HOW can we do this in Hessia?  Hmmm, I would suggest it's potentially just another view??  BUT, maybe we can play some tricks to make the UI look like it's morphing??
    const boards = statusInsts.map(status => {
      return <KanBanColumn frameworkContext={ frameworkContext } componentContext={ componentContext }  instances={ statusToTargetMap[status.id] || [] } status={ status } />
    })
    return (
      <View style={{ flexGrow: 1 }}>
        {/* TODO:  Disable pages because they didnn' update the RN PropTypes thing */}
        {/* <Pages>
          { boards }
        </Pages> */}
        {/* <ScrollView style={{ flexGrow: 1 }} horizontal={ true }>
          <FlatList
            data={ statusInsts }
            renderItem={({ item: status }) => <KanBanColumn width={ 300 } instances={ statusToTargetMap[status.id] || [] } status={ status } />}
            keyExtractor={status => status.id}
            horizontal={ true }
          />
        </ScrollView> */}
      </View>
    );
  }
}

//
//  KanBanBoard Primitive Component
//
export interface KanBanBoardHaborPrimitiveProps extends PrimitiveProps {
  userProps: {}
}
export const KanBanBoardHaborPrimitive = ({ userProps, frameworkProps }: KanBanBoardHaborPrimitiveProps) => {

  //  Unpack
  const { componentContext, context } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <KanBanBoard { ...userProps } componentContext={ componentContext } frameworkContext={ context } />
    </HaborContainer>
  );
};


//  TODO:  Consider passing an ID to ALL HaborComponents so the props are serializable.  BUT, do props need to be?
//  TODO:  Consider NOT passing functions directly because they are NOT a member of the data types in this abstraction.
export const KanBanBoardHaborComponent: HaborComponent = {
  name: "KanBanBoardHaborComponent",
  propsSchema: { type: "object", extensible: true },
  classes: [],
  element: {
    name: "KanBanBoardHaborPrimitive",
    props: {},
    children: []
  }
};
