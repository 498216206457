import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ResourcePlugin } from '.';
import { ResourceEditor } from './resource-editor';
import { ResourceList } from './resource-list';

const Stack = createStackNavigator();

export enum ResourceRoute {
  Editor = "Editor",
  List = "List"
}

export const ResourceNavigator = ({ resourcePlugin }: { resourcePlugin: ResourcePlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }}>
      <Stack.Screen name={ ResourceRoute.Editor } component={ () => <ResourceEditor resourcePlugin={ resourcePlugin } /> } />
      <Stack.Screen name={ ResourceRoute.List } component={ ({ navigation }) => <ResourceList navigation={ navigation } resourcePlugin={ resourcePlugin } /> } />
    </Stack.Navigator>
  );
}