import { useNavigation } from '@react-navigation/native';
import { registerHOC } from 'halia-native';
import * as React from 'react';
import { Image, View } from 'react-native';
import { capitalizeAllWords } from '../../../packages/davel-ui/davel-utils';
import { defaultBorderRadius } from '../../../packages/kelp-bar/styles';
import { defaultProfileImge } from '../components/fields';
import { GalleryHeader, MenuTrayButton } from '../components/header';
import { createPanelNavigator } from '../components/panel-navigator';
import { Paragraph, SubHeader } from '../constants';
import { useMenu } from '../providers/menu.provider';
import { ProfileContext, useCognitoGroups, useCognitoUser } from '../utilities';
import { Account } from './account';
import { AdminScreen } from './admin-screen';
import { CreationNavigator } from './dashboard/works/creation.nav';
import { ProfileScreen } from './profile';


const DashboardStackNavigator = createPanelNavigator();

enum DashboardRoutes {
  Creations = "Creations", Profile = "Profile", Account = "Account", Admin = "Admin"
}

const DashboardRouteTitles = {
  [DashboardRoutes.Creations]: "🎨    Creations",
  [DashboardRoutes.Profile]: "👤    Profile",
  [DashboardRoutes.Account]: "⚙️    Account",
  [DashboardRoutes.Admin]: "🛡️    Admin",
}

export const ProfileSection = () => {
  const [profile, setProfile] = React.useContext(ProfileContext);
  return (
    <>
      <Image resizeMode='contain' source={{ uri: profile?.avatar || defaultProfileImge }} style={{ minHeight: 100, flex: 1, zIndex: -100, borderRadius: defaultBorderRadius }} />
      <View style={{ height: 100, flexDirection: "column", width: '100%', paddingVertical: 30, alignItems: 'center' }}>

        {/* TODO:  Make these LINK to the profile section and make them "feel" like independent "Fields"... THINGS. */}
        <SubHeader style={{ color: '#666666', marginBottom: 5 }}>{capitalizeAllWords(profile?.displayName || "Set Username")}</SubHeader>
        <Paragraph style={{ fontSize: 14, color: '#aaaaaa' }}>{capitalizeAllWords(profile?.location || "Set Location")}</Paragraph>

      </View>
    </>
  );
}

export const DashboardPlugin = ({ children }: { children: any }): any => {
  //  TODO:  Check user?

  React.useEffect(() => {
    registerHOC("menu-root-above", () => {
      const navigation = useNavigation();
      const { toggleMenu } = useMenu();
      const user = useCognitoUser();

      if (!user) { return null }
      return (
        <>
          <ProfileSection />
          <View style={{ height: 2, backgroundColor: '#eeeeee', marginVertical: 15 }} />
          {
            Object.keys(DashboardRoutes).map((route) => (
              <MenuTrayButton
                title={DashboardRouteTitles[DashboardRoutes[route]]}
                onPress={() => {
                  toggleMenu();
                  navigation.navigate("Dashboard", { screen: DashboardRoutes[route] })
                }}
              />
            ))
          }
          <View style={{ height: 2, backgroundColor: '#eeeeee', marginVertical: 15 }} />
        </>
      );
    });
  }, [])

  return <>{children}</>
  // return (
  //   <>
  //     <Portal name="dashboard-profile" hostName="menu-root-above">

  //     </Portal>
  //     {children}
  //   </>

  // );
}

export const Dashboard = () => {

  const groups = useCognitoGroups();

  return (
    <View style={{ flex: 1 }}>

      <GalleryHeader flat={false} centered={true} transparent={true} showButtons={true} mode='light' />

      {/* Full Panel */}
      <DashboardStackNavigator.Navigator tabBarStyle={{}} contentStyle={{}} screenOptions={{ headerMode: 'hidden', cardStyle: { flex: 1 } }}>
        <DashboardStackNavigator.Screen name="Creations" component={CreationNavigator} options={{ icon: { name: "paint-brush", type: "font-awesome" } }} />
        {/* <DashboardStackNavigator.Screen name="Products" component={Manage} options={{ icon: { name: "shirt", type: "ionicon" } }} /> */}
        <DashboardStackNavigator.Screen name="Profile" component={ProfileScreen} options={{ icon: { name: "person", type: "material" } }} />
        {/* <DashboardStackNavigator.Screen name="Orders" component={Orders} options={{ icon: { name: "money", type: "material" } }} /> */}
        <DashboardStackNavigator.Screen name="Account" component={Account} options={{ icon: { name: "idcard", type: "ant-design" } }} />

        {/* <DashboardStackNavigator.Screen name="Test" component={() => <Text>Dummy Screen</Text>} options={{ icon: { name: "idcard", type: "ant-design" } }} /> */}

        {/* CONSIDER:  A DASHBOARD page hm! */}

        {/* Admin */}
        {
          groups?.includes("Admin") && (
            <>

              {/* TODO:  Move to "Products" */}
              <DashboardStackNavigator.Screen name="Admin" component={AdminScreen} options={{ icon: { name: "edit", type: "material" } }} />
            </>

          )
        }


      </DashboardStackNavigator.Navigator>

    </View>
  );
}