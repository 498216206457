import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { AaroContext } from "./aaro-core";
import * as React from "react";
import { useSizes } from "../gallery/sizes-helper";
import { Icon } from "react-native-elements";
import { Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    width: '100%'
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: 18,
    fontFamily: 'Inter-SemiBold'
  },
});

export interface AaroHeaderProps {
  borderOpacity?: any, title: any, style?: any, children?: any
}

export const AaroHeader = ({ borderOpacity, title, style = {}, children }: AaroHeaderProps) => {

  const { isDesktop } = useSizes();
  const { headerWidgets, installedList } = React.useContext(AaroContext);

  const navigation = useNavigation<any>();
  
  const additional = headerWidgets.map(widget => {
    if (installedList.includes(widget.pluginId)) {
      const Widget = widget.component;
      return <Widget />;
    }
    return null;
  })

  return (

    <View style={{ backgroundColor: 'white', justifyContent: 'flex-start', ...style }}>
      <>
        <View style={[styles.menuContainer, { paddingVertical: 15 }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>

            {
              !isDesktop && (
                <TouchableOpacity style={{ marginRight: 15, alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.toggleDrawer()}>
                  <Icon name="menu" type="ionicon" size={30} color="#555555" />
                </TouchableOpacity>
              )
            }

            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Inter-Bold', fontSize: 24, color: "#555555" }}>{title}</Text>
            </View>
          </View>




          <View style={styles.titleContainer}>

            {
              additional.map(_additional => (
                <View style={{ marginHorizontal: 5 }}>
                  {_additional}
                </View>
              ))
            }

            {
              children
            }

          </View>
        </View>
        <Animated.View style={{ height: 2, backgroundColor: '#eeeeee', opacity: borderOpacity }} />
      </>
    </View >


  );
};

export const BoxContainer = ({ children }) => {
  return (
    <View style={{ backgroundColor: 'white', borderRadius: 10, borderWidth: 2, borderColor: '#eeeeee', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 45, paddingHorizontal: 15 }}>
      {children}
    </View>
  )
}