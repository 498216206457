import * as React from 'react';
import { SDTArray } from "habor-sdk";
import { renderView } from "../../davel-ui";
import { View } from "react-native";
import { SDTViewRendererParams } from '../../davel-ui-tools';

export const sdtArrayViewRenderer = async ({ sdt, value, metadata }: SDTViewRendererParams) => {
  const values: any[] = value;

  const views: any[] = [];
  for (let i = 0; i < values.length; i++) {
    const val = values[i];
    //  TODO:  Deal with 'any' type.
    const view = await renderView({ sdt: sdt.itemType, value: val, name: i.toString(), metadata });
    views.push(view);
  }
  return (
    <View>
      { views }
    </View>
  );
};
