import { SDTObject } from 'habor-sdk';
import { deserializeHaborComponent, EntityType, FrameworkContext, HaborComponent, HaborComponentClass, HaborComponentClassNoun, HaborComponentContext, HDTRelationshipValue, InstanceInternal, SerializedHaborComponent, SerializedHaborComponentNoun } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, TouchableOpacity, Modal, ScrollView } from 'react-native';
import { DavelForm } from '../../../packages/davel-ui/davel-ui';
import { haborSDK } from '../hessia-plugin/config';
import { HaborContainer, PrimitiveProps } from '../component-plugin/habor-react/habor-component-lib';
import { HaborComponentViewer } from '../component-plugin/habor-react/habor-component-viewer';
import { Card } from '../../../packages/kelp-bar/card';
import { smallSpacer } from '../../../packages/kelp-bar/styles';
import { ComponentSettingBinding, componentSettingBindings } from '../settings-system';

//  TODO:  Eventually figure out how to let the parent control the setting selection so the Router can control setting on the child?  We MAY want the ability to GROUP setting selections onto pages.
export interface SettingSelectorProps {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  currentSettings?: any;
  component: HaborComponent;
  onClose: () => void;
}
interface SettingSelectorState {}
class SettingSelectorBase extends React.Component<SettingSelectorProps, SettingSelectorState> {
  constructor(props: SettingSelectorProps) {
    super(props);
    this.state = {}
  }
  public componentDidMount = () => {

  }

  public setSetting = (value: any, schema: SDTObject) => {

    //  NOTE:  The DavelUI shuold have automatically validated the value agains the schema!
    //  TODO:  Let the user select the context.
    //  TODO:  Support selection by component class instead of component name.  Maybe support other fields on a component?  Like a custom Tag field or attached Status?
    //  NOTE:  FOR NOW, we define a default context selection, which includes the entire stack of elements 
   
    //  Create the Binding
    const binding: ComponentSettingBinding = {
      context: { name: this.props.componentContext.name, parent: this.props.componentContext.parent },
      setting: value
    };

    //  Update the Binding
    //  TODO:  Do this in Habor
    componentSettingBindings.push(binding);

    //  Close the Form
    this.props.onClose();
    
  }

  public render = () => {

    //  Unpack
    const { currentSettings, component: { settingsSchema }, frameworkContext, componentContext, onClose } = this.props;
    const { token, user } = frameworkContext;

    //  Guard Undefined
    //  CONSIDER:  Should we throw an error here and let this be the resonsibility of the caller?  OR, should we consider giving the user the option to define settings OR attach unused settings for... some reason?
    if (settingsSchema == undefined) {
      return <Text>This component has no settings.</Text>
    }

    return (
      <TouchableOpacity onPress={ () => alert("pressed") }>
        <DavelForm metadata={{ token, user, frameworkContext, componentContext }} value={ currentSettings } schema={ settingsSchema } onSubmit={ this.setSetting } onCancel={ onClose } />
      </TouchableOpacity>
    );
  }
}
export const SettingSelector = SettingSelectorBase

/**
 * Helper function to retrieve an instance by name instead of by ID.
 * @param name
 */
export async function getInstanceByName<T>(nounId: string, name: string, token: string): Promise<InstanceInternal<T> | undefined> {

  //  Search Instances
  //  TODO:  Figure out why template type isn't working here.
  const instances = await haborSDK.searchInstances<any>(token, { nounId, search: { match: { payload: { name } } } });
  if (instances.length > 1) {
    throw new Error("Multiple instances defined for the same name.");
  } else if (instances.length <= 0) {
    throw new Error("Could not find the specified UI... Consider fallback?")
  } else {
    return instances[0];
  }
}

//  CONCERN:  IF we build the "Router" separately, then we'd have one layer to configure the router and another to configure the selected element... Hmmm... But, I almost feel like they shuold be shown on the SAME page?  Ok... so that's what I like about the "Magic Component".  IF we use a Router can we still group it??  Maybe we can stop the child from displaying and use the parent to control the edit feature?  I like that idea for now.
//  IDEA:  We COULD use a "ConfigurableHaborComponent" sub-class... but instead, for now, let's just hard-code support for settings.
//  CONCERN:  Should the entire FrameworkProps object be passed here instead of just FrameworkContext??  Consider pulling this apart more!
//  IDEA:  MAYBE when we define each "Setting" we can define the applicable Context Mask.  This way, we COULD let the user set "defaultComponent" and apply it as an override to ALL components.  For example, in the "InstanceListView" we have a configurable "InstanceListItem".  We can take this item and configure the settings for a given context.  BUT, maybe during the definition, we specifically remove the "nounId" property from the parent, unscoping the setting?  I suppose we'll need to keep working on this system... How does it work for deeply nested views?  It just checks to see if it applies, just like CSS I suppose.  MAYBE we can list all the Workspace settings for the user to see.

export enum RouterError {
  InvalidClass,
  UndefinedClass
}

export interface HaborComponentRouterSettings {
  component: HDTRelationshipValue;
}
export interface HaborComponentRouterProps {
  frameworkContext: FrameworkContext;
  componentClassName: string;
  defaultComponentName?: string;
  settings?: HaborComponentRouterSettings;
  componentContext: HaborComponentContext;
  componentProps: any;
}

interface HaborComponentRouterState {
  componentClass?: HaborComponentClass;
  component?: HaborComponent;
  showSettings: boolean;
  loading: boolean;
  error?: RouterError;
}
export class HaborComponentRouter extends React.Component<HaborComponentRouterProps, HaborComponentRouterState> {
  constructor(props: HaborComponentRouterProps) {
    super(props);
    this.state = {
      showSettings: false,
      loading: true
    }
  }
  public componentDidMount = async () => {

    //  Unpack
    //  TODO:  Consider using human readable names for the component class instead of guids.
    //  NOTE:  Settings / Context are "HaborComponent" concepts.  We pass these things when we render a HaborComponent?
    //  TODO:  Standardize the "name" system.  It's POSSIBLE that we have some instances which ARE named.  Then, it should be possible to use that name as an ID?  We shouldn't do this ad-hoc for EVERY named instance.
    const { componentClassName, frameworkContext: { token }, settings, defaultComponentName } = this.props;
    console.log(componentSettingBindings);

    //  Resolve Class
    let componentClassInst: InstanceInternal<HaborComponentClass> | undefined = undefined;
    componentClassInst = await getInstanceByName(HaborComponentClassNoun.id, componentClassName, token);
    const componentClass = componentClassInst ? componentClassInst.payload : undefined;

    //  Get the Component ID
    //  TODO:  Making assumptions about the shape of the data here.  Refactor this whole thing once you have the settings system operational.
    const componentId = (settings && settings.component) ? settings.component.instanceIdList[0].instanceId : undefined;

    //  Get the Settings Component
    let settingsComponent: HaborComponent | undefined;
    if (componentId != undefined) {
      const serSettingsComponent = await haborSDK.retrieveInstance<SerializedHaborComponent>(SerializedHaborComponentNoun.id, componentId, token)
      settingsComponent = serSettingsComponent ? deserializeHaborComponent(serSettingsComponent.payload) : undefined;
    }

    //  Get the Default Component
    let defaultComponent: HaborComponent | undefined;
    if (!componentId && defaultComponentName != undefined) {
      const defaultCandidates = await haborSDK.searchInstances<SerializedHaborComponent>(token, { nounId: SerializedHaborComponentNoun.id, search: { match: { payload: { name: defaultComponentName } } } });
      if (defaultCandidates.length == 1) {
        defaultComponent = deserializeHaborComponent(defaultCandidates[0].payload);
      }
    }

    //  Get the Selected Component
    const selectedComponent = settingsComponent || defaultComponent;

    //  Guard Undefined
    if (selectedComponent == undefined || componentClass == undefined) { return }

    //  Validate
    //  TODO:  In the future SERIOUSLY consider removing "Habor Classes" and letting components inherit from one another... I think that makes more sense?  Maybe... I'm not entirely sure.  Either way, let's start here.  We should also support multi-inheritance at some point.
    //  CONCERN:  It really concerns me that we have THREE different inheritance patterns.  2 of which are active in the code now and I'm thinking of another.
    //            The first is class / instance.  We COULD make these components "HaborClasses".  The next is the "HaborComopnentClass" Entity (class) we've defined to hold the class.  The last, is this idea of having the HaborComponents extend one another directly and inherit "props".  But then, would they ALSO inherit other features like querying?  Actually, I suggest we primarly just make "Presentational" components for now?  But... yeh... Even if we used the object pattern, would "HaborComponents" be "instance" of the "HaborComponent" "class"?  Using the object pattern WOULD simplify some things... but I think this might still be ok for now... it's getting messy though, and a unified system might help reduce the fan-out.  Especially in the "properties" we have on a class to differentiate the different types.  But, like JS, we COULD still have classes and use prototype inheritance as the driver, the background data-structure instead of actually keeping the data structured as a class.  FOR NOW, let's just keep going with what we have!  I just want to see the selector work!
    //  TODO-IMPORTANT:  Custom insance IDs would be VERY helpful in some cases... I suggest allowing "NamedObject" to use the "name" field.  BUT... sometimes we may WANT duplicates.  For that reason... consider setting it manually as an overriden noun property modifier??
    if (selectedComponent.classes) {
      const matchingClass = selectedComponent.classes.find(className => className == (componentClassInst as InstanceInternal).payload.name);
      if (matchingClass == undefined) {
        this.setState({ error: RouterError.InvalidClass, loading: false });
        throw new Error("The selected component does not extend from the selected router class.")
      }
    } else {
      this.setState({ error: RouterError.UndefinedClass, loading: false });
      throw new Error("The selected component does not have any classes.  To be rendered by the router it must inherit the selected class.")
    }

    //  Set the State
    this.setState({ componentClass, component: selectedComponent, loading: false });

  }

  //  TODO:  Eventually go to a "Settings Page" or SOME more comperhensive settings editor?  MAYBE with other components from this context?
  private showSettings = () => {
    this.setState({ showSettings: true });
  }

  private hideSettings = () => {
    this.setState({ showSettings: false });
  }

  public render = () => {

    //  Unpack
    const { settings, frameworkContext, componentContext, componentProps } = this.props;
    const { componentClass, component, showSettings, loading, error } = this.state;

    //  Handle Settings Editor
    //  NOTE:  Two ideas here... we can eiher make a RULE or some other declarative mechanism, like a Remote Option, etc... to tell the system that the selected type in the setting MUST match the one passed in as a prop... We MAY want to use the same Noun / Instance pattern and let the prop value select a Noun and then reference that value when defining the setting schema... OR, we can, FOR NOW, make some explicit code to do this and do the same for other comopnents... essentially hard-coding the setting selection, which REALLY want to avoid!  Interesting though... I believe the value of the PROP determines what we can select as an option!  That IS surprising...
    if (showSettings) {
      return (
        <Modal>
          <ScrollView>
            <SettingSelector onClose={ this.hideSettings } frameworkContext={ frameworkContext } component={ HaborComponentRouterHaborComponent } componentContext={ componentContext } />
          </ScrollView>
        </Modal>
      );
    }

    //  Show Loader
    if (loading) {
      return (
        <Card outerStyle={{ flex: 1 }} innerStyle={{ padding: smallSpacer }}>
          <Text>Loading Component</Text>
        </Card>
      )
    }

    //  Handle Undefined
    if (error) {
      return (
        <Card outerStyle={{ flex: 1 }} innerStyle={{ padding: smallSpacer }}>
          <Text>Error</Text>
        </Card>
      );
    }

    //  Handle Undefined
    if (component == undefined) {
      return (
        <Card outerStyle={{ flex: 1 }} innerStyle={{ padding: smallSpacer }}>
          <Text>No Component Selected</Text>
          <Button title="SELECT" onPress={ () => this.showSettings() } />
        </Card>
      );
    }

    //  Handle Valid Component
    return (
      <HaborComponentViewer componentProps={{ ...componentProps }} component={ component } frameworkContext={ frameworkContext } componentContext={ componentContext } handleClose={ () => alert('closed') } />
    );
  }
}

//
//  HaborComponentRouter Primitive Component
//
export interface HaborComponentRouterHaborPrimitiveProps extends PrimitiveProps {
  userProps: {
    componentClassName: string;
    defaultComponentName: string;
    componentProps: any;
  }
}
export const HaborComponentRouterHaborPrimitive = ({ userProps, frameworkProps }: HaborComponentRouterHaborPrimitiveProps) => {

  //  Unpack
  const { context, settings, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <HaborComponentRouter { ...userProps } settings={ settings } frameworkContext={ context } componentContext={ componentContext } />
    </HaborContainer>
  );
};

// registerPrimitiveHaborComponent('HaborComponentRouterHaborPrimitive', HaborComponentRouterHaborPrimitive);

export const HaborComponentRouterHaborComponent: HaborComponent = {
  name: "HaborComponentRouterHaborComponent",
  propsSchema: { type: "object", extensible: true },

  //  CONSIDER:  Can we make the "Settings" system an Addon??  Consider the NodeEvent loop.  It's static.  COULD it have been useful to only have the pieces we actually need?
  settingsSchema: {
    type: "object",
    extensible: false,
    properties: {
      //  TODO:  Get rid of "SerializedHaborComponentNoun" for a straight shot "HaborComponentNoun".
      //  TODO-IMPORTANT:  The value entered here MUST inherit from the CompoonentClass in the props passed to the component.  Because the setting is defined at runtime, we should have that at our disposal... So, we can either hard-code the requirement, or use some declarative technique like Rules or just reference the value in the type definition.
      //  TOOD:  I SERIOUSLY suggest that we either use the pre-existing the Noun interface, OR we allow arbitrary objects to inherit from one another??  Hmmm.... Noun interface sounds like the most straightforward path... WITH a new modifier for "MERGING".  OR we switch the new "Group" interpretation of "Class" where EVERYTHING is an object under the same interface.  I REALLYY like the appraoch!  Then the class stuff is built ON that... having NATIVE class stuff just seems less fundamental to me...
      component: { type: "relationship", allowMany: false, nounIds: [{ nounId: SerializedHaborComponentNoun.id, type: EntityType.Noun }] }
    }
  },
  element: {
    name: "HaborComponentRouterHaborPrimitive",
    props: {
      componentClassName: { type: "symbol", scopePath: ["props", "componentClassName"] },
      defaultComponentName: { type: "symbol", scopePath: ["props", "defaultComponentName"] },
      componentProps: { type: "symbol", scopePath: ["props", "componentProps"] },
    },
    children: []
  }
};
