import { CognitoUser } from '@aws-amplify/auth';
import { PortalHost, PortalProvider } from '@gorhom/portal';
import { NavigationContainer, NavigationProp, useNavigation } from '@react-navigation/native';
// import './2-card-detailed.css';
import { createStackNavigator } from '@react-navigation/stack';
import Amplify, { Analytics, Auth } from 'aws-amplify';
import * as Linking from 'expo-linking';
import { registerHOCRegister } from 'halia-native';
import * as React from 'react';
import { ActivityIndicator, LayoutAnimation, Text, View } from 'react-native';
import { awsConfig, basClientHost, primaryColor } from './constants';
import { CartProvider } from './providers/cart.provider';
import { MenuProvider } from './providers/menu.provider';
import { ArtistDetailPage } from './screens/artist-detail';
import { Artists } from './screens/artists';
import { Authenticate } from './screens/authenticate';
import { Cart } from './screens/cart';
import { Dashboard, DashboardPlugin } from './screens/dashboard';
import { GalleryScreen } from './screens/gallery';
import { Main } from './screens/home';
import { OrderSuccessPage } from './screens/order-success-page';
import { ProductDetail } from './screens/product-detail';
import { ShopPage } from './screens/shop';
import { PrivacyScreen, TermsScreen } from './screens/terms';
import { WorkDetailPage } from './screens/work-detail';
import { withSizes } from './sizes-helper';
import { PaymentStatusWrapper } from './stripe';
import { AttributeProvider, GenderProvider, MatureProvider, ProfileProvider, TypeProvider } from './utilities';

//  Enable Analytics
//  REFERNECE:  https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/
// Analytics.configure(awsConfig);
// Analytics.autoTrack('session', {
//   enable: true,
//   provider: 'AWSPinpoint'
// });

// Analytics.autoTrack('pageView', {
//   enable: true,
//   eventName: 'pageView',
//   type: 'SPA',
//   provider: 'AWSPinpoint',
//   getUrl: () => {
//     return window.location.origin + window.location.pathname;
//   }
// });

// Analytics.autoTrack('event', {
//   enable: true,
//   events: ['click'],
//   // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
//   // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
//   // always put 'data' as the first prefix
//   selectorPrefix: 'data-amplify-analytics-',
//   provider: 'AWSPinpoint',
//   attributes: {
//     attr: 'attr'
//   }
// });

export interface MenuItem {
  name: string;
  onPress: () => void;
}

registerHOCRegister("menu-root-above");


const GalleryStackNavigator = createStackNavigator();

export const GalleryAppContext = React.createContext<GalleryAppState>({} as any);

export const useGalleryApp = () => {
  return React.useContext(GalleryAppContext);
}

interface GalleryAppProps {
  sizes: any;
  onStateChange: any;
}

interface GalleryAppState {

  scrollPos: number,

  //  Form Fields
  title?: string;
  description?: string;
  screenName?: string;
  email?: string;
  zipCode?: string;
  isBundle: boolean;
  imageOne?: any;
  imageTwo?: any;
  imageThree?: any;
  imageFour?: any;

  //  UI State
  sharePosition?: number;
  aboutPosition?: number;

  //  Submisson
  pendingSubmission?: boolean;
  submissionModal?: boolean;
  submissionError?: boolean;

  //  Navigation
  tos?: boolean;
  pp?: boolean;

  menuVisible: boolean;

  //  TODO:  GENERALIZEEE!! MAYBE use UNIVERSAL Navigation??? idkk.. maybe use HTTP stuf?? hm
  showLogin: boolean;

  user?: CognitoUser;

  path?: string | null;
  queryParams: any;
}

export class GalleryAppBase extends React.Component<GalleryAppProps, GalleryAppState> {

  //  CONSIDER:  Should use... React Navigation?  SOMETHING in an API to navigate hm!  IT's ALL up to the plugin that's injecting this feature hm!  IT shold navigate to the Login ? HM!  CAN perhaps also set it as a dependency state of some things to have a PROVIDER automatically show? Hm!
  // public menuItems: MenuItem[] = [{ name: "GALLERY", onPress: () => this.props.navigation.navigate("Gallery") }, { name: "ARTISTS", onPress: () => this.props.navigation.navigate("Artists") }, { name: "ACCOUNT", onPress: () => this.props.navigation.navigate("Account") }, { name: "LOGIN", onPress: () => { this.props.navigation.navigate("Login") } }];

  constructor(props: any) {
    super(props);
    this.state = {
      isBundle: false,
      scrollPos: 0,
      menuVisible: false,
      showLogin: false,
      path: undefined,
      queryParams: {}
    };
  }

  public componentDidMount = async () => {

    // const userToken = await localStorage.getItem("userToken");
    //   const userData: ICognitoUserData = {
    //     Username: 'hessia',
    //     Pool: 
    //   };
    //   console.log('userData: ', userData);
    //   cognitoUser = new CognitoUser(userData);
    //   cognitoUser.setSignInUserSession(userSession);
    //   cognitoUser.getSession((err, session) => { // You must run this to verify that session (internally)
    //     if (session.isValid()) {
    //       console.log('session is valid');
    //       this.setState({user: cognitoUser})
    //       this.props.navigation.navigate('AuthLoading')
    //     } else {
    //       console.log('session is not valid: ', session);
    //     }
    //   })

    // if (user) { this.setState({ user: JSON.parse(user) as CognitoUser | undefined }); }

    // Amplify.configure(awsConfig);


    //  Get URL
    const parsedUrl = await Linking.parseInitialURLAsync();

    //  Navigate to the URL
    const { path, queryParams } = parsedUrl;

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      // if (user && path === "") {
      //   (window as any).location = "/dashboard";
      // }
      this.setState({ user });
    } catch (err) {
      console.log("User not authenticated");
    }


    // await artistSer.init();
    // await artistService.create({ first_name: 'Dan', last_name: 'Markson', age: 18, gender: 'M', photo: 'https://images.unsplash.com/photo-1502872364588-894d7d6ddfab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2670&q=80' })

    // if (path) {
    //   this.props.navigation.navigate(path);
    // }

    this.setState({ path, queryParams });

  }

  public componentWillReceiveProps = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
  }

  public toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible });
  }
  public render = () => {

    //  TODO:  HOW can we make this work with an artificial app thing? Hmm.... MAYBE put it in an iFrame??? hm!
    const { isDesktop, isTablet, isMobile } = this.props.sizes;
    const { tos, pp } = this.state;

    //  CONSIDER:  Implement Router or React Navigation

    //  NOTE:  maxWidth of the content container an the paddings are taken from AirBnb.

    //  NOTE:  This will only work on web!
    // (window as any).onscroll = () => {
    //   this.setState({ scrollPos: scrollY })
    // }

    // const screenHeight = Dimensions.get('window').height.valueOf();


    console.log("CHECK LINK");

    const ErrorPage = ({ text }: { text: string }) => {
      return (
        <View style={{ width: "100%", height: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ borderRadius: 20, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 20 }}>Error</Text>
            <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 15 }}>{text}</Text>
          </View>
        </View>
      );
    }

    //  TODO:  Build out this GRID SYSTEM very much like Bootstrap, but make it Pluggable hm!
    // const gridWidth = Dimensions.get("window").width;
    const columns = 12;
    const gutterSize = 20;
    const edgeGutters = false; //  TODO:  Make this work?  Use PLUGINS to make it easy to add new functionality like this!

    //  CONSIDER:  I COULD make a grid component dynamically hmm... Maybe it's best to like.. specify it on the Grid instead though for now.. hmm
    const numGutters = columns - 1;
    const totalGutterSize = numGutters * gutterSize;
    const gridWidth = 1200;
    const colWidth = (gridWidth - totalGutterSize) / columns;

    // if (this.props.)
    // const Login =  () => <Authenticator onStateChange={(authState) => alert(JSON.stringify(authState))} ></Authenticator>;

    // if ((this.props as any).authState != 'signedIn') { return <Text>You Are Not Signed In</Text> }

    //  NOTE:  Keep in mind that I'd like to do more DECLARATIELY based on state dependency instead of explicit IF statements hmm... that's just a way to do it where it's imperatively instead of hmmm... interesringgg...

    //  TODO:  HATE that we pass this down to children which depend on this context... I WOULD like to be able to just BECOME a part of fthis context REMOTELY.. why is that wrong??? WHY should it have to be fucking COUPLED in the FILE and the CODE lines!?  WHY should state be separate.. it's NOT an anti-pattern, AND if we want we can be even specific / selective abou the part of the state we keep hmm... I sound agitated in some of these.. and a BIG part of it is because I keep feeling like SOME part is inspired by Corey / IBM and I think that's BS and I HATE it just from that ONE stufpid comment asldkfjaslfjdsa f  But.. I do love them and idfk hmm.. complex !!Jkljasldkfj Love yoU!!  I literlly sound like a fucking psyco...haha I'll read this later and be like WTF!?  Love you Will!!  Really I do, it's okk =)

    const setUser = (user) => {
      this.setState({ user })
    }

    if (this.state.path === undefined) {
      return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}><ActivityIndicator color={primaryColor} /></View>
    }

    const initialRouteName = this.state.path || (this.state.user ? "Dashboard" : "Home");



    return (

      // TODO:  Fix portal!  Maybe try bringing it even higher in the tree?
      <PortalProvider>

        <AttributeProvider>
          <ProfileProvider>
            <GalleryAppContext.Provider value={this.state}>
              <CartProvider>
                <MenuProvider>
                  <GenderProvider>
                    {/* CONCERN:  This is rediculous.  SHOULD be able to INJECT these without explicitly encoding them, and ... shouln't even have to hmm... */}
                    <TypeProvider>
                      <MatureProvider>
                        <NavigationContainer linking={{
                          prefixes: [basClientHost],
                          enabled: true,
                          config: {
                            screens: {

                              //  CONSIDER:  These are ASSOCIATED with the routes, but here we SPLIT it!  Would like to keep them together!

                              Authenticate: 'auth',
                              Terms: 'terms',
                              Privacy: 'privacy',

                              Home: '',

                              Shop: {
                                path: 'products',
                                screens: {
                                  Shop: 'shop',
                                },
                              } as any,


                              ProductDetail: 'products/:workProductId',

                              Gallery: 'creations',

                              //  TODO:  Nest this in Creations (Gallery)
                              WorkDetail: 'creations/:creationId',

                              Artists: 'artists',
                              ArtistDetail: 'artists/:userId',

                              Cart: 'cart',
                              "order-success": 'order-success',
                              VerifyPayment: 'verify-payment',

                              //  Dashboard Routes
                              //  TODO:  Nest unser Home so they can both be empty strings.
                              Dashboard: {
                                path: 'dashboard',
                                screens: {
                                  Creations: {
                                    path: 'creations',
                                    screens: {
                                      Creations: '',
                                      "New Creation": 'create',
                                      WorkDetail: ':creationId'
                                    }
                                  },
                                  Profile: 'profile',
                                  Account: 'account',
                                } as any
                              } as any,

                            }
                          },
                        }} independent={true}>

                          <DashboardPlugin>

                            <View style={{ flex: 1, width: '100%', height: '100%', display: 'flex', overflow: 'hidden' }}>

                              {/* Root Portal */}
                              {/* CONSIDER:  I DON'T want apps to be coupled with this.  I'd like to inect this or use it as a standard? Hmm... MAYBE have a WRAPPER around all Halia apps for this? */}
                              <View pointerEvents='box-none' style={{ position: 'absolute', width: '100%', height: '100%', opacity: 1, zIndex: 100 }}>
                                <PortalHost name="root-portal" />
                              </View>

                              {/* NOTE:  Default card background color for Navigator is like an off-white */}
                              <GalleryStackNavigator.Navigator initialRouteName={initialRouteName} screenOptions={{ headerShown: false, cardStyle: { flex: 1, backgroundColor: 'white' } }}>

                                {/* Authentication */}
                                {/* <GalleryStackNavigator.Screen name="Login" component={({ navigation }) => <Login navigation={ navigation } onLogin={(user: CognitoUser) => { if (!user) { alert("NO USER"); return; } this.setState({ user }); navigation.navigate("Home"); }} />} /> */}
                                <GalleryStackNavigator.Screen name="Authenticate" component={({ navigation, route }) => <Authenticate mode={route?.params?.mode} />} />
                                <GalleryStackNavigator.Screen name="Terms" component={({ navigation, route }) => <TermsScreen />} />
                                <GalleryStackNavigator.Screen name="Privacy" component={({ navigation, route }) => <PrivacyScreen />} />

                                {/* Home */}
                                <GalleryStackNavigator.Screen name="Home" component={({ navigation }) => <Main section={this.state.queryParams.section} navigation={navigation} toggleMenu={this.toggleMenu} />} />
                                <GalleryStackNavigator.Screen name="Dashboard" component={({ navigation }) => <Dashboard />} />

                                {/* Shop */}
                                <GalleryStackNavigator.Screen name="Shop" component={ShopPage} />
                                <GalleryStackNavigator.Screen name="ProductDetail" component={({ route }: { route: any }) => <ProductDetail workProductId={route.params.workProductId} />} />

                                {/* Works */}
                                <GalleryStackNavigator.Screen name="Gallery" component={GalleryScreen} />
                                <GalleryStackNavigator.Screen name="WorkDetail" component={({ route }) => <WorkDetailPage creationId={route?.params?.creationId} />} />

                                {/* Artists */}
                                <GalleryStackNavigator.Screen name="Artists" component={Artists} />
                                <GalleryStackNavigator.Screen name="ArtistDetail" component={({ navigation, route }) => <ArtistDetailPage userId={route.params.userId} />} />

                                {/* Payments */}
                                <GalleryStackNavigator.Screen name="Cart" component={Cart} />
                                <GalleryStackNavigator.Screen name="order-success" component={() => <OrderSuccessPage checkoutSessionId={this.state.queryParams.session_id} />} />
                                <GalleryStackNavigator.Screen name="VerifyPayment" component={PaymentStatusWrapper} />

                              </GalleryStackNavigator.Navigator>

                            </View>
                          </DashboardPlugin>
                        </NavigationContainer>
                      </MatureProvider>
                    </TypeProvider>
                  </GenderProvider>
                </MenuProvider>
              </CartProvider>
            </GalleryAppContext.Provider>
          </ProfileProvider>
        </AttributeProvider>
      </PortalProvider>


    );
  }
}

export const GalleryWithSizes = withSizes(GalleryAppBase);


export const UserProvider = React.createContext<CognitoUser | undefined>(undefined);

// Amplify.configure(awsConfig);

export const GalleryNavigator = () => {
  return <GalleryAppBase sizes={{}} onStateChange={() => null} />

};


