import { SDTArray } from 'davel';
import * as React from 'react';
import { Button, View, Text } from "react-native";
import { medSpacer } from '../../../kelp-bar/styles';
import { DavelFormButton, SDTComponent } from "../../davel-ui";
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

/**
 * This WHOLE component is JUST to make sure the Redux Form flat value key is REMOVED when the component is unmounted!
 * TODO:  In the future, re-write Davel with NESTED data outside of Redux form to avoid the mapping to FLAT fields!?
 */
// interface WrappewdFieldProps {
//   formId: string;
//   fieldName: string;
//   value: any;
//   field: any;
//   removeField: () => any;
// }
// interface WrappewdFieldState {}
// class WrappedField extends React.Component<WrappewdFieldProps, WrappewdFieldState> {

//   static contextType = ReactReduxContext;

//   constructor(props: WrappewdFieldProps) {
//     super(props);
//     this.state = {}
//   }

//   public componentWillUnmount = () => {
//     const { formId, fieldName } = this.props;
//     const store = this.context.store;
//     store.dispatch(unregisterField(formId, fieldName));
//   }

//   public render = () => {
//     const { field, formId, fieldName, value, removeField } = this.props;

//     return (
//       <ReactReduxContext.Consumer>
//       {({ store }) => {
//         const state = store.getState();
//         console.log(state);

//         //  Keep the value in-sync with the local state.
//         store.dispatch(change(formId, fieldName, value));
//         return (
          
//         );
//       }}
//     </ReactReduxContext.Consumer>
//     );
//   }
// }

// interface Field {
//   fieldName: string;
//   component: any;
// }

interface ArrayRendercerState {
  fields: any[];
}

interface ArrayRendererProps {
  // fields: FieldArrayFieldsProps<any>;
  sdt: SDTArray;
  value: any[];
  metadata: any;
  name: string;
  update: (value: any) => void;

  //  Props passed through the form
  // key: any;
  // component: any;
  // rerenderOnEveryChange: any;
}
class ArrayRenderer extends React.Component<ArrayRendererProps, ArrayRendercerState> {

  // static contextType = ReactReduxContext;

  constructor(props: ArrayRendererProps) {
    super(props);
    this.state = {
      fields: []
    };
  }

  // public getFields = () => {

  //   //  Type Guard
  //   if (!isArray(value)) { return; }

  //   //  Update the Fields
  //   this.addFields(value);
  // }

  public render = () => {
    // const { fields } = this.state;
    // const state = this.context.store.getState();
    // console.log(`Rendering Array Field!`);

    //  TODO:  Support items that have their OWN type instead of all being the same.  Mixed arrays.
    const { sdt, sdt: { itemType }, metadata, name, value, update } = this.props;

    const setItem = (_fieldValue: any, index: number) => {
      const newValue = [...value];
      newValue[index] = _fieldValue;
      update(newValue);
    }

    const deleteItem = (index: number) => {
      const newValue = [...value];
      newValue.splice(index);
      update(newValue);
    }

    const addItem = (_fieldValue: any) => {
      const newValue = [...value, _fieldValue];
      update(newValue);
    }

    return (
      <DavelField required={sdt.required} key={name} name={name}>
        {
          this.props.value.map((fieldValue, fieldIndex) => {
            const fieldName = name === 'root' ? fieldIndex.toString() : `${name}.${fieldIndex}`
              const res = //  TODO:  CURRENTLY, the array drop downs are generated only for OBJECT, which have an undefined "toplevel" property!  So... build a mechanism for that at THIS array level and explicitly pass "topLevel: true" (and any other SDT specific params) here!? HM!
                <View key={ fieldName }>
                  <SDTComponent value={ fieldValue } update={ (_fieldValue) => setItem(_fieldValue, fieldIndex) } sdt={ itemType } metadata={ metadata } autoSubmit={ true } name={ fieldName } />
                  <Text>{ fieldName }</Text>
                  <Button title={`Remove ${ fieldIndex }`} onPress={ () => deleteItem(fieldIndex) } />
                </View>;
              return res;

            // return <Field
            //   props={{
            //     sdt: itemType,
            //     formId,
            //     metadata,
            //     autoSubmit,
            //     name: field  //  CONSIDER:  We now have TWO systems handling the names.. My homegrown, and the built-in.  PERHAPS we can replace mine with the Redux Form solution?
            //   }}
            //   name={field}
            //   component={SDTComponent}
            // />
          })
        }
        <View style={{ height: medSpacer }} />
        {/* TODO:  Instead of pushign undefned, make a REAL entry and THEN add that to the array */}
        <DavelFormButton title={'ADD'} onPress={() => addItem(undefined)} />
      </DavelField>
    );
  }

  //  CONSIDER-GENERAL:  Really don't see what value Redux brings here... It's nice to have a way to track global actions I guess, BUT why not do a HYBRID where we track things GLOBALLY, BUT actually manage the state LOCALLY!?  This way, it's NOT all in one giant store!?  This way we can see the local state and shit and EVERYTHING has actions and stuff assocaited with it, BUT with CONTEXT and REFERENCE to the OWNING state!?  HMmm!

  // private removeField = async (index: number) => {
  //   const { value } = this.state;
  //   const updatedValue = [...value];
  //   updatedValue.splice(index, 1);
  //   // this.setState({ value: updatedValue }, this.updateFields);
  // }

  // private addField = async () => {
  //   // this.setState({ value: [...this.state.value, undefined] }, this.updateFields);
  // }

  // public componentDidUpdate = () => {
  //   this.updateFields();
  // }

  // public componentDidMount = () => {
  //   this.updateFields();
  // }

  // private updateFields = async () => {

  //   //  Currently, we set the fields at the beginning with the defaults... This sets the Redux state.  So.. we COULD work on changing the state of the passed in object and let the state of the Form be determined by that?  Hmm.. the idea is to bind the state of the object to the form?  Hmmm... That makes a LOT of sense, but wit hthe CURRENT model, if we add / remove array fields, the existing are not removed... IF we used ONLY local state and rendered when needed, I don't believe this would be such a issue!???  So... PERHAPS we SHOULD just freaking do that.  I just feel like I've spent WAY too much time fighting with Redux Form... This means, instead of EACH key having a unique ID, we just NEST them, and PERHAPS we have like a "local reducer" to make changes?? Hmm... Then, insteaad of changing the global state, we just call a FUNCTION to make these changes?  Then, the function will go ahead and make the changes in the local tree and re-render, INSTEAD of changing the FLAT global representation?  Which makes it challenging to operate?

  //   //  Remove All Existing
  //   // const store = this.context.store;
  //   // const state = store.getState();
    

  //   const { value } = this.state;
  //   const fields: any[] = [];
  //   for (let i = 0; i < value.length; i++) {
  //     const childValue = value[i];
  //     const fieldName = `${name}[${i}]`;
  //     const renamedItemSDT: SDT = { ...itemType };
  //     const key = (new Date()).getTime().toString();  //  TODO:  REMOVE THIS...  This is just to ensure we render every time.  Perhaps key instead, on the number of updates?  If we don't do this, then the key defaults to the fieldName, and the re-render doesn't happen when the data changes..  MAYBE hash the data?  Perhaps that will take longer than just re-rendering?
  //     //  TODO:  Buil a COMPONENT for this so this doesn't have to be async and we can call "getFields" DIRECLTY from the render method!?
  //     const field = await renderSDT({ sdt: renamedItemSDT, name: fieldName, formId, metadata, value: childValue, key });

  //     const wrappedField = (
  //       <View>
  //         { field }
  //         <Button
  //           title="Remove"
  //           onPress={ () => {
  //             this.props.fields.remove(i)
  //           }
  //         } />
  //       </View>
  //     );

  //     this.props.fields.push(wrappedField)
  //   }
  // }
}

//  CONCERN:  Is it OK to let the 'value' default to an empty array here?
//  TODO:  I REALLY don't like that we have a CLASS wrapped with a function like this.. 
//  TODO:  If "value" changes in the incoming prop, then it WON'T be updated internally???
export const sdtArrayRenderer = ({ sdt, name, value = [], update, metadata }: { value?: any[] } & SDTRendererParams) => {

  //  TODO:  This is SUPER messy with a class component INSIDE a function component!  Clean this up!  Conssider a SINGLE function or class component.
  //  TODO:  Support Deletion


  console.log("Renderring Array Field!");
  return <ArrayRenderer key={ name } name={ name } metadata={ metadata } value={ value } sdt={ sdt } update={ update } />
};
