import { NavigationProp, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Image, Pressable, ScrollView, Text, TextInput, TouchableOpacity, useWindowDimensions, View, ViewProps } from 'react-native';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../../packages/kelp-bar/styles';
import { GalleryButton } from '../components/common';
import { GroupCard } from '../components/group-card';
import { GalleryHeader } from '../components/header';
import { defaultBorderRadius, Paragraph, primaryColor } from '../constants';
import { CenteredView, useSettings } from '../content-view';
import { useSizes } from '../sizes-helper';

//  CONSIDER:  Every time we use these things we're COUPLING... we COULD instead couple with an INTERFACE and then PROVIDE that hm!  THIS way we can de-couple Cognito but we STILL have the INTERFACE.
//  CONSIDER:  In the BRAIN we have LOTS of activations and hmmmm... the idea is... we can easily "see" MULTIPLE things as the SAME thing hM!  We DOOO want to be able to do this.. MAYBE expliclty with MULTI-INTERFACE or something? hmmm casting, etc hmm.. but instead of expcliity mapping build systems that LEARN and do their best shot mm!  That's what we do lol hm!
//  CONSIDER:  WOULD be nice if I could have some things available to ALL componetns without explcilty importing tlike this.. hmm...  Also consider EVERYTHING as a "component" that we can use event driven programming with... JUST a way to "observe" basically hooked up so somethign produces an encoding (like an event) which is then broadcast and "received" and INTERRPETED and spins up our things hm!  The POINT IS... we have things expliclty influenced by an encoding arriving hm!  Its INFLUENCE!  

const url = "https://linkedin.us7.list-manage.com/subscribe/post?u=37fc984d9adf4cb085f6f2f0e&amp;id=f27495793c";

const ImageSection = () => {
  const { isDesktop, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 700 : 600;
  return (
    <View style={{ flex: isDesktop ? 1 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center' }}>
      <View style={{ height: '100%', width: '100%', borderRadius: defaultBorderRadius, overflow: 'hidden', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} >
        <Image source={require('../assets/Cat Clear.png')} resizeMode="contain" style={{ width: '90%', height: '90%' }} />
      </View>
    </View>
  );
}

export const SubscribeForm = (props: ViewProps) => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 700 : 600;
  const navigation = useNavigation();
  const settings = useSettings();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  return (

    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => {

        // const FormSection = () => {
        //   return (
        //     <View style={{ flex: isDesktop ? 1 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center' }}>
        //       <View style={{ height: '100%', width: '100%', borderRadius: defaultBorderRadius, overflow: 'hidden', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} >

        //       </View>
        //     </View>
        //   );
        // }

        return (

          <CenteredView containerProps={{ onLayout: props.onLayout, style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

            <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ justifyContent: 'center', alignItems: 'center', maxWidth: 400 }}>
                <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 45 : isTablet ? 45 : 50, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Subscribe</Text>
                <Text style={{ alignSelf: 'center', textAlign: 'justify', marginTop: 35, color: '#666666', fontFamily: primaryFontFamily, fontSize: 20, letterSpacing: -0.5 }}>Subscribe to our mailing list for updates, contests, promotions, and bad art!</Text>

                <View style={{ height: 30, width: '100%' }} />

                <Paragraph style={{ alignSelf: 'flex-start', color: '#444444', fontSize: 17, fontFamily: primaryFontFamilyHeavy }}>Name</Paragraph>
                <TextInput style={{ width: '100%', height: 45, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 10 }} value={name} onChangeText={text => setName(text)} />

                {/* TODO:  NEEEDDD other ways to subscribe.. freaking GenZ doesn't want EMAIL UGH! */}
                <View style={{ height: 30, width: '100%' }} />

                <Paragraph style={{ alignSelf: 'flex-start', color: '#444444', fontSize: 17, fontFamily: primaryFontFamilyHeavy }}>Email</Paragraph>
                <TextInput style={{ width: '100%', height: 45, lineHeight: 16, fontSize: 16, borderRadius: defaultBorderRadius, backgroundColor: 'white', padding: 30, borderWidth: 2, borderColor: '#eeeeee', marginVertical: 10 }} value={email} onChangeText={text => setEmail(text)} />

                <View style={{ height: 30, width: '100%' }} />

                <GalleryButton disabled={status === "sending" || status === "success" || status === "error"} loading={status === "sending"} title={status === "success" ? "🎉  Subscribed!  🎉" : status === "error" ? "An Error Occurred =(" : "Subscribe"} style={{ width: '100%' }} onPress={() => subscribe({ EMAIL: email, FNAME: name })} />

              </View>
            </View>
          </CenteredView>

        )
      }

      }
    />
  );
}

export const IntroSection = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 800 : isTablet ? 550 : 550;
  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

      <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isDesktop ? 'flex-start' : 'center' }}>

        {/* Amateur Art, Meme Art, rejects, misfits, and non-conformant absurdities.  and all things unconventional. */}
        {/* <Text style={{ marginTop: isMobile ? -350 : -150, color: 'white', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 65 : isTablet ? 75 : 100, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 25 }}>The Island of</Text> */}
        {/* {
                !isDesktop && <View style={{ height: 40 }} />
              } */}

        <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Welcome to</Text>
        <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>the world of</Text>

        {/* TODO:  AH!! Make that word like SCROLLING thing hm! CHANGE!!! */}
        <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 50 : isTablet ? 65 : 80, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 10 }}>bad art.</Text>
        {/* </View> */}

        {/* imagery */}
        {/* non-conformant */}
        {/* managerie */}
        {/* Together, we can make the world a bit more absurd. */}

        {/* {
                !isDesktop && <View style={{ height: 10 }} />
              } */}


        {/* It's the place to DEVELOP!  It's the home for DEVELOPING artists hm!  The place for people to PLAY and GROW and work together to define their artistic world hM!  MAYBE we can have WORLDS in the Metaverse for these thigns hm! */}
        {/* Bad Art Studio is a safe place to fail, grow, and develop as an artist. */}
        {/* Art can be pretentious.  Here,  */}
        {/* to  magical place to  n */}
        {/* place to question your assumptions, grow as a human, and enjoy   artistic playground.  It's a place to grow  question your assumptions, grow your skills, and  collectively redefine "art".  Practically speaking, it's a place to buy, sell, and create misfit art.  This includes amateur art, meme art, and all brands of ill-conformed absurdities. */}
        {/* where pretty much anything goes */}
        {/* Paradise, developing artist's paradise.   */}
        {/* artistic playground */}
        {/* as developoing  and redefine  buy, sell, and create misfit art.  developing artist's paradise.  It's a place to question your assumptions, grow as a human, and enjoy the magic of ill-conformed absurdity. */}
        {/* amateur art, meme art, and all manner of */}
        {/* , play, and question your assumptions. */}
        {/* Not QUITE.... We DO want it accessible to everyone hm!   */}
        {/* IS that our goal?  Our goal is to hmm.. help you question your assumptions too hmm.. */}
        {/* Play */}
        {/* Our REAL goal is to make you think.  It's to make you open your eyes.. it's to give you more expeireicne and to reduce bias to keep you AWARE HM! */}
        {/* Well... we DO want to make people think.  But is that our "Goal"?  Hm... I don't think we NEED a goal hm!  We don't NEED that hm!  We have PRINCIPLES and one of them IS radical open mindenddes hM!  I LIKE taht.. I LOVe subjectivity and hm! */}
        {/* NOT all "everyman".. hmm.. the point is.. it's a place to learn grow etc hmm... Don't worry TOO much about this and just hit it mm! */}
        {/* Our goal is to make art accessible to everyone.  We're */}
        {/* grow in LOVE! / UNDERSTAING HM! */}
        {/* Fucking make a mess.  Fucking just make random ass shit WHY not mm!!  A celebration of Chaos hm! */}
        {/* Ugh... "embrace the chaos" was the thing fot he other thing hmm... Dmanit.. Make a THing hm */}
        {/* NOT just chaos... it's a celebration of DIFFERENCE.  It's a way to THINK about value and QUESTIOn the intent and hm!  ACCEPTANCE is at its CORE HM! */}
        {/* Bad Art Studio is a celebration of chaos.  ---  Too much like "Make a Thing" */}
        {/* FInd yourself hM!  WQuestion and hm! */}
        {/* Don't go chasing wterfalls? I think it's FINE to hm! */}
        {/* THey just mean to do it safely? Hmm... I think that makes SOME degree of sense ,but like.. nothing matters anyways... so if you do , just do it hm! */}
        {/* LOL... I COULD drive to Foxwoods or Mohegan and just have a fun tiem h!M */}
        {/* We're reclaiming art for the every-man.  */}
        {/* explore, create, and  */}
        {/* Accept art for what it is!  Acept what IS.  See the value in what is, and hmm... */}
        {/* grow in love */}
        {/* UGH... embracing the chaos is somthign I MAY have said damnit. */}
        {/* Is it a place to question OR a place to ACCEPT the nonsense h???  UGHHH... i hate the "embrace the chaos" thing hmm... damnit.. hmm */}
        {/* It's a fucking free for all... it's a red light district... it's a place to find yourself.. to just do whatever hmm... bender? Hmm...  */}
        {/* Art can be FUN.  Art can be enjoyable hm! */}
        {/* Yes, embracing the chaos is a good way to put it.  Damnit... Celebrate the absurd. */}
        {/* Bad Art Studio is a celebration of the absurd.   */}
        {/* for everyone.  It's a place to */}
        {/* consider the absurd */}
        {/* I DO want to lean toward EMBRACING HM!  But it reminds me of the other ugh. */}
        {/* consider the absurd */}
        {/* We believe EVERYTHING is art.  Help us prove that. */}
        {/* Celebrate the absurd.... celebrate the differences hm!  Difference in our BELIEFS HM! */}
        {/* celebrate our differences */}
        {/* Basically it's a place that promots uncertainty.  It advocates for self-reflection, independent thinking, and all of that.  WHY is that good???? Fuck.  Idk hmm... */}
        {/* celebration of what is.  It's a place to have fun, question your assumptions, and grow with fellow artists. */}
        {/* celebrate what is */}
        {/* Uncertainty */}
        {/* subjectivism */}
        {/* authentic beauty */}
        {/* humanity */}
        {/* with your fellow humans */}
        {/* question your assumptions */}
        {/* authenticity??? HM!  Don'T NEED to be authentic though... right???  */}
        {/* celebration of authentic beauty  ---  I LIKE this because it's the juxtaposition between what you NORMALLY think of as "beautiful" and what we're talking about here hm! */}
        {/* NOT JUST about embracing "chaos"... hmm.. ORDER can also be messy hmm... it's bout embracing the absurd? Hmm */}
        {/* NOT just humanity hm!  It's a celebration of what is. */}
        {/* celebration of what is */}
        {/* The absurd!  HM!  In a Philosohical sense hm! */}
        {/* Why "Bad" Art? */}
        {/* In a sense, ALL art is "bad", and ALL art is absurd.  One could argue that ALL art is bad, and ALL art is absurd.   */}
        {/* It's as much a persounal growth / personal journey / collective journey BOTH in mind and in ability? Hmm... In ACCEPTANCE and REJECTION.  Two sides of the same coin hm!  The idea of subjeectivity and internalizing hm!@ */}
        {/* radically open-minded.  */}
        {/* Have fun, embrace subjectivity?  Embrace consideration?  Embrace uncertainty?  Does it matter?  Even if TONS of people have opinions?  UGHH.. The poitn is... it can matter to THEM? HMM */}
        {/* a celebration of the absurd.  It's a place  */}
        {/* a celebration of the absurd.  It's a place  */}
        {/* question your assumptions, */}
        {/* a celebration of everything */}
        {/* a celebration of everything */}
        {/* reclaiming art for our  */}
        {/* WEEE will aceept everything!  We embrace the idea of QUESTIONING and for ME I WANT to believe that EVERYTHING has value!!! UGH!  RIGHT!?? Damn hm! */}
        {/* have fun */}
        {/* NOT just artists!!!  We are for EVERYONE HM! */}
        {/* safe space for developing artists */}
        {/* It REALLY is for EVERYONE UGH!!  WHY should those things be MORE VALUABLE!?  The ONLYYYY reason I even start to think that is the damn Philosophy shit ugh!  IT's just ... SUBJECTIVE FOR SURE!  It's VALUE is absolutely subjective mm!! */}
        {/* redefining art by your standards.   */}
        {/* Art is not a separate thing.  Art  */}
        {/* Acceptance ugh... I DO like that but the point is... we accept EVERYONE Hmm... even the non-accepting hM! */}
        {/* Subjective value? */}
        {/* Celebration of bad art */}
        {/* celebration of the absurd.  It's */}

        {/* have fun */}
        {/* MORE absurd kindasoudns stressful... not necessarily more absurd, and do that with ASSUMPTIONS HM!  But... document them and consider revisting later? Hmm */}
        {/* Bad Art Studio is the place to buy, sell, and create bad art.   */}
        {/* This includes  a celebration of the absurd.  It's a place to create, play, and grow with your fellow humans. */}


        {/* Incomplete  */}
        {/* WHY?  - One man's trash is another man's treasure hm! */}


        {/* Culture of growth and acceptance.  Focus on what's beautiful, because there's beauty in everything. */}

        {/* It's an artistic playground where you can have fun and grow with your fellow humans. */}

        {/* Bad Art Studio is the place to buy, sell, and create misfit art. */}
        {/* This includes amateur art, meme art, and all manner of ill-conformed absurdity. */}
        {/* We're redefining the value of "art".  All are welcome, and we hope to see you there */}
        {/* It's a place to have fun, create, and grow with your fellow humans.</Text> */}
        {/* sanctuarymake mistakes, */}
        {/* try new things, question your assumptions */}
        {/* It's a place to have fun, challenge yourself, and grow as an artist. */}
        {/* It's a place to find the beauty  */}
        {/* magnificence */}

        {/* and growThis includes amateur art, meme art, and all brands of ill-conformed absurdity. */}
        {/* challenge beliefs, and . */}
        {/* Find a voice, celebrate absurdity, and grow with fellow humans. */}
        {/* This includes amateur art, meme art, and all brands of ill-conformed absurdity. */}
        {/* It's a place to have fun, question your instincts, and grow as fellow humans. */}
        {/* OTHERS call it bad, but luckliy we know better.  ALL art is bad.  And... we don't need to hedge, because we accept our place in their world, and we consciously reject it.  It's not bad.  It's amazing.  It's non-conformant in the absurd world, and it's a chance to define it ourselves? Hmm...  Nothing matters anyways? UGHHH...  BALANCE in all things and fucking... between the multitude of systems on me.. ughhh... */}
        {/* This is for the art you worry is too bad to be published ah!!  This is a challenge to publish!!  EVEN if you think it's bad.  We know it's good. */}
        {/* call to action - even if you think your work is bad, publish is.  Take action, and  is the place to buy, sell, and create misfit art.  A lot of amazing work goes unnoticed because it isn't  */}
        {/* beautiful artwork.  It's a place to find art you just wouldn't find elsewhere.   */}
        {/* If yo're haing trouble getting attention elsewhere, try it here - we generally find our users are more open-minded, kind, and also on a journey */}

        {/* - the artists It's not that it's not beautiful - it's just too scared to live elsewhere. */}
        {/* Culture of - playfullness, fun, light-heareted, not arrogant! just... people enjoying themselves with yeah mm!!  Talk about INTENT and hm!! LOTS of ways to get recognized and yeah that other sites don't have hm! */}
        {/* art that needs a safe place to live. */}
        {/* The opitn is... it's a place that ACCEPTS ane CELEBRATES "bad" art.    */}
        {/* It's a HOME for art that wouldn't otherwise have a home hm! */}
        {/* MAYBE the idea is, by participating in GOVENANCE they get a stake? Hmm... how can they BUY a stake though? hmm... Seems like a security hmm... Not sure how private companies offer security things and hmm.. I'd like to keep learning abot this hm!  Maybe build resources to TEACH what I've learned for sure hm!  MAYBE make YOUTUBE videos and hm!   */}
        {/* be rejected by the This includes amateur art, meme art, and all brands of ill-conformed absurdity. */}
        {/* NOTE:  Want to be able to have this as a THIGN that has LOTS of associations.  The CSS system is ONE systm.  the idea of VALUES and variables is ONE system.  Ugh.. relational frame etc..  The idea is.. question your assumptions. */}
        {/* MEDIATION:  I'm feeling LESS incliced to pursue my ideas because of that belief... ugh.. ? */}
        <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', marginLeft: isDesktop ? undefined : 20, paddingHorizontal: isDesktop ? undefined : 10, maxWidth: 400, marginTop: 35, color: '#888888', fontFamily: primaryFontFamily, fontSize: isDesktop ? 22 : 20, letterSpacing: -0.5, textAlign: isDesktop ? 'left' : 'left' }}>Bad Art Studio is the place to buy, sell, and create misfit art.  It's a place to appreciate the unappreciated and grow with your fellow humans.</Text>

        {/* Why? */}

        {/* Because why not.  Sure it's about humor, growth, and charity, but fundamentally, it's about love?  It's about exporing.. It's about PLAYING, NOT being so serious and just enjoying hmm!!  It IS about answering that question.  WHY do we love the absurd?  Why do HUMANS and other animals NEED differentiation and uniqueness and hmm... let's collect DATA on these images and tag them and yeah hm!!!  The love of all things the subjectivity of value, the absurdity of semantics, and our unquenchable need for differentiation.  */}

        <TouchableOpacity onPress={() => navigation.navigate("Gallery" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ color: 'white', fontFamily: primaryFontFamilyHeavy, fontSize: 20 }}>Explore</Text>
        </TouchableOpacity>

        {
          // !isMobile && <Image source={require('../assets/neat_logo.png')} resizeMode="contain" style={{ width: 100, height: 100, marginTop: 40 }} />
        }

      </View>

      <View style={{ flex: isDesktop ? 1 : undefined, height: minHeight, alignItems: isDesktop ? 'flex-start' : 'center', justifyContent: 'center' }}>
        <View style={{ top: -75, left: isDesktop ? -30 : undefined, alignItems: 'center', height: '100%', width: '100%', borderRadius: defaultBorderRadius, overflow: 'hidden', shadowColor: 'black', shadowRadius: 5, shadowOpacity: 0 }} >
          <Image source={require('../assets/Happy Fleas.png')} resizeMode="contain" style={{ width: '100%', height: '100%' }} />
        </View>
      </View>

    </CenteredView>
  );
}

export const CultureSection = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 800 : isTablet ? 550 : 550;

  const settings = useSettings();

  const navigation = useNavigation();

  return (
    <CenteredView containerProps={{ style: { backgroundColor: '#fafafa' } }} style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

      {/* TODO:  Build a better way to ORDER based on context (screen size) hm!  MAYBE use an intermediate encoding and fucking INTERPRET that? Hmm...  BASICALLY it's just a DECISION.  Then, this is encoded and interpreted.  But, instead of doing that for each one, support a concept of ORDER? HMm... Or, even dependency.  Hmm...  */}
      {isDesktop && <ImageSection />}

      <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        <View style={{ justifyContent: 'center', alignItems: isDesktop ? 'flex-start' : 'center', maxWidth: 400 }}>
          <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 45 : isTablet ? 45 : 50, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Culture</Text>

          {/* Here, art doesn't need to be beautiful to be valued. */}

          {/* Drunk Art */}
          {/* Meme Art */}


          {/* Take a breath and remember that art doesn't need to be hard.  It doesn't need to be painful.  It can be fun, light, and absurd. */}
          {/* Get context on a PERSON.. are they being a jerk, etc hmm */}
          {/* We're building a haven for art that would otherwise be homeless.   */}
          {/* We believe art is for everyone.   */}
          {/* It's a celebration of what is.  Why?  Because the fact that it CAN exist when So many things never will - is beautiful. */}
          {/* Under subjectivism, value is subjective.  Under objectivism, the community will help determine its value? Hmm.. damnit. */}
          {/* It's a home for lost, confused, and unappreciated art */}
          {/* The beningn  */}
          {/* and take life a bit less seriously */}
          {/* Hmm... not just about "taking it easy"... hmmm.. more abotu loving and accepting what is hm! */}
          <Text style={{ marginLeft: isDesktop ? undefined : 20, alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: isDesktop ? 'left' : 'left', marginTop: 35, color: '#666666', fontFamily: primaryFontFamily, fontSize: 20, letterSpacing: -0.5 }}>Here, art doesn't have to be aesthetic, meaningful, or even honest - it just has to exist.  It's a place to question your artistic assumptions, make friends, and celebrate what is.</Text>

          {/* challenge your beliefs */}
          {/* <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: 'left', marginTop: 35, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}></Text> */}

          <TouchableOpacity onPress={() => navigation.navigate("Authenticate" as never, { mode: "login" } as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: primaryFontFamilyHeavy, fontSize: 20 }}>Get Started</Text>
          </TouchableOpacity>
        </View>

      </View>

      {!isDesktop && <ImageSection />}

    </CenteredView>
  );
}

export const ShopSection = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 550 : 550;



  const ImageSection = () => {
    return (
      <View style={{ flex: isDesktop ? 1 : undefined, height: minHeight, alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ height: '100%', width: '100%', borderRadius: defaultBorderRadius, overflow: 'hidden', alignSelf: 'center' }} >
          <Image source={require('../assets/homer.jpg')} resizeMode="cover" style={{ width: '100%', height: '100%' }} />
        </View>
      </View>
    );
  }

  const settings = useSettings();
  const navigation = useNavigation();
  return (
    <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize, flexDirection: isDesktop ? 'row' : 'column' }}>

      <View style={{ height: minHeight, flex: isDesktop ? 1 : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        <View style={{ justifyContent: 'center', alignItems: isDesktop ? 'flex-start' : 'center', maxWidth: 400 }}>
          <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 45 : isTablet ? 45 : 50, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>Support</Text>

          <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: isDesktop ? 'left' : 'left', marginTop: 35, color: '#888888', fontFamily: primaryFontFamily, fontSize: 20, letterSpacing: -0.5, marginLeft: isDesktop ? undefined : 20 }}>Support our artists by buying bad products made with our bad art!  Currently, we give 50% of the profits to the artist, and 5% to artistic charity.</Text>

          {/* <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: 'left', marginTop: 35, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>Proceeds are shared between the studio, the artist, and charity.</Text> */}

          <TouchableOpacity onPress={() => navigation.navigate("Shop" as never)} style={{ marginTop: 50, padding: 15, backgroundColor: primaryColor, borderRadius: 10, width: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white', fontFamily: primaryFontFamilyHeavy, fontSize: 20 }}>Shop</Text>
          </TouchableOpacity>
        </View>

      </View>

      {/* CONSIDER:  AHH!!!  Code has an IMPLICIT, COUPLED order with rendering!  That can KINDA be frusterating hm!  Instead .. I'd REALLY like to DE-COUPLE that hM!  THEn.. have it coupled in an auxiliary editor hm! */}
      <ImageSection />

    </CenteredView>
  );
}

export const FooterSection = () => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const minHeight = isDesktop ? 400 : isTablet ? 300 : 300;


  const settings = useSettings();
  const navigation = useNavigation();

  return (
    <CenteredView containerProps={{ style: { backgroundColor: '#333333' } }} style={{ flex: 1, paddingHorizontal: settings.marginSize, alignItems: 'center', justifyContent: 'center', paddingVertical: 30 }}>

      <View style={{ flexDirection: 'row' }}>
        <Pressable onPress={() => navigation.navigate("Terms" as any)}>
          <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: 'left', color: '#eeeeee', fontFamily: primaryFontFamily, fontSize: 14, letterSpacing: 0 }}>Terms of Use</Text>
        </Pressable>

        <View style={{ width: 7 }} />

        <Pressable onPress={() => navigation.navigate("Privacy" as any)}>
          <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', textAlign: 'left', color: '#eeeeee', fontFamily: primaryFontFamily, fontSize: 14, letterSpacing: 0 }}>Privacy Policy</Text>
        </Pressable>

      </View>

      <View style={{ height: 15 }} />

      <Text style={{ alignSelf: 'center', textAlign: 'left', color: '#eeeeee', fontFamily: primaryFontFamily, fontSize: 14, letterSpacing: 0 }}>© 2022 badart.studio All Rights Reserved</Text>


      {/* <SubscribeForm /> */}

    </CenteredView>
  );
}

export const MotivationSection = () => {

  const { isDesktop, isTablet, isMobile } = useSizes();
  const minHeight = isDesktop ? 900 : isTablet ? 700 : 600;

  return (
    <>
      <View style={{ flexDirection: 'column', borderRadius: defaultBorderRadius, alignItems: 'center', padding: 100, backgroundColor: '#f8f8f8' }}>

        <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#333333', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 30 : isTablet ? 45 : 60, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7 }}>What is bad art?</Text>

        {/* Just having fun and NOT competing with them */}
        {/* Questioning VALUE as a whole... and the idea of INTENT and anything really.. what makes something artistic?  */}
        {/* Do we want to be whimsical and kitsch?  OR do we want to be semi-serious about the Philosophy of this?  MAYBE a BLEND? Hmm... MAYBE keep them wondering? Hmm... let THEM define it? Hmm.. LIKE the Barnum and Bailey Circus hm!  MAYBE I can get some lessons from them hm! */}
        {/* <Text style={{ letterSpacing: -1.5, lineHeight: isMobile ? 40 : isTablet ? 55 : 70, color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: isMobile ? 15 : isTablet ? 25 : 40, textShadowColor: 'rgba(255,255,255,0.8)', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 7, fontStyle: 'italic' }}>All art is bad.</Text> */}

        {/* No matter who you are, somebody will always dismiss your art as bad. */}
        <Text style={{ maxWidth: 550, textAlign: 'center', marginTop: 10, color: '#888888', fontFamily: primaryFontFamily, fontSize: isMobile ? 15 : isTablet ? 25 : 40, letterSpacing: -0.5 }}>All art is bad to someone.</Text>

        {/* Dali, Van Gogh, or even Davinci, */}


      </View>

      {/* Some will say it's about INTENT... but that's not clear eithe rhm!  The POINT here, is to exhibit.  It's to show that TALENT and all that is one thing, but WHY does that matter?  Why?  We all have a story to tell hmmm...  */}

      <View style={{ flexDirection: isDesktop ? 'row' : 'column', borderRadius: defaultBorderRadius, alignItems: 'center', padding: 50 }}>

        <GroupCard style={{ flex: 1, padding: 0, minWidth: 400, overflow: 'hidden' }}>

          <Image source={require("../assets/disintegration.jpg")} style={{ width: '100%', height: 400 }} />
          <View style={{ height: 2, backgroundColor: "#eeeeee" }} />
          <View style={{ padding: 30, backgroundColor: '#f4f4f4' }}>
            <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', maxWidth: 400, marginTop: 25, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>Salvador Dalí</Text>
            <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', maxWidth: 400, marginTop: 25, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>The Disintegration of the Persistence of Memory</Text>
          </View>

          <View style={{ padding: 30 }}>
            <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', maxWidth: 400, marginTop: 25, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>The paintings are cosmic junkyards. ... There is much too much in them. There is also far too little. Your eyes are simultaneously over-stimulated and starved for something to look at. This is the visual equivalent of junk food.</Text>
            <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', maxWidth: 400, marginTop: 25, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>https://newrepublic.com/article/77084/salvador-dali-late-work-so-bad</Text>
          </View>


        </GroupCard>

        <View style={{ width: 30, height: 30 }} />

        <GroupCard style={{ flex: 1 }}>

        </GroupCard>

        {/* <View style={{ width: 30, height: 30 }} />

        <GroupCard style={{ flex: 1 }}>

        </GroupCard> */}


        {/* Dali, Van Gogh, or even Davinci, */}


      </View>

      <View style={{ flexDirection: 'column', borderRadius: defaultBorderRadius, alignItems: 'center', padding: 50 }}>



        <Text style={{ alignSelf: isDesktop ? 'flex-start' : 'center', maxWidth: 400, marginTop: 25, color: '#888888', fontFamily: primaryFontFamily, fontSize: 22, letterSpacing: -0.5 }}>So, </Text>


      </View>
    </>

  );
}

export const Main = ({ section, navigation, toggleMenu }: { section?: string, navigation: NavigationProp<any>, toggleMenu: () => void }) => {

  const { isDesktop, isMobile, isTablet } = useSizes();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [redditUsername, setRedditUsername] = React.useState("");
  const [centeredWidth, setCenteredWidth] = React.useState(1200);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const [subscribePosition, setSubscribePosition] = React.useState<number | undefined>(undefined);


  const dimensions = useWindowDimensions();
  const minHeight = isDesktop ? 900 : isTablet ? 700 : 600;
  // const minHeight = undefined;
  const screenHeight = dimensions.height;
  const screenWidth = dimensions.width;
  const aspectRatio = 3 / 1.5;  //  Width / Unit Height

  const desktopScaleFactor = 0.62;
  const tabletScaleFactor = 0.8;
  const creatureAspectRatio = 1.5 / 1;

  const creatureWidth = isDesktop ? centeredWidth * desktopScaleFactor * creatureAspectRatio : centeredWidth * tabletScaleFactor * creatureAspectRatio;
  const creatureHeight = isDesktop ? centeredWidth * desktopScaleFactor : centeredWidth * tabletScaleFactor;

  console.log("Creature Width: " + creatureWidth);
  console.log("Creature Height: " + creatureHeight);

  const settings = useSettings();

  //  CONSIDER:  This is a VERY specific system we've buit here.  We CAN use something more general and abstract with tags, and I think I'd prefer that, but ... when do we build explicit, and when do we generalize?
  const scrollToSection = () => {

    if (section === 'subscribe') {
      // alert(subscribePosition)
      scrollViewRef.current?.scrollTo(subscribePosition);
    }
  }

  React.useEffect(() => {
    scrollToSection();
  }, [subscribePosition])

  return (
    <>
      {/* TODO:  Register these similar to Wordpress hm!  BUT ... hmm.. */}

      {/* Hero Region */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
        <Image source={require('../assets/main_text.png')} style={{ width: 529, height: 135 }} />
      </View> */}


      {/* NOTE:  AHH!  DO want to be able to add "modifiers" at a GLOBAL, component, and lots of other levels, but NOT just for style... for EVERYTHIGN about a comone thm... THis way. I can do it in a CONTEXT too? Hmm.. */}
      {/* AH!!  I DO like my interfacea like this and the POINT is... we can apply that to anything hmm.  These are like MODIFIERS Hm!  "Higher level" meanin ghm!  Do the same with 3D and hm!" */}

      <ScrollView onLayout={scrollToSection} ref={scrollViewRef}>
        <GalleryHeader flat={true} centered={true} transparent={true} showButtons={true} mode='light' />
        {/* <CenteredView style={{ flex: 1, paddingHorizontal: settings.marginSize }} onLayout={(event) => { setCenteredWidth(event.nativeEvent.layout.width) }}> */}

        <IntroSection />

        <View style={{ height: 2, width: '100%', backgroundColor: '#eeeeee' }} />

        <CultureSection />

        <View style={{ height: 2, width: '100%', backgroundColor: '#eeeeee' }} />

        <ShopSection />

        <View style={{ height: 2, width: '100%', backgroundColor: '#eeeeee' }} />

        <SubscribeForm onLayout={event => { setSubscribePosition(event.nativeEvent.layout.y + 100); }} />

        {/* TODO:  Talk about MOTIVATION, adn about SUBJECTIVISIM and the technology we have to let people express their opinions!  That's a KEY thing hm!  Anything goes!  Everything is set with associations and placed in this complex space hm! */}

        <FooterSection />

        {/* <MotivationSection /> */}
        {/* </CenteredView> */}



      </ScrollView>

    </>
  );
}

// export const Home = ({ navigation, setUser, user, menuVisible, toggleMenu, menuItems, gridWidth }: { setUser: any, navigation: NavigationProp<any>, user?: CognitoUser, menuVisible: any, toggleMenu: any, menuItems: any, gridWidth: any }) => {
//   return (
//     <View style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', overflow: 'scroll' }}>
//       <Main toggleMenu={toggleMenu} navigation={navigation} />
//     </View>
//   );
// }
