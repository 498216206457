import { StackNavigationProp } from '@react-navigation/stack';
import { InstanceInternal, Page, Plugin, pluginNoun } from 'habor-sdk';
import * as React from 'react';
import { Text } from 'react-native';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { AppEditor } from './app-editor.screen';
import { SystemStackParamList } from '../apps.nav';
import { HAppRoute } from '../apps.nav.routes';

export type AppViewerNavigationProp = StackNavigationProp<
  SystemStackParamList,
  HAppRoute.SystemConfig
>;

interface AppViewerProps {
  navigation: AppViewerNavigationProp;
  plugin?: InstanceInternal<Plugin>;
  pluginId?: string;
  showConfig: boolean;  //  TODO:  Instead of routing here, I'd like to have BOTH set as routes?? Hmm... maybee.  Then I can go DIRECTLY to the default when I want to!
}

interface AppViewerState {
  page?: InstanceInternal<Page>;
  loading: boolean;
  plugin?: InstanceInternal<Plugin>;
}

//  CONSIDER:  Perhaps this default config should always be shown, as it's JUST the "Detail" view for a System as specified by the PRIMITIVE Habor system!?  This IS the value of Habor!? HM!!  Then, for custom configs, we can explicitly add those? Hmmmm...
export class AppViewer extends React.Component<AppViewerProps, AppViewerState> {

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      page: undefined,
      loading: true
    };
  }

  public componentDidMount = async () => {

    const { token } = this.context;
    const { plugin, pluginId } = this.props;

    //  Guard (for now)
    //  TODO:  SHould be generalized and done as an "aspect"... basically a side thing. hm!
    if (!token) { alert("Missing Token"); return; }
    if (!plugin && !pluginId) { alert("Missing Plugin"); return; }

    let _plugin: InstanceInternal<Plugin> | undefined = plugin ? plugin : undefined;

    //  Get the Plugin (if needed)
    if (!plugin) {
      const _plugins = await haborSDK.searchInstances(token, { nounId: pluginNoun.id, search: { match: { id: pluginId } } })
      _plugin = _plugins[0];
    }


    if (_plugin) {
      //  TODO:  Really don't like this being in global state... we do have a CONTEXT which has this, but yeah hmm...
      this.context.setPlugin(_plugin);
    } else {
      //  TODO:  Don't hard-code the name
      alert(`No plugin was loaded in 'system-config'.`)
    }

    //  TODO-IMPORTANT:  Unset the Plugin when we leave!!!  PERHAPS this is where it makes sense to have a NESTED context!  The context will apply JUST when the parent component is mounted!  in this case, perhaps it's the System Nav?  OR, maybe it's THIS component with a NESTED nav for this stuff!!!

    //  TODO:  AGAIN, I'd like to have a MORE geneic way of doing this stuff.. TO STARRT, the functionalty should reside in the individual primtive plugins / systems!!  BUT, also a generic query / search / expression language!? HM!

    //  TODO:  Bring back the config page thing?
    // const page = await getConfigPage(plugin, token);  

    //  Set the Page
    this.setState({ loading: false, plugin: _plugin });
  }

  public render = () => {

    //  Unpack
    const { page, loading, plugin } = this.state;

    // items={ installedPlugins } onItemPress={ plugin => navigate(this.props.history, RouteList.Plugins.Detail, { plugin })}
    //  TODO:  Cool loading page!
    if (loading) {
      return <Text>Loading System Config...</Text>
    }

    // CONSIDER:  Can we merge the concepts of "SystemConfig" and Pages and stuff?  Seems like a lot of redundant re-direction / registers?? Hmm!!
    //  TODO:  Instead of routing here, route at a higher level with an explicit route for each!?

    return <AppEditor navigation={this.props.navigation} />
    //  TODO:  Bring back config page??? NEver really liked it hard-coded here anyways hm!
    // return (
    //   page && !this.props.showConfig ?
    //     <WorkspacePageRouter navigation={ this.props.navigation } page={ page } pageProps={{}} /> :
    //     <DefaultSystemConfig navigation={ this.props.navigation } />
    // );
  }
}
