
import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { ENoun } from '../../../enoun-service';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { medSpacer } from '../../../packages/kelp-bar/styles';
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { Entity, EntityPlugin } from "../entity-plugin";
import { Deferred, renderEntityListItem } from '../entity-plugin/entity-list';
import { haborSDK } from '../hessia-plugin/config';
import { SystemPlugin } from '../system-plugin/system-plugin';

//  CONSIDER:  MAYBE we just accept an ID for the itnernal OR a partial for the fieldset? Hmm

//  CONSIDER:  INJECT the handlers.. instead of coupling with the component? Hmm.. the "Component" is JUST an identifiable thing!  We CAN use the modifier pattern and stuff to make it work.. SAMe thing I was doing with Aggregate trackers and shit ugh!  PLUS Swift extensions hm! MUCH like XML HM!  NOW I could speak to XML and all that with the language of semiotics hm!!!
export const SymbolWidget = ({ symbolInternal, symbolPartial, symbolPlugin, entityPlugin, onCreated, onUpdated, onChange }: { symbolInternal?: NounServiceInstanceInternal<Symbol>, entityPlugin: EntityPlugin, symbolPartial?: Partial<Symbol>, symbolPlugin: SymbolPlugin, onCreated?: (symbolInternal: NounServiceInstanceInternal<Symbol>) => void, onUpdated?: (symbolInternal: NounServiceInstanceInternal<Symbol>) => void, onChange?: (symbolInternal: NounServiceInstanceInternal<Symbol>) => void }) => {

  //  CONSIDER:  This is a COMMON pattern.  Can we generalize??  I don't see why not! Inheriting kinda? hmm.. with fallback? Hmm
  const initialSymbol: Symbol = {
    name: symbolInternal?.payload.name || symbolPartial?.name || "",
    destEntityId: symbolInternal?.payload.destEntityId || symbolPartial?.destEntityId || ""
  };

  const [symbol, setSymbol] = React.useState(initialSymbol);
  const [destEntity, setDestEntity] = React.useState<NounServiceInstanceInternal<Entity> | undefined>(undefined);

  const loadEntity = async () => {
    if (symbol.destEntityId) {
      const _targetEntity = await entityPlugin.entityService.retrieve(symbol.destEntityId);
      console.log(JSON.stringify(_targetEntity));
      setDestEntity(_targetEntity);
    }
  }

  React.useEffect(() => {
    if (symbol.destEntityId) {
      loadEntity();
    }
  }, []);

  return (
    <View style={{ paddingHorizontal: medSpacer }}>
      <Text>Name</Text>
      {/* TODO:  Figure out if it's OK to call the loadEntity thing RIGHT after calling setSYmbol or if we should be using a callback of some sort? */}
      <TextInput value={symbol.name} onChangeText={(value) => { setSymbol({ ...symbol, name: value }); loadEntity(); }} />

      <Text>Entity</Text>
      {/* TODO:  Add MULTIPLE options to select an entity hm!  MAYBE make this pluggable hm! */}
      <TextInput value={symbol.destEntityId} onChangeText={(value) => setSymbol({ ...symbol, destEntityId: value })} />
      <Deferred func={renderEntityListItem} args={{ destEntity, entityPlugin }} def={<Text>Loading...</Text>} />

      {
        symbolInternal ?
          <Button title="Update" onPress={async () => {
            const _symbolInternal = await symbolPlugin.symbolService.update(symbolInternal.id, symbol);
            if (onUpdated) { onUpdated(_symbolInternal); }
            if (onChange) { onChange(_symbolInternal); }
          }} /> :
          <Button title="Create" onPress={async () => {
            const _symbolInternal = await symbolPlugin.symbolService.create(symbol);
            if (onCreated) { onCreated(_symbolInternal); }
            if (onChange) { onChange(_symbolInternal); }
          }} />
      }

    </View>
  );
}


//  NOTE:  This is essentially a "Pointer".  hmm

export interface Symbol {
  name: string;
  destEntityId: string;
}

//  TODO:  Move this into the Plugin??
export const symbolService = new HaborNounService<Symbol>("symbol", haborSDK);


interface SymbolProps {
  symbols: NounServiceInstanceInternal<Symbol>[];
}
class SymbolListBase extends React.Component<{ navigation: NavigationProp<any>, symbolPlugin: SymbolPlugin, entityPlugin: EntityPlugin }, any> {

  constructor(props) {
    super(props);
    this.state = {
      symbols: []
    }
  }

  componentDidMount = async () => {
    const symbols = await symbolService.retrieveAll();
    this.setState({ symbols });
  }

  render() {

    const symbolElems = this.state.symbols.map((symbol: NounServiceInstanceInternal<Symbol>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate("Editor", { symbol })}>
          {/* <TextParagraph>{ symbol.payload.name }</TextParagraph> */}
          {/* <Deferred func={ renderEntityListItem } args={ symbol.payload.entityId } def={ <Text>Loading...</Text> } /> */}
          <SymbolWidget entityPlugin={this.props.entityPlugin} symbolPlugin={this.props.symbolPlugin} symbolInternal={symbol} />
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Symbols</Text>
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('Editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>
          <ScrollView showsHorizontalScrollIndicator={false}>
            {symbolElems}
          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}

const InjectedSymbolList = (props: any) => <SymbolListBase {...props} />
export const SymbolList: any = InjectedSymbolList;

const Stack = createStackNavigator();

export const SymbolPage = ({ symbolPlugin, entityPlugin }: { symbolPlugin: SymbolPlugin, entityPlugin: EntityPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} headerMode="none">
      <Stack.Screen name="Editor" component={SymbolEditor} />
      <Stack.Screen name="List" component={() => <SymbolList entityPlugin={entityPlugin} symbolPlugin={symbolPlugin} />} />
    </Stack.Navigator>
  );
}

//  NOTE:  Simulates a relationship linking a String and an Entity TAGGED as a "Symbol".. hmmm

//  TODO:  Inject into the SCRIPTING system! HM!

export const SymbolEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const [symbolNoun, setSymbolNoun] = React.useState<ENoun<Symbol>>(route?.params?.symbol);
  const [name, setName] = React.useState<string>(symbolNoun?.payload.name || "");
  const [destEntityId, setDestEntityId] = React.useState<string>(symbolNoun?.payload.entityId || "");

  return (
    <View style={{ marginTop: 100 }}>
      <Text>Name</Text>
      <TextInput value={name} onChangeText={setName} />

      <Text>Destination Entity ID</Text>
      <TextInput value={destEntityId} onChangeText={setDestEntityId} />

      <Button title={symbolNoun ? 'Update' : 'Create'} onPress={symbolNoun ? async () => { await symbolService.update(symbolNoun?.id, { name, destEntityId: destEntityId }); } : async () => { await symbolService.create({ name, destEntityId: destEntityId }); }} />
      <Button title="Delete" onPress={() => symbolService.delete(symbolNoun.id)} />
    </View>
  );
}




export class SymbolPlugin extends CorePluginClass {

  public symbolService = symbolService;

  public static details = {
    name: "PrimitiveSymbols",
    description: "Symbols Primitive System",
    dependencies: [SystemPlugin.details.id, EntityPlugin.details.id, AuthPlugin.details.id],
    id: "symbolPlugin"
  }

  // public createSymbol = (symbol: Symbol) => {
  //   cosnt await symbolService.create(symbol)
  // }
  public install = async (program: Program, { system, pEntities, authPlugin }: { system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin }) => {

    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {

    //   //  Check if the Entity is a Symbols
    //   const symbol = await symbolService.retrieveByEntityId(entityId);
    //   if (symbol) {
    //     api.setText("Symbol: " + symbol.payload.name);
    //   }

    //   //  Check if there is a symbol RELATED.
    //   const symbols = await symbolService.retrieveAll();
    //   console.log(entityId);
    //   console.log(symbols);
    //   const related = symbols.filter(symbol => symbol.payload.destEntityId === entityId);
    //   console.log(related);
    //   for (let i = 0; i < related.length; i++) {
    //     const relatedSymbol = related[i];
    //     api.addComponent(() => {
    //       return (
    //         <View>
    //           <Text>Symbol</Text>
    //           <Text>{ relatedSymbol.payload.name }</Text>
    //         </View>
    //       );
    //     });
    //   }
    // });

    await symbolService.init(authPlugin.token);

    system.registerPrimitiveSystem({
      id: 'symbols-system',
      name: "Symbol",
      description: "Symbols Primitive System",
      labels: ["core"],
      component: () => <SymbolPage entityPlugin={pEntities} symbolPlugin={this} />,
      menuItem: {
        icon: { name: "math-compass", type: "material-community" },
        backgroundColor: "#5d9af0",
        iconColor: "white"
      }
    });

    return this;
  }
}
