import {
  DT,
  DTObject,
  DTOption,
  DTOptions,
  registerSDTDeserializer,
  SDT,
  SDTObject,
  SDTSchemaProperties,
  SDTSerializedSchemaProperties,
  SDTYup
} from "davel";
import { CorePluginClass, Program } from 'halia';
import { View } from "react-native";
import { Slider } from "react-native-elements";
import * as yup from "yup";
import { registerDavelType } from "../../packages/davel-ui/davel-ui";
import { DavelField, SDTRendererParams } from "../../packages/davel-ui/davel-ui-tools";
import { registerSDTSchema } from "../../packages/davel-ui/types/sdt/sdt-field";
import { Paragraph } from "../../packages/kelp-bar/constants";


//  TODO:  Should support EXTENSIONS like Swift!  This way, other files CAN alter a plugin.  It's effectively a Plugin Plugin hmm... MAYBE just use the plugin API, BUT it would be KINDA nice to be able to define a plugin in multiple files too!

//
//  DT Core - Rating
//

export interface DTRatingOptions extends DTOptions { }

export class DTRating<MetadataType = any> extends DT<number, MetadataType> {
  public name = "Rating";

  constructor(
    public options: DTRatingOptions = {},
    protected metadata?: MetadataType
  ) {
    super(options);
  }

  public async validate(input: number): Promise<number | undefined> {

    //  Super Validation
    const inputV2 = await super.validate(input);

    //  Handle Undefined
    if (inputV2 === undefined) {
      return undefined;
    }

    //  Validate Input
    const valid = inputV2 >= 0 && inputV2 <= 10;
    if (!valid) {
      this.failValidation(inputV2);
    }
    return inputV2;
  }

  public async getElasticSchema() {
    return {
      type: "number",
    };
  }
}


//
//  Serialized DT - Rating
//

export interface SDTRating extends SDT {
  type: "rating";
}

const SDTRatingYup = SDTYup.shape({
  type: yup
    .string()
    .required()
    .matches(/rating/),
});

export async function SDTRatingParser<MetadataType = any>(
  serialized: SDT,
  metadata?: MetadataType
) {

  //  Validate
  const valid = SDTRatingYup.validateSync(serialized);
  if (!valid) {
    throw new Error(`Rating schema validation failed`);
  }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy["type"];
  const ratingOptions: DTRatingOptions = { ...serializedCopy };

  //  Create the DTRating
  const dtRating = new DTRating(ratingOptions, metadata);
  return dtRating;
}

//  Schema to Validate a Rating SDT
export const SDTRatingSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: "option", options: ["rating"] },
  },
};

export const SDTRatingSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ["rating"] }),
  },
});

registerSDTDeserializer("rating", SDTRatingParser);


//
//  Davel UI Registration - Rating
//

export const RatingWidget = ({ rating = 0, onChange }: { rating: number, onChange: (rating: number) => void }) => {

  if (isNaN(rating)) {
    rating = 0;
  }

  return (
    <View>
      <Slider minimumValue={0} maximumValue={10} step={0.25} value={rating} onValueChange={onChange} />
      <Paragraph>{rating}</Paragraph>
    </View>
  );
};

export const sdtRatingRenderer = async ({ sdt, name, value, update }: SDTRendererParams) => (
  <DavelField required={sdt.required} key={name} name={name}>
    <RatingWidget rating={value} onChange={update} />
  </DavelField>
);


//  TODO:  Inject the Davel Core.

export class RatingPlugin extends CorePluginClass {

  public static details = {
    name: 'Rating Plugin',
    description: 'Rating Plugin',
    dependencies: [],
    id: 'ratingPlugin'
  }

  public install = (program: Program, { }: {}) => {
    registerDavelType({
      id: "rating",
      name: "Rating",
      description: "A 1-10 rating field.",
      color: "#333333",
      defaultSDT: { type: "rating" },
      renderer: sdtRatingRenderer,
      icon: { name: "rating", type: "material" },
      schema: SDTRatingSerializedSchema
    });

    registerSDTSchema("rating", SDTRatingSerializedSchema);

    return this;
  }
}