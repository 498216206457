import { InstanceInternal } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, TextInput, View } from 'react-native';
import { TagPlugin } from '.';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { Entity, EntityPlugin, getEntitiesByIds, getEntityId, useEntityPlugin } from '../entity-plugin';
import { EntityWidget } from '../entity-plugin/entity-list';
import { HessiaTag, useTagPlugin } from './tag-plugin';

//  TODO:  Tag Selector
//  TODO:  Entity Selector
//  TODO:  FILTERED entity selector with CATEGORY!

//  Build a SELECTOR.. hmm... Maybe MULTIPLE "Selectors".
//  MAYBE work on CATEGORIES hm... 
//  START doing it separately.


export const getTagsForEntity = async (entity: Entity, tagPlugin: TagPlugin, entityPlugin: EntityPlugin) => {

  //  TODO:  Getting ALL is ... crazy inneficient.. like SUPER freaking innefieicnt ugh!
  //  TODO:  SHOULD be able to DEPEND on a Halia Plugin from ANY function.. Want all of this MANAGEd so we can ah!  See it in the REPRESENTATION.. the STATE space that we use in our MINDS Hm... MANY possible, and many possible!

  
  const entityId = getEntityId(entity);
  const _tagAssignmentsRaw = await tagPlugin.tagAssignmentService.retrieveAll();
  const _tagAssignments = _tagAssignmentsRaw.filter(assignment => assignment.payload.destId === entityId);
  const tagIds = _tagAssignments.map(assignment => assignment.payload.tagId);
  const allTags = await tagPlugin.tagService.retrieveAll();
  const tags = allTags.filter(tag => tagIds.includes(tag.id))
  return tags;
}

export const getEntitiesForTag = async (tag: NounServiceInstanceInternal<HessiaTag>, tagPlugin: TagPlugin, entityPlugin: EntityPlugin): Promise<Entity[]> => {

  //  CONSIDER:  A function SHOULD also be able to ... INTERNALLY define its dependencies? Hmm.. INTERESTING!  We can grab from other contexts, BUT we want that to be managed hm!

  const _tagAssignmentsRaw = await tagPlugin.tagAssignmentService.retrieveAll();
  const _tagAssignments = _tagAssignmentsRaw.filter(assignment => assignment.payload.tagId === tag.id);
  const entityIds = _tagAssignments.map(assignment => assignment.payload.destId);
  // const entities = await getEntitiesByIds(entityIds, entityPlugin);
  return entityIds.map(_entity => JSON.parse(_entity));
}


export const TagSelector = () => {

}

export const TagEditor = ({ navigation, route }: { navigation: any, route: any }) => {

  const tagPlugin = useTagPlugin();
  const entityPlugin = useEntityPlugin();

  const tagInternal: NounServiceInstanceInternal<HessiaTag> = route?.params?.tag;

  const [tag, setTag] = React.useState(tagInternal?.payload.value);
  const [assignmentEntities, setAssignmentEntities] = React.useState<Entity[]>();

  const [entityId, setEntityId] = React.useState("");

  const loadTargets = async () => {
    if (tagInternal) {

      const entities = await getEntitiesForTag(tagInternal, tagPlugin, entityPlugin);
      setAssignmentEntities(entities);
    }
  }

  React.useEffect(() => {
    loadTargets();
  }, [])

  //  TODO:  Get assignments
  //  TODO:  Build a system to ASSIGN Tags and yeah hm!  BOTH from HERE to select entities AND from the ENTITY to select Tags!
  //  CONSIDER:  A DASHBOARD with Recent Activity, and also PERSONAL dashboard for peronsl stuf fhm!  ALL those ideas I've ALWAYS had hm! 

  return (
    <View style={{ marginTop: 100 }}>

      <Text>Tag Value</Text>
      <TextInput value={tag} onChangeText={setTag} />

      <Text>Targets</Text>
      {
        assignmentEntities?.map(entity => <EntityWidget entity={entity} entityPlugin={entityPlugin} />)
      }

      <Button title={tagInternal ? 'Update' : 'Create'} onPress={tagInternal ? async () => { await tagPlugin.tagService.update(tagInternal.id, { value: tag }); } : async () => { await tagPlugin.tagService.create({ value: tag }); }} />
      <Button disabled={!tagInternal} title="Delete" onPress={() => tagPlugin.tagService.delete(tagInternal.id)} />

      <Text>Add Target</Text>
      <TextInput value={entityId} onChangeText={setEntityId} />

      <Button disabled={!tagInternal} title={'Create'} onPress={async () => { await tagPlugin.tagAssignmentService.create({ tagId: tagInternal.id, destId: entityId }) }} />

    </View>
  );
}
