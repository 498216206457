import { ConfigurationPage, ConfigurationPageNoun, EntityType, Instance, InstanceID, InstanceInternal, Noun, NounId, NounInternal, OwnershipRelationship, OwnershipRelationshipNoun, Page, PageNoun, Plugin, pluginNoun, SearchTerm, SystemAssociation, SystemAssociationNoun, SystemEnablement, SystemEnablementNoun, Workspace, workspaceNoun } from "habor-sdk";
import * as _ from 'lodash';
import * as React from 'react';
import { AppContext } from "../hessia-plugin/AppContext";
import { haborSDK } from "../hessia-plugin/config";

//  TODO-IMPORTANT:  Move JUST ABOUT ALL of these to their associated PLUGINS and DEPEND on them to get initialized and injected values!

//
//  HabotUtils - A set of utilities for interacting with the Habor Backend.
//

export async function createOrUpdateInstance<T>(nounId: string, newInstPayload: T, token: string, origInst?: InstanceInternal<T>) {
  const newInst: Instance<T> = { nounId, payload: newInstPayload };
  if (origInst) {
    return await haborSDK.updateInstance<T>(origInst.id, newInst, token);
  } else {
    return await haborSDK.createInstance<T>(newInst, token);
  }
};

export async function createOrUpdateNoun(newNoun: Noun, token: string, origNoun?: NounInternal) {
  if (origNoun) {
    return await haborSDK.updateNoun(origNoun.id, newNoun, token);
  } else {
    return await haborSDK.createNoun(newNoun, token);
  }
};


export async function getAllInstancesForNounList(nounIds: NounId[], token: string) {
  const instances: InstanceInternal[] = [];
  for (const nounId of nounIds) {
    const instancesForNoun = await haborSDK.searchInstances(token, { nounId: nounId.nounId });
    instances.push(...instancesForNoun);
  }
  return instances;
}

export async function getInstances(instanceIds: InstanceID[], token: string) {

  //  Group by NounID
  const instIdMap = _.groupBy(instanceIds, id => id.nounId);

  //  Create the Instance Map
  const instMap: { [ nounId: string ]: InstanceInternal[] } = {};

  //  Get the Instances
  for (const nounId in instIdMap) {
    const instanceIds = instIdMap[nounId];
    const queryTerms: SearchTerm[] = instanceIds.map(instId => ({ match: { id: instId.instanceId } }));
    const instances = await haborSDK.searchInstances(token, { nounId, search: { any: queryTerms } })
    instMap[nounId] = instances;
  }

  return instMap;
}

export async function getNouns(nounIds: string[], token: string) {
  const searchTerms = nounIds.map(nounId => ({ match: { id: nounId } }));
  const nouns = await haborSDK.searchNouns(token, { search: { any: searchTerms } });
  return nouns;
}

//  TODO:  We DO want to support SEVERAL TYPES of instance (and othe object) views!  SO, eventually this should probably NOT be a hard-coded selection.
export async function renderInstance(instance: InstanceInternal) {
  //  TODO:  Make this method
}

// export const getPlugins = async (user: InstanceInternal<APIUser>, token: string) => {

//      //  Get Owned Plugin ACL Policies
//     //  const pluginPolicies = await haborSDK.searchInstances<InstanceAccessPolicy>(token, { nounId: "instance-access-policy", search: { all: [{ match: { payload: { nounId: 'userplugin' } } }, { match: { payload: { owner: user.id } } }] } })

//      //  Get the Plugin IDs
//     //  const pluginIds = pluginPolicies.map(policy => policy.payload.instanceId);
 
//      //  Get Plugins owned by the logged-in User
//      //  TODO:  Currently obtaining ALL UserPlugins because they're all public... work on making them owned by a single user BUT available through a Plugin.  In other words, only show plugins you actually OWN.
//      const pluginSearchTerms = pluginIds.map(pluginId => ({ match: { id: pluginId } }));
//      const ownedPlugins: InstanceInternal<UserPlugin>[] = await haborSDK.searchInstances(token, { nounId: "userplugin", search: { any: pluginSearchTerms } });


// }


//  TODO:  Memoize this function??  BUT, the backend COULD change, so we need to be careful about caching!  FOR NOW, it's OK, because ALL updates happen through this interface, and we can invalidate it.  BUT, in the future, we MAY want to subscribe to a websocket feed or something in case an external system invalidates this cache!

export const getWorkspacePages = async (workspace: InstanceInternal<Workspace>, token: string) => {
//  TODO:  Centralize a LOT of this... It's shared with WorkspaceEditor.  FOR NOW, do it in the UI.  Later, the backend would ideally return this stuff WITH the requested objects... When we query Workspace, it's possible it returns with EVERYTHING.  Or, do something like GraphQl, where we make a NEW mapping layer between the two, and we can choose to NOT get embedded items... OR, instead of a new mapping layer, we could just support options for embedded, etc. IN our system... BUT we MIGHT want the user to be able to create their own "Pseudoobjects" which are just mappings into existing objects!  THIS is what GraphQL is doing.
    //  TODO:  A LOT of these functions can be placed in individual PLUGINS and INJECTED into this function??  Then, we'll START by centralizing on the client-side and eventually push out to the server??

    //  Get the Owner Relationships for Plugins pointing to this Workspace (Space)
    //  TODO:  The relationship represents an "Installation", BUT this should be an IMPLEMENTATION detail of the UserPlugin / Workspace systems!
    //  System (Plugin) -> Space
    const pluginOwnerRels = await haborSDK.searchInstances<SystemEnablement>(token, { nounId: SystemEnablementNoun.id, search: { all: [{ match: { payload: { srcId: { nounId: pluginNoun.id } } } }, { match: { payload: { destId: { nounId: workspaceNoun.id, instanceId: workspace.id } } } } ] } });

    //  Get the Workspace Page Relationships
    //  Object -> System
    const pageRelTerms = pluginOwnerRels.map(rel => ({ any: [{ match: { payload: { destId: rel.payload.srcId }} }] }));
    const pageRels = await haborSDK.searchInstances<SystemAssociation>(token, { nounId: SystemAssociationNoun.id, search: { all: [{ match: { payload: { srcId: { nounId: PageNoun.id } } } }, { any: pageRelTerms } ] } });

    //  Get the Workspace Pages
    const pageTerms = pageRels.map(rel => ({ match: { id: rel.payload.srcId.instanceId } }));
    const pages = await haborSDK.searchInstances<Page>(token, { nounId: PageNoun.id, search: { any: pageTerms }});
    console.log("test");

    return pages;
};

//  NOTE:  It's possible to get a false-positive here with a bad assumption, so be aware that the ASSUMPTIONN is the object is EITHER an Instance or a Noun!
export const isNoun = (object: NounInternal | InstanceInternal) => {
  const objectInst = object as InstanceInternal;
  const objectNoun = object as NounInternal;
  return (objectInst.nounId == undefined) && (!!objectNoun.id) && (!!objectNoun.name);  //  NOTE:  Only Instances have a nounId field, and all instances have a nounId field.  In addition it must have an ID field.
}

export const isInstance = (object: NounInternal | InstanceInternal) => {
  const objectInst = object as InstanceInternal;
  return (!!objectInst.nounId);  //  NOTE:  Only Instances have a nounId field, and all instances have a nounId field.
}

//  TODO:  Make sure the "Ownership" / "Nestable" system isn't just re-inventing the "Space" / "Plugin" system.  They should MEAN diffrent things.  PERHAPS, we have tasks that are "Owned" by the "Hessia" Project (for example).  PERHAPS, Spaces are Ownership, and Systems are systems??? SO... hmmm... interesting... We'll want to work through these 3, possibly more concept... Systems, Spaces, and Nestable Ownership.
export const nestObject = async (child: NounInternal | InstanceInternal, parent: NounInternal | InstanceInternal, token: string) => {

  //  Make Casts
  const childNoun = child as NounInternal;
  const childInst = child as InstanceInternal;
  const parentNoun = parent as NounInternal;
  const parentInst = parent as InstanceInternal;

  //  Check Instances
  const isInstChild = isInstance(child);
  const isInstParent = isInstance(parent);

  //  Make the Relationship
  await haborSDK.createInstance<OwnershipRelationship>({
    nounId: OwnershipRelationshipNoun.id,
    payload: {
      srcId: {
        nounId: isInstChild ? childInst.nounId : childNoun.id,
        instanceId: isInstChild ? childInst.id : undefined,
        type: isInstChild ? EntityType.Instance : EntityType.Noun
      },
      destId: {
        nounId: isInstParent ? parentInst.nounId : parentNoun.id,
        instanceId: isInstParent ? parentInst.id : undefined,
        type: isInstParent ? EntityType.Instance : EntityType.Noun
      }
    }
  }, token);
}

export class HaborReactComponent<P, S, SS = any> extends React.Component<P, S, SS> {
  static contextType = AppContext;

  //  TODO:  SHOULD be able to type this!  We get an error because the babel, expo preset doesn't seem to support declare fields... hmm...  FOR NOW, I'm typing manually before usage.
  context!: React.ContextType<typeof AppContext>;
}


//  Gets Installed User Plugin Relationships (each relationship indicates Plugin installation)
//  CONSIDER:  Perhaps eventually this should be encapsulated by the backend...
//  TODO:  Move this to its OWN system!?  HMM!!!  Then, use the DI system to inject the instance VERY simlar to the way we do it in Habor!???  Again, pehraps this is WHY we can eventually merge them!? HMMmmmm! 
//  TODO:  Make a more generic way to do this?  AGAIN, I LOVE Django and how it has a system to resolve LINKAGES!?  MAYBE we want to do something VERY similar!
//  TODO:  REMOVE this entirely.. instead of doing it with a COUPLING do it GENERICALLY where we use the context of the systems to SCOPE and stuff hm!  In the case of a Space selection, we might FILTER all things we search for to have a space association hm!  BUT... is that simple enough? hmm... Should we inject into some generic "Seaarch" system OR couple with EACH system!?  AH!  THIS is where having generic CAN be pretty helpful and a standard across system??? HM!  The INSTANCE thing kinda already provides us that.  BUT we can ALSO do this with a standard INTERFACE, even if they're separate "physcal ssytems" hm!
export const getSystemsForWorkspace = async (workspace: InstanceInternal<Workspace>, token: string): Promise<InstanceInternal<Plugin>[]> => {
  //  TODO:  Does this belong here???
  if (!workspace) { return [] }
  const pluginRelationships: InstanceInternal<SystemEnablement>[] = await haborSDK.searchInstances<SystemEnablement>(token, { nounId: SystemEnablementNoun.id, search: { all: [{ match: { payload: { destId: { instanceId: workspace.id } } } } ] } });
  console.log(pluginRelationships);
  if (pluginRelationships.length <= 0) { return []; }

  //  Get the Plugins
  const installedPlugins: InstanceInternal<Plugin>[] = await haborSDK.searchInstances(token, { nounId: pluginNoun.id, search: { any: pluginRelationships.map(rel => ({ match: { id: rel.payload.srcId.instanceId } }) ) } });

  return installedPlugins || [];
}

export const getSystemForNoun = async (noun: NounInternal, token: string) => {
  const pluginRelationships: InstanceInternal<SystemAssociation>[] = await haborSDK.searchInstances<SystemAssociation>(token, { nounId: SystemAssociationNoun.id, search: { all: [{ match: { payload: { srcId: { nounId: noun.id } } } } ] } });
  if (pluginRelationships.length <= 0) { return; }
  if (pluginRelationships.length > 1) { alert(`Multiple Systems:  The '${ noun.name }' Noun is associated with multiple Systems:  Currently, a Noun may only be associated with a SINGLE System.`); }
  const association = pluginRelationships[0];

  //  Get the Plugins
  const installedPlugins: InstanceInternal<Plugin>[] = await haborSDK.searchInstances(token, { nounId: pluginNoun.id, search: { match: { id: association.payload.destId.instanceId }  }});
  if (pluginRelationships.length <= 0) { throw `System Not Found:  The '${ noun.name }' Noun is associated with the '${ association.payload.destId.instanceId }' System, but that system was not found.` }

  return installedPlugins[0];
}

export const getSystemDependencies = async (system: InstanceInternal<Plugin>, token: string): Promise<InstanceInternal<Plugin>[]> => {
  //  THOUGHT:  OK!!!  Look what's going on here!  We're MAPPING and stuff in a PREDICTABLE way.. this is a COMMON pattern that PERHAPS we want to ABSTRACT!?  It's just taking an ID and RESOLVING the associated object.. This SHOULD be something we can do automatically in Hessia!? HMm!  Instead of expliclty doing this, the SYSTEM should be able to resolve such links!  OK!  Now that's just THIS case!  I believe there are MANY other instances where things can be standardized and simplified!?
  const { dependencies = [] } = system.payload;
  const dependentSystems: InstanceInternal<Plugin>[] = [];
  for (const dependencyId of dependencies) {
    const dependentSystem = await haborSDK.retrieveInstance(pluginNoun.id, dependencyId, token);
    dependentSystems.push(dependentSystem);
  }
  return dependentSystems;
}



export const getConfigPageRelationship = async (plugin: InstanceInternal<Plugin>, token: string) => {

  //  Get the Config Page
  const configPageRelationships: InstanceInternal<ConfigurationPage>[] = await haborSDK.searchInstances<ConfigurationPage>(token, { nounId: ConfigurationPageNoun.id, search: { match: { payload: { destId: { type: EntityType.Instance, instanceId: plugin.id, nounId: plugin.nounId } } } } });

  //  Validate
  //  TODO:  These validation checks should be performed in the back-end!
  if (configPageRelationships.length > 1) {
    throw new Error("Only one configuration page is permitted per system");
  } else if (configPageRelationships.length <= 0) {
    console.log("This system has no configuration page");
    return undefined;
  }

  return configPageRelationships[0];
}

export const getConfigPage = async (plugin: InstanceInternal<Plugin>, token: string) => {

  const configPageRel = await getConfigPageRelationship(plugin, token);
  if (!configPageRel) { return undefined }

  const configPageId = configPageRel?.payload.srcId.instanceId;
  
    //  Get the Page
    const pages = await haborSDK.searchInstances<Page>(token, { nounId: PageNoun.id, search: { match: { id: configPageId } } });
    if (pages.length > 1) {
      throw new Error(`Multiple pages are associated with the same Page ID: ${ configPageId }`);
    } else if (pages.length <= 0) {
      //  TODO:  Fix the default page system.. Don't know why it's using this noun... I think it should be a different one.  ALso.. woek on making all Entities accessible, and ALSO work on making them extensible from other systems.  AGAIN... it's like addinga Plugin to change a model.. we can do it with EXTENSIONS like Swift.  Plus.. we can do it with entiteis and associations no need for an explicit model.  This is how JS does it basically AND how I've been thinking about this for a LONG time... just entities and associations... why?  Because it needs to start SOMEWHERE ugh!
      console.warn(`The '${ configPageId }' page could not be found.  It should exist as an instance of Noun '${ configPageRel.nounId }'.  This is `);
      return undefined;
    }
    const page = pages[0];
    return page;
}

export const getInstanceId = (instance: InstanceInternal): InstanceID => {
  return {
    nounId: instance.nounId,
    instanceId: instance.id,
    type: EntityType.Instance
  };
}

export const getNounId = (noun: NounInternal): NounId => {
  return {
    nounId: noun.id,
    type: EntityType.Noun
  };
}