/* eslint-disable import/first */
import { NavigationProp } from '@react-navigation/core';
import * as _ from "lodash";
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { isObject } from 'util';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { ENounService } from '../../../enoun-service';
import { AttachmentRoutes } from './attachment-navigator';
import { AttachmentPlugin } from './attachment-plugin';

export const valueAtPath = (obj: any, path: string[]) => {
  let value = obj;
  console.log("OBJ: " + JSON.stringify(value));
  console.log("PATH: " + JSON.stringify(path));
  for (const part of path) {
    value = value[part];
    if (value === undefined) { return undefined; }
  }
  console.log("VALUE: " + JSON.stringify(value) + "\n\n");
  return value;
};
//  REFERENCE:  https://medium.com/cameron-nokes/4-common-mistakes-front-end-developers-make-when-using-fetch-1f974f9d1aa1

//  TODO:  Eventually make search universal and remove "Noun" vs "Instance" distinction?


//  REFERENCE:  https://stackoverflow.com/a/51365037
type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends (object | undefined) ? RecursivePartial<T[P]> :
    T[P];
};


export type Primitive = string | number | Date;

/**
 * I'm not sure how to express this in TS, but an ObjectPath has only ONE key per level.
 * TODO:  How can we enforce this with a Match clause and the recursive partial?
 */
export interface ObjectPath {
  [name: string]: ObjectPath | Primitive;
}

export type ArrayPath = string[];

/**
 * Converts an ObjectPath (which only 1 key per level) to an array of strings.
 * @param obj
 * @param startKey Optionally start the path from a particular key.
 * TODO:  Remove "startKey" and manage that in an outer thing.  It seems like a more complex differentiation hm.
 */
export const getArrayPathFromObjPath = (obj: any, startKey?: string): string[] => {
  const newObj: ObjectPath = startKey ? obj[startKey] : obj;
  if (isObject(newObj)) {
    if (Object.keys(newObj).length > 1) { console.log("BAD OBJ: " + JSON.stringify(newObj));  throw new Error("Cannot construct an object path from an object with multiple keys"); }
    const key = Object.keys(newObj)[0];
    const nestedObj = newObj[key];
    const path = isObject(nestedObj) ? getArrayPathFromObjPath(nestedObj) : [];
    return startKey ? [startKey, key, ...path] : [key, ...path];
  } else {
    return startKey ? [startKey] : [];
  }
  
  
};

export function processMatchTerm<T>(matchSearchTerm: MatchSearchTerm, objects: any[]): any[] {

  //  TODO:  Consider using an Array of Strings to select a path instead of a Nested Object.
  //  NOTE:  The "MatchSearchTerm" is an object with multiple keys allowed in the first layer.  Attached to each key is an "ObjectPath".  This is an Object where EVERY level has only a single key with either an ObjectPath or a Value.

  //  Validate
  const { match } = matchSearchTerm;
  if (!match) {
    throw Error("Cannot generate a Match search term with a non-match search term.");
  }

  //  Create a filtered Array
  const filteredArray = objects.filter(obj => {
    const matchKeys = Object.keys(match);

    //  NOTE:  Match Keys work like an "AND" Query...
    //  TODO:  Disable support for MULTIPLE Match keys... use an "AND" Query instead.
    for (const matchKey of matchKeys) {

      //  Construct a path for each key
      console.log("MATCH TERM: " + JSON.stringify(match))
      console.log("MATCH KEY: " + JSON.stringify(matchKey))
      const path = getArrayPathFromObjPath(match, matchKey);

      //  Get the Value of the Match Term
      // alert("match: " + JSON.stringify(match))
      console.log("MATCH PATH: " + JSON.stringify(path))
      const queryValue = valueAtPath(match, path);

      //  Get the Value of the Object
      const objValue = valueAtPath(obj, path);

      //  Check Equivalence
      if (objValue != queryValue) {
        return false;
      }
    }
    return true;
  });

  return filteredArray;
}

export function processAnyTerm<T>(anySearchterm: AnySearchTerm<T>, objects: any[]): any[] {

  //  Validate
  const { any } = anySearchterm;
  if (!any) {
    throw Error("Cannot generate an 'Any' search term with a non-any search term.");
  }

  //  Process Child Terms
  const results: any[] = [];
  any.map(term => {
    return searchObjects(term, objects);
  });

  //  Combine Results (Set Union)
  return _.union(...results);
}

export function processAllTerm<T>(allSearchTerm: AllSearchTerm<T>, objects: any[]): any[] {

  //  Validate
  const { all } = allSearchTerm;
  if (!all) {
    throw Error("Cannot generate an 'All' search term with a non-all search term.");
  }

  //  Process Child Terms
  const results = all.map(term => {
    return searchObjects(term, objects);
  });

  //  Combine Results (Set Intersection)
  return _.intersection(...results);
}

//  TODO:  Create some form of interface to keep logic shared between DB searches and local search?  Almost like a Repo!  The interface DID change though, because locally, we need to put in values.  Maybe wrap this in a Class and initialize the class with values?
export function searchObjects<T>(searchTerm: SearchTerm<T>, objects: any[]): any {

  //  Handle Undefined (Return All)
  if (searchTerm == undefined) { return objects; }

  //  Declare Types
  const matchSearchTerm = searchTerm as MatchSearchTerm<T>;
  const allSearchTerm = searchTerm as AllSearchTerm<T>;
  const anySearchterm = searchTerm as AnySearchTerm<T>;

  //  CONSIDER:  Inject these??

  //  Check Match
  if (matchSearchTerm.match !== undefined) {
    return processMatchTerm<T>(matchSearchTerm, objects);
  }

  //  Check All
  if (allSearchTerm.all !== undefined) {
    return processAllTerm<T>(allSearchTerm, objects);
  }

  //  Check Any
  if (anySearchterm.any !== undefined) {
    return processAnyTerm<T>(anySearchterm, objects);
  }
}


export interface NounSearchParams<T = any> {
  search?: SearchTerm<T>;
  from?: number;  //  Defaults to 0
  size?: number;  //  Defaults to 50
}

export interface SearchTermBase {}

export interface RangeParams {
  gt?: string;
  gte?: string;
  lt?: string;
  lte?: string;
}

export type RangeMap = { [name: string]: RangeMap | RangeParams };

export interface RangeSearchTerm<T = any> extends SearchTermBase {
  range: RangeMap;  //  TODO:  Actually type with the T type.
}

export interface MatchSearchTerm<T = any> extends SearchTermBase {
  match: RecursivePartial<T>;  //  CONCERN:  This should lead down a single path, not multiple.
}

export type ExistsMap = { [name: string]: ExistsMap | boolean };
export interface ExistsSearchTerm<T = any> extends SearchTermBase {
  exists: ExistsMap;  //  TODO:  Actually type with the T type.
}

export interface NotSearchTerm<T = any> extends SearchTermBase {
  not: { [name: string]: any };
}


export interface AllSearchTerm<T = any> extends SearchTermBase {
  all: SearchTerm<T>[];
}

export interface AnySearchTerm<T = any> extends SearchTermBase {
  any: SearchTerm<T>[];
}

export type SearchTerm<T = any> = AnySearchTerm<T> | AllSearchTerm<T> | MatchSearchTerm<T> | ExistsSearchTerm<T> | NotSearchTerm<T> | RangeSearchTerm<T>;

//  IDEA:  Currently, if the set of values meeting a search condition changes, then the start / size specifiers may be off?  So, consider passing some sort of hash / indicator that a search set was invalidated?  Maybe this is OK.  The user MAY miss data, but they can refresh?
export interface InstanceSearchParams<T = any> {
  nounId: string;
  search?: SearchTerm<T>;
  from?: number;  //  Defaults to 0
  size?: number;  //  Defaults to 50
}




//  TODO:  MAYBE I can make COMPONENTS which have Top, BOttom, Left, and Right registers? Hmm... VERY similar to the DOM in a way? Hmm...
//  TODO:  HOW will we REGISTER the view registerrs? Hmm
// export const PluggableView = () => {
// }

export const topRegister: any[] = [];

//  Make the Attachment Model
export interface Attachment {
  src: string;
  dest: string;  //  NOTE:  This is an Entity ID FOR NOW!  MAYBE we can configure an ISTANCE of the "Relatio" system for other primitive systems / uses? Hm
}

//  WOULD be nice to be able to MIXIN functioanlity... like the "Entity Builder" thing.. hmm.. instead of making a bunch of inheritance things.  The class is JUST entities / assoiations!@ HMM.. so HOW can we do that?  I'd like to be bale to apply a MIXIN to the class which does that stuff.. hmm.. the idea is have a CLASS BUILDER which injets in the appropriate places perhaps? HM!
//  NICE IDEA!:  Build a "class builder" or.. ENCODING BUILDER which lets you build an encoding with options.  Things like "Entiities", etc.. the idea is, it's MUCH like the Plugin thing where we inject based on what we've selected, like Mixins and NOT the same as inheritance, unless we support multi, but even then, wwhere does the injection occur and stuff? Hmm  MAYBE more like React Hooks where we can add a piece? hmm.. but that's JUST that one piece of state and doesn't affect the others? Hmm.. the idea is add a thing which DOES know how to HOOK into this thing!? HM!  I like that!  It may "DO" a BUNCH of stuff to the thing!? HM!
//  NOTE:  FOR NOW, let's just use inheritance to keep things rolling.
//  NOTE:  We ALREADY have th3e "emitter" thign which is the SAME as a HOOK in a spot!  It's just going to FIRE those functions HM!  This is perrhaps what we can use to build the composite thing, which AGAIN is MUCH like a Plugin system.  It's like.. selecting which plugins we want for a thing !HM!  MAYBE they can DEPEND upon one another and shit too! HM!  MABYE this is how we want to be able to build MOST encodings? HM!  Inheritance lets us wrap, but the Plugin thing will let us use any of those hooks? Hmm.. and we can keep track of which are compatible and which are not, etc.. NOW how can we do that in a LIGHTWEIGHT way? Hmm... see, it's not JUST about building the whole program, it's also sub-parts and applies to pretty much ANY encoding? HM!

//  NOTE:  I MIGHT want to store the association with the Attachment system, BUT that's recursive, AND it would be mixing Entitiy IDs and this thing.. hmm


//  NOTE:  Links a primitive thing held by a primitive system to an Entity.
//  CONSIDER:  Should we generalize to primitive link?  MAYBE we'll want to link to more than just Entities? Hmm.
//  CONSIDER:  INSTEAD of doing this like this, DOES it make sense to store the encoding in the "Entity" table?  Well.. by SEPARATING them, we can do specialized encodings and stuff here while STILL having representation in the BAG! HM!
//  NOTE:  Decided against this in favor of an ENounService (EntityNounService) which stores the 1-1 mapping.  BUT we MAY want to change that up so it doesn' HAVE to rreturn with the entityId.. hmm.  Maybe we can USE a noun service inside it? Hmm... to impleent the functions? Hmm... instead of going to the super? Hmm
// export interface EntityLink {
//   entityId: string;
//   primitiveId: string;
//   // systemId: string;  //  TODO:  COnsider scoping by System ID.
// }
// export const entityLinkService = new NounService<EntityLink>("entityLink");


//  CONSIDER:  SHOULD we bundle this together like this? Hmm...


interface AttachmentProps {
  attachments: NounServiceInstanceInternal<Attachment>[];
}
class AttachmentListBase extends React.Component<{ attachmentPlugin: AttachmentPlugin, navigation: NavigationProp<any> }, any> {

  constructor (props) {
    super(props);
    this.state = {
      attachments: []
    }
  }

  componentDidMount = async () => {

    //  Load Attachments
    const attachments = await this.props.attachmentPlugin.attachmentService.retrieveAll();
    this.setState({ attachments });
  }

  //  Filter / Search Plugin
  //  Plugins that work WITH Filter / Search
  //  Plugins that inject element into the main view? HM!  MAYBE make GENERIC view elements SIMILAR to DOM? HM!
  render() {

    const attachmentElems = this.state.attachments.map((attachment: NounServiceInstanceInternal<Attachment>) => {
      return (
        <TouchableOpacity style={{ borderRadius: 20, backgroundColor: 'white', paddingVertical: 30, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onPress={ () => this.props.navigation.navigate(AttachmentRoutes.Editor, { attachment }) }>
          <Text>{ JSON.stringify(attachment.payload) }</Text>
        </TouchableOpacity>
      );
    });
    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>
        {/* <View style={{ backgroundColor: 'blue' }}>
          { component }
        </View> */}

          {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Attachments</Text>

            {/* TODO:  Abstract with Hessia? */}
            <TouchableOpacity onPress={ () => { this.props.navigation.navigate(AttachmentRoutes.Editor) } } style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>

          {/* Top Register */}
          <View>
            { topRegister }
          </View>

          { attachmentElems }
        </Page>
      </PageLoader>
      )
  }
}

const InjectedAttachmentList = (props: any) => <AttachmentListBase { ...props } />
export const AttachmentList: any = InjectedAttachmentList;