import { AllTMSDTs, HaborComponent } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import { HessiaPlugin } from "../hessia-plugin";
import * as React from 'react';
import { PrimitiveProps } from "./habor-react/habor-component-lib";

//  TODO-IMPORTANT:  This whole Plugin is ACTUALLY a tool used to register PRIMITIVE components.  I suggest we re-brand it as such?  It may NOT have a representation in Habor???

//  CONSIDER:  Should some registration be stored in Habor?
//  CONSIDER:  Instead of registering components, SHOULD we be registering raw code?
//  TODO:  Check the prop types on register?
//  QUESTION:  Should we support duplicate registration?
//  TODO:  Remove global registration?

export const ComponentPluginContext = React.createContext({} as any);

export interface ComponentRegister {
  name: string;
  description: string;
  propType: AllTMSDTs;

  //  TODO:  Don't explicitly couple with these!  INJECT support for these things? Hmm...
  haborComponents: HaborComponent[];
  primitiveComponents: (typeof React.Component | React.FunctionComponent<PrimitiveProps>)[];
}

export class ComponentPlugin extends CorePluginClass {

  public static details = {
    name: "Component",
    description: "Component Plugin",
    dependencies: [HessiaPlugin.details.id],
    id: "component"
  }

private registers: ComponentRegister[] = []

public registerHaborComponent = (registerName: string, component: HaborComponent) => {
  
  //  Check Register
  const register = this.registers.find(existingRegister => existingRegister.name == registerName);
  if (register == undefined) { throw new Error(`The selected register '${ registerName }' does not exist.`) }

  //  Register the Component
  register.haborComponents.push(component);
}

//  TODO:  Register support for ADDITIONAL "component" types!
public registerPrimitiveComponent = (registerName: string, component: typeof React.Component | React.FunctionComponent<any>) => {
  
  //  Check Register
  const register = this.registers.find(existingRegister => existingRegister.name == registerName);
  if (register == undefined) { throw new Error(`The selected register '${ registerName }' does not exist.`) }

  //  Register the Component
  register.primitiveComponents.push(component);
}

public getRegister = (registerName: string) => {
  
  //  Check Register
  const register = this.registers.find(existingRegister => existingRegister.name == registerName);
  if (register == undefined) { throw new Error(`The selected register '${ registerName }' does not exist.`) }

  //  Return the Registerd Components
  return register;
}

/**
 * This creates a PRIMITIVE register that we can use in the PRIMITIVE context.  It is NOT stored in Habor / the DB, but it can be LOADED from that.
 * CONSIDER:  Replacing with a more generic 'before', 'after', 'above', 'left', etc... registers?
 * CONSIDER:  Supporting actual Primitive React Components since this is a primitive concept anyways!?
 * @param register
 */
public createRegister = (register: ComponentRegister) => {

  //  Check Register
  const exitingRegister = this.registers.find(existingRegister => existingRegister.name == register.name);
  if (exitingRegister != undefined) { throw new Error(`The selected register '${ register.name }' has already been created.`) }

  //  Create the Register
  this.registers.push(register);

  return register;
}

  public install = (program: Program,{ hessia }: { hessia: HessiaPlugin }) => {
    hessia.registerHOC(({ children }) => 
      <ComponentPluginContext.Provider value={ this }>
        { children }
      </ComponentPluginContext.Provider>
    )
    return this;
  }
}


export const withComponent = (Comp: any) => {
  return (props: any) => {
    const plugin = React.useContext(ComponentPluginContext);
    return <Comp { ...props } componentPlugin={ plugin } />
  }
}