import { SDTObject, AllSDT, DTObject, DTArray, DTOption, DTKeyword, DTSDT, SDT } from "davel";
import { AllTMSDTs } from "habor-sdk";
import { HRequestContext, HStatement, HStatementSchema } from "./statement-model";
import { HSDTStatement, HSDTUserFunction } from "./function-type";

//  NOTE:  The last statement in EVERY branch should be either throwing an exception OR returning the expected type (possibly no return if type is Void)

//  TODO:  Support this when we enable custom primitives.
// interface FunctionServiceOptions {
//   enableCustomPrimitives: boolean;
//   customPrimitiveDir: string;
// }

export type HPrimitiveParamMap = { [name: string]: HPrimitiveParam };
export type HParamMap = { [name: string]: HPrimitiveParam | HVariableParam };

//  TODO:  Track Schema through params

/**
 * This is a Param that references a variable in the current context.
 */
export interface HVariableParam {
  varName: string;
  type: "variable";
}

//  TODO:  When passing primitive params, the would ideally be a simple object instead of a structure like this.
export interface HPrimitiveParam {
  value: any;
  type: "primitive";
}
export interface HVariable {
  name: string;
  constant: boolean;
  value: any;
  schema: AllTMSDTs;  //  TODO:  Can a TMStatement really be used as a variable??
}

export type VariableMap = { [varName: string]: HVariable };
export interface HFunctionContext {
  functionName: string;
  variables: VariableMap;
}

export type CallStack = HFunctionContext[];

export interface ProcessStatementsParams {
  statements: HStatement[];
  params: any;
  callStack: CallStack;
}

export interface HFunction {
  name: string;
  params?: SDTObject;  //  TODO:  Replace with actual params.
  //  TODO:  Consider whether we really want to combine these here...
  //  TODO:  Shoule we pass Habor?  Is it safe to allow the use of that API, or should we use a subset?  Infinite loops may be possible.
  definition: ((params: any, habor: any, reqContext: HRequestContext) => any) | HStatement[];
  returns: AllSDT | HSDTStatement | HSDTUserFunction;  //  TODO:  Consider deriving this from the function instead of defining it.
  type: string;
}

export const HFunctionSchemaProperties = {
  name: new DTKeyword({ required: true }),
    params: new DTSDT({ itemType: "object" }),
    returns: new DTSDT(),
    type: new DTKeyword()
};

export const HFunctionSchema = new DTObject({
  extensible: false,
  properties: HFunctionSchemaProperties
});

export const HSerializedFunctionSchemaProperties = {
  name: { type: "keyword", required: true },
  params: { type: "object", extensible: true, required: false, itemType: { type: "sdt" } },
  returns: { type: "sdt", required: true },
  type: { type: "keyword", required: true }
};

export const HSerializedFunctionSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: HSerializedFunctionSchemaProperties
};

export interface HPrimitiveFunction extends HFunction {
  definition: (params: any, habor: any, reqContext: HRequestContext) => any;  //  TODO:  Remove Habor dependency.
  type: "primitive";
}

export interface HUserFunction extends HFunction {
  definition: HStatement[];
  type: "user";
}

export const HUserFunctionSchema = new DTObject({
  extensible: false,
  properties: {
    ...HFunctionSchemaProperties,
    definition: new DTArray({
      required: true,
      itemType: HStatementSchema
    }),
    type: new DTOption({
      required: true,
      options: ["user"]
    })
  },
});

export const HUserFunctionSerializedSchema = {
  type: "object",
  extensible: false,
  properties: {
    ...HSerializedFunctionSchemaProperties,
    definition: {
      type: "array",
      required: true,
      itemType: HStatementSchema
    },
    type: {
      type: "option",
      required: true,
      options: ["user"]
    }
  }
};