import { Instance } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { EntityService } from '../../noun-entity-service';
import { NounServiceInstanceInternal } from '../../packages/noun-service/noun-service';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { EntityIdentityPlugin } from './entity-identity-plugin';
import { EntityPlugin } from './entity-plugin';
import { EntityEditor } from './entity-plugin/entity-editor';
import { HaborPlugin } from './habor-plugin';
import { ModelPlugin } from './model-plugin/model-plugin';
import { HaborInstanceService } from './model-plugin/model-sdk';

export class ModelEntityPlugin extends CorePluginClass {

  public static details = {
    name: 'Model-Entity Coupling Plugin',
    description: 'Adds Models to the Entity System',
    dependencies: [ModelPlugin.details.id, EntityPlugin.details.id, EntityIdentityPlugin.details.id, HaborPlugin.details.id, AuthPlugin.details.id],
    id: 'entitiesEntityCoupler'
  }

  public entityFromInstance = (instance: NounServiceInstanceInternal<Instance<any>>) => {

    return {
      ...instance,
      payload: {
        systemId: ModelPlugin.details.id,
        route: { type: "instance", modelId: instance.payload.nounId, id: instance.id },
        metadata: instance.payload
      }
    }
  }

  public entityFromModel = (noun) => {
    return {
      ...noun,
      payload: {
        systemId: ModelPlugin.details.id,
        route: { type: "model", id: noun.id },
        metadata: noun.payload
      }
    };
  }

  public install = (program: Program, { pEntities, modelPlugin, entityIdentityPlugin, habor, authPlugin }: { pEntities: EntityPlugin, modelPlugin: ModelPlugin, entityIdentityPlugin: EntityIdentityPlugin, habor: HaborPlugin, authPlugin: AuthPlugin }) => {

    //  CONSIDER:  I use the authPlugin to get the token here, BUT "onInitialized" of the "ModelPlugin" depends on the token.  SO... it's a little derived? Hmm... MAYBE they're still independent in a sense though? Hmm... 
    //             The POINT is, maybe we'll de-couple in the future, but for now, it's best not to assume that knowledge? Hmm...  I mean... I guess it's fine, but it makes the code clearer too hmm...

    authPlugin.onLogin(() => {
      modelPlugin.onInitialized(async () => {

        //  CONSIDER:  Combine the entier "Model" system under ONE registered entity system.  It's like a NODE in the GraphQL abstraction stack!  
        //  CONSIDER:  Eventually maybe we can automatically abstract and grow to new split points.

        //  Register Model Service
        pEntities.entityService.registerService({
          id: "model",
          name: "Model Entity Service",
          service: new EntityService(modelPlugin.modelService, this.entityFromModel)
        });

        //  Register Instance Services
        const allModels = await modelPlugin.modelService.retrieveAll();
        for (const model of allModels) {
          const instanceService = new HaborInstanceService<any>(habor.haborSDK, authPlugin.token, model.id);

          //  Register Instance Service
          pEntities.entityService.registerService({
            id: `model-instance-${model.id}`,
            name: `Model Instance ${model.payload.name} Entity Service`,
            service: new EntityService(instanceService, this.entityFromInstance)
          });

          //  Register Instance Identity Provider
          entityIdentityPlugin.registerIdentityProvider({
            systemId: ModelPlugin.details.id,
            id: `model-instance-${model.id}-identity-provider`,
            name: `Model Instance (${model.payload.name}) Identity Provider`,
            provide: (entity) => {

              if (entity.systemId !== ModelPlugin.details.id) { return; }

              if (entity.route.type !== "instance") { return; }

              if (entity.route.modelId !== model.id) { return; }

              //  NOTE:  This is one place we could BRIDGE these concept domains.  We can check for "NamedObject" for example and MAP to the identity provider / custom component etc ugh...
              //  NOTE:  I'm NOT a big fan of expliclty injectin "UI", and I'd like to infer it from the Ontology, BUT... EITHER WAY, we need to couple an Ontology RESOLVER at some point!  Right??? Hmm... maybe it can be recursively defined though? Hmmm!

              //  TODO:  Use custom icon / color.
              //  TODO:  Support paging.
              //  TODO:  Be able to de-activate / activate this entity thing hm!

              return {
                type: `model-instance-${model.id}`,
                icon: { name: "box", type: "feather" },
                iconColor: "white",
                iconBackgroundColor: "#25d2f5",
                name: `${model?.payload?.name || "Unknown Instance"}`,
                description: "A Model Instance",
                value: model?.payload?.name || "Unknown Instance"  //  TODO:  Display the component
              }
            }
          });

        }

        //  Register Model Identity Provider
        entityIdentityPlugin.registerIdentityProvider({
          systemId: ModelPlugin.details.id,
          id: "model-identity-provider",
          name: "Model Identity Provider",
          provide: (entity) => {

            if (entity.systemId !== ModelPlugin.details.id) { return; }

            if (entity.route.type !== "model") { return; }

            return {
              type: "model",
              icon: { name: "box", type: "feather" },
              iconColor: "white",
              iconBackgroundColor: "#26DEAA",
              name: "Model",
              description: "A Model",
              value: entity?.metadata?.name
            }
          }
        });

        //  Add Entity Detail to Model Instance View
        //  TODO:  Be able to see these in REVERSE too hmm...
        //  TODO:  Abstract logic for converting model / instance to Entity.
        //  OK!  SO... the entityId is a single thing which FORCES us to go back through.  Instead, we can register specifically for yeah!  We want to pass the PATH to the EntityEditor so we don't have to iterate ALL entities. Hmm... I guess that makes sense.  The IDEA here is we DO have multipoel "providers" for Entiteis ... these are "Systems" and yeah hmm... 
        modelPlugin.registerDetailComponent(({ userProps: { instance } }) => {
          const entity = this.entityFromInstance(instance);
          return <EntityEditor entity={entity} />
        })
      });
    })

    return this;
  }
}