
// import { InstanceInternal } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Text } from "react-native";
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from "../auth-plugin/auth-plugin";
import { Entity, EntityListItemAPI, EntityPlugin } from "../entity-plugin";
import { HaborPlugin } from "../habor-plugin";
import { HessiaPlugin } from "../hessia-plugin";
import { SystemPlugin } from "../system-plugin/system-plugin";
import { AudioNavigator } from "./audio-navigator";

export interface HessiaAudio {
  // value: audio;
}

export class AudioPlugin extends CorePluginClass {
  public static details = {
    name: "Audio",
    description: "Audio Primitive System",
    dependencies: [HessiaPlugin.details.id, SystemPlugin.details.id, EntityPlugin.details.id, HaborPlugin.details.id, AuthPlugin.details.id],
    id: "audioPlugin"
  }

  public showAudio;
  public serviceName = "audio";
  public audioService!: HaborNounService<HessiaAudio>;
  protected pEntities!: EntityPlugin;

  public install = async (program: Program, { hessia, system, pEntities, habor, authPlugin }: { hessia: HessiaPlugin, system: SystemPlugin, pEntities: EntityPlugin, habor: HaborPlugin, authPlugin: AuthPlugin }) => {

    this.showAudio = () => {
      hessia.navigation.navigate("audio-system");
    }
    this.audioService = new HaborNounService(this.serviceName, habor.haborSDK);
    await this.audioService.init(authPlugin.token);

    this.pEntities = pEntities;

    //  TODO:  Do this through a Coupling Plugin!
    //  TODO:  Make this a NAMED thing that we can access in our visual ontology thing hgsn !  EVERYTHING shold perhapss be visible in the ontology visually with enough drill-down? Hmmm
    pEntities.registerEntityListItemRenderer({
      id: "audio-entity-renderer",
      name: "Audio Entity Renderer",
      renderer: async (entity: Entity, api: EntityListItemAPI) => {
        if (entity.systemId === this.serviceName) {
          api.addComponent(() => {
            return (
              <>
                <Text>Audio Entity</Text>
              </>
            );
          })
        }
      }
    });

    system.registerPrimitiveSystem({
      id: 'audio-system',
      name: "Audio",
      description: "Audio Primitive System",
      labels: ["primitive"],
      component: ({ navigation, route }) => <AudioNavigator route={route} navigation={navigation} audioPlugin={this} />,
      menuItem: {
        icon: { name: "speaker", type: "material" },
        backgroundColor: "#c23659",
        iconColor: "white"
      },
      //  TODO:  SHOULD be able to put a piece here WHICH is just an ELEMENT of something else UGH.. again.. I DID want to be able to freaking do aggregate trackers anddd.. the idea of spread CODE elements came from fucking trying to organize FEATURES... not from anything else... just REGISTERING them hm!  This is NOT the same as the EAV thi gnhm!
      headerRight: () => null
    });

    return this;
  }
}
