import { NavigationProp, useNavigation } from '@react-navigation/native';
import { InstanceInternal, Plugin, pluginNoun } from 'habor-sdk';
import * as React from 'react';
import { Text, View } from 'react-native';
import { AppContext } from '../../hessia-plugin/AppContext';
import { haborSDK } from '../../hessia-plugin/config';
import { getRaisedStyle, medSpacer, primaryFontFamilyHeavy, RaisedHeight } from '../../../../packages/kelp-bar/styles';
import { BubbleListItem } from '../../../../packages/kelp-bar/bubble-list-item';
import * as _ from 'lodash';
import { RootRoute } from '../../system-plugin/system-types';
import { RoundIconButton } from '../../../../packages/kelp-bar/buttons';
import { HAppRoute } from '../apps.nav.routes';

//  TODO:  Lock the dependencies if we're VIEWING and don't have access to change the system.  EITHER WAY, the backend should enforce this! HM!
export interface DependencyWidgetProps {
  navigation: NavigationProp<any>;
  system: InstanceInternal<Plugin>;
}

export interface DependencyWidgetState {
  dependencies: InstanceInternal<Plugin>[];
}

class DependencyWidgetBase extends React.Component<DependencyWidgetProps, DependencyWidgetState> {
  constructor(props: DependencyWidgetProps) {
    super(props);
    this.state = {
      dependencies: []
    }
  }

  static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>

  public componentDidMount = async () => {

    this.refreshDependencies();
  }

  public componentDidUpdate = () => {
    this.refreshDependencies();
  }

  public refreshDependencies = async () => {
    const { token } = this.context.frameworkContext;

    const depIds = this.props.system.payload.dependencies || [];

    //  Get Dependencies
    const dependencies = await haborSDK.searchInstances(token, { nounId: pluginNoun.id, search: {
      //  TODO:  Remove the filter, a dependency should not be undefined / null, but somehow we got some in the system.
      any: depIds.filter(id => id != (undefined || null)).map(id => ({ match: { id } }))
    } })

    this.setState({ dependencies });
  }

  public addDependency = async (dep: InstanceInternal<Plugin>) => {

    const { system } = this.props;
    const { token } = this.context.frameworkContext;
    const newSystemPayload: Plugin = { ...system.payload };
    const deps = newSystemPayload.dependencies || [];
    deps.push(dep.id);
    newSystemPayload.dependencies = _.uniq(deps).filter(id => !!id);
    await haborSDK.updateInstance(system.id, { nounId: pluginNoun.id, payload: newSystemPayload }, token);
    this.forceUpdate();
  }

  public removeDependency = async (dep: InstanceInternal<Plugin>) => {

    const { system } = this.props;
    const { token } = this.context.frameworkContext;
    const newSystemPayload: Plugin = { ...system.payload };
    const deps = newSystemPayload.dependencies || [];
    const depIndex = deps.findIndex(depId => depId === dep.id)
    if (depIndex !== -1) {
      deps.splice(depIndex, 1);
    }
    //  TODO-IMPORTANT:  COnsider doing this in the BACKEND, AND making sure to delete the associated dependents!? HMM.  Things that we crretes IN this suystem USING the dependency!  FOR NOW, the system just won't show them, but they're still there if we re-connect!  MAYBE give the USER the option!? HM!
    await haborSDK.updateInstance(system.id, { nounId: pluginNoun.id, payload: newSystemPayload }, token);
    this.forceUpdate();
  }

  public render = () => {
    return (
      <View style={[{ backgroundColor: 'white', borderRadius: 15, padding: 15, display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: "#EDEDED" }, getRaisedStyle({  raisedHeight: RaisedHeight.low })]}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ color: '#777777', fontFamily: primaryFontFamilyHeavy, fontSize: 16, letterSpacing: -0.5 }}>
            Dependencies
          </Text>
          <View style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignSelf: 'center', alignItems: 'center' }}>
            <RoundIconButton
              onPress={ () => this.props.navigation.navigate(RootRoute.SystemSelector, { onSelect: this.addDependency }) }
              iconSelection={{ name: 'plus', type: 'font-awesome' }}
              backgroundColor="#25ced2"
            />
          </View>
        </View>

        {
          this.state.dependencies.map(dep => (
            <View style={[{ marginTop: medSpacer, display: 'flex', alignItems: 'center', flexDirection: 'row', alignSelf: 'stretch' }]}>
              {/* <TouchableOpacity onPress= } style={{ flex: 1, backgroundColor: '#f2f2f2', borderRadius: 25, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                
              </TouchableOpacity> */}

              {/* <Text>SHOULD BE SYSTM CONFIG - Needs refactor!  HApp should not be a concept known to system core??</Text> */}
              <BubbleListItem onPress={ () => this.props.navigation.navigate(HAppRoute.SystemConfig, { plugin: dep, showConfig: true }) }>
                <View style={{ flex: 1 }}>
                  <Text style={{ color: '#999999', fontFamily: primaryFontFamilyHeavy, fontSize: 15, textAlign: 'center', letterSpacing: -0.5 }}>
                    { dep.payload.name }
                  </Text>
                </View>
              </BubbleListItem>

              <View style={{ width: 10 }} />
              <RoundIconButton
                  onPress={ () => this.props.navigation.navigate(RootRoute.AlertBox, { title: "Warning", icon: { name: "warning", type: "material" }, description: "Removing a dependency is currently irreversible.  Associated elements may be deleted.  Are you sure you'd like to continue?", onContinue: () => this.removeDependency(dep) }) }
                  iconSelection={{ name: 'close', type: 'font-awesome' }}
                  backgroundColor="#d5d5d5"
                />
            </View>
          ))
        }

      </View>
    );
  }
}

export const DependencyWidget = (props: Omit<DependencyWidgetProps, "navigation">) => {
  const navigation = useNavigation();
  return <DependencyWidgetBase navigation={ navigation } { ...props } />
}
