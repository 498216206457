/* webpackIgnore: true */

/**
 * Halia React Native
 * 
 * Visual, Reactive, Extensible Native Development Environment
 */

import { registerRootComponent } from 'expo';
import * as Font from 'expo-font';
import { HaliaStack } from 'halia';
import { HaliaComponent, TestPlugin } from 'halia-native';
import * as React from 'react';
import { ActivityIndicator } from 'react-native';
import { unpackAsset } from './packages/unpack/unpack';
// import MenuLayoutPlugin from 'halia-plugin-menu-layout';
import { GalleryPlugin } from './plugins/gallery/gallery-plugin';
import { HaliaAppPlugin, hessiaPlugins2 } from './plugins/halia-studio/halia-app-plugin';
import { HaliaStudioPlugin } from './plugins/halia-studio/halia-studio-plugin';
import { AppsPlugin } from './plugins/hessia-plugins/apps-plugin/apps-plugin';
import { AttachmentPlugin } from './plugins/hessia-plugins/attachment-plugin/attachment-plugin';
import { AudioPlugin } from './plugins/hessia-plugins/audio-plugin';
import { AuthPlugin } from './plugins/hessia-plugins/auth-plugin/auth-plugin';
import { CheckinEntity } from './plugins/hessia-plugins/checkin-plugin/checkin-entity-plugin';
import { CheckinEvents } from './plugins/hessia-plugins/checkin-plugin/checkin-events-plugin';
import { CheckinPlugin } from './plugins/hessia-plugins/checkin-plugin/checkin-plugin';
import { ComponentPlugin } from './plugins/hessia-plugins/component-plugin';
import { ComposerPlugin } from './plugins/hessia-plugins/composer-plugin';
import { ContextPlugin } from './plugins/hessia-plugins/context-plugin/context-plugin';
import { DebugPlugin } from './plugins/hessia-plugins/debug-plugin/debug-plugin';
import { DueDatePlugin } from './plugins/hessia-plugins/due-date-plugin';
import { EdgePlugin } from './plugins/hessia-plugins/edge-plugin/edge-plugin';
import { ModelAppPlugin } from './plugins/hessia-plugins/entities-apps-plugin';
import { EntityIdentityPlugin } from './plugins/hessia-plugins/entity-identity-plugin';
import { EntityMenuPlugin } from './plugins/hessia-plugins/entity-menu-plugin/entity-menu-plugin';
import { EntityPlugin } from './plugins/hessia-plugins/entity-plugin';
import { EventPlugin } from './plugins/hessia-plugins/events-plugin';
import { HaborPlugin } from './plugins/hessia-plugins/habor-plugin';
import { HessiaPlugin } from './plugins/hessia-plugins/hessia-plugin';
import { ImagePlugin } from './plugins/hessia-plugins/image-plugin';
import { MenuPlugin } from './plugins/hessia-plugins/menu-plugin';
import { ModelEntityPlugin } from './plugins/hessia-plugins/model-entity-plugin';
import { ModelPlugin } from './plugins/hessia-plugins/model-plugin/model-plugin';
import { AccessPlugin } from './plugins/hessia-plugins/model-plugins/access-plugin';
import { ActivityPlugin } from './plugins/hessia-plugins/model-plugins/activity-plugin';
import { IdentityPlugin } from './plugins/hessia-plugins/model-plugins/identity-plugin';
import { NamedObjectPlugin } from './plugins/hessia-plugins/model-plugins/named-object-plugin';
import { ParentPlugin } from './plugins/hessia-plugins/model-plugins/parent-plugin';
import { PriorityPlugin } from './plugins/hessia-plugins/model-plugins/priority-plugin';
import { NotePlugin } from './plugins/hessia-plugins/notes-plugin';
import { NumberPlugin } from './plugins/hessia-plugins/number-plugin';
import { OrchestratorPlugin } from './plugins/hessia-plugins/orchestrator-plugin';
import { PagePlugin } from './plugins/hessia-plugins/page-plugin';
// import { PrimitivesPlugin } from './plugins/hessia-plugins/primitives-plugin';
import { PropertiesPlugin } from './plugins/hessia-plugins/properties-plugin';
import { RequestRecordPlugin } from './plugins/hessia-plugins/request-record-plugin';
import { ResourcePlugin } from './plugins/hessia-plugins/resource-plugin';
import { RouterPlugin } from './plugins/hessia-plugins/router-plugin';
import { SetPlugin } from './plugins/hessia-plugins/set-plugin/set-plugin';
import { SetSelectorPlugin } from './plugins/hessia-plugins/set-selector-plugin/set-selector-plugin';
import { SpacePlugin } from './plugins/hessia-plugins/space-plugin/space-plugin';
import { StatusPlugin } from './plugins/hessia-plugins/status-plugin';
import { StringPlugin } from './plugins/hessia-plugins/string-plugin';
import { SymbolPlugin } from './plugins/hessia-plugins/symbol-plugin/symbol-plugin';
import { SystemEntityPlugin } from './plugins/hessia-plugins/system-plugin/system-entity-plugin';
import { SystemPlugin } from './plugins/hessia-plugins/system-plugin/system-plugin';
import { TagPlugin } from './plugins/hessia-plugins/tag-system';
import { TimelinePlugin } from './plugins/hessia-plugins/timeline-plugin';
import { TrackingPlugin } from './plugins/hessia-plugins/tracking-plugin';
import { WorkspacePlugin } from './plugins/hessia-plugins/workspace-plugin';
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as HaliaImports from '../halia-imports';
import { AaroLandingPlugin } from './plugins/aaro-landing/aaro-landing';
import { AaroPlugin } from './plugins/aaro/aaro-core';
import { AssistantAaroExtension } from './plugins/aaro/extensions/assistant.extension';
import { Category2Plugin } from './plugins/aaro/extensions/category.extension';
import { CheckinAaroExtension } from './plugins/aaro/extensions/checkin.extension';
import { DashboardPlugin } from './plugins/aaro/extensions/dashboard.extension';
import { DietAaroExtension } from './plugins/aaro/extensions/diet.extension';
import { HabitPlugin } from './plugins/aaro/extensions/habits.extension';
import { HierarchyPlugin } from './plugins/aaro/extensions/hierarchy.extension';
import { MixedPlugin } from './plugins/aaro/extensions/mixed.extension';
import { ProgressPlugin } from './plugins/aaro/extensions/progress.extension';
import { RankingPlugin } from './plugins/aaro/extensions/ranking.extension';
import { RewardExtension } from './plugins/aaro/extensions/rewards.extension';
import { Routine2Plugin } from './plugins/aaro/extensions/routine.extension';
import { SprintPlugin } from './plugins/aaro/extensions/sprint.extension';
import { TaskAaroExtension } from './plugins/aaro/extensions/tasks.extension';
import { MenuLayoutPlugin } from './plugins/elements/menu-layout-plugin/menu-layout-plugin';
import { FlowPlugin } from './plugins/halia-studio/flow-plugin';
import { CategoryEntityPlugin } from './plugins/hessia-plugins/category-plugin/category-entity-plugin';
import { MoodPlugin } from './plugins/hessia-plugins/mood-plugin';
import { PlaidPlugin } from './plugins/hessia-plugins/plaid-plugin';
import { PlaygroundPlugin } from './plugins/hessia-plugins/playground-plugin';
import { RatingPlugin } from './plugins/hessia-plugins/rating-plugin';
import { TrackerPlugin } from './plugins/hessia-plugins/tracker-plugin';
// import { CorePlugin } from './plugins/hessia2/core-plugin';
import { PropertyPlugin } from './plugins/hessia2/property-plugin';
import { Entity2Plugin } from './plugins/hessia2/entity-plugin';
import { Hessia2Plugin } from './plugins/hessia2/hessia2-plugin';
import { Notes2Plugin } from './plugins/hessia2/notes2-plugin';
import { PrimitivesPlugin } from './plugins/hessia2/content-plugin';
import { Property2Plugin } from './plugins/hessia2/property-system';
import { S3SyncPlugin } from './plugins/hessia2/s3-plugin';
import { PatternPlugin } from './plugins/hessia2/class-plugin';
import { OverwebPlugin } from './plugins/overweb/overweb.plugin';
import { ProjectPlugin } from './plugins/aaro/extensions/project.extension';
import { FunctionPlugin } from './plugins/hessia2/function-plugin';
import { Apps2Plugin } from './plugins/hessia2/app.plugin';
import { ServiceRegistryPlugin } from './plugins/hessia2/register.service';

const program: {
  haliaStack?: HaliaStack,
} = {};

export const buildApp = async (): Promise<void> => {


  registerRootComponent(() => {

    const [loaded, setLoaded] = React.useState(false);

    const loadFonts = async () => {
      try {
        await Font.loadAsync({

          //  Material Icons
          // "material": unpackAsset(require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf")),

          //  Poppins Font
          "Poppins-ExtraBoldItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf')),
          "Poppins-Black": unpackAsset(require('./assets/fonts/Poppins/Poppins-Black.ttf')),
          "Poppins-BlackItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-BlackItalic.ttf')),
          "Poppins-Bold": unpackAsset(require('./assets/fonts/Poppins/Poppins-Bold.ttf')),
          "Poppins-BoldItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-BoldItalic.ttf')),
          "Poppins-ExtraBold": unpackAsset(require('./assets/fonts/Poppins/Poppins-ExtraBold.ttf')),
          "Poppins-ExtraItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-ExtraBold.ttf')),
          "Poppins-ExtraLight": unpackAsset(require('./assets/fonts/Poppins/Poppins-ExtraLight.ttf')),
          "Poppins-ExtraLightItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf')),
          "Poppins-Italic": unpackAsset(require('./assets/fonts/Poppins/Poppins-Italic.ttf')),
          "Poppins-Light": unpackAsset(require('./assets/fonts/Poppins/Poppins-Light.ttf')),
          "Poppins-LightItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-LightItalic.ttf')),
          "Poppins-Medium": unpackAsset(require('./assets/fonts/Poppins/Poppins-Medium.ttf')),
          "Poppins-MediumItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-MediumItalic.ttf')),
          "Poppins-Regular": unpackAsset(require('./assets/fonts/Poppins/Poppins-Regular.ttf')),
          "Poppins-SemiBold": unpackAsset(require('./assets/fonts/Poppins/Poppins-SemiBold.ttf')),
          "Poppins-SemiBoldItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf')),
          "Poppins-Thin": unpackAsset(require('./assets/fonts/Poppins/Poppins-Thin.ttf')),
          "Poppins-ThinItalic": unpackAsset(require('./assets/fonts/Poppins/Poppins-ThinItalic.ttf')),


          'Inter-Regular': unpackAsset(require('./assets/fonts/Inter/static/Inter-Regular.ttf')),
          'Inter-Bold': unpackAsset(require('./assets/fonts/Inter/static/Inter-Bold.ttf')),
          'Inter-SemiBold': unpackAsset(require('./assets/fonts/Inter/static/Inter-SemiBold.ttf')),
          'Inter-Black': unpackAsset(require('./assets/fonts/Inter/static/Inter-Black.ttf')),
          'Inter-ExtraBold': unpackAsset(require('./assets/fonts/Inter/static/Inter-ExtraBold.ttf')),
          'Inter-Var': unpackAsset(require('./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf')),

          'Outfit-Regular': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-Regular.ttf')),
          'Outfit-Light': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-Light.ttf')),
          'Outfit-Bold': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-Bold.ttf')),
          'Outfit-SemiBold': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-SemiBold.ttf')),
          'Outfit-Black': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-Black.ttf')),
          'Outfit-ExtraBold': unpackAsset(require('./assets/fonts/Outfit/static/Outfit-ExtraBold.ttf'))

        });

        setLoaded(true);
      } catch (err) {
        console.warn("THIS IS A TEST");
        console.warn(err);
      }
    }

    React.useEffect(() => {
      loadFonts();
    }, []);


    if (!loaded) {
      return <ActivityIndicator />
    } else {


      //  Register Plugins
      const knownPlugins = [TestPlugin, GalleryPlugin, AuthPlugin, HaborPlugin, MenuLayoutPlugin, OrchestratorPlugin, HessiaPlugin, SystemPlugin, EntityPlugin, EntityIdentityPlugin, ImagePlugin, AudioPlugin, StringPlugin, EdgePlugin, PropertiesPlugin, AttachmentPlugin, SymbolPlugin, NotePlugin, TagPlugin, AppsPlugin, ComponentPlugin, ContextPlugin, SpacePlugin, ModelPlugin, ModelEntityPlugin, AccessPlugin, NamedObjectPlugin, IdentityPlugin, ModelAppPlugin, PagePlugin, HabitPlugin, ActivityPlugin, ComposerPlugin, CheckinPlugin, DebugPlugin, DueDatePlugin, EntityMenuPlugin, EventPlugin, MenuPlugin, ParentPlugin, PriorityPlugin, NumberPlugin, PrimitivesPlugin, RequestRecordPlugin, ResourcePlugin, RouterPlugin, SetPlugin, SetSelectorPlugin, StatusPlugin, TimelinePlugin, TrackingPlugin, WorkspacePlugin, CheckinEntity, CheckinEvents, SystemEntityPlugin, CategoryEntityPlugin, PlaidPlugin, MoodPlugin, TrackerPlugin, RatingPlugin, PlaygroundPlugin, OverwebPlugin, Hessia2Plugin, Notes2Plugin, PropertyPlugin, PatternPlugin, Entity2Plugin, S3SyncPlugin, AaroPlugin, Property2Plugin, ProgressPlugin, RankingPlugin, AaroLandingPlugin, PrimitivesPlugin, MixedPlugin, TaskAaroExtension, CheckinAaroExtension, DietAaroExtension, RewardExtension, RankingPlugin, AssistantAaroExtension, DashboardPlugin, HierarchyPlugin, Category2Plugin, Routine2Plugin, SprintPlugin, ProjectPlugin, FunctionPlugin, Apps2Plugin, ServiceRegistryPlugin];

      const domain = window?.location?.hostname;

      // AsyncStorage.clear();

      //  TODO:  Build a system to correct the app when it's missing.

      if ((domain === 'www.badart.studio') || (domain === 'badart.studio') || (domain === 'bristle.app') || (domain === 'www.bristle.app')) {
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Bad Art Studio" } }
        ]} />
      } else if ((domain === 'www.hessia.io') || (domain === 'hessia.io')) {
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins: hessiaPlugins2 } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Hessia2" } }
        ]} />
      } else if ((domain === 'www.overweb.link') || (domain === 'overweb.link')) {
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Overweb" } }
        ]} />
      } else if ((domain === 'www.aaro.app') || (domain === 'aaro.app')) {
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Aaro Landing" } }
        ]} />
      } else if ((domain === 'www.my.aaro.app') || (domain === 'my.aaro.app')) {
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Stickers" } }
        ]} />
      }
      else {

        //  TODO:  Use the halia.config.json file
        //  TODO:  We should CHOOSE where to mount these things... it's weird that they're "choosing" for themselves.. idk ugh!
        return <HaliaComponent plugins={[
          { plugin: MenuLayoutPlugin, options: { showHeader: false } },
          { plugin: HaliaStudioPlugin, options: { knownPlugins: knownPlugins } },
          { plugin: HaliaAppPlugin, options: { defaultAppName: "Hessia2" } },
          { plugin: FlowPlugin, options: {} }
        ]} />


        //  CONSIDER:  MAY have MUTLIPOLE plugin providers... with plugins themselves and just infinite decision makers hmm...

        //  Read From the JSON File
        const haliaConfig = require('../halia.config.json');


        const plugins = haliaConfig.plugins;

        return <HaliaComponent plugins={Object.keys(plugins).map(pluginName => {
          // alert(pluginName)
          /* webpackIgnore: true */
          const normalizedName = pluginName.replaceAll("-", "");
          const plugin = HaliaImports[normalizedName];
          // console.log(pluginName)
          // const plugin = require(pluginName);
          return (
            { plugin, options: plugins[pluginName].options }
          );
        })} />

      }

    }

  })



  // { plugin: MenuLayoutPlugin, options: { showHeader: false } },
  // { plugin: HaliaStudioPlugin, options: { knownPlugins } },
  // { plugin: HaliaAppPlugin, options: { defaultAppName: "Hessia" } },
  // { plugin: FlowPlugin, options: { } }



  // const haliaStack = new HaliaStack();

  // haliaStack.register(ReactAppPlugin);
  // haliaStack.register(MenuLayoutSplitterPlugin, { showHeader: true });
  // haliaStack.register(HaliaAppPlugin);
  // haliaStack.register(HaliaPlugin);
  // haliaStack.register(HaliaPluginBuilder);
  // haliaStack.register(AppPreview);
  // haliaStack.register(FlowPlugin);
  // haliaStack.register(SelectionPlugin);
  // haliaStack.register(HaliaDetailPlugin);

  //  Inject Plugins
  //  TODO:  Build a NEW, SERIALIZABLE Stack that can be EXPORTED and run hm!
  //  NOTE:  MAYBE we have BUILDERS to help us CREATE the system with the Plugins though hm!

  // console.log("About to Build")
  // const nodes = await haliaStack.build();


  // //  NOTE:  It's SO siimlar to React hm!!  Abilt yto CHANGE and sthi hm!! THIS way we CAN hook into lifecycle methods and do thing onMount, and hmm.. things like.. afterInstall.. hmm.. the IDEA is we're BUILDING an ENCODING... So... install, etc? Hmm... MABE it's OK.. because we have the encoded thing? Hmm.. Idk doesn't totally feel right.. seems like the Plugin should handle it. Because the things we're BUILDING in that case, IS the association with the UI with the MOUNTING!  So... hmmm... MAYBE an after-build.. but like... HOW many things like that ARE there?  What is the LIMIT? Hmm... 

  // // //  TODO:  I don't like this being done here, and I'd like to PERHAPS do it in an "after" install lifecycle method or something hm!  FOR NOW, let's just keep moving!!!
  // //     NOTE:  THE IDEA here, is we add PLUGINS to the NEW stack, which we're building... and they work together to build an ENCODING... BUT they ALSO have an INTERFACE we can use to CONFIGURE them, and perhaps do things like add new plugins? Hmm... interesting.. it's a WHOLE code-managmenet suite? Hmm...
  // const appPlugin = haliaStack.getPlugin("reactApp").plugin as ReactAppPlugin;
  // appPlugin.mount();


  //  Build a ROUTER system so MULTIPLE apps can be registered with a SINGLE set of libs hm!  THIS way we don't need to redeploy for a bunch hm!  We just make sure we're registred with DNS / have SSL setup, and this thing is good to go hm!  It's BASICALLY another service hm!  The POINT is... we can configure it with SSL and yeah, but we DON'T need to deal with DEPLOYMENT... The point is... It's useful when there's a COMMON base we need for bootstrapping, and then some files we can grab from multiple places hmmmm... like a composition thin ghmmm... I think this may be a step in an interesting direction hm!
  //  REFERENCE:  https://stackoverflow.com/questions/11401897/get-the-current-domain-name-with-javascript-not-the-path-etc
  //  The POITN is... we'll have a SERVICE to handle registration of these domains, and we'll also have a yeah... MAy want to generalize to any base, but yeah hmm...  I think the point is the CONFIG... is possibly light, or possibly soethign that we can obtain dynamically hm... Don't need to ONE, have a separate server to manager, and TWO, recreate the core HTTP / HTML stuff and inject into a common pattern hmm...




};

buildApp();

//  Support Service Worker for PWA
//  REFERENCE:  https://github.com/expo/fyi/blob/main/enabling-web-service-workers.md
// serviceWorkerRegistration.register();

export const Program = program
