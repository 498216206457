import { createStackNavigator } from '@react-navigation/stack';
import { EventEmitter } from 'events';
import { InstanceInternal, UserCredentialProps, Workspace } from 'habor-sdk';
import * as React from 'react';
import { KeyboardAvoidingView, LayoutAnimation, View } from 'react-native';
import { HessiaPlugin } from '.';
import { Route, RouteComponentProps, Switch, withRouter } from "../../../packages/isomorphic-router/react-router";
import { Page } from '../../../packages/kelp-bar/containers';
import Notification from '../../../packages/kelp-bar/notification';
import Register from '../auth-plugin/register';
import { AppManager, AppState, defaultState } from './app-utils';
import { AppContext } from './AppContext';
import { getRoutePath, RouteList } from './config';
import { RootNavigator } from './navigators/root.nav';

export const appMainEmitter: EventEmitter = new EventEmitter();

export interface WorkspaceDrawerNavigatorParams extends UserCredentialProps {
  workspace: InstanceInternal<Workspace>,
}

export interface AppProps extends RouteComponentProps {
  hessiaPlugin: HessiaPlugin;
}

class HessiaAppMainBase extends React.Component<AppProps, AppState> {

  private appManager: AppManager;

  constructor(props: any) {
    super(props);
    this.state = defaultState;
    this.appManager = new AppManager(this.setState.bind(this), this.props.history, this);
  }

  public componentDidMount = async () => {
    // this.appManager.setApp(this);
    await this.appManager.hydrate();
    appMainEmitter.emit("app-main-did-mount");
  }

  public componentWillReceiveProps = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
  }

  public showMenu = () => { this.setState({ showMenu: true }) }
  public hideMenu = () => { this.setState({ showMenu: false }) }


  public selectWorkspace = (workspace: InstanceInternal<Workspace>) => {
    this.setState({ workspace });
  }

  public render = () => {

    //  Unpack State
    const { user, token, workspace: app } = this.state;

    //  Unpack Props
    const { history, location } = this.props;

    //
    //  NOTE:  These wrapped Routes let us pass data through the Route.
    //

    // const WrappedAppCreator = (props: any) => {
    //   //  REFERENCE:  https://stackoverflow.com/a/45599159
    //   if (user == undefined || token == undefined) { throw new Error("Unauthorized") }
    //   const workspace: InstanceInternal<Workspace> = (props.location.state && props.location.state.workspace) ? props.location.state.workspace : undefined;
    //   return <CreateApp user={ user } token={ token } workspace={ workspace } />
    // };

    // const WrappedPluginStore = (props: any) => {
    //   //  REFERENCE:  https://stackoverflow.com/a/45599159
    //   if (user == undefined || token == undefined) { throw new Error("Unauthorized") }
    //   const workspace: InstanceInternal<Workspace> = (props.location.state && props.location.state.workspace) ? props.location.state.workspace : undefined;
    //   return <PluginList workspace={ workspace } token={ token } user={ user } />
    // };

    // //  TODO:  This should be called something like "Plugin Detail", because we don't currently edit basic info like "name" here... although we certainly could!
    // const WrappedPluginEditor = (props: any) => {
    //   //  REFERENCE:  https://stackoverflow.com/a/45599159
    //   if (user == undefined || token == undefined) { throw new Error("Unauthorized") }
    //   const plugin: InstanceInternal<Plugin> = (props.location.state && props.location.state.plugin) ? props.location.state.plugin : undefined;
    //   return <PluginDetails plugin={ plugin } token={ token } user={ user } />
    // };

    // const WrappedPageEditor = (props: any) => {
    //   if (user == undefined || token == undefined) { throw new Error("Unauthorized") }
    //   const plugin: InstanceInternal<Plugin> = (props.location.state && props.location.state.plugin) ? props.location.state.plugin : undefined;
    //   const workspace: InstanceInternal<Workspace> = (props.location.state && props.location.state.workspace) ? props.location.state.workspace : undefined;
    //   const page: InstanceInternal<HaborPage> = (props.location.state && props.location.state.page) ? props.location.state.page : undefined;
    //   const frameworkContext: FrameworkContext = { workspace, plugin, user, token, appEmitter: new EventEmitter(), changePage: () => { navigate(this.props.history, RouteList.Plugins.Detail, { plugin }) } };
    //   const componentContext: HaborComponentContext = { name: "root" };
    //   const modelSDK = new HaborModelSDK(haborSDK, token);
    //   return (
    //     <ScrollView style={{ width: '100%', height: '100%' }}>
    //       <InstanceCreator modelSDK={ modelSDK } instanceId={page ? { nounId: page.nounId, instanceId: page.id, type: EntityType.Instance } : undefined} nounId={{ nounId: PageNoun.id, type: EntityType.Noun }} />
    //     </ScrollView>
    //   );
    // }

    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <AppContext.Provider value={this.state}>
          <Switch>

            {/* Place for Registered Elements (FOR NOW) */}

            {/* Authenticated Routes */}
            {
              //  TODO:  Do this check elsewhere... again, should be installble with a Plugin?
              [

                //  CONSIDER:  Construct the routes automatically so we don't need to do all this.

                //  Home
                //  TODO:  Things like user / password etc. should be added with a Plugin INSTEAD of hard-coded... hmmm.  Then added to the context and made available as needed throughout the system.
                //         I'm thinking the Plugin can manually change the app State, OR maybe there's anothe system that helps coordinate with a SCOPE on the system the state's attached to??
                <Route exact path={'/'}>
                  <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
                    <RootNavigator hessiaPlugin={this.props.hessiaPlugin} />
                  </KeyboardAvoidingView>
                </Route>,

                //  Entities
                // <Route exact path={ getRoutePath(RouteList.Entities.Create) } render={ WrappedEntityEditor } />,

                //  Plugins (Systems)
                // <Route exact path={ getRoutePath(RouteList.Plugins.Detail) } render={ WrappedPluginEditor } />,
                // <Route exact path={ getRoutePath(RouteList.Plugins.List) } render={ WrappedPluginStore } />,

                //  Pages
                // <Route exact path={ getRoutePath(RouteList.Pages.Edit) } render={ WrappedPageEditor } />,

                // Workspaces
                // <Route exact path={ getRoutePath(RouteList.Workspaces.List) } render={ () => <WorkspaceList logout={ this.appManager.logout } token={ token } user={ user } /> } />,
                // <Route exact path={ getRoutePath(RouteList.Workspaces.Create) } render={ WrappedAppCreator } />,
                // <Route exact path={ getRoutePath(RouteList.Workspaces.Home) } render={ WrappedWorkspaceHome } />,

                //  Composers
                // <Route exact path={ getRoutePath(RouteList.Composer) } render={ WrappedComposer } />,

                // <Route exact path='/apps/plugins/store' render={ WrappedPluginStore } />,
              ]
            }
            {/* <Route exact path='/history' render={ () => <Profiler /> } /> */}
            <Route exact path='/register' render={() => <Register />} />
            {/* <Route exact path='/' render={ () => <Start login={ this.appManager.loginWithPassword } /> } /> */}
            <Route exact path='/register/success' render={() => (
              <Notification
                title='Verification Required'
                body="We've sent a verification link to the email address provided.  After validating your email click to login below."
                buttonTitle='LOGIN'
                route='/' />
            )} />
            {/* TODO:  Abstract "Notification" so they can be defined from the caller? */}
            <Route exact path='/error' render={() => (
              <Notification
                title='Error'
                body="The operation could not be completed."
                buttonTitle='CLOSE'
                route='back' />
            )} />
            <Route exact path='/register/failure' render={() => (
              <Notification
                title='Account Creation Failed'
                body="An error occurred while creating your account.  Please try again or contact support for assistance."
                buttonTitle='TRY AGAIN'
                route='/' />
            )} />
            <Route exact path='/login/failure' render={() => (
              <Notification
                title='Login Failed'
                body={`Please make sure you're using a valid username and password.  If ths issue persists, contact support for assistance.`}
                buttonTitle='TRY AGAIN'
                route='/' />
            )} />
          </Switch>
          {/* TODO:  Make sure the switcher only displays when it makes sense! */}
          {/* We PROBABLY want another layer to deal with this special page where we have the switched. */}
          {/* <Menu>
            <MenuItem icon={{ type: "material", name: "person" }} onPress={() => navigate(this.props.history, RouteList.Profiles.Detail, {})}></MenuItem>
            <MenuItem icon={{ type: "font-awesome", name: "plug" }} onPress={() => navigate(this.props.history, RouteList.Plugins.List, {})}></MenuItem>
            <MenuItem icon={{ type: "entypo", name: "folder" }} onPress={() => navigate(this.props.history, RouteList.Workspaces.List, {})}></MenuItem>
          </Menu> */}
        </AppContext.Provider>
      </View>
    );
  }
}

export const HessiaAppMain = withRouter(HessiaAppMainBase);
// export const HessiaAppMainType = typeof HessiaAppMain;
