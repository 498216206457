import { CognitoUser } from '@aws-amplify/auth';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Amplify, { Auth } from 'aws-amplify';
import * as React from 'react';
import { Button, Text, TextInput } from 'react-native';
import awsmobile from '../aws-exports';

import { textStyles } from '../styles';

// Amplify.configure(awsmobile);

const signIn = async (username, password, navigation, onLogin): Promise<CognitoUser | void> => {
  try {
    const user: CognitoUser = await Auth.signIn(username, password);
    alert(JSON.stringify(user));

    //  CONSIDER:  THis is SPREAD functioanltiy taht CAN be contained under one fucking LABEL UGH!! THis is NOT COREY FUCKCCKCK
    // user.
    // localStorage.setItem("userToken", user.getSignInUserSession()?.getAccessToken()?.getJwtToken() || "");

    // await localStorage.setItem("user", JSON.stringify(user));
    //  TODO:  Track this as an APP EVENT... c onsider a system specifically for this.. hmmm...  MAYBE local OR we can use a servicel hm!  THe point is.. we're using THIS interpreter, or another hm!
    // console.warn("User: " + user);
    onLogin(user);
    return user;
  } catch (err) {
    alert("Failed: " + err)
  }
}

export interface LoginProps {
  onLogin?: (user: CognitoUser) => void;
}

export const Login = ({ onLogin }: LoginProps) => {

  const navigation = useNavigation();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const options: FederatedSignInOptions = { provider: "SignInWithApple" as any };
  return (
    <>

      <Text>Email</Text>
      <TextInput value={email} onChangeText={setEmail} style={textStyles.input} />

      <Text>Password</Text>
      <TextInput secureTextEntry={ true } value={password} onChangeText={setPassword} style={textStyles.input} />

      <Button
        title="Sign in with Apple"
        onPress={() => Auth.federatedSignIn(options)}
      />

      <Button title="Sign In" onPress={() => signIn(email, password, navigation, onLogin)} />
      <Button title="Register" onPress={() => navigation.navigate("Register")} />

    </>


  )
}
