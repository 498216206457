import { NounProperty, serializedNounPropertySchema } from 'habor-sdk';
import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { SchemaEditor } from './schema-editor';

interface PropertiesViewProps {
  prop?: NounProperty;
  static: boolean;
  onNewProperty: (property?: NounProperty) => void;
}

interface PropertiesViewState {}

export class PropertiesView extends React.Component<PropertiesViewProps, PropertiesViewState> {

  constructor(props: PropertiesViewProps) {
    super(props);
    this.state = {}
  }

  private handleCancel = () => {

    //  Unpack
    const { onNewProperty } = this.props;

    //  Invoke the Callback
    onNewProperty();
  }

  private handleNewProperty = (property: NounProperty) => {

    //  Unpack
    const { onNewProperty } = this.props;

    //  Invoke the Callback
    onNewProperty(property);
  }

  public render() {

    //  Unpack
    const { prop } = this.props;

    //  Render the Editor
    //  TODO:  There SHOULD be a way to HIDE the "Static" selector?  Hmm...  It should be set by the parent component?  BUT, what if we want to change it?  I suppose the parent can be responsible for this.
    return <SchemaEditor onCancel={ this.handleCancel } onSubmit={ this.handleNewProperty } schema={ serializedNounPropertySchema } value={ { ...prop, static: this.props.static } } />;
  }
}