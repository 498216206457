import { Color } from 'habor-sdk';
import * as React from 'react';
import { ImageBackground, ImageSourcePropType, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import { extraSmallSpacer } from "./styles";

interface IconButtonProps extends React.ComponentProps<any> {
  unselectedIconColor?: string;
  selectedIconColor?: string;
  unselectedColor?: string;
  color: string;
  unselectedPath?: ImageSourcePropType;
  selectedPath?: ImageSourcePropType;
  iconName?: string;
  iconType?: string;
  onPress: any;
  selected:  boolean;
  size?: number;
}

export const IconButton = (props: IconButtonProps) => {
  const { color, unselectedPath, selectedPath, iconName, iconType, onPress, selected, size = 65, unselectedColor = 'rgba(0,0,0,0)', unselectedIconColor = Color.medGray, selectedIconColor = Color.white } = props;
  return (
    <TouchableOpacity onPress={ props.onPress } style={[{ backgroundColor: props.selected ? props.color : unselectedColor, borderRadius: size / 2, margin: extraSmallSpacer, height: size, width: size, alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 0 }]}>
      { props.unselectedPath && props.selectedPath ? <ImageBackground source={ props.selected ? props.selectedPath : props.unselectedPath } imageStyle={{ resizeMode: "contain" }} style={{ width: size / 2, height: size / 2 }} /> : null }
      { props.iconName && props.iconType ? <Icon name={ props.iconName } type={ props.iconType } color={ props.selected ? selectedIconColor : unselectedIconColor } size={ size / 2 } />  : null }
    </TouchableOpacity>
  );
};
