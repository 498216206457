
import { NavigationProp } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, Text, TextInput, TouchableOpacity, View, ScrollView } from 'react-native';
import { ENoun } from '../../../enoun-service';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { HaborNounService, NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { AuthPlugin } from '../auth-plugin/auth-plugin';
import { Entity, EntityPlugin } from '../entity-plugin';
import { Deferred, renderEntityListItem } from '../entity-plugin/entity-list';
import { haborSDK } from '../hessia-plugin/config';
import { SystemPlugin } from '../system-plugin/system-plugin';

export interface HessiaCategory {
  name: string;
}

// export const categoryService = new LocalNounService<Category>("category");

class CategoryListBase extends React.Component<{ navigation: NavigationProp<any>, entityPlugin: EntityPlugin, categoryPlugin: CategoryPlugin }, any> {

  constructor(props) {
    super(props);
    this.state = {
      categorys: []
    }
  }

  componentDidMount = async () => {
    const categorys = await this.props.categoryPlugin.categoryService.retrieveAll();
    this.setState({ categorys });
  }

  render() {


    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>

          {/* Header */}
          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Categorys</Text>
            <TouchableOpacity onPress={() => { this.props.navigation.navigate('Editor') }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>

          {/* Category List */}
          <ScrollView showsHorizontalScrollIndicator={false}>
            {this.state.categorys.map((category: ENoun<HessiaCategory>) => {
              //  TODO:  Remove the "enoun" wrapper.
              const entity: Entity = { systemId: 'entity', route: { id: category.id, systemId: 'category' }, metadata: {} };
              if (!category.payload.entityId) { return <Text>{'Missing EntityID: ' + JSON.stringify(category)}</Text> }
              return (
                <Deferred func={renderEntityListItem} args={{ entity, entityPlugin: this.props.entityPlugin }} def={<Text>Loading...</Text>} />
              );
            })}
          </ScrollView>

        </Page>
      </PageLoader>
    )
  }
}

const InjectedCategoryList = (props: any) => <CategoryListBase {...props} />
export const CategoryList: any = InjectedCategoryList;

const Stack = createStackNavigator();

//  TODO:  I'd REALLY like to be able to make React Compnoents .. or like a component FACTORY which can DEPEND upon Plugins so we can have it auto-injected... Ah!  Then it's VERY much like React Context hmm...  What IS that relationship???
export const CategoryApp = ({ entityPlugin, categoryPlugin }: { entityPlugin: EntityPlugin, categoryPlugin: CategoryPlugin }) => {

  return (
    <Stack.Navigator initialRouteName="List" screenOptions={{ animationEnabled: true }} {...{ headerMode: "none" }}>
      <Stack.Screen name="Editor" component={({ navigation, route }) => <CategoryEditor categoryPlugin={categoryPlugin} navigation={navigation} route={route} />} />
      <Stack.Screen name="List" component={({ navigation, route }) => <CategoryList categoryPlugin={categoryPlugin} entityPlugin={entityPlugin} navigation={navigation} route={route} />} />
    </Stack.Navigator>
  );
}


export const CategoryEditor = ({ navigation, route, categoryPlugin }: { navigation: any, route: any, categoryPlugin: CategoryPlugin }) => {

  const [categoryInternal, setCategoryInternal] = React.useState<NounServiceInstanceInternal<HessiaCategory>>(route?.params?.category);

  const [categoryName, setCategoryName] = React.useState<string>("");

  //  NOTE:  We DO allow the same element in the category more than once I suppose.  Hmm... maybe?
  // const removeElement = async (index: number) => {
  //   if (!categoryNoun) { throw `Cannot update the category.  No category was provided.`; }
  //   const newEntityList = [...entityIdList];
  //   newEntityList.splice(index, 1);
  //   const newCategoryNoun = await categoryService.update(categoryNoun.id, { ...categoryNoun.payload, entityIdList: newEntityList });
  //   categoryCategoryNoun(newCategoryNoun);
  // }

  // const addElement = async (entityId: string) => {
  //   if (!categoryNoun) { throw `Cannot update the category.  No category was provided.`; }
  //   const newEntityList = [...entityIdList, entityId];
  //   const newCategoryNoun = await categoryService.update(categoryNoun.id, { ...categoryNoun.payload, entityIdList: newEntityList });
  //   categoryCategoryNoun(newCategoryNoun);
  // }

  const makeCategory = async () => {
    const newCategoryNoun = await categoryPlugin.categoryService.create({ name: categoryName });
    setCategoryInternal(newCategoryNoun);
  }

  if (!categoryInternal) {
    return (
      <>
        <Text>Name</Text>
        <TextInput style={{ backgroundColor: '#eeeeee', borderRadius: 10 }} value={categoryName} onChangeText={setCategoryName} />
        <Button onPress={makeCategory} title="Make Category" />;
      </>
    );

  }

  return (
    <Text>{JSON.stringify(categoryInternal)}</Text>
  );

  // return (
  //   <ScrollView style={{ marginVertical: 100 }}>

  //     <Button title="Delete" onPress={() => categoryService.delete(categoryNoun.id)} />

  //     <Text>Entity IDs</Text>
  //     {
  //       entityIdList.map((entityId, index) => (
  //         //  TODO:  Show a mini entity view here instead of just the ID?
  //         <View style={{ padding: 30, backgroundColor: 'white', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
  //           <View style={{ flex: 1 }}>
  //             {/* <Deferred func={ renderEntityListItem } args={ entityId } def={ <Text>Loading...</Text> } /> */}
  //             <Text>Shold show Entity Lite Item</Text>
  //           </View>
  //           <Button onPress={() => removeElement(index)} title="Remove" />;
  //         </View>
  //       ))
  //     }

  //     <View style={{ height: 15 }} />
  //     <TextParagraph>Add</TextParagraph>
  //     <View style={{ height: 10 }} />

  //     <View style={{ padding: 30, backgroundColor: '#eeeeee', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
  //       <View style={{ flex: 1 }}>
  //         <TextInput value={newEntityId} onChangeText={categoryNewEntityId} />
  //       </View>
  //       <Button onPress={() => addElement(newEntityId)} title="Add" />;
  //     </View>

  //     {/* <Text>Entity ID</Text>
  //     <TextInput value={ categoryNoun.payload.entityId } /> */}


  //   </ScrollView>
  // );
}




export class CategoryPlugin extends CorePluginClass {
  public static details = {
    name: "PrimitiveCategorys",
    description: "Categorys Primitive System",
    //  TODO:   Why can't I import system here?
    dependencies: [SystemPlugin.details.id, AuthPlugin.details.id, EntityPlugin.details.id],
    id: "categoryPlugin"
  }

  public categoryService!: HaborNounService<HessiaCategory>;

  //  TODO:  Type SystemPlugin.  MAYBE have a cyclic dependency.  MAYBE split up the types from the definitions.. hmm...
  public install = async (program: Program, { system, pEntities, authPlugin }: { system: SystemPlugin, pEntities: EntityPlugin, authPlugin: AuthPlugin }) => {

    //  TODO-NEXT:  Why are we getting an error here?????

    //  TODO:  Instead of doing "onLogin" consider doing it like... where it goes IF we have the token, otherwise, when it updates?  Like.. when the token is defined? hm...
    authPlugin.onLogin(async () => {
      this.categoryService = new HaborNounService<HessiaCategory>("category10", haborSDK);
      await this.categoryService.init(authPlugin.token);
    })


    //  TODO:  Instead of doing this for EACH item, consider registering for a "class" or MAYBE just have the label for the primitive system.  THIS is what STAKING is about? HM!
    // registerEntityListItemRenderer(async (entityId: string, api: EntityListItemAPI) => {
    //   const category = await categoryService.retrieveByEntityId(entityId);
    //   if (category) {
    //     api.setText("Category");
    //   }
    // });
    
    //  CONSIDER:  SHould ba belt o make multiple entrypoints hm.. like "List View" or "List System", and Ah!  ANTYHIGN a human would map tto the same thing mM!!  MAYBE the idea is "system" hmmmmm.. that word is key associater hmm

    system.registerPrimitiveSystem({
      id: 'categorys-system',
      name: "Category",
      description: "Categorys Primitive System",
      labels: ["core"],
      component: () => <CategoryApp categoryPlugin={this} entityPlugin={pEntities} />,
      menuItem: {
        icon: { name: "select-group", type: "material-community" },
        backgroundColor: "#f25949",
        iconColor: "white"
      }
    });

    return this;
  }
}

