import { EntityType, HDTRelationshipValue, HSDTRelationship, InstanceInternal, NounInternal } from 'habor-sdk';
import * as React from 'react';
import { Button, View, ScrollView, Text } from 'react-native';
import { Field } from 'redux-form';
import { NamedObjectItem } from '../../../../plugins/hessia-plugins/component-plugin/habor-react/habor-preview-viewer';
import { getAllInstancesForNounList, getNouns } from '../../../../plugins/hessia-plugins/habor-plugin/habor-utils';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

export const RelationshipFieldStyle = [
  {
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    color: '#333333',
    borderColor: '#444444',
    borderWidth: 1,
    minHeight: 37,
  }
];

export interface RelationshipFieldProps {
  options: {
    noun: NounInternal;
    inst: InstanceInternal<any>;
  }[];
  allowMany?: boolean;
  value: HDTRelationshipValue;
  update: (value: HDTRelationshipValue) => void;
  metadata: any;
}

interface RelationshipFieldState {
  update: boolean;
}
class RelationshipField extends React.Component<RelationshipFieldProps, RelationshipFieldState> {

  constructor(props: RelationshipFieldProps) {
    super(props);
    this.state = {
      update: false
    }
  }

  public render = () => {
    const { value = {} as HDTRelationshipValue, options, metadata, update } = this.props;
    const { frameworkContext, componentContext } = metadata;

    const onChange = (inst: InstanceInternal) => {

      //  Duplicate the List
      const newInstList = value.instanceIdList ? [...value.instanceIdList] : [];

      //  Get Existing
      const existingIndex = newInstList.findIndex(instId => instId.instanceId === inst.id)
      const existing = newInstList[existingIndex];

      //  Remove if in the List
      if (existing) {
        newInstList.splice(existingIndex);
      } else {
        newInstList.push({ type: EntityType.Instance, instanceId: inst.id, nounId: inst.nounId })
      }

      update({ instanceIdList: newInstList });

    }

    const views = options.map(({ inst, noun }) => {
      //  TODO:  This can USE the preview renderer, BUT for selection we have a bigger problem... We want to make sure we allow the selection to be natural.  For that reason, IF there IS no preview renderer (which should guarantee a certain size), we MAY want to query to get the size and then adjust the exprience dynamically, like showing a Page view, etc...  FOR NOW, let's start with the basic options selection.  This is the INSTANCE selector, for selecting between instances.  If we need to select between instances which do not extend from a class which has an attached renderer, THEN we throw an error.  This means we should either extend from a renderable class, OR, make a custom renderer?  I'm just concerned this will seem too complicated to most users... Idk.
      //  TODO:  Use the NEURAL system to inject "context" like... the size, the intention, and ANYTHING else.. this COUPLES with all other systems mm!!
      //  NOTE:  About mapping between rules... much more about considering lots of options... hmm... Either way, I had the idea of things mapped to UIs LONG before.. the idea of fucking PAPER system.  Knowledge Graph, and I'd planned to use all that to help diapley the things.
      return <NamedObjectItem onPress={onChange} instance={inst} componentContext={componentContext} frameworkContext={frameworkContext} />
    });



    const toggleEditor = () => {

      //  Check Allow Many
      if (!this.props.allowMany && value?.instanceIdList && value?.instanceIdList?.length) {
        alert("Multiselect is not supported for this field.");
      } else {
        this.setState({ update: !this.state.update })
      }
    }

    //  Get the Selected Instance 
    //  TODO:  Support Multiselect
    //  CONSIDER:  Consider using primitive associations for this instead of this explicit "Relationship" field!?  Hmm... It think it will become clearer as we build?
    //  TODO:  Rename "Noun" / "Entity" to "Model"!?  BUT, "Entity" MAY be a diferent thing, MORE like an API Controller!?
    const selectedInsts = !!value && !!value.instanceIdList && value.instanceIdList.map(instId => options.find(opt => opt.inst.id === instId.instanceId)?.inst) || [];

    //  TODO:  Support REMOVAL from the list!
    return (
      <View>
        {
          selectedInsts ?
            selectedInsts.map(inst => (
              inst ?
                <NamedObjectItem onPress={onChange} instance={inst} frameworkContext={frameworkContext} componentContext={componentContext} /> :
                null  //  TODO:  Throw an error or SOMETHING if an instance is NOT found.
            )) :
            null
        }

        {
          this.props.allowMany || !value.instanceIdList || (value.instanceIdList && !value.instanceIdList.length) ?
            <Button title="Select" onPress={toggleEditor} /> :
            null
        }

        {
          this.state.update ?
            <ScrollView>
              {views}
              <Button title="Cancel" onPress={toggleEditor} />
            </ScrollView> :
            null
        }
      </View>
    );
  }
}

//  TODO:  Update to use as a component instead of async function
export const sdtRelationshipRenderer = ({ sdt, name, metadata = {}, value = {}, update }: SDTRendererParams<HSDTRelationship>) => {

  //  TODO:  Move this into the RelationshipField and consider React Context to get the token (or some suitable Hessia abstraction).

  const [noun, setNoun] = React.useState<NounInternal | undefined>(undefined);
  const [instances, setInstances] = React.useState<InstanceInternal<any>[]>([]);

  const load = async () => {


  //  Get all the instances
  //  TODO-IMPORTANT:  We should REALLY use paging here... this may return a LOT of entries in the future.
  const nounIds = sdt.nounIds.map(id => id.nounId);
  const nouns = await getNouns(nounIds, token);
  if (nouns.length > 1) {
    throw new Error("Currently only relationships with a single associated noun are supported.");
  }
  if (nouns.length <= 0) {
    //  TODO-IMPROTANT:  Like Typescript, errors should bubble up with CONTEXT at each layer!  Maybe catch, and wrap the error with additional description about the variables passed to the function?
    return <Text>No nouns found for the given relationship with valid nouns: { JSON.stringify(sdt.nounIds) }.</Text>
  }
  const noun = nouns[0];

  const instances = await getAllInstancesForNounList(sdt.nounIds, token);
  setInstances(instances);
  setNoun(noun);


  }

  React.useEffect(() => {
    load();
  }, []);

  const { token } = metadata;

  if (!token) {
    <DavelField required={sdt.required} key={name} name={name}>
      <Text>Error:  Relationship Field - Missing Token from Metadata</Text>
    </DavelField>
  }
  //  TODO:  In the NEAR future, use the Object Search page to search instances of objects!

  //  NOTE:  AGAIN, there can be MULTIPLE handlers for a type, and the Davel User MIGHT want to indicate which they'd like to use, OR specify an override?  Perhaps they can also specify other things as well?  We ALSO want a way to introduce styles / themes!??
  //  TODO-IMPORTANT:  In the future, if there are NO instances, we should indicate this and give the user the option to create one.  FOR NOW, give an alert and throw an error.
  if (instances.length == 0) {
    return <Text>{ `No instances defined for the ${name} field.` }</Text>
  }

  const options = instances.map((inst) => {
    const value = { noun, inst };
    return value;
  });

  //  EXPLANATION:  We use the normalizer to make sure the object structure lives behind ONE key instead of a bunch of keys.  Currently, for the instance creator, the pieces are present, because the array re-creates them from the parent.  Ah!  When we update WITHIN Redux Form, it's attached to the right spot in the tree.  BUT when we try to flatten an existing value, it WON'T translate correctly.  ONE way to solve this might be to use the SCHEMA when we convert to flat values for the initial state.  However, this MIGHT get tricky when it's ambiguous... an array of type ANY can hold ANY data... For this reason, it would be nice if we could just let the state live in the outer thing in a STRUCTURED form, THEN our parsers parse it in place?  IF there's a schema element, then we use it.  This avoids iterating the parse tree twice???
  const normalizer = (inst: InstanceInternal): HDTRelationshipValue => {
    return { instanceIdList: [{ type: EntityType.Instance, instanceId: inst.id, nounId: inst.nounId }] };
  }

  //  DESCRIPTION:
  //    The "Field" below is a ReduxForm concept.  It injects ReduxForm props into the associated Component.
  //    In this case, the cmponent is RelationshipField.  Its job is to invoke the ReduxForm props to update the value for this ReduxForm key. 
  //    After every change, it's passed through the "normalizer".  Here, the "instIdString" is converted to a proper Instance ID.  This means, the values returned by RelatinshipField SHOULD be of type Stringified InstanceID. 
  return (
    <DavelField required={sdt.required} key={name} name={name}>
      <RelationshipField
        options={options}
        key={name}
        metadata={metadata}
        allowMany={ sdt.allowMany }
        update={ update }
        value={ value }
      />
    </DavelField>
  );
};
