import { identityAddon, IdentityAddonHandlerHaborComponent, IdentityAddonHandlerHaborComponentPrimitive } from "./identity-addon";
import { Program, CorePluginClass } from "halia";
import { HessiaPlugin } from "../../hessia-plugin";
import { ModelPlugin } from "../../model-plugin/model-plugin";

export class IdentityPlugin extends CorePluginClass {
  public static details = {
    name: "Identity",
    description: "Identity Plugin",
    dependencies: [HessiaPlugin.details.id, ModelPlugin.details.id],
    id: "identity"
  }

  //  TODO:  The identity Plugin sould be responsible for adding the token to the request AND parsing the token and updating the incoming req / res? locals!

  public install = (program: Program, { hessia, modelPlugin }: { hessia: HessiaPlugin, modelPlugin: ModelPlugin }) => {

    hessia.registerPrimitiveComponent('IdentityAddonHandlerHaborComponentPrimitive', IdentityAddonHandlerHaborComponentPrimitive)
    hessia.registerComponent(IdentityAddonHandlerHaborComponent);
    modelPlugin.registerAddonHandler(identityAddon);
  }
}
