import { Block, InstanceInternal, AnyBlock } from 'habor-sdk';
import * as React from 'react';
import { View, ViewStyle } from "react-native";
import { TitleChiclet } from '../../../primitives-plugin/habor-components/embedded-views/chicklet-component';
import { smallSpacer } from '../../../../../packages/kelp-bar/styles';
import { blockTags } from '../models/blocks';
import { NamedObjectItemComponent } from './named-object-item';

//  CONSIDER:  Should users be able to make Plugins to augment this view?

export interface BlockItemProps extends React.ComponentProps<any> {
  block: InstanceInternal<AnyBlock>;
  onPress?: () => void;
  style?: ViewStyle;
}
interface BlockItemState {}
class BlockItemBase extends React.Component<BlockItemProps, BlockItemState> {
  constructor(props: BlockItemProps) {
    super(props);
    this.state = {}
  }
  public render = () => {

    //  Unpack
    const { block, onPress, children, style } = this.props;

    return (
      <NamedObjectItemComponent style={ style } onPress={ onPress } item={ block.payload }>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {
            block.payload.tags.map(tagId => {
              const tag = blockTags[tagId];
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <TitleChiclet title={ tag.name } style={{ backgroundColor: tag.color }} />
                </View>
              );
            })
          }
        </View>
        {
          children ? 
            <View>
              <View style={{ width: smallSpacer }} />
              { children }
            </View> :
            null
        }
      </NamedObjectItemComponent>
    );
  }
}
export const BlockItem = BlockItemBase