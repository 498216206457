import { CorePluginClass, Program } from 'halia';
import { Configuration, OpenAIApi } from 'openai';
import React from "react";
import { ActivityIndicator, ScrollView, Text, TextInput } from 'react-native';
import { GalleryButton } from '../gallery/components/common';
import { HeroCard } from '../gallery/components/hero-card';
import { AuthPlugin } from './auth-plugin/auth-plugin';
import { SystemPlugin } from './system-plugin/system-plugin';
import { primaryColor } from '../gallery/constants';
import * as Speech from 'expo-speech';
import { haborSDK } from './hessia-plugin/config';
import { useHessiaPlugin } from './hessia-plugin';
import { useAppContext } from './hessia-plugin/AppContext';

const configuration = new Configuration({
  apiKey: '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',
});
const openai = new OpenAIApi(configuration);

export const ChatGPT = () => {

  const [prompt, setPrompt] = React.useState("");
  const [response, setResponse] = React.useState<any>();

  const doAi = async () => {

    //
    //  Clientside Usage
    //  REFERENCE:  https://github.com/openai/openai-node/issues/18#issuecomment-1490970390
    //
    const url = "https://api.openai.com/v1/chat/completions";

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + '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');

    xhr.onprogress = function (event) {
      console.log("Received " + event.loaded + " bytes of data.");
      console.log("Data: " + xhr.responseText);
      const newUpdates = xhr.responseText
        .replace("data: [DONE]", "")
        .trim()
        .split('data: ')
        .filter(Boolean)


      const newUpdatesParsed = newUpdates.map((update) => {
        const parsed = JSON.parse(update);
        return parsed.choices[0].delta?.content || '';
      }
      );

      const newUpdatesJoined = newUpdatesParsed.join('')
      setResponse(newUpdatesJoined);
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log("Response complete.");
          console.log("Final data: " + xhr.responseText);
        } else {
          console.error("Request failed with status " + xhr.status);
        }
      }
    };

    const data = JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: "user",
          content: prompt,
        }],
      stream: true,
    });

    xhr.send(data);


    // const messages = [
    //   { role: 'user', content: 'what is the meaning of life?' }
    // ]
    // for await (const token of streamChatCompletion(messages)) {
    //   answer += token
    //   // do something async here if you want
    // }
    // console.log('answer finished:', answer)


    //
    //  Streamed Request
    //  REFERENCE:  https://github.com/openai/openai-node/issues/18#issuecomment-1493132878
    //
    // const _response = await openai.createChatCompletion({
    //   model: "gpt-3.5-turbo",
    //   messages: [{ role: "system", content: "You are a system that maps text messages into JSON suitable for machine interpretation." }, { role: "user", content: "I'm going to give you some text.  Please read it and return a JSON Object with 30 suggested tags and their scores.  The tag should be the key and the score should be the value.  Do not respond with anything exepct for the JSON object.  Here is the text:  " + prompt }],
    //   stream: true,
    // }, { responseType: 'stream' });

    // console.log(_response);
    // const stream = _response as unknown as any;

    // stream.on('data', (chunk: Buffer) => {
    //   // Messages in the event stream are separated by a pair of newline characters.
    //   const payloads = chunk.toString().split("\n\n")
    //   for (const payload of payloads) {
    //     if (payload.includes('[DONE]')) return;
    //     if (payload.startsWith("data:")) {
    //       const data = payload.replaceAll(/(\n)?^data:\s*/g, ''); // in case there's multiline data event
    //       try {
    //         const delta = JSON.parse(data.trim())
    //         setResponse(response + delta.choices[0].delta?.content);
    //       } catch (error) {
    //         setResponse(_response);
    //       }
    //     }
    //   }
    // })
    // stream.on('end', () => console.log('Stream done'))
    // stream.on('error', (e: Error) => console.error(e))


    //
    //  Batched Request
    //

    // const completion = await openai.createChatCompletion({
    //   model: "gpt-3.5-turbo",

    //   //  Pre-Existing Tags
    //   // messages: [{ role: "system", content: "You are a system that maps text messages into JSON suitable for machine interpretation." }, { role: "user", content: "Here are some concepts I'd like to tag my data on:  ['Sports', 'Religion', 'Programming', 'Music', 'Relationships', 'Artwork'].  I'm going to give you some text.  Please read it and assign a score to each category, and return them in a JSON Object with the category as the key and the score as the value.  Do not respond with anything exepct for the JSON object.  Here is the text:  " + prompt }],

    //   //  System-Specified Tags
    //   messages: [{ role: "system", content: "You are a system that maps text messages into JSON suitable for machine interpretation." }, { role: "user", content: "I'm going to give you some text.  Please read it and return a JSON Object with 10 suggested tags and their scores.  The tag should be the key and the score should be the value.  Do not respond with anything exepct for the JSON object.  Here is the text:  " + prompt }],

    //   //  NOTE:  Ideally we'd be able to do I driven search, where it can store a "fingerprint" or "memory" with each encoding of the position in concept space and find matches!

    //   // stream : true,

    // });

    // setResponse(completion.data.choices[0].message);

  }

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <HeroCard title="ChatGPT" subTitle="GPT 3.5 Turbo" />
      <Text>{JSON.stringify(response)}</Text>
      <TextInput onChangeText={setPrompt} value={prompt} />
      <GalleryButton title="Submit" onPress={doAi} />
    </ScrollView>
  );
}

export const TaskIngestor = () => {

  const [loading, setLoading] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");
  const [response, setResponse] = React.useState<any>();

  const appContext = useAppContext();

  const doAi = async () => {

    //
    //  Batched Request
    //


    const systemMessage = "You are a system that returns a JSON structure with two keys, 'human', and 'machine'.  This structure is called the Output.  You never output anything except this JSON structure.";


    const userMessage = `
      You are handling requests from a user who needs help converting their request to a machine readable format.

      The machine expects you to respond with a JSON Array.  Each object in the array represents a command for a particular system.

      Currently there are three systems:  "Text", "Routing", and "Tasks".


      Text:  If the user indicates that they'd like to save something textual, like a note, a string, etc, then please add the following object to the array for each note:

      { system: "string", value: <User's Text> }

      Where <User's Text> is the text the user has provided and would like to save.


      Routing:  If the user indicates that they would like to be routed to a particular screen, please add the following object to the array:

      { system: "routing", value: <Screen Name> }

      Where <Screen Name> is the lower-case screen name the user provided.


      Tasks:  If the user indicates that they have a task to accomplish (possibly multiple), then please add the following object to the array for each task:  


      { system: "routing", value: <User's Task> }

      Where <User's Task> is a JSON object with the following structure:

      { name: <Task Name>, description: <Task Description>, icon: <Material Icon Name>, color: <Hex Color> }

      Where <Task Name> is a name for the task, <Task Description> is a description for the task, <Material Icon Name> is the name of a material icon representing the task, and <Hex Color> is a hex color representing the task.


      For example, if a user says:  "Today I need to make sure I go to the mall and get some clothes".  

      An example Output would be: { human: "I have created a task for you!", machine:[{ name: "Go to Mall", description: "Get clothes", icon: "store", color: "#1e7ffc" }] }

      As you can see, the "human" part of the response is just a helpful message that we'll read back to the user.

      
      Do not output ANYTHING except for the JSON Output.  Here is the user's prompt: ${prompt}
    `;


    setLoading(true);
    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [{ role: "system", content: systemMessage }, { role: "user", content: userMessage }],
    });
    setLoading(false);
    const result = completion.data.choices[0].message?.content || "";
    console.log(result);
    const parsedResult = JSON.parse(result);
    console.log(parsedResult);
    setResponse(parsedResult.human);
    Speech.speak(parsedResult.human);

    for (const item of parsedResult.machine) {
      if (item.system === "tasks" || item.system === "task") {
        await haborSDK.createInstance({nounId: "task", payload: { name: item.value.name, description: item.value.description }}, appContext.token!);
        alert("Created Task!")
      }
    }
  }


  //  FUCK!  It works!!!
  //  CONSIDER:  I MAY want to have it generate a KNOWlEDGE graph for me.  INSTEAD of the explicit thing mmm... this way, it's more robust and general to new types.  For example, I can say encode this following these rues <Knowldge Graph Rules>, and place it in a type hierarchy that is most appropriate? Hmm ugh!  Reminds me of Corey, but ALSO reminds me of TrackMine, because I was already doing that!!!
  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <HeroCard title="Ingestor" subTitle="GPT 3.5 Turbo" />
      {
        loading ? (
          <ActivityIndicator color={primaryColor} />
        ) : (
          <>
            <Text>{JSON.stringify(response)}</Text>
            <TextInput onChangeText={setPrompt} value={prompt} />
            <GalleryButton title="Submit" onPress={doAi} />
          </>
        )
      }

    </ScrollView>
  );
}

export const ReactNativeComponent = () => {

  const [prompt, setPrompt] = React.useState("");
  const [response, setResponse] = React.useState<any>();

  const doAi = async () => {

    //
    //  Batched Request
    //

    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",

      //  Pre-Existing Tags
      // messages: [{ role: "system", content: "You are a system that maps text messages into JSON suitable for machine interpretation." }, { role: "user", content: "Here are some concepts I'd like to tag my data on:  ['Sports', 'Religion', 'Programming', 'Music', 'Relationships', 'Artwork'].  I'm going to give you some text.  Please read it and assign a score to each category, and return them in a JSON Object with the category as the key and the score as the value.  Do not respond with anything exepct for the JSON object.  Here is the text:  " + prompt }],

      //  System-Specified Tags
      // messages: [{ role: "system", content: "You are a system that maps text messages into JSON suitable for machine interpretation." }, { role: "user", content: "I'm going to give you some text.  Please read it and return a JSON Object with 10 suggested tags and their scores.  The tag should be the key and the score should be the value.  Do not respond with anything exepct for the JSON object.  Here is the text:  " + prompt }],

      //  System-Specified Tags
      messages: [{ role: "system", content: "You are a system that generates React Native components." }, { role: "user", content: "I'm going to give you some JSON.  Each item should have a name, description, material icon name, and a hex color, and a priority from low, medium, or high.  Please read it and return React Native component that shows all the tasks in a scroll view with a modern design.  Do not respond with anything exepct for the React Native code object.  Here is the JSON:  " + prompt }],

      //  NOTE:  Ideally we'd be able to do I driven search, where it can store a "fingerprint" or "memory" with each encoding of the position in concept space and find matches!

      // stream : true,

    });

    setResponse(completion.data.choices[0].message);

  }

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <HeroCard title="Task Ingestor" subTitle="GPT 3.5 Turbo" />
      <Text>{JSON.stringify(response)}</Text>
      <TextInput onChangeText={setPrompt} value={prompt} />
      <GalleryButton title="Submit" onPress={doAi} />
    </ScrollView>
  );
}


export class PlaygroundPlugin extends CorePluginClass {

  public static details = {
    name: 'Playground Plugin',
    description: 'Playground Plugin',
    dependencies: [AuthPlugin.details.id, SystemPlugin.details.id],
    id: 'playground'
  }

  public install = (program: Program, { authPlugin, system }: { authPlugin: AuthPlugin, system: SystemPlugin }) => {

    authPlugin.onLogin(({ user, token: haborToken }) => {

      system.registerPrimitiveSystem({
        id: "playground",
        name: "Assistant",
        description: "Assistant Plugin",
        menuItem: {
          backgroundColor: '#026358',
          icon: {
            name: "robot-excited-outline",
            type: "material-community"
          },
          iconColor: "#619367"
        },
        labels: ["integrations"],
        component: () => {

          return (
            <>
              {/* <ChatGPT /> */}
              <TaskIngestor />
              {/* <ReactNativeComponent /> */}
            </>
          );

        }
      })
    })



    return this;
  }
}
