import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { AttachmentEditor } from './attachment-editor';
import { AttachmentList } from './attachment-list';
import { AttachmentPlugin } from './attachment-plugin';

const Stack = createStackNavigator();

export enum AttachmentRoutes {
  List = "AttachmentList",
  Editor = "AttachmentEditor"
}
export const AttachmentPage = ({ attachmentPlugin }: { attachmentPlugin: AttachmentPlugin }) => {

  return (
    <Stack.Navigator initialRouteName={AttachmentRoutes.List} screenOptions={{ animationEnabled: true }} {...{ headerMode: "none" }}>
      <Stack.Screen name={AttachmentRoutes.Editor} component={({ navigation, route }) => {

        //  TODO:  Switch to route.params.  Not sure why the type isn't working.
        const routeParams = route.params || {};
        const { attachment, attachmentPartial } = routeParams;

        return <AttachmentEditor attachment={ attachment } attachmentPartial={ attachmentPartial } attachmentPlugin={attachmentPlugin} />

      }
      } />
      <Stack.Screen name={AttachmentRoutes.List} component={({ navigation }) => <AttachmentList navigation={navigation} attachmentPlugin={attachmentPlugin} />} />
    </Stack.Navigator>
  );
}
