import * as React from 'react';
import { Text, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';

export const BinaryFieldStyle = [
  {
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    color: '#555555',
    minHeight: 37,
    borderBottomColor: '#555555',
    borderBottomWidth: 1
  }
];

export const BinaryField = (props: { value: boolean, update: (value: boolean) => void }) => {

  // const pickImage = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.All,
  //     base64: true,
  //     allowsEditing: true,
  //     aspect: [4, 3],
  //     quality: 1,
  //   });

  //     if (!result.cancelled) {
  //       input.onChange(result.base64);
  //     }
  //   };
  // // 
  //   let imageUri = input.value ? `data:image/jpg;base64,${input.value}` : null;

  return <Text>Should be a binary field</Text>;
  return (
    <View>
      {/* <Button title="Pick Image" onPress={pickImage} />
      {
        imageUri ?
          <View style={{ width: 100, height: 100, backgroundColor: 'blue', display: 'flex' }}>
            <ImageBackground source={{ uri: imageUri }} resizeMode="cover" style={{ flex: 1 }} />
          </View>
          : null


      } */}

    </View>
  );
};

export const sdtBinaryRenderer = ({ sdt, name, key, value, update }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={name}>
    <BinaryField update={update} value={value} />
  </DavelField>
);
