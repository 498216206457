import { Color, EntityType, FrameworkContext, HaborComponent, HaborComponentContext, InstanceInternal, NounInternal, OwnershipRelationship, OwnershipRelationshipNoun } from 'habor-sdk';
import * as _ from 'lodash';
import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { haborSDK } from '../../hessia-plugin/config';
import {Picker} from '@react-native-picker/picker';
import { Card } from '../../../../packages/kelp-bar/card';
import { primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';
import { HaborContainer, PrimitiveProps } from '../../component-plugin/habor-react/habor-component-lib';

//  TODO:  Pull this out into an "ObjectSelector" widget?

export interface ParentSelectionWidgetProps {
  instance: InstanceInternal<any>;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext
}
interface ParentSelectionWidgetState {
  nouns: NounInternal[];
  instances: InstanceInternal<any>[];
  selectedNoun?: string;
  selectedInst?: string;
  ownerRelationships: InstanceInternal<OwnershipRelationship>[];
  destInstances: { [instanceId: string]: InstanceInternal<any> };
}

class ParentSelectionWidget extends React.Component<ParentSelectionWidgetProps, ParentSelectionWidgetState> {
  constructor(props: ParentSelectionWidgetProps) {
    super(props);
    this.state = {
      nouns: [],
      instances: [],
      ownerRelationships: [],
      destInstances: {}
    }
  }

  //  TODO-IMPORTANT:  Eventually support MULTIPLE selections!  MAYBE do this with the same mechanism as the "Relationship" type?  Hmmm... This is a different reltionship / system though right?  Hmm...  MAYBE this is one sub-class of Relationship?  Actually, I believe that IS how it's currently modeled.
  public componentDidMount = async () => {

    //  TODO:  We SHOULD be building this ENTIRE component in Hessia with the component buildre!  We search for all Nouns as an input, THEN we go to a "Noun Selector".  After that we pipe to an "Instance Selector Block", and these blocks ONLY appear once they have an input!  It would be AWESOME to see a LIVE view of the component connections too!
    //  FOR NOW:  I'm working on the "Owner Relationship" system, NOT the custom component builder... so, we're going to stick with that.

    //  Unpack
    const { frameworkContext: { token } } = this.props;

    //  Get Nouns
    const nouns = await haborSDK.searchNouns(token);

    //  Get the Owner Relationships
    await this.refreshOwnerRelationships();

    //  Set State
    this.setState({ nouns });
  }

  public refreshInstances = async (nounId: string) => {

    //  Unpack
    const { frameworkContext: { token } } = this.props;

    //  Get the Instances
    const instances = await haborSDK.searchInstances(token, { nounId });

    //  Set State
    this.setState({ instances });

  }

  private refreshOwnerRelationships = async () => {

    //  Unpack
    const { frameworkContext: { token }, instance } = this.props;

    //  Get Owner Relationships
    const ownerRelationships = await haborSDK.searchInstances<OwnershipRelationship>(token, { nounId: OwnershipRelationshipNoun.id, search: { match: { payload: { srcId: { instanceId: instance.id } } } } })

    //  Grouped by Noun
    const nounGroups = _.groupBy(ownerRelationships, (rel) => rel.payload.destId.nounId);

    //  Get Destination Instances
    const destInstances: { [instId: string]: InstanceInternal<any> } = {};
    for (const nounId in nounGroups) {
      const nounGroup = nounGroups[nounId];
      const instTerms = nounGroup.map(rel => ({ match: { id: rel.payload.destId.instanceId } }));
      const instances = await haborSDK.searchInstances<any>(token, { nounId, search: { any: instTerms } });
      instances.forEach(inst => {
        destInstances[inst.id] = inst;
      });
    }

    this.setState({ ownerRelationships, destInstances });
  }

  private nestInstance = async () => {

    //  Unpack
    const { instance, frameworkContext: { token } } = this.props;
    const { selectedNoun, selectedInst } = this.state;

    //  Guard Undefined
    if (!selectedNoun || !selectedInst) {
      throw new Error("Cannot nest the instance with an undefined value.");
    }

    //  Make the OwnerRelationship
    const ownerRelationship: OwnershipRelationship = {
      srcId: {
        nounId: instance.nounId,
        instanceId: instance.id,
        type: EntityType.Instance
      },
      destId: {
        nounId: selectedNoun,
        instanceId: selectedInst,
        type: EntityType.Instance
      }
    }

    //  Nest the Instance
    await haborSDK.createInstance({ nounId: OwnershipRelationshipNoun.id, payload: ownerRelationship }, token);


    //  Refresh
    this.refreshOwnerRelationships();
  }

  private selectNoun = (nounId: string) => {
    this.setState({ selectedNoun: nounId });
    this.refreshInstances(nounId);
  }

  private selectInstance = (instId: string) => {
    this.setState({ selectedInst: instId });
  }

  public render = () => {

    //  Unpack
    const { instance } = this.props;
    const { nouns, instances, selectedNoun, selectedInst, ownerRelationships, destInstances } = this.state;

    //  Create Noun Items
    const nounItems = nouns.map(noun => <Picker.Item label={ noun.name } value={ noun.id } />)

    //  Create Instance Items
    const instanceItems = instances.map(inst => <Picker.Item label={ inst.payload.name ? inst.payload.name : inst.id } value={ inst.id } />)

    return (
      <View style={{ flex: 1 }}>
        <Text>Parent Selection</Text>
        <Picker onValueChange={ (noun) => this.selectNoun(noun) } selectedValue={ selectedNoun }>
          { nounItems }
        </Picker>
        <Picker onValueChange={ (inst) => this.selectInstance(inst) } selectedValue={ selectedInst }>
          { instanceItems }
        </Picker>
        <Button title="Connect" onPress={ this.nestInstance } />
        <View>
          {
            ownerRelationships.map(rel => {

              //  Guard Undefined
              //  CONCERN:  Will the instanceId ever be null?  Do we sometimes connect to a Noun?
              if (!rel.payload.destId.instanceId) { return null }

              //  Get the Destination Instance
              const destInst = destInstances[rel.payload.destId.instanceId];

              //  TODO-IMPORTANT:  This rendering technique requires the Nestable system to be aware of the Named Object internals.  Instead, we should just rely on a generic renderer that knows how to handle the Preview for an entity?
              return (
              <Card innerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 18, color: Color.medGray }}>{ destInst.payload.name ? destInst.payload.name : destInst.id }</Text>
                {/* <Text>{ rel.payload.destId.instanceId }</Text> */}
              </Card>
              )
            })
          }
        </View>
      </View>
    );
  }
}

export interface ParentSelectionWidgetHaborComponentPrimitiveProps extends PrimitiveProps {
  userProps: {
    instance: InstanceInternal<any>;
  }
}
export const ParentSelectionWidgetHaborComponentPrimitive = ({ userProps, frameworkProps }: ParentSelectionWidgetHaborComponentPrimitiveProps) => {

  //  Unpack
  const { context, componentContext } = frameworkProps;

  return (
    <HaborContainer frameworkProps={ frameworkProps } style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
      <ParentSelectionWidget { ...userProps } frameworkContext={ context } componentContext={ componentContext } />
    </HaborContainer>
  );
};

export const ParentSelectionWidgetHaborComponent: HaborComponent = {
  name: "ParentSelectionWidgetHaborComponent",
  propsSchema: { type: "object", extensible: true },
  element: {
    name: "ParentSelectionWidgetHaborComponentPrimitive",
    props: {
      instance: { type: "symbol", scopePath: ["props", "instance"] }
    },
    children: []
  }
};
