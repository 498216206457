/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

interface HTestParams {}

export const HTestFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "test",
  returns: { type: "date" },
  definition: async (input: PrimitiveFunctionParams<HTestParams>): Promise<string> => {
    const { params } = input;
    alert("Test!!!");
    return new Date().toISOString();
  }
};