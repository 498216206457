

import { CorePluginClass, Program } from "halia";
import { Event, EventPlugin } from '../events-plugin';
import { CheckinPlugin } from "./checkin-plugin";

export class CheckinEvents extends CorePluginClass {

  public static details = {
    name: 'Checkin Events',
    description: 'Event Integration for Checkins',
    dependencies: [CheckinPlugin.details.id, EventPlugin.details.id],
    id: 'checkinEventPlugin'
  }

  public install = (program: Program, { checkinPlugin, eventPlugin }: { checkinPlugin: CheckinPlugin, eventPlugin: EventPlugin }) => {

    //  TODO:  FOR NOW, we just return ALL system events.  In the future, we MAY want to do this differently!
    //  CONSIDER:  SHOULD this have an ID of its own?  A TOP level thing we can access in the Halia system? Hmmm... in VSCode it SORTA has an identifiy. but it's pulled form the file-system? Hmm it's JUST an encoding? A symbol?

    eventPlugin.registerProvider({
      systemId: checkinPlugin.systemId,
      name: "Checkin Event Provider",
      provide: async () => {
        const checkins = await checkinPlugin.checkinService.retrieveAll();
        return checkins.map(checkin => ({ name: "Checkin Created", icon: { type: "material", name: "create" }, type: "point", time: new Date(checkin.created) } as Event))
      }
    });

    return this;
  }
}