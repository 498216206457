import { NounInternal } from "habor-sdk";
import * as React from 'react';
import { Text, View } from "react-native";
import { medSpacer } from "../../../../packages/kelp-bar/styles";
import { TextSubParagraph } from "../../../../packages/kelp-bar/text";
import { useEntityPlugin } from "../../entity-plugin";
import { AppContext } from "../../hessia-plugin/AppContext";
import { haborSDK } from "../../hessia-plugin/config";
import { WidgetItem } from "../../hessia-plugin/dynamic-component";
import { typeToFilterComponentMap } from "../../hessia-plugin/filter-utils";

//
//  Filter Provider:  Provides filter settings for the app.
//

//  CONSIDER:  We MAY have MULTIPLE places in the UI where we want filters.  
//  CONSIDER:  This MAY actually BE the context system hmm...  Then we can match and add things like that hm...

export const FilterContext = React.createContext<{ settings: any, setSettings: any }>({ settings: {}, setSettings: () => null });

export const FilterProvider = ({ children }: { children: any }) => {

  const filterContext = React.useContext(FilterContext);
  const [settings, setSettings] = React.useState({});

  return (
    <FilterContext.Provider value={{ settings, setSettings: (settings) => { setSettings(settings) } }}>
      {children}
    </FilterContext.Provider>
  );
}

//
//  Instance Filter Component
//  NOTE:  This just sets the SETTINGS for the actual filter!  Currently the filter is a part of the PLUGIN!
//

interface InstanceFilterComponentState {
  noun?: NounInternal;
}

export const InstanceFilterComponent = ({ instances }: any) => {

  const { settings = {}, setSettings } = React.useContext(FilterContext);
  const instanceSettings = settings.instance ? settings.instance : {};

  const appContext = React.useContext(AppContext);
  const entitiesPlugin = useEntityPlugin();
  const [noun, setNoun] = React.useState<NounInternal>();

  const componentDidMount = async () => {

    //  TODO-CRITICAL:  We SHOULD support filtering in a MIXED setting with "Instances" from MULTIPLE Nouns!???  For this reason, I'm hesitatnt to pass "Noun".  INSTEAD, this is a responsibility of the "Class" system.  FOR NOW, we will ASSUME one class and look at the first instance!?

    const { token } = appContext.frameworkContext;

    const inst = instances[0];

    const nounId = inst.nounId;

    //  TODO-CRITICAL-AWESOME:  Should have CACHING for this.  OR, perhaps functions registered to the INSTANCE to retrieve this associated stuff!??  I REALLY like that idea!! hen in Hessia because we dontrol the UI we can SHOW these things.  They will be DYNCMIALLY loaded, similar to typescript... HMMM.. maybe we can make a VSCode Plugn for that!!!????  BUT here, I suppose we'll expect theuser to KNOW them!??? SUPER cool!!!!!  I REALLY want to keep pushing on this!!!
    const noun = await haborSDK.retrieveNoun(nounId, token);
    setNoun(noun);
  }

  React.useEffect(() => {
    componentDidMount();
  }, [])

  const setPropSettings = (propName: string, propSettings: any) => {
    setSettings({ ...settings, ["instance"]: { ...instanceSettings, [propName]: propSettings } });
  }

  //  TODO-GENERAL:  Unified loading / waiting framework.. including modal, page level, block leve, etc...???  MULTIPLE levels???
  if (!noun) { return <Text>Loading Noun...</Text> }

  //  CONCERN:  This is VERY interesting.. because we have the filter settings on the PLUGIN... We MAY want to move them somewhere else, ESPECIALLY if we want to support multiple open filters at the same time? Hmm...
  //  TODO:  Support collapsing of filter with the current ipmlemetation.. eventually, stuff like that will be more dynamic and accessible across the system!?? Hmmm

  const Filters = Object.keys(noun.properties).map(propName => {
    const prop = noun.properties[propName];
    const type = prop.type?.type;
    //  CONSIDER:  We MAY have multiple types, but I SUPPOSE we can push that into the type itself... 
    //  FOR NOW:  We can ASSUME the property type!
    const FilterComponent: any = type ? typeToFilterComponentMap[type] : null;
    return (
      <WidgetItem name={propName} color={"#777777"}>
        {FilterComponent ? <FilterComponent entitiesPlugin={entitiesPlugin} propSettings={ instanceSettings[propName] } setPropSettings={(_propSettings) => setPropSettings(propName, _propSettings)} instances={instances} noun={noun} propName={propName} /> : null}
      </WidgetItem>
    );
  }).filter(val => val != null)

  return (
    <WidgetItem name="Class Filter" color="#bd0067">
      {
        Filters.length ?
          Filters :
          (
            <View style={{ padding: medSpacer }}>
              <TextSubParagraph style={{ color: 'white' }}>No Class Filters</TextSubParagraph>
            </View>
          )
      }
    </WidgetItem>
  )
}