import { FrameworkContext, HaborComponentContext, UserBlock, UserCredentialProps } from 'habor-sdk';
import * as React from 'react';
import { Text } from "react-native";
import { Card } from '../../../../packages/kelp-bar/card';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';
import { BlockRenderer } from './components/component-renderer';
import { getSignalNodeMap, SignalNodeResult } from './data-flow';

//  IDEA:  Make a BUNCH of super cool debugging tools, like an "Output" viewer, BUT show this in the FLOWCHART!  Support step-by-step debugging with the flowchart as well???

//  TODO-IMPORTANT:  This ENTIRE system is nearly identical to the function system we have in Habor... can we merge them??

// export const runBlock = (block: UserBlock, input: any) => {
  
//   //  Validate Input

//   //  Get the Signal Map
//   //  TODO:  Reduce the number of times we generate the signal map.
//   const res = getSignalNodeMap(block);

//   //  Create the Signal Map
//   const signalMap = {};

//   //  Iterate the SignalNodeMap
//   //  NOTE:  Unlike Plugins which cannot be done in parallel (because two siblings may affect the same resource), we will run the pipeline in parallel.
//   //  TODO:  Make sure siblings cannot affect the same resource!  That could cause a RACE CONDITION!
//   const rootSignalIds = Object.keys(res.signalNodeMap).filter(key => res.signalNodeMap[key].dependencies.length == 0);

//   //  Run the Program
//   //  NOTE:  This sets up the flow, BUT we don't want the program to just end, it should RESOPND to changes!  That said, OTHER flows, WITHOUT persistent elements should be one-shot flows?
//   rootSignalIds.forEach(rootSignalId => {
//     const rootSignal = res.signalNodeMap[rootSignalId];
//     const blockInst = rootSignal.definitions[0]
//     runBlockInst(blockInst, signalMap);
//   });

//   return;
  
// }

// export const runBlockInst = async (blockInst: BlockInst, signalMap: any) => {


//   //  Get the Block
//   const block = allBlocks[blockInst.id];

//   if (block.type == BlockType.Primitive) {

//     //  Cast the Block
//     const primitiveBlock = block as PrimitiveBlock;



//     //  TODO:  We MAY not need to await here in parallel groups.
//     const result = await primitiveBlock.function(input);

//     //  Make the output signals
//     Object.keys(result).forEach(outputName => {
//       const signalId = blockInst.output[outputName];
//       const signalValue = result[outputName];
//       signalMap[signalId] = signalValue;
//     });

//   } else if (block.type == BlockType.User) {
//     throw new Error("User Blocks are not currently supported");
//   } else {
//     throw new Error(`Unsupported block type: '${ block.type }'`);
//   }

// }

//
//  ComponentDebugger Component
//

export interface ComponentDebuggerProps extends UserCredentialProps {
  component: UserBlock;
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  signalNodeResult: SignalNodeResult;
}

interface ComponentDebuggerState {}

class ComponentDebuggerBase extends React.Component<ComponentDebuggerProps, ComponentDebuggerState> {
  constructor(props: ComponentDebuggerProps) {
    super(props);
    this.state = {}
  }

  public render = () => {

    const { component: { blockInsts }, component, user, token, frameworkContext, componentContext, signalNodeResult: res } = this.props;


    //  TODO-IMPORTANT:  REALLY reorganize the "FrameworkContext" and "HaborComponentContext" systems!  Consider if they're still necessary for the new Habor Component system.  DOCUMENT exactly what they do.  Consider supporting these with PLUGINS which extend the base component system!

    //  Validate
    if (res.errors.length > 0) {
      return (
        <Card>
          <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 12 }}>Invalid Confuguration</Text>
          <Text style={{ fontFamily: primaryFontFamily, fontSize: 11 }}>This component cannot be debugged while there are remaining errors.</Text>
        </Card>
      );
    }

    //  Run the Program
    //  NOTE:  OK!  The main idea here... We want to render a Component.  We are assuming that the OUTER block, is, FOR NOW, a component!  In the future, we MAY just want to run a pipeline which doesn't have components?  We MAY even want to form stages where user input is gathered from email or txt, etc... it MAY get complicated!  BUT that's awesome!  In this case, we have a "Screen Provider"?  Which we can use to render our comopnents??  In the future, we MAY (and probably should) support other mediums by which to obtain data??
    //  FOR NOW, when we render a component, ALL we're going to do is pull out the "Comopnent" blocks and render them to the screen.  They will all depend on "Signals".  Then, in our "componentDidMount" method, we can kick off the data flow.  From then on, whenever a signal changes, we re-run the data flow?  In the future, we MAY be able to JUST re-run the segment of the flow in which the change occurred.
    //  I AM wondering if I can re-use the HaborComponent logic we already have to do most of this work... OR, if I should totally re-write it?

    //  TODO:  Support input!
    return (
      <BlockRenderer componentContext={ componentContext } frameworkContext={ frameworkContext } component={ component } input={{ }} setOutput={ () => null } />
    );
  }
}
export const ComponentDebugger = ComponentDebuggerBase
