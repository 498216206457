import * as React from 'react';
import { Text, View, ViewStyle } from "react-native";
import { Card } from '../../../../../packages/kelp-bar/card';
import { medSpacer, primaryFontFamily, primaryFontFamilyHeavy, smallSpacer } from '../../../../../packages/kelp-bar/styles';
import { KelpIcon } from "../../../../../packages/kelp-bar/kelp-icon";
import { NamedObjectItem } from 'habor-sdk';

//  CONSIDER:  Should users be able to make Plugins to augment this view?
//  TODO:  Consider morphing with the "named-object" plugin files?
//  THOUGHT:  I think we can use both the Feature Plugin system, OR typical react-props (children) to extend this thing??  BUT, what about when we have MULTIPLE child registration points?
//            We can make an adjustment to THIS component by supporting new props for things like "draggable".  BUT, we don't want the API to get wicked messy with LOTS of settings.  Instead, we want it to be modular.  How do we do that?  It would be cool if we could build another component off of this one, maybe by cloning, adding new props, and registering into one of the screen areas to add a "Grab Bar"?
//            1.  Add a registration point to inject code within the JSX.  BUT, that code gets to look at the local state!
//            2.  Add places in "props" for additional elements... but these, like "children" don't get to look at local state.
//            3.  Hard-code the feature into "props".  The problem with this, is it adds features to the API that NOT all consumers need!  It's nice to ONLY have access to the pieces you need??
//  NOTE:  Instead of a NEW component for each of these changes, we want them to be COMPOSABLE!
//  REMEMBE:  When we ADD a feature to a Component, it's THEN possible for another Plugin? to add a feature to THAT feature.

//  BRAINSTORM:  This sounds a LOT like the Plugin system... but, we MAY be able to do this in Hessia where we have user-level code, but how will we do it here in JS?
//               What if we do use the Feature API method?  Then, we'd have a Plugin to install the "NamedObjectItem", which has registration points for additional features.  Then, imagine we have two Plugins, "Chiclet" and "Draggable", which add modifiers to NamedObjectItem.  Ok... Then, when we add "Layout" I suppose we'd want to depend upon those Plugins?  BUT, is doing that enough?  HOW do we update the API for Chiclet and Draggable?
//               We'll want a new "props" type?  But, we'll also want the new code in place to HANDLE the new props-type.

//  OK... what if we "install" all these systems, we can DEPEND on them to make sure they're installed first, and THEN, the end result is a changed API?  Is that the way it should work???  This means, if we JUST depend upon the "NamedObjectItem" plugin, then we see that API?  Hmmmm...... that WOULD be pretty cool!  But what about OPTIONS?  Mabe there are Plugin options?  Yes, these can be specified by the plugin I believe?  Ok, well if we depend on those things, then we'll have registered that code.  But, if we JUST depend upon the original, are we going to still have that code?  I think we can potentially change our view of the API by making it BACKWARDS compatible.  This means, while the feature Plugins MAY add, then don't break existing functionality.
//  SO!  This means we have a Plugin for NamedObjectItem.  It WOULD be nice if we could update the type, but it's not entirely necessary right?

//  REALIZATION:  MOST of these CS concepts that seem confusing and complex are actually built from a NEED, and a VERY simple idea!  Then we just find A path to the solution... Eventually, as that path becomes more optimal, it develops complexities, and the original simplicity is lost if not well documented.


export interface NamedObjectItemProps {
  item: NamedObjectItem;
  onPress?: () => void;
  style?: ViewStyle;
}
interface NamedObjectItemState {}
class NamedObjectItemBase extends React.Component<NamedObjectItemProps, NamedObjectItemState> {
  constructor(props: NamedObjectItemProps) {
    super(props);
    this.state = {}
  }
  public render = () => {

    //  Unpack
    const { item, onPress, children, style } = this.props;

    return (
      <Card outerStyle={ style } innerStyle={{ padding: medSpacer, display: 'flex', flexDirection: 'column' }} onPress={ onPress }>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
          <KelpIcon name={ item.icon ? item.icon.name : 'question' } type={ item.icon ? item.icon.type : 'font-awesome' } color={ item.color } size={ 30 } />
          <View style={{ display: 'flex', flexDirection: 'column', marginLeft: medSpacer, justifyContent: 'center' }}>
            <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 14 }}>{ item.name }</Text>
            <Text style={{ fontFamily: primaryFontFamily, fontSize: 13 }}>{ item.description }</Text>
          </View>
        </View>
        {
          children ?
            <View>
              <View style={{ height: smallSpacer }} />
              { children }
            </View> :
            null
        }
        
      </Card>
    );
  }
}
export const NamedObjectItemComponent = NamedObjectItemBase
