
import * as React from 'react';
import type { AppsPlugin } from './apps-plugin';


export const AppsPluginContext = React.createContext<{ appsPlugin: AppsPlugin } | undefined>(undefined);

export const useApps = () => {
  const appsContext = React.useContext(AppsPluginContext);
  if (!appsContext) throw "Could not obtain the Habor Apps Plugin Context."
  return appsContext.appsPlugin;
}
