import {
  createNavigatorFactory, StackRouter, TabActions, TabRouter, useNavigationBuilder
} from '@react-navigation/native';
import * as React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Pressable } from 'react-native';
import { defaultBorderRadius, primaryFontFamily } from '../../../packages/kelp-bar/styles';
import { primaryColor } from '../constants';
import { CenteredView, useSettings } from '../content-view';
import { ProfileSection } from '../screens/dashboard';
import { useSizes } from '../sizes-helper';
import { ProfileContext } from '../utilities';

//  NOTE:  DUDE!!! It's doing the SAME shit I do!!  It's JUST got a state for the selected route that gets set with a REDUCER HM!  This is JUST an "action".. instead of setting it direclty ugh!  WHY?  This way it's clear HOW the state will change hmmm!  AWESOME HM!


//  NOTE:  Button style influened by Google!

const MenuButton = ({ onPress, name, icon, selected }) => {
  const [hover, setHover] = React.useState(false);

  const bgColor = selected ? '#ffdee8' : hover ? '#f6f6f6' : 'transparent';
  const fgColor = selected ? primaryColor : '#777777';

  return (
    <Pressable onHoverIn={() => setHover(true)} onHoverOut={() => setHover(false)} onPress={onPress} style={{ height: 65, marginVertical: 5, backgroundColor: bgColor, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: 35, borderBottomRightRadius: 35, borderTopLeftRadius: defaultBorderRadius, borderBottomLeftRadius: defaultBorderRadius }}>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: 200 }}>
        <Icon  name={icon.name} type={icon.type} size={21} color={fgColor} />
        <View style={{ width: 25 }} />
        <Text style={{ fontFamily: primaryFontFamily, fontSize: 16, color: fgColor }}>{name}</Text>
      </View>
    </Pressable>
  )
}

function PanelNavigator({
  initialRouteName,
  children,
  screenOptions,
  tabBarStyle,
  contentStyle,
}) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  const { isDesktop, isMobile } = useSizes();
  const index = state.index;
  const routeName = state.routeNames[index];



  //  CONSIDER:  Should not couple these menu items with MULTIPLE menus explicilty!  Have a STANDARD interface? Hmm...


  const DashboardMenu = () => {

    //  TODO:  Use a "ProfileProvider" OR do something like 
    //  THOGUHT:  The obsession with GraphQL AND Redux has always bugged me... Maybe there are just USEFUL tools.  GraphQL as a was to organize encodings used to FILTER (queries) ... note that I think of THAT language as more "fundamtnal" because it applies to the fundamental ontology? Hmmm.... Then Redux is GLOBAL state, which is nice for Replay and stuff, but ... hmm... CAN we still just keep it LOCAL? Hmm... A REDUCER is JUST a PURE function which takes in the PREVOIUS and outputs the NEW.  The point is, an ACTION maps to a reducer, and the idea is, we always transition using pure functions.  This means we can replay, and hydrate and hmm... lots of useful things.. hmmm
    const [profile, setProfile] = React.useContext(ProfileContext);

    return (
      <ScrollView contentContainerStyle={{}} style={{ width: 260, flexShrink: 0, flexGrow: 0, height: '100%', maxHeight: 700, display: 'flex', flexDirection: 'column', paddingVertical: 30, marginRight: 30 }}>

        <View style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1, minHeight: 250 }}>

          <ProfileSection />

          {/* AH!!!  It's a new abstraction thta we want!  WANT to be able to INTERPRET these patterns and SHOW them as things that are ABSTRACTED WITHOUT actually making it EXPLCIIT AH!  Multi-modal acivation hm!  MEANING it can fit MULTIPLE patterns hm!!  THIS way Ah !i LOVE THAT HM!  We don't need to set a thing explilt hmm... we CAN and it'l just render out as yeah hM!  Other people don't folllwotheir instincts, and I thin we ALL have them!  It's like Bob Ross... it's not about being the "smartest", it's about following that little voice that feels like something is off!  That's LITERALLY your brain telling you that your understanding is INCONSISTENT HM!!! */}



        </View>

        {/* <View style={{ height: 2, marginBottom: 30, width: '65%', backgroundColor: '#eeeeee', borderRadius: 30, alignSelf: 'center' }} /> */}



        {state.routes.map((route) => (
          <MenuButton
            name={descriptors[route.key].options.title || route.name}
            icon={descriptors[route.key].options.icon || { name: "test", type: "material" }}
            selected={routeName == route.name}
            key={route.key}
            onPress={() => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              });

              if (!event.defaultPrevented) {
                navigation.dispatch({
                  ...TabActions.jumpTo(route.name),
                  target: state.key,
                });
              }
            }}
          />
        ))}

      </ScrollView>
    );

  }

  const settings = useSettings();

  return (
    <NavigationContent>

      {/* REALIZATION:  Thinking abotu the default margin, it ALWAYS needs to be specified!!!  EITHER as an abstracted, hard-coded thing (for this domain) OR seprate as modifieres, sepsrate propeties etc hm!!! iT's JUST an ENCODING. */}
      {/* MAYBE we can have a way to add it to a GROUP!  JUST like we have CSS classes hM!  THIS way we have PROP classes and shit too hm!  I LOVE that hm!!!  THIS way... we CAN inject based on class hm! */}


      <CenteredView style={{ flex: 1, width: '100%', height: '100%', overflow: 'hidden' }} containerProps={{ style: { flex: 1, width: '100%', height: '100%', overflow: 'hidden' } }}>

        <View style={{ flex: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'row', paddingHorizontal: settings.marginSize }}>


          {/* Left Panel (or Menu) */}
          {/* TODO:  Instead of using the Portal, just add that to the Header via an injection API?  That way we have additional context? Hmm...  */}
          {
            isDesktop ? (
              <DashboardMenu />

            ) : (
              <>

              </>

            )
          }


          {/* Center Panel */}
          {state.routes.map((route, i) => {
            return (
              <View
                key={route.key}
                style={[{ display: i === state.index ? 'flex' : 'none', flex: 1 }]}
              >
                {descriptors[route.key].render()}
              </View>
            );
          })}

          {/* Right Panel */}
          {/* {
          isDesktop && (
            <View style={[{ width: 300, overflow: 'hidden', borderLeftWidth: 2, borderLeftColor: '#dddddd' }, contentStyle]}>

            </View>
          )
        } */}


          {/* CONSIDER:  WOULD like to be able to "centralize" the idea of "Account" or at least see all these PIECES and show what they're injected into.. MAY have multiple modification profiles hmm! */}
        </View>

      </CenteredView>



    </NavigationContent>
  );
}

//  NOTE:  This creates a function which creates Group, Navigator, and Screen components for an instance of this custom navigator.
export const createPanelNavigator = createNavigatorFactory(PanelNavigator);



// export const BareNavigator = ({
//   initialRouteName,
//   children,
//   screenOptions
// }) => {
//   const { state, descriptors, NavigationContent } =
//     useNavigationBuilder(StackRouter, {
//       children,
//       screenOptions,
//       initialRouteName,
//     });

//   return (
//     <NavigationContent>
//       {state.routes.map((route, i) => {
//         return (
//           <View
//             key={route.key}
//             style={[
//               { display: i === state.index ? 'flex' : 'none', flex: 1 }
//             ]}
//           >
//             {descriptors[route.key].render()}
//           </View>
//         );
//       })}
//     </NavigationContent>
//   );
// }
// export const createBareNavigator = createNavigatorFactory(BareNavigator);
