import { NavigationProp, useNavigation } from '@react-navigation/core';
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { HessiaTag, TagPlugin } from '.';
import { Page } from '../../../packages/kelp-bar/page';
import { PageLoader } from '../../../packages/kelp-bar/page-loader';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';

export interface TagListItemAPI {
  setText: (text: string) => void;
}

type EnityListItemRenderer = (item: NounServiceInstanceInternal<HessiaTag>, api: TagListItemAPI) => void;

const tagListItemRenderers: EnityListItemRenderer[] = [];
export const registerTagListItemRenderer = (renderer: EnityListItemRenderer) => {
  tagListItemRenderers.push(renderer);
}


//  TODO:  Support composiiton with the SVO / Relation system and String systems!  This is ONE example of a CATEGORY HM!  And a CATEGORY is just an observable hm!

interface TagListProps {
  tagPlugin: TagPlugin;
  navigation: NavigationProp<any>
}
interface TagListState {
  tags: NounServiceInstanceInternal<HessiaTag>[],

  setName: string;
}

export const TagChiclet = ({ tag }: { tag: NounServiceInstanceInternal<HessiaTag> }) => {

  // const entity: Entity = {
  //   systemId: this.props.tagPlugin.serviceName,
  //   route: { id: tag.id, created: tag.created },
  //   metadata: tag.payload
  // };
  // const entityId = getEntityId(entity);

  const navigation = useNavigation();
  return (
    <TouchableOpacity style={{
      borderRadius: 15, shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 4,

      elevation: 5, backgroundColor: 'white', padding: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
    }} onPress={() => navigation.navigate("Editor", { tag })}>
      <Text>{tag.payload.value}</Text>
    </TouchableOpacity>
  );
}

export class TagList extends React.Component<TagListProps, TagListState> {

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      setName: ""
    }
  }

  componentDidMount = async () => {

    const tags = await this.props.tagPlugin.tagService.retrieveAll();
    this.setState({ tags });
  }

  render() {

    const { navigation } = this.props;

    return (

      <PageLoader loading={false}>
        <Page style={{ marginHorizontal: 20 }}>


          <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
            <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Tags</Text>

            <TouchableOpacity onPress={() => { navigation.navigate("Editor") }} style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: "#aaaaaa", alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
            </TouchableOpacity>
          </View>


          <View style={{ height: 15 }} />
          <ScrollView>

            <FlatGrid data={this.state.tags} renderItem={({ item: tag }) => <TagChiclet tag={tag} />} />

          </ScrollView>
        </Page>
      </PageLoader>
    )
  }
}
