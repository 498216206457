import { AnyBlock, AnyValueReference, FrameworkContext, InstanceInternal, SDTObject } from 'habor-sdk';
import * as React from 'react';
import { Button, KeyboardAvoidingView, Modal, ScrollView, Text, View } from "react-native";
import { DavelForm } from '../../../../../packages/davel-ui/davel-ui';
import { medSpacer } from '../../../../../packages/kelp-bar/styles';
import { HaborReactComponent } from '../../../habor-plugin/habor-utils';
import { TitleChiclet } from '../../../primitives-plugin/habor-components/embedded-views/chicklet-component';
import { SignalNodeMap } from '../data-flow';
import { resolveBlock } from '../models/blocks';
import { SelectedProperty } from '../models/signals';

export interface SignalSelectorProps {
  close: () => void;
  selectedProp: SelectedProperty;
  signalNodeMap: SignalNodeMap;
  frameworkContext: FrameworkContext;
}
interface SignalSelectorState {
  constant: string;
  blockInternal?: InstanceInternal<AnyBlock>;
}
class SignalSelectorBase extends HaborReactComponent<SignalSelectorProps, SignalSelectorState> {
  constructor(props: SignalSelectorProps) {
    super(props);
    this.state = {
      constant: ""
    }
  }

  public attachSignal = (signalId: string) => {

    //  Unpack
    const { close, selectedProp } = this.props;
    const { input, propName, blockInst } = selectedProp;


    if (input) {
      blockInst.input[propName] = { type: "signal", signalId };
    } else {
      const output = blockInst?.output;
      if (!output) { console.warn("No output!"); return; }
      output[propName] = { type: "signal", signalId };
    }

    close();
    this.forceUpdate();
  }

  public makeConstant = (value: any) => {

    //  Unpack
    const { close, selectedProp } = this.props;
    const { input, propName, blockInst } = selectedProp;
    const { constant } = this.state;

    //  Update the Block Inst
    if (input) {
      blockInst.input[propName] = { type: "constant", value };
    } else {
      blockInst.output[propName] = { type: "constant", value };
    }

    close();
    this.forceUpdate();
  }

  public componentDidMount = async () => {

    // Unpack
    const { selectedProp, signalNodeMap, close, frameworkContext } = this.props;
    const { frameworkContext: { token } } = this.context;

    const block = await resolveBlock(token, selectedProp.blockInst.blockId)
    this.setState({ blockInternal: block });
  }
  public render = () => {

    // Unpack
    const { selectedProp, signalNodeMap, close, frameworkContext } = this.props;
    const { blockInternal } = this.state;

    if (!blockInternal) {
      return <Text>Loading Block...</Text>;
    }

    const schema = blockInternal.payload[selectedProp.input ? 'inputSchema' : 'outputSchema'];
    const propSchema: SDTObject | undefined = schema && schema.properties ?
      schema.properties[selectedProp.propName] :
      undefined;


    //  Get Existing Constant
    //  CONSIDER:  AGAIN, consider simplifying this interface by REMOVING this Constant mechanism and using a BLOCK for this!? Hmm...
    const { input, propName, blockInst } = selectedProp;
    const value: AnyValueReference = (input ? blockInst.input[propName] : blockInst.output[propName]);
    //  TODO:  Remove the constant string in favor of a centralized enum.
    let constantValue;
    if (value && value.type === "constant") {
      constantValue = value.value;
    }


    return (
      <Modal>
        <KeyboardAvoidingView behavior="padding">
          <ScrollView style={{ padding: medSpacer }}>
            <Text>{`Mapping a signal to the '${selectedProp ? selectedProp.propName : 'N/A'}' ${selectedProp ? selectedProp.input ? 'input' : 'output' : 'N/A'} property of the ${selectedProp ? selectedProp.blockInst.id : 'N/A'} block instance.`}</Text>
            <Text>COMPONENT INPUTS</Text>
            {
              Object.keys(signalNodeMap).map(signalId => {
                const val = signalNodeMap[signalId];
                return val.isComponentInput ?
                  <TitleChiclet title={val.signalId} onPress={() => this.attachSignal(signalId)} /> :
                  null
              })
            }
            <Text>BLOCK SIGNALS</Text>
            {
              Object.keys(signalNodeMap).map(signalId => {
                const val = signalNodeMap[signalId];
                return !val.isComponentOutput && !val.isComponentInput ?
                  <TitleChiclet title={val.signalId} onPress={() => this.attachSignal(signalId)} /> :
                  null
              })
            }
            <Text>COMPONENT OUTPUTS</Text>
            {
              Object.keys(signalNodeMap).map(signalId => {
                const val = signalNodeMap[signalId];
                return val.isComponentOutput ?
                  <TitleChiclet title={val.signalId} onPress={() => this.attachSignal(signalId)} /> :
                  null
              })
            }
            {
              propSchema ?
                <View>
                  <Text>MAKE CONSTANT</Text>
                  <DavelForm value={{ root: constantValue }} metadata={{ ...frameworkContext }} schema={propSchema} onSubmit={val => this.makeConstant(val)} onCancel={() => alert('cancelled')} />
                  {/* <TextInput defaultValue="Constant" value={ this.state.constant } onChangeText={ (constant) => this.setState({ constant }) } />
                  <Button title="Make Constant" onPress={ () => this.makeConstant() } /> */}
                </View> :
                null
            }
            <Button title="Close" onPress={close} />
          </ScrollView>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}
export const SignalSelector = SignalSelectorBase