import { SDTObject } from 'habor-sdk';
import * as React from 'react';
import { Modal, ScrollView, Text, View } from 'react-native';
import { DavelForm } from '../../../../packages/davel-ui/davel-ui';
import { primaryFontFamilyHeavy } from '../../../../packages/kelp-bar/styles';

interface SchemaEditorProps {
  schema: SDTObject;
  onSubmit: (nestedValues: any, schema: SDTObject) => void;
  value: any;
  onCancel: () => void;
}

interface SchemaEditorState {
  form?: any;
}

//  TODO:  Support placeholder values!
export class SchemaEditor extends React.Component<SchemaEditorProps, SchemaEditorState> {

  constructor(props: SchemaEditorProps) {
    super(props);
    this.state = {};
  }

  private onSubmit = (nestedValues: any, schema: SDTObject) => {

    //  Unpack Props
    const { onSubmit } = this.props;

    //  Invoke Handler
    onSubmit(nestedValues, schema);
  }

  public render() {

    //  Unpack Props
    const { schema, value } = this.props;

    //  Unpack State
    const { form } = this.state;

    return (
      <Modal>
        <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.8 }} />
        <View style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ width: '85%', height: '85%', borderRadius: 10, backgroundColor: 'white', padding: 30 }}>
            <ScrollView>
              <View style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Text style={{ fontFamily: primaryFontFamilyHeavy, color: '#555555', fontSize: 15, letterSpacing: 1 }}>PROPERTY EDITOR</Text>
                <DavelForm value={value} schema={schema} onSubmit={this.onSubmit} onCancel={this.props.onCancel} />
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  }
}