/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { HPrimitiveFunction } from "habor-sdk";
import { PrimitiveFunctionParams } from "../primitive-function-model";

interface HHelloWorldParams {
  name: string;
}

export const HHelloWorldFunction: HPrimitiveFunction = {
  type: "primitive",
  name: "hello_world",
  returns: { type: "keyword" },
  definition: async (input: PrimitiveFunctionParams<HHelloWorldParams>): Promise<string> => {
    const { params } = input;
    return `Hello ${params.name}!`;
  }
};