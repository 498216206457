import { InstanceInternal, Relationship } from "habor-sdk";
import { CorePluginClass, Program } from "halia";
import * as React from 'react';
import { Button, View, TextInput } from "react-native";
import { DavelField } from "../../../../packages/davel-ui/davel-ui-tools";
import { TextSubParagraph } from "../../../../packages/kelp-bar/text";
import { ComponentPlugin } from "../../component-plugin";
import { ModelPlugin } from '../../model-plugin';
import { HessiaPlugin } from "../../hessia-plugin";
import { haborSDK } from "../../hessia-plugin/config";
import { FilterComponent } from "../../hessia-plugin/filter-utils";
import { ParentListHaborComponentPrimitive } from "./instance-list-widget";
import { ParentSelectionWidgetHaborComponentPrimitive } from "./parent-selection-widget";
import { WidgetItem } from "../../hessia-plugin/dynamic-component";

interface ParentFilterState {
}
class ParentFilterComponentBase extends FilterComponent<ParentFilterState, any, { parentPlugin: ParentPlugin }> {

  constructor(props) {
    super(props);
    this.state = {
      priorities: []
    }
  }

  public componentDidMount = async () => {

    const { token } = this.context.frameworkContext;

    //  Get Priorities
    //  CONSIDER:  This MAY change per CONTEXT!?

    //  TODO:  Abstract this to BOTH Primitive and User Functions in the "Parent Plugin".  
    //  CONCERN:  Figure out the relationship between Halia Plugin and Hessia System.s
    const priorities = await haborSDK.searchInstances(token, { nounId: "parent" });

    this.setState({ priorities });

  }
  public render = () => {

    const { setSettings } = this.props;

    const parentPlugin = this.props.additionalProps.parentPlugin;

    //  TODO-IMPORTANT:  Here, we're accessing the "Parent Plugin" Halia Plugin instance directly, INSTEAD of with injection... PLUS, a piece of state is being held OUTSIDE the React State tree... are EITHER of these two things acceptable to me!?
    return (
      <WidgetItem name="Parent Filter" color="#eeeeee">
        <TextSubParagraph style={{ color: "#aaaaaa" }}>Instance ID:</TextSubParagraph>
        <TextInput style={{ borderBottomColor: '#aaaaaa', borderBottomWidth: 1 }} autoCapitalize="none" defaultValue={undefined} onChangeText={parentId => setSettings({ parentId })} />
        <Button title="Clear" onPress={() => { parentPlugin.parentInstanceIdSelection = undefined; setSettings({}); }} />
      </WidgetItem>
    )
  }
}

export const ParentFilterComponent = (props: any) => {
  const parentPlugin = React.useContext(ParentPluginContext);
  return <ParentFilterComponentBase additionalProps={{ parentPlugin }} {...props} />
};

export const ParentPluginContext = React.createContext<ParentPlugin | undefined>(undefined);

export class ParentPlugin extends CorePluginClass {
  public static details = {
    name: "Nestable",
    description: "Nestable Plugin",
    dependencies: [HessiaPlugin.details.id, ComponentPlugin.details.id, ModelPlugin.details.id],
    id: "nestable"
  }

  //  TODO:  FOR NOW, we JUST support a parent ID... we can support SO much more!! Potentially incluidng IDs of SYSTEM level entities that only certain SYSTEMS are knowledgable of, etc!?  The idea is to support ABSTRACTION as a FIRST-CLASS thing!!!!!  THEN, Ids are not JUST for primitive classes / instances, but ALSO things a SYSTEM considers a unique, identifiable thing!? HMMM???  What about cmoplex EXPRESSIONS that evalulate to a thing?  Perhaps it's OK as long as it's stable or "idempotent?"??? Hmm...
  public parentInstanceIdSelection: string | undefined = undefined;

  //  TODO-GENERAL:  Token is a HESSIA App concept!?  SHOULD it be explicit in these interfaces!?
  public getParentAssociations = async (instance: InstanceInternal, token: string) => {

    //  Get Owner Relationships
    //  TODO: Use the SDK, OR eventually move the codebases to ONE!?  I THINK I prefer that.  Then the CODE is in one place, BUT the deployment is different from Frontend to back!?  Hmm...  OR, we can use the SDK approach.
    const parentAssignments = await haborSDK.searchInstances<Relationship>(token, { nounId: "parent-assignment", search: { any: [{ match: { payload: { srcId: { instanceId: instance.id } } } }, { match: { payload: { destId: { instanceId: instance.id } } } }] } })

    return parentAssignments;
  }

  public install = (program: Program, { hessia, component, modelPlugin }: { hessia: HessiaPlugin, component: ComponentPlugin, modelPlugin: ModelPlugin }) => {

    //  NOTE:  This is a COMMON pattern that I'd like to be able to "inject" AND do so with a CONFIGURATION (encoding) and fucking make an "instance" of that system, or use an existing, and have it AUGMENT this existing system.
    hessia.registerHOC(({ children }: any) => <ParentPluginContext.Provider value={this}>{children}</ParentPluginContext.Provider>);

    hessia.registerPrimitiveComponent("ParentSelectionWidgetHaborComponentPrimitive", ParentSelectionWidgetHaborComponentPrimitive);
    // hessia.registerComponent(ParentSelectionWidgetHaborComponent);
    hessia.registerPrimitiveComponent("parent-detail-component-primitive", ParentListHaborComponentPrimitive);

    // component.registerComponent("parent-detail-component-primitive", ParentSelectionWidgetHaborComponentPrimitive);

    modelPlugin.registerFilter("parent", async (instances, token, settings) => {

      //  Check "All" Case
      //  TODO:  Abstract this!
      if (!settings) { console.log("No Settings defined for the 'parent' Plugin"); return instances; }
      if (settings.parentId === undefined) { console.log("No 'parentId' defined for the 'parent' Plugin."); return instances; }

      const updatedInsts: InstanceInternal[] = [];

      //  TODO:  This will be a LOT of API calls!  MAYBE just do ONE with the Parent stuff!
      for (const inst of instances) {

        //  Get Associations
        //  TODO-GENERAL:  Now... We MAY want to make this MORE GENERAL so the user can, FOR EXAMPLE, add a "Tag" or SOMETHING to a relationship, and we can add THAT to our filter constraint!!  PERHPAS we have a RESOLVABLE expression system that can follow the chain of this shit!?  I REALLLYY like that idea, and PERHAPS, AHHHH!!!  Systems can add their OWN resolvers to the expression system so it's not JUST class based, and PERHAPS we can make this show in a unique way in the UI!????
        const associations = await this.getParentAssociations(inst, token);

        if (associations.find(assoc => assoc.payload.destId.instanceId === settings.parentId)) {
          updatedInsts.push(inst);
        }

        //  TODO-GENERAL:  Support a "null" case or use some expression for NO parents?  Hmm.. OR perhaps a COMPLEX expression to be evaluated!?  Like a BOOLEAN query!?? HMM!!!
        //  TODO-GENERAL:  Support this type of filter in GENERAL across systems so we can ALSO do CRUD operations in this context and basically ANY operation!?? Hmm!!!
      }
      return updatedInsts;
    });

    modelPlugin.registerFilterComponent("parent", ParentFilterComponent as any);

    return this;
  }
}
