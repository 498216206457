import { Color, HaborComponent, HaborIconSelection, InstanceInternal, NounInternal, UserCredentialProps, Workspace } from 'habor-sdk';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Route, RouteComponentProps } from "react-router";
import { primaryFontFamily } from '../../../../packages/kelp-bar/styles';
import { HaborEmbeddedNamedObjectView } from './embedded-views/chicklet-component';

//  TODO-IMPORTANT:  Remove the old "Page" definition from this file!

export type HaborComponentID = string;

// /**
//  * Declare params for an Embedded view:  This factors out the "instance" from the EmbeddedView, which is passed at runtime?
//  */
// export interface HaborEmbededViewDeclaration {
//   componentName: string;
//   params: any;
// }

//
//  Embedded View Registration
//
export const embeddedViewRegister: { [nounId: string]: HaborComponent } = {
  namedobject: HaborEmbeddedNamedObjectView
};

export const getEmbeddedView = (noun: NounInternal) => {

  //  Unpack
  const { inherits = [] } = noun;

  //  Get the Class List
  const classList = [noun.id, ...inherits];

  //  Find the Component
  for (const cls of classList) {
    const component = embeddedViewRegister[cls];
    if (component) { return component; }
  }
}

//
//  Page Registration
//

export interface Page {
  id: string;
  component: any;  //  TODO:  This should match the React.Component CLASS (not an instance).
  route: string;  //  TODO:  In the future, consider REFERENCING a Route instead of hard-coding a string.  We will eventually optimize Habor to follow references QUICKLY with lots of caching.
}

//  NOTE:  This "Register" is owned by the "App" (Eventually "Registers" should be a built-in, dynamic Habor feature!)

//
//  Menu Registration
//

export interface MenuItem {
  title: string;
  pageId: string;  //  NOTE:  This should eventually be a "relationship" to a "Page" entity, which is a HaborComponent?
  icon: HaborIconSelection;
}

//  NOTE:  This "Register" is owned by the "App" (Eventually "Registers" should be a built-in, dynamic Habor feature!)


//
//  Main Menu Component
//

export interface BottomMenuItemProps {
  onPress: () => void;
  title: string;
  icon: HaborIconSelection;
}
export interface BottomMenuItemState {}

export class BottomMenuItem extends React.Component<BottomMenuItemProps, BottomMenuItemState> {
  constructor(props: BottomMenuItemProps) {
    super(props);
  }

  public render() {
    const { onPress, icon, title } = this.props;
    return (
      <TouchableOpacity onPress={ onPress } style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 45 }}>
        <Icon name={ icon.name } type={ icon.type } color={ Color.white } size={ 22 } />
        <Text style={{ color: Color.white, fontSize: 10, fontFamily: primaryFontFamily }}>{ title.toUpperCase() }</Text>
      </TouchableOpacity>
    );
  }
}

//  NOTE:  We PASS the MenuRegister info down to the Main Menu, because if we make it a concern of the menu, then the menu isn't reusable outside of Habor without removing Habor specific stuff.

export interface BottomMenuProps {
  children: React.ReactElement<BottomMenuItem>[];
}
export interface BottomMenuState {}

export class BottomMenu extends React.Component<BottomMenuProps, BottomMenuState> {
  constructor(props: BottomMenuProps) {
    super(props);
  }
  public render() {
    const { children } = this.props;
    return (
      <View style={{ flex: 0, height: 60, backgroundColor: Color.darkGray, flexDirection: 'row', display: "flex", alignItems: 'center' }}>
        { children }
      </View>
    )
  }
}



//
//  Habor App Registration
//
class HaborApp {
  public mainMenuRegister: MenuItem[] = [];
  public pageRegister: Page[] = [];
  public defaultPageId?: string;
  public init() {
    // const entityPage: Page = {
    //   component: EntityRouter,
    //   id: "entity-router",
    //   route: "entity-router"
    // };
    // this.pageRegister.push(entityPage);
    // this.mainMenuRegister.push({
    //   title: "Entities",
    //   pageId: "entity-router",
    //   icon: { name: "plus", type: "font-awesome" }
    // })
    // this.defaultPageId = entityPage.id;
  }
}

//
//  Habor App React Component
//

  //  CONSIDER:  In the future, maybe we should NOT have our components coupled to the "History" element used in ReactRouter... Maybe make our own Router abstraction which HAPPENS to use ReactRouter?
  interface HaborAppReactComponentProps extends RouteComponentProps, UserCredentialProps {
    workspace: InstanceInternal<Workspace>;
    haborApp: HaborApp;
  }
export interface HaborAppReactComponentState {}
/**
 * React Component to render an App
 * NOTE:  This is NOT a HaborComponent.  This is a simple ReactComponent.  I want to move to HaborComponents where and when it makes sense... I'm just not sure where / when that will be.
 * NOTE:  We start simple, with a row of buttons on the bottom, each corresponding to a "Page" in the app.  Currently, the Page registers itself with "registerPage".
 * CONSIDER:  In the future, consider rendering the App with a HaborComponent.  For now, let's just use primitives.
 */
export class HaborAppReactComponent extends React.Component<HaborAppReactComponentProps, HaborAppReactComponentState> {
  constructor(props: HaborAppReactComponentProps) {
    super(props);
  }

  public componentDidMount = () => {

    const { pageRegister, defaultPageId } = this.props.haborApp;

    //  Set the Default Route
    // const page = EntityRouter;
    console.log("test");
    if (defaultPageId !== undefined) {
      const defaultPage = pageRegister.find(page => page.id == defaultPageId);
      if (defaultPage == undefined) { throw new Error(`Undefined default page: '${ defaultPageId }'`); }
      this.props.history.push(`${this.props.match.path}/${ defaultPage.route }`)
    }

  }

  public render() {

    const { pageRegister, mainMenuRegister } = this.props.haborApp;

    //  Unpack Props
    const { history, match } = this.props;

    //  Create the Menu Items
    const menuItems = mainMenuRegister.map(menuItem => {
      const page = pageRegister.find(page => page.id == menuItem.pageId);
      if (page == undefined) { throw new Error("Undefined Page!"); }
      const handlePress = () => {
        history.push(`${match.path}/${page.route}`);
      }
      return <BottomMenuItem onPress={ handlePress } title={ menuItem.title } icon={ menuItem.icon } />
    });

    //  Create Routes
    const routes = pageRegister.map(page => {
      const TypedPageComponent = page.component as any;
      return <Route path={`${ match.path }/${ page.route }`} render={ () => <TypedPageComponent { ...this.props } /> } />
    });

    //  Compile the App
    return (
      <View style={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: 'red' }}>
        <View style={{ flex: 1, backgroundColor: 'gray' }}>
          { routes }
        </View>
        <BottomMenu>
          { menuItems }
        </BottomMenu>
      </View>
    );
  }
}

// export class HaborAppReactComponentPrimitive extends React.Component<PrimitiveProps, any> {
//   public haborApp?: HaborApp;
//   public render() {
//     //  TODO:  Update this pattern... take through props?  Maybe put in the state?  I don't want to break state persistence...
//     if (this.haborApp == undefined) {
//       this.haborApp = new HaborApp();
//       this.haborApp.init();
//     }
//     const { frameworkProps: { context: { workspace, token, user } } } = this.props;
//     if (workspace == undefined) { throw new Error("HaborAppReactComponent requires a Workspace object."); }
//     return (
//       <HaborContainer frameworkProps={ this.props.frameworkProps } style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'orange' }}>
//         <HaborAppReactComponent haborApp={ this.haborApp } workspace={ workspace } token={ token } user={ user } />
//       </HaborContainer>
//     );
//   }
// }

// export const HaborAppReact: HaborComponent = {
//   name: "HaborAppReact",
//   propsSchema: { type: "object", extensible: false },
//   element: {
//     name: "HaborAppReactComponentPrimitive",
//     props: {},
//     children: []
//   }
// };